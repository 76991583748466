import { v4 as uuid } from "uuid"
import { HardwareChannelFilter, HardwareChannelFilterValue } from "../api/types"
import {
  HardwareValueLength,
  HardwareValueOffset,
  HardwareValueOperator,
  PacketOffset,
  PacketDataElement,
} from "../api/types/hardwareTypes"
import { MediaSpecType } from "../api/types/mediaTypes"
import { formatMediaSpec, getTypeMask, parseIPMediaSpec } from "../utils/mediaSpec"

export const COMPARATOR_LIMIT = 32
export const COMPARATOR_SIZE = 32
export const EXTRACTOR_LIMIT = 4
export const EXTRACTOR_SIZE = 32

export const defaultHardwareChannelValueFilter: HardwareChannelFilterValue = {
  clsid: "DBE5EE62-70ED-4EC0-80A4-A8D916F8B3D5",
  id: uuid().toUpperCase(),
  length: HardwareValueLength.HARDWARE_VALUE_LENGTH_1_BYTE,
  mask: 0xff,
  offset: HardwareValueOffset.HARDWARE_VALUE_OFFSET_BEGINNING_OF_PACKET,
  offsetLocation: 0,
  operator: HardwareValueOperator.HARDWARE_VALUE_OPERATOR_EQUAL_TO,
  value: 0,
  valueMediaSpec: parseIPMediaSpec("0.0.0.0"),
}

export interface HardwareResources {
  comparators: number
  extractors: number
}

export function addAndValueFilter(
  valueFilter: HardwareChannelFilterValue,
  andValueFilter: HardwareChannelFilterValue
) {
  const prevNode = valueFilter.andNode
  valueFilter.andNode = andValueFilter
  if (prevNode) {
    let currNode = valueFilter.andNode
    if (currNode) {
      while (currNode.andNode) {
        currNode = currNode.andNode
      }
      currNode.andNode = prevNode
    }
  }
}

export function addOrValueFilter(
  valueFilter: HardwareChannelFilterValue,
  orValueFilter: HardwareChannelFilterValue
) {
  const prevNode = valueFilter.orNode
  valueFilter.orNode = orValueFilter
  if (prevNode) {
    let currNode = valueFilter.orNode
    if (currNode) {
      while (currNode.orNode) {
        currNode = currNode.orNode
      }
      currNode.orNode = prevNode
    }
  }
}

export function comparePacketDataElements(a: PacketDataElement, b: PacketDataElement): boolean {
  return (
    a.dynOffset === b.dynOffset && a.offset === b.offset && a.size === b.size && a.value === b.value
  )
}

export function countValueFilters(
  valueFilter: HardwareChannelFilterValue | null | undefined
): number {
  let count = 0

  if (valueFilter) {
    count++
    if (valueFilter.andNode) {
      count += countValueFilters(valueFilter.andNode)
    }
    if (valueFilter.orNode) {
      count += countValueFilters(valueFilter.orNode)
    }
  }

  return count
}

export function deleteValueFilter(
  valueFilter: HardwareChannelFilterValue,
  id: string
): HardwareChannelFilterValue | undefined {
  if (valueFilter.id === id) {
    if (valueFilter.andNode) {
      const newValueFilter = valueFilter.andNode
      if (valueFilter.orNode) {
        addOrValueFilter(valueFilter.andNode, valueFilter.orNode)
      }
      return newValueFilter
    } else {
      return valueFilter.orNode
    }
  } else {
    if (valueFilter.andNode) {
      valueFilter.andNode = deleteValueFilter(valueFilter.andNode, id)
    }
    if (valueFilter.orNode) {
      valueFilter.orNode = deleteValueFilter(valueFilter.orNode, id)
    }
    return valueFilter
  }
}

function determineValueFilterHardwareResources(
  valueFilter: HardwareChannelFilterValue,
  comparators: PacketDataElement[],
  extractors: PacketDataElement[]
) {
  switch (valueFilter.length) {
    default:
    case HardwareValueLength.HARDWARE_VALUE_LENGTH_1_BYTE:
      extractors.push({
        dynOffset: valueFilter.offset,
        offset: valueFilter.offsetLocation,
        size: 8,
        value: null,
      })
      comparators.push({
        dynOffset: valueFilter.offset,
        offset: valueFilter.offsetLocation,
        size: 8,
        value: valueFilter.value,
      })
      break
    case HardwareValueLength.HARDWARE_VALUE_LENGTH_2_BYTE:
      extractors.push({
        dynOffset: valueFilter.offset,
        offset: valueFilter.offsetLocation,
        size: 16,
        value: null,
      })
      comparators.push({
        dynOffset: valueFilter.offset,
        offset: valueFilter.offsetLocation,
        size: 16,
        value: valueFilter.value,
      })
      break
    case HardwareValueLength.HARDWARE_VALUE_LENGTH_4_BYTE:
      extractors.push({
        dynOffset: valueFilter.offset,
        offset: valueFilter.offsetLocation,
        size: 32,
        value: null,
      })
      comparators.push({
        dynOffset: valueFilter.offset,
        offset: valueFilter.offsetLocation,
        size: 32,
        value: valueFilter.value,
      })
      break
    case HardwareValueLength.HARDWARE_VALUE_LENGTH_MAC_ADDRESS: {
      const addressMac = formatMediaSpec(valueFilter.valueMediaSpec)
      extractors.push({
        dynOffset: valueFilter.offset,
        offset: valueFilter.offsetLocation,
        size: 48,
        value: null,
      })
      comparators.push({
        dynOffset: valueFilter.offset,
        offset: valueFilter.offsetLocation,
        size: 48,
        value: addressMac,
      })
      break
    }
    case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV4_ADDRESS: {
      const addressIPv4 = formatMediaSpec(valueFilter.valueMediaSpec)
      extractors.push({
        dynOffset: valueFilter.offset,
        offset: valueFilter.offsetLocation,
        size: 32,
        value: null,
      })
      comparators.push({
        dynOffset: valueFilter.offset,
        offset: valueFilter.offsetLocation,
        size: 32,
        value: addressIPv4,
      })
      break
    }
    case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV6_ADDRESS: {
      const addressIPv6 = formatMediaSpec(valueFilter.valueMediaSpec)
      extractors.push({
        dynOffset: valueFilter.offset,
        offset: valueFilter.offsetLocation,
        size: 128,
        value: null,
      })
      comparators.push({
        dynOffset: valueFilter.offset,
        offset: valueFilter.offsetLocation,
        size: 128,
        value: addressIPv6,
      })
      break
    }
  }
  if (valueFilter.andNode) {
    determineValueFilterHardwareResources(valueFilter.andNode, comparators, extractors)
  }
  if (valueFilter.orNode) {
    determineValueFilterHardwareResources(valueFilter.orNode, comparators, extractors)
  }
}

export function determineHardwareResources(channels: HardwareChannelFilter[]): HardwareResources {
  let extractors: PacketDataElement[] = []
  let comparators: PacketDataElement[] = []

  // generate statistics for each channel
  channels.forEach((hcf: HardwareChannelFilter) => {
    if (hcf.useAddressFilter) {
      const address1 = formatMediaSpec(hcf.addressFilter.address1)
      const address2 = formatMediaSpec(hcf.addressFilter.address2)
      const anyAddress =
        ((hcf.addressFilter.address2.mask !== undefined
          ? hcf.addressFilter.address2.mask
          : getTypeMask(hcf.addressFilter.address2.type as MediaSpecType)) &
          getTypeMask(hcf.addressFilter.address2.type as MediaSpecType)) ===
        0

      let dynOffset = PacketOffset.PACKET_OFFSET_DYNOFF_IPV4_FRAME
      let offset1 = 0
      let offset2 = 0
      let size = 0
      if (hcf.addressFilter.address1.type === MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS) {
        dynOffset = PacketOffset.PACKET_OFFSET_DYNOFF_LAYER2_FRAME
        offset1 = 0
        offset2 = 6
        size = 48
      } else {
        dynOffset = PacketOffset.PACKET_OFFSET_DYNOFF_IPV4_FRAME
        offset1 = 12
        offset2 = 16
        size = 32
      }

      if (hcf.addressFilter.accept1To2 && hcf.addressFilter.accept2To1) {
        extractors.push({
          dynOffset,
          offset: offset1,
          size,
          value: null,
        })
        extractors.push({
          dynOffset,
          offset: offset2,
          size,
          value: null,
        })
        comparators.push({
          dynOffset,
          offset: offset1,
          size,
          value: address1,
        })
        comparators.push({
          dynOffset,
          offset: offset2,
          size,
          value: address1,
        })
        if (!anyAddress) {
          comparators.push({
            dynOffset,
            offset: offset1,
            size,
            value: address2,
          })
          comparators.push({
            dynOffset,
            offset: offset2,
            size,
            value: address2,
          })
        }
      } else if (hcf.addressFilter.accept1To2) {
        extractors.push({
          dynOffset,
          offset: offset1,
          size,
          value: null,
        })
        comparators.push({
          dynOffset,
          offset: offset1,
          size,
          value: address1,
        })
        if (!anyAddress) {
          extractors.push({
            dynOffset,
            offset: offset2,
            size,
            value: null,
          })
          comparators.push({
            dynOffset,
            offset: offset2,
            size,
            value: address2,
          })
        }
      } else {
        extractors.push({
          dynOffset,
          offset: offset2,
          size,
          value: null,
        })
        comparators.push({
          dynOffset,
          offset: offset2,
          size,
          value: address1,
        })
        if (!anyAddress) {
          extractors.push({
            dynOffset,
            offset: offset1,
            size,
            value: null,
          })
          comparators.push({
            dynOffset,
            offset: offset1,
            size,
            value: address2,
          })
        }
      }
    }
    if (hcf.usePortFilter) {
      const port1 = formatMediaSpec(hcf.portFilter.port1)
      const port2 = formatMediaSpec(hcf.portFilter.port2)
      const anyPort =
        ((hcf.portFilter.port2.mask !== undefined
          ? hcf.portFilter.port2.mask
          : getTypeMask(hcf.portFilter.port2.type as MediaSpecType)) &
          getTypeMask(hcf.portFilter.port2.type as MediaSpecType)) ===
        0

      const dynOffset = PacketOffset.PACKET_OFFSET_DYNOFF_TCP_UDP_FRAME
      const size = 32
      if (hcf.portFilter.accept1To2 && hcf.portFilter.accept2To1) {
        extractors.push({
          dynOffset,
          offset: 0,
          size,
          value: null,
        })
        extractors.push({
          dynOffset,
          offset: 2,
          size,
          value: null,
        })
        comparators.push({
          dynOffset,
          offset: 0,
          size,
          value: port1,
        })
        comparators.push({
          dynOffset,
          offset: 2,
          size,
          value: port1,
        })
        if (!anyPort) {
          comparators.push({
            dynOffset,
            offset: 2,
            size,
            value: port2,
          })
          comparators.push({
            dynOffset,
            offset: 0,
            size,
            value: port2,
          })
        }
      } else if (hcf.portFilter.accept1To2) {
        extractors.push({
          dynOffset,
          offset: 0,
          size,
          value: null,
        })
        comparators.push({
          dynOffset,
          offset: 0,
          size,
          value: port1,
        })
        if (!anyPort) {
          extractors.push({
            dynOffset,
            offset: 2,
            size,
            value: null,
          })
          comparators.push({
            dynOffset,
            offset: 2,
            size,
            value: port2,
          })
        }
      } else {
        extractors.push({
          dynOffset,
          offset: 2,
          size,
          value: null,
        })
        comparators.push({
          dynOffset,
          offset: 2,
          size,
          value: port1,
        })
        if (!anyPort) {
          extractors.push({
            dynOffset,
            offset: 0,
            size,
            value: null,
          })
          comparators.push({
            dynOffset,
            offset: 0,
            size,
            value: port2,
          })
        }
      }
    }
    if (hcf.useVLANFilter) {
      const dynOffset = PacketOffset.PACKET_OFFSET_DYNOFF_ETHER_TYPE_LEN_FRAME
      const size = 32
      for (let layer = 1; layer <= hcf.vlanFilter.layers; ++layer) {
        for (let subLayer = layer; subLayer > 1; --subLayer) {
          extractors.push({
            dynOffset,
            offset: 4 + 4 * (subLayer - 2),
            size,
            value: null,
          })
          comparators.push({
            dynOffset,
            offset: 4 + 4 * (subLayer - 2),
            size,
            value: 0x8100,
          })
          comparators.push({
            dynOffset,
            offset: 4 + 4 * (subLayer - 2),
            size,
            value: 0x88a8,
          })
        }

        extractors.push({
          dynOffset,
          offset: 2 + 4 * (layer - 1),
          size,
          value: null,
        })
        for (const id of hcf.vlanFilter.vlan) {
          comparators.push({
            dynOffset,
            offset: 2 + 4 * (layer - 1),
            size,
            value: id,
          })
        }
      }
    }
    if (hcf.useMPLSFilter) {
      const dynOffset = PacketOffset.PACKET_OFFSET_DYNOFF_ETHER_TYPE_FRAME
      const size = 32
      for (let layer = 1; layer <= hcf.mplsFilter.layers; ++layer) {
        for (let subLayer = layer; subLayer > 1; --subLayer) {
          extractors.push({
            dynOffset,
            offset: 2 + 4 * (subLayer - 2),
            size,
            value: null,
          })
          comparators.push({
            dynOffset,
            offset: 2 + 4 * (subLayer - 2),
            size,
            value: 0,
          })
        }

        extractors.push({
          dynOffset,
          offset: 2 + 4 * (layer - 1),
          size,
          value: null,
        })
        for (const label of hcf.mplsFilter.mpls) {
          comparators.push({
            dynOffset,
            offset: 2 + 4 * (layer - 1),
            size,
            value: label,
          })
        }
      }
    }
    if (hcf.useValueFilter && hcf.valueFilter) {
      determineValueFilterHardwareResources(hcf.valueFilter, comparators, extractors)
    }
  })

  // remove duplicates
  extractors = extractors.filter(
    (item, index, self) => index === self.findIndex(pde => comparePacketDataElements(pde, item))
  )
  comparators = comparators.filter(
    (item, index, self) => index === self.findIndex(pde => comparePacketDataElements(pde, item))
  )

  // take size into account
  const extractorsSize = extractors.reduce((total: number, pde: PacketDataElement) => {
    return total + Math.ceil(pde.size / EXTRACTOR_SIZE)
  }, 0)
  const comparatorsGrouped: PacketDataElement[] = []
  comparators.forEach((pde: PacketDataElement) => {
    const index = comparatorsGrouped.findIndex(
      (pde2: PacketDataElement) => pde.dynOffset === pde2.dynOffset && pde.offset === pde2.offset
    )
    if (index !== -1) {
      comparatorsGrouped[index].size += pde.size
    } else {
      comparatorsGrouped.push({ ...pde, value: null })
    }
  })
  const comparatorsSize = comparatorsGrouped.reduce((total: number, pde: PacketDataElement) => {
    return total + Math.ceil(pde.size / COMPARATOR_SIZE)
  }, 0)

  return {
    comparators: extractorsSize,
    extractors: comparatorsSize,
  }
}

export function insertValueFilter(
  rootValueFilter: HardwareChannelFilterValue,
  newValueFilter: HardwareChannelFilterValue,
  parentId: string,
  isAnd: boolean
): HardwareChannelFilterValue {
  if (rootValueFilter.id === parentId) {
    if (isAnd) {
      addAndValueFilter(rootValueFilter, newValueFilter)
    } else {
      addOrValueFilter(rootValueFilter, newValueFilter)
    }
  } else {
    if (rootValueFilter.andNode) {
      rootValueFilter.andNode = insertValueFilter(
        rootValueFilter.andNode,
        newValueFilter,
        parentId,
        isAnd
      )
    }
    if (rootValueFilter.orNode) {
      rootValueFilter.orNode = insertValueFilter(
        rootValueFilter.orNode,
        newValueFilter,
        parentId,
        isAnd
      )
    }
  }
  return rootValueFilter
}

export function replaceValueFilter(
  rootValueFilter: HardwareChannelFilterValue,
  valueFilter: HardwareChannelFilterValue
): HardwareChannelFilterValue {
  if (rootValueFilter.id === valueFilter.id) {
    return valueFilter
  } else {
    if (rootValueFilter.andNode) {
      rootValueFilter.andNode = replaceValueFilter(rootValueFilter.andNode, valueFilter)
    }
    if (rootValueFilter.orNode) {
      rootValueFilter.orNode = replaceValueFilter(rootValueFilter.orNode, valueFilter)
    }
    return rootValueFilter
  }
}
