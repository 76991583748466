import * as React from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { ExpertSearch } from "./ExpertSearchTypes"
import { SecondaryButton } from "../Buttons"
import { Panel } from "../Panel"
import { formatISODateTime } from "../../../utils/formatUtils"
import { getShowLocalTime } from "../../../store"

const SearchItem = styled.span``

const SearchItemLabel = styled.div`
  padding-right: 0.25rem;
  white-space: nowrap;
  font-weight: 700;
  color: ${props => props.theme.propTableHeaderColor};
  font-size: ${props => props.theme.propTableHeaderFontSize};
  line-height: 1.5rem;
  text-transform: ${props => props.theme.propTableHeaderTextTransform};
`

const SearchList = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  flex-wrap: wrap;
`

const SearchListItem = styled.li`
  border: 1px dotted ${props => props.theme.inputBorderColor};
  display: flex;
  list-style: none;
  padding: 0.375rem 0.5rem;
`

const SearchResultsPanel = styled(Panel)`
  display: flex;
  align-items: center;
  justify-content: stretch;
  white-space: nowrap;

  & > * + * {
    margin-left: 0.5rem;
  }

  margin-bottom: 10px;
`

type ExpertSearchResultsProps = {
  search: ExpertSearch
  onClearSearch: () => void
}

const ExpertSearchResults = ({ search, onClearSearch }: ExpertSearchResultsProps) => {
  const showLocalTime = useSelector(getShowLocalTime)

  const renderSearchItems = () => {
    const items: React.ReactNode[] = []
    if (search.clientAddress.length > 0) {
      items.push(
        <SearchListItem key="clientAddress">
          <SearchItemLabel>Client Address</SearchItemLabel>
          <SearchItem>{search.clientAddress}</SearchItem>
        </SearchListItem>
      )
    }
    if (search.clientPort.length > 0) {
      items.push(
        <SearchListItem key="clientPort">
          <SearchItemLabel>Client Port</SearchItemLabel>
          <SearchItem>{search.clientPort}</SearchItem>
        </SearchListItem>
      )
    }
    if (search.serverAddress.length > 0) {
      items.push(
        <SearchListItem key="serverAddress">
          <SearchItemLabel>Server Address</SearchItemLabel>
          <SearchItem>{search.serverAddress}</SearchItem>
        </SearchListItem>
      )
    }
    if (search.serverPort.length > 0) {
      items.push(
        <SearchListItem key="serverPort">
          <SearchItemLabel>Server Port</SearchItemLabel>
          <SearchItem>{search.serverPort}</SearchItem>
        </SearchListItem>
      )
    }
    if (search.protocol.length > 0) {
      items.push(
        <SearchListItem key="protocol">
          <SearchItemLabel>Protocol</SearchItemLabel>
          <SearchItem>{search.protocol}</SearchItem>
        </SearchListItem>
      )
    }
    if (search.application.length > 0) {
      items.push(
        <SearchListItem key="application">
          <SearchItemLabel>Application</SearchItemLabel>
          <SearchItem>{search.application}</SearchItem>
        </SearchListItem>
      )
    }
    if (search.mpls.length > 0) {
      items.push(
        <SearchListItem key="mpls">
          <SearchItemLabel>MPLS</SearchItemLabel>
          <SearchItem>{search.mpls}</SearchItem>
        </SearchListItem>
      )
    }
    if (search.vlan.length > 0) {
      items.push(
        <SearchListItem key="vlan">
          <SearchItemLabel>VLAN</SearchItemLabel>
          <SearchItem>{search.vlan}</SearchItem>
        </SearchListItem>
      )
    }
    if (search.vxlanGPID.length > 0) {
      items.push(
        <SearchListItem key="vxlanGPID">
          <SearchItemLabel>VXLAN GPID</SearchItemLabel>
          <SearchItem>{search.vxlanGPID}</SearchItem>
        </SearchListItem>
      )
    }
    if (search.vxlanVNI.length > 0) {
      items.push(
        <SearchListItem key="vxlanVNI">
          <SearchItemLabel>VXLAN VNI</SearchItemLabel>
          <SearchItem>{search.vxlanVNI}</SearchItem>
        </SearchListItem>
      )
    }
    if (search.start.length > 0 && search.finish.length > 0) {
      items.push(
        <SearchListItem key="time-range">
          <SearchItemLabel>Time Range</SearchItemLabel>
          <SearchItem>
            {formatISODateTime(search.start, 0, showLocalTime)} &rarr;
            {formatISODateTime(search.finish, 0, showLocalTime)}
          </SearchItem>
        </SearchListItem>
      )
    }
    return items
  }

  const searchItems = renderSearchItems()
  if (searchItems.length === 0) return null

  return (
    <SearchResultsPanel>
      <SearchList>{searchItems}</SearchList>
      <SecondaryButton onClick={() => onClearSearch()}>Clear Search</SecondaryButton>
    </SearchResultsPanel>
  )
}

export default ExpertSearchResults
