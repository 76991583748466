export function ranges(a: number[]) {
  let s: number | undefined = undefined
  let e: number | undefined = undefined
  const r: number[][] = []
  for (let z = 0, len = a.length; z < len; ++z) {
    const i = a[z]
    if (s === undefined) {
      s = e = i
    } else if (e === undefined || i === e || i === e + 1) {
      e = i
    } else {
      r.push([s, e])
      s = e = i
    }
  }
  if (s !== undefined && e !== undefined) {
    r.push([s, e])
  }
  return r
}

export default ranges
