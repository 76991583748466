import * as React from "react"

const Moon = (props: React.SVGAttributes<SVGElement>) => {
  const { fill = "#fff", stroke = "#333", strokeWidth = "2", ...svgProps } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...svgProps}>
      <g>
        <path
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
          d="M30.637 18.146c-.314-.079-.63 0-.905.198a11.534 11.534 0 0 1-3.502 2.055c-1.22.474-2.557.71-3.974.71a11.547 11.547 0 0 1-8.184-3.397 11.643 11.643 0 0 1-3.384-8.218c0-1.344.236-2.648.63-3.833a10.621 10.621 0 0 1 1.888-3.398A1.011 1.011 0 0 0 13.05.841c-.276-.198-.59-.277-.905-.198-3.345.909-6.257 2.924-8.342 5.61a15.628 15.628 0 0 0-3.187 9.483c0 4.307 1.731 8.219 4.564 11.063 2.833 2.845 6.69 4.584 11.018 4.584 3.62 0 6.964-1.265 9.64-3.359a15.585 15.585 0 0 0 5.509-8.653c.157-.553-.158-1.106-.709-1.225"
        />
      </g>
    </svg>
  )
}

export default Moon
