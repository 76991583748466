import * as React from "react"
import { connect } from "react-redux"
import { isEqual } from "lodash"
import styled from "styled-components"
import { Switch, Route, Redirect, RouteComponentProps } from "react-router-dom"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import Interval from "../common/Interval"
import Divider from "../common/Divider"
import { Spinner } from "../common/Spinner"
import CaptureNav from "../CaptureNav"
import EventsView from "../EventsView"
import CaptureHomeView from "../CaptureHomeView"
import NetworkDashboardView from "../NetworkDashboardView"
import ApplicationsDashboardView from "../ApplicationsDashboardView"
import VoIPDashboardView from "../VoIPDashboardView"
import PacketsView from "../PacketsView"
import ExpertClientsServersView from "../ExpertClientsServersView"
import ExpertFlowsView from "../ExpertFlowsView"
import ExpertApplicationsView from "../ExpertApplicationsView"
import ExpertEventSummaryView from "../ExpertEventSummaryView"
import ExpertEventLogView from "../ExpertEventLogView"
import VoIPCallsView from "../VoIPCallsView"
import VoIPMediaView from "../VoIPMediaView"
import SummaryStatisticsView from "../SummaryStatisticsView"
import NodeStatisticsView from "../NodeStatisticsView"
import ProtocolStatisticsView from "../ProtocolStatisticsView"
import ApplicationStatisticsView from "../ApplicationStatisticsView"
import ReconstructionsView from "../ReconstructionsView"
import CountryStatisticsView from "../CountryStatisticsView"
import MPLSVLANVXLANStatisticsView from "../MPLSVLANVXLANStatisticsView"
import PluginCaptureView from "../PluginCaptureView"
import VoIPCallVisualizerView from "../VoIPCallVisualizerView"
import WebClientsView from "../WebClientsView"
import WebServersView from "../WebServersView"
import WebPagesView from "../WebPagesView"
import WebRequestsView from "../WebRequestsView"
import WLANNodesView from "../WLANNodesView"
import {
  CAPTURE_FORENSIC_SEARCH_PATH,
  getEngineCapturesUrl,
  getEngineForensicSearchesUrl,
  getCaptureForensicSearchBaseUrl,
  getCaptureForensicSearchUrl,
} from "../../routes"
import { getEngine, getAuthToken, getCapabilities, getUserId } from "../../store"
import { fetchCFSProperties } from "../../api/api"
import {
  CaptureProperties,
  ForensicSearchProperties,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { PeekCaptureStatus, PeekFileViewStatus } from "../../api/types/peekTypes"

const FlowVisualizerView = React.lazy(() => import("../FlowVisualizerView"))
const GraphsView = React.lazy(() => import("../GraphsView"))
const PeerMapView = React.lazy(() => import("../PeerMapView"))
const VoIPReport = React.lazy(() => import("../VoIPReport"))

const CaptureWrapper = styled.div`
  flex-grow: 1;
  display: flex;
`

type Routes = {
  rel: string
  component: React.ElementType
}

const defaultRoutes: Routes[] = [
  {
    rel: "home",
    component: CaptureHomeView,
  },
  {
    rel: "network-dashboard",
    component: NetworkDashboardView,
  },
  {
    rel: "app-dashboard",
    component: ApplicationsDashboardView,
  },
  {
    rel: "voip-dashboard",
    component: VoIPDashboardView,
  },
  {
    rel: "packets",
    component: PacketsView,
  },
  {
    rel: "events",
    component: EventsView,
  },
  {
    rel: "expert-clients-servers",
    component: ExpertClientsServersView,
  },
  {
    rel: "expert-flows",
    component: ExpertFlowsView,
  },
  {
    rel: "expert-applications",
    component: ExpertApplicationsView,
  },
  {
    rel: "expert-event-summary",
    component: ExpertEventSummaryView,
  },
  {
    rel: "expert-event-log",
    component: ExpertEventLogView,
  },
  {
    rel: "voip-calls",
    component: VoIPCallsView,
  },
  {
    rel: "voip-media",
    component: VoIPMediaView,
  },
  {
    rel: "web-servers",
    component: WebServersView,
  },
  {
    rel: "web-clients",
    component: WebClientsView,
  },
  {
    rel: "web-pages",
    component: WebPagesView,
  },
  {
    rel: "web-requests",
    component: WebRequestsView,
  },
  {
    rel: "peer-map",
    component: PeerMapView,
  },
  {
    rel: "graphs",
    component: GraphsView,
  },
  {
    rel: "summary",
    component: SummaryStatisticsView,
  },
  {
    rel: "nodes",
    component: NodeStatisticsView,
  },
  {
    rel: "protocols",
    component: ProtocolStatisticsView,
  },
  {
    rel: "applications",
    component: ApplicationStatisticsView,
  },
  {
    rel: "reconstructions",
    component: ReconstructionsView,
  },
  {
    rel: "countries",
    component: CountryStatisticsView,
  },
  {
    rel: "mplsvlanvxlan",
    component: MPLSVLANVXLANStatisticsView,
  },
  {
    rel: "plugin/:pluginId",
    component: PluginCaptureView,
  },
  {
    rel: "flow-visualizer/:flowId",
    component: FlowVisualizerView,
  },
  {
    rel: "voice-and-video-visualizer/:callId",
    component: VoIPCallVisualizerView,
  },
  {
    rel: "voice-and-video-report/:callId",
    component: VoIPReport,
  },
  {
    rel: "voice-and-video-report",
    component: VoIPReport,
  },
  {
    rel: "nodes-wireless",
    component: WLANNodesView,
  },
]

// These props are added to all capture/forensic search views.
export type CaptureViewProps = {
  captureProperties: CaptureProperties | ForensicSearchProperties
  isLive: boolean
  onRefreshCaptureProperties: () => void
}

// All capture views receive these router params.
export type CaptureRouteParams = {
  type: "captures" | "forensic-searches"
  capId: string
}

type CaptureProps = RouteComponentProps<CaptureRouteParams> & {
  engine: string
  authToken: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  userId: string
}

type CaptureState = {
  captureProperties: CaptureProperties | ForensicSearchProperties | null
  refreshInterval: number
  isNavOpen: boolean
}

class Capture extends React.Component<CaptureProps, CaptureState> {
  state: CaptureState = {
    captureProperties: null,
    refreshInterval: 5000,
    isNavOpen: true,
  }

  componentDidMount() {
    this.onRefresh()
  }

  onRefresh = () => {
    const { engine, authToken } = this.props
    const { type, capId } = this.props.match.params
    fetchCFSProperties(engine, authToken, type, capId)
      .then((captureProperties: CaptureProperties | ForensicSearchProperties) => {
        if (captureProperties && captureProperties.id) {
          if (type === "forensic-searches") {
            const refreshInterval =
              captureProperties.status !== PeekFileViewStatus.PEEK_FILE_VIEW_STATUS_COMPLETE
                ? 2500
                : 5000
            this.setState({ refreshInterval })
          }
          if (!isEqual(captureProperties, this.state.captureProperties)) {
            this.setState({ captureProperties })
          }
        }
      })
      .catch(() => {
        this.props.history.push(
          type === "captures" ? getEngineCapturesUrl() : getEngineForensicSearchesUrl()
        )
      })
  }

  onToggleNav = () => {
    this.setState({ isNavOpen: !this.state.isNavOpen })
  }

  render() {
    const { engineCapabilities, userId } = this.props
    const { captureProperties, refreshInterval, isNavOpen } = this.state
    if (captureProperties == null || engineCapabilities == null) return null
    const { type, capId } = this.props.match.params
    const isCapture = type === "captures"

    // make sure the user can view this capture or forensic search
    const isUserOwner = userId === captureProperties.creatorSID
    const policies = engineCapabilities.userRights.policies
    const canView =
      isUserOwner ||
      ((!engineCapabilities.capabilities.includes(
        EngineCapabilities.viewCapturesForensicSearchesACL
      ) ||
        policies.includes(
          isCapture ? EngineUserPolicies.viewCaptures : EngineUserPolicies.viewForensicSearches
        )) &&
        (policies.includes(EngineUserPolicies.viewPackets) ||
          policies.includes(EngineUserPolicies.viewStats)))
    if (!canView) {
      return (
        <Redirect to={`${isCapture ? getEngineCapturesUrl() : getEngineForensicSearchesUrl()}`} />
      )
    }

    // Figure out if this is a "live" view: a capture that's capturing or a forensic search that's processing.
    let isLive = true
    if (captureProperties !== null) {
      if ("openResult" in captureProperties) {
        isLive = captureProperties.status === PeekFileViewStatus.PEEK_FILE_VIEW_STATUS_OPENING
      } else {
        isLive =
          captureProperties.status === PeekCaptureStatus.peekCaptureStatusCapturing ||
          captureProperties.status === PeekCaptureStatus.peekCaptureStatusCapturingStopActive
      }
    }

    const baseTitle = isCapture ? "Captures" : "Forensic Searches"
    const title = captureProperties ? captureProperties.name : ""
    const routes = defaultRoutes.map(route => {
      const { rel, component: Component } = route
      return (
        <Route
          key={rel}
          exact
          path={`${CAPTURE_FORENSIC_SEARCH_PATH}/${rel}`}
          render={props => (
            <Component
              {...props}
              captureProperties={captureProperties}
              isLive={isLive}
              onRefreshCaptureProperties={this.onRefresh}
            />
          )}
        />
      )
    })
    return (
      <>
        <BreadcrumbItem to={getCaptureForensicSearchBaseUrl(type)} title={baseTitle} />
        <BreadcrumbItem to={getCaptureForensicSearchUrl(type, capId)} title={title} />
        <Interval timeout={refreshInterval} enabled={true} callback={this.onRefresh} />
        <CaptureWrapper>
          {isNavOpen && <CaptureNav captureProperties={captureProperties} />}
          <Divider isOpen={isNavOpen} onToggle={this.onToggleNav} />
          <React.Suspense fallback={<Spinner />}>
            <Switch>
              <Redirect
                exact
                path={CAPTURE_FORENSIC_SEARCH_PATH}
                to={`${getCaptureForensicSearchUrl(type, capId)}/home`}
              />
              {routes}
            </Switch>
          </React.Suspense>
        </CaptureWrapper>
      </>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  engineCapabilities: getCapabilities(state) || null,
  userId: getUserId(state),
})

export default connect(mapStateToProps)(Capture)
