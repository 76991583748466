import * as React from "react"
import { useMount } from "react-use"
import { Collapse, Form, FormGroup } from "reactstrap"
import { Alert } from "../common/Alert"
import { CheckGroup, FormText, Label } from "../common/Form"
import { Input } from "../common/Input"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { PasswordInput } from "../common/PasswordInput"
import { Engine } from "../../api/types"
import { GROUP_AUTHENTICATION_FLAG } from "../../utils/engineUtils"
import { decrypt, encrypt } from "../../utils/crypto"

const enum ConnType {
  GROUP = "group",
  PASS = "pass",
  NONE = "none",
}

type EngineModalProps = {
  engine: Engine | null
  groups: string[]
  onOK: (engine: Engine) => void
  onCancel: () => void
}

const EngineModal = ({ engine, groups, onOK, onCancel }: EngineModalProps) => {
  const [group, setGroup] = React.useState(engine?.group ?? "")
  const [name, setName] = React.useState(engine?.name ?? engine?.remoteName ?? "")
  const [host, setHost] = React.useState(engine?.host ?? "")
  const [username, setUsername] = React.useState(engine?.username ?? "")
  const [password, setPassword] = React.useState("")
  const [touchedHost, setTouchedHost] = React.useState(false)
  const [connType, setConnType] = React.useState<ConnType>(() => {
    if (engine) {
      if (engine.username && engine.password) {
        return ConnType.PASS
      } else if (engine.password) {
        return ConnType.GROUP
      } else {
        return ConnType.NONE
      }
    }
    return ConnType.NONE
  })

  useMount(() => {
    if (engine && engine.username && engine.password) {
      decrypt(engine.username, engine.password)
        .then(decryptedPassword => {
          setPassword(decryptedPassword)
        })
        .catch(() => {
          console.error("Error decrypting password")
        })
    }
  })

  async function getNewEngine(): Promise<Engine> {
    if (connType === ConnType.GROUP) {
      if (engine) {
        // Editing an existing engine.
        return {
          ...engine,
          group,
          name,
          host,
          username: "",
          password: GROUP_AUTHENTICATION_FLAG,
        }
      } else {
        return {
          id: "",
          group,
          name,
          remoteName: "",
          host,
          username: "",
          password: GROUP_AUTHENTICATION_FLAG,
        }
      }
    } else if (connType === ConnType.PASS) {
      if (engine) {
        if (password.length === 0) {
          // Editing with a password not provided.
          return {
            ...engine,
            group,
            name,
            host,
            username,
            password: "",
          }
        } else {
          // Editing with a password.
          return encrypt(username, password).then(result => {
            return {
              ...engine,
              group,
              name,
              host,
              username,
              password: result,
            }
          })
        }
      } else {
        if (password.length === 0) {
          // New engine, password not provided.
          return {
            id: "",
            group,
            name,
            remoteName: "",
            host,
            username,
            password,
          }
        } else {
          // New engine with password.
          return encrypt(username, password).then(result => {
            return {
              id: "",
              group,
              name,
              remoteName: "",
              host,
              username,
              password: result,
            }
          })
        }
      }
    } else {
      if (engine) {
        // Editing an existing engine.
        return {
          ...engine,
          group,
          name,
          host,
          username: "",
          password: "",
        }
      } else {
        // Inserting a new engine.
        return {
          id: "",
          group,
          name,
          remoteName: "",
          host,
          username: "",
          password: "",
        }
      }
    }
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    getNewEngine()
      .then(newEngine => onOK(newEngine))
      .catch(error => {
        console.error(error)
      })
  }

  const title = engine ? "Edit Engine" : "Insert Engine"
  const isHostValid = host.length !== 0
  const isValid =
    isHostValid &&
    (connType === ConnType.GROUP ||
      (connType === ConnType.PASS && username.length !== 0) ||
      connType === ConnType.NONE)

  return (
    <Modal isOpen={true} toggle={onCancel}>
      <ModalHeader toggle={onCancel}>{title}</ModalHeader>
      <ModalBody>
        <Form id="engineForm" onSubmit={handleSubmit} noValidate>
          <FormGroup>
            <Label for="engine-edit--group">Group</Label>
            <Input
              type="text"
              name="engine-edit--group"
              id="engine-edit--group"
              list="engine-edit--group-list"
              value={group}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGroup(e.target.value)}
            />
            {groups && (
              <datalist id="engine-edit--group-list">
                {groups.map(group => (
                  <option key={group} value={group} />
                ))}
              </datalist>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="engine-edit--name">Name</Label>
            <Input
              type="text"
              name="engine-edit--name"
              id="engine-edit--name"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
            <FormText>Optional &lsquo;nickname&rsquo;</FormText>
          </FormGroup>
          <FormGroup>
            <Label for="engine-edit--host">Host</Label>
            <Input
              type="text"
              name="engine-edit--host"
              id="engine-edit--host"
              value={host}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHost(e.target.value)}
              onBlur={() => {
                setTouchedHost(true)
              }}
              invalid={touchedHost && !isHostValid}
              required
            />
            <FormText>IP address or hostname</FormText>
          </FormGroup>
          <FormGroup noMargin>
            <Label>Connection</Label>
            <CheckGroup
              type="radio"
              id="engine-edit--conntype-group"
              name="engine-edit--conntype"
              onChange={() => {
                setConnType(ConnType.GROUP)
              }}
              checked={connType === ConnType.GROUP}
            >
              Connect with an authentication group
            </CheckGroup>
            <Collapse isOpen={connType === ConnType.GROUP}>
              <div style={{ padding: "0.5rem 0 1rem 1.5rem" }}>
                <Alert color="info" fade={false} style={{ margin: 0 }}>
                  You will not have to enter credentials every time you connect.
                  <br />
                  The engine must be using the same group secret.
                </Alert>
              </div>
            </Collapse>
            <CheckGroup
              type="radio"
              id="engine-edit--conntype-pass"
              name="engine-edit--conntype"
              onChange={() => {
                setConnType(ConnType.PASS)
              }}
              checked={connType === ConnType.PASS}
            >
              Connect with saved credentials
            </CheckGroup>
            <Collapse isOpen={connType === ConnType.PASS}>
              <div style={{ padding: "0.5rem 0 1rem 1.5rem" }}>
                <FormGroup>
                  <Label for="engine-edit--username">Username</Label>
                  <Input
                    type="text"
                    name="engine-edit--username"
                    id="engine-edit--username"
                    value={username}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setUsername(e.target.value)
                    }
                  />
                </FormGroup>
                <FormGroup noMargin>
                  <Label for="engine-edit--password">Password</Label>
                  <PasswordInput
                    type="password"
                    name="engine-edit--password"
                    id="engine-edit--password"
                    value={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setPassword(e.target.value)
                    }
                  />
                </FormGroup>
              </div>
            </Collapse>
            <CheckGroup
              type="radio"
              id="engine-edit--conntype-none"
              name="engine-edit--conntype"
              onChange={() => {
                setConnType(ConnType.NONE)
              }}
              checked={connType === ConnType.NONE}
            >
              Connect by entering credentials each time
            </CheckGroup>
            <Collapse isOpen={connType === ConnType.NONE}>
              <div style={{ padding: "0.5rem 0 0 1.5rem" }}>
                <Alert color="info" fade={false} style={{ margin: 0 }}>
                  You will have to enter a username, password and two-factor code (if enabled) each
                  time you connect.
                </Alert>
              </div>
            </Collapse>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" form="engineForm" disabled={!isValid}>
          OK
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  )
}

export default EngineModal
