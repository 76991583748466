import * as React from "react"
import { Form } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { StatsLimitForm } from "../CaptureOptionsView/StatsLimitForm"
import { PeekStatsLimitSettings } from ".././../api/types"

type StatsLimitModalProps = {
  title: string
  baseName: string
  statsLimit: PeekStatsLimitSettings
  onOK: (statsLimit: PeekStatsLimitSettings) => void
  onCancel: () => void
}

type StatsLimitModalState = {
  statsLimit: PeekStatsLimitSettings
}

class StatsLimitModal extends React.Component<StatsLimitModalProps, StatsLimitModalState> {
  state: StatsLimitModalState = {
    statsLimit: { ...this.props.statsLimit },
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    this.props.onOK(this.state.statsLimit)
  }

  setStatsLimit = (statsLimit: PeekStatsLimitSettings) => {
    this.setState({ statsLimit })
  }

  render() {
    const { title, baseName, onCancel } = this.props
    return (
      <Modal isOpen={true}>
        <ModalHeader toggle={onCancel}>{title}</ModalHeader>
        <ModalBody>
          <Form id="statsLimitForm" onSubmit={this.onSubmit} noValidate>
            <StatsLimitForm
              baseName={baseName}
              statsLimit={this.state.statsLimit}
              setStatsLimit={this.setStatsLimit}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton type="submit" form="statsLimitForm">
            OK
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    )
  }
}

export default StatsLimitModal
