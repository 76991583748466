import styled from "styled-components"

export const SidebarRight = styled.div<{ open: boolean } & Pick<React.CSSProperties, "width">>`
  position: absolute;
  background: transparent;
  padding-left: 16px;
  box-sizing: content-box;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  visibility: ${props => (props.open ? "visible" : "hidden")};
  width: ${props => props.width || "480px"};
  opacity: ${props => (props.open ? "1.0" : "0")};
  transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: ${props => (props.open ? props.width || "100%" : "0")};
  }
`

export const SidebarLeft = styled.div<{ open: boolean } & Pick<React.CSSProperties, "width">>`
  position: absolute;
  background: transparent;
  padding-right: 16px;
  box-sizing: content-box;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  visibility: ${props => (props.open ? "visible" : "hidden")};
  width: ${props => props.width || "480px"};
  opacity: ${props => (props.open ? "1.0" : "0")};
  transition: opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: ${props => (props.open ? props.width || "100%" : "0")};
  }
`

export const Sidebar = SidebarRight

export const SidebarBodyRight = styled.div<{ open: boolean }>`
  flex-grow: 1;
  background: ${props => props.theme.sidebarBackground};
  box-shadow: ${props => props.theme.sidebarBoxShadow};
  transform: ${props => (props.open ? "translateX(0%)" : "translateX(50%)")};
  transition: transform 0.15s ease-in-out;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const SidebarBodyLeft = styled.div<{ open: boolean }>`
  flex-grow: 1;
  background: ${props => props.theme.sidebarBackground};
  box-shadow: ${props => props.theme.sidebarBoxShadow};
  transform: ${props => (props.open ? "translateX(0%)" : "translateX(-50%)")};
  transition: transform 0.15s ease-in-out;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const SidebarBody = SidebarBodyRight

export const SidebarHeader = styled.div`
  background: ${props => props.theme.sidebarTitleBackground};
  border-top: ${props => props.theme.sidebarTitleBorder};
  border-bottom: ${props => props.theme.sidebarTitleBorder};
  display: flex;
  align-items: center;

  & .btn-close {
    padding: 8px !important;
  }
`

export const SidebarTitle = styled.h5`
  display: inline-block;
  margin: 0 auto 0 0;
  padding: 8px;
  font-size: 1.1rem;
  overflow: hidden;
`

export const SidebarContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

export const SidebarOverlay = styled.div<{ open: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background-color: hsla(218, 13%, 55%, 0.35);
  visibility: ${props => (props.open ? "visible" : "hidden")};
  opacity: ${props => (props.open ? 1.0 : 0)};
  transition: opacity 0.15s ease-in-out;
`
