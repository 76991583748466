import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { v4 as uuid } from "uuid"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import FileSaver from "file-saver"
import { cloneDeep } from "lodash"
import { AutoSizer } from "react-virtualized"
import { ListRowProps } from "react-virtualized"
import { defaultAlarm, getUnits } from "../AlarmsEditView"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { defaultGraph } from "../GraphTemplatesEditView"
import Interval from "../common/Interval"
import { VirtualList } from "../common/VirtualList"
import { View, ViewContent, ViewHeader, ViewHeaderTitle, ViewHeaderButtons } from "../common/View"
import { LightButton } from "../common/Buttons"
import { IconDropdownToggle } from "../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../common/Dropdown"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import FilterBox from "../common/FilterBox"
import { Select } from "../common/Select"
import {
  formatDate,
  formatTime,
  formatDuration,
  formatInteger,
  formatFloat,
  INVALID_TIME,
  timeFormat,
  timeFormatUTC,
  peekToDate,
  dateFormat,
  dateFormatUTC,
} from "../../utils/formatUtils"
import csvStringify from "../../utils/csvStringify"
import { collator, partialSort } from "../../utils/sortUtils"
import {
  getCaptureForensicSearchUrl,
  getEngineNewGraphUrl,
  getEngineNewAlarmUrl,
} from "../../routes"
import {
  getEngine,
  getAuthToken,
  getCapabilities,
  getSummaryStatsViewType,
  getSummaryStatsFilter,
  getUserId,
  getShowLocalTime,
} from "../../store"
import { setSummaryStatsViewType, setSummaryStatsFilter } from "../../store/ui"
import { fetchCFSStatistics } from "../../api/api"
import {
  AlarmInfo,
  GraphObject,
  RequestGetStatisticsStatistic,
  ResponseGetEngineCapabilities,
  SummaryStatistics,
  SummaryStatisticsObjectSnapshotItem,
  SummaryStatisticsObjectSnapshotItemPair,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { GraphItemUnitType, PeekSummaryStatType } from "../../api/types/peekTypes"

const TOP_PARENT_ID = "00000000-0000-0000-0000-000000000000"
const PEEK_SUMMARY_STATS_SORT_SUB_ITEMS = 0x8

const ViewType = {
  COUNTS: "Counts",
  PERCENT: "Percent",
  PER_SECOND: "Per Second",
}

const SummaryGroupRow = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${props => props.theme.tableGridColor};
  background-color: ${props => props.theme.tableGroupBackgroundColor};
  font-weight: bold;
  cursor: pointer;
`

const SummaryGroupLabel = styled.div`
  flex: 1 1 200px;
  padding-left: 0.25em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SummaryStatRow = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${props => props.theme.tableGridColor};
  background-color: ${props => props.theme.tableBackgroundColor};

  &.stripe {
    background-color: ${props => props.theme.tableStripeColor};
  }

  &:hover {
    background-color: ${props => props.theme.tableHoverColor};
  }
`

const SummaryStatLabel = styled.div`
  flex: 1 1 200px;
  padding: 0.25rem 0.25rem 0.25rem 1.536em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SummaryStat = styled.div`
  flex: 0 1 140px;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0.25rem;
`

const CommandStrip = styled.div`
  flex: 0 0 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;

  ${SummaryStatRow}:hover & {
    visibility: visible;
  }
`

type SummaryStatisticsTableProps = {
  summaryStatistics: SummaryStatistics | null
  snapshot: SummaryStatisticsObjectSnapshotItem[] | null
  collapsedItems: Set<string>
  viewType: string
  onExpandCollapse: (item: SummaryStatisticsObjectSnapshotItem) => void
  onExpandAll: () => void
  onCollapseAll: () => void
  onMakeAlarm: (item: SummaryStatisticsObjectSnapshotItem) => void
  onMakeGraph: (
    item: SummaryStatisticsObjectSnapshotItem,
    snapshot: SummaryStatisticsObjectSnapshotItem[]
  ) => void
  showLocalTime: boolean
}

class SummaryStatisticsTable extends React.Component<SummaryStatisticsTableProps> {
  onClick = (item: SummaryStatisticsObjectSnapshotItem, event: React.MouseEvent<any>) => {
    const { collapsedItems, onExpandCollapse, onExpandAll, onCollapseAll } = this.props
    if (event.altKey) {
      const collapsed = collapsedItems.has(item.id)
      if (collapsed) {
        onExpandAll()
      } else {
        onCollapseAll()
      }
    } else {
      onExpandCollapse(item)
    }
  }

  rowRenderer = ({ key, index, style }: ListRowProps) => {
    const { summaryStatistics, snapshot, collapsedItems, viewType } = this.props
    if (Array.isArray(snapshot)) {
      const item = snapshot[index]
      if (item.parentId === TOP_PARENT_ID) {
        const collapsed = collapsedItems.has(item.id)
        const icon = collapsed ? "chevron-right" : "chevron-down"
        return (
          <SummaryGroupRow
            key={key}
            style={style}
            onClick={this.onClick.bind(this, item)}
            role="button"
          >
            <FontAwesome name={icon} fixedWidth />
            <SummaryGroupLabel>{item.label}</SummaryGroupLabel>
            <SummaryStat>Packets</SummaryStat>
            <SummaryStat>Bytes</SummaryStat>
            <SummaryStat>Value</SummaryStat>
            <div style={{ flex: "0 1 28px" }} />
          </SummaryGroupRow>
        )
      } else {
        let packets = ""
        let bytes = ""
        let value = ""
        switch (item.type) {
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DATE: {
            const epoch = peekToDate(item.value as number)
            value = this.props.showLocalTime
              ? dateFormat.format(epoch)
              : dateFormatUTC.format(epoch)
            break
          }
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_TIME:
            if ((item.value as number) !== INVALID_TIME) {
              const epoch = peekToDate(item.value as number)
              value = this.props.showLocalTime
                ? timeFormat.format(epoch)
                : `${timeFormatUTC.format(epoch)}Z`
            }
            break
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DURATION:
            value = formatDuration(item.value as number)
            break
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_PACKETS:
            if (viewType === ViewType.COUNTS) {
              packets = formatInteger(item.value as number)
            } else if (viewType === ViewType.PERCENT) {
              if (summaryStatistics && summaryStatistics.totalPackets > 0) {
                packets = `${formatFloat(
                  ((item.value as number) * 100.0) / summaryStatistics.totalPackets,
                  3
                )}%`
              }
            } else if (viewType === ViewType.PER_SECOND) {
              if (summaryStatistics && summaryStatistics.duration > 0) {
                const sec = summaryStatistics.duration / 1000000000
                packets = formatFloat((item.value as number) / sec, 3)
              }
            }
            break
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_BYTES:
            if (viewType === ViewType.COUNTS) {
              bytes = formatInteger(item.value as number)
            } else if (viewType === ViewType.PERCENT) {
              if (summaryStatistics && summaryStatistics.totalBytes > 0) {
                bytes = `${formatFloat(
                  ((item.value as number) * 100.0) / summaryStatistics.totalBytes,
                  3
                )}%`
              }
            } else if (viewType === ViewType.PER_SECOND) {
              if (summaryStatistics && summaryStatistics.duration > 0) {
                const sec = summaryStatistics.duration / 1000000000
                bytes = formatFloat((item.value as number) / sec, 3)
              }
            }
            break
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_VALUE_PAIR: {
            const itemPair = item.value as SummaryStatisticsObjectSnapshotItemPair
            if (viewType === ViewType.COUNTS) {
              packets = formatInteger(itemPair.packets)
              bytes = formatInteger(itemPair.bytes)
            } else if (viewType === ViewType.PERCENT) {
              if (summaryStatistics && summaryStatistics.totalPackets > 0) {
                packets = `${formatFloat(
                  (itemPair.packets * 100.0) / summaryStatistics.totalPackets,
                  3
                )}%`
              }
              if (summaryStatistics && summaryStatistics.totalBytes > 0) {
                bytes = `${formatFloat(
                  (itemPair.bytes * 100.0) / summaryStatistics.totalBytes,
                  3
                )}%`
              }
            } else if (viewType === ViewType.PER_SECOND) {
              if (summaryStatistics && summaryStatistics.duration > 0) {
                const sec = summaryStatistics.duration / 1000000000
                packets = formatFloat(itemPair.packets / sec, 3)
                bytes = formatFloat(itemPair.bytes / sec, 3)
              }
            }
            break
          }
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_INT: // integer
            value = formatInteger(item.value as number)
            break
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DOUBLE: // float
            value = formatFloat(item.value as number, 3)
            break
          default:
            break
        }
        let isGraphable = false
        switch (item.type) {
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_PACKETS:
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_BYTES:
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_VALUE_PAIR:
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_INT:
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DOUBLE:
            isGraphable = true
            break
          default:
            break
        }
        return (
          <SummaryStatRow
            key={key}
            style={style}
            className={index % 2 === 0 ? "stripe" : undefined}
          >
            <SummaryStatLabel>{item.label}</SummaryStatLabel>
            <SummaryStat>{packets}</SummaryStat>
            <SummaryStat>{bytes}</SummaryStat>
            <SummaryStat>{value}</SummaryStat>
            <CommandStrip>
              <UncontrolledDropdown>
                <IconDropdownToggle>
                  <FontAwesome name="ellipsis-h" fixedWidth />
                </IconDropdownToggle>
                <DropdownMenu end>
                  <DropdownItem
                    disabled={getUnits("705C4632-EFC9-4395-A66A-71A7F5618CC6", item.type) === 0}
                    onClick={this.props.onMakeAlarm.bind(this, item)}
                  >
                    Make Alarm
                  </DropdownItem>
                  <DropdownItem
                    disabled={!isGraphable}
                    onClick={this.props.onMakeGraph.bind(this, item, snapshot)}
                  >
                    Make Graph
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </CommandStrip>
          </SummaryStatRow>
        )
      }
    }
    return <div />
  }

  render() {
    const { snapshot, viewType } = this.props
    return (
      <AutoSizer>
        {({ height, width }) => (
          <VirtualList
            width={width}
            height={height}
            rowHeight={26}
            rowCount={Array.isArray(snapshot) ? snapshot.length : 0}
            rowRenderer={this.rowRenderer}
            triggerUpdate1={snapshot}
            triggerUpdate2={viewType}
          />
        )}
      </AutoSizer>
    )
  }
}

type SummaryStatisticsViewProps = RouteComponentProps<CaptureRouteParams> &
  CaptureViewProps & {
    dispatch: Function
    engine: string
    authToken: string
    viewType: string
    filter: string
    showLocalTime: boolean
    engineCapabilities: ResponseGetEngineCapabilities | null
    userId: string
  }

type SummaryStatisticsViewState = {
  summaryStatistics: SummaryStatistics | null
  collapsedItems: Set<string>
}

class SummaryStatisticsView extends React.Component<
  SummaryStatisticsViewProps,
  SummaryStatisticsViewState
> {
  state: SummaryStatisticsViewState = {
    summaryStatistics: null,
    collapsedItems: new Set(),
  }

  componentDidMount() {
    this.onRefresh()
  }

  onRefresh = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSStatistics<SummaryStatistics>(
      engine,
      authToken,
      type,
      capId,
      "summary" as RequestGetStatisticsStatistic
    )
      .then((summaryStatistics: SummaryStatistics) => {
        if (summaryStatistics.summary) {
          this.setState({ summaryStatistics })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onExport = () => {
    let csv = "Group,Label,Packets,Bytes,Value\n"
    const { summaryStatistics } = this.state
    const { viewType } = this.props
    const snapshot = this.getFlattenedTree()
    if (snapshot) {
      snapshot.forEach(item => {
        if (item.parentId !== TOP_PARENT_ID) {
          const row = []
          let group
          const groupItem = snapshot.find(it => it.id === item.parentId)
          if (groupItem) {
            group = groupItem.label
          }
          row.push(csvStringify(group))
          row.push(csvStringify(item.label))
          let packets = ""
          let bytes = ""
          let value = ""
          switch (item.type) {
            case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DATE:
              value = formatDate(item.value as number)
              break
            case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_TIME:
              if ((item.value as number) !== INVALID_TIME) {
                value = formatTime(item.value as number)
              }
              break
            case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DURATION:
              value = formatDuration(item.value as number)
              break
            case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_PACKETS:
              if (viewType === ViewType.COUNTS) {
                packets = String(item.value)
              } else if (viewType === ViewType.PERCENT) {
                if (summaryStatistics && summaryStatistics.totalPackets > 0) {
                  packets = String(
                    ((item.value as number) * 100.0) / summaryStatistics.totalPackets
                  )
                }
              } else if (viewType === ViewType.PER_SECOND) {
                if (summaryStatistics && summaryStatistics.duration > 0) {
                  const sec = summaryStatistics.duration / 1000000000
                  packets = String((item.value as number) / sec)
                }
              }
              break
            case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_BYTES:
              if (viewType === ViewType.COUNTS) {
                bytes = String(item.value)
              } else if (viewType === ViewType.PERCENT) {
                if (summaryStatistics && summaryStatistics.totalBytes > 0) {
                  bytes = String(((item.value as number) * 100.0) / summaryStatistics.totalBytes)
                }
              } else if (viewType === ViewType.PER_SECOND) {
                if (summaryStatistics && summaryStatistics.duration > 0) {
                  const sec = summaryStatistics.duration / 1000000000
                  bytes = String((item.value as number) / sec)
                }
              }
              break
            case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_VALUE_PAIR: {
              const itemPair = item.value as SummaryStatisticsObjectSnapshotItemPair
              if (viewType === ViewType.COUNTS) {
                packets = String(itemPair.packets)
                bytes = String(itemPair.bytes)
              } else if (viewType === ViewType.PERCENT) {
                if (summaryStatistics && summaryStatistics.totalPackets > 0) {
                  packets = String((itemPair.packets * 100.0) / summaryStatistics.totalPackets)
                }
                if (summaryStatistics && summaryStatistics.totalBytes > 0) {
                  bytes = String((itemPair.bytes * 100.0) / summaryStatistics.totalBytes)
                }
              } else if (viewType === ViewType.PER_SECOND) {
                if (summaryStatistics && summaryStatistics.duration > 0) {
                  const sec = summaryStatistics.duration / 1000000000
                  packets = String(itemPair.packets / sec)
                  bytes = String(itemPair.bytes / sec)
                }
              }
              break
            }
            case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_INT: // integer
              value = String(item.value)
              break
            case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DOUBLE: // float
              value = String(item.value)
              break
            default:
              break
          }
          row.push(csvStringify(packets))
          row.push(csvStringify(bytes))
          row.push(csvStringify(value))
          csv += row.join(",") + "\n"
        }
      })
    }

    FileSaver.saveAs(new Blob([csv], { type: "text/plain;charset=utf8" }), "Summary Statistics.csv")
  }

  onExpandCollapse = (item: SummaryStatisticsObjectSnapshotItem) => {
    const collapsedItems = new Set(this.state.collapsedItems)
    if (collapsedItems.has(item.id)) {
      collapsedItems.delete(item.id)
    } else {
      collapsedItems.add(item.id)
    }
    this.setState({ collapsedItems })
  }

  onExpandAll = () => {
    const collapsedItems = new Set<string>()
    this.setState({ collapsedItems })
  }

  onCollapseAll = () => {
    const { summaryStatistics } = this.state
    if (summaryStatistics && summaryStatistics.summary.snapshots) {
      const { currentSnapshotId } = summaryStatistics.summary
      const currentSnapshot = summaryStatistics.summary.snapshots.find(
        snapshot => snapshot.id === currentSnapshotId
      )
      if (currentSnapshot && currentSnapshot.items) {
        const collapsedItems = new Set<string>()
        for (let i = 0, len = currentSnapshot.items.length; i < len; i++) {
          const item = currentSnapshot.items[i]
          if (item.parentId === TOP_PARENT_ID) {
            collapsedItems.add(item.id)
          }
        }
        this.setState({ collapsedItems })
      }
    }
  }

  onMakeGraph = (
    item: SummaryStatisticsObjectSnapshotItem,
    snapshot: SummaryStatisticsObjectSnapshotItem[]
  ) => {
    let description = item.label
    if (item.parentId !== TOP_PARENT_ID) {
      const parentItem = snapshot.find(graphStat => graphStat.id === item.parentId)
      if (parentItem !== undefined) {
        description = parentItem.label + ": " + item.label
      }
    }

    const graph: GraphObject = {
      ...cloneDeep(defaultGraph),
      graphItems: [
        {
          clsid: "0BDD4628-5AFD-4D26-8909-EC371724F51E",
          description,
          id: uuid().toUpperCase(),
          name: item.label,
          statisticFlags: item.flags,
          statisticItemId: item.id,
          statisticType: item.type,
          unitType: GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_PACKETS,
        },
      ],
      id: uuid().toUpperCase(),
      templateId: uuid().toUpperCase(),
      title: item.label,
    }

    this.props.history.push({
      pathname: getEngineNewGraphUrl(),
      state: { graph },
    })
  }

  onMakeAlarm = (item: SummaryStatisticsObjectSnapshotItem) => {
    const alarm: AlarmInfo = {
      ...cloneDeep(defaultAlarm),
      name: item.label,
      statisticsTracker: {
        clsid: "705C4632-EFC9-4395-A66A-71A7F5618CC6",
        history: 60,
        statisticsType: item.type,
        summary: {
          id: item.id,
          flags: item.flags,
          summaryStatisticsType: item.type,
        },
      },
    }
    this.props.history.push({
      pathname: getEngineNewAlarmUrl(),
      state: { alarm },
    })
  }

  onChangeViewType = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.dispatch(setSummaryStatsViewType(e.target.value))
  }

  onChangeFilter = (filter: string) => {
    this.props.dispatch(setSummaryStatsFilter(filter))
  }

  getFlattenedTree = (): SummaryStatisticsObjectSnapshotItem[] | null => {
    // TODO: handle multiple snapshots?
    // TODO: handle subgroups?
    if (this.state.summaryStatistics) {
      if (this.state.summaryStatistics.summary.snapshots) {
        const currentSnapshotId = this.state.summaryStatistics.summary.currentSnapshotId
        const currentSnapshot = this.state.summaryStatistics.summary.snapshots.find(
          snapshot => snapshot.id === currentSnapshotId
        )
        if (currentSnapshot && currentSnapshot.items) {
          const filter = this.props.filter.toLowerCase()
          let items: SummaryStatisticsObjectSnapshotItem[]
          if (filter) {
            // Apply the filter to non-parent items
            items = currentSnapshot.items.filter(
              item =>
                item.parentId === TOP_PARENT_ID || item.label.toLowerCase().indexOf(filter) !== -1
            )
            // Remove empty parents
            items = items.filter(
              item =>
                item.parentId !== TOP_PARENT_ID ||
                items.findIndex(innerItem => innerItem.parentId === item.id) !== -1
            )
          } else {
            items = currentSnapshot.items
          }
          const rows: SummaryStatisticsObjectSnapshotItem[] = []
          const sortItems: string[] = []
          items.forEach(item => {
            if (item.parentId === TOP_PARENT_ID) {
              rows.push(item)
              if ((item.flags & PEEK_SUMMARY_STATS_SORT_SUB_ITEMS) !== 0) {
                sortItems.push(item.id)
              }
            } else {
              const collapsed = this.state.collapsedItems.has(item.parentId)
              if (!collapsed) {
                rows.push(item)
                if ((item.flags & PEEK_SUMMARY_STATS_SORT_SUB_ITEMS) !== 0) {
                  sortItems.push(item.id)
                }
              }
            }
          })

          // Sort any groups that require it
          sortItems.forEach(sortItem => {
            const parentIndex = rows.findIndex(row => row.id === sortItem)
            if (parentIndex !== -1) {
              const firstChildIndex = parentIndex + 1
              if (firstChildIndex < rows.length && rows[firstChildIndex].parentId === sortItem) {
                let lastChildIndex = firstChildIndex
                while (lastChildIndex < rows.length && rows[lastChildIndex].parentId === sortItem) {
                  lastChildIndex++
                }
                if (lastChildIndex > firstChildIndex) {
                  partialSort(rows, firstChildIndex, lastChildIndex, (a, b) => {
                    return collator.compare(a.label, b.label)
                  })
                }
              }
            }
          })
          return rows
        }
      }
    }
    return null
  }

  render() {
    const { summaryStatistics, collapsedItems } = this.state
    const { captureProperties, engineCapabilities, viewType, filter, userId } = this.props

    // make sure the user can view packets for this capture or forensic search
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
      if (!canViewStats) {
        const { type, capId } = this.props.match.params
        return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
      }
    }

    const flattenedTree = this.getFlattenedTree()
    return (
      <View>
        <BreadcrumbItem to={this.props.match.url} title="Summary Statistics" />
        <Interval timeout={30000} enabled={true} callback={this.onRefresh} />
        <ViewHeader>
          <ViewHeaderTitle title="Summary Statistics" />
          <ViewHeaderButtons>
            <Select
              name="viewType"
              id="viewType"
              aria-label="View Type"
              value={viewType}
              onChange={this.onChangeViewType}
              style={{ width: "auto" }}
            >
              <option>{ViewType.COUNTS}</option>
              <option>{ViewType.PERCENT}</option>
              <option>{ViewType.PER_SECOND}</option>
            </Select>
            <FilterBox
              aria-label="Search"
              placeholder="Search"
              onChange={this.onChangeFilter}
              value={filter}
            />
            <LightButton id="expand-all" onClick={this.onExpandAll}>
              Expand All
            </LightButton>
            <LightButton id="collapse-all" onClick={this.onCollapseAll}>
              Collapse All
            </LightButton>
            <LightButton aria-label="Export" id="export" onClick={this.onExport}>
              <FontAwesome name="download" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="export">
              Export
            </UncontrolledTooltip>
            <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefresh}>
              <FontAwesome name="refresh" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="refresh">
              Refresh
            </UncontrolledTooltip>
          </ViewHeaderButtons>
        </ViewHeader>
        <ViewContent>
          {flattenedTree && (
            <SummaryStatisticsTable
              summaryStatistics={summaryStatistics}
              snapshot={flattenedTree}
              collapsedItems={collapsedItems}
              viewType={viewType}
              onExpandCollapse={this.onExpandCollapse}
              onExpandAll={this.onExpandAll}
              onCollapseAll={this.onCollapseAll}
              onMakeAlarm={this.onMakeAlarm}
              onMakeGraph={this.onMakeGraph}
              showLocalTime={this.props.showLocalTime}
            />
          )}
        </ViewContent>
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  viewType: getSummaryStatsViewType(state) || ViewType.COUNTS,
  filter: getSummaryStatsFilter(state) || "",
  engineCapabilities: getCapabilities(state) || null,
  userId: getUserId(state),
  showLocalTime: getShowLocalTime(state),
})

export default connect(mapStateToProps)(SummaryStatisticsView)
