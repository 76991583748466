import * as React from "react"
import FontAwesome from "react-fontawesome"
import { Button } from "reactstrap"
import styled from "styled-components"
import { toNumber } from "lodash"
import Interval from "../common/Interval"
import { PlainOutsideLink } from "../common/Link"
import { MimeType, handleResponse } from "../../api/api"
import { ResponseGetEngineCapabilities } from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { getCapabilities } from "../../store"
import { connect } from "react-redux"

export const UpgradeBarBorderlessButton = styled(Button)`
  color: ${props => props.theme.upgradeBarColor};
  background-color: transparent;
  border-color: transparent;

  position: absolute;
  right: 0;
  line-height: 1;

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.upgradeBarColor};
    background-color: transparent;
    border-color: transparent;
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.upgradeBarColor};
    background-color: transparent;
    border-color: transparent;
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.upgradeBarColor};
    background-color: transparent;
    border-color: transparent;
  }

  & > .fa {
    opacity: 0.65;
  }
`

export const UpgradeBarDismissButton = ({ ...props }) => (
  <UpgradeBarBorderlessButton {...props}>
    <FontAwesome name="times" />
  </UpgradeBarBorderlessButton>
)

const UpgradeWrapper = styled.div`
  color: ${props => props.theme.upgradeBarColor};
  background-color: ${props => props.theme.upgradeBarBackgroundColor};
  font-size: 120%;
  font-weight: 500;
  padding: 4px 0 4px 8px;
`

type UpgradeBarProps = {
  engineType: string
  engineVersion: string
  engineCapabilities: ResponseGetEngineCapabilities | null
}

type UpgradeBarState = {
  latestVersion: string | null
  upgradeBarVersion: string | null
  upgradeURL: string | null
}

class UpgradeBar extends React.Component<UpgradeBarProps, UpgradeBarState> {
  state: UpgradeBarState = {
    latestVersion: null,
    upgradeBarVersion: null,
    upgradeURL: null,
  }

  componentDidMount() {
    this.onRefreshStatus()
  }

  shouldComponentUpdate(
    { engineType, engineVersion }: UpgradeBarProps,
    { latestVersion, upgradeBarVersion, upgradeURL }: UpgradeBarState
  ) {
    return (
      this.props.engineType !== engineType ||
      this.props.engineVersion !== engineVersion ||
      this.state.latestVersion !== latestVersion ||
      this.state.upgradeBarVersion !== upgradeBarVersion ||
      this.state.upgradeURL !== upgradeURL
    )
  }

  newVersionExists(currentVersion: string, latestVersion: string): boolean {
    // split versions into parts (add empty parts if necessary to make array lengths equal)
    const currentVersionArray = currentVersion.split(/\.|-/g)
    const latestVersionArray = latestVersion.split(/\.|-/g)
    if (latestVersionArray.length > currentVersionArray.length) {
      let padding = latestVersionArray.length - currentVersionArray.length
      let index = currentVersionArray.length
      while (padding && index < latestVersionArray.length) {
        currentVersionArray.push(Number.isNaN(toNumber(latestVersionArray[index])) ? "" : "0")
        padding--
        index++
      }
    } else if (currentVersionArray.length > latestVersionArray.length) {
      let padding = currentVersionArray.length - latestVersionArray.length
      let index = latestVersionArray.length
      while (padding && index < currentVersionArray.length) {
        latestVersionArray.push(Number.isNaN(toNumber(currentVersionArray[index])) ? "" : "0")
        padding--
        index++
      }
    }

    let newVersionCompare = 0

    // iterate over each part and compare
    let index = 0
    while (
      newVersionCompare === 0 &&
      index < currentVersionArray.length &&
      index < latestVersionArray.length
    ) {
      const currentValue =
        currentVersionArray[index] !== "" ? toNumber(currentVersionArray[index]) : Number.NaN
      const latestValue =
        latestVersionArray[index] !== "" ? toNumber(latestVersionArray[index]) : Number.NaN
      if (!Number.isNaN(currentValue) && !Number.isNaN(latestValue)) {
        if (currentValue < latestValue) {
          newVersionCompare = -1
        } else if (currentValue > latestValue) {
          newVersionCompare = 1
        }
      } else {
        break
      }
      index++
    }

    return newVersionCompare < 0
  }

  extractLatestVersion(fileContent: string) {
    const { engineType } = this.props

    if (engineType && fileContent) {
      // convert file content to string array separated by line break
      const fileContentArray = fileContent.toString().split("\n")
      let upgradeURL = null
      const listCurrentVersions: { [key: string]: any } = {}
      fileContentArray.forEach((fileLine: string) => {
        const tupple = fileLine.split(",")
        if (tupple.length === 2 && tupple[0] && tupple[1]) {
          listCurrentVersions[tupple[0].toLowerCase()] = tupple[1] // engineType,engineVersion
        } else if (tupple.length === 1 && tupple[0]) {
          upgradeURL = tupple[0] // URL
        }
      })
      const engineTypeLower = engineType.toLowerCase()
      this.setState({
        latestVersion:
          engineTypeLower in listCurrentVersions ? listCurrentVersions[engineTypeLower] : null,
        upgradeURL,
      })
    }
  }

  onDismiss(version: string) {
    localStorage.setItem("upgradeBarVersion", version)
    this.setState({ upgradeBarVersion: version })
  }

  onRefreshStatus() {
    this.setState({ upgradeBarVersion: localStorage.getItem("upgradeBarVersion") })

    fetch("https://mypeek.liveaction.com/dms_current_versions.txt", {
      method: "GET",
      headers: {
        accept: MimeType.MIME_TYPE_TEXT,
      },
    })
      .then(response => {
        return handleResponse<string>(response)
      })
      .then(response => {
        this.extractLatestVersion(response)
      })
      .catch(() => this.setState({ latestVersion: null, upgradeURL: null }))
  }

  render() {
    const { engineVersion, engineCapabilities } = this.props
    const { latestVersion, upgradeBarVersion, upgradeURL } = this.state

    let canConfigureEngine = true
    if (engineCapabilities) {
      const policies = engineCapabilities.userRights.policies
      canConfigureEngine = policies.includes(EngineUserPolicies.configureEngine)
    }

    if (
      canConfigureEngine &&
      latestVersion !== null &&
      upgradeURL !== null &&
      this.newVersionExists(engineVersion, latestVersion) &&
      (upgradeBarVersion === null || upgradeBarVersion !== latestVersion)
    ) {
      return (
        <UpgradeWrapper>
          <Interval timeout={900000} enabled={true} callback={this.onRefreshStatus.bind(this)} />
          Version {latestVersion} is now available for download from the{" "}
          <PlainOutsideLink href={upgradeURL} target="_blank" rel="noopener">
            LiveAction portal
          </PlainOutsideLink>
          .
          <UpgradeBarDismissButton onClick={this.onDismiss.bind(this, latestVersion)} />
        </UpgradeWrapper>
      )
    } else {
      return null
    }
  }
}

const mapStateToProps = (state: any) => ({
  engineCapabilities: getCapabilities(state),
})

export default connect(mapStateToProps)(UpgradeBar)
