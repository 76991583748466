import * as React from "react"
import Cookies from "js-cookie"
import { connect } from "react-redux"
import { StaticContext } from "react-router"
import { Redirect, RouteComponentProps } from "react-router-dom"
import styled, { ThemeProvider } from "styled-components"
import FontAwesome from "react-fontawesome"
import { Alert } from "../common/Alert"
import { Input } from "../common/Input"
import { OutsideLink } from "../common/Link"
import { PrimaryButton } from "../common/Buttons"
import * as Colors from "../../themes/colorScheme"
import theme from "../../themes/darkTheme"
import bg from "./login.png"
import {
  getServer,
  getServerAuthToken,
  getServerAuthError,
  getServerAuthInProgress,
  getServerTempAuthToken,
  getServerTwoFactorAuth,
} from "../../store"
import { serverLogIn, serverLogInCertificate, setServerAuthToken } from "../../store/auth"
import TwoFactorCode from "./TwoFactorCode"
import IframeComponent from "../common/IFrame"

const TwoFactorConfigView = React.lazy(() => import("../TwoFactorConfigView"))

const Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: ${props => props.theme.textColor};
  background: url(${bg}) 50% 0;
  background-size: cover;
  background-color: ${Colors.brandGray};
  flex-direction: column;
`

const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  width: 284px;
`

const LoginForm = styled.form`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const HR = styled.hr`
  overflow: visible;
  padding: 0;
  border: 0;
  height: 1px;
  background-color: ${Colors.gray500};
  color: ${Colors.gray500};
  text-align: center;

  &:after {
    content: "OR";
    display: inline-block;
    position: relative;
    top: -0.7rem;
    padding: 0 0.25rem;
    background: ${Colors.brandGray};
    font-size: 80%;
  }
`

const LoginFooter = styled.div`
  text-align: center;
  color: ${Colors.gray600};

  & .company {
    color: ${Colors.gray600};
  }

  & .company:hover {
    color: ${props => props.theme.linkHoverColor};
  }

  & .config {
    text-decoration: underline;
  }
`

const LoginTerms = styled.div`
  margin: 1rem 0;
  text-align: center;
`

type LocationState = {
  next?: string
}

type LoginProps = RouteComponentProps<{}, StaticContext, LocationState> & {
  dispatch: Function
  engine: string
  authToken: string | null
  authError: string | null
  authInProgress: boolean
  tempAuthToken: string | null
  twoFactorAuth: boolean
}

type LoginState = {
  engine: string
  username: string
  password: string
  redirect: boolean
  acceptedTerms: boolean
  [key: string]: string | boolean
}

class Login extends React.Component<LoginProps, LoginState> {
  state: LoginState = {
    engine: this.props.engine,
    username: "",
    password: "",
    redirect: false,
    acceptedTerms: false,
  }

  componentDidMount() {
    try {
      const acceptedTerms = localStorage.getItem("acceptedTerms")
      if (acceptedTerms) {
        this.setState({ acceptedTerms: acceptedTerms.toLowerCase() === "true" })
      }
    } catch {}
  }

  componentDidUpdate({ authToken }: LoginProps) {
    if (authToken !== this.props.authToken) {
      this.setState({ redirect: true })
    }
  }

  onAcceptTerms = () => {
    localStorage.setItem("acceptedTerms", "true")
    this.setState({ acceptedTerms: true })
  }

  onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  onLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { engine, username, password } = this.state
    if (username && password) {
      this.props.dispatch(serverLogIn(engine, { username, password, tokenType: "Bearer" }))
    }
  }

  onLoginCertificate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { engine } = this.state
    this.props.dispatch(serverLogInCertificate(engine))
  }

  onCodeVerify = (otp: string) => {
    const { engine, username, password } = this.state
    this.props.dispatch(serverLogIn(engine, { username, password, otp, tokenType: "Bearer" }))
  }

  onConfigVerify = (authToken: string, refreshToken?: string, tokenType?: string) => {
    this.props.dispatch(setServerAuthToken({ authToken, refreshToken: refreshToken ?? null }))
  }

  onCancel = () => {
    this.props.dispatch(setServerAuthToken({ authToken: null, refreshToken: null }))
  }

  render() {
    if (this.state.redirect) {
      const { location } = this.props
      const next = location.state && location.state.next ? location.state.next : "/"
      return <Redirect to={next} />
    }
    const { authError, authInProgress, tempAuthToken, twoFactorAuth } = this.props
    const { engine, username, password, acceptedTerms } = this.state
    if (tempAuthToken) {
      return (
        <React.Suspense
          fallback={
            <ThemeProvider theme={theme}>
              <Background />
            </ThemeProvider>
          }
        >
          <ThemeProvider theme={theme}>
            <Background>
              <TwoFactorConfigView
                engine={engine}
                tempAuthToken={tempAuthToken}
                onVerify={this.onConfigVerify}
                onCancel={this.onCancel}
              />
            </Background>
          </ThemeProvider>
        </React.Suspense>
      )
    }

    return (
      <ThemeProvider theme={theme}>
        <Background>
          {import.meta.env.MODE === "production" && (
            <IframeComponent content="/banner/" title="Banner" />
          )}
          <Wrapper>
            <img src={import.meta.env.BASE_URL + "/static/logos/login.svg"} width="100%" alt="" />
            {acceptedTerms ? (
              twoFactorAuth ? (
                <TwoFactorCode
                  onVerify={this.onCodeVerify}
                  onCancel={this.onCancel}
                  authError={authError}
                  authInProgress={authInProgress}
                />
              ) : (
                <>
                  <LoginForm onSubmit={this.onLogin}>
                    <Input
                      type={import.meta.env.MODE !== "production" ? "url" : "hidden"}
                      id="engine"
                      name="engine"
                      autoComplete="engine-url"
                      placeholder="Engine URL"
                      spellCheck={false}
                      value={engine}
                      onChange={this.onChangeText}
                    />
                    <Input
                      type="text"
                      autoFocus
                      id="username"
                      name="username"
                      autoComplete="username"
                      placeholder="Username"
                      value={username}
                      onChange={this.onChangeText}
                    />
                    <Input
                      type="password"
                      id="password"
                      name="password"
                      autoComplete="current-password"
                      placeholder="Password"
                      value={password}
                      onChange={this.onChangeText}
                    />
                    <PrimaryButton
                      type="submit"
                      disabled={
                        !username || !password || (password === "admin" && username === "admin")
                      }
                    >
                      <FontAwesome
                        name={authInProgress ? "circle-o-notch" : "blank"}
                        fixedWidth
                        spin
                      />{" "}
                      Login <FontAwesome name="blank" fixedWidth />
                    </PrimaryButton>
                    {Cookies.get("opt-authentication") === "certificate" && (
                      <>
                        <HR />
                        <PrimaryButton onClick={this.onLoginCertificate}>
                          <FontAwesome
                            name={authInProgress ? "circle-o-notch" : "blank"}
                            fixedWidth
                            spin
                          />{" "}
                          Login with Certificate <FontAwesome name="blank" fixedWidth />
                        </PrimaryButton>
                      </>
                    )}
                  </LoginForm>
                  {authError != null && (
                    <Alert color="danger" className="text-center">
                      {authError}
                    </Alert>
                  )}
                  {password === "admin" && username === "admin" && (
                    <Alert color="danger" className="text-center">
                      Warning: You are using the default admin credentials please change them in
                      LiveAdmin
                    </Alert>
                  )}
                  <LoginFooter>
                    Copyright &copy; 2024{" "}
                    <OutsideLink
                      className="company"
                      href="https://www.liveaction.com/"
                      target="_blank"
                      rel="noopener"
                    >
                      LiveAction
                    </OutsideLink>
                    {". "}
                    All rights reserved.
                    <br />
                    <OutsideLink className="config" href="/config/" target="_blank" rel="noopener">
                      System Configuration
                    </OutsideLink>
                  </LoginFooter>
                </>
              )
            ) : (
              <LoginTerms>
                <h2>Terms &amp; Conditions</h2>
                <p>
                  LiveAction&lsquo;s Software License and Maintenance Agreement (the
                  &ldquo;Agreement&rdquo;) governs the use of LiveAction software, hardware and
                  services.
                </p>
                <p>
                  By clicking &ldquo;I Agree&rdquo;, you acknowledge that you have read, understand
                  and agree to the terms of the Agreement. If you are entering into the Agreement on
                  behalf of a company or other legal entity, you represent that you have the
                  authority to bind such entity to the Agreement.
                </p>
                <p>
                  <OutsideLink
                    className="company"
                    href="https://www.liveaction.com/terms-and-conditions/"
                    target="_blank"
                    rel="noopener"
                  >
                    Read full Terms &amp; Conditions
                  </OutsideLink>
                </p>
                <p>
                  <PrimaryButton type="button" onClick={this.onAcceptTerms}>
                    I Agree
                  </PrimaryButton>
                </p>
              </LoginTerms>
            )}
          </Wrapper>
        </Background>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getServer(state),
  authToken: getServerAuthToken(state),
  authError: getServerAuthError(state),
  authInProgress: getServerAuthInProgress(state),
  tempAuthToken: getServerTempAuthToken(state),
  twoFactorAuth: getServerTwoFactorAuth(state),
})

export default connect(mapStateToProps)(Login)
