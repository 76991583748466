import * as React from "react"
import { cloneDeep } from "lodash"
import { connect } from "react-redux"
import { Form } from "reactstrap"
import styled from "styled-components"
import ChooseFileModal from "../ChooseFileModal"
import { Alert } from "../common/Alert"
import { MessageModal } from "../common/MessageModal"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../common/Modal"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import {
  getAuthToken,
  getCapabilities,
  getCurrentTheme,
  getEngine,
  getShowAddressNames,
  getShowPortNames,
  getStatus,
} from "../../store"
import ThemeInterface from "../../themes/theme"
import { fetchFile, putFile, sendGlobalPluginMessage } from "../../api/api"
import {
  EngineCapabilitiesPluginInfo,
  PluginOption,
  RequestPostPluginsMessage,
  ResponseGetEngineCapabilities,
  ResponseGetStatus,
  ResponsePostPluginsMessage,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import {
  WebPluginBrowseFlags,
  WebPluginData_BrowseRemoteResponse,
  WebPluginData_CommandMessageResponse,
  WebPluginData_CommandMessageResponseDisplayMessage,
  WebPluginData_DisplayPropertiesResponse,
  WebPluginData_GetPreferencesResponse,
  WebPluginData_GetRemoteFileResponse,
  WebPluginData_PluginMessage,
  WebPluginData_ReadyToClose,
  WebPluginData_SetRemoteFileResponse,
  WebPluginData_TitleResponse,
  WebPluginMessage,
  WebPluginMessageCommand,
  WebPluginMessageRequest,
  WebPluginMessageResponse,
} from "../../api/types/pluginTypes"

const OptionsModal = styled(Modal)`
  display: table;
  max-width: 100%;
  width: auto !important;
`

const OptionsIFrame = styled.iframe`
  border: 0;
  display: block;
  margin: 0 auto;
  position: relative;
`

type BrowseParams = {
  flags: number
  path: string
}

type DisplayMessageParams = {
  message: string
  title: string
}

type PluginOptionsModalProps = {
  authToken: string
  contextId: string
  engine: string
  engineStatus: ResponseGetStatus | null
  onCancel: () => void
  onOK: (pluginsConfig: PluginOption) => void
  pluginConfig: PluginOption
  pluginInfo: EngineCapabilitiesPluginInfo
  showAddressNames: boolean
  showPortNames: boolean
  theme: ThemeInterface | undefined
  engineCapabilities: ResponseGetEngineCapabilities | null
}

type PluginOptionsModalState = {
  browseParams: BrowseParams | null
  displayMessageParams: DisplayMessageParams | null
  error: any | null
  height: string
  readyToClose: boolean
  showBrowseModal: boolean
  showDisplayMessage: boolean
  title: string | null
  width: string
}

class PluginOptionsModal extends React.Component<PluginOptionsModalProps, PluginOptionsModalState> {
  iframe: any | null = null

  state: PluginOptionsModalState = {
    browseParams: null,
    displayMessageParams: null,
    error: null,
    height: "100%",
    readyToClose: true,
    showBrowseModal: false,
    showDisplayMessage: false,
    title: null,
    width: "100%",
  }

  componentDidMount = () => {
    // add interface for responding to messages from the plugin options iframe
    window.addEventListener("message", this.onMessage)
  }

  componentDidUpdate(prevProps: PluginOptionsModalProps, prevState: PluginOptionsModalState) {
    if (this.isValidIFrame()) {
      // send message for the name table
      if (
        this.props.showAddressNames !== prevProps.showAddressNames ||
        this.props.showPortNames !== prevProps.showPortNames
      ) {
        const wpmNameTable: WebPluginMessageRequest = {
          data: {
            showAddressNames: this.props.showAddressNames,
            showPortNames: this.props.showPortNames,
          },
          message: WebPluginMessage.WEB_PLUGIN_MESSAGE_NAME_TABLE,
        }
        this.iframe.contentWindow.postMessage(wpmNameTable)
      }
      // send message for the theme
      if (this.props.theme && prevProps.theme && this.props.theme.name !== prevProps.theme.name) {
        const wpmTheme: WebPluginMessageRequest = {
          data: cloneDeep(this.props.theme),
          message: WebPluginMessage.WEB_PLUGIN_MESSAGE_THEME,
        }
        this.iframe.contentWindow.postMessage(wpmTheme)
      }
    }
  }

  componentWillUnmount = () => {
    if (this.isValidIFrame()) {
      // send message for preparing to unload
      const wpmPrepareToUnload: WebPluginMessageRequest = {
        data: null,
        message: WebPluginMessage.WEB_PLUGIN_MESSAGE_PREPARE_TO_UNLOAD,
      }
      this.iframe.contentWindow.postMessage(wpmPrepareToUnload)
    }

    // remove interface for responding to messages from the plugin options iframe
    window.removeEventListener("message", this.onMessage)
  }

  getEngineStatus = () => {
    const engineStatus: any = {}
    if (this.props.engineStatus) {
      // extract the address and port from the engine status address
      let address = ""
      let port = ""
      if (this.props.engineStatus.address !== undefined) {
        const results = this.props.engineStatus.address.split(":")
        if (results.length > 0) {
          address = results[0]
        }
        if (results.length > 1) {
          port = results[1]
        }
      }

      // construct the engine status JSON object
      engineStatus.address = address
      engineStatus.dataFolder =
        this.props.engineStatus.dataFolder !== undefined ? this.props.engineStatus.dataFolder : ""
      engineStatus.domain =
        this.props.engineStatus.userDomain !== undefined ? this.props.engineStatus.userDomain : ""
      engineStatus.engineType =
        this.props.engineStatus.engineType !== undefined ? this.props.engineStatus.engineType : ""
      engineStatus.name =
        this.props.engineStatus.name !== undefined ? this.props.engineStatus.name : ""
      engineStatus.operatingSystem =
        this.props.engineStatus.operatingSystem !== undefined
          ? this.props.engineStatus.operatingSystem
          : ""
      engineStatus.port = port
      engineStatus.user =
        this.props.engineStatus.userName !== undefined ? this.props.engineStatus.userName : ""
    }

    return engineStatus
  }

  isValidIFrame = () => {
    return this.iframe && this.iframe.contentWindow
  }

  onChooseFileCancel = () => {
    this.setState({
      browseParams: null,
      showBrowseModal: false,
    })
  }

  onChooseFileOK = (path: string) => {
    if (this.isValidIFrame()) {
      // send message for browse remote
      const wpmBrowseRemote: WebPluginMessageRequest = {
        data: path,
        message: WebPluginMessage.WEB_PLUGIN_MESSAGE_BROWSE_REMOTE,
      }
      this.iframe.contentWindow.postMessage(wpmBrowseRemote)
    }

    this.setState({
      browseParams: null,
      showBrowseModal: false,
    })
  }

  onDisplayMessageOK = () => {
    this.setState({
      displayMessageParams: null,
      showDisplayMessage: false,
    })
  }

  onErrorDismiss() {
    this.setState({ error: null })
  }

  onLoad = (event: any) => {
    if (this.isValidIFrame()) {
      // send message for the context id
      const wpmContextId: WebPluginMessageRequest = {
        data: this.props.contextId,
        message: WebPluginMessage.WEB_PLUGIN_MESSAGE_CONTEXT_ID,
      }
      this.iframe.contentWindow.postMessage(wpmContextId)

      // send message for the engine info
      const wpmEngineInfo: WebPluginMessageRequest = {
        data: this.getEngineStatus(),
        message: WebPluginMessage.WEB_PLUGIN_MESSAGE_ENGINE_INFO,
      }
      this.iframe.contentWindow.postMessage(wpmEngineInfo)

      // send message for the name table
      const wpmNameTable: WebPluginMessageRequest = {
        data: {
          showAddressNames: this.props.showAddressNames,
          showPortNames: this.props.showPortNames,
        },
        message: WebPluginMessage.WEB_PLUGIN_MESSAGE_NAME_TABLE,
      }
      this.iframe.contentWindow.postMessage(wpmNameTable)

      // send message for setting preferences
      const wpmSetPreferences: WebPluginMessageRequest = {
        data: this.props.pluginConfig ? this.props.pluginConfig.options : "",
        message: WebPluginMessage.WEB_PLUGIN_MESSAGE_SET_PREFERENCES,
      }
      this.iframe.contentWindow.postMessage(wpmSetPreferences)

      // send message for the theme
      const wpmTheme: WebPluginMessageRequest = {
        data: cloneDeep(this.props.theme),
        message: WebPluginMessage.WEB_PLUGIN_MESSAGE_THEME,
      }
      this.iframe.contentWindow.postMessage(wpmTheme)
    }
  }

  onMessage = (event: MessageEvent) => {
    // IMPORTANT: Check the origin of the data!
    const doMessage =
      true /*import.meta.env.MODE !== "production" || ~event.origin.indexOf(this.props.engine)*/
    if (doMessage) {
      const response: WebPluginMessageResponse = event.data

      if (response) {
        // determine which message was received and respond accordingly
        if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_BROWSE_REMOTE) {
          const wpmBrowseRemoteResponse: WebPluginData_BrowseRemoteResponse = response.data

          // make sure the user can configure the engine
          const { engineCapabilities } = this.props
          let canConfigureEngine = true
          if (engineCapabilities) {
            const policies = engineCapabilities.userRights.policies
            canConfigureEngine = policies.includes(EngineUserPolicies.configureEngine)
          }

          // show the remote file browser modal
          if (
            canConfigureEngine &&
            wpmBrowseRemoteResponse &&
            wpmBrowseRemoteResponse.flags !== null &&
            wpmBrowseRemoteResponse.flags !== undefined &&
            typeof wpmBrowseRemoteResponse.flags === "number" &&
            wpmBrowseRemoteResponse.path !== null &&
            wpmBrowseRemoteResponse.path !== undefined &&
            typeof wpmBrowseRemoteResponse.path === "string"
          ) {
            this.setState({
              browseParams: {
                flags: wpmBrowseRemoteResponse.flags,
                path: wpmBrowseRemoteResponse.path,
              },
              showBrowseModal: true,
            })
          }
        } else if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_COMMAND_MESSAGE) {
          const wpmCommandMessageResponse: WebPluginData_CommandMessageResponse = response.data

          if (
            wpmCommandMessageResponse !== null &&
            wpmCommandMessageResponse !== undefined &&
            wpmCommandMessageResponse.id !== null &&
            wpmCommandMessageResponse.id !== undefined &&
            typeof wpmCommandMessageResponse.id === "number" &&
            wpmCommandMessageResponse.transid !== null &&
            wpmCommandMessageResponse.transid !== undefined &&
            typeof wpmCommandMessageResponse.transid === "number"
          ) {
            // determine which command was received
            if (
              wpmCommandMessageResponse.id ===
              WebPluginMessageCommand.WEB_PLUGIN_MESSAGE_COMMAND_DISPLAY_MESSAGE
            ) {
              const wpmCommandMessageResponseDisplayMessage: WebPluginData_CommandMessageResponseDisplayMessage =
                wpmCommandMessageResponse as WebPluginData_CommandMessageResponseDisplayMessage

              if (
                wpmCommandMessageResponseDisplayMessage &&
                wpmCommandMessageResponseDisplayMessage.message !== null &&
                wpmCommandMessageResponseDisplayMessage.message !== undefined &&
                typeof wpmCommandMessageResponseDisplayMessage.message === "string" &&
                wpmCommandMessageResponseDisplayMessage.title !== null &&
                wpmCommandMessageResponseDisplayMessage.title !== undefined &&
                typeof wpmCommandMessageResponseDisplayMessage.title === "string"
              ) {
                // display the message modal
                this.setState({
                  displayMessageParams: {
                    message: wpmCommandMessageResponseDisplayMessage.message,
                    title: wpmCommandMessageResponseDisplayMessage.title,
                  },
                  showDisplayMessage: true,
                })

                // send the result
                if (this.isValidIFrame()) {
                  const wpmCommandMessageRequest: WebPluginMessageRequest = {
                    data: {
                      id: wpmCommandMessageResponse.id,
                      result: 0,
                      transid: wpmCommandMessageResponse.transid,
                    },
                    message: WebPluginMessage.WEB_PLUGIN_MESSAGE_COMMAND_MESSAGE,
                  }
                  this.iframe.contentWindow.postMessage(wpmCommandMessageRequest)
                }
              }
            }
          }
        } else if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_DISPLAY_PROPERTIES) {
          const wpmDisplayPropertiesResponse: WebPluginData_DisplayPropertiesResponse =
            response.data

          // change the width and height of the iframe accordingly
          if (
            wpmDisplayPropertiesResponse &&
            wpmDisplayPropertiesResponse.height !== null &&
            wpmDisplayPropertiesResponse.height !== undefined &&
            typeof wpmDisplayPropertiesResponse.height === "string" &&
            wpmDisplayPropertiesResponse.width !== null &&
            wpmDisplayPropertiesResponse.width !== undefined &&
            typeof wpmDisplayPropertiesResponse.width === "string"
          ) {
            this.setState({
              height: wpmDisplayPropertiesResponse.height,
              width: wpmDisplayPropertiesResponse.width,
            })
          }
        } else if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_GET_PREFERENCES) {
          const responseGetPreferencesResponse: WebPluginData_GetPreferencesResponse = response.data

          // send the updated preferences to the parent
          if (
            responseGetPreferencesResponse !== null &&
            responseGetPreferencesResponse !== undefined &&
            typeof responseGetPreferencesResponse === "string"
          ) {
            this.props.onOK({
              ...(this.props.pluginConfig as any),
              options: responseGetPreferencesResponse,
            })
          } else {
            this.props.onOK(this.props.pluginConfig)
          }
        } else if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_GET_REMOTE_FILE) {
          const wpmGetRemoteFileResponse: WebPluginData_GetRemoteFileResponse = response.data

          // get the remote file from the capture engine
          if (
            wpmGetRemoteFileResponse !== null &&
            wpmGetRemoteFileResponse !== undefined &&
            typeof wpmGetRemoteFileResponse === "string"
          ) {
            fetchFile(this.props.engine, this.props.authToken, wpmGetRemoteFileResponse)
              .then((content: ArrayBuffer) => {
                if (this.isValidIFrame()) {
                  // send the remote file contents to the plugin
                  const wpmGetRemoteFileRequest: WebPluginMessageRequest = {
                    data: new TextDecoder().decode(content),
                    message: WebPluginMessage.WEB_PLUGIN_MESSAGE_GET_REMOTE_FILE,
                  }
                  this.iframe.contentWindow.postMessage(wpmGetRemoteFileRequest)
                }
              })
              .catch((error: any) => {
                this.setState({ error: error.toString() })
              })
          }
        } else if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_PLUGIN_MESSAGE) {
          const wpmPluginMessageResponse: WebPluginData_PluginMessage = response.data

          // send the plugin message to the capture engine
          if (this.props.pluginConfig && wpmPluginMessageResponse) {
            const pluginMessage: RequestPostPluginsMessage = { ...wpmPluginMessageResponse }
            sendGlobalPluginMessage(
              this.props.engine,
              this.props.authToken,
              this.props.pluginConfig.clsid,
              pluginMessage
            )
              .then((json: ResponsePostPluginsMessage) => {
                if (this.isValidIFrame()) {
                  // send the plugin message result to the plugin
                  const wpmPluginMessageRequest: WebPluginMessageRequest = {
                    data: json,
                    message: WebPluginMessage.WEB_PLUGIN_MESSAGE_PLUGIN_MESSAGE,
                  }
                  this.iframe.contentWindow.postMessage(wpmPluginMessageRequest)
                }
              })
              .catch(error => {
                this.setState({ error: `${error.code} ${error.reason}` })
              })
          }
        } else if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_READY_TO_CLOSE) {
          const wpmReadyToCloseResponse: WebPluginData_ReadyToClose = response.data
          this.setState({ readyToClose: wpmReadyToCloseResponse ? true : false })
        } else if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_SET_REMOTE_FILE) {
          const wpmSetRemoteFileResponse: WebPluginData_SetRemoteFileResponse = response.data

          // make sure the user can upload files
          const { engineCapabilities } = this.props
          let canUploadFiles = true
          if (engineCapabilities) {
            const policies = engineCapabilities.userRights.policies
            canUploadFiles = policies.includes(EngineUserPolicies.uploadFiles)
          }

          // send the remote file to the capture engine
          if (
            canUploadFiles &&
            wpmSetRemoteFileResponse &&
            wpmSetRemoteFileResponse.content !== null &&
            wpmSetRemoteFileResponse.content !== undefined &&
            typeof wpmSetRemoteFileResponse.content === "string" &&
            wpmSetRemoteFileResponse.path !== null &&
            wpmSetRemoteFileResponse.path !== undefined &&
            typeof wpmSetRemoteFileResponse.path === "string"
          ) {
            const contentBuf = new ArrayBuffer(wpmSetRemoteFileResponse.content.length)
            const contentArray = new Uint8Array(contentBuf)
            for (let i = 0, strLen = wpmSetRemoteFileResponse.content.length; i < strLen; i++) {
              contentArray[i] = wpmSetRemoteFileResponse.content.charCodeAt(i)
            }
            putFile(
              this.props.engine,
              this.props.authToken,
              wpmSetRemoteFileResponse.path,
              contentBuf
            )
              .then((content: ArrayBuffer) => {
                if (this.isValidIFrame()) {
                  // send the remote file to the plugin
                  const wpmSetRemoteFileRequest: WebPluginMessageRequest = {
                    data: new TextDecoder().decode(content),
                    message: WebPluginMessage.WEB_PLUGIN_MESSAGE_SET_REMOTE_FILE,
                  }
                  this.iframe.contentWindow.postMessage(wpmSetRemoteFileRequest)
                }
              })
              .catch((error: any) => {
                this.setState({ error: error.toString() })
              })
          }
        } else if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_TITLE) {
          const wpmTitleResponse: WebPluginData_TitleResponse = response.data

          // change the title of the modal
          if (
            wpmTitleResponse !== null &&
            wpmTitleResponse !== undefined &&
            typeof wpmTitleResponse === "string"
          ) {
            this.setState({
              title: wpmTitleResponse,
            })
          }
        }
      }
    }

    return Promise.resolve("")
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()

    if (this.isValidIFrame()) {
      // send message to get the preferences
      const wpmGetPreferences: WebPluginMessageRequest = {
        data: null,
        message: WebPluginMessage.WEB_PLUGIN_MESSAGE_GET_PREFERENCES,
      }
      this.iframe.contentWindow.postMessage(wpmGetPreferences)
    } else {
      this.props.onOK(this.props.pluginConfig)
    }
  }

  render() {
    const { engineStatus, onCancel, pluginConfig, pluginInfo } = this.props
    const {
      browseParams,
      displayMessageParams,
      error,
      readyToClose,
      showBrowseModal,
      showDisplayMessage,
      title,
    } = this.state
    if (!pluginConfig || !pluginInfo) return null

    // construct the path to the plugin web files
    const base =
      engineStatus && engineStatus.os === "Windows" && import.meta.env.MODE !== "production"
        ? "https://127.0.0.1:8887/"
        : this.props.engine + "/plugins/"
    const url = base + pluginConfig.clsid + "/options/"

    const defaultDir = engineStatus && engineStatus.os === "Windows" ? "C:\\" : "/"

    return (
      <OptionsModal isOpen={true} toggle={onCancel}>
        <ModalHeader toggle={onCancel}>{title ? title : pluginInfo.name}</ModalHeader>
        <ModalBody>
          {error !== null && (
            <Alert color="danger" isOpen={error !== null} toggle={this.onErrorDismiss.bind(this)}>
              {typeof error === "string" ? error : `${error.code} ${error.reason}`}
            </Alert>
          )}
          <Form id="pluginOptionsForm" onSubmit={this.onSubmit} noValidate>
            <OptionsIFrame
              height={this.state.height}
              id="pluginOptionsFrame"
              onLoad={this.onLoad}
              ref={(ref: any) => {
                this.iframe = ref
              }}
              sandbox="allow-same-origin allow-scripts"
              src={url}
              width={this.state.width}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton type="submit" form="pluginOptionsForm" disabled={!readyToClose}>
            OK
          </PrimaryButton>
        </ModalFooter>
        {showBrowseModal && browseParams && (
          <ChooseFileModal
            defaultDir={defaultDir}
            dir={browseParams.path}
            onCancel={this.onChooseFileCancel}
            onOK={this.onChooseFileOK}
            showFiles={
              (browseParams.flags & WebPluginBrowseFlags.WEB_PLUGIN_BROWSE_FLAGS_SHOW_FILES) !== 0
            }
            showHiddenFiles={
              (browseParams.flags & WebPluginBrowseFlags.WEB_PLUGIN_BROWSE_FLAGS_SHOW_HIDDEN) !== 0
            }
            showHiddenFilesControl={
              (browseParams.flags &
                WebPluginBrowseFlags.WEB_PLUGIN_BROWSE_FLAGS_HIDE_HIDDEN_CONTROL) ===
              0
            }
            title={
              (browseParams.flags & WebPluginBrowseFlags.WEB_PLUGIN_BROWSE_FLAGS_SHOW_FILES) !== 0
                ? "Remote File Browser"
                : "Remote Folder Browser"
            }
          />
        )}
        {showDisplayMessage && displayMessageParams && (
          <MessageModal
            message={displayMessageParams.message !== undefined ? displayMessageParams.message : ""}
            onOK={this.onDisplayMessageOK}
            show={showDisplayMessage}
            title={displayMessageParams.title !== undefined ? displayMessageParams.title : ""}
          />
        )}
      </OptionsModal>
    )
  }
}

const mapStateToProps = (state: any) => ({
  authToken: getAuthToken(state),
  engine: getEngine(state),
  engineStatus: getStatus(state),
  showAddressNames: getShowAddressNames(state),
  showPortNames: getShowPortNames(state),
  theme: getCurrentTheme(state),
  engineCapabilities: getCapabilities(state) || null,
})

export default connect(mapStateToProps)(PluginOptionsModal)
