import React from "react"
import { HeaderDiv, ContentOuter } from "./utils"
import styled from "styled-components"
import { useReconstructionsContentsContext } from "./reconstructions-contents"

const Payload = styled.div`
  height: 100%;
  font-family: monospace;
  color: ${props => props.theme.textColor};
  word-break: break-all;
  white-space: pre-wrap;
  overflow: auto;
  overflow-wrap: break-word;
  overflow-wrap: anywhere;
  tab-size: 4;
  padding: 8px;
`

interface ReconstructionsTextProps {
  reconstruction: string
}

const ReconstructionsText: React.FC<ReconstructionsTextProps> = ({ reconstruction }) => {
  const { contentType, fileSize, slicedMessage, ellipse } = useReconstructionsContentsContext()
  return (
    <>
      <HeaderDiv>
        {fileSize} {slicedMessage}
        {ellipse}
      </HeaderDiv>

      <ContentOuter>
        <Payload>
          {contentType?.includes("audio")
            ? "Audio type cannot generate text string"
            : reconstruction}
        </Payload>
      </ContentOuter>
    </>
  )
}

export default ReconstructionsText
