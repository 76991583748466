import * as React from "react"
import { cloneDeep, toNumber } from "lodash"
import FontAwesome from "react-fontawesome"
import { ButtonGroup, Form, FormGroup, InputGroup } from "reactstrap"
import { Input } from "../common/Input"
import { Label } from "../common/Form"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import { LightButton, LightDangerButton, PrimaryButton, SecondaryButton } from "../common/Buttons"
import { Select } from "../common/Select"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import { MSAProjectMappingProfile, MSAProjectSegment } from "../../api/types"
import { compareMappingProfiles } from "./utils"
import MappingProfileModal from "./MappingProfileModal"

type SegmentModalProps = {
  segment: MSAProjectSegment
  mappingProfiles: MSAProjectMappingProfile[]
  onOK: (segment: MSAProjectSegment, mappingProfiles: MSAProjectMappingProfile[]) => void
  onCancel: () => void
}

const SegmentModal = ({ segment, onOK, onCancel, ...props }: SegmentModalProps) => {
  const [name, setName] = React.useState(segment.name)
  const [manualOffset, setManualOffset] = React.useState(segment.manualOffset / 1000000000)
  const [mappingProfileId, setMappingProfileId] = React.useState(segment.mappingProfileId)
  const [mappingProfiles, setMappingProfiles] = React.useState(
    cloneDeep(props.mappingProfiles).sort(compareMappingProfiles)
  )
  const [editMappingProfile, setEditMappingProfile] =
    React.useState<MSAProjectMappingProfile | null>(null)
  const manualOffsetRef = React.useRef<HTMLInputElement | null>(null)

  const onEditMappingProfileOK = (mappingProfile: MSAProjectMappingProfile) => {
    const newMappingProfiles = cloneDeep(mappingProfiles)
    const i = newMappingProfiles.findIndex(profile => profile.id === mappingProfile.id)
    if (i === -1) {
      newMappingProfiles.push(mappingProfile)
    } else {
      newMappingProfiles[i] = mappingProfile
    }
    newMappingProfiles.sort(compareMappingProfiles)
    setMappingProfiles(newMappingProfiles)
    if (i === -1) {
      setMappingProfileId(mappingProfile.id)
    }
    setEditMappingProfile(null)
  }

  const onEditMappingProfileCancel = () => {
    setEditMappingProfile(null)
  }

  const isNameValid = name.length > 0
  const isManualOffsetValid = !Number.isNaN(manualOffset)
  const isValid = isNameValid && isManualOffsetValid

  const mappingProfileOptions = mappingProfiles.map(mappingProfile => (
    <option key={mappingProfile.id} value={mappingProfile.id}>
      {mappingProfile.name}
    </option>
  ))
  mappingProfileOptions.unshift(
    <option key={-1} value={-1}>
      (none)
    </option>
  )

  return (
    <Modal isOpen={true}>
      <ModalHeader toggle={onCancel}>Edit Segment</ModalHeader>
      <ModalBody>
        <Form
          id="editSegmentForm"
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            event.stopPropagation()
            onOK(
              {
                ...segment,
                name,
                manualOffset: manualOffset * 1000000000,
                mappingProfileId,
              },
              mappingProfiles
            )
          }}
          noValidate
        >
          <FormGroup>
            <Label for="segment-name">Name</Label>
            <Input
              type="text"
              id="segment-name"
              value={name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
              invalid={!isNameValid}
            />
          </FormGroup>
          <FormGroup>
            <Label for="manual-offset">Manual Offset</Label>
            <Input
              innerRef={manualOffsetRef}
              type="number"
              id="manual-offset"
              step="0.1"
              defaultValue={manualOffset.toFixed(6)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setManualOffset(event.target.value ? toNumber(event.target.value) : Number.NaN)
              }}
              invalid={!isManualOffsetValid}
            />
          </FormGroup>
          <FormGroup noMargin>
            <Label for="mapping-profile">Mapping Profile</Label>
            <InputGroup>
              <Select
                id="mapping-profile"
                value={mappingProfileId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setMappingProfileId(toNumber(event.target.value))
                }}
              >
                {mappingProfileOptions}
              </Select>
              <ButtonGroup>
                <LightButton
                  id="add-mapping-profile"
                  aria-label="Add mapping profile"
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  onClick={() => {
                    const nextId =
                      mappingProfiles.reduce((acc, val) => Math.max(acc, val.id), 0) + 1
                    setEditMappingProfile({
                      id: nextId,
                      name: "Untitled",
                      mappings: [],
                    })
                  }}
                >
                  <FontAwesome name="plus" />
                </LightButton>
                <UncontrolledTooltip placement="top" target="add-mapping-profile">
                  Add mapping profile
                </UncontrolledTooltip>
                <LightButton
                  id="edit-mapping-profile"
                  aria-label="Edit mapping profile"
                  disabled={mappingProfileId === -1}
                  onClick={() => {
                    const mappingProfile = mappingProfiles.find(
                      mappingProfile => mappingProfile.id === mappingProfileId
                    )
                    if (mappingProfile) {
                      setEditMappingProfile(mappingProfile)
                    }
                  }}
                >
                  <FontAwesome name="pencil" />
                </LightButton>
                <UncontrolledTooltip placement="top" target="edit-mapping-profile">
                  Edit mapping profile
                </UncontrolledTooltip>
                <LightDangerButton
                  id="delete-mapping-profile"
                  aria-label="Delete mapping profile"
                  disabled={mappingProfileId === -1}
                  onClick={() => {
                    const newMappingProfiles = cloneDeep(mappingProfiles)
                    const i = newMappingProfiles.findIndex(
                      profile => profile.id === mappingProfileId
                    )
                    if (i !== -1) {
                      newMappingProfiles.splice(i, 1)
                      setMappingProfiles(newMappingProfiles)
                      setMappingProfileId(-1)
                    }
                  }}
                >
                  <FontAwesome name="trash-o" />
                </LightDangerButton>
                <UncontrolledTooltip placement="top" target="delete-mapping-profile">
                  Delete mapping profile
                </UncontrolledTooltip>
              </ButtonGroup>
            </InputGroup>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" form="editSegmentForm" disabled={!isValid}>
          OK
        </PrimaryButton>
      </ModalFooter>
      {editMappingProfile !== null ? (
        <MappingProfileModal
          mappingProfile={editMappingProfile}
          onOK={onEditMappingProfileOK}
          onCancel={onEditMappingProfileCancel}
        />
      ) : null}
    </Modal>
  )
}

export default SegmentModal
