import * as React from "react"
import { cloneDeep, toNumber } from "lodash"
import styled from "styled-components"
import FileSaver from "file-saver"
import FontAwesome from "react-fontawesome"
import { Alert } from "../common/Alert"
import { ButtonGroup, Form, FormGroup } from "reactstrap"
import { ConfirmationModal } from "../common/ConfirmationModal"
import { Input } from "../common/Input"
import { Label } from "../common/Form"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import {
  FileInputButton,
  LightButton,
  LightDangerButton,
  PrimaryButton,
  SecondaryButton,
} from "../common/Buttons"
import { MutedText } from "../common/MutedText"
import { Table } from "../common/Table"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import { MSAProjectMapping, MSAProjectMappingProfile } from "../../api/types"
import { MediaSpecType } from "../../api/types/mediaTypes"
import { compareMappings, isPortValid } from "./utils"
import MappingModal from "./MappingModal"

const MappingsTable = styled(Table)`
  & th:nth-child(1),
  & td:nth-child(1) {
    width: 100%;
  }

  & th:nth-child(2),
  & td:nth-child(2) {
  }
`

type MappingProfileModalProps = {
  mappingProfile: MSAProjectMappingProfile
  onOK: (mappingProfile: MSAProjectMappingProfile) => void
  onCancel: () => void
}

const MappingProfileModal = ({ mappingProfile, onOK, onCancel }: MappingProfileModalProps) => {
  const [name, setName] = React.useState(mappingProfile.name)
  const [mappings, setMappings] = React.useState(
    cloneDeep(mappingProfile.mappings).sort(compareMappings)
  )
  const [editMapping, setEditMapping] = React.useState<MSAProjectMapping | null>(null)
  const [error, setError] = React.useState("")
  const [showDeleteMappingsBeforeImportConfirm, setShowDeleteMappingsBeforeImportConfirm] =
    React.useState(false)
  const [importFile, setImportFile] = React.useState<string | null>(null)

  const formatMappingAddress = (addressType: number, address: string, port: number) => {
    if (isPortValid(port)) {
      if (addressType === MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS) {
        return `${address}:${port}`
      } else {
        return `[${address}]:${port}`
      }
    } else {
      return address
    }
  }

  const onImportMappings = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("")
    if (!event.target.files) return
    const file = event.target.files[0]
    if (!file) return
    const reader = new FileReader()
    reader.onerror = () => {
      setError("Error reading file")
    }
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (typeof e?.target?.result === "string") {
        setImportFile(e.target.result)
        setShowDeleteMappingsBeforeImportConfirm(true)
      } else {
        setError("Error reading file")
      }
    }
    reader.readAsText(file)

    // Reset the file input so the same file can be used again.
    event.target.value = ""
  }

  const onImportMappingsExecute = (existingMappings: MSAProjectMapping[]) => {
    if (importFile) {
      try {
        const domParser = new DOMParser()
        const dom = domParser.parseFromString(importFile, "application/xml")

        if (dom.documentElement.nodeName !== "Mappings") {
          throw new Error("Invalid mappings file")
        }

        const newMappings: MSAProjectMapping[] = cloneDeep(existingMappings)

        // Note: the ID only needs to be unique within the profile
        let id = newMappings.reduce((acc, val) => Math.max(acc, val.id), 0) + 1

        // Import and change the name also? Omnipeek doesn't.
        // const name = dom.documentElement.getAttribute("Name")

        const loadMapping = (elem: Element) => {
          let addressType = MediaSpecType.MEDIA_SPEC_TYPE_NULL
          let address1 = ""
          let port1 = 0
          let address2 = ""
          let port2 = 0
          for (let i = 0, len = elem.children.length; i < len; ++i) {
            const childNode = elem.children[i]
            if (childNode.nodeName === "Addr1") {
              if (childNode.textContent) {
                address1 = childNode.textContent
              }
              const type = childNode.getAttribute("Type")
              if (type) {
                addressType = toNumber(type)
              }
            } else if (childNode.nodeName === "Addr2") {
              if (childNode.textContent) {
                address2 = childNode.textContent
              }
            } else if (childNode.nodeName === "Port1") {
              if (childNode.textContent) {
                port1 = toNumber(childNode.textContent)
              }
            } else if (childNode.nodeName === "Port2") {
              if (childNode.textContent) {
                port2 = toNumber(childNode.textContent)
              }
            } else {
              throw new Error("Unexpected element in mapping")
            }
          }

          // Validate.
          if (
            addressType !== MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS &&
            addressType !== MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS
          ) {
            throw new Error("Invalid address type in mappings file")
          }
          if (address1.length === 0) {
            throw new Error("Invalid or missing \u201cAddr1\u201d in mappings file")
          }
          if (address2.length === 0) {
            throw new Error("Invalid or missing \u201cAddr2\u201d in mappings file")
          }
          if (port1 !== 0 && !isPortValid(port1)) {
            throw new Error("Invalid \u201cPort1\u201d in mappings file")
          }
          if (port2 !== 0 && !isPortValid(port2)) {
            throw new Error("Invalid \u201cPort2\u201d in mappings file")
          }

          // Add the new mapping.
          newMappings.push({
            address1,
            address2,
            addressType,
            id,
            port1,
            port2,
          })

          // Bump the id.
          id++
        }

        const loadMappings = (elem: Element) => {
          for (let i = 0, len = elem.children.length; i < len; ++i) {
            const childNode = elem.children[i]
            if (childNode.nodeName === "Mapping") {
              loadMapping(childNode)
            }
          }
        }

        loadMappings(dom.documentElement)

        newMappings.sort(compareMappings)
        setMappings(newMappings)
      } catch (error: any) {
        if ("message" in error) {
          setError(error.message)
        } else {
          setError("Error importing mappings")
        }
      }
    }
  }

  const onDeleteMappingsBeforeImportCancel = () => {
    setShowDeleteMappingsBeforeImportConfirm(false)
    onImportMappingsExecute(mappings)
  }

  const onDeleteMappingsBeforeImportOK = () => {
    setShowDeleteMappingsBeforeImportConfirm(false)
    onImportMappingsExecute([])
  }

  const onExport = () => {
    try {
      setError("")

      const doc = document.implementation.createDocument(null, "Mappings", null)
      const pi = doc.createProcessingInstruction("xml", 'version="1.0" encoding="utf-8"')
      doc.insertBefore(pi, doc.firstChild)
      doc.documentElement.setAttribute("Name", name)
      for (const mapping of mappings) {
        const mappingElem = doc.createElement("Mapping")

        const addr1Elem = doc.createElement("Addr1")
        const addr1Text = doc.createTextNode(mapping.address1)
        addr1Elem.appendChild(addr1Text)
        addr1Elem.setAttribute("Type", mapping.addressType.toString())
        mappingElem.appendChild(addr1Elem)

        const port1Elem = doc.createElement("Port1")
        const port1Text = doc.createTextNode(mapping.port1.toString())
        port1Elem.appendChild(port1Text)
        mappingElem.appendChild(port1Elem)

        const addr2Elem = doc.createElement("Addr2")
        const addr2Text = doc.createTextNode(mapping.address2)
        addr2Elem.appendChild(addr2Text)
        addr2Elem.setAttribute("Type", mapping.addressType.toString())
        mappingElem.appendChild(addr2Elem)

        const port2Elem = doc.createElement("Port2")
        const port2Text = doc.createTextNode(mapping.port2.toString())
        port2Elem.appendChild(port2Text)
        mappingElem.appendChild(port2Elem)

        doc.documentElement.appendChild(mappingElem)
      }

      const serializer = new XMLSerializer()
      const xml = serializer.serializeToString(doc)
      FileSaver.saveAs(new Blob([xml], { type: "text/xml;charset=utf8" }), `${name}.xml`)
    } catch (error) {
      console.error(error)
      setError("Error exporting mappings")
    }
  }

  const isNameValid = name.length > 0
  const isMappingsValid = mappings.length > 0
  const isValid = isNameValid && isMappingsValid

  return (
    <Modal isOpen={true}>
      <ModalHeader toggle={onCancel}>Edit Mapping Profile</ModalHeader>
      <ModalBody>
        <Alert color="danger" isOpen={error.length > 0} fade={false} toggle={() => setError("")}>
          {error}
        </Alert>
        <Form
          id="mapping-profile-form"
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            event.stopPropagation()
            onOK({
              id: mappingProfile.id,
              mappings,
              name,
            })
          }}
          noValidate
        >
          <FormGroup>
            <Label for="mapping-profile-name">Name</Label>
            <Input
              type="text"
              id="mapping-profile-name"
              value={name}
              invalid={!isNameValid}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
            />
          </FormGroup>
          <FormGroup noMargin>
            <Label for="mappings">Mappings</Label>
            <div style={{ marginBottom: "0.5rem" }}>
              <ButtonGroup>
                <LightButton
                  size="sm"
                  id="add-mapping"
                  aria-label="Add mapping"
                  onClick={() => {
                    // Note: the ID only needs to be unique within the profile at this point
                    const nextId = mappings.reduce((acc, val) => Math.max(acc, val.id), 0) + 1
                    setEditMapping({
                      addressType: MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS,
                      id: nextId,
                      address1: "",
                      port1: 0,
                      address2: "",
                      port2: 0,
                    })
                  }}
                >
                  <FontAwesome name="plus" />
                </LightButton>
                <UncontrolledTooltip placement="top" target="add-mapping">
                  Add mapping
                </UncontrolledTooltip>
                <LightButton
                  size="sm"
                  id="export-mappings"
                  aria-label="export-mappings"
                  onClick={onExport}
                >
                  <FontAwesome name="download" />
                </LightButton>
                <UncontrolledTooltip placement="top" target="export-mappings">
                  Export mappings
                </UncontrolledTooltip>
                <FileInputButton
                  id="import-mappings"
                  aria-label="Import mappings"
                  className="btn-sm"
                  accept="application/xml"
                  onChange={onImportMappings}
                >
                  <FontAwesome name="upload" />
                </FileInputButton>
                <UncontrolledTooltip placement="top" target="import-mappings">
                  Import mappings
                </UncontrolledTooltip>
              </ButtonGroup>
            </div>
            <MappingsTable id="mappings">
              <tbody>
                {mappings.map((mapping, i) => (
                  <tr key={mapping.id}>
                    <td>
                      {formatMappingAddress(mapping.addressType, mapping.address1, mapping.port1)}{" "}
                      &rarr;{" "}
                      {formatMappingAddress(mapping.addressType, mapping.address2, mapping.port2)}
                    </td>
                    <td>
                      <ButtonGroup>
                        <LightButton
                          size="sm"
                          id="edit-mapping"
                          aria-label="Edit mapping"
                          onClick={() => {
                            setEditMapping(mapping)
                          }}
                        >
                          <FontAwesome name="pencil" />
                        </LightButton>
                        <UncontrolledTooltip placement="top" target="edit-mapping">
                          Edit mapping
                        </UncontrolledTooltip>
                        <LightDangerButton
                          size="sm"
                          id="delete-mapping"
                          aria-label="Delete mapping"
                          onClick={() => {
                            const newMappings = cloneDeep(mappings)
                            newMappings.splice(i, 1)
                            newMappings.sort(compareMappings)
                            setMappings(newMappings)
                          }}
                        >
                          <FontAwesome name="trash-o" />
                        </LightDangerButton>
                        <UncontrolledTooltip placement="top" target="delete-mapping">
                          Delete mapping
                        </UncontrolledTooltip>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
                {mappings.length === 0 ? (
                  <tr>
                    <td>
                      <MutedText>No mappings</MutedText>
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </MappingsTable>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" form="mapping-profile-form" disabled={!isValid}>
          OK
        </PrimaryButton>
      </ModalFooter>
      {editMapping !== null ? (
        <MappingModal
          mapping={editMapping}
          onOK={(mapping: MSAProjectMapping) => {
            const i = mappings.findIndex(val => val.id === mapping.id)
            const newMappings = cloneDeep(mappings)
            if (i !== -1) {
              newMappings[i] = mapping
            } else {
              newMappings.push(mapping)
            }
            newMappings.sort(compareMappings)
            setMappings(newMappings)
            setEditMapping(null)
          }}
          onCancel={() => {
            setEditMapping(null)
          }}
        />
      ) : null}
      {showDeleteMappingsBeforeImportConfirm && (
        <ConfirmationModal
          message={"Delete all mappings before importing?"}
          onNo={onDeleteMappingsBeforeImportCancel}
          onYes={onDeleteMappingsBeforeImportOK}
          show={showDeleteMappingsBeforeImportConfirm}
          title="Delete All Mappings"
        />
      )}
    </Modal>
  )
}

export default MappingProfileModal
