import * as React from "react"
import { Modal, ModalHeader, ModalBody } from "../common/Modal"
import PropTable from "../common/PropTable"
import { OutsideLink } from "../common/Link"
import { Protocol } from "../../api/types"

type ProtocolDescriptionModalProps = {
  isOpen?: boolean
  protocolId: number | null
  descriptions: Protocol[] | null
  onClose: () => void
}

class ProtocolDescriptionModal extends React.Component<ProtocolDescriptionModalProps> {
  render() {
    const { isOpen = true, protocolId, descriptions, onClose } = this.props
    if (!isOpen) return null
    const properties: any = {}
    if (protocolId && descriptions) {
      const desc = descriptions.find((desc: Protocol) => desc.id === protocolId)
      if (desc) {
        let description: any = desc.description
        const parts = description.split(/\[([^\]]+)\]\(([^)"\s]+)(?:\s+"(.*)")?\)/)
        if (parts && parts.length >= 3) {
          description = (
            <>
              {parts[0]}
              <OutsideLink href={parts[2]} target="_blank" rel="noopener noreferrer">
                {parts[1]}
              </OutsideLink>
              {parts.slice(3)}
            </>
          )
        }
        properties["Protocol"] = desc.shortName
        if (desc.longName !== desc.shortName) {
          properties["Long Name"] = desc.longName
        }
        properties["Description"] = description
      }
    }
    return (
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>Protocol Description</ModalHeader>
        <ModalBody>
          <PropTable
            propList={["Protocol", "Long Name", "Description"]}
            data={properties}
            skipEmptyRows
          />
        </ModalBody>
      </Modal>
    )
  }
}

export default ProtocolDescriptionModal
