import styled from "styled-components"
import {
  Popover as ReactstrapPopover,
  PopoverHeader as ReactstrapPopoverHeader,
  PopoverBody as ReactstrapPopoverBody,
} from "reactstrap"

export const Popover = styled(ReactstrapPopover)<{ maxWidth?: number | string }>`
  & .popover {
    background-color: ${props => props.theme.popoverBackgroundColor};
    max-width: ${props => props.maxWidth};
  }

  /* Fix the placement of the arrow caused by changing the default font size */
  & .bs-popover-top .arrow,
  & .bs-popover-auto[data-popper-placement^="top"] .arrow {
    bottom: -0.5rem;
  }

  & .bs-popover-right .arrow,
  & .bs-popover-auto[data-popper-placement^="right"] .arrow {
    left: -0.5rem;
  }

  & .bs-popover-bottom .arrow,
  & .bs-popover-auto[data-popper-placement^="bottom"] .arrow {
    top: -0.5rem;
  }

  & .bs-popover-left .arrow,
  & .bs-popover-auto[data-popper-placement^="left"] .arrow {
    right: -0.5rem;
  }

  /* Fix the color of the arrow */
  & .bs-popover-top .arrow::after,
  & .bs-popover-auto[data-popper-placement^="top"] .arrow::after {
    border-top-color: ${props => props.theme.popoverBackgroundColor};
  }

  & .bs-popover-right .arrow::after,
  & .bs-popover-auto[data-popper-placement^="right"] .arrow::after {
    border-right-color: ${props => props.theme.popoverBackgroundColor};
  }

  & .bs-popover-bottom .arrow::after,
  & .bs-popover-auto[data-popper-placement^="bottom"] .arrow::after {
    border-bottom-color: ${props => props.theme.popoverBackgroundColor};
  }

  & .bs-popover-left .arrow::after,
  & .bs-popover-auto[data-popper-placement^="left"] .arrow::after {
    border-left-color: ${props => props.theme.popoverBackgroundColor};
  }
`

export const PopoverHeader = styled(ReactstrapPopoverHeader)`
  border: none;
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.popoverHeaderBackgroundColor};
`

export const PopoverBody = styled(ReactstrapPopoverBody)`
  padding: 0.5rem;
  color: ${props => props.theme.textColor};
`
