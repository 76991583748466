import * as React from "react"
import styled from "styled-components"

const LinkStateList = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
`

const LinkStateListItem = styled.li`
  list-style: none;
  margin: 0;
  padding: 0;

  & + & {
    padding-left: 4px;
  }
`

const LinkStateIconStyle = styled.polygon<{ up?: boolean }>`
  stroke: ${props => props.theme.textColor};
  fill: ${props => (props.up ? props.theme.linkStateUpColor : props.theme.linkStateDownColor)};
`

const LinkStateIcon = ({ up, height = 14 }: { up: boolean; height?: number }) => {
  return (
    <svg height={height} version="1.1" viewBox="0 0 31 26" xmlns="http://www.w3.org/2000/svg">
      <title>{up ? "Link up" : "Link down"}</title>
      <g transform="translate(0.5,0.5)">
        <LinkStateIconStyle
          up={up}
          fill="none"
          stroke="#000000"
          strokeWidth="1"
          points="2,2 5,2 5,8 5,2 8,2 8,8 8,2 11,2 11,8 11,2 14,2 14,8 14,2 17,2 17,8 17,2 20,2 20,8 20,2 23,2 23,8 23,2 26,2 26,8 26,2 29,2 29,16 24,16 24,20 22,20 22,24 9,24 9,20 7,20 7,16 2,16"
        />
      </g>
    </svg>
  )
}

type LinkStateProps = {
  linkState?: number | Array<number>
}

const LinkState = ({ linkState }: LinkStateProps) => {
  if (Array.isArray(linkState)) {
    return (
      <LinkStateList>
        {linkState.map((l, i) => (
          <LinkStateListItem key={i}>
            <LinkStateIcon up={l === 1} />
          </LinkStateListItem>
        ))}
      </LinkStateList>
    )
  } else if (typeof linkState === "number") {
    if (linkState === 0 || linkState === 1) {
      return (
        <LinkStateList>
          <LinkStateListItem>
            <LinkStateIcon up={linkState === 1} />
          </LinkStateListItem>
        </LinkStateList>
      )
    }
  }
  return null
}

export default LinkState
