import * as React from "react"
import styled from "styled-components"
import { NavLink } from "react-router-dom"

const NavDiv = styled.nav`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.navMarginBottom};
  padding: ${props => props.theme.navPadding};
  border-radius: ${props => props.theme.navBorderRadius};
  box-shadow: ${props => props.theme.navBoxShadow};
  overflow: hidden;
  user-select: none;
`

const NavStrip = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`

export const NavItemGroup = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0 1rem 0 0;
`

export const NavItemGroupTitle = styled.h5`
  color: inherit;
  padding: 0;
  margin-bottom: 2px;
  white-space: nowrap;
  min-height: 1.2em;
`

export const NavItem = styled.li`
  display: flex;
  list-style: none;
  padding-left: 6px;
  padding-right: 6px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  ${NavItemGroup} & {
    padding-left: 0;
    padding-right: 0;
  }
`

export const NavItemLink = styled(NavLink)`
  height: calc(1.5rem + 3px);
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  border-bottom: 3px solid transparent;
  transition: border-bottom-color 0.15s ease-in-out;

  &:hover,
  &.active {
    color: inherit;
    text-decoration: none;
    border-bottom: 3px solid currentColor;
  }
`

export const Nav = ({ children }: { children?: React.ReactNode }) => (
  <NavDiv>
    <NavStrip>{children}</NavStrip>
  </NavDiv>
)
