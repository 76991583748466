import { cloneDeep, merge } from "lodash"
import { getAdapterEnumerator } from "../components/CaptureOptionsView/utils"
import {
  Adapter,
  CaptureTemplate,
  CaptureTemplateProperties,
  EngineCapabilitiesPluginInfo,
  ForensicSearchHistoryItemProperties,
  ForensicSearchProperties,
  PluginObject,
  ResponseGetEngineCapabilities,
  ResponseGetStatus,
} from "../api/types"
import { GraphDataIntervalUnit } from "../api/types/graphTypes"
import {
  CaptureTemplateType,
  PeekAdapterType,
  PeekCaptureStatus,
  PeekFilterMode,
  PeekSeverity,
  PeekVariantType,
  PluginCategory,
  StatisticsOutputIntervalUnit,
  StatisticsOutputMethodTypes,
  StatisticsReportTypes,
} from "../api/types/peekTypes"
import { CLSID_COMPASS } from "./pluginUtils"

export const LIVEFLOW_CAPTURE_ID = "4D79204C-6976-654E-5843-617074757265"
export const LIVEFLOW_CLSID = "8E1E4298-8FAF-48FE-893A-A4C92DD1DEF8"
export const LIVEWIRE_BRIDGE_ROUTER_IFNAME_DEFAULT = "eth4"
export const THREATEYE_NV_CAPTURE_ID = "811670D3-AD8E-4D62-AEE0-249A97BE5F4A"
export const THREATEYE_NV_CLSID = "B96E47E1-6D37-48B8-89F5-1F9CE019C334"

export function getDefaultCaptureOptions(
  engineStatus: ResponseGetStatus | null
): CaptureTemplateProperties {
  let ctdFileSize = 1073741824 // 1GB
  if (engineStatus) {
    const totalTB = Math.floor(engineStatus.storageTotal / 1099511627776)
    if (totalTB < 256) {
      // < 256 TB
      ctdFileSize = 1073741824 // 1 GB
    } else if (totalTB < 1024) {
      // 256 TB - 1 PB
      ctdFileSize = 4294967296 // 4 GB
    } else if (totalTB < 2048) {
      // 1 PB - 2 PB
      ctdFileSize = 8589934592 // 8 GB
    } else if (totalTB < 4096) {
      // 2 PB - 4 PB
      ctdFileSize = 17179869184 // 16 GB
    } else {
      // > 4 PB
      ctdFileSize = 34359738368 // 32 GB
    }
  }
  return {
    adapterSettings: {
      clsid: "6F6FBFC1-3F14-46CA-A269-7664A41FC709",
      enumerator: "",
      name: "",
      type: PeekAdapterType.PEEK_NETWORK_ADAPTER,
    },
    alarmConfig: {
      clsid: "E18AF1CF-7F83-45A7-95CB-410138FBC3E3",
      alarms: [],
    },
    filterConfig: {
      clsid: "84D2EAC7-3B74-420E-8EEE-85C7165BB471",
      mode: PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_ALL,
      filters: [],
    },
    generalSettings: {
      clsid: "6F6FBFC1-3F14-46CA-A269-7664A41FC709",
      bufferSize: 268435456,
      captureToDisk: true,
      comment: "",
      continuousCapture: true,
      ctdFilePattern: "Capture-",
      ctdFileSize,
      ctdIntelligent: false,
      ctdIntelligentCount: 20,
      ctdKeepLastFiles: true,
      ctdKeepLastFilesCount: 0,
      ctdMaxFileAge: 21600,
      ctdPriority: true,
      ctdRetentionTime: 86400,
      ctdUseMaxFileAge: false,
      ctdUseMaxTotalFileSize: false,
      ctdUseRetentionTime: false,
      ctdUseCompression: true,
      deduplicate: false,
      enableAppStats: false,
      enableTimelineStats: true,
      enableTopStats: false,
      enableVoIPStats: false,
      name: "Capture",
      owner: "",
      sliceLength: 128,
      slicing: false,
      startCapture: true,
      tapTimestamps: 0,
    },
    graphSettings: {
      clsid: "3E3D84CE-563C-486C-8E6E-AADDD29786F8",
      enabled: false,
      fileBufferSize: 50,
      fileCount: 2,
      interval: 15,
      intervalUnit: GraphDataIntervalUnit.GRAPH_DATA_INTERVAL_UNIT_SECONDS,
      memory: 1,
      preserveFiles: false,
      templates: [],
    },
    hardwareConfig: {
      clsid: "6F3377D6-FBD7-4CBD-A740-2EAE63DBF639",
      hardwareProfiles: [],
    },
    indexingSettings: {
      clsid: "99403F22-0933-41E0-A0B8-9F626083F52F",
      properties: [],
    },
    performanceConfig: {
      clsid: "6F6FBFC1-3F14-46CA-A269-7664A41FC709",
      alarms: false,
      analysisModules: false,
      applicationStatistics: false,
      countryStatistics: false,
      errorStatistics: false,
      expertAnalysis: false,
      mplsVlanVxlanStatistics: false,
      networkStatistics: false,
      nodeStatistics: false,
      nodeProtocolDetailStatistics: false,
      passiveNameResolution: false,
      protocolStatistics: false,
      sizeStatistics: false,
      summaryStatistics: false,
      topTalkerStatistics: false,
      trafficHistoryStatistics: false,
      voiceAndVideoAnalysis: false,
      webAnalysis: false,
      wirelessChannelStatistics: false,
      wirelessNodeStatistics: false,
    },
    pluginsConfig: {
      clsid: "862E83A1-BC76-479A-A7FD-06CD6451815A",
      plugins: [],
    },
    pluginsList: {
      clsid: "99403F22-0933-41E0-A0B8-9F626083F52F",
      properties: [],
    },
    repeatTrigger: false,
    startTrigger: {
      clsid: "E801FFF9-AE26-4DD7-A349-4506CF3D090E",
      enabled: false,
      notify: true,
      severity: PeekSeverity.PEEK_SEVERITY_INFORMATIONAL,
      toggleCapture: true,
      triggerEvents: [
        {
          clsid: "EC5E8097-B3D5-4B8D-AA64-F6C6B13ECB37",
          elapsedTime: 0,
          enabled: false,
          time: "",
          useDate: false,
          useElapsedTime: false,
        },
        {
          clsid: "505A9EAB-78BD-461A-9DC0-203BDF0E8837",
          enabled: false,
          mode: PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_ALL,
          packetFilter: {
            filterPack: [],
            clsid: "0B5343D2-F8E2-44E4-9CC5-15D9B3A8A788",
          },
        },
        {
          clsid: "1945F627-3E8F-462F-96D9-99FB75454F9C",
          bytesCaptured: 0,
          enabled: false,
        },
      ],
    },
    statsOutput: {
      clsid: "2B10D81D-2082-439D-B8FD-1E7C47CE0BB3",
      enabled: false,
      userPath: "",
      reportPath: "Reports",
      outputInterval: 1,
      outputIntervalUnit: StatisticsOutputIntervalUnit.STATISTICS_OUTPUT_INTERVAL_UNIT_HOURS,
      notify: false,
      alignOutput: true,
      newSetEnabled: true,
      newSetInterval: 1,
      newSetIntervalUnit: StatisticsOutputIntervalUnit.STATISTICS_OUTPUT_INTERVAL_UNIT_DAYS,
      scheduled: false,
      alignNewSet: true,
      keep: 10,
      keepEnabled: false,
      resetOutputType: false,
      outputType: StatisticsOutputMethodTypes.STATISTICS_OUTPUT_METHOD_TYPE_PDF,
    },
    statsOutputPrefs: {
      additionalReports: {
        outputAdditionalNode: false,
        outputConversationsOnly: false,
        outputExecutiveSummary: false,
        outputExpertLog: true,
        outputExpertSummary: false,
        outputVoIP: true,
      },
      clsid: "32E7B964-8B8D-4923-A75B-9D3B9E7C4BCE",
      expertStats: {
        expertApplications: true,
        expertFlows: true,
        outputExpertStatistics: true,
      },
      graphs: {
        outputGraphs: true,
      },
      nodeStats: {
        nodeBroadBytes: true,
        nodeBroadPackets: true,
        nodeFirstTime: true,
        nodeLastTime: true,
        nodeMaxSize: true,
        nodeMinSize: true,
        nodeMultiBytes: true,
        nodeMultiPackets: true,
        nodeName: true,
        nodePackets: true,
        nodeTypeApple: true,
        nodeTypeDEC: true,
        nodeTypeIP: true,
        nodeTypeIPv6: true,
        nodeTypeIPX: true,
        nodeTypePhysical: true,
        outputNodeDetails: true,
        outputNodeHierarchy: true,
        outputNodeStatistics: true,
      },
      protocolStats: {
        outputProtocolStatistics: true,
        protocolPackets: true,
        protocolPath: true,
      },
      reportType: StatisticsReportTypes.STATISTICS_REPORT_TYPE_FULL_PDF,
      summaryStats: {
        outputSnapshots: true,
        sumBytesPerSecond: true,
        sumPackets: true,
        sumPacketsPerSecond: true,
        sumPercentBytes: true,
        sumPercentPackets: true,
      },
      wirelessChannelsStats: {
        outputWirelessChannelsStatistics: true,
        wirelessChannelBand: true,
        wirelessChannelCRCPackets: true,
        wirelessChannelControlBytes: true,
        wirelessChannelControlPackets: true,
        wirelessChannelDataBytes: true,
        wirelessChannelDataPackets: true,
        wirelessChannelDS2DSBytes: true,
        wirelessChannelDS2DSPackets: true,
        wirelessChannelFromDSBytes: true,
        wirelessChannelFromDSPackets: true,
        wirelessChannelFrequency: true,
        wirelessChannelICVErrorPackets: true,
        wirelessChannelLocalBytes: true,
        wirelessChannelLocalPackets: true,
        wirelessChannelManagementBytes: true,
        wirelessChannelManagementPackets: true,
        wirelessChannelNoiseAverage: true,
        wirelessChannelNoiseCurrent: true,
        wirelessChannelNoisedBmAverage: true,
        wirelessChannelNoisedBmCurrent: true,
        wirelessChannelNoisedBmMax: true,
        wirelessChannelNoisedBmMin: true,
        wirelessChannelNoiseMax: true,
        wirelessChannelNoiseMin: true,
        wirelessChannelOrderBytes: true,
        wirelessChannelOrderPackets: true,
        wirelessChannelProtectedBytes: true,
        wirelessChannelProtectedPackets: true,
        wirelessChannelRetryBytes: true,
        wirelessChannelRetryPackets: true,
        wirelessChannelSignalAverage: true,
        wirelessChannelSignalCurrent: true,
        wirelessChannelSignaldBmAverage: true,
        wirelessChannelSignaldBmCurrent: true,
        wirelessChannelSignaldBmMax: true,
        wirelessChannelSignaldBmMin: true,
        wirelessChannelSignalMax: true,
        wirelessChannelSignalMin: true,
        wirelessChannelToDSBytes: true,
        wirelessChannelToDSPackets: true,
      },
      wirelessDataRates: {
        dataRatesBytes: true,
        dataRatesPackets: true,
        outputDataRates: true,
      },
      wlanStats: {
        outputWLANStatistics: true,
        wlanAssociations: true,
        wlanAuthentication: true,
        wlanAverageSizeReceived: true,
        wlanAverageSizeSent: true,
        wlanBeaconESSID: true,
        wlanBeaconPackets: true,
        wlanBroadcastBytes: true,
        wlanBroadcastPackets: true,
        wlanChannel: true,
        wlanDuration: true,
        wlanEncryption: true,
        wlanESSID: true,
        wlanFirstTimeReceived: true,
        wlanFirstTimeSent: true,
        wlanICVErrorPackets: true,
        wlanLastTimeReceived: true,
        wlanLastTimeSent: true,
        wlanMaxSizeReceived: true,
        wlanMaxSizeSent: true,
        wlanMinSizeReceived: true,
        wlanMinSizeSent: true,
        wlanMulticastBytes: true,
        wlanMulticastPackets: true,
        wlanName: true,
        wlanNoiseCurrent: true,
        wlanNoiseCurrentdBm: true,
        wlanNoiseMax: true,
        wlanNoiseMaxdBm: true,
        wlanNoiseMin: true,
        wlanNoiseMindBm: true,
        wlanPacketsReceived: true,
        wlanPacketsSent: true,
        wlanPrivacy: true,
        wlanRetryPackets: true,
        wlanSignalCurrent: true,
        wlanSignalCurrentdBm: true,
        wlanSignalMax: true,
        wlanSignalMaxdBm: true,
        wlanSignalMin: true,
        wlanSignalMindBm: true,
        wlanTrust: true,
        wlanType: true,
        wlanWEPKey: true,
        wlanWEPPackets: true,
      },
    },
    stopTrigger: {
      clsid: "E801FFF9-AE26-4DD7-A349-4506CF3D090E",
      enabled: false,
      notify: true,
      severity: PeekSeverity.PEEK_SEVERITY_INFORMATIONAL,
      toggleCapture: true,
      triggerEvents: [
        {
          clsid: "EC5E8097-B3D5-4B8D-AA64-F6C6B13ECB37",
          elapsedTime: 0,
          enabled: false,
          time: "",
          useDate: false,
          useElapsedTime: false,
        },
        {
          mode: PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_ALL,
          clsid: "505A9EAB-78BD-461A-9DC0-203BDF0E8837",
          enabled: false,
          packetFilter: {
            filterPack: [],
            clsid: "0B5343D2-F8E2-44E4-9CC5-15D9B3A8A788",
          },
        },
        {
          clsid: "1945F627-3E8F-462F-96D9-99FB75454F9C",
          bytesCaptured: 104857600,
          enabled: false,
        },
      ],
    },
    voipConfig: {
      clsid: "6F6FBFC1-3F14-46CA-A269-7664A41FC709",
      maxCalls: 2000,
      notify: true,
      severity: PeekSeverity.PEEK_SEVERITY_SEVERE,
      stopAnalysis: true,
    },
  }
}

export function getCaptureOptionsFromTemplate(
  ct: CaptureTemplate,
  adapters: Adapter[] | null,
  captureNames: string[],
  engineCapabilities: ResponseGetEngineCapabilities | null,
  engineStatus: ResponseGetStatus | null
): CaptureTemplateProperties {
  // create the new capture options from a merging of the
  // default capture options and the selected capture template
  let captureOptions = cloneDeep(getDefaultCaptureOptions(engineStatus))
  if (captureOptions.generalSettings) {
    if (engineStatus && engineStatus.customSettings === 2) {
      captureOptions.generalSettings.captureToDisk = false
      captureOptions.generalSettings.ctdFileSize = 83886080 // 80 MB
      captureOptions.generalSettings.ctdMaxFileAge = 21600 // 6 hours
      captureOptions.generalSettings.ctdUseMaxFileAge = true
      captureOptions.generalSettings.ctdKeepLastFiles = false
      captureOptions.generalSettings.ctdPriority = false
      captureOptions.generalSettings.ctdIntelligent = false
      captureOptions.generalSettings.enableTimelineStats = false
      captureOptions.generalSettings.startCapture = false
    }
  }

  // don't use ctdFileSize from default templates
  const template = cloneDeep(ct.template)
  if (
    ct.type === CaptureTemplateType.CAPTURE_TEMPLATE_TYPE_DEFAULT &&
    template.generalSettings?.ctdFileSize !== undefined
  ) {
    delete template.generalSettings.ctdFileSize
  }
  if (template.generalSettings) {
    delete template.generalSettings.captureId
  }

  captureOptions = merge(captureOptions, template)
  if (captureOptions.generalSettings && captureOptions.generalSettings.name !== undefined) {
    let captureCounter = 2
    const baseName = captureOptions.generalSettings.name
    while (captureNames.includes(captureOptions.generalSettings.name)) {
      captureOptions.generalSettings.name = baseName + " " + captureCounter
      captureCounter++
    }
  }

  // if analysis modules enabled, enable all analysis modules
  if (
    captureOptions.performanceConfig !== undefined &&
    captureOptions.performanceConfig.analysisModules !== undefined &&
    typeof captureOptions.performanceConfig.analysisModules === "boolean" &&
    captureOptions.performanceConfig.analysisModules
  ) {
    if (engineCapabilities && Array.isArray(engineCapabilities.pluginsInfo)) {
      const compassEnabled =
        captureOptions.performanceConfig.compass !== undefined &&
        typeof captureOptions.performanceConfig.compass === "boolean" &&
        captureOptions.performanceConfig.compass
      const templatePluginsList = engineCapabilities.pluginsInfo
        .filter(
          (info: EngineCapabilitiesPluginInfo) =>
            Array.isArray(info.categoryIds) &&
            info.categoryIds.includes(PluginCategory.PLUGIN_CATEGORY_PEEK_PLUGIN) &&
            (info.clsid !== CLSID_COMPASS || compassEnabled)
        )
        .map((info: EngineCapabilitiesPluginInfo) => {
          return {
            type: PeekVariantType.PEEK_VT_BSTR,
            value: `{${info.clsid}}`,
          }
        })

      if (captureOptions.pluginsList === undefined) {
        captureOptions.pluginsList = {
          clsid: "99403F22-0933-41E0-A0B8-9F626083F52F",
          properties: templatePluginsList,
        }
      } else {
        templatePluginsList.forEach((tpo: PluginObject) => {
          if (captureOptions.pluginsList !== undefined) {
            const pluginIndex = captureOptions.pluginsList.properties.findIndex(
              (po: PluginObject) => tpo.value === po.value
            )
            if (pluginIndex === -1) {
              captureOptions.pluginsList.properties.push(tpo)
            }
          }
        })
      }
    }
  }

  // select a default adapter (just use the first one)
  if (
    captureOptions.adapterSettings &&
    !captureOptions.adapterSettings.enumerator &&
    Array.isArray(adapters) &&
    adapters.length > 0
  ) {
    const adapter = adapters[0]
    captureOptions.adapterSettings.enumerator = getAdapterEnumerator(adapter.info)
    captureOptions.adapterSettings.name = adapter.info.description
    captureOptions.adapterSettings.type = adapter.info.type
  }

  // default storage space 50% of available
  if (engineStatus) {
    let ctdKeepLastFilesCount =
      captureOptions.generalSettings &&
      captureOptions.generalSettings.ctdKeepLastFilesCount !== undefined
        ? captureOptions.generalSettings.ctdKeepLastFilesCount
        : 0
    if (
      ctdKeepLastFilesCount === 0 &&
      captureOptions.generalSettings &&
      captureOptions.generalSettings.ctdFileSize !== undefined &&
      captureOptions.generalSettings.ctdFileSize !== 0
    ) {
      const storageAvailable = engineStatus.storageTotal - engineStatus.storageUsed
      const reserve = Math.floor(storageAvailable / 2)
      ctdKeepLastFilesCount = Math.floor(reserve / captureOptions.generalSettings.ctdFileSize)
    }
    if (captureOptions.generalSettings) {
      captureOptions.generalSettings.ctdKeepLastFilesCount = ctdKeepLastFilesCount
    }
  }

  return captureOptions
}

export function getCaptureButtonText(status: number) {
  switch (status) {
    case PeekCaptureStatus.peekCaptureStatusIdle:
    case PeekCaptureStatus.peekCaptureStatusIdleStopActive:
      return { text: "Start Capture", icon: "play" }
    case PeekCaptureStatus.peekCaptureStatusIdleStartActive:
    case PeekCaptureStatus.peekCaptureStatusIdleStartActiveStopActive:
      return { text: "Start Trigger", icon: "play" }
    case PeekCaptureStatus.peekCaptureStatusWaitStart:
    case PeekCaptureStatus.peekCaptureStatusWaitStartStopActive:
      return { text: "Abort Trigger", icon: "stop" }
    case PeekCaptureStatus.peekCaptureStatusCapturing:
    case PeekCaptureStatus.peekCaptureStatusCapturingStopActive:
      return { text: "Stop Capture", icon: "stop" }
    default:
      break
  }
  return { text: "", icon: "" }
}

export function getCaptureName(captureOptions: CaptureTemplateProperties) {
  let name = ""
  if (
    captureOptions &&
    captureOptions.generalSettings &&
    captureOptions.generalSettings.name !== undefined
  ) {
    name = captureOptions.generalSettings.name
  }
  return name
}

export function getCaptureTemplateName(captureTemplate: CaptureTemplate) {
  let name = ""
  if (captureTemplate) {
    name = getCaptureName(captureTemplate.template)
  }
  return name
}

export function setCaptureName(captureOptions: CaptureTemplateProperties, name: string) {
  if (captureOptions && captureOptions.generalSettings) {
    captureOptions.generalSettings.name = name
  }
}

export function setCaptureTemplateName(captureTemplate: CaptureTemplate, name: string) {
  if (captureTemplate) {
    setCaptureName(captureTemplate.template, name)
  }
}

export function validateFileExtension(name: string, isCompressed: boolean | undefined) {
  let valid = false
  const extension = name.includes(".") ? name.substring(name.lastIndexOf(".")) : ""
  if (isCompressed) {
    switch (extension) {
      case "":
      case ".npkt":
        valid = true
        break
      default:
        break
    }
  } else {
    switch (extension) {
      case "":
      case ".pkt":
      case ".pcap":
      case ".cap":
      case ".dmp":
      case ".appcap":
      case ".pcapng":
      case ".ntar":
      case ".npkt":
        valid = true
        break
      default:
        break
    }
  }
  return valid
}

export function getUniqueForensicSearchName(
  name: string,
  forensicSearches: ForensicSearchProperties[],
  forensicSearchHistory: ForensicSearchHistoryItemProperties[]
): string {
  const currentNames: string[] = forensicSearches
    .map((fs: ForensicSearchProperties) => fs.name)
    .concat(
      forensicSearchHistory.map((fsh: ForensicSearchHistoryItemProperties) =>
        fsh.query.name !== undefined ? fsh.query.name : ""
      )
    )
    .filter((fsName: string) => fsName.length > 0)

  let captureCounter = 2
  const baseName = name
  let fsName = name
  while (currentNames.includes(fsName)) {
    fsName = baseName + " " + captureCounter
    captureCounter++
  }
  return fsName
}
