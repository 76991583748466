import { createRoot } from "react-dom/client"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Helmet } from "react-helmet"
import { Provider } from "react-redux"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import "./index.scss"
import "font-awesome/css/font-awesome.css"
import "react-datepicker/dist/react-datepicker.css"
import "react-grid-layout/css/styles.css"
import "react-splitter-layout/lib/index.css"
import "react-virtualized/styles.css"
import "rc-slider/assets/index.css"
import App from "./components/App"
import Login from "./components/Login"
import Logout from "./components/Logout"
import { ErrorBoundary } from "./components/ErrorBoundary"
import { BASENAME, LOGIN_PATH, LOGOUT_PATH } from "./routes"
import store from "./store"

const queryClient = new QueryClient()

/*
if (import.meta.env.MODE !== "production") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js")
  whyDidYouRender(React, {
    trackAllPureComponents: true,
    include: [/^App/],
    exclude: [/(Dropdown|Nav|Header)/],
  })
}
*/

const container = document.getElementById("root")
if (container) {
  const root = createRoot(container)
  root.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          <Helmet defaultTitle="Omnipeek" titleTemplate="Omnipeek | %s" />
          <Router basename={BASENAME}>
            <Switch>
              <Route path={LOGIN_PATH} component={Login} />
              <Route path={LOGOUT_PATH} component={Logout} />
              <Route path="/" component={App} />
            </Switch>
          </Router>
          <ReactQueryDevtools buttonPosition="bottom-left" />
        </ErrorBoundary>
      </QueryClientProvider>
    </Provider>
  )
}
