import * as React from "react"
import qs from "qs"
import { cloneDeep, isNumber, isString } from "lodash"
import useMount from "react-use/lib/useMount"
import useSetState from "react-use/lib/useSetState"
import { useSelector } from "react-redux"
import { Redirect, useHistory, useLocation } from "react-router-dom"
import { Helmet } from "react-helmet"
import BreadcrumbItem from "../../BreadcrumbNav/BreadcrumbItem"
import { LoginModal } from "../../LoginModal"
import { Modal, ModalBody } from "../../common/Modal"
import { MutedText } from "../../common/MutedText"
import { View } from "../../common/View"
import {
  getDistributedForensicSearchesUrl,
  getNewDistributedForensicSearchUrl,
} from "../../../routes"
import {
  getServer,
  getServerAuthToken,
  getServerName,
  getEngines,
  getCapabilities,
  getUsername,
} from "../../../store"
import {
  isJWT,
  logIn,
  logInCertificate,
  logOut,
  fetchCaptureSessions,
  fetchEngineCapabilities,
  createDistributedForensicSearch,
} from "../../../api/api"
import { decrypt } from "../../../utils/crypto"
import { getEngineDisplayName, isUsingGroupAuthentication } from "../../../utils/engineUtils"
import { removeFileExtension } from "../../../utils/removeFileExtension"
import { WizardViewContent } from "./Styles"
import TimeRangeAndFilter from "./TimeRangeAndFilter"
import ChooseEngines from "./ChooseEngines"
import ChooseCaptureSessions from "./ChooseCaptureSessions"
import {
  EngineCaptureSessions,
  EngineConnection,
  SearchContext,
  SelectedCaptureSession,
} from "../types"
import {
  DatabaseRowGetCaptureSessions,
  DistributedForensicSearchRequestItem,
  Engine,
  RequestCreateDistributedForensicSearch,
  ResponseGetCaptureSessions,
  ResponseGetEngineCapabilities,
  ResponsePostLogin,
} from "../../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../../api/types/engineTypes"
import {
  useSearchContext,
  useEngineFilter,
  useSelectedEngines,
  useEngineConnections,
  useCaptureSessions,
  useSelectedCaptureSessions,
  useSearchDispatch,
  onNewSearch,
  setSearchContext,
  setEngineFilter,
  setSelectedEngines,
  setEngineConnections,
  setCaptureSessions,
  setSelectedCaptureSessions,
} from "../Context"

const SERVER_ENGINE_ID = "00000000-0000-0000-0000-000000000000"

type LocationState = {
  name?: string
  startTime?: string
  endTime?: string
  slopTime?: number
  filter?: string
}

type ConnectState = {
  state: "idle" | "login" | "getsessions" | "waitsessions" | "done"
  modalMessage: string
  loginConnection: EngineConnection | null
  loginEngine: Engine | null
  loginError: string
  startTime: Date
  endTime: Date
  slopTime: number
}

const Wizard = () => {
  const history = useHistory()
  const location = useLocation<LocationState>()
  const server = useSelector(getServer)
  const userName = useSelector(getUsername)
  const serverAuthToken = useSelector(getServerAuthToken)
  const serverName = useSelector(getServerName)
  const engines = useSelector(getEngines)
  const serverEngine = React.useRef<Engine>({
    group: "",
    host: "",
    id: SERVER_ENGINE_ID,
    name: serverName || "",
    remoteName: "",
    username: "",
    password: "",
  })

  const dispatch = useSearchDispatch()
  const searchContext = useSearchContext()
  const engineFilter = useEngineFilter()
  const selectedEngines = useSelectedEngines()
  const engineConnections = useEngineConnections()
  const captureSessions = useCaptureSessions()
  const selectedCaptureSessions = useSelectedCaptureSessions()
  const engineCapabilities: ResponseGetEngineCapabilities = useSelector(getCapabilities)
  const [step, setStep] = React.useState("time-range-and-filter")
  const [error, setError] = React.useState("")
  const [connectState, setConnectState] = useSetState<ConnectState>({
    state: "idle",
    modalMessage: "",
    loginConnection: null,
    loginEngine: null,
    loginError: "",
    startTime: new Date(0),
    endTime: new Date(0),
    slopTime: -1,
  })

  useMount(() => {
    if (location.state) {
      const newSearchContext = cloneDeep(searchContext)
      if (isString(location.state.name)) {
        newSearchContext.name = location.state.name
      }
      if (isString(location.state.startTime)) {
        newSearchContext.startTime = new Date(location.state.startTime)
      }
      if (isString(location.state.endTime)) {
        newSearchContext.endTime = new Date(location.state.endTime)
      }
      if (isNumber(location.state.slopTime)) {
        newSearchContext.slopTime = location.state.slopTime
      }
      if (newSearchContext.startTime.toISOString() === newSearchContext.endTime.toISOString()) {
        newSearchContext.endTime.setMilliseconds(newSearchContext.endTime.getMilliseconds() + 1)
      }
      if (isString(location.state.filter)) {
        newSearchContext.filter = location.state.filter
      }
      setSearchContext(dispatch, newSearchContext)
    } else if (location.search) {
      const params = qs.parse(window.location.search, { ignoreQueryPrefix: true })
      const newSearchContext = cloneDeep(searchContext)
      if (isString(params.name)) {
        newSearchContext.name = params.name
      }
      if (isString(params.startTime)) {
        const startTime = new Date(params.startTime)
        if (Number.isFinite(+startTime)) {
          newSearchContext.startTime = startTime
        }
      }
      if (isString(params.endTime)) {
        const endTime = new Date(params.endTime)
        if (Number.isFinite(+endTime)) {
          newSearchContext.endTime = endTime
        }
      }
      if (newSearchContext.startTime.toISOString() === newSearchContext.endTime.toISOString()) {
        newSearchContext.endTime.setMilliseconds(newSearchContext.endTime.getMilliseconds() + 1)
      }
      if (isString(params.slopTime)) {
        const slopTime = parseInt(params.slopTime, 10)
        if (!Number.isNaN(slopTime)) {
          newSearchContext.slopTime = slopTime
        }
      }
      if (isString(params.filter)) {
        newSearchContext.filter = params.filter
      }
      setSearchContext(dispatch, newSearchContext)
      history.push(getNewDistributedForensicSearchUrl()) // Clear query
    }
  })

  const findEngine = React.useCallback(
    (engineId: string) => {
      if (engineId === SERVER_ENGINE_ID) {
        return serverEngine.current
      } else {
        return engines?.find(engine => engine.id === engineId)
      }
    },
    [engines, serverEngine]
  )

  const handleLogin = React.useCallback(
    async (conn: EngineConnection) => {
      const engine = findEngine(conn.engineId)
      if (!engine) return
      try {
        let otp: string | undefined
        let response: Response
        if (conn.clientAuth || (searchContext.sameCreds && searchContext.sameClientAuth)) {
          response = await logInCertificate(conn.engineUrl)
        } else {
          let username = ""
          let password = ""
          if (searchContext.sameCreds) {
            username = searchContext.sameUsername
            password = searchContext.samePassword
          } else if (conn.username.length !== 0 && conn.password.length !== 0) {
            username = conn.username
            password = conn.password
          } else if (engine.username.length !== 0 && engine.password.length !== 0) {
            username = engine.username
            password = await decrypt(engine.username, engine.password)
          } else {
            throw new Error("Missing creds!")
          }
          otp = conn.twoFactor && conn.otp ? conn.otp : undefined
          response = await logIn(conn.engineUrl, {
            username,
            password,
            otp,
          })
        }
        if (response.ok) {
          const authToken: ResponsePostLogin = await response.json()
          const newEngineConnections = cloneDeep(engineConnections)
          const newConn = newEngineConnections.find(item => item.engineId === conn.engineId)
          if (newConn) {
            if (authToken.authAction) {
              setError(
                `The engine \u201c${getEngineDisplayName(
                  engine
                )}\u201d requires two-factor authentication configuration`
              )
              setConnectState({
                state: "idle",
                modalMessage: "",
                loginConnection: null,
                loginEngine: null,
                loginError: "",
              })
            } else {
              newConn.authToken = authToken.authToken
              setEngineConnections(dispatch, newEngineConnections)
              setConnectState({
                modalMessage: "",
                loginConnection: null,
                loginEngine: null,
                loginError: "",
              })
            }
          }
        } else {
          let loginError = ""
          switch (response.status) {
            case 401:
              if (otp !== undefined) {
                loginError = "Invalid code or credentials"
              } else {
                const twoFactor = !!response.headers.get("WWW-Authenticate")?.match(/Token-2FA/i)
                if (twoFactor) {
                  const newEngineConnections = cloneDeep(engineConnections)
                  const newConn = newEngineConnections.find(item => item.engineId === conn.engineId)
                  if (newConn) {
                    newConn.twoFactor = true
                    setEngineConnections(dispatch, newEngineConnections)
                    conn = newConn
                  } else {
                    loginError = "Username or password incorrect"
                  }
                }
              }
              break
            case 502:
            case 503:
              loginError = "Service not available"
              break
            default:
              loginError = "A network error occurred"
              break
          }
          setConnectState({
            modalMessage: "",
            loginConnection: conn,
            loginEngine: engine,
            loginError,
          })
        }
      } catch (error) {
        console.error(error)
        setConnectState({
          modalMessage: "",
          loginConnection: conn,
          loginEngine: engine,
          loginError: "A network error occurred",
        })
      }
    },
    [
      dispatch,
      setConnectState,
      findEngine,
      engineConnections,
      searchContext.sameCreds,
      searchContext.samePassword,
      searchContext.sameUsername,
      searchContext.sameClientAuth,
    ]
  )

  React.useEffect(() => {
    if (step === "engines") {
      switch (connectState.state) {
        case "idle":
          break
        case "login":
          // Ensure there's no operation in progress (aka modal message).
          if (connectState.modalMessage.length === 0 && connectState.loginConnection === null) {
            // Find the next connection without an auth token.
            const conn = engineConnections.find(conn => conn.authToken.length === 0)
            if (conn) {
              const engine = findEngine(conn.engineId)
              if (engine) {
                let username = ""
                let password = ""
                if (!conn.clientAuth) {
                  if (conn.username.length !== 0 && conn.password.length !== 0) {
                    username = conn.username
                    password = conn.password
                  } else {
                    username = engine.username
                    password = engine.password
                  }
                }
                if (
                  conn.clientAuth ||
                  searchContext.sameCreds ||
                  (username.length !== 0 && password.length !== 0)
                ) {
                  // Ready to log in.
                  setConnectState({
                    modalMessage: `Connecting to engine \u2018${getEngineDisplayName(
                      engine
                    )}\u2019`,
                  })
                  handleLogin(conn)
                } else {
                  if (isUsingGroupAuthentication(engine) && isJWT(serverAuthToken)) {
                    const newEngineConnections = cloneDeep(engineConnections)
                    const newConn = newEngineConnections.find(
                      item => item.engineId === conn.engineId
                    )
                    if (newConn) {
                      newConn.authToken = serverAuthToken
                      setEngineConnections(dispatch, newEngineConnections)
                      setConnectState({
                        modalMessage: "",
                        loginConnection: null,
                        loginEngine: null,
                        loginError: "",
                      })
                    }
                  } else {
                    // Need credentials - show a modal.
                    setConnectState({
                      modalMessage: "",
                      loginConnection: conn,
                      loginEngine: engine,
                    })
                  }
                }
              }
            } else {
              setConnectState({ state: "getsessions" })
            }
          }
          break
        case "getsessions":
          {
            setConnectState({
              state: "waitsessions",
              modalMessage: "Retrieving capture sessions...",
            })

            // Request capture sessions from all engines.
            const requestsCaptureSessions = engineConnections.map(conn =>
              fetchCaptureSessions(conn.engineUrl, conn.authToken)
            )
            const requestsCapabilities = engineConnections.map(conn =>
              fetchEngineCapabilities(conn.engineUrl, conn.authToken)
            )
            Promise.all([...requestsCaptureSessions, ...requestsCapabilities])
              .then(results => {
                if (results.length !== engineConnections.length * 2) {
                  throw new Error("Incomplete results")
                }

                const newEngineCaptureSessions: EngineCaptureSessions[] = []
                for (let i = 0; i < engineConnections.length; i++) {
                  const captureSessions = results[i] as ResponseGetCaptureSessions
                  const capabilities = results[
                    engineConnections.length + i
                  ] as ResponseGetEngineCapabilities

                  // make sure the user can perform a distributed forensic search
                  let canSaveFiles = true
                  let canCreateForensicSearch = true
                  if (capabilities) {
                    const policies = capabilities.userRights.policies
                    canSaveFiles = policies.includes(
                      capabilities.capabilities.includes(EngineCapabilities.saveFilesACL)
                        ? EngineUserPolicies.saveFiles
                        : EngineUserPolicies.downloadFiles
                    )
                    if (capabilities.capabilities.includes(EngineCapabilities.forensicSearchACL)) {
                      canCreateForensicSearch = policies.includes(
                        EngineUserPolicies.createForensicSearch
                      )
                    }
                  }

                  // Filter the capture sessions by the search time range.
                  const slopTime = searchContext.slopTime * 1000
                  const searchStartTime = searchContext.startTime.valueOf() - slopTime
                  const searchEndTime = searchContext.endTime.valueOf() + slopTime
                  captureSessions.rows = captureSessions.rows.filter(row => {
                    if (row.SessionID != null && row.StartTimestamp != null) {
                      const startTime = row.StartTimestamp
                      if (typeof startTime !== "string") return false
                      const endTime = row.StopTimestamp != null ? row.StopTimestamp : startTime
                      const startDateTime = Date.parse(startTime)
                      const endDateTime = Date.parse(endTime)

                      if (canSaveFiles && canCreateForensicSearch) {
                        if (!(endDateTime < searchStartTime || searchEndTime < startDateTime)) {
                          return true
                        }
                      }
                    }
                    return false
                  })

                  newEngineCaptureSessions.push({
                    engineId: engineConnections[i].engineId,
                    captureSessions,
                  })
                }
                setCaptureSessions(dispatch, newEngineCaptureSessions)

                // Select all capture sessions.
                const newSelectedCaptureSessions: SelectedCaptureSession[] = []
                for (const engineCaptureSession of newEngineCaptureSessions) {
                  for (const row of engineCaptureSession.captureSessions.rows) {
                    if (row.SessionID != null) {
                      newSelectedCaptureSessions.push({
                        engineId: engineCaptureSession.engineId,
                        sessionId: row.SessionID,
                      })
                    }
                  }
                }
                setSelectedCaptureSessions(dispatch, newSelectedCaptureSessions)

                setConnectState({
                  state: "done",
                  modalMessage: "",
                  loginConnection: null,
                  loginEngine: null,
                  loginError: "",
                  startTime: searchContext.startTime,
                  endTime: searchContext.endTime,
                  slopTime: searchContext.slopTime,
                })
              })
              .catch(error => {
                console.error(error)
                setError("Failed to get all capture sessions")
                setConnectState({
                  state: "idle",
                  modalMessage: "",
                  loginConnection: null,
                  loginEngine: null,
                  loginError: "",
                })
              })
          }
          break
        case "waitsessions":
          break
        case "done":
          setConnectState({
            state: "idle",
            modalMessage: "",
            loginConnection: null,
            loginEngine: null,
            loginError: "",
          })
          setStep("capture-sessions")
          break
      }
    }
  }, [
    dispatch,
    setConnectState,
    findEngine,
    handleLogin,
    step,
    connectState,
    engineConnections,
    serverAuthToken,
    searchContext.sameCreds,
    searchContext.startTime,
    searchContext.endTime,
    searchContext.slopTime,
    engineCapabilities,
  ])

  const onLogoutEngines = () => {
    const newEngineConnections = engineConnections.map(conn => {
      if (conn.engineId !== SERVER_ENGINE_ID && conn.authToken.length !== 0) {
        logOut(conn.engineUrl, { authToken: conn.authToken })
      }
      const newConn = cloneDeep(conn)
      newConn.authToken = ""
      return newConn
    })
    setEngineConnections(dispatch, newEngineConnections)
  }

  const onConnectEngines = () => {
    let changes = 0
    const newEngineConnections = engineConnections.filter(conn => {
      const engineId = selectedEngines.find(id => id === conn.engineId)
      if (!engineId) {
        // No longer selected.
        if (conn.engineId !== SERVER_ENGINE_ID && conn.authToken.length !== 0) {
          logOut(conn.engineUrl, { authToken: conn.authToken })
        }
        changes++
        return false
      }
      return true
    })
    for (const engineId of selectedEngines) {
      let conn = newEngineConnections.find(conn => conn.engineId === engineId)
      if (!conn) {
        if (engineId === SERVER_ENGINE_ID) {
          conn = {
            engineId,
            engineUrl: server,
            username: userName,
            password: "",
            twoFactor: false,
            otp: "",
            clientAuth: false,
            authToken: serverAuthToken,
          }
        } else {
          conn = {
            engineId,
            engineUrl: `${server}/engines/${engineId}`,
            username: userName,
            password: "",
            twoFactor: false,
            otp: "",
            clientAuth: false,
            authToken: "",
          }
        }
        newEngineConnections.push(conn)
        changes++
      }
    }

    if (
      changes === 0 &&
      newEngineConnections.findIndex(conn => conn.authToken.length === 0) === -1
    ) {
      // No changes to engine connections and all connections have logged in.
      if (
        searchContext.startTime !== connectState.startTime ||
        searchContext.endTime !== connectState.endTime ||
        searchContext.slopTime !== connectState.slopTime
      ) {
        // Times changed. Re-request capture sessions (OD-1906).
        setConnectState({ state: "getsessions" })
      } else {
        // Move to the next step.
        setConnectState({ loginError: "" })
        setStep("capture-sessions")
      }
    } else {
      setConnectState({
        state: "login",
        modalMessage: "",
        loginConnection: null,
        loginEngine: null,
        loginError: "",
      })
      setCaptureSessions(dispatch, [])
      setEngineConnections(dispatch, newEngineConnections)
    }
  }

  const onPrevious = () => {
    if (step === "engines") {
      setStep("time-range-and-filter")
    } else if (step === "capture-sessions") {
      setStep("engines")
    }
  }

  const onNext = () => {
    if (step === "time-range-and-filter") {
      setConnectState({
        state: "idle",
        modalMessage: "",
        loginConnection: null,
        loginEngine: null,
        loginError: "",
      })
      onLogoutEngines()
      setStep("engines")
    } else if (step === "engines") {
      onConnectEngines()
    } else if (step === "capture-sessions") {
      const searches: DistributedForensicSearchRequestItem[] = []
      for (const selectedSession of selectedCaptureSessions) {
        // Find the connection for this engine.
        const connection = engineConnections.find(
          conn => conn.engineId === selectedSession.engineId
        )

        // Find the capture session.
        let captureSession: DatabaseRowGetCaptureSessions | undefined = undefined
        const engineCaptureSessions = captureSessions.find(
          sessions => sessions.engineId === selectedSession.engineId
        )
        if (engineCaptureSessions) {
          captureSession = engineCaptureSessions.captureSessions.rows.find(
            sess => sess.SessionID === selectedSession.sessionId
          )
        }

        if (
          connection &&
          captureSession &&
          captureSession.Name != null &&
          captureSession.MediaType != null &&
          captureSession.MediaSubType != null
        ) {
          const engine = findEngine(connection.engineId)
          if (engine) {
            const engineName = getEngineDisplayName(engine)
            const engineUrl =
              connection.engineId === SERVER_ENGINE_ID
                ? "https://127.0.0.1"
                : `https://127.0.0.1/engines/${connection.engineId}`
            const sessionName = removeFileExtension(captureSession.Name)

            searches.push({
              name: `${engineName} - ${sessionName}`,
              engine: engine.host,
              engineUrl: engineUrl,
              engineAuthToken: connection.authToken,
              captureSessionId: selectedSession.sessionId,
              captureSessionMediaType: captureSession.MediaType,
              captureSessionMediaSubType: captureSession.MediaSubType,
              filePath: `${engineName}_${sessionName}.pkt`,
            })
          }
        }
      }
      const query: RequestCreateDistributedForensicSearch = {
        name: searchContext.name,
        startTime: searchContext.startTime.toISOString(),
        endTime: searchContext.endTime.toISOString(),
        slopTime: searchContext.slopTime,
        filter: searchContext.filter,
        merge: searchContext.merge && searches.length > 1,
        searches,
      }
      createDistributedForensicSearch(server, serverAuthToken, query)
        .then(() => {
          onNewSearch(dispatch)
          history.push(getDistributedForensicSearchesUrl())
        })
        .catch(error => console.error(error))
    }
  }

  const onCancel = () => {
    history.push(getDistributedForensicSearchesUrl())
  }

  // make sure the user can perform a distributed forensic search
  if (engineCapabilities) {
    const policies = engineCapabilities.userRights.policies
    const canCreateForensicSearch =
      !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL) ||
      policies.includes(EngineUserPolicies.createForensicSearch)
    if (!canCreateForensicSearch || !policies.includes(EngineUserPolicies.uploadFiles)) {
      return <Redirect to={`${getDistributedForensicSearchesUrl()}`} />
    }
  }

  return (
    <View padding={false}>
      <Helmet title="New Distributed Forensic Search" />
      <BreadcrumbItem
        to={getDistributedForensicSearchesUrl()}
        title="Distributed Forensic Searches"
      />
      <BreadcrumbItem
        to={getNewDistributedForensicSearchUrl()}
        title="New Distributed Forensic Search"
      />
      <WizardViewContent maxWidth="960px">
        {step === "time-range-and-filter" && (
          <TimeRangeAndFilter
            onPrevious={onPrevious}
            onNext={onNext}
            onCancel={onCancel}
            searchContext={searchContext}
            setSearchContext={(searchContext: SearchContext) =>
              setSearchContext(dispatch, searchContext)
            }
          />
        )}

        {step === "engines" && engines !== null && (
          <ChooseEngines
            onPrevious={onPrevious}
            onNext={onNext}
            onCancel={onCancel}
            engines={engines}
            serverEngine={serverEngine.current}
            engineFilter={engineFilter}
            setEngineFilter={(filter: string) => setEngineFilter(dispatch, filter)}
            selectedEngines={selectedEngines}
            setSelectedEngines={(selectedEngines: string[]) =>
              setSelectedEngines(dispatch, selectedEngines)
            }
            searchContext={searchContext}
            setSearchContext={(searchContext: SearchContext) =>
              setSearchContext(dispatch, searchContext)
            }
            error={error}
          />
        )}

        {step === "capture-sessions" && engines !== null && (
          <ChooseCaptureSessions
            onPrevious={onPrevious}
            onNext={onNext}
            onCancel={onCancel}
            engines={engines}
            captureSessions={captureSessions}
            selectedCaptureSessions={selectedCaptureSessions}
            setSelectedCaptureSessions={(selectedCaptureSessions: SelectedCaptureSession[]) =>
              setSelectedCaptureSessions(dispatch, selectedCaptureSessions)
            }
            searchContext={searchContext}
            setSearchContext={(searchContext: SearchContext) =>
              setSearchContext(dispatch, searchContext)
            }
          />
        )}
      </WizardViewContent>
      {connectState.modalMessage.length !== 0 && (
        <Modal isOpen={true}>
          <ModalBody>{connectState.modalMessage}</ModalBody>
        </Modal>
      )}
      {connectState.loginConnection !== null && connectState.loginEngine !== null ? (
        <LoginModal
          username={connectState.loginConnection.username || connectState.loginEngine.username}
          password={connectState.loginConnection.password}
          twoFactor={connectState.loginConnection.twoFactor}
          title="Login"
          subTitle={
            <div style={{ marginBottom: "1rem" }}>
              <h6 style={{ marginBottom: 0, fontSize: "14px" }}>
                {getEngineDisplayName(connectState.loginEngine)}
              </h6>
              <MutedText as="small">{connectState.loginEngine.host}</MutedText>
            </div>
          }
          okText={connectState.loginConnection.twoFactor ? "Verify" : "Login"}
          error={connectState.loginError}
          onOK={(username: string, password: string, otp: string, clientAuth: boolean) => {
            if (connectState.loginConnection !== null) {
              const newEngineConnections = cloneDeep(engineConnections)
              const id = connectState.loginConnection.engineId
              const newConn = newEngineConnections.find(item => item.engineId === id)
              if (newConn) {
                newConn.username = username
                newConn.password = password
                newConn.otp = otp
                newConn.clientAuth = clientAuth
                setEngineConnections(dispatch, newEngineConnections)
                setConnectState({ loginConnection: null })
              }
            }
          }}
          onCancel={() => {
            if (connectState.loginConnection !== null) {
              const newEngineConnections = cloneDeep(engineConnections)
              const id = connectState.loginConnection.engineId
              const newConn = newEngineConnections.find(item => item.engineId === id)
              if (newConn) {
                newConn.username = ""
                newConn.password = ""
                newConn.twoFactor = false
                newConn.otp = ""
                newConn.clientAuth = false
                setEngineConnections(dispatch, newEngineConnections)
              }
            }
            setConnectState({ state: "idle", loginConnection: null })
          }}
        />
      ) : null}
    </View>
  )
}

export default Wizard
