import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { View, ViewContent } from "../common/View"
import Interval from "../common/Interval"
import { Dashboard } from "../Dashboard/Dashboard"
import TrafficHistoryWidget from "../Dashboard/TrafficHistoryWidget"
import CurrentActivityWidget from "../Dashboard/CurrentActivityWidget"
import EventsWidget from "../Dashboard/EventsWidget"
import TopTalkersWidget, { TopTalkersViewType } from "../Dashboard/TopTalkersWidget"
import TopProtocolsWidget from "../Dashboard/TopProtocolsWidget"
import { MediaSpecType } from "../../api/types/mediaTypes"
import { getChartColor } from "../../utils/chartUtils"
import { peekFromDate } from "../../utils/formatUtils"
import {
  getEngine,
  getAuthToken,
  getNamesModificationTime,
  getShowAddressNames,
  getShowPortNames,
  getShowLocalTime,
  getTopTalkersWidgetViewType,
  getCapabilities,
  getUserId,
} from "../../store"
import { fetchCFSStatistics, fetchEvents } from "../../api/api"
import {
  CountryStatistics,
  CountryStatisticsObject,
  ErrorStatistics,
  EventCounts,
  HistoryStatistics,
  NetworkStatistics,
  NodesStatistics,
  NodesStatisticsObject,
  ProtocolsStatistics,
  ProtocolsStatisticsObject,
  RequestGetStatisticsStatistic,
  ResponseGetEngineCapabilities,
  ResponseGetEvents,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { getCaptureForensicSearchUrl } from "../../routes"

type NetworkDashboardViewProps = RouteComponentProps<CaptureRouteParams> &
  CaptureViewProps & {
    engine: string
    authToken: string
    namesModTime?: string
    showAddressNames: boolean
    showPortNames: boolean
    showLocalTime: boolean
    topTalkersViewType: string
    engineCapabilities: ResponseGetEngineCapabilities | null
    userId: string
  }

type NetworkDashboardViewState = {
  history: any | null
  currentActivity: any | null
  eventCounts: EventCounts | null
  topTalkers: any | null
  topProtocols: any | null
}

class NetworkDashboardView extends React.Component<
  NetworkDashboardViewProps,
  NetworkDashboardViewState
> {
  state: NetworkDashboardViewState = {
    history: null,
    currentActivity: null,
    eventCounts: null,
    topTalkers: null,
    topProtocols: null,
  }

  componentDidMount() {
    this.onRefresh()
  }

  componentDidUpdate({
    namesModTime,
    topTalkersViewType,
    showAddressNames,
    showPortNames,
    showLocalTime,
  }: NetworkDashboardViewProps) {
    if (this.props.topTalkersViewType !== topTalkersViewType) {
      this.setState({ topTalkers: null })
      this.onRefreshTopTalkers()
    }
    if (
      this.props.namesModTime !== namesModTime ||
      this.props.showAddressNames !== showAddressNames
    ) {
      this.onRefreshTopTalkers()
    }
    if (this.props.namesModTime !== namesModTime || this.props.showPortNames !== showPortNames) {
      this.onRefreshTopProtocols()
    }
    if (this.props.showLocalTime !== showLocalTime) {
      this.onRefreshHistory()
    }
  }

  onRefresh = () => {
    this.onRefreshHistory()
    this.onRefreshCurrentActivity()
    this.onRefreshEventCounts()
    this.onRefreshTopTalkers()
    this.onRefreshTopProtocols()
  }

  onRefreshHistory = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSStatistics<HistoryStatistics>(
      engine,
      authToken,
      type,
      capId,
      "history" as RequestGetStatisticsStatistic
    )
      .then((history: HistoryStatistics) => {
        if (history && history.history && history.history.length > 0) {
          const historyData = history.history[0]
          if (historyData.samples && historyData.samples.length > 0) {
            this.setState({
              history: {
                startTime: Date.parse(historyData.startTime),
                interval: historyData.interval * 1000,
                data: historyData.samples.map((bytesPerSec: number) => {
                  return { mbps: (bytesPerSec * 8) / 1000000.0 }
                }),
              },
            })
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onRefreshEventCounts = () => {
    const { capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchEvents(engine, authToken, capId)
      .then((events: ResponseGetEvents) => {
        if (events && events.counts) {
          this.setState({ eventCounts: events.counts })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onRefreshCurrentActivity = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    const req1 = fetchCFSStatistics<NetworkStatistics>(
      engine,
      authToken,
      type,
      capId,
      "network" as RequestGetStatisticsStatistic
    )
    const req2 = fetchCFSStatistics<ErrorStatistics>(
      engine,
      authToken,
      type,
      capId,
      "error" as RequestGetStatisticsStatistic
    )
    Promise.all([req1, req2])
      .then(responses => {
        const networkStatistics: NetworkStatistics = responses[0]
        const errorStatistics: ErrorStatistics = responses[1]
        const currentActivity: any = {}
        if (networkStatistics && networkStatistics.network) {
          const network = networkStatistics.network
          if (network.samples && network.samples.length === 2) {
            const perPacketIFG = 8
            const sample1 = network.samples[0]
            const sample1Time = peekFromDate(Date.parse(sample1.time))
            const sample2 = network.samples[1]
            const sample2Time = peekFromDate(Date.parse(sample2.time))
            const duration =
              sample1Time === 0 || sample2Time === 0 ? 1000000000 : sample2Time - sample1Time
            const totalBytes = sample2.totalBytes - sample1.totalBytes
            const totalPackets = sample2.totalPackets - sample1.totalPackets
            currentActivity.mbps =
              ((totalBytes + totalPackets * perPacketIFG) * 8 * 1000) / duration
            currentActivity.pps = (totalPackets * 1000000000) / duration
          }
        }
        if (errorStatistics && errorStatistics.error) {
          currentActivity.eps = errorStatistics.error.lastSampleValue
        }
        this.setState({ currentActivity })
      })
      .catch(error => {
        console.error(error)
      })
  }

  onRefreshTopTalkers = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken, topTalkersViewType } = this.props
    if (topTalkersViewType !== TopTalkersViewType.COUNTRY) {
      fetchCFSStatistics<NodesStatistics>(
        engine,
        authToken,
        type,
        capId,
        "nodes" as RequestGetStatisticsStatistic
      )
        .then((nodes: NodesStatistics) => {
          if (nodes && nodes.nodes) {
            let mediaSpecTypeFilter: (node: NodesStatisticsObject) => boolean = () => false
            switch (topTalkersViewType) {
              case TopTalkersViewType.IP:
                mediaSpecTypeFilter = node =>
                  node.mediaSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
                break
              case TopTalkersViewType.IPV6:
                mediaSpecTypeFilter = node =>
                  node.mediaSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS
                break
              case TopTalkersViewType.PHYSICAL:
                mediaSpecTypeFilter = node =>
                  node.mediaSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS ||
                  node.mediaSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_WIRELESS_ADDRESS
                break
              default:
                break
            }
            const topNodes = nodes.nodes.filter(mediaSpecTypeFilter)
            topNodes.sort((a: NodesStatisticsObject, b: NodesStatisticsObject) => {
              if (a.bytesSent < b.bytesSent) return 1
              if (a.bytesSent > b.bytesSent) return -1
              const nodeA = (this.props.showAddressNames && a.name) || a.node
              const nodeB = (this.props.showAddressNames && b.name) || b.node
              if (nodeA < nodeB) return 1
              if (nodeA > nodeB) return -1
              return 0
            })
            let totalBytesSent = 0
            let otherBytesSent = 0
            for (let i = 0; i < topNodes.length; i++) {
              const talker = topNodes[i]
              totalBytesSent += talker.bytesSent
              if (i >= 9) {
                otherBytesSent += talker.bytesSent
              }
            }
            const data = []
            for (let i = 0; i < topNodes.length && i < 10; i++) {
              const talker = topNodes[i]
              data.push({
                name: (this.props.showAddressNames && talker.name) || talker.node,
                bytesSent: talker.bytesSent,
                totalBytesSent: totalBytesSent,
                pct: (talker.bytesSent * 100) / totalBytesSent,
                color: talker.color != null ? talker.color : getChartColor(i),
              })
            }
            let topTalkers = data
            if (otherBytesSent !== 0) {
              topTalkers = topTalkers.slice(0, 9)
              topTalkers.push({
                name: "Others",
                bytesSent: otherBytesSent,
                totalBytesSent: totalBytesSent,
                pct: (otherBytesSent * 100) / totalBytesSent,
                color: "#808080",
              })
            }
            this.setState({ topTalkers })
          }
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      fetchCFSStatistics<CountryStatistics>(
        engine,
        authToken,
        type,
        capId,
        "country" as RequestGetStatisticsStatistic
      )
        .then((countries: CountryStatistics) => {
          if (countries && countries.countries) {
            const topCountries = countries.countries
            topCountries.sort((a: CountryStatisticsObject, b: CountryStatisticsObject) => {
              if (a.bytesFrom < b.bytesFrom) return 1
              if (a.bytesFrom > b.bytesFrom) return -1
              const nameA = a.name || a.code
              const nameB = b.name || b.code
              if (nameA > nameB) {
                return 1
              } else if (nameA < nameB) {
                return -1
              }
              return 0
            })
            let totalBytesSent = 0
            let otherBytesSent = 0
            for (let i = 0; i < topCountries.length; i++) {
              const talker = topCountries[i]
              totalBytesSent += talker.bytesFrom
              if (i >= 9) {
                otherBytesSent += talker.bytesFrom
              }
            }
            const data = []
            for (let i = 0; i < topCountries.length && i < 10; i++) {
              const talker = topCountries[i]
              data.push({
                name: talker.name,
                bytesSent: talker.bytesFrom,
                totalBytesSent: totalBytesSent,
                pct: (talker.bytesFrom * 100) / totalBytesSent,
                color: talker.code === "" ? "#000" : getChartColor(i),
              })
            }
            let topTalkers = data
            if (otherBytesSent !== 0) {
              topTalkers = topTalkers.slice(0, 9)
              topTalkers.push({
                name: "Others",
                bytesSent: otherBytesSent,
                totalBytesSent: totalBytesSent,
                pct: (otherBytesSent * 100) / totalBytesSent,
                color: "#808080",
              })
            }
            this.setState({ topTalkers })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  onRefreshTopProtocols = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSStatistics<ProtocolsStatistics>(
      engine,
      authToken,
      type,
      capId,
      "protocols" as RequestGetStatisticsStatistic
    )
      .then((protocols: ProtocolsStatistics) => {
        if (protocols && protocols.protocols) {
          const topProtocolsData: ProtocolsStatisticsObject[] = []
          protocols.protocols.forEach((protocol: ProtocolsStatisticsObject) => {
            const existing = topProtocolsData.find(item => item.id === protocol.id)
            if (existing) {
              existing.packets += protocol.packets
              existing.bytes += protocol.bytes
            } else {
              topProtocolsData.push(protocol)
            }
          })
          topProtocolsData.sort((a: ProtocolsStatisticsObject, b: ProtocolsStatisticsObject) => {
            if (a.bytes < b.bytes) return 1
            if (a.bytes > b.bytes) return -1
            const protocolA = (this.props.showPortNames && a.name) || a.protocol
            const protocolB = (this.props.showPortNames && b.name) || b.protocol
            if (protocolA < protocolB) return 1
            if (protocolA > protocolB) return -1
            return 0
          })
          let totalBytes = 0
          let otherBytes = 0
          for (let i = 0; i < topProtocolsData.length; i++) {
            const protocol = topProtocolsData[i]
            totalBytes += protocol.bytes
            if (i >= 9) {
              otherBytes += protocol.bytes
            }
          }
          const data = []
          for (let i = 0; i < topProtocolsData.length && i < 10; i++) {
            const protocol = topProtocolsData[i]
            data.push({
              name: (this.props.showPortNames && protocol.name) || protocol.protocol,
              bytes: protocol.bytes,
              totalBytes: totalBytes,
              pct: (protocol.bytes * 100) / totalBytes,
              color: protocol.color != null ? protocol.color : getChartColor(i),
            })
          }
          let topProtocols = data
          if (otherBytes !== 0) {
            topProtocols = topProtocols.slice(0, 9)
            topProtocols.push({
              name: "Others",
              bytes: otherBytes,
              totalBytes: totalBytes,
              pct: (otherBytes * 100) / totalBytes,
              color: "#808080",
            })
          }
          this.setState({ topProtocols })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  render() {
    const { captureProperties, engineCapabilities, userId } = this.props
    const { history, topTalkers, topProtocols, currentActivity, eventCounts } = this.state
    const { type, capId } = this.props.match.params

    // make sure the user can view stats for this capture or forensic search
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
      if (!canViewStats) {
        return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
      }
    }

    return (
      <View>
        <BreadcrumbItem to={this.props.match.url} title="Network Dashboard" />
        <Interval timeout={10000} enabled={true} callback={this.onRefresh} />
        <ViewContent>
          <Dashboard>
            <TrafficHistoryWidget history={history} />
            <CurrentActivityWidget currentActivity={currentActivity} />
            <EventsWidget eventCounts={eventCounts} />
            <TopTalkersWidget topTalkers={topTalkers} />
            <TopProtocolsWidget topProtocols={topProtocols} />
          </Dashboard>
        </ViewContent>
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  namesModTime: getNamesModificationTime(state),
  showAddressNames: getShowAddressNames(state),
  showPortNames: getShowPortNames(state),
  showLocalTime: getShowLocalTime(state),
  topTalkersViewType: getTopTalkersWidgetViewType(state) || TopTalkersViewType.IP,
  engineCapabilities: getCapabilities(state) || null,
  userId: getUserId(state),
})

export default connect(mapStateToProps)(NetworkDashboardView)
