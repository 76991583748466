import * as React from "react"
import { connect } from "react-redux"
import cn from "classnames"
import styled, { DefaultTheme, ThemeProvider } from "styled-components"
import FontAwesome from "react-fontawesome"
import { Link, withRouter, RouteComponentProps } from "react-router-dom"
import { IconDropdownToggle, PrimaryButton } from "../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import { ResponseGetEngineCapabilities } from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { getActivateUrl, getEngineTokensUrl } from "../../routes"
import {
  getServer,
  getServerAuthToken,
  getServerUsername,
  getCapabilities,
  getEngineLicenseType,
  getEngineLicenseExpired,
  getEngineLicenseExpirationDate,
  getCurrentTheme,
  getIsNavOpen,
  getShowAddressNames,
  getShowPortNames,
  getShowLocalTime,
} from "../../store"
import { setTheme } from "../../store/theme"
import { serverLogOut } from "../../store/auth"
import {
  setIsNavOpen,
  setShowAddressNames,
  setShowPortNames,
  setShowLocalTime,
} from "../../store/ui"
import darkTheme from "../../themes/darkTheme"
import Moon from "./Moon"

const HeaderStyle = styled.header`
  display: flex;
  align-items: stretch;
  margin-bottom: ${props => props.theme.navMarginBottom};
  color: ${props => props.theme.headerColor};
  background-color: ${props => props.theme.headerBackgroundColor};
  border-radius: ${props => props.theme.navBorderRadius};
  box-shadow: ${props => props.theme.navBoxShadow};
  min-height: 34px;
`

const HeaderButton = styled(PrimaryButton)`
  flex-shrink: 0;
  border-radius: 0;
  box-shadow: none !important;

  & .hamburger-box {
    top: 1px;
  }

  & > * {
    pointer-events: none;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  overflow: hidden;

  & svg {
    pointer-events: none;
  }
`

const LicenseExpirationLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-weight: 500;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`

const CommandStrip = styled.ul`
  display: flex;
  margin: 0 0 0 auto;
  padding: 0;
`

const CommandItem = styled.li`
  list-style: none;
  padding: 2px 8px;

  & .dropdown {
    height: 100%;
  }
`

const HeaderDropdownToggle = styled(IconDropdownToggle)`
  color: ${props => props.theme.headerColor};
  height: 100%;

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &[aria-expanded="true"] {
    color: #fff !important;

    &.dropdown-toggle::after {
      opacity: 1;
    }
  }

  & > .fa {
    opacity: 1;
  }
`

const NightModeItem = styled.div<{ currentThemeName: string }>`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 1rem;
  }

  & path {
    stroke: ${props =>
      props.currentThemeName === "Dark"
        ? props.theme.primaryButtonBackgroundColor
        : "currentColor"};
    fill: ${props =>
      props.currentThemeName === "Dark" ? props.theme.primaryButtonBackgroundColor : "none"};
  }
`

const getProductTypeName = (type: number) => {
  switch (type) {
    case 2:
      return "trial"
    case 3:
      return "subscription"
  }
  return "product"
}

type HeaderProps = RouteComponentProps & {
  dispatch: Function
  server: string
  authToken: string
  username: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  licenseType: number
  licenseExpired: boolean
  licenseExpirationDate: string
  isNavOpen: boolean
  showAddressNames: boolean
  showPortNames: boolean
  showLocalTime: boolean
  theme: DefaultTheme
}

class Header extends React.PureComponent<HeaderProps> {
  onToggleNav = () => {
    this.props.dispatch(setIsNavOpen(!this.props.isNavOpen))
  }

  onToggleShowAddressNames = () => {
    this.props.dispatch(setShowAddressNames(!this.props.showAddressNames))
  }

  onToggleShowPortNames = () => {
    this.props.dispatch(setShowPortNames(!this.props.showPortNames))
  }

  onToggleShowLocalTime = () => {
    this.props.dispatch(setShowLocalTime(!this.props.showLocalTime))
  }

  onToggleNightMode = () => {
    this.props.dispatch(setTheme(this.props.theme.name === "Light" ? "Dark" : "Light"))
  }

  onConfigureTokens = () => {
    this.props.history.push(getEngineTokensUrl())
  }

  onLogout = () => {
    const { server, authToken, dispatch } = this.props
    dispatch(serverLogOut(server, { authToken }))
  }

  render() {
    const {
      username,
      engineCapabilities,
      licenseType,
      licenseExpired,
      licenseExpirationDate,
      isNavOpen,
      showAddressNames,
      showPortNames,
      showLocalTime,
      theme,
    } = this.props

    // Check for expiration in the near future.
    let expirationLink: any | null = null
    if (licenseExpirationDate && !licenseExpired) {
      const now = new Date()
      const expiration = new Date(licenseExpirationDate)
      const dt = Math.max(expiration.getTime() - now.getTime(), 0)
      const days = Math.ceil(dt / (1000 * 3600 * 24))
      if (days <= 30) {
        const product = getProductTypeName(licenseType)
        const units = days === 1 ? "day" : "days"
        expirationLink = (
          <LicenseExpirationLink
            to={getActivateUrl()}
          >{`Your ${product} expires in ${days} ${units}`}</LicenseExpirationLink>
        )
      }
    }

    return (
      <HeaderStyle>
        <HeaderButton
          id="header--sidebar-button"
          aria-label="Toggle navigation sidebar"
          className={cn("hamburger", "hamburger--arrow", { "is-active": isNavOpen })}
          onClick={this.onToggleNav}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </HeaderButton>
        <LogoWrapper>
          <img src={import.meta.env.BASE_URL + "/static/logos/header.svg"} height="27" alt="" />
        </LogoWrapper>
        <ThemeProvider theme={darkTheme}>
          <CommandStrip>
            {expirationLink && <CommandItem>{expirationLink}</CommandItem>}
            <CommandItem>
              <UncontrolledDropdownWithPortal
                dropdownToggle={
                  <HeaderDropdownToggle aria-label="Settings" caret>
                    <FontAwesome name="gear" size="lg" style={{ paddingRight: "0.25rem" }} />
                  </HeaderDropdownToggle>
                }
              >
                <DropdownMenu end>
                  <DropdownItem onClick={this.onToggleShowAddressNames}>
                    <FontAwesome name={showAddressNames ? "check" : "blank"} fixedWidth /> Show
                    Address Names
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.onToggleShowPortNames}>
                    <FontAwesome name={showPortNames ? "check" : "blank"} fixedWidth /> Show Port
                    Names
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.onToggleShowLocalTime}>
                    <FontAwesome name={showLocalTime ? "check" : "blank"} fixedWidth /> Show Local
                    Time
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdownWithPortal>
            </CommandItem>
            <CommandItem>
              <UncontrolledDropdownWithPortal
                dropdownToggle={
                  <HeaderDropdownToggle aria-label="Toggle user account menu" caret>
                    <FontAwesome name="user" size="lg" style={{ paddingRight: "0.5rem" }} />
                    <span style={{ paddingRight: "0.25rem" }}>{username}</span>
                  </HeaderDropdownToggle>
                }
              >
                <DropdownMenu end>
                  <DropdownItem onClick={this.onToggleNightMode}>
                    <NightModeItem currentThemeName={theme.name}>
                      <span>Night Mode</span>
                      <Moon height={13} />
                    </NightModeItem>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    tag="a"
                    href="https://docs.liveaction.com/"
                    target="_blank"
                    rel="noopener"
                  >
                    Documentation
                  </DropdownItem>
                  <DropdownItem divider />
                  {engineCapabilities?.userRights.policies.includes(
                    engineCapabilities?.capabilities.includes(
                      EngineCapabilities.configureAPITokensACL
                    )
                      ? EngineUserPolicies.configureAPITokens
                      : EngineUserPolicies.configureEngine
                  ) && (
                    <>
                      <DropdownItem onClick={this.onConfigureTokens}>API Tokens</DropdownItem>
                      <DropdownItem divider />
                    </>
                  )}
                  <DropdownItem tag="a" href="/docs/" target="_blank" rel="noopener">
                    API Documentation
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.onLogout}>Log Out</DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdownWithPortal>
            </CommandItem>
          </CommandStrip>
        </ThemeProvider>
      </HeaderStyle>
    )
  }
}

const mapStateToProps = (state: object) => ({
  server: getServer(state),
  authToken: getServerAuthToken(state),
  engineCapabilities: getCapabilities(state) || null,
  username: getServerUsername(state) || "",
  licenseType: getEngineLicenseType(state),
  licenseExpired: getEngineLicenseExpired(state),
  licenseExpirationDate: getEngineLicenseExpirationDate(state),
  isNavOpen: getIsNavOpen(state),
  showAddressNames: getShowAddressNames(state),
  showPortNames: getShowPortNames(state),
  showLocalTime: getShowLocalTime(state),
  theme: getCurrentTheme(state),
})

export default withRouter(connect(mapStateToProps)(Header))
