import { default as ReactToggle } from "react-toggle"
import styled from "styled-components"
import "react-toggle/style.css"

const Toggle = styled(ReactToggle)`
  &.react-toggle-track {
    background-color: ${props => props.theme.secondaryButtonBackgroundColor};
  }

  &.react-toggle--checked .react-toggle-track {
    background-color: ${props => props.theme.primaryButtonBackgroundColor};
  }

  &.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: ${props => props.theme.primaryButtonHoverBackgroundColor};
  }

  &.react-toggle--checked .react-toggle-thumb {
    border-color: ${props => props.theme.primaryButtonBackgroundColor};
  }
`

export default Toggle
