import * as React from "react"
import { Col, Collapse, FormGroup } from "reactstrap"
import { TableCellProps, TableRowProps } from "react-virtualized"
import { cloneDeep, isEqual, toNumber } from "lodash"
import cn from "classnames"
import styled from "styled-components"
import { v4 as uuid } from "uuid"
import { OptionsLabel } from "./index"
import { LightButton } from "../common/Buttons"
import {
  ButtonBar,
  CheckGroup,
  HorizontalFormGroup,
  Label,
  SubSection,
  SubSectionLabel,
} from "../common/Form"
import { Input } from "../common/Input"
import { OmniTable } from "../common/OmniTable"
import { Select } from "../common/Select"
import { ViewContent } from "../common/View"
import {
  AdapterConfiguration,
  Channel,
  Channels,
  ChannelScanningSettings,
  HardwareOptions,
  WepKey,
  WepKeySet,
  WirelessHardwareOptions,
} from "../../api/types"
import { PeekWEPAlgorithm, PeekWirelessHardwareConfig } from "../../api/types/peekTypes"
import {
  formatWirelessChannel,
  scanfWirelessChannel,
  setupWirelessChannel,
} from "../../utils/channelUtils"
import { hexDecode, hexEncode } from "../../utils/encodeHex"
import { sortChannelStrings } from "../../utils/sortUtils"
import { MediaType, MediaSubType } from "../../api/types/mediaTypes"

export const KeySetHeader = styled.span`
  padding-left: 12px;
`

const columnDesc = [
  {
    dataKey: "channel",
    label: "Channel",
    width: 250,
    flexGrow: 1,
  },
  {
    dataKey: "duration",
    label: "Duration (msec)",
    width: 150,
    flexGrow: 2,
  },
]

const defaultChannel: Channel = setupWirelessChannel(
  MediaType.MEDIA_TYPE_802_3,
  MediaSubType.MEDIA_SUBTYPE_802_11_B,
  1
)

const hardwareConfigWireless: WirelessHardwareOptions = {
  band: defaultChannel.band,
  bssid: "",
  channel: defaultChannel.channel,
  channelScanningSettings: [],
  clsid: "AE727C8B-0531-45C3-A97A-0E329166176C",
  color: "#000000",
  comment: "",
  config: PeekWirelessHardwareConfig.PEEK_WIRELESS_HARDWARE_CONFIG_CHANNEL,
  created: "",
  essid: "",
  frequency: defaultChannel.frequency,
  id: "",
  keySet: undefined,
  modified: "",
  name: "",
}

const defaultKeySet: WepKeySet = {
  algorithm: PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_WPA_PASSPHRASE_SHARED_KEY,
  keys: [
    { bitLength: 0, data: "" },
    { bitLength: 0, data: "" },
    { bitLength: 0, data: "" },
    { bitLength: 0, data: "" },
  ],
  name: "",
  userBits: 0,
}

type ChannelScanningOption = {
  enabled: boolean
  duration: number
  channel: string
  key: string
}

type WirelessFormProps = {
  adapterConfig: AdapterConfiguration
  channels: Channels | undefined
  hardwareOption: WirelessHardwareOptions | undefined
  setAdapterConfig: (adapterConfig: AdapterConfiguration) => void
  setValidKeySet: (validKeySet: boolean) => void
  updateHardwareOptions: (hardwareOptions: HardwareOptions[], removeIds: string[]) => void
}

type WirelessFormState = {
  hideTyping: boolean
  invalidKeys: boolean[]
}

export class WirelessForm extends React.Component<WirelessFormProps, WirelessFormState> {
  state: WirelessFormState = {
    hideTyping: true,
    invalidKeys: [false, false, false, false],
  }

  componentDidMount() {
    const hardwareOption =
      this.props.hardwareOption !== undefined
        ? cloneDeep(this.props.hardwareOption)
        : { ...hardwareConfigWireless, id: uuid().toUpperCase() }

    // if no channel scanning settings, setup default
    if (hardwareOption.channelScanningSettings.length === 0) {
      const { channels } = this.props
      if (channels !== undefined && Array.isArray(channels.wirelessChannels)) {
        hardwareOption.channelScanningSettings = channels.wirelessChannels.map(
          (channel: Channel) => {
            return {
              band: channel.band,
              channel: channel.channel,
              frequency: channel.frequency,
              duration: 500,
              enabled:
                channel.channel === 1 ||
                channel.channel === 6 ||
                channel.channel === 11 ||
                channel.channel > 14,
            }
          }
        )
      }
    }
    this.props.updateHardwareOptions([hardwareOption], [])
    if (hardwareOption.keySet !== undefined) {
      this.validateKeys(hardwareOption.keySet.keys, hardwareOption.keySet.algorithm)
    }
  }

  shouldComponentUpdate(
    { adapterConfig, hardwareOption }: WirelessFormProps,
    { hideTyping, invalidKeys }: WirelessFormState
  ) {
    return (
      !isEqual(this.props.adapterConfig, adapterConfig) ||
      !isEqual(this.props.hardwareOption, hardwareOption) ||
      this.state.hideTyping !== hideTyping ||
      !isEqual(this.state.invalidKeys, invalidKeys)
    )
  }

  onChangeChannelNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { hardwareOption } = this.props
    if (hardwareOption) {
      const { value } = event.target
      const channel = scanfWirelessChannel(value)
      this.props.updateHardwareOptions(
        [
          {
            ...hardwareOption,
            band: channel.band,
            channel: channel.channel,
            frequency: channel.frequency,
          },
        ],
        []
      )
    }
  }

  onChangeHideTyping = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    this.setState({ hideTyping: checked })
  }

  onChangeKeySetAlgorithm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { hardwareOption } = this.props
    if (hardwareOption && hardwareOption.keySet !== undefined) {
      const value = toNumber(event.target.value)
      const keys = cloneDeep(defaultKeySet.keys)
      this.props.updateHardwareOptions(
        [
          {
            ...hardwareOption,
            keySet: {
              ...hardwareOption.keySet,
              algorithm: value,
              keys,
            },
          },
        ],
        []
      )
      this.validateKeys(keys, value)
    }
  }

  onChangeKeySetKey = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { hardwareOption } = this.props
    if (
      hardwareOption &&
      hardwareOption.keySet !== undefined &&
      index < hardwareOption.keySet.keys.length
    ) {
      const { value } = event.target
      const keys = cloneDeep(hardwareOption.keySet.keys)
      if (
        hardwareOption.keySet.algorithm ===
        PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_WPA_PASSPHRASE_SHARED_KEY
      ) {
        const encodedValue = hexEncode(value)
        keys[index].bitLength = encodedValue.length * 8
        keys[index].data = encodedValue
      } else {
        keys[index].bitLength = value.length * 8
        keys[index].data = value
      }
      this.props.updateHardwareOptions(
        [
          {
            ...hardwareOption,
            keySet: {
              ...hardwareOption.keySet,
              keys,
            },
          },
        ],
        []
      )

      this.validateKeys(keys, hardwareOption.keySet.algorithm)
    }
  }

  onChangeKeySetName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { hardwareOption } = this.props
    if (hardwareOption && hardwareOption.keySet !== undefined) {
      const { value } = event.target
      this.props.updateHardwareOptions(
        [
          {
            ...hardwareOption,
            keySet: {
              ...hardwareOption.keySet,
              name: value,
            },
          },
        ],
        []
      )
    }
  }

  onChangeSelectChannelBy = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { hardwareOption } = this.props
    if (hardwareOption) {
      const { id } = event.target
      this.props.updateHardwareOptions(
        [
          {
            ...hardwareOption,
            config:
              id === "channelNumber"
                ? PeekWirelessHardwareConfig.PEEK_WIRELESS_HARDWARE_CONFIG_CHANNEL
                : PeekWirelessHardwareConfig.PEEK_WIRELESS_HARDWARE_CONFIG_SCAN,
          },
        ],
        []
      )
    }
  }

  onCheckChangeKeySet = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.hardwareOption) {
      const { checked } = event.target
      const hardwareOption = cloneDeep(this.props.hardwareOption)
      if (checked) {
        hardwareOption.keySet = cloneDeep(defaultKeySet)
      } else {
        delete hardwareOption.keySet
      }
      this.props.updateHardwareOptions([hardwareOption], [])
    }
  }

  onCheckChannelScanSetting = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    const { hardwareOption } = this.props
    if (hardwareOption) {
      const { checked, name } = event.target
      const channel = scanfWirelessChannel(name)

      const channelScanningSettings = cloneDeep(hardwareOption.channelScanningSettings)
      const index = channelScanningSettings.findIndex(
        (css: ChannelScanningSettings) =>
          css.band === channel.band &&
          css.channel === channel.channel &&
          css.frequency === channel.frequency
      )
      if (index !== -1) {
        channelScanningSettings[index].enabled = checked
        this.props.updateHardwareOptions(
          [
            {
              ...hardwareOption,
              channelScanningSettings,
            },
          ],
          []
        )
      }
    }
  }

  onDisableAllScanningOptions = () => {
    const { hardwareOption } = this.props
    if (hardwareOption) {
      const channelScanningSettings = cloneDeep(hardwareOption.channelScanningSettings).map(
        (css: ChannelScanningSettings) => {
          return {
            ...css,
            enabled: false,
          }
        }
      )
      this.props.updateHardwareOptions(
        [
          {
            ...hardwareOption,
            channelScanningSettings,
          },
        ],
        []
      )
    }
  }

  onEnableAllScanningOptions = () => {
    const { hardwareOption } = this.props
    if (hardwareOption) {
      const channelScanningSettings = cloneDeep(hardwareOption.channelScanningSettings).map(
        (css: ChannelScanningSettings) => {
          return {
            ...css,
            enabled: true,
          }
        }
      )
      this.props.updateHardwareOptions(
        [
          {
            ...hardwareOption,
            channelScanningSettings,
          },
        ],
        []
      )
    }
  }

  validateKeys = (keys: WepKey[], algorithm: number) => {
    const invalidKeys: boolean[] = [false, false, false, false]
    let notEmpty: boolean = false
    for (let i = 0; i < Math.min(4, keys.length); ++i) {
      switch (algorithm) {
        case PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_40B_SHARED_KEY:
          if (keys[i].data.length > 0) {
            const hexCheck = new RegExp(/^[0-9A-Fa-f]{10}$/g)
            invalidKeys[i] = !hexCheck.test(keys[i].data)
          }
          notEmpty = notEmpty || keys[i].data.length !== 0
          break
        case PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_128B_SHARED_KEY:
          if (keys[i].data.length > 0) {
            const hexCheck = new RegExp(/^[0-9A-Fa-f]{26}$/g)
            invalidKeys[i] = !hexCheck.test(keys[i].data)
          }
          notEmpty = notEmpty || keys[i].data.length !== 0
          break
        case PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_152B_SHARED_KEY:
          if (keys[i].data.length > 0) {
            const hexCheck = new RegExp(/^[0-9A-Fa-f]{32}$/g)
            invalidKeys[i] = !hexCheck.test(keys[i].data)
          }
          notEmpty = notEmpty || keys[i].data.length !== 0
          break
        case PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_USER_DEFINED_SHARED_KEY:
          if (keys[i].data.length > 0) {
            const hexCheck = new RegExp(/^[0-9A-Fa-f]+$/g)
            invalidKeys[i] = !hexCheck.test(keys[i].data) || keys[i].data.length / 2 - 3 > 256
          }
          notEmpty = notEmpty || keys[i].data.length !== 0
          break
        case PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_WPA512B_SHARED_KEY:
          if (i === 0) {
            if (keys[i].data.length > 0) {
              const hexCheck = new RegExp(/^[0-9A-Fa-f]{64}$/g)
              invalidKeys[i] = !hexCheck.test(keys[i].data)
            }
            notEmpty = notEmpty || keys[i].data.length !== 0
          }
          break
        case PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_WPA_PASSPHRASE_SHARED_KEY:
          if (i === 0) {
            const decodedValue = hexDecode(keys[i].data)
            invalidKeys[i] = decodedValue.length < 1 || decodedValue.length > 133
            notEmpty = notEmpty || decodedValue.length !== 0
          } else if (i === 1) {
            const decodedValue = hexDecode(keys[i].data)
            invalidKeys[i] = decodedValue.length < 1 || decodedValue.length > 32
            notEmpty = notEmpty || decodedValue.length !== 0
          }
          break
      }
    }

    this.setState({ invalidKeys })
    this.props.setValidKeySet(notEmpty && invalidKeys.every((invalidKey: boolean) => !invalidKey))
  }

  cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content = null
    switch (dataKey) {
      case "channel":
        content = (
          <div style={{ display: "flex", alignItems: "center", marginLeft: "1.0em" }}>
            <CheckGroup
              type="checkbox"
              name={rowData.channel}
              id={`scanSetting${rowData.channel}`}
              onChange={this.onCheckChannelScanSetting}
              checked={rowData.enabled}
            >
              {cellData}
            </CheckGroup>
          </div>
        )
        break
      case "duration":
        content = <span>{cellData}</span>
        break
      default:
        break
    }
    return content
  }

  rowClassName = () => {
    // Stripes applied in rowRenderer
    return ""
  }

  onRowClick = ({ rowData }: { rowData: any }) => {}

  rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    if (index % 2 !== 0) {
      className = cn(className, "stripe", "clickable")
    } else {
      className = cn(className, "clickable")
    }
    key = rowData.key

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  render() {
    const { hideTyping, invalidKeys } = this.state
    const { channels, hardwareOption } = this.props
    if (!channels || channels.wirelessChannels === undefined || !hardwareOption) return null

    const channelStrs = channels.wirelessChannels.map((c: Channel) =>
      formatWirelessChannel(c.band, c.channel, c.frequency)
    )
    const channelOptions = channelStrs.map((channelStr: string) => {
      return (
        <option key={channelStr} value={channelStr}>
          {channelStr}
        </option>
      )
    })

    const channelScanningOptions = hardwareOption.channelScanningSettings.map(
      (css: ChannelScanningSettings) => {
        const channelStr = formatWirelessChannel(css.band, css.channel, css.frequency)
        return {
          enabled: css.enabled,
          duration: css.duration,
          channel: channelStr,
          key: channelStr,
        }
      }
    )
    channelScanningOptions.sort((a: ChannelScanningOption, b: ChannelScanningOption) =>
      sortChannelStrings(a.channel, b.channel)
    )

    return (
      <>
        <FormGroup row>
          <OptionsLabel md="3" for="">
            <SubSectionLabel>Select Channel By</SubSectionLabel>
          </OptionsLabel>
          <Col md="7">
            <SubSection>
              <HorizontalFormGroup>
                <CheckGroup
                  type="radio"
                  name="channelNumber"
                  id="channelNumber"
                  aria-label="Number"
                  checked={
                    hardwareOption.config ===
                    PeekWirelessHardwareConfig.PEEK_WIRELESS_HARDWARE_CONFIG_CHANNEL
                  }
                  onChange={this.onChangeSelectChannelBy.bind(this)}
                >
                  Number:
                </CheckGroup>
                <Select
                  name="channelNumberList"
                  id="channelNumberList"
                  value={formatWirelessChannel(
                    hardwareOption.band,
                    hardwareOption.channel,
                    hardwareOption.frequency
                  )}
                  onChange={this.onChangeChannelNumber}
                  disabled={
                    hardwareOption.config ===
                    PeekWirelessHardwareConfig.PEEK_WIRELESS_HARDWARE_CONFIG_SCAN
                  }
                >
                  {channelOptions}
                </Select>
              </HorizontalFormGroup>
              <HorizontalFormGroup>
                <CheckGroup
                  type="radio"
                  name="channelScan"
                  id="channelScan"
                  aria-label="Scan"
                  checked={
                    hardwareOption.config ===
                    PeekWirelessHardwareConfig.PEEK_WIRELESS_HARDWARE_CONFIG_SCAN
                  }
                  onChange={this.onChangeSelectChannelBy.bind(this)}
                >
                  Scan:
                </CheckGroup>
              </HorizontalFormGroup>
              <Collapse
                isOpen={
                  hardwareOption.config ===
                  PeekWirelessHardwareConfig.PEEK_WIRELESS_HARDWARE_CONFIG_SCAN
                }
                style={{ marginBottom: 0 }}
              >
                <HorizontalFormGroup>
                  <ViewContent>
                    <OmniTable
                      data={channelScanningOptions}
                      disabled={
                        hardwareOption.config ===
                        PeekWirelessHardwareConfig.PEEK_WIRELESS_HARDWARE_CONFIG_CHANNEL
                      }
                      rowCount={channelScanningOptions.length}
                      rowHeight={25}
                      columnDesc={columnDesc}
                      cellRenderer={this.cellRenderer}
                      rowClassName={this.rowClassName}
                      rowRenderer={this.rowRenderer}
                      onRowClick={this.onRowClick}
                    />
                  </ViewContent>
                </HorizontalFormGroup>
                <HorizontalFormGroup noMargin>
                  <ButtonBar>
                    <LightButton size="sm" onClick={this.onEnableAllScanningOptions}>
                      Enable All
                    </LightButton>
                    <LightButton size="sm" onClick={this.onDisableAllScanningOptions}>
                      Disable All
                    </LightButton>
                  </ButtonBar>
                </HorizontalFormGroup>
              </Collapse>
            </SubSection>
          </Col>
        </FormGroup>
        <FormGroup row noMargin>
          <OptionsLabel md="3" for="">
            <SubSectionLabel>Encryption</SubSectionLabel>
          </OptionsLabel>
          <Col md="7">
            <SubSection>
              <HorizontalFormGroup>
                <CheckGroup
                  type="checkbox"
                  name="keySet"
                  id="keySet"
                  onChange={this.onCheckChangeKeySet.bind(this)}
                  checked={hardwareOption.keySet !== undefined}
                >
                  Key Set
                </CheckGroup>
              </HorizontalFormGroup>
              {hardwareOption.keySet !== undefined && (
                <Collapse isOpen={hardwareOption.keySet !== undefined}>
                  <HorizontalFormGroup>
                    <Label md="2" for="keySetName">
                      <SubSectionLabel>Name</SubSectionLabel>
                    </Label>
                    <Col md="10">
                      <Input
                        type="text"
                        name="keySetName"
                        id="keySetName"
                        value={hardwareOption.keySet.name}
                        onChange={this.onChangeKeySetName}
                        style={{ width: "inherit" }}
                      />
                    </Col>
                  </HorizontalFormGroup>
                  <HorizontalFormGroup>
                    <Label md="2" for="keySetKeyType">
                      <SubSectionLabel>Key Type</SubSectionLabel>
                    </Label>
                    <Col md="10">
                      <Select
                        name="keySetKeyType"
                        id="keySetKeyType"
                        value={hardwareOption.keySet.algorithm}
                        onChange={this.onChangeKeySetAlgorithm}
                      >
                        <option value={PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_40B_SHARED_KEY}>
                          WEP: 64-bit Shared Key
                        </option>
                        <option value={PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_128B_SHARED_KEY}>
                          WEP: 128-bit Shared Key
                        </option>
                        <option value={PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_152B_SHARED_KEY}>
                          WEP: 152-bit Shared Key
                        </option>
                        <option value={PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_USER_DEFINED_SHARED_KEY}>
                          WEP: User defined length Shared Key
                        </option>
                        <option value={PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_WPA512B_SHARED_KEY}>
                          WPA/WPA2: 256-bit Pre-Shared Key
                        </option>
                        <option
                          value={PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_WPA_PASSPHRASE_SHARED_KEY}
                        >
                          WPA/WPA2: Passphrase
                        </option>
                      </Select>
                    </Col>
                  </HorizontalFormGroup>
                  {(hardwareOption.keySet.algorithm ===
                    PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_40B_SHARED_KEY ||
                    hardwareOption.keySet.algorithm ===
                      PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_128B_SHARED_KEY ||
                    hardwareOption.keySet.algorithm ===
                      PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_152B_SHARED_KEY ||
                    hardwareOption.keySet.algorithm ===
                      PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_USER_DEFINED_SHARED_KEY) &&
                    hardwareOption.keySet.keys.length >= 4 && (
                      <>
                        <HorizontalFormGroup>
                          <Col md={{ size: 10, offset: 2 }}>
                            {hardwareOption.keySet.algorithm ===
                              PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_40B_SHARED_KEY && (
                              <KeySetHeader>10 hex digits (0-9, A-F)</KeySetHeader>
                            )}
                            {hardwareOption.keySet.algorithm ===
                              PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_128B_SHARED_KEY && (
                              <KeySetHeader>26 hex digits (0-9, A-F)</KeySetHeader>
                            )}
                            {hardwareOption.keySet.algorithm ===
                              PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_152B_SHARED_KEY && (
                              <KeySetHeader>32 hex digits (0-9, A-F)</KeySetHeader>
                            )}
                            {hardwareOption.keySet.algorithm ===
                              PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_USER_DEFINED_SHARED_KEY && (
                              <KeySetHeader>User defined length (0-9, A-F)</KeySetHeader>
                            )}
                          </Col>
                        </HorizontalFormGroup>
                        <HorizontalFormGroup>
                          <Label md="2" for="keySetKey1">
                            <SubSectionLabel>Key 1</SubSectionLabel>
                          </Label>
                          <Col md="10">
                            <Input
                              type={hideTyping ? "password" : "text"}
                              name="keySetKey1"
                              id="keySetKey1"
                              value={hardwareOption.keySet.keys[0].data}
                              onChange={this.onChangeKeySetKey.bind(this, 0)}
                              style={{ width: "inherit" }}
                              invalid={invalidKeys[0]}
                            />
                          </Col>
                        </HorizontalFormGroup>
                        <HorizontalFormGroup>
                          <Label md="2" for="keySetKey2">
                            <SubSectionLabel>Key 2</SubSectionLabel>
                          </Label>
                          <Col md="10">
                            <Input
                              type={hideTyping ? "password" : "text"}
                              name="keySetKey2"
                              id="keySetKey2"
                              value={hardwareOption.keySet.keys[1].data}
                              onChange={this.onChangeKeySetKey.bind(this, 1)}
                              style={{ width: "inherit" }}
                              invalid={invalidKeys[1]}
                            />
                          </Col>
                        </HorizontalFormGroup>
                        <HorizontalFormGroup>
                          <Label md="2" for="keySetKey3">
                            <SubSectionLabel>Key 3</SubSectionLabel>
                          </Label>
                          <Col md="10">
                            <Input
                              type={hideTyping ? "password" : "text"}
                              name="keySetKey3"
                              id="keySetKey3"
                              value={hardwareOption.keySet.keys[2].data}
                              onChange={this.onChangeKeySetKey.bind(this, 2)}
                              style={{ width: "inherit" }}
                              invalid={invalidKeys[2]}
                            />
                          </Col>
                        </HorizontalFormGroup>
                        <HorizontalFormGroup>
                          <Label md="2" for="keySetKey4">
                            <SubSectionLabel>Key 4</SubSectionLabel>
                          </Label>
                          <Col md="10">
                            <Input
                              type={hideTyping ? "password" : "text"}
                              name="keySetKey4"
                              id="keySetKey4"
                              value={hardwareOption.keySet.keys[3].data}
                              onChange={this.onChangeKeySetKey.bind(this, 3)}
                              style={{ width: "inherit" }}
                              invalid={invalidKeys[3]}
                            />
                          </Col>
                        </HorizontalFormGroup>
                      </>
                    )}
                  {hardwareOption.keySet.algorithm ===
                    PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_WPA512B_SHARED_KEY &&
                    hardwareOption.keySet.keys.length >= 1 && (
                      <>
                        <HorizontalFormGroup>
                          <Col md={{ size: 10, offset: 2 }}>
                            <KeySetHeader>64 hex digits (0-9, A-F)</KeySetHeader>
                          </Col>
                        </HorizontalFormGroup>
                        <HorizontalFormGroup>
                          <Label md="2" for="keySetKey">
                            <SubSectionLabel>Key</SubSectionLabel>
                          </Label>
                          <Col md="10">
                            <Input
                              type={hideTyping ? "password" : "text"}
                              name="keySetKey"
                              id="keySetKey"
                              value={hardwareOption.keySet.keys[0].data}
                              onChange={this.onChangeKeySetKey.bind(this, 0)}
                              style={{ width: "inherit" }}
                              invalid={invalidKeys[0]}
                            />
                          </Col>
                        </HorizontalFormGroup>
                      </>
                    )}
                  {hardwareOption.keySet.algorithm ===
                    PeekWEPAlgorithm.PEEK_WEP_ALGORITHM_WPA_PASSPHRASE_SHARED_KEY &&
                    hardwareOption.keySet.keys.length >= 2 && (
                      <>
                        <HorizontalFormGroup>
                          <Label md="2" for="keySetPhrase">
                            <SubSectionLabel>Phrase</SubSectionLabel>
                          </Label>
                          <Col md="10">
                            <Input
                              type={hideTyping ? "password" : "text"}
                              name="keySetPhrase"
                              id="keySetPhrase"
                              value={hexDecode(hardwareOption.keySet.keys[0].data)}
                              onChange={this.onChangeKeySetKey.bind(this, 0)}
                              style={{ width: "inherit" }}
                              invalid={invalidKeys[0]}
                            />
                          </Col>
                        </HorizontalFormGroup>
                        <HorizontalFormGroup>
                          <Label md="2" for="keySetSSID">
                            <SubSectionLabel>SSID</SubSectionLabel>
                          </Label>
                          <Col md="10">
                            <Input
                              type={hideTyping ? "password" : "text"}
                              name="keySetSSID"
                              id="keySetSSID"
                              value={hexDecode(hardwareOption.keySet.keys[1].data)}
                              onChange={this.onChangeKeySetKey.bind(this, 1)}
                              style={{ width: "inherit" }}
                              invalid={invalidKeys[1]}
                            />
                          </Col>
                        </HorizontalFormGroup>
                      </>
                    )}
                  <HorizontalFormGroup>
                    <Col md={{ size: 10, offset: 2 }} style={{ paddingLeft: "1.5rem" }}>
                      <CheckGroup
                        type="checkbox"
                        name="hideTyping"
                        id="hideTyping"
                        onChange={this.onChangeHideTyping.bind(this)}
                        checked={hideTyping}
                      >
                        Hide Typing
                      </CheckGroup>
                    </Col>
                  </HorizontalFormGroup>
                </Collapse>
              )}
            </SubSection>
          </Col>
        </FormGroup>
      </>
    )
  }
}
