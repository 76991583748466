import styled from "styled-components"
import { SecondaryButton } from "../common/Buttons"
import { BarGauge } from "../common/BarGauge"
import { Panel } from "../common/Panel"
import { formatInteger } from "../../utils/formatUtils"

const SelectPacketsPanel = styled(Panel)`
  display: flex;
  align-items: center;
  justify-content: stretch;

  & > * + * {
    margin-left: 0.5rem;
  }
`

export const SelectPacketsProgress = ({
  progress,
  onCancel,
}: {
  progress: number
  onCancel: () => void
}) => (
  <SelectPacketsPanel>
    <span>Selecting packets</span>
    <BarGauge
      aria-label="Progress"
      value={progress}
      max={100}
      title={`${formatInteger(progress)}%`}
      style={{ flexGrow: 1 }}
    />
    <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
  </SelectPacketsPanel>
)

export const SelectPacketsResults = ({
  message,
  onPrevSelectedPacket,
  prevSelectedPacketDisabled,
  onNextSelectedPacket,
  nextSelectedPacketDisabled,
  onOpenSelectedPackets,
  openSelectedPacketsDisabled,
  onClose,
}: {
  message: string
  onPrevSelectedPacket: () => void
  prevSelectedPacketDisabled: boolean
  onNextSelectedPacket: () => void
  nextSelectedPacketDisabled: boolean
  onOpenSelectedPackets?: () => void
  openSelectedPacketsDisabled: boolean
  onClose: () => void
}) => (
  <SelectPacketsPanel>
    <span style={{ flexGrow: 1 }}>{message}</span>
    <SecondaryButton disabled={prevSelectedPacketDisabled} onClick={onPrevSelectedPacket}>
      Previous Selected
    </SecondaryButton>
    <SecondaryButton disabled={nextSelectedPacketDisabled} onClick={onNextSelectedPacket}>
      Next Selected
    </SecondaryButton>
    {onOpenSelectedPackets && (
      <SecondaryButton disabled={openSelectedPacketsDisabled} onClick={onOpenSelectedPackets}>
        Open Selected Packets
      </SecondaryButton>
    )}
    <SecondaryButton onClick={onClose}>Close</SecondaryButton>
  </SelectPacketsPanel>
)
