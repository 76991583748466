import * as React from "react"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import {
  Form,
  FormGroup,
  Label as ReactstrapLabel,
  FormFeedback as ReactstrapFormFeedback,
  FormText as ReactstrapFormText,
  Collapse,
  InputProps,
} from "reactstrap"
import { Input } from "../Input"
import { Panel } from "../Panel"

export const FormView = styled(Form)`
  @media (min-width: 768px) {
    & label {
      text-align: right;
    }

    & label.form-check-label {
      text-align: left;
    }
  }
`

export const FormTitle = styled.h4`
  margin-bottom: 2rem;
  text-transform: uppercase;
`

export const FieldSet = styled.fieldset`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  & > .form-group:last-child {
    margin-bottom: 0;
  }
`

export const FieldSetSummary = styled.small`
  margin-left: 0.5rem;
  color: ${props => props.theme.textMutedColor};
  text-transform: none;
`

export const Legend = styled.legend.attrs({
  role: "button",
})`
  border-bottom: 1px solid ${props => props.theme.dividerColor};
  margin-bottom: 1rem;
  cursor: pointer;
  font-weight: 500;

  & > :first-child {
    display: flex;
  }

  & > * > :last-child {
    margin-left: auto;
    color: ${props => props.theme.textMutedColor};
  }
`

export const CollapsibleLegend = ({
  title,
  onClick,
  isOpen,
  getSummary,
}: {
  title: string
  onClick: () => void
  isOpen: boolean
  getSummary?: () => string
}) => {
  const summary = getSummary && !isOpen ? getSummary() : ""
  return (
    <Legend onClick={onClick}>
      <span>
        <span>
          {title}
          {summary ? <FieldSetSummary>{summary}</FieldSetSummary> : null}
        </span>
        <span>
          <FontAwesome name={isOpen ? "angle-down" : "angle-up"} fixedWidth />
        </span>
      </span>
    </Legend>
  )
}

export const CollapsibleSection = styled(Collapse)`
  /*
  padding: 1rem;
  border: ${props => props.theme.panelBorder};
  background: ${props => props.theme.panelBackground};
  border-radius: ${props => props.theme.panelBorderRadius};
  box-shadow: ${props => props.theme.panelBoxShadow};
  */
`

export const SubSection = styled.div`
  padding-left: 1.5rem;
`

export const SubSectionLabel = styled.div`
  padding-left: 1.5rem;

  @media (min-width: 768px) {
    padding-left: 0;
  }
`

export const SubSubSection = styled.div`
  padding-left: 3rem;
`

export const Label = styled(ReactstrapLabel)`
  &:not(.form-check-label) {
    text-transform: uppercase;
    font-weight: bold;
  }
`

export const FormFeedback = styled(ReactstrapFormFeedback)`
  font-size: 83.3333%;

  &.invalid-feedback {
    color: ${props => props.theme.errorColor};
  }

  &.valid-feedback {
    color: ${props => props.theme.successColor};
  }
`

export const FormText = styled(ReactstrapFormText)`
  font-size: 83.3333%;
  color: ${props => props.theme.textMutedColor} !important;
`

export const InlineLabel = ReactstrapLabel

export const CheckboxesLabel = styled(Label)`
  @media (min-width: 768px) {
    padding-top: 0;
  }
`

export const HorizontalFormGroup = styled(FormGroup)`
  display: flex;
  align-items: center;

  & .form-control {
    width: auto;
  }

  & label {
    margin-bottom: 0;
  }

  & > * + * {
    margin-left: 0.5rem;
  }
`

export class CheckGroup extends React.Component<InputProps> {
  render() {
    const { children, style, ...inputProps } = this.props
    return (
      <div className="form-check">
        <Input className="form-check-input" addon={true} {...inputProps} />
        <Label for={inputProps.id} style={style} check>
          {children}
        </Label>
      </div>
    )
  }
}

export const CheckGroupIndeterminate: React.FC<InputProps> = props => {
  const inputRef = React.useRef<HTMLInputElement | null>(null)

  React.useEffect(() => {
    if (inputRef.current) {
      if (value === 0) {
        inputRef.current.checked = false
        inputRef.current.indeterminate = false
      } else if (value === 1) {
        inputRef.current.checked = true
        inputRef.current.indeterminate = false
      } else if (value === 2) {
        inputRef.current.checked = false
        inputRef.current.indeterminate = true
      }
    }
  })

  const { children, value, style, ...inputProps } = props
  return (
    <div className="form-check">
      <Input className="form-check-input" innerRef={inputRef} addon={true} {...inputProps} />
      <Label for={inputProps.id} style={style} check>
        {children}
      </Label>
    </div>
  )
}

const CellCheckGroupStyle = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  & .cell-check-label {
    position: absolute;
    margin: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export const CellCheckGroup: React.FC<React.PropsWithChildren<InputProps>> = ({
  children,
  ...inputProps
}) => (
  <CellCheckGroupStyle className="form-check">
    <Input className="form-check-input" addon={true} {...inputProps} />
    <label htmlFor={inputProps.id} className="cell-check-label" />
  </CellCheckGroupStyle>
)

export const CellCheckGroupIndeterminate: React.FC<React.PropsWithChildren<InputProps>> = props => {
  const inputRef = React.useRef<HTMLInputElement | null>(null)

  React.useEffect(() => {
    if (inputRef.current) {
      if (value === 0) {
        inputRef.current.checked = false
        inputRef.current.indeterminate = false
      } else if (value === 1) {
        inputRef.current.checked = true
        inputRef.current.indeterminate = false
      } else if (value === 2) {
        inputRef.current.checked = false
        inputRef.current.indeterminate = true
      }
    }
  })

  const { children, value, ...inputProps } = props
  return (
    <CellCheckGroupStyle className="form-check">
      <Input className="form-check-input" innerRef={inputRef} addon={true} {...inputProps} />
      <label htmlFor={inputProps.id} className="cell-check-label" />
    </CellCheckGroupStyle>
  )
}

export const ButtonBar = styled.div`
  & > * + * {
    margin-left: 4px;
  }
`

export const FormPanel = styled(Panel)`
  margin: 0.5rem 0 1rem 0;
  padding: 1rem;
`

/*
export const FormPanel = styled.div`
  margin: 0.5rem 0 1rem 0;
  padding-left: 1rem;
  border-left: 3px solid ${props => props.theme.dividerColor};
`
*/
