import { MediaSpecType } from "../api/types/mediaTypes"

export function filterExpressionTypeFromMediaSpecType(mst: MediaSpecType) {
  switch (mst) {
    case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_PROTOCOL:
      return "ethernet.protocol"
    case MediaSpecType.MEDIA_SPEC_TYPE_LSAP:
      return "lsap"
    case MediaSpecType.MEDIA_SPEC_TYPE_SNAP:
      return "snap"
    case MediaSpecType.MEDIA_SPEC_TYPE_LAP:
      return "lap"
    case MediaSpecType.MEDIA_SPEC_TYPE_DDP:
      return "ddp"
    case MediaSpecType.MEDIA_SPEC_TYPE_APPLICATION_ID:
    case MediaSpecType.MEDIA_SPEC_TYPE_APP_ID:
      return "application"
    case MediaSpecType.MEDIA_SPEC_TYPE_PROTOSPEC:
      return "protospec"
    case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS:
      return "ethernet"
    case MediaSpecType.MEDIA_SPEC_TYPE_TOKEN_RING_ADDRESS:
      return "tokenring"
    case MediaSpecType.MEDIA_SPEC_TYPE_LAP_ADDRESS:
      return "lap.address"
    case MediaSpecType.MEDIA_SPEC_TYPE_WIRELESS_ADDRESS:
      return "wireless"
    case MediaSpecType.MEDIA_SPEC_TYPE_APPLETALK_ADDRESS:
      return "appletalk"
    case MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS:
      return "ip"
    case MediaSpecType.MEDIA_SPEC_TYPE_DECNET_ADDRESS:
      return "decnet"
    case MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS:
      return "ipv6"
    case MediaSpecType.MEDIA_SPEC_TYPE_IPX_ADDRESS:
      return "ipx"
    case MediaSpecType.MEDIA_SPEC_TYPE_AT_PORT:
      return "atalk"
    case MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT:
      return "" // This is the default port type
    case MediaSpecType.MEDIA_SPEC_TYPE_NW_PORT:
      return "netware"
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_PPP_PROTOCOL:
      return "wan.ppp"
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_FRAME_RELAY_PROTOCOL:
      return "wan.frame.relay"
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_DLCI_ADDRESS:
      return "wan.dlci"
  }
  return ""
}
