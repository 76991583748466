import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import styled, { ThemeProvider } from "styled-components"
import FontAwesome from "react-fontawesome"
import { Button, Collapse } from "reactstrap"
import { NavLink, useHistory } from "react-router-dom"
import { SecondaryButton, IconButton } from "../common/Buttons"
import { ConfirmationModal } from "../common/ConfirmationModal"
import { IconEngine, IconWorkflow } from "../common/Icons"
import { FilterBox } from "../common/FilterBox"
import DashboardModal from "../MultiEngineDashboard/DashboardModal"
import * as Colors from "../../themes/colorScheme"
import darkTheme from "../../themes/darkTheme"
import { Engine } from "../../api/types"
import { Dashboard } from "../../api/types/dashboardTypes"
import { EngineCapabilities } from "../../api/types/engineTypes"
import {
  getEnginesUrl,
  getDashboardUrl,
  getDistributedForensicSearchesUrl,
  getEngineConfigurationSyncUrl,
  getMultiSegmentAnalysisUrl,
  getEngineHomeUrl,
} from "../../routes"
import {
  getServer,
  getServerAuthToken,
  getServerName,
  getCurrentEngine,
  getCapabilities,
  getEngines,
} from "../../store"
import { setCurrentEngine } from "../../store/engines"
import { setIsNavOpen } from "../../store/ui"
import { collator } from "../../utils/sortUtils"
import { getEngineDisplayName } from "../../utils/engineUtils"
import * as API from "../../api/api"

const NavStyle = styled.nav`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${() => Colors.gray900};
  box-shadow: ${props => props.theme.sidebarBoxShadow};
`

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`

const NavListItem = styled.li`
  padding: 0;
  display: block;
  border-top: 1px solid transparent;
  border-bottom: ${() => `1px solid ${Colors.gray1000}`};
  box-shadow: 0 1px 0 0 rgba(177, 191, 209, 0.15);
`

const NavPanelHeading = styled(Button)`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  padding: 1.5rem 2rem 1.5rem 3rem;

  &:focus,
  &:active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: none;
  }

  &:hover {
    color: #fff;
    background-color: transparent;
    border: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: ${() => Colors.gray1000};
  }

  & > :first-child {
    position: absolute;
    left: 0.75rem;
  }
`

export const NavItemLink = styled(NavLink)`
  color: ${props => props.theme.textMutedColor};
  padding: 1rem 1rem 1rem 3rem;
  flex-grow: 1;
  position: relative;
  font-weight: 500;

  &:hover,
  &.active {
    color: white;
    text-decoration: none;
  }

  &.active::after {
    opacity: 1;
  }

  &::after {
    opacity: 0;
    left: 2rem;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-left-color: ${props => props.theme.primaryButtonBackgroundColor};
    border-width: 4px;
    margin-top: -4px;
    /*transition: opacity 0.4s ease;*/
  }
`

export const NavItemButton = styled(Button)`
  background-color: transparent !important;
  color: ${props => props.theme.textMutedColor};
  border: none;
  text-align: left;
  padding: 1rem 1rem 1rem 3rem;
  flex-grow: 1;
  position: relative;
  font-weight: 500;

  &:focus,
  &:active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: none;
  }

  &:hover,
  &.current {
    color: white;
    text-decoration: none;
  }

  &.current::after {
    opacity: 1;
  }

  &::after {
    opacity: 0;
    left: 2rem;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
    border-left-color: ${props => props.theme.primaryButtonBackgroundColor};
    border-width: 4px;
    margin-top: -4px;
    /*transition: opacity 0.4s ease;*/
  }
`

export const NavGroupButton = styled(Button)`
  background-color: transparent !important;
  color: ${props => props.theme.textColor};
  border: none;
  text-align: left;
  padding: 1rem 1rem 1rem 1.4643rem;
  flex-grow: 1;

  &:focus,
  &:active:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: none;
  }
`

const NavGroupName = styled.span`
  padding-left: 0.25em;
  font-weight: 500;
`

export const NavCommandButton = styled(IconButton)`
  padding: 0.5rem;
  color: ${props => props.theme.textMutedColor};

  &:hover,
  &.active {
    color: white;
  }
`

export const NavSubList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: ${Colors.gray1100};
`

export const NavSubListItem = styled.li`
  list-style: none;
  padding: 0;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.current {
    background-color: ${Colors.gray1200};
  }
`

export const NavSubListItemButtons = styled.div`
  display: flex;
  margin: 1rem;

  & > * + * {
    margin-left: 0.5rem;
  }
`

type EngineNavListProps = {
  engines: Engine[] | null
}

type EngineGroup = {
  name: string
  children: Engine[]
}

type EngineRowData = Engine | EngineGroup

const EnginesNavList = ({ engines }: EngineNavListProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const serverName = useSelector(getServerName)
  const currentEngine = useSelector(getCurrentEngine)
  const [viewFilter, setViewFilter] = React.useState("")
  const [collapsedGroups, setCollapsedGroups] = React.useState<Set<string>>(new Set<string>())

  const getEngines = (): Engine[] | null => {
    if (Array.isArray(engines) && engines.length > 0) {
      const lowerCaseFilter = viewFilter.toLowerCase()
      return engines.filter(engine => {
        return (
          getEngineDisplayName(engine).toLowerCase().indexOf(lowerCaseFilter) !== -1 ||
          engine.host.toLowerCase().indexOf(lowerCaseFilter) !== -1
        )
      })
    }
    return null
  }

  const viewEngines = getEngines()

  const getFlattenedTree = () => {
    if (viewEngines) {
      // Build hierarchical representation
      const groups: EngineGroup[] = []
      viewEngines.forEach(engine => {
        const group = groups.find(group => collator.compare(group.name, engine.group) === 0)
        if (group) {
          group.children.push(engine)
        } else {
          groups.push({
            name: engine.group,
            children: [engine],
          })
        }
      })

      // Sort top level groups by name
      groups.sort((a, b) => {
        let result = 0
        if (a.name === "") {
          result = 1
        } else if (b.name === "") {
          result = -1
        } else {
          result = collator.compare(a.name, b.name)
        }
        return result
      })

      // Sort children
      groups.forEach(group => {
        // Only need to sort if top level or expanded
        if (group.name.length === 0 || !collapsedGroups.has(group.name)) {
          group.children.sort((a: Engine, b: Engine) => {
            let result = collator.compare(getEngineDisplayName(a), getEngineDisplayName(b))
            if (result === 0) {
              result = collator.compare(a.host, b.host)
            }
            return result
          })
        }
      })

      // Flatten the list into rows
      const rows: EngineRowData[] = []
      groups.forEach(group => {
        if (group.name) {
          rows.push(group)
          const collapsed = collapsedGroups.has(group.name)
          if (!collapsed) {
            group.children.forEach((item: any, index: number) => {
              rows.push({ ...item, childIndex: index })
            })
          }
        } else {
          group.children.forEach((item: any, index: number) => {
            rows.push({ ...item, childIndex: index })
          })
        }
      })

      return rows
    }
    return null
  }

  const onExpandCollapse = (group: string) => {
    const newCollapsedGroups = new Set(collapsedGroups)
    if (newCollapsedGroups.has(group)) {
      newCollapsedGroups.delete(group)
    } else {
      newCollapsedGroups.add(group)
    }
    setCollapsedGroups(newCollapsedGroups)
  }

  const flattenedTree = getFlattenedTree()

  let items: React.ReactNode[] = []
  if (Array.isArray(flattenedTree)) {
    items = flattenedTree.map(rowData => {
      if ("children" in rowData) {
        const collapsed = collapsedGroups.has(rowData.name)
        const icon = collapsed ? "chevron-right" : "chevron-down"
        return (
          <NavGroupButton
            key={`@group-${rowData.name}`}
            onClick={() => onExpandCollapse(rowData.name)}
          >
            <FontAwesome name={icon} fixedWidth />
            <NavGroupName>{rowData.name}</NavGroupName>
          </NavGroupButton>
        )
      } else {
        const engine = rowData as Engine
        const isCurrent = currentEngine?.id === engine.id
        return (
          <NavSubListItem key={engine.id} className={isCurrent ? "current" : ""}>
            <NavItemButton
              className={isCurrent ? "current" : ""}
              onClick={() => {
                dispatch(setIsNavOpen(false))
                dispatch(setCurrentEngine(engine.id))
                history.push(getEngineHomeUrl())
              }}
            >
              {getEngineDisplayName(engine)}
            </NavItemButton>
          </NavSubListItem>
        )
      }
    })
  }

  // Add "This Engine"
  const isCurrent = currentEngine == null
  items.unshift(
    <NavSubListItem key="this-engine" className={isCurrent ? "current" : ""}>
      <NavItemButton
        className={isCurrent ? "current" : ""}
        onClick={() => {
          dispatch(setIsNavOpen(false))
          dispatch(setCurrentEngine(null))
          history.push(getEngineHomeUrl())
        }}
      >
        <span style={{ paddingRight: ".5rem" }}>{serverName}</span>
        <FontAwesome name="star" />
      </NavItemButton>
    </NavSubListItem>
  )

  // Add the filter box
  items.unshift(
    <NavSubListItem key="engines-filter">
      <div style={{ padding: "1rem", width: "100%" }}>
        <FilterBox
          id="engines-filter"
          aria-label="Search"
          placeholder="Search"
          onChange={(filter: string) => setViewFilter(filter)}
          value={viewFilter}
        />
      </div>
    </NavSubListItem>
  )

  return (
    <NavSubList style={{ overflowY: "auto", maxHeight: "calc(100vh - 153px)" }}>{items}</NavSubList>
  )
}

type DashboardNavListProps = {
  dashboards: Dashboard[] | null
  onAddDashboard: () => void
  onEditDashboard: (dashboard: Dashboard) => void
  onDeleteDashboard: (dashboard: Dashboard) => void
}

export const DashboardNavList = ({
  dashboards,
  onAddDashboard,
  onEditDashboard,
  onDeleteDashboard,
}: DashboardNavListProps) => {
  const dispatch = useDispatch()
  let items: React.ReactNode[] = []
  if (Array.isArray(dashboards)) {
    items = dashboards.map(dashboard => {
      return (
        <NavSubListItem key={dashboard.id}>
          <NavItemLink
            to={getDashboardUrl(dashboard.id)}
            onClick={() => dispatch(setIsNavOpen(false))}
          >
            {dashboard.name}
          </NavItemLink>
          <NavCommandButton onClick={() => onEditDashboard(dashboard)}>
            <FontAwesome name="pencil" fixedWidth />
          </NavCommandButton>
          <NavCommandButton onClick={() => onDeleteDashboard(dashboard)}>
            <FontAwesome name="trash-o" fixedWidth />
          </NavCommandButton>
        </NavSubListItem>
      )
    })
  }
  items.push(
    <NavSubListItemButtons key="dashboard-buttons">
      <SecondaryButton onClick={onAddDashboard} size="sm">
        <FontAwesome name="plus" fixedWidth /> Add
      </SecondaryButton>
    </NavSubListItemButtons>
  )
  return <NavSubList>{items}</NavSubList>
}

const Nav = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const engines = useSelector(getEngines)
  const engineCapabilities = useSelector(getCapabilities)
  const [currentNav, setCurrentNav] = React.useState("engines")
  const [dashboards, setDashboards] = React.useState<Dashboard[] | null>(null)
  const [currentDashboard, setCurrentDashboard] = React.useState<Dashboard | null>(null)
  const [showDashboardModal, setShowDashboardModal] = React.useState(false)
  const [showDeleteDashboardConfirm, setShowDeleteDashboardConfirm] = React.useState(false)
  const [showDashboards] = React.useState(() => {
    try {
      const showDashboards = localStorage.getItem("showDashboards")
      if (showDashboards) {
        return showDashboards.toLowerCase() === "true"
      }
    } catch {}
    return false
  })

  const refreshDashboards = React.useCallback(() => {
    if (serverAuthToken) {
      API.fetchDashboards(server, serverAuthToken)
        .then(dashboards => {
          if (Array.isArray(dashboards.dashboards)) {
            setDashboards(dashboards.dashboards)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [server, serverAuthToken])

  React.useEffect(() => {
    refreshDashboards()
  }, [refreshDashboards])

  const onAddDashboard = () => {
    setShowDashboardModal(true)
  }

  const onEditDashboard = (dashboard: Dashboard) => {
    setCurrentDashboard(dashboard)
    setShowDashboardModal(true)
  }

  const onEditDashboardOK = (dashboard: Dashboard) => {
    if (serverAuthToken) {
      if (currentDashboard) {
        API.putDashboard(server, serverAuthToken, dashboard)
          .then(() => {
            refreshDashboards()
          })
          .catch(error => {
            console.error(error)
          })
      } else {
        API.postDashboard(server, serverAuthToken, dashboard)
          .then(() => {
            refreshDashboards()
          })
          .catch(error => {
            console.error(error)
          })
      }
    }
    setShowDashboardModal(false)
  }

  const onEditDashboardCancel = () => {
    setCurrentDashboard(null)
    setShowDashboardModal(false)
  }

  const onDeleteDashboard = (dashboard: Dashboard) => {
    setCurrentDashboard(dashboard)
    setShowDeleteDashboardConfirm(true)
  }

  const onDeleteDashboardOK = () => {
    if (serverAuthToken && currentDashboard) {
      API.deleteDashboard(server, serverAuthToken, currentDashboard)
        .then(() => {
          refreshDashboards()
        })
        .catch(error => {
          console.error(error)
        })
    }
    setShowDeleteDashboardConfirm(false)
  }

  const onDeleteDashboardCancel = () => {
    setShowDeleteDashboardConfirm(false)
  }

  const handleNav = (e: React.MouseEvent<HTMLButtonElement>) => {
    setCurrentNav(e.currentTarget.name)
  }

  return (
    <NavStyle>
      <ThemeProvider theme={darkTheme}>
        <NavList>
          <NavListItem>
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <NavPanelHeading active={currentNav === "engines"} name="engines" onClick={handleNav}>
                <IconEngine width={20} height={20} />
                <span>Engines</span>
              </NavPanelHeading>
              <SecondaryButton
                title="Engines List"
                style={{
                  position: "absolute",
                  right: "8px",
                }}
                onClick={() => {
                  dispatch(setIsNavOpen(false))
                  history.push(getEnginesUrl())
                }}
              >
                <FontAwesome name="arrow-right" />
              </SecondaryButton>
            </div>
            <Collapse isOpen={currentNav === "engines"}>
              <EnginesNavList engines={engines} />
            </Collapse>
          </NavListItem>
          <NavListItem hidden={!showDashboards}>
            <NavPanelHeading
              active={currentNav === "dashboards"}
              name="dashboards"
              onClick={handleNav}
            >
              <FontAwesome name="dashboard" fixedWidth size="lg" />
              <span>Dashboards</span>
            </NavPanelHeading>
            <Collapse isOpen={currentNav === "dashboards"}>
              <DashboardNavList
                dashboards={dashboards}
                onAddDashboard={onAddDashboard}
                onEditDashboard={onEditDashboard}
                onDeleteDashboard={onDeleteDashboard}
              />
            </Collapse>
          </NavListItem>
          <NavListItem>
            <NavPanelHeading
              active={currentNav === "workflows"}
              name="workflows"
              onClick={handleNav}
            >
              <IconWorkflow width={20} height={20} />
              <span>Workflows</span>
            </NavPanelHeading>
            <Collapse isOpen={currentNav === "workflows"}>
              <NavSubList>
                <NavSubListItem>
                  <NavItemLink
                    to={getDistributedForensicSearchesUrl()}
                    onClick={() => dispatch(setIsNavOpen(false))}
                  >
                    Distributed Forensic Search
                  </NavItemLink>
                </NavSubListItem>
                <NavSubListItem>
                  <NavItemLink
                    to={getMultiSegmentAnalysisUrl()}
                    onClick={() => dispatch(setIsNavOpen(false))}
                  >
                    Multi-Segment Analysis
                  </NavItemLink>
                </NavSubListItem>
                {engineCapabilities !== null &&
                  engineCapabilities.capabilities.includes(
                    EngineCapabilities.engineConfigurationSync
                  ) && (
                    <NavSubListItem>
                      <NavItemLink
                        to={getEngineConfigurationSyncUrl()}
                        onClick={() => dispatch(setIsNavOpen(false))}
                      >
                        Engine Configuration Sync
                      </NavItemLink>
                    </NavSubListItem>
                  )}
              </NavSubList>
            </Collapse>
          </NavListItem>
        </NavList>
      </ThemeProvider>
      {showDashboardModal && (
        <DashboardModal
          dashboard={currentDashboard}
          onOK={onEditDashboardOK}
          onCancel={onEditDashboardCancel}
        />
      )}
      {showDeleteDashboardConfirm && currentDashboard && (
        <ConfirmationModal
          title="Delete Dashboard"
          message={`Delete the dashboard "${currentDashboard.name}"?`}
          onYes={onDeleteDashboardOK}
          onNo={onDeleteDashboardCancel}
          show={true}
        />
      )}
    </NavStyle>
  )
}

export default Nav
