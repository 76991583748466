import * as React from "react"
import { Col, FormGroup } from "reactstrap"
import { isEqual, toNumber } from "lodash"
import { OptionsLabel } from "./index"
import { HorizontalFormGroup, SubSection, SubSectionLabel } from "../common/Form"
import { Input } from "../common/Input"
import { AdapterConfiguration } from "../../api/types"

const RING_BUFFER_SIZE_MIN: number = 32
const RING_BUFFER_SIZE_MAX: number = 64000

type AdvancedFormProps = {
  adapterConfig: AdapterConfiguration
  setAdapterConfig: (adapterConfig: AdapterConfiguration) => void
}

export class AdvancedForm extends React.Component<AdvancedFormProps> {
  shouldComponentUpdate({ adapterConfig }: AdvancedFormProps) {
    return !isEqual(this.props.adapterConfig, adapterConfig)
  }

  onChangeBufferSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { adapterConfig } = this.props
    const value = toNumber(event.target.value)
    if (value >= RING_BUFFER_SIZE_MIN && value <= RING_BUFFER_SIZE_MAX) {
      this.props.setAdapterConfig({
        ...adapterConfig,
        ringBufferSize: value * 1024,
      })
    }
  }

  render() {
    const { adapterConfig } = this.props
    return (
      <FormGroup row>
        <OptionsLabel md="3" for="advancedBufferSize">
          <SubSectionLabel>Peek Driver Options</SubSectionLabel>
        </OptionsLabel>
        <Col md="7">
          <SubSection>
            <HorizontalFormGroup noMargin>
              <span>Buffer size:</span>
              <Input
                type="number"
                name="advancedBufferSize"
                id="advancedBufferSize"
                aria-label="Buffer Size"
                value={Math.min(
                  RING_BUFFER_SIZE_MAX,
                  Math.max(RING_BUFFER_SIZE_MIN, adapterConfig.ringBufferSize / 1024)
                )}
                onChange={this.onChangeBufferSize.bind(this)}
                min={RING_BUFFER_SIZE_MIN}
                max={RING_BUFFER_SIZE_MAX}
                step={1}
              />
              <span>kilobytes</span>
            </HorizontalFormGroup>
          </SubSection>
        </Col>
      </FormGroup>
    )
  }
}
