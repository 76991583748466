import * as React from "react"
import { connect } from "react-redux"
import { withTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import PercentChart from "../../common/PercentChart"
import { ChartType } from "../../../api/types/chartTypes"
import {
  ThirdWidthWidget,
  WidgetHeader,
  WidgetTitle,
  WidgetOptions,
  WidgetBody,
  NoData,
} from "../Dashboard"
import { IconDropdownToggle } from "../../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../../common/Dropdown"
import {
  setTopApplicationsByFlowsWidgetChartType,
  setTopApplicationsByFlowsWidgetChartScale,
} from "../../../store/ui"
import {
  getTopApplicationsByFlowsWidgetChartType,
  getTopApplicationsByFlowsWidgetChartScale,
} from "../../../store"

type Props = {
  topAppsByFlows: any | null
  chartType: ChartType
  chartScale: "fixed" | "auto"
  theme: any
  setTopApplicationsByFlowsWidgetChartType: (chartType: ChartType) => void
  setTopApplicationsByFlowsWidgetChartScale: (chartScale: string) => void
}

class TopApplicationsByFlowsWidget extends React.Component<Props> {
  onChartType = (e: any) => {
    this.props.setTopApplicationsByFlowsWidgetChartType(e.target.name)
  }

  onChartScale = (e: any) => {
    this.props.setTopApplicationsByFlowsWidgetChartScale(e.target.name)
  }

  render() {
    const { topAppsByFlows, theme, chartType, chartScale } = this.props
    return (
      <ThirdWidthWidget>
        <WidgetHeader>
          <WidgetTitle>Top Applications by Flows</WidgetTitle>
          <WidgetOptions>
            <UncontrolledDropdown>
              <IconDropdownToggle aria-label="Settings">
                <FontAwesome name="gear" style={{ color: theme.textMutedColor }} />
              </IconDropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  name={ChartType.HORIZONTAL_BAR}
                  active={chartType === ChartType.HORIZONTAL_BAR}
                  onClick={this.onChartType}
                >
                  Bar
                </DropdownItem>
                <DropdownItem
                  name={ChartType.COLUMN}
                  active={chartType === ChartType.COLUMN}
                  onClick={this.onChartType}
                >
                  Column
                </DropdownItem>
                <DropdownItem
                  name={ChartType.PIE}
                  active={chartType === ChartType.PIE}
                  onClick={this.onChartType}
                >
                  Pie
                </DropdownItem>
                <DropdownItem
                  name={ChartType.DONUT}
                  active={chartType === ChartType.DONUT}
                  onClick={this.onChartType}
                >
                  Donut
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  name="auto"
                  active={chartScale === "auto"}
                  onClick={this.onChartScale}
                >
                  Auto Scale
                </DropdownItem>
                <DropdownItem
                  name="fixed"
                  active={chartScale === "fixed"}
                  onClick={this.onChartScale}
                >
                  Fixed Scale
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </WidgetOptions>
        </WidgetHeader>
        <WidgetBody minHeight="220px">
          {topAppsByFlows ? (
            <PercentChart
              data={topAppsByFlows}
              height={220}
              chartType={chartType}
              fixedScale={chartScale === "fixed"}
              valueKey="flows"
              totalKey="totalFlows"
              theme={theme}
            />
          ) : (
            <NoData>No Data</NoData>
          )}
        </WidgetBody>
      </ThirdWidthWidget>
    )
  }
}

const mapStateToProps = (state: any) => ({
  chartType: getTopApplicationsByFlowsWidgetChartType(state) || ChartType.HORIZONTAL_BAR,
  chartScale: getTopApplicationsByFlowsWidgetChartScale(state) || "fixed",
})

const mapDisptachToProps = {
  setTopApplicationsByFlowsWidgetChartType,
  setTopApplicationsByFlowsWidgetChartScale,
}

export default withTheme(connect(mapStateToProps, mapDisptachToProps)(TopApplicationsByFlowsWidget))
