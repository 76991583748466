import { toNumber } from "lodash"
import { Channel, MediaType, MediaSubType } from "../api/types"
import { WIRELESS_802_11_BANDS_BG_BAND, Wireless80211Bands } from "../api/types/packetTypes"
import { isWireless } from "./mediaUtils"

export const MAX_CHANNELS = 8

export function formatWirelessChannel(
  band: Wireless80211Bands,
  channel: number,
  frequency: number
): string {
  let wirelessChannel = ""

  const bandStr = getWirelessBandString(band, channel)
  if (is49GHZBroadbandPublicSafetyBand(band)) {
    // TODO: The frequency really needs to be the true frequency so that this code doesn't have
    //       to guess if the frequency has something after the decimal point. This will also
    //       require a driver change.
    if (channel % 10 === 0) {
      wirelessChannel = channel.toString() + " - " + frequency.toString() + " MHz (" + bandStr + ")"
    } else {
      wirelessChannel =
        channel.toString() + " - " + frequency.toString() + ".5 MHz (" + bandStr + ")"
    }
  } else {
    wirelessChannel = channel.toString() + " - " + frequency.toString() + " MHz (" + bandStr + ")"
  }

  return wirelessChannel
}

export function getWirelessBandString(band: Wireless80211Bands, channel: number = 0): string {
  let wirelessBandString = ""

  switch (band) {
    case Wireless80211Bands.WIRELESS_802_11_BANDS_ALL_BANDS:
      wirelessBandString = "all"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_B_BAND:
      wirelessBandString = "b"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_A_BAND:
      wirelessBandString = "a"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_G_BAND:
      wirelessBandString = "bg"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_N_BAND:
      wirelessBandString = "n"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_N_20MHZ_BAND:
      if (channel >= 1 && channel <= 14) {
        wirelessBandString = "bgn"
      } else if (channel >= 30 && channel <= 165) {
        wirelessBandString = "an"
      } else {
        wirelessBandString = "n20"
      }
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_N_40MHZ_BAND:
      wirelessBandString = "n40"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_N_40MHZ_LOW_BAND:
      wirelessBandString = "n40l"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_N_40MHZ_HIGH_BAND:
      wirelessBandString = "n40h"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_A_TURBO_BAND:
      wirelessBandString = "at"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_G_TURBO_BAND:
      wirelessBandString = "gt"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_SUPER_G_BAND:
      wirelessBandString = "sg"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_1MHZ_BAND:
      wirelessBandString = "BPS 1MHz ChBw"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_5MHZ_BAND:
      wirelessBandString = "BPS 5MHz ChBw"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_10MHZ_BAND:
      wirelessBandString = "BPS 10MHz ChBw"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_15MHZ_BAND:
      wirelessBandString = "BPS 15MHz ChBw"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_20MHZ_BAND:
      wirelessBandString = "BPS 20MHz ChBw"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_AC_BW80_PRIMARY_0_BAND:
    case Wireless80211Bands.WIRELESS_802_11_BANDS_AC_BW80_PRIMARY_1_BAND:
    case Wireless80211Bands.WIRELESS_802_11_BANDS_AC_BW80_PRIMARY_2_BAND:
    case Wireless80211Bands.WIRELESS_802_11_BANDS_AC_BW80_PRIMARY_3_BAND:
    case Wireless80211Bands.WIRELESS_802_11_BANDS_AC_NSS_2_BAND_0:
    case Wireless80211Bands.WIRELESS_802_11_BANDS_AC_NSS_2_BAND_1:
    case Wireless80211Bands.WIRELESS_802_11_BANDS_AC_NSS_2_BAND_2:
    case Wireless80211Bands.WIRELESS_802_11_BANDS_AC_NSS_2_BAND_3:
      wirelessBandString = "ac"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_UNKNOWN_5_BAND:
      wirelessBandString = "u5"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_UNKNOWN_6_BAND:
      wirelessBandString = "u6"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_UNKNOWN_7_BAND:
      wirelessBandString = "u7"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_UNKNOWN_8_BAND:
      wirelessBandString = "u8"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_UNKNOWN_9_BAND:
      wirelessBandString = "u9"
      break
    case Wireless80211Bands.WIRELESS_802_11_BANDS_AC_RESERVED:
    case Wireless80211Bands.WIRELESS_802_11_BANDS_BAND:
    default:
      break
  }

  return wirelessBandString
}

export function is49GHZBroadbandPublicSafetyBand(band: Wireless80211Bands) {
  return (
    band === Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_1MHZ_BAND ||
    band === Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_5MHZ_BAND ||
    band === Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_10MHZ_BAND ||
    band === Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_15MHZ_BAND ||
    band === Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_20MHZ_BAND
  )
}

export function scanfWirelessChannel(value: string): Channel {
  const channel: Channel = {
    band: Wireless80211Bands.WIRELESS_802_11_BANDS_ALL_BANDS,
    channel: 0,
    frequency: 0,
  }

  // must be of the form "%d - %d (%3s)" or "%d - %d.5 (%3s)" where spaces are optional
  if (/[0-9]+\s*-\s*[0-9]+(\.5){0,1}\s*MHz\s*\(.*\)/.test(value)) {
    // extract the parts of the channel string
    const matches = value.match(/([0-9]+(\.5){0,1}|\(.*\))/gi)
    if (Array.isArray(matches) && matches.length > 0) {
      channel.channel = toNumber(matches[0])
      if (matches.length > 1) {
        channel.frequency = Math.floor(toNumber(matches[1]))
        if (matches.length > 2 && matches[2].length >= 2) {
          channel.band = stringToBand(matches[2].substr(1, matches[2].length - 2))
        }
      }
    }
  }

  return channel
}

export function setupWirelessChannel(mt: MediaType, mst: MediaSubType, channel: number): Channel {
  const wirelessChannel: Channel = {
    band: Wireless80211Bands.WIRELESS_802_11_BANDS_ALL_BANDS,
    channel,
    frequency: 0,
  }

  // figure out the band and frequency
  if (!isWireless(mt, mst)) {
    return wirelessChannel
  }

  // for AP v5.1.x and below, A and B channels were all exclusive
  // BG: 1 - 14
  // A: 30 - 165
  // A turbo: ???

  // no turbo flags, so assume that the channel is non-turbo
  // calculate the channel frequency with:
  // B: 	2407 + (channel*5)
  //		1 : 2412 MHz;
  //		2 : 2417 MHz;
  //		3 : 2422 MHz; ...
  // A:   5000 + (channel*5)
  //		1 : 5005 MHz;
  //		2 : 5010 MHz;
  //		3 : 5015 MHz; ...

  // TODO: Handle 4.9GHz broadband public safety band which uses some of the same channel numbers as 802.11bg.
  if (channel <= 14) {
    // assume B/G band
    // TODO: Handle 802.11g channel 6 turbo!
    wirelessChannel.band = WIRELESS_802_11_BANDS_BG_BAND
    wirelessChannel.frequency = 2407 + channel * 5
  } else {
    wirelessChannel.band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_BAND
    wirelessChannel.frequency = 5000 + channel * 5

    switch (channel) {
      case 42:
      case 50:
      case 58:
      case 152:
      case 160:
        // these are all 802.11a turbo channels so change the band accordingly
        wirelessChannel.band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_TURBO_BAND
        break

      default:
        // don't change the band for these channels
        break
    }
  }

  return wirelessChannel
}

export function stringToBand(value: string): Wireless80211Bands {
  let band: Wireless80211Bands = Wireless80211Bands.WIRELESS_802_11_BANDS_BAND

  if (value === "all" || value === "802.11(all)") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_ALL_BANDS
  } else if (value === "b" || value === "802.11b") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_B_BAND
  } else if (value === "a" || value === "802.11a") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_BAND
  } else if (value === "bg" || value === "g" || value === "802.11bg" || value === "802.11g") {
    band = WIRELESS_802_11_BANDS_BG_BAND
  } else if (value === "n" || value === "802.11n") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_N_BAND
  } else if (
    value === "n20" ||
    value === "802.11n20" ||
    value === "bgn" ||
    value === "802.11bgn" ||
    value === "an" ||
    value === "802.11an"
  ) {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_N_20MHZ_BAND
  } else if (value === "n40" || value === "802.11n40") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_N_40MHZ_BAND
  } else if (value === "n40l" || value === "802.11n40l") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_N_40MHZ_LOW_BAND
  } else if (value === "n40h" || value === "802.11n40h") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_N_40MHZ_HIGH_BAND
  } else if (value === "at" || value === "802.11at") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_TURBO_BAND
  } else if (value === "gt" || value === "802.11gt") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_G_TURBO_BAND
  } else if (value === "sg" || value === "802.11sg") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_SUPER_G_BAND
  } else if (value === "BPS 1MHz ChBw" || value === "802.11BPS 1MHz ChBw") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_1MHZ_BAND
  } else if (value === "BPS 5MHz ChBw" || value === "802.11BPS 5MHz ChBw") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_5MHZ_BAND
  } else if (value === "BPS 10MHz ChBw" || value === "802.11BPS 10MHz ChBw") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_10MHZ_BAND
  } else if (value === "BPS 15MHz ChBw" || value === "802.11BPS 15MHz ChBw") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_15MHZ_BAND
  } else if (value === "BPS 20MHz ChBw" || value === "802.11BPS 20MHz ChBw") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_20MHZ_BAND
  } else if (value === "s1" || value === "802.11s1") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_1MHZ_BAND
  } else if (value === "s5" || value === "802.11s5") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_5MHZ_BAND
  } else if (value === "s10" || value === "802.11s10") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_10MHZ_BAND
  } else if (value === "s15" || value === "802.11s15") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_15MHZ_BAND
  } else if (value === "s20" || value === "802.11s20") {
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_A_LICENSED_20MHZ_BAND
  } else if (value === "ac" || value === "802.11ac") {
    // there are actually four possibe values, but they all just say "ac"
    band = Wireless80211Bands.WIRELESS_802_11_BANDS_AC_BW80_PRIMARY_0_BAND
  }

  // unknowns:
  if (value.length > 0 && value[0] === "u") {
    value = value.substr(1)
    // 5-9, and end of string
    if (value.length === 1 && value[0] !== "0" && !Number.isNaN(Number(value[0]))) {
      // we start at 1, so -1 off the conversion
      band = Wireless80211Bands.WIRELESS_802_11_BANDS_UNKNOWN_5_BAND + Number(value[0]) - 1
    }
  }

  return band
}
