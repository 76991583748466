import * as React from "react"
import { Col, Collapse, Form, FormGroup } from "reactstrap"
import { SortDirection, SortDirectionType, TableCellProps, TableRowProps } from "react-virtualized"
import cn from "classnames"
import { produce } from "immer"
import { cloneDeep, toNumber } from "lodash"
import { LightButton, PrimaryButton, SecondaryButton } from "../common/Buttons"
import { ButtonBar, CheckGroup, HorizontalFormGroup, Label } from "../common/Form"
import { Input } from "../common/Input"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import { OmniTable } from "../common/OmniTable"
import { Select } from "../common/Select"
import { ForensicSearchGraphSettings, GraphObject } from "../../api/types"
import { sortGraphs } from "../../utils/sortUtils"

enum GraphType {
  GRAPH_TYPE_AUTOMATIC = 0,
  GRAPH_TYPE_CUSTOM = 1,
}

enum GraphIntervalUnit {
  GRAPH_INTERVAL_UNIT_SECONDS = 0,
  GRAPH_INTERVAL_UNIT_MINUTES = 1,
  GRAPH_INTERVAL_UNIT_HOURS = 2,
  GRAPH_INTERVAL_UNIT_DAYS = 3,
}

type GraphOptionsModalProps = {
  graphs: GraphObject[]
  graphSettings: ForensicSearchGraphSettings | undefined
  onOK: (graphSettings: ForensicSearchGraphSettings) => void
  onCancel: () => void
}

type GraphOptionsModalState = {
  graphs: GraphObject[]
  graphType: GraphType
  sampleInterval: number
  sampleIntervalUnit: GraphIntervalUnit
  templates: string[]
  sortBy: string
  sortDirection: SortDirectionType
}

class GraphOptionsModal extends React.Component<GraphOptionsModalProps, GraphOptionsModalState> {
  state: GraphOptionsModalState = {
    graphs: this.props.graphs,
    graphType: GraphType.GRAPH_TYPE_AUTOMATIC,
    sampleInterval: 15,
    sampleIntervalUnit: GraphIntervalUnit.GRAPH_INTERVAL_UNIT_SECONDS,
    templates: [],
    sortBy: "title",
    sortDirection: SortDirection.ASC,
  }

  componentDidMount() {
    const { graphSettings } = this.props
    const { sortBy, sortDirection } = this.state

    // sort the graphs
    const graphs = cloneDeep(this.state.graphs)
    sortGraphs(graphs, sortBy, sortDirection)

    // determine graph settings
    let graphType = GraphType.GRAPH_TYPE_AUTOMATIC
    let sampleInterval = 15
    let sampleIntervalUnit = GraphIntervalUnit.GRAPH_INTERVAL_UNIT_SECONDS
    let templates: string[] = []
    if (graphSettings) {
      graphType =
        graphSettings.sampleInterval !== undefined && graphSettings.sampleInterval !== 0
          ? GraphType.GRAPH_TYPE_CUSTOM
          : GraphType.GRAPH_TYPE_AUTOMATIC
      if (graphSettings.sampleInterval !== undefined && graphSettings.sampleInterval !== 0) {
        sampleInterval = graphSettings.sampleInterval
        if (graphSettings.sampleInterval < 60) {
          sampleIntervalUnit = GraphIntervalUnit.GRAPH_INTERVAL_UNIT_SECONDS
        } else if (graphSettings.sampleInterval < 3600) {
          sampleInterval /= 60
          sampleIntervalUnit = GraphIntervalUnit.GRAPH_INTERVAL_UNIT_MINUTES
        } else if (graphSettings.sampleInterval < 86400) {
          sampleInterval /= 3600
          sampleIntervalUnit = GraphIntervalUnit.GRAPH_INTERVAL_UNIT_HOURS
        } else {
          sampleInterval /= 86400
          sampleIntervalUnit = GraphIntervalUnit.GRAPH_INTERVAL_UNIT_DAYS
        }
      }
      templates = Array.isArray(graphSettings.templates) ? graphSettings.templates : []
    }
    this.setState({
      graphs,
      graphType,
      sampleInterval,
      sampleIntervalUnit,
      templates,
    })
  }

  onChangeGraphType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    this.setState({
      graphType: id === "automatic" ? GraphType.GRAPH_TYPE_AUTOMATIC : GraphType.GRAPH_TYPE_CUSTOM,
    })
  }

  onChangeSampleInterval = (event: React.ChangeEvent<HTMLInputElement>) => {
    const sampleInterval = toNumber(event.target.value)
    if (sampleInterval >= 1 && sampleInterval <= 86400) {
      this.setState({ sampleInterval })
    }
  }

  onChangeSampleIntervalUnit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState({ sampleIntervalUnit: toNumber(value) as GraphIntervalUnit })
  }

  onChangeTemplate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target
    this.setState(
      produce(draft => {
        if (checked) {
          const index = draft.templates.findIndex((id: string) => id === name)
          if (index === -1) {
            draft.templates.push(name)
          }
        } else {
          draft.templates = draft.templates.filter((id: string) => id !== name)
        }
      })
    )
  }

  onEnableAllGraphs = () => {
    const { graphs } = this.state
    this.setState({ templates: graphs.map((graph: GraphObject) => graph.templateId) })
  }

  onDisableAllGraphs = () => {
    this.setState({ templates: [] })
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const { graphType, sampleIntervalUnit, templates } = this.state
    let sampleInterval = this.state.sampleInterval
    switch (sampleIntervalUnit) {
      default:
      case GraphIntervalUnit.GRAPH_INTERVAL_UNIT_SECONDS:
        break
      case GraphIntervalUnit.GRAPH_INTERVAL_UNIT_MINUTES:
        sampleInterval *= 60
        break
      case GraphIntervalUnit.GRAPH_INTERVAL_UNIT_HOURS:
        sampleInterval *= 3600
        break
      case GraphIntervalUnit.GRAPH_INTERVAL_UNIT_DAYS:
        sampleInterval *= 86400
        break
    }
    const graphSettings: ForensicSearchGraphSettings =
      graphType === GraphType.GRAPH_TYPE_AUTOMATIC
        ? {
            sampleInterval: 0,
            templates: [],
          }
        : {
            sampleInterval,
            templates,
          }
    this.props.onOK(graphSettings)
  }

  onSort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
    // Should really be sorting in componentDidUpdate when sortBy/Direction change
    // but since we're sorting in place this is easier and avoid extra renders.
    this.setState(
      produce(draft => {
        sortGraphs(draft.graphs, sortBy, sortDirection)
        draft.sortBy = sortBy
        draft.sortDirection = sortDirection
      })
    )
  }

  onRowClick = ({ rowData }: { rowData: any }) => {}

  rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    className = cn(className, "clickable")
    key = rowData.templateId

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    const { templates } = this.state
    const checked = templates.includes(rowData.templateId)

    let content = null
    switch (dataKey) {
      case "title":
        content = (
          <CheckGroup
            type="checkbox"
            name={rowData.templateId}
            id={`graph-${rowData.templateId}`}
            onChange={this.onChangeTemplate}
            checked={checked}
          >
            {rowData.title}
          </CheckGroup>
        )
        break
      case "description":
        content = <span title={cellData}>{cellData}</span>
        break
      default:
        break
    }
    return content
  }

  render() {
    const {
      graphs,
      graphType,
      sampleInterval,
      sampleIntervalUnit,
      sortBy,
      sortDirection,
      templates,
    } = this.state
    const { onCancel } = this.props

    return (
      <Modal size="lg" isOpen={true}>
        <ModalHeader toggle={onCancel}>Graph Options</ModalHeader>
        <ModalBody>
          <Form id="graphsForm" onSubmit={this.onSubmit} noValidate>
            <FormGroup row>
              <Col md="12">
                <CheckGroup
                  type="radio"
                  name="typeRadios"
                  id="automatic"
                  onChange={this.onChangeGraphType}
                  checked={graphType === GraphType.GRAPH_TYPE_AUTOMATIC}
                >
                  Automatic
                </CheckGroup>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="12">
                <div style={{ paddingLeft: "2rem" }}>
                  All graphs enabled, automatic interval based on duration
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col md="12">
                <CheckGroup
                  type="radio"
                  name="typeRadios"
                  id="custom"
                  onChange={this.onChangeGraphType}
                  checked={graphType === GraphType.GRAPH_TYPE_CUSTOM}
                >
                  Custom
                </CheckGroup>
              </Col>
            </FormGroup>
            <Collapse isOpen={graphType === GraphType.GRAPH_TYPE_CUSTOM}>
              <FormGroup row noMargin>
                <Label md="2" for="graphsInterval">
                  Interval
                </Label>
                <Col md="10">
                  <HorizontalFormGroup>
                    <Input
                      style={{ width: "8rem" }}
                      type="number"
                      name="interval"
                      id="graphsInterval"
                      onChange={this.onChangeSampleInterval}
                      value={sampleInterval}
                      min={1}
                      max={86400}
                      step={1}
                    />
                    <Select
                      name="intervalUnit"
                      id="graphsIntervalUnit"
                      aria-label="Graphs interval units"
                      value={sampleIntervalUnit}
                      onChange={this.onChangeSampleIntervalUnit}
                    >
                      <option value={GraphIntervalUnit.GRAPH_INTERVAL_UNIT_SECONDS}>Seconds</option>
                      <option value={GraphIntervalUnit.GRAPH_INTERVAL_UNIT_MINUTES}>Minutes</option>
                      <option value={GraphIntervalUnit.GRAPH_INTERVAL_UNIT_HOURS}>Hours</option>
                      <option value={GraphIntervalUnit.GRAPH_INTERVAL_UNIT_DAYS}>Days</option>
                    </Select>
                  </HorizontalFormGroup>
                </Col>
              </FormGroup>
              <FormGroup row noMargin>
                <Label md="12">Select Graph Templates</Label>
              </FormGroup>
              <FormGroup row>
                <Col md="12">
                  {templates && (
                    <div style={{ position: "relative", height: "250px" }}>
                      <OmniTable
                        data={graphs}
                        rowCount={graphs.length}
                        rowHeight={25}
                        rowRenderer={this.rowRenderer}
                        cellRenderer={this.cellRenderer}
                        onRowClick={this.onRowClick}
                        columnDesc={[
                          {
                            dataKey: "title",
                            label: "Name",
                            width: 150,
                            flexGrow: 1,
                          },
                          {
                            dataKey: "description",
                            label: "Comment",
                            width: 200,
                            flexGrow: 1,
                          },
                        ]}
                        sort={this.onSort}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                      />
                    </div>
                  )}
                </Col>
              </FormGroup>
              <FormGroup row noMargin>
                <Col md="12">
                  <ButtonBar>
                    <LightButton size="sm" onClick={this.onEnableAllGraphs}>
                      Enable All
                    </LightButton>
                    <LightButton size="sm" onClick={this.onDisableAllGraphs}>
                      Disable All
                    </LightButton>
                  </ButtonBar>
                </Col>
              </FormGroup>
            </Collapse>
          </Form>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton type="submit" form="graphsForm">
            OK
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    )
  }
}

export default GraphOptionsModal
