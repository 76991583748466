import * as React from "react"
import { connect } from "react-redux"
import { debounce } from "lodash"
import { RouteComponentProps } from "react-router-dom"
import styled, { DefaultTheme } from "styled-components"
import { Helmet } from "react-helmet"
import FontAwesome from "react-fontawesome"
import FileSaver from "file-saver"
import {
  AutoSizer,
  Column,
  CellMeasurer,
  CellMeasurerCache,
  TableCellDataGetterParams,
} from "react-virtualized"
import { VirtualTable } from "../common/VirtualTable"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { View, ViewContent, ViewHeader, ViewHeaderTitle, ViewHeaderButtons } from "../common/View"
import { LightButton } from "../common/Buttons"
import { CenterContent } from "../common/Layout"
import { Spinner } from "../common/Spinner"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import ConfirmationModal from "../common/ConfirmationModal"
import FilterBox from "../common/FilterBox"
import { IconInformational, IconMinor, IconMajor, IconSevere } from "../common/Icons"
import {
  getEngine,
  getAuthToken,
  getCurrentTheme,
  getLogTotalCount,
  getShowLocalTime,
} from "../../store"
import { formatInteger, formatISODateTime } from "../../utils/formatUtils"
import csvStringify from "../../utils/csvStringify"
import { deleteEvents, fetchEvents } from "../../api/api"
import { EventMessage, ResponseGetEvents } from "../../api/types"
import { PeekSeverity } from "../../api/types/peekTypes"

const EventsViewCounters = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
`

const EventsViewCounter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & + & {
    margin-left: 1rem;
  }
`

type EventsTableProps = {
  events: ResponseGetEvents
  changeCount: number
  theme: DefaultTheme
  showLocalTime: boolean
}

class EventsTable extends React.Component<EventsTableProps> {
  cache = new CellMeasurerCache({ fixedWidth: true, minHeight: 26, defaultHeight: 26 })
  lastRenderedWidth = 0
  lastChangeCount = 0
  table: any | null = null

  getSearchTermColor = (i: number) =>
    this.props.theme.searchTermColors[i % this.props.theme.searchTermColors.length]

  rowGetter = ({ index }: { index: number }) => {
    const { events } = this.props
    return events.messages[index]
  }

  cellDataGetter = ({ dataKey, rowData }: TableCellDataGetterParams) => {
    if (!rowData) return ""
    return rowData[dataKey]
  }

  cellRenderer = ({
    dataKey,
    cellData,
    rowData,
    columnIndex,
    key,
    parent,
    rowIndex,
    style,
  }: any) => {
    if (cellData === null || cellData === undefined) return ""
    let content
    switch (dataKey) {
      case "severity":
        switch (cellData) {
          case 0:
            content = <IconInformational />
            break
          case 1:
            content = <IconMinor />
            break
          case 2:
            content = <IconMajor />
            break
          case 3:
            content = <IconSevere />
            break
          default:
            break
        }
        break
      case "timestamp":
        content = formatISODateTime(cellData, 0, this.props.showLocalTime)
        break
      case "shortMessage": {
        let msg = cellData
        if (rowData.offsets) {
          const offsets = rowData.offsets.split(" ")
          if (offsets.length && offsets.length % 4 === 0) {
            const parts = []
            let pos = 0
            for (let i = 0, len = offsets.length; i < len; i += 4) {
              const term = parseInt(offsets[i + 1], 10)
              const offset = parseInt(offsets[i + 2], 10)
              const size = parseInt(offsets[i + 3], 10)
              if (offset >= pos) {
                parts.push(
                  <span key={`${rowData.messageId}-${i}-b`}>{msg.slice(pos, offset)}</span>
                )
                if (size > 0) {
                  parts.push(
                    <span
                      key={`${rowData.messageId}-${i}-c`}
                      style={{ backgroundColor: this.getSearchTermColor(term) }}
                    >
                      {msg.slice(offset, offset + size)}
                    </span>
                  )
                }
              }
              pos = offset + size
            }
            if (pos + 1 < msg.length) {
              parts.push(<span key={`${rowData.messageId}-d`}>{msg.slice(pos)}</span>)
            }
            msg = parts
          }
        }
        content = (
          <div style={{ ...style, whiteSpace: "normal", wordBreak: "break-all" }}>{msg}</div>
        )
        break
      }
      default:
        break
    }
    return (
      <CellMeasurer
        key={key}
        parent={parent}
        rowIndex={rowIndex}
        columnIndex={columnIndex}
        cache={this.cache}
      >
        {content}
      </CellMeasurer>
    )
  }

  rowClassName = ({ index }: { index: number }) => {
    if (index >= 0 && index % 2 !== 0) {
      return "stripe"
    }
    return ""
  }

  render() {
    const { events, changeCount } = this.props

    let scrollToIndex: number | undefined = undefined
    if (
      events.messages.length > 0 &&
      this.table &&
      this.table.Grid &&
      this.table.Grid._scrollingContainer
    ) {
      const el = this.table.Grid._scrollingContainer
      if (Math.abs(el.scrollTop - (el.scrollHeight - el.offsetHeight)) < 12) {
        scrollToIndex = events.messages.length - 1
      }
    }

    return (
      <AutoSizer>
        {({ height, width }) => {
          if (this.lastRenderedWidth !== width || this.lastChangeCount !== changeCount) {
            this.lastRenderedWidth = width
            this.lastChangeCount = changeCount
            this.cache.clearAll()
          }
          return (
            <VirtualTable
              ref={(ref: any) => {
                this.table = ref
              }}
              width={width}
              height={height}
              headerHeight={30}
              rowHeight={this.cache.rowHeight}
              rowCount={events.messages.length}
              rowGetter={this.rowGetter}
              deferredMeasurementCache={this.cache}
              rowClassName={this.rowClassName}
              scrollToIndex={scrollToIndex}
            >
              <Column
                dataKey="severity"
                label=""
                width={24}
                flexGrow={0}
                cellDataGetter={this.cellDataGetter}
                cellRenderer={this.cellRenderer}
              />
              <Column
                dataKey="timestamp"
                label="Date/Time"
                width={140}
                flexGrow={0}
                cellDataGetter={this.cellDataGetter}
                cellRenderer={this.cellRenderer}
              />
              <Column
                dataKey="shortMessage"
                label="Event"
                width={width - 154}
                flexGrow={1}
                cellDataGetter={this.cellDataGetter}
                cellRenderer={this.cellRenderer}
              />
            </VirtualTable>
          )
        }}
      </AutoSizer>
    )
  }
}

type EventsViewProps = RouteComponentProps<CaptureRouteParams> &
  CaptureViewProps & {
    dispatch: Function
    engine: string
    authToken: string
    theme: DefaultTheme
    logTotalCount?: number
    showLocalTime: boolean
  }

type EventsViewState = {
  events: ResponseGetEvents | null
  showInformational: boolean
  showMinor: boolean
  showMajor: boolean
  showSevere: boolean
  search: string
  changeCount: number
  showClearEventsConfirm: boolean
}

class EventsView extends React.Component<EventsViewProps, EventsViewState> {
  table: any | null = null

  state: EventsViewState = {
    events: null,
    showInformational: true,
    showMinor: true,
    showMajor: true,
    showSevere: true,
    search: "",
    changeCount: 0,
    showClearEventsConfirm: false,
  }

  componentDidMount() {
    this.onRefresh()
  }

  componentDidUpdate({ logTotalCount }: EventsViewProps) {
    if (this.props.logTotalCount !== logTotalCount) {
      this.onRefresh()
    }
  }

  onRefresh = () => {
    const { search, showInformational, showMajor, showMinor, showSevere } = this.state
    const { engine, authToken } = this.props
    fetchEvents(
      engine,
      authToken,
      this.props.match.params.capId,
      showInformational,
      null,
      showMajor,
      showMinor,
      true,
      null,
      search,
      showSevere,
      null,
      null,
      null,
      null
    )
      .then((events: ResponseGetEvents) => {
        if (events && events.counts) {
          this.setState({ events, changeCount: this.state.changeCount + 1 })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onShowSeverity = (e: any) => {
    const state = this.state as any
    this.setState(
      {
        ...this.state,
        [e.target.id]: !state[e.target.id],
      },
      () => {
        this.onRefresh()
      }
    )
  }

  onRefreshDebounced = debounce(this.onRefresh, 500)

  onFirstEvent = () => {
    const { events } = this.state
    if (events && events.messages.length > 0) {
      this.table.table.scrollToRow(0)
    }
  }

  onLastEvent = () => {
    const { events } = this.state
    if (events && events.messages.length > 0) {
      this.table.table.scrollToRow(events.messages.length - 1)
    }
  }

  onChangeFilter = (search: string) => {
    this.setState({ search }, () => {
      this.onRefreshDebounced()
    })
  }

  onExport = () => {
    let csv = "Severity,Date/Time,Message\n"
    const { events } = this.state
    if (events && events.messages) {
      events.messages.forEach((event: EventMessage) => {
        const row = []
        switch (event.severity) {
          case PeekSeverity.PEEK_SEVERITY_INFORMATIONAL:
            row.push("Informational")
            break
          case PeekSeverity.PEEK_SEVERITY_MINOR:
            row.push("Minor")
            break
          case PeekSeverity.PEEK_SEVERITY_MAJOR:
            row.push("Major")
            break
          case PeekSeverity.PEEK_SEVERITY_SEVERE:
            row.push("Severe")
            break
          default:
            break
        }
        row.push(event.timestamp)
        row.push(csvStringify(event.shortMessage))
        csv += row.join(",") + "\n"
      })
    }

    FileSaver.saveAs(new Blob([csv], { type: "text/plain;charset=utf8" }), "Events.csv")
  }

  onClearEvents = () => {
    this.setState({ showClearEventsConfirm: true })
  }

  onClearEventsCancel = () => {
    this.setState({ showClearEventsConfirm: false })
  }

  onClearEventsOK = () => {
    this.setState({ showClearEventsConfirm: false })

    const { engine, authToken } = this.props
    deleteEvents(engine, authToken, null, this.props.match.params.capId)
      .then(() => {
        this.onRefresh()
      })
      .catch(error => {
        console.error(error)
      })
  }

  render() {
    const { events, changeCount, showClearEventsConfirm } = this.state
    const counts = events
      ? events.counts
      : { informational: 0, minor: 0, major: 0, severe: 0, total: 0 }
    return (
      <View>
        <Helmet title="Events" />
        <BreadcrumbItem to={this.props.match.url} title="Events" />
        <ViewHeader>
          <ViewHeaderTitle title="Events" count={counts.total} />
          <EventsViewCounters>
            <EventsViewCounter>
              <LightButton
                id="showInformational"
                aria-label="Informational"
                active={this.state.showInformational}
                onClick={this.onShowSeverity}
              >
                <IconInformational />
              </LightButton>
              &nbsp;
              {formatInteger(counts.informational)}
              <UncontrolledTooltip placement="top" target="showInformational">
                Informational
              </UncontrolledTooltip>
            </EventsViewCounter>
            <EventsViewCounter>
              <LightButton
                id="showMinor"
                aria-label="Minor"
                active={this.state.showMinor}
                onClick={this.onShowSeverity}
              >
                <IconMinor />
              </LightButton>
              &nbsp;
              {formatInteger(counts.minor)}
              <UncontrolledTooltip placement="top" target="showMinor">
                Minor
              </UncontrolledTooltip>
            </EventsViewCounter>
            <EventsViewCounter>
              <LightButton
                id="showMajor"
                aria-label="Major"
                active={this.state.showMajor}
                onClick={this.onShowSeverity}
              >
                <IconMajor />
              </LightButton>
              &nbsp;
              {formatInteger(counts.major)}
              <UncontrolledTooltip placement="top" target="showMajor">
                Major
              </UncontrolledTooltip>
            </EventsViewCounter>
            <EventsViewCounter>
              <LightButton
                id="showSevere"
                aria-label="Severe"
                active={this.state.showSevere}
                onClick={this.onShowSeverity}
              >
                <IconSevere />
              </LightButton>
              &nbsp;
              {formatInteger(counts.severe)}
              <UncontrolledTooltip placement="top" target="showSevere">
                Severe
              </UncontrolledTooltip>
            </EventsViewCounter>
          </EventsViewCounters>
          <ViewHeaderButtons>
            <LightButton
              aria-label="First event"
              id="first-event"
              onClick={this.onFirstEvent}
              disabled={!events || events.messages.length === 0}
            >
              <FontAwesome name="step-backward" />
            </LightButton>
            <UncontrolledTooltip
              placement="top"
              target="first-event"
              disabled={!events || events.messages.length === 0}
            >
              First Event
            </UncontrolledTooltip>
            <LightButton
              aria-label="Last event"
              id="last-event"
              onClick={this.onLastEvent}
              disabled={!events || events.messages.length === 0}
            >
              <FontAwesome name="step-forward" />
            </LightButton>
            <UncontrolledTooltip
              placement="top"
              target="last-event"
              disabled={!events || events.messages.length === 0}
            >
              Last Event
            </UncontrolledTooltip>
            <FilterBox
              aria-label="Search"
              placeholder="Search"
              onChange={this.onChangeFilter}
              value={this.state.search}
            />
            {/*
            <LightButton disabled aria-label="Time Range" id="time">
              <FontAwesome name="clock-o" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="time">
              Date/Time Range
            </UncontrolledTooltip>
            */}
            <LightButton aria-label="Export" id="export" onClick={this.onExport}>
              <FontAwesome name="download" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="export">
              Export
            </UncontrolledTooltip>
            <LightButton id="clear-events" onClick={this.onClearEvents}>
              Clear Events
            </LightButton>
          </ViewHeaderButtons>
        </ViewHeader>
        {events !== null ? (
          <ViewContent>
            <EventsTable
              ref={ref => {
                this.table = ref
              }}
              events={events}
              changeCount={changeCount}
              theme={this.props.theme}
              showLocalTime={this.props.showLocalTime}
            />
          </ViewContent>
        ) : (
          <CenterContent>
            <Spinner />
          </CenterContent>
        )}
        {showClearEventsConfirm && (
          <ConfirmationModal
            message="Are you sure you want to clear events?"
            onNo={this.onClearEventsCancel}
            onYes={this.onClearEventsOK}
            show={showClearEventsConfirm}
            title="Clear Events"
          />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  theme: getCurrentTheme(state),
  logTotalCount: getLogTotalCount(state),
  showLocalTime: getShowLocalTime(state),
})

export default connect(mapStateToProps)(EventsView)
