import { fetchTimelineData } from "../../api/api"
import { ResponseGetTimelineData, TimelineDataType } from "../../api/types"

export class SparklineDataCache {
  engine: string
  authToken: string
  callback: (sessionId: number) => void
  data: any

  constructor(engine: string, authToken: string, callback: (sessionId: number) => void) {
    this.engine = engine
    this.authToken = authToken
    this.callback = callback
    this.data = {}
  }

  getSessionData = (sessionId: number): number[] => {
    if (this.data[sessionId] != null) {
      return this.data[sessionId]
    } else {
      this.data[sessionId] = []
      this.fetchSessionData(sessionId)
      return []
    }
  }

  fetchSessionData = (sessionId: number) => {
    //console.log(`Fetch session=${sessionId}`)
    fetchTimelineData(
      this.engine,
      this.authToken,
      sessionId,
      "utilization-mbps" as TimelineDataType
    )
      .then((timelineData: ResponseGetTimelineData) => {
        if (timelineData && timelineData.data) {
          this.data[sessionId] = timelineData.data.map((d: any) => d.mbps)
          this.callback(sessionId)
        }
      })
      .catch(() => {
        //console.log(error)
      })
  }
}
