import * as React from "react"
import styled from "styled-components"

const CountryNameStyle = styled.div`
  display: flex;
  align-items: center;

  & img {
    margin-right: 0.25rem;
    flex-shrink: 0;
    box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.16);
  }

  & span {
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

type CountryNameProps = {
  name: string
  code?: string | null
}

export const CountryName: React.FC<CountryNameProps> = ({ name, code }) => {
  return (
    <CountryNameStyle>
      <img
        src={
          import.meta.env.BASE_URL + `/static/flags/${code ? code.toLowerCase() : "_Unknown"}.svg`
        }
        width="16"
        alt=""
      />
      <span title={name}>{name}</span>
    </CountryNameStyle>
  )
}

export default CountryName
