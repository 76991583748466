import * as React from "react"
import TreeTable, { HierarchyDataItem } from "../TreeTable"
import { Redirect, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { cloneDeep, get, isEqual, remove, uniq } from "lodash"
import { v4 as uuid } from "uuid"
import {
  getAuthToken,
  getEngine,
  getNamesModificationTime,
  getShowAddressNames,
  getWirelessNodeColumns,
  getWirelessNodesSortBy,
  getWirelessNodesSortDirection,
  getWirelessNodesView,
  getCapabilities,
  getUserId,
  getShowLocalTime,
} from "../../store"
import { fetchCFSStatistics, postSelectRelatedFilterStart, resolveAddresses } from "../../api/api"
import {
  AlarmInfo,
  CaptureProperties,
  Channel,
  Filter,
  ForensicSearchProperties,
  GraphObject,
  RequestPostSelectRelatedFilterStart,
  ResponsePostSelectRelatedFilterStart,
  ResponseGetEngineCapabilities,
  WirelessNodesStatistics,
} from "../../api/types"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import {
  View,
  ViewContent,
  ViewHeader,
  ViewHeaderButtons,
  ViewHeaderPanel,
  ViewHeaderTitle,
} from "../common/View"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import { formatInteger, formatISODateTime } from "../../utils/formatUtils"
import {
  setWirelessNodeColumns,
  setWirelessNodesSort,
  setWirelessNodesViewType,
} from "../../store/ui"
import {
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  UncontrolledDropdownWithPortal,
} from "../common/Dropdown"
import { IconDropdownToggle, LightButton, LightDropdownToggle } from "../common/Buttons"
import { IconAccessPoint } from "../common/Icons"
import PropTable from "../common/PropTable"
import FontAwesome from "react-fontawesome"
import styled from "styled-components"
import { setSelectPacketsTask } from "../../store/selectPackets"
import {
  getCaptureForensicSearchUrl,
  getEngineNewAlarmUrl,
  getEngineNewFilterUrl,
  getEngineNewGraphUrl,
} from "../../routes"
import { generateNodeName } from "../GraphTemplatesEditView/NodesModal"
import { defaultGraph } from "../GraphTemplatesEditView"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import {
  GraphItemUnitType,
  PeekNodeStatsItemType,
  PeekNodeStatType,
} from "../../api/types/peekTypes"
import { defaultAlarm } from "../AlarmsEditView"
import { WirelessNodesStatisticsObjectNodeStatistic } from "../../api/types"
import { InsertNamesModal } from "../InsertNamesModal"
import { updateStatus } from "../../store/status"
import { MediaSpecType } from "../../api/types/mediaTypes"
import { Select } from "../common/Select"
import { OmniTable } from "../common/OmniTable"
import { CheckGroup } from "../common/Form"
import WLANNodesViewColumns from "./WLANNodesViewColumns"
import { formatWirelessChannel, getWirelessBandString } from "../../utils/channelUtils"
import { uniqWith } from "lodash"
import { CenterContent } from "../common/Layout"
import { Spinner } from "../common/Spinner"

const WLANViewStyled = styled(View)`
  .dropdown-menu {
    max-height: 300px;
    overflow: auto;
  }
`

const ViewType = {
  ALL_NODES: "All Nodes",
  CLIENTS: "Clients",
  ACCESS_POINTS: "Access Points",
  ESSID: "ESSID",
  ADHOC: "Ad Hoc",
  ADMIN: "Admin",
  UNKNOWN: "Unknown",
  CHANNELS: "Channels",
}

enum NodeType {
  "Unknown" = 0,
  "Admin" = 1,
  "AP" = 2,
  "ESSID" = 3,
  "STA" = 4,
  "ADHOC" = 5,
}

enum NodeTrust {
  "Unknown",
  "Untrusted",
  "Trusted",
}

enum NodeEncryption {
  "TKIP" = 3,
  "CCMP" = 4,
}

enum AssociationStrength {
  "Strong" = 2,
}

enum TypeIcons {
  "question-circle" = 0,
  "random" = 1,
  "dot-circle-o" = 2,
  "wifi" = 3,
  "laptop" = 4,
}

enum PowerSave {
  Awake,
  Sleep,
}

function isResolvable(mt: MediaSpecType) {
  return (
    mt === MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS ||
    mt === MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS
  )
}

type TableData = WirelessNodesStatisticsObjectNodeStatistic & HierarchyDataItem & { children?: any }
type ChannelOption = Channel & { id: string }

const columnsDefault = WLANNodesViewColumns

type WLANNodesViewProps = {
  captureProperties: CaptureProperties | ForensicSearchProperties
  engineCapabilities: ResponseGetEngineCapabilities | null
  userId: string
}

const WLANNodesView: React.FC<WLANNodesViewProps> = ({
  captureProperties,
  engineCapabilities,
  userId,
}) => {
  const routeParams: any = useParams()
  const engine = useSelector(getEngine)
  const authToken = useSelector(getAuthToken)
  const columns = useSelector(getWirelessNodeColumns) || columnsDefault
  const showAddressNames = useSelector(getShowAddressNames)
  const viewType = useSelector(getWirelessNodesView) || ViewType.ALL_NODES
  const sortBy = useSelector(getWirelessNodesSortBy) || "totalBytes"
  const sortDirection = useSelector(getWirelessNodesSortDirection) || SortDirection.DESC
  const namesModTime = useSelector(getNamesModificationTime)
  const match = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const [tableData, setTableData] = useState<TableData[]>([] as any)
  const [simpleTableData, setSimpleTableData] = useState<TableData[]>([] as any)
  const [expandedData, setExpandedData] = useState<string[]>([])
  const [insertNameEntry, setInsertNameEntry] = useState<any>(null)
  const [channelViewOptions, setChannelViewOptions] = useState<ChannelOption[]>([])
  const [channelViewDisabledIds, setChannelViewDisabledIds] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const showLocalTime = useSelector(getShowLocalTime)

  const clients = useRef([] as TableData[])
  const accessPoints = useRef([] as TableData[])
  const essids = useRef([] as TableData[])
  const unknowns = useRef([] as TableData[])
  const adhocs = useRef([] as TableData[])
  const admins = useRef([] as TableData[])
  const channelHierarchy = useRef([] as HierarchyDataItem[])
  const requestPending = useRef(false)

  const getChildrenByChannel = (data: TableData, channel: string): TableData => {
    if (!data.children) return data
    const dataClone = cloneDeep(data)
    dataClone.children = dataClone.children?.filter((child: TableData) => {
      return `${child.dsChannel?.channel}` === channel
    })
    dataClone.children = dataClone.children?.map((child: TableData) => {
      return {
        ...getChildrenByChannel(child, channel),
      }
    })
    return dataClone
  }

  const onRefresh = () => {
    if (requestPending.current) return
    requestPending.current = true
    setIsLoading(true)
    const { type, capId } = routeParams
    fetchCFSStatistics<WirelessNodesStatistics>(engine, authToken, type, capId, "wireless-nodes")
      .then((wirelessNodesStatistic: WirelessNodesStatistics) => {
        const { wirelessNodes } = wirelessNodesStatistic
        const clientsToSet: TableData[] = []
        const accessPointsToSet: TableData[] = []
        const unknownsToSet: TableData[] = []
        const adhocsToSet: TableData[] = []
        const adminsToSet: TableData[] = []
        const channelsArr: ChannelOption[] = []
        let channelHierarchyToSet: { name: string; id: string; children: TableData[] }[] = []
        const nodeStats: TableData[] = wirelessNodes.nodeStats.map(
          (node: WirelessNodesStatisticsObjectNodeStatistic) => {
            return {
              ...node,
              id: node.type === 3 ? `${node.index}` : `${node.node}${node.dsChannel.channel}`,
            } as TableData
          }
        )

        // 'essidUnknownStatIndex' field at 'wirelessNodes' doesn't come always correct
        // so wee need to find 'ESSID Unknown' index from essids
        const essidUnknownStatIndex = wirelessNodes.essids.find(
          idObj => idObj.essid === "ESSID Unknown"
        )?.index

        const bssidUnknownObj: any = {
          index: 4294967295,
          name: "BSSID Unknown",
          id: "bssidUnknown",
          children: [],
        }

        nodeStats.forEach((node: TableData) => {
          if (node.dsChannel && node.dsChannel.channel > 0) {
            channelsArr.push({
              ...node.dsChannel,
              id: `${node.dsChannel.band}${node.dsChannel.channel}${node.dsChannel.frequency}`,
            })
            const isExistedInChannelHierarchy = channelHierarchyToSet.find(
              c => c.name === node.dsChannel.channel.toString()
            )
            if (!isExistedInChannelHierarchy) {
              channelHierarchyToSet.push({
                name: `${node.dsChannel.channel}`,
                id: `${node.dsChannel.channel}-channel`,
                children: [],
              })
            }
          }
          // type: 0 - Unknown
          if (node.type === 0 && node.dsChannel?.channel > 0) {
            unknownsToSet.push(node)
            bssidUnknownObj.children.push(node)
          }
          // type: 4 - Client
          // type: 5 - ADHOC
          if ((node.type === 4 || node.type === 5) && node.dsChannel?.channel > 0) {
            const association = wirelessNodes.associationTable.rows.find(row => {
              return row.nodeIndex === node.index && row.essidIndex === node.nextIndex
            })
            const newObj = {
              ...node,
              associationStrength: association ? association.strength : 0,
            }
            if (node.type === 4) clientsToSet.push(newObj)
            if (node.type === 5) adhocsToSet.push(newObj)
            if (!association) {
              bssidUnknownObj.children.push(newObj)
              return
            }
            if (!nodeStats[association.bssidIndex].children) {
              nodeStats[association.bssidIndex].children = [newObj]
            } else {
              nodeStats[association.bssidIndex].children = [
                ...(nodeStats[association.bssidIndex].children as TableData[]),
                newObj,
              ]
            }
          }
        })
        nodeStats.forEach((node: TableData) => {
          // type: 1 - Admin
          // type: 2 - Access point
          if ((node.type === 2 || node.type === 1) && node.dsChannel?.channel > 0) {
            if (node.index === wirelessNodes.bssidUnknownStatIndex) return
            const association = wirelessNodes.associationTable.rows.find(row => {
              return row.bssidIndex === node.index && row.nodeIndex === node.nextIndex
            })
            if (node.type === 2) accessPointsToSet.push(node)
            if (node.type === 1) adminsToSet.push(node)
            if (!association) {
              if (essidUnknownStatIndex === undefined) return
              if (!nodeStats[essidUnknownStatIndex].children) {
                nodeStats[essidUnknownStatIndex].children = [node]
              } else {
                nodeStats[essidUnknownStatIndex].children = [
                  ...(nodeStats[essidUnknownStatIndex].children as TableData[]),
                  node,
                ]
              }
              return
            }
            const index =
              association.essidIndex !== 4294967295 ? association.essidIndex : essidUnknownStatIndex
            if (index === undefined) return
            if (!nodeStats[index].children) {
              nodeStats[index].children = [node]
            } else {
              nodeStats[index].children = [...(nodeStats[index].children as TableData[]), node]
            }
          }
        })

        let unknownEssids: TableData[] = remove(nodeStats, (node: TableData) => {
          const essid = wirelessNodes.essids.find(idObj => idObj.index === node.index)
          const correctAssociation = wirelessNodes.associationTable.rows.find(
            row => row.essidIndex === node.index && row.bssidIndex !== 4294967295
          )
          return !!essid && !correctAssociation && node.index !== essidUnknownStatIndex
        })

        unknownEssids = unknownEssids.map((node: TableData) => {
          const essidObj = wirelessNodes.essids.find(idObj => idObj.index === node.index)
          if (!essidObj) return node
          return {
            ...node,
            name: essidObj.essid,
          }
        })
        bssidUnknownObj.children.push(...unknownEssids)

        if (bssidUnknownObj.children.length > 0) {
          if (essidUnknownStatIndex === undefined) return
          if (!nodeStats[essidUnknownStatIndex].children) {
            nodeStats[essidUnknownStatIndex].children = [bssidUnknownObj]
          } else {
            nodeStats[essidUnknownStatIndex].children = [
              ...(nodeStats[essidUnknownStatIndex].children as TableData[]),
              bssidUnknownObj,
            ]
          }
        }
        let essidsToSet: TableData[] = remove(nodeStats, (node: TableData) => {
          const essid = wirelessNodes.essids.find(idObj => idObj.index === node.index)
          const correctAssociation = wirelessNodes.associationTable.rows.find(
            row => row.essidIndex === node.index && row.bssidIndex !== 4294967295
          )
          return (
            !!essid &&
            (!!correctAssociation || (node.index === essidUnknownStatIndex && node.children))
          )
        })
        essidsToSet = essidsToSet.map((node: any) => {
          const essidObj = wirelessNodes.essids.find(idObj => idObj.index === node.index)
          if (!essidObj) return node
          return {
            ...node,
            name: essidObj.essid,
          }
        })

        channelHierarchyToSet = channelHierarchyToSet.map(channel => {
          const children: any = []
          essidsToSet.forEach((essid: TableData) => {
            const objToAdd = getChildrenByChannel(essid, channel.name)
            if (objToAdd && objToAdd.children && objToAdd.children.length > 0) {
              children.push({ ...objToAdd, id: `${objToAdd.id}-${channel.name}` })
            }
          })
          return {
            ...channel,
            children,
          }
        })

        addChannelToOptions(channelsArr)
        essids.current = essidsToSet
        clients.current = clientsToSet
        accessPoints.current = accessPointsToSet
        unknowns.current = unknownsToSet
        admins.current = adminsToSet
        adhocs.current = adhocsToSet
        channelHierarchy.current = channelHierarchyToSet
        sortNeededData()
        requestPending.current = false
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const filterHierarchyDataByDisabledChannels = (data: TableData[]) => {
    return sort(removeHierarchyTableDataByDisabledChannels(data), sortBy, sortDirection)
  }

  const filterDataByDisabledChannels = (data: TableData[]): TableData[] => {
    return sort(removeTableDataByDisabledChannels(data), sortBy, sortDirection)
  }

  const sortNeededData = () => {
    switch (viewType) {
      case ViewType.ALL_NODES:
        setTableData(filterHierarchyDataByDisabledChannels(essids.current))
        break
      case ViewType.CHANNELS:
        setTableData(filterHierarchyDataByDisabledChannels(channelHierarchy.current as TableData[]))
        break
      case ViewType.ACCESS_POINTS:
        setSimpleTableData(filterDataByDisabledChannels(accessPoints.current))
        break
      case ViewType.CLIENTS:
        setSimpleTableData(filterDataByDisabledChannels(clients.current))
        break
      case ViewType.ESSID:
        setSimpleTableData(filterDataByDisabledChannels(essids.current))
        break
      case ViewType.ADHOC:
        setSimpleTableData(filterDataByDisabledChannels(adhocs.current))
        break
      case ViewType.ADMIN:
        setSimpleTableData(filterDataByDisabledChannels(admins.current))
        break
      case ViewType.UNKNOWN:
        setSimpleTableData(filterDataByDisabledChannels(unknowns.current))
        break
      default:
        break
    }
  }

  useEffect(onRefresh, [namesModTime, channelViewDisabledIds]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(sortNeededData, [sortBy, sortDirection, viewType]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (captureProperties.status === 2) return
    const interval = setInterval(onRefresh, 20000)
    return () => clearInterval(interval)
  }, [captureProperties.status]) // eslint-disable-line react-hooks/exhaustive-deps

  const onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cols = cloneDeep(columns) as any[]
    const col = cols.find((col: any) => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      dispatch(setWirelessNodeColumns(cols))
    }
  }

  const addChannelToOptions = (channels: ChannelOption[]) => {
    const uniqArr = uniqWith(channels, isEqual)
    uniqArr.sort((a, b) => a.channel - b.channel || a.frequency - b.frequency || a.band - b.band)
    setChannelViewOptions(uniqArr)
  }

  const onShowAllColumns = () => {
    const cols: any[] = cloneDeep(columns) as any[]
    cols.forEach((col: any) => {
      col.visible = true
    })
    dispatch(setWirelessNodeColumns(cols))
  }

  const onShowDefaultColumns = () => {
    dispatch(setWirelessNodeColumns(columnsDefault))
  }

  const commandCellRenderer = ({ rowData }: TableCellProps) => {
    const packetsDisabled = captureProperties === null || !captureProperties.packetBufferEnabled

    // make sure the user can view packets for this capture or forensic search
    let canViewPackets = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
    }

    return (
      <div className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={onSelectRelated.bind(null, "source", rowData)}
            >
              Select Related Packets by Source
            </DropdownItem>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={onSelectRelated.bind(null, "dest", rowData)}
            >
              Select Related Packets by Destination
            </DropdownItem>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={onSelectRelated.bind(null, "sourceOrDest", rowData)}
            >
              Select Related Packets by Source or Destination
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={onMakeFilter.bind(null, rowData)}>Make Filter</DropdownItem>
            <DropdownItem onClick={onMakeGraph.bind(null, rowData)}>Make Graph</DropdownItem>
            <DropdownItem onClick={onMakeAlarm.bind(null, rowData)}>Make Alarm</DropdownItem>
            <DropdownItem onClick={onInsertIntoNameTable.bind(null, rowData)}>
              Insert Into Name Table
            </DropdownItem>
            <DropdownItem onClick={onResolveNames.bind(null, rowData)}>Resolve Names</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </div>
    )
  }

  const getChildrenValues = (
    rowData: TableData,
    key: string,
    shouldBeCollapsed = false,
    initVal?: number
  ) => {
    const dataVal = rowData[key] !== undefined ? rowData[key] : 0
    let initialVal = initVal === undefined ? dataVal : initVal
    if (
      rowData.children !== undefined &&
      (!shouldBeCollapsed || (shouldBeCollapsed && !rowData.isExpanded))
    ) {
      rowData.children.forEach((child: any) => {
        const childVal = child[key] !== undefined ? child[key] : 0
        initialVal += childVal
        initialVal = getChildrenValues(child, key, shouldBeCollapsed, initialVal)
      })
    }
    return initialVal
  }

  const cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content
    switch (dataKey) {
      case "name":
        {
          const node = (showAddressNames && rowData.name) || rowData.node
          const icon =
            rowData.type !== undefined ? (
              rowData.type !== 2 && rowData.type !== 5 ? (
                <FontAwesome
                  name={TypeIcons[rowData.type]}
                  fixedWidth
                  style={{ marginRight: "0.25em" }}
                />
              ) : (
                <IconAccessPoint
                  style={{ marginLeft: "0.26875em", marginRight: "0.51875em", marginTop: "-3px" }}
                />
              )
            ) : null

          content = (
            <span>
              {icon}
              <span
                style={{
                  fontWeight: rowData.type === 3 || rowData.type === 2 ? "bold" : undefined,
                }}
                title={node}
              >
                {node}
              </span>
            </span>
          )
        }
        break
      case "type":
        content = NodeType[cellData]
        break
      case "trust":
        if (rowData.type === 3) break
        content = NodeTrust[cellData]
        break
      case "encryptionType":
        content = NodeEncryption[cellData]
        break
      case "signal.percentMinimum":
      case "signal.percentMaximum":
      case "signal.percentCurrent":
      case "signal.dBmCurrent":
      case "signal.dBmMinimum":
      case "signal.dBmMaximum":
      case "noise.percentMinimum":
      case "noise.percentMaximum":
      case "noise.percentCurrent":
      case "noise.dBmCurrent":
      case "noise.dBmMinimum":
      case "noise.dBmMaximum": {
        const d = get(rowData, dataKey)
        content = d && rowData.type !== 3 && d !== 0 ? d : ""
        break
      }
      case "curNoise":
        content =
          rowData.noise && rowData.noise.percentCurrent !== 0 ? rowData.noise.percentCurrent : ""
        break
      case "channel": {
        const channel =
          rowData.dsChannel && rowData.dsChannel.channel !== 0 ? [rowData.dsChannel.channel] : []
        if (rowData.children) {
          rowData.children.forEach((child: any) => {
            if (child.dsChannel && child.dsChannel.channel !== 0)
              channel.push(child.dsChannel.channel)
          })
        }
        content = uniq(channel).join(", ")
        break
      }
      case "bytesSent":
      case "bytesReceived":
      case "packetsSent":
      case "packetsReceived":
      case "broadcastPackets":
      case "broadcastBytes":
      case "multicastPackets":
      case "multicastBytes":
      case "minimumPacketSizeReceived":
      case "maximumPacketSizeReceived":
      case "minimumPacketSizeSent":
      case "maximumPacketSizeSent":
      case "beaconPackets":
        content = formatInteger(getChildrenValues(rowData, dataKey, rowData.type === 2))
        break
      case "totalBytes":
        content = formatInteger(
          getChildrenValues(rowData, "bytesSent", rowData.type === 2) +
            getChildrenValues(rowData, "bytesReceived", rowData.type === 2)
        )
        break
      case "totalPackets":
        content = formatInteger(
          getChildrenValues(rowData, "packetsSent", rowData.type === 2) +
            getChildrenValues(rowData, "packetsReceived", rowData.type === 2)
        )
        break
      case "frequency":
        content = rowData.dsChannel?.frequency > 0 ? `${rowData.dsChannel?.frequency} MHz` : ""
        break
      case "associationStrength":
        content = AssociationStrength[cellData]
        break
      case "band":
        content = rowData.dsChannel?.band
          ? `802.11${getWirelessBandString(rowData.dsChannel.band, rowData.dsChannel.channel)}`
          : ""
        break
      case "powerSaveFlag":
        content = rowData.type !== 3 ? PowerSave[cellData] : ""
        break
      case "firstTimeSent":
      case "lastTimeSent":
      case "firstTimeReceived":
      case "lastTimeReceived":
        content =
          cellData !== "1601-01-01T00:00:00.000000000Z"
            ? formatISODateTime(cellData as string, 0, showLocalTime)
            : ""
        break
      default:
        content = cellData
        break
    }
    return content
  }

  const onSelectRelated = (
    command: string,
    rowData: WirelessNodesStatisticsObjectNodeStatistic
  ) => {
    let accept1To2 = false
    let accept2To1 = false
    if (command === "source") {
      accept1To2 = true
    } else if (command === "dest") {
      accept2To1 = true
    } else if (command === "sourceOrDest") {
      accept1To2 = true
      accept2To1 = true
    }
    const body: RequestPostSelectRelatedFilterStart = {
      id: uuid(),
      rootNode: {
        accept1To2: accept1To2,
        accept2To1: accept2To1,
        address1: rowData.node,
        address2: "",
        clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
        comment: "",
        inverted: false,
        type: rowData.entity.type,
      },
    }
    const { capId } = match.params as any
    postSelectRelatedFilterStart(engine, authToken, capId, body)
      .then((task: ResponsePostSelectRelatedFilterStart) => {
        if (task.taskId) {
          dispatch(setSelectPacketsTask({ type: "filter", taskId: task.taskId, progress: 0 }))
          history.push("packets")
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const onMakeFilter = (rowData: WirelessNodesStatisticsObjectNodeStatistic) => {
    const filter: Filter = {
      clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
      color: "#000000", // TODO rowData.color,
      comment: "",
      created: "",
      group: "",
      id: "",
      modified: "",
      name: "Untitled",
      rootNode: {
        accept1To2: true,
        accept2To1: true,
        address1: rowData.node,
        address2: "",
        clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
        comment: "",
        inverted: false,
        type: rowData.entity.type,
      },
    }
    history.push({
      pathname: getEngineNewFilterUrl(),
      state: { filter },
    })
  }

  const onMakeGraph = (rowData: WirelessNodesStatisticsObjectNodeStatistic) => {
    const node = generateNodeName(rowData.entity.type, rowData.node)
    const name = (showAddressNames && rowData.name) || node
    const graph: GraphObject = {
      ...cloneDeep(defaultGraph),
      graphItems: [
        {
          clsid: "B1DAFDE2-68F2-4D35-AAA7-5861B35A3E9F",
          description: node,
          id: uuid().toUpperCase(),
          mediaSpec: rowData.node,
          mediaSpecType: rowData.entity.type,
          name: name,
          nodeStatsType: PeekNodeStatsItemType.PEEK_NODE_STATS_ITEM_TYPE_TOTAL,
          unitType: GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_BYTES,
        },
      ],
      id: uuid().toUpperCase(),
      templateId: uuid().toUpperCase(),
      title: name,
    }

    history.push({
      pathname: getEngineNewGraphUrl(),
      state: { graph },
    })
  }

  const onMakeAlarm = (rowData: WirelessNodesStatisticsObjectNodeStatistic) => {
    const alarm: AlarmInfo = {
      ...cloneDeep(defaultAlarm),
      name: (showAddressNames && rowData.name) || rowData.node,
      statisticsTracker: {
        clsid: "0EC1390E-3A7D-45B3-9803-84D74B5D5B80",
        history: 60,
        node: cloneDeep(rowData.entity),
        statisticsType: PeekNodeStatType.PEEK_NODE_STAT_TYPE_TOTAL_BYTES,
      },
    }
    history.push({
      pathname: getEngineNewAlarmUrl(),
      state: { alarm },
    })
  }

  const onInsertIntoNameTable = (rowData: WirelessNodesStatisticsObjectNodeStatistic) => {
    setInsertNameEntry({
      title: "Node Address",
      entry: rowData.node,
      entryType: rowData.entity.type,
    })
  }

  const onResolveNames = (rowData: WirelessNodesStatisticsObjectNodeStatistic) => {
    if (isResolvable(rowData.entity.type)) {
      resolveAddresses(engine, authToken, [
        { entry: rowData.node, entryType: rowData.entity.type },
      ]).catch(error => {
        console.error(error)
      })
      dispatch(updateStatus())
    }
  }

  const onInsertIntoNameTableOK = () => {
    setInsertNameEntry(null)
    onRefresh()
  }

  const onInsertIntoNameTableCancel = () => {
    setInsertNameEntry(null)
  }

  const onChangeViewType = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setWirelessNodesViewType(e.target.value))
    // setTimeout(sortNeededData, 0)
  }

  const onExpandAll = () => {
    const ids: string[] = []
    ;(function setAllRowIds(data: any) {
      data.forEach((d: any) => {
        if (d.children) {
          setAllRowIds(d.children)
          ids.push(d.id)
        }
      })
    })(tableData)
    setExpandedData(ids)
    // this.setState({ expandedData: nodesHierarchyTableData.map(node => node.id) })
  }

  const onCollapseAll = () => {
    setExpandedData([])
  }

  const onSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
  }) => {
    dispatch(setWirelessNodesSort(sortBy, sortDirection))
  }

  const sort = (data: any, sortBy: string, sortDirection: SortDirectionType): TableData[] => {
    let dataClone = cloneDeep(data)
    const collator = new Intl.Collator(undefined, { sensitivity: "base", numeric: true })
    dataClone.sort((a: any, b: any) => {
      let result = 0

      switch (sortBy) {
        case "name": {
          const valueA = (showAddressNames && a.name) || a.node
          const valueB = (showAddressNames && b.name) || b.node
          result = collator.compare(valueA, valueB)
          break
        }
        case "channel": {
          const valueA = a.dsChannel?.channel
          const valueB = b.dsChannel?.channel
          result = collator.compare(valueA, valueB)
          break
        }
        case "bytesSent":
        case "bytesReceived":
        case "packetsSent":
        case "packetsReceived":
        case "broadcastPackets":
        case "broadcastBytes":
        case "multicastPackets":
        case "multicastBytes":
        case "minimumPacketSizeReceived":
        case "maximumPacketSizeReceived":
        case "minimumPacketSizeSent":
        case "maximumPacketSizeSent":
        case "beaconPackets": {
          result = collator.compare(getChildrenValues(a, sortBy), getChildrenValues(b, sortBy))
          break
        }
        case "totalBytes":
          result = collator.compare(
            getChildrenValues(a, "bytesSent") + getChildrenValues(a, "bytesReceived"),
            getChildrenValues(b, "bytesSent") + getChildrenValues(b, "bytesReceived")
          )
          break
        case "totalPackets":
          result = collator.compare(
            getChildrenValues(a, "packetsSent") + getChildrenValues(a, "packetsReceived"),
            getChildrenValues(b, "packetsSent") + getChildrenValues(b, "packetsReceived")
          )
          break
        default: {
          result = collator.compare(a[sortBy], b[sortBy])
          break
        }
      }
      if (result === 0) {
        // Fall back to node.
        const valueA = (showAddressNames && a.name) || a.node
        const valueB = (showAddressNames && b.name) || b.node
        result = collator.compare(valueA, valueB)
      }
      if (sortDirection === SortDirection.DESC) result = -result

      return result
    })
    dataClone = dataClone.map((d: TableData) => {
      if (d.children) {
        return {
          ...d,
          children: sort(d.children, sortBy, sortDirection),
        }
      } else {
        return d
      }
    })
    return dataClone
  }

  const onChannelViewChange = (channel: any) => {
    const enabled = channelViewDisabledIds.find(id => id === channel.id)
    if (enabled) {
      const arrCopy = [...channelViewDisabledIds]
      remove(arrCopy, i => i === channel.id)
      setChannelViewDisabledIds(arrCopy)
    } else {
      setChannelViewDisabledIds([...channelViewDisabledIds, channel.id])
    }
  }

  const removeHierarchyTableDataByDisabledChannels = (data: TableData[]): TableData[] => {
    const dataClone = [...data]
    const removedData = remove(dataClone, d => {
      if (d.children !== undefined) {
        d.children = removeHierarchyTableDataByDisabledChannels(d.children)
      }
      if (d.children !== undefined && d.children.length === 0) return false
      // BSSID unknown object doesn't have 'dsChannel' field
      const id = `${d.dsChannel?.band}${d.dsChannel?.channel}${d.dsChannel?.frequency}`
      return !channelViewDisabledIds.find(channelId => channelId === id)
    })
    return removedData
  }

  const removeTableDataByDisabledChannels = (data: TableData[]): TableData[] => {
    const dataClone = [...data]
    const removedData = remove(dataClone, d => {
      // BSSID unknown object doesn't have 'dsChannel' field
      const id = `${d.dsChannel?.band}${d.dsChannel?.channel}${d.dsChannel?.frequency}`
      return !channelViewDisabledIds.find(channelId => channelId === id)
    })
    return removedData
  }

  const renderChannelViewOptions = () => {
    return channelViewOptions.map((option, index) => {
      return (
        <CheckGroup
          type="checkbox"
          key={index}
          id={`channel-view-options-${index}`}
          checked={!channelViewDisabledIds.includes(option.id)}
          onChange={() => {
            onChannelViewChange(option)
          }}
        >
          {formatWirelessChannel(option.band, option.channel, option.frequency)}
        </CheckGroup>
      )
    })
  }

  const renderSimpleTable = () => {
    return (
      <OmniTable
        data={simpleTableData}
        rowCount={simpleTableData.length}
        columnDesc={columns}
        cellRenderer={cellRenderer}
        onToggleColumn={onToggleColumn}
        onShowAllColumns={onShowAllColumns}
        onShowDefaultColumns={onShowDefaultColumns}
        renderCommands={commandCellRenderer}
        sort={onSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
    )
  }

  const renderTreeTable = () => {
    return (
      <TreeTable
        data={tableData}
        columnDesc={columns}
        expandedRows={expandedData}
        cellRenderer={cellRenderer}
        onToggleColumn={onToggleColumn}
        onShowAllColumns={onShowAllColumns}
        onShowDefaultColumns={onShowDefaultColumns}
        renderCommands={commandCellRenderer}
        sort={onSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
    )
  }

  const wlanPropList = [["Wireless Networks", "Ad Hoc Networks", "Clients", "Access Points"]]

  const formatWLANProps = (columnId: string, data: any) => {
    switch (columnId) {
      case wlanPropList[0][0]:
        return formatInteger(essids.current.length)
      case wlanPropList[0][1]:
        return formatInteger(adhocs.current.length)
      case wlanPropList[0][2]:
        return formatInteger(clients.current.length)
      case wlanPropList[0][3]:
        return formatInteger(accessPoints.current.length)
      default:
        break
    }
    return ""
  }

  // make sure the user can view stats for this capture or forensic search
  if (captureProperties && engineCapabilities) {
    const isUserOwner = userId === captureProperties.creatorSID
    const policies = engineCapabilities.userRights.policies
    const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
    if (!canViewStats) {
      const { type, capId } = match.params as any
      return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
    }
  }

  return (
    <WLANViewStyled>
      <BreadcrumbItem to={match.url} title="WLAN" />
      <ViewHeader>
        <ViewHeaderTitle title="WLAN" />
        <ViewHeaderButtons>
          <Select
            name="viewType"
            id="viewType"
            aria-label="View Type"
            value={viewType}
            onChange={onChangeViewType}
            style={{ width: "auto" }}
          >
            <option>{ViewType.ALL_NODES}</option>
            <option>{ViewType.ACCESS_POINTS}</option>
            <option>{ViewType.CLIENTS}</option>
            <option>{ViewType.ESSID}</option>
            <option>{ViewType.ADHOC}</option>
            <option>{ViewType.ADMIN}</option>
            <option>{ViewType.UNKNOWN}</option>
            <option>{ViewType.CHANNELS}</option>
          </Select>
          <UncontrolledDropdown>
            <LightDropdownToggle caret>Channel View</LightDropdownToggle>
            <DropdownMenu style={{ padding: "8px", whiteSpace: "nowrap" }}>
              {renderChannelViewOptions()}
            </DropdownMenu>
          </UncontrolledDropdown>
          <LightButton id="expand-all" onClick={onExpandAll}>
            Expand All
          </LightButton>
          <LightButton id="collapse-all" onClick={onCollapseAll}>
            Collapse All
          </LightButton>
          <LightButton aria-label="Refresh" id="refresh" onClick={onRefresh}>
            <FontAwesome name="refresh" />
          </LightButton>
        </ViewHeaderButtons>
      </ViewHeader>
      <ViewHeaderPanel>
        <PropTable propList={wlanPropList} formatProp={formatWLANProps} />
      </ViewHeaderPanel>
      <ViewContent>
        {viewType === ViewType.ALL_NODES || viewType === ViewType.CHANNELS
          ? renderTreeTable()
          : renderSimpleTable()}
        {isLoading ? (
          <CenterContent style={{ height: "100%" }}>
            <Spinner />
          </CenterContent>
        ) : null}
      </ViewContent>
      {insertNameEntry != null && (
        <InsertNamesModal
          entries={[insertNameEntry]}
          onOK={onInsertIntoNameTableOK}
          onCancel={onInsertIntoNameTableCancel}
          authToken={authToken}
          engine={engine}
        />
      )}
    </WLANViewStyled>
  )
}

const mapStateToProps = (state: any) => ({
  engineCapabilities: getCapabilities(state) || null,
  userId: getUserId(state),
})

export default connect(mapStateToProps)(WLANNodesView)
