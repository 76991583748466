import * as React from "react"
import { connect } from "react-redux"
import { cloneDeep } from "lodash"
import { withTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Legend, Label } from "recharts"
import ChartTooltip from "../../common/ChartTooltip"
import { ChartType } from "../../../api/types/chartTypes"
import { ChartComponents } from "../../../utils/chartComponents"
import {
  FullWidthWidget,
  WidgetHeader,
  WidgetTitle,
  WidgetSubTitle,
  WidgetOptions,
  WidgetBody,
  NoData,
} from "../Dashboard"
import { IconDropdownToggle } from "../../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../../common/Dropdown"
import { formatSampleInterval } from "../../../utils/formatUtils"
import {
  formatChartTooltip,
  formatTimeChartTooltipLabel,
  formatTimeChartAxis,
} from "../../../utils/chartUtils"
import {
  setApplicationResponseTimeWidgetChartType,
  setApplicationResponseTimeWidgetChartScale,
} from "../../../store/ui"
import {
  getApplicationResponseTimeWidgetChartType,
  getApplicationResponseTimeWidgetChartScale,
  getShowLocalTime,
} from "../../../store"

type Props = {
  appResponseTimeHistory: any | null
  chartType: ChartType
  chartScale: "linear" | "log"
  showLocalTime: boolean
  theme: any
  setApplicationResponseTimeWidgetChartType: (chartType: ChartType) => void
  setApplicationResponseTimeWidgetChartScale: (chartScale: string) => void
}

type State = {
  hoverKey: string | null
}

class ApplicationResponseTimeWidget extends React.Component<Props, State> {
  state: State = {
    hoverKey: null,
  }

  onChartType = (e: any) => {
    this.props.setApplicationResponseTimeWidgetChartType(e.target.name)
  }

  onChartScale = (e: any) => {
    this.props.setApplicationResponseTimeWidgetChartScale(e.target.name)
  }

  onMouseEnterLegend = (e: any) => {
    this.setState({ hoverKey: e.dataKey })
  }

  onMouseLeaveLegend = (e: any) => {
    if (e.dataKey === this.state.hoverKey) {
      this.setState({ hoverKey: null })
    }
  }

  render() {
    const { appResponseTimeHistory, theme, chartType, chartScale } = this.props
    const chartComponent = ChartComponents[chartType]
    const Chart = chartComponent.chart
    const Series = chartComponent.series
    let series = null
    if (appResponseTimeHistory && appResponseTimeHistory.series) {
      const { hoverKey } = this.state
      const seriesProps = cloneDeep(chartComponent.seriesProps)
      if (seriesProps.dot) {
        seriesProps.dot.stroke = theme.panelBackground
      }
      if (seriesProps.activeDot) {
        seriesProps.activeDot.stroke = theme.panelBackground
      }
      series = appResponseTimeHistory.series.map((app: any) => {
        const defaultOpacity = !hoverKey || hoverKey === app.applicationId
        const opacity = defaultOpacity ? undefined : 0.1
        return (
          <Series
            key={app.applicationId}
            dataKey={app.applicationId}
            name={app.applicationName}
            unit="Seconds"
            stroke={app.color}
            fill={app.color}
            strokeOpacity={opacity}
            fillOpacity={opacity}
            {...seriesProps}
          />
        )
      })
    }
    return (
      <FullWidthWidget>
        <WidgetHeader>
          <WidgetTitle>
            Application Response Time
            {appResponseTimeHistory && (
              <WidgetSubTitle>{`${formatSampleInterval(
                appResponseTimeHistory.interval / 1000
              )} Average`}</WidgetSubTitle>
            )}
          </WidgetTitle>
          <WidgetOptions>
            <UncontrolledDropdown>
              <IconDropdownToggle aria-label="Settings">
                <FontAwesome name="gear" style={{ color: theme.textMutedColor }} />
              </IconDropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  name={ChartType.OVERLAID_SKYLINE}
                  active={chartType === ChartType.OVERLAID_SKYLINE}
                  onClick={this.onChartType}
                >
                  Skyline
                </DropdownItem>
                <DropdownItem
                  name={ChartType.AREA}
                  active={chartType === ChartType.AREA}
                  onClick={this.onChartType}
                >
                  Area
                </DropdownItem>
                <DropdownItem
                  name={ChartType.LINE}
                  active={chartType === ChartType.LINE}
                  onClick={this.onChartType}
                >
                  Line
                </DropdownItem>
                <DropdownItem
                  name={ChartType.LINE_POINTS}
                  active={chartType === ChartType.LINE_POINTS}
                  onClick={this.onChartType}
                >
                  Line/Points
                </DropdownItem>
                {/*
                <DropdownItem divider />
                <DropdownItem
                  name="linear"
                  active={chartScale === "linear"}
                  onClick={this.onChartScale}
                >
                  Linear
                </DropdownItem>
                <DropdownItem
                  name="log"
                  active={chartScale === "log"}
                  onClick={this.onChartScale}
                >
                  Logarithmic
                </DropdownItem>
                */}
              </DropdownMenu>
            </UncontrolledDropdown>
          </WidgetOptions>
        </WidgetHeader>
        <WidgetBody minHeight="240px">
          {appResponseTimeHistory ? (
            <ResponsiveContainer height={240} width="100%">
              <Chart
                data={appResponseTimeHistory.data}
                margin={{ top: 10, right: 32, left: 0, bottom: 0 }}
                {...chartComponent.chartProps}
              >
                <Tooltip
                  isAnimationActive={false}
                  labelFormatter={formatTimeChartTooltipLabel.bind(
                    this,
                    appResponseTimeHistory.startTime,
                    appResponseTimeHistory.interval,
                    this.props.showLocalTime
                  )}
                  formatter={formatChartTooltip}
                  content={<ChartTooltip />}
                />
                <CartesianGrid stroke={theme.chartGridColor} vertical={false} />
                <YAxis scale={chartScale} stroke={theme.textColor}>
                  <Label
                    value="Seconds"
                    fill={theme.textColor}
                    style={{ fontWeight: 600 }}
                    position="insideLeft"
                    angle={-90}
                    offset={10}
                    dy={26}
                  />
                </YAxis>
                <XAxis
                  stroke={theme.textColor}
                  tickFormatter={formatTimeChartAxis.bind(
                    this,
                    appResponseTimeHistory.startTime,
                    appResponseTimeHistory.interval,
                    this.props.showLocalTime
                  )}
                  height={22}
                />
                {series}
                <Legend
                  iconSize={10}
                  onMouseEnter={this.onMouseEnterLegend}
                  onMouseLeave={this.onMouseLeaveLegend}
                />
              </Chart>
            </ResponsiveContainer>
          ) : (
            <NoData>No Data</NoData>
          )}
        </WidgetBody>
      </FullWidthWidget>
    )
  }
}

const mapStateToProps = (state: any) => ({
  chartType: getApplicationResponseTimeWidgetChartType(state) || ChartType.OVERLAID_SKYLINE,
  chartScale: getApplicationResponseTimeWidgetChartScale(state) || "linear",
  showLocalTime: getShowLocalTime(state),
})

const mapDisptachToProps = {
  setApplicationResponseTimeWidgetChartType,
  setApplicationResponseTimeWidgetChartScale,
}

export default withTheme(
  connect(mapStateToProps, mapDisptachToProps)(ApplicationResponseTimeWidget)
)
