export type Dashboard = {
  id: string
  name: string
}

export type DashboardCollection = {
  dashboards: Array<Dashboard>
  modificationTime?: string
}

export type WidgetType =
  | "capture-counters"
  | "global-event-counters"
  | "forensic-chart"
  | "forensic-top-stats"

export type Widget = {
  id: string
  x: number
  y: number
  w: number
  h: number
  type: WidgetType
  title: string
  dashboard: string
  engine: string | null
  settings: any | null
}

export type WidgetCollection = {
  widgets: Array<Widget>
}

export type WidgetCommand = (widget: Widget) => void

export type WidgetCommands = {
  onEditWidget: WidgetCommand
  onRemoveWidget: WidgetCommand
}

export type WidgetProps = {
  widget: Widget
  data: any | undefined
  commands: WidgetCommands
}

export enum TimelineDataViewType {
  TIMELINE_DATA_VIEW_TYPE_MBITS = 0,
  TIMELINE_DATA_VIEW_TYPE_PACKETS = 1,
  TIMELINE_DATA_VIEW_TYPE_DEST_TYPE = 2,
  TIMELINE_DATA_VIEW_TYPE_SIZE_DIST = 3,
  TIMELINE_DATA_VIEW_TYPE_VLAN = 4,
  TIMELINE_DATA_VIEW_TYPE_PROTOCOLS_MBITS = 5,
  TIMELINE_DATA_VIEW_TYPE_PROTOCOLS_PACKETS = 6,
  TIMELINE_DATA_VIEW_TYPE_CALL_QUALITY = 7,
  TIMELINE_DATA_VIEW_TYPE_CALL_UTILIZATION = 8,
  TIMELINE_DATA_VIEW_TYPE_WIRELESS_PACKETS = 9,
  TIMELINE_DATA_VIEW_TYPE_WIRELESS_RETRIES = 10,
  TIMELINE_DATA_VIEW_TYPE_APPLICATIONS_MBITS = 11,
  TIMELINE_DATA_VIEW_TYPE_APPLICATIONS_PACKETS = 12,
}

export const TopStatsViewType = {
  PHYSICAL: "physical",
  IP: "ip",
  IPV6: "ipv6",
  PROTOCOLS: "protocols",
  APPLICATIONS: "applications",
}
