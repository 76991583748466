import * as React from "react"
import { FormGroup } from "reactstrap"
import { AnalysisOptionsButton, LightButton } from "../common/Buttons"
import { ButtonBar, CheckGroup, HorizontalFormGroup } from "../common/Form"
import PluginOptionsModal from "../PluginOptionsModal"
import { EngineCapabilitiesPluginInfo, PluginObject, PluginOption } from "../../api/types"
import { PeekVariantType, PluginCategory } from "../../api/types/peekTypes"

type PluginsListFormProps = {
  contextId: string
  pluginsConfig: PluginOption[]
  pluginsInfo: EngineCapabilitiesPluginInfo[]
  pluginsList: PluginObject[]
  setPluginsConfig: (pluginsConfig: PluginOption[]) => void
  setPluginsList: (pluginsList: PluginObject[]) => void
}

type PluginsListFormState = {
  pluginConfig: PluginOption | null
  pluginInfo: EngineCapabilitiesPluginInfo | null
  showPluginsOptions: boolean
}

export class PluginsListForm extends React.PureComponent<
  PluginsListFormProps,
  PluginsListFormState
> {
  state: PluginsListFormState = {
    pluginConfig: null,
    pluginInfo: null,
    showPluginsOptions: false,
  }

  onChangePluginList = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = event.target.name
    const pluginsList = this.props.pluginsList.filter(
      (item: PluginObject) => !item.value.includes(id)
    )
    if (event.target.checked) {
      pluginsList.push({
        type: PeekVariantType.PEEK_VT_BSTR,
        value: `{${id}}`,
      })
    }
    this.props.setPluginsList(pluginsList)
  }

  onEnableAll = () => {
    const pluginsList = this.props.pluginsInfo
      .filter(
        (info: EngineCapabilitiesPluginInfo) =>
          Array.isArray(info.categoryIds) &&
          info.categoryIds.includes(PluginCategory.PLUGIN_CATEGORY_PEEK_PLUGIN)
      )
      .map((info: EngineCapabilitiesPluginInfo) => {
        return {
          type: PeekVariantType.PEEK_VT_BSTR,
          value: `{${info.clsid}}`,
        }
      })
    this.props.setPluginsList(pluginsList)
  }

  onDisableAll = () => {
    this.props.setPluginsList([])
  }

  onPluginOptions = (info: EngineCapabilitiesPluginInfo) => {
    let pluginConfig = this.props.pluginsConfig.find(
      (item: PluginOption) => item.clsid === info.clsid
    )
    if (!pluginConfig) {
      pluginConfig = {
        clsid: info.clsid,
        name: info.name,
        options: "",
      }
    }
    this.setState({
      pluginConfig,
      pluginInfo: info,
      showPluginsOptions: true,
    })
  }

  onPluginOptionsCancel = () => {
    this.setState({
      pluginConfig: null,
      pluginInfo: null,
      showPluginsOptions: false,
    })
  }

  onPluginOptionsOK = (option: PluginOption) => {
    const pluginsConfig = this.props.pluginsConfig.filter(
      (item: PluginOption) => item.clsid !== option.clsid
    )
    pluginsConfig.push(option)
    this.props.setPluginsConfig(pluginsConfig)

    this.setState({
      pluginConfig: null,
      pluginInfo: null,
      showPluginsOptions: false,
    })
  }

  render() {
    const { contextId, pluginsInfo, pluginsList } = this.props
    const { pluginConfig, pluginInfo, showPluginsOptions } = this.state

    const collator = new Intl.Collator(undefined, { sensitivity: "base" })
    const capturePluginsInfo = pluginsInfo
      .filter(
        (info: EngineCapabilitiesPluginInfo) =>
          Array.isArray(info.categoryIds) &&
          info.categoryIds.includes(PluginCategory.PLUGIN_CATEGORY_PEEK_PLUGIN)
      )
      .sort((a: EngineCapabilitiesPluginInfo, b: EngineCapabilitiesPluginInfo) =>
        collator.compare(a.name, b.name)
      )

    const rows = capturePluginsInfo.map((info: EngineCapabilitiesPluginInfo) => {
      const checked =
        pluginsList.findIndex((item: PluginObject) => item.value.includes(info.clsid)) !== -1
      return (
        <HorizontalFormGroup key={`${info.clsid}-group`} noMargin>
          <CheckGroup
            key={info.clsid}
            type="checkbox"
            name={info.clsid}
            id={info.clsid}
            onChange={this.onChangePluginList}
            checked={checked}
          >
            {info.name}
          </CheckGroup>
          {Array.isArray(info.categoryIds) &&
            info.categoryIds.includes(PluginCategory.PLUGIN_CATEGORY_OMNI_WEB_PLUGIN_OPTIONS) && (
              <AnalysisOptionsButton
                onClick={this.onPluginOptions.bind(this, info)}
                disabled={!checked}
                aria-label={`${info.name} options`}
              />
            )}
        </HorizontalFormGroup>
      )
    })

    return (
      <>
        <FormGroup>{rows}</FormGroup>
        <ButtonBar>
          <LightButton size="sm" onClick={this.onEnableAll}>
            Enable All
          </LightButton>
          <LightButton size="sm" onClick={this.onDisableAll}>
            Disable All
          </LightButton>
        </ButtonBar>
        {showPluginsOptions && pluginConfig && pluginInfo && (
          <PluginOptionsModal
            contextId={contextId}
            pluginConfig={pluginConfig}
            pluginInfo={pluginInfo}
            onOK={this.onPluginOptionsOK}
            onCancel={this.onPluginOptionsCancel}
          />
        )}
      </>
    )
  }
}
