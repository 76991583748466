import * as React from "react"
import { Col, Form, FormGroup, Row } from "reactstrap"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { CheckGroup, Label } from "../common/Form"
import { Input } from "../common/Input"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../common/Modal"
import { Select } from "../common/Select"

type PacketFileFormat = {
  fileDescription: string
  fileExt: string
}

const packetFileFormats: Array<PacketFileFormat> = [
  { fileDescription: "Omnipeek Packet File", fileExt: "pkt" },
  { fileDescription: "Omnipeek Packet File (compressed)", fileExt: "wpz" },
  { fileDescription: "Pcap (Wireshark, tcpdump, etc.)", fileExt: "pcap" },
  {
    fileDescription: "Pcap (Wireshark, tcpdump, etc.) (compressed)",
    fileExt: "pcap.gz",
  },
  { fileDescription: "PcapNG (Wireshark, etc.)", fileExt: "pcapng" },
  { fileDescription: "PcapNG (Wireshark, etc.) (compressed)", fileExt: "pcapng.gz" },
]

type PacketsDownloadModalProps = {
  hasSelectedPackets: boolean
  fileExt: string
  fileName: string
  onCancel: () => void
  onOK: (saveSelected: boolean, saveToEngine: boolean, fileName: string, fileExt: string) => void
}

type PacketsDownloadModalState = {
  fileExt: string
  fileName: string
  saveToEngine: boolean
  saveSelected: boolean
}

class PacketsDownloadModal extends React.Component<
  PacketsDownloadModalProps,
  PacketsDownloadModalState
> {
  state: PacketsDownloadModalState = {
    fileExt: this.props.fileExt,
    fileName: this.props.fileName,
    saveToEngine: false,
    saveSelected: false,
  }

  onChangePacketsType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    this.setState({
      saveSelected: id === "savePacketsTypeSelected",
    })
  }

  onChangeFileFormat = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState({
      fileExt: value,
    })
  }

  onChangeFileName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState({ fileName: value })
  }

  onChangeSaveLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    this.setState({ saveToEngine: id === "savePacketsLocationEngine" })
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const { onOK } = this.props
    const { fileExt, fileName, saveToEngine, saveSelected } = this.state
    onOK(saveSelected, saveToEngine, fileName, fileExt)
  }

  render() {
    const { hasSelectedPackets, onCancel } = this.props
    const { fileExt, fileName, saveToEngine, saveSelected } = this.state

    const optionsFormat = packetFileFormats.map((format: PacketFileFormat) => {
      return (
        <option key={format.fileExt} value={format.fileExt}>
          {`${format.fileDescription} (*.${format.fileExt})`}
        </option>
      )
    })

    return (
      <Modal size="md" isOpen={true}>
        <ModalHeader toggle={onCancel}>Save Packets</ModalHeader>
        <ModalBody>
          <Form id="packets-download-form" onSubmit={this.onSubmit}>
            <Row>
              <Col md={3}>
                <Label for="savePacketsLocation">Location</Label>
              </Col>
              <Col md={9}>
                <FormGroup>
                  <CheckGroup
                    type="radio"
                    name="savePacketsLocation"
                    id="savePacketsLocationComputer"
                    aria-label="Computer"
                    checked={!saveToEngine}
                    onChange={this.onChangeSaveLocation}
                  >
                    Download
                  </CheckGroup>
                  <CheckGroup
                    type="radio"
                    name="savePacketsLocation"
                    id="savePacketsLocationEngine"
                    aria-label="Engine"
                    checked={saveToEngine}
                    onChange={this.onChangeSaveLocation}
                  >
                    Save To Engine
                  </CheckGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label for="savePacketsType">Packets</Label>
              </Col>
              <Col md={9}>
                <FormGroup>
                  <CheckGroup
                    type="radio"
                    name="savePacketsType"
                    id="savePacketsTypeAll"
                    aria-label="All"
                    checked={!saveSelected}
                    onChange={this.onChangePacketsType}
                  >
                    Save All Packets
                  </CheckGroup>
                  <CheckGroup
                    type="radio"
                    name="savePacketsType"
                    id="savePacketsTypeSelected"
                    aria-label="Selected"
                    checked={saveSelected}
                    disabled={!hasSelectedPackets}
                    onChange={this.onChangePacketsType}
                  >
                    Save Selected Packets
                  </CheckGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label style={{ marginTop: "0.5rem" }} for="name">
                    File Name
                  </Label>
                </FormGroup>
              </Col>
              <Col md={9}>
                <FormGroup>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={fileName}
                    onChange={this.onChangeFileName}
                    invalid={fileName.length === 0}
                    spellCheck={false}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormGroup noMargin>
                  <Label style={{ marginTop: "0.5rem" }} for="format">
                    File Format
                  </Label>
                </FormGroup>
              </Col>
              <Col md={9}>
                <FormGroup noMargin>
                  <Select name="format" onChange={this.onChangeFileFormat} value={fileExt}>
                    {optionsFormat}
                  </Select>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton
            disabled={fileName.length === 0}
            type="submit"
            form="packets-download-form"
          >
            OK
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    )
  }
}

export default PacketsDownloadModal
