import * as React from "react"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Redirect, useRouteMatch } from "react-router-dom"
import { useTheme, DefaultTheme } from "styled-components"
import { Helmet } from "react-helmet"
import FontAwesome from "react-fontawesome"
import FileSaver from "file-saver"
import { TableCellProps } from "react-virtualized"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import ConfirmationModal from "../common/ConfirmationModal"
import { OmniTable } from "../common/OmniTable"
import {
  View,
  ViewHeaderItems,
  ViewContent,
  ViewHeader,
  ViewTitleStyle,
  ViewSubTitleStyle,
  ViewHeaderButtons,
} from "../common/View"
import { LightButton, LightDropdownToggle } from "../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../common/Dropdown"
import Interval from "../common/Interval"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import {
  getEngine,
  getAuthToken,
  getAuditLogSearch,
  getAuditLogRefreshInterval,
  getCapabilities,
  getShowLocalTime,
} from "../../store"
import { setAuditLogSearch, setAuditLogRefreshInterval } from "../../store/ui"
import { formatInteger, formatISODateTime } from "../../utils/formatUtils"
import csvStringify from "../../utils/csvStringify"
import { deleteAuditLog, fetchAuditLog } from "../../api/api"
import {
  AuditLogMessage,
  ResponseGetAuditLog,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { AuditLogSearch } from "./AuditLogSearch"
import AuditLogSearchPopover from "./AuditLogSearchPopover"
import AuditLogSearchResults from "./AuditLogSearchResults"
import { getDefaultAdminUrl } from "../../utils/engineUtils"

const columnDesc = [
  {
    dataKey: "timestamp",
    label: "Date/Time",
    width: 140,
    flexShrink: 0,
  },
  {
    dataKey: "client",
    label: "Client",
    width: 140,
    flexGrow: 1,
  },
  {
    dataKey: "user",
    label: "User",
    width: 140,
    flexGrow: 1,
  },
  {
    dataKey: "message",
    label: "Message",
    width: 400,
    flexGrow: 1,
  },
  {
    dataKey: "result",
    label: "Result",
    width: 180,
  },
]

const formatResult = (result: number) => {
  if (result === 0) {
    return "Success"
  } else if (result === -1) {
    return "Failed"
  } else {
    const errorCode = result >>> 0
    return `Failed (Error: ${errorCode.toString(16)})`
  }
}

const emptySearch: AuditLogSearch = {
  message: "",
  client: "",
  user: "",
  startTime: "",
  stopTime: "",
}

const AuditLogView: React.FC = () => {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const theme = useTheme() as DefaultTheme
  const engine = useSelector(getEngine)
  const authToken = useSelector(getAuthToken)
  const refreshInterval = useSelector(getAuditLogRefreshInterval) || 0
  const search = (useSelector(getAuditLogSearch) as AuditLogSearch) || emptySearch
  const engineCapabilities: ResponseGetEngineCapabilities = useSelector(getCapabilities)
  const showLocalTime = useSelector(getShowLocalTime)
  const [auditLog, setAuditLog] = useState<ResponseGetAuditLog | null>(null)
  const [refreshCount, setRefreshCount] = useState(0)
  const [showSearchPopover, setShowSearchPopover] = useState(false)
  const [showClearAuditLogConfirm, setShowClearAuditLogConfirm] = useState(false)
  let table: any | null = null

  useEffect(() => {
    fetchAuditLog(
      engine,
      authToken,
      search.client,
      null,
      null,
      search.message,
      search.startTime,
      search.stopTime,
      search.user
    )
      .then((auditLog: ResponseGetAuditLog) => {
        setAuditLog(auditLog)
      })
      .catch(error => {
        console.error(error)
      })
  }, [engine, authToken, refreshCount, search])

  const onRefresh = () => {
    setRefreshCount(refreshCount + 1)
  }

  const onRefreshIntervalChanged = (refreshInterval: number) => {
    dispatch(setAuditLogRefreshInterval(refreshInterval))
  }

  const onExport = () => {
    let csv = "Date/Time,Client,User,Message,Result\n"
    if (auditLog && auditLog.messages) {
      auditLog.messages.forEach((message: AuditLogMessage) => {
        const row = []
        row.push(message.timestamp)
        row.push(csvStringify(message.client))
        row.push(csvStringify(message.user))
        row.push(csvStringify(message.message))
        row.push(csvStringify(formatResult(message.result)))

        csv += row.join(",") + "\n"
      })
    }

    FileSaver.saveAs(new Blob([csv], { type: "text/plain;charset=utf8" }), "Audit Log.csv")
  }

  const onFirstMessage = () => {
    if (auditLog && auditLog.messages.length > 0) {
      table.table.scrollToRow(0)
    }
  }

  const onLastMessage = () => {
    if (auditLog && auditLog.messages.length > 0) {
      table.table.scrollToRow(auditLog.messages.length - 1)
    }
  }

  const onSearch = () => {
    setShowSearchPopover(!showSearchPopover)
  }

  const onSearchCancel = () => {
    setShowSearchPopover(false)
  }

  const onSearchOK = (search: AuditLogSearch) => {
    dispatch(setAuditLogSearch(search))
    setShowSearchPopover(false)
    onRefresh()
  }

  const onClearSearch = () => {
    onSearchOK(emptySearch)
  }

  const onClearAuditLog = () => {
    setShowClearAuditLogConfirm(true)
  }

  const onClearAuditLogCancel = () => {
    setShowClearAuditLogConfirm(false)
  }

  const onClearAuditLogOK = () => {
    setShowClearAuditLogConfirm(false)

    deleteAuditLog(engine, authToken)
      .then(() => {
        onRefresh()
      })
      .catch(error => {
        console.error(error)
      })
  }

  const getSearchTermColor = (i: number) =>
    theme.searchTermColors[i % theme.searchTermColors.length]

  const cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content
    switch (dataKey) {
      case "timestamp":
        content = formatISODateTime(cellData, 0, showLocalTime)
        break
      case "result":
        content = formatResult(cellData)
        break
      case "message":
        if (rowData.offsets) {
          let msg = cellData
          const offsets = rowData.offsets.split(" ")
          if (offsets.length && offsets.length % 4 === 0) {
            const parts = []
            let pos = 0
            for (let i = 0, len = offsets.length; i < len; i += 4) {
              const term = parseInt(offsets[i + 1], 10)
              const offset = parseInt(offsets[i + 2], 10)
              const size = parseInt(offsets[i + 3], 10)
              if (offset >= pos) {
                parts.push(
                  <span key={`${rowData.messageId}-${i}-b`}>{msg.slice(pos, offset)}</span>
                )
                if (size > 0) {
                  parts.push(
                    <span
                      key={`${rowData.messageId}-${i}-c`}
                      style={{ backgroundColor: getSearchTermColor(term) }}
                    >
                      {msg.slice(offset, offset + size)}
                    </span>
                  )
                }
              }
              pos = offset + size
            }
            if (pos + 1 < msg.length) {
              parts.push(<span key={`${rowData.messageId}-d`}>{msg.slice(pos)}</span>)
            }
            msg = parts
            content = msg
          }
        } else {
          content = cellData
        }
        break
      default:
        content = cellData
        break
    }
    return content
  }

  const rowStyle = ({ index }: { index: number }) => {
    if (auditLog && auditLog.messages && index >= 0) {
      const rowData = auditLog.messages[index]
      if (rowData.result !== 0) {
        return {
          color: theme.dangerColor,
          backgroundColor: theme.dangerBackgroundColor,
        }
      }
    }
    return null
  }

  if (
    !engineCapabilities?.userRights.policies.includes(EngineUserPolicies.viewAuditLog) ||
    !engineCapabilities?.capabilities.includes(EngineCapabilities.auditLog)
  ) {
    return <Redirect to={`${getDefaultAdminUrl(engineCapabilities)}`} />
  }

  const count = auditLog !== null && auditLog.counts ? auditLog.counts.total : 0
  const total = auditLog !== null && auditLog.allCounts ? auditLog.allCounts.total : 0
  return (
    <View>
      <ViewHeaderItems>
        <ViewHeader border={false} style={{ marginBottom: 0 }}>
          <Helmet title="Audit Log" />
          <BreadcrumbItem to={match.url} title="Audit Log" />
          <Interval
            timeout={refreshInterval * 1000}
            enabled={refreshInterval !== 0}
            callback={onRefresh}
          />
          {auditLog == null ? (
            <ViewTitleStyle>Audit Log</ViewTitleStyle>
          ) : count >= total ? (
            <ViewTitleStyle>
              Audit Log <ViewSubTitleStyle>({formatInteger(count)})</ViewSubTitleStyle>
            </ViewTitleStyle>
          ) : (
            <ViewTitleStyle>
              Audit Log{" "}
              <ViewSubTitleStyle>
                ({formatInteger(count)} of {formatInteger(total)})
              </ViewSubTitleStyle>
            </ViewTitleStyle>
          )}
          <ViewHeaderButtons>
            <LightButton
              aria-label="First message"
              id="first-message"
              onClick={onFirstMessage}
              disabled={!auditLog || auditLog.messages.length === 0}
            >
              <FontAwesome name="step-backward" />
            </LightButton>
            <UncontrolledTooltip
              placement="top"
              target="first-message"
              disabled={!auditLog || auditLog.messages.length === 0}
            >
              First Event
            </UncontrolledTooltip>
            <LightButton
              aria-label="Last message"
              id="last-message"
              onClick={onLastMessage}
              disabled={!auditLog || auditLog.messages.length === 0}
            >
              <FontAwesome name="step-forward" />
            </LightButton>
            <UncontrolledTooltip
              placement="top"
              target="last-message"
              disabled={!auditLog || auditLog.messages.length === 0}
            >
              Last Event
            </UncontrolledTooltip>
            <LightButton
              id="search"
              onClick={() => {
                onSearch()
              }}
            >
              Search
            </LightButton>
            {auditLog && showSearchPopover ? (
              <AuditLogSearchPopover
                isOpen={true}
                target="search"
                search={search}
                firstTimestamp={auditLog.counts?.firstTimestamp}
                lastTimestamp={auditLog.counts?.lastTimestamp}
                onOK={onSearchOK}
                onCancel={onSearchCancel}
              />
            ) : null}
            <LightButton
              id="clear-log"
              onClick={() => {
                setShowSearchPopover(false)
                onClearAuditLog()
              }}
            >
              Clear Log
            </LightButton>
            <LightButton
              aria-label="Export"
              id="export"
              onClick={() => {
                setShowSearchPopover(false)
                onExport()
              }}
            >
              <FontAwesome name="download" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="export">
              Export
            </UncontrolledTooltip>
            <UncontrolledDropdown group>
              <LightButton
                aria-label="Refresh"
                id="refresh"
                onClick={() => {
                  setShowSearchPopover(false)
                  onRefresh()
                }}
              >
                <FontAwesome name="refresh" />
              </LightButton>
              <UncontrolledTooltip placement="top" target="refresh">
                Refresh
              </UncontrolledTooltip>
              <LightDropdownToggle split />
              <DropdownMenu end>
                <DropdownItem
                  active={refreshInterval === 0}
                  onClick={() => {
                    onRefreshIntervalChanged(0)
                  }}
                >
                  Manual
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 5}
                  onClick={() => {
                    onRefreshIntervalChanged(5)
                  }}
                >
                  5 Sec.
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 10}
                  onClick={() => {
                    onRefreshIntervalChanged(10)
                  }}
                >
                  10 Sec.
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 30}
                  onClick={() => {
                    onRefreshIntervalChanged(30)
                  }}
                >
                  30 Sec.
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 60}
                  onClick={() => {
                    onRefreshIntervalChanged(60)
                  }}
                >
                  1 Min.
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 120}
                  onClick={() => {
                    onRefreshIntervalChanged(120)
                  }}
                >
                  2 Min.
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 300}
                  onClick={() => {
                    onRefreshIntervalChanged(300)
                  }}
                >
                  5 Min.
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 600}
                  onClick={() => {
                    onRefreshIntervalChanged(600)
                  }}
                >
                  10 Min.
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ViewHeaderButtons>
        </ViewHeader>
        <AuditLogSearchResults search={search} onClearSearch={onClearSearch} />
      </ViewHeaderItems>
      <ViewContent>
        {auditLog && auditLog.messages && (
          <OmniTable
            ref={ref => {
              table = ref
            }}
            data={auditLog.messages}
            rowCount={auditLog.messages.length}
            columnDesc={columnDesc}
            cellRenderer={cellRenderer}
            rowStyle={rowStyle}
          />
        )}
      </ViewContent>
      {showClearAuditLogConfirm && (
        <ConfirmationModal
          message="Are you sure you want to clear the audit log?"
          onNo={onClearAuditLogCancel}
          onYes={onClearAuditLogOK}
          show={showClearAuditLogConfirm}
          title="Clear Audit Log"
        />
      )}
    </View>
  )
}

export default AuditLogView
