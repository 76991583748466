import * as React from "react"
import styled, { useTheme, DefaultTheme } from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import FontAwesome from "react-fontawesome"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../common/Dropdown"
import { LightDropdownToggle } from "../common/Buttons"
import PropTable from "../common/PropTable"
import { PopoverHeader, PopoverBody } from "../common/Popover"
import { UncontrolledPopover } from "../common/UncontrolledPopover"
import { getShowAddressNames, getShowLocalTime, getShowPortNames } from "../../store"
import { getMSALadderViewOptions } from "../../store"
import { setMSALadderViewOptions } from "../../store/ui"
import {
  formatDuration,
  formatInteger,
  formatISODateTime,
  formatISOTime,
  formatTime,
  peekFromDate,
} from "../../utils/formatUtils"
import { measureText } from "../../utils/measureText"
import { useClientSize } from "./useClientSize"
import * as Colors from "../../themes/colorScheme"
import {
  MSAProjectFlowInfo,
  MSAProjectPacketInfo,
  MSAProjectProperties,
  MSAProjectSegmentInfo,
} from "../../api/types"

const Outer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const Inner = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  background-color: ${props => props.theme.tableBackgroundColor};
  border-left: 1px solid ${props => props.theme.tableBorderColor};
  border-bottom: 1px solid ${props => props.theme.tableBorderColor};
  border-right: 1px solid ${props => props.theme.tableBorderColor};

  & canvas {
    display: block;
    image-rendering: pixelated;
  }
`

const Scroller = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  cursor: default;
  outline: none;
  border: none;
`

function packetPropToLabel(columnId: string): string {
  switch (columnId) {
    case "ipId":
      return "IP ID"
    case "ttl":
      return "TTL"
    case "seq":
      return "Seq Number"
    case "ack":
      return "Ack Number"
    case "tcpWindow":
      return "Window"
    case "payloadLen":
      return "Payload Length"
    case "tcpFlags":
      return "TCP Flags"
    case "delay":
      return "Delay"
    case "prevDelay":
      return "Previous Delay"
    case "nextDelay":
      return "Next Delay"
    case "time":
      return "Time"
    case "absTime":
      return "Time"
    case "flowTime":
      return "Flow Time"
    case "deltaTime":
      return "Delta Time"
    default:
      break
  }
  return ""
}

type LocalTheme = {
  segmentBackgroundColor: string
  segmentTitleBackgroundColor: string
  ladderBackgroundColor: string
  ladderBorderColor: string
  minorTickColor: string
  majorTickColor: string
  scaleTextColor: string
  packetShadowColor: string
  packetNumberColor: string
  packetBackgroundColor: string
  openPacketColor: string
  closePacketColor: string
  normalPacketColor: string
  normalPacketNoPayloadColor: string
  lostPacketColor: string
  hotPacketColor: string
  hotArrowColor: string
  selectedPacketColor: string
}

const localLightTheme: LocalTheme = {
  segmentBackgroundColor: "hsl(213,20%,99%)",
  segmentTitleBackgroundColor: "#fff",
  ladderBackgroundColor: Colors.gray200,
  ladderBorderColor: Colors.gray500,
  minorTickColor: Colors.gray500,
  majorTickColor: Colors.gray500,
  scaleTextColor: Colors.gray700,
  packetShadowColor: Colors.gray600,
  packetNumberColor: "#fff",
  packetBackgroundColor: "#fff",
  openPacketColor: Colors.green700,
  closePacketColor: Colors.red900,
  normalPacketColor: Colors.gray900,
  normalPacketNoPayloadColor: Colors.gray700,
  lostPacketColor: Colors.red600,
  hotPacketColor: Colors.blue200,
  hotArrowColor: Colors.blue400,
  selectedPacketColor: Colors.blue200,
}

const localDarkTheme: LocalTheme = {
  segmentBackgroundColor: Colors.gray1300,
  segmentTitleBackgroundColor: Colors.gray1400,
  ladderBackgroundColor: Colors.gray1200,
  ladderBorderColor: Colors.gray1000,
  minorTickColor: Colors.gray1000,
  majorTickColor: Colors.gray1000,
  scaleTextColor: Colors.gray700,
  packetShadowColor: "#000",
  packetNumberColor: Colors.gray500,
  packetBackgroundColor: Colors.gray1400,
  openPacketColor: Colors.green800,
  closePacketColor: Colors.red900,
  normalPacketColor: Colors.gray800,
  normalPacketNoPayloadColor: Colors.gray1000,
  lostPacketColor: Colors.red600,
  hotPacketColor: Colors.blue500,
  hotArrowColor: Colors.blue400,
  selectedPacketColor: Colors.blue500,
}

type ViewOptions = {
  timeScale: number
  showScaleLabels: boolean
  showTooltips: boolean
  showFlowTime: boolean
  showFlowPacketNumbers: boolean
  showRelativeSeqAck: boolean
  showIPID: boolean
  showTTL: boolean
  showTCPFlags: boolean
  showSeqNum: boolean
  showAckNum: boolean
  showTCPWindow: boolean
  showPayloadLength: boolean
  showDelay: boolean
  showTime: boolean
  showDeltaTime: boolean
}

const defaultViewOptions: ViewOptions = {
  timeScale: 10000000,
  showScaleLabels: true,
  showTooltips: true,
  showFlowTime: true,
  showFlowPacketNumbers: true,
  showRelativeSeqAck: true,
  showIPID: false,
  showTTL: true,
  showTCPFlags: true,
  showSeqNum: false,
  showAckNum: false,
  showTCPWindow: false,
  showPayloadLength: false,
  showDelay: false,
  showTime: false,
  showDeltaTime: false,
}

type Point = {
  x: number
  y: number
}

type Size = {
  w: number
  h: number
}

type Rect = {
  x: number
  y: number
  w: number
  h: number
}

type Box = {
  left: number
  top: number
  right: number
  bottom: number
}

type LadderDiagramProps = {
  project: MSAProjectProperties
  flowEntryIndex: number
}

type LayoutItem = {
  iSegFlow: number
  rect: Rect
  pos: number
  packetInfo: MSAProjectPacketInfo
  flowEntryIndex: number
  flowEntryTime: number
  deltaTime: number
  itemList: Array<LayoutItem | null>
}

type SegFlowInfo = {
  segInfo: MSAProjectSegmentInfo
  flow?: MSAProjectFlowInfo
  clientBaseSeqNum: number
  serverBaseSeqNum: number
  items: LayoutItem[]
}

type ViewState = {
  segInfo: SegFlowInfo[]
  earliestTime: string
  latestTime: string
  duration: number
  totalPackets: number
  maxPackets: number
  containerWidth: number
  timeScale: number
  showTooltips: boolean
  segmentWidth: number
  imageWidth: number
  imageHeight: number
}

// TCP Flags
const FLAG_FIN = 0x01
const FLAG_SYN = 0x02
const FLAG_RST = 0x04
const FLAG_PSH = 0x08
const FLAG_ACK = 0x10
const FLAG_URG = 0x20

// Constants
const dpr = window.devicePixelRatio || 1
const pxAlign = dpr - Math.floor(dpr) === 0 ? 0.5 : 0
const pxRound = (px: number) => Math.floor(px) + pxAlign
const cxMinSegmentWidth = 220
const cxLadderWidth = 120
const cyHeader = 28
const cyTopMargin = 20
const cyBottomMargin = 20
const cxMajorTick = 6
const cxMinorTick = 3
const pxPerTick = 5
const cyPacketHeight = 18
const cyHalfPacketHeight = cyPacketHeight / 2
const arrowHeadLen = 7
const arrowNotchLen = 5
const arrowHeadAngle = (30 * Math.PI) / 180
const arrowHeadHypLen = Math.abs(arrowHeadLen / Math.cos(arrowHeadAngle))

const LadderDiagram = ({ project, flowEntryIndex }: LadderDiagramProps) => {
  const dispatch = useDispatch()
  const theme = useTheme() as DefaultTheme
  const localTheme = theme.name === "Light" ? localLightTheme : localDarkTheme
  const refDiagram = React.useRef<HTMLDivElement>(null)
  const [refScroller, { width: containerWidth, height: containerHeight }] =
    useClientSize<HTMLDivElement>()
  const refScrollToDiv = React.useRef<HTMLDivElement>(null)
  const refToolTipDiv = React.useRef<HTMLDivElement>(null)
  const refCanvas = React.useRef<HTMLCanvasElement | null>(null)
  const showAddressNames: boolean = useSelector(getShowAddressNames)
  const showPortNames: boolean = useSelector(getShowPortNames)
  const showLocalTime = useSelector(getShowLocalTime)
  const viewOptionsBase: ViewOptions = useSelector(getMSALadderViewOptions)
  const [viewState, setViewState] = React.useState<ViewState | null>(null)
  const [origin, setOrigin] = React.useState<Point>({ x: 0, y: 0 })
  const [hotItem, setHotItem] = React.useState<LayoutItem | null>(null)
  const [selectedItem, setSelectedItem] = React.useState<LayoutItem | null>(null)

  const flowEntry = project.flowEntries ? project.flowEntries[flowEntryIndex] : undefined
  const isTCP = flowEntry?.ipProtocol === 6

  const viewOptions = React.useMemo(() => {
    return {
      ...defaultViewOptions,
      ...viewOptionsBase,
    }
  }, [viewOptionsBase])

  const scrollItemIntoView = (item: LayoutItem) => {
    const el = refScrollToDiv.current
    if (el) {
      el.style.left = `${item.rect.x}px`
      el.style.top = `${item.rect.y}px`
      el.style.right = `${item.rect.x + item.rect.w}px`
      el.style.bottom = `${item.rect.y + item.rect.h}px`
      el.style.width = `${item.rect.w}px`
      el.style.height = `${item.rect.h}px`
      el.scrollIntoView({ block: "nearest" })
    }
  }

  const hitTest = (x: number, y: number): LayoutItem | null => {
    if (viewState) {
      for (let iSeg = 0; iSeg < viewState.segInfo.length; iSeg++) {
        const segFlowInfo = viewState.segInfo[iSeg]
        // TODO: this could be optimized by skipping entire segments
        for (let iItem = 0; iItem < segFlowInfo.items.length; iItem++) {
          const item = segFlowInfo.items[iItem]
          if (
            x >= item.rect.x &&
            x <= item.rect.x + item.rect.w &&
            y >= item.rect.y &&
            y <= item.rect.y + item.rect.h
          ) {
            return item
          }
        }
      }
    }
    return null
  }

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (refCanvas.current) {
      const rect = refCanvas.current.getBoundingClientRect()
      const x = event.clientX - rect.left + origin.x
      const y = event.clientY - rect.top + origin.y
      const item = hitTest(x, y)
      setSelectedItem(item)
    }
  }

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (refCanvas.current) {
      const rect = refCanvas.current.getBoundingClientRect()
      const x = event.clientX - rect.left + origin.x
      const y = event.clientY - rect.top + origin.y
      const item = hitTest(x, y)
      if (item && item !== hotItem) {
        const el = refToolTipDiv.current
        if (el) {
          el.style.left = `${item.rect.x}px`
          el.style.top = `${item.rect.y}px`
          el.style.right = `${item.rect.x + item.rect.w}px`
          el.style.bottom = `${item.rect.y + item.rect.h}px`
          el.style.width = `${item.rect.w}px`
          el.style.height = `${item.rect.h}px`
        }
      }
      setHotItem(item)
    }
  }

  const onZoomOut = () => {
    let timeScale = viewOptions.timeScale
    switch (timeScale) {
      case 50000000: // 50 ms
        timeScale = 100000000 // 100 ms
        break
      case 10000000: // 10 ms
        timeScale = 50000000 // 50 ms
        break
      case 5000000: // 5 ms
        timeScale = 10000000 // 10 ms
        break
      case 1000000: // 1 ms
        timeScale = 5000000 // 5 ms
        break
      case 500000: // 500 us
        timeScale = 1000000 // 10 ms
        break
      case 100000: // 100 us
        timeScale = 500000 // 500 us
        break
    }
    dispatch(setMSALadderViewOptions({ ...viewOptions, timeScale }))
  }

  const onZoomIn = () => {
    let timeScale = viewOptions.timeScale
    switch (timeScale) {
      case 100000000: // 100 ms
        timeScale = 50000000 // 50 ms
        break
      case 50000000: // 50 ms
        timeScale = 10000000 // 10 ms
        break
      case 10000000: // 10 ms
        timeScale = 5000000 // 5 ms
        break
      case 5000000: // 5 ms
        timeScale = 1000000 // 1 ms
        break
      case 1000000: // 1 ms
        timeScale = 500000 // 500 us
        break
      case 500000: // 500 us
        timeScale = 100000 // 100 us
        break
    }
    dispatch(setMSALadderViewOptions({ ...viewOptions, timeScale }))
  }

  const onScroll = (event: React.MouseEvent<HTMLDivElement>) => {
    const { clientWidth, clientHeight, scrollWidth, scrollHeight, scrollLeft, scrollTop } =
      event.currentTarget
    setOrigin(() => {
      // Prevent Safari's elastic scrolling from causing visual shaking when scrolling past bounds.
      const x = Math.max(0, Math.min(scrollLeft, scrollWidth - clientWidth))

      // Prevent Safari's elastic scrolling from causing visual shaking when scrolling past bounds.
      const y = Math.max(0, Math.min(scrollTop, scrollHeight - clientHeight))

      return { x, y }
    })
  }

  const onWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    if (event.shiftKey) {
      if (event.deltaY > 0) {
        onZoomOut()
      } else if (event.deltaY < 0) {
        onZoomIn()
      }
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case "-":
        onZoomOut()
        break
      case "+":
        onZoomIn()
        break
      case "Escape":
        setSelectedItem(null)
        break
      case "ArrowDown":
        event.preventDefault()
        if (viewState && selectedItem) {
          const items = viewState.segInfo[selectedItem.iSegFlow].items
          for (let iItem = 0; iItem < items.length; iItem++) {
            if (selectedItem === items[iItem]) {
              if (iItem + 1 < items.length) {
                const nextItem = items[iItem + 1]
                setSelectedItem(nextItem)
                scrollItemIntoView(nextItem)
                break
              }
            }
          }
        }
        break
      case "ArrowUp":
        event.preventDefault()
        if (viewState && selectedItem) {
          const items = viewState.segInfo[selectedItem.iSegFlow].items
          for (let iItem = 0; iItem < items.length; iItem++) {
            if (selectedItem === items[iItem]) {
              if (iItem > 0) {
                const nextItem = items[iItem - 1]
                setSelectedItem(nextItem)
                scrollItemIntoView(nextItem)
                break
              }
            }
          }
        }
        break
      case "ArrowLeft":
        event.preventDefault()
        if (viewState && selectedItem) {
          for (let iSeg = selectedItem.iSegFlow - 1; iSeg >= 0; iSeg--) {
            const item = selectedItem.itemList[iSeg]
            if (item !== null) {
              setSelectedItem(item)
              scrollItemIntoView(item)
              break
            }
          }
        }
        break
      case "ArrowRight":
        event.preventDefault()
        if (viewState && selectedItem) {
          for (let iSeg = selectedItem.iSegFlow + 1; iSeg < selectedItem.itemList.length; iSeg++) {
            const item = selectedItem.itemList[iSeg]
            if (item !== null) {
              setSelectedItem(item)
              scrollItemIntoView(item)
              break
            }
          }
        }
        break
    }
  }

  const getSegmentProp = (prop: string, data: any) => {
    const item = data as SegFlowInfo
    const flowInfo = item.flow
    if (!flowInfo) return null
    switch (prop) {
      case "Client": {
        const address = (showAddressNames && flowInfo.clientAddressName) || flowInfo.clientAddress
        const port = (showPortNames && flowInfo.clientPortName) || flowInfo.clientPort
        return `${address}:${port}`
      }
      case "Server": {
        const address = (showAddressNames && flowInfo.serverAddressName) || flowInfo.serverAddress
        const port = (showPortNames && flowInfo.serverPortName) || flowInfo.serverPort
        return `${address}:${port}`
      }
      case "Packets":
        return formatInteger(flowInfo.packets)
      case "Client Packets":
        return formatInteger(flowInfo.clientPackets)
      case "Server Packets":
        return formatInteger(flowInfo.serverPackets)
      case "Packets Lost":
        return formatInteger(flowInfo.nextLostPackets + flowInfo.prevLostPackets)
      case "Client Packets Lost":
        return formatInteger(flowInfo.nextLostPackets)
      case "Server Packets Lost":
        return formatInteger(flowInfo.prevLostPackets)
      case "Client Retransmissions":
        if (isTCP) {
          return formatInteger(flowInfo.clientRetransmissions)
        }
        break
      case "Server Retransmissions":
        if (isTCP) {
          return formatInteger(flowInfo.serverRetransmissions)
        }
        break
      case "Start":
        return formatISODateTime(flowInfo.startTime, 6, showLocalTime)
      case "Finish":
        return formatISODateTime(flowInfo.endTime, 6, showLocalTime)
      case "Duration":
        return formatDuration(flowInfo.duration, 6)
      case "TCP Status":
        if (isTCP) {
          return flowInfo.tcpStatus
        }
        break
    }
    return null
  }

  const getPacketPopoverTitle = () => {
    if (viewState && hotItem) {
      const packetNumber = viewOptions.showFlowPacketNumbers
        ? hotItem.flowEntryIndex + 1
        : hotItem.packetInfo.packetNumber
      return `${project.segments[hotItem.iSegFlow].name} \u2014 Packet ${packetNumber}`
    }
    return ""
  }

  const getPacketProp = React.useCallback(
    (prop: string, data: any): string | null => {
      const item = data as LayoutItem
      if (!item) return null
      switch (prop) {
        case "ipId":
          return item.packetInfo.ipId.toString()
        case "ttl":
          return item.packetInfo.ttl.toString()
        case "seq":
          {
            if (isTCP) {
              let seq = item.packetInfo.tcpSeqNum
              if (viewOptions.showRelativeSeqAck && viewState) {
                const segInfo = viewState.segInfo[item.iSegFlow]
                if (item.packetInfo.fromServer) {
                  seq -= segInfo.serverBaseSeqNum
                } else {
                  seq -= segInfo.clientBaseSeqNum
                }
              }
              return seq.toString()
            }
          }
          break
        case "ack":
          if (isTCP) {
            if ((item.packetInfo.tcpFlags & FLAG_ACK) !== 0) {
              let ack = item.packetInfo.tcpAckNum
              if (viewOptions.showRelativeSeqAck && viewState) {
                const segInfo = viewState.segInfo[item.iSegFlow]
                if (item.packetInfo.fromServer) {
                  ack -= segInfo.clientBaseSeqNum
                } else {
                  ack -= segInfo.serverBaseSeqNum
                }
              }
              return ack.toString()
            }
          }
          break
        case "tcpWindow":
          if (isTCP) {
            return item.packetInfo.tcpWindow.toString()
          }
          break
        case "payloadLen":
          return item.packetInfo.payloadLen.toString()
        case "tcpFlags":
          if (isTCP) {
            const tcpFlags: string[] = []
            if ((item.packetInfo.tcpFlags & FLAG_SYN) !== 0) tcpFlags.push("SYN")
            if ((item.packetInfo.tcpFlags & FLAG_ACK) !== 0) tcpFlags.push("ACK")
            if ((item.packetInfo.tcpFlags & FLAG_PSH) !== 0) tcpFlags.push("PSH")
            if ((item.packetInfo.tcpFlags & FLAG_URG) !== 0) tcpFlags.push("URG")
            if ((item.packetInfo.tcpFlags & FLAG_RST) !== 0) tcpFlags.push("RST")
            if ((item.packetInfo.tcpFlags & FLAG_FIN) !== 0) tcpFlags.push("FIN")
            if (tcpFlags.length > 0) {
              return tcpFlags.join(" ")
            }
          }
          break
        case "delay":
          {
            let prevDelay = ""
            if (item.iSegFlow > 0) {
              const prevItem = item.itemList[item.iSegFlow - 1]
              if (prevItem) {
                let d = 0
                if (item.packetInfo.fromServer) {
                  d = prevItem.flowEntryTime - item.flowEntryTime
                } else {
                  d = item.flowEntryTime - prevItem.flowEntryTime
                }
                if (d >= 0) {
                  prevDelay = formatDuration(d, 6)
                } else {
                  prevDelay = "invalid"
                }
              }
            }
            let nextDelay = ""
            if (item.iSegFlow + 1 < item.itemList.length) {
              const nextItem = item.itemList[item.iSegFlow + 1]
              if (nextItem) {
                let d = 0
                if (item.packetInfo.fromServer) {
                  d = item.flowEntryTime - nextItem.flowEntryTime
                } else {
                  d = nextItem.flowEntryTime - item.flowEntryTime
                }
                if (d >= 0) {
                  nextDelay = formatDuration(d, 6)
                } else {
                  nextDelay = "invalid"
                }
              }
            }
            if (prevDelay.length > 0 && nextDelay.length > 0) {
              return `${prevDelay},${nextDelay}`
            } else if (prevDelay.length > 0) {
              return prevDelay
            } else if (nextDelay.length > 0) {
              return nextDelay
            }
          }
          break
        case "prevDelay":
          if (item.iSegFlow > 0) {
            const prevItem = item.itemList[item.iSegFlow - 1]
            if (prevItem) {
              let d = 0
              if (item.packetInfo.fromServer) {
                d = prevItem.flowEntryTime - item.flowEntryTime
              } else {
                d = item.flowEntryTime - prevItem.flowEntryTime
              }
              if (d >= 0) {
                return formatDuration(d, 6)
              } else {
                return "invalid"
              }
            }
          }
          break
        case "nextDelay":
          if (item.iSegFlow + 1 < item.itemList.length) {
            const nextItem = item.itemList[item.iSegFlow + 1]
            if (nextItem) {
              let d = 0
              if (item.packetInfo.fromServer) {
                d = item.flowEntryTime - nextItem.flowEntryTime
              } else {
                d = nextItem.flowEntryTime - item.flowEntryTime
              }
              if (d >= 0) {
                return formatDuration(d, 6)
              } else {
                return "invalid"
              }
            }
          }
          break
        case "time":
          if (viewOptions.showFlowTime) {
            return formatDuration(item.flowEntryTime, 6)
          } else {
            return formatISOTime(item.packetInfo.adjustedTimestamp, 6, showLocalTime)
          }
        case "absTime":
          return formatISOTime(item.packetInfo.adjustedTimestamp, 6, showLocalTime)
        case "flowTime":
          return formatDuration(item.flowEntryTime, 6)
        case "deltaTime":
          if (item.packetInfo.flowTime !== 0) {
            return formatDuration(item.deltaTime, 6)
          }
      }
      return null
    },
    [viewState, viewOptions, isTCP, showLocalTime]
  )

  const getPacketDetails = React.useCallback(
    (item: LayoutItem) => {
      const details: string[] = []

      if (viewOptions.showIPID) {
        const prop = getPacketProp("ipId", item)
        if (prop) {
          details.push(`IPID:${prop}`)
        }
      }
      if (viewOptions.showTTL) {
        const prop = getPacketProp("ttl", item)
        if (prop) {
          details.push(`TTL:${prop}`)
        }
      }
      if (viewOptions.showSeqNum) {
        const prop = getPacketProp("seq", item)
        if (prop) {
          details.push(`Seq:${prop}`)
        }
      }
      if (viewOptions.showAckNum) {
        const prop = getPacketProp("ack", item)
        if (prop) {
          details.push(`Ack:${prop}`)
        }
      }
      if (viewOptions.showTCPWindow) {
        const prop = getPacketProp("tcpWindow", item)
        if (prop) {
          details.push(`Win:${prop}`)
        }
      }
      if (viewOptions.showPayloadLength) {
        const prop = getPacketProp("payloadLen", item)
        if (prop) {
          details.push(`Len:${prop}`)
        }
      }
      if (viewOptions.showTCPFlags) {
        const prop = getPacketProp("tcpFlags", item)
        if (prop) {
          details.push(prop)
        }
      }

      if (viewOptions.showDelay) {
        const prop = getPacketProp("delay", item)
        if (prop) {
          details.push(`Delay:${prop}`)
        }
      }
      if (viewOptions.showTime) {
        const prop = getPacketProp("time", item)
        if (prop) {
          details.push(`Time:${prop}`)
        }
      }
      if (viewOptions.showDeltaTime) {
        const prop = getPacketProp("deltaTime", item)
        if (prop) {
          details.push(`Delta:${prop}`)
        }
      }

      return details.join(", ")
    },
    [viewOptions, getPacketProp]
  )

  // Rebuild the entire view state for the selected flow.
  React.useEffect(() => {
    if (project.segmentInfo && flowEntry) {
      const newViewState: ViewState = {
        segInfo: [],
        earliestTime: flowEntry.earliestTime,
        latestTime: flowEntry.latestTime,
        duration: flowEntry.duration,
        totalPackets: 0,
        maxPackets: 0,
        containerWidth: 0,
        timeScale: 0,
        showTooltips: true,
        segmentWidth: 0,
        imageWidth: 0,
        imageHeight: 0,
      }

      // Pass 1: mirror the flow entry data structure and collect info.
      for (let iSeg = 0, nSeg = project.segmentInfo.length; iSeg < nSeg; iSeg++) {
        const segInfo = project.segmentInfo[iSeg]
        const flowId = flowEntry.flowList[iSeg]
        const flowInfo = segInfo.flows.find(flowInfo => flowInfo.id === flowId)

        const newSegFlowInfo: SegFlowInfo = {
          segInfo: segInfo,
          flow: flowInfo,
          clientBaseSeqNum: 0,
          serverBaseSeqNum: 0,
          items: [],
        }

        if (flowInfo) {
          let lastFlowTime = 0
          let bClientSeqNum = false
          let bServerSeqNum = false

          const nPacketInfo = flowInfo.packetInfo.length
          for (let iPacketInfo = 0; iPacketInfo < nPacketInfo; iPacketInfo++) {
            const packetInfo = flowInfo.packetInfo[iPacketInfo]

            const item: LayoutItem = {
              iSegFlow: iSeg,
              rect: { x: 0, y: 0, w: 0, h: 0 },
              pos: 0,
              packetInfo: packetInfo,
              flowEntryIndex: iPacketInfo,
              flowEntryTime: packetInfo.flowTime + flowInfo.flowEntryOffset,
              deltaTime: packetInfo.flowTime - lastFlowTime,
              itemList: new Array<LayoutItem | null>(packetInfo.packetList.length).fill(null),
            }

            lastFlowTime = packetInfo.flowTime

            // TODO: instead of min, use the value from the SYN packet?
            if (packetInfo.fromServer) {
              if (!bServerSeqNum) {
                newSegFlowInfo.serverBaseSeqNum = packetInfo.tcpSeqNum
                bServerSeqNum = true
              } else {
                newSegFlowInfo.serverBaseSeqNum = Math.min(
                  newSegFlowInfo.serverBaseSeqNum,
                  packetInfo.tcpSeqNum
                )
              }
            } else {
              if (!bClientSeqNum) {
                newSegFlowInfo.clientBaseSeqNum = packetInfo.tcpSeqNum
                bClientSeqNum = true
              } else {
                newSegFlowInfo.clientBaseSeqNum = Math.min(
                  newSegFlowInfo.clientBaseSeqNum,
                  packetInfo.tcpSeqNum
                )
              }
            }

            newSegFlowInfo.items.push(item)
          }

          newViewState.totalPackets += nPacketInfo
          newViewState.maxPackets = Math.max(newViewState.maxPackets, nPacketInfo)
        }

        newViewState.segInfo.push(newSegFlowInfo)
      }

      // Pass 2: hook up the item lists.
      for (let iSeg = 0, nSeg = newViewState.segInfo.length; iSeg < nSeg; iSeg++) {
        const segFlowInfo = newViewState.segInfo[iSeg]
        if (segFlowInfo.items.length > 0) {
          for (let iOtherSeg = 0; iOtherSeg < nSeg; iOtherSeg++) {
            if (iOtherSeg !== iSeg) {
              const otherSegFlowInfo = newViewState.segInfo[iOtherSeg]
              for (const item of segFlowInfo.items) {
                for (const otherItem of otherSegFlowInfo.items) {
                  if (item.packetInfo.packetList[iOtherSeg] === otherItem.packetInfo.id) {
                    item.itemList[iOtherSeg] = otherItem
                    otherItem.itemList[iSeg] = item
                    break
                  }
                }
              }
            }
          }
        }
      }

      setViewState(newViewState)
    } else {
      setViewState(null)
    }
  }, [project.segmentInfo, flowEntry])

  // Recalc layout.
  React.useEffect(() => {
    if (
      viewState &&
      (viewState.containerWidth !== containerWidth ||
        viewState.timeScale !== viewOptions.timeScale ||
        viewState.showTooltips !== viewOptions.showTooltips)
    ) {
      const newViewState: ViewState = {
        segInfo: viewState.segInfo,
        earliestTime: viewState.earliestTime,
        latestTime: viewState.latestTime,
        duration: viewState.duration,
        totalPackets: viewState.totalPackets,
        maxPackets: viewState.maxPackets,
        containerWidth: containerWidth,
        timeScale: viewOptions.timeScale,
        showTooltips: viewOptions.showTooltips,
        segmentWidth: 0,
        imageWidth: 0,
        imageHeight: 0,
      }

      // Calculate the segment width.
      const nSeg = newViewState.segInfo.length
      newViewState.segmentWidth = Math.max(
        Math.round(((newViewState.containerWidth - (nSeg - 1) * cxLadderWidth) / nSeg) * 1000) /
          1000,
        cxMinSegmentWidth
      )

      // Calculate the size of the image (may be adjusted below).
      newViewState.imageWidth = nSeg * newViewState.segmentWidth + (nSeg - 1) * cxLadderWidth
      newViewState.imageHeight = (newViewState.duration / newViewState.timeScale) * pxPerTick
      newViewState.imageHeight += cyHeader
      newViewState.imageHeight += cyTopMargin
      newViewState.imageHeight += cyBottomMargin

      // Calculate the packets boxes.
      for (let iSeg = 0; iSeg < nSeg; iSeg++) {
        const segFlowInfo = newViewState.segInfo[iSeg]
        const bFirstSegment = iSeg === 0
        const bLastSegment = iSeg === nSeg - 1
        const xSegment = iSeg * (newViewState.segmentWidth + cxLadderWidth)

        let prevBottom = 0
        for (let iItem = 0; iItem < segFlowInfo.items.length; iItem++) {
          const item = segFlowInfo.items[iItem]

          item.pos =
            cyHeader + cyTopMargin + (item.flowEntryTime / newViewState.timeScale) * pxPerTick

          item.rect.x = xSegment
          item.rect.y = item.pos - cyHalfPacketHeight
          item.rect.w = newViewState.segmentWidth
          item.rect.h = cyPacketHeight

          if (bFirstSegment) {
            item.rect.x += 8
            item.rect.w -= 36
          } else if (bLastSegment) {
            item.rect.x += 36
            item.rect.w -= 48
          } else {
            item.rect.x += 36
            item.rect.w -= 72
          }

          if (item.rect.y < prevBottom + 4) {
            item.rect.y = prevBottom + 4
          }
          prevBottom = item.rect.y + item.rect.h
          if (prevBottom > newViewState.imageHeight) {
            newViewState.imageHeight = prevBottom + cyBottomMargin
          }
        }
      }

      // Reposition tooltip targets for segment headers.
      if (refDiagram.current) {
        for (let iSeg = 0; iSeg < nSeg; iSeg++) {
          const segFlowInfo = newViewState.segInfo[iSeg]
          const el = refDiagram.current.querySelector(`#seg-${segFlowInfo.segInfo.segmentId}`)
          if (el !== null && el instanceof HTMLElement) {
            const xSegment = iSeg * (newViewState.segmentWidth + cxLadderWidth)
            el.style.left = `${xSegment}px`
            el.style.top = "0px"
            el.style.right = `${xSegment + newViewState.segmentWidth}px`
            el.style.bottom = `${cyHeader}px`
            el.style.width = `${newViewState.segmentWidth}px`
            el.style.height = `${cyHeader}px`
          }
        }
      }

      setHotItem(null)
      setViewState(newViewState)
    }
  }, [viewState, viewOptions.timeScale, viewOptions.showTooltips, containerWidth])

  // Redraw the canvas.
  React.useLayoutEffect(() => {
    if (
      viewState &&
      viewState.containerWidth !== 0 &&
      viewState.containerWidth === containerWidth &&
      viewState.timeScale === viewOptions.timeScale &&
      project.flowEntries &&
      refCanvas.current
    ) {
      //const t1 = performance.now()
      const canvas = refCanvas.current
      const ctx = canvas.getContext("2d", { alpha: false })
      if (ctx) {
        // Measure the size of text.
        const sizeZero = measureText("0", { fontSize: "12px" })

        // Calculate the segment width.
        const nSeg = project.flowEntries[flowEntryIndex].flowList.length

        // Get the earliest and latest times (in JS time).
        const earliestTime = Date.parse(viewState.earliestTime).valueOf()

        // Set the up canvas.
        canvas.width = containerWidth * dpr
        canvas.height = containerHeight * dpr
        canvas.style.width = containerWidth + "px"
        canvas.style.height = containerHeight + "px"
        ctx.scale(dpr, dpr)
        ctx.translate(-origin.x, -origin.y)

        // Calculate the view port.
        const viewPort: Box = {
          left: origin.x,
          top: origin.y,
          right: origin.x + containerWidth,
          bottom: origin.y + containerHeight,
        }

        const drawScaleArrow = (x1: number, y1: number, x2: number, y2: number, color: string) => {
          if (
            !(
              (y1 < viewPort.top && y2 < viewPort.top) ||
              (y1 > viewPort.bottom && y2 > viewPort.bottom)
            )
          ) {
            ctx.strokeStyle = color
            ctx.fillStyle = color
            ctx.lineWidth = 1 / dpr
            ctx.beginPath()
            ctx.moveTo(x1, y1)
            ctx.lineTo(x2, y2)
            ctx.stroke()
            if (y1 > viewPort.top - 2 && y1 < viewPort.bottom + 2) {
              ctx.beginPath()
              ctx.arc(x1, y1, 2, 0, 2 * Math.PI)
              ctx.fill()
            }
            if (y2 > viewPort.top - 2 && y2 < viewPort.bottom + 2) {
              ctx.beginPath()
              ctx.arc(x2, y2, 2, 0, 2 * Math.PI)
              ctx.fill()
            }
          }
        }

        const drawLadderArrow = (x1: number, y1: number, x2: number, y2: number, color: string) => {
          if (
            !(
              (y1 < viewPort.top && y2 < viewPort.top) ||
              (y1 > viewPort.bottom && y2 > viewPort.bottom)
            )
          ) {
            ctx.strokeStyle = color
            ctx.fillStyle = color
            ctx.lineWidth = 1 / dpr
            ctx.beginPath()
            ctx.moveTo(x1, y1)
            ctx.lineTo(x2, y2)
            ctx.stroke()
            if (y2 > viewPort.top - 10 && y2 < viewPort.bottom + 10) {
              const lineAngle = Math.PI + Math.atan2(y2 - y1, x2 - x1)
              const angle1 = lineAngle + arrowHeadAngle
              const angle2 = lineAngle - arrowHeadAngle

              // Alternative approach: create a Path2D from an SVG path and transform.
              ctx.beginPath()
              ctx.moveTo(x2, y2)
              ctx.lineTo(
                x2 + Math.cos(angle1) * arrowHeadHypLen,
                y2 + Math.sin(angle1) * arrowHeadHypLen
              )
              ctx.lineTo(
                x2 + Math.cos(lineAngle) * arrowNotchLen,
                y2 + Math.sin(lineAngle) * arrowNotchLen
              )
              ctx.lineTo(
                x2 + Math.cos(angle2) * arrowHeadHypLen,
                y2 + Math.sin(angle2) * arrowHeadHypLen
              )
              ctx.closePath()
              ctx.fill()
            }
          }
        }

        const drawLostPacketIndicator = (x: number, y: number) => {
          if (y > viewPort.top - 2 && y < viewPort.bottom + 2) {
            ctx.strokeStyle = localTheme.lostPacketColor
            ctx.lineWidth = 2 / dpr
            ctx.beginPath()
            ctx.arc(x, y, 4, 0, 2 * Math.PI)
            ctx.stroke()
          }
        }

        // Clear the canvas.
        ctx.fillStyle = theme.tableBackgroundColor
        ctx.fillRect(0, 0, canvas.width, canvas.height)

        // Draw the segment backgrounds.
        ctx.fillStyle = localTheme.segmentBackgroundColor
        for (let iSeg = 0; iSeg < nSeg; iSeg++) {
          const x = pxRound(iSeg * (viewState.segmentWidth + cxLadderWidth))
          ctx.fillRect(x, 0, viewState.segmentWidth, viewState.imageHeight)
        }

        // Draw the ladder backgrounds.
        ctx.lineWidth = 1 / dpr
        let x = 0
        for (let iSeg = 0; iSeg < nSeg - 1; iSeg++) {
          // Calculate the rect of the space between the segments.
          const rect: Rect = {
            x: pxRound(x + viewState.segmentWidth),
            y: 0,
            w: cxLadderWidth,
            h: viewState.imageHeight,
          }
          if (iSeg < nSeg - 1) {
            ctx.fillStyle = localTheme.ladderBackgroundColor
            ctx.fillRect(rect.x, rect.y, rect.w, rect.h)
          }
          if (iSeg >= 0) {
            ctx.beginPath()
            ctx.strokeStyle = localTheme.ladderBorderColor
            ctx.moveTo(rect.x, rect.y)
            ctx.lineTo(rect.x, rect.y + rect.h)
            ctx.stroke()
          }
          if (iSeg < nSeg - 1) {
            ctx.beginPath()
            ctx.strokeStyle = localTheme.ladderBorderColor
            ctx.moveTo(rect.x + rect.w, rect.y)
            ctx.lineTo(rect.x + rect.w, rect.y + rect.h)
            ctx.stroke()
          }

          // Draw the time scales.
          let nTickTime = 0
          let yTick = pxRound(cyHeader + cyTopMargin)
          for (let iTick = 0; ; iTick++) {
            if (yTick > viewState.imageHeight - cyBottomMargin) break

            if (yTick > viewPort.bottom) break

            if (yTick >= viewPort.top && yTick <= viewPort.bottom) {
              // Draw the scale labels.
              if (viewOptions.showScaleLabels) {
                let timeLabelLeft = ""
                let timeLabelRight = ""
                if (viewOptions.showFlowTime) {
                  timeLabelLeft = formatDuration(nTickTime, 6)
                  timeLabelRight = timeLabelLeft
                } else {
                  const absTime = peekFromDate(earliestTime) + nTickTime
                  timeLabelLeft = formatTime(absTime, 6)
                  timeLabelRight = timeLabelLeft
                }

                ctx.fillStyle = localTheme.scaleTextColor
                ctx.font = "normal 10px Roboto, Helvetica, Arial, sans-serif"
                ctx.textAlign = "end"
                ctx.textBaseline = "middle"
                ctx.fillText(timeLabelLeft, rect.x - cxMajorTick - 3, yTick + 1)
                ctx.textAlign = "start"
                ctx.fillText(timeLabelRight, rect.x + rect.w + cxMajorTick + 3, yTick + 1)
              }

              const x = Math.floor(rect.x)

              // Draw the major ticks.
              ctx.strokeStyle = localTheme.majorTickColor
              ctx.beginPath()
              ctx.moveTo(x, yTick)
              ctx.lineTo(x - cxMajorTick, yTick)
              ctx.moveTo(x + rect.w + 1, yTick)
              ctx.lineTo(x + rect.w + 1 + cxMajorTick, yTick)
              ctx.stroke()
              yTick += pxPerTick
              nTickTime += viewOptions.timeScale

              // Draw the minor ticks.
              ctx.beginPath()
              ctx.strokeStyle = localTheme.minorTickColor
              for (let t = 0; t < 9; t++) {
                if (yTick > viewState.imageHeight - cyBottomMargin) break
                ctx.moveTo(x, yTick)
                ctx.lineTo(x - cxMinorTick, yTick)
                ctx.moveTo(x + rect.w + 1, yTick)
                ctx.lineTo(x + rect.w + 1 + cxMinorTick, yTick)
                yTick += pxPerTick
                nTickTime += viewOptions.timeScale
              }
              ctx.stroke()
            } else {
              yTick += 10 * pxPerTick
              nTickTime += 10 * viewOptions.timeScale
            }
          }

          x += viewState.segmentWidth + cxLadderWidth
        }

        // Iterate the segments and draw packets.
        const points: Point[] = Array.from({ length: 5 }, () => {
          return { x: 0, y: 0 }
        })
        for (let iSeg = 0; iSeg < nSeg; iSeg++) {
          const segFlowInfo = viewState.segInfo[iSeg]
          const bFirstSegment = iSeg === 0
          const bLastSegment = iSeg === nSeg - 1
          const xSegment = iSeg * (viewState.segmentWidth + cxLadderWidth)

          for (let iItem = 0; iItem < segFlowInfo.items.length; iItem++) {
            const item = segFlowInfo.items[iItem]

            // Get the item pos.
            const yItem = pxRound(item.pos)

            // Get the next and previous items across segments.
            let nextItem: LayoutItem | null = null
            let prevItem: LayoutItem | null = null
            if (!bLastSegment) {
              nextItem = item.itemList[iSeg + 1]
            }
            if (!bFirstSegment) {
              prevItem = item.itemList[iSeg - 1]
            }

            // Attempt to optimize drawing by breaking out of this loop
            // as early as possible. Unfortunately, the drawing will become
            // slower at the bottom of the diagram.
            // BUG: if packets are out of order, the loop could bail out
            // early and some ladder arrows may not be drawn that should have.
            if (yItem > viewPort.bottom + containerHeight / 2) {
              if (nextItem) {
                if (nextItem.pos > viewPort.bottom + containerHeight / 2) {
                  // The item position is below the view port and
                  // the next item also not visible.
                  break
                }
              } else {
                // The item position is below the view port and
                // there is no need to draw an arrow to the next item.
                break
              }
            }

            // Determine if this is a hot item.
            let bHot = false
            if (hotItem !== null) {
              if (hotItem === item) {
                bHot = true
              } else {
                for (let iItem = 0; iItem < nSeg; iItem++) {
                  if (item.itemList[iItem] === hotItem) {
                    bHot = true
                    break
                  }
                }
              }
            }

            // Determine the packet color.
            let packetColor = localTheme.normalPacketColor
            let packetFillColor = localTheme.packetBackgroundColor
            if ((item.packetInfo.tcpFlags & FLAG_SYN) !== 0) {
              packetColor = localTheme.openPacketColor
            } else if ((item.packetInfo.tcpFlags & (FLAG_RST | FLAG_FIN)) !== 0) {
              packetColor = localTheme.closePacketColor
            } else if (item.packetInfo.payloadLen === 0) {
              packetColor = localTheme.normalPacketNoPayloadColor
            }
            if (item === selectedItem) {
              packetFillColor = localTheme.selectedPacketColor
            }
            let arrowColor = packetColor
            if (bHot) {
              packetFillColor = localTheme.hotPacketColor
              arrowColor = localTheme.hotArrowColor
            }

            // Calculate item rect.
            const rectItem: Rect = {
              x: pxRound(item.rect.x),
              y: pxRound(item.rect.y),
              w: item.rect.w,
              h: item.rect.h,
            }

            if (!(rectItem.y > viewPort.bottom || rectItem.y + rectItem.h < viewPort.top)) {
              // Format the packet number.
              const packetNumber = viewOptions.showFlowPacketNumbers
                ? (iItem + 1).toString()
                : item.packetInfo.packetNumber.toString()
              const sizeNum: Size = {
                w: sizeZero.width * packetNumber.length,
                h: sizeZero.height,
              }

              // Build the packet path.
              if (item.packetInfo.fromServer) {
                points[0].x = rectItem.x
                points[0].y = rectItem.y + cyHalfPacketHeight
                points[1].x = points[0].x + cyHalfPacketHeight
                points[1].y = rectItem.y
                points[2].x = points[1].x + rectItem.w - cyHalfPacketHeight
                points[2].y = points[1].y
                points[3].x = points[2].x
                points[3].y = points[2].y + rectItem.h
                points[4].x = points[1].x
                points[4].y = points[3].y
              } else {
                points[0].x = rectItem.x + rectItem.w
                points[0].y = rectItem.y + cyHalfPacketHeight
                points[1].x = points[0].x - cyHalfPacketHeight
                points[1].y = rectItem.y
                points[2].x = points[1].x - rectItem.w + cyHalfPacketHeight
                points[2].y = points[1].y
                points[3].x = points[2].x
                points[3].y = points[2].y + rectItem.h
                points[4].x = points[1].x
                points[4].y = points[3].y
              }
              const packetPath = new Path2D()
              packetPath.moveTo(points[0].x, points[0].y)
              for (let i = 1; i < points.length; i++) {
                packetPath.lineTo(points[i].x, points[i].y)
              }
              packetPath.closePath()

              // Draw the packet frame.
              ctx.lineWidth = 1 / dpr
              ctx.fillStyle = packetFillColor
              ctx.strokeStyle = packetColor
              ctx.save()
              ctx.shadowColor = localTheme.packetShadowColor
              ctx.shadowOffsetX = 2
              ctx.shadowOffsetY = 2
              ctx.shadowBlur = 2
              ctx.fill(packetPath)
              ctx.restore()
              ctx.stroke(packetPath)

              // Draw the packet number label.
              ctx.save()
              ctx.clip(packetPath)
              if (item.packetInfo.fromServer) {
                const x = rectItem.x
                const y = rectItem.y
                const w = cyHalfPacketHeight + sizeNum.w + 6
                const h = rectItem.h
                ctx.fillStyle = packetColor
                ctx.fillRect(x, y, w, h)
                ctx.fillStyle = localTheme.packetNumberColor
                ctx.font = "normal 12px Roboto, Helvetica, Arial, sans-serif"
                ctx.textAlign = "center"
                ctx.textBaseline = "middle"
                ctx.fillText(
                  packetNumber,
                  x + cyHalfPacketHeight + sizeNum.w / 2 + 2,
                  y + rectItem.h / 2 + 1
                )
              } else {
                const x = rectItem.x + rectItem.w - (cyHalfPacketHeight + sizeNum.w + 6)
                const y = rectItem.y
                const w = cyHalfPacketHeight + sizeNum.w + 6
                const h = rectItem.h
                ctx.fillStyle = packetColor
                ctx.fillRect(x, y, w, h)
                ctx.fillStyle = localTheme.packetNumberColor
                ctx.font = "normal 12px Roboto, Helvetica, Arial, sans-serif"
                ctx.textAlign = "center"
                ctx.textBaseline = "middle"
                ctx.fillText(packetNumber, x + sizeNum.w / 2 + 4, y + rectItem.h / 2 + 1)
              }
              ctx.restore()

              // Draw the packet details.
              const rectDetails: Rect = {
                x: rectItem.x,
                y: rectItem.y,
                w: rectItem.w,
                h: rectItem.h,
              }
              const cxPacketNumber = cyHalfPacketHeight + sizeNum.w + 6
              if (item.packetInfo.fromServer) {
                rectDetails.x += cxPacketNumber
                rectDetails.w -= cxPacketNumber
              } else {
                rectDetails.w -= cxPacketNumber
              }
              ctx.save()
              const clipDetails = new Path2D()
              clipDetails.rect(
                rectDetails.x + 2,
                rectDetails.y + 1,
                rectDetails.w - 4,
                rectDetails.h - 2
              )
              ctx.clip(clipDetails)
              ctx.fillStyle = theme.textColor
              ctx.font = "normal 12px Roboto, Helvetica, Arial, sans-serif"
              ctx.textAlign = "start"
              ctx.textBaseline = "middle"
              ctx.fillText(
                getPacketDetails(item),
                rectDetails.x + 4,
                rectDetails.y + rectDetails.h / 2 + 1
              )
              ctx.restore()
            }

            // Draw lines from the packet to the ladder.
            if (item.packetInfo.fromServer) {
              if (!bFirstSegment) {
                const x1 = rectItem.x
                const y1 = rectItem.y + cyHalfPacketHeight
                const x2 = pxRound(xSegment)
                const y2 = yItem
                drawScaleArrow(x1, y1, x2, y2, arrowColor)
              }
              if (!bLastSegment) {
                const x1 = pxRound(xSegment + viewState.segmentWidth)
                const y1 = yItem
                const x2 = rectItem.x + rectItem.w
                const y2 = rectItem.y + cyHalfPacketHeight
                drawScaleArrow(x1, y1, x2, y2, arrowColor)
              }
            } else {
              if (!bLastSegment) {
                const x1 = pxRound(xSegment + viewState.segmentWidth)
                const y1 = yItem
                const x2 = rectItem.x + item.rect.w
                const y2 = rectItem.y + cyHalfPacketHeight
                drawScaleArrow(x1, y1, x2, y2, arrowColor)
              }
              if (!bFirstSegment) {
                const x1 = pxRound(xSegment)
                const y1 = yItem
                const x2 = rectItem.x
                const y2 = rectItem.y + cyHalfPacketHeight
                drawScaleArrow(x1, y1, x2, y2, arrowColor)
              }
            }

            // Draw ladder arrows.
            if (nextItem) {
              if (item.packetInfo.fromServer) {
                const x1 = pxRound(xSegment + viewState.segmentWidth + cxLadderWidth)
                const y1 = pxRound(nextItem.pos)
                const x2 = pxRound(xSegment + viewState.segmentWidth)
                const y2 = yItem
                drawLadderArrow(x1, y1, x2, y2, arrowColor)
              } else {
                const x1 = pxRound(xSegment + viewState.segmentWidth)
                const y1 = yItem
                const x2 = pxRound(xSegment + viewState.segmentWidth + cxLadderWidth)
                const y2 = pxRound(nextItem.pos)
                drawLadderArrow(x1, y1, x2, y2, arrowColor)
              }
            }

            // OD-645 Don't show lost packet indicators for wireless retries.
            const isWirelessRetry = (item.packetInfo.flags & 0x200) !== 0

            if (!isWirelessRetry) {
              // Draw lost packet indicators.
              if (!nextItem && !bLastSegment) {
                drawLostPacketIndicator(pxRound(xSegment + viewState.segmentWidth), yItem)
              }
              if (!prevItem && !bFirstSegment) {
                drawLostPacketIndicator(pxRound(xSegment), yItem)
              }
            }
          }
        }

        // Draw the segment headers.
        ctx.lineWidth = 1 / dpr
        for (let iSeg = 0; iSeg < nSeg; iSeg++) {
          const x = pxRound(iSeg * (viewState.segmentWidth + cxLadderWidth))
          ctx.save()
          ctx.fillStyle = localTheme.segmentTitleBackgroundColor
          ctx.fillRect(x + 1, viewPort.top, viewState.segmentWidth - 2, cyHeader)
          ctx.strokeStyle = localTheme.ladderBorderColor
          const yBorder = pxRound(viewPort.top + cyHeader)
          ctx.beginPath()
          ctx.moveTo(x, yBorder)
          ctx.lineTo(x + viewState.segmentWidth, yBorder)
          ctx.stroke()
          const clipRegion = new Path2D()
          clipRegion.rect(x + 2, viewPort.top, x + viewState.segmentWidth - 4, cyHeader)
          ctx.clip(clipRegion)
          ctx.fillStyle = theme.textColor
          ctx.font = "500 14px Roboto, Helvetica, Arial, sans-serif"
          ctx.textAlign = "center"
          ctx.textBaseline = "middle"
          ctx.fillText(
            project.segments[iSeg].name,
            x + viewState.segmentWidth / 2,
            viewPort.top + cyHeader / 2
          )
          ctx.restore()
        }
      }
      //const t2 = performance.now()
      //console.log(`draw in ${(t2 - t1).toPrecision(4)}`)
    }
  }, [
    viewState,
    theme,
    localTheme,
    viewOptions,
    containerWidth,
    containerHeight,
    origin,
    flowEntryIndex,
    hotItem,
    getPacketDetails,
    project.flowEntries,
    project.segments,
    selectedItem,
  ])

  const segTargets: React.ReactNode[] = []
  const segPopovers: React.ReactNode[] = []
  if (viewState && viewOptions.showTooltips) {
    for (let iSeg = 0; iSeg < viewState.segInfo.length; iSeg++) {
      const segFlowInfo = viewState.segInfo[iSeg]
      const idTarget = `seg-${segFlowInfo.segInfo.segmentId}`
      segTargets.push(
        <div
          key={idTarget}
          id={idTarget}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            width: 0,
            height: 0,
            opacity: 0,
          }}
        />
      )
      segPopovers.push(
        <UncontrolledPopover
          key={`seg-pop-${segFlowInfo.segInfo.segmentId}`}
          trigger="hover"
          placement="bottom"
          target={idTarget}
          maxWidth="400px"
          style={{ fontSize: "1rem" }}
        >
          <PopoverHeader style={{ margin: "0.5rem 0.5rem 0 0.5rem" }}>
            {project.segments[iSeg].name}
          </PopoverHeader>
          <PopoverBody>
            <PropTable
              propList={[
                "Client",
                "Server",
                "Packets",
                "Client Packets",
                "Server Packets",
                "Packets Lost",
                "Client Packets Lost",
                "Server Packets Lost",
                "Client Retransmissions",
                "Server Retransmissions",
                "Start",
                "Finish",
                "Duration",
                "TCP Status",
              ]}
              data={segFlowInfo}
              formatProp={getSegmentProp}
              skipEmptyRows={true}
            />
          </PopoverBody>
        </UncontrolledPopover>
      )
    }
  }

  return (
    <Outer>
      <Inner ref={refDiagram}>
        {viewState && project.flowEntries ? (
          <>
            <canvas
              ref={refCanvas}
              style={{
                position: "absolute",
              }}
            />
            <Scroller ref={refScroller} onScroll={onScroll} onWheel={onWheel}>
              <div
                style={{
                  width: viewState.imageWidth,
                  height: viewState.imageHeight,
                  minWidth: "100%",
                  minHeight: "100%",
                  overflow: "hidden",
                  opacity: 0,
                }}
                tabIndex={0}
                onClick={onClick}
                onMouseMove={onMouseMove}
                onKeyDown={onKeyDown}
              >
                <div
                  ref={refScrollToDiv}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    width: 0,
                    height: 0,
                    opacity: 0,
                  }}
                />
                <div
                  id="tooltip"
                  ref={refToolTipDiv}
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                    width: 0,
                    height: 0,
                    opacity: 0,
                  }}
                />
              </div>
            </Scroller>
            {hotItem && viewOptions.showTooltips ? (
              <UncontrolledPopover
                trigger="hover"
                placement="bottom"
                target="tooltip"
                maxWidth="400px"
                style={{ fontSize: "1rem" }}
              >
                <PopoverHeader style={{ margin: "0.5rem 0.5rem 0 0.5rem" }}>
                  {getPacketPopoverTitle()}
                </PopoverHeader>
                <PopoverBody>
                  <PropTable
                    propList={
                      isTCP
                        ? [
                            ["ipId", "prevDelay"],
                            ["ttl", "nextDelay"],
                            ["tcpFlags", "absTime"],
                            ["seq", "flowTime"],
                            ["ack", "deltaTime"],
                            ["tcpWindow", null],
                            ["payloadLen", null],
                          ]
                        : [
                            ["ipId", "prevDelay"],
                            ["ttl", "nextDelay"],
                            ["payloadLen", "absTime"],
                            [null, "flowTime"],
                            [null, "deltaTime"],
                          ]
                    }
                    data={hotItem}
                    propToLabel={packetPropToLabel}
                    formatProp={getPacketProp}
                    skipEmptyRows={true}
                  />
                </PopoverBody>
              </UncontrolledPopover>
            ) : null}
            {segTargets}
            {segPopovers}
            <UncontrolledDropdown
              style={{
                position: "absolute",
                top: "8px",
                right: "25px",
              }}
            >
              <LightDropdownToggle
                aria-label="Ladder view options"
                style={{
                  boxShadow: "0 3px 5px -1px rgba(0, 0, 0, 0.18)",
                  padding: 0,
                  width: "23px",
                  height: "23px",
                }}
              >
                <FontAwesome name="chevron-down" fixedWidth />
              </LightDropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  active={viewOptions.showScaleLabels}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        showScaleLabels: !viewOptions.showScaleLabels,
                      })
                    )
                  }}
                >
                  Show Time Labels
                </DropdownItem>
                <DropdownItem
                  active={viewOptions.showTooltips}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        showTooltips: !viewOptions.showTooltips,
                      })
                    )
                  }}
                >
                  Show Tooltips
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  active={viewOptions.showFlowTime}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        showFlowTime: true,
                      })
                    )
                  }}
                >
                  Flow Time
                </DropdownItem>
                <DropdownItem
                  active={!viewOptions.showFlowTime}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        showFlowTime: false,
                      })
                    )
                  }}
                >
                  Absolute Time
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  active={viewOptions.showFlowPacketNumbers}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        showFlowPacketNumbers: true,
                      })
                    )
                  }}
                >
                  Flow Packet Numbers
                </DropdownItem>
                <DropdownItem
                  active={!viewOptions.showFlowPacketNumbers}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        showFlowPacketNumbers: false,
                      })
                    )
                  }}
                >
                  File Packet Numbers
                </DropdownItem>
                {isTCP ? (
                  <>
                    <DropdownItem divider />
                    <DropdownItem
                      active={!viewOptions.showRelativeSeqAck}
                      onClick={() => {
                        dispatch(
                          setMSALadderViewOptions({
                            ...viewOptions,
                            showRelativeSeqAck: false,
                          })
                        )
                      }}
                    >
                      Absolute Seq/Ack Numbers
                    </DropdownItem>
                    <DropdownItem
                      active={viewOptions.showRelativeSeqAck}
                      onClick={() => {
                        dispatch(
                          setMSALadderViewOptions({
                            ...viewOptions,
                            showRelativeSeqAck: true,
                          })
                        )
                      }}
                    >
                      Relative Seq/Ack Numbers
                    </DropdownItem>
                  </>
                ) : null}
                <DropdownItem divider />
                <DropdownItem
                  active={viewOptions.showIPID}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        showIPID: !viewOptions.showIPID,
                      })
                    )
                  }}
                >
                  Show IP ID
                </DropdownItem>
                <DropdownItem
                  active={viewOptions.showTTL}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        showTTL: !viewOptions.showTTL,
                      })
                    )
                  }}
                >
                  Show TTL
                </DropdownItem>
                {isTCP ? (
                  <>
                    <DropdownItem
                      active={viewOptions.showTCPFlags}
                      onClick={() => {
                        dispatch(
                          setMSALadderViewOptions({
                            ...viewOptions,
                            showTCPFlags: !viewOptions.showTCPFlags,
                          })
                        )
                      }}
                    >
                      Show TCP Flags
                    </DropdownItem>
                    <DropdownItem
                      active={viewOptions.showSeqNum}
                      onClick={() => {
                        dispatch(
                          setMSALadderViewOptions({
                            ...viewOptions,
                            showSeqNum: !viewOptions.showSeqNum,
                          })
                        )
                      }}
                    >
                      Show Seq Number
                    </DropdownItem>
                    <DropdownItem
                      active={viewOptions.showAckNum}
                      onClick={() => {
                        dispatch(
                          setMSALadderViewOptions({
                            ...viewOptions,
                            showAckNum: !viewOptions.showAckNum,
                          })
                        )
                      }}
                    >
                      Show Ack Number
                    </DropdownItem>
                    <DropdownItem
                      active={viewOptions.showTCPWindow}
                      onClick={() => {
                        dispatch(
                          setMSALadderViewOptions({
                            ...viewOptions,
                            showTCPWindow: !viewOptions.showTCPWindow,
                          })
                        )
                      }}
                    >
                      Show Window
                    </DropdownItem>
                  </>
                ) : null}
                <DropdownItem
                  active={viewOptions.showPayloadLength}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        showPayloadLength: !viewOptions.showPayloadLength,
                      })
                    )
                  }}
                >
                  Show Payload Length
                </DropdownItem>
                <DropdownItem
                  active={viewOptions.showDelay}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        showDelay: !viewOptions.showDelay,
                      })
                    )
                  }}
                >
                  Show Delay
                </DropdownItem>
                <DropdownItem
                  active={viewOptions.showTime}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        showTime: !viewOptions.showTime,
                      })
                    )
                  }}
                >
                  Show Time
                </DropdownItem>
                <DropdownItem
                  active={viewOptions.showDeltaTime}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        showDeltaTime: !viewOptions.showDeltaTime,
                      })
                    )
                  }}
                >
                  Show Delta Time
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  active={viewOptions.timeScale === 100000000}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        timeScale: 100000000,
                      })
                    )
                  }}
                >
                  Scale 100 ms
                </DropdownItem>
                <DropdownItem
                  active={viewOptions.timeScale === 50000000}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        timeScale: 50000000,
                      })
                    )
                  }}
                >
                  Scale 50 ms
                </DropdownItem>
                <DropdownItem
                  active={viewOptions.timeScale === 10000000}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        timeScale: 10000000,
                      })
                    )
                  }}
                >
                  Scale 10 ms
                </DropdownItem>
                <DropdownItem
                  active={viewOptions.timeScale === 5000000}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        timeScale: 5000000,
                      })
                    )
                  }}
                >
                  Scale 5 ms
                </DropdownItem>
                <DropdownItem
                  active={viewOptions.timeScale === 1000000}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        timeScale: 1000000,
                      })
                    )
                  }}
                >
                  Scale 1 ms
                </DropdownItem>
                <DropdownItem
                  active={viewOptions.timeScale === 500000}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        timeScale: 500000,
                      })
                    )
                  }}
                >
                  Scale 500 {"\u03bc"}s
                </DropdownItem>
                <DropdownItem
                  active={viewOptions.timeScale === 100000}
                  onClick={() => {
                    dispatch(
                      setMSALadderViewOptions({
                        ...viewOptions,
                        timeScale: 100000,
                      })
                    )
                  }}
                >
                  Scale 100 {"\u03bc"}s
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ) : null}
      </Inner>
    </Outer>
  )
}

export default LadderDiagram
