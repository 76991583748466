export enum EngineAuthServerProtocol {
  AUTH_SERVER_PROTOCOL_LDAP = 0,
  AUTH_SERVER_PROTOCOL_LDAP_SSL = 1,
  AUTH_SERVER_PROTOCOL_LDAP_TLS = 2,
}

export const CustomSettings = {
  fbi: 2,
  amex: 3,
}

export const EngineCapabilities = {
  packetbuffer: "7AE290BC-FD32-47A9-AC3C-583242426460",
  captureToDisk: "7C8565D1-94CB-482F-A352-83B9616917A3",
  captureToDiskPriority: "7EE163ED-4A13-4F17-802D-5718E6D4B7E3",
  captureToDiskDir: "8986729F-312F-4CC2-868C-23CD9108E27F",
  captureToDiskPcap: "183821D0-78C6-496B-ABFF-00E39D3DE8E1",
  highPerformanceCaptureToDisk: "029106B9-5DF0-4001-8E0B-04769F2096AE",
  timelineStats: "FB5030D0-38DE-4494-9957-DB8CFBCA0C73",
  timelineVoIPStats: "D9F2D7D7-9F73-45A2-90AC-A79D078C06FD",
  timelineWirelessStats: "7B066861-1009-49D0-9B45-6621E0CB591A",
  timelineTopTalkers: "3AB21163-C7DD-480E-9DEB-67A64E380A69",
  timelineApplicationStats: "8FDCE1DB-6F4E-4880-A7E1-1518E1F1B0BF",
  applicationStats: "56C00F66-3166-4573-9D86-7053381FC8EF",
  countryStats: "8071DE19-3914-412E-B901-9A39D763D540",
  tapTimestamps: "C63E4A93-82D5-4F3C-8EFD-A2F8AF750097",
  remoteAPCapture: "F8E624E3-B2C6-4FB9-AEDF-D00B33ABF854",
  stats: "87F48853-2B22-438F-8442-71DCFF9E81CB",
  captureComment: "5BCB5CFA-4DC6-494A-B9BB-9D90896D9ED1",
  packetList: "43E369CF-7BE6-4C8D-9247-744208EADA68",
  decodeSnippets: "C4307702-6ACB-459E-93CB-6B9C943A956C",
  logging: "73436674-319C-4B36-877B-43CD16FFA516",
  logging2: "609FAD1F-5F82-4F3F-ADD6-BF2D4042CF21",
  loggingDateTimeFilter: "4C3158FE-F34F-45A0-8B68-5040F3AF7E0D",
  securityEvents: "288125E2-22C7-4922-B49D-8A020C3C37A0",
  securityEventsDateTimeFilter: "0800972A-FE30-4096-A5C8-A5C61DED0B47",
  securityEventsSeverityFilter: "595FD962-5498-475D-BC46-A6430DE5C417",
  liveflow: "608A3567-0FFC-45E8-8101-2E4D5B4953D7",
  alarms: "068E779E-3FF8-4CC8-9AE1-1217EE97682A",
  fileOutput: "D214854D-0611-4B81-B0C2-9E4B3EC89FFF",
  savePcap: "56574F18-44F5-4F91-ABFD-4476BE1D127E",
  saveCaptureTasks: "01069A46-0EC3-40AA-A7C3-DEB13DA4CF2A",
  forensics: "AC4CF3F4-6317-4393-AE9F-87485D397B24",
  forensicsExtendedOptions: "7EE40D3B-77F3-4F4E-8451-6D3B39A1C9EE",
  offlineForensicSearch: "5CF20A13-E1C5-481A-AB39-D528474E8754",
  distributedForensicSearch: "60CFA97A-373E-4E81-AFA8-1234C7F3A295",
  forensicSearchWithoutDatabase: "897171CA-5CED-4AD8-950E-0F81762C19C8",
  putForensicSearch: "F459683B-E60E-4755-B702-94960447B36B",
  graphs: "60FC3016-225C-4B89-B739-1A83E366C7D6",
  filters: "AA400B03-6DE4-41C2-B7B9-01AE7B878471",
  filterGroups: "59557CB3-A161-4500-B4E0-54B82CE88AD6",
  multipleAddressFilters: "A26407E9-68A2-4D18-AEA0-E8A00C4F30B9",
  trustTable: "0AD82CC4-86F7-4135-B00B-6481E34C0DB0",
  nameTable: "37C7CF6E-BD6E-4D96-9B72-2A102406B946",
  matrixSwitchControl: "EADDDCD5-42D5-4C0E-8641-28663284E8A1",
  performanceOptions: "70C5F584-A710-41B8-AF26-2F7BB1554143",
  signalGraphV2: "EB143C87-D0A1-4B4C-94D2-672A2BEF3115",
  statsOutput: "E4A53BD3-665F-45AA-AB1D-3CC749142A36",
  expertQuery: "9244F0FB-9A97-43F6-AF4C-47830D98ADAA",
  voice: "CFAE59DD-630A-4FA8-A917-BAE0A44D1281",
  callSearch: "A9DDC0FA-8CF4-4E4C-ACC0-6CA3FE06E8FF",
  managePlugins: "BBB9AE3C-9E0D-4D1F-B59F-3D82D07AE888",
  uploadFiles: "D92A0FA3-D388-4954-A252-DB29F68DA4A2",
  auditLog: "77721937-500F-4626-94C4-FCEABABC05E6",
  bpfFilter: "C7387B3D-F48C-44BC-BB84-92038AF729F1",
  vlanFilter: "D6666BA9-CCEA-4AE9-89AC-D65DF5884F34",
  applicationFilter: "A832C838-F2E8-4D14-BAEF-6CEDB834C644",
  diagnosticsInfo: "F74D7114-BEA9-46E9-A6D4-903FC7A2131C",
  listConnectedUsers: "E189A0D9-F973-4EAE-BD78-91CC697C5CD6",
  disconnectUsers: "0E06167B-E99D-45F8-925E-77BD5E39DE02",
  relativeFilterOffsets: "3B872E02-9B51-4775-B5BB-6E058AB4BB01",
  countryFilter: "DF6EB7F9-3ABA-46CB-99FF-9F92D0F7BC9D",
  compass: "F4836993-22CA-42BF-88F7-7029291F479C",
  sendNotifications: "8E593BE0-F673-4D22-9865-E403E09E3B06",
  sendEmail: "956F3987-D4C2-48FB-AFC7-C1401A5CD410",
  emailAuthentication: "AEE99C6E-27A7-4598-B4FC-320BF65120C2",
  packetFileIndexing: "D35172E5-99AB-4E18-B0A4-EE7BEA5998F7",
  protocolTranslations: "B7CA04E0-6752-4F6F-8AD6-B76D90A57694",
  newLicensing: "D9707732-4920-42DC-BA32-15BE4B9AA4DC",
  licenseHandling: "97EC3A35-ED27-4619-ABC3-7AD2B7974300",
  hardwareFilterVLANMPLS: "2A519A3C-02E2-4F4B-98BF-6ADB1A77887E",
  hardwareFilterDeduplication: "38AABC5A-C062-488E-8280-CFA319E8C722",
  hardwareFilterDynamicSlicing: "F164BEB5-0548-46C8-A03F-57CC17B05F4E",
  hardwareFilterValues: "C6B6F6FB-AE91-4438-9CEB-C8CD47799F22",
  notificationSourcePeekAgent: "42D5B4FE-1A01-4002-86C9-B3C978BE11B6",
  notificationSourceCapture: "8B024791-507E-4A6A-A26A-C8EEEE7885DB",
  notificationSourceForensicSearch: "99D6F964-BC3D-460F-9A11-EC9116D4BBCC",
  notificationSourceDistributedForensicSearch: "1D15D940-2252-45C8-B82D-27EE53C1CCA8",
  notificationSourceAlarms: "43C4790B-9817-45C1-B15D-BE04300899A6",
  notificationSourceExpert: "3EE1F04B-2C60-4B5D-AF0A-68A19FFE7A92",
  notificationSourceFTPPlugin: "8EF917EE-7DDB-4D89-942E-13D930CD4F72",
  notificationSourceWebPlugin: "DA5EB7BE-30D6-49E9-8245-F75AB5C2A66F",
  notificationTypeLog: "E8E30A4F-BF63-410C-BD7B-510F774F0FCF",
  notificationTypeEmail: "ADEFC646-8E66-4E88-9FB2-19CA297B41D7",
  notificationTypeExecute: "BA5249F8-97F3-41E5-99E3-C93F5EF8EED7",
  notificationTypeSound: "43F07072-495F-4269-AE84-1F59D28C7607",
  notificationTypeTextLog: "D2DC1924-A8EC-4928-9E8E-85AC1394C5D6",
  notificationTypeSyslog: "C93ACEDD-9EF9-45E6-B501-E38F48D2F363",
  notificationTypeSNMPTrap: "AF55204C-C4EE-4BF7-AE4F-B59FDE4EE00D",
  lockFilters: "F333C937-AEF8-4F21-90CB-26FC9346ACCE",
  lockCapture: "242AEBB6-B129-4AC7-B621-35FBEF28475C",
  lockAdapterConfig: "FEBDA872-D41E-4D76-B1AF-F9682A2FC6E5",
  lockUser: "FF725C04-B4CE-4355-AC73-718CE0A40529",
  decryption: "65FC9640-31CD-4D15-BA78-F6B84063BE67",
  multiStreamCTD: "2B9B697C-E01E-4440-B290-88D8DD15ABA9",
  captureToDiskIntelligent: "C1A85490-2D86-4D38-AF00-0D8F1C3CFE01",
  remoteWebAnalysis: "A03BA5FF-3A66-40CA-BD54-ECB38EF9C556",
  reconstructions: "CAA24A58-E552-4B4E-85FB-AB8127510692",
  captureToDiskRetentionTime: "EB982490-41B7-4F74-86C5-B883FC5CACE0",
  twoFactorAuthentication: "703DAB6B-677C-49A4-B97A-996151625DCB",
  captureToDiskCompression: "6F2EC24E-9A77-4804-95C6-5F57865D569B",
  ipoquePace2Applications: "68EA1544-28EB-4D42-B937-E1FEA9A0DD3E",
  forensicSearchACL: "4C627410-B98C-450D-BFEC-9DE0A2AB3666",
  viewCapturesForensicSearchesACL: "8D84A0DF-3AE3-4745-BCB3-CAE8A5EC5DBB",
  roleACL: "00C1F459-ECF8-47CE-9042-DCE34A8B4E60",
  aclDataExclusion: "CDE964EE-4BD5-4279-8B74-7B8058E088F8",
  activeDirectory: "851FE0CC-6097-428B-89D4-0BCF80D47E25",
  roleACLUsersAndGroups: "2EE5BA64-3209-4B85-9AAC-D22520E68819",
  saveFilesACL: "6A314CF8-0FFA-4725-90AD-098065466392",
  forensicSearchAnalysisACL: "F93C8447-247B-425B-BC75-CF7C9DEB843C",
  forensicSearchHistory: "DFB50F44-88CE-4724-A9A0-4F7B746649AC",
  maxConcurrentUserSessions: "A2300421-3BEE-49AF-90F7-E5DB3BED9592",
  vxlanStats: "B7C22FB0-8607-4D23-A50F-BD2D699B59C2",
  vxlanTimelineStats: "6D0C404B-4A5B-42C8-9F3B-82E3A14EF55D",
  vxlanFilter: "235FF653-B94F-45A1-ADE4-9BEE24213D16",
  mplsVxlanSelectRelated: "AC72BE8B-6F28-4C4B-95D6-3B22536369B8",
  configureAPITokensACL: "05181CC1-6A6D-4506-9802-852436EDEAB5",
  mplsVlanVxlanStats: "A04C9A5D-2C0F-41FB-A7D6-B05FB0C6F850",
  groupAuthentication: "9867E735-7862-4548-867A-5BD7A7DA7E87",
  webPacketDecodeColumns: "B27A9EDA-5A91-4E9D-8DDF-8F4253D4E117",
  engineConfigurationSync: "531A8D09-5F41-4C4C-9561-75B3209C63BB",
}

export const EngineUserPolicies = {
  useAnyCommand: "9826ADFF-2AAD-4310-8BA1-30FB4CAF8C1E",
  createCapture: "954E2168-88C6-4C14-9FB3-14FB630F73A4",
  deleteCaptures: "962C338E-E7FE-47AC-AB9E-5CB760029C93",
  startStopCaptures: "9B86BA90-E4BC-4A39-90C7-3430BB7D922B",
  modifyCaptures: "9F1813D9-5628-4EDC-B522-CF40A9307946",
  viewCaptures: "0DA4E975-9001-4BE5-9F95-E7466C60081A",
  viewPackets: "92277A17-5BB1-49AE-ADA6-868A413A3509",
  viewStats: "99128028-ED9B-45BA-84CC-47CBCF25B471",
  deleteFiles: "B5405600-33F6-4D63-9AA9-30F5DDFDC58B",
  configureEngine: "977000CD-2261-4294-963C-AD6FC396C761",
  configureMatrixSwitches: "1A6CDC94-4896-42ee-8195-E68B72B050D7",
  viewAuditLog: "9482DD9B-6E9B-4595-8F4A-398C7A6BAB20",
  uploadFiles: "CB24BECC-5E6C-46FB-9E73-903C57706E31",
  downloadFiles: "0D4BE720-1B4D-4FE8-BC99-3C78A8C63971",
  saveFiles: "B5941F33-A0A3-482D-B1FB-FE19A5C5FC18",
  createForensicSearch: "7DABF6D6-77A3-4973-846F-AE68B3D854F4",
  deleteForensicSearches: "60310DE7-1379-48F7-8AD6-F43929D2CB32",
  viewForensicSearches: "9AC4DFAE-7A86-4DB7-A485-C754EC2F824A",
  forensicSearchAnalysis: "18B6D8A4-EDCA-471B-BFC7-F9AD74B715E2",
  configureAPITokens: "641B8126-189C-4924-8177-527870837D2D",
}

export const EngineUserPolicyDescriptions: Record<string, string> = {
  [EngineUserPolicies.useAnyCommand]: "Allow Capture Engine usage",
  [EngineUserPolicies.createCapture]: "Capture: Create new capture",
  [EngineUserPolicies.deleteCaptures]: "Capture: Delete captures created by other users",
  [EngineUserPolicies.startStopCaptures]: "Capture: Start/stop captures created by other users",
  [EngineUserPolicies.modifyCaptures]: "Capture: Modify captures created by other users",
  [EngineUserPolicies.viewCaptures]: "Capture: View captures created by other users",
  [EngineUserPolicies.viewPackets]:
    "Capture / Forensic Search: View packets from captures and forensic searches created by other users",
  [EngineUserPolicies.viewStats]:
    "Capture / Forensic Search: View statistics from captures and forensic searches created by other users",
  [EngineUserPolicies.deleteFiles]: "Capture: Delete files created by other users",
  [EngineUserPolicies.configureEngine]: "Configuration: Configure engine settings",
  [EngineUserPolicies.viewAuditLog]: "Configuration: View the audit log",
  [EngineUserPolicies.uploadFiles]: "Configuration: Upload files",
  [EngineUserPolicies.downloadFiles]: "Configuration: Download packet data",
  [EngineUserPolicies.saveFiles]: "Configuration: Save packet data",
  [EngineUserPolicies.createForensicSearch]: "Forensic Search: Create new forensic search",
  [EngineUserPolicies.deleteForensicSearches]:
    "Forensic Search: Delete forensic searches created by other users",
  [EngineUserPolicies.viewForensicSearches]:
    "Forensic Search: View forensic searches created by other users",
  [EngineUserPolicies.forensicSearchAnalysis]:
    "Forensic Search: Allow analysis in forensic searches",
  [EngineUserPolicies.configureAPITokens]: "Configuration: Configure API tokens",
}
