import * as React from "react"
import cn from "classnames"
import FileSaver from "file-saver"
import { cloneDeep } from "lodash"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import {
  SortDirection,
  SortDirectionType,
  SortIndicator,
  TableCellProps,
  TableHeaderProps,
  TableRowProps,
} from "react-virtualized"
import {
  ConfigurationItem,
  ConfigurationItems,
  EngineConnection,
  SyncResult,
  engineCanSyncConfigType,
  sortConfigurationItems,
} from "./types"
import { EngineUserPolicyDescriptions } from "../../api/types/engineTypes"
import { PeekResult } from "../../api/types/peekTypes"
import { Alert } from "../common/Alert"
import { IconButton, LightButton, LinkButton } from "../common/Buttons"
import { FilterBox } from "../common/FilterBox"
import { ButtonBar, CheckGroup, CheckGroupIndeterminate } from "../common/Form"
import { MessageModal } from "../common/MessageModal"
import { MutedText } from "../common/MutedText"
import { OmniTable } from "../common/OmniTable"
import { StrikeThroughText } from "../common/StrikeThroughText"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import * as Colors from "../../themes/colorScheme"
import { formatInteger } from "../../utils/formatUtils"
import { collator } from "../../utils/sortUtils"

const columnDesc = [
  {
    dataKey: "name",
    label: "Name",
    width: 250,
    flexGrow: 1,
    headerStyle: { paddingLeft: "1.7857rem" },
  },
  {
    dataKey: "host",
    label: "Host",
    width: 250,
  },
  {
    dataKey: "version",
    label: "Version",
    width: 80,
  },
  {
    dataKey: "configuration",
    label: "Configuration",
    width: 225,
    disableSort: true,
  },
  {
    dataKey: "status",
    label: "",
    width: 50,
    flexGrow: 0,
    disableSort: true,
  },
]

const ConfigurationCell = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const ConfigurationDetailsBlock = styled.div`
  margin-right: 1.5rem;

  & > :not(:last-child) {
    margin-right: 0.5rem;
  }
`

const ConfigurationIconBlock = styled.div`
  display: flex;
  margin-right: 0.5rem;
`

const ConfigurationItemRow = styled.div`
  display: flex;
  flex-grow: 1;
`

const ConfigurationItemText = styled.div`
  flex-grow: 1;
`

const ErrorText = styled.div`
  color: ${props => props.theme.errorColor};
`

const HiddenText = styled.div`
  visibility: hidden;
`

const ListFormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  @media (max-width: 992px) {
    flex-direction: column;

    & > * + * {
      margin-top: 0.5rem !important;
    }
  }
`

const MessageList = styled.ul`
  margin-bottom: 0px;
`

const NoteList = styled.ul`
  margin-bottom: 0px;
`

const SuccessText = styled.div`
  color: ${props => (props.theme.name === "Dark" ? Colors.green400 : props.theme.successColor)};
`

const StatusText = styled.div`
  text-align: center;
`

type EngineGroup = {
  name: string
  children: EngineConnection[]
}

type EngineRowData = EngineConnection | EngineGroup

function rowDataToId(rowData: EngineRowData) {
  const rawId = "engineId" in rowData ? rowData.engineId : rowData.name
  const id = rawId.replace(" ", "_")
  return "id:" + id
}

function isGroupRowData(rowData: EngineRowData) {
  return "children" in rowData
}

type SyncEnginesProps = {
  engines: EngineConnection[]
  engineFilter: string
  engineVersion: string | null
  deleteCaptureSessions: boolean
  review: boolean
  final: boolean
  setEngineFilter: (filter: string) => void
  sortBy: string
  setSortBy: (sortBy: string) => void
  sortDirection: SortDirectionType
  setSortDirection: (sortDirection: SortDirectionType) => void
  collapsedGroups: Set<string>
  setCollapsedGroups: (collapsedGroups: Set<string>) => void
  selectedEngines: string[]
  setSelectedEngines?: (selectedEngine: string[]) => void
  selectedConfigurationItems: ConfigurationItem[]
}

const SyncEngines = ({
  engines,
  engineFilter,
  engineVersion,
  deleteCaptureSessions,
  review,
  final,
  setEngineFilter,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  collapsedGroups,
  setCollapsedGroups,
  selectedEngines,
  setSelectedEngines,
  selectedConfigurationItems,
}: SyncEnginesProps) => {
  const [detailsMessage, setDetailsMessage] = React.useState<React.ReactNode | null>(null)
  const [detailsTitle, setDetailsTitle] = React.useState<string | null>(null)
  const [showDetails, setShowDetails] = React.useState<boolean>(false)

  const getViewEngines = (): EngineConnection[] => {
    if (Array.isArray(engines) && engines.length > 0) {
      const lowerCaseFilter = engineFilter.toLowerCase()
      return engines.filter(engine => {
        return (
          engine.engineName.toLowerCase().indexOf(lowerCaseFilter) !== -1 ||
          engine.engineHost.toLowerCase().indexOf(lowerCaseFilter) !== -1
        )
      })
    }
    return []
  }

  const viewEngines = getViewEngines()

  const onSort = ({
    sortBy,
    sortDirection,
    event,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
    event: any
  }) => {
    const tagName = (event?.target as HTMLElement)?.tagName
    if (tagName === "LABEL" || tagName === "INPUT") {
      return
    }
    setSortBy(sortBy)
    setSortDirection(sortDirection)
  }

  const onExpandCollapse = (group: string) => {
    const newCollapsedGroups = new Set<string>(collapsedGroups)
    if (newCollapsedGroups.has(group)) {
      newCollapsedGroups.delete(group)
    } else {
      newCollapsedGroups.add(group)
    }
    setCollapsedGroups(newCollapsedGroups)
  }

  const onCheckEngine = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    if (setSelectedEngines) {
      const id = event.target.name
      const newSelectedEngines = selectedEngines.filter(selectedId => selectedId !== id)
      // If the engine was selected, it is now removed from the list, else if
      // the list hasn't changed it needs to be added.
      if (newSelectedEngines.length === selectedEngines.length) {
        newSelectedEngines.push(id)
      }
      setSelectedEngines(newSelectedEngines)
    }
  }

  const onEnableAll = () => {
    if (setSelectedEngines) {
      const newSelectedEngines = viewEngines.map(engine => engine.engineId)
      setSelectedEngines(newSelectedEngines)
    }
  }

  const onDisableAll = () => {
    if (setSelectedEngines) {
      setSelectedEngines([])
    }
  }

  const onMessageDetail = (engine: EngineConnection, syncResult: SyncResult) => {
    const detailsConfig = ConfigurationItems.find(
      (configItem: ConfigurationItem) => configItem.type === syncResult.syncType
    )
    setDetailsMessage(
      <>
        <div>
          <b>Name:</b> {engine.engineName}
        </div>
        <div>
          <b>Host:</b> {engine.engineHost}
        </div>
        <div>
          <b>Version:</b> {engine.version}
        </div>
        <p />
        {syncResult.syncMessages.length > 0 && (
          <div>
            <b>Messages:</b>
            <MessageList>
              {syncResult.syncMessages.map((syncMessage: string, index: number) => (
                <li key={`message-detail-${index}`}>{syncMessage}</li>
              ))}
            </MessageList>
          </div>
        )}
      </>
    )
    setDetailsTitle(
      detailsConfig ? `Detailed Results for ${detailsConfig.name}` : "Detailed Results"
    )
    setShowDetails(true)
  }

  const onMessageDetailOK = () => {
    setDetailsMessage(null)
    setDetailsTitle(null)
    setShowDetails(false)
  }

  const onDownloadAllDetails = (engineList: EngineRowData[]) => {
    let output: string = "Name,Host,Version,Configuration,Message\n"
    engineList.forEach((engine: EngineRowData) => {
      const engineConn = engine as EngineConnection
      if (engineConn.syncResults !== undefined) {
        engineConn.syncResults.forEach((syncResult: SyncResult) => {
          const detailsConfig = ConfigurationItems.find(
            (configItem: ConfigurationItem) => configItem.type === syncResult.syncType
          )
          syncResult.syncMessages.forEach((message: string) => {
            output += `${engineConn.engineName},${engineConn.engineHost},${engineConn.version},${
              detailsConfig ? detailsConfig.name : ""
            },"${message}"\n`
          })
        })
      }
    })

    FileSaver.saveAs(
      new Blob([output], { type: "text/plain;charset=utf8" }),
      "EngineConfigurationSyncAllDetails.csv"
    )
  }

  const onDownloadDetails = (engine: EngineConnection, syncResult: SyncResult) => {
    let output: string = "Name,Host,Version,Configuration,Message\n"
    const engineConn = engine as EngineConnection
    const detailsConfig = ConfigurationItems.find(
      (configItem: ConfigurationItem) => configItem.type === syncResult.syncType
    )
    syncResult.syncMessages.forEach((message: string) => {
      output += `${engineConn.engineName},${engineConn.engineHost},${engineConn.version},${
        detailsConfig ? detailsConfig.name : ""
      },"${message}"\n`
    })

    FileSaver.saveAs(
      new Blob([output], { type: "text/plain;charset=utf8" }),
      "EngineConfigurationSyncDetails.csv"
    )
  }

  const getFlattenedTree = () => {
    if (viewEngines != null) {
      // Build hierarchical representation
      const groups: EngineGroup[] = []
      viewEngines.forEach(engine => {
        const group = groups.find(group => collator.compare(group.name, engine.engineGroup) === 0)
        if (group) {
          group.children.push(engine)
        } else {
          groups.push({
            name: engine.engineGroup,
            children: [engine],
          })
        }
      })

      // Sort top level groups by name
      groups.sort((a, b) => {
        let result = 0
        if (a.name === "") {
          result = 1
        } else if (b.name === "") {
          result = -1
        } else {
          result = collator.compare(a.name, b.name)
        }
        if (sortDirection === SortDirection.DESC) result = -result
        return result
      })

      // Sort children
      groups.forEach(group => {
        // Only need to sort if top level or expanded
        if (group.name.length === 0 || !collapsedGroups.has(group.name)) {
          group.children.sort((a: EngineConnection, b: EngineConnection) => {
            let result = 0
            switch (sortBy) {
              case "name":
                result = collator.compare(a.engineName, b.engineName)
                if (result === 0) {
                  result = collator.compare(a.engineHost, b.engineHost)
                }
                break
              case "host":
                result = collator.compare(a.engineHost, b.engineHost)
                break
              case "version":
                result = collator.compare(a.version, b.version)
                break
            }
            if (sortDirection === SortDirection.DESC) result = -result
            return result
          })
        }
      })

      // Flatten the list into rows
      const rows: EngineRowData[] = []
      groups.forEach(group => {
        if (group.name) {
          rows.push(group)
          const collapsed = collapsedGroups.has(group.name)
          if (!collapsed) {
            group.children.forEach((item: any, index: number) => {
              rows.push({ ...item, childIndex: index })
            })
          }
        } else {
          group.children.forEach((item: any, index: number) => {
            rows.push({ ...item, childIndex: index })
          })
        }
      })

      return rows
    }
    return null
  }

  const onRowClick = ({ rowData }: { rowData: any }) => {
    if (isGroupRowData(rowData)) {
      onExpandCollapse((rowData as EngineGroup).name)
    }
  }

  const rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    if (isGroupRowData(rowData)) {
      className = cn(className, "group", "clickable")
      if (Array.isArray(columns) && columns.length > 1) {
        // Only need the first column
        columns = [columns[0]]
      }
      key = (rowData as EngineGroup).name
    } else {
      if (rowData.childIndex != null && rowData.childIndex % 2 !== 0) {
        className = cn(className, "stripe")
      }
      key = rowData.engineId
    }

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  const rowClassName = () => {
    // Stripes applied in rowRenderer
    return ""
  }

  const nameHeaderRenderer = ({ dataKey, label, sortBy, sortDirection }: TableHeaderProps) => {
    let checkState = 0
    if (selectedEngines.length > 0) {
      if (viewEngines.length === selectedEngines.length) {
        checkState = 1
      } else {
        checkState = 2
      }
    }
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
        }}
      >
        <div style={{ fontSize: "1rem" }}>
          <CheckGroupIndeterminate
            type="checkbox"
            value={checkState}
            id="check-all"
            onChange={() => {
              if (checkState !== 1) {
                onEnableAll()
              } else {
                onDisableAll()
              }
            }}
          />
        </div>
        <span>{label}</span>
        {sortBy === dataKey ? <SortIndicator sortDirection={sortDirection} /> : null}
      </div>
    )
  }

  const cellRenderer = ({ dataKey, cellData, rowData, rowIndex }: TableCellProps) => {
    let content
    const isGroup = isGroupRowData(rowData)
    if (isGroup) {
      const rowItem: EngineGroup = rowData

      const allChildrenInvalid = rowItem.children.every(
        (engineConnection: EngineConnection) => !engineConnection.valid
      )
      const expanded = !collapsedGroups.has(cellData)
      const icon = expanded ? "chevron-down" : "chevron-right"
      let checkCount = 0
      let notIdleCount = 0
      for (const engine of rowItem.children) {
        const checked =
          selectedEngines.find(selectedId => selectedId === engine.engineId) !== undefined
        if (checked) {
          checkCount++
        }
        if (engine.syncStatus !== "idle") {
          notIdleCount++
        }
      }
      let checkState = 0
      if (!allChildrenInvalid) {
        if (checkCount === rowItem.children.length) {
          checkState = 1
        } else if (checkCount > 0) {
          checkState = 2
        }
      }
      content = (
        <div style={{ display: "flex", alignItems: "center" }}>
          <FontAwesome name={icon} fixedWidth />
          <span style={{ paddingLeft: ".25em" }}>
            {notIdleCount > 0 || !review ? (
              <>
                {cellData} <MutedText>{`(${formatInteger(rowItem.children.length)})`}</MutedText>
              </>
            ) : (
              <CheckGroupIndeterminate
                type="checkbox"
                value={checkState}
                id={rowDataToId(rowItem)}
                disabled={allChildrenInvalid}
                onChange={() => {
                  if (setSelectedEngines) {
                    let newSelectedEngines = selectedEngines.filter(selectedId => {
                      const engine = engines.find(engine => engine.engineId === selectedId)
                      return engine && engine.engineGroup !== rowItem.name
                    })

                    if (checkState !== 1) {
                      newSelectedEngines = newSelectedEngines.concat(
                        rowItem.children.map(engine => engine.engineId)
                      )
                    }
                    setSelectedEngines(newSelectedEngines)
                  }
                }}
              >
                {cellData} <MutedText>{`(${formatInteger(rowItem.children.length)})`}</MutedText>
              </CheckGroupIndeterminate>
            )}
          </span>
        </div>
      )
    } else {
      const engine: EngineConnection = rowData
      switch (dataKey) {
        case "name":
          {
            const id = rowDataToId(engine)
            const checked =
              selectedEngines.find(selectedId => selectedId === engine.engineId) !== undefined
            const somethingToSync = selectedConfigurationItems.some(
              (configItem: ConfigurationItem) =>
                engineCanSyncConfigType(engine, configItem.type, deleteCaptureSessions)
            )
            const marginLeft = engine.engineGroup ? "3.036em" : "1.536em"
            const name = engine.engineName
            content = (
              <div style={{ display: "flex", alignItems: "center", marginLeft }}>
                {engine.syncStatus === "idle" && review ? (
                  <CheckGroup
                    type="checkbox"
                    name={engine.engineId}
                    id={id}
                    onChange={onCheckEngine}
                    checked={checked && engine.valid && somethingToSync}
                    disabled={!engine.valid || !somethingToSync}
                  >
                    <span title={name}>{name}</span>
                  </CheckGroup>
                ) : (
                  <span title={name}>{name}</span>
                )}
              </div>
            )
          }
          break
        case "host":
          if (engine.valid) {
            content = engine.engineHost
          } else {
            content = <MutedText title={engine.engineHost}>{engine.engineHost}</MutedText>
          }
          break
        case "version":
          if (engine.valid) {
            content = engine.version
          } else {
            content = <MutedText title={engine.version}>{engine.version}</MutedText>
          }
          break
        case "configuration": {
          const selectedConfigurationItemsSorted = cloneDeep(selectedConfigurationItems)
          sortConfigurationItems(selectedConfigurationItemsSorted, sortBy, sortDirection)
          const configItems = selectedConfigurationItemsSorted.map(
            (configItem: ConfigurationItem) => {
              const icon = engine.syncResults
                .filter((syncResult: SyncResult) => syncResult.syncType === configItem.type)
                .map((syncResult: SyncResult) => {
                  if (syncResult.syncResult === PeekResult.PEEK_RESULT_S_OK) {
                    return (
                      <ConfigurationIconBlock key={`icon-block-${configItem.type}-${rowIndex}`}>
                        <SuccessText>
                          <FontAwesome
                            id={`status-config-${configItem.type}-${rowIndex}`}
                            size="lg"
                            name="check-circle"
                          />
                        </SuccessText>
                      </ConfigurationIconBlock>
                    )
                  } else {
                    return (
                      <ConfigurationIconBlock key={`icon-block-${configItem.type}-${rowIndex}`}>
                        <ErrorText>
                          <FontAwesome
                            id={`icon-block-${configItem.type}-${rowIndex}`}
                            size="lg"
                            name="times-circle"
                          />
                        </ErrorText>
                        {engine.syncError && (
                          <UncontrolledTooltip
                            placement="top"
                            target={`icon-block-${configItem.type}-${rowIndex}`}
                          >
                            {engine.syncError}
                          </UncontrolledTooltip>
                        )}
                      </ConfigurationIconBlock>
                    )
                  }
                })
              const details = engine.syncResults
                .filter((syncResult: SyncResult) => syncResult.syncType === configItem.type)
                .map((syncResult: SyncResult) => {
                  return (
                    <ConfigurationDetailsBlock key={`details-bar-${configItem.type}-${rowIndex}`}>
                      <LinkButton
                        size="sm"
                        id="defaults"
                        color="link"
                        onClick={() => onMessageDetail(engine, syncResult)}
                      >
                        Details
                      </LinkButton>
                      <IconButton
                        aria-label="Download Details"
                        id={`download-details-${configItem.type}-${rowIndex}`}
                        onClick={() => onDownloadDetails(engine, syncResult)}
                      >
                        <FontAwesome name="download" fixedWidth />
                      </IconButton>
                    </ConfigurationDetailsBlock>
                  )
                })
              if (engineCanSyncConfigType(engine, configItem.type, deleteCaptureSessions)) {
                return (
                  <ConfigurationItemRow key={`config-item-${configItem.type}-${rowIndex}`}>
                    {engine.valid ? (
                      <>
                        {final && icon}
                        <ConfigurationItemText>{configItem.name}</ConfigurationItemText>
                        {final && details}
                      </>
                    ) : (
                      <MutedText title={configItem.name}>{configItem.name}</MutedText>
                    )}
                  </ConfigurationItemRow>
                )
              } else {
                return (
                  <ConfigurationItemRow key={`config-item-${configItem.type}-${rowIndex}`}>
                    {final && (
                      <ConfigurationIconBlock>
                        <HiddenText>
                          <FontAwesome
                            id={`status-config-${configItem.type}-${rowIndex}`}
                            size="lg"
                            name="times-circle"
                          />
                        </HiddenText>
                      </ConfigurationIconBlock>
                    )}
                    {engine.valid ? (
                      <StrikeThroughText>{configItem.name}</StrikeThroughText>
                    ) : (
                      <MutedText title={configItem.name}>
                        <StrikeThroughText>{configItem.name}</StrikeThroughText>
                      </MutedText>
                    )}
                  </ConfigurationItemRow>
                )
              }
            }
          )
          content = <ConfigurationCell>{configItems}</ConfigurationCell>
          break
        }
        case "status":
          if (engine.syncStatus === "running" || (engine.syncStatus === "idle" && !review)) {
            content = (
              <StatusText>
                <FontAwesome size="lg" name="spinner" />
              </StatusText>
            )
          } else if (engine.syncStatus === "success") {
            content = (
              <StatusText>
                <SuccessText>
                  <FontAwesome id={`status-${rowIndex}`} size="lg" name="check-circle" />
                </SuccessText>
              </StatusText>
            )
          } else if (engine.syncStatus === "error") {
            content = (
              <StatusText>
                <ErrorText>
                  <FontAwesome id={`status-${rowIndex}`} size="lg" name="times-circle" />
                </ErrorText>
                {engine.syncError && (
                  <UncontrolledTooltip placement="top" target={`status-${rowIndex}`}>
                    {engine.syncError}
                  </UncontrolledTooltip>
                )}
              </StatusText>
            )
          }
          break
      }
    }
    return content
  }

  // Build the flattened tree for the table.
  const flattenedTree = getFlattenedTree()

  // Add the checkbox header renderer to the columns.
  const columns: any[] = columnDesc
    //.filter(col => (col.dataKey === "status" && final ? false : true))
    .map(col => {
      return { ...col }
    })
  const nameColumn = columns.find(col => col.dataKey === "name")
  if (nameColumn && review) {
    nameColumn.headerRenderer = nameHeaderRenderer
  }

  return (
    <>
      {flattenedTree && (
        <>
          {review && (
            <Alert color="info" fade={false}>
              <b>Notes:</b>
              <NoteList>
                <li>
                  Configuration can only be pushed to engines of the same version
                  {engineVersion !== null ? ` (${engineVersion})` : ""}. Engines running a different
                  version will not be selectable.
                </li>
                <li>
                  If access control is enabled on any of the target engines, it will determine which
                  configurations can be pushed to that target engine based on the privileges of the
                  user performing this configuration sync. Configurations that cannot be pushed to a
                  target engine due to access control restrictions will be displayed as
                  strikethrough text in the <i>Configuration</i> column in the table below and will
                  not be pushed. If no configurations can be pushed, the engine will not be
                  selectable.
                  <ul>
                    {ConfigurationItems.filter(
                      (configItem: ConfigurationItem) => configItem.policies.length > 0
                    ).map((configItem: ConfigurationItem) => (
                      <li key={`policy-list-${configItem.type}`}>
                        Pushing {configItem.name.toLowerCase()} require the following access control
                        policies:{" "}
                        {configItem.policies
                          .map((policy: string) => `"${EngineUserPolicyDescriptions[policy]}"`)
                          .join(", ")}
                      </li>
                    ))}
                  </ul>
                </li>
              </NoteList>
            </Alert>
          )}
          <ListFormGroup>
            <ButtonBar>
              <FilterBox
                aria-label="Search"
                placeholder="Search"
                onChange={(filter: string) => setEngineFilter(filter)}
                value={engineFilter}
              />
            </ButtonBar>
            {final && (
              <LightButton
                id="download-all-details"
                onClick={() => onDownloadAllDetails(flattenedTree)}
              >
                <FontAwesome name="download" /> Download All Details
              </LightButton>
            )}
          </ListFormGroup>

          <div
            style={{
              position: "relative",
              flexGrow: 1,
              minHeight: "250px",
            }}
          >
            <OmniTable
              data={flattenedTree}
              rowCount={flattenedTree.length}
              rowHeight={20 * selectedConfigurationItems.length}
              cellRenderer={cellRenderer}
              columnDesc={columns}
              rowRenderer={rowRenderer}
              rowClassName={rowClassName}
              onRowClick={onRowClick}
              sort={onSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            />
          </div>
          {showDetails && detailsMessage !== null && detailsTitle !== null && (
            <MessageModal
              show={true}
              message={detailsMessage}
              onOK={onMessageDetailOK}
              size={"md"}
              title={detailsTitle}
            />
          )}
        </>
      )}
    </>
  )
}

export default SyncEngines
