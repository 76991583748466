import * as React from "react"
import { UncontrolledTooltipProps as ReactstrapUncontrolledTooltipProps } from "reactstrap"
import Tooltip from "../Tooltip"

type UncontrolledTooltipProps = ReactstrapUncontrolledTooltipProps & {
  disabled?: boolean
}

type UncontrolledTooltipState = {
  isOpen: boolean
}

export class UncontrolledTooltip extends React.PureComponent<
  UncontrolledTooltipProps,
  UncontrolledTooltipState
> {
  state: UncontrolledTooltipState = {
    isOpen: false,
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    const { disabled = false, ...tooltipProps } = this.props
    return (
      <Tooltip
        isOpen={this.state.isOpen && !disabled}
        toggle={this.toggle}
        delay={{ show: 0, hide: 0 }}
        {...tooltipProps}
      />
    )
  }
}

export default UncontrolledTooltip
