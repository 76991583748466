import * as React from "react"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import { RouteComponentProps } from "react-router-dom"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { View, ViewMaxWidth, ViewContent } from "../common/View"
import PropTable from "../common/PropTable"
import { Link } from "../common/Link"
import { Panel } from "../common/Panel"
import { SecondaryButton } from "../common/Buttons"
import BarGauge from "../common/BarGauge"
import {
  getEngineActivateUrl,
  getEngineConfigureUrl,
  getEngineCapturesUrl,
  getEngineForensicsUrl,
  getEngineFilesUrl,
  getEngineForensicSearchesUrl,
  getEngineEventsUrl,
  getEngineAdaptersUrl,
  getEngineFiltersUrl,
  getEngineGraphsUrl,
  getEngineAlarmsUrl,
  getEngineNotificationsUrl,
  getEngineProtocolTranslationsUrl,
  getEngineNameTableUrl,
  getEngineHardwareProfilesUrl,
  getEngineDecryptionKeysUrl,
} from "../../routes"
import {
  getAuthToken,
  getEngine,
  getCapabilities,
  getStatus,
  getEngineCustomSettings,
  getEngineLicenseType,
} from "../../store"
import { propToLabel, formatProp } from "../../utils/propUtils"
import { formatInteger, formatFloat } from "../../utils/formatUtils"
import { fetchAdapters } from "../../api/api"
import { ResponseGetEngineCapabilities, ResponseGetStatus } from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { PeekAdapterFeatures } from "../../api/types/peekTypes"
import { click } from "../../utils/downloadUtils"

const propList = [
  "name",
  "hostName",
  "address",
  "userName",
  "engineType",
  "productVersion",
  "time",
  "timeZoneBias",
  "uptime",
  "operatingSystem",
  "ipmiAddr",
  "memoryTotalPhysical",
  "cpuType",
  "cpuCount",
  "dataFolder",
  "storageUsed",
]

const StatusPanel = styled(Panel)`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const StatusPanelBody = styled.div`
  flex-grow: 1;
`

const CaptureStorageBarContainer = styled.div`
  display: flex;
  align-items: center;

  & > div {
    min-width: 100px;
    margin-right: 4px;
  }
`

class CaptureStorageBar extends React.PureComponent<{ used: number; total: number }> {
  render() {
    const { used, total } = this.props
    const avail = total - used
    const totalGB = Math.floor(total / 1073741824)
    const availGB = Math.floor(avail / 1073741824)
    const pct = Math.floor((used / total) * 100)
    let color = undefined
    if (pct > 90) {
      color = "#dc3545"
    } else if (pct > 75) {
      color = "#ffc107"
    }
    const totalGBStr = formatInteger(totalGB)
    const availGBStr = formatInteger(availGB)
    const text = `${totalGBStr} GB Total; ${availGBStr} GB Avail`
    return (
      <CaptureStorageBarContainer>
        <BarGauge
          aria-label="Capture storage"
          value={pct}
          max={100}
          color={color}
          title={`${formatFloat(pct, 1)}%`}
        />
        <span>{text}</span>
      </CaptureStorageBarContainer>
    )
  }
}

const ButtonStrip = styled.div`
  display: flex;
  flex-direction: column;

  & button + button {
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    margin-top: 8px;

    & button + button {
      margin-left: 4px;
      margin-top: 0;
    }
  }
`

const LinksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 8px;
  padding-top: 8px;
`

const LinksTitle = styled.h4`
  grid-column-end: span 12;
  margin-top: 2rem;
  margin-bottom: 0;
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.textMutedColor};
`

const GridLink = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  text-decoration: none;
  color: ${props => props.theme.textColor};

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.textColor};
    background-color: ${props => props.theme.panelBackground};
  }

  grid-column-end: span 4;

  @media (max-width: 768px) {
    grid-column-end: span 6;
  }
`

const GridItemTitle = styled.h4`
  margin: 0;
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: ${props => props.theme.textMutedColor};
`

const GridItemCount = styled.div`
  font-size: 3rem;
  line-height: 4rem;
  text-align: center;
  text-decoration: none;
`

class GridItem extends React.PureComponent<{ to: string; title: string; count: string }> {
  render() {
    const { to, title, count } = this.props
    return (
      <GridLink to={to}>
        <GridItemCount>{count}</GridItemCount>
        <GridItemTitle>{title}</GridItemTitle>
      </GridLink>
    )
  }
}

type HomeProps = {
  engine: string
  authToken: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  engineStatus: ResponseGetStatus | null
  customSettings: number
  licenseType: number
}

type HomeState = {
  hardwareProfilesSupported: boolean
}

class Home extends React.Component<HomeProps & RouteComponentProps, HomeState> {
  state: HomeState = {
    hardwareProfilesSupported: false,
  }

  formatProp = (prop: string, data: ResponseGetStatus) => {
    switch (prop) {
      case "storageUsed":
        if ("storageTotal" in data) {
          return <CaptureStorageBar used={data.storageUsed} total={data.storageTotal} />
        }
        break
      default:
        break
    }

    return formatProp(prop, data)
  }

  componentDidMount() {
    const { engine, authToken } = this.props
    fetchAdapters(engine, authToken)
      .then(adapters => {
        if (Array.isArray(adapters.adapters)) {
          let show = false
          for (const adapter of adapters.adapters) {
            if ("features" in adapter.info) {
              show = (adapter.info.features & PeekAdapterFeatures.PEEK_ADAPTER_XYRATEX_FILTER) !== 0
            }
            if (show) break
          }
          this.setState({ hardwareProfilesSupported: show })
        }
      })
      .catch(() => {})
  }

  onConfigureEngine = () => {
    this.props.history.push(getEngineConfigureUrl())
  }

  onConfigureSystem = () => {
    // TODO: this is not right
    const a = document.createElement("a")
    a.href = `${this.props.engine}/config`
    a.target = "_blank"
    a.rel = "noopener"
    click(a)
  }

  onRenewLicense = () => {
    this.props.history.push(getEngineActivateUrl())
  }

  render() {
    const { hardwareProfilesSupported } = this.state
    const { engineCapabilities, engineStatus, customSettings, licenseType } = this.props
    const isConfigureEngineAllowed =
      engineCapabilities &&
      engineCapabilities.userRights.policies.includes(EngineUserPolicies.configureEngine)
    const decryptionKeysSupported =
      engineCapabilities && engineCapabilities.capabilities.includes(EngineCapabilities.decryption)
    return (
      <View>
        <Helmet title="Home" />
        <BreadcrumbItem to={this.props.match.url} title="Home" />
        <ViewMaxWidth>
          <ViewContent>
            {engineStatus && (
              <>
                <StatusPanel>
                  <StatusPanelBody>
                    <PropTable
                      skipEmptyRows={true}
                      propList={propList}
                      data={engineStatus}
                      propToLabel={propToLabel}
                      formatProp={this.formatProp}
                    />
                  </StatusPanelBody>
                  {isConfigureEngineAllowed ? (
                    <ButtonStrip>
                      <SecondaryButton onClick={this.onConfigureEngine}>
                        Configure Engine
                      </SecondaryButton>
                      {customSettings !== 2 && (
                        <SecondaryButton onClick={this.onConfigureSystem}>
                          Configure System
                        </SecondaryButton>
                      )}
                      <SecondaryButton onClick={this.onRenewLicense}>
                        {licenseType === 2 || licenseType === 3
                          ? "Renew License"
                          : "Update License"}
                      </SecondaryButton>
                    </ButtonStrip>
                  ) : null}
                </StatusPanel>
                <LinksGrid>
                  <GridItem
                    to={getEngineCapturesUrl()}
                    title="Captures"
                    count={formatInteger(engineStatus.captures)}
                  />
                  <GridItem
                    to={getEngineForensicsUrl()}
                    title="Capture Sessions"
                    count={formatInteger(engineStatus.captureSessionCount)}
                  />
                  <GridItem
                    to={getEngineFilesUrl()}
                    title="Files"
                    count={formatInteger(engineStatus.fileCount)}
                  />
                  <GridItem
                    to={getEngineForensicSearchesUrl()}
                    title="Forensic Searches"
                    count={formatInteger(engineStatus.forensicSearches)}
                  />
                  <GridItem
                    to={getEngineEventsUrl()}
                    title="Events"
                    count={formatInteger(engineStatus.logTotalCount)}
                  />
                  <GridItem
                    to={getEngineAdaptersUrl()}
                    title="Adapters"
                    count={formatInteger(engineStatus.adapterCount)}
                  />
                </LinksGrid>
                <LinksGrid>
                  <LinksTitle>Settings</LinksTitle>
                  <GridItem
                    to={getEngineFiltersUrl()}
                    title="Filters"
                    count={formatInteger(engineStatus.filterCount)}
                  />
                  <GridItem
                    to={getEngineGraphsUrl()}
                    title="Graphs"
                    count={formatInteger(engineStatus.graphCount)}
                  />
                  <GridItem
                    to={getEngineAlarmsUrl()}
                    title="Alarms"
                    count={formatInteger(engineStatus.alarmCount)}
                  />
                  <GridItem
                    to={getEngineNotificationsUrl()}
                    title="Notifications"
                    count={formatInteger(engineStatus.notificationCount)}
                  />
                  <GridItem
                    to={getEngineProtocolTranslationsUrl()}
                    title="Protocol Translations"
                    count={formatInteger(engineStatus.protocolTranslationCount)}
                  />
                  <GridItem
                    to={getEngineNameTableUrl()}
                    title="Names"
                    count={formatInteger(engineStatus.nameCount)}
                  />
                  {hardwareProfilesSupported && engineStatus.hardwareProfileCount !== undefined && (
                    <GridItem
                      to={getEngineHardwareProfilesUrl()}
                      title="Hardware Profiles"
                      count={formatInteger(engineStatus.hardwareProfileCount)}
                    />
                  )}
                  {decryptionKeysSupported && engineStatus.decryptionKeyCount !== undefined && (
                    <GridItem
                      to={getEngineDecryptionKeysUrl()}
                      title="Decryption Keys"
                      count={formatInteger(engineStatus.decryptionKeyCount)}
                    />
                  )}
                </LinksGrid>
              </>
            )}
          </ViewContent>
        </ViewMaxWidth>
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  authToken: getAuthToken(state),
  engine: getEngine(state),
  engineCapabilities: getCapabilities(state),
  engineStatus: getStatus(state),
  customSettings: getEngineCustomSettings(state),
  licenseType: getEngineLicenseType(state),
})

export default connect(mapStateToProps)(Home)
