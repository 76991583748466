import * as API from "../api/api"
import { EngineCollection } from "../api/types"

// Action types
export const types = {
  FETCH_ENGINES: "ENGINES/FETCH",
  FETCH_ENGINES_PENDING: "ENGINES/FETCH_PENDING",
  FETCH_ENGINES_FULFILLED: "ENGINES/FETCH_FULFILLED",
  FETCH_ENGINES_REJECTED: "ENGINES/FETCH_ENGINES_REJECTED",
  UPDATE_ENGINES_COUNTER: "ENGINES/UPDATE_ENGINES_COUNTER",
  SET_CURRENT_ENGINE: "ENGINES/SET_CURRENT_ENGINE",
}

export interface EnginesState {
  engines: EngineCollection | null
  isFetching: boolean
  error: string | null
  updateCounter: number
  currentEngine: string | null
}

// Initial state
export const initialState: EnginesState = {
  engines: null,
  isFetching: false,
  error: null,
  updateCounter: 0,
  currentEngine: null,
}

// Reducer
export default function reducer(state: EnginesState = initialState, action: any) {
  switch (action.type) {
    case types.FETCH_ENGINES_PENDING:
      return { ...state, isFetching: true, error: null }
    case types.FETCH_ENGINES_FULFILLED:
      return { ...state, isFetching: false, error: null, engines: action.payload }
    case types.FETCH_ENGINES_REJECTED:
      return { ...state, isFetching: false, error: action.payload }
    case types.UPDATE_ENGINES_COUNTER:
      return { ...state, updateCounter: state.updateCounter + 1 }
    case types.SET_CURRENT_ENGINE:
      return { ...state, currentEngine: action.payload }
    default:
      break
  }

  return state
}

// Actions

export const fetchEngines = (server: string, serverAuthToken: string, init?: RequestInit) => {
  return (dispatch: Function) => {
    dispatch({ type: types.FETCH_ENGINES_PENDING })
    API.fetchEngines(server, serverAuthToken, init)
      .then(engines => {
        dispatch({
          type: types.FETCH_ENGINES_FULFILLED,
          payload: engines,
        })
      })
      .catch(error => {
        dispatch({
          type: types.FETCH_ENGINES_REJECTED,
          payload: error,
        })
      })
  }
}

export const updateEngines = () => {
  return { type: types.UPDATE_ENGINES_COUNTER }
}

export const setCurrentEngine = (id: string | null) => {
  return { type: types.SET_CURRENT_ENGINE, payload: id }
}

// Selectors

export const getEngines = (state: EnginesState) =>
  state.engines != null ? state.engines.engines : null

export const getEnginesModificationTime = (state: EnginesState) =>
  state.engines != null ? state.engines.modificationTime : null

export const getEnginesUpdateCounter = (state: EnginesState) => state.updateCounter

export const getEnginesFetchInProgress = (state: EnginesState) => state.isFetching

export const getCurrentEngine = (state: EnginesState) => {
  if (state.engines != null && state.currentEngine != null) {
    return state.engines.engines.find(engine => engine.id === state.currentEngine)
  }
  return null
}
