import * as React from "react"

export const IconInformational = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 10240 10240"
    shapeRendering="geometricPrecision"
    height={16}
    width={16}
    {...props}
  >
    <path
      d="M5120 640c2474 0 4480 2006 4480 4480S7594 9600 5120 9600 640 7594 640 5120 2646 640 5120 640z"
      fill="#1d42b2"
    />
    <path
      d="M5120 1920c530 0 960 430 960 960s-430 960-960 960-960-430-960-960 430-960 960-960zm-640 3520c0-176-144-320-320-320v-640h1280c176 0 320 144 320 320v2560c0 176 144 320 320 320h320v640H3840v-640h320c176 0 320-144 320-320V5440z"
      fill="#fff"
    />
  </svg>
)
