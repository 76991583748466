import styled, { keyframes } from "styled-components"

type Size = "xs" | "sm" | "lg"

type SpinnerProps = {
  size?: Size
}

const dimensions = {
  xs: 16,
  sm: 24,
  lg: 48,
}

const border = {
  xs: 2,
  sm: 3,
  lg: 6,
}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

export const Spinner = styled.div<SpinnerProps>`
  height: ${props => (props.size ? dimensions[props.size] : 32)}px;
  width: ${props => (props.size ? dimensions[props.size] : 32)}px;
  border-radius: 50%;
  border: ${props => (props.size ? border[props.size] : 4)}px solid rgba(125, 136, 155, 0.3);
  border-left: ${props => (props.size ? border[props.size] : 4)}px solid
    ${props => props.theme.primaryButtonBackgroundColor};
  animation: ${rotate360} 1.1s infinite linear;
`
