import * as React from "react"
import FontAwesome from "react-fontawesome"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import FileSaver from "file-saver"
import { cloneDeep } from "lodash"
import styled, { DefaultTheme, useTheme } from "styled-components"
import { v4 as uuid } from "uuid"
import { defaultAlarm } from "../AlarmsEditView"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { InsertNameEntry, InsertNamesModal } from "../InsertNamesModal"
import { Alert } from "../common/Alert"
import BarGauge from "../common/BarGauge"
import { IconButton, IconDropdownToggle, LightButton } from "../common/Buttons"
import CountryName from "../common/CountryName"
import { DropdownItem, DropdownMenu, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import FilterBox from "../common/FilterBox"
import Interval from "../common/Interval"
import { OmniTable } from "../common/OmniTable"
import PropTable from "../common/PropTable"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import {
  View,
  ViewContent,
  ViewHeader,
  ViewHeaderButtons,
  ViewHeaderPanel,
  ViewHeaderTitle,
} from "../common/View"
import {
  getCaptureForensicSearchUrl,
  getEngineNewAlarmUrl,
  getEngineNewFilterUrl,
} from "../../routes"
import {
  getAuthToken,
  getCapabilities,
  getEngine,
  getMPLSVLANVXLANStatsColumns,
  getMPLSVLANVXLANStatsFilter,
  getMPLSVLANVXLANStatsSortBy,
  getMPLSVLANVXLANStatsSortDirection,
  getShowAddressNames,
  getShowLocalTime,
  getUserId,
} from "../../store"
import { setSelectPacketsTask } from "../../store/selectPackets"
import { updateStatus } from "../../store/status"
import {
  setMPLSVLANVXLANStatsFilter,
  setMPLSVLANVXLANStatsColumns,
  setMPLSVLANVXLANStatsSort,
} from "../../store/ui"
import csvStringify from "../../utils/csvStringify"
import {
  formatDuration,
  formatFloat,
  formatInteger,
  formatISODateTime,
} from "../../utils/formatUtils"
import { formatMediaSpec } from "../../utils/mediaSpec"
import { fetchCFSStatistics, postSelectRelatedFilterStart, resolveAddresses } from "../../api/api"
import {
  AddressFilterNode,
  ApplicationFilterNode,
  ApplicationStatisticsTracker,
  AlarmInfo,
  CountryFilterNode,
  CountryStatisticsTracker,
  Filter,
  FilterNode,
  MPLSVLANVXLANStatistics,
  MPLSVLANVXLANStatisticsCountryCodeObject,
  MPLSVLANVXLANStatisticsMediaSpecObject,
  MPLSVLANVXLANStatisticsObject,
  MPLSVLANVXLANStatisticsTracker,
  NodeStatisticsTracker,
  ProtocolFilterNode,
  ProtocolStatisticsTracker,
  RequestGetStatisticsStatistic,
  RequestPostSelectRelatedFilterStart,
  ResponseGetEngineCapabilities,
  ResponsePostSelectRelatedFilterStart,
  StatisticsTracker,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { MediaSpecType } from "../../api/types/mediaTypes"
import {
  PeekApplicationStatType,
  PeekCountryStatType,
  PeekMPLSVLANVXLANID,
  PeekMPLSVLANVXLANStatType,
  PeekMPLSVLANVXLANType,
  PeekNodeStatType,
  PeekProtocolStatType,
} from "../../api/types/peekTypes"

const defaultColumns = [
  {
    dataKey: "name",
    label: "MPLS/VLAN/VXLAN",
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "percentage",
    label: "Percentage",
    width: 140,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "bytes",
    label: "Bytes",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "packets",
    label: "Packets",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "firstTime",
    label: "First Time",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "lastTime",
    label: "Last Time",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "duration",
    label: "Duration",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
]

const PeekMPLSVLANVXLANNames = {
  PEEK_MPLS_VLAN_VXLAN_NAME_MPLS: "MPLS",
  PEEK_MPLS_VLAN_VXLAN_NAME_VLAN: "VLAN",
  PEEK_MPLS_VLAN_VXLAN_NAME_VXLAN_GPID: "VXLAN Group Policy ID",
  PEEK_MPLS_VLAN_VXLAN_NAME_VXLAN_VNI: "VXLAN VNI",
  PEEK_MPLS_VLAN_VXLAN_NAME_NODES: "Nodes",
  PEEK_MPLS_VLAN_VXLAN_NAME_PROTOCOLS: "Protocols",
  PEEK_MPLS_VLAN_VXLAN_NAME_APPLICATIONS: "Applications",
  PEEK_MPLS_VLAN_VXLAN_NAME_COUNTRIES: "Countries",
}

const propList = [
  [
    PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_MPLS,
    PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_VLAN,
    PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_VXLAN_GPID,
    PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_VXLAN_VNI,
  ],
]

const CommandStrip = styled.div`
  display: flex;
`

const getItemId = (
  id: PeekMPLSVLANVXLANID,
  aid: PeekMPLSVLANVXLANID,
  type: PeekMPLSVLANVXLANType,
  value: number
): string => {
  return `${id}-${aid}-${type}-${value}`
}

const buildMPLSVLANVXLANAssociatedItemsByID = (
  mplsVlanVxlanAssociatedObjs: (
    | MPLSVLANVXLANStatisticsCountryCodeObject
    | MPLSVLANVXLANStatisticsMediaSpecObject
  )[],
  id: PeekMPLSVLANVXLANID,
  aid: PeekMPLSVLANVXLANID,
  type: PeekMPLSVLANVXLANType,
  value: number,
  name: string
): MPLSVLANVXLANItem | null => {
  const associatedObjs = mplsVlanVxlanAssociatedObjs.filter(
    (
      mplsVlanVxlanAssociatedObj:
        | MPLSVLANVXLANStatisticsCountryCodeObject
        | MPLSVLANVXLANStatisticsMediaSpecObject
    ) => mplsVlanVxlanAssociatedObj.id === aid
  )
  return associatedObjs.length > 0
    ? {
        children: associatedObjs.map(
          (
            mplsVlanVxlanAssociatedObj:
              | MPLSVLANVXLANStatisticsCountryCodeObject
              | MPLSVLANVXLANStatisticsMediaSpecObject
          ) => {
            return {
              children: [],
              depth: 3,
              item: {
                id,
                aid: mplsVlanVxlanAssociatedObj.id,
                type,
                value,
              },
              itemId: getItemId(id, mplsVlanVxlanAssociatedObj.id, type, value),
              name: mplsVlanVxlanAssociatedObj.name,
              stat: mplsVlanVxlanAssociatedObj,
            }
          }
        ),
        depth: 2,
        item: {
          id,
          aid,
          type,
          value,
        },
        itemId: getItemId(id, aid, type, value),
        name,
        stat: null,
      }
    : null
}

const buildMPLSVLANVXLANAssociatedItems = (
  mplsVlanVxlanStatisticsObj: MPLSVLANVXLANStatisticsObject
): MPLSVLANVXLANItem[] => {
  const mplsVlanVxlanAssociatedItems: MPLSVLANVXLANItem[] = []

  // Nodes
  const nodeStatistics = buildMPLSVLANVXLANAssociatedItemsByID(
    mplsVlanVxlanStatisticsObj.nodes,
    mplsVlanVxlanStatisticsObj.id,
    PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_NODE,
    mplsVlanVxlanStatisticsObj.type,
    mplsVlanVxlanStatisticsObj.value,
    PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_NODES
  )
  if (nodeStatistics) {
    mplsVlanVxlanAssociatedItems.push(nodeStatistics)
  }

  // Protocols
  const protocolStatistics = buildMPLSVLANVXLANAssociatedItemsByID(
    mplsVlanVxlanStatisticsObj.protocols,
    mplsVlanVxlanStatisticsObj.id,
    PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_PROTOCOL,
    mplsVlanVxlanStatisticsObj.type,
    mplsVlanVxlanStatisticsObj.value,
    PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_PROTOCOLS
  )
  if (protocolStatistics) {
    mplsVlanVxlanAssociatedItems.push(protocolStatistics)
  }

  // Applications
  const applicationStatistics = buildMPLSVLANVXLANAssociatedItemsByID(
    mplsVlanVxlanStatisticsObj.applications,
    mplsVlanVxlanStatisticsObj.id,
    PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_APPLICATION,
    mplsVlanVxlanStatisticsObj.type,
    mplsVlanVxlanStatisticsObj.value,
    PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_APPLICATIONS
  )
  if (applicationStatistics) {
    mplsVlanVxlanAssociatedItems.push(applicationStatistics)
  }

  // Countries
  const countryStatistics = buildMPLSVLANVXLANAssociatedItemsByID(
    mplsVlanVxlanStatisticsObj.countries,
    mplsVlanVxlanStatisticsObj.id,
    PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_COUNTRY,
    mplsVlanVxlanStatisticsObj.type,
    mplsVlanVxlanStatisticsObj.value,
    PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_COUNTRIES
  )
  if (countryStatistics) {
    mplsVlanVxlanAssociatedItems.push(countryStatistics)
  }

  return mplsVlanVxlanAssociatedItems
}

const buildMPLSVLANVXLANItemsByID = (
  mplsVlanVxlanStatisticsObjs: MPLSVLANVXLANStatisticsObject[],
  id: PeekMPLSVLANVXLANID,
  type: PeekMPLSVLANVXLANType,
  name: string
): MPLSVLANVXLANItem | null => {
  const statistics = mplsVlanVxlanStatisticsObjs.filter(
    (mplsVlanVxlanStatisticsObj: MPLSVLANVXLANStatisticsObject) =>
      mplsVlanVxlanStatisticsObj.id === id
  )
  return statistics.length > 0
    ? {
        children: statistics.map((mplsVlanVxlanStatisticsObj: MPLSVLANVXLANStatisticsObject) => {
          return {
            children: buildMPLSVLANVXLANAssociatedItems(mplsVlanVxlanStatisticsObj),
            depth: 1,
            item: {
              id: mplsVlanVxlanStatisticsObj.id,
              aid: PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_UNDEFINED,
              type: mplsVlanVxlanStatisticsObj.type,
              value: mplsVlanVxlanStatisticsObj.value,
            },
            itemId: getItemId(
              mplsVlanVxlanStatisticsObj.id,
              PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_UNDEFINED,
              mplsVlanVxlanStatisticsObj.type,
              mplsVlanVxlanStatisticsObj.value
            ),
            name: mplsVlanVxlanStatisticsObj.name,
            stat: mplsVlanVxlanStatisticsObj,
          }
        }),
        depth: 0,
        item: {
          id,
          aid: PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_UNDEFINED,
          type,
          value: 0,
        },
        itemId: getItemId(id, PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_UNDEFINED, type, 0),
        name,
        stat: null,
      }
    : null
}

const buildMPLSVLANVXLANItems = (
  mplsVlanVxlanStatisticsObjs: MPLSVLANVXLANStatisticsObject[]
): MPLSVLANVXLANItem[] => {
  const mplsVlanVxlanItems: MPLSVLANVXLANItem[] = []

  // MPLS
  const mplsStatistics = buildMPLSVLANVXLANItemsByID(
    mplsVlanVxlanStatisticsObjs,
    PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_MPLS_LABEL,
    PeekMPLSVLANVXLANType.PEEK_MPLS_VLAN_VXLAN_TYPE_MPLS_LABEL,
    PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_MPLS
  )
  if (mplsStatistics) {
    mplsVlanVxlanItems.push(mplsStatistics)
  }

  // VLAN
  const vlanStatistics = buildMPLSVLANVXLANItemsByID(
    mplsVlanVxlanStatisticsObjs,
    PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_VLAN_ID,
    PeekMPLSVLANVXLANType.PEEK_MPLS_VLAN_VXLAN_TYPE_VLAN_ID,
    PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_VLAN
  )
  if (vlanStatistics) {
    mplsVlanVxlanItems.push(vlanStatistics)
  }

  // VXLAN GPID
  const vxlanGPIDStatistics = buildMPLSVLANVXLANItemsByID(
    mplsVlanVxlanStatisticsObjs,
    PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_VXLAN_GPID,
    PeekMPLSVLANVXLANType.PEEK_MPLS_VLAN_VXLAN_TYPE_VXLAN_GPID,
    PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_VXLAN_GPID
  )
  if (vxlanGPIDStatistics) {
    mplsVlanVxlanItems.push(vxlanGPIDStatistics)
  }

  // VXLAN VNI
  const vxlanVNIStatistics = buildMPLSVLANVXLANItemsByID(
    mplsVlanVxlanStatisticsObjs,
    PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_VXLAN_VNI,
    PeekMPLSVLANVXLANType.PEEK_MPLS_VLAN_VXLAN_TYPE_VXLAN_VNI,
    PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_VXLAN_VNI
  )
  if (vxlanVNIStatistics) {
    mplsVlanVxlanItems.push(vxlanVNIStatistics)
  }

  return mplsVlanVxlanItems
}

const getHeaderCounts = (
  mplsVlanVxlanStatisticsObjs: MPLSVLANVXLANStatisticsObject[]
): HeaderCountsObj => {
  const headerCounts: HeaderCountsObj = {
    mpls: 0,
    vlan: 0,
    vxlanGPID: 0,
    vxlanVNI: 0,
  }

  if (mplsVlanVxlanStatisticsObjs) {
    mplsVlanVxlanStatisticsObjs.forEach(
      (mplsVlanVxlanStatisticsObj: MPLSVLANVXLANStatisticsObject) => {
        switch (mplsVlanVxlanStatisticsObj.type) {
          case PeekMPLSVLANVXLANType.PEEK_MPLS_VLAN_VXLAN_TYPE_MPLS_LABEL:
            headerCounts.mpls++
            break
          case PeekMPLSVLANVXLANType.PEEK_MPLS_VLAN_VXLAN_TYPE_VLAN_ID:
            headerCounts.vlan++
            break
          case PeekMPLSVLANVXLANType.PEEK_MPLS_VLAN_VXLAN_TYPE_VXLAN_GPID:
            headerCounts.vxlanGPID++
            break
          case PeekMPLSVLANVXLANType.PEEK_MPLS_VLAN_VXLAN_TYPE_VXLAN_VNI:
            headerCounts.vxlanVNI++
            break
          default:
            break
        }
      }
    )
  }

  return headerCounts
}

const sortConvertName = (name: string): number => {
  let value: number = 0
  if (name === PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_MPLS) {
    value = PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_MPLS_LABEL
  } else if (name === PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_VLAN) {
    value = PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_VLAN_ID
  } else if (name === PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_VXLAN_GPID) {
    value = PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_VXLAN_GPID
  } else if (name === PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_VXLAN_VNI) {
    value = PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_VXLAN_VNI
  } else if (name === PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_NODES) {
    value = PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_NODE
  } else if (name === PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_PROTOCOLS) {
    value = PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_PROTOCOL
  } else if (name === PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_APPLICATIONS) {
    value = PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_APPLICATION
  } else if (name === PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_COUNTRIES) {
    value = PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_COUNTRY
  }
  return value
}

const sort = (
  mplsVlanVxlanItems: MPLSVLANVXLANItem[] | null,
  totalBytes: number,
  sortBy: string,
  sortDirection: SortDirectionType,
  showAddressName: boolean
) => {
  if (Array.isArray(mplsVlanVxlanItems)) {
    mplsVlanVxlanItems.sort((a: MPLSVLANVXLANItem, b: MPLSVLANVXLANItem) => {
      let result = 0
      let ignoreDirection = false

      let sortOrder: string[] = []
      switch (sortBy) {
        case "name":
          sortOrder = ["name", "packets", "bytes", "firstTime", "lastTime", "duration"]
          break
        case "packets":
          sortOrder = ["packets", "name", "bytes", "firstTime", "lastTime", "duration"]
          break
        case "percentage":
          sortOrder = [
            "percentage",
            "bytes",
            "name",
            "packets",
            "firstTime",
            "lastTime",
            "duration",
          ]
          break
        case "bytes":
          sortOrder = ["bytes", "name", "packets", "firstTime", "lastTime", "duration"]
          break
        case "firstTime":
          sortOrder = ["firstTime", "lastTime", "duration", "name", "bytes", "packets"]
          break
        case "lastTime":
          sortOrder = ["lastTime", "firstTime", "duration", "name", "bytes", "packets"]
          break
        case "duration":
          sortOrder = ["duration", "firstTime", "lastTime", "name", "bytes", "packets"]
          break
      }

      for (let i = 0; i < sortOrder.length && result === 0; i++) {
        if (a.stat !== null && b.stat !== null) {
          if (sortOrder[i] === "percentage") {
            const valueA = totalBytes !== 0 ? (a.stat.bytes / totalBytes) * 100 : 0
            const valueB = totalBytes !== 0 ? (b.stat.bytes / totalBytes) * 100 : 0
            if (valueA > valueB) {
              result = 1
            } else if (valueA < valueB) {
              result = -1
            }
          } else if (sortOrder[i] === "name") {
            const nameA = !showAddressName && "item" in a.stat ? a.stat.item : a.stat.name
            const nameB = !showAddressName && "item" in b.stat ? b.stat.item : b.stat.name
            if (nameA != null && nameB != null) {
              if (nameA > nameB) {
                result = 1
              } else if (nameA < nameB) {
                result = -1
              }
            } else if (nameA != null) {
              result = 1
            } else if (nameB != null) {
              result = -1
            }
          } else {
            const valueA =
              a.stat[
                sortOrder[i] as keyof (
                  | MPLSVLANVXLANStatisticsCountryCodeObject
                  | MPLSVLANVXLANStatisticsMediaSpecObject
                  | MPLSVLANVXLANStatisticsObject
                )
              ]
            const valueB =
              b.stat[
                sortOrder[i] as keyof (
                  | MPLSVLANVXLANStatisticsCountryCodeObject
                  | MPLSVLANVXLANStatisticsMediaSpecObject
                  | MPLSVLANVXLANStatisticsObject
                )
              ]
            if (valueA != null && valueB != null) {
              if (valueA > valueB) {
                result = 1
              } else if (valueA < valueB) {
                result = -1
              }
            } else if (valueA != null) {
              result = 1
            } else if (valueB != null) {
              result = -1
            }
          }
        } else if (a.stat !== null) {
          result = 1
        } else if (b.stat !== null) {
          result = -1
        } else {
          const nameA = sortConvertName(a.name)
          const nameB = sortConvertName(b.name)
          if (nameA > nameB) {
            result = 1
          } else if (nameA < nameB) {
            result = -1
          }
          ignoreDirection = true
        }

        if (!ignoreDirection && sortDirection === SortDirection.DESC) result = -result
      }

      return result
    })
    mplsVlanVxlanItems.forEach((mplsVlanVxlanItem: MPLSVLANVXLANItem) =>
      sort(mplsVlanVxlanItem.children, totalBytes, sortBy, sortDirection, showAddressName)
    )
  }
}

type HeaderCountsObj = {
  mpls: number
  vlan: number
  vxlanGPID: number
  vxlanVNI: number
}

type MPLSVLANVXLANItemItem = {
  id: number
  aid: number
  type: number
  value: number
}

type MPLSVLANVXLANItem = {
  children: MPLSVLANVXLANItem[]
  depth: number
  item: MPLSVLANVXLANItemItem
  itemId: string
  name: string
  stat:
    | MPLSVLANVXLANStatisticsCountryCodeObject
    | MPLSVLANVXLANStatisticsMediaSpecObject
    | MPLSVLANVXLANStatisticsObject
    | null
}

type MPLSVLANVXLANStatisticsViewProps = RouteComponentProps<CaptureRouteParams> &
  CaptureViewProps & {
    namesModTime?: string
  }

const MPLSVLANVXLANStatisticsView = ({
  captureProperties,
  namesModTime,
  ...viewProps
}: MPLSVLANVXLANStatisticsViewProps) => {
  const [collapsedItems, setCollapsedItems] = React.useState<string[]>([])
  const [fetchError, setFetchError] = React.useState<any | null>(null)
  const [headerCounts, setHeaderCounts] = React.useState<HeaderCountsObj | null>(null)
  const [insertNameEntry, setInsertNameEntry] = React.useState<InsertNameEntry | null>(null)
  const [mplsVlanVxlanItems, setMPLSVLANVXLANItems] = React.useState<MPLSVLANVXLANItem[]>([])
  const [mplsVlanVxlanStatistics, setMPLSVLANVXLANStatistics] =
    React.useState<MPLSVLANVXLANStatistics | null>(null)
  const [mplsVlanVxlanTree, setMPLSVLANVXLANTree] = React.useState<MPLSVLANVXLANItem[]>([])

  const dispatch = useDispatch()

  const history = useHistory()

  const authToken: string = useSelector(getAuthToken)
  const columns = useSelector(getMPLSVLANVXLANStatsColumns) || defaultColumns
  const engineCapabilities: ResponseGetEngineCapabilities | null =
    (useSelector(getCapabilities) as ResponseGetEngineCapabilities) || null
  const engine: string = useSelector(getEngine)
  const filter: string = useSelector(getMPLSVLANVXLANStatsFilter) || ""
  const showAddressNames: boolean = useSelector(getShowAddressNames)
  const showLocalTime: boolean = useSelector(getShowLocalTime)
  const sortBy: string = useSelector(getMPLSVLANVXLANStatsSortBy) || "bytes"
  const sortDirection: SortDirectionType =
    useSelector(getMPLSVLANVXLANStatsSortDirection) || SortDirection.DESC
  const userId: string = useSelector(getUserId)

  const theme = useTheme() as DefaultTheme

  const getItemIds = (item: MPLSVLANVXLANItem): string[] => {
    let itemIds: string[] = [item.itemId]
    item.children.forEach((childItem: MPLSVLANVXLANItem) => {
      itemIds = itemIds.concat(getItemIds(childItem))
    })
    return itemIds
  }

  const createFilterNode = (rowData: MPLSVLANVXLANItem): FilterNode => {
    // determine the MPLS/VLAN/VXLAN filter
    let ids = ""
    let labels = ""
    let vxlanGpids = ""
    let vxlanVnis = ""
    switch (rowData.item.id) {
      case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_MPLS_LABEL:
        labels = `${rowData.item.value}`
        break
      case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_VLAN_ID:
        ids = `${rowData.item.value}`
        break
      case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_VXLAN_GPID:
        vxlanGpids = `${rowData.item.value}`
        break
      case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_VXLAN_VNI:
        vxlanVnis = `${rowData.item.value}`
        break
      default:
        break
    }

    // determine the associated item filter
    let associatedFilterNode: FilterNode | null = null
    if (rowData.stat) {
      switch (rowData.stat.id) {
        case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_NODE:
          if ("item" in rowData.stat && "spec" in rowData.stat) {
            associatedFilterNode = {
              accept1To2: true,
              accept2To1: true,
              address1: rowData.stat.item,
              address2: "",
              clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
              comment: "",
              inverted: false,
              type: rowData.stat.spec.type,
            } as AddressFilterNode
          }
          break
        case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_PROTOCOL:
          if ("spec" in rowData.stat) {
            associatedFilterNode = {
              clsid: "A43DDCC0-CDD2-46B4-8114-68E5FAF35112",
              comment: "",
              inverted: false,
              protocol: rowData.stat.spec,
              protospecPath: "",
              sliceToHeader: false,
            } as ProtocolFilterNode
          }
          break
        case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_APPLICATION:
          if ("spec" in rowData.stat) {
            associatedFilterNode = {
              applicationId: parseInt(formatMediaSpec(rowData.stat.spec)),
              clsid: "B588E248-AB43-4AB8-AAFB-E2E1BD2EC428",
              comment: "",
              inverted: false,
            } as ApplicationFilterNode
          }
          break
        case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_COUNTRY:
          if ("countryCode" in rowData.stat) {
            associatedFilterNode = {
              accept1To2: true,
              accept2To1: true,
              clsid: "0866D6FD-0DBC-47A9-AD9E-927A6489D01C",
              comment: "",
              country1: rowData.stat.countryCode,
              country2: "",
              inverted: false,
            } as CountryFilterNode
          }
          break
        default:
          break
      }
    }

    return {
      andNode: associatedFilterNode ? associatedFilterNode : undefined,
      clsid: "04208487-8370-4B63-98D6-B9AA68C8DF7D",
      comment: "",
      ids,
      inverted: false,
      labels,
      vxlanGpids,
      vxlanVnis,
    }
  }

  const onRefresh = React.useCallback(() => {
    fetchCFSStatistics<MPLSVLANVXLANStatistics>(
      engine,
      authToken,
      viewProps.match.params.type,
      viewProps.match.params.capId,
      "mplsvlanvxlan" as RequestGetStatisticsStatistic
    )
      .then((statistics: MPLSVLANVXLANStatistics) => {
        const mplsVlanVxlanItems = buildMPLSVLANVXLANItems(statistics.mplsvlanvxlan)
        sort(mplsVlanVxlanItems, statistics.totalBytes, sortBy, sortDirection, showAddressNames)
        setMPLSVLANVXLANItems(mplsVlanVxlanItems)
        setMPLSVLANVXLANStatistics(statistics)
        setHeaderCounts(getHeaderCounts(statistics.mplsvlanvxlan))
      })
      .catch(error => {
        setFetchError(error)
      })
  }, [
    authToken,
    engine,
    showAddressNames,
    sortBy,
    sortDirection,
    viewProps.match.params.capId,
    viewProps.match.params.type,
  ])

  React.useEffect(() => {
    onRefresh()
  }, [namesModTime, showAddressNames, onRefresh])

  const onChangeFilter = (filter: string) => {
    dispatch(setMPLSVLANVXLANStatsFilter(filter))
  }

  const onExpandCollapse = (itemId: string) => {
    if (collapsedItems.includes(itemId)) {
      setCollapsedItems(collapsedItems.filter((id: string) => id !== itemId))
    } else {
      setCollapsedItems(collapsedItems.concat(itemId))
    }
  }

  const onExpandSelection = (rowData: MPLSVLANVXLANItem) => {
    if (collapsedItems.includes(rowData.itemId)) {
      setCollapsedItems(collapsedItems.filter((id: string) => id !== rowData.itemId))
    }
  }

  const onCollapseSelection = (rowData: MPLSVLANVXLANItem) => {
    setCollapsedItems(collapsedItems.concat(rowData.itemId))
  }

  const onExpandAll = () => {
    setCollapsedItems([])
  }

  const onCollapseAll = () => {
    let collapsedItems: string[] = []
    mplsVlanVxlanItems.forEach((mplsVlanVxlanItem: MPLSVLANVXLANItem) => {
      collapsedItems = collapsedItems.concat(getItemIds(mplsVlanVxlanItem))
    })
    setCollapsedItems(collapsedItems)
  }

  const onExport = () => {
    const visibleColumns = [{ dataKey: "indent", label: "Indent" }].concat(
      columns.filter((col: any) => col.visible)
    )
    let csv = visibleColumns.map(col => csvStringify(col.label)).join(",") + "\n"

    mplsVlanVxlanTree.forEach((mplsVlanVxlanItem: MPLSVLANVXLANItem) => {
      const row: string[] = []
      visibleColumns.forEach(col => {
        let content: string | number | any = ""
        switch (col.dataKey) {
          case "indent":
            content = mplsVlanVxlanItem.depth + 1
            break
          case "name":
            content = mplsVlanVxlanItem.name
            break
          case "percentage":
            if (mplsVlanVxlanItem.stat) {
              const percentage =
                mplsVlanVxlanStatistics && mplsVlanVxlanStatistics.totalBytes !== 0
                  ? (mplsVlanVxlanItem.stat.bytes / mplsVlanVxlanStatistics.totalBytes) * 100
                  : 0
              content = formatFloat(percentage, 3)
            }
            break
          case "bytes":
            if (mplsVlanVxlanItem.stat) {
              content = mplsVlanVxlanItem.stat.bytes
            }
            break
          case "packets":
            if (mplsVlanVxlanItem.stat) {
              content = mplsVlanVxlanItem.stat.packets
            }
            break
          case "firstTime":
            if (mplsVlanVxlanItem.stat) {
              content = formatISODateTime(mplsVlanVxlanItem.stat.firstTime, 0, showLocalTime)
            }
            break
          case "lastTime":
            if (mplsVlanVxlanItem.stat) {
              content = formatISODateTime(mplsVlanVxlanItem.stat.lastTime, 0, showLocalTime)
            }
            break
          case "duration":
            if (mplsVlanVxlanItem.stat?.duration !== undefined) {
              content = formatDuration(mplsVlanVxlanItem.stat.duration, 6)
            }
            break
          default:
            break
        }
        row.push(csvStringify(content))
      })
      csv += row.join(",") + "\n"
    })

    FileSaver.saveAs(
      new Blob([csv], { type: "text/plain;charset=utf8" }),
      "MPLSVLANVXLAN Statistics.csv"
    )
  }

  const onInsertIntoNameTable = (rowData: MPLSVLANVXLANItem) => {
    if (rowData.stat && "spec" in rowData.stat) {
      setInsertNameEntry({
        title: "Node Address",
        entry: rowData.stat.item,
        entryType: rowData.stat.spec.type,
      })
    }
  }

  const isResolvable = (mt: MediaSpecType) => {
    return (
      mt === MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS ||
      mt === MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS
    )
  }

  const onResolveNames = (rowData: MPLSVLANVXLANItem) => {
    if (rowData.stat && "spec" in rowData.stat) {
      if (isResolvable(rowData.stat.spec.type)) {
        resolveAddresses(engine, authToken, [
          { entry: rowData.stat.item, entryType: rowData.stat.spec.type },
        ]).catch(error => {
          console.error(error)
        })
        dispatch(updateStatus())
      }
    }
  }

  const onInsertIntoNameTableOK = () => {
    setInsertNameEntry(null)
    onRefresh()
  }

  const onInsertIntoNameTableCancel = () => {
    setInsertNameEntry(null)
  }

  const onMakeAlarm = (rowData: MPLSVLANVXLANItem) => {
    let name = rowData.name
    let statisticsTracker: StatisticsTracker | null = null
    if (rowData.stat) {
      switch (rowData.stat.id) {
        case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_MPLS_LABEL:
          name = `MPLS ${rowData.name}`
          if ("type" in rowData.stat && "value" in rowData.stat) {
            statisticsTracker = {
              clsid: "708D4781-7877-48C7-8487-AD1BBF9E680F",
              history: 60,
              statisticsType: PeekMPLSVLANVXLANStatType.PEEK_MPLS_VLAN_VXLAN_STAT_TYPE_TOTAL_BYTES,
              type: rowData.stat.type,
              value: rowData.stat.value,
            } as MPLSVLANVXLANStatisticsTracker
          }
          break
        case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_VLAN_ID:
          name = `VLAN ${rowData.name}`
          if ("type" in rowData.stat && "value" in rowData.stat) {
            statisticsTracker = {
              clsid: "708D4781-7877-48C7-8487-AD1BBF9E680F",
              history: 60,
              statisticsType: PeekMPLSVLANVXLANStatType.PEEK_MPLS_VLAN_VXLAN_STAT_TYPE_TOTAL_BYTES,
              type: rowData.stat.type,
              value: rowData.stat.value,
            } as MPLSVLANVXLANStatisticsTracker
          }
          break
        case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_VXLAN_GPID:
          name = `VXLAN Group Policy ID ${rowData.name}`
          if ("type" in rowData.stat && "value" in rowData.stat) {
            statisticsTracker = {
              clsid: "708D4781-7877-48C7-8487-AD1BBF9E680F",
              history: 60,
              statisticsType: PeekMPLSVLANVXLANStatType.PEEK_MPLS_VLAN_VXLAN_STAT_TYPE_TOTAL_BYTES,
              type: rowData.stat.type,
              value: rowData.stat.value,
            } as MPLSVLANVXLANStatisticsTracker
          }
          break
        case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_VXLAN_VNI:
          name = `VXLAN VNI ${rowData.name}`
          if ("type" in rowData.stat && "value" in rowData.stat) {
            statisticsTracker = {
              clsid: "708D4781-7877-48C7-8487-AD1BBF9E680F",
              history: 60,
              statisticsType: PeekMPLSVLANVXLANStatType.PEEK_MPLS_VLAN_VXLAN_STAT_TYPE_TOTAL_BYTES,
              type: rowData.stat.type,
              value: rowData.stat.value,
            } as MPLSVLANVXLANStatisticsTracker
          }
          break
        case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_NODE:
          if ("spec" in rowData.stat) {
            statisticsTracker = {
              clsid: "0EC1390E-3A7D-45B3-9803-84D74B5D5B80",
              history: 60,
              node: rowData.stat.spec,
              statisticsType: PeekNodeStatType.PEEK_NODE_STAT_TYPE_TOTAL_BYTES,
            } as NodeStatisticsTracker
          }
          break
        case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_PROTOCOL:
          if ("spec" in rowData.stat) {
            statisticsTracker = {
              clsid: "CDC6DD80-C8AC-4968-8A1C-CC30932E2E84",
              history: 60,
              protocol: rowData.stat.spec,
              statisticsType: PeekProtocolStatType.PEEK_PROTOCOL_STAT_TYPE_BYTES,
            } as ProtocolStatisticsTracker
          }
          break
        case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_APPLICATION:
          if ("spec" in rowData.stat) {
            statisticsTracker = {
              application: rowData.stat.spec,
              clsid: "055E270A-CED1-4A68-A98E-B9A4CCE93FA3",
              history: 60,
              statisticsType: PeekApplicationStatType.PEEK_APPLICATION_STAT_TYPE_BYTES,
            } as ApplicationStatisticsTracker
          }
          break
        case PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_COUNTRY:
          if ("countryCode" in rowData.stat) {
            statisticsTracker = {
              clsid: "5FF04EFD-9A1D-436B-BD5D-3E206296C90A",
              country: rowData.stat.countryCode,
              history: 60,
              statisticsType: PeekCountryStatType.PEEK_COUNTRY_STAT_TYPE_TOTAL_BYTES,
            } as CountryStatisticsTracker
          }
          break
        default:
          break
      }
    }

    if (statisticsTracker) {
      const alarm: AlarmInfo = {
        ...cloneDeep(defaultAlarm),
        name,
        statisticsTracker,
      }
      history.push({
        pathname: getEngineNewAlarmUrl(),
        state: { alarm },
      })
    }
  }

  const onMakeFilter = (rowData: MPLSVLANVXLANItem) => {
    const filter: Filter = {
      clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
      color: "#000000",
      comment: "",
      created: "",
      group: "",
      id: "",
      modified: "",
      name: "Untitled",
      rootNode: createFilterNode(rowData),
    }
    history.push({
      pathname: getEngineNewFilterUrl(),
      state: { filter },
    })
  }

  const onSelectRelated = (rowData: MPLSVLANVXLANItem) => {
    const body: RequestPostSelectRelatedFilterStart = {
      id: uuid(),
      rootNode: createFilterNode(rowData),
    }

    postSelectRelatedFilterStart(engine, authToken, viewProps.match.params.capId, body)
      .then((task: ResponsePostSelectRelatedFilterStart) => {
        if (task.taskId) {
          dispatch(setSelectPacketsTask({ type: "filter", taskId: task.taskId, progress: 0 }))
          history.push("packets")
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const onShowAllColumns = () => {
    const columnsNew = cloneDeep(columns)
    columnsNew.forEach((col: any) => {
      col.visible = true
    })
    dispatch(setMPLSVLANVXLANStatsColumns(columnsNew))
  }

  const onShowDefaultColumns = () => {
    dispatch(setMPLSVLANVXLANStatsColumns(defaultColumns))
  }

  const onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const columnsNew = cloneDeep(columns)
    const col = columnsNew.find((col: any) => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      dispatch(setMPLSVLANVXLANStatsColumns(columnsNew))
    }
  }

  const cellRenderer = ({ dataKey, rowData }: TableCellProps) => {
    let content = null
    switch (dataKey) {
      case "name": {
        const color = theme.name === "Light" && rowData.stat?.color

        content = (
          <div
            style={{
              paddingLeft: `${rowData.depth * 1.257}em`,
              fontWeight: rowData.depth === 0 ? "bold" : undefined,
            }}
          >
            {rowData.children.length > 0 && (
              <IconButton
                aria-label="Expand/Collapse"
                onClick={() => onExpandCollapse(rowData.itemId)}
              >
                <FontAwesome
                  name={collapsedItems.includes(rowData.itemId) ? "chevron-right" : "chevron-down"}
                  fixedWidth
                />
              </IconButton>
            )}
            {rowData.stat && "countryCode" in rowData.stat ? (
              <div style={{ paddingLeft: ".25em", color }} title={rowData.name}>
                <CountryName name={rowData.name} code={rowData.stat.countryCode} />
              </div>
            ) : (
              <span style={{ paddingLeft: ".25em", color }} title={rowData.name}>
                {rowData.name}
              </span>
            )}
          </div>
        )
        break
      }
      case "percentage":
        if (rowData.stat) {
          const percentage =
            mplsVlanVxlanStatistics && mplsVlanVxlanStatistics.totalBytes !== 0
              ? (rowData.stat.bytes / mplsVlanVxlanStatistics.totalBytes) * 100
              : 0
          content = (
            <BarGauge
              value={percentage}
              color={rowData.stat.color}
              max={100}
              title={`${formatFloat(percentage, 3)}%`}
            />
          )
        }
        break
      case "bytes":
        if (rowData.stat) {
          content = formatInteger(rowData.stat.bytes)
        }
        break
      case "packets":
        if (rowData.stat) {
          content = formatInteger(rowData.stat.packets)
        }
        break
      case "firstTime":
        if (rowData.stat) {
          content = formatISODateTime(rowData.stat.firstTime, 0, showLocalTime)
        }
        break
      case "lastTime":
        if (rowData.stat) {
          content = formatISODateTime(rowData.stat.lastTime, 0, showLocalTime)
        }
        break
      case "duration":
        if (rowData.stat?.duration !== undefined) {
          content = formatDuration(rowData.stat.duration, 6)
        }
        break
      default:
        break
    }
    return content
  }

  const commandCellRenderer = ({ rowData }: TableCellProps) => {
    const packetsDisabled = captureProperties === null || !captureProperties.packetBufferEnabled
    const selectRelatedPacketsDisabled = packetsDisabled || rowData.code === ""

    // make sure the user can view packets for this capture or forensic search
    let canViewPackets = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
    }

    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            <DropdownItem onClick={() => onExpandSelection(rowData)}>Expand Selection</DropdownItem>
            <DropdownItem onClick={() => onCollapseSelection(rowData)}>
              Collapse Selection
            </DropdownItem>
            {rowData.stat && (
              <>
                <DropdownItem divider />
                <DropdownItem
                  disabled={selectRelatedPacketsDisabled || !canViewPackets}
                  onClick={() => onSelectRelated(rowData)}
                >
                  Select Related Packets
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => onMakeAlarm(rowData)}>Make Alarm</DropdownItem>
                <DropdownItem onClick={() => onMakeFilter(rowData)}>Make Filter</DropdownItem>
                {rowData.stat.id === PeekMPLSVLANVXLANID.PEEK_MPLS_VLAN_VXLAN_ID_NODE && (
                  <>
                    <DropdownItem onClick={() => onInsertIntoNameTable(rowData)}>
                      Insert Into Name Table
                    </DropdownItem>
                    <DropdownItem onClick={() => onResolveNames(rowData)}>
                      Resolve Names
                    </DropdownItem>
                  </>
                )}
              </>
            )}
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  const rowClassName = ({ index }: { index: number }) => {
    if (index >= 0 && index < mplsVlanVxlanTree.length && mplsVlanVxlanTree[index].depth === 0) {
      return "group"
    } else {
      if (index >= 0 && index % 2 !== 0) {
        return "stripe"
      }
    }
    return ""
  }

  const onSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
  }) => {
    sort(
      mplsVlanVxlanItems,
      mplsVlanVxlanStatistics ? mplsVlanVxlanStatistics.totalBytes : 0,
      sortBy,
      sortDirection,
      showAddressNames
    )
    dispatch(setMPLSVLANVXLANStatsSort(sortBy, sortDirection))
  }

  const formatHeaderCountsProp = (columnId: string, data: HeaderCountsObj | null) => {
    switch (columnId) {
      case PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_MPLS:
        return data !== null ? formatInteger(data.mpls) : "0"
      case PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_VLAN:
        return data !== null ? formatInteger(data.vlan) : "0"
      case PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_VXLAN_GPID:
        return data !== null ? formatInteger(data.vxlanGPID) : "0"
      case PeekMPLSVLANVXLANNames.PEEK_MPLS_VLAN_VXLAN_NAME_VXLAN_VNI:
        return data !== null ? formatInteger(data.vxlanVNI) : "0"
      default:
        break
    }

    return ""
  }

  const buildTree = React.useCallback(
    (items: MPLSVLANVXLANItem[]): MPLSVLANVXLANItem[] => {
      return items.reduce((tree: MPLSVLANVXLANItem[], item: MPLSVLANVXLANItem) => {
        const name =
          item.stat && !showAddressNames && "item" in item.stat ? item.stat.item : item.name
        const lowerCaseFilter = filter.toLowerCase()
        if (
          item.depth !== 3 ||
          filter.length === 0 ||
          name.toLowerCase().includes(lowerCaseFilter)
        ) {
          if (collapsedItems.includes(item.itemId) || item.children.length === 0) {
            return [...tree, { ...item, name }]
          }
          const subTree = buildTree(item.children)
          if (subTree.length === 0) {
            if (item.depth === 1 && name.toLowerCase().includes(lowerCaseFilter)) {
              return [...tree, { ...item, name }]
            } else {
              return [...tree]
            }
          } else {
            return [...tree, { ...item, name }, ...subTree]
          }
        }
        return [...tree, ...buildTree(item.children)]
      }, [])
    },
    [collapsedItems, filter, showAddressNames]
  )

  React.useEffect(() => {
    setMPLSVLANVXLANTree(buildTree(mplsVlanVxlanItems))
  }, [collapsedItems, filter, mplsVlanVxlanItems, showAddressNames, buildTree])

  const { type, capId } = viewProps.match.params

  // make sure the user can view stats for this capture or forensic search
  if (captureProperties && engineCapabilities) {
    const isUserOwner = userId === captureProperties.creatorSID
    const policies = engineCapabilities.userRights.policies
    const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
    if (!canViewStats) {
      return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
    }
  }

  return (
    <View>
      <BreadcrumbItem to={viewProps.match.url} title="MPLS/VLAN/VXLAN Statistics" />
      <Interval timeout={30000} enabled={true} callback={onRefresh} />
      <ViewHeader>
        {fetchError !== null && (
          <Alert color="danger">
            {typeof fetchError === "string"
              ? fetchError
              : `${fetchError.code} ${fetchError.reason}`}
          </Alert>
        )}
        <ViewHeaderTitle
          title="MPLS/VLAN/VXLAN"
          count={mplsVlanVxlanStatistics?.mplsvlanvxlan.length || 0}
        />
        <ViewHeaderButtons>
          <LightButton id="expand-all" onClick={onExpandAll}>
            Expand All
          </LightButton>
          <LightButton id="collapse-all" onClick={onCollapseAll}>
            Collapse All
          </LightButton>
          <FilterBox
            aria-label="Search"
            placeholder="Search"
            onChange={onChangeFilter}
            value={filter}
          />
          <LightButton aria-label="Export" id="export" onClick={onExport}>
            <FontAwesome name="download" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="export">
            Export
          </UncontrolledTooltip>
          <LightButton aria-label="Refresh" id="refresh" onClick={onRefresh}>
            <FontAwesome name="refresh" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="refresh">
            Refresh
          </UncontrolledTooltip>
        </ViewHeaderButtons>
      </ViewHeader>
      <ViewHeaderPanel>
        <PropTable data={headerCounts} propList={propList} formatProp={formatHeaderCountsProp} />
      </ViewHeaderPanel>
      <ViewContent>
        <OmniTable
          data={mplsVlanVxlanTree}
          rowCount={mplsVlanVxlanTree.length}
          columnDesc={columns}
          cellRenderer={cellRenderer}
          renderCommands={commandCellRenderer}
          rowClassName={rowClassName}
          onShowDefaultColumns={onShowDefaultColumns}
          onShowAllColumns={onShowAllColumns}
          onToggleColumn={onToggleColumn}
          sort={onSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      </ViewContent>
      {insertNameEntry != null && (
        <InsertNamesModal
          engine={engine}
          authToken={authToken}
          entries={[insertNameEntry]}
          onOK={onInsertIntoNameTableOK}
          onCancel={onInsertIntoNameTableCancel}
        />
      )}
    </View>
  )
}

export default MPLSVLANVXLANStatisticsView
