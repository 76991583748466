import { toNumber } from "lodash"
import { SortDirection, SortDirectionType } from "react-virtualized"
import { getCaptureTemplateName } from "./captureUtils"
import { getMediaString } from "./mediaUtils"
import {
  Adapter,
  ApplicationInfo,
  CaptureTemplate,
  DatabaseRowGetCaptureSessions,
  GraphObject,
  HardwareOptions,
  Protocol,
} from "../api/types"

export const collator = new Intl.Collator(undefined, { sensitivity: "base", numeric: true })

// Partial sort array from start to end index (end not included)
export function partialSort(
  array: any[],
  start: number,
  end: number,
  compareFn?: ((a: any, b: any) => number) | undefined
) {
  const preSorted = array.slice(0, start)
  const postSorted = array.slice(end)
  const sorted = array.slice(start, end).sort(compareFn)
  array.length = 0
  array.push.apply(array, preSorted.concat(sorted).concat(postSorted))
  return array
}

export function sortAdapters(a: Adapter, b: Adapter) {
  let aFeatures = 0
  if (
    a.info.clsid === "56A9F62B-0048-469E-BD47-121AB13237C6" ||
    a.info.clsid === "24A9A571-C7DD-43BE-8008-2E26F47CE7CA"
  ) {
    aFeatures = a.info.features
  }
  let bFeatures = 0
  if (
    b.info.clsid === "56A9F62B-0048-469E-BD47-121AB13237C6" ||
    b.info.clsid === "24A9A571-C7DD-43BE-8008-2E26F47CE7CA"
  ) {
    bFeatures = b.info.features
  }
  if (aFeatures !== 0 && bFeatures === 0) {
    return -1
  } else if (aFeatures === 0 && bFeatures !== 0) {
    return 1
  }
  return collator.compare(a.info.description, b.info.description)
}

export function sortApplications(a: ApplicationInfo, b: ApplicationInfo) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1
  }
  return 0
}

export function sortCaptureTemplates(a: CaptureTemplate, b: CaptureTemplate) {
  // first, sort by type
  if (a.type !== b.type) {
    return a.type < b.type ? -1 : 1
  } else {
    // then, sort by name
    const nameA = getCaptureTemplateName(a)
    const nameB = getCaptureTemplateName(b)
    return collator.compare(nameA, nameB)
  }
}

export function sortChannelStrings(a: string, b: string) {
  let channelA = -1
  let frequencyA = -1
  let bandA = ""
  if (/[0-9]+\s*-\s*[0-9]+(\.5){0,1}\s*MHz\s*\(.*\)/.test(a)) {
    const matchesA = a.match(/([0-9]+(\.5){0,1}|\(.*\))/gi)
    if (Array.isArray(matchesA) && matchesA.length > 0) {
      channelA = toNumber(matchesA[0])
      if (matchesA.length > 1) {
        frequencyA = toNumber(matchesA[1])
        if (matchesA.length > 2 && matchesA[2].length >= 2) {
          bandA = matchesA[2].substr(1, matchesA[2].length - 2).toLowerCase()
        }
      }
    }
  }

  let channelB = -1
  let frequencyB = -1
  let bandB = ""
  if (/[0-9]+\s*-\s*[0-9]+(\.5){0,1}\s*MHz\s*\(.*\)/.test(b)) {
    const matchesB = b.match(/([0-9]+(\.5){0,1}|\(.*\))/gi)
    if (Array.isArray(matchesB) && matchesB.length > 0) {
      channelB = toNumber(matchesB[0])
      if (matchesB.length > 1) {
        frequencyB = toNumber(matchesB[1])
        if (matchesB.length > 2 && matchesB[2].length >= 2) {
          bandB = matchesB[2].substr(1, matchesB[2].length - 2).toLowerCase()
        }
      }
    }
  }

  const channelSort = channelA < channelB ? -1 : channelA > channelB ? 1 : 0
  const frequencySort = frequencyA < frequencyB ? -1 : frequencyA > frequencyB ? 1 : 0
  const bandSort = bandA < bandB ? -1 : bandA > bandB ? 1 : 0

  if (channelSort < 0) {
    return -1
  } else if (channelSort > 0) {
    return 1
  } else {
    if (frequencySort < 0) {
      return -1
    } else if (frequencySort > 0) {
      return 1
    } else {
      if (bandSort < 0) {
        return -1
      } else if (bandSort > 0) {
        return 1
      } else {
        return 0
      }
    }
  }
}

export function sortGraphs(
  graphs: GraphObject[] | null,
  sortBy: string,
  sortDirection: SortDirectionType
) {
  if (Array.isArray(graphs)) {
    const collator = new Intl.Collator(undefined, { sensitivity: "base", numeric: true })
    graphs.sort((a: GraphObject, b: GraphObject) => {
      let result = collator.compare(a[sortBy], b[sortBy])
      if (result === 0) {
        // Fall back to name.
        result = collator.compare(a.title, b.title)
      }
      if (sortDirection === SortDirection.DESC) result = -result
      return result
    })
  }
}

export function sortHardwareOptions(a: HardwareOptions, b: HardwareOptions) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1
  }
  return 0
}

export function sortProtocols(a: Protocol, b: Protocol) {
  if (a.shortName.toLowerCase() < b.shortName.toLowerCase()) {
    return -1
  }
  if (a.shortName.toLowerCase() > b.shortName.toLowerCase()) {
    return 1
  }
  return 0
}

export const compareCaptureSessions = (
  a: DatabaseRowGetCaptureSessions,
  b: DatabaseRowGetCaptureSessions,
  sortBy: string,
  sortDirection: SortDirectionType
) => {
  let result = 0
  const sortOrder = [sortBy, "Name", "StartTimestamp"]
  for (let i = 0; i < sortOrder.length && result === 0; i++) {
    const sortField = sortOrder[i]
    switch (sortOrder[i]) {
      case "Name":
      case "SessionStartTimestamp":
      case "StartTimestamp":
      case "StopTimestamp":
      case "AdapterName":
      case "AdapterAddr":
      case "Owner":
        if (sortField in a && sortField in b) {
          result = collator.compare(a[sortField], b[sortField])
        } else if (sortField in a) {
          result = 1
        } else if (sortField in b) {
          result = -1
        }
        break
      case "StorageUnits":
      case "PacketCount":
      case "DroppedCount":
      case "LinkSpeed":
        if (sortField in a && sortField in b) {
          const aValue = toNumber(a[sortField])
          const bValue = toNumber(b[sortField])
          if (aValue > bValue) {
            result = 1
          } else if (aValue < bValue) {
            result = -1
          }
        } else if (sortField in a) {
          result = 1
        } else if (sortField in b) {
          result = -1
        }
        break
      case "duration":
        if (
          a.StartTimestamp != null &&
          a.StopTimestamp != null &&
          b.StartTimestamp != null &&
          b.StopTimestamp != null
        ) {
          const aValue = Date.parse(a.StopTimestamp) - Date.parse(a.StartTimestamp)
          const bValue = Date.parse(b.StopTimestamp) - Date.parse(b.StartTimestamp)
          if (aValue > bValue) {
            result = 1
          } else if (aValue < bValue) {
            result = -1
          }
        } else if ("StartTimestamp" in a) {
          result = 1
        } else if ("StartTimestamp" in b) {
          result = -1
        }
        break
      case "MediaType":
        if ("MediaType" in a && "MediaSubType" in a && "MediaType" in b && "MediaSubType" in b) {
          const aValue = getMediaString(toNumber(a.MediaType), toNumber(a.MediaSubType))
          const bValue = getMediaString(toNumber(b.MediaType), toNumber(b.MediaSubType))
          result = collator.compare(aValue, bValue)
        } else if ("MediaType" in a) {
          result = 1
        } else if ("MediaType" in b) {
          result = -1
        }
        break
      default:
        break
    }
  }

  if (sortDirection === SortDirection.DESC) result = -result
  return result
}
