import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { cloneDeep, debounce } from "lodash"
import { v4 as uuid } from "uuid"
import styled, { DefaultTheme, withTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import FileSaver from "file-saver"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { DropdownMenu, DropdownItem } from "../common/Dropdown"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { OmniTable } from "../common/OmniTable"
import { View, ViewContent, ViewHeader, ViewHeaderTitle, ViewHeaderButtons } from "../common/View"
import { LightButton, IconDropdownToggle } from "../common/Buttons"
import { UncontrolledDropdownWithPortal } from "../common/Dropdown"
import Interval from "../common/Interval"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import {
  formatInteger,
  formatDuration,
  formatFloat,
  formatISODateTime,
} from "../../utils/formatUtils"
import {
  getCaptureForensicSearchUrl,
  getEngineNewFilterUrl,
  getNewDistributedForensicSearchUrl,
} from "../../routes"
import {
  getEngine,
  getAuthToken,
  getReconstructionsFilter,
  getReconstructionsColumns,
  getReconstructionsSortBy,
  getReconstructionsSortDirection,
  getNamesModificationTime,
  getShowAddressNames,
  getShowPortNames,
  getCapabilities,
  getUserId,
  getShowLocalTime,
} from "../../store"
import { setCurrentEngine } from "../../store/engines"
import {
  setReconstructionsFilter,
  setReconstructionsColumns,
  setReconstructionsSort,
} from "../../store/ui"
import { setSelectPacketsTask } from "../../store/selectPackets"
import {
  fetchReconstructionsList,
  fetchReconstructionsRaw,
  postSelectRelatedExpertStart,
  postSelectRelatedFilterStart,
  resolveAddresses,
} from "../../api/api"
import {
  AddressFilterNode,
  Filter,
  FilterNode,
  PortFilterNode,
  RequestPostSelectRelatedFilterStart,
  ResponsePostSelectRelatedFilterStart,
  ReconstructionsDesc,
  RequestPostSelectRelatedExpertStart,
  ResponsePostSelectRelatedExpertStart,
  ResponseGetReconstructionsList,
  AddressResolverRequestEntry,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { MediaSpecType } from "../../api/types/mediaTypes"
import ReconstructionsSidebar from "./ReconstructionsSidebar"
import { RowMouseEventHandlerParams } from "react-virtualized/dist/es/Table"
import { defaultColumns } from "./columns"
import ReconstructionsSearchBox, {
  SEARCH_TYPES,
  CONTENT_TYPES,
  contentTypeOptions,
} from "./ReconstructionsSearchBox"
import ReconstructionsDownloadModal from "./ReconstructionsDownloadModal"
import { InsertNameEntry, InsertNamesModal } from "../InsertNamesModal"
import { updateStatus } from "../../store/status"
import { formatMediaSpec } from "../../utils/mediaSpec"
import CountryName from "../common/CountryName"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { PeekFileViewStatus } from "../../api/types/peekTypes"
import csvStringify from "../../utils/csvStringify"
import { PROTOCOL_TYPES, protocolOptions } from "./protocols"
import { CenterContent } from "../common/Layout"
import { Spinner } from "../common/Spinner"

const CommandStrip = styled.div`
  display: flex;
`

type ReconstructionsViewProps = RouteComponentProps<CaptureRouteParams> &
  CaptureViewProps & {
    dispatch: Function
    engine: string
    authToken: string
    columns: any[]
    sortBy: string
    sortDirection: SortDirectionType
    theme: DefaultTheme
    namesModTime?: string
    showAddressNames: boolean
    showPortNames: boolean
    showLocalTime: boolean
    engineCapabilities: ResponseGetEngineCapabilities | null
    userId: string
  }

const ReconstructionsView = ({
  dispatch,
  engine,
  authToken,
  columns,
  sortBy,
  sortDirection,
  captureProperties,
  theme,
  namesModTime,
  showAddressNames,
  showPortNames,
  showLocalTime,
  engineCapabilities,
  userId,
  ...viewProps
}: ReconstructionsViewProps) => {
  const [reconstructionsList, setReconstructionsList] = React.useState<
    ReconstructionsDesc[] | null
  >(null)
  const [showSidebar, setShowSidebar] = React.useState<string | null>(null)

  const [search, setSearch] = React.useState<string>("")
  const [debouncedSearch, setDebouncedSearch] = React.useState<string>("")
  const [searchOption, setSearchOption] = React.useState<SEARCH_TYPES>(
    SEARCH_TYPES.SEARCH_FILE_NAMES
  )
  const [debouncedSearchOption, setDebouncedSearchOption] = React.useState<SEARCH_TYPES>(
    SEARCH_TYPES.SEARCH_FILE_NAMES
  )

  const [contentTypeSearch, setContentTypeSearch] = React.useState<string | null>(null)
  const [contentTypeOption, setContentTypeOption] = React.useState<CONTENT_TYPES>(CONTENT_TYPES.ALL)
  const [debouncedSearchContentType, setDebouncedSearchContentType] = React.useState<string | null>(
    null
  )

  const [downloadReconstruction, setDownloadReconstruction] = React.useState<ReconstructionsDesc>()
  const [showReconstructionsDownloadModal, setShowReconstructionsDownloadModal] =
    React.useState<boolean>(false)

  const [protocolOption, setProtocolOption] = React.useState<PROTOCOL_TYPES>(PROTOCOL_TYPES.ALL)
  const [insertNameEntries, setInsertNameEntries] = React.useState<InsertNameEntry[] | null>(null)
  const [loading, setLoading] = React.useState<boolean>(true)

  const refreshEnabled =
    captureProperties?.status !== PeekFileViewStatus.PEEK_FILE_VIEW_STATUS_COMPLETE

  const onRefresh = React.useCallback(() => {
    const queryParams = []
    const contentType: string | null =
      debouncedSearchContentType != null
        ? debouncedSearchContentType
        : contentTypeOptions[contentTypeOption].value

    if (contentType) {
      queryParams.push(`contentType=${contentType}`)
    }

    if (protocolOption !== PROTOCOL_TYPES.ALL) {
      queryParams.push(`protocol=${protocolOption}`)
    }

    if (debouncedSearch) {
      switch (debouncedSearchOption) {
        case SEARCH_TYPES.SEARCH_FILE_NAMES: {
          queryParams.push(`fileName=${debouncedSearch}`)
          break
        }
        case SEARCH_TYPES.SEARCH_HEADERS: {
          queryParams.push(`headers=${debouncedSearch}`)
          break
        }
        case SEARCH_TYPES.SEARCH_CONTENTS: {
          queryParams.push(`contents=${debouncedSearch}`)
          break
        }
      }
    }

    fetchReconstructionsList(
      engine,
      authToken,
      viewProps.match.params.type,
      viewProps.match.params.capId,
      queryParams
    )
      .then((reconstructionsList: ResponseGetReconstructionsList) => {
        if (reconstructionsList) {
          sort(reconstructionsList.reconstructions, sortBy, sortDirection, showAddressNames)
        }
        setReconstructionsList(reconstructionsList.reconstructions)
      })
      .catch(() => {
        console.error("Error while fetching reconstructions")
      })
  }, [
    viewProps.match.params.capId,
    viewProps.match.params.type,
    authToken,
    engine,
    sortBy,
    sortDirection,
    debouncedSearch,
    debouncedSearchOption,
    contentTypeOption,
    debouncedSearchContentType,
    showAddressNames,
    protocolOption,
  ])

  React.useEffect(() => {
    onRefresh()
    setLoading(false)
  }, [onRefresh, namesModTime, showAddressNames, showPortNames])

  const onChangeSearch = (option: number, text: string) => {
    dispatch(setReconstructionsFilter(text))
    setSearch(text)
    setSearchOption(option)
    debounceSearchFunc(text)
    debounceSearchOptionFunc(option)
  }

  const debounceSearchFunc = React.useMemo(
    () =>
      debounce(text => {
        setDebouncedSearch(text)
      }, 300),
    [setDebouncedSearch]
  )

  const debounceSearchOptionFunc = React.useMemo(
    () =>
      debounce(option => {
        setDebouncedSearchOption(option)
      }, 300),
    [setDebouncedSearchOption]
  )

  const onChangeContentTypeDropdown = (contentTypeOption: CONTENT_TYPES) => {
    setContentTypeOption(contentTypeOption)
  }

  const onChangeContentTypeSearch = (contentType: string | null) => {
    setContentTypeSearch(contentType)
    debounceContentTypeFunc(contentType)
  }

  const debounceContentTypeFunc = React.useMemo(
    () =>
      debounce(contentType => {
        setDebouncedSearchContentType(contentType)
      }, 300),
    [setDebouncedSearchContentType]
  )

  const onChangeProtocolDropdown = (option: PROTOCOL_TYPES) => {
    setProtocolOption(option)
  }

  const onShowDefaultColumns = () => {
    dispatch(setReconstructionsColumns(defaultColumns))
  }

  const onShowAllColumns = () => {
    const clonedColumns = cloneDeep(columns)
    clonedColumns.forEach(col => {
      col.visible = true
    })
    dispatch(setReconstructionsColumns(clonedColumns))
  }

  const onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const clonedColumns = cloneDeep(columns)
    const col = clonedColumns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      dispatch(setReconstructionsColumns(clonedColumns))
    }
  }

  const onSelectRelated = (command: string, rowData: ReconstructionsDesc) => {
    const { capId } = viewProps.match.params

    // Use Expert for flow ID and filters for everything else
    if (command === "flowId") {
      let request: RequestPostSelectRelatedExpertStart | null = null
      if (rowData.flowID) {
        request = {
          criteria: [
            {
              streamId: rowData.flowID as any,
            },
          ],
        }

        if (request != null) {
          postSelectRelatedExpertStart(engine, authToken, capId, request)
            .then((task: ResponsePostSelectRelatedExpertStart) => {
              if (task.taskId) {
                dispatch(setSelectPacketsTask({ type: "expert", taskId: task.taskId, progress: 0 }))
                viewProps.history.push("packets")
              }
            })
            .catch(() => {
              console.error("Error during select related")
            })
        }
      }
    } else {
      const address1 = command.includes("client") ? rowData.clientAddress : rowData.serverAddress
      const address2 = command === "clientAndServer" ? rowData.serverAddress : ""

      const addressFilterNode: AddressFilterNode = {
        accept1To2: true,
        accept2To1: true,
        address1: address1,
        address2: address2,
        clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
        comment: "",
        inverted: false,
        type: MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS,
      }

      const body: RequestPostSelectRelatedFilterStart = {
        id: uuid(),
        rootNode: addressFilterNode,
      }

      postSelectRelatedFilterStart(engine, authToken, capId, body)
        .then((task: ResponsePostSelectRelatedFilterStart) => {
          if (task.taskId) {
            dispatch(setSelectPacketsTask({ type: "filter", taskId: task.taskId, progress: 0 }))
            viewProps.history.push("packets")
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const onMSA = (rowData: ReconstructionsDesc) => {
    if (rowData.fileName) {
      const filter = `addr(type:ip, addr1:'${rowData.clientAddress}', addr2:'${rowData.serverAddress}')`
      dispatch(setCurrentEngine(null))
      viewProps.history.push({
        pathname: getNewDistributedForensicSearchUrl(),
        state: {
          startTime: rowData.firstTimestamp,
          endTime: rowData.lastTimestamp,
          filter,
        },
      })
    }
  }

  const onMakeFilter = (rowData: ReconstructionsDesc) => {
    const addressFilterNode: AddressFilterNode = {
      accept1To2: true,
      accept2To1: true,
      address1: rowData.clientAddress,
      address2: rowData.serverAddress,
      clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
      comment: "",
      inverted: false,
      type: MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS,
    }

    const portFilterNode: PortFilterNode = {
      accept1To2: true,
      accept2To1: true,
      clsid: "B3279AE9-91E1-4D0A-8ABD-6D1BDC5471A9",
      comment: "",
      inverted: false,
      port1: String(rowData.clientPort),
      port2: String(rowData.serverPort),
      type: MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT,
    }

    const rootNode: FilterNode = addressFilterNode
    addressFilterNode.andNode = portFilterNode

    const filter: Filter = {
      clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
      color: "#000000",
      comment: "",
      created: "",
      group: "",
      id: "",
      modified: "",
      name: "Untitled",
      rootNode,
    }

    viewProps.history.push({
      pathname: getEngineNewFilterUrl(),
      state: { filter },
    })
  }

  const onSidebarDescription = (rowData: ReconstructionsDesc) => {
    setShowSidebar(rowData.id.toString())
  }

  const onReconstructionsSidebarDescClose = () => {
    setShowSidebar(null)
  }

  const onFlowVisualizer = (flowID: number) => {
    if (flowID) {
      viewProps.history.push(`flow-visualizer/${flowID}`)
    }
  }

  const formatNameTableAddressCellEntry = (
    name: string | null,
    address: string,
    color: string | null
  ) => {
    const updatedAddr = (showAddressNames && name) || address

    return formatNameTableCellEntry(updatedAddr, color)
  }

  const formatNameTablePortCellEntry = (
    name: string | null,
    port: string,
    color: string | null
  ) => {
    const updatedPort = (showPortNames && name) || port

    return formatNameTableCellEntry(updatedPort, color)
  }

  const formatNameTableCellEntry = (name: string, color: string | null) => {
    if (theme.name === "Light" && color != null) {
      return (
        <span style={{ color: color }} title={name}>
          {name}
        </span>
      )
    }

    return name
  }

  const cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content
    switch (dataKey) {
      case "clientAddress":
        content = formatNameTableAddressCellEntry(
          rowData.clientAddressName,
          rowData.clientAddress,
          rowData.clientAddressColor
        )
        break
      case "serverAddress":
        content = formatNameTableAddressCellEntry(
          rowData.serverAddressName,
          rowData.serverAddress,
          rowData.serverAddressColor
        )
        break
      case "clientPort":
        content = formatNameTablePortCellEntry(
          rowData.clientPortName,
          rowData.clientPort,
          rowData.clientPortColor
        )
        break
      case "serverPort":
        content = formatNameTablePortCellEntry(
          rowData.serverPortName,
          rowData.serverPort,
          rowData.serverPortColor
        )
        break
      case "fileName":
        content = cellData ? cellData : "(default)"
        break
      case "payloadSize": {
        const sizeKiloBytes = cellData / 1024
        content = sizeKiloBytes < 1 ? "1 KB" : Math.floor(sizeKiloBytes).toString() + " KB"
        break
      }
      case "id":
      case "flowID":
      case "requestID":
        content = formatInteger(cellData)
        break
      case "protocol":
        content = protocolOptions[cellData].label
        break
      case "firstTimestamp":
      case "lastTimestamp":
        content = formatISODateTime(cellData, 0, showLocalTime)
        break
      case "duration":
        content = formatDuration(cellData, 6)
        break
      case "clientLatitude":
      case "clientLongitude":
      case "serverLatitude":
      case "serverLongitude":
        if (cellData != null) {
          content = formatFloat(cellData, 4)
        }
        break
      case "clientCountry":
      case "serverCountry":
        if (cellData) {
          content = (
            <CountryName
              name={cellData}
              code={
                dataKey.includes("client") ? rowData.clientCountryCode : rowData.serverCountryCode
              }
            />
          )
        }
        break
      case "contentType":
        // Don't display if not HTTP/SMTP. Sending manual inputted content types for other protocols
        content =
          protocolOptions[rowData.protocol].label === "HTTP" ||
          protocolOptions[rowData.protocol].label === "SMTP"
            ? cellData
            : null
        break
      default:
        content = cellData
        break
    }
    return content
  }

  const onDownload = (reconstruction: ReconstructionsDesc) => {
    setDownloadReconstruction(reconstruction)
    setShowReconstructionsDownloadModal(true)
  }

  const onReconstructionsDownloadOK = (fileName: string | undefined) => {
    const { type, capId } = viewProps.match.params

    if (downloadReconstruction) {
      fetchReconstructionsRaw(engine, authToken, type, capId, downloadReconstruction.id.toString())
        .then(response => {
          FileSaver.saveAs(response, fileName)
        })
        .catch(error => {
          console.error(error)
        })
    }

    setShowReconstructionsDownloadModal(false)
  }

  const onReconstructionsDownloadCancel = () => {
    setShowReconstructionsDownloadModal(false)
  }

  const commandCellRenderer = ({ rowData }: TableCellProps) => {
    const packetsDisabled = captureProperties === null || !captureProperties.packetBufferEnabled
    const expertDisabled = captureProperties === null || !captureProperties.expertEnabled

    // make sure the user can view packets for this capture or forensic search
    let canCreateForensicSearch = true
    let canUploadFiles = true
    let canViewPackets = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canCreateForensicSearch =
        !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL) ||
        policies.includes(EngineUserPolicies.createForensicSearch)
      canUploadFiles = policies.includes(EngineUserPolicies.uploadFiles)
      canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
    }

    return (
      <>
        {!rowData.isGroup && (
          <CommandStrip className="commands">
            <UncontrolledDropdownWithPortal
              dropdownToggle={
                <IconDropdownToggle>
                  <FontAwesome name="ellipsis-h" fixedWidth />
                </IconDropdownToggle>
              }
            >
              <DropdownMenu end>
                <DropdownItem
                  disabled={packetsDisabled || expertDisabled}
                  onClick={onFlowVisualizer.bind(this, rowData.flowID)}
                >
                  Flow Visualizer
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={onDownload.bind(this, rowData)}>
                  Save Payload &ldquo;{rowData.fileName}&rdquo;
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  disabled={packetsDisabled || !canViewPackets}
                  onClick={onSelectRelated.bind(this, "client", rowData)}
                >
                  Select Related Packets by Client
                </DropdownItem>
                <DropdownItem
                  disabled={packetsDisabled || !canViewPackets}
                  onClick={onSelectRelated.bind(this, "server", rowData)}
                >
                  Select Related Packets by Server
                </DropdownItem>
                <DropdownItem
                  disabled={packetsDisabled || !canViewPackets}
                  onClick={onSelectRelated.bind(this, "clientAndServer", rowData)}
                >
                  Select Related Packets by Client and Server
                </DropdownItem>
                <DropdownItem
                  disabled={packetsDisabled || !canViewPackets}
                  onClick={onSelectRelated.bind(this, "flowId", rowData)}
                >
                  Select Related Packets by Flow ID
                </DropdownItem>
                {/*
                <DropdownItem disabled={packetsDisabled || !canViewPackets}>Select Related Flow</DropdownItem>
                <DropdownItem disabled={packetsDisabled || !canViewPackets}>Select Related Request</DropdownItem>
                */}
                <DropdownItem divider />
                <DropdownItem
                  disabled={!canUploadFiles || !canCreateForensicSearch}
                  onClick={onMSA.bind(this, rowData)}
                >
                  Multi-Segment Analysis
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={onMakeFilter.bind(this, rowData)}>Make Filter</DropdownItem>
                <DropdownItem onClick={onInsertIntoNameTable.bind(this, rowData)}>
                  Insert Into Name Table
                </DropdownItem>
                <DropdownItem onClick={onResolveNames.bind(this, rowData)}>
                  Resolve Names
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdownWithPortal>
          </CommandStrip>
        )}
      </>
    )
  }

  const onSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
  }) => {
    sort(reconstructionsList ? reconstructionsList : [], sortBy, sortDirection, showAddressNames)
    dispatch(setReconstructionsSort(sortBy, sortDirection))
  }

  const sort = (
    items: ReconstructionsDesc[],
    sortBy: string,
    sortDirection: SortDirectionType,
    showAddressName: boolean
  ) => {
    const collator = new Intl.Collator(undefined, { sensitivity: "base", numeric: true })
    items.sort((a: ReconstructionsDesc, b: ReconstructionsDesc) => {
      let result = 0

      let valueA = null
      let valueB = null

      switch (sortBy) {
        case "clientAddress":
          valueA = (showAddressName && a.clientAddressName) || a.clientAddress
          valueB = (showAddressName && b.clientAddressName) || b.clientAddress
          result = collator.compare(valueA, valueB)
          break
        case "fileName":
        case "referer":
        case "clientCity":
        case "serverCity":
        case "clientCountry":
        case "serverCountry":
        case "firstTimestamp":
        case "lastTimestamp":
          if (a[sortBy] && b[sortBy]) {
            valueA = a[sortBy] || a.id.toString()
            valueB = b[sortBy] || b.id.toString()
            result = collator.compare(valueA, valueB)
          } else if (a[sortBy]) {
            result = 1
          } else if (b[sortBy]) {
            result = -1
          }
          break
        case "id":
          valueA = a.id
          valueB = b.id
          break
        case "payloadSize":
          valueA = typeof a.payloadSize === "string" ? parseInt(a.payloadSize) : a.payloadSize
          valueB = typeof b.payloadSize === "string" ? parseInt(b.payloadSize) : b.payloadSize
          break
        case "clientLatitude":
        case "clientLongitude":
        case "serverLatitude":
        case "serverLongitude":
          if (a[sortBy] && b[sortBy]) {
            valueA = a[sortBy] || a.id.toString()
            valueB = b[sortBy] || b.id.toString()
          } else if (a[sortBy]) {
            result = 1
          } else if (b[sortBy]) {
            result = -1
          }
          break
        default:
          valueA = a[sortBy as keyof ReconstructionsDesc]
          valueB = b[sortBy as keyof ReconstructionsDesc]
          break
      }

      if (result === 0) {
        if (valueA != null && valueB != null) {
          if (valueA > valueB) {
            result = 1
          } else if (valueA < valueB) {
            result = -1
          }
        }
        if (result === 0) {
          // Fall back to fileName.
          valueA = a.fileName || a.id.toString()
          valueB = b.fileName || b.id.toString()
          result = collator.compare(valueA, valueB)
        }
      }

      if (sortDirection === SortDirection.DESC) result = -result

      return result
    })
  }

  const onRowClick = ({ rowData }: RowMouseEventHandlerParams) => {
    if (rowData.isGroup) return
    onSidebarDescription(rowData)
  }

  const renderTable = (reconstructions: any) => {
    return (
      reconstructions && (
        <OmniTable
          data={reconstructions}
          rowCount={reconstructions.length}
          columnDesc={columns}
          cellRenderer={cellRenderer}
          onRowClick={onRowClick}
          renderCommands={commandCellRenderer}
          onShowDefaultColumns={onShowDefaultColumns}
          onShowAllColumns={onShowAllColumns}
          onToggleColumn={onToggleColumn}
          sort={onSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      )
    )
  }

  const onInsertIntoNameTable = (rowData: ReconstructionsDesc) => {
    const insertNameEntries: InsertNameEntry[] = []

    if (rowData.clientAddress) {
      insertNameEntries.push({
        title: "Client",
        entry: rowData.clientAddress,
        entryType: (rowData.clientAddress as any).type,
      })
    }

    if (rowData.serverAddress) {
      insertNameEntries.push({
        title: "Server",
        entry: rowData.serverAddress,
        entryType: (rowData.serverAddress as any).type,
      })
    }

    if (insertNameEntries.length > 0) {
      setInsertNameEntries(insertNameEntries)
    }
  }

  const onInsertIntoNameTableOK = () => {
    setInsertNameEntries(null)
    onRefresh()
  }

  const onInsertIntoNameTableCancel = () => {
    setInsertNameEntries(null)
  }

  const onResolveNames = (rowData: ReconstructionsDesc) => {
    const entries: AddressResolverRequestEntry[] = []
    try {
      if (rowData.clientAddressMediaSpec != null) {
        entries.push({
          entry: formatMediaSpec(rowData.clientAddressMediaSpec),
          entryType: rowData.clientAddressMediaSpec.type,
        })
      }
      if (rowData.serverAddressMediaSpec != null) {
        entries.push({
          entry: formatMediaSpec(rowData.serverAddressMediaSpec),
          entryType: rowData.serverAddressMediaSpec.type,
        })
      }
    } catch (e) {
      console.error(e)
    }
    if (entries.length > 0) {
      resolveAddresses(engine, authToken, entries).catch(error => {
        console.error(error)
      })
      dispatch(updateStatus())
    }
  }

  const onExport = () => {
    const visibleColumns = columns.filter(col => col.visible)
    let csv = visibleColumns.map(col => csvStringify(col.label)).join(",") + "\n"

    if (reconstructionsList) {
      reconstructionsList.forEach((rowData: ReconstructionsDesc) => {
        const row: string[] = []
        visibleColumns.forEach(col => {
          let content
          switch (col.dataKey) {
            case "clientAddress":
              content = (showAddressNames && rowData.clientAddressName) || rowData.clientAddress
              break
            case "serverAddress":
              content = (showAddressNames && rowData.serverAddressName) || rowData.serverAddress
              break
            case "clientPort":
              content = (showPortNames && rowData.clientPortName) || rowData.clientPort
              break
            case "serverPort":
              content = (showPortNames && rowData.serverPortName) || rowData.serverPort
              break
            case "fileName":
              content = rowData.fileName ? rowData.fileName : "(default)"
              break
            case "contentType":
              content =
                protocolOptions[rowData.protocol].label === "HTTP" ||
                protocolOptions[rowData.protocol].label === "SMTP"
                  ? rowData.contentType
                  : null
              break
            case "uri":
              content = rowData.uri
              break
            case "firstPacket":
              content = rowData.firstPacket
              break
            case "lastPacket":
              content = rowData.lastPacket
              break
            case "host":
              content = rowData.host
              break
            case "payloadSize":
              content = rowData.payloadSize
              break
            case "requestHeaders":
              content = rowData.requestHeaders
              break
            case "requestMethod":
              content = rowData.requestMethod
              break
            case "referer":
              content = rowData.referer
              break
            case "clientCity":
              if (rowData.clientCity != null) {
                content = rowData.clientCity
              }
              break
            case "serverCity":
              if (rowData.serverCity != null) {
                content = rowData.serverCity
              }
              break
            case "id":
              content = rowData.id
              break
            case "flowID":
              content = rowData.flowID
              break
            case "protocol":
              content = rowData.protocol
              break
            case "requestID":
              content = rowData.requestID
              break
            case "firstTimestamp":
              content = formatISODateTime(rowData.firstTimestamp, 0, showLocalTime)
              break
            case "lastTimestamp":
              content = formatISODateTime(rowData.lastTimestamp, 0, showLocalTime)
              break
            case "duration":
              content = formatDuration(rowData.duration, 6)
              break
            case "clientLatitude":
              if (rowData.clientLatitude != null) {
                content = formatFloat(rowData.clientLatitude, 4)
              }
              break
            case "clientLongitude":
              if (rowData.clientLongitude != null) {
                content = formatFloat(rowData.clientLongitude, 4)
              }
              break
            case "serverLatitude":
              if (rowData.serverLatitude != null) {
                content = formatFloat(rowData.serverLatitude, 4)
              }
              break
            case "serverLongitude":
              if (rowData.serverLongitude != null) {
                content = formatFloat(rowData.serverLongitude, 4)
              }
              break
            case "clientCountry":
              if (rowData.clientCountry != null) {
                content = rowData.clientCountry
              }
              break
            case "serverCountry":
              if (rowData.serverCountry != null) {
                content = rowData.serverCountry
              }
              break
            default:
              break
          }
          row.push(csvStringify(content))
        })
        csv += row.join(",") + "\n"
      })
    }

    FileSaver.saveAs(new Blob([csv], { type: "text/plain;charset=utf8" }), "Reconstructions.csv")
  }

  const { type, capId } = viewProps.match.params

  // make sure the user can view stats for this capture or forensic search
  if (captureProperties && engineCapabilities) {
    const isUserOwner = userId === captureProperties.creatorSID
    const policies = engineCapabilities.userRights.policies
    const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
    if (!canViewStats) {
      return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
    }
  }

  const count = reconstructionsList ? reconstructionsList.length : 0
  return (
    <View>
      <BreadcrumbItem to={viewProps.match.url} title="Reconstructions" />
      <Interval timeout={30000} enabled={refreshEnabled} callback={onRefresh} />
      <ViewHeader>
        <ViewHeaderTitle title="Reconstructions" count={count} />
        <ViewHeaderButtons>
          <ReconstructionsSearchBox
            searchOption={searchOption}
            search={search}
            onChangeSearch={onChangeSearch}
            contentTypeOption={contentTypeOption}
            onChangeContentTypeDropdown={onChangeContentTypeDropdown}
            onChangeContentTypeSearch={onChangeContentTypeSearch}
            contentTypeSearchInput={contentTypeSearch}
            protocolOption={protocolOption}
            onChangeProtocolDropdown={onChangeProtocolDropdown}
          />
          <LightButton aria-label="Export" id="export" onClick={onExport}>
            <FontAwesome name="download" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="export">
            Export
          </UncontrolledTooltip>
          <LightButton aria-label="Refresh" id="refresh" onClick={onRefresh}>
            <FontAwesome name="refresh" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="refresh">
            Refresh
          </UncontrolledTooltip>
        </ViewHeaderButtons>
      </ViewHeader>
      <ViewContent>{!loading && renderTable(reconstructionsList)}</ViewContent>
      {loading && (
        <CenterContent>
          <Spinner />
        </CenterContent>
      )}
      {showReconstructionsDownloadModal && (
        <ReconstructionsDownloadModal
          fileName={downloadReconstruction?.fileName}
          onOK={onReconstructionsDownloadOK}
          onCancel={onReconstructionsDownloadCancel}
        />
      )}
      <ReconstructionsSidebar
        isOpen={showSidebar !== null}
        entry={showSidebar}
        reconstructions={reconstructionsList}
        engine={engine}
        authToken={authToken}
        type={type}
        capId={capId}
        onClose={onReconstructionsSidebarDescClose}
        theme={theme}
      />
      {insertNameEntries != null && (
        <InsertNamesModal
          engine={engine}
          authToken={authToken}
          entries={insertNameEntries}
          onOK={onInsertIntoNameTableOK}
          onCancel={onInsertIntoNameTableCancel}
        />
      )}
    </View>
  )
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  namesModTime: getNamesModificationTime(state),
  showAddressNames: getShowAddressNames(state),
  showPortNames: getShowPortNames(state),
  showLocalTime: getShowLocalTime(state),
  filter: getReconstructionsFilter(state) || "",
  columns: getReconstructionsColumns(state) || defaultColumns,
  sortBy: getReconstructionsSortBy(state) || "id",
  sortDirection: getReconstructionsSortDirection(state) || SortDirection.ASC,
  engineCapabilities: getCapabilities(state) || null,
  userId: getUserId(state),
})

export default connect(mapStateToProps)(withTheme(ReconstructionsView))
