import * as React from "react"

export const IconAccessPoint = (props: React.SVGAttributes<SVGElement>) => (
  <svg viewBox="0 0 12 12" shapeRendering="geometricPrecision" height="12" width="12" {...props}>
    <path
      fill="currentColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.375 1.375C10.375 1.16789 10.2071 1 10 1C9.79289 1 9.625 1.16789 9.625 1.375V5C9.625 5.20711 9.79289 5.375 10 5.375C10.2071 5.375 10.375 5.20711 10.375 5V1.375ZM2 1.00001C2.20711 1.00001 2.375 1.1679 2.375 1.37501V5C2.375 5.20711 2.20711 5.375 2 5.375C1.79289 5.375 1.625 5.20711 1.625 5V1.37501C1.625 1.1679 1.79289 1.00001 2 1.00001ZM1 6.86111H11C11.1381 6.86111 11.25 6.97304 11.25 7.11111V10C11.25 10.1381 11.1381 10.25 11 10.25H1C0.861929 10.25 0.75 10.1381 0.75 10V7.11111C0.75 6.97304 0.861929 6.86111 1 6.86111ZM0 7.11111C0 6.55883 0.447715 6.11111 1 6.11111H11C11.5523 6.11111 12 6.55883 12 7.11111V10C12 10.5523 11.5523 11 11 11H1C0.447715 11 0 10.5523 0 10V7.11111ZM6.5 8C6.22386 8 6 8.22386 6 8.5C6 8.77614 6.22386 9 6.5 9H9.5C9.77614 9 10 8.77614 10 8.5C10 8.22386 9.77614 8 9.5 8H6.5ZM4 8.5C4 8.22386 4.22386 8 4.5 8C4.77614 8 5 8.22386 5 8.5C5 8.77614 4.77614 9 4.5 9C4.22386 9 4 8.77614 4 8.5ZM2.5 8C2.22386 8 2 8.22386 2 8.5C2 8.77614 2.22386 9 2.5 9C2.77614 9 3 8.77614 3 8.5C3 8.22386 2.77614 8 2.5 8Z"
    />
  </svg>
)
