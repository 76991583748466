import { toNumber } from "lodash"
import { SortDirectionType, SortDirection } from "react-virtualized"
import { DatabaseRowGetFileList } from "../../api/types"
import { formatTimeZone } from "../../utils/formatUtils"
import { getMediaString } from "../../utils/mediaUtils"

export const compareFiles = (
    a: DatabaseRowGetFileList,
    b: DatabaseRowGetFileList,
    sortBy: string,
    sortDirection: SortDirectionType,
    collator: Intl.Collator
  ) => {
    let result = 0
    const sortOrder = [sortBy, "FileName", "SessionStartTime"]
    for (let i = 0; i < sortOrder.length && result === 0; i++) {
      const sortField = sortOrder[i]
      switch (sortOrder[i]) {
        case "FileName":
        case "PartialPath":
        case "CaptureName":
        case "AdapterName":
        case "AdapterAddr":
        case "Owner":
          if (sortField in a && sortField in b) {
            result = collator.compare(a[sortField], b[sortField])
          } else if (sortField in a) {
            result = 1
          } else if (sortField in b) {
            result = -1
          }
          break
        case "SessionStartTime":
        case "SessionEndTime":
          if (sortField in a && sortField in b) {
            const aValue = new Date(a[sortField])
            const bValue = new Date(b[sortField])
            if (aValue > bValue) {
              result = 1
            } else if (aValue < bValue) {
              result = -1
            }
          } else if (sortField in a) {
            result = 1
          } else if (sortField in b) {
            result = -1
          }
          break
        case "FileSize":
        case "PacketCount":
        case "LinkSpeed":
        case "FileIndex":
          if (sortField in a && sortField in b) {
            const aValue = toNumber(a[sortField])
            const bValue = toNumber(b[sortField])
            if (aValue > bValue) {
              result = 1
            } else if (aValue < bValue) {
              result = -1
            }
          } else if (sortField in a) {
            result = 1
          } else if (sortField in b) {
            result = -1
          }
          break
        case "duration":
          if (
            a.SessionStartTime !== undefined &&
            a.SessionEndTime !== undefined &&
            b.SessionStartTime !== undefined &&
            b.SessionEndTime !== undefined
          ) {
            const aValue = Date.parse(a.SessionEndTime) - Date.parse(a.SessionStartTime)
            const bValue = Date.parse(b.SessionEndTime) - Date.parse(b.SessionStartTime)
            if (aValue > bValue) {
              result = 1
            } else if (aValue < bValue) {
              result = -1
            }
          } else if ("SessionStartTime" in a) {
            result = 1
          } else if ("SessionStartTime" in b) {
            result = -1
          }
          break
        case "MediaType":
          if ("MediaType" in a && "MediaSubType" in a && "MediaType" in b && "MediaSubType" in b) {
            const aValue = getMediaString(toNumber(a.MediaType), toNumber(a.MediaSubType))
            const bValue = getMediaString(toNumber(b.MediaType), toNumber(b.MediaSubType))
            result = collator.compare(aValue, bValue)
          } else if ("MediaType" in a) {
            result = 1
          } else if ("MediaType" in b) {
            result = -1
          }
          break
        case "TimeZoneBias":
          if (a.TimeZoneBias !== undefined && b.TimeZoneBias !== undefined) {
            const aValue = formatTimeZone(a.TimeZoneBias)
            const bValue = formatTimeZone(b.TimeZoneBias)
            result = collator.compare(aValue, bValue)
          } else if ("TimeZoneBias" in a) {
            result = 1
          } else if ("TimeZoneBias" in b) {
            result = -1
          }
          break
        default:
          break
      }
    }
  
    if (sortDirection === SortDirection.DESC) result = -result
    return result
  }