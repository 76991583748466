import styled from "styled-components"
import { formatInteger } from "../../../../utils/formatUtils"
import { WidgetProps } from "../../../../api/types/dashboardTypes"
import { Widget, WidgetBody } from "../../DashboardWidget"
import DashboardWidgetHeader from "../../DashboardWidgetHeader"

const Gauges = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

const GaugeDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const GaugeTitle = styled.h6`
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.textMutedColor};
`

const GaugeValue = styled.div`
  font-size: 2rem;
  text-align: center;
`

const Gauge = ({ title, value }: { title: string; value: string }) => (
  <GaugeDiv>
    <GaugeTitle>{title}</GaugeTitle>
    <GaugeValue>{value}</GaugeValue>
  </GaugeDiv>
)

const CaptureCountersWidget = ({ widget, data, commands }: WidgetProps) => {
  const title = widget.title || "Capture Counters"

  return (
    <Widget>
      <DashboardWidgetHeader title={title} widget={widget} commands={commands} />
      <WidgetBody>
        {data && (
          <Gauges>
            <Gauge title="Packets Received" value={formatInteger(data.packetsReceived)} />
            <Gauge title="Packets Filtered" value={formatInteger(data.packetsFiltered)} />
            <Gauge title="Packets Dropped" value={formatInteger(data.packetsDropped)} />
          </Gauges>
        )}
      </WidgetBody>
    </Widget>
  )
}

export default CaptureCountersWidget
