import * as React from "react"
import { toNumber } from "lodash"
import { FormGroup } from "reactstrap"
import { Input } from "../common/Input"
import { CheckGroup, HorizontalFormGroup, InlineLabel, Label } from "../common/Form"
import { Select } from "../common/Select"
import { VoipConfig } from "../../api/types"

type VoIPConfigFormProps = {
  voipConfig: VoipConfig | undefined
  setVoIPConfig: (voipConfig: VoipConfig) => void
}

export class VoIPConfigForm extends React.PureComponent<VoIPConfigFormProps> {
  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { voipConfig } = this.props
    if (voipConfig) {
      switch (event.target.name) {
        case "maxCalls":
        case "severity":
          this.props.setVoIPConfig({
            ...voipConfig,
            [event.target.name]: toNumber(event.target.value),
          })
          break
        case "notify":
        case "stopAnalysis":
          this.props.setVoIPConfig({
            ...voipConfig,
            [event.target.name]: event.target.checked,
          })
          break
        default:
          break
      }
    }
  }

  render() {
    const { voipConfig } = this.props
    if (!voipConfig) return null

    return (
      <>
        <FormGroup>
          <Label for="maxCalls">Maximum simultaneous calls</Label>
          <Input
            style={{ width: "8rem" }}
            type="number"
            name="maxCalls"
            id="maxCalls"
            onChange={this.onChange}
            value={voipConfig.maxCalls}
            min={1}
            max={1000000000}
            step={1}
          />
        </FormGroup>
        <HorizontalFormGroup>
          <CheckGroup
            type="checkbox"
            name="notify"
            id="voipConfigNotify"
            onChange={this.onChange}
            checked={voipConfig.notify}
          >
            Notify
          </CheckGroup>
          &nbsp;&nbsp;
          <InlineLabel for="stopTriggerSeverity">Severity</InlineLabel>
          <Select
            name="severity"
            id="voipConfigSeverity"
            onChange={this.onChange}
            value={voipConfig.severity}
            disabled={!voipConfig.notify}
          >
            <option value="0">Informational</option>
            <option value="1">Minor</option>
            <option value="2">Major</option>
            <option value="3">Severe</option>
          </Select>
        </HorizontalFormGroup>
        <CheckGroup
          type="checkbox"
          name="stopAnalysis"
          id="voipConfigStopAnalysis"
          onChange={this.onChange}
          checked={voipConfig.stopAnalysis}
        >
          Stop analysis
        </CheckGroup>
      </>
    )
  }
}
