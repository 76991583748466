import * as React from "react"
import { cloneDeep } from "lodash"
import styled, { useTheme } from "styled-components"
import { XAxis, YAxis, CartesianGrid, Legend, Tooltip } from "recharts"
import useMeasure from "react-use/lib/useMeasure"
import { Panel } from "../common/Panel"
import { NoData } from "../Dashboard/Dashboard"
import ChartTooltip from "../common/ChartTooltip"
import { ChartType } from "../../api/types/chartTypes"
import { ChartComponents } from "../../utils/chartComponents"
import { getChartColor } from "../../utils/chartUtils"
import { formatDuration, formatInteger, formatJitter, formatMOS } from "../../utils/formatUtils"
import ThemeInterface from "../../themes/theme"

// See: https://engwiki.savvius.com/index.php/Add_RTP_Graph_Tab_in_VoIP_Visual_Expert

const ChartPanel = styled(Panel)`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & + & {
    margin-top: 8px;
  }
`

const ChartTitle = styled.h5`
  flex: 0 0 auto;
  margin: 0;
  text-align: center;
`

const ChartWrapper = styled.div`
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
`

const ChartContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

const Circle = (props: any) => {
  return (
    <circle cx={props.cx} cy={props.cy} r={2} fill={props.fill} fillOpacity={props.fillOpacity} />
  )
}

function formatFloatTooltip(
  value: string | number | (string | number)[],
  name: string,
  entry: any
) {
  if (entry.dataKey === "x") {
    return (
      <div>
        <b>Time: </b> {formatDuration(entry.payload.x, 3)}
      </div>
    )
  } else if (entry.dataKey === "y") {
    return (
      <div>
        <b>Value: </b> {formatJitter(entry.payload.y)}
      </div>
    )
  }
  return null
}

function formatIntegerTooltip(
  value: string | number | (string | number)[],
  name: string,
  entry: any
) {
  if (entry.dataKey === "x") {
    return (
      <div>
        <b>Time: </b> {formatDuration(entry.payload.x, 3)}
      </div>
    )
  } else if (entry.dataKey === "y") {
    return (
      <div>
        <b>Value: </b> {formatInteger(entry.payload.y)}
      </div>
    )
  }
  return null
}

function formatMOSScoreTooltip(
  value: string | number | (string | number)[],
  name: string,
  entry: any
) {
  if (entry.dataKey === "x") {
    return (
      <div>
        <b>Time: </b> {formatDuration(entry.payload.x, 3)}
      </div>
    )
  } else if (entry.dataKey === "y") {
    return (
      <div>
        <b>Value: </b> {formatMOS(entry.payload.y)}
      </div>
    )
  }
  return null
}

export type RTPChartData = {
  flow: string
  data: any[]
}

export type RTPChartProps = {
  chartData: RTPChartData[]
  mosScore?: boolean
  jitterMicro?: boolean
  title: string
}

export const RTPChart: React.FC<RTPChartProps> = ({ chartData, mosScore, jitterMicro, title }) => {
  const theme = useTheme() as ThemeInterface
  const [hoverKey, setHoverKey] = React.useState(null)
  const [refChart, { width, height }] = useMeasure<HTMLDivElement>()

  const onMouseEnterLegend = (e: any) => {
    setHoverKey(e.dataKey)
  }

  const onMouseLeaveLegend = (e: any) => {
    if (e.dataKey === hoverKey) {
      setHoverKey(null)
    }
  }

  const chartComponent = ChartComponents[ChartType.POINTS]
  const Chart = chartComponent.chart
  const Series = chartComponent.series
  const seriesProps = cloneDeep(chartComponent.seriesProps)
  if (seriesProps.dot) {
    seriesProps.dot.stroke = theme.panelBackground
  }
  if (seriesProps.activeDot) {
    seriesProps.activeDot.stroke = theme.panelBackground
  }

  const series: (typeof Series)[] = []
  for (let i = 0, l = chartData.length; i < l; i++) {
    const flowData = chartData[i]
    const color = getChartColor(i)
    const defaultOpacity = !hoverKey || hoverKey === flowData.flow
    const opacity = defaultOpacity ? undefined : 0.1
    series.push(
      <Series
        key={flowData.flow}
        name={flowData.flow}
        dataKey={flowData.flow}
        data={flowData.data}
        fill={color}
        fillOpacity={opacity}
        {...seriesProps}
        legendType="circle"
        shape={<Circle />}
      />
    )
  }

  return (
    <ChartPanel>
      {chartData.length === 0 ? (
        <NoData>No Data</NoData>
      ) : (
        <>
          <ChartTitle>{title}</ChartTitle>
          <ChartWrapper ref={refChart}>
            <ChartContainer
              style={{
                width,
                height,
              }}
            >
              <Chart
                width={width}
                height={height}
                margin={{ top: 10, right: 32, left: 0, bottom: 0 }}
                {...chartComponent.chartProps}
              >
                <Tooltip
                  isAnimationActive={false}
                  formatter={
                    mosScore === undefined
                      ? jitterMicro === true
                        ? formatFloatTooltip
                        : formatIntegerTooltip
                      : formatMOSScoreTooltip
                  }
                  content={<ChartTooltip />}
                />
                <CartesianGrid stroke={theme.chartGridColor} vertical={false} />
                <YAxis
                  type="number"
                  dataKey="y"
                  domain={mosScore === undefined ? [0, "auto"] : [0, 5]}
                  ticks={
                    mosScore === undefined ? undefined : [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5]
                  }
                  tickFormatter={
                    mosScore === undefined
                      ? (y: number) => formatJitter(y, 1)
                      : (y: number) => formatMOS(y, 1)
                  }
                  stroke={theme.textColor}
                ></YAxis>
                <XAxis
                  type="number"
                  domain={[0, "dataMax"]}
                  tickCount={10}
                  dataKey="x"
                  stroke={theme.textColor}
                  tickFormatter={(x: number) => formatDuration(x, 3)}
                  height={22}
                />
                {series}
                <Legend
                  iconSize={8}
                  onMouseEnter={onMouseEnterLegend}
                  onMouseLeave={onMouseLeaveLegend}
                />
              </Chart>
            </ChartContainer>
          </ChartWrapper>
        </>
      )}
    </ChartPanel>
  )
}
