import { Widget, TopStatsViewType } from "../../../api/types/dashboardTypes"
import { Engine, TimelineStatisticsType } from "../../../api/types"
import {
  fetchCapture,
  fetchEvents,
  fetchTimelineData,
  fetchTimelineStatistics,
} from "../../../api/api"
import { getChartColor } from "../../../utils/chartUtils"

export type WidgetDataFunction = (
  widget: Widget,
  server: string,
  authToken: string,
  engine: Engine,
  setData: (data: any) => void
) => void

export function getGlobalEventCounters(
  widget: Widget,
  server: string,
  authToken: string,
  engine: Engine,
  setData: (data: any) => void
) {
  fetchEvents(`${server}/engines/${engine.id}`, authToken)
    .then(events => {
      setData(events)
    })
    .catch(error => {
      console.error(error)
    })
}

export function getCaptureCounters(
  widget: Widget,
  server: string,
  authToken: string,
  engine: Engine,
  setData: (data: any) => void
) {
  if (widget.settings && widget.settings.captureId) {
    fetchCapture(`${server}/engines/${engine.id}`, authToken, widget.settings.captureId)
      .then(capture => {
        setData(capture)
      })
      .catch(error => {
        console.log(error)
      })
  }
}

const DataURL: any = {
  0: "utilization-mbps",
  1: "packets",
  2: "multicast",
  3: "packet-sizes",
  4: "vlan-mpls",
  5: "protocols-mbps",
  6: "protocols-pps",
  7: "call-quality",
  8: "call-utilization",
  9: "wireless-packets",
  10: "wireless-retries",
  11: "applications-mbps",
  12: "applications-pps",
}

export function getForensicChartData(
  widget: Widget,
  server: string,
  authToken: string,
  engine: Engine,
  setData: (data: any) => void
) {
  if (widget.settings && widget.settings.sessionId) {
    const viewType = widget.settings.viewType != null ? widget.settings.viewType : 0
    fetchTimelineData(
      `${server}/engines/${engine.id}`,
      authToken,
      widget.settings.sessionId,
      DataURL[viewType],
      0
    )
      .then(data => {
        setData(data)
      })
      .catch(error => {
        console.log(error)
      })
  }
}

const TopStatsURL = {
  [TopStatsViewType.PHYSICAL]: "top-physical-nodes",
  [TopStatsViewType.IP]: "top-ipv4-nodes",
  [TopStatsViewType.IPV6]: "top-ipv6-nodes",
  [TopStatsViewType.PROTOCOLS]: "top-protocols",
  [TopStatsViewType.APPLICATIONS]: "top-applications",
}

export function getForensicTopStatsData(
  widget: Widget,
  server: string,
  authToken: string,
  engine: Engine,
  setData: (data: any) => void
) {
  if (engine && widget.settings && widget.settings.sessionId) {
    const viewType =
      widget.settings.viewType != null ? widget.settings.viewType : TopStatsViewType.IP
    fetchTimelineStatistics(
      `${server}/engines/${engine.id}`,
      authToken,
      widget.settings.sessionId,
      TopStatsURL[viewType] as TimelineStatisticsType,
      0,
      0
    )
      .then(data => {
        if (data != null && Array.isArray(data.entries)) {
          for (let i = 0; i < data.entries.length; i++) {
            const entry = data.entries[i]
            if (entry.color === "#000000") {
              entry.color = getChartColor(i)
            }
          }
          setData(data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }
}
