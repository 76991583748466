import styled from "styled-components"
import RcSlider from "rc-slider"

export const Slider = styled(RcSlider)`
  &.rc-slider-disabled {
    background-color: ${props => props.theme.inputDisabledBackgroundColor};
  }

  & .rc-slider-rail {
    background-color: ${props => props.theme.inputBorderColor};
  }

  & .rc-slider-track {
    background-color: ${props => props.theme.primaryButtonBackgroundColor};
  }

  & .rc-slider-dot {
    border-color: ${props => props.theme.inputBorderColor};
    background-color: ${props => props.theme.backgroundColor};
  }

  & .rc-slider-dot-active {
    border-color: ${props => props.theme.primaryButtonBackgroundColor};
    background-color: ${props => props.theme.backgroundColor};
  }

  & .rc-slider-handle {
    border-color: ${props => props.theme.primaryButtonBackgroundColor};
    background-color: ${props => props.theme.backgroundColor};
  }
`
