import styled from "styled-components"
import { HalfWidthWidget, WidgetHeader, WidgetTitle, WidgetBody } from "../Dashboard"
import { IconInformational, IconMinor, IconMajor, IconSevere } from "../../common/Icons"
import { formatInteger } from "../../../utils/formatUtils"
import { EventCounts } from "../../../api/types"

const Events = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @supports (-ms-ime-align: auto) {
    /* Edge only */
    justify-content: space-around;
  }
`

const EventCounter = styled.div``

const EventsWidget = ({ eventCounts }: { eventCounts: EventCounts | null }) => (
  <HalfWidthWidget>
    <WidgetHeader>
      <WidgetTitle>Events</WidgetTitle>
    </WidgetHeader>
    <WidgetBody minHeight="6rem">
      {eventCounts && (
        <Events>
          <EventCounter>
            <IconInformational height={24} width={24} />
            &nbsp;
            {formatInteger(eventCounts.informational)}
          </EventCounter>
          <EventCounter>
            <IconMinor height={24} width={24} />
            &nbsp;
            {formatInteger(eventCounts.minor)}
          </EventCounter>
          <EventCounter>
            <IconMajor height={24} width={24} />
            &nbsp;
            {formatInteger(eventCounts.major)}
          </EventCounter>
          <EventCounter>
            <IconSevere height={24} width={24} />
            &nbsp;
            {formatInteger(eventCounts.severe)}
          </EventCounter>
        </Events>
      )}
    </WidgetBody>
  </HalfWidthWidget>
)

export default EventsWidget
