import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { cloneDeep, remove, reverse, drop, flatten } from "lodash"
import { v4 as uuid } from "uuid"
import styled, { DefaultTheme, withTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import FileSaver from "file-saver"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import { defaultAlarm } from "../AlarmsEditView"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { defaultGraph } from "../GraphTemplatesEditView"
import { generateNodeName } from "../GraphTemplatesEditView/NodesModal"
import { OmniTable } from "../common/OmniTable"
import { IconSevere } from "../common/Icons"
import {
  View,
  ViewContent,
  ViewHeader,
  ViewHeaderTitle,
  ViewHeaderButtons,
  ViewAlert,
  ViewAlertContent,
} from "../common/View"
import { LightButton, IconDropdownToggle } from "../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import Interval from "../common/Interval"
import { UncontrolledAlert } from "../common/UncontrolledAlert"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import FilterBox from "../common/FilterBox"
import { Select } from "../common/Select"
import BarGauge from "../common/BarGauge"
import CountryName from "../common/CountryName"
import { InsertNamesModal, InsertNameEntry } from "../InsertNamesModal"
import { fetchCFSStatistics, postSelectRelatedFilterStart, resolveAddresses } from "../../api/api"
import {
  AlarmInfo,
  Filter,
  GraphObject,
  MediaSpec,
  NodesHierarchyStatistics,
  NodesHierarchyStatisticsObject,
  NodesStatistics,
  NodesStatisticsObject,
  RequestPostSelectRelatedFilterStart,
  ResponsePostSelectRelatedFilterStart,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { MediaSpecType } from "../../api/types/mediaTypes"
import {
  GraphItemUnitType,
  PeekNodeStatType,
  PeekNodeStatsItemType,
} from "../../api/types/peekTypes"
import csvStringify from "../../utils/csvStringify"
import { filterExpressionTypeFromMediaSpecType } from "../../utils/filterUtils"
import {
  formatInteger,
  formatFloat,
  formatISODateTime,
  formatDuration,
} from "../../utils/formatUtils"
import { collator } from "../../utils/sortUtils"
import {
  getCaptureForensicSearchUrl,
  getEngineNewFilterUrl,
  getEngineNewGraphUrl,
  getEngineNewAlarmUrl,
  getNewDistributedForensicSearchUrl,
} from "../../routes"
import {
  getEngine,
  getAuthToken,
  getNamesModificationTime,
  getShowAddressNames,
  getNodeStatsViewType,
  getNodeStatsFilter,
  getNodeStatsColumns,
  getNodeStatsSortBy,
  getNodeStatsSortDirection,
  getNodeHierarchyStatsColumns,
  getCapabilities,
  getUserId,
  getShowLocalTime,
} from "../../store"
import { setCurrentEngine } from "../../store/engines"
import { setSelectPacketsTask } from "../../store/selectPackets"
import { updateStatus } from "../../store/status"
import {
  setNodeStatsViewType,
  setNodeStatsFilter,
  setNodeStatsColumns,
  setNodeStatsSort,
  setNodeHierarchyStatsColumns,
} from "../../store/ui"
import TreeTable from "../TreeTable"

const defaultColumns = [
  {
    dataKey: "node",
    label: "Node",
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "name",
    label: "Name",
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "country",
    label: "Country",
    width: 140,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "city",
    label: "City",
    width: 140,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "latitude",
    label: "Latitude",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "longitude",
    label: "Longitude",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "totalBytesPercent",
    label: "Total Bytes %",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "totalPacketsPercent",
    label: "Total Packets %",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "bytesTotal",
    label: "Total Bytes",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "packetsTotal",
    label: "Total Packets",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "bytesSent",
    label: "Bytes Sent",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "bytesReceived",
    label: "Bytes Received",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "packetsSent",
    label: "Packets Sent",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "packetsReceived",
    label: "Packets Received",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "broadcastPackets",
    label: "Broadcast Packets",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "broadcastBytes",
    label: "Broadcast Bytes",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "multicastPackets",
    label: "Multicast Packets",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "multicastBytes",
    label: "Multicast Bytes",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "broadcastMulticastPackets",
    label: "Broadcast/Multicast Packets",
    width: 200,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "broadcastMulticastBytes",
    label: "Broadcast/Multicast Bytes",
    width: 200,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "minimumPacketSizeSent",
    label: "Min. Size Sent",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "maximumPacketSizeSent",
    label: "Max. Size Sent",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "averagePacketSizeSent",
    label: "Avg. Size Sent",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "minimumPacketSizeReceived",
    label: "Min. Size Received",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "maximumPacketSizeReceived",
    label: "Max. Size Received",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "averagePacketSizeReceived",
    label: "Avg. Size Received",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "firstTimeSent",
    label: "First Time Sent",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "lastTimeSent",
    label: "Last Time Sent",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "firstTimeReceived",
    label: "First Time Received",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "lastTimeReceived",
    label: "Last Time Received",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "duration",
    label: "Duration",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
]

const ViewType = {
  IP: "IP",
  IPV6: "IPv6",
  PHYSICAL: "Physical",
  HIERARCHY: "Hierarchy",
}

const HierarchyViewType = {
  SENT: "Sent",
  RECEIVED: "Received",
  SENT_RECEIVED: "Sent & Received",
}

const CommandStrip = styled.div`
  display: flex;
`

const PercentageCellStyled = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.theme.name === "Light" ? props.color : props.theme.textColor)};

  .labels {
    width: 68px;
    margin-right: 30px;

    .label {
      margin-left: 8px;
    }
  }
`

function isResolvable(mt: MediaSpecType) {
  return (
    mt === MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS ||
    mt === MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS
  )
}

interface NodesHierarchyTableData extends NodesHierarchyStatisticsObject {
  name: string
  addressName: string
  node: string
  color: string
  id: string
  mediaSpec: MediaSpec
  children: any[]
}

type NodeStatisticsViewProps = RouteComponentProps<CaptureRouteParams> &
  CaptureViewProps & {
    dispatch: Function
    engine: string
    authToken: string
    namesModTime?: string
    showAddressNames: boolean
    viewType: string
    filter: string
    showLocalTime: boolean
    columns: any[]
    hierarchyColumns: any[]
    sortBy: string
    sortDirection: SortDirectionType
    theme: DefaultTheme
    engineCapabilities: ResponseGetEngineCapabilities | null
    userId: string
  }

type NodeStatisticsViewState = {
  nodeStatistics: NodesStatistics | null
  nodesHierarchyStatistics: NodesHierarchyStatistics | null
  insertNameEntry: InsertNameEntry | null
  nodesHierarchyTableData: NodesHierarchyTableData[]
  nodesHierarchyTableSortBy: string
  nodesHierarchyTableSortDirection: SortDirectionType
  expandedData: string[]
  hierarchyViewType: string
}

class NodeStatisticsView extends React.Component<NodeStatisticsViewProps, NodeStatisticsViewState> {
  state: NodeStatisticsViewState = {
    nodeStatistics: null,
    nodesHierarchyStatistics: null,
    insertNameEntry: null,
    nodesHierarchyTableData: [],
    nodesHierarchyTableSortBy: "bytes",
    nodesHierarchyTableSortDirection: "DESC",
    expandedData: [],
    hierarchyViewType: HierarchyViewType.SENT_RECEIVED,
  }

  // 'hierarchyColumns' get more columns from 'defaultColumns' in 'componentDidMount'
  hierarchyColumns: any[] = [
    {
      dataKey: "name",
      label: "Node",
      width: 300,
      visible: true,
    },
    {
      dataKey: "addressName",
      label: "Name",
      width: 300,
      visible: false,
    },
    {
      dataKey: "logicalCountry",
      label: "Country",
      width: 140,
      flexGrow: 0,
      flexShrink: 1,
      alignRight: false,
      visible: false,
    },
    {
      dataKey: "logicalCity",
      label: "City",
      width: 140,
      flexGrow: 1,
      flexShrink: 1,
      alignRight: false,
      visible: false,
    },
    {
      dataKey: "bytesPercent",
      label: "Bytes %",
      width: 300,
      flexGrow: 1,
      disableSort: true,
      visible: true,
      cellRenderer: ({ rowData }: TableCellProps) => {
        const { nodesHierarchyStatistics, hierarchyViewType } = this.state
        const percentageSent = nodesHierarchyStatistics
          ? (rowData.bytesSent * 100) / nodesHierarchyStatistics.totalBytes
          : 0
        const percentageReceived = nodesHierarchyStatistics
          ? (rowData.bytesReceived * 100) / nodesHierarchyStatistics.totalBytes
          : 0
        return (
          <PercentageCellStyled color={rowData.color}>
            <div style={{ flexGrow: 1 }}>
              {hierarchyViewType === HierarchyViewType.SENT ||
              hierarchyViewType === HierarchyViewType.SENT_RECEIVED ? (
                <BarGauge
                  aria-label="Percentage of bytes sent"
                  value={percentageSent}
                  max={100}
                  style={{ height: "14px" }}
                  color={rowData.color}
                  title={`${formatFloat(percentageSent, 3)}%`}
                />
              ) : (
                ""
              )}
              {hierarchyViewType === HierarchyViewType.RECEIVED ||
              hierarchyViewType === HierarchyViewType.SENT_RECEIVED ? (
                <BarGauge
                  aria-label="Percentage of bytes received"
                  value={percentageReceived}
                  max={100}
                  style={{ height: "14px", opacity: 0.5, marginTop: "2px" }}
                  color={rowData.color}
                  title={`${formatFloat(percentageReceived, 3)}%`}
                />
              ) : (
                ""
              )}
            </div>
            <div className="labels">
              {hierarchyViewType === HierarchyViewType.SENT ||
              hierarchyViewType === HierarchyViewType.SENT_RECEIVED ? (
                <div>
                  <FontAwesome name="long-arrow-right" fixedWidth />
                  <span className="label">{formatFloat(percentageSent, 3)}%</span>
                </div>
              ) : (
                ""
              )}
              {hierarchyViewType === HierarchyViewType.RECEIVED ||
              hierarchyViewType === HierarchyViewType.SENT_RECEIVED ? (
                <div
                  style={{
                    opacity: 0.5,
                  }}
                >
                  <FontAwesome name="long-arrow-left" fixedWidth />
                  <span className="label">{formatFloat(percentageReceived, 3)}%</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </PercentageCellStyled>
        )
      },
    },
    {
      dataKey: "bytes",
      label: "Bytes",
      width: 160,
      alignRight: true,
      visible: true,
      cellRenderer: ({ rowData }: TableCellProps) => {
        const { hierarchyViewType } = this.state
        return (
          <>
            {hierarchyViewType === HierarchyViewType.SENT ||
            hierarchyViewType === HierarchyViewType.SENT_RECEIVED ? (
              <div>{formatInteger(rowData.bytesSent)}</div>
            ) : (
              ""
            )}
            {hierarchyViewType === HierarchyViewType.RECEIVED ||
            hierarchyViewType === HierarchyViewType.SENT_RECEIVED ? (
              <div>{formatInteger(rowData.bytesReceived)}</div>
            ) : (
              ""
            )}
          </>
        )
      },
    },
    {
      dataKey: "packets",
      label: "Packets",
      width: 160,
      alignRight: true,
      visible: true,
      cellRenderer: ({ rowData }: TableCellProps) => {
        const { hierarchyViewType } = this.state
        return (
          <>
            {hierarchyViewType === HierarchyViewType.SENT ||
            hierarchyViewType === HierarchyViewType.SENT_RECEIVED ? (
              <div>{formatInteger(rowData.packetsSent)}</div>
            ) : (
              ""
            )}
            {hierarchyViewType === HierarchyViewType.RECEIVED ||
            hierarchyViewType === HierarchyViewType.SENT_RECEIVED ? (
              <div>{formatInteger(rowData.packetsReceived)}</div>
            ) : (
              ""
            )}
          </>
        )
      },
    },
    {
      dataKey: "logicalLatitude",
      label: "Latitude",
      width: 140,
      flexGrow: 0,
      flexShrink: 1,
      alignRight: true,
      visible: false,
    },
    {
      dataKey: "logicalLongitude",
      label: "Longitude",
      width: 140,
      flexGrow: 0,
      flexShrink: 1,
      alignRight: true,
      visible: false,
    },
  ]

  componentDidMount() {
    // remove first 8 columns from 'defaultColumns' and add others to 'hierarchyColumns'
    // and make them invisible
    this.hierarchyColumns = [
      ...this.hierarchyColumns,
      ...drop(defaultColumns, 8).map(col => {
        return { ...col, visible: false }
      }),
    ]
    this.onRefresh()
  }

  componentDidUpdate({ namesModTime, showAddressNames }: NodeStatisticsViewProps) {
    if (
      this.props.namesModTime !== namesModTime ||
      this.props.showAddressNames !== showAddressNames
    ) {
      this.onRefresh()
    }
  }

  onRefresh = () => {
    const { viewType } = this.props
    if (viewType !== ViewType.HIERARCHY) {
      this.fetchNodes()
    } else {
      this.fetchNodesHierarchy()
    }
  }

  fetchNodesHierarchy = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken, showAddressNames } = this.props
    const { nodesHierarchyTableSortBy, nodesHierarchyTableSortDirection, hierarchyViewType } =
      this.state
    fetchCFSStatistics<NodesHierarchyStatistics>(engine, authToken, type, capId, "nodes-hierarchy")
      .then(nodesHierarchyStatistics => {
        const nodes: NodesHierarchyTableData[] = nodesHierarchyStatistics.nodesHierarchy.map(
          node => {
            const physicalName =
              showAddressNames && node.physicalName ? node.physicalName : node.physical
            const logicalName =
              showAddressNames && node.logicalName ? node.logicalName : node.logical
            const physicalAddressName = node.physicalName ? node.physicalName : node.physical
            const logicalAddressName = node.logicalName ? node.logicalName : node.logical
            return {
              ...node,
              name: node.logical !== "" ? logicalName : physicalName,
              node: node.logical !== "" ? node.logical : node.physical,
              addressName: node.logical !== "" ? logicalAddressName : physicalAddressName,
              color: node.logicalColor ? node.logicalColor : node.physicalColor,
              id: node.logical !== "" ? node.logical : node.physical,
              mediaSpec: node.logicalMediaSpec ? node.logicalMediaSpec : node.physicalMediaSpec,
            } as NodesHierarchyTableData
          }
        )
        const nodesHierarchyTableData = remove(nodes, node => node.logical === "")
        nodesHierarchyTableData.forEach(node => {
          node.children = remove(nodes, n => n.physical === node.physical)
          node.children = node.children.map((child: any) => {
            return {
              ...child,
              color: child.logicalName ? child.color : "",
            }
          })
        })
        this.sortHierarchy(
          nodesHierarchyTableData,
          nodesHierarchyTableSortBy,
          nodesHierarchyTableSortDirection,
          hierarchyViewType,
          showAddressNames
        )
        this.setState({
          nodesHierarchyStatistics,
          nodesHierarchyTableData,
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  fetchNodes = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken, viewType, sortBy, sortDirection } = this.props
    fetchCFSStatistics<NodesStatistics>(engine, authToken, type, capId, "nodes")
      .then(nodeStatistics => {
        if (nodeStatistics.nodes) {
          let mediaSpecTypeFilter = null
          switch (viewType) {
            case ViewType.IP:
              mediaSpecTypeFilter = (node: NodesStatisticsObject) =>
                node.mediaSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
              break
            case ViewType.IPV6:
              mediaSpecTypeFilter = (node: NodesStatisticsObject) =>
                node.mediaSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS
              break
            case ViewType.PHYSICAL:
              mediaSpecTypeFilter = (node: NodesStatisticsObject) =>
                node.mediaSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS ||
                node.mediaSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_WIRELESS_ADDRESS
              break
            default:
              break
          }
          if (mediaSpecTypeFilter) {
            nodeStatistics.nodes = nodeStatistics.nodes.filter(mediaSpecTypeFilter)
          }
          this.sort(nodeStatistics, sortBy, sortDirection)
        }
        this.setState({ nodeStatistics })
      })
      .catch(error => {
        console.error(error)
      })
  }

  formatCSVContent = (column: any, rowData: any) => {
    let content: string | number = ""
    const cellData = rowData[column.dataKey as keyof NodesStatisticsObject]
    switch (column.dataKey) {
      case "node":
        content = (this.props.showAddressNames && rowData.name) || rowData.node
        break
      case "addressName":
        content = rowData.addressName
        break
      case "latitude":
      case "longitude":
        content = formatFloat(cellData as number, 4)
        break
      case "totalBytesPercent":
        {
          const bytesTotal = rowData.bytesSent + rowData.bytesReceived
          let percentage = 0
          if (this.state.nodeStatistics) {
            const totalBytes = this.state.nodeStatistics.totalBytes
            percentage = (bytesTotal / totalBytes) * 100
          }
          content = formatFloat(percentage, 3)
        }
        break
      case "totalPacketsPercent":
        {
          const packetsTotal = rowData.packetsSent + rowData.packetsReceived
          let percentage = 0
          if (this.state.nodeStatistics) {
            const totalPackets = this.state.nodeStatistics.totalPackets
            percentage = (packetsTotal / totalPackets) * 100
          }
          content = formatFloat(percentage, 3)
        }
        break
      case "bytesTotal":
        content = rowData.bytesSent + rowData.bytesReceived
        break
      case "packetsTotal":
        content = rowData.packetsSent + rowData.packetsReceived
        break
      case "broadcastMulticastPackets":
        content = rowData.broadcastPackets + rowData.multicastPackets
        break
      case "broadcastMulticastBytes":
        content = rowData.broadcastBytes + rowData.multicastBytes
        break
      case "minimumPacketSizeSent":
      case "maximumPacketSizeSent":
        if (rowData.packetsSent > 0) {
          content = cellData as number
        }
        break
      case "averagePacketSizeSent":
        if (rowData.packetsSent > 0) {
          const avg = rowData.bytesSent / rowData.packetsSent
          content = formatInteger(avg)
        }
        break
      case "minimumPacketSizeReceived":
      case "maximumPacketSizeReceived":
        if (rowData.packetsReceived > 0) {
          content = cellData as number
        }
        break
      case "averagePacketSizeReceived":
        if (rowData.packetsReceived > 0) {
          const avg = rowData.bytesReceived / rowData.packetsReceived
          content = formatInteger(avg)
        }
        break
      case "firstTimeSent":
      case "lastTimeSent":
      case "firstTimeReceived":
      case "lastTimeReceived":
        content = formatISODateTime(cellData as string, 0, this.props.showLocalTime)
        break
      case "duration":
        content = formatDuration(cellData as number, 6)
        break
      default:
        content = cellData as string // This is a lie
        break
    }
    return content
  }

  onExport = () => {
    const { nodeStatistics } = this.state
    const { filter, columns } = this.props
    const visibleColumns = columns.filter(col => col.visible)
    let csv = visibleColumns.map(col => csvStringify(col.label)).join(",") + "\n"

    let nodes = nodeStatistics && nodeStatistics.nodes
    if (nodes && filter) {
      const lowerCaseFilter = filter.toLowerCase()
      nodes = nodes.filter(stat => {
        const node = (this.props.showAddressNames && stat.name) || stat.node
        return (
          node.toLowerCase().includes(lowerCaseFilter) ||
          (stat.name !== undefined && stat.name.toLowerCase().includes(lowerCaseFilter))
        )
      })
    }

    if (nodes) {
      nodes.forEach((rowData: NodesStatisticsObject) => {
        const row: string[] = []
        visibleColumns.forEach(col => {
          const content = this.formatCSVContent(col, rowData)
          row.push(csvStringify(content))
        })
        csv += row.join(",") + "\n"
      })
    }

    FileSaver.saveAs(new Blob([csv], { type: "text/plain;charset=utf8" }), "Node Statistics.csv")
  }

  onExportHierarchy = () => {
    let nodesHierarchyTableData = cloneDeep(this.state.nodesHierarchyTableData)
    let hierarchyColumns = cloneDeep(this.props.hierarchyColumns)
    const isBytesPercentColumnEnabled = hierarchyColumns.find(
      col => col.dataKey === "bytesPercent"
    ).visible
    const isBytesColumnEnabled = hierarchyColumns.find(col => col.dataKey === "bytes").visible
    const isPacketsColumnEnabled = hierarchyColumns.find(col => col.dataKey === "packets").visible

    // Disable 'bytesPercent', 'bytes', 'packets' and enable 'bytesSent', 'bytesReceived', 'packetsSent', 'packetsReceived'
    // to have one value in table cell
    hierarchyColumns = hierarchyColumns.map(col => {
      if (isBytesPercentColumnEnabled || isBytesColumnEnabled) {
        if (col.dataKey === "bytesPercent" || col.dataKey === "bytes") {
          return {
            ...col,
            visible: false,
          }
        }
        if (col.dataKey === "bytesSent" || col.dataKey === "bytesReceived") {
          return {
            ...col,
            visible: true,
          }
        }
      }
      if (isPacketsColumnEnabled) {
        if (col.dataKey === "packets") {
          return {
            ...col,
            visible: false,
          }
        }
        if (col.dataKey === "packetsSent" || col.dataKey === "packetsReceived") {
          return {
            ...col,
            visible: true,
          }
        }
      }
      return col
    })

    const visibleColumns = hierarchyColumns.filter(col => col.visible)
    let csv = visibleColumns.map(col => csvStringify(col.label)).join(",") + "\n"
    // Add 'Indent' column for hierarchy structure at csv file
    csv = `Indent,${csv}`
    nodesHierarchyTableData = flatten(
      nodesHierarchyTableData.map(data => {
        return [data, ...data.children]
      })
    )
    nodesHierarchyTableData.forEach((rowData: NodesHierarchyTableData) => {
      // "1" - Parent, "2" - Children
      const row: string[] = [rowData.children !== undefined ? "1" : "2"]
      visibleColumns.forEach(col => {
        const content = this.formatCSVContent(col, rowData)
        row.push(csvStringify(content))
      })
      csv += row.join(",") + "\n"
    })
    FileSaver.saveAs(new Blob([csv], { type: "text/plain;charset=utf8" }), "Node Statistics.csv")
  }

  onChangeViewType = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.dispatch(setNodeStatsViewType(e.target.value))
    if (!this.props.hierarchyColumns) {
      this.props.dispatch(setNodeHierarchyStatsColumns(this.hierarchyColumns))
    }
    setTimeout(this.onRefresh, 0)
  }

  onChangeHierarchyViewType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { nodesHierarchyTableData, nodesHierarchyTableSortBy, nodesHierarchyTableSortDirection } =
      this.state
    const { showAddressNames } = this.props
    const hierarchyViewType = e.target.value
    const tableData = cloneDeep(nodesHierarchyTableData)
    this.sortHierarchy(
      tableData,
      nodesHierarchyTableSortBy,
      nodesHierarchyTableSortDirection,
      hierarchyViewType,
      showAddressNames
    )
    this.setState({
      nodesHierarchyTableData: tableData,
      hierarchyViewType,
    })
  }

  onChangeFilter = (filter: string) => {
    this.props.dispatch(setNodeStatsFilter(filter))
  }

  onShowDefaultColumns = () => {
    this.props.dispatch(setNodeStatsColumns(defaultColumns))
  }

  onShowDefaultHierarchyColumns = () => {
    this.props.dispatch(setNodeHierarchyStatsColumns(this.hierarchyColumns))
  }

  onShowAllColumns = () => {
    const columns = cloneDeep(this.props.columns)
    columns.forEach(col => {
      col.visible = true
    })
    this.props.dispatch(setNodeStatsColumns(columns))
  }

  onShowAllHierarchyColumns = () => {
    const columns = cloneDeep(this.props.hierarchyColumns)
    columns.forEach(col => {
      col.visible = true
    })
    this.props.dispatch(setNodeHierarchyStatsColumns(columns))
  }

  onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const columns = cloneDeep(this.props.columns)
    const col = columns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      this.props.dispatch(setNodeStatsColumns(columns))
    }
  }

  onToggleHierarchyColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const columns = cloneDeep(this.props.hierarchyColumns)
    const col = columns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      this.props.dispatch(setNodeHierarchyStatsColumns(columns))
    }
  }

  onSelectRelated = (command: string, rowData: NodesStatisticsObject) => {
    let accept1To2 = false
    let accept2To1 = false
    if (command === "source") {
      accept1To2 = true
    } else if (command === "dest") {
      accept2To1 = true
    } else if (command === "sourceOrDest") {
      accept1To2 = true
      accept2To1 = true
    }
    const body: RequestPostSelectRelatedFilterStart = {
      id: uuid(),
      rootNode: {
        accept1To2: accept1To2,
        accept2To1: accept2To1,
        address1: rowData.node,
        address2: "",
        clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
        comment: "",
        inverted: false,
        type: rowData.mediaSpec.type,
      },
    }
    const { capId } = this.props.match.params
    const { engine, authToken } = this.props
    postSelectRelatedFilterStart(engine, authToken, capId, body).then(
      (task: ResponsePostSelectRelatedFilterStart) => {
        if (task.taskId) {
          this.props.dispatch(
            setSelectPacketsTask({ type: "filter", taskId: task.taskId, progress: 0 })
          )
          this.props.history.push("packets")
        }
      }
    )
  }

  onMSA = (rowData: NodesStatisticsObject) => {
    const type = filterExpressionTypeFromMediaSpecType(rowData.mediaSpec.type)
    if (type) {
      const { firstTimeSent, firstTimeReceived, lastTimeSent, lastTimeReceived } = rowData
      let startTime: string | undefined
      if (firstTimeSent && firstTimeReceived) {
        startTime = firstTimeSent < firstTimeReceived ? firstTimeSent : firstTimeReceived
      } else if (firstTimeSent) {
        startTime = firstTimeSent
      } else if (firstTimeReceived) {
        startTime = firstTimeReceived
      }
      let endTime: string | undefined
      if (lastTimeSent && lastTimeReceived) {
        endTime = lastTimeSent > lastTimeReceived ? lastTimeSent : lastTimeReceived
      } else if (lastTimeSent) {
        endTime = lastTimeSent
      } else if (lastTimeReceived) {
        endTime = lastTimeReceived
      }
      const filter = `addr(type:${type}, addr1:'${rowData.node}')`
      this.props.dispatch(setCurrentEngine(null))
      this.props.history.push({
        pathname: getNewDistributedForensicSearchUrl(),
        state: { startTime, endTime, filter },
      })
    }
  }

  onMakeFilter = (rowData: NodesStatisticsObject) => {
    const filter: Filter = {
      clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
      color: "#000000", // TODO rowData.color,
      comment: "",
      created: "",
      group: "",
      id: "",
      modified: "",
      name: "Untitled",
      rootNode: {
        accept1To2: true,
        accept2To1: true,
        address1: rowData.node,
        address2: "",
        clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
        comment: "",
        inverted: false,
        type: rowData.mediaSpec.type,
      },
    }
    this.props.history.push({
      pathname: getEngineNewFilterUrl(),
      state: { filter },
    })
  }

  onMakeGraph = (rowData: NodesStatisticsObject) => {
    const node = generateNodeName(rowData.mediaSpec.type, rowData.node)
    const name = (this.props.showAddressNames && rowData.name) || node
    const graph: GraphObject = {
      ...cloneDeep(defaultGraph),
      graphItems: [
        {
          clsid: "B1DAFDE2-68F2-4D35-AAA7-5861B35A3E9F",
          description: node,
          id: uuid().toUpperCase(),
          mediaSpec: rowData.node,
          mediaSpecType: rowData.mediaSpec.type,
          name: name,
          nodeStatsType: PeekNodeStatsItemType.PEEK_NODE_STATS_ITEM_TYPE_TOTAL,
          unitType: GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_BYTES,
        },
      ],
      id: uuid().toUpperCase(),
      templateId: uuid().toUpperCase(),
      title: name,
    }

    this.props.history.push({
      pathname: getEngineNewGraphUrl(),
      state: { graph },
    })
  }

  onMakeAlarm = (rowData: NodesStatisticsObject) => {
    const alarm: AlarmInfo = {
      ...cloneDeep(defaultAlarm),
      name: (this.props.showAddressNames && rowData.name) || rowData.node,
      statisticsTracker: {
        clsid: "0EC1390E-3A7D-45B3-9803-84D74B5D5B80",
        history: 60,
        node: cloneDeep(rowData.mediaSpec),
        statisticsType: PeekNodeStatType.PEEK_NODE_STAT_TYPE_TOTAL_BYTES,
      },
    }
    this.props.history.push({
      pathname: getEngineNewAlarmUrl(),
      state: { alarm },
    })
  }

  onInsertIntoNameTable = (rowData: NodesStatisticsObject) => {
    this.setState({
      insertNameEntry: {
        title: "Node Address",
        entry: rowData.node,
        entryType: rowData.mediaSpec.type,
      },
    })
  }

  onInsertIntoNameTableOK = () => {
    this.setState({ insertNameEntry: null })
    this.onRefresh()
  }

  onInsertIntoNameTableCancel = () => {
    this.setState({ insertNameEntry: null })
  }

  onResolveNames = (rowData: NodesStatisticsObject) => {
    if (isResolvable(rowData.mediaSpec.type)) {
      resolveAddresses(this.props.engine, this.props.authToken, [
        { entry: rowData.node, entryType: rowData.mediaSpec.type },
      ]).catch(error => {
        console.error(error)
      })
      this.props.dispatch(updateStatus())
    }
  }

  /*
  onResolveAllNames = () => {
    const { nodeStatistics } = this.state
    const { filter } = this.props
    let nodes = nodeStatistics && nodeStatistics.nodes
    if (nodes && filter) {
      const lowerCaseFilter = filter.toLowerCase()
      nodes = nodes.filter(stat => {
        const node = (this.props.showAddressNames && stat.name) || stat.node
        return node.toLowerCase().includes(lowerCaseFilter) || (stat.name !== undefined && stat.name.toLowerCase().includes(lowerCaseFilter))
      })
    }
    if (nodes) {
      const entries = nodes
        .filter(node => isResolvable(node.mediaSpec.type))
        .map(node => {
          return {
            entry: node.node,
            entryType: node.mediaSpec.type,
          }
        })
      if (entries.length) {
        resolveAddresses(this.props.engine, this.props.authToken, entries).catch(error => {
          console.error(error)
        })
        this.props.dispatch(updateStatus())
      }
    }
  }
  */

  cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content
    switch (dataKey) {
      case "node":
        {
          const node = (this.props.showAddressNames && rowData.name) || rowData.node
          if (rowData.color != null && this.props.theme.name === "Light") {
            content = (
              <span style={{ color: rowData.color }} title={node}>
                {node}
              </span>
            )
          } else {
            content = node
          }
        }
        break
      case "name":
        if (rowData.name !== undefined) {
          if (rowData.color != null && this.props.theme.name === "Light") {
            content = (
              <span style={{ color: rowData.color }} title={rowData.name}>
                {rowData.name}
              </span>
            )
          } else {
            content = rowData.name
          }
        } else {
          content = ""
        }
        break
      case "country":
      case "logicalCountry":
        if (cellData) {
          content = (
            <CountryName
              name={cellData}
              code={rowData.countryCode ? rowData.countryCode : rowData.logicalCountryCode}
            />
          )
        }
        break
      case "latitude":
      case "longitude":
      case "logicalLatitude":
      case "logicalLongitude":
        if (cellData != null) {
          content = formatFloat(cellData, 4)
        }
        break
      case "totalBytesPercent":
        {
          const bytesTotal = rowData.bytesSent + rowData.bytesReceived
          let percentage = 0
          if (this.state.nodeStatistics) {
            const totalBytes = this.state.nodeStatistics.totalBytes
            percentage = (bytesTotal / totalBytes) * 100
          }
          content = (
            <BarGauge
              aria-label="Percentage of bytes"
              value={percentage}
              max={100}
              color={rowData.color}
              title={`${formatFloat(percentage, 3)}%`}
            />
          )
        }
        break
      case "totalPacketsPercent":
        {
          const packetsTotal = rowData.packetsSent + rowData.packetsReceived
          let percentage = 0
          if (this.state.nodeStatistics) {
            const totalPackets = this.state.nodeStatistics.totalPackets
            percentage = (packetsTotal / totalPackets) * 100
          }
          content = (
            <BarGauge
              aria-label="Percentage of packets"
              value={percentage}
              max={100}
              color={rowData.color}
              title={`${formatFloat(percentage, 3)}%`}
            />
          )
        }
        break
      case "bytesTotal":
        content = formatInteger(rowData.bytesSent + rowData.bytesReceived)
        break
      case "packetsTotal":
        content = formatInteger(rowData.packetsSent + rowData.packetsReceived)
        break
      case "bytesSent":
      case "bytesReceived":
      case "packetsSent":
      case "packetsReceived":
      case "broadcastPackets":
      case "broadcastBytes":
      case "multicastPackets":
      case "multicastBytes":
        content = formatInteger(cellData)
        break
      case "broadcastMulticastPackets":
        content = formatInteger(rowData.broadcastPackets + rowData.multicastPackets)
        break
      case "broadcastMulticastBytes":
        content = formatInteger(rowData.broadcastBytes + rowData.multicastBytes)
        break
      case "minimumPacketSizeSent":
      case "maximumPacketSizeSent":
        if (rowData.packetsSent > 0) {
          content = formatInteger(cellData)
        }
        break
      case "averagePacketSizeSent":
        if (rowData.packetsSent > 0) {
          const avg = rowData.bytesSent / rowData.packetsSent
          content = formatInteger(avg)
        }
        break
      case "minimumPacketSizeReceived":
      case "maximumPacketSizeReceived":
        if (rowData.packetsReceived > 0) {
          content = formatInteger(cellData)
        }
        break
      case "averagePacketSizeReceived":
        if (rowData.packetsReceived > 0) {
          const avg = rowData.bytesReceived / rowData.packetsReceived
          content = formatInteger(avg)
        }
        break
      case "firstTimeSent":
      case "lastTimeSent":
      case "firstTimeReceived":
      case "lastTimeReceived":
        content = formatISODateTime(cellData as string, 0, this.props.showLocalTime)
        break
      case "duration":
        if (cellData) {
          content = formatDuration(cellData, 6)
        }
        break
      default:
        content = cellData
        break
    }
    return content
  }

  commandCellRenderer = ({ rowData }: TableCellProps) => {
    const { captureProperties, engineCapabilities, userId } = this.props
    const packetsDisabled = captureProperties === null || !captureProperties.packetBufferEnabled

    // make sure the user can view packets for this capture or forensic search
    let canCreateForensicSearch = true
    let canUploadFiles = true
    let canViewPackets = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canCreateForensicSearch =
        !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL) ||
        policies.includes(EngineUserPolicies.createForensicSearch)
      canUploadFiles = policies.includes(EngineUserPolicies.uploadFiles)
      canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
    }

    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, "source", rowData)}
            >
              Select Related Packets by Source
            </DropdownItem>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, "dest", rowData)}
            >
              Select Related Packets by Destination
            </DropdownItem>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, "sourceOrDest", rowData)}
            >
              Select Related Packets by Source or Destination
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              disabled={!canUploadFiles || !canCreateForensicSearch}
              onClick={this.onMSA.bind(this, rowData)}
            >
              Multi-Segment Analysis
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.onMakeFilter.bind(this, rowData)}>Make Filter</DropdownItem>
            <DropdownItem onClick={this.onMakeGraph.bind(this, rowData)}>Make Graph</DropdownItem>
            <DropdownItem onClick={this.onMakeAlarm.bind(this, rowData)}>Make Alarm</DropdownItem>
            <DropdownItem onClick={this.onInsertIntoNameTable.bind(this, rowData)}>
              Insert Into Name Table
            </DropdownItem>
            <DropdownItem onClick={this.onResolveNames.bind(this, rowData)}>
              Resolve Names
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  onSort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
    this.sort(this.state.nodeStatistics, sortBy, sortDirection)
    this.props.dispatch(setNodeStatsSort(sortBy, sortDirection))
  }

  sort(nodeStatistics: NodesStatistics | null, sortBy: string, sortDirection: SortDirectionType) {
    if (nodeStatistics && nodeStatistics.nodes) {
      nodeStatistics.nodes.sort((a, b) => {
        let result = 0

        switch (sortBy) {
          case "node":
            {
              const valueA = (this.props.showAddressNames && a.name) || a.node
              const valueB = (this.props.showAddressNames && b.name) || b.node
              result = collator.compare(valueA, valueB)
            }
            break
          case "name":
          case "country":
          case "city":
          case "firstTimeSent":
          case "lastTimeSent":
          case "firstTimeReceived":
          case "lastTimeReceived":
            {
              const valueA = a[sortBy]
              const valueB = b[sortBy]
              if (valueA != null && valueB != null) {
                result = collator.compare(valueA, valueB)
              } else if (valueA != null) {
                result = 1
              } else if (valueB != null) {
                result = -1
              }
            }
            break
          case "duration":
          case "latitude":
          case "longitude":
            {
              const valueA = a[sortBy]
              const valueB = b[sortBy]
              if (valueA != null && valueB != null) {
                if (valueA > valueB) {
                  result = 1
                } else if (valueA < valueB) {
                  result = -1
                }
              } else if (valueA != null) {
                result = 1
              } else if (valueB != null) {
                result = -1
              }
            }
            break
          case "totalBytesPercent":
          case "bytesTotal":
            {
              const valueA = a.bytesSent + a.bytesReceived
              const valueB = b.bytesSent + b.bytesReceived
              if (valueA > valueB) {
                result = 1
              } else if (valueA < valueB) {
                result = -1
              }
            }
            break
          case "totalPacketsPercent":
          case "packetsTotal":
            {
              const valueA = a.packetsSent + a.packetsReceived
              const valueB = b.packetsSent + b.packetsReceived
              if (valueA > valueB) {
                result = 1
              } else if (valueA < valueB) {
                result = -1
              }
            }
            break
          case "broadcastMulticastPackets":
            {
              const valueA = a.broadcastPackets + a.multicastPackets
              const valueB = b.broadcastPackets + b.multicastPackets
              if (valueA > valueB) {
                result = 1
              } else if (valueA < valueB) {
                result = -1
              }
            }
            break
          case "broadcastMulticastBytes":
            {
              const valueA = a.broadcastBytes + a.multicastBytes
              const valueB = b.broadcastBytes + b.multicastBytes
              if (valueA > valueB) {
                result = 1
              } else if (valueA < valueB) {
                result = -1
              }
            }
            break
          case "averagePacketSizeSent":
            {
              let valueA = 0
              let valueB = 0
              if (a.packetsSent > 0) {
                valueA = a.bytesSent / a.packetsSent
              }
              if (b.packetsSent > 0) {
                valueB = b.bytesSent / b.packetsSent
              }
              if (valueA > valueB) {
                result = 1
              } else if (valueA < valueB) {
                result = -1
              }
            }
            break
          case "averagePacketSizeReceived":
            {
              let valueA = 0
              let valueB = 0
              if (a.packetsReceived > 0) {
                valueA = a.bytesReceived / a.packetsReceived
              }
              if (b.packetsReceived > 0) {
                valueB = b.bytesReceived / b.packetsReceived
              }
              if (valueA > valueB) {
                result = 1
              } else if (valueA < valueB) {
                result = -1
              }
            }
            break
          case "broadcastBytes":
          case "broadcastPackets":
          case "bytesReceived":
          case "bytesSent":
          case "maximumPacketSizeReceived":
          case "maximumPacketSizeSent":
          case "minimumPacketSizeReceived":
          case "minimumPacketSizeSent":
          case "multicastBytes":
          case "multicastPackets":
          case "packetsReceived":
          case "packetsSent":
            {
              const valueA = a[sortBy]
              const valueB = b[sortBy]
              if (valueA > valueB) {
                result = 1
              } else if (valueA < valueB) {
                result = -1
              }
            }
            break
        }

        if (result === 0) {
          // Fall back to node.
          const valueA = (this.props.showAddressNames && a.name) || a.node
          const valueB = (this.props.showAddressNames && b.name) || b.node
          result = collator.compare(valueA, valueB)
        }

        if (sortDirection === SortDirection.DESC) result = -result

        return result
      })
    }
  }

  onSortHierarchy = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
  }) => {
    const { nodesHierarchyTableData, hierarchyViewType } = this.state
    const { showAddressNames } = this.props
    const tableData = cloneDeep(nodesHierarchyTableData)
    this.sortHierarchy(tableData, sortBy, sortDirection, hierarchyViewType, showAddressNames)
    this.setState({
      nodesHierarchyTableData: tableData,
      nodesHierarchyTableSortBy: sortBy,
      nodesHierarchyTableSortDirection: sortDirection,
    })
  }

  sortHierarchy = (
    tableData: NodesHierarchyTableData[],
    sortBy: string,
    sortDirection: SortDirectionType,
    hierarchyViewType: string,
    showAddressNames: boolean
  ) => {
    switch (sortBy) {
      case "name":
        tableData.sort((a, b) => {
          const aPhysicalName = showAddressNames && a.physicalName ? a.physicalName : a.physical
          const aLogicalName = showAddressNames && a.logicalName ? a.logicalName : a.logical
          const aName = aLogicalName !== "" ? aLogicalName : aPhysicalName
          const bPhysicalName = showAddressNames && b.physicalName ? b.physicalName : b.physical
          const bLogicalName = showAddressNames && b.logicalName ? b.logicalName : b.logical
          const bName = bLogicalName !== "" ? bLogicalName : bPhysicalName
          if (aName && bName) {
            return collator.compare(aName, bName)
          } else if (aName) {
            return 1
          } else if (bName) {
            return -1
          }
          return 0
        })
        break
      case "addressName":
        tableData.sort((a, b) => {
          const aPhysicalName = a.physicalName ? a.physicalName : a.physical
          const aLogicalName = a.logicalName ? a.logicalName : a.logical
          const aName = aLogicalName !== "" ? aLogicalName : aPhysicalName
          const bPhysicalName = b.physicalName ? b.physicalName : b.physical
          const bLogicalName = b.logicalName ? b.logicalName : b.logical
          const bName = bLogicalName !== "" ? bLogicalName : bPhysicalName
          if (aName && bName) {
            return collator.compare(aName, bName)
          } else if (aName) {
            return 1
          } else if (bName) {
            return -1
          }
          return 0
        })
        break
      case "bytes":
        tableData.sort((a, b) => {
          const aSent = hierarchyViewType === HierarchyViewType.RECEIVED ? 0 : a.bytesSent
          const aReceived = hierarchyViewType === HierarchyViewType.SENT ? 0 : a.bytesReceived
          const bSent = hierarchyViewType === HierarchyViewType.RECEIVED ? 0 : b.bytesSent
          const bReceived = hierarchyViewType === HierarchyViewType.SENT ? 0 : b.bytesReceived
          const aSum = aSent + aReceived
          const bSum = bSent + bReceived
          return aSum - bSum
        })
        break
      case "packets":
        tableData.sort((a, b) => {
          const aSent = hierarchyViewType === HierarchyViewType.RECEIVED ? 0 : a.packetsSent
          const aReceived = hierarchyViewType === HierarchyViewType.SENT ? 0 : a.packetsReceived
          const bSent = hierarchyViewType === HierarchyViewType.RECEIVED ? 0 : b.packetsSent
          const bReceived = hierarchyViewType === HierarchyViewType.SENT ? 0 : b.packetsReceived
          const aSum = aSent + aReceived
          const bSum = bSent + bReceived
          return aSum - bSum
        })
        break
      default:
        tableData.sort((a: any, b: any) => {
          if (a[sortBy] < b[sortBy]) {
            return -1
          }
          if (a[sortBy] > b[sortBy]) {
            return 1
          }
          return 0
        })
        break
    }

    if (sortDirection === SortDirection.DESC) {
      reverse(tableData)
    }

    tableData.forEach(item => {
      if (item.children) {
        this.sortHierarchy(
          item.children as NodesHierarchyTableData[],
          sortBy,
          sortDirection,
          hierarchyViewType,
          showAddressNames
        )
      }
    })
  }

  onExpandAll = () => {
    const { nodesHierarchyTableData } = this.state
    this.setState({ expandedData: nodesHierarchyTableData.map(node => node.id) })
  }

  onCollapseAll = () => {
    this.setState({ expandedData: [] })
  }

  renderNodeTable() {
    const { columns, sortBy, sortDirection, filter } = this.props
    const { nodeStatistics } = this.state
    let nodes = nodeStatistics && nodeStatistics.nodes
    if (nodes && filter) {
      const lowerCaseFilter = filter.toLowerCase()
      nodes = nodes.filter(stat => {
        const node = (this.props.showAddressNames && stat.name) || stat.node
        return (
          node.toLowerCase().includes(lowerCaseFilter) ||
          (stat.name !== undefined && stat.name.toLowerCase().includes(lowerCaseFilter))
        )
      })
    }
    return (
      <OmniTable
        data={nodes}
        rowCount={nodes?.length}
        columnDesc={columns}
        cellRenderer={this.cellRenderer}
        renderCommands={this.commandCellRenderer}
        onShowDefaultColumns={this.onShowDefaultColumns}
        onShowAllColumns={this.onShowAllColumns}
        onToggleColumn={this.onToggleColumn}
        sort={this.onSort}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
    )
  }

  renderNodeHierarchyTable() {
    const {
      nodesHierarchyTableData,
      nodesHierarchyTableSortBy,
      nodesHierarchyTableSortDirection,
      expandedData,
    } = this.state
    const { hierarchyColumns, filter } = this.props
    if (!hierarchyColumns) return ""
    return (
      <TreeTable
        data={nodesHierarchyTableData}
        rowHeight={37}
        expandedRows={expandedData}
        filter={filter}
        columnDesc={hierarchyColumns}
        cellRenderer={this.cellRenderer}
        renderCommands={this.commandCellRenderer}
        onShowDefaultColumns={this.onShowDefaultHierarchyColumns}
        onShowAllColumns={this.onShowAllHierarchyColumns}
        onToggleColumn={this.onToggleHierarchyColumn}
        sort={this.onSortHierarchy}
        sortBy={nodesHierarchyTableSortBy}
        sortDirection={nodesHierarchyTableSortDirection}
      />
    )
  }

  render() {
    const { nodeStatistics, insertNameEntry, hierarchyViewType } = this.state
    const { captureProperties, engineCapabilities, filter, userId, viewType } = this.props

    // make sure the user can view stats for this capture or forensic search
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
      if (!canViewStats) {
        const { type, capId } = this.props.match.params
        return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
      }
    }

    const count = nodeStatistics ? nodeStatistics.nodes.length : 0
    let limitMessage = ""
    let limitIcon = null
    if (nodeStatistics && nodeStatistics.timeLimitReached) {
      limitMessage = `Node statistics limit reached at ${formatISODateTime(
        nodeStatistics.timeLimitReached,
        0,
        this.props.showLocalTime
      )}`
      limitIcon = <IconSevere />
    }
    return (
      <View>
        <BreadcrumbItem to={this.props.match.url} title="Node Statistics" />
        <Interval timeout={30000} enabled={true} callback={this.onRefresh} />
        <ViewHeader>
          <ViewHeaderTitle title="Nodes" count={count} />
          <ViewHeaderButtons>
            <Select
              name="viewType"
              id="viewType"
              aria-label="View Type"
              value={viewType}
              onChange={this.onChangeViewType}
              style={{ width: "auto" }}
            >
              <option>{ViewType.IP}</option>
              <option>{ViewType.IPV6}</option>
              <option>{ViewType.PHYSICAL}</option>
              <option>{ViewType.HIERARCHY}</option>
            </Select>
            {viewType === ViewType.HIERARCHY ? (
              <Select
                name="hierarchyViewType"
                id="hierarchyViewType"
                aria-label="Hierarchy View Type"
                value={hierarchyViewType}
                onChange={this.onChangeHierarchyViewType}
                style={{ width: "auto" }}
              >
                <option>{HierarchyViewType.SENT}</option>
                <option>{HierarchyViewType.RECEIVED}</option>
                <option>{HierarchyViewType.SENT_RECEIVED}</option>
              </Select>
            ) : (
              ""
            )}
            {viewType === ViewType.HIERARCHY && (
              <>
                <LightButton id="expand-all" onClick={this.onExpandAll}>
                  Expand All
                </LightButton>
                <LightButton id="collapse-all" onClick={this.onCollapseAll}>
                  Collapse All
                </LightButton>
              </>
            )}
            <FilterBox
              aria-label="Search"
              placeholder="Search"
              onChange={this.onChangeFilter}
              value={filter}
            />
            <LightButton
              aria-label="Export"
              id="export"
              onClick={viewType !== ViewType.HIERARCHY ? this.onExport : this.onExportHierarchy}
            >
              <FontAwesome name="download" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="export">
              Export
            </UncontrolledTooltip>
            <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefresh}>
              <FontAwesome name="refresh" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="refresh">
              Refresh
            </UncontrolledTooltip>
          </ViewHeaderButtons>
        </ViewHeader>
        {limitMessage && (
          <UncontrolledAlert component={ViewAlert} color="danger" fade={false}>
            <ViewAlertContent>
              {limitIcon}
              {limitMessage}
            </ViewAlertContent>
          </UncontrolledAlert>
        )}
        <ViewContent>
          {viewType !== ViewType.HIERARCHY && nodeStatistics
            ? this.renderNodeTable()
            : this.renderNodeHierarchyTable()}
        </ViewContent>
        {insertNameEntry != null && (
          <InsertNamesModal
            engine={this.props.engine}
            authToken={this.props.authToken}
            entries={[insertNameEntry]}
            onOK={this.onInsertIntoNameTableOK}
            onCancel={this.onInsertIntoNameTableCancel}
          />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  namesModTime: getNamesModificationTime(state),
  showAddressNames: getShowAddressNames(state),
  viewType: getNodeStatsViewType(state) || ViewType.IP,
  filter: getNodeStatsFilter(state) || "",
  showLocalTime: getShowLocalTime(state),
  columns: getNodeStatsColumns(state) || defaultColumns,
  hierarchyColumns: getNodeHierarchyStatsColumns(state),
  sortBy: getNodeStatsSortBy(state) || "totalBytesPercent",
  sortDirection: getNodeStatsSortDirection(state) || SortDirection.DESC,
  engineCapabilities: getCapabilities(state) || null,
  userId: getUserId(state),
})

export default connect(mapStateToProps)(withTheme(NodeStatisticsView))
