import styled from "styled-components"

export const CenterContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

export const ControlStrip = styled.div<{ justify?: string; margin?: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: ${props => props.justify};

  & > * + * {
    margin-left: ${props => props.margin};
  }
`

ControlStrip.defaultProps = {
  justify: "flex-end",
  margin: "4px",
}
