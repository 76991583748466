import { formatInteger, formatLinkSpeed } from "../../utils/formatUtils"
import { getMediaString } from "../../utils/mediaUtils"
import { CLSID_COMPASS } from "../../utils/pluginUtils"
import {
  AdapterInfo,
  CaptureProperties,
  CaptureTemplateProperties,
  HardwareOptions,
  PluginObject,
  ResponseGetEngineCapabilities,
  ResponseGetStatus,
} from "../../api/types"
import { PeekAdapterFeatures, PeekFilterMode } from "../../api/types/peekTypes"
import { MutedText } from "../common/MutedText"

export const BYTES_PER_MEGABYTE = 1048576 // 1024 * 1024
export const BYTES_PER_GIGABYTE = 1073741824 // 1024 * 1024 * 1024

export const SECONDS_PER_MINUTE = 60
export const SECONDS_PER_HOUR = 3600
export const SECONDS_PER_DAY = 86400

export const getAdapterEnumerator = (adapter: AdapterInfo): string => {
  if (adapter) {
    if ("identifier" in adapter) {
      return adapter.identifier
    } else if ("enumerator" in adapter) {
      return adapter.enumerator
    }
  }
  return ""
}

export const formatAdapterInfo = (adapterInfo: AdapterInfo) => {
  const title = "title" in adapterInfo && adapterInfo.title.length > 0 ? adapterInfo.title : ""

  const media =
    "mediaType" in adapterInfo && "mediaSubType" in adapterInfo
      ? getMediaString(adapterInfo.mediaType, adapterInfo.mediaSubType)
      : ""
  const linkSpeed = "linkSpeed" in adapterInfo ? formatLinkSpeed(adapterInfo.linkSpeed) : ""
  let address = ""
  if ("address" in adapterInfo) {
    const match = adapterInfo.address.match(/.{1,2}/g)
    if (match) {
      address = match.join(":")
    }
  }
  let line3 = ""
  if (media.length > 0) {
    if (line3.length > 0) {
      line3 += ", "
    }
    line3 += media
  }
  if (linkSpeed.length > 0) {
    if (line3.length > 0) {
      line3 += ", "
    }
    line3 += linkSpeed
  }
  if (address.length > 0) {
    if (line3.length > 0) {
      line3 += ", "
    }
    line3 += address
  }

  let errorCapture = ""
  let hardwareFeatures = ""
  if ("interfaceFeatures" in adapterInfo) {
    // Check the adapter error capture feature.
    // Note: this may be overridden by the adapter features below.
    errorCapture = adapterInfo.interfaceFeatures !== 0 ? "Yes" : "No"
  }
  if ("features" in adapterInfo) {
    // Re-check the error capture feature.
    if ((adapterInfo.features & PeekAdapterFeatures.PEEK_ADAPTER_ERROR_CAPTURE) !== 0) {
      errorCapture = "Yes"
    }
    // Check if the adapter supports hardware slicing.
    if ((adapterInfo.features & PeekAdapterFeatures.PEEK_ADAPTER_PACKET_SLICE) !== 0) {
      hardwareFeatures += "Slicing "
    }
    // Check if the adapter supports hardware dedupliation.
    if ((adapterInfo.features & PeekAdapterFeatures.PEEK_ADAPTER_DEDUPLICATION) !== 0) {
      hardwareFeatures += "Deduplication "
    }
    // Check if the adapter supports hardware filtering.
    if ((adapterInfo.features & PeekAdapterFeatures.PEEK_ADAPTER_XYRATEX_FILTER) !== 0) {
      hardwareFeatures += "Filters "
    }
    // Check if the adapter supports hardware stats.
    if ((adapterInfo.features & PeekAdapterFeatures.PEEK_ADAPTER_HARDWARE_STATS) !== 0) {
      hardwareFeatures += "Statistics "
    }
    // Check if the adapter supports pipeline capture mode.
    if ((adapterInfo.features & PeekAdapterFeatures.PEEK_ADAPTER_PIPELINE_CAPTURE) !== 0) {
      hardwareFeatures += "Pipeline "
    }
    hardwareFeatures.trimEnd()
  }
  let hardwareVersion = ""
  if ("versions" in adapterInfo) {
    hardwareVersion = adapterInfo.versions
  }
  let line4 = ""
  if (errorCapture.length > 0) {
    line4 += `Omnipeek API: ${errorCapture}`
  }
  if (hardwareFeatures.length > 0) {
    line4 += `; Features: ${hardwareFeatures}`
  }
  if (hardwareVersion.length > 0) {
    line4 += `; Version: ${hardwareVersion}`
  }

  const content = (
    <>
      {title.length > 0 && adapterInfo.description !== title && <MutedText>{title}</MutedText>}
      {line3.length > 0 && <MutedText>{line3}</MutedText>}
      {line4.length > 0 && <MutedText>{line4}</MutedText>}
    </>
  )
  return content
}

export function getFilterSummary(filterMode: PeekFilterMode, filters: string[]) {
  let filterSummary = ""
  const enabledFilterCount = filters.length
  const accept =
    filterMode === PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_MATCHING_ANY ||
    filterMode === PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_MATCHING_ALL
  const and =
    filterMode === PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_MATCHING_ALL ||
    filterMode === PeekFilterMode.PEEK_FILTER_MODE_REJECT_MATCHING_ALL
  if (enabledFilterCount === 0) {
    filterSummary = "Accept all packets"
  } else if (enabledFilterCount === 1) {
    if (accept) {
      filterSummary = `Accept only packets matching one filter`
    } else {
      filterSummary = `Accept only packets NOT matching one filter`
    }
  } else {
    const count = formatInteger(enabledFilterCount)
    if (and) {
      if (accept) {
        filterSummary = `Accept only packets matching all of ${count} filters`
      } else {
        filterSummary = `Accept only packets NOT matching all of ${count} filters`
      }
    } else {
      if (accept) {
        filterSummary = `Accept only packets matching at least one of ${count} filters`
      } else {
        filterSummary = `Accept only packets NOT matching any of ${count} filters`
      }
    }
  }
  return filterSummary
}

export function getHardwareProfileSummary(
  hardwareProfiles: string[],
  hardwareOptions: HardwareOptions[],
  adapterSupportsDeduplication: boolean
) {
  let hardwareProfilesSummary = ""
  const enabledHardwareProfilesCount = hardwareProfiles.length
  if (enabledHardwareProfilesCount === 0) {
    hardwareProfilesSummary = "Accept all packets"
  } else {
    const hardwareOption = hardwareOptions.find(
      (ho: HardwareOptions) => ho.id === hardwareProfiles[0]
    )
    if (hardwareOption) {
      const disabled =
        "deduplication" in hardwareOption
          ? !adapterSupportsDeduplication && hardwareOption.deduplication
          : false
      if (disabled) {
        hardwareProfilesSummary = "Accept all packets"
      } else {
        hardwareProfilesSummary = hardwareOption.name
        if (hardwareOption.comment.length > 0) {
          hardwareProfilesSummary += ` (${hardwareOption.comment})`
        }
      }
    } else {
      hardwareProfilesSummary = "Accept only packets matching hardware profile"
    }
  }
  return hardwareProfilesSummary
}

export const isAnalysisOptionEnabled = (
  captureOptions: CaptureTemplateProperties | null,
  name: string
) => {
  let enabled = false
  if (captureOptions) {
    const { performanceConfig, pluginsList } = captureOptions
    if (performanceConfig) {
      const option = performanceConfig[name]
      const optionType = typeof option
      if (optionType === "boolean") {
        enabled = option
      } else if (optionType === "number") {
        enabled = option !== 0
      } else if (optionType === "object") {
        enabled = true
      }
    }
    if (name === "compass") {
      if (pluginsList) {
        enabled =
          enabled &&
          pluginsList.properties.findIndex(
            (pobj: PluginObject) => pobj.value === `{${CLSID_COMPASS}}`
          ) !== -1
      } else {
        enabled = false
      }
    }
  }
  return enabled
}

export const isMaximumHardwareProfilesExceeded = (
  engineCapabilities: ResponseGetEngineCapabilities | null,
  captureOptions: CaptureTemplateProperties,
  captures: CaptureProperties[]
) => {
  let maximumHardwareProfilesExceeded = false

  const { adapterSettings, generalSettings, hardwareConfig } = captureOptions

  // is there a limit?
  if (
    engineCapabilities?.maximumHardwareProfiles !== undefined &&
    engineCapabilities.maximumHardwareProfiles > 0
  ) {
    // determine the selected hardware profiles for this capture (if any)
    const hardwareProfiles = new Set(
      Array.isArray(hardwareConfig?.hardwareProfiles) ? hardwareConfig.hardwareProfiles : []
    )

    if (hardwareProfiles.size > 0) {
      // generate a list of unqiue hardware profiles currently in use for the current adapter
      const otherHardwareProfiles = new Set(
        captures
          .filter(
            (capture: CaptureProperties) =>
              getAdapterEnumerator(capture.adapterInfo) === adapterSettings?.enumerator &&
              `{${capture.id}}` !== generalSettings?.captureId &&
              capture.hardwareProfileID !== undefined
          )
          .map((capture: CaptureProperties) => capture.hardwareProfileID)
      )

      // determine the number of hardware profiles that would be in use
      let hardwareProfileCount = otherHardwareProfiles.size
      hardwareProfiles.forEach((id: string) => {
        if (!otherHardwareProfiles.has(id)) {
          hardwareProfileCount++
        }
      })

      // has the limit been exceeded?
      maximumHardwareProfilesExceeded =
        hardwareProfileCount > engineCapabilities.maximumHardwareProfiles
    }
  }

  return maximumHardwareProfilesExceeded
}

const analysisOptions = [
  "analysisModules",
  "applicationStatistics",
  "compass",
  "countryStatistics",
  "errorStatistics",
  "expertAnalysis",
  "mplsVlanVxlanStatistics",
  "networkStatistics",
  "nodeProtocolDetailStatistics",
  "nodeStatistics",
  "passiveNameResolution",
  "protocolStatistics",
  "sizeStatistics",
  "summaryStatistics",
  "topTalkerStatistics",
  "trafficHistoryStatistics",
  "voiceAndVideoAnalysis",
  "webAnalysis",
  "wirelessChannelStatistics",
  "wirelessNodeStatistics",
]

export const anyAnalysisOptionEnabled = (captureOptions: CaptureTemplateProperties | null) => {
  if (captureOptions) {
    for (let i = 0, len = analysisOptions.length; i < len; ++i) {
      if (isAnalysisOptionEnabled(captureOptions, analysisOptions[i])) {
        return true
      }
    }
  }
  return false
}

export const anyAnalysisEnabled = (captureOptions: CaptureTemplateProperties) => {
  if (captureOptions.graphSettings && captureOptions.graphSettings.enabled) {
    return true
  }
  if (captureOptions.statsOutput && captureOptions.statsOutput.enabled) {
    return true
  }
  if (anyAnalysisOptionEnabled(captureOptions)) {
    return true
  }
  if (isAnalysisOptionEnabled(captureOptions, "alarms")) {
    return true
  }
  return false
}

export enum TimeUnits {
  TIME_UNITS_SECONDS = 1,
  TIME_UNITS_MINUTES = 2,
  TIME_UNITS_HOURS = 3,
  TIME_UNITS_DAYS = 4,
}

export const secondsFromUnits = (units: TimeUnits): number => {
  switch (units) {
    case TimeUnits.TIME_UNITS_MINUTES:
      return SECONDS_PER_MINUTE
    case TimeUnits.TIME_UNITS_HOURS:
      return SECONDS_PER_HOUR
    case TimeUnits.TIME_UNITS_DAYS:
      return SECONDS_PER_DAY
    default:
      return 1
  }
}

export const encodeTimeWithUnits = (time: number, units: TimeUnits): number => {
  return time * secondsFromUnits(units)
}

export const decodeTimeWithUnits = (time: number, units: TimeUnits): number => {
  return Math.max(Math.floor(time / secondsFromUnits(units)), 1)
}

export const getCTDMaxFileAgeUnits = (captureOptions: CaptureTemplateProperties): TimeUnits => {
  let timeUnits = TimeUnits.TIME_UNITS_MINUTES
  const { generalSettings } = captureOptions
  if (generalSettings && generalSettings.ctdMaxFileAge !== undefined) {
    const { ctdMaxFileAge } = generalSettings
    if (ctdMaxFileAge >= SECONDS_PER_DAY && ctdMaxFileAge % SECONDS_PER_DAY === 0) {
      timeUnits = TimeUnits.TIME_UNITS_DAYS
    } else if (ctdMaxFileAge >= SECONDS_PER_HOUR && ctdMaxFileAge % SECONDS_PER_HOUR === 0) {
      timeUnits = TimeUnits.TIME_UNITS_HOURS
    }
  }
  return timeUnits
}

export const decodeCTDMaxFileAge = (
  captureOptions: CaptureTemplateProperties,
  units: TimeUnits
): number => {
  const { generalSettings } = captureOptions
  if (generalSettings && generalSettings.ctdMaxFileAge !== undefined) {
    return decodeTimeWithUnits(generalSettings.ctdMaxFileAge, units)
  }
  return SECONDS_PER_DAY
}

export const getCTDRetentionTimeUnits = (captureOptions: CaptureTemplateProperties): TimeUnits => {
  let timeUnits = TimeUnits.TIME_UNITS_MINUTES
  const { generalSettings } = captureOptions
  if (generalSettings && generalSettings.ctdRetentionTime !== undefined) {
    const { ctdRetentionTime } = generalSettings
    if (ctdRetentionTime >= SECONDS_PER_DAY && ctdRetentionTime % SECONDS_PER_DAY === 0) {
      timeUnits = TimeUnits.TIME_UNITS_DAYS
    } else if (ctdRetentionTime >= SECONDS_PER_HOUR && ctdRetentionTime % SECONDS_PER_HOUR === 0) {
      timeUnits = TimeUnits.TIME_UNITS_HOURS
    }
  }
  return timeUnits
}

export const decodeCTDRetentionTime = (
  captureOptions: CaptureTemplateProperties,
  units: TimeUnits
): number => {
  const { generalSettings } = captureOptions
  if (generalSettings && generalSettings.ctdRetentionTime !== undefined) {
    return decodeTimeWithUnits(generalSettings.ctdRetentionTime, units)
  }
  return SECONDS_PER_DAY
}

export const getStorageStats = (
  captureOptions: CaptureTemplateProperties | null,
  engineStatus: ResponseGetStatus,
  originalAllocated: number
) => {
  const storageUsed =
    engineStatus.storageUsed >= originalAllocated
      ? engineStatus.storageUsed - originalAllocated
      : engineStatus.storageUsed
  const storageAvailable =
    engineStatus.storageTotal >= storageUsed
      ? engineStatus.storageTotal - storageUsed
      : engineStatus.storageTotal
  let storageMin = 1
  if (captureOptions?.generalSettings?.ctdFileSize !== undefined) {
    storageMin = Math.max(
      Math.ceil(captureOptions.generalSettings.ctdFileSize / BYTES_PER_GIGABYTE),
      1
    )
  }
  const storageMax = Math.max(Math.floor(storageAvailable / BYTES_PER_GIGABYTE), storageMin)

  return {
    available: storageAvailable,
    max: storageMax,
    min: storageMin,
    used: storageUsed,
  }
}
