import * as React from "react"
import { connect } from "react-redux"
import { v4 as uuid } from "uuid"
import { addBreadcrumb, updateBreadcrumb, removeBreadcrumb } from "../../store/breadcrumbs"

type BreadcrumbItemProps = {
  dispatch: Function
  to: string
  title: string
}

class BreadcrumbItem extends React.Component<BreadcrumbItemProps> {
  id: string = uuid()

  componentDidMount() {
    this.props.dispatch(addBreadcrumb(this.id, this.props.to, this.props.title))
  }

  componentWillUnmount() {
    this.props.dispatch(removeBreadcrumb(this.id))
  }

  componentDidUpdate({ to, title }: BreadcrumbItemProps) {
    if (this.props.to !== to || this.props.title !== title) {
      this.props.dispatch(updateBreadcrumb(this.id, this.props.to, this.props.title))
    }
  }

  render() {
    return null
  }
}

export default connect()(BreadcrumbItem)
