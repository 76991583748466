import styled from "styled-components"
import { darken } from "polished"
import { Alert as ReactstrapAlert } from "reactstrap"

export const Alert = styled(ReactstrapAlert)`
  &.alert-danger {
    color: ${props => props.theme.dangerColor};
    background-color: ${props => props.theme.dangerBackgroundColor};
    border-color: ${props => darken(0.05, props.theme.dangerBackgroundColor)};
  }

  &.alert-warning {
    color: ${props => props.theme.warningColor};
    background-color: ${props => props.theme.warningBackgroundColor};
    border-color: ${props => darken(0.1, props.theme.warningBackgroundColor)};
  }

  &.alert-info {
    color: ${props => props.theme.infoColor};
    background-color: ${props => props.theme.infoBackgroundColor};
    border-color: ${props => darken(0.05, props.theme.infoBackgroundColor)};
  }

  &.alert-success {
    color: ${props => props.theme.successColor};
    background-color: ${props => props.theme.successBackgroundColor};
    border-color: ${props => darken(0.05, props.theme.successBackgroundColor)};
  }

  & .close {
    text-shadow: none;
    line-height: 1.2rem;
  }

  & .close:hover {
    color: ${props => props.theme.textColor} !important;
  }
`

export default Alert
