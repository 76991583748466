import * as React from "react"
import { Col, FormGroup } from "reactstrap"
import { isEqual, toNumber } from "lodash"
import { OptionsLabel } from "./index"
import { CheckGroup, HorizontalFormGroup, SubSection, SubSectionLabel } from "../common/Form"
import { Input } from "../common/Input"
import { MutedText } from "../common/MutedText"
import { AdapterConfiguration } from "../../api/types"
import { formatLinkSpeed } from "../../utils/formatUtils"

const NETWORK_SPEED_MIN: number = 0
const NETWORK_SPEED_MAX: number = 100000000000

type NetworkSpeedFormProps = {
  adapterConfig: AdapterConfiguration
  setAdapterConfig: (adapterConfig: AdapterConfiguration) => void
}

type NetworkSpeedFormState = {
  autoLinkSpeed: boolean
  linkSpeed: number
}

export class NetworkSpeedForm extends React.Component<
  NetworkSpeedFormProps,
  NetworkSpeedFormState
> {
  state: NetworkSpeedFormState = {
    autoLinkSpeed: this.props.adapterConfig.linkSpeed === 0,
    linkSpeed: this.props.adapterConfig.linkSpeed,
  }

  shouldComponentUpdate(
    { adapterConfig }: NetworkSpeedFormProps,
    { autoLinkSpeed, linkSpeed }: NetworkSpeedFormState
  ) {
    return (
      !isEqual(this.props.adapterConfig, adapterConfig) ||
      this.state.autoLinkSpeed !== autoLinkSpeed ||
      this.state.linkSpeed !== linkSpeed
    )
  }

  onChangeNetworkSpeed = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { adapterConfig } = this.props
    const { linkSpeed } = this.state
    const { id } = event.target

    this.props.setAdapterConfig({
      ...adapterConfig,
      linkSpeed: id === "networkSpeedAutoSense" ? 0 : linkSpeed * 1000,
    })
    this.setState({ autoLinkSpeed: id === "networkSpeedAutoSense" })
  }

  onChangeNetworkSpeedValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { adapterConfig } = this.props

    const value = toNumber(event.target.value)
    if (value >= NETWORK_SPEED_MIN && value <= NETWORK_SPEED_MAX) {
      this.props.setAdapterConfig({
        ...adapterConfig,
        linkSpeed: value * 1000,
      })
      this.setState({ linkSpeed: value * 1000 })
    }
  }

  render() {
    const { autoLinkSpeed, linkSpeed } = this.state
    const { adapterConfig } = this.props

    return (
      <FormGroup row noMargin>
        <OptionsLabel md="3" for="networkSpeedAutoSense">
          <SubSectionLabel>Network Speed</SubSectionLabel>
        </OptionsLabel>
        <Col md="7">
          <SubSection>
            <HorizontalFormGroup>
              <CheckGroup
                type="radio"
                name="networkSpeedAutoSense"
                id="networkSpeedAutoSense"
                aria-label="Normal"
                checked={autoLinkSpeed}
                onChange={this.onChangeNetworkSpeed.bind(this)}
              >
                Auto Sense ({formatLinkSpeed(adapterConfig.defaultLinkSpeed)})
              </CheckGroup>
            </HorizontalFormGroup>
            <HorizontalFormGroup>
              <CheckGroup
                type="radio"
                name="networkSpeedOther"
                id="networkSpeedOther"
                aria-label="Normal"
                checked={!autoLinkSpeed}
                onChange={this.onChangeNetworkSpeed.bind(this)}
              >
                Other (kbits/s):
              </CheckGroup>
              <Input
                type="number"
                name="networSpeedOtherValue"
                id="networSpeedOtherValue"
                aria-label="Link Speed"
                value={linkSpeed / 1000}
                onChange={this.onChangeNetworkSpeedValue.bind(this)}
                disabled={autoLinkSpeed}
                min={NETWORK_SPEED_MIN}
                max={NETWORK_SPEED_MAX}
                step={1}
              />
            </HorizontalFormGroup>
            <HorizontalFormGroup noMargin>
              <MutedText as="div">
                Changing the network speed does not change the adapter speed but does impact certain
                analysis.
              </MutedText>
            </HorizontalFormGroup>
          </SubSection>
        </Col>
      </FormGroup>
    )
  }
}
