import * as React from "react"
import cn from "classnames"
import { useDispatch, useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import FileSaver from "file-saver"
import FontAwesome from "react-fontawesome"
import { groupBy, toNumber } from "lodash"
import { SortDirection, SortDirectionType, TableCellProps, TableRowProps } from "react-virtualized"
import { useHistory } from "react-router-dom"
import { v4 as uuid } from "uuid"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { Alert } from "../common/Alert"
import { CenterContent } from "../common/Layout"
import { CellCheckGroup, CellCheckGroupIndeterminate } from "../common/Form"
import { ConfirmationModal } from "../common/ConfirmationModal"
import { IconEngine } from "../common/Icons"
import { FilterBox } from "../common/FilterBox"
import { LightButton, LightDangerButton, FileInputButton } from "../common/Buttons"
import { MutedText } from "../common/MutedText"
import { OmniTable } from "../common/OmniTable"
import { Spinner } from "../common/Spinner"
import { View, ViewContent, ViewHeader, ViewHeaderTitle, ViewHeaderButtons } from "../common/View"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import EngineModal from "../EngineModal"
import { getEnginesUrl, getEngineHomeUrl } from "../../routes"
import {
  getServer,
  getServerAuthToken,
  getEngines,
  getEnginesFilter,
  getEnginesSortBy,
  getEnginesSortDirection,
  getEnginesCollapsedGroups,
  getEnginesChecked,
} from "../../store"
import { setCurrentEngine, updateEngines } from "../../store/engines"
import {
  setEnginesFilter,
  setEnginesSort,
  setEnginesCollapsedGroups,
  setEnginesChecked,
} from "../../store/ui"
import { collator } from "../../utils/sortUtils"
import { formatInteger } from "../../utils/formatUtils"
import {
  getEngineDisplayName,
  isUsingGroupAuthentication,
  GROUP_AUTHENTICATION_FLAG,
} from "../../utils/engineUtils"
import * as API from "../../api/api"
import { Engine } from "../../api/types"

// "auth" types in .omc files
//const AUTH_TYPE_DEFAULT = "Default"
const AUTH_TYPE_GROUP = "Group"
const AUTH_TYPE_THIRD_PARTY = "ThirdPartyAuth"

const columnDesc = [
  {
    dataKey: "checked",
    label: "",
    width: 28,
    flexShrink: 0,
    disableSort: true,
    className: "fullsize",
    headerClassName: "fullsize",
  },
  {
    dataKey: "name",
    label: "Name",
    width: 250,
    flexGrow: 1,
  },
  {
    dataKey: "host",
    label: "Host",
    width: 250,
  },
]

type EngineGroup = {
  name: string
  children: Engine[]
}

type EngineRowData = Engine | EngineGroup

function rowDataToId(rowData: EngineRowData) {
  const rawId = (rowData as Engine).id || rowData.name
  const id = rawId.replace(" ", "_")
  return "id:" + id
}

function isGroupRowData(rowData: EngineRowData) {
  return (rowData as EngineGroup).children != null
}

const EnginesView = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const viewFilter = useSelector(getEnginesFilter) || ""
  const sortBy = (useSelector(getEnginesSortBy) as string) || "name"
  const sortDirection: SortDirectionType = useSelector(getEnginesSortDirection) || SortDirection.ASC
  const collapsedGroups: Set<string> = useSelector(getEnginesCollapsedGroups) || new Set<string>()
  const checked: Set<string> = useSelector(getEnginesChecked) || new Set<string>()

  const engines = useSelector(getEngines)
  const [error, setError] = React.useState<any | null>(null)
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false)
  const [showDeleteAllBeforeImportConfirm, setShowDeleteAllBeforeImportConfirm] =
    React.useState(false)
  const [showEngineModal, setShowEngineModal] = React.useState(false)
  const [editEngine, setEditEngine] = React.useState<Engine | null>(null)
  const [importFile, setImportFile] = React.useState<string | null>(null)

  const getViewEngines = (): [Engine[] | null, Engine[]] => {
    let viewEngines = engines
    if (viewEngines != null && viewFilter.length > 0) {
      const lowerCaseFilter = viewFilter.toLowerCase()
      viewEngines = viewEngines.filter(engine => {
        return (
          getEngineDisplayName(engine).toLowerCase().indexOf(lowerCaseFilter) !== -1 ||
          engine.host.toLowerCase().indexOf(lowerCaseFilter) !== -1
        )
      })
    }
    const checkedEngines: Engine[] = []
    if (viewEngines) {
      for (const engine of viewEngines) {
        if (checked.has(engine.id)) {
          checkedEngines.push(engine)
        }
      }
    }
    return [viewEngines, checkedEngines]
  }
  const [viewEngines, checkedEngines] = getViewEngines()

  const getGroups = (): string[] => {
    // Use the unfiltered list of engines to get the list of groups.
    if (engines) {
      const groups = new Set<string>()
      for (const engine of engines) {
        if (engine.group) {
          groups.add(engine.group)
        }
      }
      return Array.from(groups).sort()
    }
    return []
  }

  const onRefresh = () => {
    dispatch(updateEngines())
  }

  const onChangeFilter = (filter: string) => {
    dispatch(setEnginesFilter(filter))
  }

  const onImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const file = event.target.files[0]
    if (!file) return
    const reader = new FileReader()
    reader.onerror = (e: any) => {
      setError("Error reading file")
    }
    reader.onload = (e: any) => {
      if (e.target.result) {
        setImportFile(e.target.result)
        setShowDeleteAllBeforeImportConfirm(true)
      }
    }
    reader.readAsText(file)

    // Reset the file input so the same file can be used again
    event.target.value = ""
  }

  const onImportExecute = () => {
    try {
      if (importFile) {
        const domParser = new DOMParser()
        const dom = domParser.parseFromString(importFile, "application/xml")
        if (dom.documentElement) {
          const engines: Engine[] = []

          const loadEngine = (group: string, elem: Element) => {
            const remoteName = elem.getAttribute("remote-name") || ""
            const name = elem.getAttribute("name") || ""
            const uuid = elem.getAttribute("id") || ""
            const host = elem.getAttribute("address") || ""
            const auth = elem.getAttribute("auth") || ""
            const username = elem.getAttribute("user") || ""
            const lastLogin = elem.getAttribute("lastlogin") || undefined
            const latitude = elem.getAttribute("latitude")
            const longitude = elem.getAttribute("longitude")
            engines.push({
              id: uuid.replace("{", "").replace("}", ""),
              group,
              name,
              remoteName,
              host,
              username,
              password: auth === AUTH_TYPE_GROUP ? GROUP_AUTHENTICATION_FLAG : "",
              lastLogin,
              latitude: latitude != null ? toNumber(latitude) : undefined,
              longitude: latitude != null ? toNumber(longitude) : undefined,
            })
          }

          const loadNode = (group: string, elem: Element) => {
            for (let i = 0, len = elem.children.length; i < len; ++i) {
              const childNode = elem.children[i]
              if (childNode.nodeName === "node") {
                const group = childNode.getAttribute("name") || ""
                loadNode(group, childNode)
              } else if (childNode.nodeName === "engine") {
                loadEngine(group, childNode)
              }
            }
          }

          loadNode("", dom.documentElement)

          for (const engine of engines) {
            API.postEngine(server, serverAuthToken, engine)
              .then(() => {
                onRefresh()
              })
              .catch(error => {
                console.error(error)
                setError("Errors importing engines")
              })
          }
        }
      }
    } catch (error) {
      setError(error)
    }
    setImportFile(null)
  }

  const onDeleteAllBeforeImportCancel = () => {
    setShowDeleteAllBeforeImportConfirm(false)
    onImportExecute()
  }

  const onDeleteAllBeforeImportOK = () => {
    setShowDeleteAllBeforeImportConfirm(false)
    API.deleteAllEngines(server, serverAuthToken)
      .then(() => {
        onImportExecute()
      })
      .catch(error => {
        setError(error)
      })
  }

  const onExport = () => {
    try {
      const doc = document.implementation.createDocument(null, "omc-document", null)
      const pi = doc.createProcessingInstruction("xml", 'version="1.0" encoding="utf-8"')
      doc.insertBefore(pi, doc.firstChild)
      doc.documentElement.setAttribute("version", "1")
      doc.documentElement.setAttribute("id", `{${uuid().toUpperCase()}}`)
      for (const engineGroup of Object.values(groupBy(engines, "group"))) {
        if (engineGroup.length > 0) {
          let node: Element | null = null
          if (engineGroup[0].group.length !== 0) {
            node = doc.createElement("node")
            node.setAttribute("id", `{${uuid().toUpperCase()}}`)
            node.setAttribute("name", engineGroup[0].group)
            node.setAttribute("open", "1")
          } else {
            node = doc.documentElement
          }
          for (const engine of engineGroup) {
            const engineNode = doc.createElement("engine")
            // port, domain, and password will be omitted
            engineNode.setAttribute("id", `{${engine.id.toUpperCase()}}`)
            engineNode.setAttribute("name", engine.name)
            engineNode.setAttribute("remote-name", engine.remoteName)
            engineNode.setAttribute("address", engine.host)
            engineNode.setAttribute(
              "auth",
              isUsingGroupAuthentication(engine) ? AUTH_TYPE_GROUP : AUTH_TYPE_THIRD_PARTY
            )
            engineNode.setAttribute("user", engine.username)
            if (engine.lastLogin != null) {
              engineNode.setAttribute("lastlogin", engine.lastLogin)
            }
            if (engine.latitude != null) {
              engineNode.setAttribute("latitude", engine.latitude.toString())
            }
            if (engine.longitude != null) {
              engineNode.setAttribute("longitude", engine.longitude.toString())
            }
            node.appendChild(engineNode)
          }
          if (node !== doc.documentElement) {
            doc.documentElement.appendChild(node)
          }
        }
      }
      const serializer = new XMLSerializer()
      const xml = serializer.serializeToString(doc)
      FileSaver.saveAs(new Blob([xml], { type: "text/xml;charset=utf8" }), "Engines.omc")
    } catch (error) {
      console.error(error)
      setError("Error exporting the engine list")
    }
  }

  const onInsert = () => {
    setShowEngineModal(true)
  }

  const onEdit = () => {
    if (checkedEngines.length === 1) {
      setEditEngine(checkedEngines[0])
      setShowEngineModal(true)
    } else {
      // TODO: multi-edit
    }
  }

  const onEditOK = (engine: Engine) => {
    if (serverAuthToken) {
      if (editEngine) {
        API.putEngine(server, serverAuthToken, engine)
          .then(() => {
            onRefresh()
          })
          .catch(error => {
            console.error(error)
          })
      } else {
        API.postEngine(server, serverAuthToken, engine)
          .then(() => {
            onRefresh()
          })
          .catch(error => {
            console.error(error)
          })
      }
    }
    setShowEngineModal(false)
  }

  const onEditCancel = () => {
    setEditEngine(null)
    setShowEngineModal(false)
  }

  const onDelete = () => {
    if (checkedEngines.length > 0) {
      setShowDeleteConfirm(true)
    }
  }

  const onDeleteCancel = () => {
    setShowDeleteConfirm(false)
  }

  const onDeleteOK = () => {
    setShowDeleteConfirm(false)
    const requests: any[] = []
    for (const engine of checkedEngines) {
      requests.push(API.deleteEngine(server, serverAuthToken, engine))
    }
    if (requests.length > 0) {
      Promise.all(requests)
        .then(() => {
          onRefresh()
        })
        .catch(error => {
          setError(error)
        })
    }
  }

  const onExpandCollapse = (group: string) => {
    const newCollapsedGroups = new Set(collapsedGroups)
    if (newCollapsedGroups.has(group)) {
      newCollapsedGroups.delete(group)
    } else {
      newCollapsedGroups.add(group)
    }
    dispatch(setEnginesCollapsedGroups(newCollapsedGroups))
  }

  const onSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
  }) => {
    dispatch(setEnginesSort(sortBy, sortDirection))
  }

  const getFlattenedTree = () => {
    if (viewEngines) {
      // Build hierarchical representation
      const groups: EngineGroup[] = []
      viewEngines.forEach(engine => {
        const group = groups.find(group => collator.compare(group.name, engine.group) === 0)
        if (group) {
          group.children.push(engine)
        } else {
          groups.push({
            name: engine.group,
            children: [engine],
          })
        }
      })

      // Sort top level groups by name
      groups.sort((a, b) => {
        let result = 0
        if (a.name === "") {
          result = 1
        } else if (b.name === "") {
          result = -1
        } else {
          result = collator.compare(a.name, b.name)
        }
        if (sortDirection === SortDirection.DESC) result = -result
        return result
      })

      // Sort children
      groups.forEach(group => {
        // Only need to sort if top level or expanded
        if (group.name.length === 0 || !collapsedGroups.has(group.name)) {
          group.children.sort((a: Engine, b: Engine) => {
            let result = 0
            switch (sortBy) {
              case "name":
                result = collator.compare(getEngineDisplayName(a), getEngineDisplayName(b))
                if (result === 0) {
                  result = collator.compare(a.host, b.host)
                }
                break
              case "host":
                result = collator.compare(a.host, b.host)
                break
            }
            if (sortDirection === SortDirection.DESC) result = -result
            return result
          })
        }
      })

      // Flatten the list into rows
      const rows: EngineRowData[] = []
      groups.forEach(group => {
        if (group.name) {
          rows.push(group)
          const collapsed = collapsedGroups.has(group.name)
          if (!collapsed) {
            group.children.forEach((item: any, index: number) => {
              rows.push({ ...item, childIndex: index })
            })
          }
        } else {
          group.children.forEach((item: any, index: number) => {
            rows.push({ ...item, childIndex: index })
          })
        }
      })

      return rows
    }
    return null
  }

  const onRowClick = ({
    rowData,
    event,
  }: {
    rowData: any
    event: React.MouseEvent<HTMLElement>
  }) => {
    // Exclude clicks on checkboxes.
    const tagName = (event?.target as HTMLElement)?.tagName
    if (tagName === "LABEL" || tagName === "INPUT") {
      return
    }
    if (isGroupRowData(rowData)) {
      onExpandCollapse(rowData.name)
    } else {
      const engine = rowData as Engine
      dispatch(setCurrentEngine(engine.id))
      history.push(getEngineHomeUrl())
    }
  }

  const rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    if (isGroupRowData(rowData)) {
      className = cn(className, "group", "clickable")
      if (Array.isArray(columns) && columns.length > 2) {
        columns = columns.slice(0, 2) // Only need the first 2 columns
      }
      key = rowData.name
    } else {
      if (rowData.childIndex != null && rowData.childIndex % 2 !== 0) {
        className = cn(className, "stripe", "clickable")
      } else {
        className = cn(className, "clickable")
      }
      key = rowData.id
    }

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  const rowClassName = () => {
    // Stripes applied in rowRenderer
    return ""
  }

  const isChecked = (filter: Engine) => {
    return checked.has(filter.id)
  }

  const toggleCheck = (filter: Engine) => {
    const newChecked = new Set<string>(checked)
    if (newChecked.has(filter.id)) {
      newChecked.delete(filter.id)
    } else {
      newChecked.add(filter.id)
    }
    dispatch(setEnginesChecked(newChecked))
  }

  const checkedHeaderRenderer = () => {
    let checkState = 0
    if (viewEngines != null && checkedEngines.length > 0) {
      if (viewEngines.length === checkedEngines.length) {
        checkState = 1
      } else {
        checkState = 2
      }
    }
    return (
      <CellCheckGroupIndeterminate
        type="checkbox"
        value={checkState}
        id="check-all"
        onChange={() => {
          if (viewEngines != null) {
            const newChecked = new Set<string>()
            if (checkState !== 1) {
              for (const filter of viewEngines) {
                newChecked.add(filter.id)
              }
            }
            dispatch(setEnginesChecked(newChecked))
          }
        }}
      />
    )
  }

  const cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content
    const isGroup = isGroupRowData(rowData)
    switch (dataKey) {
      case "checked":
        if (isGroup) {
          let checkCount = 0
          for (const filter of rowData.children) {
            if (isChecked(filter)) {
              checkCount++
            }
          }
          let checkState = 0
          if (checkCount === rowData.children.length) {
            checkState = 1
          } else if (checkCount > 0) {
            checkState = 2
          }
          content = (
            <CellCheckGroupIndeterminate
              type="checkbox"
              value={checkState}
              id={rowDataToId(rowData)}
              onChange={() => {
                const newChecked = new Set<string>(checked)
                if (checkState === 1) {
                  for (const filter of rowData.children) {
                    newChecked.delete(filter.id)
                  }
                } else {
                  for (const filter of rowData.children) {
                    newChecked.add(filter.id)
                  }
                }
                dispatch(setEnginesChecked(newChecked))
              }}
            />
          )
        } else {
          content = (
            <CellCheckGroup
              type="checkbox"
              checked={isChecked(rowData as Engine)}
              id={rowDataToId(rowData)}
              onChange={() => {
                toggleCheck(rowData as Engine)
              }}
            />
          )
        }
        break
      case "name":
        if (isGroup) {
          const collapsed = collapsedGroups.has(cellData)
          const icon = collapsed ? "chevron-right" : "chevron-down"
          const iconFolder = collapsed ? "folder-o" : "folder-open-o"
          content = (
            <>
              <FontAwesome name={icon} fixedWidth />
              <span style={{ paddingLeft: ".25em" }} title={cellData}>
                <FontAwesome name={iconFolder} fixedWidth style={{ marginRight: "0.25em" }} />
                {cellData} <MutedText>{`(${formatInteger(rowData.children.length)})`}</MutedText>
              </span>
            </>
          )
        } else {
          const name = getEngineDisplayName(rowData)
          const paddingLeft = rowData.group ? "3.0714em" : "1.5357em"
          content = (
            <span style={{ display: "flex", alignItems: "center", paddingLeft }} title={name}>
              <IconEngine />
              <span style={{ paddingLeft: ".25em" }}>{name}</span>
            </span>
          )
        }
        break
      case "host":
        if (!isGroup) {
          content = cellData
        }
        break
      default:
        break
    }
    return content
  }

  // Get the total count before filtering.
  const count = engines !== null ? engines.length : 0

  // Build the flattened tree for the table.
  const flattenedTree = getFlattenedTree()

  // Add the checkbox header renderer to the columns.
  const columns: any[] = [...columnDesc]
  const checkedColumn = columns.find(col => col.dataKey === "checked")
  if (checkedColumn) {
    checkedColumn.headerRenderer = checkedHeaderRenderer
  }

  return (
    <View>
      <Helmet title="Engines" />
      <BreadcrumbItem to={getEnginesUrl()} title="Engines" />
      {error && (
        <Alert color="danger" toggle={() => setError("")}>
          {typeof error === "string" ? error : `${error.code} ${error.reason}`}
        </Alert>
      )}
      <ViewHeader>
        <ViewHeaderTitle title="Engines" count={count} />
        <ViewHeaderButtons>
          <FileInputButton
            aria-label="Import"
            id="import"
            accept=".omc,application/xml"
            onChange={onImport}
          >
            <FontAwesome name="upload" />
          </FileInputButton>
          <UncontrolledTooltip placement="top" target="import">
            Import
          </UncontrolledTooltip>
          <LightButton aria-label="Export" id="export" onClick={onExport}>
            <FontAwesome name="download" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="export">
            Export
          </UncontrolledTooltip>
          <LightButton aria-label="Refresh" id="refresh" onClick={onRefresh}>
            <FontAwesome name="refresh" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="refresh">
            Refresh
          </UncontrolledTooltip>
        </ViewHeaderButtons>
      </ViewHeader>
      {viewEngines ? (
        <>
          <ViewHeaderButtons style={{ marginBottom: "4px" }}>
            <div>
              <FilterBox
                aria-label="Search"
                placeholder="Search"
                onChange={onChangeFilter}
                value={viewFilter}
              />
            </div>
            <LightButton onClick={onInsert}>
              <FontAwesome name="plus" /> Insert
            </LightButton>
            <LightButton disabled={checkedEngines.length !== 1} id="edit-selected" onClick={onEdit}>
              <FontAwesome name="pencil" /> Edit
            </LightButton>
            <LightDangerButton
              disabled={checkedEngines.length === 0}
              id="delete-selected"
              onClick={onDelete}
            >
              <FontAwesome name="trash-o" /> Delete
            </LightDangerButton>
          </ViewHeaderButtons>
          <ViewContent>
            {flattenedTree && (
              <OmniTable
                data={flattenedTree}
                rowCount={flattenedTree.length}
                columnDesc={columns}
                rowRenderer={rowRenderer}
                rowClassName={rowClassName}
                cellRenderer={cellRenderer}
                onRowClick={onRowClick}
                sort={onSort}
                sortBy={sortBy}
                sortDirection={sortDirection}
              />
            )}
          </ViewContent>
        </>
      ) : (
        <CenterContent>
          <Spinner />
        </CenterContent>
      )}
      {showEngineModal && (
        <EngineModal
          engine={editEngine}
          groups={getGroups()}
          onOK={onEditOK}
          onCancel={onEditCancel}
        />
      )}
      {showDeleteConfirm && (
        <ConfirmationModal
          message={
            checkedEngines.length === 1
              ? `Delete the engine \u201c${getEngineDisplayName(checkedEngines[0])}\u201d?`
              : `Delete ${formatInteger(checkedEngines.length)} engines?`
          }
          onNo={onDeleteCancel}
          onYes={onDeleteOK}
          show={showDeleteConfirm}
          title="Delete Engine"
        />
      )}
      {showDeleteAllBeforeImportConfirm && (
        <ConfirmationModal
          message={"Delete all engines before importing?"}
          onNo={onDeleteAllBeforeImportCancel}
          onYes={onDeleteAllBeforeImportOK}
          show={showDeleteAllBeforeImportConfirm}
          title="Delete all Engines"
        />
      )}
    </View>
  )
}

export default EnginesView
