import styled from "styled-components"
import { Table, TableProps } from "react-virtualized"

export const VirtualTable = styled(Table)<TableProps>`
  & .ReactVirtualized__Table__Grid {
    background-color: ${props => props.theme.tableBackgroundColor};
    border: ${props => props.theme.tableBorder};
  }

  & .ReactVirtualized__Table__Grid:focus {
    outline: none;
  }

  & .ReactVirtualized__Table__headerColumn {
    margin: 0;
    padding: 0.25rem;
    display: flex;
    align-items: center;
  }

  & .ReactVirtualized__Table__headerColumn.fullsize {
    padding: 0;
    height: inherit;
  }

  & .ReactVirtualized__Table__headerColumn.right {
    justify-content: flex-end;
  }

  & .ReactVirtualized__Table__headerColumn.center {
    justify-content: center;
  }

  & .ReactVirtualized__Table__sortableHeaderColumn {
    outline: none;
    user-select: none;
  }

  & .ReactVirtualized__Table__headerRow {
    margin: 0;
    border-style: solid;
    border-width: ${props => props.theme.tableHeaderBorderWidth};
    border-color: ${props => props.theme.tableHeaderBorderColor};
    background-color: ${props => props.theme.tableHeaderBackgroundColor};
    color: ${props => props.theme.tableHeaderColor};
    font-size: ${props => props.theme.tableHeaderFontSize};
    font-weight: bold;
    text-transform: ${props => props.theme.tableHeaderTextTransform};

    & .dropdown button {
      color: ${props => props.theme.tableHeaderColor} !important;
    }

    & .dropdown button > .fa {
      opacity: 1;
    }
  }

  & .ReactVirtualized__Table__headerTruncatedText {
    vertical-align: middle;
  }

  & .ReactVirtualized__Table__row {
    outline: none;
    margin: 0;
    border-bottom: 1px solid ${props => props.theme.tableGridColor};
    background-color: ${props => props.theme.tableBackgroundColor};
  }

  & .ReactVirtualized__Table__row.stripe {
    background-color: ${props => props.theme.tableStripeColor};
  }

  & .ReactVirtualized__Table__row:hover {
    background-color: ${props => props.theme.tableHoverColor};
  }

  & .ReactVirtualized__Table__row.group {
    background-color: ${props => props.theme.tableGroupBackgroundColor};
  }

  & .ReactVirtualized__Table__row.marked {
    background-color: ${props => props.theme.markedBackgroundColor};
  }

  & .ReactVirtualized__Table__row.selected {
    color: ${props => props.theme.selectedColor};
    background-color: ${props => props.theme.selectedBackgroundColor};
  }

  & .ReactVirtualized__Table__row.clickable {
    cursor: pointer;
  }

  & .ReactVirtualized__Table__rowColumn {
    margin: 0;
    padding: 0.25rem;
  }

  & .ReactVirtualized__Table__rowColumn.fullsize {
    padding: 0;
    height: inherit;
  }

  & .ReactVirtualized__Table__rowColumn.right {
    text-align: right;
  }

  & .ReactVirtualized__Table__rowColumn.center {
    text-align: center;
  }

  & .ReactVirtualized__Table__row .commands {
    visibility: hidden;
  }

  /* The following rule doesn't work in Edge when
     combined with the one below for some reason */
  & .ReactVirtualized__Table__row:hover .commands {
    visibility: visible;
  }

  & .ReactVirtualized__Table__row:focus-within .commands,
  & .ReactVirtualized__Table__row .commands:focus-within {
    visibility: visible;
  }

  & .ReactVirtualized__Table__sortableHeaderIcon {
    flex: 0 0 18px;
    height: inherit;
    width: inherit;
  }
`
