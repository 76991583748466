import * as React from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import { getLoginUrl } from "../../routes"
import { getServer, getServerAuthToken } from "../../store"
import { serverLogOut } from "../../store/auth"

type LogoutProps = {
  dispatch: Function
  server: string
  serverAuthToken: string | undefined
}

class Logout extends React.Component<LogoutProps> {
  componentDidMount() {
    const { server, serverAuthToken, dispatch } = this.props
    if (server && serverAuthToken) {
      dispatch(serverLogOut(server, { authToken: serverAuthToken }))
    }
  }

  render() {
    return <Redirect to={getLoginUrl()} />
  }
}

const mapStateToProps = (state: object) => ({
  server: getServer(state),
  serverAuthToken: getServerAuthToken(state),
})

export default connect(mapStateToProps)(Logout)
