import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import FileSaver from "file-saver"
import FontAwesome from "react-fontawesome"
import { AutoSizer } from "react-virtualized"
import CopyToClipboard from "react-copy-to-clipboard"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { LightButton, LightDropdownToggle } from "../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../common/Dropdown"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import ConfirmationModal from "../common/ConfirmationModal"
import { View, ViewContent, ViewHeader, ViewHeaderButtons, ViewHeaderTitle } from "../common/View"
import { getAuthToken, getCapabilities, getEngine } from "../../store"
import { maintainDatabase, performDiagnostics } from "../../api/api"
import { DiagnosticCommand, ResponseGetEngineCapabilities } from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { getDefaultAdminUrl } from "../../utils/engineUtils"

const diagnosticTypes = [
  { name: "Default", command: "default" },
  { name: "RAID", command: "raid" },
  { name: "Drives", command: "drives" },
  { name: "Processes", command: "processes" },
  { name: "System Log", command: "system-log" },
]

const DiagnosticViewDiv = styled.pre`
  margin: 0;
  padding: 8px;
  font-size: 1rem;
  tab-size: 2;
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.tableBackgroundColor};
  border: ${props => props.theme.tableBorder};
  overflow: auto;
`

type DiagnosticsViewProps = RouteComponentProps & {
  engine: string
  authToken: string
  engineCapabilities: ResponseGetEngineCapabilities | null
}

type DiagnosticsViewState = {
  diagnosticInfo: string
  diagnosticTypeIndex: number
  showDatabaseMaintenanceConfirm: boolean
  showDiagnosticsConfirm: boolean
  fetchPending: boolean
  fetchError: any | null
}

class DiagnosticsView extends React.Component<DiagnosticsViewProps, DiagnosticsViewState> {
  state: DiagnosticsViewState = {
    diagnosticInfo: "",
    diagnosticTypeIndex: 0,
    showDatabaseMaintenanceConfirm: false,
    showDiagnosticsConfirm: false,
    fetchPending: false,
    fetchError: null,
  }

  onDatabaseMaintenance = () => {
    this.setState({ showDatabaseMaintenanceConfirm: true })
  }

  onDatabaseMaintenanceConfirmNo = () => {
    this.setState({ showDatabaseMaintenanceConfirm: false })
  }

  onDatabaseMaintenanceConfirmYes = () => {
    this.setState({ showDatabaseMaintenanceConfirm: false, fetchPending: true, fetchError: null })

    const { engine, authToken } = this.props
    maintainDatabase(engine, authToken)
      .then((diagnosticInfo: string) => {
        this.setState({ diagnosticInfo, fetchPending: false })
      })
      .catch(error => {
        this.setState({ fetchPending: false, fetchError: error })
      })
  }

  onDiagnosticTypeChanged = (diagnosticTypeIndex: number) => {
    this.setState({ diagnosticTypeIndex })
  }

  onDiagnostics = () => {
    this.setState({ showDiagnosticsConfirm: true })
  }

  onDiagnosticsConfirmNo = () => {
    this.setState({ showDiagnosticsConfirm: false })
  }

  onDiagnosticsConfirmYes = () => {
    this.setState({ showDiagnosticsConfirm: false, fetchPending: true, fetchError: null })

    const { diagnosticTypeIndex } = this.state
    if (diagnosticTypeIndex < diagnosticTypes.length) {
      const { engine, authToken } = this.props
      performDiagnostics(
        engine,
        authToken,
        diagnosticTypes[diagnosticTypeIndex].command as DiagnosticCommand
      )
        .then((diagnosticInfo: string) => {
          this.setState({ diagnosticInfo, fetchPending: false })
        })
        .catch(error => {
          this.setState({ fetchPending: false, fetchError: error })
        })
    }
  }

  onSave = () => {
    FileSaver.saveAs(
      new Blob([this.state.diagnosticInfo], { type: "text/plain;charset=utf8" }),
      "DiagnosticInformation.txt"
    )
  }

  render() {
    const { engineCapabilities } = this.props
    const {
      diagnosticInfo,
      diagnosticTypeIndex,
      showDatabaseMaintenanceConfirm,
      showDiagnosticsConfirm,
      fetchPending,
    } = this.state

    if (
      !engineCapabilities?.capabilities.includes(EngineCapabilities.diagnosticsInfo) ||
      !engineCapabilities?.userRights.policies.includes(EngineUserPolicies.configureEngine)
    ) {
      return <Redirect to={`${getDefaultAdminUrl(engineCapabilities)}`} />
    }

    // sanity test
    if (diagnosticTypeIndex >= diagnosticTypes.length) {
      return null
    }

    const diagnosticOptions = diagnosticTypes.map((diagnosticType, index) => {
      return (
        <DropdownItem
          active={diagnosticTypeIndex === index}
          key={index}
          onClick={this.onDiagnosticTypeChanged.bind(this, index)}
        >
          {diagnosticType.name}
        </DropdownItem>
      )
    })

    return (
      <View>
        <Helmet title="Diagnostics" />
        <BreadcrumbItem to={this.props.match.url} title="Diagnostics" />
        <ViewHeader>
          <ViewHeaderTitle title="Diagnostics" />
          <ViewHeaderButtons>
            <UncontrolledDropdown>
              <LightDropdownToggle caret disabled={fetchPending}>
                {diagnosticTypes[diagnosticTypeIndex].name}
              </LightDropdownToggle>
              <DropdownMenu>{diagnosticOptions}</DropdownMenu>
            </UncontrolledDropdown>
            <LightButton
              aria-label="Start diagnostics"
              id="diagnostics"
              disabled={fetchPending}
              onClick={this.onDiagnostics}
            >
              <FontAwesome name="play" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="diagnostics">
              Start Diagnostics
            </UncontrolledTooltip>
            <LightButton
              aria-label="Database maintenance"
              id="database-maintenance"
              disabled={fetchPending}
              onClick={this.onDatabaseMaintenance}
            >
              <FontAwesome name="database" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="database-maintenance">
              Database Maintenance
            </UncontrolledTooltip>
            <LightButton
              aria-label="Download"
              id="download"
              disabled={!diagnosticInfo}
              onClick={this.onSave}
            >
              <FontAwesome name="download" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="download">
              Download
            </UncontrolledTooltip>
            <CopyToClipboard text={diagnosticInfo}>
              <LightButton aria-label="Copy to clipboard" id="copy" disabled={!diagnosticInfo}>
                <FontAwesome name="copy" />
              </LightButton>
            </CopyToClipboard>
            <UncontrolledTooltip placement="top" target="copy">
              Copy
            </UncontrolledTooltip>
          </ViewHeaderButtons>
        </ViewHeader>
        <ViewContent>
          <AutoSizer>
            {({ height, width }) => (
              <DiagnosticViewDiv style={{ width, height }}>{diagnosticInfo}</DiagnosticViewDiv>
            )}
          </AutoSizer>
        </ViewContent>
        {showDatabaseMaintenanceConfirm && (
          <ConfirmationModal
            message="Performing database maintenance can take a long time, and while it is running, your capture performance will degrade, and your engine will be unresponsive. Are you sure you want to run database maintenance now?"
            onNo={this.onDatabaseMaintenanceConfirmNo}
            onYes={this.onDatabaseMaintenanceConfirmYes}
            show={showDatabaseMaintenanceConfirm}
            title="Database Maintenance"
          />
        )}
        {showDiagnosticsConfirm && (
          <ConfirmationModal
            message="Running diagnostics while capturing may adversely affect capture performance. We recommend stopping captures before running diagnostics. Are you sure you want to run diagnostics now?"
            onNo={this.onDiagnosticsConfirmNo}
            onYes={this.onDiagnosticsConfirmYes}
            show={showDiagnosticsConfirm}
            title="Perform Diagnostics"
          />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  authToken: getAuthToken(state),
  engine: getEngine(state),
  engineCapabilities: getCapabilities(state) || null,
})

export default connect(mapStateToProps)(DiagnosticsView)
