import ThemeInterface from "./theme"
import * as Colors from "./colorScheme"

const darkTheme: ThemeInterface = {
  name: "Dark",
  backgroundColor: Colors.gray1300,
  textColor: Colors.gray500,
  textMutedColor: Colors.gray700,
  selectedColor: Colors.gray500,
  selectedBackgroundColor: Colors.blue500,
  markedBackgroundColor: Colors.blue800,
  errorColor: Colors.red500,
  dangerColor: Colors.red100,
  dangerBackgroundColor: Colors.red900,
  warningColor: Colors.yellow100,
  warningBackgroundColor: Colors.yellow800,
  infoColor: Colors.blue200,
  infoBackgroundColor: Colors.blue500,
  successColor: Colors.green100,
  successBackgroundColor: Colors.green700,
  linkColor: Colors.primary300,
  linkHoverColor: Colors.primary200,
  outerPadding: "0",
  innerPadding: "8px",
  primaryButtonColor: "#fff",
  primaryButtonBackgroundColor: Colors.primary600,
  primaryButtonBorderColor: Colors.primary600,
  primaryButtonHoverColor: "#fff",
  primaryButtonHoverBackgroundColor: Colors.primary800,
  primaryButtonHoverBorderColor: Colors.primary800,
  secondaryButtonColor: Colors.gray500,
  secondaryButtonBackgroundColor: Colors.gray900,
  secondaryButtonBorderColor: Colors.gray900,
  secondaryButtonHoverColor: "#fff",
  secondaryButtonHoverBackgroundColor: Colors.gray1000,
  secondaryButtonHoverBorderColor: Colors.gray1000,
  lightButtonColor: Colors.gray500,
  lightButtonBackgroundColor: Colors.gray800,
  lightButtonBorderColor: Colors.gray800,
  lightButtonHoverColor: "#fff",
  lightButtonHoverBackgroundColor: Colors.gray900,
  lightButtonHoverBorderColor: Colors.gray900,
  dangerButtonColor: Colors.gray300,
  dangerButtonBackgroundColor: Colors.red500,
  dangerButtonBorderColor: Colors.red500,
  dangerButtonHoverColor: "#fff",
  dangerButtonHoverBackgroundColor: Colors.red600,
  dangerButtonHoverBorderColor: Colors.red600,
  tabButtonColor: Colors.primary300,
  tooltipColor: "#fff",
  tooltipBackgroundColor: "#000",
  inputColor: Colors.gray500,
  inputPlaceholderColor: Colors.gray700,
  inputBackgroundColor: Colors.gray1000,
  inputBorderColor: Colors.gray1000,
  inputDisabledBackgroundColor: Colors.gray1100,
  inputDisabledBorderColor: Colors.gray1100,
  inputFocusColor: Colors.gray500,
  inputFocusBorderColor: Colors.primary800,
  inputCheckBackgroundColor: Colors.gray500,
  inputCheckBorderColor: "hsl(218,16%,70%)",
  inputCheckDisabledBackgroundColor: Colors.gray800,
  inputCheckDisabledBorderColor: Colors.gray1100,
  inputGroupButtonColor: Colors.gray600,
  inputGroupButtonHoverColor: "#fff",
  dropdownMenuColor: Colors.gray600,
  dropdownMenuBackgroundColor: Colors.gray1000,
  dropdownItemHoverColor: "#fff",
  dropdownItemHoverBackgroundColor: Colors.gray800,
  dropdownItemDisabledColor: Colors.gray700,
  dropdownItemDisabledBackgroundColor: "transparent",
  dropdownItemActiveColor: "#fff",
  dropdownItemActiveBackgroundColor: Colors.primary600,
  dropdownItemDividerColor: Colors.gray1100,
  popoverBackgroundColor: Colors.gray1100,
  popoverHeaderBackgroundColor: Colors.gray1300,
  toastBoxShadow: "0 0.5rem 1rem rgba(0,0,0,0.5)",
  toastBackgroundColor: "hsl(218,44%,4%)",
  toastHeaderBackgroundColor: Colors.gray1000,
  modalBackgroundColor: Colors.gray1200,
  modalHeaderBackgroundColor: Colors.gray1200,
  modalHeaderBorder: `1px solid ${Colors.gray1000}`,
  modalHeaderTextTransform: "uppercase",
  modalFooterBackgroundColor: Colors.gray1200,
  modalFooterBorder: `1px solid ${Colors.gray1000}`,
  dateTimePickerHeaderBackgroundColor: Colors.gray1100,
  dateTimePickerBorderColor: Colors.gray500,
  barGaugeBackgroundColor: Colors.gray1000,
  barGaugeDefaultColor: Colors.gray800,
  dividerColor: Colors.gray900,
  dividerHoverColor: Colors.primary600,
  tableBackgroundColor: Colors.gray1300,
  tableBorderColor: Colors.gray1000,
  tableBorder: `1px solid ${Colors.gray1000}`,
  tableHeaderBackgroundColor: Colors.gray1100,
  tableHeaderBorderWidth: "1px 1px 0 1px",
  tableHeaderBorderColor: Colors.gray1000,
  tableHeaderColor: Colors.gray700,
  tableHeaderFontSize: "10px",
  tableHeaderTextTransform: "uppercase",
  tableGridColor: Colors.gray1100,
  tableHoverColor: "hsla(214,47%,97%,0.12)",
  tableStripeColor: Colors.gray1200,
  tableGroupBackgroundColor: Colors.gray1000,
  propTableHeaderColor: Colors.gray700,
  propTableHeaderFontSize: "11px",
  propTableHeaderTextTransform: "uppercase",
  panelPadding: "1rem",
  panelBackground: Colors.gray1100,
  panelBorder: "none",
  panelBorderRadius: "2px",
  panelBoxShadow: "0 2px 4px rgba(0,0,0,0.5)",
  panelTitleBackground: Colors.gray1200,
  sidebarBoxShadow: "0 0 16px 0 rgba(0,0,0,0.25), 0 0 4px 0 rgba(0,0,0,0.5)",
  sidebarBackground: Colors.gray1200,
  sidebarTitleBackground: Colors.gray1000,
  sidebarTitleBorder: `1px solid ${Colors.gray1400}`,
  chartGridColor: Colors.gray900,
  chartTooltipBackgroundColor: Colors.gray1400,
  chartTooltipBorder: `1px solid ${Colors.gray1200}`,
  navMarginBottom: "0",
  navBorderRadius: "0",
  navBoxShadow: "0 1px 4px rgba(0,0,0,0.5)",
  navPadding: "6px 8px",
  navHighlightColor: undefined,
  headerColor: Colors.gray600,
  headerBackgroundColor: Colors.brandGray,
  breadcrumbColor: Colors.gray300,
  breadcrumbBackgroundColor: Colors.gray1100,
  breadcrumbSeparatorColor: Colors.primary300,
  engineNavColor: "#fff",
  engineNavBackgroundColor: Colors.primary600,
  settingsNavColor: Colors.gray300,
  settingsNavBackgroundColor: Colors.gray1000,
  adminNavColor: Colors.gray300,
  adminNavBackgroundColor: Colors.gray1000,
  captureNavColor: Colors.gray300,
  captureNavBackgroundColor: Colors.gray1000,
  viewHeaderBorder: `2px solid ${Colors.gray900}`,
  viewFooterBackgroundColor: Colors.gray1200,
  viewFooterBorder: "none",
  viewFooterBoxShadow: "0 1px 4px rgba(0,0,0,0.24)",
  sparklineStrokeColor: "hsl(212,66%,60%)",
  sparklineFillColor: "hsla(212,66%,60%,0.25)",
  searchTermColors: [
    "hsl(60,80%,18%)",
    "hsl(29,80%,28%)",
    "hsl(180,80%,18%)",
    "hsl(300,80%,18%)",
    "hsl(120,80%,14%)",
  ],
  decodePlainColor: Colors.gray500,
  decodeLayerColor: "hsl(216,62%,56%)",
  decodeDataColor: "hsl(0,72%,48%)",
  decodeHeaderColor: "hsl(120,72%,34%)",
  decodeMessageColor: "hsl(60,72%,34%)",
  decodeDumpColor: "hsl(300,100%,30%)",
  decodeDebugColor: "hsl(300,100%,30%)",
  decodeOffsetColor: Colors.gray700,
  filterOrGroupBackgroundColor: Colors.gray900,
  filterOrGroupBorder: `1px solid ${Colors.gray1100}`,
  filterOrColor: Colors.gray500,
  filterOrBackgroundColor: Colors.blue500,
  filterAndGroupBackgroundColor: Colors.gray800,
  filterAndGroupBorder: `1px solid ${Colors.gray1100}`,
  filterAndColor: Colors.gray500,
  filterAndBackgroundColor: Colors.orange800,
  filterNodeBorder: `1px solid ${Colors.gray1200}`,
  filterNotColor: Colors.red500,
  clientColor: "hsl(212,52%,50%)",
  clientBackgroundColor: "hsl(212,42%,20%)",
  clientPacketLabelColor: "inherit",
  clientPacketLabelBackgroundColor: "hsl(212,42%,40%)",
  clientMissingDataColor: "#fff",
  clientMissingDataBackgroundColor: "hsl(212,28%,40%)",
  clientRepeatedDataColor: Colors.red100,
  clientRepeatedDataBackgroundColor: "hsl(212,28%,40%)",
  serverColor: "hsl(80,36%,40%)",
  serverBackgroundColor: "hsl(80,14%,16%)",
  serverPacketLabelColor: "inherit",
  serverPacketLabelBackgroundColor: "hsl(80,22%,27%)",
  serverMissingDataColor: "#fff",
  serverMissingDataBackgroundColor: "hsl(77,22%,34%)",
  serverRepeatedDataColor: Colors.red100,
  serverRepeatedDataBackgroundColor: "hsl(77,22%,34%)",
  flowGraphLine1Color: "rgb(0,255,0)",
  flowGraphLine2Color: "rgb(255,255,0)",
  flowGraphLine3Color: "rgb(119,119,255)",
  peerMapGraphBackgroundColor: Colors.gray1300,
  peerMapSidebarOptionsBackgroundColor: "rgba(44, 54, 70, 0.45)",
  peerMapSidebarBorderColor: Colors.gray900,
  peerMapSidebarBackground: Colors.gray1300,
  peerMapLabelBackgroundColorFont: "#111722",
  tooltipWindowHeaderBackgroundColor: Colors.gray1200,
  tooltipWindowBackgroundColor: Colors.gray1100,
  tooltipWindowTableHeaderBackgroundColor: Colors.gray1000,
  tooltipWindowTableMutedBackgroundColor: Colors.gray1000,
  tooltipWindowTableMutedTextColor: Colors.gray500,
  tooltipWindowDropdownBackgroundColor: Colors.gray1000,
  upgradeBarColor: "#000",
  upgradeBarBackgroundColor: "#ffa500",
  voipReportSectionTitle: Colors.gray500,
  voipReportSectionSubTitle: Colors.gray400,
  voipReportSectionHeader: Colors.gray1000,
  voipReportSectionSubHeader: Colors.gray900,
  voipReportTableBorder: Colors.gray800,
  previewColor: "rgb(255,165,0)",
  decryptionKeyBorder: `1px solid ${Colors.gray1200}`,
  linkStateDownColor: Colors.gray800,
  linkStateUpColor: Colors.green600,
  subPanelPadding: "1rem",
  subPanelBackground: Colors.gray1100,
  subPanelBorder: `1px solid ${Colors.gray1200}`,
  subPanelBorderRadius: "2px",
  subPanelBoxShadow: "0 2px 4px rgba(0,0,0,0.5)",
  hardwareChannelAndBackgroundColor: Colors.primary800,
  hardwareChannelAndColor: Colors.gray500,
  hardwareChannelAndGroupBackgroundColor: Colors.gray800,
  hardwareChannelAndGroupBorder: `1px solid ${Colors.gray1100}`,
  hardwareChannelNodeBorder: `1px solid ${Colors.gray1200}`,
  hardwareChannelOrBackgroundColor: Colors.blue500,
  hardwareChannelOrColor: Colors.gray500,
  hardwareChannelOrGroupBackgroundColor: Colors.gray900,
  hardwareChannelOrGroupBorder: `1px solid ${Colors.gray1100}`,
  dataReductionSlicingColor: "hsl(210,50%,22%)",
  dataReductionCompressionColor: "hsl(211,50%,28%)",
  dataReductionCombinedColor: "hsl(213,50%,40%)",
  forensicSearchHistoryBackgroundColor: Colors.gray1200,
}

export default darkTheme
