import * as React from "react"
import { cloneDeep, padStart } from "lodash"
import CountryName from "../components/common/CountryName"
import {
  ExpertSearch,
  ExpertSearchGroup,
} from "../components/common/ExpertSearchPopover/ExpertSearchTypes"
import { IconInformational, IconMinor, IconMajor, IconSevere } from "../components/common/Icons"
import { TextWithIcon } from "../components/common/TextWithIcon"
import { filterExpressionTypeFromMediaSpecType } from "./filterUtils"
import {
  formatInteger,
  formatFloat,
  formatDateTime,
  formatDuration,
  formatISODateTime,
  localToUTCCheck,
  timeUnitToWPTime,
} from "./formatUtils"
import { formatMediaSpec, isIPv6Address } from "../utils/mediaSpec"
import csvStringify from "../utils/csvStringify"
import {
  ExpertAuthenticationType,
  ExpertEncryptionType,
  ExpertColumn,
  ExpertCounterID,
  ExpertFlowType,
  ExpertRowType,
  ExpertSeverity,
  ExpertView,
  isValidExpertAuthenticationType,
  isValidExpertEncryptionType,
  isValidExpertProblem,
  isValidExpertSensitivity,
  isValidExpertSeverity,
  isValidExpertSettingGroupId,
} from "../api/types/expertTypes"
import {
  ExpertProblemObject,
  ExpertQueryRequest,
  ExpertQueryResponse,
  ExpertResponse,
  ExpertStringTable,
  ExpertStringTableValue,
  ExpertValue,
  ExpertValueUnion,
  Filter,
  FilterNode,
  MediaSpec,
  AddressFilterNode,
  PortFilterNode,
  RequestPostSelectRelatedExpertStart,
  RequestPostSelectRelatedWebStart,
  ExpertDescription,
} from "../api/types"
import { MediaSpecType } from "../api/types/mediaTypes"
import { WebSelectRelatedParam } from "../api/types/peekTypes"
import { ExpertTableColumn } from "../components/common/ExpertTable"

export type ExpertValueObj = {
  value: number
  step: number
}

export type ExpertHeaderCountsObj = {
  eventsDetected: number
  flowsAnalyzed: number
  flowsRecycled: number
  packetsDropped: number
}

export type VoIPHeaderCountsObj = {
  currentCalls: number
  mediaFlows: number
  totalCalls: number
}

export type WebHeaderCountsObj = {
  servers: number
  clients: number
  pages: number
  requests: number
}

export function addExpertColumns(columnList: ExpertColumn[], columns: ExpertColumn[]) {
  columns.forEach((col: ExpertColumn) => {
    if (columnList.indexOf(col) === -1) {
      columnList.push(col)
    }
  })
}

export function authenticationTypeToString(type: ExpertAuthenticationType): string {
  let value: string = "None"

  switch (type) {
    case ExpertAuthenticationType.EXPERT_AUTHENTICATION_TYPE_EAP:
      value = "EAP"
      break
    case ExpertAuthenticationType.EXPERT_AUTHENTICATION_TYPE_LEAP:
      value = "LEAP"
      break
    case ExpertAuthenticationType.EXPERT_AUTHENTICATION_TYPE_PEAP:
      value = "PEAP"
      break
    case ExpertAuthenticationType.EXPERT_AUTHENTICATION_TYPE_EAPTLS:
      value = "EAPTLS"
      break
    case ExpertAuthenticationType.EXPERT_AUTHENTICATION_TYPE_NONE:
    default:
      value = "None"
      break
  }

  return value
}

export function encryptionTypeToString(type: ExpertEncryptionType): string {
  let value: string = "None"

  switch (type) {
    case ExpertEncryptionType.EXPERT_ENCRYPTION_TYPE_WEP:
      value = "WEP"
      break
    case ExpertEncryptionType.EXPERT_ENCRYPTION_TYPE_CKIP:
      value = "CKIP"
      break
    case ExpertEncryptionType.EXPERT_ENCRYPTION_TYPE_TKIP:
      value = "TKIP"
      break
    case ExpertEncryptionType.EXPERT_ENCRYPTION_TYPE_CCMP:
      value = "CCMP"
      break
    case ExpertEncryptionType.EXPERT_ENCRYPTION_TYPE_NONE:
    default:
      value = "None"
      break
  }

  return value
}

export function expertCounterIdToString(id: ExpertCounterID): string {
  let value = ""

  switch (id) {
    case ExpertCounterID.EXPERT_COUNTER_ID_NONE:
    default:
      value = "NONE"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_STREAM_COUNT_CURRENT:
      value = "STREAM_COUNT_CURRENT"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_STREAM_COUNT_RECYCLED:
      value = "STREAM_COUNT_RECYCLED"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_NODEPAIR_COUNT_CURRENT:
      value = "NODEPAIR_COUNT_CURRENT"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_NODEPAIR_COUNT_RECYCLED:
      value = "NODEPAIR_COUNT_RECYCLED"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_EVENT_COUNT_CURRENT:
      value = "EVENT_COUNT_CURRENT"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_EVENT_COUNT_RECYCLED:
      value = "EVENT_COUNT_RECYCLED"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_PACKET_COUNT_DROPPED:
      value = "PACKET_COUNT_DROPPED"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_EVENT_COUNT_INFORMATIONAL:
      value = "EVENT_COUNT_INFORMATIONAL"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_EVENT_COUNT_MINOR:
      value = "EVENT_COUNT_MINOR"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_EVENT_COUNT_MAJOR:
      value = "EVENT_COUNT_MAJOR"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_EVENT_COUNT_SEVERE:
      value = "EVENT_COUNT_SEVERE"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_MAX_STREAM_COUNT:
      value = "MAX_STREAM_COUNT"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_MAX_STREAM_COUNT_MAX:
      value = "MAX_STREAM_COUNT_MAX"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_HTTP_SERVER_COUNT:
      value = "HTTP_SERVER_COUNT"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_HTTP_CLIENT_COUNT:
      value = "HTTP_CLIENT_COUNT"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_HTTP_PAGE_COUNT:
      value = "HTTP_PAGE_COUNT"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_HTTP_REQUEST_COUNT:
      value = "HTTP_REQUEST_COUNT"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_HTTP_FIRST_START:
      value = "HTTP_FIRST_START"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_HTTP_LAST_FINISH:
      value = "HTTP_LAST_FINISH"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_VOIP_CALL_COUNT:
      value = "VOIP_CALL_COUNT"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_VOIP_MEDIA_FLOW_COUNT:
      value = "VOIP_MEDIA_FLOW_COUNT"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_VOIP_TOTAL_CALL_COUNT:
      value = "VOIP_TOTAL_CALL_COUNT"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_VOIP_MAX_CALLS_COUNT:
      value = "VOIP_MAX_CALLS_COUNT"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_VOIP_MAX_CALLS_TIME:
      value = "VOIP_MAX_CALLS_TIME"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_VOIP_MAX_CALLS_NOTIFY:
      value = "VOIP_MAX_CALLS_NOTIFY"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_VOIP_MAX_CALLS_NOTIFY_SEVERITY:
      value = "VOIP_MAX_CALLS_NOTIFY_SEVERITY"
      break
    case ExpertCounterID.EXPERT_COUNTER_ID_VOIP_STOPPED_ANALYSIS:
      value = "VOIP_STOPPED_ANALYSIS"
      break
  }

  return value
}

export function expertSeverityToString(severity: ExpertSeverity): string {
  let severityStr = ""
  switch (severity) {
    case ExpertSeverity.EXPERT_SEVERITY_INFORMATIONAL:
      severityStr = "Informational"
      break
    case ExpertSeverity.EXPERT_SEVERITY_MINOR:
      severityStr = "Minor"
      break
    case ExpertSeverity.EXPERT_SEVERITY_MAJOR:
      severityStr = "Major"
      break
    case ExpertSeverity.EXPERT_SEVERITY_SEVERE:
      severityStr = "Severe"
      break
    case ExpertSeverity.EXPERT_SEVERITY_NONE:
    case ExpertSeverity.EXPERT_SEVERITY_LEVELS:
    default:
      break
  }
  return severityStr
}

export function expertSeverityToIcon(severity: ExpertSeverity, size: number = 16): React.ReactNode {
  switch (severity) {
    case ExpertSeverity.EXPERT_SEVERITY_INFORMATIONAL:
      return <IconInformational width={size} height={size} />
    case ExpertSeverity.EXPERT_SEVERITY_MINOR:
      return <IconMinor width={size} height={size} />
    case ExpertSeverity.EXPERT_SEVERITY_MAJOR:
      return <IconMajor width={size} height={size} />
    case ExpertSeverity.EXPERT_SEVERITY_SEVERE:
      return <IconSevere width={size} height={size} />
    default:
      return null
  }
}

export function exportExpertToCSV(
  results: ExpertResponse,
  columns: ExpertTableColumn[]
): string | undefined {
  if (Array.isArray(results.rowList)) {
    const visibleColumns = columns.filter((col: ExpertTableColumn) => col.visible)
    let csv =
      visibleColumns.map((col: ExpertTableColumn) => csvStringify(col.label)).join(",") + "\n"

    results.rowList.forEach((rowData: ExpertValue[]) => {
      const row: string[] = []
      visibleColumns.forEach((col: ExpertTableColumn) => {
        let content: any = ""
        const columnId = parseInt(col.dataKey, 10) as ExpertColumn
        const columnIndex = results.columnList.indexOf(columnId)
        if (columnIndex !== -1) {
          const value = rowData[columnIndex]
          if (value != null && value.value !== undefined) {
            content = formatExpertValue(
              columnId,
              value.value,
              value.rendered,
              rowData,
              results.columnList,
              false,
              false
            )
          }
        }
        if (typeof content === "string") {
          content = content.replace("\u2192", "-->").replace("\u2190", "<--")
        }
        row.push(csvStringify(content))
      })
      csv += row.join(",") + "\n"
    })

    return csv
  }

  return undefined
}

export function filterFromRowData(rowData: ExpertValue[], columnList: ExpertColumn[]) {
  const rowType = getExpertValueFromRowData(rowData, columnList, ExpertColumn.EXPERT_COLUMN_TYPE)
  let filter: Filter | null = null
  if (rowType != null && typeof rowType.value === "number") {
    switch (rowType.value) {
      case ExpertRowType.EXPERT_ROW_TYPE_APPLICATION:
        {
          const application = getExpertValueFromRowData(
            rowData,
            columnList,
            ExpertColumn.EXPERT_COLUMN_APPLICATION
          )
          if (
            application &&
            (typeof application.value === "string" || typeof application.value === "number")
          ) {
            filter = {
              clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
              color: application.color || "#000000",
              comment: "",
              created: "",
              group: "",
              id: "",
              modified: "",
              name: "Untitled",
              rootNode: {
                applicationId: application.value,
                clsid: "B588E248-AB43-4AB8-AAFB-E2E1BD2EC428",
                comment: "",
                inverted: false,
              },
            }
          }
        }
        break

      default:
        {
          // See: ExpertStreamListBox2.cpp CreateFilterNode
          let addressFilterNode: AddressFilterNode | null = null
          let portFilterNode: PortFilterNode | null = null

          let clientAddr = getExpertValueFromRowData(
            rowData,
            columnList,
            ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS
          )
          let serverAddr = getExpertValueFromRowData(
            rowData,
            columnList,
            ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS
          )
          let clientPort = getExpertValueFromRowData(
            rowData,
            columnList,
            ExpertColumn.EXPERT_COLUMN_CLIENT_PORT
          )
          let serverPort = getExpertValueFromRowData(
            rowData,
            columnList,
            ExpertColumn.EXPERT_COLUMN_SERVER_PORT
          )

          if (
            ((clientPort == null || !clientPort.value) && serverPort != null && serverPort.value) ||
            ((clientAddr == null || !clientAddr.value) && serverAddr != null && serverAddr.value)
          ) {
            ;[clientAddr, serverAddr] = [serverAddr, clientAddr]
            ;[clientPort, serverPort] = [serverPort, clientPort]
          }

          if (
            (clientAddr != null && clientAddr.value) ||
            (serverAddr != null && serverAddr.value)
          ) {
            let address1 = ""
            let address2 = ""
            let type = MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
            if (clientAddr != null) {
              if (typeof clientAddr.value === "object" && !Array.isArray(clientAddr.value)) {
                address1 =
                  clientAddr.rendered !== undefined
                    ? clientAddr.rendered
                    : formatMediaSpec(clientAddr.value as MediaSpec)
                type = (clientAddr.value as MediaSpec).type
              } else if (typeof clientAddr.value === "string") {
                address1 = clientAddr.value
                type = isIPv6Address(clientAddr.value)
                  ? MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS
                  : MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
              }
            }
            if (serverAddr != null) {
              if (typeof serverAddr.value === "object" && !Array.isArray(serverAddr.value)) {
                address2 =
                  serverAddr.rendered !== undefined
                    ? serverAddr.rendered
                    : formatMediaSpec(serverAddr.value as MediaSpec)
                type = (serverAddr.value as MediaSpec).type
              } else if (typeof serverAddr.value === "string") {
                address2 = serverAddr.value
                type = isIPv6Address(serverAddr.value)
                  ? MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS
                  : MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
              }
            }
            addressFilterNode = {
              accept1To2: true,
              accept2To1: true,
              address1,
              address2,
              clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
              comment: "",
              inverted: false,
              type: type,
            }
            // "Any address" should always be in Address 2 (OD-1066).
            if (addressFilterNode.address1 === "") {
              addressFilterNode.address1 = addressFilterNode.address2
              addressFilterNode.address2 = ""
            }
          }

          if (
            (clientPort != null && typeof clientPort.value === "number") ||
            (serverPort != null && typeof serverPort.value === "number")
          ) {
            portFilterNode = {
              accept1To2: true,
              accept2To1: true,
              clsid: "B3279AE9-91E1-4D0A-8ABD-6D1BDC5471A9",
              comment: "",
              inverted: false,
              port1:
                clientPort != null && typeof clientPort.value === "number"
                  ? String(clientPort.value)
                  : "",
              port2:
                serverPort != null && typeof serverPort.value === "number"
                  ? String(serverPort.value)
                  : "",
              type: MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT,
            }
            // "Any port" should always be in Port 2 (OD-1066).
            if (portFilterNode.port1 === "") {
              portFilterNode.port1 = portFilterNode.port2
              portFilterNode.port2 = ""
            }
          }

          let rootFilterNode: FilterNode | null = null
          if (addressFilterNode != null) {
            rootFilterNode = addressFilterNode
          }
          if (portFilterNode != null) {
            if (addressFilterNode != null) {
              addressFilterNode.andNode = portFilterNode
            } else {
              rootFilterNode = portFilterNode
            }
          }

          if (rootFilterNode != null) {
            filter = {
              clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
              color: "#000000",
              comment: "",
              created: "",
              group: "",
              id: "",
              modified: "",
              name: "Untitled",
              rootNode: rootFilterNode,
            }
          }
        }
        break
    }
  }

  return filter
}

export function filterExpressionFromRowData(rowData: ExpertValue[], columnList: ExpertColumn[]) {
  const rowType = getExpertValueFromRowData(rowData, columnList, ExpertColumn.EXPERT_COLUMN_TYPE)
  let filter = ""
  if (rowType != null && typeof rowType.value === "number") {
    switch (rowType.value) {
      case ExpertRowType.EXPERT_ROW_TYPE_APPLICATION:
        {
          const application = getExpertValueFromRowData(
            rowData,
            columnList,
            ExpertColumn.EXPERT_COLUMN_APPLICATION
          )
          if (application && typeof application.rendered === "string") {
            filter = `app('${application.rendered}')`
          }
        }
        break

      default:
        {
          // See: ExpertStreamListBox2.cpp CreateFilterNode
          let clientAddr = getExpertValueFromRowData(
            rowData,
            columnList,
            ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS
          )
          let serverAddr = getExpertValueFromRowData(
            rowData,
            columnList,
            ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS
          )
          let clientPort = getExpertValueFromRowData(
            rowData,
            columnList,
            ExpertColumn.EXPERT_COLUMN_CLIENT_PORT
          )
          let serverPort = getExpertValueFromRowData(
            rowData,
            columnList,
            ExpertColumn.EXPERT_COLUMN_SERVER_PORT
          )

          if (
            ((clientPort == null || !clientPort.rendered) &&
              serverPort != null &&
              serverPort.rendered) ||
            ((clientAddr == null || !clientAddr.rendered) &&
              serverAddr != null &&
              serverAddr.rendered)
          ) {
            ;[clientAddr, serverAddr] = [serverAddr, clientAddr]
            ;[clientPort, serverPort] = [serverPort, clientPort]
          }

          let addressFilterNode: string | undefined
          if (
            (clientAddr != null && clientAddr.rendered) ||
            (serverAddr != null && serverAddr.rendered)
          ) {
            let type = MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
            if (clientAddr != null) {
              if (typeof clientAddr.value === "object" && !Array.isArray(clientAddr.value)) {
                type = (clientAddr.value as MediaSpec).type
              } else if (typeof clientAddr.value === "string") {
                type = isIPv6Address(clientAddr.value)
                  ? MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS
                  : MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
              }
            }
            if (serverAddr != null) {
              if (typeof serverAddr.value === "object" && !Array.isArray(serverAddr.value)) {
                type = (serverAddr.value as MediaSpec).type
              } else if (typeof serverAddr.value === "string") {
                type = isIPv6Address(serverAddr.value)
                  ? MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS
                  : MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
              }
            }
            const parts: string[] = []
            parts.push(`type:${filterExpressionTypeFromMediaSpecType(type)}`)
            if (clientAddr != null && clientAddr.rendered) {
              parts.push(`${parts.length === 1 ? "addr1" : "addr2"}:'${clientAddr.rendered}'`)
            }
            if (serverAddr != null && serverAddr.rendered) {
              parts.push(`${parts.length === 1 ? "addr1" : "addr2"}:'${serverAddr.rendered}'`)
            }
            addressFilterNode = `addr(${parts.join(", ")})`
          }

          let portFilterNode: string | undefined
          if (
            (clientPort != null && clientPort.rendered) ||
            (serverPort != null && serverPort.rendered)
          ) {
            const parts: string[] = []
            if (clientPort != null && clientPort.rendered) {
              parts.push(`${parts.length === 0 ? "port1" : "port2"}:'${clientPort.rendered}'`)
            }
            if (serverPort != null && serverPort.rendered) {
              parts.push(`${parts.length === 0 ? "port1" : "port2"}:'${serverPort.rendered}'`)
            }
            portFilterNode = `port(${parts.join(", ")})`
          }

          if (addressFilterNode && portFilterNode) {
            filter = `${addressFilterNode} & ${portFilterNode}`
          } else if (addressFilterNode) {
            filter = addressFilterNode
          } else if (portFilterNode) {
            filter = portFilterNode
          }
        }
        break
    }
  }

  return filter
}

export const isExpertSearchEmpty = (search: ExpertSearch | undefined) => {
  return (
    search === undefined ||
    !(
      search.clientAddress ||
      search.clientPort ||
      search.serverAddress ||
      search.serverPort ||
      search.protocol ||
      search.application ||
      search.mpls ||
      search.vlan ||
      search.vxlanGPID ||
      search.vxlanVNI ||
      search.start ||
      search.finish
    )
  )
}

export function filterResultSetBySearch(
  resultSet: ExpertQueryResponse,
  resultIndex: number,
  search: ExpertSearch,
  showLocalTime: boolean
): ExpertQueryResponse {
  if (isExpertSearchEmpty(search)) return resultSet

  const filteredResultSet = cloneDeep(resultSet)

  if (
    Array.isArray(filteredResultSet?.results[resultIndex].rowList) &&
    resultIndex >= 0 &&
    resultIndex < filteredResultSet.results.length
  ) {
    // determine which columns to filter
    const filterColumns: ExpertSearchGroup[] = []
    if (search.clientAddress.length > 0) {
      const index = filteredResultSet.results[resultIndex].columnList.findIndex(
        (col: ExpertColumn) => col === ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS
      )
      if (index !== -1) {
        filterColumns.push({
          columnId: ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS,
          columnIndex: index,
          searchValue: search.clientAddress,
        })
      }
    }
    if (search.clientPort.length > 0) {
      const index = filteredResultSet.results[resultIndex].columnList.findIndex(
        (col: ExpertColumn) => col === ExpertColumn.EXPERT_COLUMN_CLIENT_PORT
      )
      if (index !== -1) {
        filterColumns.push({
          columnId: ExpertColumn.EXPERT_COLUMN_CLIENT_PORT,
          columnIndex: index,
          searchValue: search.clientPort,
        })
      }
    }
    if (search.serverAddress.length > 0) {
      const index = filteredResultSet.results[resultIndex].columnList.findIndex(
        (col: ExpertColumn) => col === ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS
      )
      if (index !== -1) {
        filterColumns.push({
          columnId: ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS,
          columnIndex: index,
          searchValue: search.serverAddress,
        })
      }
    }
    if (search.serverPort.length > 0) {
      const index = filteredResultSet.results[resultIndex].columnList.findIndex(
        (col: ExpertColumn) => col === ExpertColumn.EXPERT_COLUMN_SERVER_PORT
      )
      if (index !== -1) {
        filterColumns.push({
          columnId: ExpertColumn.EXPERT_COLUMN_SERVER_PORT,
          columnIndex: index,
          searchValue: search.serverPort,
        })
      }
    }
    if (search.protocol.length > 0) {
      const index = filteredResultSet.results[resultIndex].columnList.findIndex(
        (col: ExpertColumn) => col === ExpertColumn.EXPERT_COLUMN_PROTOCOL
      )
      if (index !== -1) {
        filterColumns.push({
          columnId: ExpertColumn.EXPERT_COLUMN_PROTOCOL,
          columnIndex: index,
          searchValue: search.protocol,
        })
      }
    }
    if (search.application.length > 0) {
      const index = filteredResultSet.results[resultIndex].columnList.findIndex(
        (col: ExpertColumn) => col === ExpertColumn.EXPERT_COLUMN_APPLICATION
      )
      if (index !== -1) {
        filterColumns.push({
          columnId: ExpertColumn.EXPERT_COLUMN_APPLICATION,
          columnIndex: index,
          searchValue: search.application,
        })
      }
    }
    if (search.mpls.length > 0) {
      const index = filteredResultSet.results[resultIndex].columnList.findIndex(
        (col: ExpertColumn) => col === ExpertColumn.EXPERT_COLUMN_MPLS
      )
      if (index !== -1) {
        filterColumns.push({
          columnId: ExpertColumn.EXPERT_COLUMN_MPLS,
          columnIndex: index,
          searchValue: search.mpls,
        })
      }
    }
    if (search.vlan.length > 0) {
      const index = filteredResultSet.results[resultIndex].columnList.findIndex(
        (col: ExpertColumn) => col === ExpertColumn.EXPERT_COLUMN_VLAN
      )
      if (index !== -1) {
        filterColumns.push({
          columnId: ExpertColumn.EXPERT_COLUMN_VLAN,
          columnIndex: index,
          searchValue: search.vlan,
        })
      }
    }
    if (search.vxlanGPID.length > 0) {
      const index = filteredResultSet.results[resultIndex].columnList.findIndex(
        (col: ExpertColumn) => col === ExpertColumn.EXPERT_COLUMN_VXLAN_GPID
      )
      if (index !== -1) {
        filterColumns.push({
          columnId: ExpertColumn.EXPERT_COLUMN_VXLAN_GPID,
          columnIndex: index,
          searchValue: search.vxlanGPID,
        })
      }
    }
    if (search.vxlanVNI.length > 0) {
      const index = filteredResultSet.results[resultIndex].columnList.findIndex(
        (col: ExpertColumn) => col === ExpertColumn.EXPERT_COLUMN_VXLAN_VNI
      )
      if (index !== -1) {
        filterColumns.push({
          columnId: ExpertColumn.EXPERT_COLUMN_VXLAN_VNI,
          columnIndex: index,
          searchValue: search.vxlanVNI,
        })
      }
    }
    if (search.start.length > 0 && search.finish.length > 0) {
      const indexStart = filteredResultSet.results[resultIndex].columnList.findIndex(
        (col: ExpertColumn) => col === ExpertColumn.EXPERT_COLUMN_START_TIME
      )
      const indexEnd = filteredResultSet.results[resultIndex].columnList.findIndex(
        (col: ExpertColumn) => col === ExpertColumn.EXPERT_COLUMN_END_TIME
      )
      if (indexStart !== -1 && indexEnd !== -1) {
        filterColumns.push({
          columnId: ExpertColumn.EXPERT_COLUMN_START_TIME,
          columnIndex: indexStart,
          searchValue: search.start,
        })
        filterColumns.push({
          columnId: ExpertColumn.EXPERT_COLUMN_END_TIME,
          columnIndex: indexEnd,
          searchValue: search.finish,
        })
      }
    }

    // perform the filtering
    if (filterColumns.length > 0) {
      filteredResultSet.results[resultIndex].rowList = filteredResultSet.results[
        resultIndex
      ].rowList?.filter((row: ExpertValue[]) => {
        return Array.isArray(row)
          ? filterColumns.every((filterColumn: ExpertSearchGroup) => {
              let show = true
              if (filterColumn.columnIndex >= 0 && filterColumn.columnIndex < row.length) {
                const rowItem = row[filterColumn.columnIndex]
                const searchValue = filterColumn.searchValue.toLowerCase()
                let searchValueArray: string[] = []
                switch (filterColumn.columnId) {
                  case ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS:
                  case ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS:
                    if (rowItem.rendered != null && rowItem.rendered.length > 0) {
                      show = rowItem.rendered.toLowerCase().includes(searchValue)
                    }
                    if (
                      !show &&
                      rowItem.value != null &&
                      typeof rowItem.value === "object" &&
                      "data" in rowItem.value &&
                      "msclass" in rowItem.value &&
                      "type" in rowItem.value
                    ) {
                      show = formatMediaSpec(rowItem.value as MediaSpec)
                        .toLowerCase()
                        .includes(searchValue)
                    }
                    break
                  case ExpertColumn.EXPERT_COLUMN_CLIENT_PORT:
                  case ExpertColumn.EXPERT_COLUMN_SERVER_PORT:
                    if (rowItem.rendered != null && rowItem.rendered.length > 0) {
                      show = rowItem.rendered.toLowerCase().includes(searchValue)
                    }
                    if (!show && rowItem.value != null && typeof rowItem.value === "number") {
                      show = Number(rowItem.value as number)
                        .toString()
                        .includes(searchValue)
                    }
                    break
                  case ExpertColumn.EXPERT_COLUMN_PROTOCOL:
                  case ExpertColumn.EXPERT_COLUMN_APPLICATION:
                    if (rowItem.rendered != null && rowItem.rendered.length > 0) {
                      show = rowItem.rendered.toLowerCase().includes(searchValue)
                    }
                    break
                  case ExpertColumn.EXPERT_COLUMN_MPLS:
                  case ExpertColumn.EXPERT_COLUMN_VLAN:
                  case ExpertColumn.EXPERT_COLUMN_VXLAN_GPID:
                  case ExpertColumn.EXPERT_COLUMN_VXLAN_VNI:
                    searchValueArray = searchValue.split(" ")
                    show = searchValueArray.some((searchValueArrayItem: string) => {
                      if (rowItem.rendered != null && rowItem.rendered.length > 0) {
                        return rowItem.rendered.toLowerCase().includes(searchValueArrayItem)
                      }
                      return false
                    })
                    break
                  case ExpertColumn.EXPERT_COLUMN_START_TIME: {
                    let rowStartDate: Date | null = null
                    if (typeof rowItem.value === "string") {
                      rowStartDate = localToUTCCheck(new Date(rowItem.value), showLocalTime)
                    } else if (typeof rowItem.value === "number" && rowItem.value !== 0) {
                      rowStartDate = localToUTCCheck(new Date(rowItem.value), showLocalTime)
                    }
                    if (rowStartDate) {
                      show = rowStartDate >= new Date(filterColumn.searchValue)
                    }
                    break
                  }
                  case ExpertColumn.EXPERT_COLUMN_END_TIME: {
                    let rowEndDate: Date | null = null
                    if (typeof rowItem.value === "string") {
                      rowEndDate = localToUTCCheck(new Date(rowItem.value), showLocalTime)
                    } else if (typeof rowItem.value === "number" && rowItem.value !== 0) {
                      rowEndDate = localToUTCCheck(new Date(rowItem.value), showLocalTime)
                    }
                    if (rowEndDate) {
                      show = rowEndDate <= new Date(filterColumn.searchValue)
                    }
                    break
                  }
                  default:
                    break
                }
              }
              return show
            })
          : true
      })
    }
  }

  return filteredResultSet
}

export function formatExpertValue(
  columnId: ExpertColumn,
  value: ExpertValueUnion,
  rendered?: string | null,
  row?: ExpertValue[] | null,
  columnList?: ExpertColumn[] | null,
  showLocalTime?: boolean,
  allowComponents: boolean = true
): undefined | number | string | JSX.Element {
  switch (columnId) {
    case ExpertColumn.EXPERT_COLUMN_NAME:
    case ExpertColumn.EXPERT_COLUMN_NODE_1:
      if (columnList && row && rendered != null) {
        const type = row[columnList.indexOf(ExpertColumn.EXPERT_COLUMN_TYPE)]
        if (type && type.value === ExpertRowType.EXPERT_ROW_TYPE_PROBLEM) {
          const severity = row[columnList.indexOf(ExpertColumn.EXPERT_COLUMN_EVENT_SEVERITY_MAX)]
          if (severity && typeof severity.value === "number") {
            if (allowComponents) {
              return (
                <TextWithIcon
                  icon={formatExpertValue(
                    ExpertColumn.EXPERT_COLUMN_EVENT_SEVERITY_MAX,
                    severity.value
                  )}
                  text={rendered}
                />
              )
            } else {
              return rendered
            }
          }
        }
      }
      if (rendered != null && rendered.length > 0) {
        return rendered
      } else {
        return ""
      }
    case ExpertColumn.EXPERT_COLUMN_NODE_2:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_PROTOCOL:
    case ExpertColumn.EXPERT_COLUMN_PROTOCOL_LAYER:
    case ExpertColumn.EXPERT_COLUMN_APPLICATION:
    case ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_TCP_STATUS:
    case ExpertColumn.EXPERT_COLUMN_FLOW_TYPE:
    case ExpertColumn.EXPERT_COLUMN_CALLER_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_CALLEE_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_GATEKEEPER_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_MEDIA_TYPE:
    case ExpertColumn.EXPERT_COLUMN_CALL_STATUS:
    case ExpertColumn.EXPERT_COLUMN_DSCP:
    case ExpertColumn.EXPERT_COLUMN_TLS_VERSION:
    case ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_BEFORE:
    case ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_AFTER:
    case ExpertColumn.EXPERT_COLUMN_MPLS:
    case ExpertColumn.EXPERT_COLUMN_VLAN:
    case ExpertColumn.EXPERT_COLUMN_VXLAN_GPID:
    case ExpertColumn.EXPERT_COLUMN_VXLAN_VNI:
      if (rendered != null && rendered.length > 0) {
        return rendered
      } else {
        return ""
      }
    case ExpertColumn.EXPERT_COLUMN_SOURCE_PORT:
    case ExpertColumn.EXPERT_COLUMN_DEST_PORT:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_PORT:
    case ExpertColumn.EXPERT_COLUMN_SERVER_PORT:
    case ExpertColumn.EXPERT_COLUMN_CALLER_PORT:
    case ExpertColumn.EXPERT_COLUMN_CALLEE_PORT:
    case ExpertColumn.EXPERT_COLUMN_GATEKEEPER_PORT:
      if (rendered != null && rendered.length > 0) {
        return rendered
      } else if (value === 0) {
        return allowComponents ? undefined : ""
      }
      break
    case ExpertColumn.EXPERT_COLUMN_PROBLEM_COUNT:
      if (value != null) {
        if (row && columnList && allowComponents) {
          const severityMax = getExpertValueFromRowData(
            row,
            columnList,
            ExpertColumn.EXPERT_COLUMN_EVENT_SEVERITY_MAX
          )
          if (severityMax != null && severityMax.value != null) {
            const icon = expertSeverityToIcon(severityMax.value as ExpertSeverity, 12)
            if (icon != null) {
              return (
                <TextWithIcon
                  icon={icon}
                  text={formatInteger(value as number)}
                  style={{ justifyContent: "flex-end" }}
                />
              )
            }
          }
        }
        return formatInteger(value as number)
      } else {
        return ""
      }

    case ExpertColumn.EXPERT_COLUMN_STREAM_COUNT:
    case ExpertColumn.EXPERT_COLUMN_HOP_COUNT:
    case ExpertColumn.EXPERT_COLUMN_PACKET_COUNT:
    case ExpertColumn.EXPERT_COLUMN_BYTE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT:
    case ExpertColumn.EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_BYTE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_SERVER_SENT_BYTE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_NET_LATENCY_SAMPLE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_APP_LATENCY_SAMPLE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_DELAY_SAMPLE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_SAMPLE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_SAMPLE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_HOP_COUNT:
    case ExpertColumn.EXPERT_COLUMN_SERVER_HOP_COUNT:
    case ExpertColumn.EXPERT_COLUMN_MEDIA_FLOW_COUNT:
    case ExpertColumn.EXPERT_COLUMN_MEDIA_PACKET_COUNT:
    case ExpertColumn.EXPERT_COLUMN_MEDIA_FRAME_COUNT:
    case ExpertColumn.EXPERT_COLUMN_CONTROL_FLOW_COUNT:
    case ExpertColumn.EXPERT_COLUMN_CONTROL_PACKET_COUNT:
    case ExpertColumn.EXPERT_COLUMN_SIGNALING_FLOW_COUNT:
    case ExpertColumn.EXPERT_COLUMN_SIGNALING_PACKET_COUNT:
    case ExpertColumn.EXPERT_COLUMN_REQUEST_PAYLOAD_BYTE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_RESPONSE_PAYLOAD_BYTE_COUNT:
      if (value != null) {
        return formatInteger(value as number)
      } else {
        return ""
      }
    case ExpertColumn.EXPERT_COLUMN_STREAM_ID:
    case ExpertColumn.EXPERT_COLUMN_CALL_ID:
    case ExpertColumn.EXPERT_COLUMN_REQUEST_ID:
    case ExpertColumn.EXPERT_COLUMN_PAGE_REQUEST_ID:
    case ExpertColumn.EXPERT_COLUMN_RESPONSE_CODE:
    case ExpertColumn.EXPERT_COLUMN_SEQUENCE:
      if (typeof value === "number" && value !== 0) {
        return value
      } else {
        return ""
      }
    case ExpertColumn.EXPERT_COLUMN_CLIENT_TCP_WINDOW_MIN:
    case ExpertColumn.EXPERT_COLUMN_SERVER_TCP_WINDOW_MIN:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_TCP_WINDOW_MAX:
    case ExpertColumn.EXPERT_COLUMN_SERVER_TCP_WINDOW_MAX:
    case ExpertColumn.EXPERT_COLUMN_BIT_RATE:
    case ExpertColumn.EXPERT_COLUMN_SIGNALING_MSG_INDEX:
      if (value != null && value !== 0) {
        return formatInteger(value as number)
      } else {
        return ""
      }
    case ExpertColumn.EXPERT_COLUMN_EVENT_SERIAL_NUMBER:
    case ExpertColumn.EXPERT_COLUMN_FLOW_INDEX:
      if (value != null) {
        return formatInteger((value as number) + 1)
      }
      break
    case ExpertColumn.EXPERT_COLUMN_START_TIME:
    case ExpertColumn.EXPERT_COLUMN_END_TIME:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_START_TIME:
    case ExpertColumn.EXPERT_COLUMN_SERVER_START_TIME:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_END_TIME:
    case ExpertColumn.EXPERT_COLUMN_SERVER_END_TIME:
    case ExpertColumn.EXPERT_COLUMN_EVENT_TIME:
    case ExpertColumn.EXPERT_COLUMN_TIME:
      if (typeof value === "string") {
        return formatISODateTime(value, 0, showLocalTime)
      } else if (typeof value === "number" && value !== 0) {
        return formatDateTime(value)
      }
      break
    case ExpertColumn.EXPERT_COLUMN_DURATION:
    case ExpertColumn.EXPERT_COLUMN_ONE_WAY_DELAY:
    case ExpertColumn.EXPERT_COLUMN_SETUP_TIME:
    case ExpertColumn.EXPERT_COLUMN_PDD:
    case ExpertColumn.EXPERT_COLUMN_RELATIVE_TIME:
      if (value != null) {
        return formatDuration(value as number, 6)
      }
      break
    case ExpertColumn.EXPERT_COLUMN_NET_LATENCY:
    case ExpertColumn.EXPERT_COLUMN_NET_LATENCY_BEST:
    case ExpertColumn.EXPERT_COLUMN_NET_LATENCY_WORST:
    case ExpertColumn.EXPERT_COLUMN_APP_LATENCY:
    case ExpertColumn.EXPERT_COLUMN_APP_LATENCY_BEST:
    case ExpertColumn.EXPERT_COLUMN_APP_LATENCY_WORST:
    case ExpertColumn.EXPERT_COLUMN_DELAY:
    case ExpertColumn.EXPERT_COLUMN_DELAY_BEST:
    case ExpertColumn.EXPERT_COLUMN_DELAY_WORST:
    case ExpertColumn.EXPERT_COLUMN_JITTER:
    case ExpertColumn.EXPERT_COLUMN_THREE_WAY_HANDSHAKE_TIME:
    case ExpertColumn.EXPERT_COLUMN_SERVER_NETWORK_DELAY:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_NETWORK_DELAY:
    case ExpertColumn.EXPERT_COLUMN_TLS_HANDSHAKE_LENGTH:
      if (value != null) {
        return formatDuration(value as number, 6)
      } else {
        return ""
      }
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_BEST:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_BEST:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_WORST:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_WORST:
      if (value != null) {
        return formatFloat(value as number, 0)
      }
      break
    case ExpertColumn.EXPERT_COLUMN_SSRC:
      if (value != null && value !== 0) {
        value = (value as number) >>> 0
        return padStart(value.toString(16), 8, "0").toUpperCase()
      } else {
        return ""
      }
    case ExpertColumn.EXPERT_COLUMN_PACKET_LOSS_PERCENT:
      if (value != null) {
        return formatFloat(value as number, 3)
      }
      break
    case ExpertColumn.EXPERT_COLUMN_CLIENT_LATITUDE:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_LONGITUDE:
    case ExpertColumn.EXPERT_COLUMN_SERVER_LATITUDE:
    case ExpertColumn.EXPERT_COLUMN_SERVER_LONGITUDE:
      if (value != null) {
        return formatFloat(value as number, 4)
      }
      break
    case ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY:
      if (row && columnList && value) {
        if (allowComponents) {
          return (
            <CountryName
              name={value as string}
              code={
                row[columnList.indexOf(ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY_CODE)]
                  .value as string
              }
            />
          )
        } else {
          return value as string
        }
      } else {
        return ""
      }
    case ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY:
      if (row && columnList && value) {
        if (allowComponents) {
          return (
            <CountryName
              name={value as string}
              code={
                row[columnList.indexOf(ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY_CODE)]
                  .value as string
              }
            />
          )
        } else {
          return value as string
        }
      } else {
        return ""
      }
    case ExpertColumn.EXPERT_COLUMN_MOS_LQ:
    case ExpertColumn.EXPERT_COLUMN_MOS_CQ:
    case ExpertColumn.EXPERT_COLUMN_MOS_PQ:
    case ExpertColumn.EXPERT_COLUMN_MOS_NOM:
    case ExpertColumn.EXPERT_COLUMN_MOS_A:
    case ExpertColumn.EXPERT_COLUMN_MOS_AV:
    case ExpertColumn.EXPERT_COLUMN_MOS_V:
    case ExpertColumn.EXPERT_COLUMN_MOS_LOW:
      if (value != null) {
        const mos = Math.floor((value as number) / 100) + ((value as number) % 100) / 100
        return formatFloat(mos, 2)
      } else {
        return ""
      }
    case ExpertColumn.EXPERT_COLUMN_R_FACTOR_LISTENING:
    case ExpertColumn.EXPERT_COLUMN_R_FACTOR_CONVERSATIONAL:
    case ExpertColumn.EXPERT_COLUMN_R_FACTOR_G107:
    case ExpertColumn.EXPERT_COLUMN_R_FACTOR_NOMINAL:
    case ExpertColumn.EXPERT_COLUMN_VSAQ:
    case ExpertColumn.EXPERT_COLUMN_VSMQ:
    case ExpertColumn.EXPERT_COLUMN_VSPQ:
    case ExpertColumn.EXPERT_COLUMN_VSTQ:
    case ExpertColumn.EXPERT_COLUMN_VOICE_LOSS_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_DISCARD_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_CODEC_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_DELAY_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_SIGNAL_LEVEL_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_NOISE_LEVEL_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_ECHO_LEVEL_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_RECENCY_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_LOSS_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_DISCARD_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_QUANTIZATION_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_BANDWIDTH_RESTRICTIONS_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RESOLUTION_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RATE_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_GOP_LENGTH_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_AVAILABLE_NETWORK_BANDWIDTH_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_DELAY_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_AV_SYNC_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_RECENCY_DEGRADATION:
      if (typeof value === "number" || typeof value === "string") {
        return value
      } else {
        return ""
      }
    case ExpertColumn.EXPERT_COLUMN_EVENT_SEVERITY_MAX:
      if (allowComponents) {
        switch (value) {
          case ExpertSeverity.EXPERT_SEVERITY_INFORMATIONAL:
            return <IconInformational />
          case ExpertSeverity.EXPERT_SEVERITY_MINOR:
            return <IconMinor />
          case ExpertSeverity.EXPERT_SEVERITY_MAJOR:
            return <IconMajor />
          case ExpertSeverity.EXPERT_SEVERITY_SEVERE:
            return <IconSevere />
          default:
            return undefined
        }
      } else {
        return expertSeverityToString(value as ExpertSeverity)
      }
    case ExpertColumn.EXPERT_COLUMN_REQUEST_ID_LIST:
      return ""
    default:
      break
  }

  if (typeof value === "number" || typeof value === "string") return value

  return ""
}

export function formatExpertValueFromRowData(
  rowData: ExpertValue[],
  columnList: ExpertColumn[],
  columnId: ExpertColumn
) {
  const value = getExpertValueFromRowData(rowData, columnList, columnId)
  if (value != null && value.value != null) {
    return formatExpertValue(columnId, value.value, value.rendered, rowData, columnList)
  }
  return undefined
}

export function formatExpertHeaderCountsProp(columnId: string, data: ExpertHeaderCountsObj | null) {
  switch (columnId) {
    case "Events Detected":
      return data !== null ? formatInteger(data.eventsDetected) : ""
    case "Flows Analyzed":
      return data !== null ? formatInteger(data.flowsAnalyzed) : ""
    case "Flows Recycled":
      return data !== null ? formatInteger(data.flowsRecycled) : ""
    case "Packets Dropped":
      return data !== null ? formatInteger(data.packetsDropped) : ""
    default:
      break
  }

  return ""
}

export function formatVoIPHeaderCountsProp(columnId: string, data: VoIPHeaderCountsObj | null) {
  switch (columnId) {
    case "Total Calls":
      return data !== null ? formatInteger(data.totalCalls) : ""
    case "Current Calls":
      return data !== null ? formatInteger(data.currentCalls) : ""
    case "Media Flows":
      return data !== null ? formatInteger(data.mediaFlows) : ""
    default:
      break
  }

  return ""
}

export function formatWebHeaderCountsProp(columnId: string, data: WebHeaderCountsObj | null) {
  switch (columnId) {
    case "Servers":
      return data !== null ? formatInteger(data.servers) : ""
    case "Clients":
      return data !== null ? formatInteger(data.clients) : ""
    case "Pages":
      return data !== null ? formatInteger(data.pages) : ""
    case "Requests":
      return data !== null ? formatInteger(data.requests) : ""
    default:
      break
  }

  return ""
}

export function generateExpertMinSamplePeriod(
  minSamplePeriod: number,
  expertDescription: ExpertDescription
): number {
  if (
    expertDescription.hasMinSamplePeriod &&
    expertDescription.minSamplePeriodUnits !== undefined
  ) {
    const unitTime = timeUnitToWPTime(expertDescription.minSamplePeriodUnits)
    if (unitTime !== 0) {
      minSamplePeriod /= unitTime
    }
  }
  return minSamplePeriod
}

export function generateExpertValue(
  value: number,
  expertDescription: ExpertDescription
): ExpertValueObj {
  const valObj: ExpertValueObj = { value, step: 1 }

  if (expertDescription.hasValue) {
    if (expertDescription.valueDisplayMultiplier > 0) {
      valObj.value *= expertDescription.valueDisplayMultiplier
    }
    if (expertDescription.valueDisplayMultiplier % 1 !== 0) {
      const suffixPrecision = getDecimalPlaces(expertDescription.valueDisplayMultiplier)
      if (suffixPrecision > 0) {
        const prefixPrecision = String(Math.trunc(valObj.value)).length
        valObj.value = Number(Number(valObj.value).toPrecision(prefixPrecision + suffixPrecision))
        valObj.step = Math.pow(10, -1 * suffixPrecision)
      } else {
        valObj.value = Math.trunc(valObj.value)
      }
    }
  }

  return valObj
}

function getDecimalPlaces(value: number) {
  if (!isFinite(value)) return 0
  let e = 1,
    p = 0
  while (Math.round(value * e) / e !== value) {
    e *= 10
    p++
  }
  return p
}

export function getExpertColumnName(columnId: ExpertColumn) {
  switch (columnId) {
    case ExpertColumn.EXPERT_COLUMN_NONE:
      return ""
    case ExpertColumn.EXPERT_COLUMN_TYPE:
      return ""
    case ExpertColumn.EXPERT_COLUMN_HIGHLIGHT:
      return ""
    case ExpertColumn.EXPERT_COLUMN_TREE_STATE:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_SEVERITY_MAX:
      return "Severity"
    case ExpertColumn.EXPERT_COLUMN_EVENT_TIME:
      return "Date/Time"
    case ExpertColumn.EXPERT_COLUMN_ICON_STATE:
      return ""
    case ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_NODE_1:
      return "Client Addr"
    case ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_NODE_2:
      return "Server Addr"
    case ExpertColumn.EXPERT_COLUMN_CLIENT_PORT:
      return "Client Port"
    case ExpertColumn.EXPERT_COLUMN_SERVER_PORT:
      return "Server Port"
    case ExpertColumn.EXPERT_COLUMN_PROTOCOL:
      return "Protocol"
    case ExpertColumn.EXPERT_COLUMN_TCP_STATUS:
      return "TCP Status"
    case ExpertColumn.EXPERT_COLUMN_STREAM_COUNT:
      return "Flows"
    case ExpertColumn.EXPERT_COLUMN_PROBLEM_COUNT:
      return "Events"
    case ExpertColumn.EXPERT_COLUMN_HOP_COUNT:
      return "Hops"
    case ExpertColumn.EXPERT_COLUMN_PACKET_COUNT:
      return "Packets"
    case ExpertColumn.EXPERT_COLUMN_BYTE_COUNT:
      return "Bytes"
    case ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT:
      return "Client Packets"
    case ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_BYTE_COUNT:
      return "Client Bytes"
    case ExpertColumn.EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT:
      return "Server Packets"
    case ExpertColumn.EXPERT_COLUMN_SERVER_SENT_BYTE_COUNT:
      return "Server Bytes"
    case ExpertColumn.EXPERT_COLUMN_DELAY:
      return "Avg Response Time (sec)"
    case ExpertColumn.EXPERT_COLUMN_DELAY_BEST:
      return "Best Response Time (sec)"
    case ExpertColumn.EXPERT_COLUMN_DELAY_WORST:
      return "Worst Response Time (sec)"
    case ExpertColumn.EXPERT_COLUMN_DELAY_SAMPLE_COUNT:
      return "Response Time Turn Count"
    case ExpertColumn.EXPERT_COLUMN_NODEPAIR:
      return ""
    case ExpertColumn.EXPERT_COLUMN_STREAM_ID:
      return "Flow ID"
    case ExpertColumn.EXPERT_COLUMN_PROBLEM_ID:
      return "Event"
    case ExpertColumn.EXPERT_COLUMN_START_TIME:
      return "Start"
    case ExpertColumn.EXPERT_COLUMN_END_TIME:
      return "Finish"
    case ExpertColumn.EXPERT_COLUMN_DURATION:
      return "Duration"
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND:
      return "C->S bps"
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND:
      return "S->C bps"
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_BEST:
      return "C->S bps Best"
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_BEST:
      return "S->C bps Best"
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_WORST:
      return "C->S bps Worst"
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_WORST:
      return "S->C bps Worst"
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_SAMPLE_COUNT:
      return "C->S bps Turn Count"
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_SAMPLE_COUNT:
      return "S->C bps Turn Count"
    case ExpertColumn.EXPERT_COLUMN_EVENT_SERIAL_NUMBER:
      return "Event ID"
    case ExpertColumn.EXPERT_COLUMN_PROTOCOL_LAYER:
      return "Layer"
    case ExpertColumn.EXPERT_COLUMN_MESSAGE:
      return "Event"
    case ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS:
      return "Source Addr"
    case ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS:
      return "Dest Addr"
    case ExpertColumn.EXPERT_COLUMN_SOURCE_PORT:
      return "Source Port"
    case ExpertColumn.EXPERT_COLUMN_DEST_PORT:
      return "Dest Port"
    case ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER:
      return "Packet"
    case ExpertColumn.EXPERT_COLUMN_OTHER_PACKET_NUMBER:
      return ""
    case ExpertColumn.EXPERT_COLUMN_IS_FROM_CLIENT:
      return ""
    case ExpertColumn.EXPERT_COLUMN_CLIENT_START_TIME:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SERVER_START_TIME:
      return ""
    case ExpertColumn.EXPERT_COLUMN_CLIENT_END_TIME:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SERVER_END_TIME:
      return ""
    case ExpertColumn.EXPERT_COLUMN_CLIENT_HOP_COUNT:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SERVER_HOP_COUNT:
      return ""
    case ExpertColumn.EXPERT_COLUMN_CLIENT_TCP_WINDOW_MIN:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SERVER_TCP_WINDOW_MIN:
      return ""
    case ExpertColumn.EXPERT_COLUMN_CLIENT_TCP_WINDOW_MAX:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SERVER_TCP_WINDOW_MAX:
      return ""
    case ExpertColumn.EXPERT_COLUMN_PROBLEM_SUMMARY_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_TABLE_ID:
      return ""
    case ExpertColumn.EXPERT_COLUMN_TABLE_NAME:
      return ""
    case ExpertColumn.EXPERT_COLUMN_TABLE_ATTRIBUTES:
      return ""
    case ExpertColumn.EXPERT_COLUMN_COLUMN_ID:
      return ""
    case ExpertColumn.EXPERT_COLUMN_COLUMN_NAME:
      return ""
    case ExpertColumn.EXPERT_COLUMN_COLUMN_ATTRIBUTES:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_GUID:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_NAME:
      return "Event"
    case ExpertColumn.EXPERT_COLUMN_EVENT_SENSITIVITY:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_MESSAGE:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_VALUE_SHOWN:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_VALUE_UNIT:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_VALUE_MIN:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_VALUE_MAX:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_ASSIST_SHOWN:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_ASSIST_LEFT:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_ASSIST_RIGHT:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_ASSIST_LOGSCALE:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_MINPERIOD_SHOWN:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_MINPERIOD_UNITS:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_MINPERIOD_MIN:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_MINPERIOD_MAX:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_GROUP:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_CONFIGURE_SHOWN:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_FORMAT:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_MULTIPLIER:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_SUB_GROUP:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_GROUP_ID:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_GROUP_NAME:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_GROUP_MAX_STREAM_COUNT:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_ENABLED:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_SEVERITY:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_VALUE:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_SENSITIVITY:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_MINPERIOD:
      return ""
    case ExpertColumn.EXPERT_COLUMN_FLOW_TYPE:
      return ""
    case ExpertColumn.EXPERT_COLUMN_IP_PROTOCOL:
      return ""
    case ExpertColumn.EXPERT_COLUMN_WIRELESS_RETRY_PERCENT:
      return "% Wireless Retries"
    case ExpertColumn.EXPERT_COLUMN_PROTOSPEC:
      return ""
    case ExpertColumn.EXPERT_COLUMN_NAME:
      return "Name"
    case ExpertColumn.EXPERT_COLUMN_VALUE:
      return ""
    case ExpertColumn.EXPERT_COLUMN_ACCEPT_MATCHING:
      return ""
    case ExpertColumn.EXPERT_COLUMN_AUX1:
      return ""
    case ExpertColumn.EXPERT_COLUMN_APPLICATION:
      return "Application"
    case ExpertColumn.EXPERT_COLUMN_APDEX:
      return ""
    case ExpertColumn.EXPERT_COLUMN_APDEX_SAMPLE_COUNT:
      return ""
    case ExpertColumn.EXPERT_COLUMN_INDEX:
      return ""
    case ExpertColumn.EXPERT_COLUMN_JITTER:
      return "Jitter"
    case ExpertColumn.EXPERT_COLUMN_PACKET_LOSS_PERCENT:
      return "Packet Loss %"
    case ExpertColumn.EXPERT_COLUMN_SSRC:
      return "SSRC"
    case ExpertColumn.EXPERT_COLUMN_CODEC:
      return "Codec"
    case ExpertColumn.EXPERT_COLUMN_MOS_LQ:
      return "MOS-LQ"
    case ExpertColumn.EXPERT_COLUMN_MOS_CQ:
      return "MOS-CQ"
    case ExpertColumn.EXPERT_COLUMN_MOS_PQ:
      return "MOS-PQ"
    case ExpertColumn.EXPERT_COLUMN_MOS_NOM:
      return "MOS-Nom"
    case ExpertColumn.EXPERT_COLUMN_R_FACTOR_LISTENING:
      return "R Factor Listening"
    case ExpertColumn.EXPERT_COLUMN_R_FACTOR_CONVERSATIONAL:
      return "R Factor Conversational"
    case ExpertColumn.EXPERT_COLUMN_R_FACTOR_G107:
      return "R Factor G.107"
    case ExpertColumn.EXPERT_COLUMN_R_FACTOR_NOMINAL:
      return "R Factor Nominal"
    case ExpertColumn.EXPERT_COLUMN_ONE_WAY_DELAY:
      return "One-Way Delay"
    case ExpertColumn.EXPERT_COLUMN_EVENT_LAYER_ID:
      return ""
    case ExpertColumn.EXPERT_COLUMN_EVENT_LAYER_VALUE:
      return ""
    case ExpertColumn.EXPERT_COLUMN_REQUEST_ID:
      return "Request ID"
    case ExpertColumn.EXPERT_COLUMN_PAGE_REQUEST_ID:
      return "Page ID"
    case ExpertColumn.EXPERT_COLUMN_REQUEST_HEADER:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RESPONSE_HEADER:
      return ""
    case ExpertColumn.EXPERT_COLUMN_URI:
      return "URI"
    case ExpertColumn.EXPERT_COLUMN_RESPONSE_CODE:
      return "Response Code"
    case ExpertColumn.EXPERT_COLUMN_RESPONSE_TEXT:
      return "Response Text"
    case ExpertColumn.EXPERT_COLUMN_REQUEST_PAYLOAD_BYTE_COUNT:
      return "Request Data Bytes"
    case ExpertColumn.EXPERT_COLUMN_RESPONSE_PAYLOAD_BYTE_COUNT:
      return "Response Data Bytes"
    case ExpertColumn.EXPERT_COLUMN_CONTENT_TYPE:
      return "Content-Type"
    case ExpertColumn.EXPERT_COLUMN_REFERER:
      return "Referer"
    case ExpertColumn.EXPERT_COLUMN_HOST:
      return "Host"
    case ExpertColumn.EXPERT_COLUMN_REQUEST_ID_LIST:
      return "Timing"
    case ExpertColumn.EXPERT_COLUMN_TIME:
      return "Time"
    case ExpertColumn.EXPERT_COLUMN_SEQUENCE_START:
      return ""
    case ExpertColumn.EXPERT_COLUMN_TCP_FLAGS:
      return ""
    case ExpertColumn.EXPERT_COLUMN_CALL_ID:
      return "Call Number"
    case ExpertColumn.EXPERT_COLUMN_FLOW_INDEX:
      return "Flow Index"
    case ExpertColumn.EXPERT_COLUMN_STATUS:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SIGNALING_TYPE:
      return "Signaling"
    case ExpertColumn.EXPERT_COLUMN_MEDIA_EXTRACTABLE:
      return ""
    case ExpertColumn.EXPERT_COLUMN_CALLER_ADDRESS:
      return "Caller Address"
    case ExpertColumn.EXPERT_COLUMN_CALLER_PORT:
      return "Caller Port"
    case ExpertColumn.EXPERT_COLUMN_CALLEE_ADDRESS:
      return "Callee Address"
    case ExpertColumn.EXPERT_COLUMN_CALLEE_PORT:
      return "Callee Port"
    case ExpertColumn.EXPERT_COLUMN_GATEKEEPER_ADDRESS:
      return "Gatekeeper Address"
    case ExpertColumn.EXPERT_COLUMN_GATEKEEPER_PORT:
      return "Gatekeeper Port"
    case ExpertColumn.EXPERT_COLUMN_FROM:
      return "From"
    case ExpertColumn.EXPERT_COLUMN_FROM_ABBREV:
      return ""
    case ExpertColumn.EXPERT_COLUMN_TO:
      return "To"
    case ExpertColumn.EXPERT_COLUMN_TO_ABBREV:
      return ""
    case ExpertColumn.EXPERT_COLUMN_GATEWAY_ASSIGNED_CALL_ID:
      return "Call ID"
    case ExpertColumn.EXPERT_COLUMN_CONTEXT:
      return ""
    case ExpertColumn.EXPERT_COLUMN_MEDIA_FLOW_COUNT:
      return "Media Flows"
    case ExpertColumn.EXPERT_COLUMN_MEDIA_PACKET_COUNT:
      return "Media Packets"
    case ExpertColumn.EXPERT_COLUMN_MEDIA_FRAME_COUNT:
      return "Media Frames"
    case ExpertColumn.EXPERT_COLUMN_CONTROL_FLOW_COUNT:
      return "Control Flows"
    case ExpertColumn.EXPERT_COLUMN_CONTROL_PACKET_COUNT:
      return "Control Packets"
    case ExpertColumn.EXPERT_COLUMN_SIGNALING_FLOW_COUNT:
      return "Signaling Flows"
    case ExpertColumn.EXPERT_COLUMN_SIGNALING_PACKET_COUNT:
      return "Signaling Packets"
    case ExpertColumn.EXPERT_COLUMN_BIT_RATE:
      return "Bit Rate"
    case ExpertColumn.EXPERT_COLUMN_MEDIA_TYPE:
      return "Codec Type"
    case ExpertColumn.EXPERT_COLUMN_END_CAUSE:
      return "End Cause"
    case ExpertColumn.EXPERT_COLUMN_DEGRADATION_CAUSE:
      return ""
    case ExpertColumn.EXPERT_COLUMN_SETUP_TIME:
      return "Setup Time"
    case ExpertColumn.EXPERT_COLUMN_PDD:
      return "PDD"
    case ExpertColumn.EXPERT_COLUMN_SEQUENCE:
      return "Sequence"
    case ExpertColumn.EXPERT_COLUMN_SEQUENCE_METHOD:
      return "Sequence Method"
    case ExpertColumn.EXPERT_COLUMN_FLAGS:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_PACKET_NUMBER_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_TIME_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_MEDIA_TYPE_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_BYTE_COUNT_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_FLAG_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_JITTER_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_JITTER_MICRO_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_Q1_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_Q2_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_Q3_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_Q4_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_Q5_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_Q6_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTP_Q7_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTCP_PACKET_NUMBER_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTCP_TIME_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RTCP_FLAG_LIST:
      return ""
    case ExpertColumn.EXPERT_COLUMN_RELATIVE_TIME:
      return "Relative Time"
    case ExpertColumn.EXPERT_COLUMN_VSAQ:
      return "VS-AQ"
    case ExpertColumn.EXPERT_COLUMN_VSMQ:
      return "VS-MQ"
    case ExpertColumn.EXPERT_COLUMN_VSPQ:
      return "VS-PQ"
    case ExpertColumn.EXPERT_COLUMN_VSTQ:
      return "VS-TQ"
    case ExpertColumn.EXPERT_COLUMN_MOS_A:
      return "MOS-A"
    case ExpertColumn.EXPERT_COLUMN_MOS_AV:
      return "MOS-AV"
    case ExpertColumn.EXPERT_COLUMN_MOS_V:
      return "MOS-V"
    case ExpertColumn.EXPERT_COLUMN_CALL_STATUS:
      return "Call Status"
    case ExpertColumn.EXPERT_COLUMN_MOS_LOW:
      return "MOS-Low"
    case ExpertColumn.EXPERT_COLUMN_SIGNALING_MSG_INDEX:
      return "Message"
    case ExpertColumn.EXPERT_COLUMN_MEDIA_FLOW_STATUS:
      return ""
    case ExpertColumn.EXPERT_COLUMN_APPLICATION_CONFIDENCE:
      return ""
    case ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY_CODE:
      return "Client Country"
    case ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY:
      return "Client Country"
    case ExpertColumn.EXPERT_COLUMN_CLIENT_CITY:
      return "Client City"
    case ExpertColumn.EXPERT_COLUMN_CLIENT_LATITUDE:
      return "Client Latitude"
    case ExpertColumn.EXPERT_COLUMN_CLIENT_LONGITUDE:
      return "Client Longitude"
    case ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY_CODE:
      return "Server Country"
    case ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY:
      return "Server Country"
    case ExpertColumn.EXPERT_COLUMN_SERVER_CITY:
      return "Server City"
    case ExpertColumn.EXPERT_COLUMN_SERVER_LATITUDE:
      return "Server Latitude"
    case ExpertColumn.EXPERT_COLUMN_SERVER_LONGITUDE:
      return "Server Longitude"
    case ExpertColumn.EXPERT_COLUMN_THREE_WAY_HANDSHAKE_TIME:
      return "3-Way Handshake (sec)"
    case ExpertColumn.EXPERT_COLUMN_SERVER_NETWORK_DELAY:
      return "Server Network Delay (sec)"
    case ExpertColumn.EXPERT_COLUMN_CLIENT_NETWORK_DELAY:
      return "Client Network Delay (sec)"
    case ExpertColumn.EXPERT_COLUMN_LAST_UPDATE_TIME:
      return ""
    case ExpertColumn.EXPERT_COLUMN_NET_LATENCY:
      return "Avg Network Latency (sec)"
    case ExpertColumn.EXPERT_COLUMN_NET_LATENCY_BEST:
      return "Best Network Latency (sec)"
    case ExpertColumn.EXPERT_COLUMN_NET_LATENCY_WORST:
      return "Worst Network Latency (sec)"
    case ExpertColumn.EXPERT_COLUMN_NET_LATENCY_SAMPLE_COUNT:
      return "Network Latency Turn Count"
    case ExpertColumn.EXPERT_COLUMN_APP_LATENCY:
      return "Avg Application Latency (sec)"
    case ExpertColumn.EXPERT_COLUMN_APP_LATENCY_BEST:
      return "Best Application Latency (sec)"
    case ExpertColumn.EXPERT_COLUMN_APP_LATENCY_WORST:
      return "Worst Application Latency (sec)"
    case ExpertColumn.EXPERT_COLUMN_APP_LATENCY_SAMPLE_COUNT:
      return "Application Latency Turn Count"
    case ExpertColumn.EXPERT_COLUMN_DSCP:
      return "DSCP"
    case ExpertColumn.EXPERT_COLUMN_ASSERTED_IDENTITY:
      return "Asserted Identity"
    case ExpertColumn.EXPERT_COLUMN_VOICE_LOSS_DEGRADATION:
      return "Voice Loss Degradation"
    case ExpertColumn.EXPERT_COLUMN_VOICE_DISCARD_DEGRADATION:
      return "Voice Discard Degradation"
    case ExpertColumn.EXPERT_COLUMN_VOICE_CODEC_DEGRADATION:
      return "Voice Codec Degradation"
    case ExpertColumn.EXPERT_COLUMN_VOICE_DELAY_DEGRADATION:
      return "Voice Delay Degradation"
    case ExpertColumn.EXPERT_COLUMN_VOICE_SIGNAL_LEVEL_DEGRADATION:
      return "Voice Signal Level Degradation"
    case ExpertColumn.EXPERT_COLUMN_VOICE_NOISE_LEVEL_DEGRADATION:
      return "Voice Noise Level Degradation"
    case ExpertColumn.EXPERT_COLUMN_VOICE_ECHO_LEVEL_DEGRADATION:
      return "Voice Echo Level Degradation"
    case ExpertColumn.EXPERT_COLUMN_VOICE_RECENCY_DEGRADATION:
      return "Voice Recency Degradation"
    case ExpertColumn.EXPERT_COLUMN_VIDEO_LOSS_DEGRADATION:
      return "Video Loss Degradation"
    case ExpertColumn.EXPERT_COLUMN_VIDEO_DISCARD_DEGRADATION:
      return "Video Discard Degradation"
    case ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_QUANTIZATION_DEGRADATION:
      return "Video Codec Quantization Degradation"
    case ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_BANDWIDTH_RESTRICTIONS_DEGRADATION:
      return "Video Codec Bandwidth Restrictions Degradation"
    case ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RESOLUTION_DEGRADATION:
      return "Video Frame Resolution Degradation"
    case ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RATE_DEGRADATION:
      return "Video Frame Rate Degradation"
    case ExpertColumn.EXPERT_COLUMN_VIDEO_GOP_LENGTH_DEGRADATION:
      return "Video GOP Length Degradation"
    case ExpertColumn.EXPERT_COLUMN_VIDEO_AVAILABLE_NETWORK_BANDWIDTH_DEGRADATION:
      return "Video Available Network Bandwidth Degradation"
    case ExpertColumn.EXPERT_COLUMN_VIDEO_DELAY_DEGRADATION:
      return "Video Delay Degradation"
    case ExpertColumn.EXPERT_COLUMN_VIDEO_AV_SYNC_DEGRADATION:
      return "Video A/V Synchronization Degradation"
    case ExpertColumn.EXPERT_COLUMN_VIDEO_RECENCY_DEGRADATION:
      return "Video Recency Degradation"
    case ExpertColumn.EXPERT_COLUMN_TLS_VERSION:
      return "TLS Version"
    case ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_BEFORE:
      return "TLS Cert Not Before"
    case ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_AFTER:
      return "TLS Cert Not After"
    case ExpertColumn.EXPERT_COLUMN_TLS_HANDSHAKE_LENGTH:
      return "TLS Handshake (sec)"
    case ExpertColumn.EXPERT_COLUMN_MPLS:
      return "MPLS"
    case ExpertColumn.EXPERT_COLUMN_VLAN:
      return "VLAN"
    case ExpertColumn.EXPERT_COLUMN_VXLAN_GPID:
      return "VXLAN GPID"
    case ExpertColumn.EXPERT_COLUMN_VXLAN_VNI:
      return "VXLAN VNI"
    default:
      break
  }

  return ""
}

export function getExpertColumnType(columnId: ExpertColumn) {
  switch (columnId) {
    case ExpertColumn.EXPERT_COLUMN_ACCEPT_MATCHING:
    case ExpertColumn.EXPERT_COLUMN_APP_LATENCY:
    case ExpertColumn.EXPERT_COLUMN_APP_LATENCY_BEST:
    case ExpertColumn.EXPERT_COLUMN_APP_LATENCY_SAMPLE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_APP_LATENCY_WORST:
    case ExpertColumn.EXPERT_COLUMN_AUX1:
    case ExpertColumn.EXPERT_COLUMN_BIT_RATE:
    case ExpertColumn.EXPERT_COLUMN_BYTE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_CALL_ID:
    case ExpertColumn.EXPERT_COLUMN_CALLEE_PORT:
    case ExpertColumn.EXPERT_COLUMN_CALLER_PORT:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_END_TIME:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_LATITUDE:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_LONGITUDE:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_PORT:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_BYTE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_START_TIME:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_TCP_WINDOW_MAX:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_TCP_WINDOW_MIN:
    case ExpertColumn.EXPERT_COLUMN_COLUMN_ATTRIBUTES:
    case ExpertColumn.EXPERT_COLUMN_COLUMN_ID:
    case ExpertColumn.EXPERT_COLUMN_EVENT_LAYER_ID:
    case ExpertColumn.EXPERT_COLUMN_CONTROL_FLOW_COUNT:
    case ExpertColumn.EXPERT_COLUMN_CONTROL_PACKET_COUNT:
    case ExpertColumn.EXPERT_COLUMN_DELAY:
    case ExpertColumn.EXPERT_COLUMN_DELAY_BEST:
    case ExpertColumn.EXPERT_COLUMN_DELAY_SAMPLE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_DELAY_WORST:
    case ExpertColumn.EXPERT_COLUMN_DEST_PORT:
    case ExpertColumn.EXPERT_COLUMN_DURATION:
    case ExpertColumn.EXPERT_COLUMN_EVENT_ASSIST_LEFT:
    case ExpertColumn.EXPERT_COLUMN_EVENT_ASSIST_LOGSCALE:
    case ExpertColumn.EXPERT_COLUMN_EVENT_ASSIST_RIGHT:
    case ExpertColumn.EXPERT_COLUMN_EVENT_ASSIST_SHOWN:
    case ExpertColumn.EXPERT_COLUMN_EVENT_CONFIGURE_SHOWN:
    case ExpertColumn.EXPERT_COLUMN_EVENT_MULTIPLIER:
    case ExpertColumn.EXPERT_COLUMN_EVENT_MINPERIOD_MAX:
    case ExpertColumn.EXPERT_COLUMN_EVENT_MINPERIOD_MIN:
    case ExpertColumn.EXPERT_COLUMN_EVENT_MINPERIOD_SHOWN:
    case ExpertColumn.EXPERT_COLUMN_EVENT_MINPERIOD_UNITS:
    case ExpertColumn.EXPERT_COLUMN_EVENT_SERIAL_NUMBER:
    case ExpertColumn.EXPERT_COLUMN_EVENT_SENSITIVITY:
    case ExpertColumn.EXPERT_COLUMN_EVENT_VALUE_MAX:
    case ExpertColumn.EXPERT_COLUMN_EVENT_VALUE_MIN:
    case ExpertColumn.EXPERT_COLUMN_EVENT_VALUE_SHOWN:
    case ExpertColumn.EXPERT_COLUMN_EVENT_VALUE_UNIT:
    case ExpertColumn.EXPERT_COLUMN_FLOW_INDEX:
    case ExpertColumn.EXPERT_COLUMN_GATEKEEPER_PORT:
    case ExpertColumn.EXPERT_COLUMN_HOP_COUNT:
    case ExpertColumn.EXPERT_COLUMN_ICON_STATE:
    case ExpertColumn.EXPERT_COLUMN_INDEX:
    case ExpertColumn.EXPERT_COLUMN_INTERVAL_BYTE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_INTERVAL_PACKET_COUNT:
    case ExpertColumn.EXPERT_COLUMN_IP_PROTOCOL:
    case ExpertColumn.EXPERT_COLUMN_IS_FROM_CLIENT:
    case ExpertColumn.EXPERT_COLUMN_JITTER:
    case ExpertColumn.EXPERT_COLUMN_MEDIA_EXTRACTABLE:
    case ExpertColumn.EXPERT_COLUMN_MEDIA_FLOW_COUNT:
    case ExpertColumn.EXPERT_COLUMN_MEDIA_FLOW_STATUS:
    case ExpertColumn.EXPERT_COLUMN_MEDIA_FRAME_COUNT:
    case ExpertColumn.EXPERT_COLUMN_MEDIA_PACKET_COUNT:
    case ExpertColumn.EXPERT_COLUMN_MOS_A:
    case ExpertColumn.EXPERT_COLUMN_MOS_AV:
    case ExpertColumn.EXPERT_COLUMN_MOS_CQ:
    case ExpertColumn.EXPERT_COLUMN_MOS_LOW:
    case ExpertColumn.EXPERT_COLUMN_MOS_LQ:
    case ExpertColumn.EXPERT_COLUMN_MOS_NOM:
    case ExpertColumn.EXPERT_COLUMN_MOS_PQ:
    case ExpertColumn.EXPERT_COLUMN_MOS_V:
    case ExpertColumn.EXPERT_COLUMN_NET_LATENCY:
    case ExpertColumn.EXPERT_COLUMN_NET_LATENCY_BEST:
    case ExpertColumn.EXPERT_COLUMN_NET_LATENCY_SAMPLE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_NET_LATENCY_WORST:
    case ExpertColumn.EXPERT_COLUMN_ONE_WAY_DELAY:
    case ExpertColumn.EXPERT_COLUMN_OTHER_PACKET_NUMBER:
    case ExpertColumn.EXPERT_COLUMN_PACKET_COUNT:
    case ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER:
    case ExpertColumn.EXPERT_COLUMN_PAGE_REQUEST_ID:
    case ExpertColumn.EXPERT_COLUMN_PDD:
    case ExpertColumn.EXPERT_COLUMN_PROBLEM_COUNT:
    case ExpertColumn.EXPERT_COLUMN_PROTOSPEC:
    case ExpertColumn.EXPERT_COLUMN_R_FACTOR_CONVERSATIONAL:
    case ExpertColumn.EXPERT_COLUMN_R_FACTOR_G107:
    case ExpertColumn.EXPERT_COLUMN_R_FACTOR_LISTENING:
    case ExpertColumn.EXPERT_COLUMN_R_FACTOR_NOMINAL:
    case ExpertColumn.EXPERT_COLUMN_RELATIVE_TIME:
    case ExpertColumn.EXPERT_COLUMN_REQUEST_ID:
    case ExpertColumn.EXPERT_COLUMN_REQUEST_PAYLOAD_BYTE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_RESPONSE_CODE:
    case ExpertColumn.EXPERT_COLUMN_SEQUENCE:
    case ExpertColumn.EXPERT_COLUMN_SEQUENCE_START:
    case ExpertColumn.EXPERT_COLUMN_SETUP_TIME:
    case ExpertColumn.EXPERT_COLUMN_SERVER_END_TIME:
    case ExpertColumn.EXPERT_COLUMN_SERVER_LATITUDE:
    case ExpertColumn.EXPERT_COLUMN_SERVER_LONGITUDE:
    case ExpertColumn.EXPERT_COLUMN_SERVER_PORT:
    case ExpertColumn.EXPERT_COLUMN_SERVER_SENT_BYTE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT:
    case ExpertColumn.EXPERT_COLUMN_SERVER_START_TIME:
    case ExpertColumn.EXPERT_COLUMN_SERVER_TCP_WINDOW_MAX:
    case ExpertColumn.EXPERT_COLUMN_SERVER_TCP_WINDOW_MIN:
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_GROUP_ID:
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_MINPERIOD:
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_SENSITIVITY:
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_SEVERITY:
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_VALUE:
    case ExpertColumn.EXPERT_COLUMN_SIGNALING_MSG_INDEX:
    case ExpertColumn.EXPERT_COLUMN_SIGNALING_FLOW_COUNT:
    case ExpertColumn.EXPERT_COLUMN_SIGNALING_PACKET_COUNT:
    case ExpertColumn.EXPERT_COLUMN_SOURCE_PORT:
    case ExpertColumn.EXPERT_COLUMN_STREAM_COUNT:
    case ExpertColumn.EXPERT_COLUMN_STREAM_ID:
    case ExpertColumn.EXPERT_COLUMN_TABLE_ATTRIBUTES:
    case ExpertColumn.EXPERT_COLUMN_TABLE_ID:
    case ExpertColumn.EXPERT_COLUMN_TCP_FLAGS:
    case ExpertColumn.EXPERT_COLUMN_THREE_WAY_HANDSHAKE_TIME:
    case ExpertColumn.EXPERT_COLUMN_SERVER_NETWORK_DELAY:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_NETWORK_DELAY:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_BEST:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_WORST:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_SAMPLE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_BEST:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_WORST:
    case ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_SAMPLE_COUNT:
    case ExpertColumn.EXPERT_COLUMN_TIME:
    case ExpertColumn.EXPERT_COLUMN_TREE_STATE:
    case ExpertColumn.EXPERT_COLUMN_VALUE:
    case ExpertColumn.EXPERT_COLUMN_VSAQ:
    case ExpertColumn.EXPERT_COLUMN_VSMQ:
    case ExpertColumn.EXPERT_COLUMN_VSPQ:
    case ExpertColumn.EXPERT_COLUMN_VSTQ:
    case ExpertColumn.EXPERT_COLUMN_WIRELESS_RETRY_PERCENT:
    case ExpertColumn.EXPERT_COLUMN_VOICE_LOSS_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_DISCARD_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_CODEC_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_DELAY_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_SIGNAL_LEVEL_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_NOISE_LEVEL_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_ECHO_LEVEL_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VOICE_RECENCY_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_LOSS_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_DISCARD_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_QUANTIZATION_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_BANDWIDTH_RESTRICTIONS_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RESOLUTION_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RATE_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_GOP_LENGTH_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_AVAILABLE_NETWORK_BANDWIDTH_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_DELAY_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_AV_SYNC_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_VIDEO_RECENCY_DEGRADATION:
    case ExpertColumn.EXPERT_COLUMN_TLS_HANDSHAKE_LENGTH:
      return "number"
    case ExpertColumn.EXPERT_COLUMN_APPLICATION:
    case ExpertColumn.EXPERT_COLUMN_APPLICATION_CONFIDENCE:
    case ExpertColumn.EXPERT_COLUMN_ASSERTED_IDENTITY:
    case ExpertColumn.EXPERT_COLUMN_CALL_STATUS:
    case ExpertColumn.EXPERT_COLUMN_CALLEE_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_CALLER_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_CITY:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY:
    case ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY_CODE:
    case ExpertColumn.EXPERT_COLUMN_CODEC:
    case ExpertColumn.EXPERT_COLUMN_EVENT_LAYER_VALUE:
    case ExpertColumn.EXPERT_COLUMN_COLUMN_NAME:
    case ExpertColumn.EXPERT_COLUMN_CONTENT_TYPE:
    case ExpertColumn.EXPERT_COLUMN_CONTEXT:
    case ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_DSCP:
    case ExpertColumn.EXPERT_COLUMN_END_CAUSE:
    case ExpertColumn.EXPERT_COLUMN_END_TIME:
    case ExpertColumn.EXPERT_COLUMN_EVENT_FORMAT:
    case ExpertColumn.EXPERT_COLUMN_EVENT_GROUP:
    case ExpertColumn.EXPERT_COLUMN_EVENT_GUID:
    case ExpertColumn.EXPERT_COLUMN_EVENT_MESSAGE:
    case ExpertColumn.EXPERT_COLUMN_EVENT_NAME:
    case ExpertColumn.EXPERT_COLUMN_EVENT_SEVERITY_MAX:
    case ExpertColumn.EXPERT_COLUMN_EVENT_SUB_GROUP:
    case ExpertColumn.EXPERT_COLUMN_EVENT_TIME:
    case ExpertColumn.EXPERT_COLUMN_FLAGS:
    case ExpertColumn.EXPERT_COLUMN_FLOW_TYPE:
    case ExpertColumn.EXPERT_COLUMN_FROM:
    case ExpertColumn.EXPERT_COLUMN_FROM_ABBREV:
    case ExpertColumn.EXPERT_COLUMN_GATEKEEPER_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_GATEWAY_ASSIGNED_CALL_ID:
    case ExpertColumn.EXPERT_COLUMN_HOST:
    case ExpertColumn.EXPERT_COLUMN_LAST_UPDATE_TIME:
    case ExpertColumn.EXPERT_COLUMN_MEDIA_TYPE:
    case ExpertColumn.EXPERT_COLUMN_MESSAGE:
    case ExpertColumn.EXPERT_COLUMN_NAME:
    case ExpertColumn.EXPERT_COLUMN_NODE_1:
    case ExpertColumn.EXPERT_COLUMN_NODE_2:
    case ExpertColumn.EXPERT_COLUMN_NODEPAIR:
    case ExpertColumn.EXPERT_COLUMN_PACKET_LOSS_PERCENT:
    case ExpertColumn.EXPERT_COLUMN_PROBLEM_ID:
    case ExpertColumn.EXPERT_COLUMN_PROTOCOL:
    case ExpertColumn.EXPERT_COLUMN_PROTOCOL_LAYER:
    case ExpertColumn.EXPERT_COLUMN_REFERER:
    case ExpertColumn.EXPERT_COLUMN_REQUEST_HEADER:
    case ExpertColumn.EXPERT_COLUMN_RESPONSE_HEADER:
    case ExpertColumn.EXPERT_COLUMN_RESPONSE_TEXT:
    case ExpertColumn.EXPERT_COLUMN_SEQUENCE_METHOD:
    case ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_SERVER_CITY:
    case ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY:
    case ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY_CODE:
    case ExpertColumn.EXPERT_COLUMN_SIGNALING_TYPE:
    case ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS:
    case ExpertColumn.EXPERT_COLUMN_SSRC:
    case ExpertColumn.EXPERT_COLUMN_START_TIME:
    case ExpertColumn.EXPERT_COLUMN_STATUS:
    case ExpertColumn.EXPERT_COLUMN_TABLE_NAME:
    case ExpertColumn.EXPERT_COLUMN_TCP_STATUS:
    case ExpertColumn.EXPERT_COLUMN_TO:
    case ExpertColumn.EXPERT_COLUMN_TO_ABBREV:
    case ExpertColumn.EXPERT_COLUMN_URI:
    case ExpertColumn.EXPERT_COLUMN_TLS_VERSION:
    case ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_BEFORE:
    case ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_AFTER:
    case ExpertColumn.EXPERT_COLUMN_MPLS:
    case ExpertColumn.EXPERT_COLUMN_VLAN:
    case ExpertColumn.EXPERT_COLUMN_VXLAN_GPID:
    case ExpertColumn.EXPERT_COLUMN_VXLAN_VNI:
      return "string"
    case ExpertColumn.EXPERT_COLUMN_PROBLEM_SUMMARY_LIST:
    case ExpertColumn.EXPERT_COLUMN_REQUEST_ID_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTCP_FLAG_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTCP_PACKET_NUMBER_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_BYTE_COUNT_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_FLAG_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_JITTER_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_JITTER_MICRO_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_MEDIA_TYPE_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_PACKET_NUMBER_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_Q1_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_Q2_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_Q3_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_Q4_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_Q5_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_Q6_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_Q7_LIST:
      return "array[number]"
    case ExpertColumn.EXPERT_COLUMN_RTCP_TIME_LIST:
    case ExpertColumn.EXPERT_COLUMN_RTP_TIME_LIST:
      return "array[string]"
    case ExpertColumn.EXPERT_COLUMN_SETTINGS_ENABLED:
      return "boolean"
    default:
      break
  }

  return "undefined"
}

export function getExpertHeaderCounts(
  resultSet: ExpertQueryResponse | null
): ExpertHeaderCountsObj | null {
  if (resultSet && Array.isArray(resultSet.results) && resultSet.results.length > 1) {
    const counts: ExpertHeaderCountsObj = {
      eventsDetected: 0,
      flowsAnalyzed: 0,
      flowsRecycled: 0,
      packetsDropped: 0,
    }
    const headerCounters = resultSet.results[1]
    if (Array.isArray(headerCounters.rowList)) {
      for (let i = 0, len = headerCounters.rowList.length; i < len; i++) {
        const row = headerCounters.rowList[i]
        if (Array.isArray(row) && row.length === 2) {
          if (row[0].value === "STREAM_COUNT_CURRENT") {
            counts.flowsAnalyzed += row[1].value as number
          } else if (row[0].value === "STREAM_COUNT_RECYCLED") {
            counts.flowsAnalyzed += row[1].value as number
            counts.flowsRecycled = row[1].value as number
          } else if (row[0].value === "EVENT_COUNT_CURRENT") {
            counts.eventsDetected += row[1].value as number
          } else if (row[0].value === "EVENT_COUNT_RECYCLED") {
            counts.eventsDetected += row[1].value as number
          } else if (row[0].value === "PACKET_COUNT_DROPPED") {
            counts.packetsDropped = row[1].value as number
          }
        }
      }
    }
    return counts
  } else {
    return null
  }
}

export function getExpertMessage(
  expertDescription: ExpertDescription,
  expertProblem: ExpertProblemObject,
  expertStringTable: ExpertStringTable[],
  otherPacketNumber: number,
  period: number,
  periodMin: number,
  roleClient: string,
  roleServer: string,
  valueThreshold: number,
  text: string,
  isClientPacket: boolean
): string {
  let message = expertDescription.message

  // perform substitution, if there's a chance the message might contain replacement strings
  if (message.search("%") !== -1) {
    // Perform string table substitutions like %dns-error-value%
    //
    // Perform string table substitutions FIRST, so that we can perform secondary substitutions
    // for things like wireless association "%value%: Cannot Support all requested capabilities"
    // which becomes "10: Cannot Support all requested capabilities"
    expertStringTable.forEach((stringTable: ExpertStringTable) => {
      const keySubstitution = `%${stringTable.id}%`
      if (message.search(keySubstitution) !== -1) {
        const stringTableValue = stringTable.values.find(
          (value: ExpertStringTableValue) => value.id === expertProblem.value
        )
        if (stringTableValue !== undefined) {
          message = message.replace(keySubstitution, stringTableValue.value)
        }
      }
    })

    // %if-other%...%fi-other%
    // suppress stuff that only applies of there is another packet
    const ifOtherPattern = "%if-other%"
    const fiOtherPattern = "%fi-other%"
    if (otherPacketNumber === 0) {
      // no other packet? just remove the placeholder
      const ifOtherIndex = message.indexOf(ifOtherPattern)
      const fiOtherIndex = message.indexOf(fiOtherPattern)
      if (ifOtherIndex !== -1 && fiOtherIndex !== -1) {
        message =
          message.substring(0, ifOtherIndex) +
          message.substring(fiOtherIndex + fiOtherPattern.length)
      }
    }
    message = message.replace(ifOtherPattern, "")
    message = message.replace(fiOtherPattern, "")
    message = message.replace("%other-packet-number%", otherPacketNumber.toString())

    const valuePattern = "%value%"
    if (message.search(valuePattern) !== -1 && expertProblem.value !== null) {
      if (expertDescription.valueDisplayFormat.length === 0) {
        message = message.replace(valuePattern, expertProblem.value.toString())
      } else {
        const displayValue = expertProblem.value * expertDescription.valueDisplayMultiplier
        message = message.replace(valuePattern, displayValue.toString())
      }
    }

    const signedValuePattern = "%signed-value%"
    if (expertProblem.value !== null) {
      message = message.replace(signedValuePattern, expertProblem.value.toString())
    }

    const valueMaxPattern = "%value-max%"
    if (expertDescription.valueMax !== undefined) {
      message = message.replace(valueMaxPattern, expertDescription.valueMax.toString())
    } else {
      message = message.replace(valueMaxPattern, "")
    }

    const valueThresholdPattern = "%value-threshold%"
    if (expertDescription.valueDisplayFormat.length === 0) {
      message = message.replace(valueThresholdPattern, valueThreshold.toString())
    } else {
      const displayValue = valueThreshold * expertDescription.valueDisplayMultiplier
      message = message.replace(valueThresholdPattern, displayValue.toString())
    }

    const signedValueThresholdPattern = "%signed-value-threshold%"
    message = message.replace(signedValueThresholdPattern, valueThreshold.toString())

    // %see-other-packet% only inserted if we have another packet
    const seeOtherPacketPattern = "%see-other-packet%"
    if (otherPacketNumber === 0) {
      // no other packet? just remove the placeholder
      message = message.replace(seeOtherPacketPattern, "")
    } else {
      // insert formatted packet number into "(see packet %s)"
      message = message.replace(seeOtherPacketPattern, `(see packet ${otherPacketNumber})`)
    }

    // replace %text% with m_strText
    message = message.replace("%text%", text)

    message = message.replace("%period%", formatDuration(period))

    // format duration string
    message = message.replace("%period-min%", formatDuration(periodMin))

    // format time string
    message = message.replace("%period-timestamp%", "")

    const sourceRolePattern = "%source-role%"
    if (isClientPacket) {
      message = message.replace(sourceRolePattern, roleClient)
    } else {
      message = message.replace(sourceRolePattern, roleServer)
    }

    const clientRolePattern = "%client-role%"
    if (isClientPacket) {
      message = message.replace(clientRolePattern, roleServer)
    } else {
      message = message.replace(clientRolePattern, roleClient)
    }
  }

  return message
}

export function getExpertQuery(query: ExpertQueryRequest[], view: ExpertView) {
  if (Array.isArray(query)) {
    return query.find(q => q.view === view)
  }
  return undefined
}

export function getExpertResults(results: ExpertResponse[], view: ExpertView) {
  if (Array.isArray(results)) {
    return results.find(r => r.view === view)
  }
  return undefined
}

export function getExpertValueFromRowData(
  rowData: ExpertValue[],
  columnList: ExpertColumn[],
  columnId: ExpertColumn
) {
  const columnIndex = columnList.indexOf(columnId)
  if (columnIndex >= 0 && columnIndex < rowData.length) {
    return rowData[columnIndex]
  }
  return undefined
}

export function getVoIPHeaderCounts(
  resultSet: ExpertQueryResponse | null
): VoIPHeaderCountsObj | null {
  if (resultSet && Array.isArray(resultSet.results) && resultSet.results.length > 1) {
    const counts: VoIPHeaderCountsObj = { currentCalls: 0, mediaFlows: 0, totalCalls: 0 }
    const headerCounters = resultSet.results[1]
    if (Array.isArray(headerCounters.rowList)) {
      for (let i = 0, len = headerCounters.rowList.length; i < len; i++) {
        const row = headerCounters.rowList[i]
        if (Array.isArray(row) && row.length === 2) {
          if (row[0].value === "VOIP_CALL_COUNT") {
            counts.currentCalls = row[1].value as number
          } else if (row[0].value === "VOIP_MEDIA_FLOW_COUNT") {
            counts.mediaFlows = row[1].value as number
          } else if (row[0].value === "VOIP_TOTAL_CALL_COUNT") {
            counts.totalCalls = row[1].value as number
          }
        }
      }
    }
    return counts
  } else {
    return null
  }
}

export function getWebHeaderCounts(
  resultSet: ExpertQueryResponse | null
): WebHeaderCountsObj | null {
  if (resultSet && Array.isArray(resultSet.results) && resultSet.results.length > 1) {
    const counts: WebHeaderCountsObj = { servers: 0, clients: 0, pages: 0, requests: 0 }
    const headerCounters = resultSet.results[1]
    if (Array.isArray(headerCounters.rowList)) {
      for (let i = 0, len = headerCounters.rowList.length; i < len; i++) {
        const row = headerCounters.rowList[i]
        if (Array.isArray(row) && row.length === 2) {
          if (row[0].value === "HTTP_SERVER_COUNT") {
            counts.servers = row[1].value as number
          } else if (row[0].value === "HTTP_CLIENT_COUNT") {
            counts.clients = row[1].value as number
          } else if (row[0].value === "HTTP_PAGE_COUNT") {
            counts.pages = row[1].value as number
          } else if (row[0].value === "HTTP_REQUEST_COUNT") {
            counts.requests = row[1].value as number
          }
        }
      }
    }
    return counts
  } else {
    return null
  }
}

export const parseHeaders = (
  arrayToSplit: Array<string>,
  propertiesArray: any,
  fieldArray: Array<string>
) => {
  let previousField: string = ""
  arrayToSplit.forEach(item => {
    const [field, data] = item.split(": ", 2)

    // New field is found
    if (field !== "") {
      if (field in propertiesArray) {
        if (Array.isArray(propertiesArray[field])) {
          propertiesArray[field].push(data)
        } else {
          propertiesArray[field] = [propertiesArray[field], data]
        }
      } else {
        propertiesArray[field] = data
      }
      fieldArray.push(field)
      previousField = field
    } else {
      // Multi line case. The data is in the field section. Add to previous field data
      propertiesArray[previousField].concat(field)
    }
  })
}

export function formatHeaderProp(prop: string, data: any) {
  if (Array.isArray(data[prop])) {
    return data[prop].length > 0 ? data[prop].shift() : ""
  } else {
    return data[prop]
  }
}

export function isValidExpertPolicy(policy: any): boolean {
  let valid: boolean = true
  if (
    policy &&
    policy.authentication !== undefined &&
    policy.channel !== undefined &&
    policy.encryption !== undefined &&
    policy.essId !== undefined &&
    policy.vendorId !== undefined
  ) {
    valid =
      valid &&
      policy.authentication.accept !== undefined &&
      typeof policy.authentication.accept === "boolean" &&
      Array.isArray(policy.authentication.items) &&
      policy.authentication.items.every(
        (item: any) =>
          item.enabled !== undefined &&
          typeof item.enabled === "boolean" &&
          item.type !== undefined &&
          typeof item.type === "number" &&
          isValidExpertAuthenticationType(item.type)
      )

    valid =
      valid &&
      policy.channel.accept !== undefined &&
      typeof policy.channel.accept === "boolean" &&
      policy.channel.channelBand !== undefined &&
      typeof policy.channel.channelBand === "string" &&
      Array.isArray(policy.channel.channelFamily) &&
      policy.channel.channelFamily.every(
        (family: any) =>
          Array.isArray(family.items) &&
          family.items.every(
            (familyItem: any) =>
              familyItem.band !== undefined &&
              typeof familyItem.band === "number" &&
              familyItem.band >= 0 &&
              familyItem.channel !== undefined &&
              typeof familyItem.channel === "number" &&
              familyItem.enabled !== undefined &&
              typeof familyItem.enabled === "boolean" &&
              familyItem.frequency !== undefined &&
              typeof familyItem.frequency === "number" &&
              familyItem.frequency >= 0
          )
      )

    valid =
      valid &&
      policy.encryption.accept !== undefined &&
      typeof policy.encryption.accept === "boolean" &&
      Array.isArray(policy.encryption.items) &&
      policy.encryption.items.every(
        (item: any) =>
          item.enabled !== undefined &&
          typeof item.enabled === "boolean" &&
          item.type !== undefined &&
          typeof item.type === "number" &&
          isValidExpertEncryptionType(item.type)
      )

    valid =
      valid &&
      policy.essId.accept !== undefined &&
      typeof policy.essId.accept === "boolean" &&
      Array.isArray(policy.essId.items) &&
      policy.essId.items.every(
        (item: any) =>
          item.enabled !== undefined &&
          typeof item.enabled === "boolean" &&
          item.value !== undefined &&
          typeof item.value === "string"
      )

    valid =
      valid &&
      policy.vendorId.accept !== undefined &&
      typeof policy.vendorId.accept === "boolean" &&
      Array.isArray(policy.vendorId.items) &&
      policy.vendorId.items.every(
        (item: any) =>
          item.accessPoint !== undefined &&
          typeof item.accessPoint === "boolean" &&
          item.client !== undefined &&
          typeof item.client === "boolean" &&
          item.value !== undefined &&
          typeof item.value === "string"
      )
  } else {
    valid = false
  }
  return valid
}

export function isValidExpertProblems(
  problems: any[],
  expertDescriptions: ExpertDescription[]
): boolean {
  return (
    Array.isArray(problems) &&
    problems.every((problem: any) => {
      let valid: boolean =
        problem.enabled !== undefined &&
        typeof problem.enabled === "boolean" &&
        problem.id !== undefined &&
        typeof problem.id === "number" &&
        isValidExpertProblem(problem.id) &&
        problem.minimumSample !== undefined &&
        (problem.minimumSample === null ||
          (typeof problem.minimumSample === "number" && problem.sensitivity !== undefined)) &&
        (problem.sensitivity === null ||
          (typeof problem.sensitivity === "number" &&
            isValidExpertSensitivity(problem.sensitivity))) &&
        problem.settingGroupId !== undefined &&
        typeof problem.settingGroupId === "number" &&
        isValidExpertSettingGroupId(problem.settingGroupId) &&
        problem.severity !== undefined &&
        typeof problem.severity === "number" &&
        isValidExpertSeverity(problem.severity) &&
        problem.value !== undefined &&
        (problem.value === null || typeof problem.value === "number")
      const expertDescription = expertDescriptions.find(
        (description: ExpertDescription) => description.problemId === problem.id
      )
      if (expertDescription !== undefined) {
        if (
          expertDescription.hasMinSamplePeriod &&
          expertDescription.minSamplePeriodMax !== undefined &&
          expertDescription.minSamplePeriodMin !== undefined
        ) {
          const minimumSample = generateExpertMinSamplePeriod(
            problem.minimumSample,
            expertDescription
          )
          valid =
            valid &&
            minimumSample >= expertDescription.minSamplePeriodMin &&
            minimumSample <= expertDescription.minSamplePeriodMax
        }
        if (
          expertDescription.hasValue &&
          expertDescription.valueMax !== undefined &&
          expertDescription.valueMin !== undefined
        ) {
          const valueObj = generateExpertValue(problem.value, expertDescription)
          valid =
            valid &&
            valueObj.value >= expertDescription.valueMin &&
            valueObj.value <= expertDescription.valueMax
        }
      }
      return valid
    })
  )
}

export function isValidExpertSettingsFile(
  jsonSettingsFile: any,
  expertDescriptions: ExpertDescription[]
): boolean {
  let valid: boolean = true
  if (
    jsonSettingsFile &&
    jsonSettingsFile.current !== undefined &&
    jsonSettingsFile._default !== undefined
  ) {
    valid =
      isValidExpertSettingsGroup(jsonSettingsFile.current, expertDescriptions) &&
      isValidExpertSettingsGroup(jsonSettingsFile._default, expertDescriptions)
  } else {
    valid = false
  }
  return valid
}

export function isValidExpertSettingsGroup(
  settingsGroup: any,
  expertDescriptions: ExpertDescription[]
): boolean {
  let valid: boolean = true
  if (
    settingsGroup &&
    settingsGroup.maxStreamCount !== undefined &&
    settingsGroup.policy !== undefined &&
    Array.isArray(settingsGroup.problems)
  ) {
    valid =
      typeof settingsGroup.maxStreamCount === "number" &&
      settingsGroup.maxStreamCount >= 1 &&
      isValidExpertPolicy(settingsGroup.policy) &&
      isValidExpertProblems(settingsGroup.problems, expertDescriptions)
  } else {
    valid = false
  }
  return valid
}

export function selectRelatedExpertRequestFromRowData(
  command: string,
  rowData: ExpertValue[],
  columnList: ExpertColumn[]
) {
  let request: RequestPostSelectRelatedExpertStart | null = null
  switch (command) {
    case "client":
      {
        const clientAddr = getExpertValueFromRowData(
          rowData,
          columnList,
          ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS
        )
        if (clientAddr != null && typeof clientAddr.value === "object") {
          request = {
            criteria: [
              {
                clientAddress: clientAddr.value as any,
              },
            ],
          }
        }
      }
      break
    case "server":
      {
        const serverAddr = getExpertValueFromRowData(
          rowData,
          columnList,
          ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS
        )
        if (serverAddr != null && typeof serverAddr.value === "object") {
          request = {
            criteria: [
              {
                serverAddress: serverAddr.value as any,
              },
            ],
          }
        }
      }
      break
    case "clientAndServer":
      {
        const clientAddr = getExpertValueFromRowData(
          rowData,
          columnList,
          ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS
        )
        const serverAddr = getExpertValueFromRowData(
          rowData,
          columnList,
          ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS
        )
        if (
          clientAddr != null &&
          typeof clientAddr.value === "object" &&
          serverAddr != null &&
          typeof serverAddr.value === "object"
        ) {
          request = {
            criteria: [
              {
                clientAddress: clientAddr.value as any,
                serverAddress: serverAddr.value as any,
              },
            ],
          }
        }
      }
      break
    case "port":
      {
        const clientPort = getExpertValueFromRowData(
          rowData,
          columnList,
          ExpertColumn.EXPERT_COLUMN_CLIENT_PORT
        )
        const serverPort = getExpertValueFromRowData(
          rowData,
          columnList,
          ExpertColumn.EXPERT_COLUMN_SERVER_PORT
        )
        if (
          clientPort != null &&
          typeof clientPort.value === "number" &&
          serverPort != null &&
          typeof serverPort.value === "number"
        ) {
          request = {
            criteria: [
              {
                clientPort: clientPort.value,
                serverPort: serverPort.value,
              },
            ],
          }
        }
      }
      break
    case "flowId":
      {
        const streamId = getExpertValueFromRowData(
          rowData,
          columnList,
          ExpertColumn.EXPERT_COLUMN_STREAM_ID
        )
        if (streamId != null && typeof streamId.value === "number") {
          request = {
            criteria: [
              {
                streamId: streamId.value,
              },
            ],
          }
        }
      }
      break
    case "problemId":
      {
        const streamId = getExpertValueFromRowData(
          rowData,
          columnList,
          ExpertColumn.EXPERT_COLUMN_STREAM_ID
        )
        const problemId = getExpertValueFromRowData(
          rowData,
          columnList,
          ExpertColumn.EXPERT_COLUMN_PROBLEM_ID
        )
        if (
          streamId != null &&
          typeof streamId.value === "number" &&
          problemId != null &&
          typeof problemId.value === "number"
        ) {
          request = {
            criteria: [
              {
                streamId: streamId.value,
                problemId: problemId.value,
              },
            ],
          }
        }
      }
      break
    default:
      break
  }
  return request
}

export function selectRelatedWebRequestFromRowData(
  command: string,
  rowData: ExpertValue[],
  columnList: ExpertColumn[]
) {
  let request: RequestPostSelectRelatedWebStart | null = null
  switch (command) {
    case "request":
      {
        const requestId = getExpertValueFromRowData(
          rowData,
          columnList,
          ExpertColumn.EXPERT_COLUMN_REQUEST_ID
        )
        if (requestId != null && typeof requestId.value === "number") {
          request = {
            requestIds: [requestId.value as any],
            selectRelatedBy: WebSelectRelatedParam.WEB_SELECT_RELATED_BY_REQUEST,
          }
        }
      }
      break
    case "response":
      {
        const requestId = getExpertValueFromRowData(
          rowData,
          columnList,
          ExpertColumn.EXPERT_COLUMN_REQUEST_ID
        )
        if (requestId != null && typeof requestId.value === "number") {
          request = {
            requestIds: [requestId.value as any],
            selectRelatedBy: WebSelectRelatedParam.WEB_SELECT_RELATED_BY_RESPONSE,
          }
        }
      }
      break
    case "requestAndResponse":
      {
        const requestId = getExpertValueFromRowData(
          rowData,
          columnList,
          ExpertColumn.EXPERT_COLUMN_REQUEST_ID
        )
        if (requestId != null && typeof requestId.value === "number") {
          request = {
            requestIds: [requestId.value as any],
            selectRelatedBy: WebSelectRelatedParam.WEB_SELECT_RELATED_BY_REQUEST_AND_RESPONSE,
          }
        }
      }
      break
    default:
      break
  }
  return request
}

export function stringToAuthenticationType(value: string): ExpertAuthenticationType {
  let type: ExpertAuthenticationType = ExpertAuthenticationType.EXPERT_AUTHENTICATION_TYPE_NONE

  switch (value) {
    case "EAP":
      type = ExpertAuthenticationType.EXPERT_AUTHENTICATION_TYPE_EAP
      break
    case "LEAP":
      type = ExpertAuthenticationType.EXPERT_AUTHENTICATION_TYPE_LEAP
      break
    case "PEAP":
      type = ExpertAuthenticationType.EXPERT_AUTHENTICATION_TYPE_PEAP
      break
    case "EAPTLS":
      type = ExpertAuthenticationType.EXPERT_AUTHENTICATION_TYPE_EAPTLS
      break
    case "None":
    default:
      type = ExpertAuthenticationType.EXPERT_AUTHENTICATION_TYPE_NONE
      break
  }

  return type
}

export function stringToEncryptionType(value: string): ExpertEncryptionType {
  let type: ExpertEncryptionType = ExpertEncryptionType.EXPERT_ENCRYPTION_TYPE_NONE

  switch (value) {
    case "WEP":
      type = ExpertEncryptionType.EXPERT_ENCRYPTION_TYPE_WEP
      break
    case "CKIP":
      type = ExpertEncryptionType.EXPERT_ENCRYPTION_TYPE_CKIP
      break
    case "TKIP":
      type = ExpertEncryptionType.EXPERT_ENCRYPTION_TYPE_TKIP
      break
    case "CCMP":
      type = ExpertEncryptionType.EXPERT_ENCRYPTION_TYPE_CCMP
      break
    case "None":
    default:
      type = ExpertEncryptionType.EXPERT_ENCRYPTION_TYPE_NONE
      break
  }

  return type
}

export function valueAssistToValue(
  inValue: number,
  left: number,
  right: number,
  logScale: boolean
): number {
  const range = right - left
  const value = inValue - left
  let slider = 0
  if (!logScale) {
    const ratio = 1000 / range
    slider = value * ratio
  } else {
    const logValue = value < 1 ? 0 : Math.log10(value)
    const logRange = Math.abs(range) < 1 ? 0 : Math.log10(Math.abs(range))
    const ratio = 1000 / logRange
    slider = logValue * ratio
  }
  return Math.trunc(slider)
}

export function valueToValueAssist(
  inValue: number,
  left: number,
  right: number,
  logScale: boolean
): number {
  const ratio = inValue / 1000
  const range = right - left
  const offset = left
  let value = 0
  if (!logScale) {
    value = Math.trunc(ratio * range + offset)
  } else {
    const logRange = Math.abs(range) < 1 ? 0 : Math.log10(Math.abs(range))
    const exponent = ratio * logRange
    const base = 10
    value = Math.trunc(Math.pow(base, exponent))
    // fudge for visible roundoff errors near boundaries
    if (inValue === 0) {
      value = left
    } else if (inValue === 1000) {
      value = right
    }
  }
  return value
}

export function isMSAEnabled(rowData: ExpertValue[], columnList: ExpertColumn[]): boolean {
  const rowType = getExpertValueFromRowData(rowData, columnList, ExpertColumn.EXPERT_COLUMN_TYPE)
  if (rowType != null && typeof rowType.value === "number") {
    switch (rowType.value) {
      case ExpertRowType.EXPERT_ROW_TYPE_APPLICATION:
        {
          const application = getExpertValueFromRowData(
            rowData,
            columnList,
            ExpertColumn.EXPERT_COLUMN_APPLICATION
          )
          if (application && typeof application.rendered === "string") {
            return true
          }
        }
        break

      case ExpertRowType.EXPERT_ROW_TYPE_CLIENT:
        {
          const clientAddr = getExpertValueFromRowData(
            rowData,
            columnList,
            ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS
          )
          if (clientAddr != null && clientAddr.value != null) {
            const clientAddrSpec = clientAddr.value as MediaSpec
            if (clientAddrSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS) {
              const flowType = getExpertValueFromRowData(
                rowData,
                columnList,
                ExpertColumn.EXPERT_COLUMN_FLOW_TYPE
              )
              if (
                flowType == null ||
                flowType.value == null ||
                (typeof flowType.value === "number" &&
                  flowType.value === ExpertFlowType.EXPERT_FLOW_TYPE_TCP)
              ) {
                return true
              }
            }
          } else {
            return true
          }
        }
        break

      case ExpertRowType.EXPERT_ROW_TYPE_SERVER:
        {
          const serverAddr = getExpertValueFromRowData(
            rowData,
            columnList,
            ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS
          )
          if (serverAddr != null && serverAddr.value != null) {
            const serverAddrSpec = serverAddr.value as MediaSpec
            if (serverAddrSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS) {
              const flowType = getExpertValueFromRowData(
                rowData,
                columnList,
                ExpertColumn.EXPERT_COLUMN_FLOW_TYPE
              )
              if (
                flowType == null ||
                flowType.value == null ||
                (typeof flowType.value === "number" &&
                  flowType.value === ExpertFlowType.EXPERT_FLOW_TYPE_TCP)
              ) {
                return true
              }
            }
          } else {
            return true
          }
        }
        break

      default: {
        const clientAddr = getExpertValueFromRowData(
          rowData,
          columnList,
          ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS
        )
        if (clientAddr != null && clientAddr.value != null) {
          const clientAddrSpec = clientAddr.value as MediaSpec
          if (clientAddrSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS) {
            const serverAddr = getExpertValueFromRowData(
              rowData,
              columnList,
              ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS
            )
            if (serverAddr != null && serverAddr.value != null) {
              const serverAddrSpec = serverAddr.value as MediaSpec
              if (serverAddrSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS) {
                const flowType = getExpertValueFromRowData(
                  rowData,
                  columnList,
                  ExpertColumn.EXPERT_COLUMN_FLOW_TYPE
                )
                if (
                  flowType == null ||
                  flowType.value == null ||
                  (typeof flowType.value === "number" &&
                    flowType.value === ExpertFlowType.EXPERT_FLOW_TYPE_TCP)
                ) {
                  return true
                }
              }
            }
          }
        }
      }
    }
  }
  return false
}
