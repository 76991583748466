import * as React from "react"
import { Helmet } from "react-helmet"
import { Redirect, useHistory, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { ButtonGroup } from "reactstrap"
import SplitterLayout from "react-splitter-layout"
import { Alert } from "../common/Alert"
import { BarGauge } from "../common/BarGauge"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CenterContent } from "../common/Layout"
import ConfirmationModal from "../common/ConfirmationModal"
import FilterBox from "../common/FilterBox"
import ForensicSearchModal from "../ForensicSearchModal"
import { InsertNamesModal, InsertNameEntry } from "../InsertNamesModal"
import { LightButton, LightDangerButton, TabButton } from "../common/Buttons"
import { Panel } from "../common/Panel"
import { Spinner } from "../common/Spinner"
import { View, ViewContent, ViewHeader, ViewHeaderTitle, ViewHeaderButtons } from "../common/View"
import {
  getEngineForensicSearchUrl,
  getEngineNewFilterUrl,
  getMultiSegmentAnalysisProjectOptionsUrl,
  getMultiSegmentAnalysisProjectUrl,
  getMultiSegmentAnalysisUrl,
} from "../../routes"
import {
  getCapabilities,
  getMSAFlowsFilter,
  getMSAViewType,
  getServer,
  getServerAuthToken,
  getServerNamesModificationTime,
  getUserId,
} from "../../store"
import { setCurrentEngine } from "../../store/engines"
import { updateServerStatus } from "../../store/status"
import { setMSAViewType, setMSAFlowsFilter } from "../../store/ui"
import * as API from "../../api/api"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import {
  AddressResolverRequestEntry,
  Filter,
  RequestCreateForensicSearch,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import FlowsTable, { FlowsTableAction } from "./FlowsTable"
import SegmentsView from "./SegmentsView"
import LadderDiagram from "./LadderDiagram"

const ViewHeaderButtonStrip = styled.div`
  display: flex;

  & > .btn {
    white-space: nowrap;
    flex-grow: 1;
  }

  & > * + * {
    margin-left: 4px;
  }
`

const ViewContentWrapper = styled(ViewContent)`
  position: relative;

  & .splitter-layout .layout-pane {
    overflow: hidden;
  }

  & .splitter-layout > .layout-splitter {
    width: 5px;
    background-color: ${props => props.theme.dividerColor} !important;
    transition: background-color 0.15s ease-in-out;
  }

  & .splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: 100%;
    height: 5px;
  }

  & .layout-splitter:hover,
  & .splitter-layout.layout-changing > .layout-splitter {
    background-color: ${props => props.theme.dividerHoverColor} !important;
  }
`

const ProgressStyle = styled(Panel)`
  display: flex;
  flex-direction: column;
  min-width: 319px;
  margin: 1rem auto 0 auto;
  padding: 3rem;
`

const ProgressText = styled.div`
  text-align: center;
`

type ForensicSearchParams = {
  name: string
  startTime: string
  endTime: string
  file: string
}

type RouteParams = {
  id: string
}

const ProjectView = () => {
  const { id } = useParams<RouteParams>()
  const dispatch = useDispatch()
  const history = useHistory()
  const queryClient = useQueryClient()
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const namesModTime = useSelector(getServerNamesModificationTime)
  const userId = useSelector(getUserId)
  const engineCapabilities: ResponseGetEngineCapabilities = useSelector(getCapabilities)
  const viewFilter = useSelector(getMSAFlowsFilter) || ""
  const viewType = useSelector<"segments" | "ladder">(getMSAViewType) || "segments"
  const [error, setError] = React.useState<string | null>(null)
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false)
  const [forensicSearchParams, setForensicSearchParams] =
    React.useState<ForensicSearchParams | null>(null)
  const [insertNameEntries, setInsertNameEntries] = React.useState<InsertNameEntry[] | null>(null)
  const [checkedFlowEntryIndex, setCheckedFlowEntryIndex] = React.useState(0)
  const [flowsTableAction, setFlowsTableAction] = React.useState<FlowsTableAction | undefined>()
  const invalidateOnNamesModTime = React.useRef(false)

  const { data: project, error: queryError } = useQuery({
    staleTime: Infinity,
    refetchInterval: query => {
      // Refetch continuously until complete.
      return query.state.data?.progress === 100 ? false : 2500
    },
    queryKey: ["msa", id],
    queryFn: () => {
      return API.fetchMSAProject(server, serverAuthToken, id)
    },
  })

  React.useEffect(() => {
    if (queryError && "code" in queryError && "reason" in queryError) {
      setError(`${queryError.code} ${queryError.reason}`)
    }
  }, [queryError])

  const { mutate: deleteMSAProject, isPending: isDeletePending } = useMutation({
    mutationFn: (id: string) => {
      setError(null)
      return API.deleteMSAProject(server, serverAuthToken, id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["msa", id] })
    },
    onError: () => {
      setError("Delete failed")
    },
  })

  const { mutate: stopMSAProject } = useMutation({
    mutationFn: (id: string) => {
      setError(null)
      return API.stopMSAProject(server, serverAuthToken, id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["msa", id] })
    },
    onError: () => {
      setError("Failed to stop analysis")
    },
  })

  React.useEffect(() => {
    // Avoid triggering a refetch on first render.
    if (!invalidateOnNamesModTime.current) {
      invalidateOnNamesModTime.current = true
      return
    }
    queryClient.invalidateQueries({ queryKey: ["msa", id] })
  }, [namesModTime, queryClient, id])

  const onAnalysisOptions = () => {
    history.push(getMultiSegmentAnalysisProjectOptionsUrl(id))
  }

  const onStop = () => {
    stopMSAProject(id)
  }

  const onDelete = () => {
    setShowDeleteConfirm(true)
  }

  const onDeleteCancel = () => {
    setShowDeleteConfirm(false)
  }

  const onDeleteOK = () => {
    setShowDeleteConfirm(false)
    deleteMSAProject(id)
  }

  const onExpandAll = () => {
    setFlowsTableAction({ type: "EXPAND_ALL" })
  }

  const onCollapseAll = () => {
    setFlowsTableAction({ type: "COLLAPSE_ALL" })
  }

  const onChangeViewType = (event: React.MouseEvent<HTMLButtonElement>) => {
    const newViewType = event.currentTarget.name as "segments" | "ladder"
    dispatch(setMSAViewType(newViewType))
  }

  const onChangeViewFilter = (filter: string) => {
    dispatch(setMSAFlowsFilter(filter))
  }

  const openSegmentFile = (segmentIndex: number) => {
    if (project && segmentIndex >= 0 && segmentIndex < project.segments.length) {
      const segment = project.segments[segmentIndex]
      setForensicSearchParams({
        name: segment.name,
        startTime: segment.startTime,
        endTime: segment.endTime,
        file: segment.file,
      })
    }
  }

  const onForensicSearchCancel = () => {
    setForensicSearchParams(null)
  }

  const onForensicSearchOK = (query: RequestCreateForensicSearch) => {
    setForensicSearchParams(null)
    API.createForensicSearch(server, serverAuthToken, query)
      .then(response => {
        dispatch(setCurrentEngine(null))
        history.push(getEngineForensicSearchUrl(response.id))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const onMakeFilter = (filter: Filter) => {
    dispatch(setCurrentEngine(null))
    history.push({
      pathname: getEngineNewFilterUrl(),
      state: { filter },
    })
  }

  const onResolveNames = (entries: AddressResolverRequestEntry[]) => {
    if (entries.length > 0) {
      API.resolveAddresses(server, serverAuthToken, entries).catch(error => {
        console.error(error)
      })
    }
  }

  // Make sure the user has permissions.
  let canCreateForensicSearch = true
  let canDeleteForensicSearches = true
  if (engineCapabilities) {
    const policies = engineCapabilities.userRights.policies
    if (engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL)) {
      canDeleteForensicSearches = policies.includes(EngineUserPolicies.deleteForensicSearches)
      canCreateForensicSearch = policies.includes(EngineUserPolicies.createForensicSearch)
    }
    if (userId !== project?.owner && !policies.includes(EngineUserPolicies.viewStats)) {
      return <Redirect to={`${getMultiSegmentAnalysisUrl()}`} />
    }
  }

  const name = project?.name ?? ""
  const count = project?.flowEntries?.length

  return (
    <View>
      <Helmet title={name} />
      <BreadcrumbItem to={getMultiSegmentAnalysisUrl()} title="Multi-Segment Analysis Projects" />
      {name && <BreadcrumbItem to={getMultiSegmentAnalysisProjectUrl(id)} title={name} />}
      {project ? (
        <>
          {project.progress === 100 ? (
            <>
              <ViewHeader>
                <ViewHeaderTitle title="Flows" count={count} />
                <ViewHeaderButtons>
                  <ViewHeaderButtonStrip>
                    <FilterBox
                      id="msa-flows-filter"
                      aria-label="Search"
                      placeholder="Search"
                      onChange={onChangeViewFilter}
                      value={viewFilter}
                    />
                    <ButtonGroup>
                      <TabButton
                        name="segments"
                        onClick={onChangeViewType}
                        active={viewType === "segments"}
                      >
                        Flow Map
                      </TabButton>
                      <TabButton
                        name="ladder"
                        onClick={onChangeViewType}
                        active={viewType === "ladder"}
                      >
                        Ladder
                      </TabButton>
                    </ButtonGroup>
                    <LightButton id="expand-all" onClick={onExpandAll}>
                      Expand All
                    </LightButton>
                    <LightButton id="collapse-all" onClick={onCollapseAll}>
                      Collapse All
                    </LightButton>
                    <LightDangerButton
                      id="delete"
                      disabled={
                        isDeletePending || (!canDeleteForensicSearches && userId !== project.owner)
                      }
                      onClick={onDelete}
                    >
                      <FontAwesome
                        fixedWidth
                        name={isDeletePending ? "circle-o-notch" : "trash-o"}
                        spin={isDeletePending}
                      />{" "}
                      Delete Project
                    </LightDangerButton>
                    <LightButton onClick={onAnalysisOptions}>
                      <FontAwesome fixedWidth name="gear" /> Analysis Options
                    </LightButton>
                  </ViewHeaderButtonStrip>
                </ViewHeaderButtons>
              </ViewHeader>

              <ViewContentWrapper>
                {project.error ? <Alert color="danger">{project.error}</Alert> : null}
                {project.flowEntries && project.segmentInfo ? (
                  <SplitterLayout
                    vertical
                    primaryMinSize={10}
                    secondaryInitialSize={80}
                    percentage={true}
                  >
                    <FlowsTable
                      project={project}
                      checkedFlowEntry={checkedFlowEntryIndex}
                      setCheckedFlowEntryIndex={setCheckedFlowEntryIndex}
                      openSegmentFile={canCreateForensicSearch ? openSegmentFile : undefined}
                      makeFilter={onMakeFilter}
                      insertIntoNameTable={setInsertNameEntries}
                      resolveNames={onResolveNames}
                      action={flowsTableAction}
                    />
                    {viewType === "segments" ? (
                      <SegmentsView project={project} flowEntryIndex={checkedFlowEntryIndex} />
                    ) : null}
                    {viewType === "ladder" ? (
                      <LadderDiagram project={project} flowEntryIndex={checkedFlowEntryIndex} />
                    ) : null}
                  </SplitterLayout>
                ) : null}
              </ViewContentWrapper>
              {showDeleteConfirm && (
                <ConfirmationModal
                  message="Are you sure you want to delete the multi-segment analysis project?"
                  onNo={onDeleteCancel}
                  onYes={onDeleteOK}
                  show={showDeleteConfirm}
                  title="Delete Multi-Segment Analysis Project"
                />
              )}
              {forensicSearchParams !== null && (
                <ForensicSearchModal
                  onCancel={onForensicSearchCancel}
                  onOK={onForensicSearchOK}
                  name={forensicSearchParams.name}
                  startTime={forensicSearchParams.startTime}
                  endTime={forensicSearchParams.endTime}
                  file={forensicSearchParams.file}
                />
              )}
              {insertNameEntries !== null && (
                <InsertNamesModal
                  engine={server}
                  authToken={serverAuthToken}
                  entries={insertNameEntries}
                  onOK={() => {
                    dispatch(updateServerStatus())
                    setInsertNameEntries(null)
                  }}
                  onCancel={() => {
                    setInsertNameEntries(null)
                  }}
                />
              )}
            </>
          ) : (
            <>
              {error !== null ? (
                <Alert color="danger">{error}</Alert>
              ) : (
                <ProgressStyle>
                  <ProgressText>{project.status}</ProgressText>
                  <BarGauge
                    aria-label="Progress"
                    value={project.progress}
                    max={100}
                    title={project.status}
                  />
                  <LightButton style={{ marginTop: "1rem" }} onClick={() => onStop()}>
                    Cancel
                  </LightButton>
                </ProgressStyle>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {error !== null ? (
            <Alert color="danger">{error}</Alert>
          ) : (
            <CenterContent>
              <Spinner />
            </CenterContent>
          )}
        </>
      )}
    </View>
  )
}

export default ProjectView
