import styled from "styled-components"
import DatePicker, { DatePickerProps } from "react-datepicker"
import rgba from "polished/lib/color/rgba"
import { localToUTCCheck, utcToLocalCheck } from "../../../utils/formatUtils"

type DateTimePickerProps = Omit<
  DatePickerProps,
  "onChange" | "selectsRange" | "selectsMultiple"
> & {
  showLocalTime: boolean
  onChange: (
    date: Date | null,
    event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>
  ) => void
}

type DateTimePickerWrapperProps = {
  showDateOnly: boolean | undefined
  showTimeSelectOnly: boolean | undefined
}

const DateTimePickerWrapper = styled.div<DateTimePickerWrapperProps>`
  & .react-datepicker-popper {
    min-width: ${props => (props.showTimeSelectOnly || props.showDateOnly ? "0px" : "268px")};
    z-index: 5;
  }

  & .react-datepicker-popper .react-datepicker__triangle {
    stroke: rgba(0, 0, 0, 0.175);
  }

  & .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    fill: ${props => props.theme.popoverBackgroundColor};
    color: transparent;
  }

  & .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
    fill: ${props => props.theme.popoverBackgroundColor};
    color: transparent;
  }

  & .react-datepicker__input-container input:disabled {
    background-color: ${props => props.theme.inputDisabledBackgroundColor};
    color: ${props => props.theme.inputColor};
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    position: relative;
    width: 100%;
  }

  & .react-datepicker__input-container input {
    background-color: ${props => props.theme.inputBackgroundColor};
    border-color: ${props => props.theme.inputBorderColor};
    border-radius: 2px;
    border-style: solid;
    border-width: 1px;
    color: ${props => props.theme.inputColor};
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    position: relative;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 100%;
  }

  & .react-datepicker__input-container input:focus {
    background-color: ${props => props.theme.inputBackgroundColor};
    border-color: ${props => props.theme.inputFocusBorderColor};
    border-radius: 2px;
    border-style: ridge;
    border-width: 1px;
    box-shadow: 0 0 0 0.22rem ${props => rgba(props.theme.primaryButtonBackgroundColor, 0.25)};
    color: ${props => props.theme.inputColor};
    display: block;
    font-size: 1rem;
    line-height: 1.5;
    outline: none;
    padding: 0.375rem 0.75rem;
    position: relative;
    width: 100%;
  }

  & .react-datepicker-wrapper {
    display: block;
  }

  & .react-datepicker {
    background-color: ${props => props.theme.popoverBackgroundColor};
    border-color: rgba(0, 0, 0, 0.175);
    border-radius: 2px;
    line-height: 1.5;
    font-family: inherit;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.18);
  }

  & .react-datepicker__header {
    background-color: ${props => props.theme.dateTimePickerHeaderBackgroundColor};
    border-bottom: 1px solid ${props => props.theme.dateTimePickerBorderColor};
    border-top-left-radius: 0.3rem;
    color: ${props => props.theme.inputColor};
    padding: 8px 0;
    position: relative;
    text-align: center;
  }

  & .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: ${props => props.theme.inputColor};
  }

  & .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: ${props => props.theme.inputColor};
  }

  & .react-datepicker__day:hover,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover {
    background-color: ${props => props.theme.lightButtonHoverBackgroundColor};
  }

  & .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--highlighted:hover,
  .react-datepicker__day--highlighted:hover {
    background-color: ${props => props.theme.selectedBackgroundColor};
  }

  & .react-datepicker__header {
    border-bottom: 1px solid ${props => props.theme.dateTimePickerBorderColor};
  }

  & .react-datepicker__time-container {
    border-left-width: ${props => (props.showTimeSelectOnly ? "0px" : "1px")};
    border-left-style: solid;
    border-left-color: ${props => props.theme.dateTimePickerBorderColor};
  }

  & .react-datepicker__time-container,
  .react-datepicker__time,
  .react-datepicker__time-box,
  ul.react-datepicker__time-list,
  li.react-datepicker__time-list-item {
    background-color: ${props => props.theme.popoverBackgroundColor};
    color: ${props => props.theme.inputColor};
  }

  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    .react-datepicker__time-list
    .react-datepicker__time-list-item:hover {
    background-color: ${props => props.theme.lightButtonHoverBackgroundColor};
    color: ${props => props.theme.inputColor};
  }

  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    .react-datepicker__time-list
    .react-datepicker__time-list-item--selected {
    background-color: ${props => props.theme.selectedBackgroundColor};
    color: ${props => props.theme.selectedColor};
  }

  &
    .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    .react-datepicker__time-list
    .react-datepicker__time-list-item--selected:hover {
    background-color: ${props => props.theme.selectedBackgroundColor};
    color: ${props => props.theme.selectedColor};
  }
`

export const DateTimePicker = (props: DateTimePickerProps) => {
  const {
    showLocalTime,
    selected,
    dateFormat,
    showTimeSelect,
    showTimeSelectOnly,
    onChange,
    ...datePickerProps
  } = props

  const displayDate = localToUTCCheck(selected ? selected : new Date(), showLocalTime)

  const displayDateFormat = dateFormat
    ? dateFormat
    : showLocalTime
    ? "yyyy-MM-dd HH:mm:ss.SSS"
    : "yyyy-MM-dd HH:mm:ss.SSS'Z'"

  const showDateOnly = showTimeSelect === false

  return (
    <DateTimePickerWrapper showTimeSelectOnly={showTimeSelectOnly} showDateOnly={showDateOnly}>
      <DatePicker
        selected={displayDate}
        showTimeSelect={showTimeSelect !== false}
        showTimeSelectOnly={showTimeSelectOnly}
        dateFormat={displayDateFormat}
        timeFormat="HH:mm:ss"
        timeIntervals={15}
        wrapperClassName="datepicker"
        onChange={displayDate => {
          if (displayDate) {
            onChange(utcToLocalCheck(displayDate, showLocalTime))
          }
        }}
        {...datePickerProps}
      />
    </DateTimePickerWrapper>
  )
}
