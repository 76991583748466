import styled from "styled-components"

export const Panel = styled.div`
  padding: ${props => props.theme.panelPadding};
  border: ${props => props.theme.panelBorder};
  background: ${props => props.theme.panelBackground};
  border-radius: ${props => props.theme.panelBorderRadius};
  box-shadow: ${props => props.theme.panelBoxShadow};
`

export const SubPanel = styled.div`
  padding: ${props => props.theme.subPanelPadding};
  border: ${props => props.theme.subPanelBorder};
  background: ${props => props.theme.subPanelBackground};
  border-radius: ${props => props.theme.subPanelBorderRadius};
  box-shadow: ${props => props.theme.subPanelBoxShadow};
`
