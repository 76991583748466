export const brandGray = "#13171A"
export const brandBlue = "#1C6FEC"
export const brandDarkBlue = "#1964D4"
export const brandLightBlue = "#5096FF"
export const brandOrange = "#FF7F37"
export const brandDarkOrange = "#E57232"
export const brandLightOrange = "#FE965C"
export const brandGreen = "#2FF66B"
export const brandDarkGreen = "#21AB4A"
export const brandLightGreen = "#54FB86"

export const primary100 = "hsl(216,96%,80%)"
export const primary200 = "hsl(216,94%,70%)"
export const primary300 = "hsl(216,92%,65%)" // close to brandLightBlue
export const primary400 = "hsl(216,90%,60%)"
export const primary500 = brandBlue
export const primary600 = "hsl(216,88%,47%)" // close to brandDarkBlue
export const primary700 = "hsl(216,90%,44%)"
export const primary800 = "hsl(216,92%,42%)"
export const primary900 = "hsl(216,94%,38%)"

export const gray100 = "hsl(214,47%,97%)" // blackSqueezeBase
export const gray200 = "hsl(214,44%,95%)"
export const gray300 = "hsl(214,35%,93%)"
export const gray400 = "hsl(214,28%,91%)"
export const gray500 = "hsl(216,22%,87%)" // geyserBase
export const gray600 = "hsl(216,18%,77%)"
export const gray700 = "hsl(218,13%,55%)" // regentGrayBase
export const gray800 = "hsl(218,18%,38%)"
export const gray900 = "hsl(218,22%,27%)" // oxfordGrayBase
export const gray1000 = "hsl(218,24%,22%)" // close to header menu background color
export const gray1100 = "hsl(218,26%,17%)"
export const gray1200 = "hsl(218,28%,13%)"
export const gray1300 = "hsl(218,32%,10%)"
export const gray1400 = "hsl(218,36%,8%)"

export const red100 = "hsl(356,100%,92%)"
export const red500 = "hsl(354,70%,54%)"
export const red600 = "hsl(354,70%,46%)"
export const red900 = "hsl(354,61%,28%)"

export const orange100 = "hsl(21,96%,80%)"
export const orange200 = "hsl(21,94%,70%)"
export const orange300 = "hsl(21,92%,65%)"
export const orange400 = "hsl(21,90%,60%)"
export const orange500 = "hsl(21,88%,57%)" // jaffBase
export const orange600 = "hsl(21,88%,47%)" // persimmonRed
export const orange700 = "hsl(21,90%,44%)"
export const orange800 = "hsl(21,92%,42%)"
export const orange900 = "hsl(21,94%,38%)"

export const blue100 = "hsl(210,100%,94%)"
export const blue200 = "hsl(210,100%,88%)"
export const blue300 = "hsl(211,80%,78%)"
export const blue400 = "hsl(212,66%,60%)"
export const blue500 = "hsl(213,50%,40%)"
export const blue600 = "hsl(213,44%,36%)"
export const blue700 = "hsl(214,42%,32%)"
export const blue800 = "hsl(215,40%,30%)"
export const blue900 = "hsl(216,38%,28%)"

export const green100 = "hsl(139,80%,90%)"
export const green200 = "hsl(142,76%,80%)"
export const green300 = "hsl(146,72%,67%)"
export const green400 = "hsl(148,64%,52%)"
export const green500 = "hsl(150,84%,39%)"
export const green600 = "hsl(151,94%,30%)"
export const green700 = "hsl(153,94%,24%)"
export const green800 = "hsl(153,88%,20%)"
export const green900 = "hsl(154,86%,16%)"

export const yellow100 = "hsl(52,100%,90%)"
export const yellow500 = "hsl(50,72%,50%)"
export const yellow800 = "hsl(48,92%,27%)"
export const yellow900 = "hsl(46,96%,17%)"
