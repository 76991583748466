import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { cloneDeep } from "lodash"
import { v4 as uuid } from "uuid"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import FileSaver from "file-saver"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import { defaultAlarm } from "../AlarmsEditView"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { OmniTable } from "../common/OmniTable"
import { View, ViewContent, ViewHeader, ViewHeaderTitle, ViewHeaderButtons } from "../common/View"
import { LightButton, IconDropdownToggle } from "../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import Interval from "../common/Interval"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import FilterBox from "../common/FilterBox"
import BarGauge from "../common/BarGauge"
import CountryName from "../common/CountryName"
import {
  formatInteger,
  formatFloat,
  formatISODateTime,
  formatDuration,
} from "../../utils/formatUtils"
import csvStringify from "../../utils/csvStringify"
import {
  getCaptureForensicSearchUrl,
  getEngineNewFilterUrl,
  getEngineNewAlarmUrl,
} from "../../routes"
import {
  getEngine,
  getAuthToken,
  getCapabilities,
  getCountryStatsFilter,
  getCountryStatsColumns,
  getCountryStatsSortBy,
  getCountryStatsSortDirection,
  getUserId,
  getShowLocalTime,
} from "../../store"
import { setCountryStatsFilter, setCountryStatsColumns, setCountryStatsSort } from "../../store/ui"
import { setSelectPacketsTask } from "../../store/selectPackets"
import { fetchCFSStatistics, postSelectRelatedFilterStart } from "../../api/api"
import {
  AlarmInfo,
  CountryStatistics,
  CountryStatisticsObject,
  Filter,
  RequestGetStatisticsStatistic,
  RequestPostSelectRelatedFilterStart,
  ResponseGetEngineCapabilities,
  ResponsePostSelectRelatedFilterStart,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { PeekCountryStatType } from "../../api/types/peekTypes"

const defaultColumns = [
  {
    dataKey: "name",
    label: "Country",
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "bytesFromPercent",
    label: "Bytes From %",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "packetsFromPercent",
    label: "Packets From %",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "bytesToPercent",
    label: "Bytes To %",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "packetsToPercent",
    label: "Packets To %",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "bytesFrom",
    label: "Bytes From",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "packetsFrom",
    label: "Packets From",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "bytesTo",
    label: "Bytes To",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "packetsTo",
    label: "Packets To",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "firstTimeFrom",
    label: "First Time From",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "lastTimeFrom",
    label: "Last Time From",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "firstTimeTo",
    label: "First Time To",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "lastTimeTo",
    label: "Last Time To",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "duration",
    label: "Duration",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
]

const CommandStrip = styled.div`
  display: flex;
`

type CountryStatisticsViewProps = RouteComponentProps<CaptureRouteParams> &
  CaptureViewProps & {
    dispatch: Function
    engine: string
    authToken: string
    engineCapabilities: ResponseGetEngineCapabilities | null
    filter: string
    showLocalTime: boolean
    columns: any[]
    sortBy: string
    sortDirection: SortDirectionType
    userId: string
  }

type CountryStatisticsViewState = {
  countryStatistics: CountryStatistics | null
}

class CountryStatisticsView extends React.Component<
  CountryStatisticsViewProps,
  CountryStatisticsViewState
> {
  state: CountryStatisticsViewState = {
    countryStatistics: null,
  }

  componentDidMount() {
    this.onRefresh()
  }

  onRefresh = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSStatistics<CountryStatistics>(
      engine,
      authToken,
      type,
      capId,
      "country" as RequestGetStatisticsStatistic
    )
      .then((countryStatistics: CountryStatistics) => {
        if (countryStatistics.countries) {
          const { sortBy, sortDirection } = this.props
          this.sort(countryStatistics, sortBy, sortDirection)
          this.setState({ countryStatistics })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onExport = () => {
    const { countryStatistics } = this.state
    const { filter, columns } = this.props
    const visibleColumns = columns.filter(col => col.visible)
    let csv = visibleColumns.map(col => csvStringify(col.label)).join(",") + "\n"

    let countries = countryStatistics && countryStatistics.countries
    if (countries && filter) {
      const lowerCaseFilter = filter.toLowerCase()
      countries = countries.filter(country =>
        country.name ? country.name.toLowerCase().includes(lowerCaseFilter) : false
      )
    }

    if (countries) {
      countries.forEach(rowData => {
        const row: string[] = []
        visibleColumns.forEach(col => {
          let content: string | number = ""
          switch (col.dataKey) {
            case "name":
              content = rowData.name || rowData.code
              break
            case "bytesFromPercent":
              {
                let percentage = 0
                if (countryStatistics) {
                  const totalBytes = countryStatistics.totalBytes
                  percentage = (rowData.bytesFrom / totalBytes) * 100
                }
                content = formatFloat(percentage, 6)
              }
              break
            case "packetsFromPercent":
              {
                let percentage = 0
                if (countryStatistics) {
                  const totalPackets = countryStatistics.totalPackets
                  percentage = (rowData.packetsFrom / totalPackets) * 100
                }
                content = formatFloat(percentage, 6)
              }
              break
            case "bytesToPercent":
              {
                let percentage = 0
                if (countryStatistics) {
                  const totalBytes = countryStatistics.totalBytes
                  percentage = (rowData.bytesTo / totalBytes) * 100
                }
                content = formatFloat(percentage, 6)
              }
              break
            case "packetsToPercent":
              {
                let percentage = 0
                if (countryStatistics) {
                  const totalPackets = countryStatistics.totalPackets
                  percentage = (rowData.packetsTo / totalPackets) * 100
                }
                content = formatFloat(percentage, 6)
              }
              break
            case "bytesFrom":
            case "packetsFrom":
            case "bytesTo":
            case "packetsTo":
              content = rowData[col.dataKey as keyof CountryStatisticsObject] as number
              break
            case "firstTimeFrom":
            case "lastTimeFrom":
            case "firstTimeTo":
            case "lastTimeTo":
              {
                const cellData = rowData[col.dataKey as keyof CountryStatisticsObject]
                if (cellData) {
                  content = formatISODateTime(cellData as string, 0, this.props.showLocalTime)
                }
              }
              break
            case "duration":
              if (rowData.duration != null) {
                content = formatDuration(rowData.duration, 6)
              }
              break
            default:
              break
          }
          row.push(csvStringify(content))
        })
        csv += row.join(",") + "\n"
      })
    }

    FileSaver.saveAs(new Blob([csv], { type: "text/plain;charset=utf8" }), "Country Statistics.csv")
  }

  onChangeFilter = (filter: string) => {
    this.props.dispatch(setCountryStatsFilter(filter))
  }

  onShowDefaultColumns = () => {
    this.props.dispatch(setCountryStatsColumns(defaultColumns))
  }

  onShowAllColumns = () => {
    const columns = cloneDeep(this.props.columns)
    columns.forEach(col => {
      col.visible = true
    })
    this.props.dispatch(setCountryStatsColumns(columns))
  }

  onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const columns = cloneDeep(this.props.columns)
    const col = columns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      this.props.dispatch(setCountryStatsColumns(columns))
    }
  }

  onSelectRelated = (command: string, rowData: CountryStatisticsObject) => {
    let accept1To2 = false
    let accept2To1 = false
    if (command === "source") {
      accept1To2 = true
    } else if (command === "dest") {
      accept2To1 = true
    } else if (command === "sourceOrDest") {
      accept1To2 = true
      accept2To1 = true
    }
    const body: RequestPostSelectRelatedFilterStart = {
      id: uuid(),
      rootNode: {
        accept1To2: accept1To2,
        accept2To1: accept2To1,
        country1: rowData.code,
        country2: "",
        clsid: "0866D6FD-0DBC-47A9-AD9E-927A6489D01C",
        comment: "",
        inverted: false,
      },
    }
    const { capId } = this.props.match.params
    const { engine, authToken } = this.props
    postSelectRelatedFilterStart(engine, authToken, capId, body)
      .then((task: ResponsePostSelectRelatedFilterStart) => {
        if (task.taskId) {
          this.props.dispatch(
            setSelectPacketsTask({ type: "filter", taskId: task.taskId, progress: 0 })
          )
          this.props.history.push("packets")
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onMakeFilter = (rowData: CountryStatisticsObject) => {
    const filter: Filter = {
      clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
      color: "#000000",
      comment: "",
      created: "",
      group: "",
      id: "",
      modified: "",
      name: "Untitled",
      rootNode: {
        accept1To2: true,
        accept2To1: true,
        clsid: "0866D6FD-0DBC-47A9-AD9E-927A6489D01C",
        comment: "",
        country1: rowData.code,
        country2: "",
        inverted: false,
      },
    }
    this.props.history.push({
      pathname: getEngineNewFilterUrl(),
      state: { filter },
    })
  }

  onMakeAlarm = (rowData: CountryStatisticsObject) => {
    const alarm: AlarmInfo = {
      ...cloneDeep(defaultAlarm),
      name: rowData.name || rowData.code,
      statisticsTracker: {
        clsid: "5FF04EFD-9A1D-436B-BD5D-3E206296C90A",
        country: rowData.code,
        history: 60,
        statisticsType: PeekCountryStatType.PEEK_COUNTRY_STAT_TYPE_TOTAL_BYTES,
      },
    }
    this.props.history.push({
      pathname: getEngineNewAlarmUrl(),
      state: { alarm },
    })
  }

  cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content
    switch (dataKey) {
      case "name":
        content = <CountryName name={cellData} code={rowData.code} />
        break
      case "bytesFromPercent":
        {
          let percentage = 0
          if (this.state.countryStatistics) {
            const totalBytes = this.state.countryStatistics.totalBytes
            percentage = (rowData.bytesFrom / totalBytes) * 100
          }
          content = (
            <BarGauge
              aria-label="Percentage of bytes from"
              value={percentage}
              max={100}
              title={`${formatFloat(percentage, 3)}%`}
            />
          )
        }
        break
      case "packetsFromPercent":
        {
          let percentage = 0
          if (this.state.countryStatistics) {
            const totalPackets = this.state.countryStatistics.totalPackets
            percentage = (rowData.packetsFrom / totalPackets) * 100
          }
          content = (
            <BarGauge
              aria-label="Percentage of packets from"
              value={percentage}
              max={100}
              title={`${formatFloat(percentage, 3)}%`}
            />
          )
        }
        break
      case "bytesToPercent":
        {
          let percentage = 0
          if (this.state.countryStatistics) {
            const totalBytes = this.state.countryStatistics.totalBytes
            percentage = (rowData.bytesTo / totalBytes) * 100
          }
          content = (
            <BarGauge
              aria-label="Percentage of bytes to"
              value={percentage}
              max={100}
              title={`${formatFloat(percentage, 3)}%`}
            />
          )
        }
        break
      case "packetsToPercent":
        {
          let percentage = 0
          if (this.state.countryStatistics) {
            const totalPackets = this.state.countryStatistics.totalPackets
            percentage = (rowData.packetsTo / totalPackets) * 100
          }
          content = (
            <BarGauge
              aria-label="Percentage of packets to"
              value={percentage}
              max={100}
              title={`${formatFloat(percentage, 3)}%`}
            />
          )
        }
        break
      case "bytesFrom":
      case "packetsFrom":
      case "bytesTo":
      case "packetsTo":
        content = formatInteger(cellData)
        break
      case "firstTimeFrom":
      case "lastTimeFrom":
      case "firstTimeTo":
      case "lastTimeTo":
        if (cellData != null) {
          content = formatISODateTime(cellData, 0, this.props.showLocalTime)
        }
        break
      case "duration":
        if (cellData != null) {
          content = formatDuration(cellData, 6)
        }
        break
      default:
        break
    }
    return content
  }

  commandCellRenderer = ({ rowData }: TableCellProps) => {
    const { captureProperties, engineCapabilities, userId } = this.props
    const packetsDisabled = captureProperties === null || !captureProperties.packetBufferEnabled
    const selectRelatedPacketsDisabled = packetsDisabled || rowData.code === ""

    // make sure the user can view packets for this capture or forensic search
    let canViewPackets = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
    }

    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            <DropdownItem
              disabled={selectRelatedPacketsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, "source", rowData)}
            >
              Select Related Packets by Source Country
            </DropdownItem>
            <DropdownItem
              disabled={selectRelatedPacketsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, "dest", rowData)}
            >
              Select Related Packets by Destination Country
            </DropdownItem>
            <DropdownItem
              disabled={selectRelatedPacketsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, "sourceOrDest", rowData)}
            >
              Select Related Packets by Source or Destination Country
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.onMakeFilter.bind(this, rowData)}>Make Filter</DropdownItem>
            <DropdownItem onClick={this.onMakeAlarm.bind(this, rowData)}>Make Alarm</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  onSort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
    this.sort(this.state.countryStatistics, sortBy, sortDirection)
    this.props.dispatch(setCountryStatsSort(sortBy, sortDirection))
  }

  sort(
    countryStatistics: CountryStatistics | null,
    sortBy: string,
    sortDirection: SortDirectionType
  ) {
    if (countryStatistics && countryStatistics.countries) {
      // "bytesFromPercent" -> "bytesFrom", e.g.
      const sortField = sortBy.replace("Percent", "")
      countryStatistics.countries.sort((a: CountryStatisticsObject, b: CountryStatisticsObject) => {
        let result = 0
        const valueA = a[sortField as keyof CountryStatisticsObject]
        const valueB = b[sortField as keyof CountryStatisticsObject]
        if (valueA != null && valueB != null) {
          if (valueA > valueB) {
            result = 1
          } else if (valueA < valueB) {
            result = -1
          }
        } else if (valueA != null) {
          result = 1
        } else if (valueB != null) {
          result = -1
        }

        // Fall back to name.
        if (result === 0) {
          const nameA = a.name || a.code
          const nameB = b.name || b.code
          if (nameA > nameB) {
            result = 1
          } else if (nameA < nameB) {
            result = -1
          }
        }

        if (sortDirection === SortDirection.DESC) result = -result

        return result
      })
    }
  }

  render() {
    const { countryStatistics } = this.state
    const {
      captureProperties,
      engineCapabilities,
      filter,
      columns,
      sortBy,
      sortDirection,
      userId,
    } = this.props

    // make sure the user can view stats for this capture or forensic search
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
      if (!canViewStats) {
        const { type, capId } = this.props.match.params
        return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
      }
    }

    let countries = countryStatistics && countryStatistics.countries
    const count = countries ? countries.length : 0
    if (countries && filter) {
      const lowerCaseFilter = filter.toLowerCase()
      countries = countries.filter(country =>
        country.name ? country.name.toLowerCase().includes(lowerCaseFilter) : false
      )
    }
    return (
      <View>
        <BreadcrumbItem to={this.props.match.url} title="Country Statistics" />
        <Interval timeout={30000} enabled={true} callback={this.onRefresh} />
        <ViewHeader>
          <ViewHeaderTitle title="Countries" count={count} />
          <ViewHeaderButtons>
            <FilterBox
              aria-label="Search"
              placeholder="Search"
              onChange={this.onChangeFilter}
              value={filter}
            />
            <LightButton aria-label="Export" id="export" onClick={this.onExport}>
              <FontAwesome name="download" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="export">
              Export
            </UncontrolledTooltip>
            <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefresh}>
              <FontAwesome name="refresh" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="refresh">
              Refresh
            </UncontrolledTooltip>
          </ViewHeaderButtons>
        </ViewHeader>
        <ViewContent>
          {countries && (
            <OmniTable
              data={countries}
              rowCount={countries.length}
              columnDesc={columns}
              cellRenderer={this.cellRenderer}
              renderCommands={this.commandCellRenderer}
              onShowDefaultColumns={this.onShowDefaultColumns}
              onShowAllColumns={this.onShowAllColumns}
              onToggleColumn={this.onToggleColumn}
              sort={this.onSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            />
          )}
        </ViewContent>
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  engineCapabilities: getCapabilities(state) || null,
  showLocalTime: getShowLocalTime(state),
  filter: getCountryStatsFilter(state) || "",
  columns: getCountryStatsColumns(state) || defaultColumns,
  sortBy: getCountryStatsSortBy(state) || "bytesToPercent",
  sortDirection: getCountryStatsSortDirection(state) || SortDirection.DESC,
  userId: getUserId(state),
})

export default connect(mapStateToProps)(CountryStatisticsView)
