import * as React from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../Modal"
import { SecondaryButton, DangerButton } from "../Buttons"

export type ConfirmationModalProps = {
  title?: React.ReactNode
  message?: React.ReactNode
  onYes?: () => void
  onNo?: () => void
  onCancel?: () => void
  show: boolean
  size?: string | null
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  title,
  message,
  onYes,
  onNo,
  onCancel,
  show,
  size,
}) => {
  const [disableYes, setDisableYes] = React.useState(false)

  const onYesClicked = () => {
    setDisableYes(true)
    if (onYes !== undefined) {
      onYes()
    }
  }

  return (
    <Modal size={size === null ? undefined : size} isOpen={show}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        {onCancel && <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>}
        {onNo && <SecondaryButton onClick={onNo}>No</SecondaryButton>}
        {onYes && (
          <DangerButton disabled={disableYes} onClick={onYesClicked}>
            Yes
          </DangerButton>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmationModal
