import { combineReducers, configureStore, ThunkAction, Tuple, Action } from "@reduxjs/toolkit"
import { isObject, isString } from "lodash"
import { jwtDecode } from "jwt-decode"
import { thunk } from "redux-thunk"
import { isJWT } from "../api/api"
import auth, { actions as authActions } from "./auth"
import * as authStore from "./auth"
import engines, { types as engineTypes } from "./engines"
import * as enginesStore from "./engines"
import theme, { types as themeTypes } from "./theme"
import * as themeStore from "./theme"
import breadcrumbs from "./breadcrumbs"
import * as breadcrumbsStore from "./breadcrumbs"
import capabilities from "./capabilities"
import * as capabilitiesStore from "./capabilities"
import status from "./status"
import * as statusStore from "./status"
import ui, { types as uiTypes } from "./ui"
import * as uiStore from "./ui"
import selectPackets from "./selectPackets"
import * as selectPacketsStore from "./selectPackets"

// Redux support is arranged following the "ducks" proposal.
// See: https://github.com/erikras/ducks-modular-redux

const appReducer = combineReducers({
  auth,
  engines,
  theme,
  breadcrumbs,
  capabilities,
  status,
  ui,
  selectPackets,
})

// Wrap the application reducer to catch the log out actions and reset to the
// initial state.
// See: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const rootReducer = (state: any, action: any) => {
  if (
    authActions.setServerLogOutSuccess.match(action) ||
    authActions.setServerLogOutError.match(action)
  ) {
    state = loadInitialState()
  }
  return appReducer(state, action)
}

// Helper for setting a local storage item that could be null. May throw.
const setLocalStorageItem = (key: string, value: string | null) => {
  if (value != null) {
    localStorage.setItem(key, value)
  } else {
    localStorage.removeItem(key)
  }
}

// Helper for checking JWT expiration.
const isValidToken = (token: string | null, now: number) => {
  if (isString(token) && token.length > 0) {
    if (isJWT(token)) {
      try {
        const decoded = jwtDecode(token)
        return decoded.exp !== undefined && decoded.exp * 1000 > now
      } catch (ex) {
        console.log(ex)
      }
      return false
    } else {
      return true
    }
  }
  return false
}

const loadInitialState = () => {
  const initialState: any = {}

  try {
    const now = Date.now()

    initialState.auth = {
      ...authStore.initialState,
    }

    const server = localStorage.getItem("server")
    if (server) {
      initialState.auth = {
        ...initialState.auth,
        server,
      }
    }
    const serverAuthToken = localStorage.getItem("serverAuthToken")
    if (isValidToken(serverAuthToken, now)) {
      initialState.auth = {
        ...initialState.auth,
        serverAuthToken,
      }
    }
    const serverRefreshToken = localStorage.getItem("serverRefreshToken")
    if (isValidToken(serverRefreshToken, now)) {
      initialState.auth = {
        ...initialState.auth,
        serverRefreshToken,
      }
    }
    const serverVersion = localStorage.getItem("serverVersion")
    if (serverVersion) {
      initialState.auth = {
        ...initialState.auth,
        serverVersion,
      }
    }

    const engine = localStorage.getItem("engine")
    if (engine) {
      initialState.auth = {
        ...initialState.auth,
        engine,
      }
    }
    const authToken = localStorage.getItem("authToken")
    if (isValidToken(authToken, now)) {
      initialState.auth = {
        ...initialState.auth,
        authToken,
      }
    }
    const engineVersion = localStorage.getItem("engineVersion")
    if (engineVersion) {
      initialState.auth = {
        ...initialState.auth,
        engineVersion,
      }
    }
  } catch {}

  try {
    const currentEngine = localStorage.getItem("currentEngine")
    initialState.engines = {
      ...enginesStore.initialState,
      currentEngine,
    }
  } catch {}

  try {
    const currentThemeName = localStorage.getItem("theme")
    if (currentThemeName) {
      initialState.theme = {
        ...themeStore.initialState,
        currentThemeName,
      }
    }
  } catch {}

  try {
    initialState.ui = {
      ...uiStore.initialState,
    }

    const forensicsViewRefreshInterval = localStorage.getItem("forensicsViewRefreshInterval")
    if (forensicsViewRefreshInterval !== null) {
      initialState.ui = {
        ...initialState.ui,
        forensicsViewRefreshInterval: Number(forensicsViewRefreshInterval),
      }
    }

    const showAddressNames = localStorage.getItem("showAddressNames")
    if (showAddressNames) {
      initialState.ui = {
        ...initialState.ui,
        showAddressNames: showAddressNames === "true",
      }
    }

    const showPortNames = localStorage.getItem("showPortNames")
    if (showPortNames) {
      initialState.ui = {
        ...initialState.ui,
        showPortNames: showPortNames === "true",
      }
    }

    const showLocalTime = localStorage.getItem("showLocalTime")
    if (showLocalTime) {
      initialState.ui = {
        ...initialState.ui,
        showLocalTime: showLocalTime === "true",
      }
    }

    const msaLadderViewOptionsString = localStorage.getItem("msaLadderViewOptions")
    if (msaLadderViewOptionsString) {
      const msaLadderViewOptions = JSON.parse(msaLadderViewOptionsString)
      initialState.ui = {
        ...initialState.ui,
        msaLadderViewOptions,
      }
    }
  } catch {}

  return initialState
}

const localStorageMiddleware = (store: any) => (next: any) => (action: any) => {
  const result = next(action)
  switch (action.type) {
    case authActions.setServerAuthToken.toString(): {
      try {
        const serverAuthToken = store.getState().auth.serverAuthToken
        setLocalStorageItem("serverAuthToken", serverAuthToken)
      } catch {}
      try {
        const serverRefreshToken = store.getState().auth.serverRefreshToken
        setLocalStorageItem("serverRefreshToken", serverRefreshToken)
      } catch {}
      break
    }
    case authActions.setServerLogInSuccess.toString(): {
      try {
        const server = store.getState().auth.server
        setLocalStorageItem("server", server)
        const serverAuthToken = store.getState().auth.serverAuthToken
        setLocalStorageItem("serverAuthToken", serverAuthToken)
        const serverRefreshToken = store.getState().auth.serverRefreshToken
        setLocalStorageItem("serverRefreshToken", serverRefreshToken)
        const serverVersion = store.getState().auth.serverVersion
        setLocalStorageItem("serverVersion", serverVersion)

        // Default to "This Engine". Maybe there's a better way?
        const engine = store.getState().auth.engine
        if (!engine || engine === server) {
          store.dispatch(authActions.setLogIn(server))
          store.dispatch(
            authActions.setLogInSuccess({
              authToken: serverAuthToken,
              refreshToken: serverRefreshToken,
              engineVersion: serverVersion,
            })
          )
        }
      } catch {}
      break
    }

    case authActions.setServerLogInError.toString():
    case authActions.setServerLogOutSuccess.toString():
    case authActions.setServerLogOutError.toString(): {
      try {
        localStorage.removeItem("serverAuthToken")
        localStorage.removeItem("serverRefreshToken")
        localStorage.removeItem("serverVersion")
        localStorage.removeItem("authToken")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("engineVersion")
      } catch {}
      break
    }
    case authActions.setAuthToken.toString(): {
      try {
        const authToken = store.getState().auth.authToken
        setLocalStorageItem("authToken", authToken)
      } catch {}
      break
    }
    case authActions.setLogInSuccess.toString():
    case authActions.setEngine.toString(): {
      try {
        const engine = store.getState().auth.engine
        setLocalStorageItem("engine", engine)
        const authToken = store.getState().auth.authToken
        setLocalStorageItem("authToken", authToken)
        const refreshToken = store.getState().auth.refreshToken
        setLocalStorageItem("refreshToken", refreshToken)
        const engineVersion = store.getState().auth.engineVersion
        setLocalStorageItem("engineVersion", engineVersion)
      } catch {}
      break
    }
    case authActions.setLogInError.toString():
    case authActions.setLogOutSuccess.toString():
    case authActions.setLogOutError.toString(): {
      try {
        localStorage.removeItem("authToken")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("engineVersion")
      } catch {}
      break
    }
    case authActions.setLogIn.toString():
      store.dispatch(statusStore.resetStatus())
      store.dispatch(capabilitiesStore.resetCapabilities())
      break
    case engineTypes.SET_CURRENT_ENGINE:
      try {
        const currentEngine = store.getState().engines.currentEngine
        const { server, serverAuthToken } = store.getState().auth
        if (currentEngine == null) {
          store.dispatch(
            authStore.setEngine({
              engine: server,
              authToken: serverAuthToken,
            })
          )
        } else {
          store.dispatch(
            authStore.setEngine({
              engine: `${server}/engines/${currentEngine}`,
              authToken: null,
            })
          )
        }
        setLocalStorageItem("currentEngine", currentEngine)
      } catch {}
      break
    case themeTypes.SET_THEME: {
      try {
        const theme = store.getState().theme.currentThemeName
        setLocalStorageItem("theme", theme)
      } catch {}
      break
    }
    case uiTypes.SET_FORENSICS_VIEW_REFRESH_INTERVAL: {
      try {
        setLocalStorageItem(
          "forensicsViewRefreshInterval",
          store.getState().ui.forensicsViewRefreshInterval
        )
      } catch {}
      break
    }
    case uiTypes.SET_SHOW_ADDRESS_NAMES: {
      try {
        const showAddressNames = store.getState().ui.showAddressNames
        setLocalStorageItem("showAddressNames", showAddressNames)
      } catch {}
      break
    }
    case uiTypes.SET_SHOW_PORT_NAMES: {
      try {
        const showPortNames = store.getState().ui.showPortNames
        setLocalStorageItem("showPortNames", showPortNames)
      } catch {}
      break
    }
    case uiTypes.SET_SHOW_LOCAL_TIME: {
      try {
        const showLocalTime = store.getState().ui.showLocalTime
        setLocalStorageItem("showLocalTime", showLocalTime)
      } catch {}
      break
    }
    case uiTypes.SET_MSA_LADDER_VIEW_OPTION: {
      try {
        const msaLadderViewOptions = store.getState().ui.msaLadderViewOptions
        if (isObject(msaLadderViewOptions)) {
          setLocalStorageItem("msaLadderViewOptions", JSON.stringify(msaLadderViewOptions))
        }
      } catch {}
      break
    }
    default: {
      break
    }
  }
  return result
}

const store = configureStore({
  reducer: rootReducer,
  // TODO: use getDefaultMiddleware instead of replacing all middleware
  middleware: () => new Tuple(thunk, localStorageMiddleware),
  preloadedState: loadInitialState(),
})
export default store

// Export various store types
// See https://react-redux.js.org/using-react-redux/static-typing#typing-the-usedispatch-hook
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

// Helpers

export const checkFetchResponse = (response: Response, action: () => void) => {
  if (!response.ok) {
    // 401 Unauthorized - login failed
    // 403 Forbidden - auth expired or user got kicked
    // 502 Bad Gateway - probably the engine is not running
    // 503 Service Unavailable - plug-in may not be loaded
    const statusCode = response.status
    if (statusCode === 401 || statusCode === 403 || statusCode === 502 || statusCode === 503) {
      action()
    }
  }
}

// Selectors
// See: https://egghead.io/lessons/javascript-redux-colocating-selectors-with-reducers

export const getServer = (state: any) => authStore.getServer(state.auth)

export const getServerAuthToken = (state: any) => authStore.getServerAuthToken(state.auth)

export const getServerRefreshToken = (state: any) => authStore.getServerRefreshToken(state.auth)

export const getServerTempAuthToken = (state: any) => authStore.getServerTempAuthToken(state.auth)

export const getServerAuthError = (state: any) => authStore.getServerAuthError(state.auth)

export const getServerAuthInProgress = (state: any) => authStore.getServerAuthInProgress(state.auth)

export const getServerVersion = (state: any) => authStore.getServerVersion(state.auth)

export const getServerTwoFactorAuth = (state: any) => authStore.getServerTwoFactorAuth(state.auth)

export const getEngine = (state: any) => authStore.getEngine(state.auth)

export const getAuthToken = (state: any) => authStore.getAuthToken(state.auth)

export const getTempAuthToken = (state: any) => authStore.getTempAuthToken(state.auth)

export const getAuthError = (state: any) => authStore.getAuthError(state.auth)

export const getAuthInProgress = (state: any) => authStore.getAuthInProgress(state.auth)

export const getTwoFactorAuth = (state: any) => authStore.getTwoFactorAuth(state.auth)

export const getEngines = (state: any) => enginesStore.getEngines(state.engines)

export const getEnginesUpdateCounter = (state: any) =>
  enginesStore.getEnginesUpdateCounter(state.engines)

export const getEnginesFetchInProgress = (state: any) =>
  enginesStore.getEnginesFetchInProgress(state.engines)

export const getCurrentEngine = (state: any) => enginesStore.getCurrentEngine(state.engines)

export const getThemes = (state: any) => themeStore.getThemes(state.theme)

export const getCurrentTheme = (state: any) => themeStore.getCurrentTheme(state.theme)

export const isDarkTheme = (state: any) => themeStore.isDarkTheme(state.theme)

export const getBreadcrumbs = (state: any) => breadcrumbsStore.getBreadcrumbs(state.breadcrumbs)

export const getCapabilities = (state: any) => capabilitiesStore.getCapabilities(state.capabilities)

export const getStatus = (state: any) => statusStore.getStatus(state.status)

export const getServerStatus = (state: any) => statusStore.getServerStatus(state.status)

export const getStatusUpdateCounter = (state: any) =>
  statusStore.getStatusUpdateCounter(state.status)

export const getServerStatusUpdateCounter = (state: any) =>
  statusStore.getServerStatusUpdateCounter(state.status)

export const getEngineCustomSettings = (state: any) =>
  statusStore.getEngineCustomSettings(state.status)

export const getEngineAddress = (state: any) => statusStore.getEngineAddress(state.status)

export const getEngineName = (state: any) => statusStore.getEngineName(state.status)

export const getEngineType = (state: any) => statusStore.getEngineType(state.status)

export const getEngineVersion = (state: any) => statusStore.getEngineVersion(state.status)

export const getEngineFileVersion = (state: any) => statusStore.getEngineFileVersion(state.status)

export const getEngineLicensed = (state: any) => statusStore.getEngineLicensed(state.status)

export const getEngineLicenseType = (state: any) => statusStore.getEngineLicenseType(state.status)

export const getEngineLicenseExpired = (state: any) =>
  statusStore.getEngineLicenseExpired(state.status)

export const getEngineLicenseExpirationDate = (state: any) =>
  statusStore.getEngineLicenseExpirationDate(state.status)

export const getEngineSerialNumber = (state: any) => statusStore.getEngineSerialNumber(state.status)

export const getUserId = (state: any): string => statusStore.getUserId(state.status)

export const getUsername = (state: any): string => statusStore.getUsername(state.status)

export const getIdleTimeout = (state: any): number | undefined =>
  statusStore.getIdleTimeout(state.status)

export const getDataFolder = (state: any) => statusStore.getDataFolder(state.status)

export const getAlarmsModificationTime = (state: any) =>
  statusStore.getAlarmsModificationTime(state.status)

export const getFiltersModificationTime = (state: any) =>
  statusStore.getFiltersModificationTime(state.status)

export const getNamesModificationTime = (state: any) =>
  statusStore.getNamesModificationTime(state.status)

export const getNotificationsModificationTime = (state: any) =>
  statusStore.getNotificationsModificationTime(state.status)

export const getServerName = (state: any) => statusStore.getServerName(state.status)

export const getServerUsername = (state: any) => statusStore.getServerUsername(state.status)

export const getServerLicensed = (state: any) => statusStore.getServerLicensed(state.status)

export const getServerLicenseExpired = (state: any) =>
  statusStore.getServerLicenseExpired(state.status)

export const getServerSerialNumber = (state: any) => statusStore.getServerSerialNumber(state.status)

export const getServerCustomSettings = (state: any) =>
  statusStore.getServerCustomSettings(state.status)

export const getServerNamesModificationTime = (state: any) =>
  statusStore.getServerNamesModificationTime(state.status)

export const getEnginesModificationTime = (state: any) =>
  statusStore.getEnginesModificationTime(state.status)

export const getLogTotalCount = (state: any) => statusStore.getLogTotalCount(state.status)

export const getIsNavOpen = (state: any) => uiStore.getIsNavOpen(state.ui)

export const getShowAddressNames = (state: any) => uiStore.getShowAddressNames(state.ui)

export const getShowPortNames = (state: any) => uiStore.getShowPortNames(state.ui)

export const getShowLocalTime = (state: any) => uiStore.getShowLocalTime(state.ui)

export const getEnginesFilter = (state: any) => uiStore.getEnginesFilter(state.ui)

export const getEnginesSortBy = (state: any) => uiStore.getEnginesSortBy(state.ui)

export const getEnginesSortDirection = (state: any) => uiStore.getEnginesSortDirection(state.ui)

export const getEnginesCollapsedGroups = (state: any) => uiStore.getEnginesCollapsedGroups(state.ui)

export const getEnginesChecked = (state: any) => uiStore.getEnginesChecked(state.ui)

export const getCapturesViewType = (state: any) => uiStore.getCapturesViewType(state.ui)

export const getCapturesFilter = (state: any) => uiStore.getCapturesFilter(state.ui)

export const getCapturesColumns = (state: any) => uiStore.getCapturesColumns(state.ui)

export const getCapturesSortBy = (state: any) => uiStore.getCapturesSortBy(state.ui)

export const getCapturesSortDirection = (state: any) => uiStore.getCapturesSortDirection(state.ui)

export const getForensicSearchesColumns = (state: any) =>
  uiStore.getForensicSearchesColumns(state.ui)

export const getForensicSearchesFilter = (state: any) => uiStore.getForensicSearchesFilter(state.ui)

export const getForensicSearchesSortBy = (state: any) => uiStore.getForensicSearchesSortBy(state.ui)

export const getForensicSearchesSortDirection = (state: any) =>
  uiStore.getForensicSearchesSortDirection(state.ui)

export const getForensicSearchesViewType = (state: any) =>
  uiStore.getForensicSearchesViewType(state.ui)

export const getForensicSearchHistoryFilter = (state: any) =>
  uiStore.getForensicSearchHistoryFilter(state.ui)

export const getAnalysisModulesFilter = (state: any) => uiStore.getAnalysisModulesFilter(state.ui)

export const getAuditLogRefreshInterval = (state: any) =>
  uiStore.getAuditLogRefreshInterval(state.ui)

export const getForensicsViewRefreshInterval = (state: any) =>
  uiStore.getForensicsViewRefreshInterval(state.ui)

export const getForensicsViewType = (state: any) => uiStore.getForensicsViewType(state.ui)

export const getForensicsSampleInterval = (state: any) =>
  uiStore.getForensicsSampleInterval(state.ui)

export const getForensicsTopTalkersViewType = (state: any) =>
  uiStore.getForensicsTopTalkersViewType(state.ui)

export const getForensicsTopProtocolsViewType = (state: any) =>
  uiStore.getForensicsTopProtocolsViewType(state.ui)

export const getForensicsTopStatsChartType = (state: any) =>
  uiStore.getForensicsTopStatsChartType(state.ui)

export const getForensicsTopStatsChartScale = (state: any) =>
  uiStore.getForensicsTopStatsChartScale(state.ui)

export const getForensicsTimelineChartType = (state: any) =>
  uiStore.getForensicsTimelineChartType(state.ui)

export const getForensicsTimelineChartScale = (state: any) =>
  uiStore.getForensicsTimelineChartScale(state.ui)

export const getForensicsTimelineChartInterpolation = (state: any) =>
  uiStore.getForensicsTimelineChartInterpolation(state.ui)

export const getForensicsTimelineSelection = (state: any) =>
  uiStore.getForensicsTimelineSelection(state.ui)

export const getForensicsTimelineSelectionStart = (state: any) =>
  uiStore.getForensicsTimelineSelectionStart(state.ui)

export const getForensicsTimelineSelectionEnd = (state: any) =>
  uiStore.getForensicsTimelineSelectionEnd(state.ui)

export const getCaptureSessionsFilter = (state: any) => uiStore.getCaptureSessionsFilter(state.ui)

export const getCaptureSessionsColumns = (state: any) => uiStore.getCaptureSessionsColumns(state.ui)

export const getCaptureSessionsSortBy = (state: any) => uiStore.getCaptureSessionsSortBy(state.ui)

export const getCaptureSessionsSortDirection = (state: any) =>
  uiStore.getCaptureSessionsSortDirection(state.ui)

export const getCaptureSessionsCollapsedGroups = (state: any) =>
  uiStore.getCaptureSessionsCollapsedGroups(state.ui)

export const getDecryptionKeysFilter = (state: any) => uiStore.getDecryptionKeysFilter(state.ui)

export const getDecryptionKeysSortBy = (state: any) => uiStore.getDecryptionKeysSortBy(state.ui)

export const getDecryptionKeysSortDirection = (state: any) =>
  uiStore.getDecryptionKeysSortDirection(state.ui)

export const getFilesFilter = (state: any) => uiStore.getFilesFilter(state.ui)

export const getFilesColumns = (state: any) => uiStore.getFilesColumns(state.ui)

export const getFilesSortBy = (state: any) => uiStore.getFilesSortBy(state.ui)

export const getFilesSortDirection = (state: any) => uiStore.getFilesSortDirection(state.ui)

export const getFilesCollapsedGroups = (state: any) => uiStore.getFilesCollapsedGroups(state.ui)

export const getFiltersFilter = (state: any) => uiStore.getFiltersFilter(state.ui)

export const getFiltersSortBy = (state: any) => uiStore.getFiltersSortBy(state.ui)

export const getFiltersSortDirection = (state: any) => uiStore.getFiltersSortDirection(state.ui)

export const getFiltersExpandedGroups = (state: any) => uiStore.getFiltersExpandedGroups(state.ui)

export const getFiltersChecked = (state: any) => uiStore.getFiltersChecked(state.ui)

export const getGraphsFilter = (state: any) => uiStore.getGraphsFilter(state.ui)

export const getGraphsSortBy = (state: any) => uiStore.getGraphsSortBy(state.ui)

export const getGraphsSortDirection = (state: any) => uiStore.getGraphsSortDirection(state.ui)

export const getHardwareProfilesFilter = (state: any) => uiStore.getHardwareProfilesFilter(state.ui)

export const getHardwareProfilesSortBy = (state: any) => uiStore.getHardwareProfilesSortBy(state.ui)

export const getHardwareProfilesSortDirection = (state: any) =>
  uiStore.getHardwareProfilesSortDirection(state.ui)

export const getHardwareProfilesChecked = (state: any) =>
  uiStore.getHardwareProfilesChecked(state.ui)

export const getAlarmsFilter = (state: any) => uiStore.getAlarmsFilter(state.ui)

export const getAlarmsSortBy = (state: any) => uiStore.getAlarmsSortBy(state.ui)

export const getAlarmsSortDirection = (state: any) => uiStore.getAlarmsSortDirection(state.ui)

export const getNamesViewType = (state: any) => uiStore.getNamesViewType(state.ui)

export const getNamesFilter = (state: any) => uiStore.getNamesFilter(state.ui)

export const getNamesAddressesSortBy = (state: any) => uiStore.getNamesAddressesSortBy(state.ui)

export const getNamesAddressesSortDirection = (state: any) =>
  uiStore.getNamesAddressesSortDirection(state.ui)

export const getNamesProtocolsSortBy = (state: any) => uiStore.getNamesProtocolsSortBy(state.ui)

export const getNamesProtocolsSortDirection = (state: any) =>
  uiStore.getNamesProtocolsSortDirection(state.ui)

export const getNamesPortsSortBy = (state: any) => uiStore.getNamesPortsSortBy(state.ui)

export const getNamesPortsSortDirection = (state: any) =>
  uiStore.getNamesPortsSortDirection(state.ui)

export const getNamesAddressesExpandedGroups = (state: any) =>
  uiStore.getNamesAddressesExpandedGroups(state.ui)

export const getNamesProtocolsExpandedGroups = (state: any) =>
  uiStore.getNamesProtocolsExpandedGroups(state.ui)

export const getNamesPortsExpandedGroups = (state: any) =>
  uiStore.getNamesPortsExpandedGroups(state.ui)

export const getNamesAddressesChecked = (state: any) => uiStore.getNamesAddressesChecked(state.ui)

export const getNamesProtocolsChecked = (state: any) => uiStore.getNamesProtocolsChecked(state.ui)

export const getNamesPortsChecked = (state: any) => uiStore.getNamesPortsChecked(state.ui)

export const getNotificationsFilter = (state: any) => uiStore.getNotificationsFilter(state.ui)

export const getNotificationsSortDirection = (state: any) =>
  uiStore.getNotificationsSortDirection(state.ui)

export const getAuditLogSearch = (state: any) => uiStore.getAuditLogSearch(state.ui)

export const getForensicSearchHistorySearch = (state: any) =>
  uiStore.getForensicSearchHistorySearch(state.ui)

export const getForensicSearchHistorySortAsc = (state: any) =>
  uiStore.getForensicSearchHistorySortAsc(state.ui)

export const getForensicSearchHistorySortBy = (state: any) =>
  uiStore.getForensicSearchHistorySortBy(state.ui)

export const getPacketsColumns = (state: any) => uiStore.getPacketsColumns(state.ui)

export const getPacketsColumnsDecode = (state: any) => uiStore.getPacketsColumnsDecode(state.ui)

export const getPacketsSelection = (state: any) => uiStore.getPacketsSelection(state.ui)

export const getPacketsShowDecodeView = (state: any) => uiStore.getPacketsShowDecodeView(state.ui)

export const getPacketsDecodePacketNumber = (state: any) =>
  uiStore.getPacketsDecodePacketNumber(state.ui)

export const getPacketsShowFilterBar = (state: any) => uiStore.getPacketsShowFilterBar(state.ui)

export const getPacketsFilterBarExpression = (state: any) =>
  uiStore.getPacketsFilterBarExpression(state.ui)

export const getPacketsFilterBarError = (state: any) => uiStore.getPacketsFilterBarError(state.ui)

export const getRecentFilterBarExpressions = (state: any) =>
  uiStore.getRecentFilterBarExpressions(state.ui)

export const getTrafficHistoryWidgetChartType = (state: any) =>
  uiStore.getTrafficHistoryWidgetChartType(state.ui)

export const getTrafficHistoryWidgetChartScale = (state: any) =>
  uiStore.getTrafficHistoryWidgetChartScale(state.ui)

export const getTrafficHistoryWidgetChartInterpolation = (state: any) =>
  uiStore.getTrafficHistoryWidgetChartInterpolation(state.ui)

export const getTopTalkersWidgetViewType = (state: any) =>
  uiStore.getTopTalkersWidgetViewType(state.ui)

export const getTopTalkersWidgetChartType = (state: any) =>
  uiStore.getTopTalkersWidgetChartType(state.ui)

export const getTopTalkersWidgetChartScale = (state: any) =>
  uiStore.getTopTalkersWidgetChartScale(state.ui)

export const getTopProtocolsWidgetChartType = (state: any) =>
  uiStore.getTopProtocolsWidgetChartType(state.ui)

export const getTopProtocolsWidgetChartScale = (state: any) =>
  uiStore.getTopProtocolsWidgetChartScale(state.ui)

export const getTopApplicationsByFlowsWidgetChartType = (state: any) =>
  uiStore.getTopApplicationsByFlowsWidgetChartType(state.ui)

export const getTopApplicationsByFlowsWidgetChartScale = (state: any) =>
  uiStore.getTopApplicationsByFlowsWidgetChartScale(state.ui)

export const getTopApplicationsByBytesWidgetChartType = (state: any) =>
  uiStore.getTopApplicationsByBytesWidgetChartType(state.ui)

export const getTopApplicationsByBytesWidgetChartScale = (state: any) =>
  uiStore.getTopApplicationsByBytesWidgetChartScale(state.ui)

export const getTopApplicationCategoriesWidgetChartType = (state: any) =>
  uiStore.getTopApplicationCategoriesWidgetChartType(state.ui)

export const getTopApplicationCategoriesWidgetChartScale = (state: any) =>
  uiStore.getTopApplicationCategoriesWidgetChartScale(state.ui)

export const getApplicationUtilizationWidgetChartType = (state: any) =>
  uiStore.getApplicationUtilizationWidgetChartType(state.ui)

export const getApplicationUtilizationWidgetChartScale = (state: any) =>
  uiStore.getApplicationUtilizationWidgetChartScale(state.ui)

export const getApplicationUtilizationWidgetChartInterpolation = (state: any) =>
  uiStore.getApplicationUtilizationWidgetChartInterpolation(state.ui)

export const getApplicationResponseTimeWidgetChartType = (state: any) =>
  uiStore.getApplicationResponseTimeWidgetChartType(state.ui)

export const getApplicationResponseTimeWidgetChartScale = (state: any) =>
  uiStore.getApplicationResponseTimeWidgetChartScale(state.ui)

export const getCallQualityDistributionWidgetChartType = (state: any) =>
  uiStore.getCallQualityDistributionWidgetChartType(state.ui)

export const getCallQualityDistributionWidgetChartScale = (state: any) =>
  uiStore.getCallQualityDistributionWidgetChartScale(state.ui)

export const getCallQualityWidgetChartType = (state: any) =>
  uiStore.getCallQualityWidgetChartType(state.ui)

export const getCallQualityWidgetChartInterpolation = (state: any) =>
  uiStore.getCallQualityWidgetChartInterpolation(state.ui)

export const getCallQualityByCodecWidgetChartType = (state: any) =>
  uiStore.getCallQualityByCodecWidgetChartType(state.ui)

export const getCallQualityByCodecWidgetChartInterpolation = (state: any) =>
  uiStore.getCallQualityByCodecWidgetChartInterpolation(state.ui)

export const getCallVolumeByCodecWidgetChartType = (state: any) =>
  uiStore.getCallVolumeByCodecWidgetChartType(state.ui)

export const getCallVolumeByCodecWidgetChartInterpolation = (state: any) =>
  uiStore.getCallVolumeByCodecWidgetChartInterpolation(state.ui)

export const getCallUtilizationWidgetChartType = (state: any) =>
  uiStore.getCallUtilizationWidgetChartType(state.ui)

export const getCallUtilizationWidgetChartScale = (state: any) =>
  uiStore.getCallUtilizationWidgetChartScale(state.ui)

export const getCallUtilizationWidgetChartInterpolation = (state: any) =>
  uiStore.getCallUtilizationWidgetChartInterpolation(state.ui)

export const getSummaryStatsViewType = (state: any) => uiStore.getSummaryStatsViewType(state.ui)

export const getSummaryStatsFilter = (state: any) => uiStore.getSummaryStatsFilter(state.ui)

export const getNodeStatsViewType = (state: any) => uiStore.getNodeStatsViewType(state.ui)

export const getNodeStatsFilter = (state: any) => uiStore.getNodeStatsFilter(state.ui)

export const getNodeStatsColumns = (state: any) => uiStore.getNodeStatsColumns(state.ui)

export const getNodeHierarchyStatsColumns = (state: any) =>
  uiStore.getNodeHierarchyStatsColumns(state.ui)

export const getNodeStatsSortBy = (state: any) => uiStore.getNodeStatsSortBy(state.ui)

export const getNodeStatsSortDirection = (state: any) => uiStore.getNodeStatsSortDirection(state.ui)

export const getProtocolStatsViewType = (state: any) => uiStore.getProtocolStatsViewType(state.ui)

export const getProtocolStatsFilter = (state: any) => uiStore.getProtocolStatsFilter(state.ui)

export const getProtocolStatsSortBy = (state: any) => uiStore.getProtocolStatsSortBy(state.ui)

export const getProtocolStatsSortDirection = (state: any) =>
  uiStore.getProtocolStatsSortDirection(state.ui)

export const getAppStatsFilter = (state: any) => uiStore.getAppStatsFilter(state.ui)

export const getAppStatsColumns = (state: any) => uiStore.getAppStatsColumns(state.ui)

export const getAppStatsSortBy = (state: any) => uiStore.getAppStatsSortBy(state.ui)

export const getAppStatsSortDirection = (state: any) => uiStore.getAppStatsSortDirection(state.ui)

export const getAppStatsViewType = (state: any) => uiStore.getAppStatsViewType(state.ui)

export const getCountryStatsFilter = (state: any) => uiStore.getCountryStatsFilter(state.ui)

export const getCountryStatsColumns = (state: any) => uiStore.getCountryStatsColumns(state.ui)

export const getCountryStatsSortBy = (state: any) => uiStore.getCountryStatsSortBy(state.ui)

export const getCountryStatsSortDirection = (state: any) =>
  uiStore.getCountryStatsSortDirection(state.ui)

export const getMPLSVLANVXLANStatsFilter = (state: any) =>
  uiStore.getMPLSVLANVXLANStatsFilter(state.ui)

export const getMPLSVLANVXLANStatsColumns = (state: any) =>
  uiStore.getMPLSVLANVXLANStatsColumns(state.ui)

export const getMPLSVLANVXLANStatsSortBy = (state: any) =>
  uiStore.getMPLSVLANVXLANStatsSortBy(state.ui)

export const getMPLSVLANVXLANStatsSortDirection = (state: any) =>
  uiStore.getMPLSVLANVXLANStatsSortDirection(state.ui)

export const getExpertClientsServersColumns = (state: any) =>
  uiStore.getExpertClientsServersColumns(state.ui)

export const getExpertClientsServersSortBy = (state: any) =>
  uiStore.getExpertClientsServersSortBy(state.ui)

export const getExpertClientsServersSortDirection = (state: any) =>
  uiStore.getExpertClientsServersSortDirection(state.ui)

export const getExpertFlowsSearch = (state: any) => uiStore.getExpertFlowsSearch(state.ui)

export const getExpertFlowsColumns = (state: any) => uiStore.getExpertFlowsColumns(state.ui)

export const getExpertFlowsSortBy = (state: any) => uiStore.getExpertFlowsSortBy(state.ui)

export const getExpertFlowsSortDirection = (state: any) =>
  uiStore.getExpertFlowsSortDirection(state.ui)

export const getExpertAppsColumns = (state: any) => uiStore.getExpertAppsColumns(state.ui)

export const getExpertAppsSortBy = (state: any) => uiStore.getExpertAppsSortBy(state.ui)

export const getExpertAppsSortDirection = (state: any) =>
  uiStore.getExpertAppsSortDirection(state.ui)

export const getExpertEventSummarySortBy = (state: any) =>
  uiStore.getExpertEventSummarySortBy(state.ui)

export const getExpertEventSummarySortDirection = (state: any) =>
  uiStore.getExpertEventSummarySortDirection(state.ui)

export const getExpertEventLogColumns = (state: any) => uiStore.getExpertEventLogColumns(state.ui)

export const getExpertEventLogSortBy = (state: any) => uiStore.getExpertEventLogSortBy(state.ui)

export const getExpertEventLogSortDirection = (state: any) =>
  uiStore.getExpertEventLogSortDirection(state.ui)

export const getReconstructionsColumns = (state: any) => uiStore.getReconstructionsColumns(state.ui)

export const getReconstructionsFilter = (state: any) => uiStore.getReconstructionsFilter(state.ui)

export const getReconstructionsSortBy = (state: any) => uiStore.getReconstructionsSortBy(state.ui)

export const getReconstructionsSortDirection = (state: any) =>
  uiStore.getReconstructionsSortDirection(state.ui)

export const getVoIPCallsColumns = (state: any) => uiStore.getVoIPCallsColumns(state.ui)

export const getVoIPCallsSortBy = (state: any) => uiStore.getVoIPCallsSortBy(state.ui)

export const getVoIPCallsSortDirection = (state: any) => uiStore.getVoIPCallsSortDirection(state.ui)

export const getVoIPMediaColumns = (state: any) => uiStore.getVoIPMediaColumns(state.ui)

export const getVoIPMediaSortBy = (state: any) => uiStore.getVoIPMediaSortBy(state.ui)

export const getVoIPMediaSortDirection = (state: any) => uiStore.getVoIPMediaSortDirection(state.ui)

export const getFlowVisualizerViewType = (state: any) => uiStore.getFlowVisualizerViewType(state.ui)

export const getFlowVisualizerColumns = (state: any) => uiStore.getFlowVisualizerColumns(state.ui)

export const getFlowVisualizerRelativeSeqAck = (state: any) =>
  uiStore.getFlowVisualizerRelativeSeqAck(state.ui)

export const getFlowVisualizerRelativeTime = (state: any) =>
  uiStore.getFlowVisualizerRelativeTime(state.ui)

export const getFlowVisualizerMarkRelatedPackets = (state: any) =>
  uiStore.getFlowVisualizerMarkRelatedPackets(state.ui)

export const getFlowVisualizerShowClientPayload = (state: any) =>
  uiStore.getFlowVisualizerShowClientPayload(state.ui)

export const getFlowVisualizerShowServerPayload = (state: any) =>
  uiStore.getFlowVisualizerShowServerPayload(state.ui)

export const getFlowVisualizerShowPayloadPacketLabels = (state: any) =>
  uiStore.getFlowVisualizerShowPayloadPacketLabels(state.ui)

export const getFlowVisualizerShowPayloadCRLF = (state: any) =>
  uiStore.getFlowVisualizerShowPayloadCRLF(state.ui)

export const getFlowVisualizerGraphType = (state: any) =>
  uiStore.getFlowVisualizerGraphType(state.ui)

export const getFlowVisualizerGraphDirection = (state: any) =>
  uiStore.getFlowVisualizerGraphDirection(state.ui)

export const getVoIPCallVisualizerViewType = (state: any) =>
  uiStore.getVoIPCallVisualizerViewType(state.ui)

export const getVoIPCallVisualizerColumns = (state: any) =>
  uiStore.getVoIPCallVisualizerColumns(state.ui)

export const getVoIPCallVisualizerSortBy = (state: any) =>
  uiStore.getVoIPCallVisualizerSortBy(state.ui)

export const getVoIPCallVisualizerSortDirection = (state: any) =>
  uiStore.getVoIPCallVisualizerSortDirection(state.ui)

export const getGraphsShowList = (state: any) => uiStore.getGraphsShowList(state.ui)

export const getGraphsEnabled = (state: any) => uiStore.getGraphsEnabled(state.ui)

export const getPeerMapOptions = (state: any) => uiStore.getPeerMapOptions(state.ui)

export const getSelectPacketsTask = (state: any) =>
  selectPacketsStore.getSelectPacketsTask(state.selectPackets)

export const getMSAListFilter = (state: any) => uiStore.getMSAListFilter(state.ui)

export const getMSAViewType = (state: any) => uiStore.getMSAViewType(state.ui)

export const getMSAFlowsColumns = (state: any) => uiStore.getMSAFlowsColumns(state.ui)

export const getMSAFlowsSortBy = (state: any) => uiStore.getMSAFlowsSortBy(state.ui)

export const getMSAFlowsSortDirection = (state: any) => uiStore.getMSAFlowsSortDirection(state.ui)

export const getMSAFlowsFilter = (state: any) => uiStore.getMSAFlowsFilter(state.ui)

export const getMSALadderViewOptions = (state: any) => uiStore.getMSALadderViewOptions(state.ui)

export const getWirelessNodeColumns = (state: any) => uiStore.getWirelessNodeColumns(state.ui)

export const getWirelessNodesView = (state: any) => uiStore.getWirelessNodesView(state.ui)

export const getWirelessNodesSortBy = (state: any) => uiStore.getWirelessNodesSortBy(state.ui)

export const getWirelessNodesSortDirection = (state: any) =>
  uiStore.getWirelessNodesSortDirection(state.ui)

export const getAuthenticationTokensFilter = (state: any) =>
  uiStore.getAuthenticationTokensFilter(state.ui)

export const getAuthenticationTokensSortBy = (state: any) =>
  uiStore.getAuthenticationTokensSortBy(state.ui)

export const getAuthenticationTokensSortDirection = (state: any) =>
  uiStore.getAuthenticationTokensSortDirection(state.ui)

export const getWebServersColumns = (state: any) => uiStore.getWebServersColumns(state.ui)

export const getWebServersSortBy = (state: any) => uiStore.getWebServersSortBy(state.ui)

export const getWebServersSortDirection = (state: any) =>
  uiStore.getWebServersSortDirection(state.ui)

export const getWebClientsColumns = (state: any) => uiStore.getWebClientsColumns(state.ui)

export const getWebClientsSortBy = (state: any) => uiStore.getWebClientsSortBy(state.ui)

export const getWebClientsSortDirection = (state: any) =>
  uiStore.getWebClientsSortDirection(state.ui)

export const getWebPagesColumns = (state: any) => uiStore.getWebPagesColumns(state.ui)

export const getWebPagesSortBy = (state: any) => uiStore.getWebPagesSortBy(state.ui)

export const getWebPagesSortDirection = (state: any) => uiStore.getWebPagesSortDirection(state.ui)

export const getWebRequestsColumns = (state: any) => uiStore.getWebRequestsColumns(state.ui)

export const getWebRequestsSortBy = (state: any) => uiStore.getWebRequestsSortBy(state.ui)

export const getWebRequestsSortDirection = (state: any) =>
  uiStore.getWebRequestsSortDirection(state.ui)
