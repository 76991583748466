import * as React from "react"
import { cloneDeep } from "lodash"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import useSetState from "react-use/lib/useSetState"
import { SortDirection, SortDirectionType } from "react-virtualized"
import styled from "styled-components"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import ChooseConfiguration from "./ChooseConfiguration"
import ChooseEngines from "./ChooseEngines"
import SyncEngines from "./SyncEngines"
import {
  AuthSettings,
  ConfigurationItem,
  ConfigurationItems,
  ConfigurationType,
  EngineConnection,
  LiveWireConfiguration,
  SyncResult,
  engineCanSyncConfigType,
} from "./types"
import { LoginModal } from "../LoginModal"
import { Alert } from "../common/Alert"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { ButtonBar } from "../common/Form"
import { Modal, ModalBody } from "../common/Modal"
import { MutedText } from "../common/MutedText"
import { Panel } from "../common/Panel"
import PropTable from "../common/PropTable"
import { StepProgress } from "../common/StepProgress"
import { View, ViewContentContainer } from "../common/View"
import {
  isJWT,
  logIn,
  logInCertificate,
  logOut,
  fetchEngineCapabilities,
  fetchSettings,
  fetchSync,
  fetchVersion,
  postSync,
} from "../../api/api"
import {
  AlarmCollection,
  Engine,
  EngineSettings,
  EngineSyncResults,
  FilterCollection,
  GraphCollection,
  HardwareOptionsCollection,
  LiveFlowConfiguration,
  RequestPostEngineSync,
  ResponseGetEngineCapabilities,
  ResponseGetEngineSync,
  ResponseGetStatus,
  ResponseGetVersion,
  ResponsePostLogin,
} from "../../api/types"
import { EngineCapabilities } from "../../api/types/engineTypes"
import { PeekResult } from "../../api/types/peekTypes"
import { getEngineConfigurationSyncUrl } from "../../routes"
import {
  getEngines,
  getServer,
  getServerAuthToken,
  getServerStatus,
  getUsername,
} from "../../store"
import { decrypt } from "../../utils/crypto"
import { getEngineDisplayName, isUsingGroupAuthentication } from "../../utils/engineUtils"
import { propToLabel, formatProp } from "../../utils/propUtils"

const EngineTitle = styled.h5``

const StatusPanel = styled(Panel)`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const StatusPanelBody = styled.div`
  flex-grow: 1;
`

const StepProgressWrapper = styled.div`
  margin-bottom: 2rem;
`

const WizardHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 2rem;
`

const WizardHeaderSubTitle = styled.h5`
  font-weight: normal;
  margin: 0;
`

const WizardHeaderTitle = styled.h4`
  text-transform: uppercase;
`

const WizardHeaderTitleWrapper = styled.div`
  margin-right: 2rem;
`

const WizardViewContent = styled(ViewContentContainer)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

const propList = ["name", "hostName", "address", "userName", "engineType", "productVersion"]

type ConnectState = {
  state: "idle" | "login" | "getinfo" | "waitinfo" | "done"
  modalMessage: string
  loginConnection: EngineConnection | null
  loginEngine: Engine | null
  loginError: string
}

const EngineConfigurationSyncView = () => {
  // this engine information
  const engines = useSelector(getEngines)
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const userName = useSelector(getUsername)
  const engineStatus = useSelector(getServerStatus) as ResponseGetStatus | null

  // engines
  const [authSettings, setAuthSettings] = React.useState<AuthSettings>({
    sameCreds: false,
    sameUsername: "",
    samePassword: "",
    sameClientAuth: false,
  })
  const [connectState, setConnectState] = useSetState<ConnectState>({
    state: "idle",
    modalMessage: "",
    loginConnection: null,
    loginEngine: null,
    loginError: "",
  })
  const [engineConnections, setEngineConnections] = React.useState<EngineConnection[]>([])
  const [engineFilter, setEngineFilter] = React.useState("")
  const [enginesCollapsedGroups, setEnginesCollapsedGroups] = React.useState(new Set<string>())
  const [enginesSortBy, setEnginesSortBy] = React.useState("name")
  const [enginesSortDirection, setEnginesSortDirection] = React.useState<SortDirectionType>(
    SortDirection.ASC
  )
  const [selectedEngines, setSelectedEngines] = React.useState<string[]>([])

  // configuration
  const [configurationsSortBy, setConfigurationsSortBy] = React.useState("name")
  const [configurationsSortDirection, setConfigurationsSortDirection] =
    React.useState<SortDirectionType>(SortDirection.ASC)
  const [deleteCaptureSessions, setDeleteCaptureSessions] = React.useState<boolean>(false)
  const [engineVersion, setEngineVersion] = React.useState<string | null>(null)
  const [selectedConfigurationTypes, setSelectedConfigurationTypes] = React.useState<
    ConfigurationType[]
  >(ConfigurationItems.map((configItem: ConfigurationItem) => configItem.type))

  // wizard
  const [error, setError] = React.useState<any | null>(null)
  const [step, setStep] = React.useState<
    "choose-engines" | "choose-configuration" | "review-confirm" | "sync-progress" | "sync-complete"
  >("choose-engines")

  const getEngineVersion = React.useCallback(() => {
    // get the version for this engine
    fetchVersion(server, serverAuthToken)
      .then((responseVersion: ResponseGetVersion) => {
        if (!responseVersion) {
          setError("Failed to get engine version")
        } else {
          setEngineVersion(responseVersion.engineVersion)
        }
      })
      .catch(error => {
        setError(`Failed to get engine version information: ${error.toString()}`)
      })
  }, [server, serverAuthToken, setEngineVersion, setError])

  React.useEffect(() => {
    // get the engine version
    getEngineVersion()
  }, [getEngineVersion])

  const findEngine = React.useCallback(
    (engineId: string) => {
      return engines?.find(engine => engine.id === engineId)
    },
    [engines]
  )

  const handleLogin = React.useCallback(
    async (conn: EngineConnection) => {
      const engine = findEngine(conn.engineId)
      if (!engine) return
      try {
        let otp: string | undefined
        let response: Response
        if (conn.clientAuth || (authSettings.sameCreds && authSettings.sameClientAuth)) {
          response = await logInCertificate(conn.engineUrl)
        } else {
          let username = ""
          let password = ""
          if (authSettings.sameCreds) {
            username = authSettings.sameUsername
            password = authSettings.samePassword
          } else if (conn.username.length !== 0 && conn.password.length !== 0) {
            username = conn.username
            password = conn.password
          } else if (engine.username.length !== 0 && engine.password.length !== 0) {
            username = engine.username
            password = await decrypt(engine.username, engine.password)
          } else {
            throw new Error("Missing creds!")
          }
          otp = conn.twoFactor && conn.otp ? conn.otp : undefined
          response = await logIn(conn.engineUrl, {
            username,
            password,
            otp,
          })
        }
        if (response.ok) {
          const authToken: ResponsePostLogin = await response.json()
          const newEngineConnections = cloneDeep(engineConnections)
          const newConn = newEngineConnections.find(item => item.engineId === conn.engineId)
          if (newConn) {
            if (authToken.authAction) {
              setError(
                `The engine \u201c${getEngineDisplayName(
                  engine
                )}\u201d requires two-factor authentication configuration`
              )
              setConnectState({
                state: "idle",
                modalMessage: "",
                loginConnection: null,
                loginEngine: null,
                loginError: "",
              })
            } else {
              newConn.authToken = authToken.authToken
              newConn.version = authToken.engineVersion
              setEngineConnections(newEngineConnections)
              setConnectState({
                modalMessage: "",
                loginConnection: null,
                loginEngine: null,
                loginError: "",
              })
            }
          }
        } else {
          let loginError = ""
          switch (response.status) {
            case 401:
              if (otp !== undefined) {
                loginError = "Invalid code or credentials"
              } else {
                const twoFactor = !!response.headers.get("WWW-Authenticate")?.match(/Token-2FA/i)
                if (twoFactor) {
                  const newEngineConnections = cloneDeep(engineConnections)
                  const newConn = newEngineConnections.find(item => item.engineId === conn.engineId)
                  if (newConn) {
                    newConn.twoFactor = true
                    setEngineConnections(newEngineConnections)
                    conn = newConn
                  } else {
                    loginError = "Username or password incorrect"
                  }
                }
              }
              break
            case 502:
            case 503:
              loginError = "Service not available"
              break
            default:
              loginError = "A network error occurred"
              break
          }
          setConnectState({
            modalMessage: "",
            loginConnection: conn,
            loginEngine: engine,
            loginError,
          })
        }
      } catch (error) {
        console.error(error)
        setConnectState({
          modalMessage: "",
          loginConnection: conn,
          loginEngine: engine,
          loginError: "A network error occurred",
        })
      }
    },
    [
      setConnectState,
      findEngine,
      engineConnections,
      authSettings.sameCreds,
      authSettings.samePassword,
      authSettings.sameUsername,
      authSettings.sameClientAuth,
    ]
  )

  React.useEffect(() => {
    if (step === "choose-engines") {
      switch (connectState.state) {
        case "idle":
          break
        case "login":
          // Ensure there's no operation in progress (aka modal message).
          if (connectState.modalMessage.length === 0 && connectState.loginConnection === null) {
            // Find the next connection without an auth token.
            const conn = engineConnections.find(conn => conn.authToken.length === 0)
            if (conn) {
              const engine = findEngine(conn.engineId)
              if (engine) {
                let username = ""
                let password = ""
                if (!conn.clientAuth) {
                  if (conn.username.length !== 0 && conn.password.length !== 0) {
                    username = conn.username
                    password = conn.password
                  } else {
                    username = engine.username
                    password = engine.password
                  }
                }
                if (
                  conn.clientAuth ||
                  authSettings.sameCreds ||
                  (username.length !== 0 && password.length !== 0)
                ) {
                  // Ready to log in.
                  setConnectState({
                    modalMessage: `Connecting to engine \u2018${getEngineDisplayName(
                      engine
                    )}\u2019`,
                  })
                  handleLogin(conn)
                } else {
                  if (isUsingGroupAuthentication(engine) && isJWT(serverAuthToken)) {
                    const newEngineConnections = cloneDeep(engineConnections)
                    const newConn = newEngineConnections.find(
                      item => item.engineId === conn.engineId
                    )
                    if (newConn) {
                      newConn.authToken = serverAuthToken
                      setEngineConnections(newEngineConnections)
                      setConnectState({
                        modalMessage: "",
                        loginConnection: null,
                        loginEngine: null,
                        loginError: "",
                      })
                    }
                  } else {
                    // Need credentials - show a modal.
                    setConnectState({
                      modalMessage: "",
                      loginConnection: conn,
                      loginEngine: engine,
                    })
                  }
                }
              }
            } else {
              setConnectState({ state: "getinfo" })
            }
          }
          break
        case "getinfo":
          {
            setConnectState({
              state: "waitinfo",
              modalMessage: "Retrieving engine information...",
            })

            // Request version, capabilities, and settings from all engines.
            const requestsVersions = engineConnections.map(conn =>
              fetchVersion(conn.engineUrl, conn.authToken)
            )
            const requestsCapabilities = engineConnections.map(conn =>
              fetchEngineCapabilities(conn.engineUrl, conn.authToken)
            )
            const requestsSettings = engineConnections.map(conn =>
              fetchSettings(conn.engineUrl, conn.authToken)
            )
            Promise.all([...requestsVersions, ...requestsCapabilities, ...requestsSettings])
              .then(results => {
                if (results.length !== engineConnections.length * 3) {
                  throw new Error("Incomplete results")
                }

                const newEngineConnections = cloneDeep(engineConnections)
                for (let i = 0; i < newEngineConnections.length; i++) {
                  const version = results[i] as ResponseGetVersion
                  const capabilities = results[
                    engineConnections.length + i
                  ] as ResponseGetEngineCapabilities
                  const settings = results[engineConnections.length * 2 + i] as EngineSettings

                  // to be valid, engine versions must match and include a capability for engine configuration sync
                  newEngineConnections[i].version = version.engineVersion
                  newEngineConnections[i].policies = cloneDeep(capabilities.userRights.policies)
                  newEngineConnections[i].valid =
                    capabilities.capabilities.includes(
                      EngineCapabilities.engineConfigurationSync
                    ) && version.engineVersion === engineVersion
                  newEngineConnections[i].settings = settings
                }
                setEngineConnections(newEngineConnections)

                setConnectState({
                  state: "done",
                  modalMessage: "",
                  loginConnection: null,
                  loginEngine: null,
                  loginError: "",
                })
              })
              .catch(error => {
                console.error(error)
                setError("Failed to get all engine information")
                setConnectState({
                  state: "idle",
                  modalMessage: "",
                  loginConnection: null,
                  loginEngine: null,
                  loginError: "",
                })
              })
          }
          break
        case "waitinfo":
          break
        case "done":
          setConnectState({
            state: "idle",
            modalMessage: "",
            loginConnection: null,
            loginEngine: null,
            loginError: "",
          })
          setStep("choose-configuration")
          break
      }
    }
  }, [
    setConnectState,
    findEngine,
    handleLogin,
    step,
    connectState,
    engineConnections,
    engineVersion,
    authSettings.sameCreds,
    serverAuthToken,
  ])

  const onConnectEngines = () => {
    let changes = 0
    const newEngineConnections = engineConnections.filter(conn => {
      const engineId = selectedEngines.find(id => id === conn.engineId)
      if (!engineId) {
        // No longer selected.
        if (conn.authToken.length !== 0) {
          logOut(conn.engineUrl, { authToken: conn.authToken })
        }
        changes++
        return false
      }
      return true
    })
    for (const engineId of selectedEngines) {
      let conn = newEngineConnections.find(conn => conn.engineId === engineId)
      if (!conn) {
        const engine = findEngine(engineId)
        if (engine) {
          conn = {
            engineId,
            engineGroup: engine.group,
            engineHost: engine.host,
            engineName: engine.name,
            engineUrl: `${server}/engines/${engineId}`,
            username: userName || "",
            password: "",
            twoFactor: false,
            otp: "",
            clientAuth: false,
            authToken: "",
            version: "",
            valid: false,
            policies: [],
            settings: null,
            syncResults: [],
            syncStatus: "idle",
            syncError: "",
          }
          newEngineConnections.push(conn)
          changes++
        }
      }
    }

    if (
      changes === 0 &&
      newEngineConnections.findIndex(conn => conn.authToken.length === 0) === -1
    ) {
      // No changes to engine connections and all connections have logged in.
      // Move to the next step.
      setConnectState({ loginError: "" })
      setStep("choose-configuration")
    } else {
      setConnectState({
        state: "login",
        modalMessage: "",
        loginConnection: null,
        loginEngine: null,
        loginError: "",
      })
      setEngineConnections(newEngineConnections)
    }
  }

  const onSynchronize = () => {
    // get the configuration for this engine
    fetchSync(server, serverAuthToken)
      .then((responseSync: ResponseGetEngineSync) => {
        if (!responseSync) {
          setError("Failed to get engine sync settings")
        } else {
          const config: LiveWireConfiguration = {
            alarms: responseSync.alarms ? (responseSync.alarms as AlarmCollection) : null,
            captures: responseSync.captures ? responseSync.captures.captures : [],
            filters: responseSync.filters ? (responseSync.filters as FilterCollection) : null,
            graphs: responseSync.graphs ? (responseSync.graphs as GraphCollection) : null,
            hardwareProfiles: responseSync.hardwareProfiles
              ? (responseSync.hardwareProfiles as HardwareOptionsCollection)
              : null,
            liveFlowConfig: responseSync.liveFlowConfig
              ? (responseSync.liveFlowConfig as LiveFlowConfiguration)
              : null,
            settings: responseSync.engineSettings
              ? (responseSync.engineSettings as EngineSettings)
              : null,
          }

          // determine selected engines that are valid
          const syncEngines = engineConnections.filter(
            conn =>
              conn.valid &&
              conn.authToken.length > 0 &&
              conn.settings !== null &&
              selectedEngines.includes(conn.engineId)
          )

          // determine if there is configuration to sync and target engines
          if (syncEngines.length > 0 && selectedConfigurationTypes.length > 0) {
            // iterate all the selected engines
            for (const id of selectedEngines) {
              const newEngineConnections = cloneDeep(engineConnections)
              const conn = newEngineConnections.find(conn => conn.engineId === id)
              if (conn && conn.valid && conn.authToken.length > 0 && conn.settings !== null) {
                if (config.settings) {
                  conn.settings.acl = config.settings.acl
                  conn.settings.otel = config.settings.otel
                  conn.settings.security = config.settings.security
                }
                conn.syncStatus = "running"
                conn.syncError = ""
                setEngineConnections(newEngineConnections)

                // prepare the sync
                const syncSettings: RequestPostEngineSync = {}
                if (
                  config.alarms &&
                  selectedConfigurationTypes.includes("alarms") &&
                  engineCanSyncConfigType(conn, "alarms", deleteCaptureSessions)
                ) {
                  syncSettings.alarms = config.alarms
                }
                if (
                  (config.captures || config.liveFlowConfig) &&
                  selectedConfigurationTypes.includes("captures") &&
                  engineCanSyncConfigType(conn, "captures", deleteCaptureSessions)
                ) {
                  if (config.captures) {
                    syncSettings.captures = {
                      captures: config.captures,
                      deleteCaptureSessions,
                    }
                  }
                  if (config.liveFlowConfig) {
                    syncSettings.liveFlowConfig = config.liveFlowConfig
                  }
                }
                if (
                  selectedConfigurationTypes.includes("engineSettings") &&
                  engineCanSyncConfigType(conn, "engineSettings", deleteCaptureSessions)
                ) {
                  syncSettings.engineSettings = conn.settings
                }
                if (
                  config.filters &&
                  selectedConfigurationTypes.includes("filters") &&
                  engineCanSyncConfigType(conn, "filters", deleteCaptureSessions)
                ) {
                  syncSettings.filters = config.filters
                }
                if (
                  config.graphs &&
                  selectedConfigurationTypes.includes("graphs") &&
                  engineCanSyncConfigType(conn, "graphs", deleteCaptureSessions)
                ) {
                  syncSettings.graphs = config.graphs
                }
                if (
                  config.hardwareProfiles &&
                  selectedConfigurationTypes.includes("hardwareProfiles") &&
                  engineCanSyncConfigType(conn, "hardwareProfiles", deleteCaptureSessions)
                ) {
                  syncSettings.hardwareProfiles = config.hardwareProfiles
                }

                // perform the sync
                postSync(conn.engineUrl, conn.authToken, syncSettings)
                  .then((responseSyncResults: EngineSyncResults) => {
                    // get the results
                    const syncResults: SyncResult[] = []
                    if (responseSyncResults.alarms !== undefined) {
                      syncResults.push({
                        syncMessages: [...responseSyncResults.alarms.messages],
                        syncResult: responseSyncResults.alarms.result,
                        syncType: "alarms",
                      })
                    }
                    if (responseSyncResults.captures !== undefined) {
                      syncResults.push({
                        syncMessages: [...responseSyncResults.captures.messages],
                        syncResult: responseSyncResults.captures.result,
                        syncType: "captures",
                      })
                    }
                    if (responseSyncResults.engineSettings !== undefined) {
                      syncResults.push({
                        syncMessages: [...responseSyncResults.engineSettings.messages],
                        syncResult: responseSyncResults.engineSettings.result,
                        syncType: "engineSettings",
                      })
                    }
                    if (responseSyncResults.filters !== undefined) {
                      syncResults.push({
                        syncMessages: [...responseSyncResults.filters.messages],
                        syncResult: responseSyncResults.filters.result,
                        syncType: "filters",
                      })
                    }
                    if (responseSyncResults.graphs !== undefined) {
                      syncResults.push({
                        syncMessages: [...responseSyncResults.graphs.messages],
                        syncResult: responseSyncResults.graphs.result,
                        syncType: "graphs",
                      })
                    }
                    if (responseSyncResults.hardwareProfiles !== undefined) {
                      syncResults.push({
                        syncMessages: [...responseSyncResults.hardwareProfiles.messages],
                        syncResult: responseSyncResults.hardwareProfiles.result,
                        syncType: "hardwareProfiles",
                      })
                    }
                    if (responseSyncResults.liveFlowConfig !== undefined) {
                      const syncResult: SyncResult | undefined = syncResults.find(
                        (syncResult: SyncResult) => syncResult.syncType === "captures"
                      )
                      if (syncResult) {
                        if (syncResult.syncResult === PeekResult.PEEK_RESULT_S_OK) {
                          syncResult.syncResult = responseSyncResults.liveFlowConfig.result
                        }
                        syncResult.syncMessages.push(...responseSyncResults.liveFlowConfig.messages)
                      } else {
                        syncResults.push({
                          syncMessages: [...responseSyncResults.liveFlowConfig.messages],
                          syncResult: responseSyncResults.liveFlowConfig.result,
                          syncType: "captures",
                        })
                      }
                    }

                    // report the results
                    setEngineConnections(prevEngineConnections => {
                      const newEngineConnections2 = cloneDeep(prevEngineConnections)
                      const conn2 = newEngineConnections2.find(conn => conn.engineId === id)
                      if (conn2) {
                        if (
                          !syncResults.every(
                            (syncResult: SyncResult) =>
                              syncResult.syncResult === PeekResult.PEEK_RESULT_S_OK
                          )
                        ) {
                          conn2.syncStatus = "error"
                          conn2.syncError = "Failed to sync settings"
                        } else {
                          conn2.syncStatus = "success"
                          conn2.syncError = ""
                        }
                        conn2.syncResults = syncResults
                        if (newEngineConnections2.find(conn => conn.syncStatus !== "running")) {
                          setStep("sync-complete")
                        }
                      }
                      return newEngineConnections2
                    })
                  })
                  .catch(error => {
                    console.error(error)
                    const newEngineConnections2 = cloneDeep(engineConnections)
                    const conn2 = newEngineConnections2.find(conn => conn.engineId === id)
                    if (conn2) {
                      conn2.syncStatus = "error"
                      conn2.syncError = `something went wrong: ${error.toString()}`
                      setEngineConnections(newEngineConnections2)
                      if (newEngineConnections2.find(conn => conn.syncStatus !== "running")) {
                        setStep("sync-complete")
                      }
                    }
                  })
              }
            }
          }
        }
      })
      .catch(error => {
        setError(`Failed to get configuration information: ${error.toString()}`)
      })
  }

  const onNext = () => {
    if (step === "choose-engines") {
      onConnectEngines()
    } else if (step === "choose-configuration") {
      setStep("review-confirm")
    } else if (step === "review-confirm") {
      setStep("sync-progress")
      onSynchronize()
    }
  }

  const onPrevious = () => {
    if (step === "choose-configuration") {
      setStep("choose-engines")
    } else if (step === "review-confirm") {
      setStep("choose-configuration")
    }
  }

  const onFinish = () => {
    setAuthSettings({
      sameCreds: false,
      sameUsername: "",
      samePassword: "",
      sameClientAuth: false,
    })
    setConnectState({
      state: "idle",
      modalMessage: "",
      loginConnection: null,
      loginEngine: null,
      loginError: "",
    })
    setEngineConnections([])
    setEngineFilter("")
    setEnginesCollapsedGroups(new Set<string>())
    setEnginesSortBy("name")
    setEnginesSortDirection(SortDirection.ASC)
    setSelectedEngines([])

    getEngineVersion()
    setConfigurationsSortBy("name")
    setConfigurationsSortDirection(SortDirection.ASC)
    setDeleteCaptureSessions(false)
    setSelectedConfigurationTypes(
      ConfigurationItems.map((configItem: ConfigurationItem) => configItem.type)
    )

    setError(null)
    setStep("choose-engines")
  }

  let stepNumber = 1
  let subtitle: string = ""
  let header: React.ReactNode = null
  let form: React.ReactNode = null
  if (step === "choose-engines") {
    stepNumber = 1
    const isSelectionValid = selectedEngines.length > 0
    const isCredentialsValid =
      !authSettings.sameCreds ||
      (authSettings.sameUsername.length > 0 && authSettings.samePassword.length > 0) ||
      authSettings.sameClientAuth
    const isNextEnabled = isSelectionValid && isCredentialsValid
    if (engines !== null) {
      subtitle = "Push configuration from this engine to selected engines"
      header = (
        <>
          <PrimaryButton
            id="next"
            disabled={!isNextEnabled || engineVersion === null || connectState.state !== "idle"}
            onClick={() => onNext()}
          >
            Next
          </PrimaryButton>
        </>
      )
      form = (
        <ChooseEngines
          engines={engines}
          authSettings={authSettings}
          setAuthSettings={setAuthSettings}
          engineFilter={engineFilter}
          setEngineFilter={setEngineFilter}
          sortBy={enginesSortBy}
          setSortBy={setEnginesSortBy}
          sortDirection={enginesSortDirection}
          setSortDirection={setEnginesSortDirection}
          collapsedGroups={enginesCollapsedGroups}
          setCollapsedGroups={setEnginesCollapsedGroups}
          selectedEngines={selectedEngines}
          setSelectedEngines={setSelectedEngines}
        />
      )
    }
  } else if (step === "choose-configuration") {
    stepNumber = 2
    const isSelectionValid = selectedConfigurationTypes.length > 0
    subtitle = "Choose configuration to push to selected engines"
    header = (
      <>
        <SecondaryButton id="previous" onClick={() => onPrevious()}>
          Previous
        </SecondaryButton>
        <PrimaryButton id="next" disabled={!isSelectionValid} onClick={() => onNext()}>
          Next
        </PrimaryButton>
      </>
    )
    form = (
      <ChooseConfiguration
        sortBy={configurationsSortBy}
        setSortBy={setConfigurationsSortBy}
        sortDirection={configurationsSortDirection}
        setSortDirection={setConfigurationsSortDirection}
        deleteCaptureSessions={deleteCaptureSessions}
        setDeleteCaptureSessions={setDeleteCaptureSessions}
        selectedConfigurationTypes={selectedConfigurationTypes}
        setSelectedConfigurationTypes={setSelectedConfigurationTypes}
      />
    )
  } else if (step === "review-confirm") {
    stepNumber = 3
    const selectedConfigurationItems = ConfigurationItems.filter((configItem: ConfigurationItem) =>
      selectedConfigurationTypes.includes(configItem.type)
    )
    const isNextEnabled =
      selectedEngines.filter((selectedEngine: string) => {
        const conn = engineConnections.find(
          (engineConnection: EngineConnection) => engineConnection.engineId === selectedEngine
        )
        return conn !== undefined && conn.valid
      }).length > 0
    subtitle = "Confirm which engines should receive selected configuration from this engine"
    header = (
      <>
        <SecondaryButton id="previous" onClick={() => onPrevious()}>
          Previous
        </SecondaryButton>
        <PrimaryButton id="next" disabled={!isNextEnabled} onClick={() => onNext()}>
          Start
        </PrimaryButton>
      </>
    )
    form = (
      <>
        <SyncEngines
          engines={engineConnections}
          engineFilter={engineFilter}
          engineVersion={engineVersion}
          deleteCaptureSessions={deleteCaptureSessions}
          review={true}
          final={false}
          setEngineFilter={setEngineFilter}
          sortBy={enginesSortBy}
          setSortBy={setEnginesSortBy}
          sortDirection={enginesSortDirection}
          setSortDirection={setEnginesSortDirection}
          collapsedGroups={enginesCollapsedGroups}
          setCollapsedGroups={setEnginesCollapsedGroups}
          selectedEngines={selectedEngines}
          setSelectedEngines={setSelectedEngines}
          selectedConfigurationItems={selectedConfigurationItems}
        />
      </>
    )
  } else if (step === "sync-progress") {
    stepNumber = 4
    const selectedConfigurationItems = ConfigurationItems.filter((configItem: ConfigurationItem) =>
      selectedConfigurationTypes.includes(configItem.type)
    )
    subtitle = "Syncing selected configuration from this engine to selected engines"
    form = (
      <>
        <SyncEngines
          engines={engineConnections}
          engineFilter={engineFilter}
          engineVersion={engineVersion}
          deleteCaptureSessions={deleteCaptureSessions}
          review={false}
          final={false}
          setEngineFilter={setEngineFilter}
          sortBy={enginesSortBy}
          setSortBy={setEnginesSortBy}
          sortDirection={enginesSortDirection}
          setSortDirection={setEnginesSortDirection}
          collapsedGroups={enginesCollapsedGroups}
          setCollapsedGroups={setEnginesCollapsedGroups}
          selectedEngines={selectedEngines}
          selectedConfigurationItems={selectedConfigurationItems}
        />
      </>
    )
  } else if (step === "sync-complete") {
    stepNumber = 5
    const selectedConfigurationItems = ConfigurationItems.filter((configItem: ConfigurationItem) =>
      selectedConfigurationTypes.includes(configItem.type)
    )
    const isFinishEnabled = selectedEngines.every((selectedEngine: string) => {
      const conn = engineConnections.find(
        (engineConnection: EngineConnection) => engineConnection.engineId === selectedEngine
      )
      return (
        conn !== undefined &&
        conn.valid &&
        (conn.syncStatus === "success" || conn.syncStatus === "error")
      )
    })
    subtitle =
      "Showing results for pushing selected configuration from this engine to selected engines"
    header = (
      <>
        <PrimaryButton id="finish" disabled={!isFinishEnabled} onClick={() => onFinish()}>
          Finish
        </PrimaryButton>
      </>
    )
    form = (
      <>
        <SyncEngines
          engines={engineConnections}
          engineFilter={engineFilter}
          engineVersion={engineVersion}
          deleteCaptureSessions={deleteCaptureSessions}
          review={false}
          final={true}
          setEngineFilter={setEngineFilter}
          sortBy={enginesSortBy}
          setSortBy={setEnginesSortBy}
          sortDirection={enginesSortDirection}
          setSortDirection={setEnginesSortDirection}
          collapsedGroups={enginesCollapsedGroups}
          setCollapsedGroups={setEnginesCollapsedGroups}
          selectedEngines={selectedEngines}
          selectedConfigurationItems={selectedConfigurationItems}
        />
      </>
    )
  }

  return (
    <>
      <View padding={false}>
        <Helmet title="Engine Configuration Sync" />
        <BreadcrumbItem to={getEngineConfigurationSyncUrl()} title="Engine Configuration Sync" />
        <WizardViewContent maxWidth="960px">
          <WizardHeader>
            <WizardHeaderTitleWrapper>
              <WizardHeaderTitle>Engine Configuration Sync</WizardHeaderTitle>
              <WizardHeaderSubTitle>{subtitle}</WizardHeaderSubTitle>
            </WizardHeaderTitleWrapper>
            <ButtonBar>{header}</ButtonBar>
          </WizardHeader>
          <EngineTitle>This Engine</EngineTitle>
          <StatusPanel>
            <StatusPanelBody>
              <PropTable
                skipEmptyRows={true}
                propList={propList}
                data={engineStatus}
                propToLabel={propToLabel}
                formatProp={formatProp}
              />
            </StatusPanelBody>
          </StatusPanel>
          <StepProgressWrapper>
            <StepProgress
              items={[
                "1. Choose Engines",
                "2. Choose Configuration",
                "3. Review & confirm",
                "4. Progress",
                "5. Complete",
              ]}
              value={stepNumber - 1}
            />
          </StepProgressWrapper>
          {error?.length > 0 ? (
            <Alert color="danger" fade={false}>
              {error}
            </Alert>
          ) : null}
          {form}
        </WizardViewContent>
      </View>
      {connectState.modalMessage.length !== 0 && (
        <Modal isOpen={true}>
          <ModalBody>{connectState.modalMessage}</ModalBody>
        </Modal>
      )}
      {connectState.loginConnection !== null && connectState.loginEngine !== null ? (
        <LoginModal
          username={connectState.loginConnection.username || connectState.loginEngine.username}
          password={connectState.loginConnection.password}
          twoFactor={connectState.loginConnection.twoFactor}
          title="Login"
          subTitle={
            <div style={{ marginBottom: "1rem" }}>
              <h6 style={{ marginBottom: 0, fontSize: "14px" }}>
                {getEngineDisplayName(connectState.loginEngine)}
              </h6>
              <MutedText as="small">{connectState.loginEngine.host}</MutedText>
            </div>
          }
          okText={connectState.loginConnection.twoFactor ? "Verify" : "Login"}
          error={connectState.loginError}
          onOK={(username: string, password: string, otp: string, clientAuth: boolean) => {
            if (connectState.loginConnection !== null) {
              const newEngineConnections = cloneDeep(engineConnections)
              const id = connectState.loginConnection.engineId
              const newConn = newEngineConnections.find(item => item.engineId === id)
              if (newConn) {
                newConn.username = username
                newConn.password = password
                newConn.otp = otp
                newConn.clientAuth = clientAuth
                setEngineConnections(newEngineConnections)
                setConnectState({ loginConnection: null })
              }
            }
          }}
          onCancel={() => {
            if (connectState.loginConnection !== null) {
              const newEngineConnections = cloneDeep(engineConnections)
              const id = connectState.loginConnection.engineId
              const newConn = newEngineConnections.find(item => item.engineId === id)
              if (newConn) {
                newConn.username = ""
                newConn.password = ""
                newConn.twoFactor = false
                newConn.otp = ""
                newConn.clientAuth = false
                setEngineConnections(newEngineConnections)
              }
            }
            setConnectState({ state: "idle", loginConnection: null })
          }}
        />
      ) : null}
    </>
  )
}

export default EngineConfigurationSyncView
