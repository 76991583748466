export enum MergeOptions {
  NoMatchAdd = 0x0001,
  NoMatchSkip = 0x0002,
  NameMatchAdd = 0x0010,
  NameMatchReplace = 0x0020,
  NameMatchSkip = 0x0040,
  EntryMatchAdd = 0x0100,
  EntryMatchReplace = 0x0200,
  EntryMatchSkip = 0x0400,
  Delete = 0x8000,
  Default = NoMatchAdd | NameMatchReplace | EntryMatchSkip,
  Manual = NoMatchAdd | NameMatchAdd | EntryMatchReplace,
}
