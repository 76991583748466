import React from "react"
import styled from "styled-components"
import { DropdownItem, DropdownMenu, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import FontAwesome from "react-fontawesome"
import { IconDropdownToggle } from "../common/Buttons"

const DataTypeDropdown = styled.div`
  vertical-align: text-top;
  float: right;
  position: relative;
`

const DROPDOWNS = ["Auto", "Image", "Text", "Hexadecimal"]

interface ReconstructionsDropdownProps {
  activeDataType: string
  onChangeDataType: (dataType: string) => void
}

const ReconstructionsDropdown: React.FC<ReconstructionsDropdownProps> = ({
  activeDataType,
  onChangeDataType,
}) => {
  return (
    <DataTypeDropdown>
      <UncontrolledDropdownWithPortal
        dropdownToggle={
          <IconDropdownToggle>
            <FontAwesome name="ellipsis-h" fixedWidth />
          </IconDropdownToggle>
        }
      >
        <DropdownMenu end>
          {DROPDOWNS.map((dropdown) => (
            <DropdownItem key={dropdown}
            onClick={() => {
              onChangeDataType(dropdown)
            }}
            active={activeDataType === dropdown}
          >
            {dropdown}
          </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdownWithPortal>
    </DataTypeDropdown>
  )
}

export default ReconstructionsDropdown
