import * as React from "react"
import { cloneDeep } from "lodash"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Alert } from "../common/Alert"
import { getExpertQuery, getExpertResults, formatExpertValue } from "../../utils/expertUtils"
import { getEngine, getAuthToken } from "../../store"
import { expertQueryCFS } from "../../api/api"
import { RequestExpertQuery, ExpertQueryResponse } from "../../api/types"
import { ExpertColumn, ExpertView } from "../../api/types/expertTypes"
import { largestTriangleThreeBuckets } from "../../utils/largestTriangleThreeBuckets"
import { RTPChart, RTPChartData } from "./RTPChart"

// See: https://engwiki.savvius.com/index.php/Add_RTP_Graph_Tab_in_VoIP_Visual_Expert

const ViewWrapper = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-rows: 50% 50%;
`

const queryTemplate: RequestExpertQuery = {
  query: [
    {
      columnList: [
        ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER,
        ExpertColumn.EXPERT_COLUMN_CALL_ID,
        ExpertColumn.EXPERT_COLUMN_MEDIA_TYPE,
        ExpertColumn.EXPERT_COLUMN_RTP_JITTER_LIST,
        ExpertColumn.EXPERT_COLUMN_RTP_Q6_LIST,
        ExpertColumn.EXPERT_COLUMN_RTP_Q7_LIST,
        ExpertColumn.EXPERT_COLUMN_SSRC,
        ExpertColumn.EXPERT_COLUMN_RTP_TIME_LIST,
        ExpertColumn.EXPERT_COLUMN_FLOW_INDEX,
        ExpertColumn.EXPERT_COLUMN_RTP_FLAG_LIST,
        ExpertColumn.EXPERT_COLUMN_RTP_JITTER_MICRO_LIST,
      ],
      orderBy: [ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER],
      orderByAscending: true,
      view: ExpertView.EXPERT_VIEW_VOIP_SIGNALING,
      viewSettings: {
        la: "\u2190",
        ra: "\u2192",
        ba: "\u2194",
      },
    },
  ],
}

function downsampleChartData(chartData: RTPChartData[], threshold: number) {
  const downsampledData: RTPChartData[] = []
  for (let i = 0, l = chartData.length; i < l; i++) {
    const data = largestTriangleThreeBuckets(chartData[i].data, threshold, "x", "y")
    downsampledData.push({
      flow: chartData[i].flow,
      data,
    })
    //console.log(chartData[i].flow, chartData[i].data.length, data.length)
  }
  return downsampledData
}

type RTPViewData = {
  jitterData: RTPChartData[]
  jitterMicro: boolean
  qualityData: RTPChartData[]
}

function responseToData(response: ExpertQueryResponse, threshold: number): RTPViewData | null {
  const signaling = getExpertResults(response.results, ExpertView.EXPERT_VIEW_VOIP_SIGNALING)
  if (signaling && signaling.rowList) {
    const nMediaTypeIndex = signaling.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_MEDIA_TYPE)
    const nRTPJitterIndex = signaling.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_RTP_JITTER_LIST)
    const nRTPJitterMicroIndex = signaling.columnList.indexOf(
      ExpertColumn.EXPERT_COLUMN_RTP_JITTER_MICRO_LIST
    )
    const nRTPQ6Index = signaling.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_RTP_Q6_LIST)
    const nRTPQ7Index = signaling.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_RTP_Q7_LIST)
    const nSSRCIndex = signaling.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_SSRC)
    const nRTPTimeIndex = signaling.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_RTP_TIME_LIST)
    const nFlowIndexIndex = signaling.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_FLOW_INDEX)
    const nRTPFlagIndex = signaling.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_RTP_FLAG_LIST)
    if (
      nMediaTypeIndex !== -1 &&
      (nRTPJitterIndex !== -1 || nRTPJitterMicroIndex !== -1) &&
      nRTPQ6Index !== -1 &&
      nRTPQ7Index !== -1 &&
      nSSRCIndex !== -1 &&
      nRTPTimeIndex !== -1 &&
      nFlowIndexIndex !== -1 &&
      nRTPFlagIndex !== -1
    ) {
      let rtpStartTime = 0
      let jitterMicro = false
      const jitterFlowMap: any = {}
      const qualityFlowMap: any = {}
      const flowIndexSSRCJitterMap: any = {}
      const flowIndexSSRCQualityMap: any = {}
      for (const row of signaling.rowList) {
        const flowIndexValue = row[nFlowIndexIndex]?.value as number
        if (flowIndexValue != null) {
          const flowIndex = flowIndexValue + 1
          const rtpTimeList = row[nRTPTimeIndex].value
          if (Array.isArray(rtpTimeList) && rtpTimeList.length > 0) {
            const rtpFirstTime = Date.parse(rtpTimeList[0] as string)
            if (rtpStartTime === 0 || rtpFirstTime < rtpStartTime) {
              rtpStartTime = rtpFirstTime
            }

            const mediaType = row[nMediaTypeIndex].value ?? 0

            if (!(flowIndex in flowIndexSSRCJitterMap)) {
              const ssrcValue = row[nSSRCIndex].value
              if (ssrcValue != null) {
                const ssrc = formatExpertValue(ExpertColumn.EXPERT_COLUMN_SSRC, ssrcValue)
                if (ssrc) {
                  let qualityMetric = ""
                  switch (mediaType) {
                    case 1: // Audio
                    case 4: // Voice
                      qualityMetric = "MOS-CQ"
                      break
                    case 2: // Video
                      qualityMetric = "MOS-V"
                      break
                  }

                  flowIndexSSRCJitterMap[flowIndex] = `${flowIndex}: ${ssrc}`
                  flowIndexSSRCQualityMap[flowIndex] = `${flowIndex}: ${ssrc} (${qualityMetric})`
                } else {
                  flowIndexSSRCJitterMap[flowIndex] = ""
                  flowIndexSSRCQualityMap[flowIndex] = ""
                }
              }
            }

            const rtpFlagList = row[nRTPFlagIndex].value

            // store the jitter values by timestamp and grouped by flow index
            let rtpJitterList = null
            if (nRTPJitterMicroIndex !== -1) {
              rtpJitterList = row[nRTPJitterMicroIndex].value
              if (!Array.isArray(rtpJitterList)) {
                rtpJitterList = row[nRTPJitterIndex].value
              } else {
                jitterMicro = true
              }
            } else {
              rtpJitterList = row[nRTPJitterIndex].value
            }
            if (!(flowIndex in jitterFlowMap)) {
              jitterFlowMap[flowIndex] = {}
            }
            const jitterMap = jitterFlowMap[flowIndex]
            if (
              jitterMap &&
              Array.isArray(rtpJitterList) &&
              rtpTimeList.length === rtpJitterList.length &&
              Array.isArray(rtpFlagList) &&
              rtpTimeList.length === rtpFlagList.length
            ) {
              for (let i = 0, len = rtpTimeList.length; i < len; i++) {
                const flags = rtpFlagList[i] as number
                // Test flags for codec unsupported
                if ((flags & 0x10) === 0) {
                  jitterMap[rtpTimeList[i] as number | string] = jitterMicro
                    ? Number(rtpJitterList[i]) / 1000
                    : rtpJitterList[i]
                }
              }
            }

            let rtpQualityIndex = -1
            switch (mediaType) {
              case 1: // Audio
              case 4: // Voice
                rtpQualityIndex = nRTPQ6Index
                break
              case 2: // Video
                rtpQualityIndex = nRTPQ7Index
                break
            }
            let rtpQualityList: number[] = []
            if (rtpQualityIndex !== -1) {
              const rtpQualityValue = row[rtpQualityIndex]
              if (rtpQualityValue && Array.isArray(rtpQualityValue.value)) {
                rtpQualityList = rtpQualityValue.value as number[]
              }
            }
            if (!(flowIndex in qualityFlowMap)) {
              qualityFlowMap[flowIndex] = {}
            }
            const qualityMap = qualityFlowMap[flowIndex]
            if (
              qualityMap &&
              Array.isArray(rtpQualityList) &&
              rtpTimeList.length === rtpQualityList.length &&
              Array.isArray(rtpFlagList) &&
              rtpTimeList.length === rtpFlagList.length
            ) {
              for (let i = 0, len = rtpTimeList.length; i < len; i++) {
                const flags = rtpFlagList[i] as number
                // Test for codec unsupported and quality valid combined
                if ((flags & 0x30) === 0x20) {
                  qualityMap[rtpTimeList[i] as number | string] = rtpQualityList[i]
                }
              }
            }
          }
        }
      }

      const jitterData = []
      const qualityData = []
      for (const [flowIndex, flowJitter] of Object.entries(jitterFlowMap)) {
        const jitter: any[] = []
        for (const [time, value] of Object.entries(flowJitter as object)) {
          const x = Date.parse(time)
          const y = value
          jitter.push({ x, y })
        }
        for (let i = 0, l = jitter.length; i < l; i++) {
          jitter[i].x = (jitter[i].x - rtpStartTime) * 1000000
        }

        const quality: any[] = []
        const flowQuality = qualityFlowMap[flowIndex]
        if (flowQuality) {
          for (const [time, value] of Object.entries(flowQuality as object)) {
            const x = Date.parse(time)
            const y = value / 100
            quality.push({ x, y })
          }
          for (let i = 0, l = quality.length; i < l; i++) {
            quality[i].x = (quality[i].x - rtpStartTime) * 1000000
          }
        }

        if (jitter.length > 0) {
          jitterData.push({
            flow: flowIndexSSRCJitterMap[flowIndex],
            data: jitter,
          })
        }
        if (quality.length > 0) {
          qualityData.push({
            flow: flowIndexSSRCQualityMap[flowIndex],
            data: quality,
          })
        }
      }

      // Downsample data
      return {
        jitterData: downsampleChartData(jitterData, threshold),
        jitterMicro,
        qualityData: downsampleChartData(qualityData, threshold),
      }
    }
  }

  return null
}

type RouteParams = {
  type: string
  capId: string
  callId: string
}

const RTPView: React.FC = () => {
  const engine = useSelector(getEngine)
  const authToken = useSelector(getAuthToken)
  const { type, capId, callId } = useParams<RouteParams>()
  const [chartData, setChartData] = React.useState<RTPViewData | null>(null)
  const [error, setError] = React.useState<any | null>(null)

  React.useEffect(() => {
    const request = cloneDeep(queryTemplate)

    // Set the columns in the query.
    const querySignaling = getExpertQuery(request.query, ExpertView.EXPERT_VIEW_VOIP_SIGNALING)
    if (callId && querySignaling) {
      // Set the call id in the query.
      querySignaling.where = {
        criteria: "parent",
        key: callId.split(",").map((id: string) => {
          return {
            column: ExpertColumn.EXPERT_COLUMN_CALL_ID,
            value: id,
          }
        }),
      }

      // Make the request
      expertQueryCFS(engine, authToken, type, capId, request)
        .then((response: ExpertQueryResponse) => {
          if (response) {
            const threshold = 1000
            setChartData(responseToData(response, threshold))
          }
        })
        .catch(() => {
          setError("An error occurred")
        })
    }
  }, [engine, authToken, type, capId, callId])

  return (
    <ViewWrapper>
      {error ? (
        <Alert color="danger" fade={false}>
          {typeof error === "string" ? error : `${error.code} ${error.reason}`}
        </Alert>
      ) : chartData != null ? (
        <>
          <RTPChart
            chartData={chartData.jitterData}
            jitterMicro={chartData.jitterMicro}
            title="Jitter (ms)"
          />
          <RTPChart chartData={chartData.qualityData} mosScore={true} title="Quality" />
        </>
      ) : null}
    </ViewWrapper>
  )
}

export default RTPView
