import * as React from "react"
import cn from "classnames"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { FormGroup } from "reactstrap"
import {
  SortDirection,
  SortDirectionType,
  SortIndicator,
  TableCellProps,
  TableHeaderProps,
  TableRowProps,
} from "react-virtualized"
import { Alert } from "../../common/Alert"
import {
  ButtonBar,
  CheckGroup,
  CheckGroupIndeterminate,
  HorizontalFormGroup,
} from "../../common/Form"
import { FilterBox } from "../../common/FilterBox"
import { LightButton, PrimaryButton } from "../../common/Buttons"
import { LoginModal } from "../../LoginModal"
import { MutedText } from "../../common/MutedText"
import { OmniTable } from "../../common/OmniTable"
import { collator } from "../../../utils/sortUtils"
import { formatInteger } from "../../../utils/formatUtils"
import { getEngineDisplayName } from "../../../utils/engineUtils"
import { Engine } from "../../../api/types"
import { SearchContext } from "../types"
import { WizardProps } from "./types"
import {
  WizardHeader,
  WizardHeaderTitleWrapper,
  WizardHeaderTitle,
  WizardHeaderSubTitle,
} from "./Styles"

const columnDesc = [
  {
    dataKey: "name",
    label: "Name",
    width: 250,
    flexGrow: 1,
    headerStyle: { paddingLeft: "1.7857rem" },
  },
  {
    dataKey: "host",
    label: "Host",
    width: 250,
  },
]

const ListFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 0.5rem !important;

  @media (max-width: 768px) {
    flex-direction: column;

    & > * + * {
      margin-top: 0.5rem !important;
    }
  }
`

type EngineGroup = {
  name: string
  children: Engine[]
}

type EngineRowData = Engine | EngineGroup

function rowDataToId(rowData: EngineRowData) {
  const rawId = (rowData as Engine).id || rowData.name
  const id = rawId.replace(" ", "_")
  return "id:" + id
}

function isGroupRowData(rowData: EngineRowData) {
  return "children" in rowData
}

type ChooseEnginesProps = WizardProps & {
  engines: Engine[]
  serverEngine: Engine
  engineFilter: string
  setEngineFilter: (filter: string) => void
  selectedEngines: string[]
  setSelectedEngines: (selectedEngine: string[]) => void
  searchContext: SearchContext
  setSearchContext: (context: SearchContext) => void
  error: string
}

const ChooseEngines = ({
  onPrevious,
  onNext,
  engines,
  serverEngine,
  engineFilter,
  setEngineFilter,
  selectedEngines,
  setSelectedEngines,
  searchContext,
  setSearchContext,
  error,
}: ChooseEnginesProps) => {
  const [sortBy, setSortBy] = React.useState("name")
  const [sortDirection, setSortDirection] = React.useState<SortDirectionType>(SortDirection.ASC)
  const [collapsedGroups, setCollapsedGroups] = React.useState(new Set<string>())
  const [showCredsModal, setShowCredsModal] = React.useState(false)

  const getViewEngines = (): Engine[] => {
    if (Array.isArray(engines) && engines.length > 0) {
      const lowerCaseFilter = engineFilter.toLowerCase()
      return engines.filter(engine => {
        return (
          getEngineDisplayName(engine).toLowerCase().indexOf(lowerCaseFilter) !== -1 ||
          engine.host.toLowerCase().indexOf(lowerCaseFilter) !== -1
        )
      })
    }
    return []
  }

  const viewEngines = getViewEngines()

  const onSort = ({
    sortBy,
    sortDirection,
    event,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
    event: any
  }) => {
    const tagName = (event?.target as HTMLElement)?.tagName
    if (tagName === "LABEL" || tagName === "INPUT") {
      return
    }
    setSortBy(sortBy)
    setSortDirection(sortDirection)
  }

  const onExpandCollapse = (group: string) => {
    const newCollapsedGroups = new Set<string>(collapsedGroups)
    if (newCollapsedGroups.has(group)) {
      newCollapsedGroups.delete(group)
    } else {
      newCollapsedGroups.add(group)
    }
    setCollapsedGroups(newCollapsedGroups)
  }

  const onCheckEngine = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    const id = event.target.name
    const newSelectedEngines = selectedEngines.filter(selectedId => selectedId !== id)
    // If the engine was selected, it is now removed from the list, else if
    // the list hasn't changed it needs to be added.
    if (newSelectedEngines.length === selectedEngines.length) {
      newSelectedEngines.push(id)
    }
    setSelectedEngines(newSelectedEngines)
  }

  const onEnableAll = () => {
    const newSelectedEngines = viewEngines.map(engine => engine.id)
    newSelectedEngines.push(serverEngine.id)
    setSelectedEngines(newSelectedEngines)
  }

  const onDisableAll = () => {
    setSelectedEngines([])
  }

  const getFlattenedTree = () => {
    if (viewEngines != null) {
      // Build hierarchical representation
      const groups: EngineGroup[] = []
      viewEngines.forEach(engine => {
        const group = groups.find(group => collator.compare(group.name, engine.group) === 0)
        if (group) {
          group.children.push(engine)
        } else {
          groups.push({
            name: engine.group,
            children: [engine],
          })
        }
      })

      // Sort top level groups by name
      groups.sort((a, b) => {
        let result = 0
        if (a.name === "") {
          result = 1
        } else if (b.name === "") {
          result = -1
        } else {
          result = collator.compare(a.name, b.name)
        }
        if (sortDirection === SortDirection.DESC) result = -result
        return result
      })

      // Sort children
      groups.forEach(group => {
        // Only need to sort if top level or expanded
        if (group.name.length === 0 || !collapsedGroups.has(group.name)) {
          group.children.sort((a: Engine, b: Engine) => {
            let result = 0
            switch (sortBy) {
              case "name":
                result = collator.compare(getEngineDisplayName(a), getEngineDisplayName(b))
                if (result === 0) {
                  result = collator.compare(a.host, b.host)
                }
                break
              case "host":
                result = collator.compare(a.host, b.host)
                break
            }
            if (sortDirection === SortDirection.DESC) result = -result
            return result
          })
        }
      })

      // Flatten the list into rows
      const rows: EngineRowData[] = []
      groups.forEach(group => {
        if (group.name) {
          rows.push(group)
          const collapsed = collapsedGroups.has(group.name)
          if (!collapsed) {
            group.children.forEach((item: any, index: number) => {
              rows.push({ ...item, childIndex: index })
            })
          }
        } else {
          group.children.forEach((item: any, index: number) => {
            rows.push({ ...item, childIndex: index })
          })
        }
      })

      // Insert the server engine at the beginning.
      if (serverEngine != null) {
        rows.unshift(serverEngine)
      }

      return rows
    }
    return null
  }

  const onRowClick = ({ rowData }: { rowData: any }) => {
    if (isGroupRowData(rowData)) {
      onExpandCollapse((rowData as EngineGroup).name)
    }
  }

  const rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    if (isGroupRowData(rowData)) {
      className = cn(className, "group", "clickable")
      if (Array.isArray(columns) && columns.length > 1) {
        // Only need the first column
        columns = [columns[0]]
      }
      key = (rowData as EngineGroup).name
    } else {
      if (rowData.childIndex != null && rowData.childIndex % 2 !== 0) {
        className = cn(className, "stripe")
      }
      key = rowData.id
    }

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  const rowClassName = () => {
    // Stripes applied in rowRenderer
    return ""
  }

  const nameHeaderRenderer = ({ dataKey, label, sortBy, sortDirection }: TableHeaderProps) => {
    let checkState = 0
    if (selectedEngines.length > 0) {
      if (viewEngines.length + 1 === selectedEngines.length) {
        checkState = 1
      } else {
        checkState = 2
      }
    }
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
        }}
      >
        <div style={{ fontSize: "1rem" }}>
          <CheckGroupIndeterminate
            type="checkbox"
            value={checkState}
            id="check-all"
            onChange={() => {
              if (checkState !== 1) {
                onEnableAll()
              } else {
                onDisableAll()
              }
            }}
          />
        </div>
        <span>{label}</span>
        {sortBy === dataKey ? <SortIndicator sortDirection={sortDirection} /> : null}
      </div>
    )
  }

  const cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content
    const isGroup = isGroupRowData(rowData)
    if (isGroup) {
      const rowItem: EngineGroup = rowData

      const expanded = !collapsedGroups.has(cellData)
      const icon = expanded ? "chevron-down" : "chevron-right"
      let checkCount = 0
      for (const engine of rowItem.children) {
        const checked = selectedEngines.find(selectedId => selectedId === engine.id) !== undefined
        if (checked) {
          checkCount++
        }
      }
      let checkState = 0
      if (checkCount === rowItem.children.length) {
        checkState = 1
      } else if (checkCount > 0) {
        checkState = 2
      }
      content = (
        <div style={{ display: "flex", alignItems: "center" }}>
          <FontAwesome name={icon} fixedWidth />
          <span style={{ paddingLeft: ".25em" }}>
            <CheckGroupIndeterminate
              type="checkbox"
              value={checkState}
              id={rowDataToId(rowItem)}
              onChange={() => {
                let newSelectedEngines = selectedEngines.filter(selectedId => {
                  const engine = engines.find(engine => engine.id === selectedId)
                  return engine && engine.group !== rowItem.name
                })

                if (checkState !== 1) {
                  newSelectedEngines = newSelectedEngines.concat(
                    rowItem.children.map(engine => engine.id)
                  )
                }
                setSelectedEngines(newSelectedEngines)
              }}
            >
              {cellData} <MutedText>{`(${formatInteger(rowItem.children.length)})`}</MutedText>
            </CheckGroupIndeterminate>
          </span>
        </div>
      )
    } else {
      const engine: Engine = rowData
      switch (dataKey) {
        case "name":
          {
            const id = rowDataToId(engine)
            const checked =
              selectedEngines.find(selectedId => selectedId === engine.id) !== undefined
            const marginLeft = engine.group ? "3.036em" : "1.536em"
            const isServerEngine = engine === serverEngine
            const name = getEngineDisplayName(engine)
            const label = isServerEngine ? (
              <>
                <span style={{ paddingRight: ".5rem" }} title={name}>
                  {name}
                </span>
                <FontAwesome name="star" />
              </>
            ) : (
              <span title={name}>{name}</span>
            )
            content = (
              <div style={{ display: "flex", alignItems: "center", marginLeft }}>
                <CheckGroup
                  type="checkbox"
                  name={engine.id}
                  id={id}
                  onChange={onCheckEngine}
                  checked={checked}
                >
                  {label}
                </CheckGroup>
              </div>
            )
          }
          break
        case "host":
          content = engine.host
          break
      }
    }
    return content
  }

  // Build the flattened tree for the table.
  const flattenedTree = getFlattenedTree()

  // Add the checkbox header renderer to the columns.
  const columns: any[] = [...columnDesc]
  const nameColumn = columns.find(col => col.dataKey === "name")
  if (nameColumn) {
    nameColumn.headerRenderer = nameHeaderRenderer
  }

  const isSelectionValid = selectedEngines.length > 0
  const isCredentialsValid =
    !searchContext.sameCreds ||
    (searchContext.sameUsername.length > 0 && searchContext.samePassword.length > 0) ||
    searchContext.sameClientAuth
  const isValid = isSelectionValid && isCredentialsValid

  return (
    <>
      <WizardHeader>
        <WizardHeaderTitleWrapper>
          <WizardHeaderTitle>Engines</WizardHeaderTitle>
          <WizardHeaderSubTitle>Choose engines to search</WizardHeaderSubTitle>
        </WizardHeaderTitleWrapper>
        <ButtonBar>
          <LightButton onClick={onPrevious}>Previous</LightButton>
          <PrimaryButton onClick={onNext} disabled={!isValid}>
            Next
          </PrimaryButton>
        </ButtonBar>
      </WizardHeader>

      {error.length > 0 ? (
        <Alert color="danger" fade={false}>
          {error}
        </Alert>
      ) : null}

      {flattenedTree && (
        <>
          <ListFormGroup
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "0.5rem",
            }}
          >
            <HorizontalFormGroup noMargin>
              <CheckGroup
                type="checkbox"
                id="sameCreds"
                name="sameCreds"
                onChange={() => {
                  setSearchContext({
                    ...searchContext,
                    sameCreds: !searchContext.sameCreds,
                  })
                }}
                checked={searchContext.sameCreds}
              >
                Use the same credentials for all engines
              </CheckGroup>
              <LightButton
                disabled={!searchContext.sameCreds}
                onClick={() => {
                  setShowCredsModal(true)
                }}
              >
                Set Credentials
              </LightButton>
            </HorizontalFormGroup>

            <ButtonBar>
              <div style={{ display: "inline-block" }}>
                <FilterBox
                  aria-label="Search"
                  placeholder="Search"
                  onChange={(filter: string) => setEngineFilter(filter)}
                  value={engineFilter}
                />
              </div>
            </ButtonBar>
          </ListFormGroup>

          <FormGroup
            noMargin
            style={{
              position: "relative",
              flexGrow: 1,
              minHeight: "250px",
            }}
          >
            <OmniTable
              data={flattenedTree}
              rowCount={flattenedTree.length}
              rowHeight={25}
              cellRenderer={cellRenderer}
              columnDesc={columns}
              rowRenderer={rowRenderer}
              rowClassName={rowClassName}
              onRowClick={onRowClick}
              sort={onSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            />
          </FormGroup>
        </>
      )}
      {showCredsModal ? (
        <LoginModal
          username={searchContext.sameUsername}
          password={searchContext.samePassword}
          twoFactor={false}
          title="Credentials for all Engines"
          onOK={(username: string, password: string, otp: string, clientAuth: boolean) => {
            setShowCredsModal(false)
            setSearchContext({
              ...searchContext,
              sameUsername: username,
              samePassword: password,
              sameClientAuth: clientAuth,
            })
          }}
          onCancel={() => {
            setShowCredsModal(false)
          }}
        />
      ) : null}
    </>
  )
}

export default ChooseEngines
