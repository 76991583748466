import styled from "styled-components"
import { Input as ReactstrapInput } from "reactstrap"

export const Input = styled(ReactstrapInput)`
  color: ${props => props.theme.inputColor};
  background-color: ${props => props.theme.inputBackgroundColor};
  border-color: ${props => props.theme.inputBorderColor};

  &::placeholder {
    color: ${props => props.theme.inputPlaceholderColor};
  }

  &:focus {
    color: ${props => props.theme.inputFocusColor};
    background-color: ${props => props.theme.inputBackgroundColor};
    border-color: ${props => props.theme.inputFocusBorderColor};
  }

  &:disabled,
  &[readonly] {
    color: ${props => props.theme.textMutedColor};
    background-color: ${props => props.theme.inputDisabledBackgroundColor};
    border-color: ${props => props.theme.inputDisabledBorderColor};
  }

  &[type="checkbox"],
  &[type="radio"] {
    background-color: ${props => props.theme.inputCheckBackgroundColor};
    border-color: ${props => props.theme.inputCheckBorderColor};

    &:disabled {
      background-color: ${props => props.theme.inputCheckDisabledBackgroundColor};
      border-color: ${props => props.theme.inputCheckDisabledBorderColor};
      opacity: 1;
    }

    &:checked,
    &:indeterminate:not([type="radio"]) {
      background-color: ${props => props.theme.primaryButtonBackgroundColor};
      border-color: ${props => props.theme.primaryButtonBorderColor};
    }

    &:checked:disabled,
    &:indeterminate:disabled {
      opacity: 0.5;
    }
  }
`

export default Input
