import { cloneDeep } from "lodash"
import styled from "styled-components"
import { rgba } from "polished"

const AndDivider = styled.span`
  font-size: 10px;
  font-weight: bold;
  position: relative;
  width: 2.75em;
  height: 2.75em;
  line-height: 2.75em;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 50%;
  background-color: ${props => props.theme.hardwareChannelAndBackgroundColor};
  top: -0.75em;
  margin-bottom: -1.375em;
  left: 1rem;
  color: ${props => props.theme.hardwareChannelAndColor};
`

const AndGroupWrapper = styled.div`
  padding: 1rem;
  background-color: ${props => props.theme.hardwareChannelAndGroupBackgroundColor};
  border: ${props => props.theme.hardwareChannelAndGroupBorder};
  border-radius: ${props => props.theme.panelBorderRadius};
`

export const AndGroup = ({ children }: { children: any[] }) => {
  const withDividers: any = []
  for (let i = 0; i < children.length; i++) {
    withDividers.push(children[i])
    if (i + 1 !== children.length) {
      withDividers.push(<AndDivider key={`and-divider-${i}`}>AND</AndDivider>)
    }
  }
  return (
    <AndGroupWrapper>
      <GroupChildren>{withDividers}</GroupChildren>
    </AndGroupWrapper>
  )
}

const GroupChildren = styled.div`
  display: flex;
  flex-direction: column;
`

export const HardwareValueFilterContent = styled.div`
  margin-top: 4px;
  padding: 0;
  text-align: left;
  color: ${props => props.theme.textColor};
  background-color: transparent;
  border-color: transparent;
  border: none;

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.textColor};
    background-color: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.lightButtonBorderColor, 0.5)};
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.textColor};
    background-color: transparent;
    border-color: transparent;
  }
`

export const HardwareValueFilterButtons = styled.div`
  display: flex;
  justify-content: flex-end;

  & > * + * {
    margin-left: 4px;
  }

  & button {
    white-space: nowrap;
  }
`

export const HardwareValueFilterRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: ${props => props.theme.hardwareChannelNodeBorder};
  background: ${props => props.theme.panelBackground};
  border-radius: ${props => props.theme.panelBorderRadius};
  box-shadow: ${props => props.theme.panelBoxShadow};
  outline: none;
`

export const HardwareValueFilterHeader = styled.div`
  display: flex;
  align-items: center;
`

export const HardwareValueFilterTitle = styled.div`
  flex-grow: 1;
  margin-right: 4px;
  text-align: left;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  padding: 0.2rem 0 0.2rem 0;
  color: ${props => props.theme.textColor};
  background-color: transparent;
  border-color: transparent;
  border: none;

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.textColor};
    background-color: transparent;
    border-color: transparent;
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.lightButtonBorderColor, 0.5)};
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.textColor};
    background-color: transparent;
    border-color: transparent;
  }

  & > * + * {
    margin-left: 4px;
  }
`

export const EmptyValueFilterRowStyled = styled(HardwareValueFilterRow)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 71px;
`

export const HardwareValueFiltersView = styled.div`
  display: flex;
  flex-direction: column;
`

const OrDivider = styled.span`
  font-size: 10px;
  font-weight: bold;
  position: relative;
  width: 2.75em;
  height: 2.75em;
  line-height: 2.75em;
  text-align: center;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 50%;
  background-color: ${props => props.theme.hardwareChannelOrBackgroundColor};
  top: -0.75em;
  margin-bottom: -1.375em;
  left: 1rem;
  color: ${props => props.theme.hardwareChannelOrColor};
`

const OrGroupWrapper = styled.div`
  padding: 1rem;
  background-color: ${props => props.theme.hardwareChannelOrGroupBackgroundColor};
  border: ${props => props.theme.hardwareChannelOrGroupBorder};
  border-radius: ${props => props.theme.panelBorderRadius};
`

export const OrGroup = ({ children }: { children: any[] }) => {
  const withDividers: any = []
  for (let i = 0; i < children.length; i++) {
    withDividers.push(children[i])
    if (i + 1 !== children.length) {
      withDividers.push(<OrDivider key={`or-divider-${i}`}>OR</OrDivider>)
    }
  }
  return (
    <OrGroupWrapper>
      <GroupChildren>{withDividers}</GroupChildren>
    </OrGroupWrapper>
  )
}

export const RadioInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`

const DumpText = styled.pre`
  color: ${props => props.theme.textColor};
`

export const DumpValueFilter = ({ valueFilter }: { valueFilter: any }) => {
  const dumpValueFilter = cloneDeep(valueFilter)
  delete dumpValueFilter.andNode
  delete dumpValueFilter.orNode
  return <DumpText>{JSON.stringify(dumpValueFilter, undefined, "  ")}</DumpText>
}
