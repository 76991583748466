import * as React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import { getBreadcrumbs } from "../../store"
import { BreadcrumbInterface } from "../../api/types/componentTypes"

const BreadcrumbDiv = styled.nav`
  display: flex;
  margin-bottom: ${props => props.theme.navMarginBottom};
  padding: ${props => props.theme.navPadding};
  color: ${props => props.theme.breadcrumbColor};
  background-color: ${props => props.theme.breadcrumbBackgroundColor};
  border-radius: ${props => props.theme.navBorderRadius};
  box-shadow: ${props => props.theme.navBoxShadow};
`

const BreadcrumbStrip = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`

const BreadcrumbItem = styled.li`
  list-style: none;
  padding: 0 4px;

  & + &:before {
    display: inline-block;
    padding-right: 0.5rem;
    color: ${props => props.theme.breadcrumbSeparatorColor};
    content: "/";
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    font-weight: 500;
  }
`

const Breadcrumb = styled(NavLink)`
  color: ${props => props.theme.breadcrumbColor};

  &:hover {
    color: ${props => props.theme.breadcrumbColor};
  }
`

type BreadcrumbNavProps = {
  breadcrumbs: Array<BreadcrumbInterface>
}

class BreadcrumbNav extends React.Component<BreadcrumbNavProps> {
  render() {
    if (this.props.breadcrumbs.length === 0) return null
    return (
      <BreadcrumbDiv aria-label="breadcrumb">
        <BreadcrumbStrip>
          {this.props.breadcrumbs.map((breadcrumb, i) => (
            <BreadcrumbItem key={i}>
              <Breadcrumb to={breadcrumb.to}>{breadcrumb.title}</Breadcrumb>
            </BreadcrumbItem>
          ))}
        </BreadcrumbStrip>
      </BreadcrumbDiv>
    )
  }
}

const mapStateToProps = (state: object) => ({
  breadcrumbs: getBreadcrumbs(state),
})

export default connect(mapStateToProps)(BreadcrumbNav)
