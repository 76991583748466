import { ExpertTimeUnit } from "../api/types/expertTypes"

export const numberFormatDefault = Intl.NumberFormat()

export const numberFormatDecimal = Intl.NumberFormat(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const numberFormatTwoDigits = Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const numberFormatThreeDigits = Intl.NumberFormat(undefined, {
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
})

export const numberFormatSixDigits = Intl.NumberFormat(undefined, {
  minimumFractionDigits: 6,
  maximumFractionDigits: 6,
})

export const numberFormatNineDigits = Intl.NumberFormat(undefined, {
  minimumFractionDigits: 9,
  maximumFractionDigits: 9,
})

export function numberFormatFromDigits(digits: number) {
  if (digits < 0) {
    return numberFormatDecimal
  }

  switch (digits) {
    case 0:
      return numberFormatDecimal
    case 2:
      return numberFormatTwoDigits
    case 3:
      return numberFormatThreeDigits
    case 6:
      return numberFormatSixDigits
    case 9:
      return numberFormatNineDigits
    default:
      break
  }

  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  })
}

export function zeroPad(num: string) {
  if (num.length === 0) {
    return "00"
  } else {
    const s = "0" + num
    return s.substr(s.length - 2)
  }
}

export function formatInteger(n: number) {
  if (n === undefined) return ""
  return numberFormatDecimal.format(n)
}

export function formatFloat(n: number, digits: number = 3) {
  if (n === undefined) return ""
  const numberFormat = numberFormatFromDigits(digits)
  return numberFormat.format(n)
}

export function formatColorForTheme(color: string, themeName: string) {
  if (color && color.length && themeName === "Light") {
    return color
  } else {
    return undefined
  }
}

export function formatTimeZone(tzBias: number) {
  let tzb = Math.floor(tzBias)
  const pre = tzb < 0 ? "+" : "-"
  tzb = Math.abs(tzb)
  const hrs = Math.floor(tzb / 60)
  const min = tzb % 60
  const h = zeroPad(hrs.toString())
  const m = zeroPad(min.toString())
  return `GMT${pre}${h}:${m}`
}

export function formatDuration(duration: number, digits: number = 0) {
  if (duration === undefined) return ""
  const isNegative = duration < 0
  duration = Math.abs(duration)
  let t = Math.floor(duration / 1000000000)
  const f = (duration - t * 1000000000) / 1000000000
  const days = Math.floor(t / 86400)
  t = Math.floor(t % 86400)
  const hours = Math.floor(t / 3600)
  t = Math.floor(t % 3600)
  const minutes = Math.floor(t / 60)
  const seconds = Math.floor(t % 60) + (digits !== 0 ? f : 0)

  let str = isNegative ? "-" : ""
  if (days > 0) {
    let strSeconds = seconds < 10 ? "0" : ""
    strSeconds += formatFloat(seconds, digits)
    str += `${days}d ${hours}:${zeroPad(minutes.toString())}:${strSeconds}`
  } else {
    if (digits === 0 || hours > 0 || minutes > 0) {
      let strSeconds = seconds < 10 ? "0" : ""
      strSeconds += formatFloat(seconds, digits)
      str += `${hours}:${zeroPad(minutes.toString())}:${strSeconds}`
    } else {
      str += formatFloat(seconds, digits)
    }
  }

  return str
}

export function formatDurationRange(
  beg: number | undefined,
  end: number | undefined,
  digits: number = 0
) {
  if (beg === undefined || end === undefined) return ""
  const d = end - beg
  return formatDuration(d, digits)
}

export function utcToLocal(epoch: Date | string | number | undefined) {
  if (!epoch) {
    return new Date(0)
  }
  const d = new Date(epoch)
  const diffM = d.getTimezoneOffset()
  return new Date(d.valueOf() - diffM * 60 * 1000)
}

export function utcToLocalCheck(epoch: Date | string | number | undefined, showLocalTime: boolean) {
  if (!epoch) {
    return new Date(0)
  }
  if (showLocalTime) {
    return new Date(epoch)
  }
  return utcToLocal(epoch)
}

export function localToUTC(epoch: Date | string | number | undefined) {
  if (!epoch) {
    return new Date(0)
  }
  const d = new Date(epoch)
  const diffM = d.getTimezoneOffset()
  return new Date(d.valueOf() + diffM * 60 * 1000)
}

export function localToUTCCheck(epoch: Date | string | number | undefined, showLocalTime: boolean) {
  if (!epoch) {
    return new Date(0)
  }
  if (showLocalTime) {
    return new Date(epoch)
  }
  return localToUTC(epoch)
}

export function peekToDate(ts: number) {
  ts = ts * 1
  if (ts <= 0) return 0
  const t = ts / 1000000
  if (t < 11644473600000) return 0
  return t - 11644473600000
}

export function peekFromDate(ts: number) {
  ts = ts * 1
  if (ts <= 0) return 0
  const t = ts + 11644473600000
  return t * 1000000
}

export const dateFormat = Intl.DateTimeFormat(undefined, {
  hourCycle: "h23",
  year: "numeric",
  month: "numeric",
  day: "2-digit",
})

export const dateFormatUTC = Intl.DateTimeFormat(undefined, {
  hourCycle: "h23",
  year: "numeric",
  month: "numeric",
  day: "2-digit",
  timeZone: "UTC",
})

export const timeFormat = Intl.DateTimeFormat(undefined, {
  hourCycle: "h23",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
})

export const timeFormatUTC = Intl.DateTimeFormat(undefined, {
  hourCycle: "h23",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  timeZone: "UTC",
})

export function formatDate(ts: number | undefined) {
  if (ts === undefined || ts < 0) return ""
  ts = ts * 1
  if (ts === 0) return ""
  const d = new Date(peekToDate(ts))
  return dateFormat.format(d)
}

export function formatTime(ts: number | undefined, digits: number = 0) {
  if (ts === undefined || ts < 0) return ""
  ts = ts * 1
  if (ts === 0) return ""
  const d = new Date(peekToDate(ts))
  let time = timeFormat.format(d)
  if (digits > 0) {
    time += ((ts % 1000000000) / 1000000000).toFixed(digits).substring(1)
  }
  return time
}

export function formatDateTime(ts: number | undefined, digits: number = 0) {
  if (ts === undefined || ts < 0) return ""
  ts = ts * 1
  if (ts === 0) return ""
  const d = new Date(peekToDate(ts))
  const date = dateFormat.format(d)
  let time = timeFormat.format(d)
  if (digits > 0) {
    time += ((ts % 1000000000) / 1000000000).toFixed(digits).substring(1)
  }
  return `${date} ${time}`
}

export function formatISODate(iso: string | undefined, localTimezone: boolean = true) {
  if (iso === undefined) return ""
  try {
    const t = Date.parse(iso)
    const d = new Date(t)
    const date = localTimezone ? dateFormat.format(d) : dateFormatUTC.format(d)
    return date
  } catch {
    return ""
  }
}

function formatISOTimeFractionalSeconds(iso: string, digits: number) {
  let fraction = ""
  if (digits > 0) {
    let f = 0
    const match = iso.match(/\.(?<secfrac>\d+)/)
    const secfrac = match?.groups?.secfrac
    if (secfrac && secfrac.length > 0) {
      f = parseInt(secfrac, 10) / Math.pow(10, secfrac.length)
      if (Number.isNaN(f)) {
        f = 0
      }
    }
    fraction = f.toFixed(digits).substring(1)
  }
  return fraction
}

export function formatISOTime(
  iso: string | undefined,
  digits: number = 0,
  localTimezone: boolean = true
) {
  if (iso === undefined) return ""
  try {
    const t = Date.parse(iso)
    const d = new Date(t)
    const time = localTimezone ? timeFormat.format(d) : timeFormatUTC.format(d)
    const fraction = formatISOTimeFractionalSeconds(iso, digits)
    return time + fraction + (localTimezone ? "" : "Z")
  } catch {
    return ""
  }
}

export function formatISODateTime(
  iso: string | undefined,
  digits: number = 0,
  localTimezone: boolean = true
) {
  if (iso === undefined) return ""
  try {
    const t = Date.parse(iso)
    const d = new Date(t)
    const date = localTimezone ? dateFormat.format(d) : dateFormatUTC.format(d)
    const time = localTimezone ? timeFormat.format(d) : timeFormatUTC.format(d)
    const fraction = formatISOTimeFractionalSeconds(iso, digits)
    return localTimezone ? `${date} ${time}${fraction}` : `${date} ${time}${fraction}Z`
  } catch {
    return ""
  }
}

export function formatLinkSpeed(speed: number | undefined) {
  if (speed === undefined) return ""
  speed = speed * 1
  if (speed === 0) return ""
  const mbits = speed / 1000000
  if (mbits !== Math.floor(mbits)) {
    return `${numberFormatThreeDigits.format(mbits)} Mbits/s`
  }
  return `${formatInteger(mbits)} Mbits/s`
}

export function formatKB(bytes: number | undefined, digits: number = 0) {
  if (bytes === undefined || bytes < 0) return ""
  const kb = bytes / 1024
  const numberFormat = numberFormatFromDigits(digits)
  return `${numberFormat.format(kb)} KB`
}

export function formatMB(bytes: number | undefined, digits: number = 0) {
  if (bytes === undefined || bytes < 0) return ""
  const mb = bytes / 1048576
  const numberFormat = numberFormatFromDigits(digits)
  return `${numberFormat.format(mb)} MB`
}

export function formatGB(bytes: number | undefined, digits: number = 3) {
  if (bytes === undefined || bytes < 0) return ""
  const gb = bytes / 1073741824
  const numberFormat = numberFormatFromDigits(digits)
  return `${numberFormat.format(gb)} GB`
}

export function formatJitter(jitter: number | undefined, digits: number = 3) {
  if (jitter === undefined || jitter < 0) return ""
  const numberFormat = numberFormatFromDigits(digits)
  return `${numberFormat.format(jitter)}`
}

export function formatMOS(mos: number | undefined, digits: number = 2) {
  if (mos === undefined || mos < 0) return ""
  const numberFormat = numberFormatFromDigits(digits)
  return `${numberFormat.format(mos)}`
}

export function formatPacketLoss(packetLoss: number | undefined, digits: number = 3) {
  if (packetLoss === undefined || packetLoss < 0) return ""
  const numberFormat = numberFormatFromDigits(digits)
  return `${numberFormat.format(packetLoss)}`
}

export function formatSampleInterval(seconds: number) {
  if (seconds < 0) return ""
  let unit = ""
  let unitsPerSecond = 1
  if (seconds < 60) {
    unit = "Second"
    unitsPerSecond = 1
  } else if (seconds < 3600) {
    unit = "Minute"
    unitsPerSecond = 60
  } else if (seconds < 86400) {
    unit = "Hour"
    unitsPerSecond = 3600
  } else {
    unit = "Day"
    unitsPerSecond = 86400
  }

  let sampleInterval = ""
  if (seconds % unitsPerSecond === 0) {
    const interval = Math.floor(seconds / unitsPerSecond)
    sampleInterval = formatInteger(interval)
  } else {
    // Fractional sample interval not recomended
    const interval = seconds / unitsPerSecond
    sampleInterval = formatFloat(interval, 3)
  }

  return `${sampleInterval} ${unit}`
}

export const INVALID_TIME = 0xffffffffffffffff

// number of WPTime counts in a single second.
export const WPTIME_SECONDS = 1000000000

export function timeUnitToWPTime(timeUnit: ExpertTimeUnit) {
  let wpTime = WPTIME_SECONDS
  switch (timeUnit) {
    case ExpertTimeUnit.EXPERT_TIME_UNIT_NANOSECONDS:
      wpTime /= 1000000000
      break
    case ExpertTimeUnit.EXPERT_TIME_UNIT_MICROSECONDS:
      wpTime /= 1000000
      break
    case ExpertTimeUnit.EXPERT_TIME_UNIT_MILLISECONDS:
      wpTime /= 1000
      break
    case ExpertTimeUnit.EXPERT_TIME_UNIT_SECONDS:
      break
    case ExpertTimeUnit.EXPERT_TIME_UNIT_MINUTES:
      wpTime *= 60
      break
    case ExpertTimeUnit.EXPERT_TIME_UNIT_HOURS:
      wpTime *= 3600
      break
    case ExpertTimeUnit.EXPERT_TIME_UNIT_DAYS:
      wpTime *= 86400
      break
    default:
      break
  }
  return wpTime
}

export function timeUnitToString(timeUnit: ExpertTimeUnit) {
  let str = ""
  switch (timeUnit) {
    case ExpertTimeUnit.EXPERT_TIME_UNIT_NANOSECONDS:
      str = "nanoseconds"
      break
    case ExpertTimeUnit.EXPERT_TIME_UNIT_MICROSECONDS:
      str = "microseconds"
      break
    case ExpertTimeUnit.EXPERT_TIME_UNIT_MILLISECONDS:
      str = "milliseconds"
      break
    case ExpertTimeUnit.EXPERT_TIME_UNIT_SECONDS:
      str = "seconds"
      break
    case ExpertTimeUnit.EXPERT_TIME_UNIT_MINUTES:
      str = "minutes"
      break
    case ExpertTimeUnit.EXPERT_TIME_UNIT_HOURS:
      str = "hours"
      break
    case ExpertTimeUnit.EXPERT_TIME_UNIT_DAYS:
      str = "days"
      break
    default:
      break
  }
  return str
}

export function formatForensicSearchResult(result: number) {
  result = result >>> 0
  let text = ""
  if (result !== 0) {
    if (result === 1223 || result === 0x800704c7) {
      text = "Canceled"
    } else if (result === 0x80000002) {
      text = "Not enough memory to open the forensic search"
    } else if (result === 0x80040200 || result === 0x80040211) {
      text = "No results were found"
    } else if (result === 0x80040201) {
      text = "The limit of active forensic searches has been reached"
    } else if (result === 0x80040202) {
      text = "Selected search location is being written to and can not be used"
    } else if (result === 0x80070070) {
      text = "Disk full"
    } else {
      text = `Error: ${result.toString(16)}`
    }
  }
  return text
}
