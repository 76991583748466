import * as React from "react"
import styled from "styled-components"

const TextWithIconStyle = styled.div`
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 0.25rem;
    flex-shrink: 0;
  }

  & > :last-child {
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

type TextWithIconProps = {
  icon: React.ReactNode
  text?: string
  textStyle?: React.CSSProperties
  style?: React.CSSProperties
}

export const TextWithIcon: React.FC<TextWithIconProps> = ({ icon, text, textStyle, style }) => {
  return (
    <TextWithIconStyle style={style}>
      {icon}
      <span title={text} style={textStyle}>
        {text}
      </span>
    </TextWithIconStyle>
  )
}

export const TextWithIconList = styled.div`
  display: flex;
  flex-direction: row;

  & > * + * {
    margin-left: 0.5rem;
  }

  & > * {
    min-width: 0;
  }
`
