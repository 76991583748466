import * as React from "react"
import { withTheme, DefaultTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import {
  AutoSizer,
  Column,
  ColumnProps,
  TableProps,
  TableHeaderRowProps,
  TableCellProps,
  TableCellRenderer,
} from "react-virtualized"
import { VirtualTable } from "../VirtualTable"
import { IconDropdownToggle, IconButton } from "../Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../Dropdown"
import { formatExpertValue } from "../../../utils/expertUtils"
import { ExpertQueryResponse, ExpertValue } from "../../../api/types"
import { ExpertColumn, ExpertTreeState } from "../../../api/types/expertTypes"

export function isExpandable(treeState: number) {
  return (treeState & ExpertTreeState.EXPERT_TREE_STATE_EXPANDABLE) !== 0
}

export function isExpanded(treeState: number) {
  return (treeState & ExpertTreeState.EXPERT_TREE_STATE_EXPANDED) !== 0
}

// See: CDataViewHelper::CalcIndentCount(
export function calcIndent(treeState: number) {
  const shift = ExpertTreeState.EXPERT_TREE_STATE_BIT_COUNT
  let indent = 0
  for (let s = treeState; 0 !== s; s = s >> shift) {
    indent++
  }
  return indent === 0 ? 0 : indent - 1
}

export type ExpertTableColumn = ColumnProps & {
  alignRight: boolean
  listLabel?: string
  visible: boolean
}

type Without<T, K> = Pick<T, Exclude<keyof T, K>>
type TablePropsNoHeight = Without<TableProps, "height">
type ExpertTableProps = TablePropsNoHeight & {
  resultSet: ExpertQueryResponse
  renderCommands: TableCellRenderer
  columnDesc: ExpertTableColumn[]
  onShowDefaultColumns?: () => void
  onShowAllColumns?: () => void
  onToggleColumn?: (event: any) => void
  onExpandCollapse?: (rowData: ExpertValue[]) => void
  theme: DefaultTheme
  showLocalTime: boolean
}

class ExpertTable extends React.Component<ExpertTableProps> {
  rowGetter = ({ index }: { index: number }) => {
    const { resultSet } = this.props
    if (!resultSet || !resultSet.results) return {}
    const results = resultSet.results[0]
    if (!results.rowList) return {}
    return results.rowList[index]
  }

  headerRowRenderer = ({ className, columns, style }: TableHeaderRowProps) => {
    // Adjust the right padding to take into account borders on the table
    // so header columns line up with cells properly.
    if (typeof style.paddingRight === "number" && style.paddingRight >= 2) {
      style.paddingRight = +style.paddingRight - 2
    }
    // Just do what defaultHeaderRowRenderer does.
    return (
      <div className={className} role="row" style={style}>
        {columns}
      </div>
    )
  }

  cellDataGetter = () => {
    return undefined
  }

  cellRenderer = ({ dataKey, rowData }: TableCellProps) => {
    const { resultSet } = this.props
    if (!resultSet || !resultSet.results || rowData == null) return null
    const columnId = parseInt(dataKey, 10) as ExpertColumn
    const results = resultSet.results[0]
    const columnIndex = results.columnList.indexOf(columnId)
    if (columnIndex === -1) return null
    const value = rowData[columnIndex]
    if (value != null) {
      let content: React.ReactNode
      const formatted = formatExpertValue(
        columnId,
        value.value,
        value.rendered,
        rowData,
        results.columnList,
        this.props.showLocalTime
      )
      if (
        formatted != null &&
        this.props.theme.name === "Light" &&
        value.color != null &&
        value.color !== "#000000"
      ) {
        content = (
          <span title={String(formatted)} style={{ color: value.color }}>
            {formatted}
          </span>
        )
      } else {
        content = formatted
      }

      if (
        this.props.onExpandCollapse &&
        (columnId === ExpertColumn.EXPERT_COLUMN_NAME ||
          columnId === ExpertColumn.EXPERT_COLUMN_NODE_1)
      ) {
        const treeStateColumnIndex = results.columnList.indexOf(
          ExpertColumn.EXPERT_COLUMN_TREE_STATE
        )
        if (treeStateColumnIndex !== -1) {
          const treeState = rowData[treeStateColumnIndex].value
          if (treeState != null && typeof treeState === "number") {
            const indent = calcIndent(treeState)
            const title = typeof content === "object" ? undefined : (content as string)
            if (isExpandable(treeState)) {
              const expanded = isExpanded(treeState)
              const icon = expanded ? "chevron-down" : "chevron-right"
              content = (
                <div style={{ paddingLeft: `${indent * 1.257}em`, display: "flex" }}>
                  <IconButton
                    aria-label="Expand/Collapse"
                    onClick={event => {
                      event.stopPropagation()
                      this.onExpandCollapse(rowData)
                    }}
                  >
                    <FontAwesome name={icon} fixedWidth />
                  </IconButton>
                  <span
                    style={{
                      paddingLeft: ".25em",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={title}
                  >
                    {content}
                  </span>
                </div>
              )
            } else {
              content = (
                <div style={{ paddingLeft: `${indent * 1.257}em`, display: "flex" }}>
                  <span
                    style={{
                      paddingLeft: "1.536em",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                    title={title}
                  >
                    {content}
                  </span>
                </div>
              )
            }
          }
        }
      }

      return content
    }
    return null
  }

  commandHeaderRenderer = () => {
    const { columnDesc, onToggleColumn, onShowDefaultColumns, onShowAllColumns } = this.props
    if (columnDesc && onToggleColumn) {
      const items: any[] = []

      if (onShowDefaultColumns) {
        items.push(
          <DropdownItem key="defaultColumns" onClick={onShowDefaultColumns}>
            <FontAwesome name="blank" fixedWidth /> Default Columns
          </DropdownItem>
        )
      }

      if (onShowAllColumns) {
        items.push(
          <DropdownItem key="allColumns" onClick={onShowAllColumns}>
            <FontAwesome name="blank" fixedWidth /> All Columns
          </DropdownItem>
        )
      }

      if (items.length) {
        items.push(<DropdownItem key="divider" divider />)
      }

      columnDesc.forEach(col => {
        let label = col.label
        if (col.listLabel !== undefined) {
          label = col.listLabel
        }
        if (label === undefined || (typeof label === "string" && label.length === 0)) {
          // No label.
        } else {
          items.push(
            <DropdownItem key={col.dataKey} name={col.dataKey} onClick={onToggleColumn}>
              <FontAwesome name={col.visible ? "check" : "blank"} fixedWidth /> {label}
            </DropdownItem>
          )
        }
      })

      return (
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle aria-label="Toggle Columns">
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>{items}</DropdownMenu>
        </UncontrolledDropdownWithPortal>
      )
    }

    return null
  }

  rowClassName = ({ index }: { index: number }) => {
    if (index >= 0 && index % 2 !== 0) {
      return "stripe"
    }
    return ""
  }

  onExpandCollapse(rowData: ExpertValue[]) {
    if (this.props.onExpandCollapse) {
      this.props.onExpandCollapse(rowData)
    }
  }

  render() {
    const {
      resultSet,
      headerHeight = 30,
      rowHeight = 26,
      columnDesc,
      rowGetter = this.rowGetter,
      headerRowRenderer = this.headerRowRenderer,
      cellDataGetter = this.cellDataGetter,
      cellRenderer = this.cellRenderer,
      renderCommands,
      ...tableProps
    } = this.props
    if (!resultSet || !resultSet.results) return null
    const results = resultSet.results[0]
    const columns: any[] = []
    columnDesc.forEach(col => {
      const { visible = true } = col
      if (visible) {
        const { dataKey, alignRight = false, visible: unused, ...colProps } = col
        const className = alignRight ? "right" : undefined
        columns.push(
          <Column
            key={dataKey}
            dataKey={dataKey}
            className={className}
            headerClassName={className}
            cellDataGetter={cellDataGetter}
            cellRenderer={cellRenderer}
            {...colProps}
          />
        )
      }
    })
    if (renderCommands) {
      columns.push(
        <Column
          key="commands"
          dataKey="commands"
          width={28}
          flexShrink={0}
          cellRenderer={renderCommands}
          headerRenderer={this.commandHeaderRenderer}
          disableSort
        />
      )
    }
    return (
      <AutoSizer>
        {({ height, width }) => (
          <VirtualTable
            width={width}
            height={height}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            rowCount={results.rowList ? results.rowList.length : 0}
            rowGetter={rowGetter}
            headerRowRenderer={headerRowRenderer}
            rowClassName={this.rowClassName}
            {...tableProps}
          >
            {columns}
          </VirtualTable>
        )}
      </AutoSizer>
    )
  }
}

const ExpertTableWithTheme = withTheme(ExpertTable)
export { ExpertTableWithTheme as ExpertTable }
