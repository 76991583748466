// See FileSaver.js
export function click(node: any) {
  try {
    node.dispatchEvent(new MouseEvent("click"))
  } catch {
    const event = document.createEvent("MouseEvents")
    event.initMouseEvent(
      "click",
      true,
      true,
      window,
      0,
      0,
      0,
      10,
      10,
      false,
      false,
      false,
      false,
      0,
      null
    )
    node.dispatchEvent(event)
  }
}

export function download(url: string, fileName: string) {
  const a = document.createElement("a")
  a.download = fileName
  a.href = url
  click(a)
}
