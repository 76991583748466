import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom"
import { useSelector } from "react-redux"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import AdminNav from "../AdminNav"
import AuditLogView from "../AuditLogView"
import ConnectedUsersView from "../ConnectedUsersView"
import SupportView from "../SupportView"
import DiagnosticsView from "../DiagnosticsView"
import { getCapabilities } from "../../store"
import {
  ENGINE_AUDIT_LOG_PATH,
  ENGINE_CONNECTED_USERS_PATH,
  ENGINE_SUPPORT_PATH,
  ENGINE_DIAGNOSTICS_PATH,
} from "../../routes"
import { getDefaultAdminUrl, hasAdminViews } from "../../utils/engineUtils"

const Admin = () => {
  const match = useRouteMatch()
  const engineCapabilities = useSelector(getCapabilities)
  const defaultUrl = getDefaultAdminUrl(engineCapabilities)

  if (!hasAdminViews(engineCapabilities)) {
    return <Redirect to={`${defaultUrl}`} />
  }

  return (
    <>
      <BreadcrumbItem to={match.url} title="Admin" />
      <AdminNav />
      <Switch>
        <Redirect exact path={match.path} to={defaultUrl} />
        <Route exact path={ENGINE_AUDIT_LOG_PATH} component={AuditLogView} />
        <Route exact path={ENGINE_CONNECTED_USERS_PATH} component={ConnectedUsersView} />
        <Route exact path={ENGINE_SUPPORT_PATH} component={SupportView} />
        <Route exact path={ENGINE_DIAGNOSTICS_PATH} component={DiagnosticsView} />
      </Switch>
    </>
  )
}

export default Admin
