import { EngineCapabilitiesPluginInfo } from "./model/engineCapabilitiesPluginInfo"

export interface NewActivation {
  product: string
  version: string
  serialNumPkId: string
  lockingCode: string
  customerName: string
  email: string
  company: string
  method: string
}

export enum ActivationType {
  ACTIVATION_TYPE_NONE = -1,

  ACTIVATION_TYPE_CAPTURE_ETHERNET = 0,
  ACTIVATION_TYPE_CAPTURE_WIRELESS = 1,
  ACTIVATION_TYPE_CAPTURE_GIG_HARDWARE = 2,
  ACTIVATION_TYPE_CAPTURE_WAN = 3,
  ACTIVATION_TYPE_FEATURE_EXPERT = 4,
  ACTIVATION_TYPE_FEATURE_PEER_MAP = 5,
  ACTIVATION_TYPE_FEATURE_VOICE = 6,
  ACTIVATION_TYPE_FEATURE_LOCAL_CAPTURE = 7,
  ACTIVATION_TYPE_FEATURE_REMOTE_CAPTURE = 8,
  ACTIVATION_TYPE_FEATURE_GRAPHS = 9,
  ACTIVATION_TYPE_CAPTURE_LOCAL_WIRELESS = 10,
  ACTIVATION_TYPE_FEATURE_ENGINE_STATS = 11,
  ACTIVATION_TYPE_FEATURE_TELCHEMY_VOIP = 12,
  ACTIVATION_TYPE_FEATURE_APDEX = 13,
  ACTIVATION_TYPE_FEATURE_NO_EXPERT_LIMIT = 14,
  ACTIVATION_TYPE_FEATURE_NO_ADAPTER_LIMIT = 15,
  ACTIVATION_TYPE_FEATURE_NO_REMOTE_CONSOLE_LIMIT = 16,

  ACTIVATION_TYPE_FEATURE_DASHBOARD = 17, // This and below new for 5.0
  ACTIVATION_TYPE_FEATURE_WEB_VIEWS = 18,
  ACTIVATION_TYPE_FEATURE_RADCOM_VOIP = 19,
  ACTIVATION_TYPE_FEATURE_FORENSIC_SEARCH = 20,
  ACTIVATION_TYPE_FEATURE_PROMISCUOUS_CAPTURE = 21,

  ACTIVATION_TYPE_FEATURE_CALL_PLAYBACK = 22, // New for Omni 6.0
  ACTIVATION_TYPE_FEATURE_ORA = 23, // New for Omni 6.5
  ACTIVATION_TYPE_FEATURE_ORA2 = 24, // New for Omni 7.0
  ACTIVATION_TYPE_FEATURE_ORA3 = 25, // New for Omni 7.0
  ACTIVATION_TYPE_FEATURE_ORA4 = 26, // New for Omni 7.0

  ACTIVATION_TYPE_FEATURE_MSA = 27, // Added in Omni 12
  ACTIVATION_TYPE_FEATURE_FILES_VIEW = 28, // Added in Omni 12
  ACTIVATION_TYPE_FEATURE_IMPORT_EVENTS = 29, // Added in Omni 12

  ACTIVATION_TYPE_FEATURE_LIVEFLOW = 30, // Added in Omni 20.1
  ACTIVATION_TYPE_FEATURE_LIVEFLOW_LIMIT1 = 31, // Added in Omni 20.1
  ACTIVATION_TYPE_FEATURE_LIVEFLOW_LIMIT2 = 32, // Added in Omni 20.1
  ACTIVATION_TYPE_FEATURE_LIVEFLOW_LIMIT3 = 33, // Added in Omni 20.1

  ACTIVATION_TYPE_FEATURE_THREATEYE_NV = 34, // Added in Omni 22.3

  ACTIVATION_TYPE_FEATURE_NO_CTD = 35, // Added in Omni 22.4
}

export function hasActivationCapability(activationCapabilities: string, type: ActivationType) {
  return (
    import.meta.env.MODE !== "production" ||
    (type > ActivationType.ACTIVATION_TYPE_NONE &&
      activationCapabilities.length > type &&
      activationCapabilities[type] === "1")
  )
}

export function hasPluginCapability(
  pluginsInfo: EngineCapabilitiesPluginInfo[],
  pluginClsid: string
) {
  return (
    import.meta.env.MODE !== "production" ||
    pluginsInfo.find(
      (pluginInfo: EngineCapabilitiesPluginInfo) => pluginInfo.clsid === pluginClsid
    ) !== undefined
  )
}
