import styled from "styled-components"
import { List, ListProps } from "react-virtualized"

export const VirtualList = styled(List)<ListProps>`
  background-color: ${props => props.theme.tableBackgroundColor};
  border: ${props => props.theme.tableBorder};

  &:focus {
    outline: none;
  }
`
