import { Tooltip as ReactstrapTooltip, TooltipProps } from "reactstrap"
import styled from "styled-components"

const Tooltip = styled(ReactstrapTooltip)<TooltipProps>`
  & .tooltip-inner {
    color: ${props => props.theme.tooltipColor};
    background-color: ${props => props.theme.tooltipBackgroundColor};
  }
`

export default Tooltip
