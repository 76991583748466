import { Batch, BatchItem } from "@rpldy/uploady"

export const UPLOAD_PACKET_ACCEPT =
  ".pkt,.npkt,.wpz,.apc,.wpc,.pcap,.wcap,.pcap.gz,.cap,.dmp,.appcap,.appcapz,.appcapture,.pcapng,.pcapng.gz,.ntar,.ntar.gz"

export enum UploadType {
  UPLOAD_TYPE_NONE = -1,

  UPLOAD_TYPE_UPLOAD_PACKETS = 0,
  UPLOAD_TYPE_OPEN_FILE = 1,
  UPLOAD_TYPE_DRAG_DROP = 2,
}

export function validPacketFileExtensions(batch: Batch): boolean {
  // make sure the file extension is one we support
  const fileExts = UPLOAD_PACKET_ACCEPT.split(",")
  return batch.items.every((item: BatchItem) => {
    for (const ext of fileExts) {
      if (item.file.name.endsWith(ext)) {
        return true
      }
    }
    return false
  })
}
