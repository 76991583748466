import { Engine, EngineSettingsAclRole, ResponseGetEngineCapabilities } from "../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../api/types/engineTypes"
import { PeekResult } from "../api/types/peekTypes"
import {
  getEngineAuditLogUrl,
  getEngineConnectedUsersUrl,
  getEngineHomeUrl,
  getEngineSupportUrl,
} from "../routes"

export const defaultEngineName = "Capture Engine"

export const ROLE_DEFAULT_ADMIN = "DF8E202A-E100-4485-B358-E64835634946"
export const ROLE_DEFAULT_MONITOR = "7D461A94-EE22-4878-B902-B0027E784CE1"
export const ROLE_DEFAULT_OPERATOR = "7045771B-9EB1-4A9D-B6FC-141EB2DEA85E"

export function getEngineDisplayName(engine: Engine | null | undefined) {
  if (!engine) return defaultEngineName
  return engine.name || engine.remoteName || defaultEngineName
}

export const GROUP_AUTHENTICATION_FLAG = "*" // In password field

export function isUsingGroupAuthentication(engine: Engine) {
  return engine.password === GROUP_AUTHENTICATION_FLAG
}

export function getDefaultAdminUrl(
  engineCapabilities: ResponseGetEngineCapabilities | null
): string {
  let url: string = getEngineHomeUrl()

  const isConfigureEngineAllowed =
    engineCapabilities &&
    engineCapabilities.userRights.policies.includes(EngineUserPolicies.configureEngine)
  const isViewAuditLogAllowed =
    engineCapabilities &&
    engineCapabilities.userRights.policies.includes(EngineUserPolicies.viewAuditLog)

  if (
    isViewAuditLogAllowed &&
    engineCapabilities?.capabilities.includes(EngineCapabilities.auditLog)
  ) {
    url = getEngineAuditLogUrl()
  } else if (
    isConfigureEngineAllowed &&
    engineCapabilities?.capabilities.includes(EngineCapabilities.listConnectedUsers)
  ) {
    url = getEngineConnectedUsersUrl()
  } else if (isConfigureEngineAllowed) {
    url = getEngineSupportUrl()
  } /* else if (
    isConfigureEngineAllowed &&
    engineCapabilities?.capabilities.includes(EngineCapabilities.diagnosticsInfo)
  ) {
    url = getEngineDiagnosticsUrl()
  }*/

  return url
}

export function getDefaultRoles(
  engineCapabilities: ResponseGetEngineCapabilities | null
): EngineSettingsAclRole[] {
  const hasSaveFilesACL =
    engineCapabilities && engineCapabilities.capabilities.includes(EngineCapabilities.saveFilesACL)
  const defaultRoles = [
    {
      description: "Has full access",
      groups: [],
      id: ROLE_DEFAULT_ADMIN,
      name: "Administrator",
      policies: [
        EngineUserPolicies.createCapture,
        EngineUserPolicies.deleteCaptures,
        EngineUserPolicies.deleteFiles,
        EngineUserPolicies.modifyCaptures,
        EngineUserPolicies.startStopCaptures,
        EngineUserPolicies.viewCaptures,
        EngineUserPolicies.viewPackets,
        EngineUserPolicies.viewStats,
        EngineUserPolicies.configureEngine,
        EngineUserPolicies.downloadFiles,
        EngineUserPolicies.saveFiles,
        EngineUserPolicies.uploadFiles,
        EngineUserPolicies.viewAuditLog,
        EngineUserPolicies.createForensicSearch,
        EngineUserPolicies.deleteForensicSearches,
        EngineUserPolicies.viewForensicSearches,
        EngineUserPolicies.useAnyCommand,
        EngineUserPolicies.configureAPITokens,
      ],
      sessions: 0,
      users: [],
    },
    {
      description: "Cannot configure the engine, but otherwise has full access",
      groups: [],
      id: ROLE_DEFAULT_MONITOR,
      name: "Monitor",
      policies: [
        EngineUserPolicies.createCapture,
        EngineUserPolicies.deleteCaptures,
        EngineUserPolicies.deleteFiles,
        EngineUserPolicies.modifyCaptures,
        EngineUserPolicies.startStopCaptures,
        EngineUserPolicies.viewCaptures,
        EngineUserPolicies.viewPackets,
        EngineUserPolicies.viewStats,
        EngineUserPolicies.downloadFiles,
        EngineUserPolicies.saveFiles,
        EngineUserPolicies.uploadFiles,
        EngineUserPolicies.viewAuditLog,
        EngineUserPolicies.createForensicSearch,
        EngineUserPolicies.deleteForensicSearches,
        EngineUserPolicies.viewForensicSearches,
        EngineUserPolicies.useAnyCommand,
      ],
      sessions: 0,
      users: [],
    },
    {
      description: "Has limited access to view data",
      groups: [],
      id: ROLE_DEFAULT_OPERATOR,
      name: "Operator",
      policies: [
        EngineUserPolicies.viewPackets,
        EngineUserPolicies.viewStats,
        hasSaveFilesACL ? EngineUserPolicies.saveFiles : EngineUserPolicies.downloadFiles,
        EngineUserPolicies.useAnyCommand,
        EngineUserPolicies.viewCaptures,
        EngineUserPolicies.viewForensicSearches,
      ],
      sessions: 0,
      users: [],
    },
  ] as EngineSettingsAclRole[]

  // add additional default policies
  if (engineCapabilities?.capabilities.includes(EngineCapabilities.forensicSearchAnalysisACL)) {
    defaultRoles.forEach((role: EngineSettingsAclRole) => {
      if (role.id === ROLE_DEFAULT_ADMIN || role.id === ROLE_DEFAULT_MONITOR) {
        role.policies.push(EngineUserPolicies.forensicSearchAnalysis)
      }
    })
  }
  if (engineCapabilities?.capabilities.includes(EngineCapabilities.configureAPITokensACL)) {
    defaultRoles.forEach((role: EngineSettingsAclRole) => {
      if (role.id === ROLE_DEFAULT_ADMIN) {
        role.policies.push(EngineUserPolicies.configureAPITokens)
      }
    })
  }

  // remove unsupported policies
  if (engineCapabilities) {
    defaultRoles.forEach((role: EngineSettingsAclRole) => {
      if (!engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL)) {
        role.policies = role.policies.filter(
          (policyId: string) =>
            policyId !== EngineUserPolicies.createForensicSearch &&
            policyId !== EngineUserPolicies.deleteForensicSearches
        )
      }
      if (
        !engineCapabilities.capabilities.includes(
          EngineCapabilities.viewCapturesForensicSearchesACL
        )
      ) {
        role.policies = role.policies.filter(
          (policyId: string) =>
            policyId !== EngineUserPolicies.viewCaptures &&
            policyId !== EngineUserPolicies.viewForensicSearches
        )
      }
      if (!engineCapabilities.capabilities.includes(EngineCapabilities.saveFilesACL)) {
        role.policies = role.policies.filter(
          (policyId: string) => policyId !== EngineUserPolicies.saveFiles
        )
      }
      if (!engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchAnalysisACL)) {
        role.policies = role.policies.filter(
          (policyId: string) => policyId !== EngineUserPolicies.forensicSearchAnalysis
        )
      }
      if (!engineCapabilities.capabilities.includes(EngineCapabilities.configureAPITokensACL)) {
        role.policies = role.policies.filter(
          (policyId: string) => policyId !== EngineUserPolicies.configureAPITokens
        )
      }
    })
  }

  return defaultRoles
}

export function getErrorText(result: number): string {
  switch (result >>> 0) {
    case PeekResult.PEEK_RESULT_S_OK:
    case PeekResult.PEEK_RESULT_S_FALSE:
      return "Success"
    case PeekResult.PEEK_RESULT_E_UNEXPECTED:
      return "Unexpected error"
    case PeekResult.PEEK_RESULT_E_PENDING:
      return "Pending"
    case PeekResult.PEEK_RESULT_E_NOT_IMPLEMENTED:
      return "Not Implemented"
    case PeekResult.PEEK_RESULT_E_NO_INTERFACE:
      return "No Interface"
    case PeekResult.PEEK_RESULT_E_INVALID_OR_NULL_POINTER:
      return "Invalid or Null Pointer"
    case PeekResult.PEEK_RESULT_E_ABORT:
      return "Aborted"
    case PeekResult.PEEK_RESULT_E_FAIL:
      return "Fail"
    case PeekResult.PEEK_RESULT_E_NOT_AVAILABLE:
      return "Not Available"
    case PeekResult.PEEK_RESULT_E_FACTORY_NOT_REGISTERED:
      return "Factory Not Registered"
    case PeekResult.PEEK_RESULT_E_FACTORY_NOT_LOADED:
      return "Factory Not Loaded"
    case PeekResult.PEEK_RESULT_E_FILE_NOT_FOUND:
      return "File Not Found"
    case PeekResult.PEEK_RESULT_E_PATH_NOT_FOUND:
      return "Path Not Found"
    case PeekResult.PEEK_RESULT_E_ACCESS_DENIED:
      return "Access Denied"
    case PeekResult.PEEK_RESULT_E_INVALID_HANDLE:
      return "Invalid Handle"
    case PeekResult.PEEK_RESULT_E_OUT_OF_MEMORY:
      return "Out Of Memory"
    case PeekResult.PEEK_RESULT_E_WRITE_PROTECT:
      return "Write Protected"
    case PeekResult.PEEK_RESULT_E_EOF:
      return "EOF"
    case PeekResult.PEEK_RESULT_E_FILE_EXISTS:
      return "File Exists"
    case PeekResult.PEEK_RESULT_E_ILLEGAL_OR_INVALID_VALUE:
      return "Illegal or Invalid Value"
    case PeekResult.PEEK_RESULT_E_DISK_FULL:
      return "Disk Full"
    case PeekResult.PEEK_RESULT_E_INSUFFICIENT_BUFFER:
      return "Insufficient Buffer"
    case PeekResult.PEEK_RESULT_E_ILLEGAL_FILE_NAME:
      return "Illegal File Name"
    case PeekResult.PEEK_RESULT_E_MAX_SESSIONS_REACHED:
      return "Maximum User Sessions Reached"
    case PeekResult.PEEK_RESULT_E_CANCELLED:
      return "Cancelled"
    case PeekResult.PEEK_RESULT_E_TIMEOUT:
      return "Timed Out"
    case PeekResult.PEEK_RESULT_E_TYPE_MISMATCH:
      return "Type Mismatch"
    case PeekResult.PEEK_RESULT_E_BADVARTYPE:
      return "Bad Variable Type"
    case PeekResult.PEEK_RESULT_E_OVERFLOW:
      return "Overflow"
    default:
      return "General Error"
  }
}

export function hasAdminViews(engineCapabilities: ResponseGetEngineCapabilities | null): boolean {
  const isConfigureEngineAllowed =
    engineCapabilities &&
    engineCapabilities.userRights.policies.includes(EngineUserPolicies.configureEngine)
  const isViewAuditLogAllowed =
    engineCapabilities &&
    engineCapabilities.userRights.policies.includes(EngineUserPolicies.viewAuditLog)

  if (
    isViewAuditLogAllowed &&
    engineCapabilities?.capabilities.includes(EngineCapabilities.auditLog)
  ) {
    return true
  } else if (isConfigureEngineAllowed) {
    return true
  }

  return false
}
