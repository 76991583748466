import * as React from "react"
import { Helmet } from "react-helmet"
import { useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { Alert } from "../common/Alert"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { LightButton, PrimaryButton } from "../common/Buttons"
import { View, ViewContentScrollable, ViewContentContainer, ViewFooter } from "../common/View"
import {
  getMultiSegmentAnalysisUrl,
  getNewMultiSegmentAnalysisUrl,
  getMultiSegmentAnalysisProjectUrl,
} from "../../routes"
import { getServer, getServerAuthToken } from "../../store"
import { createMSAProject } from "../../api/api"
import {
  DistributedForensicSearchProperties,
  MSAProjectProperties,
  MSAProjectSegment,
} from "../../api/types"
import { defaultOptions } from "./utils"
import ProjectOptionsForm from "./ProjectOptionsForm"

type LocationParams = {
  distributedForensicSearch: DistributedForensicSearchProperties
}

const NewProjectView = () => {
  const history = useHistory()
  const location = useLocation<LocationParams>()
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const [error, setError] = React.useState<any | null>(null)

  const search = location.state.distributedForensicSearch

  const onSubmit = (newProject: MSAProjectProperties) => {
    createMSAProject(server, serverAuthToken, {
      created: newProject.created,
      endTime: search?.endTime,
      filter: search?.filter,
      file: newProject.file,
      name: newProject.name,
      notes: newProject.notes,
      mappingProfiles: newProject.mappingProfiles,
      options: newProject.options,
      segments: newProject.segments,
      startTime: search?.startTime,
    })
      .then(project => {
        history.push(getMultiSegmentAnalysisProjectUrl(project.id))
      })
      .catch(() => {
        setError("Failed to create project")
      })
  }

  const onCancel = () => {
    history.goBack()
  }

  let project: MSAProjectProperties | null = null
  if (search) {
    const segments: MSAProjectSegment[] = search.searches.map((search, i) => {
      return {
        analyzed: false,
        calcOffset: 0,
        calcOffsetCount: 0,
        endTime: "",
        file: search.filePath,
        id: i + 1,
        linkSpeed: 0,
        manualOffset: 0,
        mappingProfileId: -1,
        mediaSubType: 0,
        mediaType: 0,
        name: search.name,
        num: i + 1,
        startTime: "",
      }
    })
    project = {
      created: search.created,
      error: "",
      errorCode: 0,
      file: `${search.name}.msa`,
      id: "",
      mappingProfiles: [],
      modified: search.created,
      name: search.name,
      notes: "",
      options: defaultOptions,
      owner: "",
      progress: 0,
      segments,
      status: "",
    }
  }

  return (
    <View padding={false}>
      <Helmet title="New Multi-Segment Analysis Project" />
      <BreadcrumbItem to={getMultiSegmentAnalysisUrl()} title="Multi-Segment Analysis Projects" />
      <BreadcrumbItem
        to={getNewMultiSegmentAnalysisUrl()}
        title="New Multi-Segment Analysis Project"
      />
      {project !== null ? (
        <>
          <ViewContentScrollable>
            <ViewContentContainer maxWidth="960px">
              {error !== null ? (
                <Alert color="danger">
                  {typeof error === "string" ? error : `${error.code} ${error.reason}`}
                </Alert>
              ) : null}
              <ProjectOptionsForm
                formId="msaProjectOptionsForm"
                project={project}
                onSubmit={onSubmit}
              />
            </ViewContentContainer>
          </ViewContentScrollable>
          <ViewFooter>
            <LightButton onClick={onCancel}>Cancel</LightButton>
            <PrimaryButton type="submit" form="msaProjectOptionsForm">
              OK
            </PrimaryButton>
          </ViewFooter>
        </>
      ) : null}
    </View>
  )
}

export default NewProjectView
