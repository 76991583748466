import * as React from "react"
import { sortedIndexOf } from "lodash"
import cn from "classnames"
import styled, { withTheme, DefaultTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import {
  AutoSizer,
  Column,
  InfiniteLoader,
  Index,
  IndexRange,
  TableHeaderRowProps,
  TableCellDataGetterParams,
  TableCellProps,
} from "react-virtualized"
import { VirtualTable } from "../common/VirtualTable"
import { IconDropdownToggle } from "../common/Buttons"
import { Dot1 as Dot } from "../common/Dot"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import CountryName from "../common/CountryName"
import { TextWithIcon, TextWithIconList } from "../common/TextWithIcon"
import { isEthernet, isWireless, isWan } from "../../utils/mediaUtils"
import {
  formatInteger,
  formatFloat,
  formatISODate,
  formatISOTime,
  formatDuration,
} from "../../utils/formatUtils"
import { EngineCapabilities } from "../../api/types/engineTypes"
import { formatExpertValue } from "../../utils/expertUtils"
import { ExpertColumn } from "../../api/types/expertTypes"
import { MediaSubType, MediaType } from "../../api/types/mediaTypes"
import { PeekSelectRelatedParam } from "../../api/types/peekTypes"
import {
  CaptureProperties,
  ForensicSearchProperties,
  Packet,
  PacketExpert,
  ResponseGetEngineCapabilities,
  ResultsSelectRelatedResponse,
} from "../../api/types"
import { DecodePacketData } from "../../api/types/model/decodePacketData"
import { DecodeTagStyle } from "./types"

const PacketNumber = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    flex-shrink: 1;
  }

  & > :last-child {
    flex-grow: 1;
  }
`

const CommandStrip = styled.div`
  display: flex;
`

export const defaultColumns = [
  {
    dataKey: "packetNumber",
    label: "Packet",
    width: 88,
    flexGrow: false,
    alignRight: true,
    visible: true,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "source",
    label: "Source",
    width: 160,
    flexGrow: false,
    alignRight: false,
    visible: true,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "sourceLogical",
    label: "Source Logical",
    width: 160,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "sourcePhysical",
    label: "Source Physical",
    width: 140,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "sourcePort",
    label: "Source Port",
    width: 90,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "sourceCountry",
    label: "Source Country",
    width: 120,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "sourceCity",
    label: "Source City",
    width: 160,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "sourceLatitude",
    label: "Source Latitude",
    width: 120,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "sourceLongitude",
    label: "Source Longitude",
    width: 120,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "destination",
    label: "Destination",
    width: 160,
    flexGrow: false,
    alignRight: false,
    visible: true,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "destinationLogical",
    label: "Dest. Logical",
    width: 160,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "destinationPhysical",
    label: "Dest. Physical",
    width: 140,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "destinationPort",
    label: "Dest. Port",
    width: 90,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "destinationCountry",
    label: "Dest. Country",
    width: 120,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "destinationCity",
    label: "Dest. City",
    width: 160,
    flexGrow: false,
    visible: false,
    alignRight: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "destinationLatitude",
    label: "Dest. Latitude",
    width: 120,
    flexGrow: false,
    visible: false,
    alignRight: true,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "destinationLongitude",
    label: "Dest. Longitude",
    width: 120,
    flexGrow: false,
    visible: false,
    alignRight: true,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "flowId",
    label: "Flow ID",
    width: 72,
    flexGrow: false,
    alignRight: true,
    visible: true,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "vlan",
    label: "VLAN",
    width: 100,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: true,
    wireless: false,
    wan: false,
  },
  {
    dataKey: "mpls",
    label: "MPLS",
    width: 100,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: true,
    wireless: false,
    wan: false,
  },
  {
    dataKey: "vxlanGpid",
    label: "VXLAN GPID",
    width: 100,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: true,
    wireless: false,
    wan: false,
  },
  {
    dataKey: "vxlanVni",
    label: "VXLAN VNI",
    width: 100,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: true,
    wireless: false,
    wan: false,
  },
  {
    dataKey: "bssid",
    label: "BSSID",
    width: 140,
    flexGrow: false,
    alignRight: false,
    visible: true,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "transmitter",
    label: "Transmitter",
    width: 140,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "receiver",
    label: "Receiver",
    width: 140,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "address1",
    label: "Address 1",
    width: 140,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "address2",
    label: "Address 2",
    width: 140,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "address3",
    label: "Address 3",
    width: 140,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "address4",
    label: "Address 4",
    width: 140,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "flags",
    label: "Flags",
    width: 48,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "fullDuplexChannel",
    label: "Channel",
    width: 64,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: true,
    wireless: false,
    wan: false,
  },
  {
    dataKey: "wirelessChannel",
    label: "Channel",
    width: 64,
    flexGrow: false,
    alignRight: true,
    visible: true,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "frequency",
    label: "Frequency",
    width: 120,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "band",
    label: "Band",
    width: 120,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "dataRate",
    label: "Data Rate",
    width: 80,
    flexGrow: false,
    alignRight: true,
    visible: true,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "signalStrength",
    label: "Signal",
    width: 56,
    flexGrow: false,
    alignRight: true,
    visible: true,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "signaldBm",
    label: "Signal dBm",
    width: 56,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "noiseStrength",
    label: "Noise",
    width: 56,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "noisedBm",
    label: "Noise dBm",
    width: 56,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "flags80211",
    label: "802.11 Flags",
    width: 56,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "mcs",
    label: "MCS",
    width: 120,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "spatialStreams",
    label: "Spacial Streams",
    width: 120,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: false,
    wireless: true,
    wan: false,
  },
  {
    dataKey: "wanDirection",
    label: "Direction",
    width: 56,
    flexGrow: false,
    alignRight: true,
    visible: true,
    ethernet: false,
    wireless: false,
    wan: true,
  },
  {
    dataKey: "adapter",
    label: "Adapter",
    width: 120,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "size",
    label: "Size",
    width: 56,
    flexGrow: false,
    alignRight: true,
    visible: true,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  //  {
  //  dataKey: "sizeBar",
  //  label: "Size Bar",
  //  width: 120,
  //  flexGrow: false,
  //  alignRight: false,
  //  visible: false,
  //  ethernet: true,
  //  wireless: true,
  //  wan: true,
  //  },
  {
    dataKey: "ipLength",
    label: "IP Length",
    width: 78,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "ipIdentifier",
    label: "IP ID",
    width: 64,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "ipTTL",
    label: "IP TTL",
    width: 64,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "date",
    label: "Date",
    width: 100,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "absoluteTime",
    label: "Time",
    width: 160,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "deltaTime",
    label: "Delta Time",
    width: 160,
    flexGrow: false,
    alignRight: true,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "relativeTime",
    label: "Relative Time",
    width: 140,
    flexGrow: false,
    alignRight: true,
    visible: true,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "protocol",
    label: "Protocol",
    width: 140,
    flexGrow: false,
    alignRight: false,
    visible: true,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "application",
    label: "Application",
    width: 140,
    flexGrow: false,
    alignRight: false,
    visible: true,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "filter",
    label: "Filter",
    width: 140,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "summary",
    label: "Summary",
    width: 160,
    flexGrow: true,
    alignRight: false,
    visible: true,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  {
    dataKey: "summarySource",
    label: "Analysis Module",
    width: 140,
    flexGrow: false,
    alignRight: false,
    visible: false,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  //  {
  //  dataKey: "note",
  //  label: "Note",
  //  width: 120,
  //  flexGrow: true,
  //  alignRight: false,
  //  visible: false,
  //  ethernet: true,
  //  wireless: true,
  //  wan: true,
  //  },
  {
    dataKey: "expert",
    label: "Expert",
    width: 160,
    flexGrow: true,
    alignRight: false,
    visible: true,
    ethernet: true,
    wireless: true,
    wan: true,
  },
  //  {
  //  dataKey: "dynamicDecode",
  //  label: "Decode",
  //  width: 120,
  //  flexGrow: false,
  //  alignRight: false,
  //  visible: false,
  //  ethernet: true,
  //  wireless: true,
  //  wan: true,
  //  },
]

function getMediaColumns(columns: any[], mt: MediaType, mst: MediaSubType) {
  const ethernet = isEthernet(mt, mst)
  const wireless = isWireless(mt, mst)
  const wan = isWan(mt)
  return columns.filter(
    col => (ethernet && col.ethernet) || (wireless && col.wireless) || (wan && col.wan)
  )
}

function getVisibleColumns(columns: any[]) {
  return columns.filter(col => col.visible)
}

type PacketsTableProps = {
  packets: Packet[]
  firstPacketNumber: number
  rowCount: number
  engineCapabilities: ResponseGetEngineCapabilities | null
  mediaType: MediaType
  mediaSubType: MediaSubType
  columnDesc: any[]
  selectedPackets: ResultsSelectRelatedResponse | null
  decodePacketNumber: number
  onToggleColumn: (event: any) => void
  onRowClick: (params: { rowData: Packet }) => void
  isRowLoaded: (params: Index) => boolean
  loadMoreRows: (params: IndexRange) => Promise<any>
  onFlowVisualizer: (rowData: Packet) => void
  onSelectRelated: (command: PeekSelectRelatedParam, rowData: Packet) => void
  onInsertIntoNameTable: (rowData: Packet) => void
  onResolveNames: (rowData: Packet) => void
  onMakeFilter: (rowData: Packet) => void
  onProtocolDescription: (rowData: Packet) => void
  onApplicationDescription: (rowData: Packet) => void
  onMSA: (rowData: Packet) => void
  scrollToIndex?: number
  theme: DefaultTheme
  captureProperties: CaptureProperties | ForensicSearchProperties | null
  canDoMSA: boolean
  canViewPackets: boolean
  showLocalTime: boolean
  batchSize?: number
}

class PacketsTable extends React.Component<PacketsTableProps> {
  infiniteLoader: any | null = null
  table: any | null = null

  rowGetter = ({ index }: { index: number }) => {
    const { packets, firstPacketNumber } = this.props
    const packetNumber = firstPacketNumber + index
    const packet = packets.find(packet => packet.packetNumber === packetNumber)
    return packet ? packet : {}
  }

  cellDataGetter = ({ dataKey, rowData }: TableCellDataGetterParams) => {
    if (!rowData) return ""
    return rowData[dataKey]
  }

  headerRowRenderer = ({ className, columns, style }: TableHeaderRowProps) => {
    // Adjust the right padding to take into account borders on the table
    // so header columns line up with cells properly.
    if (typeof style.paddingRight === "number" && style.paddingRight >= 2) {
      style.paddingRight = +style.paddingRight - 2
    }
    // Just do what defaultHeaderRowRenderer does.
    return (
      <div className={className} role="row" style={style}>
        {columns}
      </div>
    )
  }

  cellRenderer = ({ cellData, dataKey, rowData }: TableCellProps) => {
    if (cellData == null) return ""
    let content
    switch (dataKey) {
      case "packetNumber":
        content = (
          <PacketNumber>
            <Dot show={this.props.decodePacketNumber === cellData} />
            <span>{formatInteger(cellData)}</span>
          </PacketNumber>
        )
        break
      case "size":
        content = formatInteger(cellData)
        break
      case "sourceCountry":
      case "destinationCountry":
        content = <CountryName name={cellData.name} code={cellData.code} />
        break
      case "sourceLatitude":
      case "sourceLongitude":
      case "destinationLatitude":
      case "destinationLongitude":
        content = formatFloat(cellData, 4)
        break
      case "date":
        content = formatISODate(cellData, this.props.showLocalTime)
        break
      case "absoluteTime":
        content = formatISOTime(cellData, 6, this.props.showLocalTime)
        break
      case "deltaTime":
      case "relativeTime":
        content = formatDuration(cellData, 6)
        break
      case "flags":
        content = ""
        if (cellData.snap) content += "*"
        if (cellData.crc) content += "C"
        if (cellData.frame) content += "F"
        if (cellData.runt) content += "R"
        if (cellData.oversize) content += "O"
        if (cellData.droppPackets) content += "D"
        if (cellData.trigger) content += "T"
        break
      case "expert":
        if (Array.isArray(cellData)) {
          const useColor = this.props.theme.name === "Light"
          const eventItems = (cellData as PacketExpert[]).map(
            (packetExpert: PacketExpert, i: number) => (
              <TextWithIcon
                key={i}
                icon={formatExpertValue(
                  ExpertColumn.EXPERT_COLUMN_EVENT_SEVERITY_MAX,
                  packetExpert.severity
                )}
                text={packetExpert.message}
                textStyle={{
                  color: useColor && packetExpert.color ? packetExpert.color : undefined,
                }}
              />
            )
          )
          content = <TextWithIconList>{eventItems}</TextWithIconList>
        }
        break
      case "vlan":
      case "mpls":
        if (Array.isArray(cellData)) {
          content = cellData.join(", ")
        }
        break
      default:
        if (/decode\//.test(dataKey)) {
          if (Array.isArray(cellData)) {
            const htmlarr = cellData.map((obj: DecodePacketData, index: number) => {
              if (obj.text !== undefined && obj.style !== undefined) {
                let color: string = ""
                let fontStyle: string = ""
                if (
                  this.props.theme.name === "Light" &&
                  obj.red !== undefined &&
                  obj.green !== undefined &&
                  obj.blue !== undefined
                ) {
                  color = `rgb(${obj.red},${obj.green},${obj.blue})`
                }
                switch (obj.style) {
                  case DecodeTagStyle.Message:
                    if (color === "") {
                      color = this.props.theme.decodeMessageColor
                    }
                    fontStyle = "italic"
                    break
                  case DecodeTagStyle.NameTable:
                    fontStyle = "italic"
                    break
                  default:
                    break
                }
                return (
                  <span key={index} title={obj.text} style={{ color, fontStyle }}>
                    {obj.text}&ensp;
                  </span>
                )
              } else {
                return <span key={index} />
              }
            })
            content = <span>{htmlarr}</span>
          }
        } else {
          if (this.props.theme.name === "Light") {
            const color = rowData[dataKey + "Color"]
            if (color) {
              content = (
                <span title={cellData} style={{ color }}>
                  {cellData}
                </span>
              )
            } else {
              content = String(cellData)
            }
          } else {
            content = String(cellData)
          }
          break
        }
    }
    return content // return non-string to avoid title
  }

  commandCellRenderer = ({ rowData }: TableCellProps) => {
    const { captureProperties, canViewPackets, canDoMSA, engineCapabilities } = this.props
    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            {captureProperties !== null && !!captureProperties.expertEnabled && (
              <>
                <DropdownItem
                  disabled={!rowData.flowId}
                  onClick={this.props.onFlowVisualizer.bind(this, rowData)}
                >
                  Flow Visualizer
                </DropdownItem>
                <DropdownItem divider />
              </>
            )}
            <DropdownItem
              disabled={!canViewPackets}
              onClick={this.props.onSelectRelated.bind(
                this,
                PeekSelectRelatedParam.PEEK_SELECT_RELATED_BY_SOURCE,
                rowData
              )}
            >
              Select Related Packets by Source
            </DropdownItem>
            <DropdownItem
              disabled={!canViewPackets}
              onClick={this.props.onSelectRelated.bind(
                this,
                PeekSelectRelatedParam.PEEK_SELECT_RELATED_BY_DESTINATION,
                rowData
              )}
            >
              Select Related Packets by Destination
            </DropdownItem>
            <DropdownItem
              disabled={!canViewPackets}
              onClick={this.props.onSelectRelated.bind(
                this,
                PeekSelectRelatedParam.PEEK_SELECT_RELATED_BY_SOURCE_AND_DESTINATION,
                rowData
              )}
            >
              Select Related Packets by Source &amp; Destination
            </DropdownItem>
            {engineCapabilities?.capabilities.includes(
              EngineCapabilities.mplsVxlanSelectRelated
            ) && (
              <DropdownItem
                disabled={!canViewPackets}
                onClick={this.props.onSelectRelated.bind(
                  this,
                  PeekSelectRelatedParam.PEEK_SELECT_RELATED_BY_MPLS,
                  rowData
                )}
              >
                Select Related Packets by MPLS
              </DropdownItem>
            )}
            <DropdownItem
              disabled={!canViewPackets}
              onClick={this.props.onSelectRelated.bind(
                this,
                PeekSelectRelatedParam.PEEK_SELECT_RELATED_BY_VLAN,
                rowData
              )}
            >
              Select Related Packets by VLAN
            </DropdownItem>
            {engineCapabilities?.capabilities.includes(
              EngineCapabilities.mplsVxlanSelectRelated
            ) && (
              <>
                <DropdownItem
                  disabled={!canViewPackets}
                  onClick={this.props.onSelectRelated.bind(
                    this,
                    PeekSelectRelatedParam.PEEK_SELECT_RELATED_BY_VXLAN_GPID,
                    rowData
                  )}
                >
                  Select Related Packets by VXLAN GPID
                </DropdownItem>
                <DropdownItem
                  disabled={!canViewPackets}
                  onClick={this.props.onSelectRelated.bind(
                    this,
                    PeekSelectRelatedParam.PEEK_SELECT_RELATED_BY_VXLAN_VNI,
                    rowData
                  )}
                >
                  Select Related Packets by VXLAN VNI
                </DropdownItem>
              </>
            )}
            <DropdownItem
              disabled={!canViewPackets}
              onClick={this.props.onSelectRelated.bind(
                this,
                PeekSelectRelatedParam.PEEK_SELECT_RELATED_BY_PROTOCOL,
                rowData
              )}
            >
              Select Related Packets by Protocol
            </DropdownItem>
            <DropdownItem
              disabled={!canViewPackets}
              onClick={this.props.onSelectRelated.bind(
                this,
                PeekSelectRelatedParam.PEEK_SELECT_RELATED_BY_PORT,
                rowData
              )}
            >
              Select Related Packets by Port
            </DropdownItem>
            <DropdownItem
              disabled={!canViewPackets}
              onClick={this.props.onSelectRelated.bind(
                this,
                PeekSelectRelatedParam.PEEK_SELECT_RELATED_BY_APPLICATION,
                rowData
              )}
            >
              Select Related Packets by Application
            </DropdownItem>
            {/*
            <DropdownItem disabled={true || !canViewPackets} onClick={this.props.onSelectRelated.bind(this, PeekSelectRelatedParam.PEEK_SELECT_RELATED_BY_CONVERSATION, rowData)}>
              Select Related Packets by Flow
            </DropdownItem>
            */}
            <DropdownItem divider />
            <DropdownItem disabled={!canDoMSA} onClick={this.props.onMSA.bind(this, rowData)}>
              Multi-Segment Analysis
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.props.onMakeFilter.bind(this, rowData)}>
              Make Filter
            </DropdownItem>
            <DropdownItem onClick={this.props.onInsertIntoNameTable.bind(this, rowData)}>
              Insert Into Name Table
            </DropdownItem>
            <DropdownItem onClick={this.props.onResolveNames.bind(this, rowData)}>
              Resolve Names
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.props.onProtocolDescription.bind(this, rowData)}>
              Protocol Description
            </DropdownItem>
            <DropdownItem onClick={this.props.onApplicationDescription.bind(this, rowData)}>
              Application Description
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  commandHeaderRenderer = () => {
    const { columnDesc, mediaType, mediaSubType } = this.props
    const columns = getMediaColumns(columnDesc, mediaType, mediaSubType)
    const items: any[] = []
    columns.forEach(col => {
      items.push(
        <DropdownItem key={col.dataKey} name={col.dataKey} onClick={this.props.onToggleColumn}>
          <FontAwesome name={col.visible ? "check" : "blank"} fixedWidth /> {col.label}
        </DropdownItem>
      )
    })
    return (
      <UncontrolledDropdownWithPortal
        dropdownToggle={
          <IconDropdownToggle aria-label="Toggle Columns">
            <FontAwesome name="ellipsis-h" fixedWidth />
          </IconDropdownToggle>
        }
      >
        <DropdownMenu end>{items}</DropdownMenu>
      </UncontrolledDropdownWithPortal>
    )
  }

  rowClassName = ({ index }: { index: number }) => {
    let className = "clickable"
    if (index >= 0 && index % 2 !== 0) {
      className = cn(className, "stripe")
    }
    const { firstPacketNumber, selectedPackets } = this.props
    if (selectedPackets && selectedPackets.packets.length) {
      const packetNumber = firstPacketNumber + index
      if (sortedIndexOf(selectedPackets.packets, packetNumber) !== -1) {
        className = cn(className, "selected")
      }
    }
    return className
  }

  render() {
    const {
      rowCount,
      mediaType,
      mediaSubType,
      columnDesc,
      onRowClick,
      isRowLoaded,
      loadMoreRows,
      scrollToIndex,
      batchSize,
    } = this.props
    const columns = getVisibleColumns(getMediaColumns(columnDesc, mediaType, mediaSubType))
    return (
      <InfiniteLoader
        ref={ref => {
          this.infiniteLoader = ref
        }}
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={rowCount}
        minimumBatchSize={batchSize || 100}
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ height, width }) => (
              <VirtualTable
                width={width}
                height={height}
                headerHeight={30}
                rowHeight={26}
                rowCount={rowCount}
                rowGetter={this.rowGetter}
                headerRowRenderer={this.headerRowRenderer}
                onRowClick={onRowClick}
                onRowsRendered={onRowsRendered}
                overscanRowCount={4}
                scrollToIndex={scrollToIndex}
                scrollToAlignment="start"
                rowClassName={this.rowClassName}
                ref={(ref: any) => {
                  this.table = ref
                  registerChild(ref)
                }}
              >
                {columns.map(col => {
                  const className = col.alignRight ? "right" : undefined
                  return (
                    <Column
                      key={col.dataKey}
                      dataKey={col.dataKey}
                      label={col.label}
                      width={col.width}
                      flexGrow={col.flexGrow ? 1 : 0}
                      className={className}
                      headerClassName={className}
                      cellDataGetter={this.cellDataGetter}
                      cellRenderer={this.cellRenderer}
                    />
                  )
                })}
                <Column
                  key="commands"
                  dataKey="commands"
                  width={28}
                  flexShrink={0}
                  cellRenderer={this.commandCellRenderer}
                  headerRenderer={this.commandHeaderRenderer}
                />
              </VirtualTable>
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    )
  }
}

export default withTheme(PacketsTable)
