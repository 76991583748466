import * as React from "react"
import { useSelector } from "react-redux"
import { Helmet } from "react-helmet"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import BreadcrumbItem from "../../BreadcrumbNav/BreadcrumbItem"
import { Alert } from "../../common/Alert"
import { CenterContent } from "../../common/Layout"
import ConfirmationModal from "../../common/ConfirmationModal"
import FilterBox from "../../common/FilterBox"
import Interval from "../../common/Interval"
import { LightButton, LightDangerButton } from "../../common/Buttons"
import { Spinner } from "../../common/Spinner"
import { UncontrolledTooltip } from "../../common/UncontrolledTooltip"
import {
  View,
  ViewContentScrollable,
  ViewHeader,
  ViewHeaderTitle,
  ViewHeaderButtons,
} from "../../common/View"
import { collator } from "../../../utils/sortUtils"
import {
  getDistributedForensicSearchesUrl,
  getDistributedForensicSearchUrl,
  getNewDistributedForensicSearchUrl,
} from "../../../routes"
import { getServer, getServerAuthToken, getCapabilities, getUserId } from "../../../store"
import {
  deleteDistributedForensicSearch,
  deleteAllDistributedForensicSearches,
  fetchDistributedForensicSearches,
  stopDistributedForensicSearch,
} from "../../../api/api"
import {
  DistributedForensicSearchProperties,
  ResponseGetEngineCapabilities,
} from "../../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../../api/types/engineTypes"
import { useSearchDispatch, useViewFilter, setViewFilter } from "../Context"
import Panel from "../Panel"

const Table = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: 0 auto;
`

export const ViewHeaderButtonStrip = styled.div`
  display: flex;

  & .btn {
    white-space: nowrap;
    flex-grow: 1;
  }

  & > * + * {
    margin-left: 4px;
  }
`

const ListView = () => {
  const dispatch = useSearchDispatch()
  const history = useHistory()
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const userId = useSelector(getUserId)
  const engineCapabilities: ResponseGetEngineCapabilities = useSelector(getCapabilities)
  const viewFilter = useViewFilter()
  const [error, setError] = React.useState<any | null>(null)

  const [distributedForensicSearches, setDistributedForensicSearches] = React.useState<
    DistributedForensicSearchProperties[] | null
  >(null)
  const [showDeleteAllConfirm, setShowDeleteAllConfirm] = React.useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false)
  const [deletePending, setDeletePending] = React.useState<string[]>([])
  const [currentId, setCurrentId] = React.useState<string | null>(null)
  const [refreshInterval, setRefreshInterval] = React.useState(10000)

  const onRefresh = React.useCallback(() => {
    fetchDistributedForensicSearches(server, serverAuthToken)
      .then(response => {
        if (Array.isArray(response.distributedForensicSearches)) {
          response.distributedForensicSearches.sort((a, b) => {
            let result = collator.compare(a.name, b.name)
            if (result === 0) {
              // Names are the same. Put the most recent search on top.
              if (a.created < b.created) {
                result = 1
              } else if (a.created > b.created) {
                result = -1
              }
            }
            return result
          })
          setDistributedForensicSearches(response.distributedForensicSearches)
          const newRefreshInterval = response.distributedForensicSearches.find(
            it => it.status !== "complete"
          )
            ? 2500
            : 10000
          setRefreshInterval(newRefreshInterval)
        }
      })
      .catch(error => {
        setError(error)
      })
  }, [server, serverAuthToken])

  const onNewSearch = () => {
    history.push(getNewDistributedForensicSearchUrl())
  }

  const onDeleteAll = () => {
    setShowDeleteAllConfirm(true)
  }

  const onDeleteAllCancel = () => {
    setShowDeleteAllConfirm(false)
  }

  const onDeleteAllOK = () => {
    setShowDeleteAllConfirm(false)
    deleteAllDistributedForensicSearches(server, serverAuthToken).finally(() => {
      onRefresh()
    })
  }

  const onDelete = (forensicSearchId: string) => {
    setCurrentId(forensicSearchId)
    setShowDeleteConfirm(true)
  }

  const onDeleteCancel = () => {
    setCurrentId(null)
    setShowDeleteConfirm(false)
  }

  const onDeleteOK = () => {
    const distributedForensicSearchId = currentId
    setCurrentId(null)
    setShowDeleteConfirm(false)
    if (distributedForensicSearchId != null) {
      const newDeletePending = deletePending.filter(id => id === distributedForensicSearchId)
      newDeletePending.push(distributedForensicSearchId)
      setDeletePending(newDeletePending)
      deleteDistributedForensicSearch(server, serverAuthToken, distributedForensicSearchId)
        .then(() => {
          setError(null)
          onRefresh()
        })
        .catch(() => {
          setError("Delete failed")
        })
        .finally(() => {
          setDeletePending(deletePending.filter(id => id === distributedForensicSearchId))
        })
    }
  }

  const onStop = (forensicSearchId: string) => {
    stopDistributedForensicSearch(server, serverAuthToken, forensicSearchId)
      .then(() => {
        setError(null)
        onRefresh()
      })
      .catch(() => {
        setError("Failed to stop search")
      })
  }

  const getDistributedForensicSearches = () => {
    let viewDistributedForensicSearches = distributedForensicSearches
    if (viewDistributedForensicSearches && viewFilter) {
      const lowerCaseFilter = viewFilter.toLowerCase()
      viewDistributedForensicSearches = viewDistributedForensicSearches.filter(forensicSearch =>
        forensicSearch.name.toLowerCase().includes(lowerCaseFilter)
      )
    }
    return viewDistributedForensicSearches
  }

  React.useEffect(() => {
    onRefresh()
  }, [onRefresh])

  // Get the filtered list of forensic searches
  const searches = getDistributedForensicSearches()

  // Count is the total without any filtering
  const count = searches?.length ?? 0

  let userOwnsAllForensicSearches = false
  if (searches) {
    userOwnsAllForensicSearches = searches.every(
      (fs: DistributedForensicSearchProperties) => userId === fs.creatorSID
    )
  }

  // Make sure the user can upload files and delete forensic searches
  let canUploadFiles = true
  let canCreateForensicSearchFiles = true
  let canDeleteForensicSearches = true
  if (engineCapabilities) {
    const policies = engineCapabilities.userRights.policies
    canUploadFiles = policies.includes(EngineUserPolicies.uploadFiles)
    if (engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL)) {
      canCreateForensicSearchFiles = policies.includes(EngineUserPolicies.createForensicSearch)
      canDeleteForensicSearches =
        userOwnsAllForensicSearches || policies.includes(EngineUserPolicies.deleteForensicSearches)
    }
  }

  return (
    <View>
      <Helmet title="Distributed Forensic Searches" />
      <BreadcrumbItem
        to={getDistributedForensicSearchesUrl()}
        title="Distributed Forensic Searches"
      />
      <Interval timeout={refreshInterval} enabled={true} callback={onRefresh} />
      <ViewHeader>
        <ViewHeaderTitle title="Distributed Forensic Searches" count={count} />
        <ViewHeaderButtons>
          <FilterBox
            aria-label="Search"
            placeholder="Search"
            onChange={(filter: string) => {
              setViewFilter(dispatch, filter)
            }}
            value={viewFilter}
          />
          <ViewHeaderButtonStrip>
            <LightButton
              onClick={onNewSearch}
              disabled={!canUploadFiles || !canCreateForensicSearchFiles}
            >
              <FontAwesome name="search" flip="horizontal" /> New Search
            </LightButton>
            <LightDangerButton
              id="delete-all"
              disabled={count === 0 || !canDeleteForensicSearches}
              onClick={onDeleteAll}
            >
              <FontAwesome name="trash-o" /> Delete All
            </LightDangerButton>
            <LightButton aria-label="Refresh" id="refresh" onClick={onRefresh}>
              <FontAwesome name="refresh" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="refresh">
              Refresh
            </UncontrolledTooltip>
          </ViewHeaderButtonStrip>
        </ViewHeaderButtons>
      </ViewHeader>
      {error != null ? (
        <Alert color="danger">
          {typeof error === "string" ? error : `${error.code} ${error.reason}`}
        </Alert>
      ) : searches != null ? (
        <ViewContentScrollable>
          <Table>
            {searches.map(distributedForensicSearch => (
              <Panel
                key={distributedForensicSearch.id}
                distributedForensicSearch={distributedForensicSearch}
                url={getDistributedForensicSearchUrl(distributedForensicSearch.id)}
                server={server}
                serverAuthToken={serverAuthToken}
                onDelete={() => onDelete(distributedForensicSearch.id)}
                isDeleting={deletePending.includes(distributedForensicSearch.id)}
                onStop={() => onStop(distributedForensicSearch.id)}
                showDetails={true}
              />
            ))}
          </Table>
        </ViewContentScrollable>
      ) : (
        <CenterContent>
          <Spinner />
        </CenterContent>
      )}
      {showDeleteAllConfirm && (
        <ConfirmationModal
          message={
            <span>
              Are you sure you want to delete all distributed forensic searches?
              <br />
              <br />
              Completed search results will not be deleted and can be managed in the Files view.
            </span>
          }
          onNo={onDeleteAllCancel}
          onYes={onDeleteAllOK}
          show={showDeleteAllConfirm}
          title="Delete All Distributed Forensic Searches"
        />
      )}
      {showDeleteConfirm && (
        <ConfirmationModal
          message={
            <span>
              Are you sure you want to delete the distributed forensic search?
              <br />
              <br />
              Completed search results will not be deleted and can be managed in the Files view.
            </span>
          }
          onNo={onDeleteCancel}
          onYes={onDeleteOK}
          show={showDeleteConfirm}
          title="Delete Distributed Forensic Search"
        />
      )}
    </View>
  )
}

export default ListView
