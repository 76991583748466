import {
  AdapterConfiguration,
  AddressResolverRequest,
  AddressResolverRequestEntry,
  AddressResolverResponse,
  AlarmCollection,
  AlarmInfo,
  AuthenticationToken,
  CaptureProperties,
  CaptureTemplate,
  CaptureTemplateProperties,
  DecryptionKeys,
  DiagnosticCommand,
  DistributedForensicSearchProperties,
  DMSConfiguration,
  Engine,
  EngineCollection,
  EngineSettings,
  EngineSyncResults,
  ExpertExecuteResponse,
  ExpertPreferencesGet,
  ExpertPreferencesSet,
  ExpertQueryResponse,
  Filter,
  FilterCollection,
  ForensicSearchProperties,
  ForensicSearchHistoryItemProperties,
  ForensicSearchHistorySortBy,
  GraphCollection,
  GraphObject,
  GraphTemplates,
  HardwareOptionsCollection,
  LicenseGetInfo,
  LicenseSetInfo,
  LicenseSettings,
  LiveFlowConfiguration,
  LiveFlowConfigurationResult,
  NameResolverRequest,
  NameResolverRequestEntry,
  NameResolverResponse,
  NameTable,
  NameTableEntry,
  Notifications,
  NotificationAction,
  PostNames,
  ProgressSelectRelatedResponse,
  ProtocolTranslations,
  RequestCreateAuthenticationToken,
  RequestCreateForensicSearch,
  RequestCreateDistributedForensicSearch,
  RequestCreateMSAProject,
  RequestExpertExecute,
  RequestExpertQuery,
  RequestGetStatisticsStatistic,
  RequestModifyAuthenticationToken,
  RequestPostEngineSync,
  RequestPostFlowVisualizer,
  RequestPostForensicSearchHistoryItem,
  RequestPostLogin,
  RequestPostLoginConfigureVerify,
  RequestPostLoginRefresh,
  RequestPostLogout,
  RequestPostPluginsMessage,
  RequestPostSelectRelatedStart,
  RequestPostSelectRelatedExpertStart,
  RequestPostSelectRelatedFilterStart,
  RequestPostSelectRelatedFilterConfigStart,
  RequestPostSelectRelatedWebStart,
  RequestPostSendEmail,
  RequestPostSendNotifications,
  RequestPostSNMPSettings,
  RequestPostStatisticsQuery,
  RequestSetMSAProjectOptions,
  RequestPutForensicSearch,
  RequestTestActiveDirectory,
  ResponseCreateCapture,
  ResponseCreateForensicSearch,
  ResponseCreateDistributedForensicSearch,
  ResponseCreateMSAProject,
  ResponseDeleteAuthenticationTokens,
  ResponseDeleteDecryptionKeys,
  ResponseDeleteEvents,
  ResponseDeleteFiles,
  ResponseGetAdapterInfos,
  ResponseGetAdapters,
  ResponseGetApplications,
  ResponseGetAuditLog,
  ResponseGetAuthenticationTokens,
  ResponseGetCaptureSessions,
  ResponseGetCaptureList,
  ResponseGetCaptureTemplates,
  ResponseGetConnectedUsers,
  ResponseGetCountries,
  ResponseGetDecryptionKeys,
  ResponseGetDirectoryList,
  ResponseGetDistributedForensicSearches,
  ResponseGetEngineCapabilities,
  ResponseGetEngineSync,
  ResponseGetEvents,
  ResponseGetFileList,
  ResponseGetFilterConvert,
  ResponseGetFlowVisualizer,
  ResponseGetForensicSearches,
  ResponseGetForensicSearchHistory,
  ResponseGetGraphDataById,
  ResponseGetGraphStats,
  ResponseGetLockingCode,
  ResponseGetMSAProjects,
  ResponseGetPacket,
  ResponseGetPacketList,
  ResponseGetProtocols,
  ResponseGetReconstructionsList,
  ResponseGetSaveCaptureTask,
  ResponseGetSNMPSettings,
  ResponseGetTimelineData,
  ResponseGetTimelineStatistics,
  ResponseGetUserList,
  ResponseGetVersion,
  ResponsePostLoginConfigure,
  ResponsePostLoginConfigureVerify,
  ResponsePostLoginRefresh,
  ResponsePostPluginsMessage,
  ResponsePostSaveCapture,
  ResponsePostSaveCaptureTask,
  ResponsePostSaveSelectedPackets,
  ResponsePostSelectRelatedStart,
  ResponsePostSelectRelatedExpertStart,
  ResponsePostSelectRelatedFilterStart,
  ResponsePostSelectRelatedFilterConfigStart,
  ResponsePostSelectRelatedWebStart,
  ResponsePostSendEmail,
  ResponsePostStatisticsQuery,
  ResponsePostToken,
  ResponseTestActiveDirectory,
  Result,
  Results,
  ResultsSelectRelatedResponse,
  SNMPSettings,
  ThreatEyeNVConfiguration,
  TimelineDataType,
  TimelineStatisticsType,
  MSAProjectProperties,
} from "./types"
import { MergeOptions } from "./types/nameTypes"
import { PeekAdapterType } from "./types/peekTypes"
import { Dashboard, DashboardCollection, Widget, WidgetCollection } from "./types/dashboardTypes"
import { peekToDate } from "../utils/formatUtils"

const BASE_URL = "/api/v1"

const CACHE_CONTROL = "no-store, max-age=0"

export enum MimeType {
  MIME_TYPE_HTML = "text/html",
  MIME_TYPE_JSON = "application/json",
  MIME_TYPE_OCTECT_STREAM = "application/octet-stream",
  MIME_TYPE_RTF = "application/rtf",
  MIME_TYPE_TEXT = "text/plain",
  MIME_TYPE_X_WWW_FORM_URLENCODED = "application/x-www-form-urlencoded",
  MIME_TYPE_XML = "application/xml",
  MIME_TYPE_X_JAVASCRIPT = "application/x-javascript",
}

//////////////////////////////////////////////////////////////////////////
// Helpers
//////////////////////////////////////////////////////////////////////////

// See:
// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
// https://css-tricks.com/using-fetch/
export const handleJSONResponse = <T>(response: Response): Promise<T> => {
  return response.json().then(body => {
    if (response.ok) {
      return body
    } else {
      return Promise.reject({
        code: response.status,
        reason: response.statusText,
        message: "",
      })
    }
  })
}

export const handleTextResponse = <T>(response: Response): Promise<T> => {
  return response.text().then((body: any) => {
    if (response.ok) {
      return body
    } else {
      return Promise.reject({
        code: response.status,
        reason: response.statusText,
        message: "",
      })
    }
  })
}

export const handleBinaryResponse = <T>(response: Response): Promise<T> => {
  return response.arrayBuffer().then((body: any) => body)
}

export const handleResponse = <T>(response: Response): Promise<T> => {
  const contentType = response.headers.get("content-type")
  if (contentType != null) {
    if (contentType.includes("json")) {
      return handleJSONResponse(response)
    } else if (
      contentType.includes("text") ||
      contentType.includes("xml") ||
      contentType === MimeType.MIME_TYPE_RTF
    ) {
      return handleTextResponse(response)
    } else if (contentType === MimeType.MIME_TYPE_OCTECT_STREAM) {
      return handleBinaryResponse(response)
    }
    throw new Error(`Content type ${contentType} not supported`)
  }
  if (!response.ok) {
    return Promise.reject({
      code: response.status,
      reason: response.statusText,
      message: "",
    })
  } else if (response.status === 204) {
    return new Promise(resolve => {
      // @ts-ignore
      resolve()
    })
  }
  throw new Error("No content type")
}

export const isJWT = (token: string) => {
  return token.length > 32 && token.startsWith("ey")
}

//////////////////////////////////////////////////////////////////////////
// Adapters
//////////////////////////////////////////////////////////////////////////

export const fetchAdapters = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetAdapters> => {
  return fetch(`${engine}${BASE_URL}/adapters/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetAdapters>(response)
  })
}

export const refreshAdapters = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/adapters/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const deleteAdapter = (
  engine: string,
  authToken: string,
  adapterId: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/adapters/${adapterId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const fetchAdapterConfig = (
  engine: string,
  authToken: string,
  adapterId: string,
  adapterType: PeekAdapterType,
  init?: RequestInit
): Promise<AdapterConfiguration> => {
  return fetch(`${engine}${BASE_URL}/adapters/config/${adapterId}/${adapterType}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<AdapterConfiguration>(response)
  })
}

export const fetchAdapterHardwareConfigs = (
  engine: string,
  authToken: string,
  ids: string[] | null = null,
  clsids: string[] | null = null,
  init?: RequestInit
): Promise<HardwareOptionsCollection> => {
  const queryParams: string[] = []
  if (Array.isArray(ids)) {
    ids.forEach(id => {
      queryParams.push(`ids=${id}`)
    })
  }
  if (Array.isArray(clsids)) {
    clsids.forEach(clsid => {
      queryParams.push(`clsids=${clsid}`)
    })
  }
  const query = queryParams ? `?${queryParams.join("&")}` : ""
  return fetch(`${engine}${BASE_URL}/adapters/hardware/${query}`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<HardwareOptionsCollection>(response)
  })
}

export const fetchAdapterHardwareConfigsXML = (
  engine: string,
  authToken: string,
  ids: string[] = [],
  init?: RequestInit
): Promise<string> => {
  if (ids.length > 0) {
    return fetch(`${engine}${BASE_URL}/adapters/hardware/?ids=${ids}`, {
      method: "GET",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_XML,
        "cache-control": CACHE_CONTROL,
      },
      ...init,
    }).then(response => {
      return handleResponse<string>(response)
    })
  } else {
    return fetch(`${engine}${BASE_URL}/adapters/hardware/`, {
      method: "GET",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_XML,
      },
      ...init,
    }).then(response => {
      return handleResponse<string>(response)
    })
  }
}

export const fetchAdapterInfos = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetAdapterInfos> => {
  return fetch(`${engine}${BASE_URL}/adapters/info/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetAdapterInfos>(response)
  })
}

export const setAdapterConfig = (
  engine: string,
  authToken: string,
  adapterId: string,
  adapterType: PeekAdapterType,
  adapterConfig: AdapterConfiguration,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/adapters/config/${adapterId}/${adapterType}/`, {
    method: "PUT",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(adapterConfig),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const setAdapterHardwareConfigs = (
  engine: string,
  authToken: string,
  hardwareConfigs: HardwareOptionsCollection,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/adapters/hardware/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(hardwareConfigs),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const setAdapterHardwareConfigsXML = (
  engine: string,
  authToken: string,
  xml: string,
  deleteAll: boolean | null = null,
  init?: RequestInit
): Promise<void> => {
  const queryParams: string[] = []
  if (deleteAll !== null) {
    queryParams.push(`deleteAll=${deleteAll}`)
  }
  const query = queryParams ? `?${queryParams.join("&")}` : ""
  return fetch(`${engine}${BASE_URL}/adapters/hardware/${query}`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_XML,
    },
    body: xml,
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const setAdapterName = (
  engine: string,
  authToken: string,
  adapterId: string,
  adapterType: PeekAdapterType,
  name: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/adapters/name/${adapterId}/${adapterType}/`, {
    method: "PUT",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify({
      name,
    }),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Alarms
//////////////////////////////////////////////////////////////////////////

export const deleteAlarm = (
  engine: string,
  authToken: string,
  alarmId: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/alarms/${alarmId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const fetchAlarm = (
  engine: string,
  authToken: string,
  alarmId: string,
  init?: RequestInit
): Promise<AlarmCollection> => {
  return fetch(`${engine}${BASE_URL}/alarms/${alarmId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<AlarmCollection>(response)
  })
}

export const fetchAlarms = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<AlarmCollection> => {
  return fetch(`${engine}${BASE_URL}/alarms/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<AlarmCollection>(response)
  })
}

export const fetchAlarmsXML = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<string> => {
  return fetch(`${engine}${BASE_URL}/alarms/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_XML,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<string>(response)
  })
}

export const setAlarm = (
  engine: string,
  authToken: string,
  alarm: AlarmInfo,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/alarms/${alarm.id}/`, {
    method: "PUT",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(alarm),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const setAlarms = (
  engine: string,
  authToken: string,
  alarms: AlarmCollection,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/alarms/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(alarms),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Applications
//////////////////////////////////////////////////////////////////////////

export const fetchApplications = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetApplications> => {
  return fetch(`${engine}${BASE_URL}/applications/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetApplications>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Authentication
//////////////////////////////////////////////////////////////////////////

export const logIn = (
  engine: string,
  request: RequestPostLogin,
  init?: RequestInit
): Promise<Response> => {
  if (request.client === undefined || request.client === "") {
    request.client = "Peek"
  }
  return fetch(`${engine}${BASE_URL}/login/`, {
    method: "POST",
    headers: {
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(request),
    ...init,
  })
}

export const logInCertificate = (engine: string, init?: RequestInit): Promise<Response> => {
  return fetch(`${engine}${BASE_URL}/login/certificate`, {
    method: "POST",
    headers: {
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  })
}

export const logOut = (
  engine: string,
  request: RequestPostLogout,
  init?: RequestInit
): Promise<Response> => {
  return fetch(`${engine}${BASE_URL}/logout/`, {
    method: "POST",
    headers: {
      authorization: request.authToken ? `Token ${request.authToken}` : "",
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(request),
    ...init,
  })
}

export const logInConfigure = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponsePostLoginConfigure> => {
  return fetch(`${engine}${BASE_URL}/login/configure/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostLoginConfigure>(response)
  })
}

export const logInConfigureVerify = (
  engine: string,
  authToken: string,
  request: RequestPostLoginConfigureVerify,
  init?: RequestInit
): Promise<ResponsePostLoginConfigureVerify> => {
  return fetch(`${engine}${BASE_URL}/login/configure/verify/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    body: JSON.stringify(request),
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostLoginConfigureVerify>(response)
  })
}

export const logInRefresh = (
  engine: string,
  authToken: string,
  request: RequestPostLoginRefresh,
  init?: RequestInit
): Promise<ResponsePostLoginRefresh> => {
  return fetch(`${engine}${BASE_URL}/login/refresh/`, {
    method: "POST",
    headers: {
      authorization: `Bearer ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    body: JSON.stringify(request),
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostLoginRefresh>(response)
  })
}

export const createAuthenticationToken = (
  engine: string,
  authToken: string,
  token: RequestCreateAuthenticationToken,
  init?: RequestInit
): Promise<ResponsePostToken> => {
  return fetch(`${engine}${BASE_URL}/token/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(token),
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostToken>(response)
  })
}

export const setAuthenticationToken = (
  engine: string,
  authToken: string,
  authTokenId: string,
  token: RequestModifyAuthenticationToken,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/token/${authTokenId}/`, {
    method: "PUT",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(token),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const deleteAuthenticationTokens = (
  engine: string,
  authToken: string,
  authTokenIds: string[],
  init?: RequestInit
): Promise<ResponseDeleteAuthenticationTokens> => {
  if (authTokenIds.length === 1) {
    // For a single id, just include it as a query param.
    return fetch(`${engine}${BASE_URL}/token/?ids=${authTokenIds[0]}`, {
      method: "DELETE",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_JSON,
      },
      ...init,
    }).then(response => {
      return handleResponse<ResponseDeleteAuthenticationTokens>(response)
    })
  } else {
    // For multiple ids, send the list form-encoded in the body.
    // Sending a body with DELETE is maybe not a great idea but this
    // is an easy way to batch the request. Some clients might not even
    // be able to send a body with a DELETE request. The alternative is
    // to make multiple requests.
    let body = ""
    for (let i = 0; i < authTokenIds.length; i++) {
      body += "ids="
      body += encodeURIComponent(authTokenIds[i])
      if (i + 1 < authTokenIds.length) {
        body += "&"
      }
    }
    return fetch(`${engine}${BASE_URL}/token/`, {
      method: "DELETE",
      headers: {
        authorization: `Token ${authToken}`,
        "content-type": MimeType.MIME_TYPE_X_WWW_FORM_URLENCODED,
        accept: MimeType.MIME_TYPE_JSON,
      },
      body,
      ...init,
    }).then(response => {
      return handleResponse<ResponseDeleteAuthenticationTokens>(response)
    })
  }
}

export const fetchAuthenticationToken = (
  engine: string,
  authToken: string,
  authTokenId: string,
  init?: RequestInit
): Promise<AuthenticationToken> => {
  return fetch(`${engine}${BASE_URL}/token/${authTokenId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<AuthenticationToken>(response)
  })
}

export const fetchAuthenticationTokens = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetAuthenticationTokens> => {
  return fetch(`${engine}${BASE_URL}/token/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetAuthenticationTokens>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Capture Sessions
//////////////////////////////////////////////////////////////////////////

export const deleteAllCaptureSessions = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/capture-sessions/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const deleteCaptureSession = (
  engine: string,
  authToken: string,
  captureId: number,
  init?: RequestInit
): Promise<Results> => {
  return fetch(`${engine}${BASE_URL}/capture-sessions/${captureId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<Results>(response)
  })
}

export const fetchCaptureSessions = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetCaptureSessions> => {
  return fetch(`${engine}${BASE_URL}/capture-sessions/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetCaptureSessions>(response)
  })
}

export const fetchTimelineData = (
  engine: string,
  authToken: string,
  captureSessionId: number,
  viewType: TimelineDataType,
  sampleInterval: number = 0,
  init?: RequestInit
): Promise<ResponseGetTimelineData> => {
  let sampleQuery = ""
  if (sampleInterval !== 0) {
    sampleQuery = `?sampleInterval=${sampleInterval}`
  }
  return fetch(
    `${engine}${BASE_URL}/capture-sessions/${captureSessionId}/${viewType}/${sampleQuery}`,
    {
      method: "GET",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_JSON,
        "cache-control": CACHE_CONTROL,
      },
      ...init,
    }
  ).then(response => {
    return handleResponse<ResponseGetTimelineData>(response)
  })
}

export const fetchTimelineStatistics = (
  engine: string,
  authToken: string,
  captureSessionId: number,
  statisticsType: TimelineStatisticsType,
  startTime: number,
  endTime: number,
  init?: RequestInit
): Promise<ResponseGetTimelineStatistics> => {
  let timeQuery = ""
  if (startTime !== 0 && endTime !== 0) {
    const start = new Date(peekToDate(startTime)).toISOString()
    const end = new Date(peekToDate(endTime)).toISOString()
    timeQuery = `?start=${start}&end=${end}`
  }
  return fetch(
    `${engine}${BASE_URL}/capture-sessions/${captureSessionId}/${statisticsType}/${timeQuery}`,
    {
      method: "GET",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_JSON,
        "cache-control": CACHE_CONTROL,
      },
      ...init,
    }
  ).then(response => {
    return handleResponse<ResponseGetTimelineStatistics>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Capture Templates
//////////////////////////////////////////////////////////////////////////

export const createCaptureTemplate = (
  engine: string,
  authToken: string,
  captureTemplate: CaptureTemplate,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/capture-templates/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(captureTemplate),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const deleteCaptureTemplate = (
  engine: string,
  authToken: string,
  templateId: string,
  init?: RequestInit
): Promise<Results> => {
  return fetch(`${engine}${BASE_URL}/capture-templates/${templateId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<Results>(response)
  })
}

export const fetchCaptureTemplates = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetCaptureTemplates> => {
  return fetch(`${engine}${BASE_URL}/capture-templates/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetCaptureTemplates>(response)
  })
}

export const modifyCaptureTemplate = (
  engine: string,
  authToken: string,
  templateId: string,
  captureOptions: CaptureTemplateProperties,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/capture-templates/${templateId}/`, {
    method: "PUT",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(captureOptions),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Captures
//////////////////////////////////////////////////////////////////////////

export const createCapture = (
  engine: string,
  authToken: string,
  captureOptions: CaptureTemplateProperties,
  init?: RequestInit
): Promise<ResponseCreateCapture> => {
  return fetch(`${engine}${BASE_URL}/captures/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(captureOptions),
    ...init,
  }).then(response => {
    return handleResponse<ResponseCreateCapture>(response)
  })
}

export const deleteAllCaptures = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<Results> => {
  return fetch(`${engine}${BASE_URL}/captures/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<Results>(response)
  })
}

export const deleteCapture = (
  engine: string,
  authToken: string,
  captureId: string,
  init?: RequestInit
): Promise<Results> => {
  return fetch(`${engine}${BASE_URL}/captures/${captureId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<Results>(response)
  })
}

export const fetchCapture = (
  engine: string,
  authToken: string,
  captureId: string,
  init?: RequestInit
): Promise<CaptureProperties> => {
  return fetch(`${engine}${BASE_URL}/captures/${captureId}`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<CaptureProperties>(response)
  })
}

export const fetchCaptures = (
  engine: string,
  authToken: string,
  templates: boolean | null = null,
  init?: RequestInit
): Promise<ResponseGetCaptureList> => {
  const queryParams = []
  if (templates) {
    queryParams.push(`templates=${templates}`)
  }
  const query = queryParams ? `?${queryParams.join("&")}` : ""
  return fetch(`${engine}${BASE_URL}/captures/${query}`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetCaptureList>(response)
  })
}

export const fetchCaptureOptions = (
  engine: string,
  authToken: string,
  captureId: string,
  init?: RequestInit
): Promise<CaptureTemplateProperties> => {
  return fetch(`${engine}${BASE_URL}/captures/${captureId}/options/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<CaptureTemplateProperties>(response)
  })
}

export const setCaptureOptions = (
  engine: string,
  authToken: string,
  captureId: string,
  captureOptions: CaptureTemplateProperties,
  restart: boolean | null = null,
  init?: RequestInit
): Promise<void> => {
  const queryParams = []
  if (restart !== null) {
    queryParams.push(`restart=${restart}`)
  }
  const query = queryParams ? `?${queryParams.join("&")}` : ""
  return fetch(`${engine}${BASE_URL}/captures/${captureId}/options/${query}`, {
    method: "PUT",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(captureOptions),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const startCapture = (
  engine: string,
  authToken: string,
  captureId: string,
  init?: RequestInit
): Promise<Results> => {
  return fetch(`${engine}${BASE_URL}/running-captures/${captureId}/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<Results>(response)
  })
}

export const stopCapture = (
  engine: string,
  authToken: string,
  captureId: string,
  init?: RequestInit
): Promise<Results> => {
  return fetch(`${engine}${BASE_URL}/running-captures/${captureId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<Results>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Captures or Forensic Searches
//////////////////////////////////////////////////////////////////////////

export const deleteCFS = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  init?: RequestInit
): Promise<Results> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<Results>(response)
  })
}

export const deleteCFSFlowVisualizer = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  flowId: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/flow-visualizer/${flowId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const deleteCFSGraphs = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  templates: GraphTemplates,
  init?: RequestInit
): Promise<Results> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/graphs/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(templates),
    ...init,
  }).then(response => {
    return handleResponse<Results>(response)
  })
}

export const expertExecuteCFS = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  query: RequestExpertExecute,
  init?: RequestInit
): Promise<ExpertExecuteResponse> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/expert-execute/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(query),
    ...init,
  }).then(response => {
    return handleResponse<ExpertExecuteResponse>(response)
  })
}

export const expertQueryCFS = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  query: RequestExpertQuery,
  init?: RequestInit
): Promise<ExpertQueryResponse> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/expert-query/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(query),
    ...init,
  }).then(response => {
    return handleResponse<ExpertQueryResponse>(response)
  })
}

export const fetchCFSFlowVisualizer = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  flowId: string,
  init?: RequestInit
): Promise<ResponseGetFlowVisualizer> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/flow-visualizer/${flowId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetFlowVisualizer>(response)
  })
}

export const fetchCFSGraph = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  graphId: string,
  startTime: string,
  numberSamples: number,
  init?: RequestInit
): Promise<ResponseGetGraphDataById> => {
  return fetch(
    `${engine}${BASE_URL}/${type}/${id}/graphs/${graphId}/?startTime=${startTime}&numberSamples=${numberSamples}`,
    {
      method: "GET",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_JSON,
        "cache-control": CACHE_CONTROL,
      },
      ...init,
    }
  ).then(response => {
    return handleResponse<ResponseGetGraphDataById>(response)
  })
}

export const fetchCFSGraphs = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  init?: RequestInit
): Promise<GraphCollection> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/graphs/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<GraphCollection>(response)
  })
}

export const fetchCFSPacketData = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  packetNumber: number,
  init?: RequestInit
): Promise<ResponseGetPacket> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/packets/${packetNumber}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetPacket>(response)
  })
}

export const fetchCFSPacketDecode = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  packetNumber: number,
  showOffsets: boolean = false,
  codePage: number = 0,
  init?: RequestInit
): Promise<any> => {
  const queryParams = []
  if (showOffsets) {
    queryParams.push("showOffsets=true")
  }
  if (codePage !== 0) {
    queryParams.push(`codePage=${codePage}`)
  }
  const query = queryParams ? `?${queryParams.join("&")}` : ""
  return fetch(`${engine}${BASE_URL}/${type}/${id}/packets/${packetNumber}/${query}`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: "application/vnd+omni.decode",
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleJSONResponse(response)
  })
}

export type PacketDecodeTextFormat =
  | "text/html"
  | "text/plain"
  | "application/rtf"
  | "application/vnd+omni.decode+tags"

export const fetchCFSPacketDecodeText = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  packetNumber: number,
  showOffsets: boolean = false,
  codePage: number = 0,
  format: PacketDecodeTextFormat,
  init?: RequestInit
): Promise<string> => {
  const queryParams = []
  if (showOffsets) {
    queryParams.push("showOffsets=true")
  }
  if (codePage !== 0) {
    queryParams.push(`codePage=${codePage}`)
  }
  const query = queryParams ? `?${queryParams.join("&")}` : ""
  return fetch(`${engine}${BASE_URL}/${type}/${id}/packets/${packetNumber}/${query}`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: format,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleTextResponse<string>(response)
  })
}

export const fetchCFSPacketList = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  firstPacketNumber: number,
  packetCount: number,
  columns: string[] | null = null,
  decodeColumns: string[] | null = null,
  showLogical: boolean | null = null,
  showAddressNames: boolean | null = null,
  showPortNames: boolean | null = null,
  init?: RequestInit
): Promise<ResponseGetPacketList> => {
  const queryParams = []
  if (firstPacketNumber !== null) {
    queryParams.push(`firstPacketNumber=${firstPacketNumber}`)
  }
  if (packetCount !== null) {
    queryParams.push(`packetCount=${packetCount}`)
  }
  if (Array.isArray(columns)) {
    columns.forEach(col => {
      queryParams.push(`columns=${col}`)
    })
  }
  if (Array.isArray(decodeColumns)) {
    decodeColumns.forEach(dcol => {
      queryParams.push(`decodeColumns=${dcol}`)
    })
  }
  if (showLogical !== null) {
    queryParams.push(`showLogical=${showLogical}`)
  }
  if (showAddressNames !== null) {
    queryParams.push(`showAddressNames=${showAddressNames}`)
  }
  if (showPortNames !== null) {
    queryParams.push(`showPortNames=${showPortNames}`)
  }
  const query = queryParams ? `?${queryParams.join("&")}` : ""
  return fetch(`${engine}${BASE_URL}/${type}/${id}/packet-list/${query}`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetPacketList>(response)
  })
}

export const fetchCFSProperties = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  init?: RequestInit
): Promise<CaptureProperties | ForensicSearchProperties> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<CaptureProperties | ForensicSearchProperties>(response)
  })
}

export const fetchCFSStatistics = <T>(
  engine: string,
  authToken: string,
  type: string,
  id: string,
  statistic: RequestGetStatisticsStatistic,
  init?: RequestInit
): Promise<T> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/${statistic}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<T>(response)
  })
}

export const fetchReconstructionsRaw = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  fileId: string,
  init?: RequestInit
) => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/reconstructions/${fileId}`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => response.blob())
}

export const fetchReconstructions = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  fileId: string,
  overrideContentType: string,
  init?: RequestInit
) => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/reconstructions/${fileId}`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleReconstructions(response, overrideContentType)
  })
}

export const handleReconstructions = (response: Response, overrideContentType: string) => {
  if (response.body != null) {
    const contentType =
      overrideContentType !== "" ? overrideContentType : response.headers.get("content-type")
    if (contentType?.includes("image")) {
      return handleReconstructedImage(response.body)
    } else if (
      contentType?.includes("text") ||
      contentType?.includes("javascript") ||
      contentType?.includes("multipart")
    ) {
      return response.text().then((text: any) => text)
    } else if (contentType?.includes("json")) {
      return response.text().then((json: any) => {
        try {
          return JSON.stringify(JSON.parse(json), null, 2)
        } catch {
          return json
        }
      })
    }
    return response.arrayBuffer().then(array => new Uint8Array(array))
  } else {
    return Promise.reject({
      code: response.status,
      reason: response.statusText,
      message: "",
    })
  }
}

const handleReconstructedImage = (body: ReadableStream<Uint8Array>) => {
  const reader = body.getReader()

  const stream = new ReadableStream({
    start(controller) {
      return pump()

      function pump(): any {
        return reader.read().then(({ done, value }) => {
          if (done) {
            controller.close()
            return
          }

          controller.enqueue(value)
          return pump()
        })
      }
    },
  })
  const streamResponse = new Response(stream)
  return streamResponse
    .blob()
    .then(blob => URL.createObjectURL(blob))
    .then(url => url)
}

export const fetchReconstructionsList = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  queryParams: any[] | null,
  init?: RequestInit
): Promise<ResponseGetReconstructionsList> => {
  const query = queryParams ? `?${queryParams.join("&")}` : ""
  return fetch(`${engine}${BASE_URL}/${type}/${id}/reconstructions/${query}`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetReconstructionsList>(response)
  })
}

export const postCFSFlowVisualizer = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  flowId: string,
  request?: RequestPostFlowVisualizer,
  init?: RequestInit
): Promise<any> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/flow-visualizer/${flowId}/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: request != null ? JSON.stringify(request) : undefined,
    ...init,
  }).then(response => {
    return handleResponse(response)
  })
}

export const postCFSStatsQuery = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  request: RequestPostStatisticsQuery,
  init?: RequestInit
): Promise<ResponsePostStatisticsQuery> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/stats-query/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(request),
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostStatisticsQuery>(response)
  })
}

export const putCFSGraphs = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  templates: GraphTemplates,
  init?: RequestInit
): Promise<Results> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/graphs/`, {
    method: "PUT",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(templates),
    ...init,
  }).then(response => {
    return handleResponse<Results>(response)
  })
}

export const saveCFSPackets = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  fileName: string,
  sync: boolean,
  init?: RequestInit
): Promise<ResponsePostSaveCapture> => {
  return fetch(
    `${engine}${BASE_URL}/${type}/${id}/save-capture/?fileName=${fileName}&sync=${sync}`,
    {
      method: "POST",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_JSON,
      },
      ...init,
    }
  ).then(response => {
    return handleResponse<ResponsePostSaveCapture>(response)
  })
}

export const saveCFSSelectedPackets = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  fileName: string,
  sync: boolean,
  packets: number[][],
  init?: RequestInit
): Promise<ResponsePostSaveSelectedPackets> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/save-selected-packets/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_X_WWW_FORM_URLENCODED,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: `fileName=${fileName}&sync=${sync}&packets=${JSON.stringify(packets)}`,
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostSaveSelectedPackets>(response)
  })
}

export const postSaveCaptureTask = (
  engine: string,
  authToken: string,
  id: string,
  fileName: string,
  sync: boolean,
  packets?: number[][],
  init?: RequestInit
): Promise<ResponsePostSaveCaptureTask> => {
  let body = `id=${id}&fileName=${fileName}&sync=${sync}`
  if (packets !== undefined) {
    body += `&packets=${JSON.stringify(packets)}`
  }
  return fetch(`${engine}${BASE_URL}/save-capture-tasks/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_X_WWW_FORM_URLENCODED,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body,
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostSaveCaptureTask>(response)
  })
}

export const fetchSaveCaptureTask = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<ResponseGetSaveCaptureTask> => {
  return fetch(`${engine}${BASE_URL}/save-capture-tasks/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetSaveCaptureTask>(response)
  })
}

export const deleteSaveCaptureTask = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/save-capture-tasks/${taskId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    // Allow the request to work when the page is unloading.
    keepalive: true,
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const sendCFSPluginMessage = (
  engine: string,
  authToken: string,
  type: string,
  id: string,
  pluginId: string,
  pluginMessage: RequestPostPluginsMessage,
  init?: RequestInit
): Promise<ResponsePostPluginsMessage> => {
  return fetch(`${engine}${BASE_URL}/${type}/${id}/plugins/${pluginId}/message/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(pluginMessage),
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostPluginsMessage>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Countries
//////////////////////////////////////////////////////////////////////////

export const fetchCountries = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetCountries> => {
  return fetch(`${engine}${BASE_URL}/countries/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetCountries>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Database
//////////////////////////////////////////////////////////////////////////

export const indexDatabase = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/database-index/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const maintainDatabase = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<string> => {
  return fetch(`${engine}${BASE_URL}/database-maintenance/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_TEXT,
    },
    ...init,
  }).then(response => {
    return handleResponse<string>(response)
  })
}

export const synchronizeDatabase = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/database-sync/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Decryption
//////////////////////////////////////////////////////////////////////////

export const deleteDecryptionKeys = (
  engine: string,
  authToken: string,
  ids: string[],
  init?: RequestInit
): Promise<ResponseDeleteDecryptionKeys> => {
  if (ids.length === 1) {
    // For a single id, just include it as a query param.
    return fetch(`${engine}${BASE_URL}/decryption-keys/?ids=${ids[0]}`, {
      method: "DELETE",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_JSON,
      },
      ...init,
    }).then(response => {
      return handleResponse<ResponseDeleteDecryptionKeys>(response)
    })
  } else {
    // For multiple ids, send the list form-encoded in the body.
    // Sending a body with DELETE is maybe not a great idea but this
    // is an easy way to batch the request. Some clients might not even
    // be able to send a body with a DELETE request. The alternative is
    // to make multiple requests.
    let body = ""
    for (let i = 0; i < ids.length; i++) {
      body += "ids="
      body += encodeURIComponent(ids[i])
      if (i + 1 < ids.length) {
        body += "&"
      }
    }
    return fetch(`${engine}${BASE_URL}/decryption-keys/`, {
      method: "DELETE",
      headers: {
        authorization: `Token ${authToken}`,
        "content-type": MimeType.MIME_TYPE_X_WWW_FORM_URLENCODED,
        accept: MimeType.MIME_TYPE_JSON,
      },
      body,
      ...init,
    }).then(response => {
      return handleResponse<ResponseDeleteDecryptionKeys>(response)
    })
  }
}

export const fetchDecryptionKeys = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetDecryptionKeys> => {
  return fetch(`${engine}${BASE_URL}/decryption-keys/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetDecryptionKeys>(response)
  })
}

export const setDecryptionKeyName = (
  engine: string,
  authToken: string,
  decryptionKeyId: string,
  name: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/decryption-keys/name/${decryptionKeyId}/`, {
    method: "PUT",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify({
      name,
    }),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const setDecryptionKeys = (
  engine: string,
  authToken: string,
  keys: DecryptionKeys,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/decryption-keys/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(keys),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Engine
//////////////////////////////////////////////////////////////////////////

export const createDirectory = (
  engine: string,
  authToken: string,
  path: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/create-directory/?path=${path}`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const createFile = (
  engine: string,
  authToken: string,
  path: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/create-file/?path=${path}`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const deleteAuditLog = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/audit-log/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const fetchAuditLog = (
  engine: string,
  authToken: string,
  client: string | null,
  limit: number | null,
  offset: number | null,
  search: string | null,
  startTime: string | null,
  stopTime: string | null,
  user: string | null,
  init?: RequestInit
): Promise<ResponseGetAuditLog> => {
  const queryParams = []
  if (client) {
    queryParams.push(`client=${client}`)
  }
  if (limit !== null) {
    queryParams.push(`limit=${limit}`)
  }
  if (offset !== null) {
    queryParams.push(`offset=${offset}`)
  }
  if (search) {
    queryParams.push(`search=${search}`)
  }
  if (startTime && stopTime) {
    queryParams.push(`startTime=${startTime}`)
    queryParams.push(`stopTime=${stopTime}`)
  }
  if (user) {
    queryParams.push(`user=${user}`)
  }
  const query = queryParams ? `?${queryParams.join("&")}` : ""
  return fetch(`${engine}${BASE_URL}/audit-log/${query}`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetAuditLog>(response)
  })
}

export const fetchCapabilities = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<Response> => {
  return fetch(`${engine}${BASE_URL}/capabilities/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  })
}

export const fetchEngineCapabilities = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetEngineCapabilities> => {
  return fetch(`${engine}${BASE_URL}/capabilities/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetEngineCapabilities>(response)
  })
}

export const fetchConnectedUsers = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetConnectedUsers> => {
  return fetch(`${engine}${BASE_URL}/connected-users/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetConnectedUsers>(response)
  })
}

export const fetchSettings = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<EngineSettings> => {
  return fetch(`${engine}${BASE_URL}/settings/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<EngineSettings>(response)
  })
}

export const fetchDirectoryList = (
  engine: string,
  authToken: string,
  path: string,
  showFiles: boolean,
  showHiddenFiles: boolean,
  init?: RequestInit
): Promise<ResponseGetDirectoryList> => {
  return fetch(
    `${engine}${BASE_URL}/directory-list/?path=${path}&showFiles=${showFiles}&showHiddenFiles=${showHiddenFiles}`,
    {
      method: "GET",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_JSON,
        "cache-control": CACHE_CONTROL,
      },
      ...init,
    }
  ).then(response => {
    return handleResponse<ResponseGetDirectoryList>(response)
  })
}

export const fetchDMSConfiguration = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<DMSConfiguration> => {
  return fetch(`${engine}${BASE_URL}/dms-config/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<DMSConfiguration>(response)
  })
}

export const fetchExpertPreferences = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ExpertPreferencesGet> => {
  return fetch(`${engine}${BASE_URL}/expert-prefs/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ExpertPreferencesGet>(response)
  })
}

export const fetchStatus = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<Response> => {
  return fetch(`${engine}${BASE_URL}/status/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  })
}

export const fetchSupport = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<string> => {
  return fetch(`${engine}${BASE_URL}/support/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_TEXT,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<string>(response)
  })
}

export const fetchSync = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetEngineSync> => {
  return fetch(`${engine}${BASE_URL}/sync/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetEngineSync>(response)
  })
}

export const fetchUserList = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetUserList> => {
  return fetch(`${engine}${BASE_URL}/user-list/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetUserList>(response)
  })
}

export const fetchVersion = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetVersion> => {
  return fetch(`${engine}${BASE_URL}/version/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetVersion>(response)
  })
}

export const performDiagnostics = (
  engine: string,
  authToken: string,
  command: DiagnosticCommand,
  init?: RequestInit
): Promise<string> => {
  return fetch(`${engine}${BASE_URL}/diagnostics/${command}/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_TEXT,
    },
    ...init,
  }).then(response => {
    return handleResponse<string>(response)
  })
}

export const postDMSConfiguration = (
  engine: string,
  authToken: string,
  config: DMSConfiguration,
  init?: RequestInit
): Promise<Result> => {
  return fetch(`${engine}${BASE_URL}/dms-config/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(config),
    ...init,
  }).then(response => {
    return handleResponse<Result>(response)
  })
}

export const postExpertPreferences = (
  engine: string,
  authToken: string,
  prefs: ExpertPreferencesSet,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/expert-prefs/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(prefs),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const postSettings = (
  engine: string,
  authToken: string,
  settings: EngineSettings,
  init?: RequestInit
): Promise<Result> => {
  return fetch(`${engine}${BASE_URL}/settings/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(settings),
    ...init,
  }).then(response => {
    return handleResponse<Result>(response)
  })
}

export const postSync = (
  engine: string,
  authToken: string,
  syncSettings: RequestPostEngineSync,
  init?: RequestInit
): Promise<EngineSyncResults> => {
  return fetch(`${engine}${BASE_URL}/sync/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(syncSettings),
    ...init,
  }).then(response => {
    return handleResponse<EngineSyncResults>(response)
  })
}

export const postTestActiveDirectory = (
  engine: string,
  authToken: string,
  activeDirectory: RequestTestActiveDirectory,
  init?: RequestInit
): Promise<ResponseTestActiveDirectory> => {
  return fetch(`${engine}${BASE_URL}/test-active-directory/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(activeDirectory),
    ...init,
  }).then(response => {
    return handleResponse<ResponseTestActiveDirectory>(response)
  })
}

export const restartDMS = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/dms-restart/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const restartEngine = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/restart/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Events
//////////////////////////////////////////////////////////////////////////

export const deleteEvents = (
  engine: string,
  authToken: string,
  compact: boolean | null = null,
  contextId: string | null = null,
  init?: RequestInit
): Promise<ResponseDeleteEvents> => {
  const queryParams = []
  if (compact !== null) {
    queryParams.push(`compact=${compact}`)
  }
  if (contextId) {
    queryParams.push(`contextId=${contextId}`)
  }
  const query = queryParams ? `?${queryParams.join("&")}` : ""
  return fetch(`${engine}${BASE_URL}/events/${query}`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseDeleteEvents>(response)
  })
}

export const fetchEvents = (
  engine: string,
  authToken: string,
  contextId: string | null = null,
  informational: boolean | null = null,
  limit: number | null = null,
  major: boolean | null = null,
  minor: boolean | null = null,
  messages: boolean | null = null,
  offset: number | null = null,
  search: string | null = null,
  severe: boolean | null = null,
  sourceId: string | null = null,
  sourceKey: number | null = null,
  startTime: string | null = null,
  stopTime: string | null = null,
  init?: RequestInit
): Promise<ResponseGetEvents> => {
  const queryParams = []
  if (contextId) {
    queryParams.push(`contextId={${contextId}}`)
  }
  if (informational !== null) {
    queryParams.push(`informational=${informational}`)
  }
  if (limit !== null) {
    queryParams.push(`limit=${limit}`)
  }
  if (major !== null) {
    queryParams.push(`major=${major}`)
  }
  if (minor !== null) {
    queryParams.push(`minor=${minor}`)
  }
  if (messages !== null) {
    queryParams.push(`messages=${messages}`)
  }
  if (offset !== null) {
    queryParams.push(`offset=${offset}`)
  }
  if (search) {
    queryParams.push(`search=${search}`)
  }
  if (severe !== null) {
    queryParams.push(`severe=${severe}`)
  }
  if (sourceId) {
    queryParams.push(`sourceId=${sourceId}`)
  }
  if (sourceKey !== null) {
    queryParams.push(`sourceKey=${sourceKey}`)
  }
  if (startTime) {
    queryParams.push(`startTime=${startTime}`)
  }
  if (stopTime) {
    queryParams.push(`stopTime=${stopTime}`)
  }
  const query = queryParams ? `?${queryParams.join("&")}` : ""
  return fetch(`${engine}${BASE_URL}/events/${query}`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetEvents>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Files
//////////////////////////////////////////////////////////////////////////

export const deleteFiles = (
  engine: string,
  authToken: string,
  files: string[],
  init?: RequestInit
): Promise<ResponseDeleteFiles> => {
  if (files.length === 1) {
    // For a single file, just include it as a query param.
    return fetch(`${engine}${BASE_URL}/files/?files=${files[0]}`, {
      method: "DELETE",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_JSON,
      },
    }).then(response => {
      return handleResponse<ResponseDeleteFiles>(response)
    })
  } else {
    // For multiple files, send the list form-encoded in the body.
    // Sending a body with DELETE is maybe not a great idea but this
    // is an easy way to batch the request. Some clients might not even
    // be able to send a body with a DELETE request. The alternative is
    // to make multiple requests.
    let body = ""
    for (let i = 0; i < files.length; i++) {
      body += "files="
      body += encodeURIComponent(files[i])
      if (i + 1 < files.length) {
        body += "&"
      }
    }
    return fetch(`${engine}${BASE_URL}/files/`, {
      method: "DELETE",
      headers: {
        authorization: `Token ${authToken}`,
        "content-type": MimeType.MIME_TYPE_X_WWW_FORM_URLENCODED,
        accept: MimeType.MIME_TYPE_JSON,
      },
      body,
      ...init,
    }).then(response => {
      return handleResponse<ResponseDeleteFiles>(response)
    })
  }
}

export const fetchFile = (
  engine: string,
  authToken: string,
  file: string,
  init?: RequestInit
): Promise<ArrayBuffer> => {
  return fetch(`${engine}${BASE_URL}/files/?file=${file}`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_OCTECT_STREAM,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ArrayBuffer>(response)
  })
}

export const fetchFilesList = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetFileList> => {
  return fetch(`${engine}${BASE_URL}/files-list/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetFileList>(response)
  })
}

export const getFileDownloadURL = (
  engine: string,
  authToken: string,
  file: string,
  fileName: string,
  deleteAfterDownload: boolean = false
): string => {
  return `${engine}${BASE_URL}/files/?authToken=${authToken}&file=${file}&fileName=${fileName}&delete=${deleteAfterDownload}`
}

export const getFileUploadURL = (engine: string) => `${engine}${BASE_URL}/files/`

export const putFile = (
  engine: string,
  authToken: string,
  file: string,
  content: ArrayBuffer,
  init?: RequestInit
): Promise<ArrayBuffer> => {
  return fetch(`${engine}${BASE_URL}/files/?file=${file}`, {
    method: "PUT",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_OCTECT_STREAM,
      accept: MimeType.MIME_TYPE_OCTECT_STREAM,
    },
    body: content,
    ...init,
  }).then(response => {
    return handleResponse<ArrayBuffer>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Filters
//////////////////////////////////////////////////////////////////////////

export const deleteAllFilters = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/filters/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const deleteFilter = (
  engine: string,
  authToken: string,
  filterId: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/filters/${filterId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const fetchFilter = (
  engine: string,
  authToken: string,
  filterId: string,
  init?: RequestInit
): Promise<FilterCollection> => {
  return fetch(`${engine}${BASE_URL}/filters/${filterId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<FilterCollection>(response)
  })
}

export const fetchFilters = (
  engine: string,
  authToken: string,
  ids: string[] = [],
  init?: RequestInit
): Promise<FilterCollection> => {
  if (ids.length > 0) {
    return fetch(`${engine}${BASE_URL}/filters/?ids=${ids}`, {
      method: "GET",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_JSON,
        "cache-control": CACHE_CONTROL,
      },
      ...init,
    }).then(response => {
      return handleResponse<FilterCollection>(response)
    })
  } else {
    return fetch(`${engine}${BASE_URL}/filters/`, {
      method: "GET",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_JSON,
        "cache-control": CACHE_CONTROL,
      },
      ...init,
    }).then(response => {
      return handleResponse<FilterCollection>(response)
    })
  }
}

export const fetchFiltersXML = (
  engine: string,
  authToken: string,
  ids: string[] = [],
  init?: RequestInit
): Promise<string> => {
  if (ids.length > 0) {
    return fetch(`${engine}${BASE_URL}/filters/?ids=${ids}`, {
      method: "GET",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_XML,
        "cache-control": CACHE_CONTROL,
      },
      ...init,
    }).then(response => {
      return handleResponse<string>(response)
    })
  } else {
    return fetch(`${engine}${BASE_URL}/filters/`, {
      method: "GET",
      headers: {
        authorization: `Token ${authToken}`,
        accept: MimeType.MIME_TYPE_XML,
        "cache-control": CACHE_CONTROL,
      },
      ...init,
    }).then(response => {
      return handleResponse<string>(response)
    })
  }
}

export const postFilterConvert = (
  engine: string,
  authToken: string,
  filter: string,
  validateOnly: boolean | null = null,
  init?: RequestInit
): Promise<ResponseGetFilterConvert> => {
  let query = ""
  if (validateOnly !== null) {
    query = `?validateOnly=${validateOnly}`
  }
  return fetch(`${engine}${BASE_URL}/filter-convert/${query}`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_TEXT,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: filter,
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetFilterConvert>(response)
  })
}

export const postFiltersXML = (
  engine: string,
  authToken: string,
  xml: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/filters/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_XML,
    },
    body: xml,
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const setFilter = (
  engine: string,
  authToken: string,
  filter: Filter,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/filters/${filter.id}/`, {
    method: "PUT",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(filter),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const setFilters = (
  engine: string,
  authToken: string,
  filters: FilterCollection,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/filters/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(filters),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Forensic Searches
//////////////////////////////////////////////////////////////////////////

export const createForensicSearch = (
  engine: string,
  authToken: string,
  query: RequestCreateForensicSearch,
  init?: RequestInit
): Promise<ResponseCreateForensicSearch> => {
  return fetch(`${engine}${BASE_URL}/forensic-searches/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(query),
    ...init,
  }).then(response => {
    return handleResponse<ResponseCreateForensicSearch>(response)
  })
}

export const putForensicSearch = (
  engine: string,
  authToken: string,
  forensicSearchId: string,
  query: RequestPutForensicSearch,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/forensic-searches/${forensicSearchId}/`, {
    method: "PUT",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(query),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const deleteAllForensicSearches = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<Results> => {
  return fetch(`${engine}${BASE_URL}/forensic-searches/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<Results>(response)
  })
}

export const deleteForensicSearch = (
  engine: string,
  authToken: string,
  forensicSearchId: string,
  init?: RequestInit
): Promise<Results> => {
  return fetch(`${engine}${BASE_URL}/forensic-searches/${forensicSearchId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<Results>(response)
  })
}

export const fetchForensicSearches = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetForensicSearches> => {
  return fetch(`${engine}${BASE_URL}/forensic-searches/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetForensicSearches>(response)
  })
}

export const stopLoadForensicSearch = (
  engine: string,
  authToken: string,
  forensicSearchId: string,
  init?: RequestInit
): Promise<Results> => {
  return fetch(`${engine}${BASE_URL}/running-forensic-searches/${forensicSearchId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<Results>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Forensic Search Histories
//////////////////////////////////////////////////////////////////////////

export const deleteForensicSearchHistory = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/forensic-searches/history/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const deleteForensicSearchHistoryItem = (
  engine: string,
  authToken: string,
  forensicSearchHistoryItemId: number,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/forensic-searches/history/${forensicSearchHistoryItemId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const fetchForensicSearchHistory = (
  engine: string,
  authToken: string,
  limit: number | null = null,
  offset: number | null = null,
  sortAsc: boolean | null = null,
  sortBy: ForensicSearchHistorySortBy | null = null,
  favorite: boolean | null = null,
  name: string | null = null,
  owner: string | null = null,
  startTime: string | null = null,
  stopTime: string | null = null,
  init?: RequestInit
): Promise<ResponseGetForensicSearchHistory> => {
  const queryParams = []
  if (favorite !== null) {
    queryParams.push(`favorite=${favorite}`)
  }
  if (limit !== null) {
    queryParams.push(`limit=${limit}`)
  }
  if (name) {
    queryParams.push(`name=${name}`)
  }
  if (offset !== null) {
    queryParams.push(`offset=${offset}`)
  }
  if (owner) {
    queryParams.push(`owner=${owner}`)
  }
  if (sortAsc !== null) {
    queryParams.push(`sortAsc=${sortAsc}`)
  }
  if (sortBy !== null) {
    queryParams.push(`sortBy=${sortBy}`)
  }
  if (startTime && stopTime) {
    queryParams.push(`startTime=${startTime}`)
    queryParams.push(`stopTime=${stopTime}`)
  }
  const query = queryParams ? `?${queryParams.join("&")}` : ""
  return fetch(`${engine}${BASE_URL}/forensic-searches/history/${query}`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetForensicSearchHistory>(response)
  })
}

export const fetchForensicSearchHistoryItem = (
  engine: string,
  authToken: string,
  forensicSearchHistoryItemId: number,
  init?: RequestInit
): Promise<ForensicSearchHistoryItemProperties> => {
  return fetch(`${engine}${BASE_URL}/forensic-searches/history/${forensicSearchHistoryItemId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ForensicSearchHistoryItemProperties>(response)
  })
}

export const setForensicSearchHistoryItem = (
  engine: string,
  authToken: string,
  forensicSearchHistoryItemId: number,
  forensicSearchHistoryItemChanges: RequestPostForensicSearchHistoryItem,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/forensic-searches/history/${forensicSearchHistoryItemId}/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    body: JSON.stringify(forensicSearchHistoryItemChanges),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Graphs
//////////////////////////////////////////////////////////////////////////

export const deleteGraph = (
  engine: string,
  authToken: string,
  graphId: string,
  init?: RequestInit
): Promise<Results> => {
  return fetch(`${engine}${BASE_URL}/graphs/${graphId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<Results>(response)
  })
}

export const fetchGraph = (
  engine: string,
  authToken: string,
  graphId: string,
  init?: RequestInit
): Promise<GraphCollection> => {
  return fetch(`${engine}${BASE_URL}/graphs/${graphId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<GraphCollection>(response)
  })
}

export const fetchGraphs = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<GraphCollection> => {
  return fetch(`${engine}${BASE_URL}/graphs/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<GraphCollection>(response)
  })
}

export const fetchGraphsXML = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<string> => {
  return fetch(`${engine}${BASE_URL}/graphs/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_XML,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<string>(response)
  })
}

export const fetchGraphStats = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetGraphStats> => {
  return fetch(`${engine}${BASE_URL}/graphs/stats/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetGraphStats>(response)
  })
}

export const setGraph = (
  engine: string,
  authToken: string,
  graph: GraphObject,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/graphs/${graph.templateId}/`, {
    method: "PUT",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(graph),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const setGraphs = (
  engine: string,
  authToken: string,
  graphs: GraphCollection,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/graphs/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(graphs),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// License
//////////////////////////////////////////////////////////////////////////

export const fetchLicenseInfo = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<LicenseGetInfo> => {
  return fetch(`${engine}${BASE_URL}/license/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<LicenseGetInfo>(response)
  })
}

export const fetchLicenseSettings = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<LicenseSettings> => {
  return fetch(`${engine}${BASE_URL}/license/settings/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<LicenseSettings>(response)
  })
}

export const fetchLockingCode = (
  engine: string,
  authToken: string,
  flags: number,
  init?: RequestInit
): Promise<ResponseGetLockingCode> => {
  return fetch(`${engine}${BASE_URL}/license/locking-code/${flags}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetLockingCode>(response)
  })
}

export const postLicense = (
  engine: string,
  authToken: string,
  licenseInfo: LicenseSetInfo,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/license/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(licenseInfo),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const postLicenseSettings = (
  engine: string,
  authToken: string,
  licenseSettings: LicenseSettings,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/license/settings/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(licenseSettings),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// LiveFlow
//////////////////////////////////////////////////////////////////////////

export const fetchLiveFlowConfiguration = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<LiveFlowConfiguration> => {
  return fetch(`${engine}${BASE_URL}/liveflow/configuration/`, {
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<LiveFlowConfiguration>(response)
  })
}

export const postLiveFlowConfiguration = (
  engine: string,
  authToken: string,
  liveFlowOptions: LiveFlowConfiguration,
  init?: RequestInit
): Promise<LiveFlowConfigurationResult> => {
  return fetch(`${engine}${BASE_URL}/liveflow/configuration/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(liveFlowOptions),
    ...init,
  }).then(response => {
    return handleResponse<LiveFlowConfigurationResult>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Names
//////////////////////////////////////////////////////////////////////////

export const deleteNames = (
  engine: string,
  authToken: string,
  names: NameTableEntry[],
  init?: RequestInit
): Promise<void> => {
  return postNames(engine, authToken, names, MergeOptions.Delete, init)
}

export const fetchNames = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<NameTable> => {
  return fetch(`${engine}${BASE_URL}/names/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<NameTable>(response)
  })
}

export const fetchNamesXML = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<string> => {
  return fetch(`${engine}${BASE_URL}/names/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_XML,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<string>(response)
  })
}

export const postNames = (
  engine: string,
  authToken: string,
  names: NameTableEntry[],
  mergeOptions?: MergeOptions,
  init?: RequestInit
): Promise<void> => {
  const request: PostNames = {
    mergeOptions,
    nameTable: {
      names,
    },
  }
  return fetch(`${engine}${BASE_URL}/names/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(request),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const postNamesXML = (
  engine: string,
  authToken: string,
  xml: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/names/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_XML,
    },
    body: xml,
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const resolveAddresses = (
  engine: string,
  authToken: string,
  entries: AddressResolverRequestEntry[],
  mergeOptions: MergeOptions = MergeOptions.Default,
  init?: RequestInit
): Promise<AddressResolverResponse> => {
  const request: AddressResolverRequest = {
    entries,
    mergeOptions,
  }
  return fetch(`${engine}${BASE_URL}/address-resolver/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(request),
    ...init,
  }).then(response => {
    return handleResponse<AddressResolverResponse>(response)
  })
}

export const resolveNames = (
  engine: string,
  authToken: string,
  entries: NameResolverRequestEntry[],
  mergeOptions: MergeOptions = MergeOptions.Default,
  init?: RequestInit
): Promise<NameResolverResponse> => {
  const request: NameResolverRequest = {
    entries,
    mergeOptions,
  }
  return fetch(`${engine}${BASE_URL}/name-resolver/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(request),
    ...init,
  }).then(response => {
    return handleResponse<NameResolverResponse>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Notifications
//////////////////////////////////////////////////////////////////////////

export const deleteNotification = (
  engine: string,
  authToken: string,
  notificationId: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/notifications/${notificationId}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const fetchNotifications = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<Notifications> => {
  return fetch(`${engine}${BASE_URL}/notifications/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<Notifications>(response)
  })
}

export const sendEmail = (
  engine: string,
  authToken: string,
  request: RequestPostSendEmail,
  init?: RequestInit
): Promise<ResponsePostSendEmail> => {
  return fetch(`${engine}${BASE_URL}/send/email/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(request),
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostSendEmail>(response)
  })
}

export const sendNotifications = (
  engine: string,
  authToken: string,
  request: RequestPostSendNotifications,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/send/notifications/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(request),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const setNotification = (
  engine: string,
  authToken: string,
  notification: NotificationAction,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/notifications/${notification.id}/`, {
    method: "PUT",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(notification),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Plugins
//////////////////////////////////////////////////////////////////////////

export const sendGlobalPluginMessage = (
  engine: string,
  authToken: string,
  pluginId: string,
  pluginMessage: RequestPostPluginsMessage,
  init?: RequestInit
): Promise<ResponsePostPluginsMessage> => {
  return fetch(`${engine}${BASE_URL}/plugins/${pluginId}/message/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(pluginMessage),
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostPluginsMessage>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Protocols
//////////////////////////////////////////////////////////////////////////

export const fetchProtocols = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetProtocols> => {
  return fetch(`${engine}${BASE_URL}/protocols/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetProtocols>(response)
  })
}

export const fetchProtocolTranslations = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ProtocolTranslations> => {
  return fetch(`${engine}${BASE_URL}/protocol-translations/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ProtocolTranslations>(response)
  })
}

export const postProtocolTranslations = (
  engine: string,
  authToken: string,
  translations: ProtocolTranslations,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/protocol-translations/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(translations),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Selection
//////////////////////////////////////////////////////////////////////////

export const fetchSelectRelatedExpertCancel = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/select-related-expert/cancel/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const fetchSelectRelatedExpertProgress = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<ProgressSelectRelatedResponse> => {
  return fetch(`${engine}${BASE_URL}/select-related-expert/progress/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ProgressSelectRelatedResponse>(response)
  })
}

export const fetchSelectRelatedExpertResults = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<ResultsSelectRelatedResponse> => {
  return fetch(`${engine}${BASE_URL}/select-related-expert/results/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResultsSelectRelatedResponse>(response)
  })
}

export const fetchSelectRelatedFilterCancel = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/select-related-filter/cancel/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const fetchSelectRelatedFilterProgress = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<ProgressSelectRelatedResponse> => {
  return fetch(`${engine}${BASE_URL}/select-related-filter/progress/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ProgressSelectRelatedResponse>(response)
  })
}

export const fetchSelectRelatedFilterResults = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<ResultsSelectRelatedResponse> => {
  return fetch(`${engine}${BASE_URL}/select-related-filter/results/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResultsSelectRelatedResponse>(response)
  })
}

export const fetchSelectRelatedFilterConfigCancel = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/select-related-filter-config/cancel/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const fetchSelectRelatedFilterConfigProgress = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<ProgressSelectRelatedResponse> => {
  return fetch(`${engine}${BASE_URL}/select-related-filter-config/progress/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ProgressSelectRelatedResponse>(response)
  })
}

export const fetchSelectRelatedFilterConfigResults = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<ResultsSelectRelatedResponse> => {
  return fetch(`${engine}${BASE_URL}/select-related-filter-config/results/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResultsSelectRelatedResponse>(response)
  })
}

export const fetchSelectRelatedCancel = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/select-related/cancel/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const fetchSelectRelatedProgress = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<ProgressSelectRelatedResponse> => {
  return fetch(`${engine}${BASE_URL}/select-related/progress/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ProgressSelectRelatedResponse>(response)
  })
}

export const fetchSelectRelatedResults = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<ResultsSelectRelatedResponse> => {
  return fetch(`${engine}${BASE_URL}/select-related/results/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResultsSelectRelatedResponse>(response)
  })
}

export const fetchSelectRelatedWebCancel = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/select-related-web/cancel/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const fetchSelectRelatedWebProgress = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<ProgressSelectRelatedResponse> => {
  return fetch(`${engine}${BASE_URL}/select-related-web/progress/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ProgressSelectRelatedResponse>(response)
  })
}

export const fetchSelectRelatedWebResults = (
  engine: string,
  authToken: string,
  taskId: number,
  init?: RequestInit
): Promise<ResultsSelectRelatedResponse> => {
  return fetch(`${engine}${BASE_URL}/select-related-web/results/${taskId}/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResultsSelectRelatedResponse>(response)
  })
}

export const postSelectRelatedStart = (
  engine: string,
  authToken: string,
  id: string,
  selectRelatedBody: RequestPostSelectRelatedStart,
  init?: RequestInit
): Promise<ResponsePostSelectRelatedStart> => {
  return fetch(`${engine}${BASE_URL}/select-related/start/${id}/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(selectRelatedBody),
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostSelectRelatedStart>(response)
  })
}

export const postSelectRelatedExpertStart = (
  engine: string,
  authToken: string,
  id: string,
  selectRelatedBody: RequestPostSelectRelatedExpertStart,
  init?: RequestInit
): Promise<ResponsePostSelectRelatedExpertStart> => {
  return fetch(`${engine}${BASE_URL}/select-related-expert/start/${id}/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(selectRelatedBody),
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostSelectRelatedExpertStart>(response)
  })
}

export const postSelectRelatedFilterStart = (
  engine: string,
  authToken: string,
  id: string,
  selectRelatedBody: RequestPostSelectRelatedFilterStart,
  init?: RequestInit
): Promise<ResponsePostSelectRelatedFilterStart> => {
  return fetch(`${engine}${BASE_URL}/select-related-filter/start/${id}/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(selectRelatedBody),
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostSelectRelatedFilterStart>(response)
  })
}

export const postSelectRelatedFilterConfigStart = (
  engine: string,
  authToken: string,
  id: string,
  selectRelatedBody: RequestPostSelectRelatedFilterConfigStart,
  init?: RequestInit
): Promise<ResponsePostSelectRelatedFilterConfigStart> => {
  return fetch(`${engine}${BASE_URL}/select-related-filter-config/start/${id}/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(selectRelatedBody),
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostSelectRelatedFilterConfigStart>(response)
  })
}

export const postSelectRelatedWebStart = (
  engine: string,
  authToken: string,
  id: string,
  selectRelatedBody: RequestPostSelectRelatedWebStart,
  init?: RequestInit
): Promise<ResponsePostSelectRelatedWebStart> => {
  return fetch(`${engine}${BASE_URL}/select-related-web/start/${id}/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(selectRelatedBody),
    ...init,
  }).then(response => {
    return handleResponse<ResponsePostSelectRelatedWebStart>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// SNMP
//////////////////////////////////////////////////////////////////////////

export const fetchSNMPSettings = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetSNMPSettings | SNMPSettings> => {
  return fetch(`${engine}${BASE_URL}/snmp-settings/`, {
    method: "GET",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetSNMPSettings | SNMPSettings>(response)
  })
}

export const postSNMPSettings = (
  engine: string,
  authToken: string,
  snmpSettings: RequestPostSNMPSettings | SNMPSettings,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/snmp-settings/`, {
    method: "POST",
    headers: {
      authorization: "Token " + authToken,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(snmpSettings),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// ThreatEye NV
//////////////////////////////////////////////////////////////////////////

export const fetchThreatEyeNVConfiguration = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ThreatEyeNVConfiguration> => {
  return fetch(`${engine}${BASE_URL}/threateyenv/configuration/`, {
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<ThreatEyeNVConfiguration>(response)
  })
}

export const postThreatEyeNVConfiguration = (
  engine: string,
  authToken: string,
  threatEyeNVOptions: ThreatEyeNVConfiguration,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/threateyenv/configuration/`, {
    method: "POST",
    headers: {
      authorization: `Token ${authToken}`,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(threatEyeNVOptions),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Engines
//////////////////////////////////////////////////////////////////////////

export const fetchEngines = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<EngineCollection> => {
  return fetch(`${engine}${BASE_URL}/engines/`, {
    method: "GET",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<EngineCollection>(response)
  })
}

export const postEngine = (
  engine: string,
  authToken: string,
  engineItem: Engine,
  init?: RequestInit
): Promise<EngineCollection> => {
  return fetch(`${engine}${BASE_URL}/engines/`, {
    method: "POST",
    headers: {
      authorization: "Token " + authToken,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(engineItem),
    ...init,
  }).then(response => {
    return handleResponse<EngineCollection>(response)
  })
}

export const putEngine = (
  engine: string,
  authToken: string,
  engineItem: Engine,
  init?: RequestInit
): Promise<Engine> => {
  return fetch(`${engine}${BASE_URL}/engines/${engineItem.id}/`, {
    method: "PUT",
    headers: {
      authorization: "Token " + authToken,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(engineItem),
    ...init,
  }).then(response => {
    return handleResponse<Engine>(response)
  })
}

export const deleteEngine = (
  engine: string,
  authToken: string,
  engineItem: Engine,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/engines/${engineItem.id}/`, {
    method: "DELETE",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const deleteAllEngines = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/engines/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Multi-Engine Dashboards
//////////////////////////////////////////////////////////////////////////

export const fetchDashboards = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<DashboardCollection> => {
  return fetch(`${engine}${BASE_URL}/dashboards/`, {
    method: "GET",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<DashboardCollection>(response)
  })
}

export const postDashboard = (
  engine: string,
  authToken: string,
  dashboard: Dashboard,
  init?: RequestInit
): Promise<Dashboard> => {
  return fetch(`${engine}${BASE_URL}/dashboards/`, {
    method: "POST",
    headers: {
      authorization: "Token " + authToken,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(dashboard),
    ...init,
  }).then(response => {
    return handleResponse<Dashboard>(response)
  })
}

export const putDashboard = (
  engine: string,
  authToken: string,
  dashboard: Dashboard,
  init?: RequestInit
): Promise<Dashboard> => {
  return fetch(`${engine}${BASE_URL}/dashboards/${dashboard.id}/`, {
    method: "PUT",
    headers: {
      authorization: "Token " + authToken,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(dashboard),
    ...init,
  }).then(response => {
    return handleResponse<Dashboard>(response)
  })
}

export const deleteDashboard = (
  engine: string,
  authToken: string,
  dashboard: Dashboard,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/dashboards/${dashboard.id}/`, {
    method: "DELETE",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

function serializeWidget(widget: Widget): Widget {
  if (widget.settings != null && typeof widget.settings === "object") {
    return {
      ...widget,
      settings: JSON.stringify(widget.settings),
    }
  } else {
    return widget
  }
}

function deserializeWidget(widget: Widget): Widget {
  if (typeof widget.settings === "string") {
    try {
      const w = {
        ...widget,
        settings: JSON.parse(widget.settings),
      }
      return w
    } catch {
      return widget
    }
  } else {
    return widget
  }
}

function deserializeWidgetCollection(widgetCollection: WidgetCollection): WidgetCollection {
  if (Array.isArray(widgetCollection.widgets)) {
    return {
      ...widgetCollection,
      widgets: widgetCollection.widgets.map(widget => deserializeWidget(widget)),
    }
  } else {
    return widgetCollection
  }
}

export const fetchWidgets = (
  engine: string,
  authToken: string,
  dashboardId: string,
  init?: RequestInit
): Promise<WidgetCollection> => {
  return fetch(`${engine}${BASE_URL}/dashboards/${dashboardId}/widgets/`, {
    method: "GET",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  })
    .then(response => handleResponse<WidgetCollection>(response))
    .then(widgets => deserializeWidgetCollection(widgets))
}

export const postWidget = (
  engine: string,
  authToken: string,
  widget: Widget,
  init?: RequestInit
): Promise<Widget> => {
  return fetch(`${engine}${BASE_URL}/widgets/`, {
    method: "POST",
    headers: {
      authorization: "Token " + authToken,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(serializeWidget(widget)),
    ...init,
  })
    .then(response => handleResponse<Widget>(response))
    .then(widget => deserializeWidget(widget))
}

export const putWidget = (
  engine: string,
  authToken: string,
  widget: Widget,
  init?: RequestInit
): Promise<Widget> => {
  return fetch(`${engine}${BASE_URL}/widgets/${widget.id}/`, {
    method: "PUT",
    headers: {
      authorization: "Token " + authToken,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(serializeWidget(widget)),
    ...init,
  })
    .then(response => handleResponse<Widget>(response))
    .then(widget => deserializeWidget(widget))
}

export const deleteWidget = (
  engine: string,
  authToken: string,
  widget: Widget,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/widgets/${widget.id}/`, {
    method: "DELETE",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// Distributed Forensic Search
//////////////////////////////////////////////////////////////////////////

export const fetchDistributedForensicSearches = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetDistributedForensicSearches> => {
  return fetch(`${engine}${BASE_URL}/distributed-forensic-searches/`, {
    method: "GET",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetDistributedForensicSearches>(response)
  })
}

export const createDistributedForensicSearch = (
  engine: string,
  authToken: string,
  query: RequestCreateDistributedForensicSearch,
  init?: RequestInit
): Promise<ResponseCreateDistributedForensicSearch> => {
  return fetch(`${engine}${BASE_URL}/distributed-forensic-searches/`, {
    method: "POST",
    headers: {
      authorization: "Token " + authToken,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(query),
    ...init,
  }).then(response => {
    return handleResponse<ResponseCreateDistributedForensicSearch>(response)
  })
}

export const fetchDistributedForensicSearch = (
  engine: string,
  authToken: string,
  id: string,
  init?: RequestInit
): Promise<DistributedForensicSearchProperties> => {
  return fetch(`${engine}${BASE_URL}/distributed-forensic-searches/${id}/`, {
    method: "GET",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<DistributedForensicSearchProperties>(response)
  })
}

export const deleteDistributedForensicSearch = (
  engine: string,
  authToken: string,
  id: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/distributed-forensic-searches/${id}/`, {
    method: "DELETE",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const deleteAllDistributedForensicSearches = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/distributed-forensic-searches/`, {
    method: "DELETE",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const stopDistributedForensicSearch = (
  engine: string,
  authToken: string,
  id: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/running-distributed-forensic-searches/${id}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

//////////////////////////////////////////////////////////////////////////
// MSA
//////////////////////////////////////////////////////////////////////////

export const fetchMSAProjects = (
  engine: string,
  authToken: string,
  init?: RequestInit
): Promise<ResponseGetMSAProjects> => {
  return fetch(`${engine}${BASE_URL}/msa-projects/`, {
    method: "GET",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
      "cache-control": CACHE_CONTROL,
    },
    ...init,
  }).then(response => {
    return handleResponse<ResponseGetMSAProjects>(response)
  })
}

export const createMSAProject = (
  engine: string,
  authToken: string,
  query: RequestCreateMSAProject,
  init?: RequestInit
): Promise<ResponseCreateMSAProject> => {
  return fetch(`${engine}${BASE_URL}/msa-projects/`, {
    method: "POST",
    headers: {
      authorization: "Token " + authToken,
      "content-type": MimeType.MIME_TYPE_JSON,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(query),
    ...init,
  }).then(response => {
    return handleResponse<ResponseCreateMSAProject>(response)
  })
}

export const fetchMSAProject = (
  engine: string,
  authToken: string,
  id: string,
  init?: RequestInit
): Promise<MSAProjectProperties> => {
  return fetch(`${engine}${BASE_URL}/msa-projects/${id}/`, {
    method: "GET",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<MSAProjectProperties>(response)
  })
}

export const deleteMSAProject = (
  engine: string,
  authToken: string,
  id: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/msa-projects/${id}/`, {
    method: "DELETE",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const stopMSAProject = (
  engine: string,
  authToken: string,
  id: string,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/running-msa-projects/${id}/`, {
    method: "DELETE",
    headers: {
      authorization: `Token ${authToken}`,
      accept: MimeType.MIME_TYPE_JSON,
    },
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}

export const setMSAProjectOptions = (
  engine: string,
  authToken: string,
  id: string,
  options: RequestSetMSAProjectOptions,
  init?: RequestInit
): Promise<void> => {
  return fetch(`${engine}${BASE_URL}/msa-projects/${id}/options/`, {
    method: "PUT",
    headers: {
      authorization: "Token " + authToken,
      accept: MimeType.MIME_TYPE_JSON,
    },
    body: JSON.stringify(options),
    ...init,
  }).then(response => {
    return handleResponse<void>(response)
  })
}
