const WLANNodesViewColumns: any[] = [
  {
    dataKey: "name",
    label: "Node",
    width: 250,
    flexGrow: 1,
    flexShrink: 1,
    visible: true,
  },
  {
    dataKey: "type",
    label: "Type",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    visible: true,
  },
  {
    dataKey: "channel",
    label: "Channel",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    visible: true,
  },
  {
    dataKey: "associationStrength",
    label: "Association Strength",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "frequency",
    label: "Frequency",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    visible: false,
  },
  {
    dataKey: "band",
    label: "Band",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    visible: true,
  },
  {
    dataKey: "encryptionType",
    label: "Encryption",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    visible: true,
  },
  {
    dataKey: "trust",
    label: "Trust",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    visible: true,
  },
  {
    dataKey: "signal.percentCurrent",
    label: "Cur. Signal",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "signal.percentMinimum",
    label: "Min. Signal",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "signal.percentMaximum",
    label: "Max. Signal",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "signal.dBmCurrent",
    label: "Cur. Signal dBm",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "signal.dBmMinimum",
    label: "Min. Signal dBm",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "signal.dBmMaximum",
    label: "Max. Signal dBm",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "noise.percentCurrent",
    label: "Cur. Noise",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "noise.percentMinimum",
    label: "Min. Noise",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "noise.percentMaximum",
    label: "Max. Noise",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "noise.dBmCurrent",
    label: "Cur. Noise dBm",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "noise.dBmMinimum",
    label: "Min. Noise dBm",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "noise.dBmMaximum",
    label: "Max. Noise dBm",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "bytesSent",
    label: "Bytes Sent",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "bytesReceived",
    label: "Bytes Received",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "totalBytes",
    label: "Total Bytes",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "packetsSent",
    label: "Packets Sent",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "packetsReceived",
    label: "Packets Received",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "totalPackets",
    label: "Total Packets",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "retryPackets",
    label: "Retry Packets",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "wepPackets",
    label: "Protected Packets",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    visible: false,
  },
  {
    dataKey: "wepICVErrors",
    label: "WEP ICV Errors",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    visible: false,
  },
  {
    dataKey: "wepKey",
    label: "WEP Key",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    visible: false,
  },
  {
    dataKey: "beaconPackets",
    label: "Beacon Packets",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "powerSaveFlag",
    label: "Power Save",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    visible: false,
  },
  {
    dataKey: "broadcastPackets",
    label: "Broadcast Packets",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "broadcastBytes",
    label: "Broadcast Bytes",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "multicastPackets",
    label: "Multicast Packets",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "multicastBytes",
    label: "Multicast Bytes",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "minimumPacketSizeReceived",
    label: "Min. Size Received",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "maximumPacketSizeReceived",
    label: "Max. Size Received",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "minimumPacketSizeSent",
    label: "Min. Size Sent",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "maximumPacketSizeSent",
    label: "Max. Size Sent",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "firstTimeSent",
    label: "First Time Sent",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "lastTimeSent",
    label: "Last Time Sent",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "firstTimeReceived",
    label: "First Time Received",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "lastTimeReceived",
    label: "Last Time Received",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
]

export default WLANNodesViewColumns
