import { Link as ReactRouterLink, NavLink as ReactRouterNavLink } from "react-router-dom"
import styled from "styled-components"

export const Link = styled(ReactRouterLink)`
  color: ${props => props.theme.linkColor};

  &:hover {
    color: ${props => props.theme.linkHoverColor};
  }
`

export const NavLink = styled(ReactRouterNavLink)`
  color: ${props => props.theme.linkColor};

  &:hover,
  &:active {
    color: ${props => props.theme.linkHoverColor};
  }
`

export const TextLink = styled(ReactRouterLink)`
  color: ${props => props.theme.textColor};
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.textColor};
    text-decoration: underline;
  }
`

export const PlainLink = styled(ReactRouterLink)`
  text-decoration: underline;
  color: inherit;

  &:hover {
    color: inherit;
  }
`

export const InsideLink = styled.a`
  color: ${props => props.theme.linkColor};
  font-size: 120%;

  &:hover {
    color: ${props => props.theme.linkHoverColor};
  }
`

export const OutsideLink = styled.a`
  color: ${props => props.theme.linkColor};

  &:hover {
    color: ${props => props.theme.linkHoverColor};
  }
`

export const PlainOutsideLink = styled.a`
  text-decoration: underline;
  color: inherit;

  &:hover {
    color: inherit;
  }
`
