import * as React from "react"
import { Form } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { VoIPConfigForm } from "../CaptureOptionsView/VoIPConfigForm"
import { VoipConfig } from "../../api/types"

type VoIPConfigModalProps = {
  voipConfig: VoipConfig
  onOK: (voipConfig: VoipConfig) => void
  onCancel: () => void
}

type VoIPConfigModalState = {
  voipConfig: VoipConfig
}

class VoIPConfigModal extends React.Component<VoIPConfigModalProps, VoIPConfigModalState> {
  state: VoIPConfigModalState = {
    voipConfig: { ...this.props.voipConfig },
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    this.props.onOK(this.state.voipConfig)
  }

  setVoIPConfig = (voipConfig: VoipConfig) => {
    this.setState({ voipConfig })
  }

  render() {
    const { onCancel } = this.props
    return (
      <Modal isOpen={true}>
        <ModalHeader toggle={onCancel}>Voice &amp; Video</ModalHeader>
        <ModalBody>
          <Form id="voipConfigForm" onSubmit={this.onSubmit} noValidate>
            <VoIPConfigForm voipConfig={this.state.voipConfig} setVoIPConfig={this.setVoIPConfig} />
          </Form>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton type="submit" form="voipConfigForm">
            OK
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    )
  }
}

export default VoIPConfigModal
