import * as React from "react"
import { connect } from "react-redux"
import { withTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import PercentChart from "../../common/PercentChart"
import { ChartType } from "../../../api/types/chartTypes"
import {
  HalfWidthWidget,
  WidgetHeader,
  WidgetTitle,
  WidgetOptions,
  WidgetBody,
  NoData,
} from "../Dashboard"
import { IconDropdownToggle } from "../../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../../common/Dropdown"
import {
  setTopTalkersWidgetViewType,
  setTopTalkersWidgetChartType,
  setTopTalkersWidgetChartScale,
} from "../../../store/ui"
import {
  getTopTalkersWidgetViewType,
  getTopTalkersWidgetChartType,
  getTopTalkersWidgetChartScale,
} from "../../../store"

export const TopTalkersViewType = {
  IP: "ip",
  IPV6: "ipv6",
  PHYSICAL: "physical",
  COUNTRY: "country",
}

const TopTalkersViewTitle = {
  [TopTalkersViewType.IP]: "Top Talkers by IP Address",
  [TopTalkersViewType.IPV6]: "Top Talkers by IPv6 Address",
  [TopTalkersViewType.PHYSICAL]: "Top Talkers by Physical Address",
  [TopTalkersViewType.COUNTRY]: "Top Talkers by Country",
}

type Props = {
  topTalkers: any | null
  viewType: string
  chartType: ChartType
  chartScale: "fixed" | "auto"
  theme: any
  setTopTalkersWidgetViewType: (viewType: string) => void
  setTopTalkersWidgetChartType: (chartType: ChartType) => void
  setTopTalkersWidgetChartScale: (chartScale: string) => void
}

class TopTalkersWidget extends React.Component<Props> {
  onViewType = (e: any) => {
    this.props.setTopTalkersWidgetViewType(e.target.name)
  }

  onChartType = (e: any) => {
    this.props.setTopTalkersWidgetChartType(e.target.name)
  }

  onChartScale = (e: any) => {
    this.props.setTopTalkersWidgetChartScale(e.target.name)
  }

  render() {
    const { topTalkers, theme, viewType, chartType, chartScale } = this.props
    return (
      <HalfWidthWidget>
        <WidgetHeader>
          <WidgetTitle>{TopTalkersViewTitle[viewType]}</WidgetTitle>
          <WidgetOptions>
            <UncontrolledDropdown>
              <IconDropdownToggle aria-label="Settings">
                <FontAwesome name="gear" style={{ color: theme.textMutedColor }} />
              </IconDropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  name={TopTalkersViewType.IP}
                  active={viewType === TopTalkersViewType.IP}
                  onClick={this.onViewType}
                >
                  {TopTalkersViewTitle[TopTalkersViewType.IP]}
                </DropdownItem>
                <DropdownItem
                  name={TopTalkersViewType.IPV6}
                  active={viewType === TopTalkersViewType.IPV6}
                  onClick={this.onViewType}
                >
                  {TopTalkersViewTitle[TopTalkersViewType.IPV6]}
                </DropdownItem>
                <DropdownItem
                  name={TopTalkersViewType.PHYSICAL}
                  active={viewType === TopTalkersViewType.PHYSICAL}
                  onClick={this.onViewType}
                >
                  {TopTalkersViewTitle[TopTalkersViewType.PHYSICAL]}
                </DropdownItem>
                <DropdownItem
                  name={TopTalkersViewType.COUNTRY}
                  active={viewType === TopTalkersViewType.COUNTRY}
                  onClick={this.onViewType}
                >
                  {TopTalkersViewTitle[TopTalkersViewType.COUNTRY]}
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  name={ChartType.HORIZONTAL_BAR}
                  active={chartType === ChartType.HORIZONTAL_BAR}
                  onClick={this.onChartType}
                >
                  Bar
                </DropdownItem>
                <DropdownItem
                  name={ChartType.COLUMN}
                  active={chartType === ChartType.COLUMN}
                  onClick={this.onChartType}
                >
                  Column
                </DropdownItem>
                <DropdownItem
                  name={ChartType.PIE}
                  active={chartType === ChartType.PIE}
                  onClick={this.onChartType}
                >
                  Pie
                </DropdownItem>
                <DropdownItem
                  name={ChartType.DONUT}
                  active={chartType === ChartType.DONUT}
                  onClick={this.onChartType}
                >
                  Donut
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  name="auto"
                  active={chartScale === "auto"}
                  onClick={this.onChartScale}
                >
                  Auto Scale
                </DropdownItem>
                <DropdownItem
                  name="fixed"
                  active={chartScale === "fixed"}
                  onClick={this.onChartScale}
                >
                  Fixed Scale
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </WidgetOptions>
        </WidgetHeader>
        <WidgetBody minHeight="220px">
          {topTalkers && topTalkers.length > 0 ? (
            <PercentChart
              data={topTalkers}
              height={220}
              chartType={chartType}
              fixedScale={chartScale === "fixed"}
              valueKey="bytesSent"
              totalKey="totalBytesSent"
              theme={theme}
            />
          ) : (
            <NoData>No Data</NoData>
          )}
        </WidgetBody>
      </HalfWidthWidget>
    )
  }
}

const mapStateToProps = (state: any) => ({
  viewType: getTopTalkersWidgetViewType(state) || TopTalkersViewType.IP,
  chartType: getTopTalkersWidgetChartType(state) || ChartType.HORIZONTAL_BAR,
  chartScale: getTopTalkersWidgetChartScale(state) || "fixed",
})

const mapDisptachToProps = {
  setTopTalkersWidgetViewType,
  setTopTalkersWidgetChartType,
  setTopTalkersWidgetChartScale,
}

export default withTheme(connect(mapStateToProps, mapDisptachToProps)(TopTalkersWidget))
