import * as React from "react"
import { Helmet } from "react-helmet"
import { useHistory, useParams } from "react-router-dom"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useSelector } from "react-redux"
import { Alert } from "../common/Alert"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CenterContent } from "../common/Layout"
import { LightButton, PrimaryButton } from "../common/Buttons"
import { Spinner } from "../common/Spinner"
import { View, ViewContentScrollable, ViewContentContainer, ViewFooter } from "../common/View"
import {
  getMultiSegmentAnalysisProjectUrl,
  getMultiSegmentAnalysisUrl,
  getMultiSegmentAnalysisProjectOptionsUrl,
} from "../../routes"
import { getServer, getServerAuthToken } from "../../store"
import * as API from "../../api/api"
import { MSAProjectOptions, MSAProjectProperties, MSAProjectSegment } from "../../api/types"
import ProjectOptionsForm from "./ProjectOptionsForm"

export interface RequestPutMSAProject {
  name: string
  notes: string
  options: MSAProjectOptions
  segments: Array<MSAProjectSegment>
}

type RouteParams = {
  id: string
}

const ProjectOptionsView = () => {
  const { id } = useParams<RouteParams>()
  const history = useHistory()
  const queryClient = useQueryClient()
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const [error, setError] = React.useState<string | null>(null)

  const {
    data: project,
    status,
    error: queryError,
  } = useQuery({
    staleTime: Infinity,
    refetchInterval: query => {
      return query.state.data?.progress === 100 ? false : 2500
    },
    queryKey: ["msa", id],
    queryFn: () => {
      return API.fetchMSAProject(server, serverAuthToken, id)
    },
  })

  React.useEffect(() => {
    if (queryError && "code" in queryError && "reason" in queryError) {
      setError(`${queryError.code} ${queryError.reason}`)
    }
  }, [queryError])

  const { mutate: setMSAProjectOptions } = useMutation({
    mutationFn: (newProject: MSAProjectProperties) => {
      setError(null)
      return API.setMSAProjectOptions(server, serverAuthToken, id, {
        name: newProject.name,
        notes: newProject.notes,
        mappingProfiles: newProject.mappingProfiles,
        options: newProject.options,
        segments: newProject.segments,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["msa", id] })
      history.goBack()
    },
    onError: () => {
      setError("Setting project options failed")
    },
  })

  const onSubmit = (newProject: MSAProjectProperties) => {
    setMSAProjectOptions(newProject)
  }

  const onCancel = () => {
    history.goBack()
  }

  return (
    <View padding={false}>
      <Helmet title={project?.name || ""} />
      <BreadcrumbItem to={getMultiSegmentAnalysisUrl()} title="Multi-Segment Analysis Projects" />
      <BreadcrumbItem to={getMultiSegmentAnalysisProjectUrl(id)} title={project?.name || " "} />
      <BreadcrumbItem to={getMultiSegmentAnalysisProjectOptionsUrl(id)} title="Options" />
      {status === "pending" ? (
        <CenterContent>
          <Spinner />
        </CenterContent>
      ) : null}
      {status === "error" && error !== null ? <Alert color="danger">{error}</Alert> : null}
      {status === "success" && project !== null ? (
        <>
          <ViewContentScrollable>
            <ViewContentContainer maxWidth="960px">
              {error !== null ? <Alert color="danger">{error}</Alert> : null}
              <ProjectOptionsForm
                formId="msaProjectOptionsForm"
                project={project}
                onSubmit={onSubmit}
              />
            </ViewContentContainer>
          </ViewContentScrollable>
          <ViewFooter>
            <LightButton onClick={onCancel}>Cancel</LightButton>
            <PrimaryButton type="submit" form="msaProjectOptionsForm">
              OK
            </PrimaryButton>
          </ViewFooter>
        </>
      ) : null}
    </View>
  )
}

export default ProjectOptionsView
