import * as React from "react"
import styled from "styled-components"
import { Tooltip } from "recharts"

type TooltipProps = React.ComponentProps<typeof Tooltip>

export const ChartTooltipStyle = styled.div`
  margin: 0;
  padding: 8px;
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.chartTooltipBackgroundColor};
  border: ${props => props.theme.chartTooltipBorder};
  white-space: nowrap;
  max-width: 400px;
  overflow: hidden;
`

export const ChartTooltipLabel = styled.p`
  margin: 0;
  font-weight: 500;
`

export const ChartTooltipItemList = styled.ul`
  margin: 0;
  padding: 0;
`

export const ChartTooltipItem = styled.li`
  display: block;
  margin: 0;
  padding: 0;
`

const defaultTooltipItemFormatter = (
  value: string | number | Array<string | number>,
  name: string
) => `${name}: ${value}`

class ChartTooltip extends React.Component<TooltipProps> {
  renderContent() {
    const { payload } = this.props
    if (payload && payload.length) {
      const { formatter } = this.props
      const items = payload.map((entry: any, i: number) => {
        const itemFormatter = entry.formatter || formatter || defaultTooltipItemFormatter
        return (
          <ChartTooltipItem key={i}>
            {/*
              TODO: there an error in @types/recharts 1.8.1 with the definition 
              of TooltipFormatter. Add a cast here to work around the problem for now.
            */}
            {itemFormatter(entry.value as string, entry.name, entry, i)}
          </ChartTooltipItem>
        )
      })
      return <ChartTooltipItemList>{items}</ChartTooltipItemList>
    }
    return null
  }

  render() {
    const { active, payload, labelFormatter } = this.props
    if (active && payload) {
      let label = this.props.label || ""
      if (label === undefined && payload && payload.length) {
        label = payload[0].name
      }
      const finalLabel = labelFormatter ? labelFormatter(label, payload) : label
      return (
        <ChartTooltipStyle>
          {finalLabel && <ChartTooltipLabel>{finalLabel}</ChartTooltipLabel>}
          {this.renderContent()}
        </ChartTooltipStyle>
      )
    }
    return null
  }
}

export default ChartTooltip
