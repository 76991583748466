import * as React from "react"
import styled from "styled-components"
import { Modal, ModalHeader, ModalBody } from "../common/Modal"
import PropTable from "../common/PropTable"
import { OutsideLink } from "../common/Link"
import { ApplicationInfo } from "../../api/types"

const Key = styled.div`
  margin-top: 1rem;
  padding: 0.5rem;
  font-size: 0.846rem;
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.backgroundColor};

  & ol {
    margin: 0;
    padding: 0;
  }

  & li {
    list-style-position: inside;
  }

  & strong {
    font-weight: bold;
  }
`

type ApplicationDescriptionModalProps = {
  isOpen?: boolean
  application: string | number | null
  descriptions: ApplicationInfo[] | null
  onClose: () => void
}

class ApplicationDescriptionModal extends React.Component<ApplicationDescriptionModalProps> {
  render() {
    const { isOpen = true, application, descriptions, onClose } = this.props
    if (!isOpen) return null
    const properties: any = {}
    if (application && descriptions) {
      const desc = descriptions.find((app: ApplicationInfo) => app.id === application)
      if (desc) {
        properties["Application"] = desc.name
        properties["Category"] = desc.category
        properties["Description"] = desc.description
        properties["Productivity"] = desc.productivity
        properties["Risk"] = desc.risk
        if (desc.reference) {
          properties["Reference"] = (
            <OutsideLink href={desc.reference} target="_blank" rel="noopener noreferrer">
              {desc.reference}
            </OutsideLink>
          )
        }
      }
    }
    return (
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>Application Description</ModalHeader>
        <ModalBody>
          <PropTable
            propList={[
              "Application",
              "Category",
              "Description",
              "Reference",
              "Productivity",
              "Risk",
            ]}
            data={properties}
            skipEmptyRows
          />
          {properties["Productivity"] !== undefined ? (
            <Key>
              <strong>Productivity</strong> is scored relative to a work environment, and follows
              this scheme:
              <ol>
                <li>Not suitable inside of a working environment</li>
                <li>Unlikely to be used for work tasks</li>
                <li>
                  Broad-use traffic that could be used for either personal or work-related tasks
                </li>
                <li>Likely work-oriented traffic</li>
                <li>Traffic is solely for work or office purposes</li>
              </ol>
            </Key>
          ) : null}
          {properties["Risk"] !== undefined ? (
            <Key>
              <strong>Risk</strong> is determined on a scale of 1 to 5 using 4 weighted risk
              factors:
              <br />1 pt. Excessive bandwidth
              <br />1 pt. Potential data leakage
              <br />1 pt. Prone to misuse
              <br />2 pts. Contains or is used by malware
              <br />
            </Key>
          ) : null}
        </ModalBody>
      </Modal>
    )
  }
}

export default ApplicationDescriptionModal
