import { logOutErrorAction } from "./auth"
import { checkFetchResponse } from "../store"
import * as API from "../api/api"
import { ResponseGetEngineCapabilities } from "../api/types"

// Action types
export const types = {
  RESET_CAPABILITIES: "CAPABILITIES/RESET",
  FETCH_CAPABILITIES: "CAPABILITIES/FETCH",
  FETCH_CAPABILITIES_PENDING: "CAPABILITIES/FETCH_PENDING",
  FETCH_CAPABILITIES_FULFILLED: "CAPABILITIES/FETCH_FULFILLED",
  FETCH_CAPABILITIES_REJECTED: "CAPABILITIES/FETCH_REJECTED",
}

// Initial state
export const initialState = {
  capabilities: null,
  error: null,
}

// Reducer
export default function reducer(state = initialState, action: any) {
  switch (action.type) {
    case types.RESET_CAPABILITIES:
      return { ...state, capabilities: null }
    case types.FETCH_CAPABILITIES_PENDING:
      return { ...state, error: null }
    case types.FETCH_CAPABILITIES_FULFILLED:
      return { ...state, error: null, capabilities: { ...action.payload } }
    case types.FETCH_CAPABILITIES_REJECTED:
      return { ...state, error: action.payload }
    default:
      break
  }

  return state
}

// Actions

export const resetCapabilities = () => {
  return { type: types.RESET_CAPABILITIES }
}

export const fetchCapabilities = (engine: string, authToken: string, init?: RequestInit) => {
  return (dispatch: Function) => {
    dispatch({ type: types.FETCH_CAPABILITIES_PENDING })
    API.fetchCapabilities(engine, authToken, init)
      .then(response => {
        if (response.ok) {
          response
            .json()
            .then((json: ResponseGetEngineCapabilities) => {
              dispatch({
                type: types.FETCH_CAPABILITIES_FULFILLED,
                payload: json,
              })
            })
            .catch(error => {
              dispatch({
                type: types.FETCH_CAPABILITIES_REJECTED,
                payload: error,
              })
            })
        } else {
          dispatch({
            type: types.FETCH_CAPABILITIES_REJECTED,
            payload: response.statusText,
          })
          checkFetchResponse(response, () => dispatch(logOutErrorAction(response.statusText)))
        }
      })
      .catch(error => {
        dispatch({
          type: types.FETCH_CAPABILITIES_REJECTED,
          payload: error,
        })
        dispatch(logOutErrorAction("Network error"))
      })
  }
}

// Selectors

export const getCapabilities = (state: any) => state.capabilities
