import { produce } from "immer"
import { SortDirectionType } from "react-virtualized"
import { ExpertColumn } from "../api/types/expertTypes"
import { ExpertTableColumn } from "../components/common/ExpertTable"
import { PeerMapOptions } from "../components/PeerMapView"
import { ResultsSelectRelatedResponse } from "../api/types"

type PacketsSelection = {
  id: string
  selection: ResultsSelectRelatedResponse
}

// Action types
export const types = {
  SET_NAV_OPEN: "NAV_OPEN/SET",
  SET_SHOW_ADDRESS_NAMES: "SHOW_ADDRESS_NAMES/SET",
  SET_SHOW_PORT_NAMES: "SHOW_PORT_NAMES/SET",
  SET_SHOW_LOCAL_TIME: "SHOW_LOCAL_TIME/SET",
  SET_ENGINES_FILTER: "ENGINES_FILTER/SET",
  SET_ENGINES_SORT: "ENGINES_SORT/SET",
  SET_ENGINES_COLLAPSED_GROUPS: "ENGINES_COLLAPSED_GROUPS/SET",
  SET_ENGINES_CHECKED: "ENGINES_CHECKED/SET",
  SET_CAPTURES_VIEW_TYPE: "CAPTURES_VIEW_TYPE/SET",
  SET_CAPTURES_FILTER: "CAPTURES_FILTER/SET",
  SET_CAPTURES_COLUMNS: "CAPTURES_COLUMNS/SET",
  SET_CAPTURES_SORT: "CAPTURES_SORT/SET",
  SET_FORENSIC_SEARCHES_COLUMNS: "FORENSIC_SEARCHES_COLUMNS/SET",
  SET_FORENSIC_SEARCHES_FILTER: "FORENSIC_SEARCHES_FILTER/SET",
  SET_FORENSIC_SEARCHES_SORT: "FORENSIC_SEARCHES_SORT/SET",
  SET_FORENSIC_SEARCHES_VIEW_TYPE: "FORENSIC_SEARCHES_VIEW_TYPE/SET",
  SET_FORENSIC_SEARCH_HISTORY_FILTER: "FORENSIC_SEARCH_HISTORY_FILTER/SET",
  SET_ANALYSIS_MODULES_FILTER: "ANALYSIS_MODULES_FILTER/SET",
  SET_AUDIT_LOG_REFRESH_INTERVAL: "AUDIT_LOG_REFRESH_INTERVAL/SET",
  SET_FORENSICS_VIEW_REFRESH_INTERVAL: "FORENSICS_VIEW_REFRESH_INTERVAL/SET",
  SET_FORENSICS_VIEW_TYPE: "FORENSICS_VIEW_TYPE/SET",
  SET_FORENSICS_SAMPLE_INTERVAL: "FORENSICS_SAMPLE_INTERVAL/SET",
  SET_FORENSICS_TOP_TALKERS_VIEW_TYPE: "FORENSICS_TOP_TALKERS_VIEW_TYPE/SET",
  SET_FORENSICS_TOP_PROTOCOLS_VIEW_TYPE: "FORENSICS_TOP_PROTOCOLS_VIEW_TYPE/SET",
  SET_FORENSICS_TOP_STATS_CHART_TYPE: "FORENSICS_TOP_STATS_CHART_TYPE/SET",
  SET_FORENSICS_TOP_STATS_CHART_SCALE: "FORENSICS_TOP_STATS_CHART_SCALE/SET",
  SET_FORENSICS_TIMELINE_CHART_TYPE: "FORENSICS_TIMELINE_CHART_TYPE/SET",
  SET_FORENSICS_TIMELINE_CHART_SCALE: "FORENSICS_TIMELINE_CHART_SCALE/SET",
  SET_FORENSICS_TIMELINE_CHART_INTERPOLATION: "FORENSICS_TIMELINE_CHART_INTERPOLATION/SET",
  SET_FORENSICS_TIMELINE_SELECTION: "FORENSICS_TIMELINE_SELECTION/SET",
  SET_CAPTURE_SESSIONS_FILTER: "CAPTURE_SESSIONS_FILTER/SET",
  SET_CAPTURE_SESSIONS_COLUMNS: "CAPTURE_SESSIONS_COLUMNS/SET",
  SET_CAPTURE_SESSIONS_SORT: "CAPTURE_SESSIONS_SORT/SET",
  SET_CAPTURE_SESSIONS_COLLAPSED_GROUPS: "CAPTURE_SESSIONS_COLLAPSED_GROUPS/SET",
  SET_DECRYPTION_KEYS_FILTER: "DECRYPTION_KEYS_FILTER/SET",
  SET_DECRYPTION_KEYS_SORT: "DECRYPTION_KEYS_SORT/SET",
  SET_FILES_FILTER: "FILES_FILTER/SET",
  SET_FILES_COLUMNS: "FILES_COLUMNS/SET",
  SET_FILES_SORT: "FILES_SORT/SET",
  SET_FILES_COLLAPSED_GROUPS: "FILES_COLLAPSED_GROUPS/SET",
  SET_FILTERS_FILTER: "FILTERS_FILTER/SET",
  SET_FILTERS_SORT: "FILTERS_SORT/SET",
  SET_FILTERS_EXPANDED_GROUPS: "FILTERS_EXPANDED_GROUPS/SET",
  SET_FILTERS_CHECKED: "FILTERS_CHECKED/SET",
  SET_GRAPHS_FILTER: "GRAPHS_FILTER/SET",
  SET_GRAPHS_SORT: "GRAPHS_SORT/SET",
  SET_HARDWARE_PROFILES_FILTER: "HARDWARE_PROFILES_FILTER/SET",
  SET_HARDWARE_PROFILES_SORT: "HARDWARE_PROFILES_SORT/SET",
  SET_HARDWARE_PROFILES_CHECKED: "HARDWARE_PROFILES_CHECKED/SET",
  SET_ALARMS_FILTER: "ALARMS_FILTER/SET",
  SET_ALARMS_SORT: "ALARMS_SORT/SET",
  SET_NAMES_VIEW_TYPE: "NAMES_VIEW_TYPE/SET",
  SET_NAMES_FILTER: "NAMES_FILTER/SET",
  SET_NAMES_ADDRESSES_SORT: "NAMES_ADDRESSES_SORT/SET",
  SET_NAMES_PROTOCOLS_SORT: "NAMES_PROTOCOLS_SORT/SET",
  SET_NAMES_PORTS_SORT: "NAMES_PORTS_SORT/SET",
  SET_NAMES_ADDRESSES_EXPANDED_GROUPS: "NAMES_ADDRESSES_EXPANDED_GROUPS/SET",
  SET_NAMES_PROTOCOLS_EXPANDED_GROUPS: "NAMES_PROTOCOLS_EXPANDED_GROUPS/SET",
  SET_NAMES_PORTS_EXPANDED_GROUPS: "NAMES_PORTS_EXPANDED_GROUPS/SET",
  SET_NAMES_ADDRESSES_CHECKED: "NAMES_ADDRESSES_CHECKED/SET",
  SET_NAMES_PROTOCOLS_CHECKED: "NAMES_PROTOCOLS_CHECKED/SET",
  SET_NAMES_PORTS_CHECKED: "NAMES_PORTS_CHECKED/SET",
  SET_NOTIFICATIONS_FILTER: "NOTIFICATIONS_FILTER/SET",
  SET_NOTIFICATIONS_SORT: "NOTIFICATIONS_SORT/SET",
  SET_AUDIT_LOG_SEARCH: "AUDIT_LOG_SEARCH/SET",
  SET_FORENSIC_SEARCH_HISTORY_SEARCH: "FORENSIC_SEARCH_HISTORY_SEARCH/SET",
  SET_FORENSIC_SEARCH_HISTORY_SORT_ASC: "FORENSIC_SEARCH_HISTORY_SORT_ASC/SET",
  SET_FORENSIC_SEARCH_HISTORY_SORT_BY: "FORENSIC_SEARCH_HISTORY_SORT_BY/SET",
  SET_PACKETS_COLUMNS: "PACKETS_COLUMNS/SET",
  SET_PACKETS_COLUMNS_DECODE: "PACKETS_COLUMNS_DECODE/SET",
  SET_PACKETS_SELECTION: "PACKETS_SELECTION/SET",
  SET_PACKETS_SHOW_DECODE_VIEW: "PACKETS_SHOW_DECODE_VIEW/SET",
  SET_PACKETS_DECODE_PACKET_NUMBER: "PACKETS_DECODE_PACKET_NUMBER/SET",
  SET_PACKETS_SHOW_FILTER_BAR: "PACKETS_SHOW_FILTER_BAR/SET",
  SET_PACKETS_FILTER_BAR_EXPRESSION: "PACKETS_FILTER_BAR_EXPRESSION/SET",
  SET_PACKETS_FILTER_BAR_ERROR: "PACKETS_FILTER_BAR_ERROR/SET",
  PUSH_RECENT_FILTER_BAR_EXPRESSION: "RECENT_FILTER_BAR_EXPRESSION/PUSH",
  SET_TRAFFIC_HISTORY_WIDGET_CHART_TYPE: "TRAFFIC_HISTORY_WIDGET_CHART_TYPE/SET",
  SET_TRAFFIC_HISTORY_WIDGET_CHART_SCALE: "TRAFFIC_HISTORY_WIDGET_CHART_SCALE/SET",
  SET_TRAFFIC_HISTORY_WIDGET_CHART_INTERPOLATION: "TRAFFIC_HISTORY_WIDGET_CHART_INTERPOLATION/SET",
  SET_TOP_TALKERS_WIDGET_VIEW_TYPE: "TOP_TALKERS_WIDGET_VIEW_TYPE/SET",
  SET_TOP_TALKERS_WIDGET_CHART_TYPE: "TOP_TALKERS_WIDGET_CHART_TYPE/SET",
  SET_TOP_TALKERS_WIDGET_CHART_SCALE: "TOP_TALKERS_WIDGET_CHART_SCALE/SET",
  SET_TOP_PROTOCOLS_WIDGET_CHART_TYPE: "TOP_PROTOCOLS_WIDGET_CHART_TYPE/SET",
  SET_TOP_PROTOCOLS_WIDGET_CHART_SCALE: "TOP_PROTOCOLS_WIDGET_CHART_SCALE/SET",
  SET_TOP_APPLICATIONS_BY_FLOWS_WIDGET_CHART_TYPE:
    "TOP_APPLICATIONS_BY_FLOWS_WIDGET_CHART_TYPE/SET",
  SET_TOP_APPLICATIONS_BY_FLOWS_WIDGET_CHART_SCALE:
    "TOP_APPLICATIONS_BY_FLOWS_WIDGET_CHART_SCALE/SET",
  SET_TOP_APPLICATIONS_BY_BYTES_WIDGET_CHART_TYPE:
    "TOP_APPLICATIONS_BY_BYTES_WIDGET_CHART_TYPE/SET",
  SET_TOP_APPLICATIONS_BY_BYTES_WIDGET_CHART_SCALE:
    "TOP_APPLICATIONS_BY_BYTES_WIDGET_CHART_SCALE/SET",
  SET_TOP_APPLICATION_CATEGORIES_WIDGET_CHART_TYPE:
    "TOP_APPLICATION_CATEGORIES_WIDGET_CHART_TYPE/SET",
  SET_TOP_APPLICATION_CATEGORIES_WIDGET_CHART_SCALE:
    "TOP_APPLICATION_CATEGORIES_WIDGET_CHART_SCALE/SET",
  SET_APPLICATION_UTILIZATION_WIDGET_CHART_TYPE: "APPLICATION_UTILIZATION_WIDGET_CHART_TYPE/SET",
  SET_APPLICATION_UTILIZATION_WIDGET_CHART_SCALE: "APPLICATION_UTILIZATION_WIDGET_CHART_SCALE/SET",
  SET_APPLICATION_UTILIZATION_WIDGET_CHART_INTERPOLATION:
    "APPLICATION_UTILIZATION_WIDGET_CHART_INTERPOLATION/SET",
  SET_APPLICATION_RESPONSE_TIME_WIDGET_CHART_TYPE:
    "APPLICATION_RESPONSE_TIME_WIDGET_CHART_TYPE/SET",
  SET_APPLICATION_RESPONSE_TIME_WIDGET_CHART_SCALE:
    "APPLICATION_RESPONSE_TIME_WIDGET_CHART_SCALE/SET",
  SET_CALL_QUALITY_DISTRIBUTION_WIDGET_CHART_TYPE:
    "CALL_QUALITY_DISTRIBUTION_WIDGET_CHART_TYPE/SET",
  SET_CALL_QUALITY_DISTRIBUTION_WIDGET_CHART_SCALE:
    "CALL_QUALITY_DISTRIBUTION_WIDGET_CHART_SCALE/SET",
  SET_CALL_QUALITY_WIDGET_CHART_TYPE: "CALL_QUALITY_WIDGET_CHART_TYPE/SET",
  SET_CALL_QUALITY_WIDGET_CHART_INTERPOLATION: "CALL_QUALITY_WIDGET_CHART_INTERPOLATION/SET",
  SET_CALL_QUALITY_BY_CODEC_WIDGET_CHART_TYPE: "CALL_QUALITY_BY_CODEC_WIDGET_CHART_TYPE/SET",
  SET_CALL_QUALITY_BY_CODEC_WIDGET_CHART_INTERPOLATION:
    "CALL_QUALITY_BY_CODEC_WIDGET_CHART_INTERPOLATION/SET",
  SET_CALL_VOLUME_BY_CODEC_WIDGET_CHART_TYPE: "CALL_VOLUME_BY_CODEC_WIDGET_CHART_TYPE/SET",
  SET_CALL_VOLUME_BY_CODEC_WIDGET_CHART_INTERPOLATION:
    "CALL_VOLUME_BY_CODEC_WIDGET_CHART_INTERPOLATION/SET",
  SET_CALL_UTILIZATION_WIDGET_CHART_TYPE: "CALL_UTILIZATION_WIDGET_CHART_TYPE/SET",
  SET_CALL_UTILIZATION_WIDGET_CHART_SCALE: "CALL_UTILIZATION_WIDGET_CHART_SCALE/SET",
  SET_CALL_UTILIZATION_WIDGET_CHART_INTERPOLATION: "CALL_UTILIZATION_WIDGET_INTERPOLATION/SET",
  SET_SUMMARY_STATS_VIEW_TYPE: "SUMMARY_STATS_VIEW_TYPE/SET",
  SET_SUMMARY_STATS_FILTER: "SUMMARY_STATS_FILTER/SET",
  SET_NODE_STATS_VIEW_TYPE: "NODE_STATS_VIEW_TYPE/SET",
  SET_NODE_STATS_FILTER: "NODE_STATS_FILTER/SET",
  SET_NODE_STATS_COLUMNS: "NODE_STATS_COLUMNS/SET",
  SET_NODE_STATS_SORT: "NODE_STATS_SORT/SET",
  SET_NODE_STATS_HIERARCHY_COLUMNS: "SET_NODE_STATS_HIERARCHY_COLUMNS/SET",
  SET_PROTOCOL_STATS_VIEW_TYPE: "PROTOCOL_STATS_VIEW_TYPE/SET",
  SET_PROTOCOL_STATS_FILTER: "PROTOCOL_STATS_FILTER/SET",
  SET_PROTOCOL_STATS_SORT: "PROTOCOL_STATS_SORT/SET",
  SET_APP_STATS_FILTER: "APP_STATS_FILTER/SET",
  SET_APP_STATS_COLUMNS: "APP_STATS_COLUMNS/SET",
  SET_APP_STATS_SORT: "APP_STATS_SORT/SET",
  SET_APP_STATS_VIEW_TYPE: "SET_APP_STATS_VIEW_TYPE/SET",
  SET_FILE_RECONSTRUCTOR_FILTER: "FILE_RECONSTRUCTOR_FILTER/SET",
  SET_FILE_RECONSTRUCTOR_COLUMNS: "FILE_RECONSTRUCTOR_COLUMNS/SET",
  SET_FILE_RECONSTRUCTOR_SORT: "FILE_RECONSTRUCTOR_SORT/SET",
  SET_COUNTRY_STATS_FILTER: "COUNTRY_STATS_FILTER/SET",
  SET_COUNTRY_STATS_COLUMNS: "COUNTRY_STATS_COLUMNS/SET",
  SET_COUNTRY_STATS_SORT: "COUNTRY_STATS_SORT/SET",
  SET_MPLS_VLAN_VXLAN_STATS_FILTER: "MPLS_VLAN_VXLAN_STATS_FILTER/SET",
  SET_MPLS_VLAN_VXLAN_STATS_COLUMNS: "MPLS_VLAN_VXLAN_STATS_COLUMNS/SET",
  SET_MPLS_VLAN_VXLAN_STATS_SORT: "MPLS_VLAN_VXLAN_STATS_SORT/SET",
  SET_EXPERT_CLIENTS_SERVERS_COLUMNS: "EXPERT_CLIENTS_SERVERS_COLUMNS/SET",
  SET_EXPERT_CLIENTS_SERVERS_SORT: "EXPERT_CLIENTS_SERVERS_SORT/SET",
  SET_EXPERT_FLOWS_SEARCH: "EXPERT_FLOWS_SEARCH/SET",
  SET_EXPERT_FLOWS_COLUMNS: "EXPERT_FLOWS_COLUMNS/SET",
  SET_EXPERT_FLOWS_SORT: "EXPERT_FLOWS_SORT/SET",
  SET_EXPERT_APPS_COLUMNS: "EXPERT_APPS_COLUMNS/SET",
  SET_EXPERT_APPS_SORT: "EXPERT_APPS_SORT/SET",
  SET_EXPERT_EVENT_SUMMARY_SORT: "EXPERT_EVENT_SUMMARY_SORT/SET",
  SET_EXPERT_EVENT_LOG_COLUMNS: "EXPERT_EVENT_LOG_COLUMNS/SET",
  SET_EXPERT_EVENT_LOG_SORT: "EXPERT_EVENT_LOG_SORT/SET",
  SET_VOIP_CALLS_COLUMNS: "VOIP_CALLS_COLUMNS/SET",
  SET_VOIP_CALLS_SORT: "VOIP_CALLS_SORT/SET",
  SET_VOIP_MEDIA_COLUMNS: "VOIP_MEDIA_COLUMNS/SET",
  SET_VOIP_MEDIA_SORT: "VOIP_MEDIA_SORT/SET",
  SET_FLOW_VISUALIZER_VIEW_TYPE: "FLOW_VISUALIZER_VIEW_TYPE/SET",
  SET_FLOW_VISUALIZER_COLUMNS: "FLOW_VISUALIZER_COLUMNS/SET",
  SET_FLOW_VISUALIZER_RELATIVE_SEQ_ACK: "FLOW_VISUALIZER_RELATIVE_SEQ_ACK/SET",
  SET_FLOW_VISUALIZER_RELATIVE_TIME: "FLOW_VISUALIZER_RELATIVE_TIME/SET",
  SET_FLOW_VISUALIZER_MARK_RELATED_PACKETS: "FLOW_VISUALIZER_MARK_RELATED_PACKETS/SET",
  SET_FLOW_VISUALIZER_SHOW_CLIENT_PAYLOAD: "FLOW_VISUALIZER_SHOW_CLIENT_PAYLOAD/SET",
  SET_FLOW_VISUALIZER_SHOW_SERVER_PAYLOAD: "FLOW_VISUALIZER_SHOW_SERVER_PAYLOAD/SET",
  SET_FLOW_VISUALIZER_SHOW_PAYLOAD_PACKET_LABELS: "FLOW_VISUALIZER_SHOW_PAYLOAD_PACKET_LABELS/SET",
  SET_FLOW_VISUALIZER_SHOW_PAYLOAD_CRLF: "FLOW_VISUALIZER_SHOW_PAYLOAD_CRLF/SET",
  SET_FLOW_VISUALIZER_GRAPH_TYPE: "FLOW_VISUALIZER_GRAPH_TYPE/SET",
  SET_FLOW_VISUALIZER_GRAPH_DIRECTION: "FLOW_VISUALIZER_GRAPH_DIRECTION/SET",
  SET_VOIP_CALL_VISUALIZER_VIEW_TYPE: "VOIP_CALL_VISUALIZER_VIEW_TYPE/SET",
  SET_VOIP_CALL_VISUALIZER_COLUMNS: "VOIP_CALL_VISUALIZER_COLUMNS/SET",
  SET_VOIP_CALL_VISUALIZER_SORT: "VOIP_CALL_VISUALIZER_SORT/SET",
  SET_GRAPHS_SHOW_LIST: "GRAPHS_SHOW_LIST/SET",
  SET_GRAPHS_ENABLED: "GRAPHS_ENABLED/SET",
  SET_PEER_MAP_OPTIONS: "PEER_MAP_OPTIONS/SET",
  SET_WIRELESS_NODE_COLUMNS: "WIRELESS_NODE_COLUMNS/SET",
  SET_WIRELESS_NODE_VIEW_TYPE: "WIRELESS_NODE_VIEW/SET",
  SET_WIRELESS_NODES_SORT: "WIRELESS_NODE_SORT/SET",
  SET_MSA_LIST_FILTER: "MSA_LIST_FILTER/SET",
  SET_MSA_VIEW_TYPE: "MSA_VIEW_TYPE/SET",
  SET_MSA_FLOWS_COLUMNS: "MSA_FLOWS_COLUMNS/SET",
  SET_MSA_FLOWS_SORT: "MSA_FLOWS_SORT/SET",
  SET_MSA_FLOWS_FILTER: "MSA_FLOWS_FILTER/SET",
  SET_MSA_LADDER_VIEW_OPTION: "MSA_LADDER_VIEW_OPTION/SET",
  SET_AUTHENTICATION_TOKENS_FILTER: "AUTHENTICATION_TOKENS_FILTER/SET",
  SET_AUTHENTICATION_TOKENS_SORT: "AUTHENTICATION_TOKENS_SORT/SET",
  SET_WEB_CLIENTS_COLUMNS: "WEB_CLIENTS_COLUMNS/SET",
  SET_WEB_CLIENTS_SORT: "WEB_CLIENTS_SORT/SET",
  SET_WEB_SERVERS_COLUMNS: "WEB_SERVERS_COLUMNS/SET",
  SET_WEB_SERVERS_SORT: "WEB_SERVERS_SORT/SET",
  SET_WEB_PAGES_COLUMNS: "WEB_PAGES_COLUMNS/SET",
  SET_WEB_PAGES_SORT: "WEB_PAGES_SORT/SET",
  SET_WEB_REQUESTS_COLUMNS: "WEB_REQUESTS_COLUMNS/SET",
  SET_WEB_REQUESTS_SORT: "WEB_REQUESTS_SORT/SET",
}

// Initial state
export const initialState = {
  isNavOpen: false,
  showAddressNames: true,
  showPortNames: true,
  showLocalTime: true,
  enginesFilter: undefined,
  enginesSortBy: undefined,
  enginesSortDirection: undefined,
  enginesCollapsedGroups: undefined,
  enginesChecked: undefined,
  capturesViewType: undefined,
  capturesFilter: undefined,
  capturesSortBy: undefined,
  capturesSortDirection: undefined,
  forensicSearchesFilter: undefined,
  forensicSearchesSortBy: undefined,
  forensicSearchesSortDirection: undefined,
  forensicSearchesViewType: undefined,
  forensicSearchHistoryFilter: undefined,
  analysisModulesFilter: undefined,
  auditLogRefreshInterval: undefined,
  forensicsViewRefreshInterval: undefined,
  forensicsViewType: undefined,
  forensicsSampleInterval: undefined,
  forensicsTopTalkersViewType: undefined,
  forensicsTopProtocolsViewType: undefined,
  forensicsTopStatsChartType: undefined,
  forensicsTopStatsChartScale: undefined,
  forensicsTimelineChartType: undefined,
  forensicsTimelineChartScale: undefined,
  forensicsTimelineChartInterpolation: undefined,
  forensicsTimelineSelection: undefined,
  forensicsTimelineSelectionStart: undefined,
  forensicsTimelineSelectionEnd: undefined,
  captureSessionsFilter: undefined,
  captureSessionsColumns: undefined,
  captureSessionsSortBy: undefined,
  captureSessionsSortDirection: undefined,
  captureSessionsCollapsedGroups: undefined,
  decryptionKeysFilter: undefined,
  decryptionKeysSortBy: undefined,
  decryptionKeysSortDirection: undefined,
  filesFilter: undefined,
  filesColumns: undefined,
  filesSortBy: undefined,
  filesSortDirection: undefined,
  filesCollapsedGroups: undefined,
  filtersFilter: undefined,
  filtersSortBy: undefined,
  filtersSortDirection: undefined,
  filtersExpandedGroups: undefined,
  filtersChecked: undefined,
  graphsFilter: undefined,
  graphsSortBy: undefined,
  graphsSortDirection: undefined,
  hardwareProfilesFilter: undefined,
  hardwareProfilesSortBy: undefined,
  hardwareProfilesSortDirection: undefined,
  hardwareProfilesChecked: undefined,
  alarmsFilter: undefined,
  alarmsSortBy: undefined,
  alarmsSortDirection: undefined,
  namesViewType: undefined,
  namesFilter: undefined,
  namesAddressesSortBy: undefined,
  namesAddressesSortDirection: undefined,
  namesProtocolsSortBy: undefined,
  namesProtocolsSortDirection: undefined,
  namesPortsSortBy: undefined,
  namesPortsSortDirection: undefined,
  namesAddressesExpandedGroups: undefined,
  namesProtocolsExpandedGroups: undefined,
  namesPortsExpandedGroups: undefined,
  namesAddressesChecked: undefined,
  namesProtocolsChecked: undefined,
  namesPortsChecked: undefined,
  notificationsFilter: undefined,
  notificationsSortDirection: undefined,
  auditLogSearch: undefined,
  forensicSearchHistorySearch: undefined,
  forensicSearchHistorySortAsc: undefined,
  forensicSearchHistorySortBy: undefined,
  packetsColumns: undefined,
  packetsColumnsDecode: undefined,
  packetsSelection: [],
  showDecodeView: false,
  showFilterBar: true,
  filterBarExpression: undefined,
  filterBarError: undefined,
  recentFilterBarExpressions: [],
  decodePacketNumber: 0,
  trafficHistoryWidgetChartType: undefined,
  trafficHistoryWidgetChartScale: undefined,
  trafficHistoryWidgetChartInterpolation: undefined,
  topTalkersWidgetViewType: undefined,
  topTalkersWidgetChartType: undefined,
  topTalkersWidgetChartScale: undefined,
  topProtocolsWidgetChartType: undefined,
  topProtocolsWidgetChartScale: undefined,
  topApplicationsByFlowsWidgetChartType: undefined,
  topApplicationsByFlowsWidgetChartScale: undefined,
  topApplicationsByBytesWidgetChartType: undefined,
  topApplicationsByBytesWidgetChartScale: undefined,
  topApplicationCategoriesWidgetChartType: undefined,
  topApplicationCategoriesWidgetChartScale: undefined,
  applicationUtilizationWidgetChartType: undefined,
  applicationUtilizationWidgetChartScale: undefined,
  applicationUtilizationWidgetChartInterpolation: undefined,
  applicationResponseTimeWidgetChartType: undefined,
  applicationResponseTimeWidgetChartScale: undefined,
  callQualityDistributionWidgetChartType: undefined,
  callQualityDistributionWidgetChartScale: undefined,
  callQualityWidgetChartType: undefined,
  callQualityWidgetChartInterpolation: undefined,
  callQualityByCodecWidgetChartType: undefined,
  callQualityByCodecWidgetChartInterpolation: undefined,
  callVolumeByCodecWidgetChartType: undefined,
  callVolumeByCodecWidgetChartInterpolation: undefined,
  callUtilizationWidgetChartType: undefined,
  callUtilizationWidgetChartScale: undefined,
  callUtilizationWidgetChartInterpolation: undefined,
  summaryStatsViewType: undefined,
  summaryStatsFilter: undefined,
  nodeStatsViewType: undefined,
  nodeStatsFilter: undefined,
  nodeStatsColumns: undefined,
  nodeStatsSortBy: undefined,
  nodeStatsHierarchyColumns: undefined,
  nodeStatsSortDirection: undefined,
  protocolStatsViewType: undefined,
  protocolStatsFilter: undefined,
  protocolStatsSortBy: undefined,
  protocolStatsSortDirection: undefined,
  appStatsFilter: undefined,
  appStatsColumns: undefined,
  appStatsSortBy: undefined,
  appStatsSortDirection: undefined,
  appStatsViewType: undefined,
  countryStatsFilter: undefined,
  countryStatsColumns: undefined,
  countryStatsSortBy: undefined,
  countryStatsSortDirection: undefined,
  mplsVlanVxlanStatsFilter: undefined,
  mplsVlanVxlanStatsColumns: undefined,
  mplsVlanVxlanStatsSortBy: undefined,
  mplsVlanVxlanStatsSortDirection: undefined,
  expertClientsServersColumns: undefined,
  expertClientsServersSortBy: undefined,
  expertClientsServersSortDirection: undefined,
  expertFlowsSearch: undefined,
  expertFlowsColumns: undefined,
  expertFlowsSortBy: undefined,
  expertFlowsSortDirection: undefined,
  expertAppsColumns: undefined,
  expertAppsSortBy: undefined,
  expertAppsSortDirection: undefined,
  expertEventSummarySortBy: undefined,
  expertEventSummarySortDirection: undefined,
  expertEventLogColumns: undefined,
  expertEventLogSortBy: undefined,
  expertEventLogSortDirection: undefined,
  voipCallsColumns: undefined,
  voipCallsSortBy: undefined,
  voipCallsSortDirection: undefined,
  voipMediaColumns: undefined,
  voipMediaSortBy: undefined,
  voipMediaSortDirection: undefined,
  flowVisualizerViewType: undefined,
  flowVisualizerColumns: undefined,
  flowVisualizerRelativeSeqAck: true,
  flowVisualizerRelativeTime: true,
  flowVisualizerMarkRelatedPackets: true,
  flowVisualizerShowClientPayload: true,
  flowVisualizerShowServerPayload: true,
  flowVisualizerShowPayloadPacketLabels: true,
  flowVisualizerShowPayloadCRLF: false,
  flowVisualizerGraphType: undefined,
  flowVisualizerGraphDirection: undefined,
  voipCallVisualizerViewType: undefined,
  voipCallVisualizerColumns: undefined,
  voipCallVisualizerSortBy: undefined,
  voipCallVisualizerSortDirection: undefined,
  wirelessNodesColumns: undefined,
  wirelessNodesViewType: undefined,
  wirelessNodesSortBy: undefined,
  wirelessNodesSortDirection: undefined,
  graphsShowList: true,
  graphsEnabled: [],
  peerMapOptions: {},
  msaListFilter: undefined,
  msaViewType: undefined,
  msaFlowsColumns: undefined,
  msaFlowsSortBy: undefined,
  msaFlowsSortDirection: undefined,
  msaFlowsFilter: undefined,
  msaLadderViewOptions: undefined,
  authenticationTokensFilter: undefined,
  authenticationTokensSortBy: undefined,
  authenticationTokensSortDirection: undefined,
  webServersSortBy: undefined,
  webServersColumns: undefined,
  webServersSortDirection: undefined,
  webClientsSortBy: undefined,
  webClientsColumns: undefined,
  webClientsSortDirection: undefined,
  webPagesSortBy: undefined,
  webPagesColumns: undefined,
  webPagesSortDirection: undefined,
  webRequestsSortBy: undefined,
  webRequestsColumns: undefined,
  webRequestsSortDirection: undefined,
}

// Reducer
export default produce((state: any = initialState, action: any) => {
  switch (action.type) {
    case types.SET_NAV_OPEN:
      state.isNavOpen = action.payload
      break
    case types.SET_SHOW_ADDRESS_NAMES:
      state.showAddressNames = action.payload
      break
    case types.SET_SHOW_PORT_NAMES:
      state.showPortNames = action.payload
      break
    case types.SET_SHOW_LOCAL_TIME:
      state.showLocalTime = action.payload
      break
    case types.SET_ENGINES_FILTER:
      state.enginesFilter = action.payload
      break
    case types.SET_ENGINES_SORT:
      state.enginesSortBy = action.payload.sortBy
      state.enginesSortDirection = action.payload.sortDirection
      break
    case types.SET_ENGINES_COLLAPSED_GROUPS:
      state.enginesCollapsedGroups = action.payload
      break
    case types.SET_ENGINES_CHECKED:
      state.enginesChecked = action.payload
      break
    case types.SET_CAPTURES_VIEW_TYPE:
      state.capturesViewType = action.payload
      break
    case types.SET_CAPTURES_FILTER:
      state.capturesFilter = action.payload
      break
    case types.SET_CAPTURES_COLUMNS:
      state.capturesColumns = action.payload
      break
    case types.SET_CAPTURES_SORT:
      state.capturesSortBy = action.payload.sortBy
      state.capturesSortDirection = action.payload.sortDirection
      break
    case types.SET_FORENSIC_SEARCHES_COLUMNS:
      state.forensicSearchesColumns = action.payload
      break
    case types.SET_FORENSIC_SEARCHES_FILTER:
      state.forensicSearchesFilter = action.payload
      break
    case types.SET_FORENSIC_SEARCHES_SORT:
      state.forensicSearchesSortBy = action.payload.sortBy
      state.forensicSearchesSortDirection = action.payload.sortDirection
      break
    case types.SET_FORENSIC_SEARCHES_VIEW_TYPE:
      state.forensicSearchesViewType = action.payload
      break
    case types.SET_FORENSIC_SEARCH_HISTORY_FILTER:
      state.forensicSearchHistoryFilter = action.payload
      break
    case types.SET_ANALYSIS_MODULES_FILTER:
      state.analysisModulesFilter = action.payload
      break
    case types.SET_AUDIT_LOG_REFRESH_INTERVAL:
      state.auditLogRefreshInterval = action.payload
      break
    case types.SET_FORENSICS_VIEW_REFRESH_INTERVAL:
      state.forensicsViewRefreshInterval = action.payload
      break
    case types.SET_FORENSICS_VIEW_TYPE:
      state.forensicsViewType = action.payload
      break
    case types.SET_FORENSICS_SAMPLE_INTERVAL:
      state.forensicsSampleInterval = action.payload
      break
    case types.SET_FORENSICS_TOP_TALKERS_VIEW_TYPE:
      state.forensicsTopTalkersViewType = action.payload
      break
    case types.SET_FORENSICS_TOP_PROTOCOLS_VIEW_TYPE:
      state.forensicsTopProtocolsViewType = action.payload
      break
    case types.SET_FORENSICS_TOP_STATS_CHART_TYPE:
      state.forensicsTopStatsChartType = action.payload
      break
    case types.SET_FORENSICS_TOP_STATS_CHART_SCALE:
      state.forensicsTopStatsChartScale = action.payload
      break
    case types.SET_FORENSICS_TIMELINE_CHART_TYPE:
      state.forensicsTimelineChartType = action.payload
      break
    case types.SET_FORENSICS_TIMELINE_CHART_SCALE:
      state.forensicsTimelineChartScale = action.payload
      break
    case types.SET_FORENSICS_TIMELINE_CHART_INTERPOLATION:
      state.forensicsTimelineChartInterpolation = action.payload
      break
    case types.SET_FORENSICS_TIMELINE_SELECTION:
      state.forensicsTimelineSelection = action.payload.selection
      state.forensicsTimelineSelectionStart = action.payload.start
      state.forensicsTimelineSelectionEnd = action.payload.end
      break
    case types.SET_CAPTURE_SESSIONS_FILTER:
      state.captureSessionsFilter = action.payload
      break
    case types.SET_CAPTURE_SESSIONS_COLUMNS:
      state.captureSessionsColumns = action.payload
      break
    case types.SET_CAPTURE_SESSIONS_SORT:
      state.captureSessionsSortBy = action.payload.sortBy
      state.captureSessionsSortDirection = action.payload.sortDirection
      break
    case types.SET_CAPTURE_SESSIONS_COLLAPSED_GROUPS:
      state.captureSessionsCollapsedGroups = action.payload
      break
    case types.SET_DECRYPTION_KEYS_FILTER:
      state.decryptionKeysFilter = action.payload
      break
    case types.SET_DECRYPTION_KEYS_SORT:
      state.decryptionKeysSortBy = action.payload.sortBy
      state.decryptionKeysSortDirection = action.payload.sortDirection
      break
    case types.SET_FILES_FILTER:
      state.filesFilter = action.payload
      break
    case types.SET_FILES_COLUMNS:
      state.filesColumns = action.payload
      break
    case types.SET_FILES_SORT:
      state.filesSortBy = action.payload.sortBy
      state.filesSortDirection = action.payload.sortDirection
      break
    case types.SET_FILES_COLLAPSED_GROUPS:
      state.filesCollapsedGroups = action.payload
      break
    case types.SET_FILTERS_FILTER:
      state.filtersFilter = action.payload
      break
    case types.SET_FILTERS_SORT:
      state.filtersSortBy = action.payload.sortBy
      state.filtersSortDirection = action.payload.sortDirection
      break
    case types.SET_FILTERS_EXPANDED_GROUPS:
      state.filtersExpandedGroups = action.payload
      break
    case types.SET_FILTERS_CHECKED:
      state.filtersChecked = action.payload
      break
    case types.SET_GRAPHS_FILTER:
      state.graphsFilter = action.payload
      break
    case types.SET_GRAPHS_SORT:
      state.graphsSortBy = action.payload.sortBy
      state.graphsSortDirection = action.payload.sortDirection
      break
    case types.SET_HARDWARE_PROFILES_FILTER:
      state.hardwareProfilesFilter = action.payload
      break
    case types.SET_HARDWARE_PROFILES_SORT:
      state.hardwareProfilesSortBy = action.payload.sortBy
      state.hardwareProfilesSortDirection = action.payload.sortDirection
      break
    case types.SET_HARDWARE_PROFILES_CHECKED:
      state.hardwareProfilesChecked = action.payload
      break
    case types.SET_ALARMS_FILTER:
      state.alarmsFilter = action.payload
      break
    case types.SET_ALARMS_SORT:
      state.alarmsSortBy = action.payload.sortBy
      state.alarmsSortDirection = action.payload.sortDirection
      break
    case types.SET_NAMES_VIEW_TYPE:
      state.namesViewType = action.payload
      break
    case types.SET_NAMES_FILTER:
      state.namesFilter = action.payload
      break
    case types.SET_NAMES_ADDRESSES_SORT:
      state.namesAddressesSortBy = action.payload.sortBy
      state.namesAddressesSortDirection = action.payload.sortDirection
      break
    case types.SET_NAMES_PROTOCOLS_SORT:
      state.namesProtocolsSortBy = action.payload.sortBy
      state.namesProtocolsSortDirection = action.payload.sortDirection
      break
    case types.SET_NAMES_PORTS_SORT:
      state.namesPortsSortBy = action.payload.sortBy
      state.namesPortsSortDirection = action.payload.sortDirection
      break
    case types.SET_NAMES_ADDRESSES_EXPANDED_GROUPS:
      state.namesAddressesExpandedGroups = action.payload
      break
    case types.SET_NAMES_PROTOCOLS_EXPANDED_GROUPS:
      state.namesProtocolsExpandedGroups = action.payload
      break
    case types.SET_NAMES_PORTS_EXPANDED_GROUPS:
      state.namesPortsExpandedGroups = action.payload
      break
    case types.SET_NAMES_ADDRESSES_CHECKED:
      state.namesAddressesChecked = action.payload
      break
    case types.SET_NAMES_PROTOCOLS_CHECKED:
      state.namesProtocolsChecked = action.payload
      break
    case types.SET_NAMES_PORTS_CHECKED:
      state.namesPortsChecked = action.payload
      break
    case types.SET_NOTIFICATIONS_FILTER:
      state.notificationsFilter = action.payload
      break
    case types.SET_NOTIFICATIONS_SORT:
      state.notificationsSortDirection = action.payload
      break
    case types.SET_AUDIT_LOG_SEARCH:
      state.auditLogSearch = action.payload
      break
    case types.SET_FORENSIC_SEARCH_HISTORY_SEARCH:
      state.forensicSearchHistorySearch = action.payload
      break
    case types.SET_FORENSIC_SEARCH_HISTORY_SORT_ASC:
      state.forensicSearchHistorySortAsc = action.payload
      break
    case types.SET_FORENSIC_SEARCH_HISTORY_SORT_BY:
      state.forensicSearchHistorySortBy = action.payload
      break
    case types.SET_PACKETS_COLUMNS:
      state.packetsColumns = action.payload
      break
    case types.SET_PACKETS_COLUMNS_DECODE:
      state.packetsColumnsDecode = action.payload
      break
    case types.SET_PACKETS_SELECTION:
      if (action.payload.selection === null) {
        // Remove the packets selection with id.
        state.packetsSelection = state.packetsSelection.filter(
          (item: PacketsSelection) => item.id !== action.payload.id
        )
      } else {
        const selectionWithId = state.packetsSelection.find(
          (item: PacketsSelection) => item.id === action.payload.id
        )
        if (selectionWithId) {
          // Modify the packets selection in place.
          selectionWithId.selection = action.payload.selection
        } else {
          // Add new packets selection.
          state.packetsSelection.push(action.payload)
        }
      }
      break
    case types.SET_PACKETS_SHOW_DECODE_VIEW:
      state.showDecodeView = action.payload
      break
    case types.SET_PACKETS_DECODE_PACKET_NUMBER:
      state.decodePacketNumber = action.payload
      break
    case types.SET_PACKETS_SHOW_FILTER_BAR:
      state.showFilterBar = action.payload
      break
    case types.SET_PACKETS_FILTER_BAR_EXPRESSION:
      state.filterBarExpression = action.payload
      break
    case types.SET_PACKETS_FILTER_BAR_ERROR:
      state.filterBarError = action.payload
      break
    case types.PUSH_RECENT_FILTER_BAR_EXPRESSION:
      if (state.recentFilterBarExpressions) {
        const filtered = state.recentFilterBarExpressions.filter(
          (item: string) => item !== action.payload
        )
        const recent = [action.payload, ...filtered]
        state.recentFilterBarExpressions = recent.slice(0, 8)
      } else {
        state.recentFilterBarExpressions = [action.payload]
      }
      break
    case types.SET_TRAFFIC_HISTORY_WIDGET_CHART_TYPE:
      state.trafficHistoryWidgetChartType = action.payload
      break
    case types.SET_TRAFFIC_HISTORY_WIDGET_CHART_SCALE:
      state.trafficHistoryWidgetChartScale = action.payload
      break
    case types.SET_TRAFFIC_HISTORY_WIDGET_CHART_INTERPOLATION:
      state.trafficHistoryWidgetChartInterpolation = action.payload
      break
    case types.SET_TOP_TALKERS_WIDGET_VIEW_TYPE:
      state.topTalkersWidgetViewType = action.payload
      break
    case types.SET_TOP_TALKERS_WIDGET_CHART_TYPE:
      state.topTalkersWidgetChartType = action.payload
      break
    case types.SET_TOP_TALKERS_WIDGET_CHART_SCALE:
      state.topTalkersWidgetChartScale = action.payload
      break
    case types.SET_TOP_PROTOCOLS_WIDGET_CHART_TYPE:
      state.topProtocolsWidgetChartType = action.payload
      break
    case types.SET_TOP_PROTOCOLS_WIDGET_CHART_SCALE:
      state.topProtocolsWidgetChartScale = action.payload
      break
    case types.SET_TOP_APPLICATIONS_BY_FLOWS_WIDGET_CHART_TYPE:
      state.topApplicationsByFlowsWidgetChartType = action.payload
      break
    case types.SET_TOP_APPLICATIONS_BY_FLOWS_WIDGET_CHART_SCALE:
      state.topApplicationsByFlowsWidgetChartScale = action.payload
      break
    case types.SET_TOP_APPLICATIONS_BY_BYTES_WIDGET_CHART_TYPE:
      state.topApplicationsByBytesWidgetChartType = action.payload
      break
    case types.SET_TOP_APPLICATIONS_BY_BYTES_WIDGET_CHART_SCALE:
      state.topApplicationsByBytesWidgetChartScale = action.payload
      break
    case types.SET_TOP_APPLICATION_CATEGORIES_WIDGET_CHART_TYPE:
      state.topApplicationCategoriesWidgetChartType = action.payload
      break
    case types.SET_TOP_APPLICATION_CATEGORIES_WIDGET_CHART_SCALE:
      state.topApplicationCategoriesWidgetChartScale = action.payload
      break
    case types.SET_APPLICATION_UTILIZATION_WIDGET_CHART_TYPE:
      state.applicationUtilizationWidgetChartType = action.payload
      break
    case types.SET_APPLICATION_UTILIZATION_WIDGET_CHART_SCALE:
      state.applicationUtilizationWidgetChartScale = action.payload
      break
    case types.SET_APPLICATION_UTILIZATION_WIDGET_CHART_INTERPOLATION:
      state.applicationUtilizationWidgetChartInterpolation = action.payload
      break
    case types.SET_APPLICATION_RESPONSE_TIME_WIDGET_CHART_TYPE:
      state.applicationResponseTimeWidgetChartType = action.payload
      break
    case types.SET_APPLICATION_RESPONSE_TIME_WIDGET_CHART_SCALE:
      state.applicationResponseTimeWidgetChartScale = action.payload
      break
    case types.SET_CALL_QUALITY_DISTRIBUTION_WIDGET_CHART_TYPE:
      state.callQualityDistributionWidgetChartType = action.payload
      break
    case types.SET_CALL_QUALITY_DISTRIBUTION_WIDGET_CHART_SCALE:
      state.callQualityDistributionWidgetChartScale = action.payload
      break
    case types.SET_CALL_QUALITY_WIDGET_CHART_TYPE:
      state.callQualityWidgetChartType = action.payload
      break
    case types.SET_CALL_QUALITY_WIDGET_CHART_INTERPOLATION:
      state.callQualityWidgetChartInterpolation = action.payload
      break
    case types.SET_CALL_QUALITY_BY_CODEC_WIDGET_CHART_TYPE:
      state.callQualityByCodecWidgetChartType = action.payload
      break
    case types.SET_CALL_QUALITY_BY_CODEC_WIDGET_CHART_INTERPOLATION:
      state.callQualityByCodecWidgetChartInterpolation = action.payload
      break
    case types.SET_CALL_VOLUME_BY_CODEC_WIDGET_CHART_TYPE:
      state.callVolumeByCodecWidgetChartType = action.payload
      break
    case types.SET_CALL_VOLUME_BY_CODEC_WIDGET_CHART_INTERPOLATION:
      state.callVolumeByCodecWidgetChartInterpolation = action.payload
      break
    case types.SET_CALL_UTILIZATION_WIDGET_CHART_TYPE:
      state.callUtilizationWidgetChartType = action.payload
      break
    case types.SET_CALL_UTILIZATION_WIDGET_CHART_SCALE:
      state.callUtilizationWidgetChartScale = action.payload
      break
    case types.SET_CALL_UTILIZATION_WIDGET_CHART_INTERPOLATION:
      state.callUtilizationWidgetChartInterpolation = action.payload
      break
    case types.SET_SUMMARY_STATS_VIEW_TYPE:
      state.summaryStatsViewType = action.payload
      break
    case types.SET_SUMMARY_STATS_FILTER:
      state.summaryStatsFilter = action.payload
      break
    case types.SET_NODE_STATS_VIEW_TYPE:
      state.nodeStatsViewType = action.payload
      break
    case types.SET_NODE_STATS_FILTER:
      state.nodeStatsFilter = action.payload
      break
    case types.SET_NODE_STATS_COLUMNS:
      state.nodeStatsColumns = action.payload
      break
    case types.SET_NODE_STATS_SORT:
      state.nodeStatsSortBy = action.payload.sortBy
      state.nodeStatsSortDirection = action.payload.sortDirection
      break
    case types.SET_NODE_STATS_HIERARCHY_COLUMNS:
      state.nodeStatsHierarchyColumns = action.payload
      break
    case types.SET_PROTOCOL_STATS_VIEW_TYPE:
      state.protocolStatsViewType = action.payload
      break
    case types.SET_PROTOCOL_STATS_FILTER:
      state.protocolStatsFilter = action.payload
      break
    case types.SET_PROTOCOL_STATS_SORT:
      state.protocolStatsSortBy = action.payload.sortBy
      state.protocolStatsSortDirection = action.payload.sortDirection
      break
    case types.SET_APP_STATS_FILTER:
      state.appStatsFilter = action.payload
      break
    case types.SET_APP_STATS_COLUMNS:
      state.appStatsColumns = action.payload
      break
    case types.SET_APP_STATS_SORT:
      state.appStatsSortBy = action.payload.sortBy
      state.appStatsSortDirection = action.payload.sortDirection
      break
    case types.SET_APP_STATS_VIEW_TYPE:
      state.appStatsViewType = action.payload
      break
    case types.SET_FILE_RECONSTRUCTOR_FILTER:
      state.reconstructionsFilter = action.payload
      break
    case types.SET_FILE_RECONSTRUCTOR_COLUMNS:
      state.reconstructionsColumns = action.payload
      break
    case types.SET_FILE_RECONSTRUCTOR_SORT:
      state.reconstructionsSortBy = action.payload.sortBy
      state.reconstructionsSortDirection = action.payload.sortDirection
      break
    case types.SET_COUNTRY_STATS_FILTER:
      state.countryStatsFilter = action.payload
      break
    case types.SET_COUNTRY_STATS_COLUMNS:
      state.countryStatsColumns = action.payload
      break
    case types.SET_COUNTRY_STATS_SORT:
      state.countryStatsSortBy = action.payload.sortBy
      state.countryStatsSortDirection = action.payload.sortDirection
      break
    case types.SET_MPLS_VLAN_VXLAN_STATS_FILTER:
      state.mplsVlanVxlanStatsFilter = action.payload
      break
    case types.SET_MPLS_VLAN_VXLAN_STATS_COLUMNS:
      state.mplsVlanVxlanStatsColumns = action.payload
      break
    case types.SET_MPLS_VLAN_VXLAN_STATS_SORT:
      state.mplsVlanVxlanStatsSortBy = action.payload.sortBy
      state.mplsVlanVxlanStatsSortDirection = action.payload.sortDirection
      break
    case types.SET_EXPERT_CLIENTS_SERVERS_COLUMNS:
      state.expertClientsServersColumns = action.payload
      break
    case types.SET_EXPERT_CLIENTS_SERVERS_SORT:
      state.expertClientsServersSortBy = action.payload.sortBy
      state.expertClientsServersSortDirection = action.payload.sortDirection
      break
    case types.SET_EXPERT_FLOWS_SEARCH:
      state.expertFlowsSearch = action.payload
      break
    case types.SET_EXPERT_FLOWS_COLUMNS:
      state.expertFlowsColumns = action.payload
      break
    case types.SET_EXPERT_FLOWS_SORT:
      state.expertFlowsSortBy = action.payload.sortBy
      state.expertFlowsSortDirection = action.payload.sortDirection
      break
    case types.SET_EXPERT_APPS_COLUMNS:
      state.expertAppsColumns = action.payload
      break
    case types.SET_EXPERT_APPS_SORT:
      state.expertAppsSortBy = action.payload.sortBy
      state.expertAppsSortDirection = action.payload.sortDirection
      break
    case types.SET_EXPERT_EVENT_SUMMARY_SORT:
      state.expertEventSummarySortBy = action.payload.sortBy
      state.expertEventSummarySortDirection = action.payload.sortDirection
      break
    case types.SET_EXPERT_EVENT_LOG_COLUMNS:
      state.expertEventLogColumns = action.payload
      break
    case types.SET_EXPERT_EVENT_LOG_SORT:
      state.expertEventLogSortBy = action.payload.sortBy
      state.expertEventLogSortDirection = action.payload.sortDirection
      break
    case types.SET_VOIP_CALLS_COLUMNS:
      state.voipCallsColumns = action.payload
      break
    case types.SET_VOIP_CALLS_SORT:
      state.voipCallsSortBy = action.payload.sortBy
      state.voipCallsSortDirection = action.payload.sortDirection
      break
    case types.SET_VOIP_MEDIA_COLUMNS:
      state.voipMediaColumns = action.payload
      break
    case types.SET_VOIP_MEDIA_SORT:
      state.voipMediaSortBy = action.payload.sortBy
      state.voipMediaSortDirection = action.payload.sortDirection
      break
    case types.SET_FLOW_VISUALIZER_VIEW_TYPE:
      state.flowVisualizerViewType = action.payload
      break
    case types.SET_FLOW_VISUALIZER_COLUMNS:
      state.flowVisualizerColumns = action.payload
      break
    case types.SET_FLOW_VISUALIZER_RELATIVE_SEQ_ACK:
      state.flowVisualizerRelativeSeqAck = action.payload
      break
    case types.SET_FLOW_VISUALIZER_RELATIVE_TIME:
      state.flowVisualizerRelativeTime = action.payload
      break
    case types.SET_FLOW_VISUALIZER_MARK_RELATED_PACKETS:
      state.flowVisualizerMarkRelatedPackets = action.payload
      break
    case types.SET_FLOW_VISUALIZER_SHOW_CLIENT_PAYLOAD:
      state.flowVisualizerShowClientPayload = action.payload
      break
    case types.SET_FLOW_VISUALIZER_SHOW_SERVER_PAYLOAD:
      state.flowVisualizerShowServerPayload = action.payload
      break
    case types.SET_FLOW_VISUALIZER_SHOW_PAYLOAD_PACKET_LABELS:
      state.flowVisualizerShowPayloadPacketLabels = action.payload
      break
    case types.SET_FLOW_VISUALIZER_SHOW_PAYLOAD_CRLF:
      state.flowVisualizerShowPayloadCRLF = action.payload
      break
    case types.SET_FLOW_VISUALIZER_GRAPH_TYPE:
      state.flowVisualizerGraphType = action.payload
      break
    case types.SET_FLOW_VISUALIZER_GRAPH_DIRECTION:
      state.flowVisualizerGraphDirection = action.payload
      break
    case types.SET_VOIP_CALL_VISUALIZER_VIEW_TYPE:
      state.voipCallVisualizerViewType = action.payload
      break
    case types.SET_VOIP_CALL_VISUALIZER_COLUMNS:
      state.voipCallVisualizerColumns = action.payload
      break
    case types.SET_VOIP_CALL_VISUALIZER_SORT:
      state.voipCallVisualizerSortBy = action.payload.sortBy
      state.voipCallVisualizerSortDirection = action.payload.sortDirection
      break
    case types.SET_GRAPHS_SHOW_LIST:
      state.graphsShowList = action.payload
      break
    case types.SET_GRAPHS_ENABLED:
      state.graphsEnabled = action.payload
      break
    case types.SET_PEER_MAP_OPTIONS:
      state.peerMapOptions = {
        ...state.peerMapOptions,
        ...action.payload,
      }
      break
    case types.SET_MSA_LIST_FILTER:
      state.msaListFilter = action.payload
      break
    case types.SET_MSA_VIEW_TYPE:
      state.msaViewType = action.payload
      break
    case types.SET_MSA_FLOWS_COLUMNS:
      state.msaFlowsColumns = action.payload
      break
    case types.SET_MSA_FLOWS_SORT:
      state.msaFlowsSortBy = action.payload.sortBy
      state.msaFlowsSortDirection = action.payload.sortDirection
      break
    case types.SET_MSA_FLOWS_FILTER:
      state.msaFlowsFilter = action.payload
      break
    case types.SET_MSA_LADDER_VIEW_OPTION:
      state.msaLadderViewOptions = action.payload
      break
    case types.SET_WIRELESS_NODE_COLUMNS:
      state.wirelessNodesColumns = action.payload
      break
    case types.SET_WIRELESS_NODE_VIEW_TYPE:
      state.wirelessNodesViewType = action.payload
      break
    case types.SET_WIRELESS_NODES_SORT:
      state.wirelessNodesSortBy = action.payload.sortBy
      state.wirelessNodesSortDirection = action.payload.sortDirection
      break
    case types.SET_AUTHENTICATION_TOKENS_FILTER:
      state.authenticationTokensFilter = action.payload
      break
    case types.SET_AUTHENTICATION_TOKENS_SORT:
      state.authenticationTokensSortBy = action.payload.sortBy
      state.authenticationTokensSortDirection = action.payload.sortDirection
      break
    case types.SET_WEB_SERVERS_COLUMNS:
      state.webServersColumns = action.payload
      break
    case types.SET_WEB_SERVERS_SORT:
      state.webServersSortBy = action.payload.sortBy
      state.webServersSortDirection = action.payload.sortDirection
      break
    case types.SET_WEB_CLIENTS_COLUMNS:
      state.webClientsColumns = action.payload
      break
    case types.SET_WEB_CLIENTS_SORT:
      state.webClientsSortBy = action.payload.sortBy
      state.webClientsSortDirection = action.payload.sortDirection
      break
    case types.SET_WEB_PAGES_COLUMNS:
      state.webPagesColumns = action.payload
      break
    case types.SET_WEB_PAGES_SORT:
      state.webPagesSortBy = action.payload.sortBy
      state.webPagesSortDirection = action.payload.sortDirection
      break
    case types.SET_WEB_REQUESTS_COLUMNS:
      state.webRequestsColumns = action.payload
      break
    case types.SET_WEB_REQUESTS_SORT:
      state.webRequestsSortBy = action.payload.sortBy
      state.webRequestsSortDirection = action.payload.sortDirection
      break
    default:
      break
  }

  return state
})

// Actions

export const setIsNavOpen = (isNavOpen: boolean) => ({
  type: types.SET_NAV_OPEN,
  payload: isNavOpen,
})

export const setShowAddressNames = (show: boolean) => ({
  type: types.SET_SHOW_ADDRESS_NAMES,
  payload: show,
})

export const setShowPortNames = (show: boolean) => ({
  type: types.SET_SHOW_PORT_NAMES,
  payload: show,
})

export const setShowLocalTime = (show: boolean) => ({
  type: types.SET_SHOW_LOCAL_TIME,
  payload: show,
})

export const setEnginesFilter = (filter: string) => ({
  type: types.SET_ENGINES_FILTER,
  payload: filter,
})

export const setEnginesSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_ENGINES_SORT,
  payload: { sortBy, sortDirection },
})

export const setEnginesCollapsedGroups = (collapsedGroups: Set<string>) => ({
  type: types.SET_ENGINES_COLLAPSED_GROUPS,
  payload: collapsedGroups,
})

export const setEnginesChecked = (checked: Set<string>) => ({
  type: types.SET_ENGINES_CHECKED,
  payload: checked,
})

export const setCapturesViewType = (viewType: string) => ({
  type: types.SET_CAPTURES_VIEW_TYPE,
  payload: viewType,
})

export const setCapturesFilter = (filter: string) => ({
  type: types.SET_CAPTURES_FILTER,
  payload: filter,
})

export const setCapturesColumns = (columns: any[]) => ({
  type: types.SET_CAPTURES_COLUMNS,
  payload: columns,
})

export const setCapturesSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_CAPTURES_SORT,
  payload: { sortBy, sortDirection },
})

export const setForensicSearchesColumns = (columns: any[]) => ({
  type: types.SET_FORENSIC_SEARCHES_COLUMNS,
  payload: columns,
})

export const setForensicSearchesFilter = (filter: string) => ({
  type: types.SET_FORENSIC_SEARCHES_FILTER,
  payload: filter,
})

export const setForensicSearchesSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_FORENSIC_SEARCHES_SORT,
  payload: { sortBy, sortDirection },
})

export const setForensicSearchesViewType = (viewType: string) => ({
  type: types.SET_FORENSIC_SEARCHES_VIEW_TYPE,
  payload: viewType,
})

export const setForensicSearchHistoryFilter = (filter: string) => ({
  type: types.SET_FORENSIC_SEARCH_HISTORY_FILTER,
  payload: filter,
})

export const setAnalysisModulesFilter = (filter: string) => ({
  type: types.SET_ANALYSIS_MODULES_FILTER,
  payload: filter,
})

export const setAuditLogRefreshInterval = (refreshInterval: number) => ({
  type: types.SET_AUDIT_LOG_REFRESH_INTERVAL,
  payload: refreshInterval,
})

export const setForensicsViewRefreshInterval = (refreshInterval: number) => ({
  type: types.SET_FORENSICS_VIEW_REFRESH_INTERVAL,
  payload: refreshInterval,
})

export const setForensicsViewType = (viewType: number) => ({
  type: types.SET_FORENSICS_VIEW_TYPE,
  payload: viewType,
})

export const setForensicsSampleInterval = (sampleInterval: number) => ({
  type: types.SET_FORENSICS_SAMPLE_INTERVAL,
  payload: sampleInterval,
})

export const setForensicsTopTalkersViewType = (viewType: string) => ({
  type: types.SET_FORENSICS_TOP_TALKERS_VIEW_TYPE,
  payload: viewType,
})

export const setForensicsTopProtocolsViewType = (viewType: string) => ({
  type: types.SET_FORENSICS_TOP_PROTOCOLS_VIEW_TYPE,
  payload: viewType,
})

export const setForensicsTopStatsChartType = (chartType: string) => ({
  type: types.SET_FORENSICS_TOP_STATS_CHART_TYPE,
  payload: chartType,
})

export const setForensicsTopStatsChartScale = (chartScale: string) => ({
  type: types.SET_FORENSICS_TOP_STATS_CHART_SCALE,
  payload: chartScale,
})

export const setForensicsTimelineChartType = (chartType: string) => ({
  type: types.SET_FORENSICS_TIMELINE_CHART_TYPE,
  payload: chartType,
})

export const setForensicsTimelineChartScale = (chartScale: string) => ({
  type: types.SET_FORENSICS_TIMELINE_CHART_SCALE,
  payload: chartScale,
})

export const setForensicsTimelineChartInterpolation = (chartInterpolation: string) => ({
  type: types.SET_FORENSICS_TIMELINE_CHART_INTERPOLATION,
  payload: chartInterpolation,
})

export const setForensicsTimelineSelection = (
  selection: boolean,
  start: string | number,
  end: string | number
) => ({
  type: types.SET_FORENSICS_TIMELINE_SELECTION,
  payload: { selection, start, end },
})

export const setCaptureSessionsFilter = (filter: string) => ({
  type: types.SET_CAPTURE_SESSIONS_FILTER,
  payload: filter,
})

export const setCaptureSessionsColumns = (columns: any[]) => ({
  type: types.SET_CAPTURE_SESSIONS_COLUMNS,
  payload: columns,
})

export const setCaptureSessionsSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_CAPTURE_SESSIONS_SORT,
  payload: { sortBy, sortDirection },
})

export const setCaptureSessionsCollapsedGroups = (collapsedGroups: Set<string>) => ({
  type: types.SET_CAPTURE_SESSIONS_COLLAPSED_GROUPS,
  payload: collapsedGroups,
})

export const setDecryptionKeysFilter = (filter: string) => ({
  type: types.SET_DECRYPTION_KEYS_FILTER,
  payload: filter,
})

export const setDecryptionKeysSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_DECRYPTION_KEYS_SORT,
  payload: { sortBy, sortDirection },
})

export const setFilesFilter = (filter: string) => ({
  type: types.SET_FILES_FILTER,
  payload: filter,
})

export const setFilesColumns = (columns: any[]) => ({
  type: types.SET_FILES_COLUMNS,
  payload: columns,
})

export const setFilesSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_FILES_SORT,
  payload: { sortBy, sortDirection },
})

export const setFilesCollapsedGroups = (collapsedGroups: Set<string>) => ({
  type: types.SET_FILES_COLLAPSED_GROUPS,
  payload: collapsedGroups,
})

export const setFiltersFilter = (filter: string) => ({
  type: types.SET_FILTERS_FILTER,
  payload: filter,
})

export const setFiltersSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_FILTERS_SORT,
  payload: { sortBy, sortDirection },
})

export const setFiltersExpandedGroups = (expandedGroups: Set<string>) => ({
  type: types.SET_FILTERS_EXPANDED_GROUPS,
  payload: expandedGroups,
})

export const setFiltersChecked = (checked: Set<string>) => ({
  type: types.SET_FILTERS_CHECKED,
  payload: checked,
})

export const setGraphsFilter = (filter: string) => ({
  type: types.SET_GRAPHS_FILTER,
  payload: filter,
})

export const setGraphsSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_GRAPHS_SORT,
  payload: { sortBy, sortDirection },
})

export const setHardwareProfilesFilter = (filter: string) => ({
  type: types.SET_HARDWARE_PROFILES_FILTER,
  payload: filter,
})

export const setHardwareProfilesSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_HARDWARE_PROFILES_SORT,
  payload: { sortBy, sortDirection },
})

export const setHardwareProfilesChecked = (checked: Set<string>) => ({
  type: types.SET_HARDWARE_PROFILES_CHECKED,
  payload: checked,
})

export const setAlarmsFilter = (filter: string) => ({
  type: types.SET_ALARMS_FILTER,
  payload: filter,
})

export const setAlarmsSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_ALARMS_SORT,
  payload: { sortBy, sortDirection },
})

export const setNamesViewType = (viewType: string) => ({
  type: types.SET_NAMES_VIEW_TYPE,
  payload: viewType,
})

export const setNamesFilter = (filter: string) => ({
  type: types.SET_NAMES_FILTER,
  payload: filter,
})

export const setNamesAddressesSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_NAMES_ADDRESSES_SORT,
  payload: { sortBy, sortDirection },
})

export const setNamesProtocolsSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_NAMES_PROTOCOLS_SORT,
  payload: { sortBy, sortDirection },
})

export const setNamesPortsSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_NAMES_PORTS_SORT,
  payload: { sortBy, sortDirection },
})

export const setNamesAddressesExpandedGroups = (expandedGroups: Set<string>) => ({
  type: types.SET_NAMES_ADDRESSES_EXPANDED_GROUPS,
  payload: expandedGroups,
})

export const setNamesProtocolsExpandedGroups = (expandedGroups: Set<string>) => ({
  type: types.SET_NAMES_PROTOCOLS_EXPANDED_GROUPS,
  payload: expandedGroups,
})

export const setNamesPortsExpandedGroups = (expandedGroups: Set<string>) => ({
  type: types.SET_NAMES_PORTS_EXPANDED_GROUPS,
  payload: expandedGroups,
})

export const setNamesAddressesChecked = (checked: Set<string>) => ({
  type: types.SET_NAMES_ADDRESSES_CHECKED,
  payload: checked,
})

export const setNamesProtocolsChecked = (checked: Set<string>) => ({
  type: types.SET_NAMES_PROTOCOLS_CHECKED,
  payload: checked,
})

export const setNamesPortsChecked = (checked: Set<string>) => ({
  type: types.SET_NAMES_PORTS_CHECKED,
  payload: checked,
})

export const setNotificationsFilter = (filter: string) => ({
  type: types.SET_NOTIFICATIONS_FILTER,
  payload: filter,
})

export const setNotificationsSort = (sortDirection: SortDirectionType) => ({
  type: types.SET_NOTIFICATIONS_SORT,
  payload: sortDirection,
})

export const setAuditLogSearch = (search: any) => ({
  type: types.SET_AUDIT_LOG_SEARCH,
  payload: search,
})

export const setForensicSearchHistorySearch = (search: any) => ({
  type: types.SET_FORENSIC_SEARCH_HISTORY_SEARCH,
  payload: search,
})

export const setForensicSearchHistorySortAsc = (sortAsc: any) => ({
  type: types.SET_FORENSIC_SEARCH_HISTORY_SORT_ASC,
  payload: sortAsc,
})

export const setForensicSearchHistorySortBy = (sortBy: any) => ({
  type: types.SET_FORENSIC_SEARCH_HISTORY_SORT_BY,
  payload: sortBy,
})

export const setPacketsColumns = (columns: any[]) => ({
  type: types.SET_PACKETS_COLUMNS,
  payload: columns,
})

export const setPacketsColumnsDecode = (decodeColumns: any[]) => ({
  type: types.SET_PACKETS_COLUMNS_DECODE,
  payload: decodeColumns,
})

export const setPacketsSelection = (
  id: string,
  selection: ResultsSelectRelatedResponse | null
) => ({
  type: types.SET_PACKETS_SELECTION,
  payload: { id, selection },
})

export const setPacketsShowDecodeView = (show: boolean) => ({
  type: types.SET_PACKETS_SHOW_DECODE_VIEW,
  payload: show,
})

export const setPacketsDecodePacketNumber = (packetNumber: number) => ({
  type: types.SET_PACKETS_DECODE_PACKET_NUMBER,
  payload: packetNumber,
})

export const setPacketsShowFilterBar = (show: boolean) => ({
  type: types.SET_PACKETS_SHOW_FILTER_BAR,
  payload: show,
})

export const setPacketsFilterBarExpression = (expression: string) => ({
  type: types.SET_PACKETS_FILTER_BAR_EXPRESSION,
  payload: expression,
})

export const setPacketsFilterBarError = (error: string) => ({
  type: types.SET_PACKETS_FILTER_BAR_ERROR,
  payload: error,
})

export const pushRecentFilterBarExpression = (expression: string) => ({
  type: types.PUSH_RECENT_FILTER_BAR_EXPRESSION,
  payload: expression,
})

export const setTrafficHistoryWidgetChartType = (chartType: string) => ({
  type: types.SET_TRAFFIC_HISTORY_WIDGET_CHART_TYPE,
  payload: chartType,
})

export const setTrafficHistoryWidgetChartScale = (chartScale: string) => ({
  type: types.SET_TRAFFIC_HISTORY_WIDGET_CHART_SCALE,
  payload: chartScale,
})

export const setTrafficHistoryWidgetChartInterpolation = (chartInterpolation: string) => ({
  type: types.SET_TRAFFIC_HISTORY_WIDGET_CHART_INTERPOLATION,
  payload: chartInterpolation,
})

export const setTopTalkersWidgetViewType = (viewType: string) => ({
  type: types.SET_TOP_TALKERS_WIDGET_VIEW_TYPE,
  payload: viewType,
})

export const setTopTalkersWidgetChartType = (chartType: string) => ({
  type: types.SET_TOP_TALKERS_WIDGET_CHART_TYPE,
  payload: chartType,
})

export const setTopTalkersWidgetChartScale = (chartScale: string) => ({
  type: types.SET_TOP_TALKERS_WIDGET_CHART_SCALE,
  payload: chartScale,
})

export const setTopProtocolsWidgetChartType = (chartType: string) => ({
  type: types.SET_TOP_PROTOCOLS_WIDGET_CHART_TYPE,
  payload: chartType,
})

export const setTopProtocolsWidgetChartScale = (chartScale: string) => ({
  type: types.SET_TOP_PROTOCOLS_WIDGET_CHART_SCALE,
  payload: chartScale,
})

export const setTopApplicationsByFlowsWidgetChartType = (chartType: string) => ({
  type: types.SET_TOP_APPLICATIONS_BY_FLOWS_WIDGET_CHART_TYPE,
  payload: chartType,
})

export const setTopApplicationsByFlowsWidgetChartScale = (chartScale: string) => ({
  type: types.SET_TOP_APPLICATIONS_BY_FLOWS_WIDGET_CHART_SCALE,
  payload: chartScale,
})

export const setTopApplicationsByBytesWidgetChartType = (chartType: string) => ({
  type: types.SET_TOP_APPLICATIONS_BY_BYTES_WIDGET_CHART_TYPE,
  payload: chartType,
})

export const setTopApplicationsByBytesWidgetChartScale = (chartScale: string) => ({
  type: types.SET_TOP_APPLICATIONS_BY_BYTES_WIDGET_CHART_SCALE,
  payload: chartScale,
})

export const setTopApplicationCategoriesWidgetChartType = (chartType: string) => ({
  type: types.SET_TOP_APPLICATION_CATEGORIES_WIDGET_CHART_TYPE,
  payload: chartType,
})

export const setTopApplicationCategoriesWidgetChartScale = (chartScale: string) => ({
  type: types.SET_TOP_APPLICATION_CATEGORIES_WIDGET_CHART_SCALE,
  payload: chartScale,
})

export const setApplicationUtilizationWidgetChartType = (chartType: string) => ({
  type: types.SET_APPLICATION_UTILIZATION_WIDGET_CHART_TYPE,
  payload: chartType,
})

export const setApplicationUtilizationWidgetChartScale = (chartScale: string) => ({
  type: types.SET_APPLICATION_UTILIZATION_WIDGET_CHART_SCALE,
  payload: chartScale,
})

export const setApplicationUtilizationWidgetChartInterpolation = (chartInterpolation: string) => ({
  type: types.SET_APPLICATION_UTILIZATION_WIDGET_CHART_INTERPOLATION,
  payload: chartInterpolation,
})

export const setApplicationResponseTimeWidgetChartType = (chartType: string) => ({
  type: types.SET_APPLICATION_RESPONSE_TIME_WIDGET_CHART_TYPE,
  payload: chartType,
})

export const setApplicationResponseTimeWidgetChartScale = (chartScale: string) => ({
  type: types.SET_APPLICATION_RESPONSE_TIME_WIDGET_CHART_SCALE,
  payload: chartScale,
})

export const setCallQualityDistributionWidgetChartType = (chartType: string) => ({
  type: types.SET_CALL_QUALITY_DISTRIBUTION_WIDGET_CHART_TYPE,
  payload: chartType,
})

export const setCallQualityDistributionWidgetChartScale = (chartScale: string) => ({
  type: types.SET_CALL_QUALITY_DISTRIBUTION_WIDGET_CHART_SCALE,
  payload: chartScale,
})

export const setCallQualityWidgetChartType = (chartType: string) => ({
  type: types.SET_CALL_QUALITY_WIDGET_CHART_TYPE,
  payload: chartType,
})

export const setCallQualityWidgetChartInterpolation = (chartInterpolation: string) => ({
  type: types.SET_CALL_QUALITY_WIDGET_CHART_INTERPOLATION,
  payload: chartInterpolation,
})

export const setCallQualityByCodecWidgetChartType = (chartType: string) => ({
  type: types.SET_CALL_QUALITY_BY_CODEC_WIDGET_CHART_TYPE,
  payload: chartType,
})

export const setCallQualityByCodecWidgetChartInterpolation = (chartInterpolation: string) => ({
  type: types.SET_CALL_QUALITY_BY_CODEC_WIDGET_CHART_INTERPOLATION,
  payload: chartInterpolation,
})

export const setCallVolumeByCodecWidgetChartType = (chartType: string) => ({
  type: types.SET_CALL_VOLUME_BY_CODEC_WIDGET_CHART_TYPE,
  payload: chartType,
})

export const setCallVolumeByCodecWidgetChartInterpolation = (chartInterpolation: string) => ({
  type: types.SET_CALL_VOLUME_BY_CODEC_WIDGET_CHART_INTERPOLATION,
  payload: chartInterpolation,
})

export const setCallUtilizationWidgetChartType = (chartType: string) => ({
  type: types.SET_CALL_UTILIZATION_WIDGET_CHART_TYPE,
  payload: chartType,
})

export const setCallUtilizationWidgetChartScale = (chartScale: string) => ({
  type: types.SET_CALL_UTILIZATION_WIDGET_CHART_SCALE,
  payload: chartScale,
})

export const setCallUtilizationWidgetChartInterpolation = (chartInterpolation: string) => ({
  type: types.SET_CALL_UTILIZATION_WIDGET_CHART_INTERPOLATION,
  payload: chartInterpolation,
})

export const setSummaryStatsViewType = (viewType: string) => ({
  type: types.SET_SUMMARY_STATS_VIEW_TYPE,
  payload: viewType,
})

export const setSummaryStatsFilter = (filter: string) => ({
  type: types.SET_SUMMARY_STATS_FILTER,
  payload: filter,
})

export const setNodeStatsViewType = (viewType: string) => ({
  type: types.SET_NODE_STATS_VIEW_TYPE,
  payload: viewType,
})

export const setNodeStatsFilter = (filter: string) => ({
  type: types.SET_NODE_STATS_FILTER,
  payload: filter,
})

export const setNodeStatsColumns = (columns: any[]) => ({
  type: types.SET_NODE_STATS_COLUMNS,
  payload: columns,
})

export const setNodeStatsSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_NODE_STATS_SORT,
  payload: { sortBy, sortDirection },
})

export const setNodeHierarchyStatsColumns = (columns: any[]) => ({
  type: types.SET_NODE_STATS_HIERARCHY_COLUMNS,
  payload: columns,
})

export const setProtocolStatsViewType = (viewType: string) => ({
  type: types.SET_PROTOCOL_STATS_VIEW_TYPE,
  payload: viewType,
})

export const setProtocolStatsFilter = (filter: string) => ({
  type: types.SET_PROTOCOL_STATS_FILTER,
  payload: filter,
})

export const setProtocolStatsSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_PROTOCOL_STATS_SORT,
  payload: { sortBy, sortDirection },
})

export const setAppStatsFilter = (filter: string) => ({
  type: types.SET_APP_STATS_FILTER,
  payload: filter,
})

export const setAppStatsColumns = (columns: any[]) => ({
  type: types.SET_APP_STATS_COLUMNS,
  payload: columns,
})

export const setAppStatsSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_APP_STATS_SORT,
  payload: { sortBy, sortDirection },
})

export const setAppStatsViewType = (viewType: string) => ({
  type: types.SET_APP_STATS_VIEW_TYPE,
  payload: viewType,
})

export const setReconstructionsColumns = (columns: any[]) => ({
  type: types.SET_FILE_RECONSTRUCTOR_COLUMNS,
  payload: columns,
})

export const setReconstructionsFilter = (filter: string) => ({
  type: types.SET_FILE_RECONSTRUCTOR_FILTER,
  payload: filter,
})

export const setReconstructionsSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_FILE_RECONSTRUCTOR_SORT,
  payload: { sortBy, sortDirection },
})

export const setCountryStatsFilter = (filter: string) => ({
  type: types.SET_COUNTRY_STATS_FILTER,
  payload: filter,
})

export const setCountryStatsColumns = (columns: any[]) => ({
  type: types.SET_COUNTRY_STATS_COLUMNS,
  payload: columns,
})

export const setCountryStatsSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_COUNTRY_STATS_SORT,
  payload: { sortBy, sortDirection },
})

export const setMPLSVLANVXLANStatsFilter = (filter: string) => ({
  type: types.SET_MPLS_VLAN_VXLAN_STATS_FILTER,
  payload: filter,
})

export const setMPLSVLANVXLANStatsColumns = (columns: any[]) => ({
  type: types.SET_MPLS_VLAN_VXLAN_STATS_COLUMNS,
  payload: columns,
})

export const setMPLSVLANVXLANStatsSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_MPLS_VLAN_VXLAN_STATS_SORT,
  payload: { sortBy, sortDirection },
})

export const setExpertClientsServersColumns = (columns: ExpertTableColumn[]) => ({
  type: types.SET_EXPERT_CLIENTS_SERVERS_COLUMNS,
  payload: columns,
})

export const setExpertClientsServersSort = (
  sortBy: ExpertColumn,
  sortDirection: SortDirectionType
) => ({
  type: types.SET_EXPERT_CLIENTS_SERVERS_SORT,
  payload: { sortBy, sortDirection },
})

export const setExpertFlowsSearch = (search: any) => ({
  type: types.SET_EXPERT_FLOWS_SEARCH,
  payload: search,
})

export const setExpertFlowsColumns = (columns: ExpertTableColumn[]) => ({
  type: types.SET_EXPERT_FLOWS_COLUMNS,
  payload: columns,
})

export const setExpertFlowsSort = (sortBy: ExpertColumn, sortDirection: SortDirectionType) => ({
  type: types.SET_EXPERT_FLOWS_SORT,
  payload: { sortBy, sortDirection },
})

export const setExpertAppsColumns = (columns: ExpertTableColumn[]) => ({
  type: types.SET_EXPERT_APPS_COLUMNS,
  payload: columns,
})

export const setExpertAppsSort = (sortBy: ExpertColumn, sortDirection: SortDirectionType) => ({
  type: types.SET_EXPERT_APPS_SORT,
  payload: { sortBy, sortDirection },
})

export const setExpertEventSummarySort = (
  sortBy: ExpertColumn,
  sortDirection: SortDirectionType
) => ({
  type: types.SET_EXPERT_EVENT_SUMMARY_SORT,
  payload: { sortBy, sortDirection },
})

export const setExpertEventLogColumns = (columns: ExpertTableColumn[]) => ({
  type: types.SET_EXPERT_EVENT_LOG_COLUMNS,
  payload: columns,
})

export const setExpertEventLogSort = (sortBy: ExpertColumn, sortDirection: SortDirectionType) => ({
  type: types.SET_EXPERT_EVENT_LOG_SORT,
  payload: { sortBy, sortDirection },
})

export const setVoIPCallsColumns = (columns: ExpertTableColumn[]) => ({
  type: types.SET_VOIP_CALLS_COLUMNS,
  payload: columns,
})

export const setVoIPCallsSort = (sortBy: ExpertColumn, sortDirection: SortDirectionType) => ({
  type: types.SET_VOIP_CALLS_SORT,
  payload: { sortBy, sortDirection },
})

export const setVoIPMediaColumns = (columns: ExpertTableColumn[]) => ({
  type: types.SET_VOIP_MEDIA_COLUMNS,
  payload: columns,
})

export const setVoIPMediaSort = (sortBy: ExpertColumn, sortDirection: SortDirectionType) => ({
  type: types.SET_VOIP_MEDIA_SORT,
  payload: { sortBy, sortDirection },
})

export const setFlowVisualizerViewType = (viewType: string) => ({
  type: types.SET_FLOW_VISUALIZER_VIEW_TYPE,
  payload: viewType,
})

export const setFlowVisualizerColumns = (columns: any[]) => ({
  type: types.SET_FLOW_VISUALIZER_COLUMNS,
  payload: columns,
})

export const setFlowVisualizerRelativeSeqAck = (isRelativeSeqAck: boolean) => ({
  type: types.SET_FLOW_VISUALIZER_RELATIVE_SEQ_ACK,
  payload: isRelativeSeqAck,
})

export const setFlowVisualizerRelativeTime = (isRelativeTime: boolean) => ({
  type: types.SET_FLOW_VISUALIZER_RELATIVE_TIME,
  payload: isRelativeTime,
})

export const setFlowVisualizerMarkRelatedPackets = (markRelatedPackets: boolean) => ({
  type: types.SET_FLOW_VISUALIZER_MARK_RELATED_PACKETS,
  payload: markRelatedPackets,
})

export const setFlowVisualizerShowClientPayload = (showClientPayload: boolean) => ({
  type: types.SET_FLOW_VISUALIZER_SHOW_CLIENT_PAYLOAD,
  payload: showClientPayload,
})

export const setFlowVisualizerShowServerPayload = (showServerPayload: boolean) => ({
  type: types.SET_FLOW_VISUALIZER_SHOW_SERVER_PAYLOAD,
  payload: showServerPayload,
})

export const setFlowVisualizerShowPayloadPacketLabels = (showPayloadPacketLabels: boolean) => ({
  type: types.SET_FLOW_VISUALIZER_SHOW_PAYLOAD_PACKET_LABELS,
  payload: showPayloadPacketLabels,
})

export const setFlowVisualizerShowPayloadCRLF = (showPayloadCRLF: boolean) => ({
  type: types.SET_FLOW_VISUALIZER_SHOW_PAYLOAD_CRLF,
  payload: showPayloadCRLF,
})

export const setFlowVisualizerGraphType = (graphType: string) => ({
  type: types.SET_FLOW_VISUALIZER_GRAPH_TYPE,
  payload: graphType,
})

export const setFlowVisualizerGraphDirection = (graphDirection: string) => ({
  type: types.SET_FLOW_VISUALIZER_GRAPH_DIRECTION,
  payload: graphDirection,
})

export const setVoIPCallVisualizerViewType = (viewType: string) => ({
  type: types.SET_VOIP_CALL_VISUALIZER_VIEW_TYPE,
  payload: viewType,
})

export const setVoIPCallVisualizerColumns = (columns: any[]) => ({
  type: types.SET_VOIP_CALL_VISUALIZER_COLUMNS,
  payload: columns,
})

export const setVoIPCallVisualizerSort = (
  sortBy: ExpertColumn,
  sortDirection: SortDirectionType
) => ({
  type: types.SET_VOIP_CALL_VISUALIZER_SORT,
  payload: { sortBy, sortDirection },
})

export const setGraphsShowList = (show: boolean) => ({
  type: types.SET_GRAPHS_SHOW_LIST,
  payload: show,
})

export const setGraphsEnabled = (graphsEnabled: any[]) => ({
  type: types.SET_GRAPHS_ENABLED,
  payload: graphsEnabled,
})

export const setPeerMapOptions = (captureId: string, options: PeerMapOptions) => ({
  type: types.SET_PEER_MAP_OPTIONS,
  payload: {
    [captureId]: options,
  },
})

export const setMSAListFilter = (filter: string) => ({
  type: types.SET_MSA_LIST_FILTER,
  payload: filter,
})

export const setMSAViewType = (viewType: string) => ({
  type: types.SET_MSA_VIEW_TYPE,
  payload: viewType,
})

export const setMSAFlowsColumns = (columns: any[]) => ({
  type: types.SET_MSA_FLOWS_COLUMNS,
  payload: columns,
})

export const setMSAFlowsSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_MSA_FLOWS_SORT,
  payload: { sortBy, sortDirection },
})

export const setMSAFlowsFilter = (filter: string) => ({
  type: types.SET_MSA_FLOWS_FILTER,
  payload: filter,
})

export const setMSALadderViewOptions = (viewOptions: any) => ({
  type: types.SET_MSA_LADDER_VIEW_OPTION,
  payload: viewOptions,
})

export const setWirelessNodeColumns = (columns: any[]) => ({
  type: types.SET_WIRELESS_NODE_COLUMNS,
  payload: columns,
})

export const setWirelessNodesViewType = (viewType: string) => ({
  type: types.SET_WIRELESS_NODE_VIEW_TYPE,
  payload: viewType,
})

export const setWirelessNodesSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_WIRELESS_NODES_SORT,
  payload: { sortBy, sortDirection },
})

export const setWebServersColumns = (columns: ExpertTableColumn[]) => ({
  type: types.SET_WEB_SERVERS_COLUMNS,
  payload: columns,
})

export const setWebServersSort = (sortBy: ExpertColumn, sortDirection: SortDirectionType) => ({
  type: types.SET_WEB_SERVERS_SORT,
  payload: { sortBy, sortDirection },
})

export const setWebClientsColumns = (columns: ExpertTableColumn[]) => ({
  type: types.SET_WEB_CLIENTS_COLUMNS,
  payload: columns,
})

export const setWebClientsSort = (sortBy: ExpertColumn, sortDirection: SortDirectionType) => ({
  type: types.SET_WEB_CLIENTS_SORT,
  payload: { sortBy, sortDirection },
})

export const setWebPagesColumns = (columns: ExpertTableColumn[]) => ({
  type: types.SET_WEB_PAGES_COLUMNS,
  payload: columns,
})

export const setWebPagesSort = (sortBy: ExpertColumn, sortDirection: SortDirectionType) => ({
  type: types.SET_WEB_PAGES_SORT,
  payload: { sortBy, sortDirection },
})

export const setWebRequestsColumns = (columns: ExpertTableColumn[]) => ({
  type: types.SET_WEB_REQUESTS_COLUMNS,
  payload: columns,
})

export const setWebRequestsSort = (sortBy: ExpertColumn, sortDirection: SortDirectionType) => ({
  type: types.SET_WEB_REQUESTS_SORT,
  payload: { sortBy, sortDirection },
})

// Selectors

export const getIsNavOpen = (state: any) => state.isNavOpen

export const getShowAddressNames = (state: any) => state.showAddressNames

export const getShowPortNames = (state: any) => state.showPortNames

export const getShowLocalTime = (state: any) => state.showLocalTime

export const getEnginesFilter = (state: any) => state.enginesFilter

export const getEnginesSortBy = (state: any) => state.enginesSortBy

export const getEnginesSortDirection = (state: any) => state.enginesSortDirection

export const getEnginesCollapsedGroups = (state: any) => state.enginesCollapsedGroups

export const getEnginesChecked = (state: any) => state.enginesChecked

export const getCapturesViewType = (state: any) => state.capturesViewType

export const getCapturesFilter = (state: any) => state.capturesFilter

export const getCapturesColumns = (state: any) => state.capturesColumns

export const getCapturesSortBy = (state: any) => state.capturesSortBy

export const getCapturesSortDirection = (state: any) => state.capturesSortDirection

export const getForensicSearchesColumns = (state: any) => state.forensicSearchesColumns

export const getForensicSearchesFilter = (state: any) => state.forensicSearchesFilter

export const getForensicSearchesSortBy = (state: any) => state.forensicSearchesSortBy

export const getForensicSearchesSortDirection = (state: any) => state.forensicSearchesSortDirection

export const getForensicSearchesViewType = (state: any) => state.forensicSearchesViewType

export const getForensicSearchHistoryFilter = (state: any) => state.forensicSearchHistoryFilter

export const getAnalysisModulesFilter = (state: any) => state.analysisModulesFilter

export const getAuditLogRefreshInterval = (state: any) => state.auditLogRefreshInterval

export const getForensicsViewRefreshInterval = (state: any) => state.forensicsViewRefreshInterval

export const getForensicsViewType = (state: any) => state.forensicsViewType

export const getForensicsSampleInterval = (state: any) => state.forensicsSampleInterval

export const getForensicsTopTalkersViewType = (state: any) => state.forensicsTopTalkersViewType

export const getForensicsTopProtocolsViewType = (state: any) => state.forensicsTopProtocolsViewType

export const getForensicsTopStatsChartType = (state: any) => state.forensicsTopStatsChartType

export const getForensicsTopStatsChartScale = (state: any) => state.forensicsTopStatsChartScale

export const getForensicsTimelineChartType = (state: any) => state.forensicsTimelineChartType

export const getForensicsTimelineChartScale = (state: any) => state.forensicsTimelineChartScale

export const getForensicsTimelineChartInterpolation = (state: any) =>
  state.forensicsTimelineChartInterpolation

export const getForensicsTimelineSelection = (state: any) => state.forensicsTimelineSelection

export const getForensicsTimelineSelectionStart = (state: any) =>
  state.forensicsTimelineSelectionStart

export const getForensicsTimelineSelectionEnd = (state: any) => state.forensicsTimelineSelectionEnd

export const getCaptureSessionsFilter = (state: any) => state.captureSessionsFilter

export const getCaptureSessionsColumns = (state: any) => state.captureSessionsColumns

export const getCaptureSessionsSortBy = (state: any) => state.captureSessionsSortBy

export const getCaptureSessionsSortDirection = (state: any) => state.captureSessionsSortDirection

export const getCaptureSessionsCollapsedGroups = (state: any) =>
  state.captureSessionsCollapsedGroups

export const getDecryptionKeysFilter = (state: any) => state.decryptionKeysFilter

export const getDecryptionKeysSortBy = (state: any) => state.decryptionKeysSortBy

export const getDecryptionKeysSortDirection = (state: any) => state.decryptionKeysSortDirection

export const getFilesFilter = (state: any) => state.filesFilter

export const getFilesColumns = (state: any) => state.filesColumns

export const getFilesSortBy = (state: any) => state.filesSortBy

export const getFilesSortDirection = (state: any) => state.filesSortDirection

export const getFilesCollapsedGroups = (state: any) => state.filesCollapsedGroups

export const getFiltersFilter = (state: any) => state.filtersFilter

export const getFiltersSortBy = (state: any) => state.filtersSortBy

export const getFiltersSortDirection = (state: any) => state.filtersSortDirection

export const getFiltersExpandedGroups = (state: any) => state.filtersExpandedGroups

export const getFiltersChecked = (state: any) => state.filtersChecked

export const getGraphsFilter = (state: any) => state.graphsFilter

export const getGraphsSortBy = (state: any) => state.graphsSortBy

export const getGraphsSortDirection = (state: any) => state.graphsSortDirection

export const getHardwareProfilesFilter = (state: any) => state.hardwareProfilesFilter

export const getHardwareProfilesSortBy = (state: any) => state.hardwareProfilesSortBy

export const getHardwareProfilesSortDirection = (state: any) => state.hardwareProfilesSortDirection

export const getHardwareProfilesChecked = (state: any) => state.hardwareProfilesChecked

export const getAlarmsFilter = (state: any) => state.alarmsFilter

export const getAlarmsSortBy = (state: any) => state.alarmsSortBy

export const getAlarmsSortDirection = (state: any) => state.alarmsSortDirection

export const getNamesViewType = (state: any) => state.namesViewType

export const getNamesFilter = (state: any) => state.namesFilter

export const getNamesAddressesSortBy = (state: any) => state.namesAddressesSortBy

export const getNamesAddressesSortDirection = (state: any) => state.namesAddressesSortDirection

export const getNamesProtocolsSortBy = (state: any) => state.namesProtocolsSortBy

export const getNamesProtocolsSortDirection = (state: any) => state.namesProtocolsSortDirection

export const getNamesPortsSortBy = (state: any) => state.namesPortsSortBy

export const getNamesPortsSortDirection = (state: any) => state.namesPortsSortDirection

export const getNamesAddressesExpandedGroups = (state: any) => state.namesAddressesExpandedGroups

export const getNamesProtocolsExpandedGroups = (state: any) => state.namesProtocolsExpandedGroups

export const getNamesPortsExpandedGroups = (state: any) => state.namesPortsExpandedGroups

export const getNamesAddressesChecked = (state: any) => state.namesAddressesChecked

export const getNamesProtocolsChecked = (state: any) => state.namesProtocolsChecked

export const getNamesPortsChecked = (state: any) => state.namesPortsChecked

export const getNotificationsFilter = (state: any) => state.notificationsFilter

export const getNotificationsSortDirection = (state: any) => state.notificationsSortDirection

export const getAuditLogSearch = (state: any) => state.auditLogSearch

export const getForensicSearchHistorySearch = (state: any) => state.forensicSearchHistorySearch

export const getForensicSearchHistorySortAsc = (state: any) => state.forensicSearchHistorySortAsc

export const getForensicSearchHistorySortBy = (state: any) => state.forensicSearchHistorySortBy

export const getPacketsColumns = (state: any) => state.packetsColumns

export const getPacketsColumnsDecode = (state: any) => state.packetsColumnsDecode

export const getPacketsSelection = (state: any): PacketsSelection[] => state.packetsSelection

export const getPacketsShowDecodeView = (state: any) => state.showDecodeView

export const getPacketsDecodePacketNumber = (state: any) => state.decodePacketNumber

export const getPacketsShowFilterBar = (state: any) => state.showFilterBar

export const getPacketsFilterBarExpression = (state: any) => state.filterBarExpression

export const getPacketsFilterBarError = (state: any) => state.filterBarError

export const getRecentFilterBarExpressions = (state: any) => state.recentFilterBarExpressions

export const getTrafficHistoryWidgetChartType = (state: any) => state.trafficHistoryWidgetChartType

export const getTrafficHistoryWidgetChartScale = (state: any) =>
  state.trafficHistoryWidgetChartScale

export const getTrafficHistoryWidgetChartInterpolation = (state: any) =>
  state.trafficHistoryWidgetChartInterpolation

export const getTopTalkersWidgetViewType = (state: any) => state.topTalkersWidgetViewType

export const getTopTalkersWidgetChartType = (state: any) => state.topTalkersWidgetChartType

export const getTopTalkersWidgetChartScale = (state: any) => state.topTalkersWidgetChartScale

export const getTopProtocolsWidgetChartType = (state: any) => state.topProtocolsWidgetChartType

export const getTopProtocolsWidgetChartScale = (state: any) => state.topProtocolsWidgetChartScale

export const getTopApplicationsByFlowsWidgetChartType = (state: any) =>
  state.topApplicationsByFlowsWidgetChartType

export const getTopApplicationsByFlowsWidgetChartScale = (state: any) =>
  state.topApplicationsByFlowsWidgetChartScale

export const getTopApplicationsByBytesWidgetChartType = (state: any) =>
  state.topApplicationsByBytesWidgetChartType

export const getTopApplicationsByBytesWidgetChartScale = (state: any) =>
  state.topApplicationsByBytesWidgetChartScale

export const getTopApplicationCategoriesWidgetChartType = (state: any) =>
  state.topApplicationCategoriesWidgetChartType

export const getTopApplicationCategoriesWidgetChartScale = (state: any) =>
  state.topApplicationCategoriesWidgetChartScale

export const getApplicationUtilizationWidgetChartType = (state: any) =>
  state.applicationUtilizationWidgetChartType

export const getApplicationUtilizationWidgetChartScale = (state: any) =>
  state.applicationUtilizationWidgetChartScale

export const getApplicationUtilizationWidgetChartInterpolation = (state: any) =>
  state.applicationUtilizationWidgetChartInterpolation

export const getApplicationResponseTimeWidgetChartType = (state: any) =>
  state.applicationResponseTimeWidgetChartType

export const getApplicationResponseTimeWidgetChartScale = (state: any) =>
  state.applicationResponseTimeWidgetChartScale

export const getCallQualityDistributionWidgetChartType = (state: any) =>
  state.callQualityDistributionWidgetChartType

export const getCallQualityDistributionWidgetChartScale = (state: any) =>
  state.callQualityDistributionWidgetChartScale

export const getCallQualityWidgetChartType = (state: any) => state.callQualityWidgetChartType

export const getCallQualityWidgetChartInterpolation = (state: any) =>
  state.callQualityWidgetChartInterpolation

export const getCallQualityByCodecWidgetChartType = (state: any) =>
  state.callQualityByCodecWidgetChartType

export const getCallQualityByCodecWidgetChartInterpolation = (state: any) =>
  state.callQualityByCodecWidgetChartInterpolation

export const getCallVolumeByCodecWidgetChartType = (state: any) =>
  state.callVolumeByCodecWidgetChartType

export const getCallVolumeByCodecWidgetChartInterpolation = (state: any) =>
  state.callVolumeByCodecWidgetChartInterpolation

export const getCallUtilizationWidgetChartType = (state: any) =>
  state.callUtilizationWidgetChartType

export const getCallUtilizationWidgetChartScale = (state: any) =>
  state.callUtilizationWidgetChartScale

export const getCallUtilizationWidgetChartInterpolation = (state: any) =>
  state.callUtilizationWidgetChartInterpolation

export const getSummaryStatsViewType = (state: any) => state.summaryStatsViewType

export const getSummaryStatsFilter = (state: any) => state.summaryStatsFilter

export const getNodeStatsViewType = (state: any) => state.nodeStatsViewType

export const getNodeStatsFilter = (state: any) => state.nodeStatsFilter

export const getNodeStatsColumns = (state: any) => state.nodeStatsColumns

export const getNodeHierarchyStatsColumns = (state: any) => state.nodeStatsHierarchyColumns

export const getNodeStatsSortBy = (state: any) => state.nodeStatsSortBy

export const getNodeStatsSortDirection = (state: any) => state.nodeStatsSortDirection

export const getProtocolStatsViewType = (state: any) => state.protocolStatsViewType

export const getProtocolStatsFilter = (state: any) => state.protocolStatsFilter

export const getProtocolStatsSortBy = (state: any) => state.protocolStatsSortBy

export const getProtocolStatsSortDirection = (state: any) => state.protocolStatsSortDirection

export const getAppStatsFilter = (state: any) => state.appStatsFilter

export const getAppStatsColumns = (state: any) => state.appStatsColumns

export const getAppStatsSortBy = (state: any) => state.appStatsSortBy

export const getAppStatsSortDirection = (state: any) => state.appStatsSortDirection

export const getReconstructionsFilter = (state: any) => state.reconstructionsFilter

export const getReconstructionsColumns = (state: any) => state.reconstructionsColumns

export const getReconstructionsSortBy = (state: any) => state.reconstructionsSortBy

export const getReconstructionsSortDirection = (state: any) => state.reconstructionsSortDirection

export const getAppStatsViewType = (state: any) => state.appStatsViewType

export const getCountryStatsFilter = (state: any) => state.countryStatsFilter

export const getCountryStatsColumns = (state: any) => state.countryStatsColumns

export const getCountryStatsSortBy = (state: any) => state.countryStatsSortBy

export const getCountryStatsSortDirection = (state: any) => state.countryStatsSortDirection

export const getMPLSVLANVXLANStatsFilter = (state: any) => state.mplsVlanVxlanStatsFilter

export const getMPLSVLANVXLANStatsColumns = (state: any) => state.mplsVlanVxlanStatsColumns

export const getMPLSVLANVXLANStatsSortBy = (state: any) => state.mplsVlanVxlanStatsSortBy

export const getMPLSVLANVXLANStatsSortDirection = (state: any) =>
  state.mplsVlanVxlanStatsSortDirection

export const getExpertClientsServersColumns = (state: any) => state.expertClientsServersColumns

export const getExpertClientsServersSortBy = (state: any) => state.expertClientsServersSortBy

export const getExpertClientsServersSortDirection = (state: any) =>
  state.expertClientsServersSortDirection

export const getExpertFlowsSearch = (state: any) => state.expertFlowsSearch

export const getExpertFlowsColumns = (state: any) => state.expertFlowsColumns

export const getExpertFlowsSortBy = (state: any) => state.expertFlowsSortBy

export const getExpertFlowsSortDirection = (state: any) => state.expertFlowsSortDirection

export const getExpertAppsColumns = (state: any) => state.expertAppsColumns

export const getExpertAppsSortBy = (state: any) => state.expertAppsSortBy

export const getExpertAppsSortDirection = (state: any) => state.expertAppsSortDirection

export const getExpertEventSummarySortBy = (state: any) => state.expertEventSummarySortBy

export const getExpertEventSummarySortDirection = (state: any) =>
  state.expertEventSummarySortDirection

export const getExpertEventLogColumns = (state: any) => state.expertEventLogColumns

export const getExpertEventLogSortBy = (state: any) => state.expertEventLogSortBy

export const getExpertEventLogSortDirection = (state: any) => state.expertEventLogSortDirection

export const getVoIPCallsColumns = (state: any) => state.voipCallsColumns

export const getVoIPCallsSortBy = (state: any) => state.voipCallsSortBy

export const getVoIPCallsSortDirection = (state: any) => state.voipCallsSortDirection

export const getVoIPMediaColumns = (state: any) => state.voipMediaColumns

export const getVoIPMediaSortBy = (state: any) => state.voipMediaSortBy

export const getVoIPMediaSortDirection = (state: any) => state.voipMediaSortDirection

export const getFlowVisualizerViewType = (state: any) => state.flowVisualizerViewType

export const getFlowVisualizerColumns = (state: any) => state.flowVisualizerColumns

export const getFlowVisualizerRelativeSeqAck = (state: any) => state.flowVisualizerRelativeSeqAck

export const getFlowVisualizerRelativeTime = (state: any) => state.flowVisualizerRelativeTime

export const getFlowVisualizerMarkRelatedPackets = (state: any) =>
  state.flowVisualizerMarkRelatedPackets

export const getFlowVisualizerShowClientPayload = (state: any) =>
  state.flowVisualizerShowClientPayload

export const getFlowVisualizerShowServerPayload = (state: any) =>
  state.flowVisualizerShowServerPayload

export const getFlowVisualizerShowPayloadPacketLabels = (state: any) =>
  state.flowVisualizerShowPayloadPacketLabels

export const getFlowVisualizerShowPayloadCRLF = (state: any) => state.flowVisualizerShowPayloadCRLF

export const getFlowVisualizerGraphType = (state: any) => state.flowVisualizerGraphType

export const getFlowVisualizerGraphDirection = (state: any) => state.flowVisualizerGraphDirection

export const getVoIPCallVisualizerViewType = (state: any) => state.voipCallVisualizerViewType

export const getVoIPCallVisualizerColumns = (state: any) => state.voipCallVisualizerColumns

export const getVoIPCallVisualizerSortBy = (state: any) => state.voipCallVisualizerSortBy

export const getVoIPCallVisualizerSortDirection = (state: any) =>
  state.voipCallVisualizerSortDirection

export const getGraphsShowList = (state: any) => state.graphsShowList

export const getGraphsEnabled = (state: any) => state.graphsEnabled

export const getPeerMapOptions = (state: any) => state.peerMapOptions

export const getMSAListFilter = (state: any) => state.msaListFilter

export const getMSAViewType = (state: any) => state.msaViewType

export const getMSAFlowsColumns = (state: any) => state.msaFlowsColumns

export const getMSAFlowsSortBy = (state: any) => state.msaFlowsSortBy

export const getMSAFlowsSortDirection = (state: any) => state.msaFlowsSortDirection

export const getMSAFlowsFilter = (state: any) => state.msaFlowsFilter

export const getMSALadderViewOptions = (state: any) => state.msaLadderViewOptions

export const getWirelessNodeColumns = (state: any) => state.wirelessNodesColumns

export const getWirelessNodesView = (state: any) => state.wirelessNodesViewType

export const getWirelessNodesSortBy = (state: any) => state.wirelessNodesSortBy

export const getWirelessNodesSortDirection = (state: any) => state.wirelessNodesSortDirection

export const getAuthenticationTokensFilter = (state: any) => state.authenticationTokensFilter

export const getAuthenticationTokensSortBy = (state: any) => state.authenticationTokensSortBy

export const getAuthenticationTokensSortDirection = (state: any) =>
  state.authenticationTokensSortDirection

export const setAuthenticationTokensFilter = (filter: string) => ({
  type: types.SET_AUTHENTICATION_TOKENS_FILTER,
  payload: filter,
})

export const setAuthenticationTokensSort = (sortBy: string, sortDirection: SortDirectionType) => ({
  type: types.SET_AUTHENTICATION_TOKENS_SORT,
  payload: { sortBy, sortDirection },
})

export const getWebServersColumns = (state: any) => state.webServersColumns

export const getWebServersSortBy = (state: any) => state.webServersSortBy

export const getWebServersSortDirection = (state: any) => state.webServersSortDirection

export const getWebClientsColumns = (state: any) => state.webClientsColumns

export const getWebClientsSortBy = (state: any) => state.webClientsSortBy

export const getWebClientsSortDirection = (state: any) => state.webClientsSortDirection

export const getWebPagesColumns = (state: any) => state.webPagesColumns

export const getWebPagesSortBy = (state: any) => state.webPagesSortBy

export const getWebPagesSortDirection = (state: any) => state.webPagesSortDirection

export const getWebRequestsColumns = (state: any) => state.webRequestsColumns

export const getWebRequestsSortBy = (state: any) => state.webRequestsSortBy

export const getWebRequestsSortDirection = (state: any) => state.webRequestsSortDirection
