import { useIdle } from "react-use"

export const IdleTimeout = ({
  idleTimeout,
  onIdle,
}: {
  idleTimeout: number
  onIdle: () => void
}) => {
  const isIdle = useIdle(idleTimeout * 1000)

  if (isIdle) {
    onIdle()
  }

  return null
}

export default IdleTimeout
