export enum AlarmComparisonType {
  ALARM_COMPARISON_TYPE_UNDEFINED = 0, // Unknown
  ALARM_COMPARISON_TYPE_LESS_THAN = 1, // Less than
  ALARM_COMPARISON_TYPE_LESS_THAN_EQUAL = 2, // Less than or equal to
  ALARM_COMPARISON_TYPE_GREATER_THAN = 3, // Greater than
  ALARM_COMPARISON_TYPE_GREATER_THAN_EQUAL = 4, // Greater than or equal to
  ALARM_COMPARISON_TYPE_EQUAL = 5, // Equal to
  ALARM_COMPARISON_TYPE_NOT_EQUAL = 6, // Not equal to
}

export enum AlarmConditionType {
  ALARM_CONDITION_TYPE_UNDEFINED = 0, // Unknown
  ALARM_CONDITION_TYPE_SUSPECT = 1, // Suspect condition
  ALARM_CONDITION_TYPE_PROBLEM = 2, // Problem condition
  ALARM_CONDITION_TYPE_RESOLVE = 3, // Resolve condition
}

export enum AlarmTrackType {
  ALARM_TRACK_TYPE_UNDEFINED = 0, // Unknown
  ALARM_TRACK_TYPE_TOTAL = 1, // Total
  ALARM_TRACK_TYPE_DIFFERENCE = 2, // Difference
  ALARM_TRACK_TYPE_DIFFERENCE_PER_SEC = 3, // Difference per Second
}

export enum CaptureFlags {
  CAPTURE_TO_DISK = 0x00000001,
  CAPTURE_TO_BUFFER = 0x00000002,
  CAPTURE_CONTINUOUS = 0x00000004,
  CAPTURE_VOIP = 0x00000008,
  CAPTURE_TOP_STATS = 0x00000010,
  CAPTURE_DISTRIBUTED = 0x00000020,
  CAPTURE_APP_STATS = 0x00000040,
}

export enum CaptureState {
  CAPTURE_STATE_IDLE = 0,
  CAPTURE_STATE_CAPTURING = 1,
}

export enum CaptureTemplateType {
  CAPTURE_TEMPLATE_TYPE_UNKNOWN = 0, // Initial state - unknown
  CAPTURE_TEMPLATE_TYPE_DEFAULT = 1, // Default template
  CAPTURE_TEMPLATE_TYPE_USER = 2, // User defined template
}

export enum CaptureType {
  CAPTURE_TYPE_CLASSIC = 0,
  CAPTURE_TYPE_HPCTD = 1,
}

export enum ColorBy {
  COLOR_BY_NONE = 0,
  COLOR_BY_INDEPENDENT = 1,
  COLOR_BY_CLIENT = 2,
  COLOR_BY_SERVER = 3,
}

export enum EngineAuthServerType {
  ENGINE_AUTH_SERVER_TYPE_RADIUS = 0,
  ENGINE_AUTH_SERVER_TYPE_TACACS = 1,
  ENGINE_AUTH_SERVER_TYPE_ACTIVE_DIRECTORY = 2,
  ENGINE_AUTH_SERVER_TYPE_KERBEROS = 3,
  ENGINE_AUTH_SERVER_TYPE_LOCAL = 4,
  ENGINE_AUTH_SERVER_TYPE_UNKNOWN = 5,
}

export enum GraphItemUnitType {
  GRAPH_ITEM_UNIT_TYPE_UNKNOWN = 0,
  GRAPH_ITEM_UNIT_TYPE_BYTES = 1,
  GRAPH_ITEM_UNIT_TYPE_PACKETS = 2,
}

export enum IndexingID {
  INDEXING_ID_IPV4 = 0,
  INDEXING_ID_IPV6 = 1,
  INDEXING_ID_ETHERNET = 2,
  INDEXING_ID_PORT = 3,
  INDEXING_ID_PROTOSPEC = 4,
  INDEXING_ID_APPLICATION = 5,
  INDEXING_ID_VLAN = 6,
  INDEXING_ID_MPLS = 7,
  INDEXING_ID_COUNTRY = 8,
}

export enum LimitMode {
  LIMIT_NONE = 0, // None.
  LIMIT_BY_PACKETS = 1, // K packets.
  LIMIT_BY_BYTES = 2, // Bytes (packet data).
  LIMIT_ROLLING_BYTES = 3, // Static sized packet buffer, roll out packets.
}

export enum PeekAdapterFeatures {
  PEEK_ADAPTER_PACKET_SLICE = 0x00000001, // Adapter can slice packets.
  PEEK_ADAPTER_XYRATEX_FILTER = 0x00000002, // Adapter supports Xyratex-style filter definition.
  PEEK_ADAPTER_HARDWARE_STATS = 0x00000004, // Adapter supports non-NDIS statistics.
  PEEK_ADAPTER_ERROR_CAPTURE = 0x00000008, // Adapter passes along error packets.
  PEEK_ADAPTER_PORT_MODE = 0x00000010, // Adapter port(s) mode can be configured.
  PEEK_ADAPTER_CANNOT_SEND_PKTS = 0x00000020, // Adapter cannot transmit packets onto the network.
  PEEK_ADAPTER_SUPPORTS_CHANNELS = 0x00000040, // Adapter supports multiple channels.
  PEEK_ADAPTER_SUPPORTS_CHANNEL_COMBINATIONS = 0x00000080, // Adapter supports creating channel combinations for captures.
  PEEK_ADAPTER_START_CAPTURE_REQUIRED = 0x00000100, // Adapter requires a start capture command to capture packets.
  PEEK_ADAPTER_PKTS_AFTER_STOP_CAPTURE = 0x00000200, // Packets only flow out of the adapter after the stop capture command.
  PEEK_ADAPTER_GENERIC_FILTER = 0x00000400, // Adapter supports Generic filter definitions.
  PEEK_ADAPTER_GIG_SUPPORT = 0x00000800, // Adapter supports Gig interfaces.
  PEEK_ADAPTER_NT_EXTENDED_DESCRIPTOR = 0x00001000, // Adapter provides Napatech extended descriptor.
  PEEK_ADAPTER_QUERY_DROP_AFTER_STOP = 0x00002000, // Adapter supports querying dropped packets after stop capture.
  PEEK_ADAPTER_SYNTHESIZES_PACKETS = 0x00004000, // Adapter synthesizes packets.
  PEEK_ADAPTER_SETS_PER_CAPTURE_DROP = 0x00008000, // Adapter sets each capture's dropped packet count.
  PEEK_ADAPTER_PIPELINE_CAPTURE = 0x00010000, // Adapter supports "pipeline" capture mode.
  PEEK_ADAPTER_NO_PAUSE = 0x00020000, // Adapter does not support pause.
  PEEK_ADAPTER_DEDUPLICATION = 0x00040000, // Adapter can deduplicate packets.
}

export enum PeekAdapterInterfaceFeatures {
  PEEK_ADAPTER_INTERFACE_FEATURE_CRC_ERROR = 0x00000001,
  PEEK_ADAPTER_INTERFACE_FEATURE_RUNT_ERROR = 0x00000002,
  PEEK_ADAPTER_INTERFACE_FEATURE_ALIGN_ERROR = 0x00000004,
  PEEK_ADAPTER_INTERFACE_FEATURE_OVERSIZE_ERROR = 0x00000008,
  PEEK_ADAPTER_INTERFACE_FEATURE_NET_SERVICES = 0x00000010,
  PEEK_ADAPTER_INTERFACE_FEATURE_RF_MONITOR = 0x00000020,
  PEEK_ADAPTER_INTERFACE_FEATURE_RF_MONITOR_EX = 0x00000040,
  PEEK_ADAPTER_INTERFACE_FEATURE_EXTENDED_HEADER = 0x00000080,
  PEEK_ADAPTER_INTERFACE_FEATURE_DETAILED_CHANNELS = 0x00000100,
  PEEK_ADAPTER_INTERFACE_FEATURE_NON_PROMISCUOUS = 0x00000200, // Internal to the NdisAdapter coclass only; not reported to the outside world via INdisAdapterInfo.
  PEEK_ADAPTER_INTERFACE_FEATURE_BASIC_WPA = 0x00001000,
  PEEK_ADAPTER_INTERFACE_FEATURE_WPA = 0x00002000,
  PEEK_ADAPTER_INTERFACE_FEATURE_TGI = 0x00003000,
  PEEK_ADAPTER_INTERFACE_FEATURE_COUNTRY_CHANGE = 0x00004000,
}

export enum PeekAdapterType {
  PEEK_NULL_ADAPTER = 0, // Null adapter, none
  PEEK_NETWORK_ADAPTER = 1, // Network adapter
  PEEK_REPLAY_ADAPTER = 2, // Replay adapter (a file adapter)
  PEEK_PLUGIN_ADAPTER = 3, // Adapter managed by a Distributed Analysis Module
  PEEK_REMOTE_ADAPTER = 4, // Remote adapter (adapter managed by omni-capture daemon)
}

export enum PeekApplicationStatType {
  PEEK_APPLICATION_STAT_TYPE_NULL = 0, // None
  PEEK_APPLICATION_STAT_TYPE_PACKETS = 1, // Total packets using this application.
  PEEK_APPLICATION_STAT_TYPE_BYTES = 2, // Total bytes using this application.
}

export enum PeekCaptureState {
  PEEK_CAPTURE_STATE_IDLE = 0x0000, // Idle.
  PEEK_CAPTURE_STATE_CAPTURING = 0x0001, // Capturing packets.
}

export enum PeekCountryStatType {
  PEEK_COUNTRY_STAT_TYPE_NULL = 0, // None
  PEEK_COUNTRY_STAT_TYPE_TOTAL_PACKETS = 1, // Total packets using this country.
  PEEK_COUNTRY_STAT_TYPE_TOTAL_BYTES = 2, // Total bytes using this country.
  PEEK_COUNTRY_STAT_TYPE_PACKETS_FROM = 3, // Packets from this country.
  PEEK_COUNTRY_STAT_TYPE_BYTES_FROM = 4, // Bytes from this country.
  PEEK_COUNTRY_STAT_TYPE_PACKETS_TO = 5, // Packets to this country.
  PEEK_COUNTRY_STAT_TYPE_BYTES_TO = 6, // Bytes to this country.
}

export enum PeekFileViewStatus {
  PEEK_FILE_VIEW_STATUS_CLOSED = 0,
  PEEK_FILE_VIEW_STATUS_OPENING = 1,
  PEEK_FILE_VIEW_STATUS_COMPLETE = 2,
}

export enum PeekFilterMode {
  PEEK_FILTER_MODE_ACCEPT_ALL = 0, // Accept all packets.
  PEEK_FILTER_MODE_ACCEPT_MATCHING_ANY = 1, // Accept only packets matching any filters.
  PEEK_FILTER_MODE_REJECT_ALL = 2, // Reject all packets.
  PEEK_FILTER_MODE_REJECT_MATCHING_ANY = 3, // Reject packets matching any filters.
  PEEK_FILTER_MODE_ACCEPT_MATCHING_ALL = 4, // Accept only packets matching all filters.
  PEEK_FILTER_MODE_REJECT_MATCHING_ALL = 5, // Reject packets matching all filters.
}

export enum PeekMPLSVLANVXLANID {
  PEEK_MPLS_VLAN_VXLAN_ID_UNDEFINED = 0, // Undefined.
  PEEK_MPLS_VLAN_VXLAN_ID_MPLS_LABEL = 1, // MPLS Label.
  PEEK_MPLS_VLAN_VXLAN_ID_VLAN_ID = 2, // VLAN ID.
  PEEK_MPLS_VLAN_VXLAN_ID_VXLAN_GPID = 3, // VXLAN GPID.
  PEEK_MPLS_VLAN_VXLAN_ID_VXLAN_VNI = 4, // VXLAN VNI.
  PEEK_MPLS_VLAN_VXLAN_ID_NODE = 5, // Node.
  PEEK_MPLS_VLAN_VXLAN_ID_PROTOCOL = 6, // Protocol.
  PEEK_MPLS_VLAN_VXLAN_ID_APPLICATION = 7, // Application.
  PEEK_MPLS_VLAN_VXLAN_ID_COUNTRY = 8, // Country.
}

export enum PeekMPLSVLANVXLANStatType {
  PEEK_MPLS_VLAN_VXLAN_STAT_TYPE_NULL = 0, // None
  PEEK_MPLS_VLAN_VXLAN_STAT_TYPE_TOTAL_PACKETS = 1, // Total packets using this MPLS/VLAN/VXLAN.
  PEEK_MPLS_VLAN_VXLAN_STAT_TYPE_TOTAL_BYTES = 2, // Total bytes using this MPLS/VLAN/VXLAN.
}

export enum PeekMPLSVLANVXLANType {
  PEEK_MPLS_VLAN_VXLAN_TYPE_UNDEFINED = 0, // Undefined.
  PEEK_MPLS_VLAN_VXLAN_TYPE_MPLS_LABEL = 1, // MPLS Label.
  PEEK_MPLS_VLAN_VXLAN_TYPE_VLAN_ID = 2, // VLAN ID.
  PEEK_MPLS_VLAN_VXLAN_TYPE_VXLAN_GPID = 3, // VXLAN GPID.
  PEEK_MPLS_VLAN_VXLAN_TYPE_VXLAN_VNI = 4, // VXLAN VNI.
}

export enum PeekNodeStatsItemType {
  PEEK_NODE_STATS_ITEM_TYPE_UNKNOWN = 0,
  PEEK_NODE_STATS_ITEM_TYPE_SENT = 1, // Total packets/Bytes sent by this node.
  PEEK_NODE_STATS_ITEM_TYPE_RECEIVED = 2, // Total packets/bytes received by this node.
  PEEK_NODE_STATS_ITEM_TYPE_BROADCAST = 3, // Broadcast packets sent by this node.
  PEEK_NODE_STATS_ITEM_TYPE_MULTICAST = 4, // Multicast packets sent by this node.
  PEEK_NODE_STATS_ITEM_TYPE_TOTAL = 5, // Total packets/bytes sent/received by this node.
}

export enum PeekNodeStatType {
  PEEK_NODE_STAT_TYPE_NULL = 0,
  PEEK_NODE_STAT_TYPE_TOTAL_BYTES = 1,
  PEEK_NODE_STAT_TYPE_TOTAL_PACKETS = 2, // Calculated.
  PEEK_NODE_STAT_TYPE_PACKETS_SENT = 3, // Total packets sent by this node.
  PEEK_NODE_STAT_TYPE_BYTES_SENT = 4, // Total bytes sent by this node.
  PEEK_NODE_STAT_TYPE_PACKETS_RECEIVED = 5, // Total packets received by this node.
  PEEK_NODE_STAT_TYPE_BYTES_RECEIVED = 6, // Total bytes received by this node.
  PEEK_NODE_STAT_TYPE_BROADCAST_PACKETS = 7, // Broadcast packets sent by this node.
  PEEK_NODE_STAT_TYPE_BROADCAST_BYTES = 8, // Broadcast bytes sent by this node.
  PEEK_NODE_STAT_TYPE_MULTICAST_PACKETS = 9, // Multicast packets sent by this node.
  PEEK_NODE_STAT_TYPE_MULTICAST_BYTES = 10, // Multicast bytes sent by this node.
  PEEK_NODE_STAT_TYPE_BROADCAST_MULTICAST_PACKETS = 11, // Calculated.
  PEEK_NODE_STAT_TYPE_BROADCAST_MULTICAST_BYTES = 12, // Calculated.
  PEEK_NODE_STAT_TYPE_MIN_PACKET_SIZE_SENT = 13, // Minimum size packet sent by this node in bytes.
  PEEK_NODE_STAT_TYPE_MAX_PACKET_SIZE_SENT = 14, // Maximum size packet sent by this node in bytes.
  PEEK_NODE_STAT_TYPE_MIN_PACKET_SIZE_RECEIVED = 15, // Minimum size packet received by this node in bytes.
  PEEK_NODE_STAT_TYPE_MAX_PACKET_SIZE_RECEIVED = 16, // Maximum size packet received by this node in bytes.
}

export enum PeekPluginAdapterType {
  PEEK_NULL_PLUGIN_ADAPTER = 0, // Null Distributed Analysis Module adapter type, none.
  PEEK_ROOT_PLUGIN_ADAPTER = 1, // TODO
  PEEK_GROUP_PLUGIN_ADAPTER = 2, // TODO
  PEEK_ADAPTER_PLUGIN_ADAPTER = 3, // TODO
  PEEK_PRIVATE_PLUGIN_ADAPTER = 4, // Private
}

export enum PeekProtocolStatType {
  PEEK_PROTOCOL_STAT_TYPE_NULL = 0, // None
  PEEK_PROTOCOL_STAT_TYPE_PACKETS = 1, // Total packets using this protocol.
  PEEK_PROTOCOL_STAT_TYPE_BYTES = 2, // Total bytes using this protocol.
}

export enum PeekResult {
  PEEK_RESULT_S_OK = 0,
  PEEK_RESULT_S_FALSE = 0x00000001,
  PEEK_RESULT_E_UNEXPECTED = 0x8000ffff,
  PEEK_RESULT_E_PENDING = 0x8000000a,
  PEEK_RESULT_E_NOT_IMPLEMENTED = 0x80004001,
  PEEK_RESULT_E_NO_INTERFACE = 0x80004002,
  PEEK_RESULT_E_INVALID_OR_NULL_POINTER = 0x80004003,
  PEEK_RESULT_E_ABORT = 0x80004004,
  PEEK_RESULT_E_FAIL = 0x80004005,
  PEEK_RESULT_E_NOT_AVAILABLE = 0x80040111,
  PEEK_RESULT_E_FACTORY_NOT_REGISTERED = 0x80040154,
  PEEK_RESULT_E_FACTORY_NOT_LOADED = 0x800401f8,
  PEEK_RESULT_E_FILE_NOT_FOUND = 0x80070002,
  PEEK_RESULT_E_PATH_NOT_FOUND = 0x80070003,
  PEEK_RESULT_E_ACCESS_DENIED = 0x80070005,
  PEEK_RESULT_E_INVALID_HANDLE = 0x80070006,
  PEEK_RESULT_E_OUT_OF_MEMORY = 0x8007000e,
  PEEK_RESULT_E_WRITE_PROTECT = 0x80070013,
  PEEK_RESULT_E_EOF = 0x80070026,
  PEEK_RESULT_E_FILE_EXISTS = 0x80070050,
  PEEK_RESULT_E_ILLEGAL_OR_INVALID_VALUE = 0x80070057,
  PEEK_RESULT_E_DISK_FULL = 0x80070070,
  PEEK_RESULT_E_INSUFFICIENT_BUFFER = 0x8007007a,
  PEEK_RESULT_E_ILLEGAL_FILE_NAME = 0x8007007b,
  PEEK_RESULT_E_MAX_SESSIONS_REACHED = 0x80070161,
  PEEK_RESULT_E_CANCELLED = 0x800704c7,
  PEEK_RESULT_E_TIMEOUT = 0x800705b4,
  PEEK_RESULT_E_TYPE_MISMATCH = 0x80020005,
  PEEK_RESULT_E_BADVARTYPE = 0x80020008,
  PEEK_RESULT_E_OVERFLOW = 0x8002000a,
}

export enum PeekSelectRelatedParam {
  PEEK_SELECT_RELATED_BY_SOURCE = 0, ///< Select by source address.
  PEEK_SELECT_RELATED_BY_DESTINATION = 1, ///< Select by destination address.
  PEEK_SELECT_RELATED_BY_SOURCE_AND_DESTINATION = 2, ///< Select by source and destination addresses.
  PEEK_SELECT_RELATED_BY_PROTOCOL = 3, ///< Select by protocol.
  PEEK_SELECT_RELATED_BY_PORT = 4, ///< Select by port.
  PEEK_SELECT_RELATED_BY_CONVERSATION = 5, ///< Select by conversation (address and port or protocol).
  PEEK_SELECT_RELATED_BY_VLAN = 6, ///< Select by VLAN ID.
  PEEK_SELECT_RELATED_BY_APPLICATION = 7, ///< Select by application.
  PEEK_SELECT_RELATED_BY_MPLS = 8, ///< Select by MPLS label.
  PEEK_SELECT_RELATED_BY_VXLAN_GPID = 9, ///< Select by VXLAN GPID.
  PEEK_SELECT_RELATED_BY_VXLAN_VNI = 10, ///< Select by VXLAN VNI.
}

export enum PeekSeverity {
  PEEK_SEVERITY_INFORMATIONAL = 0,
  PEEK_SEVERITY_MINOR = 1,
  PEEK_SEVERITY_MAJOR = 2,
  PEEK_SEVERITY_SEVERE = 3,
}

export enum PeekSummaryStatFlags {
  PEEK_SUMMARY_STAT_FLAG_UNGRAPHABLE = 0x00000001, ///< This item should not be graphed.
  PEEK_SUMMARY_STAT_FLAG_OVERTME = 0x00000002, ///< This item is an average over time.
  PEEK_SUMMARY_STAT_FLAG_SAMPLED = 0x00000004, ///< This item is a sample per second.
  PEEK_SUMMARY_STAT_FLAG_SUBITEMS = 0x00000008, ///< If this item has subitems, sort them alphabetically.
  PEEK_SUMMARY_STAT_FLAG_COUNTER = 0x00000010, ///< This item is a counter that should be reduced using differences.
}

export enum PeekSummaryStatType {
  PEEK_SUMMARY_STAT_TYPE_NULL = 0,
  PEEK_SUMMARY_STAT_TYPE_DATE = 1,
  PEEK_SUMMARY_STAT_TYPE_TIME = 2,
  PEEK_SUMMARY_STAT_TYPE_DURATION = 3,
  PEEK_SUMMARY_STAT_TYPE_PACKETS = 4,
  PEEK_SUMMARY_STAT_TYPE_BYTES = 5,
  PEEK_SUMMARY_STAT_TYPE_VALUE_PAIR = 6,
  PEEK_SUMMARY_STAT_TYPE_INT = 7,
  PEEK_SUMMARY_STAT_TYPE_DOUBLE = 8,
}

export enum PeekTriggerState {
  PEEK_TRIGGER_STATE_START_ACTIVE = 0x0100, // Start trigger is active.
  PEEK_TRIGGER_STATE_STOP_ACTIVE = 0x02000, // Stop trigger is active.
}

export enum PeekCaptureStatus {
  /// Idle.
  peekCaptureStatusIdle = PeekCaptureState.PEEK_CAPTURE_STATE_IDLE,
  /// Idle - waiting to start trigger.
  peekCaptureStatusIdleStartActive = PeekCaptureState.PEEK_CAPTURE_STATE_IDLE |
    PeekTriggerState.PEEK_TRIGGER_STATE_START_ACTIVE,
  /// Idle - with a stop trigger active.
  peekCaptureStatusIdleStopActive = PeekCaptureState.PEEK_CAPTURE_STATE_IDLE |
    PeekTriggerState.PEEK_TRIGGER_STATE_STOP_ACTIVE,
  /// Idle - waiting to start trigger with a stop trigger active.
  peekCaptureStatusIdleStartActiveStopActive = PeekCaptureState.PEEK_CAPTURE_STATE_IDLE |
    PeekTriggerState.PEEK_TRIGGER_STATE_START_ACTIVE |
    PeekTriggerState.PEEK_TRIGGER_STATE_STOP_ACTIVE,
  /// Capturing - waiting on a start trigger.
  peekCaptureStatusWaitStart = PeekCaptureState.PEEK_CAPTURE_STATE_CAPTURING |
    PeekTriggerState.PEEK_TRIGGER_STATE_START_ACTIVE,
  /// Capturing - waiting on a start trigger with a stop trigger active.
  peekCaptureStatusWaitStartStopActive = PeekCaptureState.PEEK_CAPTURE_STATE_CAPTURING |
    PeekTriggerState.PEEK_TRIGGER_STATE_START_ACTIVE |
    PeekTriggerState.PEEK_TRIGGER_STATE_STOP_ACTIVE,
  /// Capturing packets.
  peekCaptureStatusCapturing = PeekCaptureState.PEEK_CAPTURE_STATE_CAPTURING,
  /// Capturing packets with a stop trigger active.
  peekCaptureStatusCapturingStopActive = PeekCaptureState.PEEK_CAPTURE_STATE_CAPTURING |
    PeekTriggerState.PEEK_TRIGGER_STATE_STOP_ACTIVE,
}

export enum PeekVariantType {
  PEEK_VT_EMPTY = 0,
  PEEK_VT_NULL = 1,
  PEEK_VT_SINT16 = 2,
  PEEK_VT_SINT32 = 3,
  PEEK_VT_FLOAT = 4,
  PEEK_VT_DOUBLE = 5,
  PEEK_VT_CY = 6,
  PEEK_VT_DATE = 7,
  PEEK_VT_BSTR = 8,
  PEEK_VT_DISPATCH = 9,
  PEEK_VT_ERROR = 10,
  PEEK_VT_BOOL = 11,
  PEEK_VT_VARIANT = 12,
  PEEK_VT_UNKNOWN = 13,
  PEEK_VT_DECIMAL = 14,
  PEEK_VT_CHAR = 16,
  PEEK_VT_UINT8 = 17,
  PEEK_VT_UINT16 = 18,
  PEEK_VT_UINT32 = 19,
  PEEK_VT_SINT64 = 20,
  PEEK_VT_UINT64 = 21,
  PEEK_VT_INT = 22,
  PEEK_VT_UINT = 23,
  PEEK_VT_VOID = 24,
  PEEK_VT_HERESULT = 25,
  PEEK_VT_PTR = 26,
  PEEK_VT_SAFEARRAY = 27,
  PEEK_VT_CARRAY = 28,
  PEEK_VT_USERDEFINED = 29,
  PEEK_VT_LPSTR = 30,
  PEEK_VT_LPWSTR = 31,
  PEEK_VT_RECORD = 36,
  PEEK_VT_INT_PTR = 37,
  PEEK_VT_UINT_PTR = 38,
  PEEK_VT_FILETIME = 64,
  PEEK_VT_BLOB = 65,
  PEEK_VT_STREAM = 66,
  PEEK_VT_STORAGE = 67,
  PEEK_VT_STREAMED_OBJECT = 68,
  PEEK_VT_STORED_OBJECT = 69,
  PEEK_VT_BLOB_OBJECT = 70,
  PEEK_VT_CF = 71,
  PEEK_VT_CLSID = 72,
  PEEK_VT_VERSIONED_STREAM = 73,
  PEEK_VT_BSTR_BLOB = 0x0fff,
  PEEK_VT_VECTOR = 0x1000,
  PEEK_VT_ARRAY = 0x2000,
  PEEK_VT_BYREF = 0x4000,
  PEEK_VT_RESERVED = 0x8000,
  PEEK_VT_ILLEGAL = 0xffff,
  PEEK_VT_ILLEGALMASKED = 0x0fff,
  PEEK_VT_TYPEMASK = 0x0fff,
}

export enum PeekWEPAlgorithm {
  PEEK_WEP_ALGORITHM_40B_SHARED_KEY = 0, // TODO
  PEEK_WEP_ALGORITHM_128B_SHARED_KEY = 1, // TODO
  PEEK_WEP_ALGORITHM_152B_SHARED_KEY = 2, // TODO
  PEEK_WEP_ALGORITHM_USER_DEFINED_SHARED_KEY = 3, // TODO
  PEEK_WEP_ALGORITHM_WPA512B_SHARED_KEY = 4, // TODO
  PEEK_WEP_ALGORITHM_WPA_PASSPHRASE_SHARED_KEY = 5, // TODO
}

export enum PeekWirelessHardwareConfig {
  PEEK_WIRELESS_HARDWARE_CONFIG_CHANNEL = 0, // TODO
  PEEK_WIRELESS_HARDWARE_CONFIG_BSSID = 1, // TODO
  PEEK_WIRELESS_HARDWARE_CONFIG_ESSID = 2, // TODO
  PEEK_WIRELESS_HARDWARE_CONFIG_SCAN = 3, // TODO
}

export const PluginCategory = {
  PLUGIN_CATEGORY_PEEK_PLUGIN: "D8A51BAC-0297-4247-BF9A-9C956262ABD5",
  PLUGIN_CATEGORY_CONSOLE_PLUGIN: "CCF7D60E-46ED-4323-AC9A-03B854D17D4E",
  PLUGIN_CATEGORY_OMNI_ENGINE_PLUGIN: "4C116C92-7B4C-4C93-B586-B87570862337",
  PLUGIN_CATEGORY_OMNI_WEB_PLUGIN_ABOUT: "A5A2F78B-F8BD-41EC-80B5-F7410120ACD2",
  PLUGIN_CATEGORY_OMNI_WEB_PLUGIN_OPTIONS: "731379BF-FA2E-4389-8D6E-898DAA69A1BE",
  PLUGIN_CATEGORY_OMNI_WEB_PLUGIN_CAPTURE: "053ABC7A-56F0-49B0-9974-B555325497D7",
  PLUGIN_CATEGORY_OMNI_WEB_PLUGIN_ENGINE: "3D39505C-EB69-4EA9-A9AC-9270AF397E97",
  PLUGIN_CATEGORY_OMNI_WEB_PLUGIN_ADAPTER: "6166FE20-D4C6-4B42-BC71-8F1AABE7B5A2",
}

export enum ProtocolTranslationType {
  PROTOCOL_TRANSLATION_TYPE_TCP_PORT = 0,
  PROTOCOL_TRANSLATION_TYPE_UDP_PORT = 1,
  PROTOCOL_TRANSLATION_TYPE_SCTP_PORT = 2,
  PROTOCOL_TRANSLATION_TYPE_SCTP_PAYLOAD_ID = 3,
}

export enum SliceOffsetType {
  SLICE_OFFSET_START_OF_PACKET = 0,
  SLICE_OFFSET_LAYER_3_HEADER = 1,
  SLICE_OFFSET_LAYER_4_HEADER = 2,
  SLICE_OFFSET_LAYER_4_PAYLOAD = 3,
  SLICE_OFFSET_INNER_LAYER_3_HEADER = 4,
  SLICE_OFFSET_INNER_LAYER_4_HEADER = 5,
  SLICE_OFFSET_INNER_LAYER_4_PAYLOAD = 6,
}

export enum StatisticsOutputIntervalUnit {
  STATISTICS_OUTPUT_INTERVAL_UNIT_UNDEFINED = 0,
  STATISTICS_OUTPUT_INTERVAL_UNIT_SECONDS = 1,
  STATISTICS_OUTPUT_INTERVAL_UNIT_MINUTES = 2,
  STATISTICS_OUTPUT_INTERVAL_UNIT_HOURS = 3,
  STATISTICS_OUTPUT_INTERVAL_UNIT_DAYS = 4,
}

export enum StatisticsOutputMethodTypes {
  STATISTICS_OUTPUT_METHOD_TYPE_CSV = 0,
  STATISTICS_OUTPUT_METHOD_TYPE_TXT = 1,
  STATISTICS_OUTPUT_METHOD_TYPE_XML = 2,
  STATISTICS_OUTPUT_METHOD_TYPE_HTML = 3, // Obsolete. Now treated identically to STATISTICS_OUTPUT_METHOD_TYPE_XML. Kept for backwards compatibility.
  STATISTICS_OUTPUT_METHOD_TYPE_PDF = 4,
  STATISTICS_OUTPUT_METHOD_TYPE_CDR = 5,
}

export enum StatisticsReportTypes {
  STATISTICS_REPORT_TYPE_FULL_CSV = 0,
  STATISTICS_REPORT_TYPE_FULL_Text = 1,
  STATISTICS_REPORT_TYPE_FULL_XML = 2,
  STATISTICS_REPORT_TYPE_FULL_PDF = 3,
  STATISTICS_REPORT_TYPE_EXECUTIVE_SUMMARY_XML = 4,
  STATISTICS_REPORT_TYPE_NODE_SUMMARY_XML = 5,
  STATISTICS_REPORT_TYPE_NODE_XML = 6,
  STATISTICS_REPORT_TYPE_CONVERSATION_XML = 7,
  STATISTICS_REPORT_TYPE_EXPERT_SUMMARY_XML = 8,
  STATISTICS_REPORT_TYPE_EXPERT_XML = 9,
  STATISTICS_REPORT_TYPE_VOIP_XML = 10,
  STATISTICS_REPORT_TYPE_SUMMARY_XML = 11,
  STATISTICS_REPORT_TYPE_WIRELESS_XML = 12,
  STATISTICS_REPORT_TYPE_NODE_PDF = 13,
  STATISTICS_REPORT_TYPE_EXPERT_PDF = 14,
  STATISTICS_REPORT_TYPE_VOIP_PDF = 15,
  STATISTICS_REPORT_TYPE_SUMMARY_PDF = 16,
  STATISTICS_REPORT_TYPE_WIRELESS_PDF = 17,
  STATISTICS_REPORT_TYPE_CALL_DETAIL_RECORDS = 18,
}

export enum TimelineDataViewType {
  TIMELINE_DATA_VIEW_TYPE_MBITS = 0,
  TIMELINE_DATA_VIEW_TYPE_PACKETS = 1,
  TIMELINE_DATA_VIEW_TYPE_DEST_TYPE = 2,
  TIMELINE_DATA_VIEW_TYPE_SIZE_DIST = 3,
  TIMELINE_DATA_VIEW_TYPE_VLAN = 4,
  TIMELINE_DATA_VIEW_TYPE_PROTOCOLS_MBITS = 5,
  TIMELINE_DATA_VIEW_TYPE_PROTOCOLS_PACKETS = 6,
  TIMELINE_DATA_VIEW_TYPE_CALL_QUALITY = 7,
  TIMELINE_DATA_VIEW_TYPE_CALL_UTILIZATION = 8,
  TIMELINE_DATA_VIEW_TYPE_WIRELESS_PACKETS = 9,
  TIMELINE_DATA_VIEW_TYPE_WIRELESS_RETRIES = 10,
  TIMELINE_DATA_VIEW_TYPE_APPLICATIONS_MBITS = 11,
  TIMELINE_DATA_VIEW_TYPE_APPLICATIONS_PACKETS = 12,
}

export enum TimeStampFormat {
  TIMESTAMP_MILLISECONDS = 0,
  TIMESTAMP_MICROSECONDS = 1,
  TIMESTAMP_NANOSECONDS = 2,
  TIMESTAMP_SECONDS = 3,
}

export enum WebSelectRelatedParam {
  WEB_SELECT_RELATED_BY_REQUEST = 0, ///< Select by request.
  WEB_SELECT_RELATED_BY_RESPONSE = 1, ///< Select by response.
  WEB_SELECT_RELATED_BY_REQUEST_AND_RESPONSE = 2, ///< Select by request and response.
}
