export enum ChartType {
  AREA = "area",
  STACKED_AREA = "stackedArea",
  COLUMN = "column",
  STACKED_COLUMN = "stackedColumn",
  SKYLINE = "skyline",
  STACKED_SKYLINE = "stackedSkyline",
  OVERLAID_SKYLINE = "overlaidSkyline",
  LINE = "line",
  LINE_POINTS = "linePoints",
  POINTS = "points",
  HORIZONTAL_BAR = "horizontalBar",
  PIE = "pie",
  DONUT = "donut",
}
