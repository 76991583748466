import * as React from "react"
import { connect } from "react-redux"
import { toNumber } from "lodash"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import CopyToClipboard from "react-copy-to-clipboard"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Helmet } from "react-helmet"
import { Row, Col, FormGroup } from "reactstrap"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { View, ViewContent, ViewContentContainer } from "../common/View"
import { Input } from "../common/Input"
import { ButtonBar, CheckGroup, FieldSet, FormText, FormView, Label, Legend } from "../common/Form"
import { Alert } from "../common/Alert"
import { OutsideLink, PlainOutsideLink } from "../common/Link"
import { PrimaryButton, LightButton, OptionsButton, FileInputButton } from "../common/Buttons"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import {
  fetchLockingCode,
  fetchLicenseInfo,
  fetchVersion,
  postLicense,
  postLicenseSettings,
  restartEngine,
  MimeType,
} from "../../api/api"
import {
  LicenseGetInfo,
  LicenseSetInfo,
  LicenseSettings,
  ResponseGetEngineCapabilities,
  ResponseGetLockingCode,
  ResponseGetVersion,
} from "../../api/types"
import { NewActivation } from "../../api/types/activationTypes"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { getCapabilities, getServerUsername } from "../../store"

enum ActivationStep {
  REACTIVATE = 0,
  METHOD = 1,
  INFO = 2,
  MANUAL = 3,
  FINISH = 4,
}

enum ReactivationMethod {
  AUTOMATIC = 0,
  MANUAL = 1,
}

enum ActivationMethod {
  AUTOMATIC = 0,
  MANUAL = 1,
}

const ActivationViewHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 2rem;
`

const ActivationViewHeaderTitle = styled.h4`
  text-transform: uppercase;
`

const ActivationViewHeaderSubTitle = styled.h5`
  font-weight: normal;
`

const ActivationViewHeaderLinks = styled.div`
  font-size: 1.25rem;
  line-height: 1.2;
`

const PermissionAlert = styled(Alert)`
  margin-top: 1rem;
`

const PermissionWrapper = styled.div`
  display: flex;
  justify-content: center;
`

function getMajorMinorVersion(version: string) {
  const parts = version.split(/\./g, 2)
  if (parts.length === 2) {
    return parts.join(".")
  }
  return version
}

function ActivationAlert({ text }: { text: string }) {
  return (
    <Alert color="danger" fade={false} style={{ marginTop: "1rem" }}>
      <div>{text}</div>
      <div style={{ marginTop: "1rem" }}>
        <PlainOutsideLink
          href="https://www.liveaction.com/support/technical-support/"
          target="_blank"
          rel="noopener"
        >
          Contact Technical Support
        </PlainOutsideLink>
      </div>
    </Alert>
  )
}

type ActivationViewProps = RouteComponentProps & {
  engine: string
  authToken: string
  engineVersion: string
  serialNumber: string
  nextUrl: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  username: string
}

type ActivationViewState = {
  fatalError: string
  error: string
  activationServer: string
  licenseInfo: LicenseGetInfo | null
  reactivate: boolean
  lockingCode: string
  currentStep: ActivationStep
  reactivationMethod: ReactivationMethod
  activationMethod: ActivationMethod
  userName: string
  companyName: string
  email: string
  productKey: string
  licenseFile: string
  progress: string
  prodVersion: string
}

class ActivationView extends React.Component<ActivationViewProps, ActivationViewState> {
  state: ActivationViewState = {
    fatalError: "",
    error: "",
    activationServer: import.meta.env.VITE_ACTIVATION_SERVER || "https://api.savvius.com",
    licenseInfo: null,
    reactivate: false,
    lockingCode: "",
    currentStep: ActivationStep.METHOD,
    reactivationMethod: ReactivationMethod.AUTOMATIC,
    activationMethod: ActivationMethod.AUTOMATIC,
    userName: "",
    companyName: "",
    email: "",
    productKey: "",
    licenseFile: "",
    progress: "",
    prodVersion: "",
  }

  componentDidMount() {
    // Use an alternative activation server by adding an "activationServer"
    // key to local storage such as "https://api.webdev4.savvius.com".
    try {
      const activationServer = localStorage.getItem("activationServer")
      if (activationServer) {
        this.setState({ activationServer })
      }
    } catch {}

    const { engine, authToken } = this.props

    // Get the current license info from the engine.
    fetchLicenseInfo(engine, authToken)
      .then(licenseInfo => {
        if (licenseInfo) {
          const reactivate = licenseInfo.valid && (licenseInfo.type === 2 || licenseInfo.type === 3)
          const currentStep = reactivate ? ActivationStep.REACTIVATE : ActivationStep.METHOD
          this.setState({ licenseInfo, reactivate, currentStep })
        } else {
          throw new Error("Invalid license info response")
        }
      })
      .catch(() => {
        this.setState({ fatalError: "Error retrieving license info" })
      })

    // Get the locking key from the engine.
    fetchLockingCode(engine, authToken, 8208)
      .then((response: ResponseGetLockingCode) => {
        if (response && response.lockingCode) {
          this.setState({ lockingCode: response.lockingCode })
        } else {
          throw new Error("Invalid locking code response")
        }
      })
      .catch(() => {
        this.setState({ fatalError: "Error retrieving locking code" })
      })

    // Pull in the current engine version
    fetchVersion(engine, authToken)
      .then((response: ResponseGetVersion) => {
        this.setState({ prodVersion: response.engineVersion })
      })
      .catch(() => {
        this.setState({ fatalError: "Error retrieving engine version" })
      })
  }

  autoReactivate = () => {
    if (this.state.licenseInfo == null) return

    this.setState({ progress: "Contacting activation server" })

    // Renew activation.
    fetch(
      `${this.state.activationServer}/v5/activations/${this.state.licenseInfo.aid}/renew?retrieveLic=yes`,
      {
        method: "POST",
        headers: {
          authorization: `Basic ${import.meta.env.VITE_ACTIVATION_AUTH}`,
          accept: MimeType.MIME_TYPE_JSON,
        },
      }
    )
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          return Promise.reject({
            code: response.status,
            reason: response.statusText,
            message: "",
          })
        }
      })
      .then(response => {
        if (
          response != null &&
          response.licenseString != null &&
          typeof response.licenseString === "string"
        ) {
          this.setState({ licenseFile: response.licenseString }, () => {
            this.installEngineLicense()
          })
        } else {
          throw new Error("Invalid license file response")
        }
      })
      .catch(error => {
        let message = "Error renewing activation"
        if (error != null && error.code != null) {
          message = `Error renewing activation (Error code: ${error.code})`
        }
        this.setState({ error: message })
      })
  }

  autoActivate = () => {
    this.setState({ progress: "Contacting activation server" })

    // Add a new activation.
    const newActivation: NewActivation = {
      product: "oee",
      version: getMajorMinorVersion(this.props.engineVersion),
      serialNumPkId: this.state.productKey.trim(),
      lockingCode: this.state.lockingCode,
      customerName: this.state.userName,
      email: this.state.email,
      company: this.state.companyName,
      method: "automatically",
    }
    fetch(`${this.state.activationServer}/v5/activations`, {
      method: "POST",
      headers: {
        authorization: `Basic ${import.meta.env.VITE_ACTIVATION_AUTH}`,
        accept: MimeType.MIME_TYPE_JSON,
        "content-type": MimeType.MIME_TYPE_JSON,
      },
      body: JSON.stringify(newActivation),
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          return response.text().then(body => {
            return Promise.reject({
              code: response.status,
              reason: response.statusText,
              message: body,
            })
          })
        }
      })
      .then(response => {
        if (response && response.activation && response.activation.id !== undefined) {
          // Get the license file.
          this.setState({ progress: "Retrieving license file" })
          fetch(
            `${this.state.activationServer}/v5/activations/${response.activation.id}?type=licFile`,
            {
              method: "GET",
              headers: {
                authorization: `Basic ${import.meta.env.VITE_ACTIVATION_AUTH}`,
                accept: MimeType.MIME_TYPE_JSON,
              },
            }
          )
            .then(response => {
              if (response.ok) {
                return response.json()
              } else {
                return Promise.reject({
                  code: response.status,
                  reason: response.statusText,
                  message: "",
                })
              }
            })
            .then(response => {
              if (
                response &&
                response.licenseString !== undefined &&
                typeof response.licenseString === "string"
              ) {
                this.setState({ licenseFile: response.licenseString }, () => {
                  this.installEngineLicense()
                })
              } else {
                throw new Error("Invalid license file response")
              }
            })
            .catch(error => {
              let message = "Error retrieving license file"
              if (error != null && error.code != null) {
                message = `Error retrieving license file (Error code: ${error.code})`
              }
              this.setState({ error: message })
            })
        } else {
          throw new Error("Invalid activation response")
        }
      })
      .catch(error => {
        let message = "Error adding activation"
        if (error != null && error.code != null) {
          message = `Error adding activation (Error code: ${error.code})`
        }
        this.setState({ error: message })
      })
  }

  installEngineLicense = () => {
    this.setState({ progress: "Installing license file" })

    const { engine, authToken } = this.props
    const licenseInfo: LicenseSetInfo = {
      data: this.state.licenseFile,
      source: 1,
    }
    const { productKey } = this.state
    const licenseSettings: LicenseSettings = {
      licenseId: "",
      productKey,
    }

    const requestPostLicense = postLicense(engine, authToken, licenseInfo)
    const requestPostLicenseSettings = postLicenseSettings(engine, authToken, licenseSettings)
    Promise.all([requestPostLicense, requestPostLicenseSettings])
      .then(() => {
        this.setState({ progress: "Activation successful! The engine will now restart." }, () => {
          setTimeout(() => {
            restartEngine(engine, authToken).finally(() => {
              this.props.history.push(this.props.nextUrl)
            })
          }, 2000)
        })
      })
      .catch(() => {
        this.setState({ error: "Invalid license" })
      })
  }

  onPrevious = () => {
    let { currentStep } = this.state
    if (currentStep === ActivationStep.METHOD) {
      if (this.state.reactivate) {
        currentStep = ActivationStep.REACTIVATE
      }
    } else if (currentStep === ActivationStep.INFO) {
      currentStep = ActivationStep.METHOD
    } else if (currentStep === ActivationStep.MANUAL) {
      currentStep = ActivationStep.METHOD
    } else if (currentStep === ActivationStep.FINISH) {
      if (this.state.reactivate) {
        if (this.state.reactivationMethod === ReactivationMethod.AUTOMATIC) {
          currentStep = ActivationStep.REACTIVATE
        } else {
          if (this.state.activationMethod === ActivationMethod.AUTOMATIC) {
            currentStep = ActivationStep.INFO
          } else {
            currentStep = ActivationStep.MANUAL
          }
        }
      } else {
        if (this.state.activationMethod === ActivationMethod.AUTOMATIC) {
          currentStep = ActivationStep.INFO
        } else {
          currentStep = ActivationStep.MANUAL
        }
      }
    }
    this.setState({ currentStep, error: "", progress: "" })
  }

  onNext = () => {
    let { currentStep } = this.state
    if (currentStep === ActivationStep.REACTIVATE) {
      if (this.state.reactivationMethod === ReactivationMethod.AUTOMATIC) {
        this.autoReactivate()
        currentStep = ActivationStep.FINISH
      } else {
        currentStep = ActivationStep.METHOD
      }
    } else if (currentStep === ActivationStep.METHOD) {
      if (this.state.activationMethod === ActivationMethod.AUTOMATIC) {
        currentStep = ActivationStep.INFO
      } else {
        currentStep = ActivationStep.MANUAL
      }
    } else if (currentStep === ActivationStep.INFO) {
      this.autoActivate()
      currentStep = ActivationStep.FINISH
    } else if (currentStep === ActivationStep.MANUAL) {
      this.installEngineLicense()
      currentStep = ActivationStep.FINISH
    } else if (currentStep === ActivationStep.FINISH) {
      // Engine will restart and the user will wind up at the login page.
    }
    this.setState({ currentStep })
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { type, name, value, checked } = event.target
    if (type === "text" || type.includes("select")) {
      this.setState(prevState => ({ ...prevState, [name]: value }))
    } else if (type === "checkbox") {
      this.setState(prevState => ({ ...prevState, [name]: checked }))
    } else if (type === "number" || type === "radio") {
      this.setState(prevState => ({ ...prevState, [name]: toNumber(value) }))
    }
  }

  onOpenLicenseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const file = event.target.files[0]
    if (!file) return
    const reader = new FileReader()
    reader.onerror = () => {
      this.setState({
        error: "Error reading license file",
      })
    }
    reader.onload = (e: any) => {
      try {
        if (e.target.result) {
          this.setState({
            licenseFile: e.target.result,
            error: "",
          })
        } else {
          this.setState({
            error: "Error: empty license file?",
          })
        }
      } catch {
        this.setState({
          error: "Error importing license file",
        })
      }
    }
    reader.readAsText(file)

    // Reset the file input so the same file can be used again
    event.target.value = ""
  }

  render() {
    if (this.state.fatalError) {
      return (
        <View padding={false}>
          <Helmet title="Activate" />
          <BreadcrumbItem to={this.props.match.url} title="Activate" />
          <ViewContent>
            <ViewContentContainer maxWidth="960px">
              <ActivationAlert text={this.state.fatalError} />
            </ViewContentContainer>
          </ViewContent>
        </View>
      )
    }

    if (!this.state.licenseInfo || !this.state.lockingCode) {
      return null
    }

    const { engineCapabilities, username } = this.props
    if (
      engineCapabilities?.userRights.policies.includes(EngineUserPolicies.configureEngine) === false
    ) {
      return (
        <PermissionWrapper>
          <PermissionAlert color="danger">
            <h4>Activation Required</h4>
            This product must be activated by the LiveAction Activation server in order to be fully
            functional.
            <p>
              The user <b>{username}</b> does not have permission to make configuration changes.
              Please log out and contact your administrator.
            </p>
          </PermissionAlert>
        </PermissionWrapper>
      )
    }

    const { currentStep } = this.state

    let prevDisabled = false
    let prevHidden = false
    let nextDisabled = false
    const nextHidden = false
    switch (currentStep) {
      case ActivationStep.REACTIVATE:
        prevDisabled = true
        prevHidden = true
        break
      case ActivationStep.METHOD:
        if (!this.state.reactivate) {
          prevDisabled = true
          prevHidden = true
        }
        break
      case ActivationStep.INFO:
        nextDisabled =
          this.state.userName.length === 0 ||
          this.state.companyName.length === 0 ||
          this.state.email.length === 0 ||
          this.state.productKey.length === 0
        break
      case ActivationStep.MANUAL:
        nextDisabled = this.state.licenseFile.length === 0 || this.state.productKey.length === 0
        break
      case ActivationStep.FINISH:
        nextDisabled = true
        break
    }

    return (
      <View padding={false}>
        <Helmet title="Activate" />
        <BreadcrumbItem to={this.props.match.url} title="Activate" />
        <ViewContent>
          <ViewContentContainer maxWidth="960px">
            <ActivationViewHeader>
              <div>
                <ActivationViewHeaderTitle>
                  {this.state.reactivate ? "Update License" : "Activate License"}
                </ActivationViewHeaderTitle>
                <ActivationViewHeaderSubTitle>
                  {currentStep === ActivationStep.REACTIVATE
                    ? "Choose an option below to renew your license"
                    : "This product must be activated by the LiveAction Activation Server in order to be fully functional"}
                </ActivationViewHeaderSubTitle>
                <ActivationViewHeaderLinks>
                  <OutsideLink
                    href="https://www.liveaction.com/livewire-activation"
                    target="_blank"
                    rel="noopener"
                  >
                    Help
                  </OutsideLink>
                  &ensp;&bull;&ensp;
                  <OutsideLink
                    href="https://www.liveaction.com/support/frequently-asked-questions/"
                    target="_blank"
                    rel="noopener"
                  >
                    Frequently Asked Questions
                  </OutsideLink>
                  &ensp;&bull;&ensp;
                  <OutsideLink
                    href="https://www.liveaction.com/contact-sales/"
                    target="_blank"
                    rel="noopener"
                  >
                    Contact Sales
                  </OutsideLink>
                </ActivationViewHeaderLinks>
              </div>
              <ButtonBar>
                <LightButton hidden={prevHidden} disabled={prevDisabled} onClick={this.onPrevious}>
                  Previous
                </LightButton>
                <PrimaryButton hidden={nextHidden} disabled={nextDisabled} onClick={this.onNext}>
                  Next
                </PrimaryButton>
              </ButtonBar>
            </ActivationViewHeader>

            <FormView id="activationForm">
              {currentStep === ActivationStep.REACTIVATE ? (
                <FieldSet style={{ margin: 0 }}>
                  <Legend>Renew License</Legend>
                  <FormGroup>
                    <Row>
                      <Col md={{ size: 8, offset: 2 }}>
                        <CheckGroup
                          type="radio"
                          id="activationReactivateAutomatic"
                          name="reactivationMethod"
                          onChange={this.onChange}
                          value={ReactivationMethod.AUTOMATIC}
                          checked={this.state.reactivationMethod === ReactivationMethod.AUTOMATIC}
                        >
                          Check for an updated license
                        </CheckGroup>
                        <CheckGroup
                          type="radio"
                          id="activationReactivateManual"
                          name="reactivationMethod"
                          onChange={this.onChange}
                          value={ReactivationMethod.MANUAL}
                          checked={this.state.reactivationMethod === ReactivationMethod.MANUAL}
                        >
                          Activate with a new product key
                        </CheckGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                </FieldSet>
              ) : null}

              {currentStep === ActivationStep.METHOD ? (
                <FieldSet style={{ margin: 0 }}>
                  <Legend>Activation Method</Legend>
                  <FormGroup>
                    <Row>
                      <Col md={{ size: 8, offset: 2 }}>
                        <CheckGroup
                          type="radio"
                          id="activationMethodAutomatic"
                          name="activationMethod"
                          onChange={this.onChange}
                          value={ActivationMethod.AUTOMATIC}
                          checked={this.state.activationMethod === ActivationMethod.AUTOMATIC}
                        >
                          Automatic: requires an Internet connection
                        </CheckGroup>
                        <CheckGroup
                          type="radio"
                          id="activationMethodManual"
                          name="activationMethod"
                          onChange={this.onChange}
                          value={ActivationMethod.MANUAL}
                          checked={this.state.activationMethod === ActivationMethod.MANUAL}
                        >
                          Manual: generates your license via a web page
                        </CheckGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                </FieldSet>
              ) : null}

              {currentStep === ActivationStep.INFO ? (
                <FieldSet style={{ margin: 0 }}>
                  <Legend>Customer Information</Legend>
                  <FormGroup row>
                    <Label md="2" for="userName">
                      Name
                    </Label>
                    <Col md="8">
                      <Input
                        type="text"
                        id="userName"
                        name="userName"
                        onChange={this.onChange}
                        value={this.state.userName}
                        invalid={this.state.userName.length === 0}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="2" for="companyName">
                      Company
                    </Label>
                    <Col md="8">
                      <Input
                        type="text"
                        id="companyName"
                        name="companyName"
                        onChange={this.onChange}
                        value={this.state.companyName}
                        invalid={this.state.companyName.length === 0}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="2" for="email">
                      Email
                    </Label>
                    <Col md="8">
                      <Input
                        type="text"
                        id="email"
                        name="email"
                        onChange={this.onChange}
                        value={this.state.email}
                        invalid={this.state.email.length === 0}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="2" for="productKey">
                      Product Key
                    </Label>
                    <Col md="8">
                      <Input
                        type="text"
                        id="productKey"
                        name="productKey"
                        onChange={this.onChange}
                        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                          this.setState({ productKey: event.target.value.trim() })
                        }}
                        value={this.state.productKey}
                        invalid={this.state.productKey.length === 0}
                      />
                      {this.props.serialNumber ? (
                        <FormText>Device serial number: {this.props.serialNumber}</FormText>
                      ) : null}
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="2">Version</Label>
                    <Col md="8">
                      <Input type="text" readonly="true" value={this.state.prodVersion}></Input>
                    </Col>
                  </FormGroup>
                </FieldSet>
              ) : null}

              {currentStep === ActivationStep.MANUAL ? (
                <FieldSet style={{ margin: 0 }}>
                  <Legend>Manual Activation</Legend>
                  <FormGroup>
                    <Row>
                      <Col md={{ size: 8, offset: 2 }}>
                        <p>
                          Follow this link to{" "}
                          <OutsideLink
                            href="https://mypeek.liveaction.com/activate_product.php"
                            target="_blank"
                            rel="noopener"
                          >
                            activate
                          </OutsideLink>{" "}
                          and fill out the form there.
                        </p>
                        <p>
                          You will need the following information:
                          <br />
                          Locking code: {this.state.lockingCode}{" "}
                          <CopyToClipboard text={this.state.lockingCode}>
                            <OptionsButton
                              aria-label="Copy locking code to clipboard"
                              id="copy-locking-code"
                            >
                              <FontAwesome name="clipboard" />
                            </OptionsButton>
                          </CopyToClipboard>
                          <UncontrolledTooltip placement="top" target="copy-locking-code">
                            Copy locking code to clipboard
                          </UncontrolledTooltip>
                          <br />
                          Version:&nbsp;
                          {this.state.prodVersion}
                        </p>
                        <p>
                          When you are finished and have a license file, enter the Product Key,
                          click Choose License File below and then click Next.
                        </p>
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup row>
                    <Label md="2" for="productKey">
                      Product Key
                    </Label>
                    <Col md="8">
                      <Input
                        type="text"
                        id="productKey"
                        name="productKey"
                        onChange={this.onChange}
                        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => {
                          this.setState({ productKey: event.target.value.trim() })
                        }}
                        value={this.state.productKey}
                        invalid={this.state.productKey.length === 0}
                      />
                      {this.props.serialNumber ? (
                        <FormText>Device serial number: {this.props.serialNumber}</FormText>
                      ) : null}
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Label md="2">License File</Label>
                      <Col md="8">
                        <FileInputButton accept="*" onChange={this.onOpenLicenseFile}>
                          Choose License File
                        </FileInputButton>
                        {this.state.licenseFile && (
                          <FontAwesome name="check" style={{ marginLeft: "1rem" }} />
                        )}
                        {this.state.error && <ActivationAlert text={this.state.error} />}
                      </Col>
                    </Row>
                  </FormGroup>
                </FieldSet>
              ) : null}

              {currentStep === ActivationStep.FINISH ? (
                <FieldSet style={{ margin: 0 }}>
                  <Legend>{this.state.error ? "Activation Failed" : "Activation Complete"}</Legend>
                  <FormGroup>
                    <Row>
                      <Col md={{ size: 8, offset: 2 }}>
                        {this.state.error ? (
                          <ActivationAlert text={this.state.error} />
                        ) : (
                          <p>{this.state.progress}</p>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </FieldSet>
              ) : null}
            </FormView>
          </ViewContentContainer>
        </ViewContent>
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engineCapabilities: getCapabilities(state) || null,
  username: getServerUsername(state) || "",
})

export default connect(mapStateToProps)(withRouter(ActivationView))
