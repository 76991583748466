import { useSelector } from "react-redux"
import styled from "styled-components"
import { Nav, NavItem, NavItemLink } from "../common/Nav"
import {
  getEngineAuditLogUrl,
  getEngineConnectedUsersUrl,
  getEngineSupportUrl,
  getEngineDiagnosticsUrl,
} from "../../routes"
import { getCapabilities } from "../../store"
import { ResponseGetEngineCapabilities } from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"

const NavWrapper = styled.nav`
  color: ${props => props.theme.adminNavColor};
  background-color: ${props => props.theme.adminNavBackgroundColor};
`

const AdminNav = () => {
  const engineCapabilities: ResponseGetEngineCapabilities = useSelector(getCapabilities)
  const isConfigureEngineAllowed =
    engineCapabilities &&
    engineCapabilities.userRights.policies.includes(EngineUserPolicies.configureEngine)
  const isViewAuditLogAllowed =
    engineCapabilities &&
    engineCapabilities.userRights.policies.includes(EngineUserPolicies.viewAuditLog)
  return (
    <NavWrapper>
      <Nav>
        {isViewAuditLogAllowed &&
          engineCapabilities?.capabilities.includes(EngineCapabilities.auditLog) && (
            <NavItem>
              <NavItemLink to={getEngineAuditLogUrl()}>Audit Log</NavItemLink>
            </NavItem>
          )}
        {isConfigureEngineAllowed &&
          engineCapabilities?.capabilities.includes(EngineCapabilities.listConnectedUsers) && (
            <NavItem>
              <NavItemLink to={getEngineConnectedUsersUrl()}>Connected Users</NavItemLink>
            </NavItem>
          )}
        {isConfigureEngineAllowed && (
          <NavItem>
            <NavItemLink to={getEngineSupportUrl()}>Support</NavItemLink>
          </NavItem>
        )}
        {isConfigureEngineAllowed &&
          engineCapabilities?.capabilities.includes(EngineCapabilities.diagnosticsInfo) && (
            <NavItem>
              <NavItemLink to={getEngineDiagnosticsUrl()}>Diagnostics</NavItemLink>
            </NavItem>
          )}
      </Nav>
    </NavWrapper>
  )
}

export default AdminNav
