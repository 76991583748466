import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import useMeasure from "react-use/lib/useMeasure"
import { cloneDeep, toNumber } from "lodash"
import cn from "classnames"
import styled, { useTheme, DefaultTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import {
  SortIndicator,
  SortDirection,
  SortDirectionType,
  TableRowProps,
  TableCellProps,
  TableHeaderProps,
} from "react-virtualized"
import { DangerText } from "../common/DangerText"
import { Dot1 as Dot } from "../common/Dot"
import { OmniTable } from "../common/OmniTable"
import { IconDropdownToggle } from "../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import FilterBox from "../common/FilterBox"
import { SparklineSVG as Sparkline } from "../common/Sparkline"
import { SparklineDataCache } from "./sparklineDataCache"
import {
  formatInteger,
  formatDuration,
  formatISODateTime,
  formatGB,
  formatLinkSpeed,
} from "../../utils/formatUtils"
import { getMediaString } from "../../utils/mediaUtils"
import { compareCaptureSessions } from "../../utils/sortUtils"
import { getEngineForensicsCaptureSessionUrl } from "../../routes"
import {
  getCaptureSessionsFilter,
  getCaptureSessionsColumns,
  getCaptureSessionsSortBy,
  getCaptureSessionsSortDirection,
  getCaptureSessionsCollapsedGroups,
  getShowLocalTime,
} from "../../store"
import {
  setCaptureSessionsFilter,
  setCaptureSessionsColumns,
  setCaptureSessionsSort,
  setCaptureSessionsCollapsedGroups,
} from "../../store/ui"
import {
  DatabaseRowGetCaptureSessions,
  ResponseGetCaptureSessions,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"

const defaultColumns = [
  {
    dataKey: "Name",
    label: "Capture",
    width: 160,
    flexGrow: 1,
    flexShrink: 0,
    visible: 1,
  },
  {
    dataKey: "SessionStartTimestamp",
    label: "Session Start Time",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "StartTimestamp",
    label: "Data Start Time",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "StopTimestamp",
    label: "Data End Time",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "duration",
    label: "Data Duration",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "StorageUnits",
    label: "Size",
    width: 100,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "PacketCount",
    label: "Packets",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "DroppedCount",
    label: "Packets Dropped",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "MediaType",
    label: "Media",
    width: 120,
    visible: false,
  },
  {
    dataKey: "AdapterName",
    label: "Adapter",
    width: 200,
    visible: true,
  },
  {
    dataKey: "AdapterAddr",
    label: "Adapter Address",
    width: 140,
    visible: false,
  },
  {
    dataKey: "LinkSpeed",
    label: "Link Speed",
    width: 140,
    visible: false,
  },
  {
    dataKey: "Owner",
    label: "Owner",
    width: 140,
    visible: true,
  },
]

const CaptureHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;

  & > :last-child {
    flex-grow: 1;
  }
`

const CaptureGroup = styled.span`
  padding-left: 0.25em;
  font-weight: bold;
`

const CaptureName = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    flex: 0 0 19px;
  }

  &:last-child {
    flex: 1 0 auto;
  }
`

const CommandStrip = styled.div`
  display: flex;
`

type CaptureSessionsTableProps = {
  triggerRender?: number
  captureSessions: ResponseGetCaptureSessions | null
  captureSessionId: number | undefined
  onDeleteCaptureSessions: (captureId: number, name: string) => void
  onDeleteAllCaptureSessions: () => void
  sparklineDataCache: SparklineDataCache | null
  engineCapabilities: ResponseGetEngineCapabilities | null
  userId: string
}

const CaptureSessionsTable: React.FC<CaptureSessionsTableProps> = props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme() as DefaultTheme
  const filter = useSelector(getCaptureSessionsFilter) || ""
  const columns = useSelector(getCaptureSessionsColumns) || defaultColumns
  const sortBy = useSelector(getCaptureSessionsSortBy) || "StartTimestamp"
  const sortDirection: SortDirectionType =
    useSelector(getCaptureSessionsSortDirection) || SortDirection.DESC
  const collapsedGroups = useSelector(getCaptureSessionsCollapsedGroups) || new Set()
  const [captureHeaderRef, { width: captureHeaderWidth }] = useMeasure<HTMLDivElement>()
  const filterBoxRef: React.RefObject<HTMLDivElement> = React.useRef(null)
  const showLocalTime = useSelector(getShowLocalTime)

  const onChangeFilter = (filter: string) => {
    dispatch(setCaptureSessionsFilter(filter))
  }

  const onShowDefaultColumns = () => {
    dispatch(setCaptureSessionsColumns(defaultColumns))
  }

  const onShowAllColumns = () => {
    const newColumns = cloneDeep(columns)
    newColumns.forEach((col: any) => {
      col.visible = true
    })
    dispatch(setCaptureSessionsColumns(newColumns))
  }

  const onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColumns = cloneDeep(columns)
    const col = newColumns.find((col: any) => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      dispatch(setCaptureSessionsColumns(newColumns))
    }
  }

  const onExpandCollapse = (group: string) => {
    const newCollapsedGroups = new Set<string>(collapsedGroups)
    if (newCollapsedGroups.has(group)) {
      newCollapsedGroups.delete(group)
    } else {
      newCollapsedGroups.add(group)
    }
    dispatch(setCaptureSessionsCollapsedGroups(newCollapsedGroups))
  }

  const onRowClick = ({ rowData }: { rowData: any }) => {
    const isGroup = rowData.children !== undefined
    if (isGroup) {
      onExpandCollapse(String(rowData.captureId))
    } else {
      history.push(getEngineForensicsCaptureSessionUrl(rowData.SessionID))
    }
  }

  const rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    const isGroup = rowData.children !== undefined
    if (isGroup) {
      className = cn(className, "group")
      key = `${rowData.captureId}-group`
      if (Array.isArray(columns) && columns.length > 1) {
        // Only need the first and last columns
        columns = [columns[0], columns[columns.length - 1]]
      }
    } else {
      const isSelected = props.captureSessionId === rowData.SessionID
      if (isSelected) {
        className = cn(className, "selected")
      } else {
        if (rowData.childIndex !== undefined) {
          if (rowData.childIndex % 2 !== 0) {
            className = cn(className, "stripe")
          }
        }
      }
      key = rowData.SessionID
    }

    // All rows are clickable.
    className = cn(className, "clickable")

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  const rowClassName = () => {
    // Stripes applied in rowRenderer
    return ""
  }

  const captureHeaderRenderer = ({ dataKey, label, sortBy, sortDirection }: TableHeaderProps) => {
    return (
      <CaptureHeader ref={captureHeaderRef}>
        <div>{label}</div>
        {sortBy === dataKey ? <SortIndicator sortDirection={sortDirection} /> : <div>&nbsp;</div>}
        <div ref={filterBoxRef}>
          <FilterBox
            size="sm"
            value={filter}
            aria-label="Search capture sessions"
            placeholder="Search"
            onChange={onChangeFilter}
          />
        </div>
      </CaptureHeader>
    )
  }

  const cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    const isGroup = rowData.children !== undefined
    if (isGroup) {
      if (dataKey === "Name") {
        const collapsed = collapsedGroups.has(String(rowData.captureId))
        const icon = collapsed ? "chevron-right" : "chevron-down"
        return (
          <>
            <FontAwesome name={icon} fixedWidth />
            <CaptureGroup>{rowData.name}</CaptureGroup>
          </>
        )
      } else {
        return null
      }
    }
    let content
    switch (dataKey) {
      case "Name":
        {
          let sparkline: React.ReactNode
          const sparklineData = props.sparklineDataCache?.getSessionData(rowData.SessionID)
          if (sparklineData && sparklineData.length > 0) {
            sparkline = (
              <Sparkline
                width={captureHeaderWidth - 18}
                height={18}
                data={sparklineData}
                type="step"
                strokeStyle={theme.sparklineStrokeColor}
                fillStyle={theme.sparklineFillColor}
              />
            )
          }

          content = (
            <CaptureName>
              <Dot show={props.captureSessionId === rowData.SessionID} />
              {sparkline}
            </CaptureName>
          )
        }
        break
      case "SessionStartTimestamp":
      case "StartTimestamp":
      case "StopTimestamp":
        if (cellData) {
          content = formatISODateTime(cellData, 0, showLocalTime)
        }
        break
      case "duration":
        if (
          rowData.StartTimestamp != null &&
          rowData.StartTimestamp !== 0 &&
          rowData.StopTimestamp != null &&
          rowData.StopTimestamp !== 0
        ) {
          const start = Date.parse(rowData.StartTimestamp)
          const stop = Date.parse(rowData.StopTimestamp)
          content = formatDuration((stop - start) * 1000000)
        }
        break
      case "StorageUnits":
        if (cellData !== undefined) {
          content = formatGB(cellData)
        }
        break
      case "PacketCount":
        if (cellData !== undefined) {
          content = formatInteger(cellData)
        }
        break
      case "DroppedCount":
        if (cellData !== undefined) {
          const droppedCount = formatInteger(cellData)
          if (cellData > 0) {
            content = <DangerText title={droppedCount}>{droppedCount}</DangerText>
          } else {
            content = droppedCount
          }
        }
        break
      case "MediaType":
        if ("MediaType" in rowData && "MediaSubType" in rowData) {
          content = getMediaString(toNumber(rowData.MediaType), toNumber(rowData.MediaSubType))
        }
        break
      case "LinkSpeed":
        if (cellData) {
          content = formatLinkSpeed(cellData)
        }
        break
      case "AdapterName":
      case "AdapterAddr":
      case "Owner":
        content = cellData
        break
      default:
        break
    }
    return content
  }

  const commandCellRenderer = ({ rowData }: TableCellProps) => {
    const isGroup = rowData.children !== undefined
    if (isGroup) {
      let userOwnsAllCaptures = false
      if (props.captureSessions) {
        userOwnsAllCaptures = props.captureSessions.rows.every(
          (row: DatabaseRowGetCaptureSessions) => props.userId === row.Owner
        )
      }
      const isUserOwner = props.userId === rowData.owner
      let canDeleteAllCaptures = true
      let canDeleteAllFiles = true
      let canDeleteCaptures = true
      let canDeleteFiles = true
      if (props.engineCapabilities) {
        const policies = props.engineCapabilities.userRights.policies
        canDeleteAllCaptures =
          userOwnsAllCaptures || policies.includes(EngineUserPolicies.deleteCaptures)
        canDeleteAllFiles = userOwnsAllCaptures || policies.includes(EngineUserPolicies.deleteFiles)
        canDeleteCaptures = isUserOwner || policies.includes(EngineUserPolicies.deleteCaptures)
        canDeleteFiles = isUserOwner || policies.includes(EngineUserPolicies.deleteFiles)
      }

      return (
        <CommandStrip className="commands">
          <UncontrolledDropdownWithPortal
            dropdownToggle={
              <IconDropdownToggle>
                <FontAwesome name="ellipsis-h" fixedWidth />
              </IconDropdownToggle>
            }
          >
            <DropdownMenu end>
              <DropdownItem
                disabled={!canDeleteCaptures || !canDeleteFiles}
                onClick={props.onDeleteCaptureSessions.bind(null, rowData.captureId, rowData.name)}
              >
                Delete Capture Sessions
              </DropdownItem>
              <DropdownItem
                disabled={!canDeleteAllCaptures || !canDeleteAllFiles}
                onClick={props.onDeleteAllCaptureSessions}
              >
                Delete All Capture Sessions
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdownWithPortal>
        </CommandStrip>
      )
    }
    return null
  }

  const onSort = ({
    sortBy,
    sortDirection,
    event,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
    event: any
  }) => {
    const isFilterBox =
      filterBoxRef && filterBoxRef.current && filterBoxRef.current.contains(event.target)
    if (!isFilterBox) {
      dispatch(setCaptureSessionsSort(sortBy, sortDirection))
    }
  }

  const getFlattenedTree = () => {
    if (props.captureSessions == null) return null

    let captureSessionRows = cloneDeep(props.captureSessions.rows)
    if (filter) {
      const lowerCaseFilter = filter.toLowerCase()
      captureSessionRows = captureSessionRows.filter((row: DatabaseRowGetCaptureSessions) =>
        row.Name ? row.Name.toLowerCase().indexOf(lowerCaseFilter) !== -1 : false
      )
    }

    // Build hierarchical representation
    const groups: any[] = []
    captureSessionRows.forEach((row: DatabaseRowGetCaptureSessions) => {
      const group = groups.find(group => group.captureId === row.CaptureID)
      if (group) {
        group.children.push(row)
      } else {
        groups.push({
          captureId: row.CaptureID,
          name: row.Name,
          owner: row.Owner,
          children: [row],
        })
      }
    })

    // Sort children
    groups.forEach(group => {
      group.children.sort((a: DatabaseRowGetCaptureSessions, b: DatabaseRowGetCaptureSessions) =>
        compareCaptureSessions(a, b, sortBy, sortDirection)
      )
    })

    // Sort top level groups
    groups.sort((a, b) => {
      let childA = 0
      let childB = 0
      if (sortDirection === SortDirection.ASC) {
        childA = a.children.length - 1
        childB = b.children.length - 1
      }
      return compareCaptureSessions(a.children[childA], b.children[childB], sortBy, sortDirection)
    })

    // Flatten the list into rows
    const rows: any[] = []
    groups.forEach(group => {
      rows.push(group)
      const collapsed = collapsedGroups.has(String(group.captureId))
      if (!collapsed) {
        group.children.forEach((item: DatabaseRowGetCaptureSessions, index: number) => {
          rows.push({ ...item, childIndex: index })
        })
      }
    })

    return rows
  }

  const captureSessions = getFlattenedTree()
  if (captureSessions == null) return null
  const newColumns = cloneDeep(columns)
  const captureColumn = newColumns.find((col: any) => col.dataKey === "Name")
  if (captureColumn) {
    captureColumn.headerRenderer = captureHeaderRenderer
  }
  return (
    <OmniTable
      data={captureSessions}
      rowCount={captureSessions.length}
      headerHeight={34}
      columnDesc={newColumns}
      rowRenderer={rowRenderer}
      rowClassName={rowClassName}
      cellRenderer={cellRenderer}
      renderCommands={commandCellRenderer}
      onRowClick={onRowClick}
      onShowDefaultColumns={onShowDefaultColumns}
      onShowAllColumns={onShowAllColumns}
      onToggleColumn={onToggleColumn}
      sort={onSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
    />
  )
}

export default React.memo(CaptureSessionsTable)
