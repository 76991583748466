import * as React from "react"
import { Col, Form, FormGroup, Row } from "reactstrap"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { Label } from "../common/Form"
import { Input } from "../common/Input"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../common/Modal"

type ReconstructionsDownloadModalProps = {
  fileName: string | undefined
  onCancel: () => void
  onOK: (fileName: string | undefined) => void
}

const ReconstructionsDownloadModal: React.FC<ReconstructionsDownloadModalProps> = downloadProps => {
  const [fileName, setFileName] = React.useState<string | undefined>(downloadProps.fileName)

  const onChangeFileName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setFileName(value)
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const { onOK } = downloadProps
    onOK(fileName)
  }

  const { onCancel } = downloadProps

  return (
    <Modal size="md" isOpen={true}>
      <ModalHeader toggle={onCancel}>Download File</ModalHeader>
      <ModalBody>
        <p>
          Some files can contain viruses or otherwise be harmful to your computer. Save this file?
        </p>
        <Form id="reconstructions-download-form" onSubmit={onSubmit}>
          <Row>
            <Col md={3}>
              <FormGroup noMargin>
                <Label style={{ marginTop: "0.5rem" }} for="name">
                  File Name
                </Label>
              </FormGroup>
            </Col>
            <Col md={9}>
              <FormGroup noMargin>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  value={fileName}
                  onChange={onChangeFileName}
                  invalid={fileName === undefined}
                  spellCheck={false}
                />
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton
          disabled={fileName === undefined}
          type="submit"
          form="reconstructions-download-form"
        >
          OK
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  )
}

export default ReconstructionsDownloadModal
