import styled from "styled-components"
import {
  Toast as ReactstrapToast,
  ToastHeader as ReactstrapToastHeader,
  ToastBody as ReactstrapToastBody,
} from "reactstrap"

export const Toast = styled(ReactstrapToast)`
  &.toast {
    font-size: 1rem;
    box-shadow: ${props => props.theme.toastBoxShadow};
    background-color: ${props => props.theme.toastBackgroundColor};
  }
`

export const ToastHeader = styled(ReactstrapToastHeader)`
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.toastHeaderBackgroundColor};
`

export const ToastBody = styled(ReactstrapToastBody)`
  padding: 1rem;
`
