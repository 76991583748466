import * as React from "react"
import FontAwesome from "react-fontawesome"
import { InputProps } from "reactstrap"
import { IconButton } from "../Buttons"
import { Input } from "../Input"

export const PasswordInput = (props: InputProps) => {
  const [showPassword, setShowPassword] = React.useState(false)
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "stretch",
        width: "100%",
      }}
    >
      <Input
        {...props}
        type={showPassword ? "text" : "password"}
        style={{ paddingRight: "calc(1.5em + 0.75rem)", backgroundImage: "none" }}
      />
      <IconButton
        aria-label="Toggle password visibility"
        onClick={() => {
          setShowPassword(!showPassword)
        }}
        style={{
          position: "absolute",
          top: "calc(1px + 0.375rem)",
          right: "calc(1px + 0.375rem)",
        }}
      >
        <FontAwesome fixedWidth name={showPassword ? "eye" : "eye-slash"} />
      </IconButton>
    </div>
  )
}

export default PasswordInput
