import * as React from "react"

export const IconWorkflow = (props: React.SVGAttributes<SVGElement>) => {
  const { fill = "none", stroke = "currentColor", ...svgProps } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 16 16"
      shapeRendering="geometricPrecision"
      height={16}
      width={16}
      {...svgProps}
    >
      <g transform="translate(0.5,0.5)">
        <rect
          id="rect"
          x="2"
          y="10"
          width="4"
          height="4"
          fill={fill}
          stroke={stroke}
          strokeWidth="1"
        />
        <circle id="circle" cx="4" cy="4" r="2" fill={fill} stroke={stroke} strokeWidth="1" />
        <path
          id="diamond"
          d="M 10,4 L 12,2 L 14,4 12,6 Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1"
        />
        <path
          id="triangle"
          d="M 10,14 L 12,10 L 14,14 Z"
          fill={fill}
          stroke={stroke}
          strokeWidth="1"
        />
        <line x1="4" y1="9" x2="4" y2="7" stroke={stroke} strokeWidth="1" />
        <line x1="7" y1="4" x2="9" y2="4" stroke={stroke} strokeWidth="1" />
        <line x1="12" y1="7" x2="12" y2="9" stroke={stroke} strokeWidth="1" />
      </g>
    </svg>
  )
}
