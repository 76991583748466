import { serverLogOutErrorAction, logOutErrorAction } from "./auth"
import { checkFetchResponse } from "../store"
import * as API from "../api/api"
import { ResponseGetStatus } from "../api/types"

// Action types
export const types = {
  RESET_STATUS: "STATUS/RESET",
  FETCH_STATUS: "STATUS/FETCH",
  FETCH_STATUS_PENDING: "STATUS/FETCH_PENDING",
  FETCH_STATUS_FULFILLED: "STATUS/FETCH_FULFILLED",
  FETCH_STATUS_REJECTED: "STATUS/FETCH_STATUS_REJECTED",
  UPDATE_STATUS_COUNTER: "STATUS/UPDATE_STATUS_COUNTER",
  UPDATE_SERVER_STATUS: "STATUS/UPDATE_SERVER_STATUS",
  UPDATE_SERVER_STATUS_COUNTER: "STATUS/UPDATE_SERVER_STATUS_COUNTER",
}

// Initial state
export const initialState = {
  status: null,
  error: null,
  updateCounter: 0,
  serverUpdateCounter: 0,
  serverName: null,
  serverUserName: null,
  serverLicensed: null,
  serverLicenseExpired: null,
  serverSerialNumber: null,
  serverCustomSettings: 0,
  serverNamesModificationTime: null,
  serverStatus: null,
  enginesModificationTime: null,
}

// Reducer
export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case types.RESET_STATUS:
      return { ...state, status: null }
    case types.FETCH_STATUS_PENDING:
      return { ...state, error: null }
    case types.FETCH_STATUS_FULFILLED:
      return { ...state, error: null, status: { ...action.payload } }
    case types.FETCH_STATUS_REJECTED:
      return { ...state, error: action.payload }
    case types.UPDATE_STATUS_COUNTER:
      return { ...state, updateCounter: state.updateCounter + 1 }
    case types.UPDATE_SERVER_STATUS:
      return {
        ...state,
        error: null,
        serverName: action.payload.name,
        serverUserName: action.payload.userName,
        serverLicensed: action.payload.licensed != null ? action.payload.licensed : false,
        serverLicenseExpired:
          action.payload.licenseExpired != null ? action.payload.licenseExpired : false,
        serverSerialNumber: action.payload.serialNumber,
        serverCustomSettings:
          action.payload.customSettings != null ? action.payload.customSettings : 0,
        serverNamesModificationTime: action.payload.namesModificationTime,
        serverStatus: { ...action.payload },
        enginesModificationTime: action.payload.enginesModificationTime,
      }
    case types.UPDATE_SERVER_STATUS_COUNTER:
      return { ...state, serverUpdateCounter: state.serverUpdateCounter + 1 }
    default:
      break
  }

  return state
}

// Actions

export const resetStatus = () => {
  return { type: types.RESET_STATUS }
}

export const fetchStatus = (
  engine: string,
  authToken: string,
  updateStatus: boolean,
  updateServer: boolean,
  init?: RequestInit
) => {
  return (dispatch: Function) => {
    dispatch({ type: types.FETCH_STATUS_PENDING })
    API.fetchStatus(engine, authToken, init)
      .then(response => {
        if (response.ok) {
          response
            .json()
            .then((json: ResponseGetStatus) => {
              if (updateStatus) {
                dispatch({
                  type: types.FETCH_STATUS_FULFILLED,
                  payload: json,
                })
              }
              if (updateServer) {
                dispatch({
                  type: types.UPDATE_SERVER_STATUS,
                  payload: json,
                })
              }
            })
            .catch(error => {
              dispatch({
                type: types.FETCH_STATUS_REJECTED,
                payload: error,
              })
            })
        } else {
          dispatch({
            type: types.FETCH_STATUS_REJECTED,
            payload: response.statusText,
          })
          if (updateStatus) {
            checkFetchResponse(response, () => dispatch(logOutErrorAction(response.statusText)))
          }
          if (updateServer) {
            checkFetchResponse(response, () =>
              dispatch(serverLogOutErrorAction(response.statusText))
            )
          }
        }
      })
      .catch(error => {
        dispatch({
          type: types.FETCH_STATUS_REJECTED,
          payload: error,
        })
        if (updateStatus) {
          // Log out on a network error.
          dispatch(logOutErrorAction("Network error"))
        }
        if (updateServer) {
          // Log out on a network error.
          dispatch(serverLogOutErrorAction("Network error"))
        }
      })
  }
}

export const updateStatus = () => {
  return { type: types.UPDATE_STATUS_COUNTER }
}

export const updateServerStatus = () => {
  return { type: types.UPDATE_SERVER_STATUS_COUNTER }
}

// Selectors

export const getStatus = (state: any) => state.status

export const getStatusUpdateCounter = (state: any) => state.updateCounter

export const getServerStatusUpdateCounter = (state: any) => state.serverUpdateCounter

export const getEngineCustomSettings = (state: any): number =>
  state.status && state.status.customSettings != null ? state.status.customSettings : 0

export const getEngineAddress = (state: any): string =>
  state.status !== null && state.status.address !== undefined
    ? state.status.address.includes(".")
      ? state.status.address.split(":")[0]
      : state.status.address
    : ""

export const getEngineName = (state: any): string | undefined => state.status && state.status.name

export const getEngineType = (state: any): string =>
  state.status !== null && state.status.engineType !== undefined ? state.status.engineType : ""

export const getEngineVersion = (state: any): string =>
  state.status !== null && state.status.productVersion !== undefined
    ? state.status.productVersion
    : ""

export const getEngineFileVersion = (state: any): string =>
  state.status !== null && state.status.fileVersion !== undefined ? state.status.fileVersion : ""

export const getEngineLicensed = (state: any): boolean =>
  state.status !== null && state.status.licensed !== undefined ? state.status.licensed : false

export const getEngineLicenseType = (state: any): number =>
  state.status !== null && state.status.licenseType !== undefined ? state.status.licenseType : 0

export const getEngineLicenseExpired = (state: any): boolean =>
  state.status !== null && state.status.licenseExpired !== undefined
    ? state.status.licenseExpired
    : false

export const getEngineLicenseExpirationDate = (state: any): string =>
  state.status !== null && state.status.licenseExpirationDate !== undefined
    ? state.status.licenseExpirationDate
    : ""

export const getEngineSerialNumber = (state: any): string =>
  state.status !== null && state.status.serialNumber !== undefined ? state.status.serialNumber : ""

export const getUserId = (state: any): string => state.status && state.status.userId

export const getUsername = (state: any): string => state.status && state.status.userName

export const getIdleTimeout = (state: any): number | undefined =>
  state.status !== null ? state.status.idleTimeout : undefined

export const getDataFolder = (state: any): string | undefined =>
  state.status && state.status.dataFolder

export const getFiltersModificationTime = (state: any): string | undefined =>
  state.status && state.status.filtersModificationTime

export const getAlarmsModificationTime = (state: any): string | undefined =>
  state.status && state.status.alarmsModificationTime

export const getNamesModificationTime = (state: any): string | undefined =>
  state.status && state.status.namesModificationTime

export const getNotificationsModificationTime = (state: any): string | undefined =>
  state.status && state.status.notificationsModificationTime

export const getServerName = (state: any): string | null => state.serverName

export const getServerUsername = (state: any): string | null => state.serverUserName

export const getServerLicensed = (state: any): boolean => state.serverLicensed

export const getServerLicenseExpired = (state: any): boolean => state.serverLicenseExpired

export const getServerSerialNumber = (state: any): string =>
  state.serverSerialNumber !== null ? state.serverSerialNumber : ""

export const getServerCustomSettings = (state: any): number => state.serverCustomSettings

export const getServerNamesModificationTime = (state: any): string | null =>
  state.serverNamesModificationTime

export const getServerStatus = (state: any) => state.serverStatus

export const getEnginesModificationTime = (state: any): string | null =>
  state.enginesModificationTime

export const getLogTotalCount = (state: any): number | undefined =>
  state.status && state.status.logTotalCount
