import * as React from "react"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { Input } from "../common/Input"
import { Link } from "../common/Link"
import { Panel } from "../common/Panel"
import { IconButton, SecondaryButton, SecondaryDangerButton } from "../common/Buttons"
import PropTable from "../common/PropTable"
import { propToLabel, formatProp } from "../../utils/propUtils"
import { getEngineForensicSearchUrl } from "../../routes"
import { ForensicSearchProperties } from "../../api/types"
import { PeekFileViewStatus } from "../../api/types/peekTypes"

const propList = [
  ["status", "startTime"],
  ["captureName", "stopTime"],
  ["adapter", "duration"],
  ["linkSpeed", "creator"],
  ["mediaType", "creationTime"],
  ["packetCount", "runDuration"],
]

const ForensicSearch = styled(Panel)`
  display: flex;
  flex-direction: row;

  & + & {
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const ForensicSearchItem = styled.div`
  flex-grow: 1;
`

const ButtonStrip = styled.div`
  display: flex;
  flex-direction: column;

  & button + button {
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    margin-top: 8px;

    & button + button {
      margin-left: 4px;
      margin-top: 0;
    }
  }
`

type ForensicSearchPanelProps = {
  forensicSearch: ForensicSearchProperties
  isViewEnabled: boolean
  isDeleteForensicSearchEnabled: boolean
  onDeleteForensicSearch: (id: string) => void
  isEditingOfNameEnabled: boolean
  onUpdateForensicSearchName: (id: string, name: string) => void
  onStopLoadForensicSearch: (id: string) => void
  showLocalTime: boolean
}

const ForensicSearchPanel = (props: ForensicSearchPanelProps) => {
  const {
    forensicSearch,
    isViewEnabled,
    isDeleteForensicSearchEnabled,
    onDeleteForensicSearch,
    isEditingOfNameEnabled,
    onUpdateForensicSearchName,
    onStopLoadForensicSearch,
    showLocalTime,
  } = props

  const [newForensicName, setNewForensicName] = React.useState(forensicSearch.name)
  const [isNameBeingEdited, setIsNameBeingEdited] = React.useState(false)

  const onForensicSearchNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewForensicName(event.target.value)
  }

  const updateForensicNameIfChanged = (id: string, name: string) => {
    if (name !== forensicSearch.name) {
      onUpdateForensicSearchName(forensicSearch.id, newForensicName)
    }
    // regardless of name changing or not, we are done editing
    onEnabledEditingOfName(false)
  }

  const onForensicSearchNameKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      updateForensicNameIfChanged(forensicSearch.id, newForensicName)
    } else if (event.key === "Escape") {
      setNewForensicName(forensicSearch.name)
      onEnabledEditingOfName(false) // stop editing
    }
  }

  const onForensicSearchNameLoseFocus = (event: React.FocusEvent<HTMLElement>) => {
    updateForensicNameIfChanged(forensicSearch.id, newForensicName)
  }

  const onEnabledEditingOfName = (enable: boolean) => {
    setIsNameBeingEdited(enable) // start/stop editing
  }

  return (
    <ForensicSearch key={forensicSearch.id}>
      <ForensicSearchItem>
        {isEditingOfNameEnabled && isNameBeingEdited ? (
          <Input
            style={{ width: "auto" }}
            type="text"
            name={`fs-${forensicSearch.id}-name`}
            id={`fs-${forensicSearch.id}-name`}
            aria-label="Edit Name"
            autoFocus
            value={newForensicName}
            onBlur={onForensicSearchNameLoseFocus.bind(this)}
            onChange={onForensicSearchNameChange.bind(this)}
            onKeyUp={onForensicSearchNameKeyUp.bind(this)}
          />
        ) : (
          <h5>
            {forensicSearch.openResult !== 0 || !isViewEnabled ? (
              forensicSearch.name
            ) : (
              <Link to={getEngineForensicSearchUrl(forensicSearch.id)}>
                {forensicSearch.name}
              </Link>
            )}
            {isEditingOfNameEnabled && (
              <IconButton
                aria-label="Edit"
                style={{ marginLeft: "0.5rem" }}
                onClick={onEnabledEditingOfName.bind(this, true)}
              >
                <FontAwesome name="pencil" fixedWidth />
              </IconButton>
            )}
          </h5>
        )}
        <PropTable
          propList={propList}
          data={forensicSearch}
          propToLabel={propToLabel}
          formatProp={(prop: string, data: any) => {
            return formatProp(prop, data, { showLocalTime })
          }}
        />
      </ForensicSearchItem>
      <ButtonStrip>
        {forensicSearch.status !== PeekFileViewStatus.PEEK_FILE_VIEW_STATUS_COMPLETE && (
          <SecondaryButton
            size="sm"
            onClick={onStopLoadForensicSearch.bind(this, forensicSearch.id)}
          >
            <FontAwesome name="stop" /> Stop
          </SecondaryButton>
        )}
        <SecondaryDangerButton
          size="sm"
          disabled={!isDeleteForensicSearchEnabled}
          onClick={onDeleteForensicSearch.bind(this, forensicSearch.id)}
        >
          <FontAwesome name="trash-o" /> Delete
        </SecondaryDangerButton>
      </ButtonStrip>
    </ForensicSearch>
  )
}

export default ForensicSearchPanel
