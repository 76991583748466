import * as React from "react"
import styled, { useTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import ReactGridLayout, { WidthProvider, Layout } from "react-grid-layout"
import { useImmer } from "use-immer"
import { useParams } from "react-router-dom"
import useMedia from "react-use/lib/useMedia"
import { useSelector } from "react-redux"
import { LightDropdownToggle } from "../../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../../common/Dropdown"
import { Interval } from "../../common/Interval"
import AddWidgetModal from "../AddWidgetModal"
import CaptureCountersWidget from "../Widgets/CaptureCountersWidget"
import GlobalEventCountersWidget from "../Widgets/GlobalEventCountersWidget"
import ForensicChartWidget from "../Widgets/ForensicChartWidget"
import ForensicTopStatsWidget from "../Widgets/ForensicTopStatsWidget"
import { Dashboard, Widget } from "../../../api/types/dashboardTypes"
import {
  getGlobalEventCounters,
  getCaptureCounters,
  getForensicChartData,
  getForensicTopStatsData,
} from "./dashboardUtils"
import {
  fetchDashboards,
  fetchWidgets,
  postWidget,
  putWidget,
  deleteWidget,
} from "../../../api/api"
import { getServer, getServerAuthToken, getEngines } from "../../../store"

const GridLayout = WidthProvider(ReactGridLayout)

const DashboardContainer = styled.div`
  flex-grow: 1;

  & .react-grid-item:not(.react-grid-placeholder) {
    display: flex;
  }

  & .react-grid-item.react-grid-placeholder {
    background: ${props => props.theme.primaryButtonBackgroundColor};
  }

  & .react-grid-item.resizing {
    opacity: 0.9;
  }

  & .react-grid-item > .react-resizable-handle::after {
    opacity: 0.65;
    border-color: ${props => props.theme.textMutedColor};
  }

  & .react-grid-item .react-resizable-handle,
  & .react-grid-item .show-on-hover {
    opacity: 0;
    visibility: hidden;
  }

  & .react-grid-item:hover .react-resizable-handle,
  & .react-grid-item.resizing .react-resizable-handle,
  & .react-grid-item:hover .show-on-hover,
  & .react-grid-item.resizing .show-on-hover,
  & .react-grid-item:focus-within .show-on-hover {
    opacity: 1;
    visibility: visible;
  }
`

const DashboardHeader = styled.div`
  flex-grow: 1;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  position: relative;
`

const DashboardTitle = styled.h2`
  margin: 0;
  text-align: center;
`

type DashboardOptionsProps = {
  onAddWidget: () => void
  onRefresh: () => void
}

const DashboardOptions = ({ onAddWidget }: DashboardOptionsProps) => {
  const theme = useTheme()
  return (
    <UncontrolledDropdown style={{ position: "absolute", top: "1.5rem", right: "2rem" }}>
      <LightDropdownToggle size="sm" aria-label="Dashboard options">
        <FontAwesome name="bars" fixedWidth style={{ color: theme.textMutedColor }} />
      </LightDropdownToggle>
      <DropdownMenu end>
        <DropdownItem onClick={onAddWidget}>Add Widget</DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

type RouteParams = {
  id: string
}

const DashboardView = () => {
  const { id: dashboardId } = useParams<RouteParams>()
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const engines = useSelector(getEngines)
  const [dashboards, setDashboards] = React.useState<Dashboard[]>([])
  const [widgets, setWidgets] = React.useState<Widget[]>([])
  const [showAddWidgetdModal, setShowAddWidgetModal] = React.useState(false)
  const [editWidget, setEditWidget] = React.useState<Widget | null>(null)
  const [widgetData, setWidgetData] = useImmer({})
  const isNarrow = useMedia("(max-width: 768px)")

  React.useEffect(() => {
    fetchDashboards(server, serverAuthToken)
      .then(dashboards => {
        if (Array.isArray(dashboards.dashboards)) {
          setDashboards(dashboards.dashboards)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }, [server, serverAuthToken])

  React.useEffect(() => {
    fetchWidgets(server, serverAuthToken, dashboardId)
      .then(widgets => {
        if (Array.isArray(widgets.widgets)) {
          setWidgets(widgets.widgets)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }, [server, serverAuthToken, dashboardId])

  const onRefresh = React.useCallback(() => {
    for (const w of widgets) {
      if (!engines) continue
      const engine = engines.find(engine => engine.id === w.engine)
      if (!engine) continue

      if (w.type === "global-event-counters") {
        getGlobalEventCounters(w, server, serverAuthToken, engine, data => {
          setWidgetData((draft: any) => {
            draft[w.id.toString()] = data
          })
        })
      } else if (w.type === "capture-counters") {
        getCaptureCounters(w, server, serverAuthToken, engine, data => {
          setWidgetData((draft: any) => {
            draft[w.id.toString()] = data
          })
        })
      } else if (w.type === "forensic-chart") {
        getForensicChartData(w, server, serverAuthToken, engine, data => {
          setWidgetData((draft: any) => {
            draft[w.id.toString()] = data
          })
        })
      } else if (w.type === "forensic-top-stats") {
        getForensicTopStatsData(w, server, serverAuthToken, engine, data => {
          setWidgetData((draft: any) => {
            draft[w.id.toString()] = data
          })
        })
      }
    }
  }, [server, serverAuthToken, engines, setWidgetData, widgets])

  React.useEffect(() => {
    onRefresh()
  }, [onRefresh])

  if (!dashboards) return null
  const dashboard = dashboards.find(dashboard => dashboard.id === dashboardId)
  if (dashboard == null) return null

  const onLayoutChange = (currentLayout: Layout[]) => {
    // Skip if we're in narrow mode.
    if (isNarrow) return
    // Copy the layout back to the widgets.
    for (const layout of currentLayout) {
      const w = widgets.find((w: Widget) => w.id === layout.i)
      if (w) {
        if (layout.x !== w.x || layout.y !== w.y || layout.w !== w.w || layout.h !== w.h) {
          w.x = layout.x
          w.y = layout.y
          w.w = layout.w
          w.h = layout.h

          putWidget(server, serverAuthToken, w)
        }
      }
    }
  }

  const onAddWidget = () => {
    setShowAddWidgetModal(true)
  }

  const onAddWidgetOK = (widget: Widget) => {
    setShowAddWidgetModal(false)

    let maxY = 0
    for (const w of widgets) {
      if (w.id) {
        if (w.y > maxY) maxY = w.y
      }
    }

    widget.id = ""
    widget.dashboard = dashboardId
    widget.x = 0
    widget.y = maxY + 1
    widget.w = 24
    widget.h = 8

    postWidget(server, serverAuthToken, widget)
      .then(() => fetchWidgets(server, serverAuthToken, dashboardId))
      .then(widgets => {
        if (Array.isArray(widgets.widgets)) {
          setWidgets(widgets.widgets)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const onAddWidgetCancel = () => {
    setShowAddWidgetModal(false)
  }

  const onEditWidget = (widget: Widget) => {
    setEditWidget(widget)
    setShowAddWidgetModal(true)
  }

  const onEditWidgetOK = (widget: Widget) => {
    setShowAddWidgetModal(false)

    postWidget(server, serverAuthToken, widget)
      .then(() => fetchWidgets(server, serverAuthToken, dashboardId))
      .then(widgets => {
        if (Array.isArray(widgets.widgets)) {
          setWidgets(widgets.widgets)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const onEditWidgetCancel = () => {
    setShowAddWidgetModal(false)
    setEditWidget(null)
  }

  const onRemoveWidget = (widget: Widget) => {
    deleteWidget(server, serverAuthToken, widget)
      .then(() => {
        setWidgets(widgets.filter((w: Widget) => w.id !== widget.id))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const buildLayout = (widgets: Widget[]) => {
    const layouts: Layout[] = []
    for (const w of widgets) {
      if (isNarrow) {
        layouts.push({
          i: w.id.toString(),
          x: 0,
          y: w.y,
          w: 24,
          h: w.h,
          isResizable: false,
        })
      } else {
        layouts.push({
          i: w.id.toString(),
          x: w.x,
          y: w.y,
          w: w.w,
          h: w.h,
        })
      }
    }
    return layouts
  }

  const renderGridItem = (w: Widget) => {
    const data = (widgetData as any)[w.id.toString()]
    let GridItem: React.ElementType
    switch (w.type) {
      case "capture-counters":
        GridItem = CaptureCountersWidget
        break
      case "global-event-counters":
        GridItem = GlobalEventCountersWidget
        break
      case "forensic-chart":
        GridItem = ForensicChartWidget
        break
      case "forensic-top-stats":
        GridItem = ForensicTopStatsWidget
        break
    }
    return (
      <div key={w.id}>
        <GridItem widget={w} data={data} commands={{ onEditWidget, onRemoveWidget }} />
      </div>
    )
  }

  const renderGridItems = (widgets: Widget[]) => {
    const gridItems: React.ReactNode[] = []
    for (const w of widgets) {
      gridItems.push(renderGridItem(w))
    }
    return gridItems
  }

  return (
    <DashboardContainer>
      <Interval timeout={10000} enabled={true} callback={onRefresh} />
      <DashboardHeader>
        <DashboardTitle>{dashboard.name}</DashboardTitle>
        <DashboardOptions onAddWidget={onAddWidget} onRefresh={onRefresh} />
      </DashboardHeader>
      <GridLayout
        autoSize
        className="layout"
        compactType="vertical"
        draggableCancel=".grid-drag-cancel"
        draggableHandle=".grid-drag-handle"
        layout={buildLayout(widgets)}
        cols={24}
        rowHeight={30}
        margin={[8, 8]}
        containerPadding={[8, 8]}
        onLayoutChange={onLayoutChange}
        useCSSTransforms
      >
        {renderGridItems(widgets)}
      </GridLayout>
      {showAddWidgetdModal && engines !== null ? (
        editWidget !== null ? (
          <AddWidgetModal
            server={server}
            authToken={serverAuthToken}
            engines={engines}
            widget={editWidget}
            onOK={onEditWidgetOK}
            onCancel={onEditWidgetCancel}
          />
        ) : (
          <AddWidgetModal
            server={server}
            authToken={serverAuthToken}
            engines={engines}
            widget={null}
            onOK={onAddWidgetOK}
            onCancel={onAddWidgetCancel}
          />
        )
      ) : null}
    </DashboardContainer>
  )
}

export default DashboardView
