import styled from "styled-components"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../Modal"
import { PrimaryButton } from "../Buttons"
import { OutsideLink } from "../Link"

export const LinkBlock = styled.div`
  padding-top: 2rem;
`

export type MessageLink = {
  href: string
  text: string
}

export const MessageModal = ({
  message = "",
  onOK,
  show = false,
  title = "",
  link = null,
  size = "sm",
}: {
  message: string | React.ReactNode
  onOK: () => void
  show: boolean
  title: string
  link?: null | MessageLink
  size?: string
}) => (
  <Modal size={size} isOpen={show}>
    <ModalHeader>{title}</ModalHeader>
    <ModalBody>
      {message}
      {link && (
        <LinkBlock>
          <OutsideLink href={link.href} target="_blank" rel="noopener noreferrer">
            {link.text}
          </OutsideLink>
        </LinkBlock>
      )}
    </ModalBody>
    <ModalFooter>
      <PrimaryButton onClick={onOK}>OK</PrimaryButton>
    </ModalFooter>
  </Modal>
)

export default MessageModal
