import { AdapterInfo } from "../api/types"

export function getAdapterId(adapter: AdapterInfo): string {
  let id = ""
  if (adapter) {
    if ("identifier" in adapter) {
      id = adapter.identifier
    } else if ("enumerator" in adapter) {
      id = adapter.enumerator
    } else if ("filePath" in adapter) {
      id = adapter.filePath
    }
  }
  return id
}

export function isNapatechAdapter(adapter: AdapterInfo): boolean {
  return (
    "identifier" in adapter &&
    adapter.identifier.length >= 2 &&
    adapter.identifier.substr(0, 2) === "nt"
  )
}
