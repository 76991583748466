import * as React from "react"
import styled from "styled-components"
import { AutoSizer, List } from "react-virtualized"
import { HeaderDiv } from "./utils"
import { useReconstructionsContentsContext } from "./reconstructions-contents"

const ReconstructionsHexPaneStyle = styled.div`
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  border: 1px solid ${props => props.theme.tableBorderColor};
`

const Offsets = styled.pre`
  flex-shrink: 0;
  margin: 0;
  overflow: visible;
  font-size: 1rem;
  color: ${props => props.theme.textMutedColor};
  background: ${props => props.theme.tableHeaderBackgroundColor};
  display: inline-block;
  min-width: calc(6ch + 16px);
  vertical-align: text-top;
  margin-right: 5px;
  text-align: right;
  padding-right: 8px;
`

const Data = styled.pre`
  flex-shrink: 0;
  margin: 0;
  display: inline-block;
  overflow: visible;
  font-size: 1rem;
  color: ${props => props.theme.textColor};
  cursor: default;
`

const BYTES_PER_ROW = 16

const zeroPad = (num: string) => {
  const s = "0" + num
  return s.substr(s.length - 2)
}

const printCharFromCharCode = (ch: number) => {
  return ch >= 0x20 && ch <= 0x7e ? String.fromCharCode(ch) : "."
}

export type ReconstructionsHexPaneProps = {
  packetData: Uint8Array | null
}

const ReconstructionsHexPane = ({ packetData }: ReconstructionsHexPaneProps) => {
  const { fileSize, slicedMessage, ellipse } = useReconstructionsContentsContext()

  const [offsets, setOffsets] = React.useState<string[]>([])
  const [dataRows, setDataRows] = React.useState<React.ReactNode[]>([])

  React.useEffect(() => {
    const dummyOffsets: string[] = []
    const dummyRows: React.ReactNode[] = []

    if (packetData != null && BYTES_PER_ROW != null && packetData.length !== 0) {
      const len = packetData.length

      for (let i = 0; i < len; i += BYTES_PER_ROW) {
        dummyOffsets.push(i.toString(10))
      }

      for (let i = 0; i < len; i += BYTES_PER_ROW) {
        let hex = ""
        let ascii = ""
        for (let j = i; j < i + BYTES_PER_ROW; j++) {
          const byte = packetData[j]
          if (j < len) {
            hex += zeroPad(byte.toString(16).toUpperCase())
            hex += " "
            ascii += printCharFromCharCode(byte)
          } else {
            hex += "   "
            ascii += " "
          }
        }
        dummyRows.push(<>{hex + " " + ascii}</>)
      }
      setOffsets(dummyOffsets)
      setDataRows(dummyRows)
    }
  }, [packetData])

  return (
    <>
      <HeaderDiv>
        {fileSize} {slicedMessage}
        {ellipse}
      </HeaderDiv>
      <ReconstructionsHexPaneStyle>
        <AutoSizer>
          {({ width, height }) => {
            return (
              <List
                width={width}
                height={height}
                rowHeight={18}
                rowCount={dataRows.length}
                overscanRowCount={100}
                rowRenderer={({ key, index, style }) => {
                  return (
                    <div key={key} style={style}>
                      <Offsets>{offsets[index]}</Offsets>
                      <Data>{dataRows[index]}</Data>
                    </div>
                  )
                }}
              />
            )
          }}
        </AutoSizer>
      </ReconstructionsHexPaneStyle>
    </>
  )
}

ReconstructionsHexPane.defaultProps = {
  packetData: null,
  bytesPerRow: 8,
}

export default ReconstructionsHexPane
