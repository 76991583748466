import * as React from "react"
import { StaticContext } from "react-router"
import { Prompt, RouteComponentProps } from "react-router-dom"
import { produce } from "immer"
import { cloneDeep, isEqual, toNumber } from "lodash"
import cn from "classnames"
import styled from "styled-components"
import { Col, FormGroup } from "reactstrap"
import { v4 as uuid } from "uuid"
import { connect } from "react-redux"
import FontAwesome from "react-fontawesome"
import { Helmet } from "react-helmet"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { Input } from "../common/Input"
import { CheckGroup, FormTitle, FormView, HorizontalFormGroup, Label } from "../common/Form"
import { IconSevere } from "../common/Icons"
import { PrimaryButton, LightButton } from "../common/Buttons"
import { Select } from "../common/Select"
import {
  View,
  ViewContentScrollable,
  ViewContentContainer,
  ViewFooter,
  ViewAlert,
  ViewAlertContent,
} from "../common/View"
import { getEngineAlarmsUrl } from "../../routes"
import { fetchAlarms, setAlarm } from "../../api/api"
import { AlarmInfo, AlarmSettings, StatisticsTracker } from "../../api/types"
import {
  AlarmComparisonType,
  AlarmConditionType,
  AlarmTrackType,
  PeekApplicationStatType,
  PeekCountryStatType,
  PeekNodeStatType,
  PeekProtocolStatType,
  PeekSeverity,
  PeekSummaryStatType,
} from "../../api/types/peekTypes"
import { getEngine, getAuthToken, getAlarmsModificationTime } from "../../store"

const SubSection = styled.div`
  margin-top: 1rem;
  padding-left: 2.5rem;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

export const defaultAlarm: AlarmInfo = {
  clsid: "E1B48467-CC9F-4C91-A030-6CEAE1FD934D",
  conditions: [
    {
      comparisonType: AlarmComparisonType.ALARM_COMPARISON_TYPE_GREATER_THAN,
      conditionType: AlarmConditionType.ALARM_CONDITION_TYPE_SUSPECT,
      duration: 5,
      enabled: true,
      severity: PeekSeverity.PEEK_SEVERITY_MINOR,
      value: 1000,
    },
    {
      comparisonType: AlarmComparisonType.ALARM_COMPARISON_TYPE_GREATER_THAN,
      conditionType: AlarmConditionType.ALARM_CONDITION_TYPE_PROBLEM,
      duration: 5,
      enabled: true,
      severity: PeekSeverity.PEEK_SEVERITY_MAJOR,
      value: 2000,
    },
    {
      comparisonType: AlarmComparisonType.ALARM_COMPARISON_TYPE_LESS_THAN,
      conditionType: AlarmConditionType.ALARM_CONDITION_TYPE_RESOLVE,
      duration: 5,
      enabled: true,
      severity: PeekSeverity.PEEK_SEVERITY_INFORMATIONAL,
      value: 1000,
    },
  ],
  created: "",
  id: "",
  modified: "",
  name: "Untitled Alarm",
  trackType: AlarmTrackType.ALARM_TRACK_TYPE_DIFFERENCE_PER_SEC,
}

export function getUnits(clsid: string, statisticsType: PeekSummaryStatType) {
  let count = 0
  if (clsid === "055E270A-CED1-4A68-A98E-B9A4CCE93FA3") {
    // application
    count = 2
  } else if (clsid === "5FF04EFD-9A1D-436B-BD5D-3E206296C90A") {
    // country
    count = 6
  } else if (clsid === "0EC1390E-3A7D-45B3-9803-84D74B5D5B80") {
    // node
    count = 16
  } else if (clsid === "CDC6DD80-C8AC-4968-8A1C-CC30932E2E84") {
    // protocol
    count = 2
  } else if (clsid === "705C4632-EFC9-4395-A66A-71A7F5618CC6") {
    // summary
    switch (statisticsType) {
      default:
      case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_NULL:
      case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DATE:
      case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_TIME:
      case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DURATION:
        count = 0
        break
      case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_PACKETS:
      case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_BYTES:
      case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_INT:
      case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DOUBLE:
        count = 1
        break
      case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_VALUE_PAIR:
        count = 2
        break
    }
  }
  return count
}

type AlarmTrackTypeLabel = {
  text: string
  type: number
}

type StatisticsTypeLabel = {
  id: number
  text: string
  type: number
}

type AlarmConditionProps = {
  conditionName: string
  id: string
  compairsonTypeEditable: boolean
  condition: AlarmSettings
  onChangeCondition: (newCondition: AlarmSettings) => void
}

class AlarmCondition extends React.Component<AlarmConditionProps> {
  onChangeCheckBox = () => {
    this.props.onChangeCondition({
      ...this.props.condition,
      enabled: !this.props.condition.enabled,
    })
  }

  onChangeSeverity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.props.onChangeCondition({
      ...this.props.condition,
      severity: toNumber(value),
    })
  }

  onChangeComparison = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.props.onChangeCondition({
      ...this.props.condition,
      comparisonType: toNumber(value),
    })
  }

  onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.props.onChangeCondition({
      ...this.props.condition,
      value: toNumber(value),
    })
  }

  onChangeDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.props.onChangeCondition({
      ...this.props.condition,
      duration: toNumber(value),
    })
  }

  render() {
    const severityToString = (severity: PeekSeverity) => {
      switch (severity) {
        default:
        case PeekSeverity.PEEK_SEVERITY_INFORMATIONAL:
          return "Informational"
        case PeekSeverity.PEEK_SEVERITY_MINOR:
          return "Minor"
        case PeekSeverity.PEEK_SEVERITY_MAJOR:
          return "Major"
        case PeekSeverity.PEEK_SEVERITY_SEVERE:
          return "Severe"
      }
    }

    const comparisonTypeToString = (comparisonType: AlarmComparisonType) => {
      switch (comparisonType) {
        case AlarmComparisonType.ALARM_COMPARISON_TYPE_LESS_THAN:
          return "does not exceed"
        case AlarmComparisonType.ALARM_COMPARISON_TYPE_LESS_THAN_EQUAL:
          return "does not exceed or is"
        case AlarmComparisonType.ALARM_COMPARISON_TYPE_GREATER_THAN:
          return "exceeds"
        case AlarmComparisonType.ALARM_COMPARISON_TYPE_GREATER_THAN_EQUAL:
          return "exceeds or is"
        case AlarmComparisonType.ALARM_COMPARISON_TYPE_EQUAL:
          return "is"
        case AlarmComparisonType.ALARM_COMPARISON_TYPE_NOT_EQUAL:
          return "is not"
        default:
          return null
      }
    }

    const severityLabels = [
      PeekSeverity.PEEK_SEVERITY_INFORMATIONAL,
      PeekSeverity.PEEK_SEVERITY_MINOR,
      PeekSeverity.PEEK_SEVERITY_MAJOR,
      PeekSeverity.PEEK_SEVERITY_SEVERE,
    ].map((severity: PeekSeverity) => (
      <option key={severity} value={severity}>
        {severityToString(severity)}
      </option>
    ))

    const comparisonTypeLabels = [
      AlarmComparisonType.ALARM_COMPARISON_TYPE_GREATER_THAN,
      AlarmComparisonType.ALARM_COMPARISON_TYPE_LESS_THAN,
    ].map((comparisonType: AlarmComparisonType) => (
      <option key={comparisonType} value={comparisonType}>
        {comparisonTypeToString(comparisonType)}
      </option>
    ))

    return (
      <FormGroup row>
        <Col md={{ size: 11, offset: 1 }}>
          <CheckGroup
            type="checkbox"
            id={this.props.id}
            checked={this.props.condition.enabled}
            onChange={this.onChangeCheckBox}
          >
            {this.props.conditionName}
          </CheckGroup>
          <SubSection className={cn({ disabled: !this.props.condition.enabled })}>
            <HorizontalFormGroup>
              <span>Severity:</span>
              <Select
                id={`${this.props.id}Severity`}
                value={this.props.condition.severity}
                onChange={this.onChangeSeverity}
              >
                {severityLabels}
              </Select>
            </HorizontalFormGroup>
            <HorizontalFormGroup>
              <span>
                Notify when value
                {this.props.compairsonTypeEditable
                  ? ""
                  : ` ${comparisonTypeToString(this.props.condition.comparisonType)}`}
              </span>
              {this.props.compairsonTypeEditable && (
                <Select
                  id={`${this.props.id}ComparisonType`}
                  value={this.props.condition.comparisonType}
                  onChange={this.onChangeComparison}
                >
                  {comparisonTypeLabels}
                </Select>
              )}
              <Input
                type="number"
                id={`${this.props.id}Value`}
                aria-label={`${this.props.conditionName} value`}
                min={0}
                onChange={this.onChangeValue}
                value={this.props.condition.value}
                style={{ width: "10rem" }}
              />
            </HorizontalFormGroup>
            <HorizontalFormGroup>
              <span>for a sustained period of</span>
              <Input
                type="number"
                id={`${this.props.id}Duration`}
                aria-label={`${this.props.conditionName} duration`}
                min={0}
                onChange={this.onChangeDuration}
                value={this.props.condition.duration}
                style={{ width: "10rem" }}
              />
              <span>seconds</span>
            </HorizontalFormGroup>
          </SubSection>
        </Col>
      </FormGroup>
    )
  }
}

type RouteInfo = {
  alarmId: string
}

type LocationState = {
  alarm?: AlarmInfo
}

type AlarmsEditViewProps = RouteComponentProps<RouteInfo, StaticContext, LocationState> & {
  engine: string
  authToken: string
  alarmsModificationTime?: string
}

type AlarmsEditViewState = {
  alarms: AlarmInfo[] | null
  alarm: AlarmInfo | null
  past: AlarmInfo[]
  future: AlarmInfo[]
  error: any | null
  showPrompt: boolean
}

class AlarmsEditView extends React.Component<AlarmsEditViewProps, AlarmsEditViewState> {
  state: AlarmsEditViewState = {
    alarms: null,
    alarm: null,
    past: [],
    future: [],
    error: null,
    showPrompt: true,
  }

  componentDidMount() {
    this.onRefresh()
  }

  componentDidUpdate({ alarmsModificationTime }: AlarmsEditViewProps) {
    if (this.props.alarmsModificationTime !== alarmsModificationTime) {
      this.onRefresh()
    }
  }

  onRefresh = () => {
    const { engine, authToken } = this.props
    const { alarmId } = this.props.match.params
    fetchAlarms(engine, authToken)
      .then(alarms => {
        if (alarms && Array.isArray(alarms.alarms)) {
          let alarm: AlarmInfo | undefined
          if (alarmId) {
            alarm = alarms.alarms.find(a => a.id === alarmId)
          } else {
            if (this.props.location.state && this.props.location.state.alarm !== undefined) {
              alarm = cloneDeep(this.props.location.state.alarm)
            } else {
              alarm = cloneDeep(defaultAlarm)
            }
            if (alarm) {
              alarm.id = uuid().toUpperCase()
            }
          }
          if (alarm) {
            let error = null
            if (this.state.alarm) {
              if (!isEqual(alarm, this.state.alarm)) {
                error = "The alarm has changed on the server"
              }
              alarm = this.state.alarm
            } else {
              alarm = cloneDeep(alarm)
            }
            this.setState({ alarms: alarms.alarms, alarm, error })
          }
        }
      })
      .catch(error => {
        this.setState({ error })
      })
  }

  onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      produce(draft => {
        if (draft.alarm) {
          draft.past.push(cloneDeep(draft.alarm))
          draft.alarm.name = value
        }
      })
    )
  }

  onChangeCondition = (newCondition: AlarmSettings) => {
    const { alarm } = this.state
    if (alarm) {
      this.setState(
        produce(draft => {
          draft.past.push(cloneDeep(draft.alarm))
          draft.alarm.conditions = draft.alarm.conditions.map((condition: AlarmSettings) => {
            if (condition.conditionType === newCondition.conditionType) {
              return cloneDeep(newCondition)
            } else if (
              newCondition.conditionType === AlarmConditionType.ALARM_CONDITION_TYPE_SUSPECT ||
              newCondition.conditionType === AlarmConditionType.ALARM_CONDITION_TYPE_PROBLEM
            ) {
              if (
                condition.conditionType === AlarmConditionType.ALARM_CONDITION_TYPE_SUSPECT ||
                condition.conditionType === AlarmConditionType.ALARM_CONDITION_TYPE_PROBLEM
              ) {
                condition.comparisonType = newCondition.comparisonType
              } else {
                if (
                  newCondition.comparisonType ===
                  AlarmComparisonType.ALARM_COMPARISON_TYPE_GREATER_THAN
                ) {
                  condition.comparisonType = AlarmComparisonType.ALARM_COMPARISON_TYPE_LESS_THAN
                } else {
                  condition.comparisonType = AlarmComparisonType.ALARM_COMPARISON_TYPE_GREATER_THAN
                }
              }
              return condition
            } else {
              return condition
            }
          })
        })
      )
    }
  }

  onOK = () => {
    this.onSubmit()
  }

  onCancel = () => {
    this.setState({ showPrompt: false }, () => {
      this.props.history.goBack()
    })
  }

  onSubmit = () => {
    if (this.state.alarm) {
      const alarm = cloneDeep(this.state.alarm)
      const currentDateTime = new Date().toISOString()
      if (alarm.created === "") {
        alarm.created = currentDateTime
      }

      const { engine, authToken } = this.props
      setAlarm(engine, authToken, alarm)
        .then(() => {
          this.setState({ showPrompt: false }, () => {
            this.props.history.goBack()
          })
        })
        .catch(error => {
          this.setState({ error })
        })
    }
  }

  onUndo = () => {
    // 1. Remove the last element from past
    // 2. Set the present to the element we removed from the previous step
    // 3. Insert the old present state at the beginning of the future
    const { alarm, past, future } = this.state
    if (alarm && past.length > 0) {
      const previous = past[past.length - 1]
      const newPast = past.slice(0, past.length - 1)
      this.setState({
        alarm: previous,
        past: newPast,
        future: [cloneDeep(alarm), ...future],
      })
    }
  }

  onRedo = () => {
    // 1. Remove the first element from the future
    // 2. Set the present to the element we removed from the previous step
    // 3. Insert the old present state at the end of the past
    const { alarm, past, future } = this.state
    if (alarm && future.length > 0) {
      const next = future[0]
      const newFuture = future.slice(1)
      this.setState({
        alarm: next,
        past: [...past, cloneDeep(alarm)],
        future: newFuture,
      })
    }
  }

  onCloseAlert = () => {
    this.setState({ error: null })
  }

  getUnitsStatsTypeLabels = (statisticsTracker: StatisticsTracker) => {
    const count = getUnits(statisticsTracker.clsid, statisticsTracker.statisticsType)
    const labels: StatisticsTypeLabel[] = []
    for (let x = 0; x < count; ++x) {
      if (statisticsTracker.clsid === "055E270A-CED1-4A68-A98E-B9A4CCE93FA3") {
        // application
        switch (x) {
          case 0:
            labels.push({
              id: x,
              text: "Total Packets",
              type: PeekApplicationStatType.PEEK_APPLICATION_STAT_TYPE_PACKETS,
            })
            break
          case 1:
            labels.push({
              id: x,
              text: "Total Bytes",
              type: PeekApplicationStatType.PEEK_APPLICATION_STAT_TYPE_BYTES,
            })
            break
        }
      } else if (statisticsTracker.clsid === "5FF04EFD-9A1D-436B-BD5D-3E206296C90A") {
        // country
        switch (x) {
          case 0:
            labels.push({
              id: x,
              text: "Total Packets",
              type: PeekCountryStatType.PEEK_COUNTRY_STAT_TYPE_TOTAL_PACKETS,
            })
            break
          case 1:
            labels.push({
              id: x,
              text: "Total Bytes",
              type: PeekCountryStatType.PEEK_COUNTRY_STAT_TYPE_TOTAL_BYTES,
            })
            break
          case 2:
            labels.push({
              id: x,
              text: "Packets From",
              type: PeekCountryStatType.PEEK_COUNTRY_STAT_TYPE_PACKETS_FROM,
            })
            break
          case 3:
            labels.push({
              id: x,
              text: "Bytes From",
              type: PeekCountryStatType.PEEK_COUNTRY_STAT_TYPE_BYTES_FROM,
            })
            break
          case 4:
            labels.push({
              id: x,
              text: "Packets To",
              type: PeekCountryStatType.PEEK_COUNTRY_STAT_TYPE_PACKETS_TO,
            })
            break
          case 5:
            labels.push({
              id: x,
              text: "Bytes To",
              type: PeekCountryStatType.PEEK_COUNTRY_STAT_TYPE_BYTES_TO,
            })
            break
        }
      } else if (statisticsTracker.clsid === "0EC1390E-3A7D-45B3-9803-84D74B5D5B80") {
        // node
        switch (x) {
          case 0:
            labels.push({
              id: x,
              text: "Total Bytes",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_TOTAL_BYTES,
            })
            break
          case 1:
            labels.push({
              id: x,
              text: "Total Packets",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_TOTAL_PACKETS,
            })
            break
          case 2:
            labels.push({
              id: x,
              text: "Packets Sent",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_PACKETS_SENT,
            })
            break
          case 3:
            labels.push({
              id: x,
              text: "Bytes Sent",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_BYTES_SENT,
            })
            break
          case 4:
            labels.push({
              id: x,
              text: "Packets Received",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_PACKETS_RECEIVED,
            })
            break
          case 5:
            labels.push({
              id: x,
              text: "Bytes Received",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_BYTES_RECEIVED,
            })
            break
          case 6:
            labels.push({
              id: x,
              text: "Broadcast Packets",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_BROADCAST_PACKETS,
            })
            break
          case 7:
            labels.push({
              id: x,
              text: "Broadcast Bytes",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_BROADCAST_BYTES,
            })
            break
          case 8:
            labels.push({
              id: x,
              text: "Multicast Packets",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_MULTICAST_PACKETS,
            })
            break
          case 9:
            labels.push({
              id: x,
              text: "Multicast Bytes",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_MULTICAST_BYTES,
            })
            break
          case 10:
            labels.push({
              id: x,
              text: "Broadcast/Multicast Packets",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_BROADCAST_MULTICAST_PACKETS,
            })
            break
          case 11:
            labels.push({
              id: x,
              text: "Broadcast/Multicast Bytes",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_BROADCAST_MULTICAST_BYTES,
            })
            break
          case 12:
            labels.push({
              id: x,
              text: "Min Packet Size Sent",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_MIN_PACKET_SIZE_SENT,
            })
            break
          case 13:
            labels.push({
              id: x,
              text: "Max Packet Size Sent",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_MAX_PACKET_SIZE_SENT,
            })
            break
          case 14:
            labels.push({
              id: x,
              text: "Min Packet Size Received",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_MIN_PACKET_SIZE_RECEIVED,
            })
            break
          case 15:
            labels.push({
              id: x,
              text: "Max Packet Size Received",
              type: PeekNodeStatType.PEEK_NODE_STAT_TYPE_MAX_PACKET_SIZE_RECEIVED,
            })
            break
        }
      } else if (statisticsTracker.clsid === "CDC6DD80-C8AC-4968-8A1C-CC30932E2E84") {
        // protocol
        switch (x) {
          case 0:
            labels.push({
              id: x,
              text: "Total Packets",
              type: PeekProtocolStatType.PEEK_PROTOCOL_STAT_TYPE_PACKETS,
            })
            break
          case 1:
            labels.push({
              id: x,
              text: "Total Bytes",
              type: PeekProtocolStatType.PEEK_PROTOCOL_STAT_TYPE_BYTES,
            })
            break
        }
      } else if (statisticsTracker.clsid === "705C4632-EFC9-4395-A66A-71A7F5618CC6") {
        // summary
        switch (statisticsTracker.statisticsType) {
          default:
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_NULL:
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DATE:
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_TIME:
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DURATION:
            break
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_PACKETS:
            labels.push({
              id: statisticsTracker.statisticsType,
              text: "Packets",
              type: statisticsTracker.statisticsType,
            })
            break
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_BYTES:
            labels.push({
              id: statisticsTracker.statisticsType,
              text: "Bytes",
              type: statisticsTracker.statisticsType,
            })
            break
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_INT:
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DOUBLE:
            labels.push({
              id: statisticsTracker.statisticsType,
              text: "Count",
              type: statisticsTracker.statisticsType,
            })
            break
          case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_VALUE_PAIR:
            if (x === 0) {
              labels.push({
                id: PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_PACKETS,
                text: "Packets",
                type: PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_VALUE_PAIR,
              })
            } else {
              labels.push({
                id: PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_BYTES,
                text: "Bytes",
                type: PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_VALUE_PAIR,
              })
            }
            break
        }
      }
    }
    return labels
  }

  onChangeUnitsStatsType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      produce(draft => {
        if (draft.alarm && draft.alarm.statisticsTracker) {
          draft.past.push(cloneDeep(draft.alarm))
          if (draft.alarm.statisticsTracker.clsid === "705C4632-EFC9-4395-A66A-71A7F5618CC6") {
            const valueArr = value.split(",")
            if (valueArr.length === 2) {
              draft.alarm.statisticsTracker.statisticsType = toNumber(valueArr[1])
              draft.alarm.statisticsTracker.summary.summaryStatisticsType = toNumber(valueArr[0])
            }
          } else {
            draft.alarm.statisticsTracker.statisticsType = toNumber(value)
          }
        }
      })
    )
  }

  onChangeAlarmTrackType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      produce(draft => {
        if (draft.alarm) {
          draft.past.push(cloneDeep(draft.alarm))
          draft.alarm.trackType = toNumber(value)
        }
      })
    )
  }

  render() {
    const { alarm, past, future, error, showPrompt } = this.state
    if (!alarm) return null

    const renderUnitsStatsTypeDropDown = (statisticsTracker: StatisticsTracker) => {
      const labels: StatisticsTypeLabel[] = this.getUnitsStatsTypeLabels(statisticsTracker)
      const dropDownLabels = labels.map((label: StatisticsTypeLabel) => {
        const value =
          statisticsTracker.clsid === "705C4632-EFC9-4395-A66A-71A7F5618CC6"
            ? label.id + "," + label.type
            : label.type
        return (
          <option key={value} value={value}>
            {label.text}
          </option>
        )
      })
      const selectedLabel = labels.find((label: StatisticsTypeLabel) =>
        statisticsTracker.clsid !== "705C4632-EFC9-4395-A66A-71A7F5618CC6"
          ? label.type === statisticsTracker.statisticsType
          : label.id === statisticsTracker.summary.summaryStatisticsType
      )
      if (dropDownLabels.length > 0 && selectedLabel) {
        const selectedLabelValue =
          statisticsTracker.clsid === "705C4632-EFC9-4395-A66A-71A7F5618CC6"
            ? selectedLabel.id + "," + selectedLabel.type
            : selectedLabel.type
        return (
          <Select
            id="unitsStatsType"
            value={selectedLabelValue}
            onChange={this.onChangeUnitsStatsType}
          >
            {dropDownLabels}
          </Select>
        )
      } else {
        return null
      }
    }

    const renderUnitsAlarmTrackTypeDropDown = (alarmType: AlarmTrackType) => {
      const labels: AlarmTrackTypeLabel[] = [
        { text: "Total", type: AlarmTrackType.ALARM_TRACK_TYPE_TOTAL },
        { text: "Per second", type: AlarmTrackType.ALARM_TRACK_TYPE_DIFFERENCE_PER_SEC },
      ]
      const labelItems = labels.map((label: AlarmTrackTypeLabel) => (
        <option key={label.type} value={label.type}>
          {label.text}
        </option>
      ))
      const selectedLabel = labels.find((label: AlarmTrackTypeLabel) => label.type === alarmType)
      if (selectedLabel) {
        return (
          <Select
            id="unitsAlarmTrackType"
            value={selectedLabel.type}
            onChange={this.onChangeAlarmTrackType}
          >
            {labelItems}
          </Select>
        )
      } else {
        return null
      }
    }

    const suspectConditionIndex = alarm.conditions.findIndex(
      condition => condition.conditionType === AlarmConditionType.ALARM_CONDITION_TYPE_SUSPECT
    )
    const problemConditionIndex = alarm.conditions.findIndex(
      condition => condition.conditionType === AlarmConditionType.ALARM_CONDITION_TYPE_PROBLEM
    )
    const resolveConditionIndex = alarm.conditions.findIndex(
      condition => condition.conditionType === AlarmConditionType.ALARM_CONDITION_TYPE_RESOLVE
    )

    return (
      <View padding={false}>
        <Prompt
          when={showPrompt && past.length > 0}
          message="Warning! Changes have not been saved. Discard changes?"
        />
        <Helmet title={alarm.name} />
        <BreadcrumbItem to={getEngineAlarmsUrl()} title="Alarms" />
        <BreadcrumbItem to={this.props.match.url} title={alarm.name} />
        {error && (
          <ViewAlert isOpen={true} color="danger" toggle={this.onCloseAlert}>
            <ViewAlertContent>
              <IconSevere />
              {typeof error === "string" ? error : `${error.code} ${error.reason}`}
            </ViewAlertContent>
          </ViewAlert>
        )}
        <ViewContentScrollable>
          <ViewContentContainer maxWidth="768px">
            <FormTitle>Edit Alarm</FormTitle>
            <FormView noValidate>
              <FormGroup row>
                <Label md="1" for="name">
                  Name
                </Label>
                <Col md="11">
                  <Input
                    type="text"
                    id="name"
                    onChange={this.onChangeName}
                    value={alarm.name}
                    required
                    invalid={alarm.name === undefined || alarm.name.length === 0}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="1" for="units">
                  Units
                </Label>
                <Col md="11">
                  <HorizontalFormGroup>
                    {alarm.statisticsTracker &&
                      renderUnitsStatsTypeDropDown(alarm.statisticsTracker)}
                    {renderUnitsAlarmTrackTypeDropDown(alarm.trackType)}
                  </HorizontalFormGroup>
                </Col>
              </FormGroup>
              {suspectConditionIndex !== -1 && (
                <AlarmCondition
                  conditionName="Suspect condition"
                  id="suspectCondition"
                  compairsonTypeEditable={true}
                  condition={alarm.conditions[suspectConditionIndex]}
                  onChangeCondition={this.onChangeCondition}
                />
              )}
              {problemConditionIndex !== -1 && (
                <AlarmCondition
                  conditionName="Problem condition"
                  id="problemCondition"
                  compairsonTypeEditable={true}
                  condition={alarm.conditions[problemConditionIndex]}
                  onChangeCondition={this.onChangeCondition}
                />
              )}
              {resolveConditionIndex !== -1 && (
                <AlarmCondition
                  conditionName="Resolve condition"
                  id="resolveCondition"
                  compairsonTypeEditable={false}
                  condition={alarm.conditions[resolveConditionIndex]}
                  onChangeCondition={this.onChangeCondition}
                />
              )}
            </FormView>
          </ViewContentContainer>
        </ViewContentScrollable>
        <ViewFooter>
          <LightButton id="undo" disabled={past.length === 0} onClick={this.onUndo}>
            <FontAwesome name="undo" /> Undo
          </LightButton>
          <LightButton id="redo" disabled={future.length === 0} onClick={this.onRedo}>
            <FontAwesome name="repeat" /> Redo
          </LightButton>
          <LightButton id="cancel" style={{ marginLeft: "auto" }} onClick={this.onCancel}>
            Cancel
          </LightButton>
          <PrimaryButton
            id="ok"
            disabled={
              !alarm ||
              alarm.name.length === 0 ||
              alarm.conditions.filter(condition => condition.duration > 0 && condition.value > 0)
                .length !== alarm.conditions.length
            }
            onClick={this.onOK}
          >
            OK
          </PrimaryButton>
        </ViewFooter>
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  alarmsModificationTime: getAlarmsModificationTime(state),
})

export default connect(mapStateToProps)(AlarmsEditView)
