import ipaddr from "ipaddr.js"
import { MediaSpec } from "../api/types"
import { MediaSpecClass, MediaSpecType } from "../api/types/mediaTypes"
import {
  hexCharCodeToValue,
  hexStringToByte,
  hexDecode,
  hexEncodeByteArray,
  hexDecodeByteArray,
} from "./encodeHex"

export const emptyMediaSpec: MediaSpec = {
  msclass: MediaSpecClass.MEDIA_SPEC_CLASS_NULL,
  type: MediaSpecType.MEDIA_SPEC_TYPE_NULL,
  mask: 0,
  data: "",
}

const mediaSpecDataLengths: number[] = [
  0, // 0  MediaSpecType.MEDIA_SPEC_TYPE_NULL
  2, // 1  MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_PROTOCOL
  1, // 2  MediaSpecType.MEDIA_SPEC_TYPE_LSAP
  5, // 3  MediaSpecType.MEDIA_SPEC_TYPE_SNAP
  1, // 4  MediaSpecType.MEDIA_SPEC_TYPE_LAP
  1, // 5  MediaSpecType.MEDIA_SPEC_TYPE_DDP
  1, // 6  MediaSpecType.MEDIA_SPEC_TYPE_MAC_CONTROL
  64, // 7  MediaSpecType.MEDIA_SPEC_TYPE_PROTOSPEC_HIERARCHY
  8, // 8  MediaSpecType.MEDIA_SPEC_TYPE_APPLICATION_ID
  4, // 9  MediaSpecType.MEDIA_SPEC_TYPE_PROTOSPEC
  6, // 10 MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS
  6, // 11 MediaSpecType.MEDIA_SPEC_TYPE_TOKEN_RING_ADDRESS
  6, // 12 MediaSpecType.MEDIA_SPEC_TYPE_LAP_ADDRESS
  6, // 13 MediaSpecType.MEDIA_SPEC_TYPE_WIRELESS_ADDRESS
  4, // 14 MediaSpecType.MEDIA_SPEC_TYPE_APP_ID
  0, // 15 Unused
  0, // 16 Unused
  0, // 17 Unused
  0, // 18 Unused
  0, // 19 Unused
  3, // 20 MediaSpecType.MEDIA_SPEC_TYPE_APPLETALK_ADDRESS
  4, // 21 MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
  2, // 22 MediaSpecType.MEDIA_SPEC_TYPE_DECNET_ADDRESS
  0, // 23 MediaSpecType.MEDIA_SPEC_TYPE_OTHER_ADDRESS
  16, // 24 MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS
  10, // 25 MediaSpecType.MEDIA_SPEC_TYPE_IPX_ADDRESS
  0, // 26 Unused
  0, // 27 Unused
  0, // 28 Unused
  0, // 29 Unused
  0, // 30 Unused
  0, // 31 Unused
  1, // 32 MediaSpecType.MEDIA_SPEC_TYPE_ERROR
  1, // 33 MediaSpecType.MEDIA_SPEC_TYPE_AT_PORT
  2, // 34 MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT
  2, // 35 MediaSpecType.MEDIA_SPEC_TYPE_NW_PORT
  4, // 36 MediaSpecType.MEDIA_SPEC_TYPE_TCP_PORT_PAIR
  2, // 37 MediaSpecType.MEDIA_SPEC_TYPE_WAN_PPP_PROTOCOL
  2, // 38 MediaSpecType.MEDIA_SPEC_TYPE_WAN_FRAME_RELAY_PROTOCOL
  0, // 39 MediaSpecType.MEDIA_SPEC_TYPE_WAN_X25_PROTOCOL
  0, // 40 MediaSpecType.MEDIA_SPEC_TYPE_WAN_X25E_PROTOCOL
  0, // 41 MediaSpecType.MEDIA_SPEC_TYPE_WAN_IPARS_PROTOCOL
  0, // 42 MediaSpecType.MEDIA_SPEC_TYPE_WAN_U200_PROTOCOL
  2, // 43 MediaSpecType.MEDIA_SPEC_TYPE_WAN_DLCI_ADDRESS
  0, // 44 MediaSpecType.MEDIA_SPEC_TYPE_WAN_Q931_PROTOCOL
]

export function getDataLength(mst: MediaSpecType) {
  return mst < mediaSpecDataLengths.length ? mediaSpecDataLengths[mst] : 0
}

export function getTypeMask(mst: MediaSpecType) {
  switch (mst) {
    case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS:
      return 0xfc000000
    case MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS:
      return 0xffffffff
    case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_PROTOCOL:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_PPP_PROTOCOL:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_FRAME_RELAY_PROTOCOL:
      return 0xc0000000
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_X25_PROTOCOL:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_X25E_PROTOCOL:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_U200_PROTOCOL:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_IPARS_PROTOCOL:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_Q931_PROTOCOL:
      return 0x00000000 // no!
    case MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT:
      return 0xc0000000
    case MediaSpecType.MEDIA_SPEC_TYPE_APP_ID:
      return 0xc0000000
    case MediaSpecType.MEDIA_SPEC_TYPE_APPLICATION_ID:
      return 0xff000000
    case MediaSpecType.MEDIA_SPEC_TYPE_PROTOSPEC:
      return 0xc0000000
    case MediaSpecType.MEDIA_SPEC_TYPE_TCP_PORT_PAIR:
      return 0xf0000000
    case MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS:
      return 0xffff0000
    case MediaSpecType.MEDIA_SPEC_TYPE_WIRELESS_ADDRESS:
      return 0xfc000000
    case MediaSpecType.MEDIA_SPEC_TYPE_LSAP:
      return 0x80000000
    case MediaSpecType.MEDIA_SPEC_TYPE_SNAP:
      return 0xf8000000
    case MediaSpecType.MEDIA_SPEC_TYPE_IPX_ADDRESS:
      return 0xffc00000
    case MediaSpecType.MEDIA_SPEC_TYPE_NW_PORT:
      return 0xc0000000
    case MediaSpecType.MEDIA_SPEC_TYPE_APPLETALK_ADDRESS:
      return 0xe0000000
    case MediaSpecType.MEDIA_SPEC_TYPE_AT_PORT:
      return 0x80000000
    case MediaSpecType.MEDIA_SPEC_TYPE_DECNET_ADDRESS:
      return 0xc0000000
    case MediaSpecType.MEDIA_SPEC_TYPE_TOKEN_RING_ADDRESS:
      return 0xfc000000
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_DLCI_ADDRESS:
      // it's only 10 bits but the resolution is bytes
      return 0xc0000000
    default:
      break
  }
  let typeMask = 0
  const dataLen = getDataLength(mst)
  for (let i = 0; i < dataLen; ++i) {
    typeMask >>= 1
    typeMask |= 0x80000000
  }
  return typeMask
}

export function mediaSpecFromProtoSpec(pspec: number): MediaSpec {
  const data: number[] = []
  data.push(pspec & 0xff)
  data.push((pspec >> 8) & 0xff)
  data.push((pspec >> 16) & 0xff)
  data.push((pspec >> 24) & 0xff)
  return {
    msclass: MediaSpecClass.MEDIA_SPEC_CLASS_PROTOCOL,
    type: MediaSpecType.MEDIA_SPEC_TYPE_PROTOSPEC,
    data: hexEncodeByteArray(data),
    mask: getTypeMask(MediaSpecType.MEDIA_SPEC_TYPE_PROTOSPEC),
  }
}

export function mediaSpecToProtoSpecID(mediaSpec: MediaSpec): number {
  if (mediaSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_PROTOSPEC) {
    return swap32(parseInt(mediaSpec.data, 16)) & 0xffff
  }
  return 0
}

export function mediaSpecToAppID(mediaSpec: MediaSpec): number {
  if (mediaSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_APP_ID) {
    const data = mediaSpec.data.padStart(8, "0")
    if (data.length === 8) {
      return parseInt(
        `${data.substring(6)}${data.substring(4, 6)}${data.substring(2, 4)}${data.substring(0, 2)}`,
        16
      )
    }
  }
  return 0
}

export function swap16(val: number) {
  return ((val & 0xff) << 8) | ((val >> 8) & 0xff)
}

export function swap32(val: number) {
  return ((val & 0xff) << 24) | ((val & 0xff00) << 8) | ((val >> 8) & 0xff00) | ((val >> 24) & 0xff)
}

export function isAny(mediaSpec: MediaSpec) {
  return mediaSpec.data.length === 0 || mediaSpec.mask === 0
}

export function formatMediaSpecData(str: string, mask: number, delim: string): string {
  let data = ""
  const bytes = hexDecodeByteArray(str)
  let valid = 0x80000000
  for (let i = 0; i < bytes.length; i++) {
    if ((mask & valid) !== 0) {
      let h = bytes[i].toString(16)
      if (h.length < 2) {
        h = "0" + h
      }
      data += h
    } else {
      data += "*"
    }
    if (i + 1 < bytes.length) {
      data += delim
    }
    valid = valid >>> 1
  }
  return data
}

export interface FormatMediaSpecOptions {
  showAny?: boolean
  getProtoSpecShortName?: (pspec: number) => string
  getApplicationName?: (appId: string | number) => string
}

export function formatMediaSpec(mediaSpec: MediaSpec, options?: FormatMediaSpecOptions) {
  let str = mediaSpec.data
  switch (mediaSpec.type) {
    case MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS:
      if (isAny(mediaSpec) && (options?.showAny === undefined || options.showAny)) {
        return "Any address"
      } else if (mediaSpec.mask === undefined || mediaSpec.mask === getTypeMask(mediaSpec.type)) {
        const ipv4 = mediaSpec.data.length > 0 ? parseInt(mediaSpec.data, 16) : 0
        const b1 = (ipv4 >> 24) & 0xff
        const b2 = (ipv4 >> 16) & 0xff
        const b3 = (ipv4 >> 8) & 0xff
        const b4 = ipv4 & 0xff
        return `${b1}.${b2}.${b3}.${b4}`
      } else {
        let isSimpleMask = true

        // figure out if mask is simple class A, B, C, or none
        for (let i = 3; i >= 0; --i) {
          const m = (mediaSpec.mask >> (i * 8)) & 0xff
          if (m !== 0xff && m !== 0) {
            isSimpleMask = false
            break
          }
        }

        let outString = ""

        // if it's simple, print it out as nnn.nnn.nnn.* as appropriate
        let ipv4 = mediaSpec.data.length > 0 ? parseInt(mediaSpec.data, 16) : 0
        if (isSimpleMask) {
          for (let i = 3; i >= 0; --i) {
            if (((mediaSpec.mask >> (i * 8)) & 0xff) !== 0) {
              outString += `${(ipv4 >> (i * 8)) & 0xff}`
            } else {
              // wildcard character
              outString += "*"
            }

            if (i !== 0) {
              // add a delimiter
              outString += "."
            }
          }
        } else {
          // not simple mask, print as nnn.nnn.nnn.nnn/mm
          // apply the mask
          ipv4 &= mediaSpec.mask

          // count the number of network bits
          let count = 0
          let bitmask = 0x80000000
          while ((bitmask & mediaSpec.mask) !== 0) {
            count++
            bitmask = Math.abs(bitmask >> 1)
          }

          const b1 = (ipv4 >> 24) & 0xff
          const b2 = (ipv4 >> 16) & 0xff
          const b3 = (ipv4 >> 8) & 0xff
          const b4 = ipv4 & 0xff
          outString = `${b1}.${b2}.${b3}.${b4}/${count}`
        }

        return outString
      }
    case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_WIRELESS_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_TOKEN_RING_ADDRESS:
      if (isAny(mediaSpec) && (options?.showAny === undefined || options.showAny)) {
        return "Any address"
      } else {
        const mask = mediaSpec.mask !== undefined ? mediaSpec.mask : getTypeMask(mediaSpec.type)
        return formatMediaSpecData(mediaSpec.data, mask, ":")
      }
    case MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS:
      if (isAny(mediaSpec) && (options?.showAny === undefined || options.showAny)) {
        return "Any address"
      } else {
        // mask is used in one of two ways for IPv6 addresses depending on whether it is a normal address or a CIDR address
        // 1. For a normal address, the upper two bytes are a bit mask where each bit == one byte of the IPv6 address
        // If the bit is set then the equivalent byte is valid.
        // If the bit is not set then the equivalent byte is a wildcard.
        // 2. For a CIDR address, the lower two bytes specify the CIDR prefix.
        const kWildcardMask = 0xffff0000
        const kPrefixMask = 0x0000ffff

        let outString = ""

        const data = []
        for (let i = 0; i < 32; i += 2) {
          data.push(i + 2 <= mediaSpec.data.length ? mediaSpec.data.substring(i, i + 2) : "00")
        }
        const mask = mediaSpec.mask !== undefined ? mediaSpec.mask : getTypeMask(mediaSpec.type)

        // is this a full IPv6 address or a CIDR address?
        if (mask === kWildcardMask || (mask & kWildcardMask) === 0) {
          // are there any zero runs?
          let nZeroes = 0
          let nMostZeroes = 0
          let nZeroStartPos = 0
          let nMostZeroStartPos = 0
          for (let i = 0; i < 16; i += 2) {
            if (parseInt(data[i], 16) === 0 && parseInt(data[i + 1], 16) === 0) {
              if (nZeroes === 0) {
                nZeroStartPos = i / 2
              }
              nZeroes++
            } else {
              if (nZeroes >= nMostZeroes) {
                nMostZeroes = nZeroes
                nMostZeroStartPos = nZeroStartPos
                nZeroes = 0
                nZeroStartPos = 0
              }
              if (nMostZeroes <= 1) {
                // don't bother unless it's at least 2
                nMostZeroes = 0
                nMostZeroStartPos = 0
              }
            }
          }

          if (nMostZeroes === 0 && nZeroes !== 0) {
            nMostZeroes = nZeroes
            nMostZeroStartPos = nZeroStartPos
          }

          if (nMostZeroes <= 1) {
            for (let i = 0; i < 16; i += 2) {
              if (i !== 0) {
                outString += ":"
              }
              const str = (data[i] + data[i + 1]).replace(/^0+/, "")
              outString += str.length > 0 ? str : "0"
            }
          } else {
            // output the part before the zeroes
            for (let i = 0; i < nMostZeroStartPos; i++) {
              const str = (data[2 * i] + data[2 * i + 1]).replace(/^0+/, "")
              outString += `${str.length > 0 ? str : "0"}:`
            }

            // extra colon (2 if no parts before zero run)
            outString += nMostZeroStartPos === 0 ? "::" : ":"

            // now the part after the zeroes
            for (let i = nMostZeroStartPos + nMostZeroes; i < 8; i++) {
              const str = (data[2 * i] + data[2 * i + 1]).replace(/^0+/, "")
              outString += str.length > 0 ? str : "0"
              if (i < 7) {
                outString += ":"
              }
            }
          }

          // don't print the CIDR prefix if this is a full IPv6 address or the prefix is >= 128
          const prefix = mask & kPrefixMask
          if ((mask & kWildcardMask) === 0 && prefix < 128) {
            outString += `/${prefix}`
          }
        } else {
          // this address has one or more wildcards
          let nMask = BigInt(0xc0000000)
          for (let i = 0; i < 16; i += 2) {
            if ((nMask & BigInt(mask)) === nMask) {
              const str = (data[i] + data[i + 1]).replace(/^0+/, "")
              outString += str.length > 0 ? str : "0"
            } else {
              // wildcard character
              outString += "*"
            }

            if (i < 14) {
              // add a delimiter
              outString += ":"
            }

            // move the mask
            nMask >>= BigInt(2)
          }
        }
        return outString.toLowerCase()
      }
    case MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT:
      if (isAny(mediaSpec) && (options?.showAny === undefined || options.showAny)) {
        return "Any port"
      } else {
        const bytes = hexDecodeByteArray(mediaSpec.data)
        if (bytes.length === 2) {
          const port = (bytes[0] << 8) + bytes[1]
          return port.toString()
        }
      }
      break
    case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_PROTOCOL: {
      const mask = mediaSpec.mask !== undefined ? mediaSpec.mask : getTypeMask(mediaSpec.type)
      return formatMediaSpecData(mediaSpec.data, mask, "-")
    }
    case MediaSpecType.MEDIA_SPEC_TYPE_LSAP:
      if (mediaSpec.mask === undefined || mediaSpec.mask === getTypeMask(mediaSpec.type)) {
        return mediaSpec.data
      } else {
        return "*"
      }
    case MediaSpecType.MEDIA_SPEC_TYPE_SNAP: {
      const mask = mediaSpec.mask !== undefined ? mediaSpec.mask : getTypeMask(mediaSpec.type)
      return formatMediaSpecData(mediaSpec.data, mask, "-")
    }
    case MediaSpecType.MEDIA_SPEC_TYPE_APPLICATION_ID:
      {
        const appId = hexDecode(mediaSpec.data).replace(/\0/g, "")
        if (options && options.getApplicationName) {
          str = options.getApplicationName(appId)
          if (!str) {
            str = appId
          }
        } else {
          str = appId
        }
      }
      break
    case MediaSpecType.MEDIA_SPEC_TYPE_APP_ID:
      {
        const appID = mediaSpecToAppID(mediaSpec)
        if (appID !== 0) {
          if (options && options.getApplicationName) {
            str = options.getApplicationName(appID)
            if (!str) {
              str = appID.toString()
            }
          } else {
            str = appID.toString()
          }
        } else {
          str = ""
        }
      }
      break
    case MediaSpecType.MEDIA_SPEC_TYPE_PROTOSPEC:
      {
        const pspec = mediaSpecToProtoSpecID(mediaSpec)
        if (pspec !== 0) {
          if (options && options.getProtoSpecShortName) {
            str = options.getProtoSpecShortName(pspec)
            if (!str) {
              str = pspec.toString(16)
            }
          } else {
            str = pspec.toString()
          }
        } else {
          str = ""
        }
      }
      break
    default:
      break
  }
  return str
}

function parseMediaSpecData(
  str: string,
  bytes: number,
  msclass: MediaSpecClass,
  type: MediaSpecType,
  isAny: boolean = false
): MediaSpec {
  // Valid formats:
  // 0xE0, $E0, E0.
  // 0x809B, $809B, 809B, 80-9B, 60-*, *-9B.
  // 0x800007809B, $800007809B, 800007809B, 80-00-07-80-9B, 80-*-07-*-9B.

  // Skip leading $ or 0x.
  if (str.length > 1 && str.charCodeAt(0) === 36) {
    str = str.substring(1)
  } else if (
    str.length > 2 &&
    str.charCodeAt(0) === 48 &&
    (str.charCodeAt(1) === 120 || str.charCodeAt(1) === 88)
  ) {
    str = str.substring(2)
  }

  let i = 0
  let mask = 0
  let valid = 0x80000000
  let data = ""
  for (let j = 0; j < bytes; j++) {
    if (i === str.length) {
      // Invalid format: ran out of string.
      throw new Error("Invalid format")
    }

    if (str.charCodeAt(i) === 42) {
      // Wildcard character.
      // Skip to the next character.
      i++
      data += "00"
    } else {
      // Parse the next hex byte.
      const hex = str.substring(i, i + 2)
      const byte = hexStringToByte(hex)
      if (byte < 0) {
        throw new Error("Invalid format")
      }
      data += hex

      // Add the valid mask bits.
      mask = mask | valid

      // Skip two characters.
      i += 2
    }

    // Skip delimiters (dash, period, colon).
    const delim = str.charCodeAt(i)
    if (delim === 45 || delim === 46 || delim === 58) {
      i++
    }

    // Shift the valid mask bits.
    valid = valid >>> 1
  }

  // Make sure there's nothing extra on the end.
  if (i !== str.length) {
    throw new Error("Invalid format")
  }

  return { msclass, type, mask: isAny ? 0 : mask >>> 0, data }
}

export function parseIPMediaSpec(str: string, isAny: boolean = false) {
  let addr
  let mask: number = isAny ? 0 : getTypeMask(MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS)
  if (str.indexOf("/") === -1) {
    if (str.indexOf("*") === -1) {
      addr = ipaddr.IPv4.parse(str)
    } else {
      const parseIntAuto = (s: string) => {
        if (s.length > 2 && s[0] === "0" && s[1] !== "x") {
          return parseInt(s, 8)
        } else {
          return parseInt(s)
        }
      }
      const match = str.match(
        /^(0?\d+|0x[a-f0-9]+|\*)\.(0?\d+|0x[a-f0-9]+|\*)\.(0?\d+|0x[a-f0-9]+|\*)\.(0?\d+|0x[a-f0-9]+|\*)$/i
      )
      if (!match || match.length !== 5) throw Error("Incorrect wildcard format")
      const bytes: number[] = []
      for (let i = 1; i < 5; i++) {
        if (match[i] === "*") {
          bytes.push(0)
          mask = (mask & ~(0xff << ((4 - i) * 8))) >>> 0
        } else {
          bytes.push(parseIntAuto(match[i]))
        }
      }
      addr = new ipaddr.IPv4(bytes)
    }
  } else {
    const [addrPart, cidr] = ipaddr.IPv4.parseCIDR(str)
    addr = addrPart
    mask = (0xffffffff << (32 - cidr)) >>> 0
  }
  return {
    msclass: MediaSpecClass.MEDIA_SPEC_CLASS_ADDRESS,
    type: MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS,
    data: hexEncodeByteArray(addr.toByteArray()),
    mask: mask >>> 0,
  }
}

export function parseIPv6MediaSpecWithWildcard(str: string) {
  const bytes = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  let mask = 0
  let tempMask = 0xc0000000
  let hasZeroRun = false
  let parts = 0
  let pos = 0
  for (let i = 0; i < 16; i += 2) {
    if (pos >= str.length) {
      throw Error("IPv6 address length exceeded")
    }

    if (str.charAt(pos) === "*") {
      // Wildcard character, skip it.
      pos++
    } else if (str.charAt(pos) === ":") {
      hasZeroRun = true
      pos++
      break
    } else {
      // Read the next 16-bit value.
      let value = 0
      for (let j = 0; j < 4; j++) {
        const n = hexCharCodeToValue(str.charCodeAt(pos))
        if (n === -1) break
        value = (value << 4) + n
        pos++
      }

      // Value should fit in 16 bits.
      if (value > 0xffff) {
        throw Error("IPv6 address invalid")
      }

      // Convert to data bytes.
      bytes[i] = (value >> 8) & 0xff
      bytes[i + 1] = value & 0xff
      mask |= tempMask
      tempMask = tempMask >> 2
      parts++
    }

    if (pos >= str.length) {
      break
    }

    // Skip delimiters.
    if (str.charAt(pos) === ":" || str.charAt(pos) === ".") {
      pos++
    } else if (str.charAt(pos) === "/") {
      break
    }
  }

  if (hasZeroRun && parts < 8) {
    // Skip past the delimiter.
    if (str.charAt(pos) === ":") {
      pos++
    }

    // Count how many parts we have
    let partsAfterZeroRun = 1
    let pos2 = pos
    for (;;) {
      if (pos2 >= str.length || str.charAt(pos2) === "/") {
        break
      }
      if (str.charAt(pos2) === ":") {
        partsAfterZeroRun++
      }
      pos2++
    }

    // Now given the number of parts before and after the zero run,
    // we know how many zero parts we need.
    const zeroParts = 8 - (parts + partsAfterZeroRun)
    for (let i = 0; i < zeroParts; i++) {
      mask |= tempMask
      tempMask >>= 2
      parts++
    }

    const partsLeft = 8 - parts
    for (let i = 0; i < partsLeft * 2; i += 2) {
      if (str.charAt(pos) === "*") {
        // Wildcard character, skip it.
        pos++
      } else if (str.charAt(pos) === ":") {
        throw Error("IPv6 address invalid")
      } else {
        // Read the next 16-bit value.
        let value = 0
        for (let j = 0; j < 4; j++) {
          const n = hexCharCodeToValue(str.charCodeAt(pos))
          if (n === -1) break
          value = (value << 4) + n
          pos++
        }

        // Value should fit in 16 bits.
        if (value > 0xffff) {
          throw Error("IPv6 address invalid")
        }

        // Convert to data bytes.
        bytes[parts * 2] = (value >> 8) & 0xff
        bytes[parts * 2 + 1] = value & 0xff
        mask |= tempMask
        tempMask = tempMask >> 2
        parts++
      }

      if (pos >= str.length) {
        break
      }

      // Skip delimiters.
      if (str.charAt(pos) === ":" || str.charAt(pos) === ".") {
        pos++
      } else if (str.charAt(pos) === "/") {
        break
      }
    }

    // Parse CIDR notation.
    if (pos <= str.length && str.charAt(pos) === "/") {
      const cidr = parseInt(str.substring(pos + 1))
      if (Number.isNaN(cidr)) {
        throw Error("IPv6 address invalid CIDR notation")
      }
      if (cidr < 0 || cidr > 128) {
        throw Error("IPv6 address invalid CIDR value")
      }
      mask = cidr
    }
  }

  return {
    msclass: MediaSpecClass.MEDIA_SPEC_CLASS_ADDRESS,
    type: MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS,
    data: hexEncodeByteArray(bytes),
    mask: mask >>> 0,
  }
}

export function parseIPv6MediaSpec(str: string, isAny: boolean = false) {
  let addr
  let mask: number = isAny ? 0 : getTypeMask(MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS)
  if (str.indexOf("/") === -1) {
    if (str.indexOf("*") === -1) {
      addr = ipaddr.IPv6.parse(str)
    } else {
      return parseIPv6MediaSpecWithWildcard(str)
    }
  } else {
    const [addrPart, cidr] = ipaddr.IPv6.parseCIDR(str)
    addr = addrPart
    mask = cidr
  }
  return {
    msclass: MediaSpecClass.MEDIA_SPEC_CLASS_ADDRESS,
    type: MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS,
    data: hexEncodeByteArray(addr.toByteArray()),
    mask: mask >>> 0,
  }
}

export function parseIPPortMediaSpec(str: string, isAny: boolean = false): MediaSpec {
  // Valid formats: 1021, 0x1234, $6767, no wildcards.

  const mask: number = isAny ? 0 : getTypeMask(MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT)

  // Skip leading $ or 0x.
  if (str.length > 1 && str.charCodeAt(0) === 36) {
    str = str.substring(1)
  } else if (
    str.length > 2 &&
    str.charCodeAt(0) === 48 &&
    (str.charCodeAt(1) === 120 || str.charCodeAt(1) === 88)
  ) {
    str = str.substring(2)
  }

  if (str.length === 0) {
    throw new Error("Invalid format")
  }

  let value = 0
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) >= 48 && str.charCodeAt(i) <= 57) {
      value = value * 10 + (str.charCodeAt(i) - 48)
    } else {
      throw new Error("Invalid character")
    }
  }

  if ((value & 0xffff0000) !== 0) {
    throw new Error("Number too big")
  }

  const high = (value >> 8) & 0xff
  const low = value & 0xff

  return {
    msclass: MediaSpecClass.MEDIA_SPEC_CLASS_PORT,
    type: MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT,
    data: hexEncodeByteArray([high, low]),
    mask,
  }
}

export function parseMediaSpec(
  str: string,
  type: MediaSpecType,
  isAny: boolean = false
): MediaSpec {
  switch (type) {
    case MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS:
      return parseIPMediaSpec(str, isAny)
    case MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS:
      return parseIPv6MediaSpec(str, isAny)
    case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_PROTOCOL:
      return parseMediaSpecData(str, 2, MediaSpecClass.MEDIA_SPEC_CLASS_PROTOCOL, type, isAny)
    case MediaSpecType.MEDIA_SPEC_TYPE_SNAP:
      return parseMediaSpecData(str, 5, MediaSpecClass.MEDIA_SPEC_CLASS_PROTOCOL, type, isAny)
    case MediaSpecType.MEDIA_SPEC_TYPE_LSAP:
      return parseMediaSpecData(str, 1, MediaSpecClass.MEDIA_SPEC_CLASS_PROTOCOL, type, isAny)
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_PPP_PROTOCOL:
      return parseMediaSpecData(str, 2, MediaSpecClass.MEDIA_SPEC_CLASS_PROTOCOL, type, isAny)
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_FRAME_RELAY_PROTOCOL:
      return parseMediaSpecData(str, 2, MediaSpecClass.MEDIA_SPEC_CLASS_PROTOCOL, type, isAny)
    case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_WIRELESS_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_TOKEN_RING_ADDRESS:
      return parseMediaSpecData(str, 6, MediaSpecClass.MEDIA_SPEC_CLASS_ADDRESS, type, isAny)
    case MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT:
      return parseIPPortMediaSpec(str, isAny)
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_DLCI_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_APPLETALK_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_IPX_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_DECNET_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_AT_PORT:
    case MediaSpecType.MEDIA_SPEC_TYPE_NW_PORT:
    case MediaSpecType.MEDIA_SPEC_TYPE_APPLICATION_ID:
    case MediaSpecType.MEDIA_SPEC_TYPE_APP_ID:
      // TODO
      break
    default:
      break
  }
  return emptyMediaSpec
}

const mediaSpecTypeDefaults: string[] = [
  "", // MEDIA_SPEC_TYPE_NULL
  "00-00", // MEDIA_SPEC_TYPE_ETHERNET_PROTOCOL
  "00", // MEDIA_SPEC_TYPE_LSAP
  "00-00-00-00-00", // MEDIA_SPEC_TYPE_SNAP
  "0", // MEDIA_SPEC_TYPE_LAP
  "0", // MEDIA_SPEC_TYPE_DDP
  "0", // MEDIA_SPEC_TYPE_MAC_CONTROL
  "", // MEDIA_SPEC_TYPE_PROTOSPEC_HIERARCHY
  "", // MEDIA_SPEC_TYPE_APPLICATION_ID
  "", // MEDIA_SPEC_TYPE_PROTOSPEC
  "00:00:00:00:00:00", // MEDIA_SPEC_TYPE_ETHERNET_ADDRESS
  "00:00:00:00:00:00", // MEDIA_SPEC_TYPE_TOKEN_RING_ADDRESS
  "0", // MEDIA_SPEC_TYPE_LAP_ADDRESS
  "00:00:00:00:00:00", // MEDIA_SPEC_TYPE_WIRELESS_ADDRESS
  "", // MEDIA_SPEC_TYPE_APP_ID
  "",
  "",
  "",
  "",
  "",
  "0.0", // MEDIA_SPEC_TYPE_APPLETALK_ADDRESS
  "0.0.0.0", // MEDIA_SPEC_TYPE_IP_ADDRESS
  "0.0", // MEDIA_SPEC_TYPE_DECNET_ADDRESS
  "", // MEDIA_SPEC_TYPE_OTHER_ADDRESS
  "0000:0000:0000:0000:0000:0000:0000:0000", // MEDIA_SPEC_TYPE_IPV6_ADDRESS
  "0.000000000000", // MEDIA_SPEC_TYPE_IPX_ADDRESS
  "",
  "",
  "",
  "",
  "",
  "",
  "", // MEDIA_SPEC_TYPE_ERROR
  "0", // MEDIA_SPEC_TYPE_AT_PORT
  "0", // MEDIA_SPEC_TYPE_IP_PORT
  "0", // MEDIA_SPEC_TYPE_NW_PORT
  "0", // MEDIA_SPEC_TYPE_TCP_PORT_PAIR
  "00-00", // MEDIA_SPEC_TYPE_WAN_PPP_PROTOCOL
  "00-00", // MEDIA_SPEC_TYPE_WAN_FRAME_RELAY_PROTOCOL
  "00-00", // MEDIA_SPEC_TYPE_WAN_X25_PROTOCOL
  "00-00", // MEDIA_SPEC_TYPE_WAN_X25E_PROTOCOL
  "00-00", // MEDIA_SPEC_TYPE_WAN_IPARS_PROTOCOL
  "0", // MEDIA_SPEC_TYPE_WAN_U200_PROTOCOL
  "0", // MEDIA_SPEC_TYPE_WAN_DLCI_ADDRESS
  "0", // MEDIA_SPEC_TYPE_WAN_Q931_PROTOCOL
]

export function getMediaSpecTypeDefault(mst: MediaSpecType) {
  if (mst >= 0 && mst < mediaSpecTypeDefaults.length) {
    return mediaSpecTypeDefaults[mst]
  }
  return ""
}

const mediaSpecTypeExamples: string[] = [
  "", // MEDIA_SPEC_TYPE_NULL
  "0x809B, $809B, 809B, 80-9B, 80-*", // MEDIA_SPEC_TYPE_ETHERNET_PROTOCOL
  "0xE0, $E0, E0", // MEDIA_SPEC_TYPE_LSAP
  "080007809B, 08-00-07-80-9B, 08:00:07:80:9B, 08-00-07-*-*", // MEDIA_SPEC_TYPE_SNAP
  "", // MEDIA_SPEC_TYPE_LAP
  "", // MEDIA_SPEC_TYPE_DDP
  "", // MEDIA_SPEC_TYPE_MAC_CONTROL
  "", // MEDIA_SPEC_TYPE_PROTOSPEC_HIERARCHY
  "", // MEDIA_SPEC_TYPE_APPLICATION_ID
  "", // MEDIA_SPEC_TYPE_PROTOSPEC
  "08:00:2B:10:76:40, 08-00-2B-10-76-40, 08:00:2B:10:76:*, 08:00:2B:*:*:*", // MEDIA_SPEC_TYPE_ETHERNET_ADDRESS
  "", // MEDIA_SPEC_TYPE_TOKEN_RING_ADDRESS
  "", // MEDIA_SPEC_TYPE_LAP_ADDRESS
  "08:00:2B:10:76:40, 08-00-2B-10-76-40, 08:00:2B:10:76:*, 08:00:2B:*:*:*", // MEDIA_SPEC_TYPE_WIRELESS_ADDRESS
  "", // MEDIA_SPEC_TYPE_APP_ID
  "",
  "",
  "",
  "",
  "",
  "net.node, 0.255, 1000.255, 1000.*, *.255", // MEDIA_SPEC_TYPE_APPLETALK_ADDRESS
  "192.216.124.1, 192.216.*.*", // MEDIA_SPEC_TYPE_IP_ADDRESS
  "area.node, 17.381, 17.*", // MEDIA_SPEC_TYPE_DECNET_ADDRESS
  "", // MEDIA_SPEC_TYPE_OTHER_ADDRESS
  "0000:000CF:BA4C:1344:0000:000CF:BA4C:1344", // MEDIA_SPEC_TYPE_IPV6_ADDRESS
  "50000.0000947545B4, FACE.000000000001", // MEDIA_SPEC_TYPE_IPX_ADDRESS
  "",
  "",
  "",
  "",
  "",
  "",
  "", // MEDIA_SPEC_TYPE_ERROR
  "", // MEDIA_SPEC_TYPE_AT_PORT
  "23, 0x0017, $0017", // MEDIA_SPEC_TYPE_IP_PORT
  "00A0, 0x00A0, $00A0", // MEDIA_SPEC_TYPE_NW_PORT
  "", // MEDIA_SPEC_TYPE_TCP_PORT_PAIR
  "0x809B, $809B, 809B, 80-9B, 80-*", // MEDIA_SPEC_TYPE_WAN_PPP_PROTOCOL
  "0x809B, $809B, 809B, 80-9B, 80-*", // MEDIA_SPEC_TYPE_WAN_FRAME_RELAY_PROTOCOL
  "", // MEDIA_SPEC_TYPE_WAN_X25_PROTOCOL
  "", // MEDIA_SPEC_TYPE_WAN_X25E_PROTOCOL
  "", // MEDIA_SPEC_TYPE_WAN_IPARS_PROTOCOL
  "", // MEDIA_SPEC_TYPE_WAN_U200_PROTOCOL
  "16", // MEDIA_SPEC_TYPE_WAN_DLCI_ADDRESS
  "", // MEDIA_SPEC_TYPE_WAN_Q931_PROTOCOL
]

export function getMediaSpecTypeExample(mst: MediaSpecType) {
  if (mst >= 0 && mst < mediaSpecTypeExamples.length) {
    return mediaSpecTypeExamples[mst]
  }
  return ""
}

export function getMediaSpecClassFromMediaSpecType(mst: MediaSpecType): MediaSpecClass {
  switch (mst) {
    case MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_WIRELESS_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_TOKEN_RING_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_APPLETALK_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_IPX_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_DECNET_ADDRESS:
      return MediaSpecClass.MEDIA_SPEC_CLASS_ADDRESS
    case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_PROTOCOL:
    case MediaSpecType.MEDIA_SPEC_TYPE_LSAP:
    case MediaSpecType.MEDIA_SPEC_TYPE_SNAP:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_PPP_PROTOCOL:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_FRAME_RELAY_PROTOCOL:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_X25_PROTOCOL:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_X25E_PROTOCOL:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_U200_PROTOCOL:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_IPARS_PROTOCOL:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_DLCI_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_Q931_PROTOCOL:
      return MediaSpecClass.MEDIA_SPEC_CLASS_PROTOCOL
    case MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT:
    case MediaSpecType.MEDIA_SPEC_TYPE_AT_PORT:
    case MediaSpecType.MEDIA_SPEC_TYPE_NW_PORT:
      return MediaSpecClass.MEDIA_SPEC_CLASS_PORT
  }
  return MediaSpecClass.MEDIA_SPEC_CLASS_NULL
}

export function mediaSpecTypeToString(mst: MediaSpecType) {
  switch (mst) {
    case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_PROTOCOL:
      return "Ethernet Protocol"
    case MediaSpecType.MEDIA_SPEC_TYPE_LSAP:
      return "LSAP"
    case MediaSpecType.MEDIA_SPEC_TYPE_SNAP:
      return "SNAP"
    case MediaSpecType.MEDIA_SPEC_TYPE_LAP:
      return "LAP"
    case MediaSpecType.MEDIA_SPEC_TYPE_DDP:
      return "DDP"
    case MediaSpecType.MEDIA_SPEC_TYPE_MAC_CONTROL:
      return "MacCtl"
    case MediaSpecType.MEDIA_SPEC_TYPE_APPLICATION_ID:
      return "Application"
    case MediaSpecType.MEDIA_SPEC_TYPE_PROTOSPEC:
      return "ProtoSpec"
    case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS:
      return "Ethernet"
    case MediaSpecType.MEDIA_SPEC_TYPE_TOKEN_RING_ADDRESS:
      return "TokenRing"
    case MediaSpecType.MEDIA_SPEC_TYPE_LAP_ADDRESS:
      return "LAP Address"
    case MediaSpecType.MEDIA_SPEC_TYPE_WIRELESS_ADDRESS:
      return "Wireless"
    case MediaSpecType.MEDIA_SPEC_TYPE_APP_ID:
      return "Application"
    case MediaSpecType.MEDIA_SPEC_TYPE_APPLETALK_ADDRESS:
      return "AppleTalk"
    case MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS:
      return "IP"
    case MediaSpecType.MEDIA_SPEC_TYPE_DECNET_ADDRESS:
      return "DECnet"
    case MediaSpecType.MEDIA_SPEC_TYPE_OTHER_ADDRESS:
      return "Other Address"
    case MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS:
      return "IPv6"
    case MediaSpecType.MEDIA_SPEC_TYPE_IPX_ADDRESS:
      return "IPX"
    case MediaSpecType.MEDIA_SPEC_TYPE_ERROR:
      return "Error"
    case MediaSpecType.MEDIA_SPEC_TYPE_AT_PORT:
      return "AT Port"
    case MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT:
      return "TCP-UDP Port"
    case MediaSpecType.MEDIA_SPEC_TYPE_NW_PORT:
      return "NW Port"
    case MediaSpecType.MEDIA_SPEC_TYPE_TCP_PORT_PAIR:
      return "TCP Port Pair"
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_PPP_PROTOCOL:
      return "WAN PPP"
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_FRAME_RELAY_PROTOCOL:
      return "WAN Frame Relay"
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_X25_PROTOCOL:
      return "WAN X.25"
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_X25E_PROTOCOL:
      return "WAN X.25E"
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_IPARS_PROTOCOL:
      return "WAN IPARS"
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_U200_PROTOCOL:
      return "WAN U200"
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_DLCI_ADDRESS:
      return "WAN DLCI"
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_Q931_PROTOCOL:
      return "WAN Q931"
  }
  return ""
}

export function validateMediaSpec(str: string, type: MediaSpecType): boolean {
  let valid = true
  try {
    parseMediaSpec(str, type)
  } catch {
    valid = false
  }
  return valid
}

export function validateMediaSpecs(str: string, type: MediaSpecType): boolean {
  let valid = true
  const strs = str.split(/[,;\s\n]+/)
  for (let i = 0; i < strs.length; i++) {
    try {
      if (strs[i].length > 0) {
        parseMediaSpec(strs[i], type)
      }
    } catch {
      valid = false
      break
    }
  }
  return valid
}

export function isWildcard(mediaSpec: MediaSpec): boolean {
  return mediaSpec.mask !== undefined && mediaSpec.mask !== getTypeMask(mediaSpec.type)
}

export function getWildcardBits(mediaSpec: MediaSpec): number {
  let wildcardBits = 0
  if (mediaSpec.mask !== undefined && isWildcard(mediaSpec)) {
    switch (mediaSpec.type) {
      case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS:
        wildcardBits = ((mediaSpec.mask >> 26) & 0x3f).toString(2).replace(/1/g, "").length * 8
        break
      case MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS:
        wildcardBits = mediaSpec.mask.toString(2).replace(/1/g, "").length
        break
      case MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS:
        if (mediaSpec.mask & 0xffff) {
          const cidr = mediaSpec.mask & 0xffff
          if (cidr <= 128) {
            wildcardBits = 128 - cidr
          }
        } else {
          wildcardBits = ((mediaSpec.mask >> 16) & 0xffff).toString(2).replace(/1/g, "").length * 8
        }
        break
      default:
        break
    }
  }
  return wildcardBits
}

export function isIPv6Address(address: string): boolean {
  let ipv6 = false
  try {
    const addr = ipaddr.parse(address)
    ipv6 = addr.kind() === "ipv6"
  } catch {}
  return ipv6
}
