import styled from "styled-components"
import { HalfWidthWidget, WidgetHeader, WidgetTitle, WidgetBody } from "../Dashboard"
import { formatFloat } from "../../../utils/formatUtils"

const Gauges = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @supports (-ms-ime-align: auto) {
    /* Edge only */
    justify-content: space-around;
  }
`

const GaugeDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const GaugeTitle = styled.h6`
  margin: 0;
  text-transform: uppercase;
  text-align: center;
  color: ${props => props.theme.textMutedColor};
`

const GaugeValue = styled.div`
  font-size: 2rem;
  text-align: center;
`

const GaugeUnit = styled.div`
  text-align: center;
`

const Gauge = ({ title, value, unit }: { title: string; value: string; unit: string }) => (
  <GaugeDiv>
    <GaugeTitle>{title}</GaugeTitle>
    <GaugeValue>{value}</GaugeValue>
    <GaugeUnit>{unit}</GaugeUnit>
  </GaugeDiv>
)

const CurrentActivityWidget = ({ currentActivity }: { currentActivity: any | null }) => (
  <HalfWidthWidget>
    <WidgetHeader>
      <WidgetTitle>Current Activity</WidgetTitle>
    </WidgetHeader>
    <WidgetBody minHeight="6rem">
      {currentActivity && (
        <Gauges>
          <Gauge
            title="Network Utilization"
            value={formatFloat(currentActivity.mbps, 3)}
            unit="Mbits/s"
          />
          <Gauge title="Packets" value={formatFloat(currentActivity.pps, 0)} unit="packets/s" />
          <Gauge title="Errors" value={formatFloat(currentActivity.eps, 0)} unit="errors/s" />
        </Gauges>
      )}
    </WidgetBody>
  </HalfWidthWidget>
)

export default CurrentActivityWidget
