import styled from "styled-components"

export const Dashboard = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 8px;
`

export const Widget = styled.div`
  display: flex;
  flex-direction: column;
  border: ${props => props.theme.panelBorder};
  background: ${props => props.theme.panelBackground};
  border-radius: ${props => props.theme.panelBorderRadius};
  box-shadow: ${props => props.theme.panelBoxShadow};
`

export const FullWidthWidget = styled(Widget)`
  grid-column-end: span 12;
`

export const HalfWidthWidget = styled(Widget)`
  grid-column-end: span 6;

  @media (max-width: 768px) {
    grid-column-end: span 12;
  }
`

export const ThirdWidthWidget = styled(Widget)`
  grid-column-end: span 4;

  @media (max-width: 768px) {
    grid-column-end: span 12;
  }
`

export const WidgetHeader = styled.div`
  display: flex;
  margin: 0;
  padding: 4px 8px;
  border-bottom: ${props => props.theme.panelBorder};
  background: ${props => props.theme.panelTitleBackground};
  border-top-left-radius: ${props => props.theme.panelBorderRadius};
  border-top-right-radius: ${props => props.theme.panelBorderRadius};
`

export const WidgetTitle = styled.h5`
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
`

export const WidgetSubTitle = styled.span`
  margin-left: 0.5rem;
  font-size: 80%;
  font-weight: normal;
  color: ${props => props.theme.textMutedColor};
`

export const WidgetOptions = styled.span`
  margin-left: auto;
  display: flex;
`

export const WidgetBody = styled.div<{ minHeight?: string }>`
  flex-grow: 1;
  padding: ${props => props.theme.panelPadding};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${props => (props.minHeight ? props.minHeight : "auto")};
  box-sizing: content-box;
`

export const NoData = styled.h5`
  display: inline-block;
  margin: 0;
  text-align: center;
  color: ${props => props.theme.textMutedColor};
`
