import * as React from "react"
import { connect } from "react-redux"
import { Col, Form, FormGroup, Row } from "reactstrap"
import { produce } from "immer"
import { cloneDeep, debounce } from "lodash"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { DateTimePicker } from "../common/DateTimePicker"
import { ErrorText } from "../common/ErrorText"
import { Label } from "../common/Form"
import { Input } from "../common/Input"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../common/Modal"
import { Table as PropTable } from "../common/PropTable"
import { Select } from "../common/Select"
import { FilterBar } from "../FilterBar"
import { pushRecentFilterBarExpression } from "../../store/ui"
import { fetchFilesList, fetchTimelineData, postFilterConvert } from "../../api/api"
import {
  getAuthToken,
  getEngine,
  getCapabilities,
  getRecentFilterBarExpressions,
  getShowLocalTime,
} from "../../store"
import {
  DatabaseRowGetFileList,
  RequestCreateForensicSearch,
  ResponseGetEngineCapabilities,
  ResponseGetFileList,
  ResponseGetFilterConvert,
  ResponseGetTimelineData,
  TimelineDataType,
} from "../../api/types"
import { MediaType, MediaSubType } from "../../api/types/mediaTypes"
import { LimitMode, TimelineDataViewType } from "../../api/types/peekTypes"
import {
  formatDurationRange,
  formatFloat,
  formatInteger,
  peekFromDate,
  utcToLocal,
} from "../../utils/formatUtils"

type PacketFileFormat = {
  fileDescription: string
  fileExt: string
}

const packetFileFormats: Array<PacketFileFormat> = [
  { fileDescription: "Omnipeek Packet File", fileExt: "pkt" },
  { fileDescription: "Omnipeek Packet File (compressed)", fileExt: "wpz" },
  { fileDescription: "Pcap (Wireshark, tcpdump, etc.)", fileExt: "pcap" },
  {
    fileDescription: "Pcap (Wireshark, tcpdump, etc.) (compressed)",
    fileExt: "pcap.gz",
  },
  { fileDescription: "PcapNG (Wireshark, etc.)", fileExt: "pcapng" },
  { fileDescription: "PcapNG (Wireshark, etc.) (compressed)", fileExt: "pcapng.gz" },
]

type DownloadPacketsModalProps = {
  authToken: string
  engine: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  onCancel: () => void
  onOK: (query: RequestCreateForensicSearch, fileName: string, fileExt: string) => void
  captureSessionId?: number
  startTime?: string
  endTime?: string
  filter?: string
  mediaSubType?: number
  mediaType?: number
  name?: string
  file?: string
  recentFilterBarExpressions: string[]
  pushRecentFilterBarExpression: (expression: string) => void
  showLocalTime: boolean
}

type DownloadPacketsModalState = {
  fileExt: string
  fileName: string
  filterBarError: string
  hasFiles: boolean | null
  packetsEstimate: string | null
  query: RequestCreateForensicSearch
}

class DownloadPacketsModal extends React.Component<
  DownloadPacketsModalProps,
  DownloadPacketsModalState
> {
  state: DownloadPacketsModalState = {
    fileExt: "pkt",
    fileName: this.props.name || "Download Packets",
    filterBarError: "",
    hasFiles: null,
    packetsEstimate: "",
    query: {
      application: false,
      captureSessionId: this.props.captureSessionId || -1,
      conversation: false,
      country: false,
      endTime: this.props.endTime || new Date().toISOString(),
      error: false,
      expert: false,
      files: false,
      filter: this.props.filter || "",
      graphs: false,
      history: false,
      limitType: LimitMode.LIMIT_NONE,
      log: false,
      mediaSubType: this.props.mediaSubType || MediaSubType.MEDIA_SUBTYPE_NATIVE,
      mediaType: this.props.mediaType || MediaType.MEDIA_TYPE_802_3,
      name: this.props.name || "Download Packets",
      network: false,
      node: false,
      packets: true,
      passiveNameResolution: false,
      plugins: false,
      protocol: false,
      singleFiles: this.props.file ? [this.props.file] : [],
      size: false,
      startTime: this.props.startTime || new Date().toISOString(),
      summary: false,
      topTalkers: false,
      voice: false,
      web: false,
      wirelessChannel: false,
      wirelessNode: false,
    },
  }

  componentDidMount = () => {
    const fileExt = localStorage.getItem("downloadPacketsFileExt")
    this.setState({
      fileExt: fileExt !== null ? fileExt : "pkt",
    })

    this.validateFilter()
    this.onPacketsEstimate()
    this.onLoadFileList()
  }

  onChangeEndTime = (date: Date | null) => {
    if (date != null) {
      const endTime = new Date(date).toISOString()
      this.setState(
        produce((draft: DownloadPacketsModalState) => {
          draft.query.endTime = endTime
        }),
        () => {
          this.onPacketsEstimate()
          this.onLoadFileList()
        }
      )
    }
  }

  onChangeFilter = (filterExpression: string) => {
    this.setState(
      produce((draft: DownloadPacketsModalState) => {
        draft.query.filter = filterExpression
      }),
      () => {
        this.validateFilterDebounced()
      }
    )
  }

  onChangeStartTime = (date: Date | null) => {
    if (date != null) {
      const startTime = new Date(date).toISOString()
      this.setState(
        produce((draft: DownloadPacketsModalState) => {
          draft.query.startTime = startTime
        }),
        () => {
          this.onPacketsEstimate()
          this.onLoadFileList()
        }
      )
    }
  }

  graphTimeToIndex = (time: number, graphSampleInterval: number, graphStartTime: number) => {
    if (time < graphStartTime || graphSampleInterval === 0) return -1
    const d = time - graphStartTime
    return Math.trunc(d / (graphSampleInterval * 1000))
  }

  onPacketsEstimate = () => {
    const { query } = this.state
    const { captureSessionId, startTime: queryStartTime, endTime: queryEndTime } = query

    if (captureSessionId !== -1 && queryStartTime && queryEndTime) {
      const { engine, authToken } = this.props
      fetchTimelineData(
        engine,
        authToken,
        captureSessionId as number,
        "utilization-mbps" as TimelineDataType,
        1
      )
        .then((timelineData: ResponseGetTimelineData) => {
          if (
            timelineData &&
            Array.isArray(timelineData.data) &&
            timelineData.viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_MBITS
          ) {
            const graphStartTime = Date.parse(timelineData.startTime)
            const graphSampleInterval = timelineData.sampleInterval

            const startTime = Date.parse(queryStartTime)
            const endTime = Date.parse(queryEndTime)

            const startIndex =
              startTime < graphStartTime
                ? 0
                : this.graphTimeToIndex(startTime, graphSampleInterval, graphStartTime)
            const endIndex = Math.min(
              this.graphTimeToIndex(endTime, graphSampleInterval, graphStartTime),
              timelineData.data.length
            )
            if (
              startIndex >= 0 &&
              startIndex <= timelineData.data.length &&
              endIndex >= 0 &&
              endIndex <= timelineData.data.length &&
              startIndex <= endIndex
            ) {
              let totalPackets = 0
              for (let i = 0; i < endIndex; ++i) {
                if (i >= startIndex && i < endIndex) {
                  totalPackets += timelineData.data[i].totalPackets
                }
              }

              let packetsEstimate = ""
              if (totalPackets > 1000000000) {
                const d = totalPackets / 1000000000.0
                packetsEstimate += formatFloat(d, 2) + " B"
              } else if (totalPackets > 1000000) {
                const d = totalPackets / 1000000.0
                packetsEstimate += formatFloat(d, 2) + " M"
              } else if (totalPackets > 1000) {
                const d = totalPackets / 1000.0
                packetsEstimate += formatFloat(d, 2) + " K"
              } else {
                packetsEstimate += formatInteger(totalPackets)
              }
              packetsEstimate = "~" + packetsEstimate

              this.setState({ packetsEstimate })
            }
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  onLoadFileList = () => {
    const { engine, authToken } = this.props
    fetchFilesList(engine, authToken)
      .then((response: ResponseGetFileList) => {
        const { captureSessionId } = this.props
        const { query } = this.state

        const files: DatabaseRowGetFileList[] =
          captureSessionId !== undefined
            ? response.rows.filter(
                (row: DatabaseRowGetFileList) =>
                  row.SessionID !== undefined && row.SessionID === captureSessionId
              )
            : response.rows

        let hasFiles: boolean | null = null
        const startTime = query.startTime ? new Date(query.startTime) : undefined
        const endTime = query.endTime ? new Date(query.endTime) : undefined
        if (startTime && endTime) {
          if (startTime > endTime) {
            hasFiles = false
          } else {
            for (const file of files) {
              if (file.SessionStartTime !== undefined && file.SessionEndTime !== undefined) {
                const sessionStartValue = new Date(file.SessionStartTime)
                const sessionEndValue = new Date(file.SessionEndTime)
                hasFiles = !(
                  (startTime < sessionStartValue && endTime < sessionStartValue) ||
                  (startTime > sessionEndValue && endTime > sessionEndValue)
                )
                if (hasFiles) {
                  break
                }
              } else if (file.SessionStartTime !== undefined) {
                const sessionStartValue = new Date(file.SessionStartTime)
                hasFiles = startTime >= sessionStartValue || endTime >= sessionStartValue
                if (hasFiles) {
                  break
                }
              } else if (file.SessionEndTime !== undefined) {
                const sessionEndValue = new Date(file.SessionEndTime)
                hasFiles = startTime <= sessionEndValue || endTime <= sessionEndValue
                if (hasFiles) {
                  break
                }
              }
            }
          }
        }
        this.setState({
          hasFiles,
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  validateFilter = () => {
    if (this.state.query.filter !== undefined) {
      const { engine, authToken } = this.props
      postFilterConvert(engine, authToken, this.state.query.filter, true)
        .then((response: ResponseGetFilterConvert) => {
          if (!response.valid) {
            let errorMessage = ""
            if (Array.isArray(response.errorsParsing) && response.errorsParsing.length > 0) {
              errorMessage = `Character ${response.errorsParsing[0].position}: ${response.errorsParsing[0].message}`
            } else if (response.errorConversion) {
              errorMessage = response.errorConversion
            } else {
              errorMessage = "Error in filter expression"
            }
            this.setState({ filterBarError: errorMessage })
          } else {
            this.setState({ filterBarError: "" })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  validateFilterDebounced = debounce(this.validateFilter, 500)

  onOK = () => {
    if (this.state.query.filter !== undefined) {
      const { engine, authToken } = this.props
      if (!this.props.showLocalTime) {
        if (this.state.query.startTime) {
          this.setState(
            produce((draft: DownloadPacketsModalState) => {
              draft.query.startTime = utcToLocal(this.state.query.startTime).toISOString()
            })
          )
        }
        if (this.state.query.endTime) {
          this.setState(
            produce((draft: DownloadPacketsModalState) => {
              draft.query.endTime = utcToLocal(this.state.query.endTime).toISOString()
            })
          )
        }
      }
      postFilterConvert(engine, authToken, this.state.query.filter, true)
        .then((response: ResponseGetFilterConvert) => {
          if (!response.valid) {
            let errorMessage = ""
            if (Array.isArray(response.errorsParsing) && response.errorsParsing.length > 0) {
              errorMessage = `Character ${response.errorsParsing[0].position}: ${response.errorsParsing[0].message}`
            } else if (response.errorConversion) {
              errorMessage = response.errorConversion
            } else {
              errorMessage = "Error in filter expression"
            }
            this.setState({ filterBarError: errorMessage })
          } else {
            this.setState({ filterBarError: "" })
            if (this.state.query.filter) {
              this.props.pushRecentFilterBarExpression(this.state.query.filter)
            }
            const query = cloneDeep(this.state.query)
            query.name = `Downloading ${this.state.fileName}.${this.state.fileExt}`
            localStorage.setItem("downloadPacketsFileExt", this.state.fileExt)
            this.props.onOK(query, this.state.fileName, this.state.fileExt)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  render() {
    const { onCancel, engineCapabilities, recentFilterBarExpressions, showLocalTime } = this.props
    const { fileExt, fileName, filterBarError, packetsEstimate, query, hasFiles } = this.state

    const startTime = query.startTime ? new Date(query.startTime) : undefined
    const endTime = query.endTime ? new Date(query.endTime) : undefined
    const duration =
      query.startTime && query.endTime
        ? formatDurationRange(
            peekFromDate(Date.parse(query.startTime)),
            peekFromDate(Date.parse(query.endTime)),
            3
          )
        : undefined

    const optionsFormat = packetFileFormats.map((format: PacketFileFormat) => {
      return (
        <option key={format.fileExt} value={format.fileExt}>
          {`${format.fileDescription} (*.${format.fileExt})`}
        </option>
      )
    })

    return (
      <Modal size="md" isOpen={true} toggle={onCancel}>
        <ModalHeader toggle={onCancel}>Download Packets</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="name">File Name</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.setState({ fileName: event.target.value })
                    }
                    value={fileName}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="format">File Format</Label>
                  <Select
                    name="format"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.setState({ fileExt: event.target.value })
                    }
                    value={fileExt}
                  >
                    {optionsFormat}
                  </Select>
                </FormGroup>
                <FormGroup>
                  <FormGroup>
                    <Label for="start-date">Start&nbsp;time{showLocalTime ? "" : " (UTC)"}</Label>
                    <DateTimePicker
                      id="start-date"
                      selected={startTime ? startTime : new Date()}
                      onChange={this.onChangeStartTime}
                      showLocalTime={showLocalTime}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="end-date">End time{showLocalTime ? "" : " (UTC)"}</Label>
                    <DateTimePicker
                      id="end-date"
                      selected={endTime ? endTime : new Date()}
                      onChange={this.onChangeEndTime}
                      showLocalTime={showLocalTime}
                    />
                  </FormGroup>
                  {duration && (
                    <FormGroup>
                      <PropTable>
                        <tbody>
                          <tr>
                            <th>Duration</th>
                            <td>{duration}</td>
                          </tr>
                          <tr>
                            <th>Packets</th>
                            <td>{packetsEstimate}</td>
                          </tr>
                          {this.props.file && (
                            <tr>
                              <th>Files</th>
                              <td>1</td>
                            </tr>
                          )}
                        </tbody>
                      </PropTable>
                    </FormGroup>
                  )}
                </FormGroup>
                <FormGroup noMargin={hasFiles === true}>
                  <Label for="filterbar">Filter</Label>
                  <FilterBar
                    engineCapabilities={engineCapabilities}
                    filterBarExpression={query.filter ? query.filter : ""}
                    filterBarError={filterBarError}
                    recentFilterBarExpressions={recentFilterBarExpressions}
                    onChange={this.onChangeFilter}
                  />
                </FormGroup>
                {hasFiles === false && (
                  <FormGroup noMargin>
                    <ErrorText>
                      There may not be any packet files within the start and end time window
                    </ErrorText>
                  </FormGroup>
                )}
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton onClick={this.onOK}>Download</PrimaryButton>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = (state: any) => ({
  authToken: getAuthToken(state),
  engine: getEngine(state),
  engineCapabilities: getCapabilities(state),
  recentFilterBarExpressions: getRecentFilterBarExpressions(state),
  showLocalTime: getShowLocalTime(state),
})

const mapDisptachToProps = {
  pushRecentFilterBarExpression,
}

export default connect(mapStateToProps, mapDisptachToProps)(DownloadPacketsModal)
