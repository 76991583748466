import * as React from "react"
import Cookies from "js-cookie"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { Col, Form, FormGroup } from "reactstrap"
import { Alert } from "../common/Alert"
import { Input } from "../common/Input"
import { ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import { MutedText } from "../common/MutedText"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import UpgradeBar from "../UpgradeBar"
import { View, ViewContentContainer } from "../common/View"
import {
  getAuthError,
  getAuthInProgress,
  getTempAuthToken,
  getTwoFactorAuth,
  getServerCustomSettings,
  AppDispatch,
} from "../../store"
import { logIn, logInCertificate, setAuthToken } from "../../store/auth"
import { decrypt } from "../../utils/crypto"

const TwoFactorConfigView = React.lazy(() => import("../TwoFactorConfigView"))

const LoginContentContainer = styled(ViewContentContainer)`
  @media (min-width: 576px) {
    max-width: 465px;
  }
`

const LoginModalFooter = styled(ModalFooter)`
  flex-wrap: nowrap;
  & button {
    width: 100%;
  }
`

const LoginAlert = styled(Alert)`
  margin-top: 1rem;
  margin-bottom: 0;
  text-align: center;
`

const HR = styled.hr`
  overflow: visible;
  width: 100%;
  margin: 2rem 0 2rem 0;
  padding: 0;
  border: 0;
  height: 1px;
  background-color: ${props => props.theme.textMutedColor};
  color: ${props => props.theme.textMutedColor};
  text-align: center;

  &:after {
    content: "OR";
    display: inline-block;
    position: relative;
    top: -0.7rem;
    padding: 0 0.25rem;
    background: ${props => props.theme.modalFooterBackgroundColor};
    font-size: 80%;
  }
`

type EngineLoginProps = {
  engine: string
  engineName: string
  engineHost: string
  username: string
  password: string
}

const EngineLogin = ({
  engine,
  engineName,
  engineHost,
  username: defaultUsername,
  password: defaultPassword,
}: EngineLoginProps) => {
  const dispatch: AppDispatch = useDispatch()
  const authInProgress = useSelector(getAuthInProgress)
  const authError = useSelector(getAuthError)
  const tempAuthToken = useSelector(getTempAuthToken)
  const twoFactorAuth = useSelector(getTwoFactorAuth)
  const serverCustomSettings = useSelector(getServerCustomSettings)
  const usernameInput = React.useRef<HTMLInputElement | null>(null)
  const passwordInput = React.useRef<HTMLInputElement | null>(null)
  const otpInput = React.useRef<HTMLInputElement | null>(null)
  const [usernameChanges, setUsernameChanges] = React.useState(0)
  const [passwordChanges, setPasswordChanges] = React.useState(0)

  const username = usernameInput.current?.value
  const password = passwordInput.current?.value
  const otp = twoFactorAuth && otpInput.current ? otpInput.current.value : undefined

  React.useEffect(() => {
    if (usernameChanges === 1) {
      if (passwordInput.current) {
        passwordInput.current.value = ""
      }
    }
  }, [usernameChanges])

  const onLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (username && password) {
      if (username === defaultUsername && password === defaultPassword) {
        decrypt(username, password)
          .then(password => {
            dispatch(logIn(engine, { username, password, otp }))
          })
          .catch(error => {
            console.error("Error decrypting password", error)
          })
      } else {
        dispatch(logIn(engine, { username, password, otp }))
      }
    }
  }

  const onLoginCertificate = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch(logInCertificate(engine))
  }

  const onConfigVerify = (authToken: string, refreshToken?: string, tokenType?: string) => {
    dispatch(setAuthToken(authToken))
  }

  const onCancel = () => {
    dispatch(setAuthToken(null))
    if (otpInput.current) {
      otpInput.current.value = ""
    }
  }

  if (authError != null && authError.startsWith("{")) {
    try {
      const error = JSON.parse(authError)
      const { engineType, engineVersion } = error
      if (engineType != null && engineVersion != null) {
        return (
          <View padding={false}>
            {serverCustomSettings !== 2 ? (
              <UpgradeBar engineType={engineType} engineVersion={engineVersion} />
            ) : null}
            <Alert
              color="danger"
              fade={false}
              className="text-center"
              style={{ margin: "1rem auto" }}
            >
              <p style={{ fontSize: "14px" }}>
                <FontAwesome name="exclamation-circle" size="lg" fixedWidth />
                An upgrade is required to connect to this engine
                <br />
                with the multi-engine feature
              </p>
              <p>
                {`This engine is version ${engineVersion}, but must be at least version ${
                  import.meta.env.VITE_MINIMUM_API_VERSION
                }`}
              </p>
              <p style={{ marginBottom: 0 }}>The engine can still be accessed directly</p>
            </Alert>
          </View>
        )
      }
    } catch {}
  }

  if (tempAuthToken) {
    return (
      <React.Suspense fallback={<div />}>
        <TwoFactorConfigView
          engine={engine}
          tempAuthToken={tempAuthToken}
          onVerify={onConfigVerify}
          onCancel={onCancel}
        />
      </React.Suspense>
    )
  }

  return (
    <View padding={false}>
      <LoginContentContainer>
        <ModalHeader>Login</ModalHeader>
        <ModalBody>
          <Form id="engine-login" onSubmit={onLogin}>
            <FormGroup row>
              <Col>
                <h6 style={{ marginBottom: 0, fontSize: "14px" }}>{engineName}</h6>
                <MutedText as="small">{engineHost}</MutedText>
              </Col>
            </FormGroup>
            <FormGroup row hidden={twoFactorAuth}>
              <Col>
                <Input
                  innerRef={usernameInput}
                  type="text"
                  autoFocus
                  id="engine-login--username"
                  name="engine-login--username"
                  aria-label="Username"
                  autoComplete="engine-login--username"
                  placeholder="Username"
                  defaultValue={defaultUsername}
                  onChange={() => {
                    setUsernameChanges(usernameChanges + 1)
                  }}
                  hidden={twoFactorAuth}
                  disabled={authInProgress}
                />
              </Col>
            </FormGroup>
            <FormGroup row hidden={twoFactorAuth} noMargin>
              <Col>
                <Input
                  innerRef={passwordInput}
                  type="password"
                  id="engine-login--password"
                  name="engine-login--password"
                  aria-label="Password"
                  autoComplete="engine-login--password"
                  placeholder="Password"
                  defaultValue={defaultPassword}
                  onChange={() => {
                    setPasswordChanges(passwordChanges + 1)
                  }}
                  hidden={twoFactorAuth}
                  disabled={authInProgress}
                />
              </Col>
            </FormGroup>
            <FormGroup row hidden={!twoFactorAuth}>
              <Col>
                Enter the code from your two-factor app.
                <br />
                If you've lost your device, you may enter one of your recovery codes.
              </Col>
            </FormGroup>
            <FormGroup row hidden={!twoFactorAuth} noMargin>
              <Col>
                <Input
                  innerRef={otpInput}
                  type="text"
                  id="engine-login--otp"
                  name="engine-login--otp"
                  aria-label="Two-factor code"
                  autoComplete="one-time-code"
                  placeholder="Two-factor code"
                  inputMode="numeric"
                  pattern="[0-9]{6}"
                  onChange={() => {
                    setPasswordChanges(passwordChanges + 1)
                  }}
                  hidden={!twoFactorAuth}
                  disabled={authInProgress}
                />
              </Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <LoginModalFooter>
          {twoFactorAuth ? (
            <>
              <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
              <PrimaryButton type="submit" form="engine-login" disabled={!otp || otp.length < 6}>
                <FontAwesome name={authInProgress ? "circle-o-notch" : "blank"} fixedWidth spin />{" "}
                Verify <FontAwesome name="blank" fixedWidth />
              </PrimaryButton>
            </>
          ) : (
            <>
              <PrimaryButton
                type="submit"
                form="engine-login"
                disabled={authInProgress || !username || !password}
              >
                <FontAwesome name={authInProgress ? "circle-o-notch" : "blank"} fixedWidth spin />{" "}
                Login <FontAwesome name="blank" fixedWidth />
              </PrimaryButton>
            </>
          )}
        </LoginModalFooter>
        {!twoFactorAuth && Cookies.get("opt-authentication") === "certificate" && (
          <>
            <LoginModalFooter style={{ padding: 0 }}>
              <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <HR />
                <PrimaryButton onClick={onLoginCertificate} disabled={authInProgress}>
                  <FontAwesome name={authInProgress ? "circle-o-notch" : "blank"} fixedWidth spin />{" "}
                  Login with Certificate <FontAwesome name="blank" fixedWidth />
                </PrimaryButton>
              </div>
            </LoginModalFooter>
          </>
        )}
        {authError != null && <LoginAlert color="danger">{authError}</LoginAlert>}
        {password === "admin" && username === "admin" && (
          <LoginAlert color="danger" className="text-center">
            Warning: You are using the default admin credentials for this engine please change it in
            LiveAdmin
          </LoginAlert>
        )}
      </LoginContentContainer>
    </View>
  )
}

export default EngineLogin
