import * as React from "react"
import styled from "styled-components"
import { SketchPicker, ColorResult } from "react-color"

const Swatch = styled.div`
  background-color: ${props => props.theme.inputBackgroundColor};
  border: 1px solid ${props => props.theme.inputBorderColor};
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  padding: 4px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(28, 111, 236, 0.25);
    background-color: ${props => props.theme.inputBackgroundColor};
    border-color: ${props => props.theme.inputFocusBorderColor};
  }
`

const Color = styled.div<{ color: string }>`
  background: ${props => props.color};
  border-radius: 2px;
  height: 1.5rem;
  width: 2rem;
`

const Popover = styled.div`
  position: absolute;
  z-index: 2;
  top: calc(1.5rem + 10px);
`

const PopoverCover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

type ColorPickerProps = {
  id?: string
  color: string
  onChange: (color: string) => void
}

type ColorPickerState = {
  displayColorPicker: boolean
}

export class ColorPicker extends React.Component<ColorPickerProps, ColorPickerState> {
  state: ColorPickerState = {
    displayColorPicker: false,
  }

  presetColors = [
    "#FF0000",
    "#FF6A00",
    "#FFD800",
    "#B6FF00",
    "#4CFF00",
    "#00FF21",
    "#00FF90",
    "#00FFFF",
    "#0094FF",
    "#0026FF",
    "#4800FF",
    "#B200FF",
    "#FF00DC",
    "#FF006E",
    "#000000",
    "#FFFFFF",
    "#7F0000",
    "#7F3300",
    "#7F6A00",
    "#5B7F00",
    "#267F00",
    "#007F0E",
    "#007F46",
    "#007F7F",
    "#004A7F",
    "#00137F",
    "#21007F",
    "#57007F",
    "#7F006E",
    "#7F0037",
    "#303030",
    "#C0C0C0",
    "#FF7F7F",
    "#FFB27F",
    "#FFE97F",
    "#DAFF7F",
    "#A5FF7F",
    "#7FFF8E",
    "#7FFFC5",
    "#7FFFFF",
    "#7FC9FF",
    "#7F92FF",
    "#A17FFF",
    "#D67FFF",
    "#FF7FED",
    "#FF7FB6",
    "#606060",
    "#9B9B9B",
  ]

  onChange = (color: ColorResult) => {
    this.props.onChange(color.hex)
  }

  onClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker })
  }

  onKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event
    if (key === "Enter") {
      this.setState({ displayColorPicker: !this.state.displayColorPicker })
    } else if (key === "Escape") {
      this.setState({ displayColorPicker: false })
    }
  }

  onClose = () => {
    this.setState({ displayColorPicker: false })
  }

  render() {
    return (
      <div style={{ display: "flex", position: "relative" }}>
        <Swatch
          id={this.props.id}
          role="button"
          aria-label="Color picker"
          tabIndex={0}
          onClick={this.onClick}
          onKeyUp={this.onKeyUp}
        >
          <Color color={this.props.color} />
        </Swatch>
        {this.state.displayColorPicker ? (
          <Popover>
            <PopoverCover onClick={this.onClose} />
            <SketchPicker
              color={this.props.color}
              disableAlpha={true}
              onChange={this.onChange}
              presetColors={this.presetColors}
            />
          </Popover>
        ) : null}
      </div>
    )
  }
}

export default ColorPicker
