export function hexCharCodeToValue(charCode: number): number {
  let byte: number = 0
  if (charCode >= 48 && charCode <= 57) {
    // Number.
    byte = charCode - 48
  } else if (charCode >= 65 && charCode <= 70) {
    // Uppercase hex.
    byte = 10 + (charCode - 65)
  } else if (charCode >= 97 && charCode <= 102) {
    // Lowercase hex.
    byte = 10 + (charCode - 97)
  } else {
    // Invalid character.
    return -1
  }
  return byte
}

export function hexStringToByte(str: string, index: number = 0): number {
  if (index < 0 || str.length - index < 2) return -1
  let n = hexCharCodeToValue(str.charCodeAt(index))
  if (n === -1) return -1
  let byte: number = n
  n = hexCharCodeToValue(str.charCodeAt(index + 1))
  if (n === -1) return -1
  byte = (byte << 4) + n
  return byte
}

export function hexDecode(str: string): string {
  let r = ""
  for (let i = 0; i < str.length - 1; i += 2) {
    r += unescape("%" + str.substr(i, 2))
  }
  return r
}

export function hexEncode(str: string): string {
  let r = ""
  let i = 0
  let h
  while (i < str.length) {
    h = str.charCodeAt(i++).toString(16)
    while (h.length < 2) {
      h = "0" + h
    }
    r += h
  }
  return r.toUpperCase()
}

export function hexEncodeByteArray(bytes: number[]): string {
  let r = ""
  for (let i = 0; i < bytes.length; i++) {
    const b = bytes[i]
    if (b > 255) throw Error("Byte value overflow")
    let s = b.toString(16)
    if (s.length === 1) {
      s = "0" + s
    }
    r += s
  }
  return r.toUpperCase()
}

export function hexDecodeByteArray(str: string): number[] {
  const bytes: number[] = []
  for (let i = 0; i < str.length; i += 2) {
    const byte = hexStringToByte(str, i)
    if (byte === -1) throw Error("Invalid data")
    bytes.push(byte)
  }
  return bytes
}

export function hexStringToData(str: string): number[] {
  let bytes: number[] = []
  if (str.length < 2) return bytes

  // skip leading $ or 0x
  let strIndex = 0
  if (str[0] === "$") {
    strIndex++
  } else if (str[0] === "0" && str[1].toLowerCase() === "x") {
    strIndex += 2
  }

  while (strIndex < str.length) {
    const byte = hexStringToByte(str, strIndex)
    if (byte === -1) {
      bytes = []
      break
    }
    bytes.push(byte)
    strIndex += 2

    // skip delimiters
    if (
      str[strIndex] === "-" ||
      str[strIndex] === ":" ||
      str[strIndex] === " " ||
      str[strIndex] === "."
    ) {
      strIndex++
    }
  }

  return bytes
}
