import * as React from "react"
import { connect } from "react-redux"
import { cloneDeep } from "lodash"
import { withTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Legend, Label } from "recharts"
import ChartTooltip from "../../common/ChartTooltip"
import { ChartType } from "../../../api/types/chartTypes"
import { ChartComponents } from "../../../utils/chartComponents"
import {
  FullWidthWidget,
  WidgetHeader,
  WidgetTitle,
  WidgetSubTitle,
  WidgetOptions,
  WidgetBody,
  NoData,
} from "./../Dashboard"
import { IconDropdownToggle } from "../../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../../common/Dropdown"
import {
  chartTypeSupportsSmoothing,
  formatIntegerChartTooltip,
  formatTimeChartTooltipLabel,
  formatTimeChartAxis,
  getChartColor,
} from "../../../utils/chartUtils"
import { formatSampleInterval } from "../../../utils/formatUtils"
import {
  setCallVolumeByCodecWidgetChartType,
  setCallVolumeByCodecWidgetChartInterpolation,
} from "../../../store/ui"
import {
  getCallVolumeByCodecWidgetChartType,
  getCallVolumeByCodecWidgetChartInterpolation,
  getShowLocalTime,
} from "../../../store"

type Props = {
  callVolumeByCodec: any | null
  chartType: ChartType
  chartInterpolation: string
  showLocalTime: boolean
  theme: any
  setCallVolumeByCodecWidgetChartType: (chartType: ChartType) => void
  setCallVolumeByCodecWidgetChartInterpolation: (chartInterpolation: string) => void
}

type State = {
  hoverKey: string | null
}

class CallVolumeByCodecWidget extends React.Component<Props, State> {
  state: State = {
    hoverKey: null,
  }

  onChartType = (e: any) => {
    this.props.setCallVolumeByCodecWidgetChartType(e.target.name)
  }

  onChartSmoothing = () => {
    const chartInterpolation = this.props.chartInterpolation === "linear" ? "monotone" : "linear"
    this.props.setCallVolumeByCodecWidgetChartInterpolation(chartInterpolation)
  }

  onMouseEnterLegend = (e: any) => {
    this.setState({ hoverKey: e.dataKey })
  }

  onMouseLeaveLegend = (e: any) => {
    if (e.dataKey === this.state.hoverKey) {
      this.setState({ hoverKey: null })
    }
  }

  render() {
    const { callVolumeByCodec, theme, chartType, chartInterpolation } = this.props
    const chartComponent = ChartComponents[chartType]
    const Chart = chartComponent.chart
    const Series = chartComponent.series
    let series = null
    const supportsSmoothing = chartTypeSupportsSmoothing(chartType)
    if (callVolumeByCodec && callVolumeByCodec.codecs) {
      const { hoverKey } = this.state
      const seriesProps = cloneDeep(chartComponent.seriesProps)
      if (seriesProps.dot) {
        seriesProps.dot.stroke = theme.panelBackground
      }
      if (seriesProps.activeDot) {
        seriesProps.activeDot.stroke = theme.panelBackground
      }
      if (supportsSmoothing) {
        seriesProps.type = chartInterpolation
      }
      series = callVolumeByCodec.codecs.map((codec: any, i: number) => {
        const color = getChartColor(i)
        const defaultOpacity = !hoverKey || hoverKey === codec
        const opacity = defaultOpacity ? undefined : 0.1
        return (
          <Series
            key={codec}
            dataKey={codec}
            name={codec}
            unit="Calls"
            stroke={color}
            strokeOpacity={opacity}
            fillOpacity={opacity}
            fill={color}
            {...seriesProps}
          />
        )
      })
    }
    return (
      <FullWidthWidget>
        <WidgetHeader>
          <WidgetTitle>
            Call Volume by Codec
            {callVolumeByCodec && (
              <WidgetSubTitle>{`${formatSampleInterval(
                callVolumeByCodec.interval / 1000
              )} Average`}</WidgetSubTitle>
            )}
          </WidgetTitle>
          <WidgetOptions>
            <UncontrolledDropdown>
              <IconDropdownToggle aria-label="Settings">
                <FontAwesome name="gear" style={{ color: theme.textMutedColor }} />
              </IconDropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  name={ChartType.STACKED_COLUMN}
                  active={chartType === ChartType.STACKED_COLUMN}
                  onClick={this.onChartType}
                >
                  Stacked Column
                </DropdownItem>
                <DropdownItem
                  name={ChartType.OVERLAID_SKYLINE}
                  active={chartType === ChartType.OVERLAID_SKYLINE}
                  onClick={this.onChartType}
                >
                  Skyline
                </DropdownItem>
                <DropdownItem
                  name={ChartType.STACKED_SKYLINE}
                  active={chartType === ChartType.STACKED_SKYLINE}
                  onClick={this.onChartType}
                >
                  Stacked Skyline
                </DropdownItem>
                <DropdownItem
                  name={ChartType.AREA}
                  active={chartType === ChartType.AREA}
                  onClick={this.onChartType}
                >
                  Area
                </DropdownItem>
                <DropdownItem
                  name={ChartType.STACKED_AREA}
                  active={chartType === ChartType.STACKED_AREA}
                  onClick={this.onChartType}
                >
                  Stacked Area
                </DropdownItem>
                <DropdownItem
                  name={ChartType.LINE}
                  active={chartType === ChartType.LINE}
                  onClick={this.onChartType}
                >
                  Line
                </DropdownItem>
                <DropdownItem
                  name={ChartType.LINE_POINTS}
                  active={chartType === ChartType.LINE_POINTS}
                  onClick={this.onChartType}
                >
                  Line/Points
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  name="smoothing"
                  active={chartInterpolation !== "linear"}
                  onClick={this.onChartSmoothing}
                  disabled={!supportsSmoothing}
                >
                  Smoothing
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </WidgetOptions>
        </WidgetHeader>
        <WidgetBody minHeight="220px">
          {callVolumeByCodec ? (
            <ResponsiveContainer height={220} width="100%">
              <Chart
                data={callVolumeByCodec.data}
                margin={{ top: 10, right: 32, left: 0, bottom: 0 }}
                {...chartComponent.chartProps}
              >
                <Tooltip
                  isAnimationActive={false}
                  labelFormatter={formatTimeChartTooltipLabel.bind(
                    this,
                    callVolumeByCodec.startTime,
                    callVolumeByCodec.interval,
                    this.props.showLocalTime
                  )}
                  formatter={formatIntegerChartTooltip}
                  content={<ChartTooltip />}
                />
                <CartesianGrid stroke={theme.chartGridColor} vertical={false} />
                <YAxis stroke={theme.textColor}>
                  <Label
                    value="Calls"
                    fill={theme.textColor}
                    style={{ fontWeight: 600 }}
                    position="insideLeft"
                    angle={-90}
                    offset={10}
                    dy={14}
                  />
                </YAxis>
                <XAxis
                  stroke={theme.textColor}
                  tickFormatter={formatTimeChartAxis.bind(
                    this,
                    callVolumeByCodec.startTime,
                    callVolumeByCodec.interval,
                    this.props.showLocalTime
                  )}
                  height={22}
                />
                {series}
                <Legend
                  iconSize={10}
                  onMouseEnter={this.onMouseEnterLegend}
                  onMouseLeave={this.onMouseLeaveLegend}
                />
              </Chart>
            </ResponsiveContainer>
          ) : (
            <NoData>No Data</NoData>
          )}
        </WidgetBody>
      </FullWidthWidget>
    )
  }
}

const mapStateToProps = (state: any) => ({
  chartType: getCallVolumeByCodecWidgetChartType(state) || ChartType.OVERLAID_SKYLINE,
  chartInterpolation: getCallVolumeByCodecWidgetChartInterpolation(state) || "linear",
  showLocalTime: getShowLocalTime(state),
})

const mapDisptachToProps = {
  setCallVolumeByCodecWidgetChartType,
  setCallVolumeByCodecWidgetChartInterpolation,
}

export default withTheme(connect(mapStateToProps, mapDisptachToProps)(CallVolumeByCodecWidget))
