import { produce } from "immer"

// Action types
export const types = {
  SET_SELECT_PACKETS_TASK: "SELECT_PACKETS_TASK/SET",
}

// Initial state
export const initialState = {
  selectPacketsTask: null,
}

// Reducer
export default produce((state: any = initialState, action: any) => {
  switch (action.type) {
    case types.SET_SELECT_PACKETS_TASK:
      state.selectPacketsTask = action.payload
      break
    default:
      break
  }

  return state
})

// Actions

export const setSelectPacketsTask = (task: any) => ({
  type: types.SET_SELECT_PACKETS_TASK,
  payload: task,
})

// Selectors

export const getSelectPacketsTask = (state: any) => state.selectPacketsTask
