import { useSelector } from "react-redux"
import { useRouteMatch } from "react-router-dom"
import { cloneDeep } from "lodash"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { Nav, NavItem, NavItemLink } from "../common/Nav"
import { getEngineCustomSettings, getCapabilities } from "../../store"
import { ResponseGetEngineCapabilities } from "../../api/types"
import { PluginCategory } from "../../api/types/peekTypes"
import { hasAdminViews } from "../../utils/engineUtils"

const NavWrapper = styled.div`
  color: ${props => props.theme.engineNavColor};
  background-color: ${props => props.theme.engineNavBackgroundColor};
`

type NavItemInfo = {
  title: string
  url: string
  icon?: string
}

const defaultNavItems: NavItemInfo[] = [
  {
    title: "Home",
    url: "home",
    icon: "home",
  },
  {
    title: "Captures",
    url: "captures",
  },
  {
    title: "Forensics",
    url: "forensics",
  },
  {
    title: "Files",
    url: "files",
  },
  {
    title: "Forensic Searches",
    url: "forensic-searches",
  },
  {
    title: "Events",
    url: "events",
  },
  {
    title: "Adapters",
    url: "adapters",
  },
  {
    title: "Settings",
    url: "settings",
  },
  {
    title: "Admin",
    url: "admin",
  },
]

const EngineNav = () => {
  const match = useRouteMatch()
  const engineCustomSettings = useSelector(getEngineCustomSettings)
  const engineCapabilities: ResponseGetEngineCapabilities = useSelector(getCapabilities)

  let items = cloneDeep(defaultNavItems)
  if (!hasAdminViews(engineCapabilities)) {
    items = items.filter(item => item.url !== "admin")
  }
  if (engineCustomSettings === 2) {
    items = items.filter(item => item.url !== "forensics") // TT36826
  }
  if (engineCapabilities && Array.isArray(engineCapabilities.pluginsInfo)) {
    engineCapabilities.pluginsInfo.forEach(info => {
      if (
        Array.isArray(info.categoryIds) &&
        info.categoryIds.includes(PluginCategory.PLUGIN_CATEGORY_OMNI_WEB_PLUGIN_ENGINE)
      ) {
        items.push({
          title: info.name,
          url: `plugin/${info.clsid}`,
        })
      }
    })
  }

  const navItems = items.map(item => {
    const url = `${match.url}/${item.url}`
    return (
      <NavItem key={url}>
        <NavItemLink to={url}>
          {item.icon ? (
            <>
              <FontAwesome name={item.icon} /> {item.title}
            </>
          ) : (
            item.title
          )}
        </NavItemLink>
      </NavItem>
    )
  })

  return (
    <NavWrapper>
      <Nav>{navItems}</Nav>
    </NavWrapper>
  )
}

export default EngineNav
