import * as React from "react"
import { extent } from "d3-array"

type SparklineProps = {
  className?: string
  style?: React.CSSProperties
  width: number
  height: number
  data: number[]
  type: "line" | "step"
  strokeStyle?: string
  fillStyle?: string
}

export const SparklineSVG: React.FC<SparklineProps> = ({
  className,
  style,
  width,
  height,
  data,
  type,
  strokeStyle = "rgb(35,98,173)",
  fillStyle = "rgba(35,98,173,0.25)",
}) => {
  if (data.length === 0) return null
  const [min, max] = extent(data)
  if (min === undefined || max === undefined) return null
  const padding = 2
  const baseY = min < 0 ? height / 2 : height - padding
  const xScale = (width - padding * 2) / data.length
  const yScale = max > 0 ? baseY / max : 0

  let linePoints = ""
  if (type === "line") {
    for (let i = 0, len = data.length; i < len; i++) {
      const x = i * xScale + padding
      const y = baseY - data[i] * yScale
      linePoints += x + " " + y + " "
    }
  } else if (type === "step") {
    for (let i = 0, len = data.length; i < len; i++) {
      const x1 = i * xScale + padding
      const x2 = x1 + xScale
      const y1 = baseY - data[i] * yScale
      const y2 = y1
      linePoints += x1 + " " + y1 + " " + x2 + " " + y2 + " "
    }
  }

  const fillPoints =
    linePoints +
    (width - padding) +
    " " +
    (height - padding) +
    " " +
    padding +
    " " +
    (height - padding) +
    " " +
    padding +
    " " +
    (baseY - data[0] * yScale)

  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g>
        <polyline points={fillPoints} stroke="none" fill={fillStyle} />
        <polyline points={linePoints} stroke={strokeStyle} fill="none" />
      </g>
    </svg>
  )
}

/*
export const SparklineCanvas: React.FC<SparklineProps> = ({
  className,
  style,
  width,
  height,
  data,
  type,
  strokeStyle,
  fillStyle,
}) => {
  const ref = React.useRef<HTMLCanvasElement | null>(null)

  React.useEffect(() => {
    const [min, max] = extent(data)
    if (min !== undefined && max !== undefined) {
      if (ref && ref.current) {
        const canvas = ref.current
        const ctx = canvas.getContext("2d")
        if (ctx) {
          const dpr = window.devicePixelRatio || 1
          canvas.width = width * dpr
          canvas.height = height * dpr
          canvas.style.width = width + "px"
          canvas.style.height = height + "px"
          ctx.scale(dpr, dpr)

          const padding = 2
          const baseY = min < 0 ? height / 2 : height - padding
          const xScale = (width - padding * 2) / data.length
          const yScale = baseY / max

          ctx.strokeStyle = strokeStyle || "rgb(35,98,173)"
          ctx.fillStyle = fillStyle || "rgb(35,98,173,0.25)"

          ctx.lineCap = "butt"
          ctx.lineWidth = 1

          if (type === "line") {
            ctx.beginPath()
            ctx.moveTo(padding, baseY)
            for (let i = 0, len = data.length; i < len; i++) {
              const x = i * xScale + padding
              const y = baseY - data[i] * yScale
              ctx.lineTo(x, y)
            }
            ctx.lineTo(width - padding, baseY)
            ctx.fill()
            ctx.stroke()
          } else if (type === "step") {
            let x1 = 0
            let x2 = 0
            let y1 = 0
            let y2 = 0
            ctx.beginPath()
            ctx.moveTo(padding, baseY)
            for (let i = 0, len = data.length; i < len; i++) {
              x1 = i * xScale + padding
              x2 = x1 + xScale

              if (min < 0) {
                y1 =
                  data[i] === 0
                    ? baseY
                    : baseY - Math.abs(data[i]) * yScale * (Math.abs(data[i]) / data[i])
                y2 =
                  data[i + 1] === 0
                    ? baseY
                    : baseY - Math.abs(data[i + 1]) * yScale * (Math.abs(data[i + 1]) / data[i + 1])
              } else {
                y1 = baseY - data[i] * yScale
                y2 = baseY - data[i + 1] * yScale
              }

              if (i === 0) {
                //ctx.moveTo(x1, y1)
              }

              ctx.lineTo(x2, y1)

              if (i < data.length) {
                ctx.lineTo(x2, y2)
              }
            }
            ctx.lineTo(x2, baseY)
            ctx.fill()
            ctx.stroke()
          }
        }
      }
    }
  }, [width, height, data, type, fillStyle, strokeStyle])

  if (data.length === 0) return null

  return <canvas ref={ref} className={className} style={style} width={width} height={height} />
}
*/
