import { generatePath } from "react-router-dom"

// Basename for root router
export const BASENAME = "/omnipeek"

// Use these paths with React Router <Route> path parameter
export const LOGIN_PATH = "/login"
export const LOGOUT_PATH = "/logout"
export const ACTIVATE_PATH = "/activate"
export const ENGINES_PATH = "/engines"
export const DASHBOARDS_PATH = "/dashboards/:id"
export const DISTRIBUTED_FORENSIC_SEARCHES_PATH = "/distributed-forensic-searches"
export const NEW_DISTRIBUTED_FORENSIC_SEARCH_PATH =
  "/distributed-forensic-searches/new-distributed-forensic-search"
export const DISTRIBUTED_FORENSIC_SEARCH_PATH = "/distributed-forensic-searches/:id"
export const ENGINE_CONFIGURATION_SYNC_PATH = "/sync"
export const MULTI_SEGMENT_ANALYSIS_PATH = "/multi-segment-analysis"
export const NEW_MULTI_SEGMENT_ANALYSIS_PATH = "/multi-segment-analysis/new-multi-segment-analysis"
export const MULTI_SEGMENT_ANALYSIS_PROJECT_PATH = "/multi-segment-analysis/:id"
export const MULTI_SEGMENT_ANALYSIS_PROJECT_OPTIONS_PATH = "/multi-segment-analysis/:id/options"
export const APP_SETTINGS_PATH = "/app-settings"
export const CROSS_LAUNCH_PATH = "/forensics"

export const ENGINE_PATH = "/engine"
export const ENGINE_ACTIVATE_PATH = "/engine/activate"
export const ENGINE_CONFIGURE_PATH = "/engine/configure"
export const ENGINE_TOKENS_PATH = "/engine/tokens"
export const ENGINE_HOME_PATH = "/engine/home"
export const ENGINE_CAPTURES_PATH = "/engine/captures"
export const ENGINE_NEW_CAPTURE_PATH = "/engine/captures/new-capture"
export const ENGINE_CAPTURE_PATH = "/engine/captures/:capId"
export const ENGINE_CAPTURE_OPTIONS_PATH = "/engine/captures/:capId/options"
export const ENGINE_FORENSICS_PATH = "/engine/forensics"
export const ENGINE_FORENSICS_CAPTURE_SESSION_PATH = "/engine/forensics/:sessId"
export const ENGINE_FILES_PATH = "/engine/files"
export const ENGINE_FORENSIC_SEARCHES_PATH = "/engine/forensic-searches"
export const ENGINE_FORENSIC_SEARCH_PATH = "/engine/forensic-searches/:id"
export const ENGINE_EVENTS_PATH = "/engine/events"
export const ENGINE_ADAPTERS_PATH = "/engine/adapters"
export const ENGINE_ADAPTER_PATH = "/engine/adapters/:adapterId/:adapterType"
export const ENGINE_ADAPTER_NEW_HARDWARE_PROFILE_PATH =
  "/engine/adapters/:adapterId/:adapterType/hardware/new-profile"
export const ENGINE_ADAPTER_HARDWARE_PROFILE_PATH =
  "/engine/adapters/:adapterId/:adapterType/hardware/:hardwareId"
export const ENGINE_SETTINGS_PATH = "/engine/settings"
export const ENGINE_ADMIN_PATH = "/engine/admin"
export const ENGINE_FILTERS_PATH = "/engine/settings/filters"
export const ENGINE_NEW_FILTER_PATH = "/engine/settings/filters/new-filter"
export const ENGINE_FILTER_PATH = "/engine/settings/filters/:filterId"
export const ENGINE_GRAPHS_PATH = "/engine/settings/graphs"
export const ENGINE_NEW_GRAPH_PATH = "/engine/settings/graphs/new-graph"
export const ENGINE_GRAPH_PATH = "/engine/settings/graphs/:graphId"
export const ENGINE_ALARMS_PATH = "/engine/settings/alarms"
export const ENGINE_NEW_ALARM_PATH = "/engine/settings/alarms/new-alarm"
export const ENGINE_ALARM_PATH = "/engine/settings/alarms/:alarmId"
export const ENGINE_NOTIFICATIONS_PATH = "/engine/settings/notifications"
export const ENGINE_NEW_NOTIFICATION_PATH = "/engine/settings/notifications/new-notification"
export const ENGINE_NOTIFICATION_PATH = "/engine/settings/notifications/:notificationId"
export const ENGINE_PROTOCOL_TRANSLATIONS_PATH = "/engine/settings/protocol-translations"
export const ENGINE_NAME_TABLE_PATH = "/engine/settings/name-table"
export const ENGINE_DECRYPTION_KEYS_PATH = "/engine/settings/decryption-keys"
export const ENGINE_NEW_DECRYPTION_KEYS_PATH =
  "/engine/settings/decryption-keys/new-decryption-keys"
export const ENGINE_DECRYPTION_KEY_PATH = "/engine/settings/decryption-keys/:decryptionKeyId"
export const ENGINE_HARDWARE_PROFILES_PATH = "/engine/settings/hardware-profiles"
export const ENGINE_HARDWARE_PROFILE_PATH = "/engine/settings/hardware-profiles/:hardwareId"
export const ENGINE_NEW_HARDWARE_PROFILE_PATH = "/engine/settings/hardware-profiles/new-profile"
export const ENGINE_ANALYSIS_MODULES_PATH = "/engine/settings/analysis-modules"
export const ENGINE_PLUGIN_PATH = "/engine/plugin/:pluginId"
export const ENGINE_AUDIT_LOG_PATH = "/engine/admin/audit-log"
export const ENGINE_CONNECTED_USERS_PATH = "/engine/admin/connected-users"
export const ENGINE_SUPPORT_PATH = "/engine/admin/support"
export const ENGINE_DIAGNOSTICS_PATH = "/engine/admin/diagnostics"

export const CAPTURE_FORENSIC_SEARCH_BASE_PATH = "/engine/:type(captures|forensic-searches)"
export const CAPTURE_FORENSIC_SEARCH_PATH = "/engine/:type(captures|forensic-searches)/:capId"

// Use these functions to generate a URL for a Link
export const getLoginUrl = () => LOGIN_PATH
export const getLogoutUrl = () => LOGOUT_PATH
export const getActivateUrl = () => ACTIVATE_PATH
export const getEnginesUrl = () => ENGINES_PATH
export const getDashboardUrl = (id: number | string) => generatePath(DASHBOARDS_PATH, { id })
export const getDistributedForensicSearchesUrl = () => DISTRIBUTED_FORENSIC_SEARCHES_PATH
export const getNewDistributedForensicSearchUrl = () => NEW_DISTRIBUTED_FORENSIC_SEARCH_PATH
export const getDistributedForensicSearchUrl = (id: string) =>
  generatePath(DISTRIBUTED_FORENSIC_SEARCH_PATH, { id })
export const getEngineConfigurationSyncUrl = () => ENGINE_CONFIGURATION_SYNC_PATH
export const getMultiSegmentAnalysisUrl = () => MULTI_SEGMENT_ANALYSIS_PATH
export const getNewMultiSegmentAnalysisUrl = () => NEW_MULTI_SEGMENT_ANALYSIS_PATH
export const getMultiSegmentAnalysisProjectUrl = (id: string) =>
  generatePath(MULTI_SEGMENT_ANALYSIS_PROJECT_PATH, { id })
export const getMultiSegmentAnalysisProjectOptionsUrl = (id: string) =>
  generatePath(MULTI_SEGMENT_ANALYSIS_PROJECT_OPTIONS_PATH, { id })

export const getEngineUrl = () => ENGINE_PATH
export const getEngineActivateUrl = () => ENGINE_ACTIVATE_PATH
export const getEngineConfigureUrl = () => ENGINE_CONFIGURE_PATH
export const getEngineTokensUrl = () => ENGINE_TOKENS_PATH
export const getEngineHomeUrl = () => ENGINE_HOME_PATH
export const getEngineCapturesUrl = () => ENGINE_CAPTURES_PATH
export const getEngineNewCaptureUrl = () => ENGINE_NEW_CAPTURE_PATH
export const getEngineCaptureUrl = (capId: string) => generatePath(ENGINE_CAPTURE_PATH, { capId })
export const getEngineCaptureOptionsUrl = (capId: string) =>
  generatePath(ENGINE_CAPTURE_OPTIONS_PATH, { capId })
export const getEngineForensicsUrl = () => ENGINE_FORENSICS_PATH
export const getEngineForensicsCaptureSessionUrl = (sessId: number | string) =>
  generatePath(ENGINE_FORENSICS_CAPTURE_SESSION_PATH, { sessId })
export const getEngineFilesUrl = () => ENGINE_FILES_PATH
export const getEngineForensicSearchesUrl = () => ENGINE_FORENSIC_SEARCHES_PATH
export const getEngineForensicSearchUrl = (id: string) =>
  generatePath(ENGINE_FORENSIC_SEARCH_PATH, { id })
export const getEngineEventsUrl = () => ENGINE_EVENTS_PATH
export const getEngineAdaptersUrl = () => ENGINE_ADAPTERS_PATH
export const getEngineAdapterUrl = (adapterId: string, adapterType: number | string) =>
  generatePath(ENGINE_ADAPTER_PATH, { adapterId, adapterType })
export const getEngineAdapterNewHardwareProfileUrl = (adapterId: string, adapterType: string) =>
  generatePath(ENGINE_ADAPTER_NEW_HARDWARE_PROFILE_PATH, { adapterId, adapterType })
export const getEngineAdapterHardwareProfileUrl = (
  adapterId: string,
  adapterType: string,
  hardwareId: string
) => generatePath(ENGINE_ADAPTER_HARDWARE_PROFILE_PATH, { adapterId, adapterType, hardwareId })
export const getEngineSettingsUrl = () => ENGINE_SETTINGS_PATH
export const getEngineAdminUrl = () => ENGINE_ADMIN_PATH
export const getEngineFiltersUrl = () => ENGINE_FILTERS_PATH
export const getEngineNewFilterUrl = () => ENGINE_NEW_FILTER_PATH
export const getEngineGraphsUrl = () => ENGINE_GRAPHS_PATH
export const getEngineNewGraphUrl = () => ENGINE_NEW_GRAPH_PATH
export const getEngineAlarmsUrl = () => ENGINE_ALARMS_PATH
export const getEngineNewAlarmUrl = () => ENGINE_NEW_ALARM_PATH
export const getEngineNotificationsUrl = () => ENGINE_NOTIFICATIONS_PATH
export const getEngineNewNotificationUrl = () => ENGINE_NEW_NOTIFICATION_PATH
export const getEngineProtocolTranslationsUrl = () => ENGINE_PROTOCOL_TRANSLATIONS_PATH
export const getEngineNameTableUrl = () => ENGINE_NAME_TABLE_PATH
export const getEngineNewDecryptionKeysUrl = () => ENGINE_NEW_DECRYPTION_KEYS_PATH
export const getEngineDecryptionKeyUrl = (decryptionKeyId: string) =>
  generatePath(ENGINE_DECRYPTION_KEY_PATH, { decryptionKeyId })
export const getEngineDecryptionKeysUrl = () => ENGINE_DECRYPTION_KEYS_PATH
export const getEngineHardwareProfilesUrl = () => ENGINE_HARDWARE_PROFILES_PATH
export const getEngineNewHardwareProfileUrl = () => ENGINE_NEW_HARDWARE_PROFILE_PATH
export const getEngineHardwareProfileUrl = (hardwareId: string) =>
  generatePath(ENGINE_HARDWARE_PROFILE_PATH, { hardwareId })
export const getEngineAnalysisModulesUrl = () => ENGINE_ANALYSIS_MODULES_PATH
export const getEnginePluginUrl = (pluginId: string) =>
  generatePath(ENGINE_PLUGIN_PATH, { pluginId })
export const getEngineAuditLogUrl = () => ENGINE_AUDIT_LOG_PATH
export const getEngineConnectedUsersUrl = () => ENGINE_CONNECTED_USERS_PATH
export const getEngineSupportUrl = () => ENGINE_SUPPORT_PATH
export const getEngineDiagnosticsUrl = () => ENGINE_DIAGNOSTICS_PATH

export const getCaptureForensicSearchBaseUrl = (type: string) =>
  generatePath(CAPTURE_FORENSIC_SEARCH_BASE_PATH, { type } as any)
export const getCaptureForensicSearchUrl = (type: string, capId: string) =>
  generatePath(CAPTURE_FORENSIC_SEARCH_PATH, { type, capId } as any)
