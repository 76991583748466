import * as React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import { FormGroup } from "reactstrap"
import { Alert } from "../common/Alert"
import { CheckGroup } from "../common/Form"
import { CenterContent, ControlStrip } from "../common/Layout"
import { Input } from "../common/Input"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import { MutedText } from "../common/MutedText"
import { OutsideLink } from "../common/Link"
import { Popover, PopoverBody } from "../common/Popover"
import { PrimaryButton, SecondaryButton, LightButton } from "../common/Buttons"
import { Spinner } from "../common/Spinner"
import { collator } from "../../utils/sortUtils"
import { getEngine, getAuthToken } from "../../store"
import { fetchDirectoryList, createDirectory, createFile } from "../../api/api"
import { ResponseGetDirectoryList } from "../../api/types"

const FileGroup = styled(FormGroup)`
  display: flex;
  flex-grow: 1;
`

const FileList = styled.div`
  min-height: 221px;
  max-height: 221px;
  overflow: auto;
  padding: 0.5rem;
  background-color: ${props => props.theme.tableBackgroundColor};
  border: 1px solid ${props => props.theme.tableBorderColor};
  flex-grow: 1;
  flex-basis: 50%;

  &:last-child {
    border-left: none;
  }
`

const SelectedFile = styled.div`
  background-color: ${props => props.theme.selectedBackgroundColor};
`

type ChooseFileModalProps = {
  engine: string
  authToken: string
  title?: string
  dir: string | undefined
  defaultDir: string
  showFiles: boolean
  showHiddenFiles: boolean
  showHiddenFilesControl: boolean
  onOK: (path: string) => void
  onCancel: () => void
}

type ChooseFileModalState = {
  dirList: ResponseGetDirectoryList | null
  error: any | null
  file: string | null
  newFile: string | null
  newFolder: string | null
  showHiddenFiles: boolean
}

class ChooseFileModal extends React.Component<ChooseFileModalProps, ChooseFileModalState> {
  state: ChooseFileModalState = {
    dirList: null,
    error: null,
    file: null,
    newFile: null,
    newFolder: null,
    showHiddenFiles: this.props.showHiddenFiles,
  }

  componentDidMount() {
    let defaultDir = this.props.defaultDir
    if (this.props.dir) {
      const path = this.props.dir.match(/(.*)[/\\]/)
      if (Array.isArray(path) && path.length > 0) {
        defaultDir = path[0]
      }
    }
    this.onBrowsePath(defaultDir, this.state.showHiddenFiles)
  }

  createDir = (path: string) => {
    const { engine, authToken } = this.props
    createDirectory(engine, authToken, path)
      .then(() => {
        this.onBrowsePath(path, this.state.showHiddenFiles)
      })
      .catch(error => {
        this.setState({ error })
      })
  }

  createFile = (dir: string, path: string) => {
    const { engine, authToken } = this.props
    createFile(engine, authToken, path)
      .then(() => {
        this.onBrowsePath(dir, this.state.showHiddenFiles)
      })
      .catch(error => {
        this.setState({ error })
      })
  }

  IsOKDisabled = () => {
    if (this.props.showFiles) {
      return this.state.file === null
    } else {
      return false
    }
  }

  onBrowseFile = (file: string) => {
    this.setState({ file })
  }

  onBrowsePath = (path: string, showHiddenFiles: boolean) => {
    const { engine, authToken } = this.props
    this.setState({ file: null })
    fetchDirectoryList(engine, authToken, path, this.props.showFiles, showHiddenFiles)
      .then((dirList: ResponseGetDirectoryList) => {
        dirList.dirs.sort((a, b) => collator.compare(a, b))
        dirList.files.sort((a, b) => collator.compare(a, b))
        this.setState({ dirList, error: null })
      })
      .catch(() => {
        // if the file can't be found, default to the default dir
        fetchDirectoryList(
          engine,
          authToken,
          this.props.defaultDir,
          this.props.showFiles,
          showHiddenFiles
        )
          .then((dirList: ResponseGetDirectoryList) => {
            dirList.dirs.sort((a, b) => collator.compare(a, b))
            dirList.files.sort((a, b) => collator.compare(a, b))
            this.setState({ dirList, error: null })
          })
          .catch(error => {
            this.setState({ error })
          })
      })
  }

  onUp = () => {
    const { dirList } = this.state
    if (dirList && dirList.dir) {
      let path = dirList.dir
      if (!path.endsWith("/") && !path.endsWith("\\")) {
        path += "\\"
      }
      path += "..\\"
      this.onBrowsePath(path, this.state.showHiddenFiles)
    }
  }

  onNewFile = () => {
    this.setState({ newFile: "" })
  }

  onNewFileCancel = () => {
    this.setState({ newFile: null })
  }

  onNewFileOK = () => {
    const { dirList, newFile } = this.state
    if (dirList && dirList.dir) {
      let path = dirList.dir
      if (!path.endsWith("/") && !path.endsWith("\\")) {
        path += "\\"
      }
      path += newFile
      this.createFile(dirList.dir, path)
    }
    this.setState({ newFile: null })
  }

  onNewFolder = () => {
    this.setState({ newFolder: "" })
  }

  onNewFolderCancel = () => {
    this.setState({ newFolder: null })
  }

  onNewFolderOK = () => {
    const { dirList, newFolder } = this.state
    if (dirList && dirList.dir) {
      let path = dirList.dir
      if (!path.endsWith("/") && !path.endsWith("\\")) {
        path += "\\"
      }
      path += newFolder
      this.createDir(path)
    }
    this.setState({ newFolder: null })
  }

  onOK = () => {
    const { dirList, file } = this.state

    let path = ""
    if (this.props.showFiles) {
      if (file !== null && dirList && dirList.dir) {
        path = dirList.dir
        if (!path.endsWith("/") && !path.endsWith("\\")) {
          path += "\\"
        }
        path += file
      }
    } else {
      if (dirList) {
        path = dirList.dir
      }
    }

    this.props.onOK(path)
  }

  onShowHidden = () => {
    const { dirList, showHiddenFiles } = this.state
    this.setState({ showHiddenFiles: !showHiddenFiles })
    if (dirList) {
      this.onBrowsePath(dirList.dir, !showHiddenFiles)
    }
  }

  render() {
    const { title, onCancel, showFiles, showHiddenFilesControl } = this.props
    const { dirList, error: dirListError, file, newFile, newFolder, showHiddenFiles } = this.state

    return (
      <Modal size="lg" isOpen={true} toggle={onCancel}>
        <ModalHeader toggle={onCancel}>{title || "Choose Folder"}</ModalHeader>
        <ModalBody>
          {dirListError && (
            <Alert color="danger">
              {typeof dirListError === "string"
                ? dirListError
                : `${dirListError.code} ${dirListError.reason}`}
            </Alert>
          )}
          <FormGroup>
            <Input
              type="text"
              aria-label="Current selected folder"
              readOnly
              spellCheck={false}
              value={dirList ? (dirList.dir ? dirList.dir : "") : ""}
            />
          </FormGroup>
          <FileGroup>
            <FileList>
              {dirList ? (
                dirList.dirs && dirList.dirs.length > 0 ? (
                  dirList.dirs.map((dir: string) => {
                    const name = dirList.drive ? dir : dir.replace(/^.*[\\/]/, "")
                    return (
                      <div key={dir}>
                        <OutsideLink
                          href="#"
                          role="button"
                          onClick={(event: any) => {
                            event.preventDefault()
                            this.onBrowsePath(dir, showHiddenFiles)
                          }}
                        >
                          {name}
                        </OutsideLink>
                      </div>
                    )
                  })
                ) : (
                  <MutedText>No subdirectories</MutedText>
                )
              ) : (
                <CenterContent>
                  <Spinner size="sm" />
                </CenterContent>
              )}
            </FileList>
            {showFiles && (
              <FileList>
                {dirList ? (
                  dirList.files && dirList.files.length > 0 ? (
                    dirList.files.map((fileEntry: string) => {
                      if (file === fileEntry) {
                        return <SelectedFile key={fileEntry}>{fileEntry}</SelectedFile>
                      } else {
                        return (
                          <div key={fileEntry}>
                            <OutsideLink
                              href="#"
                              role="button"
                              onClick={(event: any) => {
                                event.preventDefault()
                                this.onBrowseFile(fileEntry)
                              }}
                            >
                              {fileEntry}
                            </OutsideLink>
                          </div>
                        )
                      }
                    })
                  ) : (
                    <MutedText>No files</MutedText>
                  )
                ) : (
                  <CenterContent>
                    <Spinner size="sm" />
                  </CenterContent>
                )}
              </FileList>
            )}
          </FileGroup>
          <ControlStrip justify="flex-start">
            <LightButton size="sm" onClick={this.onUp}>
              Up
            </LightButton>
            <LightButton size="sm" id="new-folder" onClick={this.onNewFolder}>
              New Folder
            </LightButton>
            <Popover placement="bottom" isOpen={newFolder != null} target="new-folder" fade={false}>
              <PopoverBody>
                <div style={{ marginBottom: "0.5rem" }}>
                  <Input
                    type="text"
                    style={{ width: "18em" }}
                    spellCheck={false}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      this.setState({ newFolder: event.target.value })
                    }}
                    value={newFolder != null ? newFolder : ""}
                  />
                </div>
                <ControlStrip justify="flex-start">
                  <PrimaryButton size="sm" onClick={this.onNewFolderOK}>
                    New Folder
                  </PrimaryButton>
                  <SecondaryButton size="sm" onClick={this.onNewFolderCancel}>
                    Cancel
                  </SecondaryButton>
                </ControlStrip>
              </PopoverBody>
            </Popover>
            {showFiles && (
              <LightButton size="sm" id="new-file" onClick={this.onNewFile}>
                New File
              </LightButton>
            )}
            {showFiles && (
              <Popover placement="bottom" isOpen={newFile != null} target="new-file">
                <PopoverBody>
                  <div style={{ marginBottom: "0.5rem" }}>
                    <Input
                      type="text"
                      style={{ width: "18em" }}
                      spellCheck={false}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        this.setState({ newFile: event.target.value })
                      }}
                      value={newFile != null ? newFile : ""}
                    />
                  </div>
                  <ControlStrip justify="flex-start">
                    <PrimaryButton size="sm" onClick={this.onNewFileOK}>
                      New File
                    </PrimaryButton>
                    <SecondaryButton size="sm" onClick={this.onNewFileCancel}>
                      Cancel
                    </SecondaryButton>
                  </ControlStrip>
                </PopoverBody>
              </Popover>
            )}
            {showHiddenFilesControl && (
              <ControlStrip justify="flex-end" style={{ flexGrow: 1 }}>
                <CheckGroup
                  type="checkbox"
                  name="showHiddenFiles"
                  id="showHiddenFiles"
                  onChange={(event: any) => this.onShowHidden()}
                  checked={this.state.showHiddenFiles}
                >
                  Show hidden files
                </CheckGroup>
              </ControlStrip>
            )}
          </ControlStrip>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton disabled={this.IsOKDisabled()} onClick={() => this.onOK()}>
            OK
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
})

export default connect(mapStateToProps)(ChooseFileModal)
