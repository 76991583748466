import * as React from "react"
import { BarGauge } from "../components/common/BarGauge"
import { DangerText } from "../components/common/DangerText"
import { IconInformational, IconMinor, IconMajor, IconSevere } from "../components/common/Icons"
import { OutsideLink } from "../components/common/Link"
import { SuccessTextWithBackground } from "../components/common/SuccessText"
import { UncontrolledTooltip } from "../components/common/UncontrolledTooltip"
import {
  formatInteger,
  formatDuration,
  formatFloat,
  formatISODateTime,
  formatLinkSpeed,
  formatMB,
  formatGB,
  formatTimeZone,
  formatForensicSearchResult,
} from "./formatUtils"
import { getMediaString } from "./mediaUtils"
import { PeekCaptureStatus, PeekTriggerState } from "../api/types/peekTypes"
import { PropFormatterOptions } from "../components/common/PropTable"

const dateFormatUTC = Intl.DateTimeFormat(undefined, {
  hourCycle: "h23",
  year: "numeric",
  month: "numeric",
  day: "2-digit",
  timeZone: "UTC",
})

const timeFormatUTC = Intl.DateTimeFormat(undefined, {
  hourCycle: "h23",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
  timeZone: "UTC",
})

export function propToLabel(prop: string) {
  switch (prop) {
    case "name":
      return "Name"
    case "hostName":
      return "Host Name"
    case "engineType":
      return "Engine Type"
    case "version":
    case "fileVersion":
    case "productVersion":
      return "Version"
    case "time":
      return "Engine Local Time"
    case "timeZoneBias":
      return "Time Zone"
    case "uptime":
      return "Uptime"
    case "operatingSystem":
      return "Operating System"
    case "ipmiAddr":
      return "IPMI Address"
    case "memoryTotalPhysical":
      return "Memory"
    case "cpuType":
      return "CPU Type"
    case "cpuCount":
      return "CPU Count"
    case "dataFolder":
      return "Data Folder"
    case "storageUsed":
      return "Capture Storage"
    case "licensed":
      return "Licensed"
    case "address":
      return "Address"
    case "publisher":
      return "Publisher"
    case "type":
      return "Type"
    case "file":
      return "File"
    case "status":
      return "Status"
    case "captureName":
      return "Capture"
    case "adapter":
      return "Adapter"
    case "linkSpeed":
      return "Link Speed"
    case "linkState":
      return "Link State"
    case "mediaType":
    case "mediaSubType":
      return "Media"
    case "packetCount":
      return "Packets"
    case "packetsReceived":
      return "Packets Received"
    case "packetsFiltered":
      return "Packets Filtered"
    case "packetsAnalyzed":
      return "Packets Analyzed"
    case "analysisDroppedPackets":
      return "Analysis Dropped Packets"
    case "packetsDropped":
      return "Packets Dropped"
    case "flowsDropped":
      return "Flows Dropped"
    case "startTime":
      return "Start Time"
    case "endTime":
    case "stopTime":
      return "Stop Time"
    case "runDuration":
      return "Run Time"
    case "duration":
      return "Duration"
    case "dataDuration":
      return "Data Duration"
    case "dataSizeReserved":
      return "Data Size Reserved"
    case "bufferCapacity":
      return "Buffer Size"
    case "alarms":
      return "Alarms"
    case "filtersEnabled":
      return "Filters"
    case "creator":
      return "Owner"
    case "creationTime":
      return "Created"
    case "userName":
      return "User"
    case "modificationBy":
      return "Modified By"
    case "modificationType":
      return "Action"
    case "modificationTime":
      return "Modified"
    case "title":
      return "Title"
    case "hardwareProfileName":
      return "Hardware Profile"
    case "ctdEnabled":
      return "Capture To Disk"
    case "ctdIntelligent":
      return "Intelligent CTD"
    case "indexingEnabled":
      return "Indexing"
    case "description":
      return "Description"
    case "groups":
      return "Groups"
    case "users":
      return "Users"
    case "policies":
      return "Policies"
    case "filter":
      return "Filter"
    case "sessions":
      return "Sessions"
    case "retentionFactor":
      return "Retention Factor"
    case "retentionTime":
      return "Retention Time"
    default:
      break
  }
  return prop
}

export function formatProp(prop: string, data: any, options?: PropFormatterOptions) {
  const value = data[prop]
  switch (prop) {
    case "productVersion":
      if ("fileVersion" in data) {
        return `${value} (build ${data["fileVersion"]})`
      }
      break
    case "address":
      if (value && value.includes(".")) {
        return value.split(":")[0]
      }
      break
    case "time":
      {
        const timeZoneBias = data["timeZoneBias"]
        if (typeof value === "string" && typeof timeZoneBias === "number") {
          let t = Date.parse(value)
          // Adjust for the time zone (OD-1918).
          t = t - timeZoneBias * 60 * 1000
          return `${dateFormatUTC.format(t)} ${timeFormatUTC.format(t)}`
        }
      }
      break
    case "timeZoneBias":
      return formatTimeZone(value)
    case "uptime":
      return formatDuration(value, 0)
    case "ipmiAddr":
      if (value) {
        return (
          <OutsideLink href={`http://${value}`} target="_blank" rel="noopener noreferrer">
            {value}
          </OutsideLink>
        )
      }
      break
    case "memoryTotalPhysical": {
      const totalMB = Math.floor(value / 1048576)
      if ("memoryAvailablePhysical" in data) {
        const availMB = Math.floor(data["memoryAvailablePhysical"] / 1048576)
        return `${formatInteger(totalMB)} MB Total Phys; ${formatInteger(availMB)} MB Avail Phys`
      } else {
        return `${formatInteger(totalMB)} MB Total Phys`
      }
    }
    case "licensed":
      if (value === 0) {
        return "No"
      } else {
        return null
      }
    case "status":
      if (value !== undefined) {
        if (data.openResult === undefined) {
          if (data.errorCondition !== undefined && data.errorCondition !== 0) {
            if (data.errorConditionText) {
              return <DangerText>{`Error: ${data.errorConditionText}`}</DangerText>
            } else {
              const errorCode = data.errorCondition >>> 0
              return <DangerText>{`Error: ${errorCode.toString(16)}`}</DangerText>
            }
          } else {
            let status: React.ReactNode = ""
            if (
              value === PeekCaptureStatus.peekCaptureStatusIdle ||
              value === PeekCaptureStatus.peekCaptureStatusIdleStartActive ||
              value === PeekCaptureStatus.peekCaptureStatusIdleStopActive ||
              value === PeekCaptureStatus.peekCaptureStatusIdleStartActiveStopActive
            ) {
              status = "Idle"
            } else if (
              value === PeekCaptureStatus.peekCaptureStatusWaitStart ||
              value === PeekCaptureStatus.peekCaptureStatusWaitStartStopActive
            ) {
              status = "Waiting for Start Trigger"
            } else if (
              value === PeekCaptureStatus.peekCaptureStatusCapturing ||
              value === PeekCaptureStatus.peekCaptureStatusCapturingStopActive
            ) {
              status = <SuccessTextWithBackground>Capturing</SuccessTextWithBackground>
            }
            if (value & PeekTriggerState.PEEK_TRIGGER_STATE_STOP_ACTIVE) {
              status = (
                <>
                  <span>{status}</span>
                  <span> (Stop Trigger Active)</span>
                </>
              )
            }
            return status
          }
        } else {
          let status: React.ReactNode = ""
          if (value === 0) {
            status = "Closed"
          } else if (value === 1) {
            status = "Opening"
            if (data.loadPercentProgress !== undefined) {
              status = (
                <BarGauge
                  aria-label="Progress"
                  value={data.loadPercentProgress}
                  max={100}
                  title={`${formatInteger(data.loadPercentProgress)}%`}
                  style={{ minWidth: "100px" }}
                />
              )
            }
          } else if (value === 2) {
            status = "Complete"
            if (data.openResult !== undefined) {
              const openResult = data.openResult >>> 0
              if (openResult !== 0) {
                status = <DangerText>{formatForensicSearchResult(openResult)}</DangerText>
              }
            }
          }
          return status
        }
      }
      break
    case "linkSpeed":
      return formatLinkSpeed(value)
    case "mediaType":
      if ("mediaSubType" in data) {
        let mt = value
        let mst = data["mediaSubType"]
        mt = typeof mt === "string" ? parseInt(mt, 10) : mt
        mst = typeof mst === "string" ? parseInt(mst, 10) : mst
        return getMediaString(mt, mst)
      }
      break
    case "bufferCapacity":
      return formatMB(value)
    case "dataSizeReserved":
      return formatGB(value, 0)
    case "packetCount":
    case "packetsReceived":
    case "packetsFiltered":
    case "packetsAnalyzed":
      return formatInteger(value)
    case "analysisDroppedPackets":
    case "packetsDropped":
    case "flowsDropped":
      if (typeof value === "number" && value > 0) {
        return <DangerText>{formatInteger(value)}</DangerText>
      } else {
        return formatInteger(value)
      }
    case "startTime":
    case "endTime":
    case "stopTime":
    case "creationTime":
    case "modificationTime":
      return formatISODateTime(value, 0, options?.showLocalTime)
    case "duration":
    case "dataDuration":
      return formatDuration(value, 0)
    case "retentionTime":
    case "runDuration":
      if (value) {
        return formatDuration(value, 0)
      }
      return null
    case "alarms": {
      const alarms = []
      if (data.alarmsInfo > 0) {
        const id = `alarms-info-${data.id}`
        alarms.push(<IconInformational key="alarms-info" id={id} />)
        alarms.push(
          <UncontrolledTooltip key="alarms-info-tooltip" placement="bottom" target={id}>
            {formatInteger(data.alarmsInfo)}
          </UncontrolledTooltip>
        )
      }
      if (data.alarmsMinor > 0) {
        const id = `alarms-minor-${data.id}`
        alarms.push(<IconMinor key="alarms-minor" id={id} />)
        alarms.push(
          <UncontrolledTooltip key="alarms-minor-tooltip" placement="bottom" target={id}>
            {formatInteger(data.alarmsMinor)}
          </UncontrolledTooltip>
        )
      }
      if (data.alarmsMajor > 0) {
        const id = `alarms-major-${data.id}`
        alarms.push(<IconMajor key="alarms-major" id={id} />)
        alarms.push(
          <UncontrolledTooltip key="alarms-major-tooltip" placement="bottom" target={id}>
            {formatInteger(data.alarmsMajor)}
          </UncontrolledTooltip>
        )
      }
      if (data.alarmsSevere > 0) {
        const id = `alarms-severe-${data.id}`
        alarms.push(<IconSevere key="alarms-severe" id={id} />)
        alarms.push(
          <UncontrolledTooltip key="alarms-severe-tooltip" placement="bottom" target={id}>
            {formatInteger(data.alarmsSevere)}
          </UncontrolledTooltip>
        )
      }
      if (alarms.length > 0) {
        return alarms
      }
      break
    }
    case "ctdEnabled":
    case "ctdIntelligent":
    case "indexingEnabled":
      if (value !== undefined) {
        return value ? "Yes" : "No"
      }
      return null
    case "filtersEnabled":
      return data.filtersEnabled > 0 ? data.filtersEnabled : ""
    case "retentionFactor":
      if (value) {
        return `${formatFloat(value, 1)}\u00d7`
      }
      return null
    default:
      break
  }

  return value
}
