import * as React from "react"
import FontAwesome from "react-fontawesome"
import { connect } from "react-redux"
import { withRouter, RouteComponentProps } from "react-router-dom"
import styled from "styled-components"
import ForensicSearchModal from "../ForensicSearchModal"
import { ForensicSearchHistorySearch } from "./ForensicSearchHistorySearchTypes"
import ForensicSearchHistorySearchPopover from "./ForensicSearchHistorySearchPopover"
import ForensicSearchHistorySearchResults from "./ForensicSearchHistorySearchResults"
import { Alert } from "../common/Alert"
import {
  IconButton,
  LightButton,
  LightDangerButton,
  LightDropdownToggle,
  SecondaryButton,
  SecondaryDangerButton,
} from "../common/Buttons"
import ConfirmationModal from "../common/ConfirmationModal"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../common/Dropdown"
import Interval from "../common/Interval"
//import { Panel } from "../common/Panel"
import PropTable from "../common/PropTable"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import {
  View,
  ViewMaxWidth,
  ViewContent,
  ViewHeader,
  ViewHeaderButtons,
  ViewHeaderItems,
} from "../common/View"
import {
  createForensicSearch,
  deleteForensicSearchHistoryItem,
  fetchForensicSearchHistory,
  setForensicSearchHistoryItem,
} from "../../api/api"
import {
  ForensicSearchHistoryItemProperties,
  ForensicSearchHistorySortBy,
  RequestCreateForensicSearch,
  RequestPostForensicSearchHistoryItem,
  ResponseCreateForensicSearch,
  ResponseGetEngineCapabilities,
  ResponseGetForensicSearchHistory,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { PeekFilterMode } from "../../api/types/peekTypes"
import { getEngineForensicSearchUrl } from "../../routes"
import {
  getAuthToken,
  getEngine,
  getForensicSearchHistorySearch,
  getForensicSearchHistorySortAsc,
  getForensicSearchHistorySortBy,
  getUserId,
} from "../../store"
import {
  setForensicSearchHistorySearch,
  setForensicSearchHistorySortAsc,
  setForensicSearchHistorySortBy,
} from "../../store/ui"
import { getErrorText } from "../../utils/engineUtils"
import { formatDuration } from "../../utils/formatUtils"
import { CLSID_COMPASS } from "../../utils/pluginUtils"
import { propToLabel, formatProp } from "../../utils/propUtils"

const propList = [
  ["packetCount", "startTime"],
  ["timeWindow", "endTime"],
  ["analysis", "executionTime"],
  ["filter", "owner"],
  ["result", null],
]

const HistoryDiv = styled.nav`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  user-select: none;
`

/*const ForensicSearchHistoryPanel = styled(Panel)`
  display: flex;
  flex-direction: column;

  background-color: ${props => props.theme.forensicSearchHistoryBackgroundColor};

  & + & {
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`*/

const ForensicSearchHistory = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${props => props.theme.panelPadding};
  border: 1px solid ${props => props.theme.tableBorderColor};
  background: ${props => props.theme.panelBackground};
`

const ForensicSearchHistoryButtonStrip = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 0.5rem;

  & button + button {
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    margin-top: 8px;

    & button + button {
      margin-left: 4px;
      margin-top: 0;
    }
  }
`

const ForensicSearchHistoryFooter = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;

  & > :not(:first-child) {
    margin-left: 0.25rem;
  }

  & > :not(:last-child) {
    margin-right: 0.25rem;
  }
`

const ForensicSearchHistoryItemContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`

const ForensicSearchHistoryItemHeader = styled.div`
  flex-grow: 1;
`

const ForensicSearchHistoryItemFavoriteButton = styled(IconButton)`
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`

const ForensicSearchHistoryItemTitle = styled.h5`
  flex-grow: 1;
`

const ForensicSearchHistoryItemTitleBlock = styled.div`
  display: flex;
  flex-direction: row;
`

const ForensicSearchHistoryPropTable = styled.div`
  flex-grow: 1;
`

const ForensicSearchHistoryWrapper = styled.div`
  flex-grow: 1;
  display: flex;
`

const SearchButton = styled(LightButton)`
  flex-grow: 1;
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.tableBorderColor};
`

type ForensicSearchHistoryTableProps = {
  engineCapabilities: ResponseGetEngineCapabilities | null
  forensicSearchHistory: ForensicSearchHistoryItemProperties[]
  showLocalTime: boolean
  userId: string
  onDeleteForensicSearchHistoryItem: (id: number) => void
  onFavoriteForensicSearch: (forensicSearchHistoryItem: ForensicSearchHistoryItemProperties) => void
  onRunForensicSearch: (forensicSearchHistoryItem: ForensicSearchHistoryItemProperties) => void
}

class ForensicSearchHistoryTable extends React.Component<ForensicSearchHistoryTableProps> {
  formatHistoryProp(prop: string, data: ForensicSearchHistoryItemProperties) {
    const { showLocalTime } = this.props
    switch (prop) {
      case "analysis": {
        let hasAnalysis = false
        const hasCompass =
          (data.query.pluginsList !== undefined &&
            data.query.pluginsList.properties.findIndex(
              pobj => pobj.value === `{${CLSID_COMPASS}}`
            ) !== -1) ||
          (data.query.plugins && data.query.pluginsList === undefined)
        if (
          data.query.expert ||
          data.query.files ||
          data.query.passiveNameResolution ||
          data.query.voice ||
          data.query.web ||
          hasCompass ||
          data.query.application ||
          data.query.graphs ||
          data.query.conversation ||
          data.query.country ||
          data.query.error ||
          data.query.history ||
          data.query.log ||
          data.query.mplsvlanvxlan ||
          data.query.network ||
          data.query.node ||
          data.query.plugins ||
          data.query.protocol ||
          data.query.size ||
          data.query.summary ||
          data.query.wirelessChannel ||
          data.query.wirelessNode
        ) {
          hasAnalysis = true
        }
        return hasAnalysis ? "Yes" : "No"
      }
      case "executionTime": {
        let executionTime = "Running"
        if (data.endTime !== undefined) {
          const start = new Date(data.startTime)
          const end = new Date(data.endTime)
          executionTime = formatDuration((end.getTime() - start.getTime()) * 1000000, 0)
        }
        return executionTime
      }
      case "filter": {
        let hasFilter = "No"
        if ((data.query.filter?.length ?? 0) > 0 || (data.query.filters?.length ?? 0) > 0) {
          hasFilter = "Yes"
        }
        return hasFilter
      }
      case "result":
        return getErrorText(data.result)
      case "timeWindow": {
        let timeWindow = ""
        if (data.query.startTime && data.query.endTime) {
          const start = new Date(data.query.startTime)
          const end = new Date(data.query.endTime)
          timeWindow = formatDuration((end.getTime() - start.getTime()) * 1000000, 0)
        }
        return timeWindow
      }
      default:
        return formatProp(prop, data, { showLocalTime })
    }
  }

  historyPropToLabel(prop: string) {
    switch (prop) {
      case "analysis":
        return "Analysis"
      case "endTime":
        return "Finish Time"
      case "executionTime":
        return "Run Time"
      case "filter":
        return "Filter"
      case "result":
        return "Result"
      case "startTime":
        return "Begin Time"
      case "timeWindow":
        return "Duration"
      default:
        return propToLabel(prop)
    }
  }

  render() {
    const {
      engineCapabilities,
      forensicSearchHistory,
      userId,
      onDeleteForensicSearchHistoryItem,
      onFavoriteForensicSearch,
      onRunForensicSearch,
    } = this.props

    if (!engineCapabilities) return null

    const rows = forensicSearchHistory.map(
      (forensicSearchHistoryItem: ForensicSearchHistoryItemProperties) => {
        const isUserOwner = userId === forensicSearchHistoryItem.owner

        // make sure the user can delete forensic search history items
        let canCreateForensicSearch = true
        let canDeleteForensicSearch = true
        if (
          engineCapabilities &&
          engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL)
        ) {
          const policies = engineCapabilities.userRights.policies
          canCreateForensicSearch = policies.includes(EngineUserPolicies.createForensicSearch)
          canDeleteForensicSearch =
            isUserOwner || policies.includes(EngineUserPolicies.deleteForensicSearches)
        }

        return (
          <ForensicSearchHistory key={forensicSearchHistoryItem.id}>
            <ForensicSearchHistoryItemHeader>
              <ForensicSearchHistoryItemTitleBlock>
                <ForensicSearchHistoryItemFavoriteButton
                  aria-label="Favorite"
                  id={`favorite-${forensicSearchHistoryItem.id}`}
                  onClick={onFavoriteForensicSearch.bind(this, forensicSearchHistoryItem)}
                >
                  <FontAwesome
                    name={forensicSearchHistoryItem.favorite ? "star" : "star-o"}
                    fixedWidth
                  />
                </ForensicSearchHistoryItemFavoriteButton>
                <ForensicSearchHistoryItemTitle>
                  {forensicSearchHistoryItem.query.name}
                </ForensicSearchHistoryItemTitle>
              </ForensicSearchHistoryItemTitleBlock>
            </ForensicSearchHistoryItemHeader>
            <ForensicSearchHistoryItemContent>
              <ForensicSearchHistoryPropTable>
                <PropTable
                  propList={propList}
                  data={forensicSearchHistoryItem}
                  propToLabel={this.historyPropToLabel.bind(this)}
                  formatProp={this.formatHistoryProp.bind(this)}
                />
              </ForensicSearchHistoryPropTable>
              <ForensicSearchHistoryButtonStrip>
                <SecondaryButton
                  size="sm"
                  disabled={!canCreateForensicSearch}
                  onClick={onRunForensicSearch.bind(this, forensicSearchHistoryItem)}
                >
                  <FontAwesome name="run" /> Run
                </SecondaryButton>
                <SecondaryDangerButton
                  size="sm"
                  disabled={!canDeleteForensicSearch}
                  onClick={onDeleteForensicSearchHistoryItem.bind(
                    this,
                    forensicSearchHistoryItem.id
                  )}
                >
                  <FontAwesome name="trash-o" /> Delete
                </SecondaryDangerButton>
              </ForensicSearchHistoryButtonStrip>
            </ForensicSearchHistoryItemContent>
          </ForensicSearchHistory>
        )
      }
    )
    return <Table>{rows}</Table>
  }
}

const HISTORY_LIMIT_STEP: number = 10

const emptySearch: ForensicSearchHistorySearch = {
  favorite: null,
  name: "",
  owner: "",
  startTime: "",
  stopTime: "",
}

type ForensicSearchHistoryViewProps = RouteComponentProps & {
  authToken: string
  dispatchFunc: Function
  engine: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  isHistorySearchOpen: boolean
  search: ForensicSearchHistorySearch
  showLocalTime: boolean
  sortAsc: boolean
  sortBy: ForensicSearchHistorySortBy
  userId: string
  onSetHistorySearchOpen: (isHistorySearchOpen: boolean) => void
}

type ForensicSearchHistoryViewState = {
  fetchError: any | null
  forensicSearchHistory: ResponseGetForensicSearchHistory | null
  forensicSearchHistoryItem: ForensicSearchHistoryItemProperties | null
  forensicSearchHistoryItemId: number | null
  historyLimit: number
  refreshInterval: number
  showDeleteAllForensicSearchHistoryConfirm: boolean
  showDeleteForensicSearchHistoryItemConfirm: boolean
  showForensicSearchModal: boolean
}

class ForensicSearchHistoryView extends React.Component<
  ForensicSearchHistoryViewProps,
  ForensicSearchHistoryViewState
> {
  state: ForensicSearchHistoryViewState = {
    fetchError: null,
    forensicSearchHistory: null,
    forensicSearchHistoryItem: null,
    forensicSearchHistoryItemId: null,
    historyLimit: HISTORY_LIMIT_STEP,
    refreshInterval: 5000,
    showDeleteAllForensicSearchHistoryConfirm: false,
    showDeleteForensicSearchHistoryItemConfirm: false,
    showForensicSearchModal: false,
  }

  componentDidMount() {
    this.onRefresh()
  }

  onRefresh = (
    search: ForensicSearchHistorySearch | null = null,
    sortBy: ForensicSearchHistorySortBy | null = null,
    sortAsc: boolean | null = null
  ) => {
    const { engine, authToken } = this.props
    const { historyLimit } = this.state
    const historySearch = search !== null ? search : this.props.search
    const historySortBy = sortBy !== null ? sortBy : this.props.sortBy
    const historySortAsc = sortAsc !== null ? sortAsc : this.props.sortAsc
    fetchForensicSearchHistory(
      engine,
      authToken,
      historyLimit,
      null,
      historySortAsc,
      historySortBy,
      historySearch.favorite,
      historySearch.name.length === 0 ? null : historySearch.name,
      historySearch.owner.length === 0 ? null : historySearch.owner,
      historySearch.startTime.length === 0 ? null : historySearch.startTime,
      historySearch.stopTime.length === 0 ? null : historySearch.stopTime
    )
      .then((forensicSearchHistory: ResponseGetForensicSearchHistory) => {
        this.setState({ forensicSearchHistory })
      })
      .catch(error => {
        this.setState({ fetchError: error })
      })
  }

  onSearch = () => {
    this.props.onSetHistorySearchOpen(!this.props.isHistorySearchOpen)
  }

  onSearchCancel = () => {
    this.props.onSetHistorySearchOpen(false)
  }

  onSearchOK = (search: ForensicSearchHistorySearch) => {
    this.props.dispatchFunc(setForensicSearchHistorySearch(search))
    this.props.onSetHistorySearchOpen(false)
    this.setState({ historyLimit: HISTORY_LIMIT_STEP }, () => this.onRefresh(search))
  }

  onClearSearch = () => {
    this.onSearchOK(emptySearch)
  }

  onDeleteAllForensicSearchHistory = () => {
    this.setState({ showDeleteAllForensicSearchHistoryConfirm: true })
  }

  onDeleteAllForensicSearchHistoryCancel = () => {
    this.setState({ showDeleteAllForensicSearchHistoryConfirm: false })
  }

  onDeleteAllForensicSearchHistoryOK = () => {
    this.setState({ showDeleteAllForensicSearchHistoryConfirm: false })
    const { forensicSearchHistory } = this.state
    if (forensicSearchHistory) {
      const requests = forensicSearchHistory.forensicSearchHistory.map(
        (forensicSearchHistoryItem: ForensicSearchHistoryItemProperties) => {
          const { engine, authToken } = this.props
          return deleteForensicSearchHistoryItem(engine, authToken, forensicSearchHistoryItem.id)
        }
      )
      Promise.all(requests)
        .then(() => {
          this.setState({ historyLimit: HISTORY_LIMIT_STEP }, () => this.onRefresh())
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  onDeleteForensicSearchHistoryItem = (forensicSearchHistoryItemId: number) => {
    this.setState({ showDeleteForensicSearchHistoryItemConfirm: true, forensicSearchHistoryItemId })
  }

  onDeleteForensicSearchHistoryItemCancel = () => {
    this.setState({
      showDeleteForensicSearchHistoryItemConfirm: false,
      forensicSearchHistoryItemId: null,
    })
  }

  onDeleteForensicSearchHistoryItemOK = () => {
    const { forensicSearchHistoryItemId } = this.state
    this.setState({
      showDeleteForensicSearchHistoryItemConfirm: false,
      forensicSearchHistoryItemId: null,
    })
    if (forensicSearchHistoryItemId !== null) {
      const { engine, authToken } = this.props
      deleteForensicSearchHistoryItem(engine, authToken, forensicSearchHistoryItemId)
        .then(() => {
          this.onRefresh()
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  onFavoriteForensicSearch = (forensicSearchHistoryItem: ForensicSearchHistoryItemProperties) => {
    const { engine, authToken } = this.props
    const request: RequestPostForensicSearchHistoryItem = {
      favorite: !forensicSearchHistoryItem.favorite,
    }
    setForensicSearchHistoryItem(engine, authToken, forensicSearchHistoryItem.id, request)
      .then(() => {
        this.onRefresh()
      })
      .catch(error => {
        console.error(error)
      })
  }

  onForensicSearchCancel = () => {
    this.setState({ showForensicSearchModal: false, forensicSearchHistoryItem: null })
  }

  onForensicSearchOK = (query: RequestCreateForensicSearch) => {
    this.setState({ showForensicSearchModal: false, forensicSearchHistoryItem: null })
    query.filterMode = query.filter
      ? PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_MATCHING_ANY
      : PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_ALL

    const { engine, authToken } = this.props
    createForensicSearch(engine, authToken, query)
      .then((response: ResponseCreateForensicSearch) => {
        this.props.history.push(getEngineForensicSearchUrl(response.id))
      })
      .catch(error => {
        console.error(error)
        this.setState({ fetchError: "Failed to create forensic search" })
      })
  }

  onRunForensicSearch = (forensicSearchHistoryItem: ForensicSearchHistoryItemProperties) => {
    this.setState({ showForensicSearchModal: true, forensicSearchHistoryItem })
  }

  onMore = () => {
    const { forensicSearchHistory, historyLimit } = this.state
    if (
      forensicSearchHistory &&
      forensicSearchHistory.forensicSearchHistory.length < forensicSearchHistory.counts.total
    ) {
      const remainingHistoryItems =
        forensicSearchHistory.counts.total - forensicSearchHistory.forensicSearchHistory.length
      this.setState(
        {
          historyLimit: historyLimit + Math.min(remainingHistoryItems, HISTORY_LIMIT_STEP),
        },
        () => this.onRefresh()
      )
    }
  }

  onSortByChanged = (sortBy: ForensicSearchHistorySortBy, sortAsc: boolean) => {
    this.props.dispatchFunc(setForensicSearchHistorySortAsc(sortAsc))
    this.props.dispatchFunc(setForensicSearchHistorySortBy(sortBy))
    this.onRefresh(null, sortBy, sortAsc)
  }

  render() {
    const { engineCapabilities, isHistorySearchOpen, search, sortAsc, sortBy, userId } = this.props
    const {
      fetchError,
      forensicSearchHistory,
      forensicSearchHistoryItem,
      refreshInterval,
      showDeleteAllForensicSearchHistoryConfirm,
      showDeleteForensicSearchHistoryItemConfirm,
      showForensicSearchModal,
    } = this.state

    // Make sure the user can delete forensic searches
    let canDeleteForensicSearches = true
    if (
      forensicSearchHistory &&
      engineCapabilities &&
      engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL)
    ) {
      const userOwnsAllForensicSearches = forensicSearchHistory.forensicSearchHistory.every(
        (forensicSearchHistoryItem: ForensicSearchHistoryItemProperties) =>
          userId === forensicSearchHistoryItem.owner
      )
      const policies = engineCapabilities.userRights.policies
      canDeleteForensicSearches =
        userOwnsAllForensicSearches || policies.includes(EngineUserPolicies.deleteForensicSearches)
    }

    return (
      <HistoryDiv>
        <ForensicSearchHistoryWrapper>
          <View>
            <ViewHeaderItems border={false} style={{ marginBottom: 0 }}>
              {fetchError && (
                <Alert color="danger">
                  {typeof fetchError === "string"
                    ? fetchError
                    : `${fetchError.code} ${fetchError.reason}`}
                </Alert>
              )}
              <Interval
                timeout={refreshInterval}
                enabled={true}
                callback={() => this.onRefresh()}
              />
              <ViewHeader>
                <ViewHeaderButtons style={{ flexGrow: 1 }}>
                  <SearchButton id="search" onClick={this.onSearch}>
                    Search
                  </SearchButton>
                  {forensicSearchHistory && isHistorySearchOpen ? (
                    <ForensicSearchHistorySearchPopover
                      isOpen={true}
                      target="search"
                      search={search}
                      firstTimestamp={forensicSearchHistory.counts.firstTimestamp}
                      lastTimestamp={forensicSearchHistory.counts.lastTimestamp}
                      onOK={this.onSearchOK}
                      onCancel={this.onSearchCancel}
                    />
                  ) : null}
                  <LightDangerButton
                    id="delete-all"
                    disabled={
                      forensicSearchHistory?.forensicSearchHistory.length === 0 ||
                      !canDeleteForensicSearches
                    }
                    onClick={this.onDeleteAllForensicSearchHistory}
                  >
                    <FontAwesome name="trash-o" /> Clear History
                  </LightDangerButton>
                  <UncontrolledDropdown>
                    <LightDropdownToggle caret style={{ width: "100%" }}>
                      Sort By
                    </LightDropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        active={sortBy === ("name" as ForensicSearchHistorySortBy) && sortAsc}
                        onClick={this.onSortByChanged.bind(
                          this,
                          "name" as ForensicSearchHistorySortBy,
                          true
                        )}
                      >
                        Name (Ascending)
                      </DropdownItem>
                      <DropdownItem
                        active={sortBy === ("name" as ForensicSearchHistorySortBy) && !sortAsc}
                        onClick={this.onSortByChanged.bind(
                          this,
                          "name" as ForensicSearchHistorySortBy,
                          false
                        )}
                      >
                        Name (Descending)
                      </DropdownItem>
                      <DropdownItem
                        active={sortBy === ("begin-time" as ForensicSearchHistorySortBy) && sortAsc}
                        onClick={this.onSortByChanged.bind(
                          this,
                          "begin-time" as ForensicSearchHistorySortBy,
                          true
                        )}
                      >
                        Begin Time (Ascending)
                      </DropdownItem>
                      <DropdownItem
                        active={
                          sortBy === ("begin-time" as ForensicSearchHistorySortBy) && !sortAsc
                        }
                        onClick={this.onSortByChanged.bind(
                          this,
                          "begin-time" as ForensicSearchHistorySortBy,
                          false
                        )}
                      >
                        Begin Time (Descending)
                      </DropdownItem>
                      <DropdownItem
                        active={
                          sortBy === ("finish-time" as ForensicSearchHistorySortBy) && sortAsc
                        }
                        onClick={this.onSortByChanged.bind(
                          this,
                          "finish-time" as ForensicSearchHistorySortBy,
                          true
                        )}
                      >
                        Finish Time (Ascending)
                      </DropdownItem>
                      <DropdownItem
                        active={
                          sortBy === ("finish-time" as ForensicSearchHistorySortBy) && !sortAsc
                        }
                        onClick={this.onSortByChanged.bind(
                          this,
                          "finish-time" as ForensicSearchHistorySortBy,
                          false
                        )}
                      >
                        Finish Time (Descending)
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <LightButton
                    aria-label="Refresh"
                    id="refresh"
                    onClick={() =>
                      this.setState({ historyLimit: HISTORY_LIMIT_STEP }, () => this.onRefresh())
                    }
                  >
                    <FontAwesome name="refresh" />
                  </LightButton>
                  <UncontrolledTooltip placement="top" target="refresh">
                    Refresh
                  </UncontrolledTooltip>
                </ViewHeaderButtons>
              </ViewHeader>
              <ForensicSearchHistorySearchResults
                search={search}
                onClearSearch={this.onClearSearch}
              />
            </ViewHeaderItems>
            <ViewMaxWidth maxWidth={undefined}>
              <ViewContent>
                <ForensicSearchHistoryTable
                  engineCapabilities={engineCapabilities}
                  forensicSearchHistory={
                    forensicSearchHistory ? forensicSearchHistory.forensicSearchHistory : []
                  }
                  showLocalTime={this.props.showLocalTime}
                  userId={userId}
                  onDeleteForensicSearchHistoryItem={this.onDeleteForensicSearchHistoryItem}
                  onFavoriteForensicSearch={this.onFavoriteForensicSearch}
                  onRunForensicSearch={this.onRunForensicSearch}
                />
              </ViewContent>
            </ViewMaxWidth>
            {forensicSearchHistory &&
              forensicSearchHistory.forensicSearchHistory.length > 0 &&
              forensicSearchHistory.forensicSearchHistory.length <
                forensicSearchHistory.counts.total && (
                <ForensicSearchHistoryFooter>
                  <LightButton id="more" onClick={this.onMore}>
                    More ...
                  </LightButton>
                </ForensicSearchHistoryFooter>
              )}
            {showForensicSearchModal && forensicSearchHistoryItem && (
              <ForensicSearchModal
                onCancel={this.onForensicSearchCancel}
                onOK={this.onForensicSearchOK}
                queryJSON={forensicSearchHistoryItem.query}
              />
            )}
            {showDeleteAllForensicSearchHistoryConfirm && (
              <ConfirmationModal
                message="Are you sure you want to delete the forensic search history?"
                onNo={this.onDeleteAllForensicSearchHistoryCancel}
                onYes={this.onDeleteAllForensicSearchHistoryOK}
                show={showDeleteAllForensicSearchHistoryConfirm}
                title="Delete Forensic Search History"
              />
            )}
            {showDeleteForensicSearchHistoryItemConfirm && (
              <ConfirmationModal
                message="Are you sure you want to remove the forensic search from the history?"
                onNo={this.onDeleteForensicSearchHistoryItemCancel}
                onYes={this.onDeleteForensicSearchHistoryItemOK}
                show={showDeleteForensicSearchHistoryItemConfirm}
                title="Remove Forensic Search From History"
              />
            )}
          </View>
        </ForensicSearchHistoryWrapper>
      </HistoryDiv>
    )
  }
}

const mapStateToProps = (state: object) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  search: getForensicSearchHistorySearch(state) || emptySearch,
  sortAsc: getForensicSearchHistorySortAsc(state) || false,
  sortBy: getForensicSearchHistorySortBy(state) || ("begin-time" as ForensicSearchHistorySortBy),
  userId: getUserId(state),
})

export default connect(mapStateToProps)(withRouter(ForensicSearchHistoryView))
