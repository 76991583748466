import * as React from "react"
import { toNumber } from "lodash"
import FontAwesome from "react-fontawesome"
import { InputGroup } from "reactstrap"
import { Input } from "../common/Input"
import { InputButton } from "../common/Buttons"
import { LightDropdownToggle } from "../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../common/Dropdown"
import { PROTOCOL_TYPES, protocolOptions } from "./protocols"

export enum SEARCH_TYPES {
  SEARCH_FILE_NAMES,
  SEARCH_HEADERS,
  SEARCH_CONTENTS,
}

export type SearchOption = {
  column: SEARCH_TYPES
  label: string
}

export const searchOptions: SearchOption[] = [
  {
    column: SEARCH_TYPES.SEARCH_FILE_NAMES,
    label: "File Names",
  },
  {
    column: SEARCH_TYPES.SEARCH_HEADERS,
    label: "Headers",
  },
  {
    column: SEARCH_TYPES.SEARCH_CONTENTS,
    label: "Contents",
  },
]

export enum CONTENT_TYPES {
  NONE,
  ALL,
  IMAGES,
  TEXT,
  TEXT_CSS,
  TEXT_HTML,
  TEXT_JS,
  TEXT_PLAIN,
  APPS,
  APPS_GZIP,
  APPS_OCTET_STREAM,
  APPS_PDF,
  APPS_X_JS,
  APPS_X_SHOCKWAVE_FLASH,
  AUDIO,
  VIDEO,
}

export type ContentTypeOption = {
  contentEnum: CONTENT_TYPES
  label: string
  value: string | null
}

export const contentTypeOptions: ContentTypeOption[] = [
  {
    contentEnum: CONTENT_TYPES.NONE,
    label: "",
    value: null,
  },
  {
    contentEnum: CONTENT_TYPES.ALL,
    label: "*",
    value: null,
  },
  {
    contentEnum: CONTENT_TYPES.IMAGES,
    label: "image/*",
    value: "image",
  },
  {
    contentEnum: CONTENT_TYPES.TEXT,
    label: "text/*",
    value: "text",
  },
  {
    contentEnum: CONTENT_TYPES.TEXT_CSS,
    label: "text/css",
    value: "text/css",
  },
  {
    contentEnum: CONTENT_TYPES.TEXT_HTML,
    label: "text/html",
    value: "text/html",
  },
  {
    contentEnum: CONTENT_TYPES.TEXT_JS,
    label: "text/javascript",
    value: "text/javascript",
  },
  {
    contentEnum: CONTENT_TYPES.TEXT_PLAIN,
    label: "text/plain",
    value: "text/plain",
  },
  {
    contentEnum: CONTENT_TYPES.APPS,
    label: "application/*",
    value: "application",
  },
  {
    contentEnum: CONTENT_TYPES.APPS_GZIP,
    label: "application/gzip",
    value: "application/gzip",
  },
  {
    contentEnum: CONTENT_TYPES.APPS_OCTET_STREAM,
    label: "application/octet-stream",
    value: "application/octet-stream",
  },
  {
    contentEnum: CONTENT_TYPES.APPS_PDF,
    label: "application/pdf",
    value: "application/pdf",
  },
  {
    contentEnum: CONTENT_TYPES.APPS_X_JS,
    label: "application/x-javascript",
    value: "application/x-javascript",
  },
  {
    contentEnum: CONTENT_TYPES.APPS_X_SHOCKWAVE_FLASH,
    label: "application/x-shockwave-flash",
    value: "application/x-shockwave-flash",
  },
  {
    contentEnum: CONTENT_TYPES.AUDIO,
    label: "audio/*",
    value: "audio",
  },
  {
    contentEnum: CONTENT_TYPES.VIDEO,
    label: "video/*",
    value: "video",
  },
]

type ReconstructionsSearchBoxProps = {
  searchOption: SEARCH_TYPES
  search: string
  onChangeSearch: (searchOption: SEARCH_TYPES, filter: string) => void
  disabled?: boolean
  contentTypeOption: CONTENT_TYPES
  onChangeContentTypeDropdown: (contentTypeOption: CONTENT_TYPES) => void
  contentTypeSearchInput: string | null
  onChangeContentTypeSearch: (contentType: string | null) => void
  protocolOption: PROTOCOL_TYPES
  onChangeProtocolDropdown: (dropdown: PROTOCOL_TYPES) => void
}

const ReconstructionsSearchBox: React.FC<ReconstructionsSearchBoxProps> = ({
  searchOption,
  search,
  onChangeSearch,
  disabled,
  contentTypeOption,
  onChangeContentTypeDropdown,
  contentTypeSearchInput,
  onChangeContentTypeSearch,
  protocolOption,
  onChangeProtocolDropdown,
}) => {
  const onChangeSearchDropdown = (e: any) => {
    onChangeSearch(toNumber(e.target.name), search)
  }

  const onChangeContentDropdown = (e: any) => {
    onChangeContentTypeDropdown(toNumber(e.target.name))
    onChangeContentTypeSearch(null)
  }

  const onChangeProtocol = (e: any) => {
    onChangeProtocolDropdown(toNumber(e.target.name))
  }

  const onChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSearch(searchOption, e.target.value)
  }

  const onClearSearch = () => {
    onChangeSearch(searchOption, "")
  }

  const onChangeContentTypeSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChangeContentTypeSearch(e.target.value)

    const potentialMatchToDefaultTypes = contentTypeOptions.find(option => {
      if (e.target.value !== "") {
        return option.value?.includes(e.target.value)
      }
      return contentTypeOptions[CONTENT_TYPES.NONE]
    })

    if (potentialMatchToDefaultTypes) {
      onChangeContentTypeDropdown(potentialMatchToDefaultTypes?.contentEnum)
    }
  }

  const onClearContentTypeSearch = () => {
    onChangeContentTypeDropdown(CONTENT_TYPES.NONE)
    onChangeContentTypeSearch(null)
  }

  const currentSearchOption = searchOptions.find(option => option.column === searchOption)
  const currentContentOption = contentTypeOptions.find(
    option => option.contentEnum === contentTypeOption
  )

  const currentSearchOptionLabel = currentSearchOption ? currentSearchOption.label : ""
  let currentContentSearch = currentContentOption?.label ?? ""
  currentContentSearch = contentTypeSearchInput ? contentTypeSearchInput : currentContentSearch

  const currentProtocolOption = protocolOptions.find(option => option.protocol === protocolOption)
  const currentProtocolLabel = currentProtocolOption ? currentProtocolOption.label : ""

  const searchDropdownItems = searchOptions.map(option => (
    <DropdownItem
      key={option.column}
      name={String(option.column)}
      active={option.column === searchOption}
      onClick={onChangeSearchDropdown}
    >
      {option.label}
    </DropdownItem>
  ))

  const contentDropdownItems = contentTypeOptions.map(option =>
    option.contentEnum !== CONTENT_TYPES.NONE ? (
      <DropdownItem
        key={option.contentEnum}
        name={String(option.contentEnum)}
        active={option.contentEnum === contentTypeOption}
        onClick={onChangeContentDropdown}
      >
        {option.label}
      </DropdownItem>
    ) : null
  )

  const protocolDropdownItems = protocolOptions.map(option =>
    option.protocol !== PROTOCOL_TYPES.POP && option.protocol !== PROTOCOL_TYPES.TLS_CERT ? (
      <DropdownItem
        key={option.protocol}
        name={String(option.protocol)}
        active={option.protocol === protocolOption}
        onClick={onChangeProtocol}
      >
        {option.label}
      </DropdownItem>
    ) : null
  )

  return (
    <>
      <InputGroup>
        <UncontrolledDropdown addonType="prepend">
          <LightDropdownToggle caret>{currentSearchOptionLabel}</LightDropdownToggle>
          <DropdownMenu>{searchDropdownItems}</DropdownMenu>
        </UncontrolledDropdown>
        <Input
          className="border-left-0 border-right-0"
          aria-label="Search"
          placeholder="Search"
          value={search}
          onChange={onChangeSearchText}
          disabled={disabled}
          spellCheck={false}
        />
        <InputButton aria-label="Clear" onClick={onClearSearch} disabled={disabled}>
          <FontAwesome name="times" />
        </InputButton>
      </InputGroup>
      <InputGroup>
        <UncontrolledDropdown addonType="append">
          <LightDropdownToggle caret>Content Type</LightDropdownToggle>
          <DropdownMenu> {contentDropdownItems}</DropdownMenu>
        </UncontrolledDropdown>
        <Input
          className="border-left-0 border-right-0"
          aria-label="Search"
          placeholder="Search"
          value={currentContentSearch}
          onChange={onChangeContentTypeSearchText}
          disabled={disabled}
          spellCheck={false}
        />
        <InputButton aria-label="Clear" onClick={onClearContentTypeSearch} disabled={disabled}>
          <FontAwesome name="times" />
        </InputButton>
      </InputGroup>
      <UncontrolledDropdown addonType="append">
        <LightDropdownToggle caret>{currentProtocolLabel}</LightDropdownToggle>
        <DropdownMenu> {protocolDropdownItems}</DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}

export default ReconstructionsSearchBox
