export const defaultColumns = [
    {
      dataKey: "FileName",
      label: "Name",
      width: 200,
      flexGrow: 1,
      visible: true,
    },
    {
      dataKey: "PartialPath",
      label: "Path",
      width: 200,
      visible: false,
    },
    {
      dataKey: "CaptureName",
      label: "Capture",
      width: 200,
      visible: false,
    },
    {
      dataKey: "FileSize",
      label: "Size",
      width: 100,
      alignRight: true,
      visible: false,
    },
    {
      dataKey: "MediaType",
      label: "Media",
      width: 120,
      visible: true,
    },
    {
      dataKey: "PacketCount",
      label: "Packets",
      width: 140,
      alignRight: true,
      visible: true,
    },
    {
      dataKey: "SessionStartTime",
      label: "Start Time",
      width: 140,
      alignRight: true,
      visible: true,
    },
    {
      dataKey: "SessionEndTime",
      label: "Stop Time",
      width: 140,
      alignRight: true,
      visible: false,
    },
    {
      dataKey: "duration",
      label: "Duration",
      width: 140,
      alignRight: true,
      visible: true,
    },
    {
      dataKey: "TimeZoneBias",
      label: "Time Zone",
      width: 100,
      visible: false,
    },
    {
      dataKey: "AdapterName",
      label: "Adapter",
      width: 200,
      visible: true,
    },
    {
      dataKey: "AdapterAddr",
      label: "Adapter Address",
      width: 140,
      visible: false,
    },
    {
      dataKey: "LinkSpeed",
      label: "Link Speed",
      width: 140,
      visible: false,
    },
    {
      dataKey: "FileIndex",
      label: "File Number",
      width: 100,
      alignRight: true,
      visible: false,
    },
    {
      dataKey: "Owner",
      label: "Owner",
      width: 100,
      alignRight: false,
      visible: false,
    },
  ]