import * as React from "react"
import { connect } from "react-redux"
import { cloneDeep, debounce } from "lodash"
import { produce } from "immer"
import { Row, Col, Form, FormGroup } from "reactstrap"
import {
  AnalysisOptionsButton,
  LightButton,
  LightDropdownToggle,
  PrimaryButton,
  SecondaryButton,
} from "../common/Buttons"
import { ButtonBar, CheckGroup, HorizontalFormGroup, Label } from "../common/Form"
import { DateTimePicker } from "../common/DateTimePicker"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../common/Dropdown"
import { ErrorText } from "../common/ErrorText"
import { Input } from "../common/Input"
import { MessageModal } from "../common/MessageModal"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import DateRangePresets from "../common/DateRangePresets"
import { Table as PropTable } from "../common/PropTable"
import ExpertSettingsModal from "../ExpertSettingsModal"
import { FilterBar } from "../FilterBar"
import GraphOptionsModal from "./GraphOptionsModal"
import PluginsListModal from "./PluginsListModal"
import StatsLimitModal from "./StatsLimitModal"
import VoIPConfigModal from "./VoIPConfigModal"
import { getUniqueForensicSearchName } from "../../utils/captureUtils"
import {
  formatDurationRange,
  formatFloat,
  formatInteger,
  peekFromDate,
} from "../../utils/formatUtils"
import { isWireless } from "../../utils/mediaUtils"
import { pushRecentFilterBarExpression } from "../../store/ui"
import {
  getAuthToken,
  getEngine,
  getCapabilities,
  getRecentFilterBarExpressions,
  getShowLocalTime,
} from "../../store"
import {
  fetchExpertPreferences,
  fetchFilesList,
  fetchForensicSearchHistory,
  fetchForensicSearches,
  fetchGraphs,
  fetchTimelineData,
  postFilterConvert,
} from "../../api/api"
import {
  EngineCapabilitiesPluginInfo,
  ExpertPreferencesGet,
  ExpertPreferencesSet,
  ExpertSettings,
  ForensicSearchGraphSettings,
  ForensicSearchVoipSettings,
  GraphObject,
  PeekStatsLimitSettings,
  ResponseGetEngineCapabilities,
  RequestCreateForensicSearch,
  PluginObject,
  PluginOption,
  PluginsConfig,
  PluginsList,
  VoipConfig,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { MediaType, MediaSubType } from "../../api/types/mediaTypes"
import {
  PeekSeverity,
  PeekVariantType,
  PluginCategory,
  TimelineDataViewType,
} from "../../api/types/peekTypes"
import { CLSID_COMPASS, CLSID_QOS_PLUGIN, CLSID_VOIP_PLUGIN } from "../../utils/pluginUtils"
import PluginOptionsModal from "../PluginOptionsModal"

interface FileQueryStorage {
  application?: boolean
  conversation?: boolean
  conversationSettings?: PeekStatsLimitSettings
  country?: boolean
  error?: boolean
  expert?: boolean
  expertSettings?: ExpertPreferencesSet
  files?: boolean
  graphs?: boolean
  graphSettings?: ForensicSearchGraphSettings
  history?: boolean
  log?: boolean
  mplsvlanvxlan?: boolean
  mplsvlanvxlanSettings?: PeekStatsLimitSettings
  network?: boolean
  node?: boolean
  nodeSettings?: PeekStatsLimitSettings
  packets?: boolean
  passiveNameResolution?: boolean
  plugins?: boolean
  pluginsConfig?: PluginsConfig
  pluginsList?: PluginsList
  protocol?: boolean
  protocolSettings?: PeekStatsLimitSettings
  size?: boolean
  summary?: boolean
  topTalkers?: boolean
  voice?: boolean
  voipSettings?: ForensicSearchVoipSettings
  web?: boolean
}

interface FileQueryStorageWireless {
  wirelessChannel?: boolean
  wirelessNode?: boolean
}

type ForensicSearchModalProps = {
  engine: string
  authToken: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  onCancel: () => void
  onOK: (query: RequestCreateForensicSearch) => void
  captureSessionId?: number
  startTime?: string
  endTime?: string
  filter?: string
  mediaSubType?: number
  mediaType?: number
  name?: string
  file?: string
  recentFilterBarExpressions: string[]
  showLocalTime: boolean
  queryJSON?: RequestCreateForensicSearch
  pushRecentFilterBarExpression: (expression: string) => void
}

type ForensicSearchModalState = {
  expertPrefs: ExpertPreferencesGet | null
  filterBarError: string
  graphs: GraphObject[] | null
  hasFiles: boolean | null
  packetsEstimate: string | null
  query: RequestCreateForensicSearch
  showEmptyAnalysis: boolean
  showExpertSettingsModal: boolean
  showGraphListModal: boolean
  showPluginListModal: boolean
  showMPLSVLANVXLANStatsLimitModal: boolean
  showNodeStatsLimitModal: boolean
  showProtocolStatsLimitModal: boolean
  showConversationStatsLimitModal: boolean
  showVoIPConfigModal: boolean
  pluginConfig: PluginOption | null
  pluginInfo: EngineCapabilitiesPluginInfo | null
  showPluginsOptions: boolean
}

class ForensicSearchModal extends React.Component<
  ForensicSearchModalProps,
  ForensicSearchModalState
> {
  state: ForensicSearchModalState = {
    expertPrefs: null,
    filterBarError: "",
    graphs: null,
    hasFiles: null,
    packetsEstimate: "",
    query: {
      application: true,
      captureSessionId: this.props.captureSessionId || -1,
      conversation: true,
      country: true,
      endTime: this.props.endTime || new Date().toISOString(),
      error: true,
      expert: true,
      files: true,
      filter: this.props.filter || "",
      graphs: true,
      history: true,
      log: true,
      mediaSubType: this.props.mediaSubType || MediaSubType.MEDIA_SUBTYPE_NATIVE,
      mediaType: this.props.mediaType || MediaType.MEDIA_TYPE_802_3,
      mplsvlanvxlan: true,
      name: this.props.name || "Forensic Search 1",
      network: true,
      node: true,
      packets: true,
      passiveNameResolution: true,
      plugins: true,
      protocol: true,
      singleFiles: this.props.file ? [this.props.file] : [],
      size: true,
      startTime: this.props.startTime || new Date().toISOString(),
      summary: true,
      topTalkers: false,
      voice: true,
      web: true,
      wirelessChannel: false,
      wirelessNode: false,
    },
    showEmptyAnalysis: false,
    showExpertSettingsModal: false,
    showGraphListModal: false,
    showPluginListModal: false,
    showMPLSVLANVXLANStatsLimitModal: false,
    showNodeStatsLimitModal: false,
    showProtocolStatsLimitModal: false,
    showConversationStatsLimitModal: false,
    showVoIPConfigModal: false,
    pluginConfig: null,
    pluginInfo: null,
    showPluginsOptions: false,
  }

  componentDidMount = () => {
    this.loadQuery()
    this.validateName()
    this.validateFilter()
    this.onPacketsEstimate()
    this.onLoadGraphs()
    this.onLoadFileList()
  }

  loadQuery = () => {
    const { mediaType, mediaSubType, engineCapabilities, queryJSON } = this.props

    const canDoAnalysis =
      !engineCapabilities ||
      !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchAnalysisACL) ||
      engineCapabilities.userRights.policies.includes(EngineUserPolicies.forensicSearchAnalysis)

    const wireless =
      mediaType !== undefined && mediaSubType !== undefined
        ? isWireless(mediaType, mediaSubType)
        : false

    this.setState(
      produce((draft: ForensicSearchModalState) => {
        const { query } = draft
        if (queryJSON) {
          Object.keys(queryJSON).forEach(key => {
            query[key] = queryJSON[key]
          })
        }

        if (!canDoAnalysis) {
          query.application = false
          query.conversation = false
          query.country = false
          query.error = false
          query.expert = false
          query.files = false
          query.graphs = false
          query.history = false
          query.log = false
          query.mplsvlanvxlan = false
          query.network = false
          query.node = false
          query.packets = true
          query.passiveNameResolution = false
          query.plugins = false
          query.protocol = false
          query.size = false
          query.summary = false
          query.topTalkers = false
          query.voice = false
          query.web = false
          if (wireless) {
            query.wirelessChannel = false
            query.wirelessNode = false
          }
          if (query.pluginsList) {
            query.pluginsList = {
              clsid: "99403F22-0933-41E0-A0B8-9F626083F52F",
              properties: [],
            }
          }
        } else if (!queryJSON) {
          const fileQueryString = localStorage.getItem("fileQuery")
          if (fileQueryString !== null) {
            const fileQuery: FileQueryStorage = JSON.parse(fileQueryString)
            if (fileQuery.application !== undefined) {
              query.application = fileQuery.application
            }
            if (fileQuery.conversation !== undefined) {
              query.conversation = fileQuery.conversation
            }
            if (fileQuery.conversationSettings !== undefined) {
              query.conversationLimitStatisticSettings = cloneDeep(fileQuery.conversationSettings)
            }
            if (fileQuery.country !== undefined) {
              query.country = fileQuery.country
            }
            if (fileQuery.error !== undefined) {
              query.error = fileQuery.error
            }
            if (fileQuery.expert !== undefined) {
              query.expert = fileQuery.expert
            }
            if (fileQuery.files !== undefined) {
              query.files = fileQuery.files
            }
            if (fileQuery.graphs !== undefined) {
              query.graphs = fileQuery.graphs
            }
            if (fileQuery.graphSettings !== undefined) {
              query.graphSettings = cloneDeep(fileQuery.graphSettings)
            }
            if (fileQuery.history !== undefined) {
              query.history = fileQuery.history
            }
            if (fileQuery.log !== undefined) {
              query.log = fileQuery.log
            }
            if (fileQuery.mplsvlanvxlan !== undefined) {
              query.mplsvlanvxlan = fileQuery.mplsvlanvxlan
            }
            if (fileQuery.mplsvlanvxlanSettings !== undefined) {
              query.mplsVlanVxlanLimitStatisticSettings = cloneDeep(fileQuery.mplsvlanvxlanSettings)
            }
            if (fileQuery.network !== undefined) {
              query.network = fileQuery.network
            }
            if (fileQuery.node !== undefined) {
              query.node = fileQuery.node
            }
            if (fileQuery.nodeSettings !== undefined) {
              query.nodeLimitStatisticSettings = cloneDeep(fileQuery.nodeSettings)
            }
            if (fileQuery.packets !== undefined) {
              query.packets = fileQuery.packets
            }
            if (fileQuery.passiveNameResolution !== undefined) {
              query.passiveNameResolution = fileQuery.passiveNameResolution
            }
            if (fileQuery.plugins !== undefined) {
              query.plugins = fileQuery.plugins
            }
            if (fileQuery.pluginsConfig !== undefined) {
              query.pluginsConfig = cloneDeep(fileQuery.pluginsConfig)
            }
            if (fileQuery.pluginsList !== undefined) {
              query.pluginsList = cloneDeep(fileQuery.pluginsList)
            }
            if (fileQuery.protocol !== undefined) {
              query.protocol = fileQuery.protocol
            }
            if (fileQuery.protocolSettings !== undefined) {
              query.protocolLimitStatisticSettings = cloneDeep(fileQuery.protocolSettings)
            }
            if (fileQuery.size !== undefined) {
              query.size = fileQuery.size
            }
            if (fileQuery.summary !== undefined) {
              query.summary = fileQuery.summary
            }
            if (fileQuery.topTalkers !== undefined) {
              query.topTalkers = fileQuery.topTalkers
            }
            if (fileQuery.voice !== undefined) {
              query.voice = fileQuery.voice
            }
            if (fileQuery.voipSettings !== undefined) {
              query.voipSettings = cloneDeep(fileQuery.voipSettings)
            }
            if (fileQuery.web !== undefined) {
              query.web = fileQuery.web
            }
          }

          if (
            query.files &&
            engineCapabilities &&
            !engineCapabilities.capabilities.includes(EngineCapabilities.reconstructions)
          ) {
            query.files = false
          }

          if (
            query.web &&
            engineCapabilities &&
            !engineCapabilities.capabilities.includes(EngineCapabilities.remoteWebAnalysis)
          ) {
            query.web = false
          }

          if (wireless) {
            const fileQueryWirelessString = localStorage.getItem("fileQueryWireless")
            if (fileQueryWirelessString !== null) {
              const { query } = draft
              const fileQueryWireless: FileQueryStorageWireless =
                JSON.parse(fileQueryWirelessString)
              if (fileQueryWireless.wirelessChannel !== undefined) {
                query.wirelessChannel = fileQueryWireless.wirelessChannel
              }
              if (fileQueryWireless.wirelessNode !== undefined) {
                query.wirelessNode = fileQueryWireless.wirelessNode
              }
            }
          }
        }
      })
    )
  }

  saveQuery = () => {
    const { engineCapabilities } = this.props
    const { query } = this.state

    const canDoAnalysis =
      !engineCapabilities ||
      !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchAnalysisACL) ||
      engineCapabilities.userRights.policies.includes(EngineUserPolicies.forensicSearchAnalysis)

    if (canDoAnalysis) {
      const fileQuery: FileQueryStorage = {}

      if (query.application !== undefined) {
        fileQuery.application = query.application
      }
      if (query.conversation !== undefined) {
        fileQuery.conversation = query.conversation
      }
      if (query.conversationLimitStatisticSettings !== undefined) {
        fileQuery.conversationSettings = cloneDeep(query.conversationLimitStatisticSettings)
      }
      if (query.country !== undefined) {
        fileQuery.country = query.country
      }
      if (query.error !== undefined) {
        fileQuery.error = query.error
      }
      if (query.expert !== undefined) {
        fileQuery.expert = query.expert
      }
      if (query.expertPrefs !== undefined) {
        fileQuery.expertSettings = cloneDeep(query.expertPrefs)
      }
      if (query.files !== undefined) {
        fileQuery.files = query.files
      }
      if (query.graphs !== undefined) {
        fileQuery.graphs = query.graphs
      }
      if (query.graphSettings !== undefined) {
        fileQuery.graphSettings = cloneDeep(query.graphSettings)
      }
      if (query.history !== undefined) {
        fileQuery.history = query.history
      }
      if (query.log !== undefined) {
        fileQuery.log = query.log
      }
      if (query.mplsvlanvxlan !== undefined) {
        fileQuery.mplsvlanvxlan = query.mplsvlanvxlan
      }
      if (query.mplsVlanVxlanLimitStatisticSettings !== undefined) {
        fileQuery.mplsvlanvxlanSettings = cloneDeep(query.mplsVlanVxlanLimitStatisticSettings)
      }
      if (query.network !== undefined) {
        fileQuery.network = query.network
      }
      if (query.node !== undefined) {
        fileQuery.node = query.node
      }
      if (query.nodeLimitStatisticSettings !== undefined) {
        fileQuery.nodeSettings = cloneDeep(query.nodeLimitStatisticSettings)
      }
      if (query.packets !== undefined) {
        fileQuery.packets = query.packets
      }
      if (query.passiveNameResolution !== undefined) {
        fileQuery.passiveNameResolution = query.passiveNameResolution
      }
      if (query.plugins !== undefined) {
        fileQuery.plugins = query.plugins
      }
      if (query.pluginsConfig !== undefined) {
        fileQuery.pluginsConfig = cloneDeep(query.pluginsConfig)
      }
      if (query.pluginsList !== undefined) {
        fileQuery.pluginsList = cloneDeep(query.pluginsList)
      }
      if (query.protocol !== undefined) {
        fileQuery.protocol = query.protocol
      }
      if (query.protocolLimitStatisticSettings !== undefined) {
        fileQuery.protocolSettings = cloneDeep(query.protocolLimitStatisticSettings)
      }
      if (query.size !== undefined) {
        fileQuery.size = query.size
      }
      if (query.summary !== undefined) {
        fileQuery.summary = query.summary
      }
      if (query.topTalkers !== undefined) {
        fileQuery.topTalkers = query.topTalkers
      }
      if (query.voice !== undefined) {
        fileQuery.voice = query.voice
      }
      if (query.voipSettings !== undefined) {
        fileQuery.voipSettings = cloneDeep(query.voipSettings)
      }
      if (query.web !== undefined) {
        fileQuery.web = query.web
      }

      localStorage.setItem("fileQuery", JSON.stringify(fileQuery))

      const { mediaType, mediaSubType } = this.props

      const wireless =
        mediaType !== undefined && mediaSubType !== undefined
          ? isWireless(mediaType, mediaSubType)
          : false

      if (wireless) {
        const fileQueryWireless: FileQueryStorageWireless = {}

        if (query.wirelessChannel !== undefined) {
          fileQueryWireless.wirelessChannel = query.wirelessChannel
        }
        if (query.wirelessNode !== undefined) {
          fileQueryWireless.wirelessNode = query.wirelessNode
        }

        localStorage.setItem("fileQueryWireless", JSON.stringify(fileQueryWireless))
      }
    }
  }

  onPreset = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { mediaType, mediaSubType } = this.props

    const wireless =
      mediaType !== undefined && mediaSubType !== undefined
        ? isWireless(mediaType, mediaSubType)
        : false

    const presetName = event.currentTarget.name
    this.setState(
      produce((draft: ForensicSearchModalState) => {
        const { query } = draft
        switch (presetName) {
          case "overview":
            {
              query.application = true
              query.conversation = false
              query.country = true
              query.error = true
              query.expert = false
              query.files = false
              query.graphs = false
              query.history = true
              query.log = true
              query.mplsvlanvxlan = true
              query.network = true
              query.node = true
              query.packets = true
              query.passiveNameResolution = true
              query.plugins = true
              query.protocol = true
              query.size = true
              query.summary = true
              query.topTalkers = false
              query.voice = false
              query.web = false
              if (wireless) {
                query.wirelessChannel = true
                query.wirelessNode = true
              }
              // Include all plugins except compass
              const { engineCapabilities } = this.props
              if (engineCapabilities && engineCapabilities.pluginsInfo) {
                query.pluginsConfig = {
                  clsid: "862E83A1-BC76-479A-A7FD-06CD6451815A",
                  plugins: [],
                }
                query.pluginsList = {
                  clsid: "99403F22-0933-41E0-A0B8-9F626083F52F",
                  properties: engineCapabilities.pluginsInfo
                    .filter(
                      info =>
                        (!Array.isArray(info.categoryIds) ||
                          info.categoryIds.includes(PluginCategory.PLUGIN_CATEGORY_PEEK_PLUGIN)) &&
                        info.clsid !== CLSID_COMPASS
                    )
                    .map(info => {
                      return {
                        type: PeekVariantType.PEEK_VT_BSTR,
                        value: `{${info.clsid}}`,
                      }
                    }),
                }
              }
            }
            break
          case "packets":
            query.application = false
            query.conversation = false
            query.country = false
            query.error = false
            query.expert = false
            query.files = false
            query.graphs = false
            query.history = false
            query.log = false
            query.mplsvlanvxlan = false
            query.network = false
            query.node = false
            query.packets = true
            query.passiveNameResolution = false
            query.plugins = false
            query.protocol = false
            query.size = false
            query.summary = false
            query.topTalkers = false
            query.voice = false
            query.web = false
            if (wireless) {
              query.wirelessChannel = false
              query.wirelessNode = false
            }
            if (query.pluginsList) {
              query.pluginsList = {
                clsid: "99403F22-0933-41E0-A0B8-9F626083F52F",
                properties: [],
              }
            }
            break
          case "expert":
            query.application = true
            query.conversation = false
            query.country = true
            query.error = false
            query.expert = true
            query.files = false
            query.graphs = false
            query.history = false
            query.log = true
            query.mplsvlanvxlan = false
            query.network = false
            query.node = true
            query.packets = true
            query.passiveNameResolution = true
            query.plugins = false
            query.protocol = true
            query.size = false
            query.summary = true
            query.topTalkers = false
            query.voice = false
            query.web = false
            if (wireless) {
              query.wirelessChannel = false
              query.wirelessNode = false
            }
            if (query.pluginsList) {
              query.pluginsList = {
                clsid: "99403F22-0933-41E0-A0B8-9F626083F52F",
                properties: [],
              }
            }
            break
          case "voip":
            {
              query.application = true
              query.conversation = false
              query.country = false
              query.error = false
              query.expert = true
              query.files = false
              query.graphs = true
              query.history = true
              query.log = true
              query.mplsvlanvxlan = false
              query.network = false
              query.node = true
              query.packets = true
              query.passiveNameResolution = true
              query.plugins = true
              query.protocol = true
              query.size = false
              query.summary = true
              query.topTalkers = false
              query.voice = true
              query.web = false
              if (wireless) {
                query.wirelessChannel = false
                query.wirelessNode = false
              }
              // Include voip and qos plugins
              const { engineCapabilities } = this.props
              if (engineCapabilities && engineCapabilities.pluginsInfo) {
                query.pluginsConfig = {
                  clsid: "862E83A1-BC76-479A-A7FD-06CD6451815A",
                  plugins: [],
                }
                query.pluginsList = {
                  clsid: "99403F22-0933-41E0-A0B8-9F626083F52F",
                  properties: engineCapabilities.pluginsInfo
                    .filter(
                      info => info.clsid === CLSID_QOS_PLUGIN || info.clsid === CLSID_VOIP_PLUGIN
                    )
                    .map(info => {
                      return {
                        type: PeekVariantType.PEEK_VT_BSTR,
                        value: `{${info.clsid}}`,
                      }
                    }),
                }
              }
            }
            break
          default:
            break
        }
      })
    )
  }

  onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    this.setState(
      produce((draft: any) => {
        draft.query[name as keyof RequestCreateForensicSearch] = value
      })
    )
  }

  onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    this.setState(
      produce((draft: any) => {
        draft.query[name as keyof RequestCreateForensicSearch] = checked
      })
    )
  }

  onChangeCompass = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    this.setState(
      produce((draft: ForensicSearchModalState) => {
        if (!draft.query.pluginsList) {
          draft.query.pluginsList = {
            clsid: "99403F22-0933-41E0-A0B8-9F626083F52F",
            properties: [],
          }
        }
        const compassIndex = draft.query.pluginsList.properties.findIndex(
          pobj => pobj.value === `{${CLSID_COMPASS}}`
        )
        if (checked && compassIndex === -1) {
          draft.query.pluginsList.properties.push({
            type: PeekVariantType.PEEK_VT_BSTR,
            value: `{${CLSID_COMPASS}}`,
          })
        } else if (!checked && compassIndex !== -1) {
          draft.query.pluginsList.properties.splice(compassIndex, 1)
        }
      })
    )
  }

  onChangeTimes = (start?: Date, end?: Date) => {
    this.onChangeStartTime(start ? start : new Date())
    this.onChangeEndTime(end ? end : new Date())
  }

  onChangeStartTime = (date: Date | null) => {
    if (date != null) {
      const startTime = date.toISOString()
      this.setState(
        produce((draft: ForensicSearchModalState) => {
          draft.query.startTime = startTime
        }),
        () => {
          this.onPacketsEstimate()
          this.onLoadFileList()
        }
      )
    }
  }

  onChangeEndTime = (date: Date | null) => {
    if (date != null) {
      const endTime = date.toISOString()
      this.setState(
        produce((draft: ForensicSearchModalState) => {
          draft.query.endTime = endTime
        }),
        () => {
          this.onPacketsEstimate()
          this.onLoadFileList()
        }
      )
    }
  }

  onRevertTimes = () => {
    if (this.props.startTime) {
      this.onChangeStartTime(new Date(this.props.startTime))
    }
    if (this.props.endTime) {
      this.onChangeEndTime(new Date(this.props.endTime))
    }
  }

  onPluginSettings = () => {
    this.setState({ showPluginListModal: true })
  }

  onPluginSettingsCancel = () => {
    this.setState({ showPluginListModal: false })
  }

  onPluginSettingsOK = (pluginsConfig: PluginOption[], pluginsList: PluginObject[]) => {
    // merge with compass option
    const newPluginsConfig = cloneDeep(pluginsConfig)
    if (this.state.query.pluginsConfig) {
      const indexCompassCurrent = this.state.query.pluginsConfig.plugins.findIndex(
        pobj => pobj.clsid === CLSID_COMPASS
      )
      if (indexCompassCurrent !== -1) {
        const indexCompassNew = newPluginsConfig.findIndex(pobj => pobj.clsid === CLSID_COMPASS)
        if (indexCompassNew !== -1) {
          newPluginsConfig[indexCompassNew] =
            this.state.query.pluginsConfig.plugins[indexCompassCurrent]
        } else {
          newPluginsConfig.push(this.state.query.pluginsConfig.plugins[indexCompassCurrent])
        }
      }
    }
    const newPluginsList = cloneDeep(pluginsList)
    if (this.state.query.pluginsList) {
      const indexCompass = this.state.query.pluginsList.properties.findIndex(
        pobj => pobj.value === `{${CLSID_COMPASS}}`
      )
      if (indexCompass !== -1) {
        const indexCompassNew = newPluginsList.findIndex(
          pobj => pobj.value === `{${CLSID_COMPASS}}`
        )
        if (indexCompassNew !== -1) {
          newPluginsList[indexCompassNew] = this.state.query.pluginsList.properties[indexCompass]
        } else {
          newPluginsList.push(this.state.query.pluginsList.properties[indexCompass])
        }
      }
    }

    this.setState(
      produce((draft: ForensicSearchModalState) => {
        draft.query.pluginsConfig = {
          clsid: "862E83A1-BC76-479A-A7FD-06CD6451815A",
          plugins: [...newPluginsConfig],
        }
        draft.query.pluginsList = {
          clsid: "99403F22-0933-41E0-A0B8-9F626083F52F",
          properties: [...newPluginsList],
        }
        draft.showPluginListModal = false
      })
    )
  }

  onExpertSettings = () => {
    const { engine, authToken } = this.props
    fetchExpertPreferences(engine, authToken)
      .then(expertPrefs => {
        if (this.state.query.expertPrefs !== undefined) {
          // Do not use cache if there are new experts.
          if (
            expertPrefs.settings._default.problems.length ===
            this.state.query.expertPrefs.settings._default.problems.length
          ) {
            expertPrefs.settings = cloneDeep(this.state.query.expertPrefs.settings)
          }
        }
        this.setState({ expertPrefs, showExpertSettingsModal: true })
      })
      .catch(error => {
        console.error(error)
      })
  }

  onExpertSettingsCancel = () => {
    this.setState({ expertPrefs: null, showExpertSettingsModal: false })
  }

  onExpertSettingsOK = (expertSettings: ExpertSettings) => {
    this.setState(
      produce(draft => {
        draft.query.expertPrefs = {
          settings: cloneDeep(expertSettings),
        }
        draft.expertPrefs = null
        draft.showExpertSettingsModal = false
      })
    )
  }

  onGraphSettings = () => {
    this.setState({ showGraphListModal: true })
  }

  onGraphSettingsCancel = () => {
    this.setState({ showGraphListModal: false })
  }

  onGraphSettingsOK = (graphSettings: ForensicSearchGraphSettings) => {
    this.setState(
      produce((draft: ForensicSearchModalState) => {
        draft.query.graphSettings = cloneDeep(graphSettings)
        draft.showGraphListModal = false
      })
    )
  }

  onMPLSVLANVXLANStatsLimit = () => {
    this.setState({ showMPLSVLANVXLANStatsLimitModal: true })
  }

  onMPLSVLANVXLANStatsLimitCancel = () => {
    this.setState({ showMPLSVLANVXLANStatsLimitModal: false })
  }

  onMPLSVLANVXLANStatsLimitOK = (statsLimit: PeekStatsLimitSettings) => {
    this.setState(
      produce((draft: ForensicSearchModalState) => {
        draft.query.mplsVlanVxlanLimitStatisticSettings = statsLimit
        draft.showMPLSVLANVXLANStatsLimitModal = false
      })
    )
  }

  getMPLSVLANVXLANLimitStatisticSettings = (): PeekStatsLimitSettings => {
    const { mplsVlanVxlanLimitStatisticSettings } = this.state.query
    if (mplsVlanVxlanLimitStatisticSettings !== undefined) {
      return mplsVlanVxlanLimitStatisticSettings
    }
    return {
      clsid: "F7FD1F47-F210-4A95-A9A9-FC041414D3C2",
      enabled: true,
      limit: 100000,
      notify: true,
      reset: false,
      severity: PeekSeverity.PEEK_SEVERITY_SEVERE,
    }
  }

  onNodeStatsLimit = () => {
    this.setState({ showNodeStatsLimitModal: true })
  }

  onNodeStatsLimitCancel = () => {
    this.setState({ showNodeStatsLimitModal: false })
  }

  onNodeStatsLimitOK = (statsLimit: PeekStatsLimitSettings) => {
    this.setState(
      produce((draft: ForensicSearchModalState) => {
        draft.query.nodeLimitStatisticSettings = statsLimit
        draft.showNodeStatsLimitModal = false
      })
    )
  }

  getNodeLimitStatisticSettings = (): PeekStatsLimitSettings => {
    const { nodeLimitStatisticSettings } = this.state.query
    if (nodeLimitStatisticSettings !== undefined) {
      return nodeLimitStatisticSettings
    }
    return {
      clsid: "F7FD1F47-F210-4A95-A9A9-FC041414D3C2",
      enabled: true,
      limit: 100000,
      notify: true,
      reset: false,
      severity: PeekSeverity.PEEK_SEVERITY_SEVERE,
    }
  }

  onProtocolStatsLimit = () => {
    this.setState({ showProtocolStatsLimitModal: true })
  }

  onProtocolStatsLimitCancel = () => {
    this.setState({ showProtocolStatsLimitModal: false })
  }

  onProtocolStatsLimitOK = (statsLimit: PeekStatsLimitSettings) => {
    this.setState(
      produce((draft: ForensicSearchModalState) => {
        draft.query.protocolLimitStatisticSettings = statsLimit
        draft.showProtocolStatsLimitModal = false
      })
    )
  }

  getProtocolLimitStatisticSettings = (): PeekStatsLimitSettings => {
    const { protocolLimitStatisticSettings } = this.state.query
    if (protocolLimitStatisticSettings !== undefined) {
      return protocolLimitStatisticSettings
    }
    return {
      clsid: "F7FD1F47-F210-4A95-A9A9-FC041414D3C2",
      enabled: true,
      limit: 100000,
      notify: true,
      reset: false,
      severity: PeekSeverity.PEEK_SEVERITY_SEVERE,
    }
  }

  onConversationStatsLimit = () => {
    this.setState({ showConversationStatsLimitModal: true })
  }

  onConversationStatsLimitCancel = () => {
    this.setState({ showConversationStatsLimitModal: false })
  }

  onConversationStatsLimitOK = (statsLimit: PeekStatsLimitSettings) => {
    this.setState(
      produce((draft: ForensicSearchModalState) => {
        draft.query.conversationLimitStatisticSettings = statsLimit
        draft.showConversationStatsLimitModal = false
      })
    )
  }

  getConversationLimitStatisticSettings = (): PeekStatsLimitSettings => {
    const { conversationLimitStatisticSettings } = this.state.query
    if (conversationLimitStatisticSettings !== undefined) {
      return conversationLimitStatisticSettings
    }
    return {
      clsid: "F7FD1F47-F210-4A95-A9A9-FC041414D3C2",
      enabled: true,
      limit: 500000,
      notify: true,
      reset: false,
      severity: PeekSeverity.PEEK_SEVERITY_SEVERE,
    }
  }

  onVoIPSettings = () => {
    this.setState({ showVoIPConfigModal: true })
  }

  onVoIPSettingsCancel = () => {
    this.setState({ showVoIPConfigModal: false })
  }

  onVoIPSettingsOK = (voipConfig: VoipConfig) => {
    // TODO: this conversion sucks (voipConfig vs voipSettings and maxCalls vs maximumCalls)
    const voipSettings: ForensicSearchVoipSettings = {
      maximumCalls: voipConfig.maxCalls,
      notify: voipConfig.notify,
      severity: voipConfig.severity,
      stopAnalysis: voipConfig.stopAnalysis,
    }
    this.setState(
      produce((draft: ForensicSearchModalState) => {
        draft.query.voipSettings = voipSettings
        draft.showVoIPConfigModal = false
      })
    )
  }

  getVoIPConfig = (): VoipConfig => {
    // TODO: conversion still sucks
    // Return settings in voipConfig format (maxCalls)
    const { voipSettings } = this.state.query
    if (voipSettings !== undefined) {
      return {
        clsid: "6F6FBFC1-3F14-46CA-A269-7664A41FC709",
        maxCalls: voipSettings.maximumCalls !== undefined ? voipSettings.maximumCalls : 2000,
        notify: voipSettings.notify !== undefined ? voipSettings.notify : true,
        severity:
          voipSettings.severity !== undefined
            ? voipSettings.severity
            : PeekSeverity.PEEK_SEVERITY_SEVERE,
        stopAnalysis: voipSettings.stopAnalysis !== undefined ? voipSettings.stopAnalysis : true,
      }
    }
    return {
      clsid: "6F6FBFC1-3F14-46CA-A269-7664A41FC709",
      maxCalls: 2000,
      notify: true,
      severity: PeekSeverity.PEEK_SEVERITY_SEVERE,
      stopAnalysis: true,
    }
  }

  onEnableAll = () => {
    const { mediaType, mediaSubType, engineCapabilities } = this.props

    const wireless =
      mediaType !== undefined && mediaSubType !== undefined
        ? isWireless(mediaType, mediaSubType)
        : false

    this.setState(
      produce((draft: ForensicSearchModalState) => {
        draft.query.application = true
        draft.query.conversation = true
        draft.query.country = true
        draft.query.error = true
        draft.query.expert = true
        draft.query.files =
          engineCapabilities !== null &&
          engineCapabilities.capabilities.includes(EngineCapabilities.reconstructions)
        draft.query.graphs = true
        draft.query.history = true
        draft.query.log = true
        draft.query.mplsvlanvxlan =
          engineCapabilities !== null &&
          engineCapabilities.capabilities.includes(EngineCapabilities.mplsVlanVxlanStats)
        draft.query.network = true
        draft.query.node = true
        draft.query.packets = true
        draft.query.passiveNameResolution = true
        draft.query.plugins = true
        draft.query.protocol = true
        draft.query.size = true
        draft.query.summary = true
        draft.query.voice = true
        draft.query.web =
          engineCapabilities !== null &&
          engineCapabilities.capabilities.includes(EngineCapabilities.remoteWebAnalysis)
        if (wireless) {
          draft.query.wirelessChannel = true
          draft.query.wirelessNode = true
        }
        if (!draft.query.pluginsList) {
          draft.query.pluginsList = {
            clsid: "99403F22-0933-41E0-A0B8-9F626083F52F",
            properties: [],
          }
        }
        if (engineCapabilities && engineCapabilities.pluginsInfo) {
          draft.query.pluginsList.properties = engineCapabilities.pluginsInfo
            .filter(
              info =>
                !Array.isArray(info.categoryIds) ||
                info.categoryIds.includes(PluginCategory.PLUGIN_CATEGORY_PEEK_PLUGIN)
            )
            .map(info => {
              return {
                type: PeekVariantType.PEEK_VT_BSTR,
                value: `{${info.clsid}}`,
              }
            })
        }
      })
    )
  }

  onDisableAll = () => {
    const { mediaType, mediaSubType } = this.props

    const wireless =
      mediaType !== undefined && mediaSubType !== undefined
        ? isWireless(mediaType, mediaSubType)
        : false

    this.setState(
      produce((draft: ForensicSearchModalState) => {
        draft.query.application = false
        draft.query.conversation = false
        draft.query.country = false
        draft.query.error = false
        draft.query.expert = false
        draft.query.files = false
        draft.query.graphs = false
        draft.query.history = false
        draft.query.log = false
        draft.query.mplsvlanvxlan = false
        draft.query.network = false
        draft.query.node = false
        draft.query.packets = false
        draft.query.passiveNameResolution = false
        draft.query.plugins = false
        draft.query.protocol = false
        draft.query.size = false
        draft.query.summary = false
        draft.query.topTalkers = false
        draft.query.voice = false
        draft.query.web = false
        if (wireless) {
          draft.query.wirelessChannel = false
          draft.query.wirelessNode = false
        }
        if (draft.query.pluginsList) {
          const compassIndex = draft.query.pluginsList.properties.findIndex(
            pobj => pobj.value === `{${CLSID_COMPASS}}`
          )
          if (compassIndex !== -1) {
            draft.query.pluginsList.properties.splice(compassIndex, 1)
          }
        }
      })
    )
  }

  getPluginsInfo = () => {
    // Return all "capture" plugins except compass
    const { engineCapabilities } = this.props
    if (engineCapabilities && engineCapabilities.pluginsInfo) {
      return engineCapabilities.pluginsInfo.filter(
        info =>
          !Array.isArray(info.categoryIds) ||
          info.categoryIds.includes(PluginCategory.PLUGIN_CATEGORY_PEEK_PLUGIN)
      )
    }
    return []
  }

  graphTimeToIndex = (time: number, graphSampleInterval: number, graphStartTime: number) => {
    if (time < graphStartTime || graphSampleInterval === 0) return -1
    const d = time - graphStartTime
    return Math.trunc(d / (graphSampleInterval * 1000))
  }

  onLoadGraphs = () => {
    const { engine, authToken } = this.props
    fetchGraphs(engine, authToken)
      .then(graphs => {
        if (Array.isArray(graphs.graphs)) {
          this.setState({ graphs: graphs.graphs })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onPacketsEstimate = () => {
    const { query } = this.state
    const { captureSessionId, startTime: queryStartTime, endTime: queryEndTime } = query

    if (captureSessionId !== -1 && queryStartTime && queryEndTime) {
      const { engine, authToken } = this.props
      fetchTimelineData(engine, authToken, captureSessionId as number, "utilization-mbps", 1)
        .then(timelineData => {
          if (
            timelineData &&
            Array.isArray(timelineData.data) &&
            timelineData.viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_MBITS
          ) {
            const graphStartTime = Date.parse(timelineData.startTime)
            const graphSampleInterval = timelineData.sampleInterval

            const startTime = Date.parse(queryStartTime)
            const endTime = Date.parse(queryEndTime)

            const startIndex =
              startTime < graphStartTime
                ? 0
                : this.graphTimeToIndex(startTime, graphSampleInterval, graphStartTime)
            const endIndex = Math.min(
              this.graphTimeToIndex(endTime, graphSampleInterval, graphStartTime),
              timelineData.data.length
            )
            let packetsEstimate = ""

            if (
              startIndex >= 0 &&
              startIndex <= timelineData.data.length &&
              endIndex >= 0 &&
              endIndex <= timelineData.data.length &&
              startIndex <= endIndex
            ) {
              let totalPackets = 0
              for (let i = 0; i < endIndex; ++i) {
                if (i >= startIndex && i < endIndex) {
                  totalPackets += timelineData.data[i].totalPackets
                }
              }
              if (totalPackets > 1000000000) {
                const d = totalPackets / 1000000000.0
                packetsEstimate += formatFloat(d, 2) + " B"
              } else if (totalPackets > 1000000) {
                const d = totalPackets / 1000000.0
                packetsEstimate += formatFloat(d, 2) + " M"
              } else if (totalPackets > 1000) {
                const d = totalPackets / 1000.0
                packetsEstimate += formatFloat(d, 2) + " K"
              } else {
                packetsEstimate += formatInteger(totalPackets)
              }
              packetsEstimate = "~" + packetsEstimate
            } else {
              packetsEstimate = "0"
            }

            this.setState({ packetsEstimate })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  onLoadFileList = () => {
    const { engine, authToken } = this.props
    fetchFilesList(engine, authToken)
      .then(response => {
        const { captureSessionId } = this.props
        const { query } = this.state

        const files =
          captureSessionId !== undefined
            ? response.rows.filter(
                row => row.SessionID !== undefined && row.SessionID === captureSessionId
              )
            : response.rows

        let hasFiles: boolean | null = null
        const startTime = query.startTime ? new Date(query.startTime) : undefined
        const endTime = query.endTime ? new Date(query.endTime) : undefined
        if (startTime && endTime) {
          if (startTime > endTime) {
            hasFiles = false
          } else {
            for (const file of files) {
              if (file.SessionStartTime !== undefined && file.SessionEndTime !== undefined) {
                const sessionStartValue = new Date(file.SessionStartTime)
                const sessionEndValue = new Date(file.SessionEndTime)
                hasFiles = !(
                  (startTime < sessionStartValue && endTime < sessionStartValue) ||
                  (startTime > sessionEndValue && endTime > sessionEndValue)
                )
                if (hasFiles) {
                  break
                }
              } else if (file.SessionStartTime !== undefined) {
                const sessionStartValue = new Date(file.SessionStartTime)
                hasFiles = startTime >= sessionStartValue || endTime >= sessionStartValue
                if (hasFiles) {
                  break
                }
              } else if (file.SessionEndTime !== undefined) {
                const sessionEndValue = new Date(file.SessionEndTime)
                hasFiles = startTime <= sessionEndValue || endTime <= sessionEndValue
                if (hasFiles) {
                  break
                }
              }
            }
          }
        }
        this.setState({
          hasFiles,
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  onChangeFilter = (filterExpression: string) => {
    this.setState(
      produce((draft: ForensicSearchModalState) => {
        draft.query.filter = filterExpression
      }),
      () => {
        this.validateFilterDebounced()
      }
    )
  }

  validateName = () => {
    const { engine, authToken } = this.props
    const requestForensicSearches = fetchForensicSearches(engine, authToken)
    const requestForensicSearchHistory = fetchForensicSearchHistory(engine, authToken)
    Promise.all([requestForensicSearches, requestForensicSearchHistory])
      .then(([forensicSearches, forensicSearchHistory]) => {
        if (
          Array.isArray(forensicSearches.forensicSearches) &&
          Array.isArray(forensicSearchHistory.forensicSearchHistory)
        ) {
          this.setState({
            query: {
              ...this.state.query,
              name: getUniqueForensicSearchName(
                this.state.query.name || "Forensic Search 1",
                forensicSearches.forensicSearches,
                forensicSearchHistory.forensicSearchHistory
              ),
            },
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  validateFilter = () => {
    if (this.state.query.filter !== undefined) {
      const { engine, authToken } = this.props
      postFilterConvert(engine, authToken, this.state.query.filter, true)
        .then(response => {
          if (!response.valid) {
            let errorMessage = ""
            if (Array.isArray(response.errorsParsing) && response.errorsParsing.length > 0) {
              errorMessage = `Character ${response.errorsParsing[0].position}: ${response.errorsParsing[0].message}`
            } else if (response.errorConversion) {
              errorMessage = response.errorConversion
            } else {
              errorMessage = "Error in filter expression"
            }
            this.setState({ filterBarError: errorMessage })
          } else {
            this.setState({ filterBarError: "" })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  validateFilterDebounced = debounce(this.validateFilter, 500)

  onPluginOptions = (info: EngineCapabilitiesPluginInfo) => {
    let pluginConfig: PluginOption | undefined = undefined
    if (this.state.query.pluginsConfig) {
      pluginConfig = this.state.query.pluginsConfig.plugins.find(item => item.clsid === info.clsid)
    }
    if (!pluginConfig) {
      pluginConfig = {
        clsid: info.clsid,
        name: info.name,
        options: "",
      }
    }
    this.setState({
      pluginConfig,
      pluginInfo: info,
      showPluginsOptions: true,
    })
  }

  onPluginOptionsCancel = () => {
    this.setState({
      pluginConfig: null,
      pluginInfo: null,
      showPluginsOptions: false,
    })
  }

  onPluginOptionsOK = (option: PluginOption) => {
    let pluginsConfig: PluginOption[] = []
    if (this.state.query.pluginsConfig) {
      pluginsConfig = this.state.query.pluginsConfig.plugins.filter(
        item => item.clsid !== option.clsid
      )
    }
    pluginsConfig.push(option)

    this.setState(
      produce((draft: ForensicSearchModalState) => {
        draft.query.pluginsConfig = {
          clsid: "862E83A1-BC76-479A-A7FD-06CD6451815A",
          plugins: [...pluginsConfig],
        }
        draft.pluginConfig = null
        draft.pluginInfo = null
        draft.showPluginsOptions = false
      })
    )
  }

  onOK = () => {
    if (!this.hasAnyAnalysis()) {
      this.setState({ showEmptyAnalysis: true })
    } else {
      if (this.state.query.filter !== undefined) {
        const { engine, authToken } = this.props
        postFilterConvert(engine, authToken, this.state.query.filter, true)
          .then(response => {
            if (!response.valid) {
              let errorMessage = ""
              if (Array.isArray(response.errorsParsing) && response.errorsParsing.length > 0) {
                errorMessage = `Character ${response.errorsParsing[0].position}: ${response.errorsParsing[0].message}`
              } else if (response.errorConversion) {
                errorMessage = response.errorConversion
              } else {
                errorMessage = "Error in filter expression"
              }
              this.setState({ filterBarError: errorMessage })
            } else {
              this.setState({ filterBarError: "" })
              if (this.state.query.filter) {
                this.props.pushRecentFilterBarExpression(this.state.query.filter)
              }
              this.saveQuery()
              const query = cloneDeep(this.state.query)
              if (query.pluginsList && !query.plugins) {
                query.plugins =
                  query.pluginsList.properties.findIndex(
                    po => po.value === `{${CLSID_COMPASS}}`
                  ) !== -1
              }
              this.props.onOK(query)
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    }
  }

  hasAnyAnalysis() {
    const { query } = this.state
    return (
      (query.plugins !== undefined && query.plugins) ||
      (query.application !== undefined && query.application) ||
      (query.conversation !== undefined && query.conversation) ||
      (query.country !== undefined && query.country) ||
      (query.error !== undefined && query.error) ||
      (query.expert !== undefined && query.expert) ||
      (query.files !== undefined && query.files) ||
      (query.history !== undefined && query.history) ||
      (query.log !== undefined && query.log) ||
      (query.graphs !== undefined && query.graphs) ||
      (query.mplsvlanvxlan !== undefined && query.mplsvlanvxlan) ||
      (query.network !== undefined && query.network) ||
      (query.node !== undefined && query.node) ||
      (query.packets !== undefined && query.packets) ||
      (query.passiveNameResolution !== undefined && query.passiveNameResolution) ||
      (query.plugins && query.pluginsList === undefined) ||
      (query.pluginsList !== undefined &&
        query.pluginsList.properties.findIndex(pobj => pobj.value === `{${CLSID_COMPASS}}`) !==
          -1) ||
      (query.protocol !== undefined && query.protocol) ||
      (query.size !== undefined && query.size) ||
      (query.voice !== undefined && query.voice) ||
      (query.web !== undefined && query.web) ||
      (query.wirelessChannel !== undefined && query.wirelessChannel) ||
      (query.wirelessNode !== undefined && query.wirelessNode)
    )
  }

  render() {
    const {
      onCancel,
      recentFilterBarExpressions,
      mediaType,
      mediaSubType,
      engineCapabilities,
      showLocalTime,
    } = this.props
    const { expertPrefs, filterBarError, packetsEstimate, query, hasFiles } = this.state

    const canDoAnalysis =
      !engineCapabilities ||
      !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchAnalysisACL) ||
      engineCapabilities.userRights.policies.includes(EngineUserPolicies.forensicSearchAnalysis)

    const wireless =
      mediaType !== undefined && mediaSubType !== undefined
        ? isWireless(mediaType, mediaSubType)
        : false

    const startTime = query.startTime ? new Date(query.startTime) : undefined
    const endTime = query.endTime ? new Date(query.endTime) : undefined
    const duration =
      query.startTime && query.endTime
        ? formatDurationRange(
            peekFromDate(Date.parse(query.startTime)),
            peekFromDate(Date.parse(query.endTime)),
            3
          )
        : undefined

    let compassPluginInfo: EngineCapabilitiesPluginInfo | undefined = undefined
    if (engineCapabilities && Array.isArray(engineCapabilities.pluginsInfo)) {
      compassPluginInfo = engineCapabilities.pluginsInfo.find(ecpi => ecpi.clsid === CLSID_COMPASS)
    }

    return (
      <Modal size="lg" isOpen={true} toggle={onCancel}>
        <ModalHeader toggle={onCancel}>Forensic Search</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Label for="name">Name</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    onChange={this.onChangeText}
                    value={query.name}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroup>
                    <Label for="start-date">Start&nbsp;time{showLocalTime ? "" : " (UTC)"}</Label>
                    <DateTimePicker
                      id="start-date"
                      selected={startTime ? startTime : new Date()}
                      onChange={this.onChangeStartTime}
                      showLocalTime={showLocalTime}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="end-date">End time{showLocalTime ? "" : " (UTC)"}</Label>
                    <DateTimePicker
                      id="end-date"
                      selected={endTime ? endTime : new Date()}
                      onChange={this.onChangeEndTime}
                      showLocalTime={showLocalTime}
                    />
                  </FormGroup>
                  <FormGroup>
                    <DateRangePresets
                      showLocalTime={showLocalTime}
                      onChangeTimes={this.onChangeTimes}
                      onRevertTimes={this.onRevertTimes}
                    />
                  </FormGroup>
                  {duration && (
                    <FormGroup>
                      <PropTable>
                        <tbody>
                          <tr>
                            <th>Duration</th>
                            <td>{duration}</td>
                          </tr>
                          <tr>
                            <th>Packets</th>
                            <td>{packetsEstimate}</td>
                          </tr>
                          {this.props.file && (
                            <tr>
                              <th>Files</th>
                              <td>1</td>
                            </tr>
                          )}
                        </tbody>
                      </PropTable>
                    </FormGroup>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="filterbar">Filter</Label>
                  <FilterBar
                    engineCapabilities={engineCapabilities}
                    filterBarExpression={query.filter ? query.filter : ""}
                    filterBarError={filterBarError}
                    recentFilterBarExpressions={recentFilterBarExpressions}
                    onChange={this.onChangeFilter}
                  />
                </FormGroup>
                {hasFiles === false && (
                  <FormGroup>
                    <ErrorText>
                      There may not be any packet files within the start and end time window
                    </ErrorText>
                  </FormGroup>
                )}
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label>Analysis &amp; Output</Label>
                  <HorizontalFormGroup noMargin>
                    <CheckGroup
                      type="checkbox"
                      name="plugins"
                      id="plugins"
                      checked={query.plugins !== undefined && query.plugins}
                      disabled={!canDoAnalysis}
                      onChange={this.onChangeCheckbox}
                    >
                      Analysis Modules
                    </CheckGroup>
                    <AnalysisOptionsButton
                      disabled={!canDoAnalysis || query.plugins === undefined || !query.plugins}
                      onClick={this.onPluginSettings}
                    />
                  </HorizontalFormGroup>
                  <CheckGroup
                    type="checkbox"
                    name="application"
                    id="application"
                    checked={query.application !== undefined && query.application}
                    disabled={!canDoAnalysis}
                    onChange={this.onChangeCheckbox}
                  >
                    Application Statistics
                  </CheckGroup>
                  {engineCapabilities?.capabilities.includes(EngineCapabilities.compass) && (
                    <HorizontalFormGroup key="compass-group" noMargin>
                      <CheckGroup
                        type="checkbox"
                        name="compass"
                        id="compass"
                        checked={
                          (query.pluginsList !== undefined &&
                            query.pluginsList.properties.findIndex(
                              pobj => pobj.value === `{${CLSID_COMPASS}}`
                            ) !== -1) ||
                          (query.plugins && query.pluginsList === undefined)
                        }
                        disabled={!canDoAnalysis}
                        onChange={this.onChangeCompass}
                      >
                        Compass
                      </CheckGroup>
                      {!!compassPluginInfo &&
                        Array.isArray(compassPluginInfo.categoryIds) &&
                        compassPluginInfo.categoryIds.includes(
                          PluginCategory.PLUGIN_CATEGORY_OMNI_WEB_PLUGIN_OPTIONS
                        ) && (
                          <AnalysisOptionsButton
                            onClick={this.onPluginOptions.bind(this, compassPluginInfo)}
                            disabled={
                              !canDoAnalysis ||
                              (query.pluginsList === undefined && !query.plugins) ||
                              (query.pluginsList !== undefined &&
                                query.pluginsList.properties.findIndex(
                                  pobj => pobj.value === `{${CLSID_COMPASS}}`
                                ) === -1)
                            }
                            aria-label={`${compassPluginInfo.name} options`}
                          />
                        )}
                    </HorizontalFormGroup>
                  )}
                  <CheckGroup
                    type="checkbox"
                    name="country"
                    id="country"
                    checked={query.country !== undefined && query.country}
                    disabled={!canDoAnalysis}
                    onChange={this.onChangeCheckbox}
                  >
                    Country Statistics
                  </CheckGroup>
                  <CheckGroup
                    type="checkbox"
                    name="error"
                    id="error"
                    checked={query.error !== undefined && query.error}
                    disabled={!canDoAnalysis}
                    onChange={this.onChangeCheckbox}
                  >
                    Error Statistics
                  </CheckGroup>
                  <CheckGroup
                    type="checkbox"
                    name="log"
                    id="log"
                    checked={query.log !== undefined && query.log}
                    disabled={!canDoAnalysis}
                    onChange={this.onChangeCheckbox}
                  >
                    Events
                  </CheckGroup>
                  <HorizontalFormGroup noMargin>
                    <CheckGroup
                      type="checkbox"
                      name="expert"
                      id="expert"
                      checked={query.expert !== undefined && query.expert}
                      disabled={!canDoAnalysis}
                      onChange={this.onChangeCheckbox}
                    >
                      Expert
                    </CheckGroup>
                    <AnalysisOptionsButton
                      disabled={!canDoAnalysis || query.expert === undefined || !query.expert}
                      onClick={this.onExpertSettings}
                    />
                  </HorizontalFormGroup>
                  <HorizontalFormGroup noMargin>
                    <CheckGroup
                      type="checkbox"
                      name="graphs"
                      id="graphs"
                      checked={query.graphs !== undefined && query.graphs}
                      disabled={!canDoAnalysis}
                      onChange={this.onChangeCheckbox}
                    >
                      Graphs
                    </CheckGroup>
                    <AnalysisOptionsButton
                      disabled={!canDoAnalysis || query.graphs === undefined || !query.graphs}
                      onClick={this.onGraphSettings}
                    />
                  </HorizontalFormGroup>
                  {engineCapabilities?.capabilities.includes(
                    EngineCapabilities.mplsVlanVxlanStats
                  ) && (
                    <HorizontalFormGroup noMargin>
                      <CheckGroup
                        type="checkbox"
                        name="mplsvlanvxlan"
                        id="mplsvlanvxlan"
                        checked={query.mplsvlanvxlan !== undefined && query.mplsvlanvxlan}
                        disabled={!canDoAnalysis}
                        onChange={this.onChangeCheckbox}
                      >
                        MPLS/VLAN/VXLAN Statistics
                      </CheckGroup>
                      <AnalysisOptionsButton
                        disabled={
                          !canDoAnalysis ||
                          query.mplsvlanvxlan === undefined ||
                          !query.mplsvlanvxlan
                        }
                        onClick={this.onMPLSVLANVXLANStatsLimit}
                      />
                    </HorizontalFormGroup>
                  )}
                  <CheckGroup
                    type="checkbox"
                    name="network"
                    id="network"
                    checked={query.network !== undefined && query.network}
                    disabled={!canDoAnalysis}
                    onChange={this.onChangeCheckbox}
                  >
                    Network Statistics
                  </CheckGroup>
                  <HorizontalFormGroup noMargin>
                    <CheckGroup
                      type="checkbox"
                      name="conversation"
                      id="conversation"
                      checked={query.conversation !== undefined && query.conversation}
                      disabled={!canDoAnalysis}
                      onChange={this.onChangeCheckbox}
                    >
                      Node Detail Statistics
                    </CheckGroup>
                    <AnalysisOptionsButton
                      disabled={
                        !canDoAnalysis || query.conversation === undefined || !query.conversation
                      }
                      onClick={this.onConversationStatsLimit}
                    />
                  </HorizontalFormGroup>
                  <HorizontalFormGroup noMargin>
                    <CheckGroup
                      type="checkbox"
                      name="node"
                      id="node"
                      checked={query.node !== undefined && query.node}
                      disabled={!canDoAnalysis}
                      onChange={this.onChangeCheckbox}
                    >
                      Node Statistics
                    </CheckGroup>
                    <AnalysisOptionsButton
                      disabled={!canDoAnalysis || query.node === undefined || !query.node}
                      onClick={this.onNodeStatsLimit}
                    />
                  </HorizontalFormGroup>
                  <CheckGroup
                    type="checkbox"
                    name="packets"
                    id="packets"
                    checked={query.packets !== undefined && query.packets}
                    onChange={this.onChangeCheckbox}
                  >
                    Packets
                  </CheckGroup>
                  <CheckGroup
                    type="checkbox"
                    name="passiveNameResolution"
                    id="passiveNameResolution"
                    checked={
                      query.passiveNameResolution !== undefined && query.passiveNameResolution
                    }
                    disabled={!canDoAnalysis}
                    onChange={this.onChangeCheckbox}
                  >
                    Passive Name Resolution
                  </CheckGroup>
                  <HorizontalFormGroup noMargin>
                    <CheckGroup
                      type="checkbox"
                      name="protocol"
                      id="protocol"
                      checked={query.protocol !== undefined && query.protocol}
                      disabled={!canDoAnalysis}
                      onChange={this.onChangeCheckbox}
                    >
                      Protocol Statistics
                    </CheckGroup>
                    <AnalysisOptionsButton
                      disabled={!canDoAnalysis || query.protocol === undefined || !query.protocol}
                      onClick={this.onProtocolStatsLimit}
                    />
                  </HorizontalFormGroup>
                  {engineCapabilities &&
                    engineCapabilities.capabilities.includes(
                      EngineCapabilities.reconstructions
                    ) && (
                      <CheckGroup
                        type="checkbox"
                        name="files"
                        id="files"
                        checked={query.files !== undefined && query.files}
                        disabled={!canDoAnalysis}
                        onChange={this.onChangeCheckbox}
                      >
                        Reconstructions
                      </CheckGroup>
                    )}
                  <CheckGroup
                    type="checkbox"
                    name="size"
                    id="size"
                    checked={query.size !== undefined && query.size}
                    disabled={!canDoAnalysis}
                    onChange={this.onChangeCheckbox}
                  >
                    Size Statistics
                  </CheckGroup>
                  {/*<CheckGroup
                    type="checkbox"
                    name="summary"
                    id="summary"
                    checked={query.summary !== undefined && query.summary}
                    disabled={!canDoAnalysis}
                    onChange={this.onChangeCheckbox}
                  >
                    Summary Statistics
                  </CheckGroup>*/}
                  <CheckGroup
                    type="checkbox"
                    name="history"
                    id="history"
                    checked={query.history !== undefined && query.history}
                    disabled={!canDoAnalysis}
                    onChange={this.onChangeCheckbox}
                  >
                    Traffic History Statistics
                  </CheckGroup>
                  <HorizontalFormGroup noMargin>
                    <CheckGroup
                      type="checkbox"
                      name="voice"
                      id="voice"
                      checked={query.voice !== undefined && query.voice}
                      disabled={!canDoAnalysis}
                      onChange={this.onChangeCheckbox}
                    >
                      Voice &amp; Video
                    </CheckGroup>
                    <AnalysisOptionsButton
                      disabled={!canDoAnalysis || query.voice === undefined || !query.voice}
                      onClick={this.onVoIPSettings}
                    />
                  </HorizontalFormGroup>
                  {engineCapabilities &&
                    engineCapabilities.capabilities.includes(
                      EngineCapabilities.remoteWebAnalysis
                    ) && (
                      <CheckGroup
                        type="checkbox"
                        name="web"
                        id="web"
                        checked={query.web !== undefined && query.web}
                        disabled={!canDoAnalysis}
                        onChange={this.onChangeCheckbox}
                      >
                        Web Analysis
                      </CheckGroup>
                    )}
                  <CheckGroup
                    type="checkbox"
                    name="wirelessChannel"
                    id="wirelessChannel"
                    checked={query.wirelessChannel !== undefined && query.wirelessChannel}
                    disabled={!wireless || !canDoAnalysis}
                    onChange={this.onChangeCheckbox}
                  >
                    Wireless Channel Statistics
                  </CheckGroup>
                  <CheckGroup
                    type="checkbox"
                    name="wirelessNode"
                    id="wirelessNode"
                    checked={query.wirelessNode !== undefined && query.wirelessNode}
                    disabled={!wireless || !canDoAnalysis}
                    onChange={this.onChangeCheckbox}
                  >
                    Wireless Node Statistics
                  </CheckGroup>
                </FormGroup>
                <ButtonBar>
                  <LightButton size="sm" disabled={!canDoAnalysis} onClick={this.onEnableAll}>
                    Enable All
                  </LightButton>
                  <LightButton size="sm" disabled={!canDoAnalysis} onClick={this.onDisableAll}>
                    Disable All
                  </LightButton>
                  <UncontrolledDropdown group>
                    <LightDropdownToggle disabled={!canDoAnalysis} size="sm" caret>
                      Presets
                    </LightDropdownToggle>
                    <DropdownMenu>
                      <DropdownItem name="overview" onClick={this.onPreset}>
                        Overview
                      </DropdownItem>
                      <DropdownItem name="packets" onClick={this.onPreset}>
                        Packets
                      </DropdownItem>
                      <DropdownItem name="expert" onClick={this.onPreset}>
                        Expert
                      </DropdownItem>
                      <DropdownItem name="voip" onClick={this.onPreset}>
                        Voice &amp; Video
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </ButtonBar>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton onClick={this.onOK}>Start</PrimaryButton>
        </ModalFooter>
        {this.state.showEmptyAnalysis && (
          <MessageModal
            show={true}
            message="Please choose at least one analysis option."
            onOK={() => this.setState({ showEmptyAnalysis: false })}
            title="No Analysis Selected"
          />
        )}
        {this.state.showExpertSettingsModal &&
          expertPrefs &&
          Array.isArray(expertPrefs.descriptions) &&
          Array.isArray(expertPrefs.layers) &&
          expertPrefs.settings && (
            <ExpertSettingsModal
              expertDescriptions={expertPrefs.descriptions}
              expertLayers={expertPrefs.layers}
              expertSettings={expertPrefs.settings}
              maxStreamCountMax={5000000}
              type={"forensic-searches"}
              onCancel={this.onExpertSettingsCancel}
              onOK={this.onExpertSettingsOK}
              engine={this.props.engine}
              authToken={this.props.authToken}
            />
          )}
        {this.state.showGraphListModal && Array.isArray(this.state.graphs) && (
          <GraphOptionsModal
            graphs={this.state.graphs}
            graphSettings={query.graphSettings ? query.graphSettings : undefined}
            onOK={this.onGraphSettingsOK}
            onCancel={this.onGraphSettingsCancel}
          />
        )}
        {this.state.showPluginListModal && this.props.engineCapabilities && (
          <PluginsListModal
            compassEnabled={
              query.pluginsList !== undefined &&
              query.pluginsList.properties.findIndex(
                pobj => pobj.value === `{${CLSID_COMPASS}}`
              ) !== -1
            }
            pluginsConfig={query.pluginsConfig ? query.pluginsConfig.plugins : undefined}
            pluginsInfo={this.getPluginsInfo()}
            pluginsList={
              query.pluginsList
                ? query.pluginsList.properties.filter(pobj => pobj.value !== `{${CLSID_COMPASS}}`)
                : undefined
            }
            onOK={this.onPluginSettingsOK}
            onCancel={this.onPluginSettingsCancel}
          />
        )}
        {this.state.showMPLSVLANVXLANStatsLimitModal && (
          <StatsLimitModal
            title="MPLS/VLAN/VXLAN Statistics Limits"
            baseName="mplsVlanVxlanStatistics"
            statsLimit={this.getMPLSVLANVXLANLimitStatisticSettings()}
            onOK={this.onMPLSVLANVXLANStatsLimitOK}
            onCancel={this.onMPLSVLANVXLANStatsLimitCancel}
          />
        )}
        {this.state.showNodeStatsLimitModal && (
          <StatsLimitModal
            title="Node Statistics Limits"
            baseName="nodeStatistics"
            statsLimit={this.getNodeLimitStatisticSettings()}
            onOK={this.onNodeStatsLimitOK}
            onCancel={this.onNodeStatsLimitCancel}
          />
        )}
        {this.state.showProtocolStatsLimitModal && (
          <StatsLimitModal
            title="Protocol Statistics Limits"
            baseName="protocolStatistics"
            statsLimit={this.getProtocolLimitStatisticSettings()}
            onOK={this.onProtocolStatsLimitOK}
            onCancel={this.onProtocolStatsLimitCancel}
          />
        )}
        {this.state.showConversationStatsLimitModal && (
          <StatsLimitModal
            title="Node Detail Statistics Limits"
            baseName="nodeProtocolDetailStatistics"
            statsLimit={this.getConversationLimitStatisticSettings()}
            onOK={this.onConversationStatsLimitOK}
            onCancel={this.onConversationStatsLimitCancel}
          />
        )}
        {this.state.showVoIPConfigModal && (
          <VoIPConfigModal
            voipConfig={this.getVoIPConfig()}
            onOK={this.onVoIPSettingsOK}
            onCancel={this.onVoIPSettingsCancel}
          />
        )}
        {this.state.showPluginsOptions && this.state.pluginConfig && this.state.pluginInfo && (
          <PluginOptionsModal
            contextId={"{00000000-0000-0000-0000-000000000000}"}
            pluginConfig={this.state.pluginConfig}
            pluginInfo={this.state.pluginInfo}
            onOK={this.onPluginOptionsOK}
            onCancel={this.onPluginOptionsCancel}
          />
        )}
      </Modal>
    )
  }
}

const mapStateToProps = (state: any) => ({
  authToken: getAuthToken(state),
  engine: getEngine(state),
  engineCapabilities: getCapabilities(state),
  recentFilterBarExpressions: getRecentFilterBarExpressions(state),
  showLocalTime: getShowLocalTime(state),
})

const mapDisptachToProps = {
  pushRecentFilterBarExpression,
}

export default connect(mapStateToProps, mapDisptachToProps)(ForensicSearchModal)
