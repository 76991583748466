import * as React from "react"
import { FormGroup } from "reactstrap"
import FontAwesome from "react-fontawesome"
import { TableCellProps, TableRowProps } from "react-virtualized"
import styled, { DefaultTheme, withTheme } from "styled-components"
import cn from "classnames"
import FileSaver from "file-saver"
import { produce } from "immer"
import { cloneDeep, isEqual, toNumber } from "lodash"
import { MaximumFlowsSlider } from "./MaximumFlowsSlider"
import ProblemEditSidebar from "./ProblemEditSidebar"
import { Alert } from "../common/Alert"
import {
  CloseButton,
  FileInputButton,
  IconButton,
  LightButton,
  PrimaryButton,
  SecondaryButton,
} from "../common/Buttons"
import { FilterBox } from "../common/FilterBox"
import { CellCheckGroup, CellCheckGroupIndeterminate, Label } from "../common/Form"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../common/Modal"
import { OmniTable } from "../common/OmniTable"
import { Select } from "../common/Select"
import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarTitle,
  SidebarContent,
} from "../common/Sidebar"
import { ViewHeaderButtons } from "../common/View"
import {
  ExpertDescription,
  ExpertExecuteRequest,
  ExpertLayerMapping,
  ExpertPolicy,
  ExpertPolicyAuthenticationItem,
  ExpertPolicyChannelItem,
  ExpertPolicyChannelFamilyItem,
  ExpertPolicyEncryptionItem,
  ExpertPolicyESSIDItem,
  ExpertPolicyVendorIdItem,
  ExpertProblemObject,
  ExpertQueryRequest,
  ExpertResponse,
  ExpertSettings,
  ExpertValue,
  ExpertPreferencesSet,
} from "../../api/types"
import {
  ExpertColumn,
  ExpertCounterID,
  ExpertLayer,
  ExpertOperation,
  ExpertProblem,
  ExpertSensitivity,
  ExpertSettingGroupId,
  ExpertSeverity,
  ExpertThroughputUnits,
  ExpertTimeUnit,
  ExpertView,
  isValidExpertLayer,
  isValidExpertProblem,
  isValidExpertSensitivity,
  isValidExpertSettingGroupId,
  isValidExpertSeverity,
  isValidExpertTimeUnit,
} from "../../api/types/expertTypes"
import { Wireless80211Bands } from "../../api/types/packetTypes"
import { ColorBy, TimeStampFormat } from "../../api/types/peekTypes"
import {
  formatWirelessChannel,
  getWirelessBandString,
  scanfWirelessChannel,
  stringToBand,
} from "../../utils/channelUtils"
import {
  authenticationTypeToString,
  encryptionTypeToString,
  expertCounterIdToString,
  expertSeverityToString,
  isValidExpertSettingsFile,
  stringToAuthenticationType,
  stringToEncryptionType,
} from "../../utils/expertUtils"
import { collator } from "../../utils/sortUtils"
import { postExpertPreferences } from "../../api/api"

const ProblemTableContainer = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;
  height: 200px;
`

const ProblemTableHeader = styled(ViewHeaderButtons)`
  margin-bottom: 0.5rem;
`

const ProblemInfoIFrame = styled.iframe`
  width: 100%;
  height: 180px;
  background-color: ${props => props.theme.tableBackgroundColor};
  border: ${props => props.theme.tableBorder};
`

export interface ExpertSettingsEx {
  maxStreamCountMax: number
  settings: ExpertSettings
}

export const eventFinderViews: ExpertView[] = [
  ExpertView.EXPERT_VIEW_HEADER_COUNTERS,
  ExpertView.EXPERT_VIEW_EVENT_DESCRIPTIONS,
  ExpertView.EXPERT_VIEW_EVENT_LAYER_MAPPING,
  ExpertView.EXPERT_VIEW_EVENT_SETTINGS,
  ExpertView.EXPERT_VIEW_POLICY_SETTINGS,
  ExpertView.EXPERT_VIEW_POLICY_ITEMS,
]

export const queryEventFinder: ExpertQueryRequest = {
  columnList: [],
  treeExpand: {
    defaultState: "collapse-all",
    exceptionList: [],
  },
  view: ExpertView.EXPERT_VIEW_NONE,
  viewSettings: {
    ba: "<->",
    colorBy: ColorBy.COLOR_BY_INDEPENDENT,
    la: "<--",
    ra: "-->",
    showAddressNames: false,
    showNumberSeparators: false,
    showPortNames: false,
    showLocalTime: false,
    suppressGrid: false,
    throughputUnits: ExpertThroughputUnits.EXPERT_THROUGHPUT_UNITS_BITS_PER_SECOND,
    timestampPrecision: TimeStampFormat.TIMESTAMP_NANOSECONDS,
    utc: false,
  },
}

export function expertResponseToExpertDescriptions(
  responses: ExpertResponse[]
): ExpertDescription[] {
  const expertDescriptions: (ExpertDescription | null)[] = []

  if (Array.isArray(responses)) {
    const descriptionResult = responses.find(
      (response: ExpertResponse) => response.view === ExpertView.EXPERT_VIEW_EVENT_DESCRIPTIONS
    )
    if (descriptionResult !== undefined && Array.isArray(descriptionResult.rowList)) {
      descriptionResult.rowList.forEach((resultRow: ExpertValue[]) => {
        let description: any = {}
        const columnCount = Math.min(descriptionResult.columnList.length, resultRow.length)
        for (let c = 0; c < columnCount; c++) {
          switch (descriptionResult.columnList[c]) {
            case ExpertColumn.EXPERT_COLUMN_PROBLEM_ID: {
              const valueProblemId = Number(resultRow[c].value)
              if (isValidExpertProblem(valueProblemId)) {
                description.problemId = valueProblemId as ExpertProblem
              } else {
                description = {}
                c = columnCount
              }
              break
            }
            case ExpertColumn.EXPERT_COLUMN_EVENT_GUID:
              description.guid = String(resultRow[c].value).replace(/[{}]+/g, "")
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_NAME:
              description.name = String(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_SENSITIVITY:
              description.hasSensitivity = Number(resultRow[c].value) !== 0
              break
            case ExpertColumn.EXPERT_COLUMN_PROTOCOL_LAYER: {
              const valueLayer = Number(resultRow[c].value)
              if (isValidExpertLayer(valueLayer)) {
                description.layer = valueLayer as ExpertLayer
              }
              break
            }
            case ExpertColumn.EXPERT_COLUMN_EVENT_MESSAGE:
              description.message = String(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_VALUE_SHOWN:
              description.hasValue = Number(resultRow[c].value) !== 0
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_VALUE_UNIT:
              description.valueUnits = String(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_VALUE_MIN:
              description.valueMin = Number(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_VALUE_MAX:
              description.valueMax = Number(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_ASSIST_SHOWN:
              description.hasValueAssist = Number(resultRow[c].value) !== 0
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_ASSIST_LEFT:
              description.valueAssistLeft = Number(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_ASSIST_RIGHT:
              description.valueAssistRight = Number(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_ASSIST_LOGSCALE:
              description.valueAssistLogScale = Number(resultRow[c].value) !== 0
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_MINPERIOD_SHOWN:
              description.hasMinSamplePeriod = Number(resultRow[c].value) !== 0
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_MINPERIOD_UNITS: {
              const valueMinSamplePeriodUnits = Number(resultRow[c].value)
              if (isValidExpertTimeUnit(valueMinSamplePeriodUnits)) {
                description.minSamplePeriodUnits = valueMinSamplePeriodUnits as ExpertTimeUnit
              }
              break
            }
            case ExpertColumn.EXPERT_COLUMN_EVENT_MINPERIOD_MIN:
              description.minSamplePeriodMin = Number(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_MINPERIOD_MAX:
              description.minSamplePeriodMax = Number(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_GROUP:
              description.group = String(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_SUB_GROUP:
              description.subGroup = String(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_CONFIGURE_SHOWN:
              description.hasConfigure = Number(resultRow[c].value) !== 0
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_FORMAT:
              description.valueDisplayFormat = String(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_EVENT_MULTIPLIER:
              description.valueDisplayMultiplier = Number(resultRow[c].value)
              break
            default:
              break
          }
        }

        if (description.layer === undefined) {
          description.layer = ExpertLayer.EXPERT_LAYER_UNKNOWN
        }
        if (description.problemId !== undefined) {
          while (expertDescriptions.length <= description.problemId) {
            expertDescriptions.push(null)
          }
          expertDescriptions[description.problemId] = description
        }
      })
    }
  }

  return expertDescriptions.filter(
    (description: any) =>
      description !== null &&
      description.group !== undefined &&
      description.guid !== undefined &&
      description.hasConfigure !== undefined &&
      description.hasMinSamplePeriod !== undefined &&
      description.hasSensitivity !== undefined &&
      description.hasValue !== undefined &&
      description.hasValueAssist !== undefined &&
      description.layer !== undefined &&
      description.message !== undefined &&
      description.name !== undefined &&
      description.problemId !== undefined &&
      description.subGroup !== undefined &&
      description.valueDisplayFormat !== undefined &&
      description.valueDisplayMultiplier !== undefined &&
      description.problemId !== ExpertProblem.EXPERT_PROBLEM_ID_NONE
  ) as ExpertDescription[]
}

export function expertResponseToExpertLayers(responses: ExpertResponse[]): ExpertLayerMapping[] {
  let expertLayers: ExpertLayerMapping[] = []

  if (Array.isArray(responses)) {
    const layerMapResult = responses.find(
      (response: ExpertResponse) => response.view === ExpertView.EXPERT_VIEW_EVENT_LAYER_MAPPING
    )
    if (layerMapResult !== undefined && Array.isArray(layerMapResult.rowList)) {
      expertLayers = layerMapResult.rowList.map((resultRow: ExpertValue[]): ExpertLayerMapping => {
        let id = ExpertLayer.EXPERT_LAYER_UNKNOWN
        let layer = ""
        const columnCount = Math.min(layerMapResult.columnList.length, resultRow.length)
        for (let c = 0; c < columnCount; c++) {
          switch (layerMapResult.columnList[c]) {
            case ExpertColumn.EXPERT_COLUMN_EVENT_LAYER_ID: {
              const value = Number(resultRow[c].value)
              if (isValidExpertLayer(value)) {
                id = value as ExpertLayer
              } else {
                id = ExpertLayer.EXPERT_LAYER_UNKNOWN
                c = columnCount
              }
              break
            }
            case ExpertColumn.EXPERT_COLUMN_EVENT_LAYER_VALUE:
              layer = String(resultRow[c].value)
              break
            default:
              break
          }
        }
        return { id, layer }
      })
    }
  }

  return expertLayers.filter(
    (layer: ExpertLayerMapping) => layer.id !== ExpertLayer.EXPERT_LAYER_UNKNOWN
  )
}

function expertSettingsToMaxStreamCounts(responses: ExpertResponse[]): number[] {
  const maxStreamCounts: number[] = [1, 1]

  if (Array.isArray(responses)) {
    const headerCountersResult = responses.find(
      (response: ExpertResponse) => response.view === ExpertView.EXPERT_VIEW_HEADER_COUNTERS
    )
    if (headerCountersResult !== undefined && Array.isArray(headerCountersResult.rowList)) {
      let counterValueResults: any[] = []
      headerCountersResult.rowList.forEach((resultRow: ExpertValue[]) => {
        const counterValue: any = {}
        const valueCount = Math.min(headerCountersResult.columnList.length, resultRow.length)
        for (let c = 0; c < valueCount; c++) {
          switch (headerCountersResult.columnList[c]) {
            case ExpertColumn.EXPERT_COLUMN_NAME:
              counterValue.id = String(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_VALUE:
              counterValue.value = Number(resultRow[c].value)
              break
            default:
              break
          }
        }
        counterValueResults.push(counterValue)
      })
      counterValueResults = counterValueResults.filter(
        (counterValue: any) => counterValue.id !== undefined && counterValue.value !== undefined
      )
      counterValueResults.forEach((counterValue: any) => {
        switch (counterValue.id) {
          case "MAX_STREAM_COUNT":
            maxStreamCounts[0] = counterValue.value
            break
          case "MAX_STREAM_COUNT_MAX":
            maxStreamCounts[1] = counterValue.value
            break
          default:
            break
        }
      })
    }
  }

  return maxStreamCounts
}

function expertSettingsToPolicies(responses: ExpertResponse[]): ExpertPolicy[] {
  const expertPolicies: ExpertPolicy[] = [
    {
      authentication: {
        accept: false,
        items: [],
      },
      channel: {
        accept: false,
        channelBand: "",
        channelFamily: [],
      },
      encryption: {
        accept: false,
        items: [],
      },
      essId: {
        accept: false,
        items: [],
      },
      vendorId: {
        accept: false,
        items: [],
      },
    },
    {
      authentication: {
        accept: false,
        items: [],
      },
      channel: {
        accept: false,
        channelBand: "",
        channelFamily: [],
      },
      encryption: {
        accept: false,
        items: [],
      },
      essId: {
        accept: false,
        items: [],
      },
      vendorId: {
        accept: false,
        items: [],
      },
    },
  ]

  if (Array.isArray(responses)) {
    const policySettingsResult = responses.find(
      (response: ExpertResponse) => response.view === ExpertView.EXPERT_VIEW_POLICY_SETTINGS
    )
    if (policySettingsResult !== undefined && Array.isArray(policySettingsResult.rowList)) {
      const policySettings: any[] = []
      policySettingsResult.rowList.forEach((resultRow: ExpertValue[]) => {
        const policySetting: any = {}
        const columnCount = Math.min(policySettingsResult.columnList.length, resultRow.length)
        for (let c = 0; c < columnCount; c++) {
          switch (policySettingsResult.columnList[c]) {
            case ExpertColumn.EXPERT_COLUMN_SETTINGS_GROUP_ID: {
              const valueSettingsGroupId = Number(resultRow[c].value)
              if (isValidExpertSettingGroupId(valueSettingsGroupId)) {
                policySetting.settingGroupId = valueSettingsGroupId as ExpertSettingGroupId
              }
              break
            }
            case ExpertColumn.EXPERT_COLUMN_PROBLEM_ID: {
              const valueProblemId = Number(resultRow[c].value)
              if (isValidExpertProblem(valueProblemId)) {
                policySetting.id = valueProblemId as ExpertProblem
              }
              break
            }
            case ExpertColumn.EXPERT_COLUMN_ACCEPT_MATCHING:
              policySetting.accept = Number(resultRow[c].value) !== 0
              break
            case ExpertColumn.EXPERT_COLUMN_AUX1:
              policySetting.aux = String(resultRow[c].value)
              break
            default:
              break
          }
        }
        if (
          policySetting.settingGroupId !== undefined &&
          policySetting.id !== undefined &&
          policySetting.accept !== undefined
        ) {
          policySettings.push(policySetting)
        }
      })
      policySettings.forEach((policySetting: any) => {
        let index = -1
        switch (policySetting.settingGroupId) {
          case ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT:
            index = 0
            break
          case ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT:
            index = 1
            break
          default:
            break
        }
        if (index !== -1) {
          switch (policySetting.id) {
            case ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_VENDOR_ID:
              expertPolicies[index].vendorId.accept = policySetting.accept
              break
            case ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_CHANNEL: {
              let channelBand = "802.11"
              if (policySetting.aux !== undefined) {
                channelBand += getWirelessBandString(
                  toNumber(policySetting.aux) as Wireless80211Bands
                )
              }
              expertPolicies[index].channel.accept = policySetting.accept
              expertPolicies[index].channel.channelBand = channelBand
              break
            }
            case ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_ESSID:
              expertPolicies[index].essId.accept = policySetting.accept
              break
            case ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_ENCRYPTION:
              expertPolicies[index].encryption.accept = policySetting.accept
              break
            case ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_AUTHENTICATION:
              expertPolicies[index].authentication.accept = policySetting.accept
              break
            default:
              break
          }
        }
      })
    }

    const policyItemsResult = responses.find(
      (response: ExpertResponse) => response.view === ExpertView.EXPERT_VIEW_POLICY_ITEMS
    )
    if (policyItemsResult !== undefined && Array.isArray(policyItemsResult.rowList)) {
      const policyItems: any[] = []
      policyItemsResult.rowList.forEach((resultRow: ExpertValue[]) => {
        const policyItem: any = {}
        const columnCount = Math.min(policyItemsResult.columnList.length, resultRow.length)
        for (let c = 0; c < columnCount; c++) {
          switch (policyItemsResult.columnList[c]) {
            case ExpertColumn.EXPERT_COLUMN_SETTINGS_GROUP_ID: {
              const valueSettingsGroupId = Number(resultRow[c].value)
              if (isValidExpertSettingGroupId(valueSettingsGroupId)) {
                policyItem.settingGroupId = valueSettingsGroupId as ExpertSettingGroupId
              }
              break
            }
            case ExpertColumn.EXPERT_COLUMN_PROBLEM_ID: {
              const valueProblemId = Number(resultRow[c].value)
              if (isValidExpertProblem(valueProblemId)) {
                policyItem.id = valueProblemId as ExpertProblem
              }
              break
            }
            case ExpertColumn.EXPERT_COLUMN_SETTINGS_ENABLED:
              policyItem.enabled = Number(resultRow[c].value) !== 0
              break
            case ExpertColumn.EXPERT_COLUMN_AUX1:
              policyItem.aux = Number(resultRow[c].value)
              break
            case ExpertColumn.EXPERT_COLUMN_VALUE:
              policyItem.value = String(resultRow[c].value)
              break
            default:
              break
          }
        }
        if (
          policyItem.settingGroupId !== undefined &&
          policyItem.id !== undefined &&
          policyItem.enabled !== undefined &&
          policyItem.value !== undefined
        ) {
          policyItems.push(policyItem)
        }
      })
      policyItems.forEach((policyItem: any) => {
        let index = -1
        switch (policyItem.settingGroupId) {
          case ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT:
            index = 0
            break
          case ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT:
            index = 1
            break
          default:
            break
        }
        if (index !== -1) {
          switch (policyItem.id) {
            case ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_VENDOR_ID:
              expertPolicies[index].vendorId.items.push({
                accessPoint: policyItem.enabled,
                client: policyItem.aux !== 0,
                value: policyItem.value,
              })
              break
            case ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_CHANNEL: {
              const channel = scanfWirelessChannel(policyItem.value)
              while (expertPolicies[index].channel.channelFamily.length <= policyItem.aux) {
                expertPolicies[index].channel.channelFamily.push({ items: [] })
              }
              expertPolicies[index].channel.channelFamily[policyItem.aux].items.push({
                ...channel,
                enabled: policyItem.enabled,
              })
              break
            }
            case ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_ESSID:
              expertPolicies[index].essId.items.push({
                enabled: policyItem.enabled,
                value: policyItem.value,
              })
              break
            case ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_ENCRYPTION:
              expertPolicies[index].encryption.items.push({
                enabled: policyItem.enabled,
                type: stringToEncryptionType(policyItem.value),
              })
              break
            case ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_AUTHENTICATION:
              expertPolicies[index].authentication.items.push({
                enabled: policyItem.enabled,
                type: stringToAuthenticationType(policyItem.value),
              })
              break
            default:
              break
          }
        }
      })
    }
  }

  return expertPolicies
}

function expertSettingsToProblems(responses: ExpertResponse[]): ExpertProblemObject[][] {
  const expertProblems: (ExpertProblemObject | null)[][] = [[], []]

  if (Array.isArray(responses)) {
    const settingsResult = responses.find(
      (response: ExpertResponse) => response.view === ExpertView.EXPERT_VIEW_EVENT_SETTINGS
    )
    if (settingsResult !== undefined && Array.isArray(settingsResult.rowList)) {
      const mergedExpertProblems: any[] = []
      settingsResult.rowList.forEach((resultRow: ExpertValue[]) => {
        const expertProblem: any = {}
        const columnCount = Math.min(settingsResult.columnList.length, resultRow.length)
        for (let c = 0; c < columnCount; c++) {
          switch (settingsResult.columnList[c]) {
            case ExpertColumn.EXPERT_COLUMN_SETTINGS_GROUP_ID: {
              const valueSettingGroupId = Number(resultRow[c].value)
              if (isValidExpertSettingGroupId(valueSettingGroupId)) {
                expertProblem.settingGroupId = valueSettingGroupId as ExpertSettingGroupId
              }
              break
            }
            case ExpertColumn.EXPERT_COLUMN_PROBLEM_ID: {
              const valueProblemId = Number(resultRow[c].value)
              if (isValidExpertProblem(valueProblemId)) {
                expertProblem.id = valueProblemId as ExpertProblem
              }
              break
            }
            case ExpertColumn.EXPERT_COLUMN_SETTINGS_ENABLED:
              expertProblem.enabled = Number(resultRow[c].value) !== 0
              break
            case ExpertColumn.EXPERT_COLUMN_SETTINGS_SEVERITY: {
              const valueSeverity = Number(resultRow[c].value)
              if (isValidExpertSeverity(valueSeverity)) {
                expertProblem.severity = valueSeverity as ExpertSeverity
              }
              break
            }
            case ExpertColumn.EXPERT_COLUMN_SETTINGS_SENSITIVITY: {
              const valueSensitivity = Number(resultRow[c].value)
              if (isValidExpertSensitivity(valueSensitivity)) {
                expertProblem.sensitivity = valueSensitivity as ExpertSensitivity
              }
              break
            }
            case ExpertColumn.EXPERT_COLUMN_SETTINGS_MINPERIOD:
              if (resultRow[c].value !== null) {
                expertProblem.minimumSample = Number(resultRow[c].value)
              } else {
                expertProblem.minimumSample = -1
              }
              break
            case ExpertColumn.EXPERT_COLUMN_SETTINGS_VALUE:
              expertProblem.value = Number(resultRow[c].value)
              break
            default:
              break
          }
        }
        if (
          expertProblem.enabled !== undefined &&
          expertProblem.id !== undefined &&
          expertProblem.minimumSample !== undefined &&
          expertProblem.sensitivity !== undefined &&
          expertProblem.settingGroupId !== undefined &&
          expertProblem.severity !== undefined &&
          expertProblem.value !== undefined
        ) {
          mergedExpertProblems.push(expertProblem)
        }
      })

      mergedExpertProblems.forEach((expertProblem: any) => {
        let index = -1
        switch (expertProblem.settingGroupId) {
          case ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT:
            index = 0
            break
          case ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT:
            index = 1
            break
          default:
            break
        }
        if (index !== -1) {
          while (expertProblems[index].length <= expertProblem.id) {
            expertProblems[index].push(null)
          }
          expertProblems[index][expertProblem.id] = expertProblem
        }
      })
    }
  }

  return expertProblems.map(
    (expertProblem: (ExpertProblemObject | null)[]) =>
      expertProblem.filter(
        (problem: ExpertProblemObject | null) => problem !== null
      ) as ExpertProblemObject[]
  )
}

export function expertResponseToExpertSettings(
  responses: ExpertResponse[]
): ExpertSettingsEx | null {
  const maxStreamCounts: number[] = expertSettingsToMaxStreamCounts(responses)
  const expertPolicies: ExpertPolicy[] = expertSettingsToPolicies(responses)
  const expertProblems: ExpertProblemObject[][] = expertSettingsToProblems(responses)

  if (
    Array.isArray(maxStreamCounts) &&
    maxStreamCounts.length === 2 &&
    Array.isArray(expertPolicies) &&
    expertPolicies.length === 2 &&
    Array.isArray(expertProblems) &&
    expertProblems.every((expertProblemList: ExpertProblemObject[]) =>
      Array.isArray(expertProblemList)
    ) &&
    expertProblems.length === 2
  ) {
    return {
      maxStreamCountMax: maxStreamCounts[1],
      settings: {
        current: {
          maxStreamCount: maxStreamCounts[0],
          policy: expertPolicies[0],
          problems: expertProblems[0],
        },
        _default: {
          maxStreamCount: maxStreamCounts[0],
          policy: expertPolicies[1],
          problems: expertProblems[1],
        },
      },
    }
  } else {
    return null
  }
}

export function expertSettingsToExpertRequests(
  expertSettings: ExpertSettings
): ExpertExecuteRequest[] {
  const requests: ExpertExecuteRequest[] = []

  // write max stream count to ExpertView.EXPERT_VIEW_HEADER_COUNTERS
  if (expertSettings.current.maxStreamCount !== 0) {
    requests.push({
      columnList: [ExpertColumn.EXPERT_COLUMN_NAME, ExpertColumn.EXPERT_COLUMN_VALUE],
      operation: ExpertOperation.EXPERT_OPERATION_UPDATE,
      rowList: [
        [
          {
            value: expertCounterIdToString(ExpertCounterID.EXPERT_COUNTER_ID_MAX_STREAM_COUNT),
          },
          {
            value: expertSettings.current.maxStreamCount,
          },
        ],
      ],
      view: ExpertView.EXPERT_VIEW_HEADER_COUNTERS,
    })
  }

  // write current and default settings both to ExpertView.EXPERT_VIEW_EVENT_SETTINGS
  const mergedProblems: ExpertProblemObject[] = expertSettings.current.problems.concat(
    expertSettings._default.problems
  )
  const executeEventSettings: ExpertExecuteRequest = {
    columnList: [
      ExpertColumn.EXPERT_COLUMN_SETTINGS_GROUP_ID,
      ExpertColumn.EXPERT_COLUMN_PROBLEM_ID,
      ExpertColumn.EXPERT_COLUMN_SETTINGS_ENABLED,
      ExpertColumn.EXPERT_COLUMN_SETTINGS_SEVERITY,
      ExpertColumn.EXPERT_COLUMN_SETTINGS_VALUE,
      ExpertColumn.EXPERT_COLUMN_SETTINGS_SENSITIVITY,
      ExpertColumn.EXPERT_COLUMN_SETTINGS_MINPERIOD,
    ],
    operation: ExpertOperation.EXPERT_OPERATION_UPDATE,
    rowList: [],
    view: ExpertView.EXPERT_VIEW_EVENT_SETTINGS,
  }
  mergedProblems.forEach((problem: ExpertProblemObject) => {
    if (executeEventSettings.rowList) {
      const row: ExpertValue[] = []
      for (let c = 0; c < executeEventSettings.columnList.length; c++) {
        switch (executeEventSettings.columnList[c]) {
          case ExpertColumn.EXPERT_COLUMN_SETTINGS_GROUP_ID:
            row.push({ value: problem.settingGroupId })
            break
          case ExpertColumn.EXPERT_COLUMN_PROBLEM_ID:
            row.push({ value: problem.id })
            break
          case ExpertColumn.EXPERT_COLUMN_SETTINGS_ENABLED:
            row.push({ value: problem.enabled ? 1 : 0 })
            break
          case ExpertColumn.EXPERT_COLUMN_SETTINGS_SEVERITY:
            row.push({ value: problem.severity })
            break
          case ExpertColumn.EXPERT_COLUMN_SETTINGS_VALUE:
            row.push({ value: problem.value })
            break
          case ExpertColumn.EXPERT_COLUMN_SETTINGS_SENSITIVITY:
            row.push({ value: problem.sensitivity })
            break
          case ExpertColumn.EXPERT_COLUMN_SETTINGS_MINPERIOD:
            if (problem.minimumSample === -1) {
              row.push({ value: null })
            } else {
              row.push({ value: problem.minimumSample })
            }
            break
          default:
            break
        }
      }
      executeEventSettings.rowList.push(row)
    }
  })
  requests.push(executeEventSettings)

  // write current and default network policy settings (just the accept/reject flags)
  const executePolicySettings: ExpertExecuteRequest = {
    columnList: [
      ExpertColumn.EXPERT_COLUMN_SETTINGS_GROUP_ID,
      ExpertColumn.EXPERT_COLUMN_PROBLEM_ID,
      ExpertColumn.EXPERT_COLUMN_ACCEPT_MATCHING,
      ExpertColumn.EXPERT_COLUMN_AUX1,
    ],
    operation: ExpertOperation.EXPERT_OPERATION_UPDATE,
    rowList: [],
    view: ExpertView.EXPERT_VIEW_POLICY_SETTINGS,
  }
  const policySettings: any[] = [
    {
      settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT,
      id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_VENDOR_ID,
      accept: expertSettings.current.policy.vendorId.accept,
    },
    {
      settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT,
      id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_CHANNEL,
      accept: expertSettings.current.policy.channel.accept,
      aux: stringToBand(expertSettings.current.policy.channel.channelBand),
    },
    {
      settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT,
      id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_ESSID,
      accept: expertSettings.current.policy.essId.accept,
    },
    {
      settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT,
      id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_ENCRYPTION,
      accept: expertSettings.current.policy.encryption.accept,
    },
    {
      settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT,
      id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_AUTHENTICATION,
      accept: expertSettings.current.policy.authentication.accept,
    },
    {
      settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT,
      id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_VENDOR_ID,
      accept: expertSettings._default.policy.vendorId.accept,
    },
    {
      settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT,
      id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_CHANNEL,
      accept: expertSettings._default.policy.channel.accept,
      aux: stringToBand(expertSettings._default.policy.channel.channelBand),
    },
    {
      settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT,
      id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_ESSID,
      accept: expertSettings._default.policy.essId.accept,
    },
    {
      settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT,
      id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_ENCRYPTION,
      accept: expertSettings._default.policy.encryption.accept,
    },
    {
      settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT,
      id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_AUTHENTICATION,
      accept: expertSettings._default.policy.authentication.accept,
    },
  ]
  policySettings.forEach((policySetting: any) => {
    const row: ExpertValue[] = []
    for (let c = 0; c < executePolicySettings.columnList.length; c++) {
      switch (executePolicySettings.columnList[c]) {
        case ExpertColumn.EXPERT_COLUMN_SETTINGS_GROUP_ID:
          row.push({ value: policySetting.settingGroupId })
          break
        case ExpertColumn.EXPERT_COLUMN_PROBLEM_ID:
          row.push({ value: policySetting.id })
          break
        case ExpertColumn.EXPERT_COLUMN_ACCEPT_MATCHING:
          row.push({ value: policySetting.accept ? 1 : 0 })
          break
        case ExpertColumn.EXPERT_COLUMN_AUX1:
          row.push({ value: policySetting.aux !== undefined ? policySetting.aux : 0 })
          break
        default:
          break
      }
    }
    if (executePolicySettings.rowList) {
      executePolicySettings.rowList.push(row)
    }
  })
  requests.push(executePolicySettings)

  // write the current and default network policy line items
  const executePolicyItems: ExpertExecuteRequest = {
    columnList: [
      ExpertColumn.EXPERT_COLUMN_SETTINGS_GROUP_ID,
      ExpertColumn.EXPERT_COLUMN_PROBLEM_ID,
      ExpertColumn.EXPERT_COLUMN_SETTINGS_ENABLED,
      ExpertColumn.EXPERT_COLUMN_AUX1,
      ExpertColumn.EXPERT_COLUMN_VALUE,
    ],
    operation: ExpertOperation.EXPERT_OPERATION_UPDATE,
    rowList: [],
    view: ExpertView.EXPERT_VIEW_POLICY_ITEMS,
  }
  let policyItems: any[] = []
  policyItems = policyItems.concat(
    expertSettings.current.policy.vendorId.items.map((item: ExpertPolicyVendorIdItem) => {
      return {
        settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT,
        id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_VENDOR_ID,
        enabled: item.accessPoint,
        aux: item.client ? 1 : 0,
        value: item.value,
      }
    })
  )
  expertSettings.current.policy.channel.channelFamily.forEach(
    (family: ExpertPolicyChannelFamilyItem) => {
      policyItems = policyItems.concat(
        family.items.map((familyItem: ExpertPolicyChannelItem) => {
          return {
            settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT,
            id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_CHANNEL,
            enabled: familyItem.enabled,
            value: formatWirelessChannel(familyItem.band, familyItem.channel, familyItem.frequency),
          }
        })
      )
    }
  )
  policyItems = policyItems.concat(
    expertSettings.current.policy.essId.items.map((item: ExpertPolicyESSIDItem) => {
      return {
        settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT,
        id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_ESSID,
        enabled: item.enabled,
        value: item.value,
      }
    })
  )
  policyItems = policyItems.concat(
    expertSettings.current.policy.encryption.items.map((item: ExpertPolicyEncryptionItem) => {
      return {
        settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT,
        id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_ENCRYPTION,
        enabled: item.enabled,
        value: encryptionTypeToString(item.type),
      }
    })
  )
  policyItems = policyItems.concat(
    expertSettings.current.policy.authentication.items.map(
      (item: ExpertPolicyAuthenticationItem) => {
        return {
          settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT,
          id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_AUTHENTICATION,
          enabled: item.enabled,
          value: authenticationTypeToString(item.type),
        }
      }
    )
  )
  policyItems = policyItems.concat(
    expertSettings._default.policy.vendorId.items.map((item: ExpertPolicyVendorIdItem) => {
      return {
        settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT,
        id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_VENDOR_ID,
        enabled: item.accessPoint,
        aux: item.client ? 1 : 0,
        value: item.value,
      }
    })
  )
  expertSettings._default.policy.channel.channelFamily.forEach(
    (family: ExpertPolicyChannelFamilyItem) => {
      policyItems = policyItems.concat(
        family.items.map((familyItem: ExpertPolicyChannelItem) => {
          return {
            settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT,
            id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_CHANNEL,
            enabled: familyItem.enabled,
            value: formatWirelessChannel(familyItem.band, familyItem.channel, familyItem.frequency),
          }
        })
      )
    }
  )
  policyItems = policyItems.concat(
    expertSettings._default.policy.essId.items.map((item: ExpertPolicyESSIDItem) => {
      return {
        settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT,
        id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_ESSID,
        enabled: item.enabled,
        value: item.value,
      }
    })
  )
  policyItems = policyItems.concat(
    expertSettings._default.policy.encryption.items.map((item: ExpertPolicyEncryptionItem) => {
      return {
        settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT,
        id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_ENCRYPTION,
        enabled: item.enabled,
        value: encryptionTypeToString(item.type),
      }
    })
  )
  policyItems = policyItems.concat(
    expertSettings._default.policy.authentication.items.map(
      (item: ExpertPolicyAuthenticationItem) => {
        return {
          settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT,
          id: ExpertProblem.EXPERT_PROBLEM_ID_NETWORK_POLICY_AUTHENTICATION,
          enabled: item.enabled,
          value: authenticationTypeToString(item.type),
        }
      }
    )
  )
  policyItems.forEach((policyItem: any) => {
    const row: ExpertValue[] = []
    for (let c = 0; c < executePolicyItems.columnList.length; c++) {
      switch (executePolicyItems.columnList[c]) {
        case ExpertColumn.EXPERT_COLUMN_SETTINGS_GROUP_ID:
          row.push({ value: policyItem.settingGroupId })
          break
        case ExpertColumn.EXPERT_COLUMN_PROBLEM_ID:
          row.push({ value: policyItem.id })
          break
        case ExpertColumn.EXPERT_COLUMN_SETTINGS_ENABLED:
          row.push({ value: policyItem.enabled ? 1 : 0 })
          break
        case ExpertColumn.EXPERT_COLUMN_AUX1:
          row.push({ value: policyItem.aux !== undefined ? policyItem.aux : 0 })
          break
        case ExpertColumn.EXPERT_COLUMN_VALUE:
          row.push({ value: policyItem.value })
          break
        default:
          break
      }
    }
    if (executePolicyItems.rowList) {
      executePolicyItems.rowList.push(row)
    }
  })
  requests.push(executePolicyItems)

  return requests
}

type ExpertSettingsModalProps = {
  expertDescriptions: ExpertDescription[]
  expertLayers: ExpertLayerMapping[]
  expertSettings: ExpertSettings
  initialExpertId?: number | undefined
  maxStreamCountMax: number
  readOnly?: boolean | undefined
  onOK: (expertSettingsGroup: ExpertSettings) => void
  onCancel: () => void
  theme: DefaultTheme
  type: string
  engine: string
  authToken: string
}

type ExpertSettingsModalState = {
  error: any | null
  expandedProblemGroups: Set<string>
  expandedProblemLayers: Set<string>
  expertSettings: ExpertSettings
  filter: string
  problemIdConfig: ExpertProblem
  problemIdInfo: ExpertProblem
}

class ExpertSettingsModal extends React.Component<
  ExpertSettingsModalProps,
  ExpertSettingsModalState
> {
  problemInfoIframe: any | null = null

  state: ExpertSettingsModalState = {
    error: null,
    expandedProblemGroups: new Set(),
    expandedProblemLayers: new Set(),
    expertSettings: cloneDeep(this.props.expertSettings),
    filter: "",
    problemIdConfig: ExpertProblem.EXPERT_PROBLEM_ID_NONE,
    problemIdInfo: ExpertProblem.EXPERT_PROBLEM_ID_NONE,
  }

  severityLabels = [
    ExpertSeverity.EXPERT_SEVERITY_INFORMATIONAL,
    ExpertSeverity.EXPERT_SEVERITY_MINOR,
    ExpertSeverity.EXPERT_SEVERITY_MAJOR,
    ExpertSeverity.EXPERT_SEVERITY_SEVERE,
  ].map((severity: ExpertSeverity) => (
    <option key={severity} value={severity}>
      {expertSeverityToString(severity)}
    </option>
  ))

  componentDidMount = () => {
    const { initialExpertId } = this.props
    if (initialExpertId !== undefined) {
      const { expertDescriptions, expertLayers } = this.props
      const { expertSettings } = this.state

      const problem = expertSettings.current.problems.find(
        (p: ExpertProblemObject) => p.id === initialExpertId
      )
      if (problem) {
        const description = expertDescriptions.find(
          (d: ExpertDescription) => d.problemId === problem.id
        )
        if (description) {
          const layer = expertLayers.find((l: ExpertLayerMapping) => l.id === description.layer)
          if (layer) {
            this.onExpandCollapse(layer.layer, true)
          }
          if (description.group.length > 0) {
            this.onExpandCollapse(description.group, false)
          }
          if (
            description.subGroup.length > 0 &&
            description.group !== description.subGroup &&
            (!layer || layer.layer !== description.subGroup)
          ) {
            this.onExpandCollapse(description.subGroup, false)
          }
        }
        this.setState({ problemIdInfo: initialExpertId })
      }
    }
  }

  shouldComponentUpdate(
    props: ExpertSettingsModalProps,
    {
      error,
      expertSettings,
      expandedProblemGroups,
      expandedProblemLayers,
      filter,
      problemIdConfig,
      problemIdInfo,
    }: ExpertSettingsModalState
  ) {
    return (
      this.state.error !== error ||
      !isEqual(this.state.expandedProblemGroups, expandedProblemGroups) ||
      !isEqual(this.state.expandedProblemLayers, expandedProblemLayers) ||
      !isEqual(this.state.expertSettings, expertSettings) ||
      this.state.filter !== filter ||
      this.state.problemIdConfig !== problemIdConfig ||
      this.state.problemIdInfo !== problemIdInfo
    )
  }

  onChangeFilter = (filter: string) => {
    this.setState({ filter })
  }

  onChangeMemoryUsage = (value: number) => {
    this.setState(
      produce(draft => {
        draft.expertSettings.current.maxStreamCount = value
      })
    )
  }

  onCheckProblem = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    if (event.target) {
      const { checked, name } = event.target
      this.setState(
        produce(draft => {
          const problemIndex = draft.expertSettings.current.problems.findIndex(
            (problem: ExpertProblemObject) => problem.id === toNumber(name)
          )
          if (problemIndex !== -1) {
            draft.expertSettings.current.problems[problemIndex].enabled = checked
          }
        })
      )
    }
  }

  onChangeSeverity = (id: ExpertProblem, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target) {
      const { value } = event.target
      this.setState(
        produce(draft => {
          const problemIndex = draft.expertSettings.current.problems.findIndex(
            (problem: ExpertProblemObject) => problem.id === id
          )
          if (problemIndex !== -1) {
            draft.expertSettings.current.problems[problemIndex].severity = toNumber(
              value
            ) as ExpertSeverity
          }
        })
      )
    }
  }

  onCloseProblemsEditSidebar = () => {
    this.setState({ problemIdConfig: ExpertProblem.EXPERT_PROBLEM_ID_NONE })
  }

  onDisableAll = () => {
    const { expertDescriptions } = this.props
    this.setState(
      produce(draft => {
        for (let i = 0; i < draft.expertSettings.current.problems.length; i++) {
          const expertDescription = expertDescriptions.find(
            (description: ExpertDescription) =>
              description.problemId === draft.expertSettings.current.problems[i].id
          )
          if (expertDescription !== undefined && !expertDescription.hasConfigure) {
            draft.expertSettings.current.problems[i].enabled = false
          }
        }
      })
    )
  }

  onEnableAll = () => {
    const { expertDescriptions } = this.props
    this.setState(
      produce(draft => {
        for (let i = 0; i < draft.expertSettings.current.problems.length; i++) {
          const expertDescription = expertDescriptions.find(
            (description: ExpertDescription) =>
              description.problemId === draft.expertSettings.current.problems[i].id
          )
          if (expertDescription !== undefined && !expertDescription.hasConfigure) {
            draft.expertSettings.current.problems[i].enabled = true
          }
        }
      })
    )
  }

  onErrorDismiss() {
    this.setState({ error: null })
  }

  onExpandCollapse = (expandedItem: string, isLayer: boolean) => {
    if (isLayer) {
      const expandedProblemLayers = new Set(this.state.expandedProblemLayers)
      if (expandedProblemLayers.has(expandedItem)) {
        expandedProblemLayers.delete(expandedItem)
      } else {
        expandedProblemLayers.add(expandedItem)
      }
      this.setState({ expandedProblemLayers })
    } else {
      const expandedProblemGroups = new Set(this.state.expandedProblemGroups)
      if (expandedProblemGroups.has(expandedItem)) {
        expandedProblemGroups.delete(expandedItem)
      } else {
        expandedProblemGroups.add(expandedItem)
      }
      this.setState({ expandedProblemGroups })
    }
  }

  onExport = () => {
    const jsonOutput = JSON.stringify(this.state.expertSettings, null, 2)
    FileSaver.saveAs(
      new Blob([jsonOutput], { type: "application/json;charset=utf8" }),
      "ExpertSettings.json"
    )
  }

  onImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onerror = (e: any) => {
          this.setState({
            error: "Error reading file",
          })
        }
        reader.onload = (e: any) => {
          try {
            if (e.target.result) {
              const jsonInput = JSON.parse(e.target.result)
              if (isValidExpertSettingsFile(jsonInput, this.props.expertDescriptions)) {
                this.setState({ expertSettings: jsonInput })
              } else {
                this.setState({
                  error: "Invalid file format",
                })
              }
            }
          } catch {
            this.setState({
              error: "Error importing file",
            })
          }
        }
        reader.readAsText(file)

        // Reset the file input so the same file can be used again
        event.target.value = ""
      }
    }
  }

  onOpenProblemsEditSidebar = (problemId: ExpertProblem) => {
    this.setState({ problemIdConfig: problemId })
  }

  onOK = () => {
    this.props.onOK(this.state.expertSettings)
  }

  onProblemInfoIframeLoad = (event: any) => {
    if (
      this.problemInfoIframe &&
      this.problemInfoIframe.contentWindow &&
      this.problemInfoIframe.contentWindow.document
    ) {
      const body = this.problemInfoIframe.contentWindow.document.querySelector("body")
      if (body && body.style) {
        body.style.backgroundColor = `${this.props.theme.tableBackgroundColor}`
        body.style.color = `${this.props.theme.textColor}`
      }
    }
  }

  onRevertToDefaults = () => {
    this.setState(
      produce(draft => {
        draft.expertSettings.current.maxStreamCount = draft.expertSettings._default.maxStreamCount
        draft.expertSettings.current.policy = cloneDeep(draft.expertSettings._default.policy)
        draft.expertSettings.current.problems = draft.expertSettings._default.problems.map(
          (problem: ExpertProblemObject) => {
            return {
              ...problem,
              settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT,
            }
          }
        )
      })
    )
  }

  onSetAsDefaults = () => {
    this.setState(
      produce(draft => {
        draft.expertSettings._default.maxStreamCount = draft.expertSettings.current.maxStreamCount
        draft.expertSettings._default.policy = cloneDeep(draft.expertSettings.current.policy)
        draft.expertSettings._default.problems = draft.expertSettings.current.problems.map(
          (problem: ExpertProblemObject) => {
            return {
              ...problem,
              settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT,
            }
          }
        )
        // OD-4210 Only save defaults to match Omnipeek
        const expertPreferencesSet: ExpertPreferencesSet = {
          settings: {
            _default: draft.expertSettings._default,
            current: draft.expertSettings._default,
          },
        }
        postExpertPreferences(this.props.engine, this.props.authToken, expertPreferencesSet)
          .then()
          .catch(() => {
            console.error("Unable to set expert preferences.")
          })
      })
    )
  }

  onSetAsDefaultsIndividual = (updatedProblemDefault: ExpertProblemObject) => {
    this.setState(
      produce(draft => {
        const problemIndexDefault = draft.expertSettings._default.problems.findIndex(
          (problem: ExpertProblemObject) => problem.id === updatedProblemDefault.id
        )
        if (problemIndexDefault !== -1) {
          draft.expertSettings._default.problems[problemIndexDefault] =
            cloneDeep(updatedProblemDefault)
        }
        const expertPreferencesSet: ExpertPreferencesSet = {
          settings: {
            _default: draft.expertSettings._default,
            current: draft.expertSettings._default,
          },
        }
        postExpertPreferences(this.props.engine, this.props.authToken, expertPreferencesSet)
          .then()
          .catch(() => {
            console.error("Unable to set expert preferences.")
          })
      })
    )
  }

  onToggleAll = () => {
    const { expertDescriptions } = this.props
    this.setState(
      produce(draft => {
        for (let i = 0; i < draft.expertSettings.current.problems.length; i++) {
          const expertDescription = expertDescriptions.find(
            (description: ExpertDescription) =>
              description.problemId === draft.expertSettings.current.problems[i].id
          )
          if (expertDescription !== undefined && !expertDescription.hasConfigure) {
            draft.expertSettings.current.problems[i].enabled =
              !draft.expertSettings.current.problems[i].enabled
          }
        }
      })
    )
  }

  onUpdateProblemConfig = (
    updatedProblemCurrent: ExpertProblemObject,
    updatedProblemDefault: ExpertProblemObject
  ) => {
    this.setState(
      produce(draft => {
        const problemIndexCurrent = draft.expertSettings.current.problems.findIndex(
          (problem: ExpertProblemObject) => problem.id === updatedProblemCurrent.id
        )
        if (problemIndexCurrent !== -1) {
          draft.expertSettings.current.problems[problemIndexCurrent] =
            cloneDeep(updatedProblemCurrent)
        }
        const problemIndexDefault = draft.expertSettings._default.problems.findIndex(
          (problem: ExpertProblemObject) => problem.id === updatedProblemDefault.id
        )
        if (problemIndexDefault !== -1) {
          draft.expertSettings._default.problems[problemIndexDefault] =
            cloneDeep(updatedProblemDefault)
        }
        draft.problemIdConfig = ExpertProblem.EXPERT_PROBLEM_ID_NONE
      })
    )
  }

  getFlattenedTree = () => {
    const { expertDescriptions, expertLayers } = this.props
    const { expandedProblemGroups, expandedProblemLayers, expertSettings, filter } = this.state
    if (expertSettings) {
      // Prepare filter data
      const lowerCaseFilter = filter.toLowerCase()
      const hasFilter = lowerCaseFilter.length > 0

      // Build hierarchical representation
      const groups: any[] = []
      expertSettings.current.problems.forEach((problem: ExpertProblemObject) => {
        const description = expertDescriptions.find(
          (d: ExpertDescription) => d.problemId === problem.id
        )
        if (description !== undefined && !description.hasConfigure) {
          // Filter on problem name
          if (hasFilter && description.name.toLowerCase().indexOf(lowerCaseFilter) === -1) {
            return
          }
          const layer = expertLayers.find((l: ExpertLayerMapping) => l.id === description.layer)
          const layerName = layer !== undefined ? layer.layer : description.group
          const groupLayer = groups.find((group: any) => group.name === layerName)
          if (groupLayer) {
            if (description.group.length > 0 && description.group !== layerName) {
              const groupGroup = groupLayer.children.find(
                (group: any) => group.name === description.group
              )
              if (groupGroup) {
                groupGroup.children.push({ ...problem, heirId: 2, problemName: description.name })
              } else {
                groupLayer.children.push({
                  name: description.group,
                  isLayer: false,
                  children: [{ ...problem, heirId: 2, problemName: description.name }],
                })
              }
            } else if (description.subGroup.length > 0 && description.subGroup !== layerName) {
              const groupGroup = groupLayer.children.find(
                (group: any) => group.name === description.subGroup
              )
              if (groupGroup) {
                groupGroup.children.push({ ...problem, heirId: 2, problemName: description.name })
              } else {
                groupLayer.children.push({
                  name: description.subGroup,
                  isLayer: false,
                  children: [{ ...problem, heirId: 2 }],
                })
              }
            } else {
              groupLayer.children.push({ ...problem, heirId: 1, problemName: description.name })
            }
          } else {
            if (description.group.length > 0 && description.group !== layerName) {
              groups.push({
                layer: description.layer,
                name: layerName,
                isLayer: true,
                children: [
                  {
                    name: description.group,
                    isLayer: false,
                    children: [{ ...problem, heirId: 2, problemName: description.name }],
                  },
                ],
              })
            } else if (description.subGroup.length > 0 && description.subGroup !== layerName) {
              groups.push({
                layer: description.layer,
                name: layerName,
                isLayer: true,
                children: [
                  {
                    name: description.subGroup,
                    isLayer: false,
                    children: [{ ...problem, heirId: 2, problemName: description.name }],
                  },
                ],
              })
            } else {
              groups.push({
                layer: description.layer,
                name: layerName,
                isLayer: true,
                children: [{ ...problem, heirId: 1, problemName: description.name }],
              })
            }
          }
        }
      })

      // Sort top level groups by layer
      groups.sort((a, b) => {
        if (a.layer === -1) return -1
        if (b.layer === -1) return 1
        if (a.layer < b.layer) return 1
        if (a.layer > b.layer) return -1
        return 0
      })

      // Sort children by group name or problem id
      groups.forEach(group => {
        group.children.sort((a: any, b: any) => {
          if (a.name !== undefined) {
            if (b.name !== undefined) return collator.compare(a.name, b.name)
            return -1
          } else if (b.name !== undefined) {
            return 1
          } else if (a.id !== undefined && b.id !== undefined) {
            if (a.id < b.id) return -1
            if (a.id > b.id) return 1
            return 0
          } else {
            return 0
          }
        })
      })

      // Flatten the list into rows
      const rows: any[] = []
      groups.forEach((group: any) => {
        rows.push(group)
        const expandedLayer = expandedProblemLayers.has(group.name)
        if (expandedLayer) {
          group.children.forEach((item: any, index: number) => {
            if (item.name !== undefined) {
              rows.push(item)
              const expandedGroup = expandedProblemGroups.has(item.name)
              if (expandedGroup) {
                item.children.forEach((subItem: any, subIndex: number) => {
                  rows.push({ ...subItem, childIndex: subIndex })
                })
              }
            } else {
              rows.push({ ...item, childIndex: index })
            }
          })
        }
      })

      return rows
    }
    return null
  }

  onRowClick = ({ rowData, event }: { rowData: any; event: React.MouseEvent<HTMLElement> }) => {
    // Exclude clicks on checkboxes.
    const tagName = (event?.target as HTMLElement)?.tagName
    const id = (event?.target as HTMLElement)?.id
    if (
      tagName === "LABEL" ||
      tagName === "INPUT" ||
      tagName === "SELECT" ||
      (tagName === "SPAN" && id.startsWith("configgear"))
    ) {
      return
    }
    const isGroup = rowData.children !== undefined
    if (isGroup) {
      this.onExpandCollapse(rowData.name, rowData.isLayer)
    } else {
      this.setState({ problemIdInfo: rowData.id })
    }
  }

  rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    const isGroup = rowData.children !== undefined
    if (isGroup) {
      className = cn(className, "group", "clickable")
      key = rowData.name
    } else {
      if (rowData.childIndex != null && rowData.childIndex % 2 !== 0) {
        className = cn(className, "stripe")
      }
      key = rowData.id
    }

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  rowClassName = () => {
    // Stripes applied in rowRenderer
    return ""
  }

  cellRenderer = ({ cellData, rowData, dataKey }: TableCellProps) => {
    let content = null

    const { readOnly } = this.props
    const isGroup = rowData.children !== undefined
    switch (dataKey) {
      case "checked":
        if (isGroup) {
          let name = "group"
          let checkCount = 0
          let rowCount = 0
          for (const child of rowData.children) {
            if (child.children !== undefined) {
              name += "-" + child.children.map((gchild: any) => gchild.id.toString()).join("-")
              checkCount += child.children.filter((gchild: any) => gchild.enabled).length
              rowCount += child.children.length
            } else {
              name += "-" + child.id.toString()
              if (child.enabled) {
                checkCount++
              }
              rowCount++
            }
          }
          let checkState = 0
          if (checkCount === rowCount) {
            checkState = 1
          } else if (checkCount > 0) {
            checkState = 2
          }
          content = (
            <CellCheckGroupIndeterminate
              type="checkbox"
              value={checkState}
              id={`group-${rowData.name}`}
              disabled={!!readOnly}
              onChange={() => {
                this.setState(
                  produce(draft => {
                    const nameArray = name.split("-")
                    nameArray.forEach((value: string) => {
                      const id = Number(value)
                      if (!Number.isNaN(id)) {
                        const problemIndex = draft.expertSettings.current.problems.findIndex(
                          (problem: ExpertProblemObject) => problem.id === id
                        )
                        if (problemIndex !== -1) {
                          draft.expertSettings.current.problems[problemIndex].enabled =
                            checkState !== 1
                        }
                      }
                    })
                  })
                )
              }}
            />
          )
        } else {
          const { expertSettings } = this.state
          const problem = expertSettings.current.problems.find(
            (problem: ExpertProblemObject) => problem.id === rowData.id
          )
          const checked = problem !== undefined ? problem.enabled : false
          content = (
            <CellCheckGroup
              type="checkbox"
              checked={checked}
              id={`problem-${rowData.id}`}
              name={rowData.id}
              disabled={!!readOnly}
              onChange={this.onCheckProblem}
            />
          )
        }
        break
      case "name":
        if (isGroup) {
          const { expandedProblemGroups, expandedProblemLayers } = this.state
          const expanded = rowData.isLayer
            ? expandedProblemLayers.has(cellData)
            : expandedProblemGroups.has(cellData)
          const icon = expanded ? "chevron-down" : "chevron-right"
          const marginLeft = rowData.isLayer ? "0em" : "1.7em"
          content = (
            <div style={{ display: "flex", alignItems: "center", marginLeft }}>
              <FontAwesome name={icon} fixedWidth style={{ paddingRight: ".25em" }} />
              {cellData}
            </div>
          )
        } else {
          const { expertDescriptions } = this.props
          const description = expertDescriptions.find(
            (d: ExpertDescription) => d.problemId === rowData.id
          )
          const name = description !== undefined ? description.name : ""
          content = (
            <span
              style={{
                marginLeft: `${rowData.heirId * 1.7}em`,
              }}
            >
              {name}
            </span>
          )
        }
        break
      case "severity":
        if (!isGroup) {
          content = (
            <Select
              name="severity"
              id="severity"
              bsSize="sm"
              value={cellData}
              disabled={!!readOnly}
              onChange={this.onChangeSeverity.bind(this, rowData.id)}
            >
              {this.severityLabels}
            </Select>
          )
        }
        break
      case "config":
        if (!isGroup) {
          const { expertDescriptions } = this.props
          const description = expertDescriptions.find(
            (d: ExpertDescription) => d.problemId === rowData.id
          )
          if (
            description !== undefined &&
            (description.hasValue ||
              description.hasMinSamplePeriod ||
              description.hasValueAssist ||
              description.hasSensitivity ||
              description.hasConfigure)
          ) {
            content = (
              <IconButton
                id={`config-${rowData.id}`}
                onClick={this.onOpenProblemsEditSidebar.bind(this, rowData.id)}
              >
                <FontAwesome id={`configgear-${rowData.id}`} fixedWidth name="gear" />
              </IconButton>
            )
          }
        }
        break
      default:
        break
    }

    return content
  }

  render() {
    const { expertDescriptions, maxStreamCountMax, readOnly, type, onCancel } = this.props
    const { error, expertSettings, filter, problemIdConfig, problemIdInfo } = this.state

    const flattenedProblems = this.getFlattenedTree()
    const selectedDescription = expertDescriptions.find(
      (description: ExpertDescription) => description.problemId === problemIdConfig
    )
    const selectedProblemCurrent = expertSettings.current.problems.find(
      (problem: ExpertProblemObject) => problem.id === problemIdConfig
    )
    const selectedProblemDefault = expertSettings._default.problems.find(
      (problem: ExpertProblemObject) => problem.id === problemIdConfig
    )

    let readOnlyMessage = "These settings are read-only."
    if (type === "forensic-searches") {
      readOnlyMessage +=
        ' Modification of these settings must be made before the forensic search is created. This can be done by clicking on the configuration gear button next to the "Expert" analysis option in the Forensic Search creation modal.'
    }

    return (
      <Modal size="xl" isOpen={true} toggle={onCancel}>
        <ModalHeader toggle={onCancel}>Expert Settings</ModalHeader>
        <ModalBody>
          {readOnly && <Alert color="info">{readOnlyMessage}</Alert>}
          {error && (
            <FormGroup>
              <Alert color="danger" isOpen={error !== null} toggle={this.onErrorDismiss.bind(this)}>
                {typeof error === "string" ? error : `${error.code} ${error.reason}`}
              </Alert>
            </FormGroup>
          )}
          {flattenedProblems && (
            <>
              <FormGroup>
                <Label>Expert Events</Label>
                <ProblemTableHeader>
                  <FilterBox
                    aria-label="Search"
                    placeholder="Search"
                    onChange={this.onChangeFilter}
                    value={filter}
                  />
                  {!readOnly && (
                    <>
                      <LightButton onClick={this.onEnableAll}>Enable All</LightButton>
                      <LightButton onClick={this.onDisableAll}>Disable All</LightButton>
                      <LightButton onClick={this.onToggleAll}>Toggle All</LightButton>
                    </>
                  )}
                </ProblemTableHeader>
                <ProblemTableContainer>
                  <OmniTable
                    data={flattenedProblems}
                    rowCount={flattenedProblems.length}
                    rowHeight={25}
                    cellRenderer={this.cellRenderer}
                    columnDesc={[
                      {
                        dataKey: "checked",
                        label: "",
                        width: 28,
                        flexShrink: 0,
                        disableSort: true,
                        className: "fullsize",
                        headerClassName: "fullsize",
                      },
                      {
                        dataKey: "name",
                        label: "Event",
                        width: 330,
                        flexGrow: 1,
                      },
                      {
                        dataKey: "severity",
                        label: "Severity",
                        width: 50,
                        flexGrow: 1,
                      },
                      {
                        dataKey: "config",
                        label: "",
                        width: 25,
                        flexShrink: 0,
                        disableSort: true,
                        className: "center",
                      },
                    ]}
                    disableHeader
                    rowRenderer={this.rowRenderer}
                    rowClassName={this.rowClassName}
                    onRowClick={this.onRowClick}
                  />
                </ProblemTableContainer>
              </FormGroup>
              <FormGroup>
                <Label>Detailed Information</Label>
                <ProblemInfoIFrame
                  onLoad={this.onProblemInfoIframeLoad}
                  ref={(ref: any) => {
                    this.problemInfoIframe = ref
                  }}
                  src={import.meta.env.BASE_URL + `/static/expert/${problemIdInfo}.html`}
                />
              </FormGroup>
            </>
          )}
          <FormGroup noMargin>
            <Label for="maxStreamCountEdit">Maximum Flows & Events</Label>
            <MaximumFlowsSlider
              maxStreamCount={expertSettings.current.maxStreamCount}
              maxStreamCountMax={maxStreamCountMax}
              name="maxStreamCountEdit"
              disabled={!!readOnly}
              onChange={this.onChangeMemoryUsage}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          {!readOnly && (
            <FileInputButton accept=".json,application/json" onChange={this.onImport}>
              <FontAwesome name="upload" /> Import
            </FileInputButton>
          )}
          <LightButton id="export" onClick={this.onExport}>
            <FontAwesome name="download" /> Export
          </LightButton>
          {!readOnly && (
            <>
              <LightButton id="revertAllDefaults" onClick={this.onRevertToDefaults.bind(this)}>
                <FontAwesome name="history" /> Revert To Defaults
              </LightButton>
              <LightButton id="setAsDefaults" onClick={this.onSetAsDefaults.bind(this)}>
                <FontAwesome name="history" flip="horizontal" /> Set As Defaults
              </LightButton>
              <SecondaryButton style={{ marginLeft: "auto" }} onClick={onCancel}>
                Cancel
              </SecondaryButton>
              <PrimaryButton onClick={() => this.onOK()}>Save</PrimaryButton>
            </>
          )}
          {!!readOnly && (
            <PrimaryButton style={{ marginLeft: "auto" }} onClick={() => onCancel()}>
              Close
            </PrimaryButton>
          )}
        </ModalFooter>
        {selectedDescription !== undefined &&
          selectedProblemCurrent !== undefined &&
          selectedProblemDefault !== undefined && (
            <Sidebar open={problemIdConfig !== ExpertProblem.EXPERT_PROBLEM_ID_NONE}>
              <SidebarBody open={problemIdConfig !== ExpertProblem.EXPERT_PROBLEM_ID_NONE}>
                <SidebarHeader>
                  <SidebarTitle>{selectedDescription.name}</SidebarTitle>
                  <CloseButton onClick={() => this.onCloseProblemsEditSidebar()} />
                </SidebarHeader>
                <SidebarContent>
                  <ProblemEditSidebar
                    expertDescription={selectedDescription}
                    expertProblemCurrent={selectedProblemCurrent}
                    expertProblemDefault={selectedProblemDefault}
                    readOnly={readOnly}
                    onClose={this.onCloseProblemsEditSidebar}
                    onUpdateProblem={this.onUpdateProblemConfig}
                    onPostProblem={this.onSetAsDefaultsIndividual}
                  />
                </SidebarContent>
              </SidebarBody>
            </Sidebar>
          )}
      </Modal>
    )
  }
}

export default withTheme(ExpertSettingsModal)
