const csvStringify = (item: any): string => {
  if (item === 0) {
    item = "0"
  } else if (item == null) {
    item = ""
  }
  if (typeof item !== "string") {
    const s = item.toString()
    if (s === "[object Object]") {
      item = JSON.stringify(item)
      if (item === "{}") {
        item = ""
      }
    } else {
      item = s
    }
  }
  if (item.indexOf(",") !== -1) {
    // TODO: double-quote embedded quotes
    // TODO: also search for newlines
    item = '"' + item + '"'
  }
  return "" + item
}

export default csvStringify
