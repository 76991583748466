import * as React from "react"
import { connect } from "react-redux"
import { cloneDeep } from "lodash"
import { withTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Legend, Label } from "recharts"
import ChartTooltip from "../../common/ChartTooltip"
import { ChartType } from "../../../api/types/chartTypes"
import { ChartComponents } from "../../../utils/chartComponents"
import {
  FullWidthWidget,
  WidgetHeader,
  WidgetTitle,
  WidgetSubTitle,
  WidgetOptions,
  WidgetBody,
  NoData,
} from "../Dashboard"
import { IconDropdownToggle } from "../../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../../common/Dropdown"
import {
  chartTypeSupportsSmoothing,
  formatChartTooltip,
  formatTimeChartTooltipLabel,
  formatTimeChartAxis,
  getChartColor,
} from "../../../utils/chartUtils"
import { formatSampleInterval } from "../../../utils/formatUtils"
import {
  setCallUtilizationWidgetChartType,
  setCallUtilizationWidgetChartScale,
  setCallUtilizationWidgetChartInterpolation,
} from "../../../store/ui"
import {
  getCallUtilizationWidgetChartType,
  getCallUtilizationWidgetChartScale,
  getCallUtilizationWidgetChartInterpolation,
  getShowLocalTime,
} from "../../../store"

type Props = {
  callUtilization: any | null
  networkUtilization: any | null
  chartType: ChartType
  chartScale: "linear" | "log"
  chartInterpolation: string
  showLocalTime: boolean
  theme: any
  setCallUtilizationWidgetChartType: (chartType: ChartType) => void
  setCallUtilizationWidgetChartScale: (chartScale: string) => void
  setCallUtilizationWidgetChartInterpolation: (chartInterpolation: string) => void
}

type State = {
  hoverKey: string | null
}

class CallUtilizationWidget extends React.Component<Props, State> {
  state: State = {
    hoverKey: null,
  }

  onChartType = (e: any) => {
    this.props.setCallUtilizationWidgetChartType(e.target.name)
  }

  onChartScale = (e: any) => {
    this.props.setCallUtilizationWidgetChartScale(e.target.name)
  }

  onChartSmoothing = () => {
    const chartInterpolation = this.props.chartInterpolation === "linear" ? "monotone" : "linear"
    this.props.setCallUtilizationWidgetChartInterpolation(chartInterpolation)
  }

  onMouseEnterLegend = (e: any) => {
    this.setState({ hoverKey: e.dataKey })
  }

  onMouseLeaveLegend = (e: any) => {
    if (e.dataKey === this.state.hoverKey) {
      this.setState({ hoverKey: null })
    }
  }

  render() {
    const {
      callUtilization,
      networkUtilization,
      theme,
      chartType,
      chartScale,
      chartInterpolation,
    } = this.props
    const chartComponent = ChartComponents[chartType]
    const Chart = chartComponent.chart
    const Series = chartComponent.series
    const supportsSmoothing = chartTypeSupportsSmoothing(chartType)
    const seriesProps = cloneDeep(chartComponent.seriesProps)
    if (seriesProps.dot) {
      seriesProps.dot.stroke = theme.panelBackground
    }
    if (seriesProps.activeDot) {
      seriesProps.activeDot.stroke = theme.panelBackground
    }
    if (supportsSmoothing) {
      seriesProps.type = chartInterpolation
    }

    // Combine the call and network utilization.
    let data: any | null = null
    if (callUtilization) {
      if (networkUtilization) {
        data = cloneDeep(callUtilization.data)
        for (let i = 0; i < data.length; i++) {
          if (i < networkUtilization.data.length) {
            data[i].networkMbps = networkUtilization.data[i].mbps
          }
        }
      } else {
        data = callUtilization.data
      }
    }

    const { hoverKey } = this.state
    const callUtilizationOpacity = !hoverKey || hoverKey === "mbps" ? undefined : 0.1
    const networkUtilizationOpacity = !hoverKey || hoverKey === "networkMbps" ? undefined : 0.1

    return (
      <FullWidthWidget>
        <WidgetHeader>
          <WidgetTitle>
            Call Utilization
            {callUtilization && (
              <WidgetSubTitle>{`${formatSampleInterval(
                callUtilization.interval / 1000
              )} Average`}</WidgetSubTitle>
            )}
          </WidgetTitle>
          <WidgetOptions>
            <UncontrolledDropdown>
              <IconDropdownToggle aria-label="Settings">
                <FontAwesome name="gear" style={{ color: theme.textMutedColor }} />
              </IconDropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  name={ChartType.OVERLAID_SKYLINE}
                  active={chartType === ChartType.OVERLAID_SKYLINE}
                  onClick={this.onChartType}
                >
                  Skyline
                </DropdownItem>
                <DropdownItem
                  name={ChartType.AREA}
                  active={chartType === ChartType.AREA}
                  onClick={this.onChartType}
                >
                  Area
                </DropdownItem>
                <DropdownItem
                  name={ChartType.LINE}
                  active={chartType === ChartType.LINE}
                  onClick={this.onChartType}
                >
                  Line
                </DropdownItem>
                <DropdownItem
                  name={ChartType.LINE_POINTS}
                  active={chartType === ChartType.LINE_POINTS}
                  onClick={this.onChartType}
                >
                  Line/Points
                </DropdownItem>
                {/*
                <DropdownItem divider />
                <DropdownItem
                  name="linear"
                  active={chartScale === "linear"}
                  onClick={this.onChartScale}
                >
                  Linear
                </DropdownItem>
                <DropdownItem
                  disabled
                  name="log"
                  active={chartScale === "log"}
                  onClick={this.onChartScale}
                >
                  Logarithmic
                </DropdownItem>
                */}
                <DropdownItem divider />
                <DropdownItem
                  name="smoothing"
                  active={chartInterpolation !== "linear"}
                  onClick={this.onChartSmoothing}
                  disabled={!supportsSmoothing}
                >
                  Smoothing
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </WidgetOptions>
        </WidgetHeader>
        <WidgetBody minHeight="220px">
          {callUtilization && data ? (
            <ResponsiveContainer height={220} width="100%">
              <Chart
                data={data}
                margin={{ top: 10, right: 32, left: 0, bottom: 0 }}
                {...chartComponent.chartProps}
              >
                <Tooltip
                  isAnimationActive={false}
                  labelFormatter={formatTimeChartTooltipLabel.bind(
                    this,
                    callUtilization.startTime,
                    callUtilization.interval,
                    this.props.showLocalTime
                  )}
                  formatter={formatChartTooltip}
                  content={<ChartTooltip />}
                />
                <CartesianGrid stroke={theme.chartGridColor} vertical={false} />
                <YAxis scale={chartScale} stroke={theme.textColor}>
                  <Label
                    value="Mbits/s"
                    fill={theme.textColor}
                    style={{ fontWeight: 600 }}
                    position="insideLeft"
                    angle={-90}
                    offset={10}
                    dy={14}
                  />
                </YAxis>
                <XAxis
                  stroke={theme.textColor}
                  tickFormatter={formatTimeChartAxis.bind(
                    this,
                    callUtilization.startTime,
                    callUtilization.interval,
                    this.props.showLocalTime
                  )}
                  height={22}
                />
                {networkUtilization ? (
                  <Series
                    key="networkMbps"
                    dataKey="networkMbps"
                    name="Network Utilization"
                    unit="Mbits/s"
                    stroke={getChartColor(0)}
                    strokeOpacity={networkUtilizationOpacity}
                    fill={getChartColor(0)}
                    fillOpacity={networkUtilizationOpacity}
                    {...seriesProps}
                  />
                ) : null}
                <Series
                  key="mbps"
                  dataKey="mbps"
                  name="Call Utilization"
                  unit="Mbits/s"
                  stroke={getChartColor(1)}
                  strokeOpacity={callUtilizationOpacity}
                  fill={getChartColor(1)}
                  fillOpacity={callUtilizationOpacity}
                  {...seriesProps}
                />
                <Legend
                  iconSize={10}
                  onMouseEnter={this.onMouseEnterLegend}
                  onMouseLeave={this.onMouseLeaveLegend}
                />
              </Chart>
            </ResponsiveContainer>
          ) : (
            <NoData>No Data</NoData>
          )}
        </WidgetBody>
      </FullWidthWidget>
    )
  }
}

const mapStateToProps = (state: any) => ({
  chartType: getCallUtilizationWidgetChartType(state) || ChartType.OVERLAID_SKYLINE,
  chartScale: getCallUtilizationWidgetChartScale(state) || "linear",
  chartInterpolation: getCallUtilizationWidgetChartInterpolation(state) || "linear",
  showLocalTime: getShowLocalTime(state),
})

const mapDisptachToProps = {
  setCallUtilizationWidgetChartType,
  setCallUtilizationWidgetChartScale,
  setCallUtilizationWidgetChartInterpolation,
}

export default withTheme(connect(mapStateToProps, mapDisptachToProps)(CallUtilizationWidget))
