import * as React from "react"
import { toNumber } from "lodash"
import FontAwesome from "react-fontawesome"
import { InputGroup } from "reactstrap"
import { Input } from "../common/Input"
import { InputButton } from "../common/Buttons"
import { LightDropdownToggle } from "../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../common/Dropdown"
import { ExpertColumn } from "../../api/types/expertTypes"

type FilterOption = {
  column: number
  label: string
}

const filterOptions: FilterOption[] = [
  {
    column: ExpertColumn.EXPERT_COLUMN_NAME,
    label: "Name",
  },
  {
    column: ExpertColumn.EXPERT_COLUMN_FROM,
    label: "From",
  },
  {
    column: ExpertColumn.EXPERT_COLUMN_TO,
    label: "To",
  },
  {
    column: ExpertColumn.EXPERT_COLUMN_GATEWAY_ASSIGNED_CALL_ID,
    label: "Call ID",
  },
  {
    column: ExpertColumn.EXPERT_COLUMN_ASSERTED_IDENTITY,
    label: "Asserted Identity",
  },
  {
    column: ExpertColumn.EXPERT_COLUMN_END_CAUSE,
    label: "End Cause",
  },
  {
    column: ExpertColumn.EXPERT_COLUMN_MOS_LOW,
    label: "MOS-Low <=",
  },
]

type CallsFilterBoxProps = {
  filterColumn: ExpertColumn
  filter: string
  onChange: (filterColumn: ExpertColumn, filter: string) => void
  disabled?: boolean
}

class CallsFilterBox extends React.PureComponent<CallsFilterBoxProps> {
  onChangeDropdown = (e: any) => {
    this.props.onChange(toNumber(e.target.name), this.props.filter)
  }

  onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(this.props.filterColumn, e.target.value)
  }

  onClear = () => {
    this.props.onChange(this.props.filterColumn, "")
  }

  render() {
    const currentFilterOption = filterOptions.find(
      option => option.column === this.props.filterColumn
    )
    const currentFilterOptionLabel = currentFilterOption ? currentFilterOption.label : ""
    const dropdownItems = filterOptions.map(option => (
      <DropdownItem
        key={option.column}
        name={String(option.column)}
        active={option.column === this.props.filterColumn}
        onClick={this.onChangeDropdown}
      >
        {option.label}
      </DropdownItem>
    ))
    return (
      <InputGroup>
        <UncontrolledDropdown addonType="prepend">
          <LightDropdownToggle caret>{currentFilterOptionLabel}</LightDropdownToggle>
          <DropdownMenu>{dropdownItems}</DropdownMenu>
        </UncontrolledDropdown>
        <Input
          className="border-left-0 border-right-0"
          aria-label="Search"
          placeholder="Search"
          value={this.props.filter}
          onChange={this.onChangeText}
          disabled={this.props.disabled}
          spellCheck={false}
        />
        <InputButton aria-label="Clear" onClick={this.onClear} disabled={this.props.disabled}>
          <FontAwesome name="times" />
        </InputButton>
      </InputGroup>
    )
  }
}

export default CallsFilterBox
