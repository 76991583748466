import * as React from "react"
import { OmniTable, OmniTableProps } from "../common/OmniTable"
import { TableCellProps } from "react-virtualized"
import FontAwesome from "react-fontawesome"
import { RowMouseEventHandlerParams } from "react-virtualized/dist/es/Table"
import { cloneDeep, findIndex } from "lodash"
import { useTheme } from "styled-components"
import ThemeInterface from "../../themes/theme"

export type HierarchyDataItem = {
  id: string
  name: string
  children: any[]
  [key: string]: any
}

type TreeTableProps = Omit<OmniTableProps, "rowCount" | "rowHeight"> & {
  data: HierarchyDataItem[]
  filter?: string
  expandedRows?: string[]
  columnDesc: any[]
}

// First column should have a key 'name'
// Every row should contain an unique 'id' key
const TreeTable: React.FC<TreeTableProps> = props => {
  const theme = useTheme() as ThemeInterface

  const [expandedData, setExpandedData] = React.useState<Set<any>>(new Set())

  React.useEffect(() => {
    if (props.expandedRows === undefined) return
    setExpandedData(new Set(props.expandedRows))
  }, [props.expandedRows])

  const cellRenderer = (cellProps: TableCellProps) => {
    const { dataKey, cellData, rowData } = cellProps
    if (dataKey === "name") {
      const isExpandable = rowData?.children && rowData?.children.length > 0
      const expanded = expandedData.has(rowData.id)
      const icon = expanded ? "chevron-down" : "chevron-right"
      const color = rowData.color ? rowData.color : undefined
      return (
        <div
          style={{
            paddingLeft: rowData?.depth ? `${rowData.depth * 1.2857 + 0.25}em` : "0",
            cursor: isExpandable ? "pointer" : "inherit",
          }}
        >
          {isExpandable ? <FontAwesome name={icon} fixedWidth /> : ""}
          <span
            style={{
              marginLeft: isExpandable ? "0.25em" : "1.5375em",
              color: theme.name === "Light" ? color : undefined,
            }}
          >
            {props.cellRenderer !== undefined ? props.cellRenderer(cellProps) : cellData}
          </span>
        </div>
      )
    }
    if (props.cellRenderer !== undefined) return props.cellRenderer(cellProps)
    return cellData
  }

  const onRowClick = (info: RowMouseEventHandlerParams) => {
    if (props.onRowClick !== undefined) {
      props.onRowClick(info)
    }
    const id = info.rowData.id
    if (!info.rowData.children || info.rowData.children.length === 0) return
    const expandedSet = new Set(expandedData)
    if (expandedSet.has(id)) {
      expandedSet.delete(id)
    } else {
      expandedSet.add(id)
    }
    setExpandedData(expandedSet)
  }
  let dataToModify: any
  // Recursive function
  const expandChildren = (tableData: any, depth = 1) => {
    const dataClone = cloneDeep(tableData)
    if (dataToModify === undefined) dataToModify = cloneDeep(tableData)
    dataClone.forEach((data: any) => {
      if (!data.id) return
      if (expandedData.has(data.id)) {
        const children = data.children.map((d: any) => {
          return {
            ...d,
            depth,
            isExpanded: expandedData.has(d.id),
          }
        })
        const existedIndex = findIndex(dataToModify, (d: any) => d.id === data.id)
        if (existedIndex !== -1) {
          dataToModify.splice(existedIndex + 1, 0, ...children)
          expandChildren(children, depth + 1)
        }
      }
    })
    return dataToModify
  }

  const getHierarchyData = () => {
    const { filter, data } = props
    let filteredData = cloneDeep(data)
    if (filter) {
      const lowerCaseFilter = filter.toLowerCase()
      filteredData = filteredData.filter((node: any) => {
        return (
          node.name.toLowerCase().includes(lowerCaseFilter) ||
          (node.addressName !== undefined &&
            node.addressName.toLowerCase().includes(lowerCaseFilter))
        )
      })
    }
    // let dataDuplicate = cloneDeep(filteredData)
    // let indexGap = 0
    // filteredData.forEach((d: any, index: number) => {
    //   if (!d.id) return
    //   if (expandedData.has(d.id)) {
    //     dataDuplicate.splice(index + indexGap + 1, 0, ...d.children)
    //     indexGap += d.children.length
    //   }
    // })
    return expandChildren(filteredData)
    // return dataDuplicate
  }

  const dataToRender = getHierarchyData()

  return (
    <OmniTable
      {...props}
      data={dataToRender}
      rowCount={dataToRender.length}
      cellRenderer={cellRenderer}
      onRowClick={onRowClick}
    />
  )
}

export default TreeTable
