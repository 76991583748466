import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { cloneDeep, isEqual } from "lodash"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import {
  View,
  ViewContent,
  ViewHeader,
  ViewHeaderButtons,
  ViewHeaderPanel,
  ViewHeaderTitle,
} from "../common/View"
import { LightButton, IconDropdownToggle, CloseButton } from "../common/Buttons"
import { CenterContent } from "../common/Layout"
import { Spinner } from "../common/Spinner"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import PropTable from "../common/PropTable"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import { InsertNamesModal, InsertNameEntry } from "../InsertNamesModal"
import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarTitle,
  SidebarContent,
} from "../common/Sidebar"
import { ExpertTable, ExpertTableColumn, isExpanded } from "../common/ExpertTable"
import WebRequestDetails from "../WebRequestDetails"
import {
  filterExpressionFromRowData,
  filterFromRowData,
  formatExpertValueFromRowData,
  formatWebHeaderCountsProp,
  getExpertColumnName,
  getExpertValueFromRowData,
  getWebHeaderCounts,
  isMSAEnabled,
  selectRelatedExpertRequestFromRowData,
  selectRelatedWebRequestFromRowData,
} from "../../utils/expertUtils"
import { formatMediaSpec } from "../../utils/mediaSpec"
import {
  getEngine,
  getAuthToken,
  getNamesModificationTime,
  getShowAddressNames,
  getShowPortNames,
  getWebClientsColumns,
  getWebClientsSortBy,
  getWebClientsSortDirection,
  getCapabilities,
  getUserId,
  getShowLocalTime,
} from "../../store"
import { updateStatus } from "../../store/status"
import { setCurrentEngine } from "../../store/engines"
import { setSelectPacketsTask } from "../../store/selectPackets"
import { setWebClientsColumns, setWebClientsSort } from "../../store/ui"
import {
  getCaptureForensicSearchUrl,
  getEngineNewFilterUrl,
  getNewDistributedForensicSearchUrl,
} from "../../routes"
import {
  expertQueryCFS,
  postSelectRelatedExpertStart,
  postSelectRelatedWebStart,
  resolveAddresses,
} from "../../api/api"
import {
  AddressResolverRequestEntry,
  ExpertKeyItemKey,
  ExpertQueryResponse,
  ExpertTreeExpand,
  ExpertValue,
  MediaSpec,
  RequestExpertQuery,
  ResponsePostSelectRelatedExpertStart,
  ResponsePostSelectRelatedWebStart,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { ExpertColumn, ExpertRowType, ExpertView } from "../../api/types/expertTypes"

const defaultColumns: ExpertTableColumn[] = [
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_NAME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_NAME),
    width: 300,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  /*{
    dataKey: ExpertColumn.EXPERT_COLUMN_REQUEST_ID_LIST.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_REQUEST_ID_LIST),
    width: 300,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },*/
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_REQUEST_ID.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_REQUEST_ID),
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_PAGE_REQUEST_ID.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_PAGE_REQUEST_ID),
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_STREAM_ID.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_STREAM_ID),
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS),
    width: 150,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_PORT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_PORT),
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY),
    width: 120,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_CITY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_CITY),
    width: 120,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_LATITUDE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_LATITUDE),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_LONGITUDE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_LONGITUDE),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS),
    width: 150,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_PORT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_PORT),
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY),
    width: 120,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_CITY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_CITY),
    width: 120,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_LATITUDE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_LATITUDE),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_LONGITUDE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_LONGITUDE),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_URI.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_URI),
    width: 300,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_RESPONSE_CODE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_RESPONSE_CODE),
    width: 110,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_RESPONSE_TEXT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_RESPONSE_TEXT),
    width: 110,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CONTENT_TYPE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CONTENT_TYPE),
    width: 134,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_REFERER.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_REFERER),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_HOST.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_HOST),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_PACKET_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_PACKET_COUNT),
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT),
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT),
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_BYTE_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_BYTE_COUNT),
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_BYTE_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_BYTE_COUNT),
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_SENT_BYTE_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_SENT_BYTE_COUNT),
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_REQUEST_PAYLOAD_BYTE_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_REQUEST_PAYLOAD_BYTE_COUNT),
    width: 134,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_RESPONSE_PAYLOAD_BYTE_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_RESPONSE_PAYLOAD_BYTE_COUNT),
    width: 134,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_START_TIME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_START_TIME),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_END_TIME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_END_TIME),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DURATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DURATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
]

const propList = [["Servers", "Clients", "Pages", "Requests"]]

const CommandStrip = styled.div`
  display: flex;
`

const queryTemplate: RequestExpertQuery = {
  query: [
    {
      columnList: [
        ExpertColumn.EXPERT_COLUMN_NAME,
        ExpertColumn.EXPERT_COLUMN_NODEPAIR,
        ExpertColumn.EXPERT_COLUMN_TYPE,
        ExpertColumn.EXPERT_COLUMN_TREE_STATE,
        ExpertColumn.EXPERT_COLUMN_REQUEST_ID_LIST,
        ExpertColumn.EXPERT_COLUMN_REQUEST_ID,
        ExpertColumn.EXPERT_COLUMN_PAGE_REQUEST_ID,
        ExpertColumn.EXPERT_COLUMN_STREAM_ID,
        ExpertColumn.EXPERT_COLUMN_FLOW_TYPE,
        ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_CLIENT_PORT,
        ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY,
        ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY_CODE,
        ExpertColumn.EXPERT_COLUMN_CLIENT_CITY,
        ExpertColumn.EXPERT_COLUMN_CLIENT_LATITUDE,
        ExpertColumn.EXPERT_COLUMN_CLIENT_LONGITUDE,
        ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_SERVER_PORT,
        ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY,
        ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY_CODE,
        ExpertColumn.EXPERT_COLUMN_SERVER_CITY,
        ExpertColumn.EXPERT_COLUMN_SERVER_LATITUDE,
        ExpertColumn.EXPERT_COLUMN_SERVER_LONGITUDE,
        ExpertColumn.EXPERT_COLUMN_URI,
        ExpertColumn.EXPERT_COLUMN_RESPONSE_CODE,
        ExpertColumn.EXPERT_COLUMN_RESPONSE_TEXT,
        ExpertColumn.EXPERT_COLUMN_CONTENT_TYPE,
        ExpertColumn.EXPERT_COLUMN_REFERER,
        ExpertColumn.EXPERT_COLUMN_HOST,
        ExpertColumn.EXPERT_COLUMN_PACKET_COUNT,
        ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT,
        ExpertColumn.EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT,
        ExpertColumn.EXPERT_COLUMN_BYTE_COUNT,
        ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_BYTE_COUNT,
        ExpertColumn.EXPERT_COLUMN_SERVER_SENT_BYTE_COUNT,
        ExpertColumn.EXPERT_COLUMN_REQUEST_PAYLOAD_BYTE_COUNT,
        ExpertColumn.EXPERT_COLUMN_RESPONSE_PAYLOAD_BYTE_COUNT,
        ExpertColumn.EXPERT_COLUMN_START_TIME,
        ExpertColumn.EXPERT_COLUMN_CLIENT_START_TIME,
        ExpertColumn.EXPERT_COLUMN_SERVER_START_TIME,
        ExpertColumn.EXPERT_COLUMN_END_TIME,
        ExpertColumn.EXPERT_COLUMN_CLIENT_END_TIME,
        ExpertColumn.EXPERT_COLUMN_SERVER_END_TIME,
        ExpertColumn.EXPERT_COLUMN_DURATION,
        ExpertColumn.EXPERT_COLUMN_REQUEST_HEADER,
        ExpertColumn.EXPERT_COLUMN_RESPONSE_HEADER,
        /*ExpertColumn.EXPERT_COLUMN_NODE_1,
        ExpertColumn.EXPERT_COLUMN_NODE_2,
        ExpertColumn.EXPERT_COLUMN_HIGHLIGHT,
        ExpertColumn.EXPERT_COLUMN_EVENT_TIME,*/
      ],
      orderBy: [],
      orderByAscending: true,
      view: ExpertView.EXPERT_VIEW_HTTP_CLIENT_HIERARCHY,
      viewSettings: {
        la: "\u2190",
        ra: "\u2192",
        ba: "\u2194",
        showAddressNames: true,
        showPortNames: true,
      },
    },
    {
      columnList: [],
      view: ExpertView.EXPERT_VIEW_HEADER_COUNTERS,
    },
  ],
}

type WebClientsViewProps = RouteComponentProps<CaptureRouteParams> &
  CaptureViewProps & {
    dispatch: Function
    engine: string
    authToken: string
    namesModTime?: string
    showAddressNames: boolean
    showPortNames: boolean
    showLocalTime: boolean
    columns: ExpertTableColumn[]
    sortBy: ExpertColumn
    sortDirection: SortDirectionType
    engineCapabilities: ResponseGetEngineCapabilities | null
    userId: string
  }

type WebClientsViewState = {
  resultSet: ExpertQueryResponse | null
  expansionState: ExpertTreeExpand
  detailsOpen: boolean
  detailsTitle: string
  detailsColumnList: ExpertColumn[] | null
  detailsRowData: ExpertValue[] | null
  insertNameEntries: InsertNameEntry[] | null
}

class WebClientsView extends React.Component<WebClientsViewProps, WebClientsViewState> {
  state: WebClientsViewState = {
    resultSet: null,
    expansionState: {
      defaultState: "collapse-all",
      exceptionList: [],
    },
    detailsOpen: false,
    detailsTitle: "",
    detailsColumnList: null,
    detailsRowData: null,
    insertNameEntries: null,
  }

  componentDidMount() {
    this.onRefresh()
  }

  componentDidUpdate({
    namesModTime,
    showAddressNames,
    showPortNames,
    sortBy,
    sortDirection,
  }: WebClientsViewProps) {
    if (
      this.props.namesModTime !== namesModTime ||
      this.props.showAddressNames !== showAddressNames ||
      this.props.showPortNames !== showPortNames ||
      this.props.sortBy !== sortBy ||
      this.props.sortDirection !== sortDirection
    ) {
      this.setState({ detailsOpen: false })
      this.onRefresh()
    }
  }

  onRefresh = () => {
    const { sortBy, sortDirection } = this.props
    const query = cloneDeep(queryTemplate)
    if (query.query[0].orderBy !== undefined) {
      query.query[0].orderBy = [
        sortBy,
        ExpertColumn.EXPERT_COLUMN_NODEPAIR,
        ExpertColumn.EXPERT_COLUMN_REQUEST_ID,
        ExpertColumn.EXPERT_COLUMN_PAGE_REQUEST_ID,
      ]
    }
    query.query[0].orderByAscending = sortDirection === SortDirection.ASC
    query.query[0].treeExpand = this.state.expansionState
    if (query.query[0].viewSettings) {
      query.query[0].viewSettings.showAddressNames = this.props.showAddressNames
      query.query[0].viewSettings.showPortNames = this.props.showPortNames
    }
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    expertQueryCFS(engine, authToken, type, capId, query)
      .then((resultSet: ExpertQueryResponse) => {
        if (resultSet.results) {
          this.setState({ resultSet })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onExport = () => {
    // TODO
  }

  onSort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
    this.props.dispatch(setWebClientsSort(parseInt(sortBy, 10) as ExpertColumn, sortDirection))
  }

  onShowDefaultColumns = () => {
    this.props.dispatch(setWebClientsColumns(defaultColumns))
  }

  onShowAllColumns = () => {
    const columns = cloneDeep(this.props.columns)
    columns.forEach(col => {
      col.visible = true
    })
    this.props.dispatch(setWebClientsColumns(columns))
  }

  onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const columns = cloneDeep(this.props.columns)
    const col = columns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      this.props.dispatch(setWebClientsColumns(columns))
    }
  }

  onFlowVisualizer = (rowData: ExpertValue[]) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]
    const flowId = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_STREAM_ID
    )
    if (flowId != null && typeof flowId.value === "number") {
      this.props.history.push(`flow-visualizer/${flowId.value}`)
    }
  }

  onSelectRelatedExpert = (command: string, rowData: ExpertValue[]) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    const request = selectRelatedExpertRequestFromRowData(command, rowData, results.columnList)
    if (request != null) {
      const { capId } = this.props.match.params
      const { engine, authToken } = this.props
      postSelectRelatedExpertStart(engine, authToken, capId, request)
        .then((task: ResponsePostSelectRelatedExpertStart) => {
          if (task.taskId) {
            this.props.dispatch(
              setSelectPacketsTask({ type: "expert", taskId: task.taskId, progress: 0 })
            )
            this.props.history.push("packets")
          }
        })
        .catch(() => {
          console.error("Error during select related")
        })
    }
  }

  onSelectRelatedWeb = (command: string, rowData: ExpertValue[]) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    const request = selectRelatedWebRequestFromRowData(command, rowData, results.columnList)
    if (request != null) {
      const { capId } = this.props.match.params
      const { engine, authToken } = this.props
      postSelectRelatedWebStart(engine, authToken, capId, request)
        .then((task: ResponsePostSelectRelatedWebStart) => {
          if (task.taskId) {
            this.props.dispatch(
              setSelectPacketsTask({ type: "web", taskId: task.taskId, progress: 0 })
            )
            this.props.history.push("packets")
          }
        })
        .catch(() => {
          console.error("Error during select related")
        })
    }
  }

  onMSA = (rowData: ExpertValue[]) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]
    const startTime = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_START_TIME
    )
    const endTime = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_END_TIME
    )
    const filter = filterExpressionFromRowData(rowData, results.columnList)
    if (startTime && endTime && filter) {
      this.props.dispatch(setCurrentEngine(null))
      this.props.history.push({
        pathname: getNewDistributedForensicSearchUrl(),
        state: { startTime: startTime.value, endTime: endTime.value, filter },
      })
    }
  }

  onMakeFilter = (rowData: ExpertValue[]) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]
    const filter = filterFromRowData(rowData, results.columnList)
    if (filter != null) {
      this.props.history.push({
        pathname: getEngineNewFilterUrl(),
        state: { filter },
      })
    }
  }

  onInsertIntoNameTable = (rowData: ExpertValue[]) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    const insertNameEntries: InsertNameEntry[] = []

    const clientAddr = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS
    )
    if (clientAddr && clientAddr.rendered && clientAddr.value) {
      insertNameEntries.push({
        title: "Client",
        entry: clientAddr.rendered,
        entryType: (clientAddr.value as any).type,
      })
    }

    const serverAddr = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS
    )
    if (serverAddr && serverAddr.rendered && serverAddr.value) {
      insertNameEntries.push({
        title: "Server",
        entry: serverAddr.rendered,
        entryType: (serverAddr.value as any).type,
      })
    }

    if (insertNameEntries.length > 0) {
      this.setState({ insertNameEntries })
    }
  }

  onInsertIntoNameTableOK = () => {
    this.setState({ insertNameEntries: null })
    this.onRefresh()
  }

  onInsertIntoNameTableCancel = () => {
    this.setState({ insertNameEntries: null })
  }

  onResolveNames = (rowData: ExpertValue[]) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    const entries: AddressResolverRequestEntry[] = []

    try {
      const clientAddr = getExpertValueFromRowData(
        rowData,
        results.columnList,
        ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS
      )
      if (clientAddr != null && clientAddr.value != null) {
        const clientAddrSpec = clientAddr.value as MediaSpec
        entries.push({
          entry: formatMediaSpec(clientAddrSpec),
          entryType: clientAddrSpec.type,
        })
      }
    } catch (e) {
      console.error(e)
    }

    try {
      const serverAddr = getExpertValueFromRowData(
        rowData,
        results.columnList,
        ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS
      )
      if (serverAddr != null && serverAddr.value != null) {
        const serverAddrSpec = serverAddr.value as MediaSpec
        entries.push({
          entry: formatMediaSpec(serverAddrSpec),
          entryType: serverAddrSpec.type,
        })
      }
    } catch (e) {
      console.error(e)
    }

    if (entries.length > 0) {
      resolveAddresses(this.props.engine, this.props.authToken, entries).catch(error => {
        console.error(error)
      })
      this.props.dispatch(updateStatus())
    }
  }

  onDetailsOpen = (open: boolean) => {
    this.setState({ detailsOpen: open })
  }

  onExpandAll = () => {
    this.setState(
      {
        expansionState: {
          defaultState: "expand-all",
          exceptionList: [],
        },
      },
      () => {
        this.onRefresh()
      }
    )
  }

  onCollapseAll = () => {
    this.setState(
      {
        expansionState: {
          defaultState: "collapse-all",
          exceptionList: [],
        },
      },
      () => {
        this.onRefresh()
      }
    )
  }

  onExpandCollapse = (rowData: ExpertValue[]) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    const treeStateValue = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_TREE_STATE
    )
    if (treeStateValue && typeof treeStateValue.value === "number") {
      const treeState = treeStateValue.value
      const isExpanding = !isExpanded(treeState)

      const keyColumns = [
        ExpertColumn.EXPERT_COLUMN_NODEPAIR,
        ExpertColumn.EXPERT_COLUMN_PAGE_REQUEST_ID,
      ]
      const key: ExpertKeyItemKey = { key: [] }
      for (const columnId of keyColumns) {
        const valueType = getExpertValueFromRowData(
          rowData,
          results.columnList,
          ExpertColumn.EXPERT_COLUMN_TYPE
        )
        const value = getExpertValueFromRowData(rowData, results.columnList, columnId)
        if (value != null && value.value != null) {
          key.key.push({
            column: columnId,
            value: value.value.toString(),
          })
        } else if (
          valueType != null &&
          valueType.value != null &&
          valueType.value === ExpertRowType.EXPERT_ROW_TYPE_HTTP_PAGE &&
          typeof value === "object" &&
          value.value == null
        ) {
          key.key.push({
            column: columnId,
            value: null,
          })
        }
      }

      const expansionState = cloneDeep(this.state.expansionState)

      const setRowState = (
        expansionState: ExpertTreeExpand,
        key: ExpertKeyItemKey,
        isExpanding: boolean
      ) => {
        // Does "a" contain "b"?
        const keyContains = (a: ExpertKeyItemKey, b: ExpertKeyItemKey) => {
          for (const idA of a.key) {
            if (idA.column !== 0) {
              const idB = b.key.find(idB => idB.column === idA.column)
              if (idB == null) return false
              if (!isEqual(idA.value, idB.value)) {
                return false
              }
            }
          }
          return true
        }

        const isKey = (element: ExpertKeyItemKey) => {
          return keyContains(key, element) && keyContains(element, key)
        }

        const index = expansionState.exceptionList.findIndex(isKey)
        const isExpandAll = expansionState.defaultState === "expand-all"
        if (isExpanding === isExpandAll) {
          if (index !== -1) {
            expansionState.exceptionList.splice(index, 1)
          }
        } else {
          if (index === -1) {
            expansionState.exceptionList.push(key)
          }
        }
      }

      setRowState(expansionState, key, isExpanding)

      this.setState({ expansionState }, () => {
        this.onRefresh()
      })
    }
  }

  onRowClick = ({ index }: { index: number }) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return

    const results = resultSet.results[0]
    if (results.rowList) {
      const columnList = results.columnList
      const rowData = results.rowList[index]
      if (rowData) {
        // Build a title for the details sidebar.
        let detailsTitle = ""
        const clientAddr = formatExpertValueFromRowData(
          rowData,
          results.columnList,
          ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS
        )
        const serverAddr = formatExpertValueFromRowData(
          rowData,
          results.columnList,
          ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS
        )
        const clientPort = formatExpertValueFromRowData(
          rowData,
          results.columnList,
          ExpertColumn.EXPERT_COLUMN_CLIENT_PORT
        )
        const serverPort = formatExpertValueFromRowData(
          rowData,
          results.columnList,
          ExpertColumn.EXPERT_COLUMN_SERVER_PORT
        )
        // TODO: format differently if IPv6
        if (clientAddr != null && serverAddr != null) {
          if (clientPort != null && serverPort != null) {
            detailsTitle = `${clientAddr}:${clientPort}\u2194${serverAddr}:${serverPort}`
          } else {
            detailsTitle = `${clientAddr}\u2194${serverAddr}`
          }
        } else if (clientAddr != null) {
          if (clientPort != null) {
            detailsTitle = `${clientAddr}:${clientPort}`
          } else {
            detailsTitle = `${clientAddr}`
          }
        } else if (serverAddr != null) {
          if (serverPort != null) {
            detailsTitle = `${serverAddr}:${serverPort}`
          } else {
            detailsTitle = `${serverAddr}`
          }
        }

        this.setState({
          detailsOpen: true,
          detailsTitle,
          detailsColumnList: cloneDeep(columnList),
          detailsRowData: cloneDeep(rowData),
        })
      }
    }
  }

  renderCommands = ({ rowData }: TableCellProps) => {
    const { captureProperties, engineCapabilities, userId } = this.props
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return undefined
    const results = resultSet.results[0]

    const clientAddr = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS
    )
    const hasClientAddr = clientAddr != null && clientAddr.rendered

    const serverAddr = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS
    )
    const hasServerAddr = serverAddr != null && serverAddr.rendered

    const clientPort = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_CLIENT_PORT
    )
    const hasClientPort = clientPort != null && typeof clientPort.value === "number"

    const serverPort = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_SERVER_PORT
    )
    const hasServerPort = serverPort != null && typeof serverPort.value === "number"

    const clientPackets = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT
    )
    const hasClientPackets =
      clientPackets != null && typeof clientPackets.value === "number" && clientPackets.value > 0

    const serverPackets = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT
    )
    const hasServerPackets =
      serverPackets != null && typeof serverPackets.value === "number" && serverPackets.value > 0

    const flowId = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_STREAM_ID
    )
    const hasFlowId = flowId != null && typeof flowId.value === "number"

    const requestId = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_REQUEST_ID
    )
    const hasRequestId = requestId != null && typeof requestId.value === "number"

    const packetsDisabled = captureProperties === null || !captureProperties.packetBufferEnabled
    const expertDisabled = captureProperties === null || !captureProperties.expertEnabled

    // make sure the user can view packets for this capture or forensic search
    let canCreateForensicSearch = true
    let canUploadFiles = true
    if (captureProperties && engineCapabilities) {
      const policies = engineCapabilities.userRights.policies
      canCreateForensicSearch =
        !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL) ||
        policies.includes(EngineUserPolicies.createForensicSearch)
      canUploadFiles = policies.includes(EngineUserPolicies.uploadFiles)
    }

    const msaDisabled =
      !isMSAEnabled(rowData, results.columnList) || !canUploadFiles || !canCreateForensicSearch

    // make sure the user can view packets for this capture or forensic search
    let canViewPackets = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
    }

    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            {hasFlowId ? (
              <>
                <DropdownItem
                  disabled={packetsDisabled || expertDisabled}
                  onClick={this.onFlowVisualizer.bind(this, rowData)}
                >
                  Flow Visualizer
                </DropdownItem>
                <DropdownItem divider />
              </>
            ) : null}
            {hasClientAddr ? (
              <DropdownItem
                disabled={packetsDisabled || !canViewPackets}
                onClick={this.onSelectRelatedExpert.bind(this, "client", rowData)}
              >
                Select Related Packets by Client
              </DropdownItem>
            ) : null}
            {hasServerAddr ? (
              <DropdownItem
                disabled={packetsDisabled || !canViewPackets}
                onClick={this.onSelectRelatedExpert.bind(this, "server", rowData)}
              >
                Select Related Packets by Server
              </DropdownItem>
            ) : null}
            {hasClientAddr && hasServerAddr ? (
              <DropdownItem
                disabled={packetsDisabled || !canViewPackets}
                onClick={this.onSelectRelatedExpert.bind(this, "clientAndServer", rowData)}
              >
                Select Related Packets by Client &amp; Server
              </DropdownItem>
            ) : null}
            {hasClientPort && hasServerPort ? (
              <DropdownItem
                disabled={packetsDisabled || !canViewPackets}
                onClick={this.onSelectRelatedExpert.bind(this, "port", rowData)}
              >
                Select Related Packets by Port
              </DropdownItem>
            ) : null}
            {hasFlowId ? (
              <DropdownItem
                disabled={packetsDisabled || !canViewPackets}
                onClick={this.onSelectRelatedExpert.bind(this, "flowId", rowData)}
              >
                Select Related Packets by Flow ID
              </DropdownItem>
            ) : null}
            {hasRequestId ? (
              <>
                {hasClientPackets && (
                  <DropdownItem
                    disabled={packetsDisabled || !canViewPackets}
                    onClick={this.onSelectRelatedWeb.bind(this, "request", rowData)}
                  >
                    Select Related Packets by Request
                  </DropdownItem>
                )}
                {hasServerPackets && (
                  <DropdownItem
                    disabled={packetsDisabled || !canViewPackets}
                    onClick={this.onSelectRelatedWeb.bind(this, "response", rowData)}
                  >
                    Select Related Packets by Response
                  </DropdownItem>
                )}
                {hasClientPackets && hasServerPackets && (
                  <DropdownItem
                    disabled={packetsDisabled || !canViewPackets}
                    onClick={this.onSelectRelatedWeb.bind(this, "requestAndResponse", rowData)}
                  >
                    Select Related Packets by Request &amp; Response
                  </DropdownItem>
                )}
              </>
            ) : null}
            {hasClientAddr ||
            hasServerAddr ||
            (hasClientPort && hasServerPort) ||
            hasFlowId ||
            hasRequestId ? (
              <DropdownItem divider />
            ) : null}
            <DropdownItem disabled={msaDisabled} onClick={this.onMSA.bind(this, rowData)}>
              Multi-Segment Analysis
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.onMakeFilter.bind(this, rowData)}>Make Filter</DropdownItem>
            <DropdownItem
              disabled={!hasClientAddr && !hasServerAddr}
              onClick={this.onInsertIntoNameTable.bind(this, rowData)}
            >
              Insert Into Name Table
            </DropdownItem>
            <DropdownItem
              disabled={!hasClientAddr && !hasServerAddr}
              onClick={this.onResolveNames.bind(this, rowData)}
            >
              Resolve Names
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  render() {
    const {
      resultSet,
      detailsOpen,
      detailsTitle,
      detailsColumnList,
      detailsRowData,
      insertNameEntries,
    } = this.state
    const { captureProperties, columns, engineCapabilities, sortBy, sortDirection, userId } =
      this.props

    // make sure the user can view stats for this capture or forensic search
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
      if (!canViewStats) {
        const { type, capId } = this.props.match.params
        return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
      }
    }

    const webHeaderCounts = getWebHeaderCounts(resultSet)
    return (
      <View>
        <BreadcrumbItem to={this.props.match.url} title="Web Clients" />
        <ViewHeader>
          <ViewHeaderTitle title="Web Clients" />
          <ViewHeaderButtons>
            {/* TODO
            <LightButton aria-label="Export" id="export" onClick={this.onExport}>
              <FontAwesome name="download" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="export">
              Export
            </UncontrolledTooltip>
            */}
            <LightButton id="expand-all" onClick={this.onExpandAll}>
              Expand All
            </LightButton>
            <LightButton id="collapse-all" onClick={this.onCollapseAll}>
              Collapse All
            </LightButton>
            <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefresh}>
              <FontAwesome name="refresh" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="refresh">
              Refresh
            </UncontrolledTooltip>
          </ViewHeaderButtons>
        </ViewHeader>
        {resultSet ? (
          <>
            <ViewHeaderPanel>
              <PropTable
                data={webHeaderCounts}
                propList={propList}
                formatProp={formatWebHeaderCountsProp}
              />
            </ViewHeaderPanel>
            <ViewContent>
              <ExpertTable
                resultSet={resultSet}
                renderCommands={this.renderCommands}
                columnDesc={columns}
                onShowDefaultColumns={this.onShowDefaultColumns}
                onShowAllColumns={this.onShowAllColumns}
                onToggleColumn={this.onToggleColumn}
                onExpandCollapse={this.onExpandCollapse}
                onRowClick={this.onRowClick}
                sort={this.onSort}
                sortBy={sortBy.toString()}
                sortDirection={sortDirection}
                showLocalTime={this.props.showLocalTime}
              />
            </ViewContent>
          </>
        ) : (
          <CenterContent>
            <Spinner />
          </CenterContent>
        )}
        <Sidebar open={detailsOpen}>
          <SidebarBody open={detailsOpen}>
            <SidebarHeader>
              <SidebarTitle>{`${detailsTitle} Details`}</SidebarTitle>
              <CloseButton onClick={() => this.onDetailsOpen(false)} />
            </SidebarHeader>
            <SidebarContent>
              <WebRequestDetails
                captureProperties={captureProperties}
                columnList={detailsColumnList}
                rowData={detailsRowData}
              />
            </SidebarContent>
          </SidebarBody>
        </Sidebar>
        {insertNameEntries != null && (
          <InsertNamesModal
            engine={this.props.engine}
            authToken={this.props.authToken}
            entries={insertNameEntries}
            onOK={this.onInsertIntoNameTableOK}
            onCancel={this.onInsertIntoNameTableCancel}
          />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  namesModTime: getNamesModificationTime(state),
  showAddressNames: getShowAddressNames(state),
  showPortNames: getShowPortNames(state),
  showLocalTime: getShowLocalTime(state),
  columns: getWebClientsColumns(state) || defaultColumns,
  sortBy: getWebClientsSortBy(state) || ExpertColumn.EXPERT_COLUMN_REQUEST_ID,
  sortDirection: getWebClientsSortDirection(state) || SortDirection.ASC,
  engineCapabilities: getCapabilities(state) || null,
  userId: getUserId(state),
})

export default connect(mapStateToProps)(WebClientsView)
