import * as React from "react"
import { connect } from "react-redux"
import styled, { withTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  LabelList,
} from "recharts"
import ChartTooltip from "../../common/ChartTooltip"
import { ChartType } from "../../../api/types/chartTypes"
import {
  HalfWidthWidget,
  WidgetHeader,
  WidgetTitle,
  WidgetOptions,
  WidgetBody,
  NoData,
} from "../Dashboard"
import { IconDropdownToggle } from "../../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../../common/Dropdown"
import { formatPercentChartLabel, formatPercentChartTooltip } from "../../../utils/chartUtils"
import {
  setCallQualityDistributionWidgetChartType,
  setCallQualityDistributionWidgetChartScale,
} from "../../../store/ui"
import {
  getCallQualityDistributionWidgetChartType,
  getCallQualityDistributionWidgetChartScale,
} from "../../../store"

const CallQualityDistributionChartLayout = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  flex-grow: 1;
  max-width: 100%;
`

const CallQualityDistributionChartContainer = styled.div`
  display: flex;
  flex-direction: column;

  & h5 {
    text-align: center;
  }
`

const CallQualityDistributionChart = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

type Props = {
  callQualityDistribution: any | null
  chartType: ChartType
  chartScale: "linear" | "log"
  theme: any
  setCallQualityDistributionWidgetChartType: (chartType: ChartType) => void
  setCallQualityDistributionWidgetChartScale: (chartScale: string) => void
}

class CallQualityDistributionWidget extends React.Component<Props> {
  render() {
    const { callQualityDistribution, theme } = this.props
    const labelListProps = {
      fill: theme.textMutedColor,
      stroke: "none",
    }
    return (
      <HalfWidthWidget>
        <WidgetHeader>
          <WidgetTitle>Call Quality Distribution</WidgetTitle>
          {/* TODO */}
          <WidgetOptions style={{ display: "none" }}>
            <UncontrolledDropdown>
              <IconDropdownToggle aria-label="Settings">
                <FontAwesome name="gear" style={{ color: theme.textMutedColor }} />
              </IconDropdownToggle>
              <DropdownMenu end>
                <DropdownItem disabled>Bar</DropdownItem>
                <DropdownItem disabled>Column</DropdownItem>
                <DropdownItem disabled>Pie</DropdownItem>
                <DropdownItem disabled>Donut</DropdownItem>
                <DropdownItem divider />
                <DropdownItem disabled>Auto Scale</DropdownItem>
                <DropdownItem disabled>Fixed Scale</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </WidgetOptions>
        </WidgetHeader>
        <WidgetBody minHeight="166px">
          {callQualityDistribution ? (
            <CallQualityDistributionChartLayout>
              <CallQualityDistributionChartContainer>
                <h5>All Calls</h5>
                <CallQualityDistributionChart>
                  <ResponsiveContainer height={140} width="100%">
                    <BarChart
                      data={callQualityDistribution.allCalls}
                      margin={{ top: 16, right: 0, left: 0, bottom: 0 }}
                    >
                      <Tooltip
                        isAnimationActive={false}
                        formatter={formatPercentChartTooltip.bind(this, "calls", "totalCalls")}
                        content={<ChartTooltip />}
                      />
                      <CartesianGrid stroke={theme.chartGridColor} vertical={false} />
                      <Bar dataKey="pct" isAnimationActive={false}>
                        <LabelList
                          dataKey="pct"
                          position="top"
                          formatter={formatPercentChartLabel}
                          {...labelListProps}
                        />
                        {callQualityDistribution.allCalls.map((entry: any, i: number) => (
                          <Cell key={i} fill={entry.fill} stroke={entry.stroke} />
                        ))}
                      </Bar>
                      <YAxis domain={[0, 100]} stroke={theme.textColor} width={32} />
                      <XAxis dataKey="name" stroke={theme.textColor} interval={0} height={22} />
                    </BarChart>
                  </ResponsiveContainer>
                </CallQualityDistributionChart>
              </CallQualityDistributionChartContainer>
              <CallQualityDistributionChartContainer>
                <h5>Open Calls</h5>
                <CallQualityDistributionChart>
                  <ResponsiveContainer height={140} width="100%">
                    <BarChart
                      data={callQualityDistribution.openCalls}
                      margin={{ top: 16, right: 0, left: 0, bottom: 0 }}
                    >
                      <Tooltip
                        isAnimationActive={false}
                        formatter={formatPercentChartTooltip.bind(this, "calls", "totalCalls")}
                        content={<ChartTooltip />}
                      />
                      <CartesianGrid stroke={theme.chartGridColor} vertical={false} />
                      <Bar dataKey="pct" isAnimationActive={false}>
                        <LabelList
                          dataKey="pct"
                          position="top"
                          formatter={formatPercentChartLabel}
                          {...labelListProps}
                        />
                        {callQualityDistribution.openCalls.map((entry: any, i: number) => (
                          <Cell key={i} fill={entry.fill} stroke={entry.stroke} />
                        ))}
                      </Bar>
                      <YAxis domain={[0, 100]} stroke={theme.textColor} width={32} />
                      <XAxis dataKey="name" stroke={theme.textColor} interval={0} height={22} />
                    </BarChart>
                  </ResponsiveContainer>
                </CallQualityDistributionChart>
              </CallQualityDistributionChartContainer>
            </CallQualityDistributionChartLayout>
          ) : (
            <NoData>No Data</NoData>
          )}
        </WidgetBody>
      </HalfWidthWidget>
    )
  }
}

const mapStateToProps = (state: any) => ({
  chartType: getCallQualityDistributionWidgetChartType(state) || ChartType.COLUMN,
  chartScale: getCallQualityDistributionWidgetChartScale(state) || "fixed",
})

const mapDisptachToProps = {
  setCallQualityDistributionWidgetChartType,
  setCallQualityDistributionWidgetChartScale,
}

export default withTheme(
  connect(mapStateToProps, mapDisptachToProps)(CallQualityDistributionWidget)
)
