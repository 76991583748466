import * as React from "react"
import { UncontrolledPopoverProps as ReactstrapUncontrolledPopoverProps } from "reactstrap"
import { Popover } from "../Popover"

type UncontrolledPopoverProps = ReactstrapUncontrolledPopoverProps & {
  disabled?: boolean
  maxWidth?: number | string
}

type UncontrolledPopoverState = {
  isOpen: boolean
}

export class UncontrolledPopover extends React.PureComponent<
  UncontrolledPopoverProps,
  UncontrolledPopoverState
> {
  state: UncontrolledPopoverState = {
    isOpen: false,
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return <Popover isOpen={this.state.isOpen} toggle={this.toggle} {...this.props} />
  }
}

export default UncontrolledPopover
