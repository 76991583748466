import { SortDirection, SortDirectionType } from "react-virtualized"
import {
  AlarmCollection,
  CaptureCollection,
  EngineSettings,
  FilterCollection,
  GraphCollection,
  HardwareOptionsCollection,
  LiveFlowConfiguration,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { collator } from "../../utils/sortUtils"

export type AuthSettings = {
  sameCreds: boolean
  sameUsername: string
  samePassword: string
  sameClientAuth: boolean
}

export type ConfigurationType =
  | "alarms"
  | "captures"
  | "engineSettings"
  | "filters"
  | "graphs"
  | "hardwareProfiles"

export type ConfigurationItem = {
  type: ConfigurationType
  name: string
  description: string
  captureDependent: boolean
  policies: string[]
}

export const ConfigurationItems: ConfigurationItem[] = [
  { type: "alarms", name: "Alarms", description: "", captureDependent: true, policies: [] },
  {
    type: "captures",
    name: "Captures",
    description: "",
    captureDependent: false,
    policies: [
      EngineUserPolicies.createCapture,
      EngineUserPolicies.deleteCaptures,
      EngineUserPolicies.modifyCaptures,
      EngineUserPolicies.startStopCaptures,
    ],
  },
  {
    type: "engineSettings",
    name: "Engine Settings",
    description: "Security, authentication, access control, and OpenTelemetry settings ",
    captureDependent: true,
    policies: [EngineUserPolicies.configureEngine],
  },
  { type: "filters", name: "Filters", description: "", captureDependent: true, policies: [] },
  { type: "graphs", name: "Graphs", description: "", captureDependent: true, policies: [] },
  {
    type: "hardwareProfiles",
    name: "Hardware Profiles",
    description: "",
    captureDependent: true,
    policies: [EngineUserPolicies.configureEngine],
  },
]

export type LiveWireConfiguration = {
  alarms: AlarmCollection | null
  captures: CaptureCollection[] | null
  filters: FilterCollection | null
  graphs: GraphCollection | null
  hardwareProfiles: HardwareOptionsCollection | null
  liveFlowConfig: LiveFlowConfiguration | null
  settings: EngineSettings | null
}

export type SyncResult = {
  syncMessages: string[]
  syncResult: number
  syncType: ConfigurationType
}

export type EngineConnection = {
  engineId: string
  engineGroup: string
  engineHost: string
  engineName: string
  engineUrl: string
  username: string
  password: string
  twoFactor: boolean
  otp: string
  clientAuth: boolean
  authToken: string
  version: string
  valid: boolean
  policies: string[]
  settings: EngineSettings | null
  syncResults: SyncResult[]
  syncStatus: "idle" | "running" | "success" | "error"
  syncError: string
}

export function engineCanSyncConfigType(
  conn: EngineConnection,
  configType: ConfigurationType,
  deleteCaptureSessions: boolean
) {
  const config = ConfigurationItems.find(
    (configItem: ConfigurationItem) => configItem.type === configType
  )
  return config
    ? [...config.policies]
        .concat(deleteCaptureSessions ? [EngineUserPolicies.deleteFiles] : [])
        .every((policy: string) => conn.policies.includes(policy))
    : false
}

export function sortConfigurationItems(
  configItems: ConfigurationItem[],
  sortBy: string,
  sortDirection: SortDirectionType
) {
  configItems.sort((a: ConfigurationItem, b: ConfigurationItem) => {
    let result = 0
    let valueA = ""
    let valueB = ""
    switch (sortBy) {
      case "name":
        valueA = a.name
        valueB = b.name
        break
      case "description":
        valueA = a.description
        valueB = b.description
        break
    }
    if (valueA === "") {
      result = 1
    } else if (valueB === "") {
      result = -1
    } else {
      result = collator.compare(valueA, valueB)
    }
    if (sortDirection === SortDirection.DESC) result = -result
    return result
  })
}
