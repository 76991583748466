import ThemeInterface from "../../themes/theme"

// Constants

export const WebPluginBrowseFlags = {
  WEB_PLUGIN_BROWSE_FLAGS_HIDE_HIDDEN_CONTROL: 0x02,
  WEB_PLUGIN_BROWSE_FLAGS_SHOW_HIDDEN: 0x04,
  WEB_PLUGIN_BROWSE_FLAGS_SHOW_FILES: 0x08,
}

export const WebPluginMessage = {
  WEB_PLUGIN_MESSAGE_ADAPTER_INFO: "AdapterInfo",
  WEB_PLUGIN_MESSAGE_BROWSE_REMOTE: "BrowseRemote",
  WEB_PLUGIN_MESSAGE_COMMAND_MESSAGE: "CommandMessage",
  WEB_PLUGIN_MESSAGE_CONTEXT_ID: "ContextId",
  WEB_PLUGIN_MESSAGE_DISPLAY_PROPERTIES: "DisplayProperties",
  WEB_PLUGIN_MESSAGE_DPI_INFO: "DPIInfo", // todo
  WEB_PLUGIN_MESSAGE_ENGINE_INFO: "EngineInfo",
  WEB_PLUGIN_MESSAGE_GET_PREFERENCES: "GetPreferences",
  WEB_PLUGIN_MESSAGE_GET_REMOTE_FILE: "GetRemoteFile",
  WEB_PLUGIN_MESSAGE_MEDIA_INFO: "MediaInfo",
  WEB_PLUGIN_MESSAGE_NAME_TABLE: "NameTable",
  WEB_PLUGIN_MESSAGE_PLUGIN_MESSAGE: "PluginMessage",
  WEB_PLUGIN_MESSAGE_PREPARE_TO_UNLOAD: "PrepareToUnload",
  WEB_PLUGIN_MESSAGE_READY_TO_CLOSE: "ReadyToClose",
  WEB_PLUGIN_MESSAGE_PUT_ACL_INFO: "PutACLInfo",
  WEB_PLUGIN_MESSAGE_PUT_PREFERENCES: "PutPreferences",
  WEB_PLUGIN_MESSAGE_SEND_PREFERENCES: "SendPreferences",
  WEB_PLUGIN_MESSAGE_SET_PREFERENCES: "SetPreferences",
  WEB_PLUGIN_MESSAGE_SET_REMOTE_FILE: "SetRemoteFile",
  WEB_PLUGIN_MESSAGE_THEME: "Theme",
  WEB_PLUGIN_MESSAGE_TITLE: "Title",
}

export enum WebPluginMessageCommand {
  WEB_PLUGIN_MESSAGE_COMMAND_SELECT_PACKETS_BY_TIME_AND_FILTER = 0,
  WEB_PLUGIN_MESSAGE_COMMAND_FORENSIC_SEARCH = 1,
  WEB_PLUGIN_MESSAGE_COMMAND_DISPLAY_MESSAGE = 2,
}

// Types (General)

export interface WebPluginMessageRequest {
  data: any
  message: string
}

export interface WebPluginMessageResponse {
  data: any
  message: string
}

// Types (Specific)

export interface WebPluginData_AdapterInfo {
  id: string
  title: string
}

export type WebPluginData_BrowseRemoteFileRequest = string

export interface WebPluginData_BrowseRemoteResponse {
  flags: number
  path: string
}

export interface WebPluginData_CommandMessageRequest {
  id: number
  result: number
  transid: number
}

export interface WebPluginData_CommandMessageResponseDisplayMessage {
  flags: number
  id: number
  message: string
  title: string
  transid: number
}

export interface WebPluginData_CommandMessageResponseForensicSearch {
  filter: string
  id: number
  start: number
  stop: number
  transid: number
}

export interface WebPluginData_CommandMessageResponseSelectPacketsByTimeAndFilter {
  flags: number
  filter: string
  id: number
  start: number
  stop: number
  transid: number
}

export type WebPluginData_CommandMessageResponse =
  | WebPluginData_CommandMessageResponseDisplayMessage
  | WebPluginData_CommandMessageResponseForensicSearch
  | WebPluginData_CommandMessageResponseSelectPacketsByTimeAndFilter

export type WebPluginData_ContextId = string

export type WebPluginData_DisplayPropertiesRequest = null

export interface WebPluginData_DisplayPropertiesResponse {
  height: string
  width: string
}

export interface WebPluginData_DPIInfo {
  dpi: number
}

export interface WebPluginData_EngineInfo {
  address: string
  auth: string
  dataFolder: string
  domain: string
  engineType: string
  name: string
  operatingSystem: string
  port: string
  user: string
}

export type WebPluginData_GetPreferencesRequest = null

export type WebPluginData_GetPreferencesResponse = string

export type WebPluginData_GetRemoteFileRequest = string

export type WebPluginData_GetRemoteFileResponse = string

export interface WebPluginData_MediaInfo {
  mediaSubType: number
  mediaType: number
}

export interface WebPluginData_PluginMessage {
  data: string
  message: string
}

export type WebPluginData_PrepareToUnload = null

export interface WebPluginData_PutPreferences {
  data: any
  message: string
}

export type WebPluginData_ReadyToClose = boolean

export type WebPluginData_SendPreferences = any

export type WebPluginData_SetPreferences = string

export type WebPluginData_SetRemoteFileRequest = string

export interface WebPluginData_SetRemoteFileResponse {
  content: string
  path: string
}

export type WebPluginData_Theme = ThemeInterface

export type WebPluginData_TitleRequest = null

export type WebPluginData_TitleResponse = string
