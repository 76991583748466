import styled from "styled-components"
import { parseHeaders } from "../../utils/expertUtils"
import CountryName from "../common/CountryName"
import { formatInteger } from "../../utils/formatUtils"

export const HeaderDiv = styled.div`
  word-break: break-all;
`

export const ContentOuter = styled.div`
  flex-grow: 1;
  overflow: hidden;
  background-color: ${props => props.theme.tableBackgroundColor};
  border: 1px solid ${props => props.theme.tableBorderColor};
`

export const getNameFromNameTable = (
  name: string | undefined,
  data: string,
  color: string | undefined,
  theme: any
) => {
  let retval
  if (color && theme.name === "Light") {
    retval = (
      <span>
        {data}{" "}
        <span style={{ color: color }} title={name}>
          {" "}
          {name}
        </span>
      </span>
    )
  } else if (color) {
    retval = <span>{data + " " + name}</span>
  } else {
    retval = data
  }
  return retval
}

export const formatCountryName = (country: string | undefined, code: string | undefined) => {
  return country ? <CountryName name={country} code={code} /> : country
}

export const parseHeadersWrapper = (
  headers: string,
  propertiesArray: any,
  fieldNames: Array<string>
) => {
  const splitHeaders: Array<string> = headers.split("\r\n")
  const initialMessage = splitHeaders.splice(0, 1)[0]
  parseHeaders(splitHeaders, propertiesArray, fieldNames)
  return initialMessage
}

export const getSlicedMessage = (filesize: string | number, filesizeLimit?: number) => {
  let slicedMessage: string = " "

  if (filesizeLimit) {
    const numFileSize = typeof filesize === "string" ? parseInt(filesize) : filesize

    if (numFileSize > filesizeLimit) {
      slicedMessage = `This file is sliced. Only showing ${formatInteger(
        filesizeLimit
      )} bytes of ${formatInteger(numFileSize)} bytes`
    }
  }

  return slicedMessage
}

export const getDisplayOption = (contentType: string, override: string = "") => {
  let displayOption: string = ""

  if (override !== "") {
    displayOption = override
  } else if (contentType !== null) {
    displayOption = contentType
  }

  return displayOption
}

export const formatPayloadSize = (payloadSize: number | string) => {
  const payloadSizeAsNumber = typeof payloadSize === "string" ? parseInt(payloadSize) : payloadSize
  const payloadSizeCommaFormatted = "(" + formatInteger(payloadSizeAsNumber) + " bytes)"
  const payloadSizeInKB = payloadSizeAsNumber / 1024
  const payloadSizeinKBasString =
    payloadSizeInKB >= 1 ? formatInteger(Math.floor(payloadSizeInKB)) : "1"

  return payloadSizeinKBasString + " KB " + payloadSizeCommaFormatted
}
