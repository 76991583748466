import * as React from "react"
import { Helmet } from "react-helmet"
import { StaticContext } from "react-router"
import { Prompt, RouteComponentProps } from "react-router-dom"
import { connect } from "react-redux"
import { Col, FormGroup } from "reactstrap"
import FileSaver from "file-saver"
import { produce } from "immer"
import styled from "styled-components"
import { cloneDeep, toNumber } from "lodash"
import { AdvancedForm } from "./AdvancedForm"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { HardwareForm } from "./HardwareForm"
import { NetworkSpeedForm } from "./NetworkSpeedForm"
import { WanForm, getPeekWanProtocolString } from "./WanForm"
import { WirelessForm } from "./WirelessForm"
import { LightButton, PrimaryButton } from "../common/Buttons"
import { CollapsibleLegend, CollapsibleSection, FieldSet, FormView, Label } from "../common/Form"
import { IconSevere } from "../common/Icons"
import { Input } from "../common/Input"
import {
  View,
  ViewAlert,
  ViewAlertContent,
  ViewContentScrollable,
  ViewContentContainer,
  ViewFooter,
} from "../common/View"
import {
  fetchAdapters,
  fetchAdapterHardwareConfigs,
  fetchAdapterHardwareConfigsXML,
  setAdapterConfig,
  setAdapterHardwareConfigs,
  setAdapterHardwareConfigsXML,
  setAdapterName,
} from "../../api/api"
import {
  Adapter,
  AdapterConfiguration,
  GigHardwareOptions,
  HardwareOptions,
  ResponseGetEngineCapabilities,
  WanHardwareOptions,
  WirelessHardwareOptions,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { MediaType } from "../../api/types/mediaTypes"
import {
  PeekAdapterFeatures,
  PeekAdapterInterfaceFeatures,
  PeekAdapterType,
  PeekWirelessHardwareConfig,
} from "../../api/types/peekTypes"
import { getEngine, getAuthToken, getCapabilities } from "../../store"
import { getAdapterId } from "../../utils/adapterUtils"
import { formatInteger, formatLinkSpeed } from "../../utils/formatUtils"
import {
  getEngineAdaptersUrl,
  getEngineAdapterUrl,
  getEngineAdapterNewHardwareProfileUrl,
  getEngineAdapterHardwareProfileUrl,
} from "../../routes"

export const OptionsLabel = styled(Label)`
  padding-top: 0rem;
`

type RouteInfo = {
  adapterId: string
  adapterType: string
}

type LocationState = {
  adapter: Adapter
  hardwareOptions: HardwareOptions[]
  showPrompt: boolean
}

type AdapterOptionsViewProps = RouteComponentProps<RouteInfo, StaticContext, LocationState> & {
  engine: string
  authToken: string
  onClose: () => void
  onOK: (
    adapterId: string,
    adapterType: PeekAdapterType,
    adapterConfig: AdapterConfiguration
  ) => void
  engineCapabilities: ResponseGetEngineCapabilities | null
}

type AdapterOptionsViewState = {
  adapter: Adapter | null
  error: any | null
  hardwareOptions: HardwareOptions[] | null
  invalidKeySet: boolean
  isAdvancedOpen: boolean
  isHardwareOptionsOpen: boolean
  isNetworkSpeedOpen: boolean
  isTitleOpen: boolean
  isWirelessOptionsOpen: boolean
  isWanOptionsOpen: boolean
  showPrompt: boolean
  title: string
}

class AdapterOptionsView extends React.Component<AdapterOptionsViewProps, AdapterOptionsViewState> {
  state: AdapterOptionsViewState = {
    adapter: null,
    error: null,
    hardwareOptions: null,
    invalidKeySet: false,
    isAdvancedOpen: true,
    isHardwareOptionsOpen: true,
    isNetworkSpeedOpen: true,
    isTitleOpen: true,
    isWirelessOptionsOpen: true,
    isWanOptionsOpen: true,
    showPrompt:
      this.props.location.state && this.props.location.state.showPrompt !== undefined
        ? this.props.location.state.showPrompt
        : false,
    title: "Adapter Options",
  }

  componentDidMount() {
    if (this.props.location.state) {
      const { adapter } = this.props.location.state
      this.setState({
        adapter,
        hardwareOptions: this.props.location.state.hardwareOptions,
        title: `Adapter Options${adapter ? ` - ${adapter.info.description}` : ""}`,
      })
    } else {
      this.onRefresh()
    }
  }

  exportHardwareFilters = () => {
    const { engine, authToken } = this.props

    fetchAdapterHardwareConfigsXML(engine, authToken)
      .then(hardwareFilters => {
        FileSaver.saveAs(
          new Blob([hardwareFilters], { type: "text/xml;charset=utf8" }),
          "HardwareFilters.flt"
        )
      })
      .catch(error => {
        this.setState({ error })
      })
  }

  getAdvancedSummary = () => {
    const { adapter } = this.state

    let summary: string = ""
    if (adapter) {
      summary = `(Buffer Size: ${formatInteger(
        Math.min(64000, Math.max(32, adapter.configuration.ringBufferSize / 1024))
      )} kilobytes)`
    }
    return summary
  }

  getHardwareOptionsSummary = () => {
    const { adapter } = this.state

    let summary: string = "(No Profiles)"
    if (adapter && adapter.hardwareOption && adapter.hardwareOption.name.length !== 0) {
      summary = `(Profile: ${adapter.hardwareOption.name})`
    }
    return summary
  }

  getNetworkSpeedSummary = () => {
    const { adapter } = this.state

    const summary: string = ""
    if (adapter) {
      if (adapter.configuration.linkSpeed === 0) {
        return `(Auto Sense: ${formatLinkSpeed(adapter.configuration.defaultLinkSpeed)})`
      } else {
        return `(${formatInteger(adapter.configuration.linkSpeed / 1000)} kbits/s)`
      }
    }
    return summary
  }

  getTitleSummary = () => {
    const { adapter } = this.state

    let summary: string = ""
    if (adapter) {
      summary = `(Adapter: ${adapter.info.description})`
    }
    return summary
  }

  getWanSummary = () => {
    const { adapter } = this.state

    let summary: string = ""
    if (
      adapter &&
      adapter.hardwareOption &&
      adapter.hardwareOption.clsid === "F64FCB14-BCA4-4707-8AE9-086349512088"
    ) {
      summary = getPeekWanProtocolString((adapter.hardwareOption as WanHardwareOptions).protocol)
    }
    return summary
  }

  getWirelessSummary = () => {
    const { adapter } = this.state

    let summary: string = ""
    if (
      adapter &&
      adapter.hardwareOption &&
      adapter.hardwareOption.clsid === "AE727C8B-0531-45C3-A97A-0E329166176C"
    ) {
      summary = `(Select Channel By ${
        (adapter.hardwareOption as WirelessHardwareOptions).config ===
        PeekWirelessHardwareConfig.PEEK_WIRELESS_HARDWARE_CONFIG_CHANNEL
          ? "Number"
          : "Scan"
      }${
        (adapter.hardwareOption as WirelessHardwareOptions).keySet !== undefined
          ? ", Key Set In Use"
          : ""
      })`
    }
    return summary
  }

  importHardwareFilters = (importFile: string, deleteAll: boolean) => {
    const { engine, authToken } = this.props

    setAdapterHardwareConfigsXML(engine, authToken, importFile, deleteAll)
      .then(() => {
        this.onRefresh()
      })
      .catch(error => {
        this.setState({ error })
      })
  }

  onCancel = () => {
    this.setState({ showPrompt: false }, () => {
      this.props.history.push(getEngineAdaptersUrl())
    })
  }

  onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      produce(draft => {
        if (draft.adapter) {
          draft.adapter.info.description = value
          draft.showPrompt = true
        }
      })
    )
  }

  onCloseAlert = () => {
    this.setState({ error: null })
  }

  onOK = () => {
    this.onSubmit()
  }

  onRefresh = () => {
    const { engine, authToken } = this.props
    const { adapterId, adapterType } = this.props.match.params

    // get the adapter
    const requestAdapters = fetchAdapters(engine, authToken)
    const requestAdapterHardwareConfigs = fetchAdapterHardwareConfigs(engine, authToken)
    Promise.all([requestAdapters, requestAdapterHardwareConfigs])
      .then(([adapters, hardwareConfigs]) => {
        const adapter = adapters.adapters.find(
          (a: Adapter) =>
            getAdapterId(a.info) === adapterId && a.info.type === toNumber(adapterType)
        )
        this.setState({
          adapter: adapter ? adapter : null,
          hardwareOptions: hardwareConfigs.hardwareOptions,
          title: `Adapter Options${adapter ? ` - ${adapter.info.description}` : ""}`,
        })
      })
      .catch(error => {
        this.setState({ error })
      })
  }

  onSubmit = () => {
    const { engine, authToken } = this.props
    const { adapterId, adapterType } = this.props.match.params
    const { adapter, hardwareOptions } = this.state

    // set the adapter information
    const requests: any[] = []
    if (adapter) {
      requests.push(
        setAdapterConfig(engine, authToken, adapterId, toNumber(adapterType), adapter.configuration)
      )
      requests.push(
        setAdapterName(
          engine,
          authToken,
          adapterId,
          toNumber(adapterType),
          adapter.info.description
        )
      )
      if (hardwareOptions) {
        requests.push(setAdapterHardwareConfigs(engine, authToken, { hardwareOptions }))
      }
    }
    Promise.all(requests)
      .then(() => {
        this.setState({ showPrompt: false }, () => {
          this.props.history.push(getEngineAdaptersUrl())
        })
      })
      .catch(error => {
        this.setState({ error })
      })
  }

  onToggle = (field: string) => {
    this.setState(state => {
      const prevState = state as any
      return { [field]: !prevState[field] } as any
    })
  }

  showHardwareProfile = (hardwareId: string | null) => {
    const oldShowPrompt = this.state.showPrompt
    this.setState({ showPrompt: false }, () => {
      const { adapter, hardwareOptions } = this.state
      if (adapter && Array.isArray(hardwareOptions)) {
        const { adapterId, adapterType } = this.props.match.params
        this.props.history.push({
          pathname:
            hardwareId != null
              ? getEngineAdapterHardwareProfileUrl(adapterId, adapterType, hardwareId)
              : getEngineAdapterNewHardwareProfileUrl(adapterId, adapterType),
          state: {
            adapter,
            hardwareOptions,
            showPrompt: oldShowPrompt,
          },
        })
      }
    })
  }

  setAdapterConfig = (adapterConfig: AdapterConfiguration) => {
    this.setState(
      produce(draft => {
        if (draft.adapter) {
          draft.adapter.configuration = adapterConfig
          draft.showPrompt = true
        }
      })
    )
  }

  setValidKeySet = (validKeySet: boolean) => {
    this.setState({ invalidKeySet: !validKeySet })
  }

  updateHardwareOptions = (hardwareOptions: HardwareOptions[], removeIds: string[]) => {
    // if the hardware option already exists replace it, otherwise add it
    this.setState(
      produce(draft => {
        draft.showPrompt = true
        hardwareOptions.forEach((hardwareOption: HardwareOptions) => {
          let index = draft.hardwareOptions.findIndex(
            (hoption: HardwareOptions) => hoption.id === hardwareOption.id
          )
          if (index !== -1) {
            draft.hardwareOptions[index] = hardwareOption
          } else {
            draft.hardwareOptions.push(hardwareOption)
          }

          index = draft.adapter.configuration.ids.findIndex(
            (id: string) => id === hardwareOption.id
          )
          if (index === -1) {
            draft.adapter.configuration.ids.push(hardwareOption.id)
          }
        })
        if (draft.adapter.hardwareOption) {
          const hardwareOption = hardwareOptions.find(
            (ho: HardwareOptions) => ho.id === draft.adapter.hardwareOption.id
          )
          if (hardwareOption) {
            draft.adapter.hardwareOption = cloneDeep(hardwareOption)
          }
        }
        draft.adapter.configuration.ids = draft.adapter.configuration.ids.filter(
          (id: string) => !removeIds.includes(id)
        )
        draft.hardwareOptions = draft.hardwareOptions.filter(
          (hoption: HardwareOptions) => !removeIds.includes(hoption.id)
        )
      })
    )
  }

  render() {
    const { engineCapabilities } = this.props
    const { adapterId, adapterType } = this.props.match.params
    const {
      adapter,
      error,
      hardwareOptions,
      invalidKeySet,
      isAdvancedOpen,
      isHardwareOptionsOpen,
      isNetworkSpeedOpen,
      isTitleOpen,
      isWirelessOptionsOpen,
      isWanOptionsOpen,
      showPrompt,
      title,
    } = this.state
    if (!adapter) return null

    // get the hardware options
    let hardwareOptionsGig: GigHardwareOptions[] = []
    let hardwareOptionWan: WanHardwareOptions | undefined = undefined
    let hardwareOptionWireless: WirelessHardwareOptions | undefined = undefined
    if (adapter.hardwareOption && adapter.configuration.ids.length > 0) {
      if (adapter.hardwareOption.clsid === "F64FCB14-BCA4-4707-8AE9-086349512088") {
        hardwareOptionWan = adapter.hardwareOption as WanHardwareOptions
      }
      if (adapter.hardwareOption.clsid === "AE727C8B-0531-45C3-A97A-0E329166176C") {
        hardwareOptionWireless = adapter.hardwareOption as WirelessHardwareOptions
      }
    }
    if (Array.isArray(hardwareOptions)) {
      hardwareOptionsGig = hardwareOptions.filter(
        (hoption: HardwareOptions) => hoption.clsid === "7DA20E4B-A157-469D-AB1A-F454D6CA5AE9"
      ) as GigHardwareOptions[]
    }

    // determine which property pages to show
    //let genericHardwareFilters = false
    let pageAdvanced = false
    let pageHardwareOptions = false
    const pageNetworkSpeed = true
    let pageWANOptions = false
    let pageWirelessOptions = false

    if (adapter.configuration.ringBufferSize !== 0) {
      pageAdvanced = true
    }

    if (adapter.info.clsid === "56A9F62B-0048-469E-BD47-121AB13237C6") {
      if ("features" in adapter.info) {
        pageHardwareOptions =
          (adapter.info.features & PeekAdapterFeatures.PEEK_ADAPTER_XYRATEX_FILTER) !== 0 &&
          ("supportsVirtual" in adapter.info
            ? !adapter.info.supportsVirtual
            : (adapter.info.features & PeekAdapterFeatures.PEEK_ADAPTER_PIPELINE_CAPTURE) === 0)
        //genericHardwareFilters = (adapter.info.features & PeekAdapterFeatures.PEEK_ADAPTER_GENERIC_FILTER) !== 0
      }
      if ("interfaceFeatures" in adapter.info) {
        pageWirelessOptions =
          (adapter.info.interfaceFeatures &
            PeekAdapterInterfaceFeatures.PEEK_ADAPTER_INTERFACE_FEATURE_RF_MONITOR) !==
          0
      }
      if ("mediaType" in adapter.info) {
        pageWANOptions =
          adapter.info.mediaType === MediaType.MEDIA_TYPE_WAN ||
          adapter.info.mediaType === MediaType.MEDIA_TYPE_COWAN
      }
    }

    const adapterSupportsDeduplication =
      "features" in adapter.info
        ? (adapter.info.features & PeekAdapterFeatures.PEEK_ADAPTER_DEDUPLICATION) !== 0
        : false

    // determine if the user can configure the engine
    let canConfigureEngine = true
    if (engineCapabilities) {
      const policies = engineCapabilities.userRights.policies
      canConfigureEngine = policies.includes(EngineUserPolicies.configureEngine)
    }

    return (
      <View padding={false}>
        <Prompt
          when={showPrompt}
          message="Warning! Changes have not been saved. Discard changes?"
        />
        <Helmet title={title} />
        <BreadcrumbItem to={getEngineAdaptersUrl()} title="Adapters" />
        <BreadcrumbItem
          to={getEngineAdapterUrl(adapterId, adapterType)}
          title={adapter.info.description}
        />
        {error && (
          <ViewAlert isOpen={true} color="danger" toggle={this.onCloseAlert}>
            <ViewAlertContent>
              <IconSevere />
              {typeof error === "string" ? error : `${error.code} ${error.reason}`}
            </ViewAlertContent>
          </ViewAlert>
        )}
        <ViewContentScrollable>
          <ViewContentContainer maxWidth="960px">
            <FormView noValidate>
              <FieldSet>
                <CollapsibleLegend
                  title="Name"
                  onClick={this.onToggle.bind(this, "isTitleOpen")}
                  isOpen={isTitleOpen}
                  getSummary={this.getTitleSummary}
                />
                <CollapsibleSection isOpen={isTitleOpen}>
                  <FormGroup row>
                    <Label md="3" for="name">
                      Adapter
                    </Label>
                    <Col md="7">
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        value={
                          adapter.info.description !== undefined ? adapter.info.description : ""
                        }
                        onChange={this.onChangeDescription}
                        readOnly={!canConfigureEngine}
                      />
                    </Col>
                  </FormGroup>
                </CollapsibleSection>
              </FieldSet>
              {pageWirelessOptions && (
                <FieldSet>
                  <CollapsibleLegend
                    title="802.11"
                    onClick={this.onToggle.bind(this, "isWirelessOptionsOpen")}
                    isOpen={isWirelessOptionsOpen}
                    getSummary={this.getWirelessSummary}
                  />
                  <CollapsibleSection isOpen={isWirelessOptionsOpen}>
                    <WirelessForm
                      adapterConfig={adapter.configuration}
                      channels={adapter.info.channels}
                      hardwareOption={hardwareOptionWireless}
                      setAdapterConfig={this.setAdapterConfig}
                      setValidKeySet={this.setValidKeySet}
                      updateHardwareOptions={this.updateHardwareOptions}
                    />
                  </CollapsibleSection>
                </FieldSet>
              )}
              {pageAdvanced && (
                <FieldSet>
                  <CollapsibleLegend
                    title="Advanced"
                    onClick={this.onToggle.bind(this, "isAdvancedOpen")}
                    isOpen={isAdvancedOpen}
                    getSummary={this.getAdvancedSummary}
                  />
                  <CollapsibleSection isOpen={isAdvancedOpen}>
                    <AdvancedForm
                      adapterConfig={adapter.configuration}
                      setAdapterConfig={this.setAdapterConfig}
                    />
                  </CollapsibleSection>
                </FieldSet>
              )}
              {pageHardwareOptions && (
                <FieldSet>
                  <CollapsibleLegend
                    title="Hardware Profiles"
                    onClick={this.onToggle.bind(this, "isHardwareOptionsOpen")}
                    isOpen={isHardwareOptionsOpen}
                    getSummary={this.getHardwareOptionsSummary}
                  />
                  <CollapsibleSection isOpen={isHardwareOptionsOpen}>
                    <HardwareForm
                      adapterConfig={adapter.configuration}
                      adapterSupportsDeduplication={adapterSupportsDeduplication}
                      exportHardwareFilters={this.exportHardwareFilters}
                      hardwareOptions={hardwareOptionsGig}
                      importHardwareFilters={this.importHardwareFilters}
                      setAdapterConfig={this.setAdapterConfig}
                      showHardwareProfile={this.showHardwareProfile}
                      updateHardwareOptions={this.updateHardwareOptions}
                    />
                  </CollapsibleSection>
                </FieldSet>
              )}
              {pageNetworkSpeed && (
                <FieldSet>
                  <CollapsibleLegend
                    title="Network Speed"
                    onClick={this.onToggle.bind(this, "isNetworkSpeedOpen")}
                    isOpen={isNetworkSpeedOpen}
                    getSummary={this.getNetworkSpeedSummary}
                  />
                  <CollapsibleSection isOpen={isNetworkSpeedOpen}>
                    <NetworkSpeedForm
                      adapterConfig={adapter.configuration}
                      setAdapterConfig={this.setAdapterConfig}
                    />
                  </CollapsibleSection>
                </FieldSet>
              )}
              {pageWANOptions && (
                <FieldSet>
                  <CollapsibleLegend
                    title="WAN"
                    onClick={this.onToggle.bind(this, "isWanOptionsOpen")}
                    isOpen={isWanOptionsOpen}
                    getSummary={this.getWanSummary}
                  />
                  <CollapsibleSection isOpen={isWanOptionsOpen}>
                    <WanForm
                      adapterConfig={adapter.configuration}
                      hardwareOption={hardwareOptionWan}
                      setAdapterConfig={this.setAdapterConfig}
                      updateHardwareOptions={this.updateHardwareOptions}
                    />
                  </CollapsibleSection>
                </FieldSet>
              )}
            </FormView>
          </ViewContentContainer>
        </ViewContentScrollable>
        <ViewFooter>
          <LightButton id="cancel" style={{ marginLeft: "auto" }} onClick={this.onCancel}>
            Cancel
          </LightButton>
          <PrimaryButton id="ok" onClick={this.onOK} disabled={invalidKeySet}>
            OK
          </PrimaryButton>
        </ViewFooter>
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  engineCapabilities: getCapabilities(state) || null,
})

export default connect(mapStateToProps)(AdapterOptionsView)
