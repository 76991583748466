export enum ExpertAuthenticationType {
  EXPERT_AUTHENTICATION_TYPE_NONE = 0,
  EXPERT_AUTHENTICATION_TYPE_EAP = 1,
  EXPERT_AUTHENTICATION_TYPE_LEAP = 2,
  EXPERT_AUTHENTICATION_TYPE_PEAP = 3,
  EXPERT_AUTHENTICATION_TYPE_EAPTLS = 4,
  EXPERT_AUTHENTICATION_TYPE_COUNT = 5,
}
export function isValidExpertAuthenticationType(value: number): boolean {
  return (
    value >= ExpertAuthenticationType.EXPERT_AUTHENTICATION_TYPE_NONE &&
    value < ExpertAuthenticationType.EXPERT_AUTHENTICATION_TYPE_COUNT
  )
}

export enum ExpertColumn {
  EXPERT_COLUMN_NONE = 0,

  EXPERT_COLUMN_TYPE = 1,
  EXPERT_COLUMN_HIGHLIGHT = 2,
  //EXPERT_COLUMN_CHILD_COUNT = 3,
  EXPERT_COLUMN_TREE_STATE = 4,
  //EXPERT_COLUMN_PARENT_TYPE = 5,
  //EXPERT_COLUMN_PARENT_ID = 6,
  EXPERT_COLUMN_EVENT_SEVERITY_MAX = 7,
  EXPERT_COLUMN_EVENT_TIME = 8,
  EXPERT_COLUMN_ICON_STATE = 9, // calculated on the client side, ignored by server

  EXPERT_COLUMN_CLIENT_ADDRESS = 10,
  EXPERT_COLUMN_SERVER_ADDRESS = 11,
  EXPERT_COLUMN_CLIENT_PORT = 12,
  EXPERT_COLUMN_SERVER_PORT = 13,
  EXPERT_COLUMN_NODE_1 = 14, // "client", usually uint32 IPv4, but type varies GREATLY with row type
  EXPERT_COLUMN_NODE_2 = 15, // "server", usually uint32 IPv4, but type varies with row type

  EXPERT_COLUMN_PROTOCOL = 20,
  EXPERT_COLUMN_TCP_STATUS = 21,

  EXPERT_COLUMN_STREAM_COUNT = 30,
  EXPERT_COLUMN_PROBLEM_COUNT = 31,
  EXPERT_COLUMN_HOP_COUNT = 32,
  EXPERT_COLUMN_PACKET_COUNT = 33,
  EXPERT_COLUMN_BYTE_COUNT = 34,
  EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT = 35,
  EXPERT_COLUMN_CLIENT_SENT_BYTE_COUNT = 36,
  EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT = 37,
  EXPERT_COLUMN_SERVER_SENT_BYTE_COUNT = 38,

  EXPERT_COLUMN_DELAY = 40,
  EXPERT_COLUMN_DELAY_BEST = 41,
  EXPERT_COLUMN_DELAY_WORST = 42,
  EXPERT_COLUMN_DELAY_SAMPLE_COUNT = 43,

  EXPERT_COLUMN_NODEPAIR = 50,
  EXPERT_COLUMN_STREAM_ID = 51,
  EXPERT_COLUMN_PROBLEM_ID = 52,

  EXPERT_COLUMN_START_TIME = 60,
  EXPERT_COLUMN_END_TIME = 61,
  EXPERT_COLUMN_DURATION = 62,

  EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND = 70,
  EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND = 71,
  EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_BEST = 72,
  EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_BEST = 73,
  EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_WORST = 74,
  EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_WORST = 75,
  EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_SAMPLE_COUNT = 76,
  EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_SAMPLE_COUNT = 77,

  // event log columns, not used for flow view
  EXPERT_COLUMN_EVENT_SERIAL_NUMBER = 80,
  EXPERT_COLUMN_PROTOCOL_LAYER = 81,
  EXPERT_COLUMN_MESSAGE = 82,
  EXPERT_COLUMN_SOURCE_ADDRESS = 83,
  EXPERT_COLUMN_DEST_ADDRESS = 84,
  EXPERT_COLUMN_SOURCE_PORT = 85,
  EXPERT_COLUMN_DEST_PORT = 86,
  EXPERT_COLUMN_PACKET_NUMBER = 87,
  EXPERT_COLUMN_OTHER_PACKET_NUMBER = 88,
  EXPERT_COLUMN_IS_FROM_CLIENT = 89,

  // flow view support columns for node details tab
  EXPERT_COLUMN_CLIENT_START_TIME = 90,
  EXPERT_COLUMN_SERVER_START_TIME = 91,
  EXPERT_COLUMN_CLIENT_END_TIME = 92,
  EXPERT_COLUMN_SERVER_END_TIME = 93,
  EXPERT_COLUMN_CLIENT_HOP_COUNT = 94,
  EXPERT_COLUMN_SERVER_HOP_COUNT = 95,
  EXPERT_COLUMN_CLIENT_TCP_WINDOW_MIN = 96,
  EXPERT_COLUMN_SERVER_TCP_WINDOW_MIN = 97,
  EXPERT_COLUMN_CLIENT_TCP_WINDOW_MAX = 98,
  EXPERT_COLUMN_SERVER_TCP_WINDOW_MAX = 99,
  EXPERT_COLUMN_PROBLEM_SUMMARY_LIST = 100,
  //  end pre-5.8.0 columns

  // begin post-5.8.0 columns
  // EXPERT_VIEW_SYSTABLES
  EXPERT_COLUMN_TABLE_ID = 120,
  EXPERT_COLUMN_TABLE_NAME = 121,
  EXPERT_COLUMN_TABLE_ATTRIBUTES = 122,

  // EXPERT_VIEW_SYSCOLUMNS
  EXPERT_COLUMN_COLUMN_ID = 130,
  EXPERT_COLUMN_COLUMN_NAME = 131,
  EXPERT_COLUMN_COLUMN_ATTRIBUTES = 132,

  // EXPERT_VIEW_EVENT_DESCRIPTIONS
  EXPERT_COLUMN_EVENT_GUID = 140,
  EXPERT_COLUMN_EVENT_NAME = 141,
  //EXPERT_COLUMN_EVENT_DESCRIPTION = 142,
  //EXPERT_COLUMN_EVENT_CAUSE = 143,
  //EXPERT_COLUMN_EVENT_REMEDY = 144,
  EXPERT_COLUMN_EVENT_SENSITIVITY = 145,
  EXPERT_COLUMN_EVENT_MESSAGE = 146,
  EXPERT_COLUMN_EVENT_VALUE_SHOWN = 147,
  EXPERT_COLUMN_EVENT_VALUE_UNIT = 148,
  EXPERT_COLUMN_EVENT_VALUE_MIN = 149,
  EXPERT_COLUMN_EVENT_VALUE_MAX = 150,
  EXPERT_COLUMN_EVENT_ASSIST_SHOWN = 151,
  EXPERT_COLUMN_EVENT_ASSIST_LEFT = 152,
  EXPERT_COLUMN_EVENT_ASSIST_RIGHT = 153,
  EXPERT_COLUMN_EVENT_ASSIST_LOGSCALE = 154,
  EXPERT_COLUMN_EVENT_MINPERIOD_SHOWN = 155,
  EXPERT_COLUMN_EVENT_MINPERIOD_UNITS = 156,
  EXPERT_COLUMN_EVENT_MINPERIOD_MIN = 157,
  EXPERT_COLUMN_EVENT_MINPERIOD_MAX = 158,
  EXPERT_COLUMN_EVENT_GROUP = 159,
  EXPERT_COLUMN_EVENT_CONFIGURE_SHOWN = 160,
  EXPERT_COLUMN_EVENT_FORMAT = 161,
  EXPERT_COLUMN_EVENT_MULTIPLIER = 162,
  EXPERT_COLUMN_EVENT_SUB_GROUP = 163,

  // EXPERT_VIEW_EVENT_SETTINGS_GROUPS
  EXPERT_COLUMN_SETTINGS_GROUP_ID = 166,
  EXPERT_COLUMN_SETTINGS_GROUP_NAME = 167,
  EXPERT_COLUMN_SETTINGS_GROUP_MAX_STREAM_COUNT = 168,
  // ### settings criteria some day

  // EXPERT_VIEW_EVENT_SETTINGS
  EXPERT_COLUMN_SETTINGS_ENABLED = 170,
  EXPERT_COLUMN_SETTINGS_SEVERITY = 171,
  EXPERT_COLUMN_SETTINGS_VALUE = 172,
  EXPERT_COLUMN_SETTINGS_SENSITIVITY = 173,
  EXPERT_COLUMN_SETTINGS_MINPERIOD = 174,

  // more stream columns
  EXPERT_COLUMN_FLOW_TYPE = 178,
  EXPERT_COLUMN_IP_PROTOCOL = 179,
  EXPERT_COLUMN_WIRELESS_RETRY_PERCENT = 180,
  EXPERT_COLUMN_PROTOSPEC = 181,

  // EXPERT_VIEW_HEADER_COUNTERS
  EXPERT_COLUMN_NAME = 182, // type varies GREATLY by row type, often == EXPERT_COLUMN_NODE_1
  EXPERT_COLUMN_VALUE = 183,

  // EXPERT_VIEW_POLICY_SETTINGS
  EXPERT_COLUMN_ACCEPT_MATCHING = 184,
  EXPERT_COLUMN_AUX1 = 185, // for channel, selected channel family

  // EXPERT_VIEW_APPLICATION_HIERARCHY
  EXPERT_COLUMN_APPLICATION = 186,
  EXPERT_COLUMN_APDEX = 187, // uint64, lower 32 bits=score * 100. upper 32 bits = sample count
  EXPERT_COLUMN_APDEX_SAMPLE_COUNT = 188, // uint64, lower 32 bits=score * 100. upper 32 bits = sample count

  // EXPERT_VIEW_APPLICATION_DESCRIPTIONS
  EXPERT_COLUMN_INDEX = 189,

  EXPERT_COLUMN_JITTER = 191,
  EXPERT_COLUMN_PACKET_LOSS_PERCENT = 192,
  EXPERT_COLUMN_SSRC = 193,
  EXPERT_COLUMN_CODEC = 194,
  EXPERT_COLUMN_MOS_LQ = 195,
  EXPERT_COLUMN_MOS_CQ = 196,
  EXPERT_COLUMN_MOS_PQ = 197,
  EXPERT_COLUMN_MOS_NOM = 198,
  EXPERT_COLUMN_R_FACTOR_LISTENING = 199,
  EXPERT_COLUMN_R_FACTOR_CONVERSATIONAL = 200,
  EXPERT_COLUMN_R_FACTOR_G107 = 201,
  EXPERT_COLUMN_R_FACTOR_NOMINAL = 202,
  EXPERT_COLUMN_ONE_WAY_DELAY = 203,

  // EXPERT_VIEW_EVENT_LAYER_MAPPING
  EXPERT_COLUMN_EVENT_LAYER_ID = 220,
  EXPERT_COLUMN_EVENT_LAYER_VALUE = 221,

  // EXPERT_VIEW_HTTP_REQUEST
  EXPERT_COLUMN_REQUEST_ID = 300,
  EXPERT_COLUMN_PAGE_REQUEST_ID = 301,
  EXPERT_COLUMN_REQUEST_HEADER = 302,
  EXPERT_COLUMN_RESPONSE_HEADER = 303,
  EXPERT_COLUMN_URI = 304,
  EXPERT_COLUMN_RESPONSE_CODE = 305,
  EXPERT_COLUMN_RESPONSE_TEXT = 306,
  EXPERT_COLUMN_REQUEST_PAYLOAD_BYTE_COUNT = 307,
  EXPERT_COLUMN_RESPONSE_PAYLOAD_BYTE_COUNT = 308,
  EXPERT_COLUMN_CONTENT_TYPE = 309,
  EXPERT_COLUMN_REFERER = 310,
  EXPERT_COLUMN_HOST = 311,
  EXPERT_COLUMN_REQUEST_ID_LIST = 312,

  // EXPERT_VIEW_HTTP_REQUEST_PACKET
  EXPERT_COLUMN_TIME = 320,
  EXPERT_COLUMN_SEQUENCE_START = 321,
  EXPERT_COLUMN_TCP_FLAGS = 322,

  // EXPERT_VIEW_VOIP_CALL
  EXPERT_COLUMN_CALL_ID = 351,
  EXPERT_COLUMN_FLOW_INDEX = 352,
  EXPERT_COLUMN_STATUS = 353,
  EXPERT_COLUMN_SIGNALING_TYPE = 354,
  EXPERT_COLUMN_MEDIA_EXTRACTABLE = 355, // New for 6.3, uint64 1 if media can be extracted to WAV file.
  EXPERT_COLUMN_CALLER_ADDRESS = 356,
  EXPERT_COLUMN_CALLER_PORT = 357,
  EXPERT_COLUMN_CALLEE_ADDRESS = 358,
  EXPERT_COLUMN_CALLEE_PORT = 359,
  EXPERT_COLUMN_GATEKEEPER_ADDRESS = 360,
  EXPERT_COLUMN_GATEKEEPER_PORT = 361,
  EXPERT_COLUMN_FROM = 362,
  EXPERT_COLUMN_FROM_ABBREV = 363,
  EXPERT_COLUMN_TO = 364,
  EXPERT_COLUMN_TO_ABBREV = 365,
  EXPERT_COLUMN_GATEWAY_ASSIGNED_CALL_ID = 366,
  EXPERT_COLUMN_CONTEXT = 367,
  EXPERT_COLUMN_MEDIA_FLOW_COUNT = 368,
  EXPERT_COLUMN_MEDIA_PACKET_COUNT = 369,
  EXPERT_COLUMN_MEDIA_FRAME_COUNT = 370,
  EXPERT_COLUMN_CONTROL_FLOW_COUNT = 371,
  EXPERT_COLUMN_CONTROL_PACKET_COUNT = 372,
  EXPERT_COLUMN_SIGNALING_FLOW_COUNT = 373,
  EXPERT_COLUMN_SIGNALING_PACKET_COUNT = 374,
  EXPERT_COLUMN_BIT_RATE = 375, // usually a round multiple of 1000, like 8KHz = 8000 bits per second
  EXPERT_COLUMN_MEDIA_TYPE = 376,
  EXPERT_COLUMN_END_CAUSE = 377,
  EXPERT_COLUMN_DEGRADATION_CAUSE = 378,
  EXPERT_COLUMN_SETUP_TIME = 379,
  EXPERT_COLUMN_PDD = 380,

  // EXPERT_VIEW_VOIP_MEDIA (same columns as EXPERT_VIEW_VOIP_CALL)

  // EXPERT_VIEW_VOIP_SIGNALING
  // Signaling rows only:
  EXPERT_COLUMN_SEQUENCE = 381, // VoIP: 101 of "101 INVITE", TCP: TCP sequence number
  EXPERT_COLUMN_SEQUENCE_METHOD = 382, // "INVITE" of "101 INVITE"
  EXPERT_COLUMN_FLAGS = 383, // view-defined bit flags
  // RTP block rows only:
  EXPERT_COLUMN_RTP_PACKET_NUMBER_LIST = 384,
  EXPERT_COLUMN_RTP_TIME_LIST = 385,
  EXPERT_COLUMN_RTP_MEDIA_TYPE_LIST = 386,
  EXPERT_COLUMN_RTP_BYTE_COUNT_LIST = 387,
  EXPERT_COLUMN_RTP_FLAG_LIST = 388,
  EXPERT_COLUMN_RTP_JITTER_LIST = 389, // Jitter (milliseconds) USHORT array, not WPTime ULONGLONG!
  EXPERT_COLUMN_RTP_Q1_LIST = 390,
  EXPERT_COLUMN_RTP_Q2_LIST = 391,
  EXPERT_COLUMN_RTP_Q3_LIST = 392,
  EXPERT_COLUMN_RTP_Q4_LIST = 393,
  EXPERT_COLUMN_RTP_Q5_LIST = 394,
  EXPERT_COLUMN_RTP_Q6_LIST = 395,
  EXPERT_COLUMN_RTP_Q7_LIST = 396,
  EXPERT_COLUMN_RTCP_PACKET_NUMBER_LIST = 397,
  EXPERT_COLUMN_RTCP_TIME_LIST = 398,
  EXPERT_COLUMN_RTCP_FLAG_LIST = 399,
  EXPERT_COLUMN_RELATIVE_TIME = 400,
  EXPERT_COLUMN_VSAQ = 401, // [0-50] for audio portion of video call
  EXPERT_COLUMN_VSMQ = 402, // [0-50] for multimedia (audio+video) of video call
  EXPERT_COLUMN_VSPQ = 403, // [0-50] for video
  EXPERT_COLUMN_VSTQ = 404, // [0-50] for transport layer of call
  EXPERT_COLUMN_MOS_A = 405, // [0.00-5.00 * 100] for audio portion of video call
  EXPERT_COLUMN_MOS_AV = 406, // [0.00-5.00 * 100] for multimedia (audio+video) of video call
  EXPERT_COLUMN_MOS_V = 407, // [0.00-5.00 * 100] for video
  EXPERT_COLUMN_CALL_STATUS = 408, // Call status: open, closed, etc.
  EXPERT_COLUMN_MOS_LOW = 409, // MOS score for all flows in a call (mix of MOS-CQ, MOS-AV, and MOS-A)
  EXPERT_COLUMN_SIGNALING_MSG_INDEX = 410, // Index of a signaling message within the flow
  // Does not necessarily align with the packet number
  EXPERT_COLUMN_MEDIA_FLOW_STATUS = 411, // Media flow status: open vs. closed

  EXPERT_COLUMN_APPLICATION_CONFIDENCE = 412, // Procera app confidence score

  EXPERT_COLUMN_CLIENT_COUNTRY_CODE = 413,
  EXPERT_COLUMN_CLIENT_COUNTRY = 414,
  EXPERT_COLUMN_CLIENT_CITY = 415,
  EXPERT_COLUMN_CLIENT_LATITUDE = 416,
  EXPERT_COLUMN_CLIENT_LONGITUDE = 417,
  EXPERT_COLUMN_SERVER_COUNTRY_CODE = 418,
  EXPERT_COLUMN_SERVER_COUNTRY = 419,
  EXPERT_COLUMN_SERVER_CITY = 420,
  EXPERT_COLUMN_SERVER_LATITUDE = 421,
  EXPERT_COLUMN_SERVER_LONGITUDE = 422,

  EXPERT_COLUMN_THREE_WAY_HANDSHAKE_TIME = 423,

  EXPERT_COLUMN_LAST_UPDATE_TIME = 424,

  EXPERT_COLUMN_NET_LATENCY = 425,
  EXPERT_COLUMN_NET_LATENCY_BEST = 426,
  EXPERT_COLUMN_NET_LATENCY_WORST = 427,
  EXPERT_COLUMN_NET_LATENCY_SAMPLE_COUNT = 428,
  EXPERT_COLUMN_APP_LATENCY = 429,
  EXPERT_COLUMN_APP_LATENCY_BEST = 430,
  EXPERT_COLUMN_APP_LATENCY_WORST = 431,
  EXPERT_COLUMN_APP_LATENCY_SAMPLE_COUNT = 432,

  EXPERT_COLUMN_DSCP = 433,
  EXPERT_COLUMN_INTERVAL_PACKET_COUNT = 434,
  EXPERT_COLUMN_INTERVAL_BYTE_COUNT = 435,

  EXPERT_COLUMN_ASSERTED_IDENTITY = 436,

  EXPERT_COLUMN_VOICE_LOSS_DEGRADATION = 437,
  EXPERT_COLUMN_VOICE_DISCARD_DEGRADATION = 438,
  EXPERT_COLUMN_VOICE_CODEC_DEGRADATION = 439,
  EXPERT_COLUMN_VOICE_DELAY_DEGRADATION = 440,
  EXPERT_COLUMN_VOICE_SIGNAL_LEVEL_DEGRADATION = 441,
  EXPERT_COLUMN_VOICE_NOISE_LEVEL_DEGRADATION = 442,
  EXPERT_COLUMN_VOICE_ECHO_LEVEL_DEGRADATION = 443,
  EXPERT_COLUMN_VOICE_RECENCY_DEGRADATION = 444,
  EXPERT_COLUMN_VIDEO_LOSS_DEGRADATION = 445,
  EXPERT_COLUMN_VIDEO_DISCARD_DEGRADATION = 446,
  EXPERT_COLUMN_VIDEO_CODEC_QUANTIZATION_DEGRADATION = 447,
  EXPERT_COLUMN_VIDEO_CODEC_BANDWIDTH_RESTRICTIONS_DEGRADATION = 448,
  EXPERT_COLUMN_VIDEO_FRAME_RESOLUTION_DEGRADATION = 449,
  EXPERT_COLUMN_VIDEO_FRAME_RATE_DEGRADATION = 450,
  EXPERT_COLUMN_VIDEO_GOP_LENGTH_DEGRADATION = 451,
  EXPERT_COLUMN_VIDEO_AVAILABLE_NETWORK_BANDWIDTH_DEGRADATION = 452,
  EXPERT_COLUMN_VIDEO_DELAY_DEGRADATION = 453,
  EXPERT_COLUMN_VIDEO_AV_SYNC_DEGRADATION = 454,
  EXPERT_COLUMN_VIDEO_RECENCY_DEGRADATION = 455,

  EXPERT_COLUMN_RTP_JITTER_MICRO_LIST = 456, // Jitter (microseconds) ULONGLONG array

  EXPERT_COLUMN_TLS_VERSION = 457,
  EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_BEFORE = 458,
  EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_AFTER = 459,
  EXPERT_COLUMN_TLS_HANDSHAKE_LENGTH = 460,

  EXPERT_COLUMN_SERVER_NETWORK_DELAY = 461,
  EXPERT_COLUMN_CLIENT_NETWORK_DELAY = 462,

  EXPERT_COLUMN_MPLS = 463,
  EXPERT_COLUMN_VLAN = 464,
  EXPERT_COLUMN_VXLAN_GPID = 465,
  EXPERT_COLUMN_VXLAN_VNI = 466,

  EXPERT_COLUMN_MAX = 467, // one more than the highest legal column ID, used for some reserve() operations
}

export enum ExpertCounter {
  COUNTER_ID_NONE,

  // stream view header counters
  COUNTER_ID_STREAM_COUNT_CURRENT,
  COUNTER_ID_STREAM_COUNT_RECYCLED,
  COUNTER_ID_NODEPAIR_COUNT_CURRENT,
  COUNTER_ID_NODEPAIR_COUNT_RECYCLED,
  COUNTER_ID_EVENT_COUNT_CURRENT,
  COUNTER_ID_EVENT_COUNT_RECYCLED,
  COUNTER_ID_PACKET_COUNT_DROPPED,

  // event log header counter
  COUNTER_ID_EVENT_COUNT_INFORMATIONAL,
  COUNTER_ID_EVENT_COUNT_MINOR,
  COUNTER_ID_EVENT_COUNT_MAJOR,
  COUNTER_ID_EVENT_COUNT_SEVERE,

  // miscellany
  COUNTER_ID_MAX_STREAM_COUNT,
  COUNTER_ID_MAX_STREAM_COUNT_MAX,
  COUNTER_ID_APDEX_THRESHOLD, // WPTime TODO

  // http
  COUNTER_ID_HTTP_SERVER_COUNT,
  COUNTER_ID_HTTP_CLIENT_COUNT,
  COUNTER_ID_HTTP_PAGE_COUNT,
  COUNTER_ID_HTTP_REQUEST_COUNT,
  COUNTER_ID_HTTP_FIRST_START,
  COUNTER_ID_HTTP_LAST_FINISH,

  // voip
  COUNTER_ID_VOIP_CALL_COUNT,
  COUNTER_ID_VOIP_MEDIA_FLOW_COUNT,
  COUNTER_ID_VOIP_TOTAL_CALL_COUNT,
  COUNTER_ID_VOIP_MAX_CALLS_COUNT,
  COUNTER_ID_VOIP_MAX_CALLS_TIME,
  COUNTER_ID_VOIP_MAX_CALLS_NOTIFY,
  COUNTER_ID_VOIP_MAX_CALLS_NOTIFY_SEVERITY,
  COUNTER_ID_VOIP_STOPPED_ANALYSIS,
  COUNTER_ID_VOIP_OVER_MAX_CALLS,
}

export enum ExpertEncryptionType {
  EXPERT_ENCRYPTION_TYPE_NONE = 0,
  EXPERT_ENCRYPTION_TYPE_WEP = 1,
  EXPERT_ENCRYPTION_TYPE_CKIP = 2,
  EXPERT_ENCRYPTION_TYPE_TKIP = 3,
  EXPERT_ENCRYPTION_TYPE_CCMP = 4,
  EXPERT_ENCRYPTION_TYPE_COUNT = 5,
}
export function isValidExpertEncryptionType(value: number): boolean {
  return (
    value >= ExpertEncryptionType.EXPERT_ENCRYPTION_TYPE_NONE &&
    value < ExpertEncryptionType.EXPERT_ENCRYPTION_TYPE_COUNT
  )
}

export enum ExpertLayer {
  EXPERT_LAYER_UNKNOWN = -1,
  EXPERT_LAYER_PHYSICAL,
  EXPERT_LAYER_DATA_LINK,
  EXPERT_LAYER_NETWORK,
  EXPERT_LAYER_TRANSPORT,
  EXPERT_LAYER_SESSION,
  EXPERT_LAYER_APPLICATION,
  EXPERT_LAYER_CLIENT_SERVER,
}
export function isValidExpertLayer(value: number): boolean {
  return (
    value >= ExpertLayer.EXPERT_LAYER_UNKNOWN && value <= ExpertLayer.EXPERT_LAYER_CLIENT_SERVER
  )
}

export enum ExpertOperation {
  EXPERT_OPERATION_UPDATE = 0,
  EXPERT_OPERATION_INSERT = 1,
  EXPERT_OPERATION_DELETE = 2,
}

export enum ExpertPolicyEncryptionType {
  EXPERT_POLICY_ENCRYPTION_TYPE_NONE = 0,
  EXPERT_POLICY_ENCRYPTION_TYPE_WEP = 1,
  EXPERT_POLICY_ENCRYPTION_TYPE_CKIP = 2,
  EXPERT_POLICY_ENCRYPTION_TYPE_TKIP = 3,
  EXPERT_POLICY_ENCRYPTION_TYPE_CCMP = 4,
  EXPERT_POLICY_ENCRYPTION_TYPE_COUNT = 5,
}

export enum ExpertProblem {
  EXPERT_PROBLEM_ID_NONE = 0, // not really a problem

  // client/server
  EXPERT_PROBLEM_ID_BUSY_NETWORK = 1,
  EXPERT_PROBLEM_ID_INEFFICIENT_CLIENT = 2,
  EXPERT_PROBLEM_ID_LOW_SC_THROUGHPUT = 3,
  EXPERT_PROBLEM_ID_LOW_CS_THROUGHPUT = 4,
  EXPERT_PROBLEM_ID_CLIENT_NOT_RESPONDING = 5,
  EXPERT_PROBLEM_ID_SERVER_NOT_RESPONDING = 6,
  EXPERT_PROBLEM_ID_ONE_WAY_TRAFFIC = 7,
  EXPERT_PROBLEM_ID_SLOW_SERVER_RESPONSE_TIME = 8,

  // Apdex - Deprecated
  EXPERT_PROBLEM_ID_APDEX_CLIENT_LOW_SCORE = 9, // 6.0.0
  EXPERT_PROBLEM_ID_APDEX_TASK_ENDED = 10, // 6.0.0
  EXPERT_PROBLEM_ID_APDEX_TASK_ENDED_TOLERATING = 11, // 6.0.0
  EXPERT_PROBLEM_ID_APDEX_TASK_ENDED_FRUSTRATED = 12, // 6.0.0

  // application
  EXPERT_PROBLEM_ID_DHCP_LOW_LEASE_TIME = 13,
  EXPERT_PROBLEM_ID_DHCP_MULTIPLE_SERVER_RESPONSE = 14, // 6.0.0
  EXPERT_PROBLEM_ID_DHCP_REJECTED = 15,
  EXPERT_PROBLEM_ID_DHCP_REQUEST_STORM = 16,
  // 6.0.0
  EXPERT_PROBLEM_ID_DNS_SLOW_RESPONSE_TIME = 17,
  EXPERT_PROBLEM_ID_DNS_SERVER_ERROR = 18,
  EXPERT_PROBLEM_ID_DNS_NON_EXISTENT_DOMAIN = 19,
  EXPERT_PROBLEM_ID_DNS_IDLE_TOO_LONG = 220, // 24.2.0

  EXPERT_PROBLEM_ID_FTP_CONTROL_SLOW = 20,

  EXPERT_PROBLEM_ID_HTTP_REQUEST_NOT_FOUND = 21,
  EXPERT_PROBLEM_ID_HTTP_CLIENT_ERROR = 22,
  EXPERT_PROBLEM_ID_HTTP_SERVER_ERROR = 23,
  EXPERT_PROBLEM_ID_HTTP_SLOW_RESPONSE_TIME = 24,

  EXPERT_PROBLEM_ID_KERBEROS_REJECTED = 25,

  EXPERT_PROBLEM_ID_LDAP_SLOW_RESPONSE_TIME = 26,

  EXPERT_PROBLEM_ID_NFS_RETRANSMISSION = 27,

  EXPERT_PROBLEM_ID_TNS_LOGIN_REJECT = 28,
  EXPERT_PROBLEM_ID_TNS_SLOW_RESPONSE_TIME = 29,
  EXPERT_PROBLEM_ID_TNS_REFUSED = 30,

  EXPERT_PROBLEM_ID_POP3_LOGIN_REJECTED = 31,
  EXPERT_PROBLEM_ID_POP3_REJECTED = 32,
  EXPERT_PROBLEM_ID_POP3_SLOW_RESPONSE_TIME = 33,

  EXPERT_PROBLEM_ID_SMB_LOGON_FAILURE = 34,
  EXPERT_PROBLEM_ID_SMB_ERROR = 35,
  EXPERT_PROBLEM_ID_SMB_BAD_NETWORK_NAME = 36,
  EXPERT_PROBLEM_ID_SMB_LOOP_ON_REQUEST = 37,
  EXPERT_PROBLEM_ID_SMB_EXCESSIVE_LOOPS = 38,

  EXPERT_PROBLEM_ID_SMTP_REJECTED = 39,
  EXPERT_PROBLEM_ID_SMTP_SLOW_RESPONSE_TIME = 40,

  EXPERT_PROBLEM_ID_SQL_LOGIN_REJECTED = 41,
  EXPERT_PROBLEM_ID_SQL_CLIENT_ERROR = 42,
  EXPERT_PROBLEM_ID_SQL_FATAL_ERROR = 43,
  EXPERT_PROBLEM_ID_SQL_RESOURCE_ERROR = 44,
  EXPERT_PROBLEM_ID_SQL_SLOW_RESPONSE_TIME = 45,

  EXPERT_PROBLEM_ID_SMB_BROWSER_ELECT = 46,

  // session
  EXPERT_PROBLEM_ID_NETBIOS_SESSION_REFUSED = 47,

  // voip
  EXPERT_PROBLEM_ID_H225_RAS_REJECT = 48,
  EXPERT_PROBLEM_ID_H225_Q931_DROP = 49, // 6.0.0
  EXPERT_PROBLEM_ID_H225_Q931_REJECT = 50,
  EXPERT_PROBLEM_ID_H245_REJECT = 51,
  EXPERT_PROBLEM_ID_LOW_PMOS = 52, // 6.0.0
  EXPERT_PROBLEM_ID_LOW_R_FACTOR = 53, // 6.0.0
  EXPERT_PROBLEM_ID_MGCP_400_ERROR = 54, // 6.0.0
  EXPERT_PROBLEM_ID_MGCP_500_ERROR = 55, // 6.0.0
  EXPERT_PROBLEM_ID_MGCP_900_ERROR = 56, // 6.0.0
  EXPERT_PROBLEM_ID_RTP_EXCESSIVE_JITTER_DETECTED = 57, // 6.0.0
  EXPERT_PROBLEM_ID_RTP_EXCESSIVE_JITTER = 58,
  EXPERT_PROBLEM_ID_RTP_EXCESSIVE_PACKET_LOSS_DETECTED = 59, // 6.0.0
  EXPERT_PROBLEM_ID_RTP_EXCESSIVE_PACKET_LOSS = 60,
  EXPERT_PROBLEM_ID_RTP_PACKET_NOT_MARKED_FOR_QOS = 61, // 6.0.0
  EXPERT_PROBLEM_ID_RTP_PACKET_LATE = 62,
  EXPERT_PROBLEM_ID_RTP_PACKET_OUT_OF_SEQUENCE = 63,
  EXPERT_PROBLEM_ID_SCCP_STATION_ALARM_ADVISORY = 64, // 6.2.0
  EXPERT_PROBLEM_ID_SCCP_STATION_ALARM_CRITICAL = 65, // 6.2.0
  EXPERT_PROBLEM_ID_SCCP_STATION_QOS_ERROR_NOTIFY = 66, // 6.2.0
  EXPERT_PROBLEM_ID_SCCP_STATION_REGISTER_REJECT = 67, // 6.2.0
  EXPERT_PROBLEM_ID_SIP_POST_DIAL_DELAY = 68, // 6.0.0
  EXPERT_PROBLEM_ID_SIP_REDIRECTION = 69, // 6.0.0
  EXPERT_PROBLEM_ID_SIP_CLIENT_AUTH_REQUIRED = 70, // 6.0.0
  EXPERT_PROBLEM_ID_SIP_CLIENT_ERROR = 71, // 6.0.0
  EXPERT_PROBLEM_ID_SIP_SERVER_ERROR = 72, // 6.0.0
  EXPERT_PROBLEM_ID_SIP_GLOBAL_ERROR = 73, // 6.0.0

  // transport
  EXPERT_PROBLEM_ID_RSVP_ERROR = 74, // 6.0.0
  EXPERT_PROBLEM_ID_TCP_RESET_CONN_REJECTED = 75,
  EXPERT_PROBLEM_ID_TCP_RESET_CONN_LOST = 76,
  EXPERT_PROBLEM_ID_TCP_RESET_CONN_INACTIVE = 77,
  EXPERT_PROBLEM_ID_TCP_RESET_CONN_GENERIC = 78,
  EXPERT_PROBLEM_ID_TCP_RETRANSMISSION_EXCESSIVE = 79,
  EXPERT_PROBLEM_ID_TCP_RETRANSMISSION_FAST_3ACK = 80,
  EXPERT_PROBLEM_ID_TCP_RETRANSMISSION_FAST = 81,
  EXPERT_PROBLEM_ID_TCP_RETRANSMISSION_SLOW = 82,
  EXPERT_PROBLEM_ID_TCP_RETRANSMISSION_GENERIC = 83,
  EXPERT_PROBLEM_ID_TCP_IDLE_TOO_LONG = 84,
  EXPERT_PROBLEM_ID_TCP_CHECKSUM = 85,
  EXPERT_PROBLEM_ID_TCP_LOW_MSS = 86,
  EXPERT_PROBLEM_ID_TCP_REPEATED_SYN = 87,
  EXPERT_PROBLEM_ID_TCP_SLOW_ACK = 88,
  EXPERT_PROBLEM_ID_TCP_SLOW_SEGMENT_RECOVERY = 89,
  EXPERT_PROBLEM_ID_TCP_TRIPLE_DUPLICATE_ACK = 90,
  EXPERT_PROBLEM_ID_TCP_WINDOW_LOW = 91,
  EXPERT_PROBLEM_ID_TCP_WINDOW_STUCK = 92,
  EXPERT_PROBLEM_ID_TCP_WINDOW_ZERO = 93,
  EXPERT_PROBLEM_ID_TCP_SEGMENT_OUT_OF_SEQUENCE = 94,
  EXPERT_PROBLEM_ID_TCP_SEGMENT_OUTSIDE_WINDOW = 95,
  EXPERT_PROBLEM_ID_TCP_SEGMENT_MISSING = 96,
  EXPERT_PROBLEM_ID_TCP_KEEP_ALIVE = 207,
  EXPERT_PROBLEM_ID_TCP_KEEP_ALIVE_ACK = 208,
  EXPERT_PROBLEM_ID_TCP_HEADER_INCOMPLETE = 210,
  EXPERT_PROBLEM_ID_TCP_DUPLICATE_ACK = 213, // 12.0.0
  EXPERT_PROBLEM_ID_TCP_SELECTIVE_ACK = 214, // 12.0.0
  EXPERT_PROBLEM_ID_TCP_SLOW_HANDSHAKE = 219, // 24.2.0

  EXPERT_PROBLEM_ID_UDP_CHECKSUM = 97,
  EXPERT_PROBLEM_ID_UDP_LENGTH_TOO_BIG = 211,

  EXPERT_PROBLEM_ID_TLS_VERSION = 215, // 24.2.0
  EXPERT_PROBLEM_ID_TLS_CERT_VALIDITY_NOT_BEFORE = 216, // 24.2.0
  EXPERT_PROBLEM_ID_TLS_CERT_VALIDITY_NOT_AFTER = 217, // 24.2.0
  EXPERT_PROBLEM_ID_TLS_SLOW_HANDSHAKE = 218, // 24.2.0

  // network
  EXPERT_PROBLEM_ID_IP_HEADER_CHECKSUM = 98,
  EXPERT_PROBLEM_ID_IP_LOCAL_ROUTING = 99,
  EXPERT_PROBLEM_ID_IP_NETWORK_DUPLICATE = 100, // 5.8.0
  EXPERT_PROBLEM_ID_IP_LOW_TTL = 101,
  EXPERT_PROBLEM_ID_IP_MISSING_FRAGMENT = 102,
  EXPERT_PROBLEM_ID_IP_CRC = 103,
  EXPERT_PROBLEM_ID_IP_ZERO_BROADCAST_ADDRESS = 104,
  EXPERT_PROBLEM_ID_IP_LENGTH_TOO_BIG = 209,

  EXPERT_PROBLEM_ID_ICMP_NETWORK_UNREACHABLE = 105,
  EXPERT_PROBLEM_ID_ICMP_HOST_UNREACHABLE = 106,
  EXPERT_PROBLEM_ID_ICMP_PROTOCOL_UNREACHABLE = 107,
  EXPERT_PROBLEM_ID_ICMP_PORT_UNREACHABLE = 108,
  EXPERT_PROBLEM_ID_ICMP_FRAGMENTATION_NEEDED = 109,
  EXPERT_PROBLEM_ID_ICMP_SOURCE_ROUTE_FAILED = 110,
  EXPERT_PROBLEM_ID_ICMP_HOST_UNKNOWN = 111,
  EXPERT_PROBLEM_ID_ICMP_NET_UNREACHABLE_TOS = 112,
  EXPERT_PROBLEM_ID_ICMP_HOST_UNREACHABLE_TOS = 113,
  EXPERT_PROBLEM_ID_ICMP_COMMUNICATION_ADMIN_PROHIBITED = 114,
  EXPERT_PROBLEM_ID_ICMP_HOST_PRECEDENCE_VIOLATION = 115,
  EXPERT_PROBLEM_ID_ICMP_PRECEDENCE_CUTOFF_IN_EFFECT = 116,
  EXPERT_PROBLEM_ID_ICMP_REDIRECT_HOST_DATAGRAM = 117,
  EXPERT_PROBLEM_ID_ICMP_REDIRECT_TOS_HOST_DATAGRAM = 118,
  EXPERT_PROBLEM_ID_ICMP_TTL_EXCEEDED = 119,
  EXPERT_PROBLEM_ID_ICMP_FRAGMENT_REASSEMBLY_TIME_EXCEEDED = 120,
  EXPERT_PROBLEM_ID_ICMP_PARAMETER_PROBLEM = 121,
  EXPERT_PROBLEM_ID_ICMP_OBSOLETE_MESSAGE = 122,

  // data link
  EXPERT_PROBLEM_ID_802_1X_ATTACK = 123, // 6.2.0
  EXPERT_PROBLEM_ID_ARP_STORM = 124, // 6.0.0
  EXPERT_PROBLEM_ID_BROADCAST_STORM = 125,
  EXPERT_PROBLEM_ID_MULTICAST_STORM = 126,
  EXPERT_PROBLEM_ID_SEVERE_BROADCAST_STORM = 127,
  EXPERT_PROBLEM_ID_SEVERE_MULTICAST_STORM = 128,
  EXPERT_PROBLEM_ID_BPDU_TOPOLOGY_CHANGE = 129,
  EXPERT_PROBLEM_ID_EAP_AUTH_FAILURE = 130,
  EXPERT_PROBLEM_ID_FRAME_RELAY_FECN = 131, // 6.0.0
  EXPERT_PROBLEM_ID_FRAME_RELAY_BECN = 132, // 6.0.0
  EXPERT_PROBLEM_ID_FRAME_RELAY_LMI_STATUS_CHANGE = 133, // 6.0.0
  EXPERT_PROBLEM_ID_PPP_REJECT = 134, // 6.0.0
  EXPERT_PROBLEM_ID_PPP_CONFIGURE_NAK = 135, // 6.0.0
  EXPERT_PROBLEM_ID_PPP_TERMINATE_REQUEST = 136, // 6.0.0
  EXPERT_PROBLEM_ID_ARP_GRATUITOUS = 212, // 12.0.0

  // LAN
  EXPERT_PROBLEM_ID_LAN_CRC = 137,
  EXPERT_PROBLEM_ID_LAN_MAC_FLOODING = 138, // 6.0.0

  // Wireless
  EXPERT_PROBLEM_ID_WIRELESS_CRC = 139,
  EXPERT_PROBLEM_ID_WIRELESS_AP_QBSS_CLIENT_TOO_MANY = 140, // 6.2.0
  EXPERT_PROBLEM_ID_WIRELESS_AP_EXPOSSED_SSID = 141,
  EXPERT_PROBLEM_ID_WIRELESS_AP_INCONSISTENT_CONFIGURATION = 142, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_AP_STOLEN = 143, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_AP_MIXED_MODE = 144,
  EXPERT_PROBLEM_ID_WIRELESS_AP_NOT_CONFIG = 145,
  EXPERT_PROBLEM_ID_WIRELESS_AP_N_CAPABLE = 146, // 6.2.0
  EXPERT_PROBLEM_ID_WIRELESS_AP_N_DUAL_CHANNEL = 147, // 6.2.0
  EXPERT_PROBLEM_ID_WIRELESS_AP_CRC = 148,
  EXPERT_PROBLEM_ID_WIRELESS_AP_FAKE = 149, // 6.0.0
  EXPERT_PROBLEM_ID_WIRELESS_AP_QOS_MISSING = 150, // 6.2.0
  EXPERT_PROBLEM_ID_WIRELESS_AP_REPEATER_MODE = 151, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_AP_RESTARTED = 152,
  EXPERT_PROBLEM_ID_WIRELESS_AP_ROGUE = 153,
  EXPERT_PROBLEM_ID_WIRELESS_AP_TOO_MANY = 154,
  EXPERT_PROBLEM_ID_WIRELESS_AP_TOO_MANY_CLIENTS = 155, // 6.0.0
  EXPERT_PROBLEM_ID_WIRELESS_AP_TOO_MANY_RETRIES = 156,
  EXPERT_PROBLEM_ID_WIRELESS_AP_WEAK_SIGNAL = 157,
  EXPERT_PROBLEM_ID_WIRELESS_AP_NO_WEP = 158,
  EXPERT_PROBLEM_ID_WIRELESS_ADHOC_DETECTED = 159,
  EXPERT_PROBLEM_ID_WIRELESS_ASSOCIATION_ATTACK = 160,
  EXPERT_PROBLEM_ID_WIRELESS_ASSOCIATION_DENIED = 161,
  EXPERT_PROBLEM_ID_WIRELESS_AUTH_ATTACK = 162,
  EXPERT_PROBLEM_ID_WIRELESS_AUTH_DENIED = 163,
  EXPERT_PROBLEM_ID_WIRELESS_CHANNEL_BLEED = 164,
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_ROGUE_AP_ASSOCIATION = 165, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_EXCESSIVE_ROAM_TIME = 166, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_HIGH_FRAGMENTATION_RATE = 167, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_PROBE_REQUEST_IGNORED = 168, // 5.8.0 -> 6.0.0 changed name from AP_PROBE_REQUEST_IGNORED
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_CRC = 169,
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_POWER_SAVE_INTERVAL_EXCEEDED = 170, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_POWER_SAVE_LISTEN_INTERVAL_TOO_LONG = 171, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_POWER_SAVE_MISSED_PACKET = 172, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_PROBE_RESPONSE_IGNORED = 173, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_DHCP_SERVER = 174, // 6.2.0
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_ROGUE = 175,
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_TOO_MANY_RETRIES = 176,
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_USING_AP_SSID = 177, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_USING_AP_MAC = 178,
  EXPERT_PROBLEM_ID_WIRELESS_CLIENT_WEAK_SIGNAL = 179,
  EXPERT_PROBLEM_ID_WIRELESS_DATA_RATE_CHANGE = 180,
  EXPERT_PROBLEM_ID_WIRELESS_DEAUTH = 181,
  EXPERT_PROBLEM_ID_WIRELESS_DEAUTH_ATTACK = 182,
  EXPERT_PROBLEM_ID_WIRELESS_DURATION_ATTACK = 183, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_EXCESSIVE_DATA_RATE_CHANGE = 184,
  EXPERT_PROBLEM_ID_WIRELESS_EXCESSIVE_PROBE_REQUESTS = 185,
  EXPERT_PROBLEM_ID_WIRELESS_EXCESSIVE_RTS = 186,
  EXPERT_PROBLEM_ID_WIRELESS_FRAG_TOO_SMALL = 187, // 6.2.0
  EXPERT_PROBLEM_ID_WIRELESS_G_SHORT_SLOT = 188,
  EXPERT_PROBLEM_ID_WIRELESS_HIGH_BEACON_RATE = 189,
  EXPERT_PROBLEM_ID_WIRELESS_LOW_SNR = 190, // 6.0.0
  EXPERT_PROBLEM_ID_WIRELESS_PSPF_VIOLATION = 191, // 6.2.0
  EXPERT_PROBLEM_ID_WIRELESS_REASSOCIATION_DENIED = 192,
  EXPERT_PROBLEM_ID_WIRELESS_RF_INTERFERENCE = 193, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_RF_JAMMING = 194, // 5.8.0
  EXPERT_PROBLEM_ID_WIRELESS_RTS_CTS_PKTS_SIZE_SMALL = 195, // 6.2.0
  EXPERT_PROBLEM_ID_WIRELESS_SAME_SND_RCV = 196,
  EXPERT_PROBLEM_ID_WIRELESS_SECURITY_ERROR = 197,
  EXPERT_PROBLEM_ID_WIRELESS_SRC_BROADCAST = 198,
  EXPERT_PROBLEM_ID_WIRELESS_SRC_MULTICAST = 199,
  EXPERT_PROBLEM_ID_WIRELESS_RETRY = 200,

  // Network Policy
  EXPERT_PROBLEM_ID_NETWORK_POLICY_VENDOR_ID = 201,
  EXPERT_PROBLEM_ID_NETWORK_POLICY_CHANNEL = 202,
  EXPERT_PROBLEM_ID_NETWORK_POLICY_ESSID = 203,
  EXPERT_PROBLEM_ID_NETWORK_POLICY_ENCRYPTION = 204,
  EXPERT_PROBLEM_ID_NETWORK_POLICY_AUTHENTICATION = 205,

  // ESP
  EXPERT_PROBLEM_ID_ESP_OUT_OF_SEQUENCE = 206,

  EXPERT_PROBLEM_ID_MAX = 221, // IMPORTANT increment this if you add an enumeration!
}
export function isValidExpertProblem(value: number): boolean {
  return (
    value >= ExpertProblem.EXPERT_PROBLEM_ID_NONE && value < ExpertProblem.EXPERT_PROBLEM_ID_MAX
  )
}

export enum ExpertCounterID {
  EXPERT_COUNTER_ID_NONE = 0,

  // stream view header counters
  EXPERT_COUNTER_ID_STREAM_COUNT_CURRENT = 1,
  EXPERT_COUNTER_ID_STREAM_COUNT_RECYCLED = 2,
  EXPERT_COUNTER_ID_NODEPAIR_COUNT_CURRENT = 3,
  EXPERT_COUNTER_ID_NODEPAIR_COUNT_RECYCLED = 4,
  EXPERT_COUNTER_ID_EVENT_COUNT_CURRENT = 5,
  EXPERT_COUNTER_ID_EVENT_COUNT_RECYCLED = 6,
  EXPERT_COUNTER_ID_PACKET_COUNT_DROPPED = 7,

  // event log header counter
  EXPERT_COUNTER_ID_EVENT_COUNT_INFORMATIONAL = 8,
  EXPERT_COUNTER_ID_EVENT_COUNT_MINOR = 9,
  EXPERT_COUNTER_ID_EVENT_COUNT_MAJOR = 10,
  EXPERT_COUNTER_ID_EVENT_COUNT_SEVERE = 11,

  // miscellany
  EXPERT_COUNTER_ID_MAX_STREAM_COUNT = 12,
  EXPERT_COUNTER_ID_MAX_STREAM_COUNT_MAX = 13,
  EXPERT_COUNTER_ID_APDEX_THRESHOLD = 14, // WPTime TODO

  // http
  EXPERT_COUNTER_ID_HTTP_SERVER_COUNT = 15,
  EXPERT_COUNTER_ID_HTTP_CLIENT_COUNT = 16,
  EXPERT_COUNTER_ID_HTTP_PAGE_COUNT = 17,
  EXPERT_COUNTER_ID_HTTP_REQUEST_COUNT = 18,
  EXPERT_COUNTER_ID_HTTP_FIRST_START = 19,
  EXPERT_COUNTER_ID_HTTP_LAST_FINISH = 20,

  // voip
  EXPERT_COUNTER_ID_VOIP_CALL_COUNT = 21,
  EXPERT_COUNTER_ID_VOIP_MEDIA_FLOW_COUNT = 22,
  EXPERT_COUNTER_ID_VOIP_TOTAL_CALL_COUNT = 23,
  EXPERT_COUNTER_ID_VOIP_MAX_CALLS_COUNT = 24,
  EXPERT_COUNTER_ID_VOIP_MAX_CALLS_TIME = 25,
  EXPERT_COUNTER_ID_VOIP_MAX_CALLS_NOTIFY = 26,
  EXPERT_COUNTER_ID_VOIP_MAX_CALLS_NOTIFY_SEVERITY = 27,
  EXPERT_COUNTER_ID_VOIP_STOPPED_ANALYSIS = 28,
  EXPERT_COUNTER_ID_VOIP_OVER_MAX_CALLS = 29,

  // VIEW_ID_PROBLEM_SUMMARY uses Expert::ProblemID as its counter ID, so
  // make sure you're big enough for that
  EXPERT_COUNTER_ID_BE_BIG_ENOUGH_FOR_PROBLEM_ID = ExpertProblem.EXPERT_PROBLEM_ID_MAX,
}

export enum ExpertRowType {
  EXPERT_ROW_TYPE_NONE = 0,
  EXPERT_ROW_TYPE_NODEPAIR = 1,
  EXPERT_ROW_TYPE_STREAM = 2,
  EXPERT_ROW_TYPE_PROBLEM = 3,
  EXPERT_ROW_TYPE_EVENT = 4,
  EXPERT_ROW_TYPE_APPLICATION = 5,
  EXPERT_ROW_TYPE_SERVER = 6,
  EXPERT_ROW_TYPE_CLIENT = 7,
  EXPERT_ROW_TYPE_HTTP_REQUEST = 8,
  EXPERT_ROW_TYPE_HTTP_PAGE = 9,
  EXPERT_ROW_TYPE_PACKET = 10,
  EXPERT_ROW_TYPE_CALL = 11,
  EXPERT_ROW_TYPE_MEDIA_FLOW = 12,
  EXPERT_ROW_TYPE_MEDIA_CONTROL_FLOW = 13,
  EXPERT_ROW_TYPE_SIGNALING_FLOW = 14,
  EXPERT_ROW_TYPE_MEDIA_PACKET_BLOCK = 15,
}

export enum ExpertSensitivity {
  EXPERT_SENSITIVITY_NONE = 0,
  EXPERT_SENSITIVITY_LOW = 1,
  EXPERT_SENSITIVITY_MEDIUM = 2,
  EXPERT_SENSITIVITY_HIGH = 3,
}
export function isValidExpertSensitivity(value: number): boolean {
  return (
    value >= ExpertSensitivity.EXPERT_SENSITIVITY_NONE &&
    value <= ExpertSensitivity.EXPERT_SENSITIVITY_HIGH
  )
}

export enum ExpertSettingGroupId {
  EXPERT_SETTING_GROUP_ID_CURRENT = 0,
  EXPERT_SETTING_GROUP_ID_DEFAULT = 1,
}
export function isValidExpertSettingGroupId(value: number): boolean {
  return (
    value >= ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT &&
    value <= ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT
  )
}

export enum ExpertSeverity {
  EXPERT_SEVERITY_NONE = 0,
  EXPERT_SEVERITY_INFORMATIONAL = 1,
  EXPERT_SEVERITY_MINOR = 2,
  EXPERT_SEVERITY_MAJOR = 3,
  EXPERT_SEVERITY_SEVERE = 4,
  EXPERT_SEVERITY_LEVELS = 5,
}
export function isValidExpertSeverity(value: number): boolean {
  return (
    value > ExpertSeverity.EXPERT_SEVERITY_NONE && value < ExpertSeverity.EXPERT_SEVERITY_LEVELS
  )
}

export enum ExpertFlowType {
  EXPERT_FLOW_TYPE_UNKNOWN = 0,
  EXPERT_FLOW_TYPE_TCP = 1,
  EXPERT_FLOW_TYPE_UDP = 2,
  EXPERT_FLOW_TYPE_ICMP_ECHO = 3,
  EXPERT_FLOW_TYPE_IP_FRAGMENT = 4,
  EXPERT_FLOW_TYPE_SCTP = 5,
}
export function isValidExpertFlowType(value: number): boolean {
  return (
    value > ExpertFlowType.EXPERT_FLOW_TYPE_UNKNOWN && value < ExpertFlowType.EXPERT_FLOW_TYPE_SCTP
  )
}

export enum ExpertStream {
  EXPERT_STREAM_NONE = -1,
}

export enum ExpertThroughputUnits {
  EXPERT_THROUGHPUT_UNITS_BITS_PER_SECOND = 0,
  EXPERT_THROUGHPUT_UNITS_KILOBITS_PER_SECOND = 1,
  EXPERT_THROUGHPUT_UNITS_MEGABITS_PER_SECOND = 2,
  EXPERT_THROUGHPUT_UNITS_GIGABITS_PER_SECOND = 3,
  EXPERT_THROUGHPUT_UNITS_BYTES_PER_SECOND = 4,
  EXPERT_THROUGHPUT_UNITS_KILOBYTES_PER_SECOND = 5,
  EXPERT_THROUGHPUT_UNITS_MEGABYTES_PER_SECOND = 6,
  EXPERT_THROUGHPUT_UNITS_GIGABYTES_PER_SECOND = 7,
}

export enum ExpertTimeUnit {
  EXPERT_TIME_UNIT_NONE = 0,
  EXPERT_TIME_UNIT_NANOSECONDS = 1,
  EXPERT_TIME_UNIT_MICROSECONDS = 2,
  EXPERT_TIME_UNIT_MILLISECONDS = 3,
  EXPERT_TIME_UNIT_SECONDS = 4,
  EXPERT_TIME_UNIT_MINUTES = 5,
  EXPERT_TIME_UNIT_HOURS = 6,
  EXPERT_TIME_UNIT_DAYS = 7,
  EXPERT_TIME_UNIT_MAX = 8,
}
export function isValidExpertTimeUnit(value: number): boolean {
  return (
    value >= ExpertTimeUnit.EXPERT_TIME_UNIT_NONE && value < ExpertTimeUnit.EXPERT_TIME_UNIT_MAX
  )
}

export enum ExpertTreeState {
  EXPERT_TREE_STATE_NONE = 0x00,
  EXPERT_TREE_STATE_EXPANDABLE = 0x01,
  EXPERT_TREE_STATE_EXPANDED = 0x02,
  EXPERT_TREE_STATE_FIRST_CHILD = 0x04,
  EXPERT_TREE_STATE_LAST_CHILD = 0x08,
  EXPERT_TREE_STATE_BIT_COUNT = 4,
  EXPERT_TREE_STATE_MASK = 0x0f,
}

export enum ExpertView {
  EXPERT_VIEW_NONE = 0,
  EXPERT_VIEW_CLIENT_SERVER = 1, // client-server pairs, stream children, problem children
  EXPERT_VIEW_NODE_PAIRX = 2, // node pairs, stream children, problem children
  EXPERT_VIEW_STREAM = 3, // streams, flat, no children
  EXPERT_VIEW_STREAM_HIERARCHY = 4, // streams, problem children
  EXPERT_VIEW_PROBLEM = 5, // problems, flat, no children
  EXPERT_VIEW_EVENT_LOG = 6,
  //EXPERT_VIEW_STREAM_COUNTER = 7,	// @obsolete, use EXPERT_VIEW_HEADER_COUNTERS
  //EXPERT_VIEW_EVENT_LOG_COUNTER = 8,	// @obsolete, use EXPERT_VIEW_HEADER_COUNTERS // this was the last pre-5.8.0 view.

  // begin post-5.8.0 views
  EXPERT_VIEW_EVENT_DESCRIPTIONS = 9,
  EXPERT_VIEW_EVENT_SETTINGS_GROUPS = 10,
  EXPERT_VIEW_EVENT_SETTINGS = 11,
  EXPERT_VIEW_EVENT_COUNTS = 12,
  EXPERT_VIEW_POLICY_SETTINGS = 13,
  EXPERT_VIEW_POLICY_ITEMS = 14,
  EXPERT_VIEW_APPLICATION_HIERARCHY = 15,
  EXPERT_VIEW_APPLICATION_SERVER_HIERARCHY = 16,
  EXPERT_VIEW_APPLICATION_CLIENT_HIERARCHY = 17,
  EXPERT_VIEW_APPLICATION_DESCRIPTIONS = 18,
  EXPERT_VIEW_EVENT_LAYER_MAPPING = 20, // Layer mappings loaded from ExpertDescriptions.xml file.
  EXPERT_VIEW_SYSTABLES = 100,
  EXPERT_VIEW_SYSCOLUMNS = 101,
  EXPERT_VIEW_HEADER_COUNTERS = 102,

  EXPERT_VIEW_HTTP_REQUEST = 103,
  EXPERT_VIEW_HTTP_SERVER_HIERARCHY = 104, //  Server/ Client/Page/Request
  EXPERT_VIEW_HTTP_SERVER_HIERARCHY_CS = 105, // [Client/]Server/Page/Request
  EXPERT_VIEW_HTTP_CLIENT_HIERARCHY = 106, //  Client/ Server/Page/Request
  EXPERT_VIEW_HTTP_CLIENT_HIERARCHY_SC = 107, // [Server/]Client/Page/Request
  EXPERT_VIEW_HTTP_PAGE_HIERARCHY = 108, // [C/S,S/C,nada] /Page/Request
  EXPERT_VIEW_HTTP_REQUEST_PACKET = 109, // packet info for all packets in request N

  EXPERT_VIEW_VOIP_CALL = 151,
  EXPERT_VIEW_VOIP_MEDIA = 152,
  EXPERT_VIEW_VOIP_CALL_FLOW = 153,
  EXPERT_VIEW_VOIP_FROM = 154,
  EXPERT_VIEW_VOIP_SIGNALING = 155, // packet-per-row signaling details, plus RTP block rows, requires WHERE clause

  // Omni 12.3
  EXPERT_VIEW_FLOW_VISUALIZER = 160, // Packet-per-row details for reimplemented Flow Visualizer.

  EXPERT_VIEW_MAX, // when you need to know the one-past-the-highest possible ViewID
}
