export const defaultColumns = [
  {
    dataKey: "fileName",
    label: "Name",
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "fileExtension",
    label: "Extension",
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "id",
    label: "File ID",
    width: 72,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "requestID",
    label: "Request ID",
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "flowID",
    label: "Flow ID",
    width: 72,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "clientAddress",
    label: "Client Addr",
    width: 160,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "clientPort",
    label: "Client Port",
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "clientCountry",
    label: "Client Country",
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "clientCity",
    label: "Client City",
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "clientLatitude",
    label: "Client Latitude",
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "clientLongitude",
    label: "Client Longitude",
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "serverAddress",
    label: "Server Addr",
    width: 160,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "serverPort",
    label: "Server Port",
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "serverCountry",
    label: "Server Country",
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "serverCity",
    label: "Server City",
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "serverLatitude",
    label: "Server Latitude",
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "serverLongitude",
    label: "Server Longitude",
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "uri",
    label: "URI",
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "protocol",
    label: "Protocol",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "contentType",
    label: "Content-Type",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "referer",
    label: "Referer",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "host",
    label: "Host",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "payloadSize",
    label: "Size",
    width: 72,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "firstPacket",
    label: "First Packet",
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "lastPacket",
    label: "Last Packet",
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "firstTimestamp",
    label: "Start",
    width: 160,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "lastTimestamp",
    label: "Finish",
    width: 160,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "duration",
    label: "Duration",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
]
