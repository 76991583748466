import * as React from "react"
import FontAwesome from "react-fontawesome"
import { connect } from "react-redux"
import { TableCellProps, TableRowProps } from "react-virtualized"
import { ButtonGroup, FormGroup } from "reactstrap"
import { v4 as uuid } from "uuid"
import cn from "classnames"
import { produce } from "immer"
import { toNumber } from "lodash"
import styled, { withTheme } from "styled-components"
import { LightButton, PrimaryButton, TabButton } from "../common/Buttons"
import { DateTimePicker } from "../common/DateTimePicker"
import { ButtonBar, CheckGroup, HorizontalFormGroup, Label } from "../common/Form"
import { Input } from "../common/Input"
import { MutedText } from "../common/MutedText"
import { OmniTable } from "../common/OmniTable"
import { Select } from "../common/Select"
import {
  fetchFilters,
  postSelectRelatedFilterStart,
  postSelectRelatedFilterConfigStart,
} from "../../api/api"
import {
  EngineCapabilitiesPluginInfo,
  Filter,
  RequestPostSelectRelatedFilterStart,
  RequestPostSelectRelatedFilterConfigStart,
  ResponseGetEngineCapabilities,
  ResponsePostSelectRelatedFilterStart,
  ResponsePostSelectRelatedFilterConfigStart,
} from "../../api/types"
import {
  EncodingCommands,
  MatchCriteria,
  MatchSense,
  MatchSelection,
  PatternFilterType,
} from "../../api/types/filterTypes"
import { PeekFilterMode } from "../../api/types/peekTypes"
import { getAuthToken, getCapabilities, getEngine, getShowLocalTime } from "../../store"
import { hexEncodeByteArray, hexStringToData } from "../../utils/encodeHex"
import { formatColorForTheme, formatInteger, peekFromDate } from "../../utils/formatUtils"
import { collator } from "../../utils/sortUtils"

const SelectPacketsContent = styled.div`
  padding: 1rem;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1rem;
  }

  /* Prevent tab buttons from wrapping text */
  & .btn-group .btn {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
`

const MatchHeaderGrid = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content auto;
  grid-gap: 0 0.5rem;
`

const CriteriaGroup = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

type PatternFilterTypeObj = {
  codePage: number | null
  filterType: PatternFilterType
  key?: string
  text: string
}

type SelectPacketsSidebarProps = {
  engine: string
  authToken: string
  capId: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  firstPacketNumber: number
  packetCount: number
  packetStartTime: string
  packetEndTime: string
  selectPacketRange: (
    firstPacketNumber: number,
    lastPacketNumber: number,
    inverted: boolean
  ) => void
  setSelectPacketsTask: (task: any) => void
  showLocalTime: boolean
  theme: any
}

type SelectPacketsSidebarState = {
  expandedGroups: Set<string>
  fetchError: any | null
  filters: Filter[] | null
  matchCriteria: MatchCriteria
  matchSense: MatchSense
  matchSelection: MatchSelection
  packetLengthMin: number
  packetLengthMax: number
  packetRangeMin: number
  packetRangeMax: number
  packetStartTime: string
  packetEndTime: string
  pattern: string
  patternCodePage: number | null
  patternFilterType: PatternFilterType
  pluginClsid: string | null
  selectedFilters: string[]
}

class SelectPacketsSidebar extends React.Component<
  SelectPacketsSidebarProps,
  SelectPacketsSidebarState
> {
  state: SelectPacketsSidebarState = {
    expandedGroups: new Set(),
    fetchError: null,
    filters: null,
    matchCriteria: MatchCriteria.MATCH_CRITERIA_FILTERS,
    matchSense: MatchSense.MATCH_SENSE_NORMAL,
    matchSelection: MatchSelection.MATCH_SELECTION_REPLACE,
    packetLengthMin: 64,
    packetLengthMax: 1518,
    packetRangeMin: this.props.firstPacketNumber,
    packetRangeMax: this.props.packetCount,
    packetStartTime:
      this.props.packetStartTime && new Date(this.props.packetStartTime).valueOf() > 0
        ? this.props.packetStartTime
        : new Date().toISOString(),
    packetEndTime:
      this.props.packetEndTime && new Date(this.props.packetEndTime).valueOf() > 0
        ? this.props.packetEndTime
        : new Date().toISOString(),
    pattern: "",
    patternCodePage: null,
    patternFilterType: PatternFilterType.PATTERN_FILTER_TYPE_ASCII,
    pluginClsid: null,
    selectedFilters: [],
  }

  static getDerivedStateFromProps(
    props: SelectPacketsSidebarProps,
    state: SelectPacketsSidebarState
  ) {
    const newState: any = { ...state }
    if (state.matchCriteria !== MatchCriteria.MATCH_CRITERIA_RANGE) {
      newState.packetRangeMin = props.firstPacketNumber
      newState.packetRangeMax = props.packetCount
    }
    if (state.matchCriteria !== MatchCriteria.MATCH_CRITERIA_TIME) {
      newState.packetStartTime =
        props.packetStartTime && new Date(props.packetStartTime).valueOf() > 0
          ? props.packetStartTime
          : new Date().toISOString()
      newState.packetEndTime =
        props.packetEndTime && new Date(props.packetEndTime).valueOf() > 0
          ? props.packetEndTime
          : new Date().toISOString()
    }
    return newState
  }

  componentDidMount() {
    const { engine, authToken, engineCapabilities } = this.props
    if (
      engineCapabilities &&
      Array.isArray(engineCapabilities.pluginsInfo) &&
      engineCapabilities.pluginsInfo.length > 0
    ) {
      const pluginsInfo = engineCapabilities.pluginsInfo.filter(
        pluginInfo => pluginInfo.features.filter
      )
      if (pluginsInfo.length > 0) {
        pluginsInfo.sort((a: EngineCapabilitiesPluginInfo, b: EngineCapabilitiesPluginInfo) =>
          collator.compare(a.name, b.name)
        )
        this.setState({
          pluginClsid: pluginsInfo[0].clsid,
        })
      }
    }
    fetchFilters(engine, authToken)
      .then(filters => {
        if (Array.isArray(filters.filters)) {
          this.setState({
            filters: filters.filters,
          })
        }
      })
      .catch(error => {
        this.setState({ fetchError: error })
      })
  }

  generatePatternKey = (filterType: PatternFilterType, codePage: number | null) => {
    return filterType.toString() + ":" + (codePage !== null ? codePage.toString() : "")
  }

  onCheckFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    const { checked } = event.target
    const id = event.target.name
    this.setState(
      produce(draft => {
        if (checked) {
          if (!draft.selectedFilters.includes(id)) {
            draft.selectedFilters.push(id)
          }
        } else {
          draft.selectedFilters = draft.selectedFilters.filter(
            (filterId: string) => filterId !== id
          )
        }
      })
    )
  }

  onDisableAll = () => {
    this.setState({ selectedFilters: [] })
  }

  onEnableAll = () => {
    const { filters } = this.state
    this.setState(
      produce(draft => {
        if (filters) {
          draft.selectedFilters = filters.map(filter => filter.id)
        } else {
          draft.selectedFilters = []
        }
      })
    )
  }

  onExpandCollapse = (group: any) => {
    const expandedGroups = new Set(this.state.expandedGroups)
    if (expandedGroups.has(group)) {
      expandedGroups.delete(group)
    } else {
      expandedGroups.add(group)
    }
    this.setState({ expandedGroups })
  }

  getFlattenedTree = () => {
    const { filters } = this.state
    if (filters) {
      // Build hierarchical representation
      const groups: any[] = []
      filters.forEach((filter: Filter) => {
        const group = groups.find(group => group.name === filter.group)
        if (group) {
          group.children.push(filter)
        } else {
          groups.push({
            name: filter.group,
            children: [filter],
          })
        }
      })

      // Sort top level groups by name
      groups.sort((a, b) => {
        let result = 0
        if (a.name === "") {
          result = 1
        } else if (b.name === "") {
          result = -1
        } else {
          result = collator.compare(a.name, b.name)
        }
        return result
      })

      // Sort children
      groups.forEach(group => {
        group.children.sort((a: Filter, b: Filter) => {
          return collator.compare(a.name, b.name)
        })
      })

      // Flatten the list into rows
      const rows: any[] = []
      groups.forEach(group => {
        if (group.name) {
          rows.push(group)
          const expanded = this.state.expandedGroups.has(group.name)
          if (expanded) {
            group.children.forEach((item: any, index: number) => {
              rows.push({ ...item, childIndex: index })
            })
          }
        } else {
          group.children.forEach((item: any, index: number) => {
            rows.push({ ...item, childIndex: index })
          })
        }
      })

      return rows
    }
    return null
  }

  onChangeMatchCriteria = (matchCriteria: MatchCriteria) => {
    this.setState({ matchCriteria })
  }

  onChangeMatchSense = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    this.setState({
      matchSense:
        id === "matchSenseRadiosNormal"
          ? MatchSense.MATCH_SENSE_NORMAL
          : MatchSense.MATCH_SENSE_INVERTED,
    })
  }

  onChangeMatchSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    this.setState({
      matchSelection:
        id === "matchSelectionRadiosReplace"
          ? MatchSelection.MATCH_SELECTION_REPLACE
          : MatchSelection.MATCH_SELECTION_ADD_TO,
    })
  }

  onChangePacketLengthMin = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = toNumber(event.target.value)
    if (value >= 0 && value <= 32767) {
      this.setState({ packetLengthMin: value })
    }
  }

  onChangePacketLengthMax = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = toNumber(event.target.value)
    if (value >= 0 && value <= 32767) {
      this.setState({ packetLengthMax: value })
    }
  }

  onChangePacketRangeMin = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = toNumber(event.target.value)
    if (value >= 1) {
      this.setState({ packetRangeMin: value })
    }
  }

  onChangePacketRangeMax = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = toNumber(event.target.value)
    if (value >= 1) {
      this.setState({ packetRangeMax: value })
    }
  }

  onChangePacketStartTime = (date: Date | null) => {
    if (date != null) {
      const packetStartTime = date.toISOString()
      this.setState({ packetStartTime })
    }
  }

  onChangePacketEndTime = (date: Date | null) => {
    if (date != null) {
      const packetEndTime = date.toISOString()
      this.setState({ packetEndTime })
    }
  }

  onChangePattern = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState({ pattern: value })
  }

  onChangePatternFilterType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const keys = value.split(":")
    if (keys.length === 2) {
      this.setState(
        produce(draft => {
          draft.patternFilterType = toNumber(keys[0])
          draft.patternCodePage = keys[1].length > 0 ? toNumber(keys[1]) : null
        })
      )
    }
  }

  onChangePlugin = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState({ pluginClsid: value })
  }

  onSubmit = () => {
    const { engine, authToken, capId, firstPacketNumber, packetCount } = this.props
    const {
      matchCriteria,
      matchSelection,
      matchSense,
      packetEndTime,
      packetLengthMax,
      packetLengthMin,
      packetRangeMax,
      packetRangeMin,
      packetStartTime,
      pattern,
      patternCodePage,
      patternFilterType,
      pluginClsid,
      selectedFilters,
    } = this.state

    if (matchCriteria === MatchCriteria.MATCH_CRITERIA_FILTERS) {
      if (selectedFilters.length > 0) {
        const selectRelatedBody: RequestPostSelectRelatedFilterConfigStart = {
          filters: selectedFilters,
          mode:
            matchSense === MatchSense.MATCH_SENSE_NORMAL
              ? PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_MATCHING_ANY
              : PeekFilterMode.PEEK_FILTER_MODE_REJECT_MATCHING_ALL,
        }

        postSelectRelatedFilterConfigStart(engine, authToken, capId, selectRelatedBody)
          .then((task: ResponsePostSelectRelatedFilterConfigStart) => {
            if (task.taskId) {
              this.props.setSelectPacketsTask({
                type: "filter-config",
                taskId: task.taskId,
                progress: 0,
                replace: matchSelection === MatchSelection.MATCH_SELECTION_REPLACE,
              })
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    } else if (matchCriteria === MatchCriteria.MATCH_CRITERIA_PLUGIN) {
      if (pluginClsid) {
        const selectRelatedBody: RequestPostSelectRelatedFilterStart = {
          id: uuid().toUpperCase(),
          rootNode: {
            clsid: "E461CF99-9A0A-4DCF-9691-89ECED1D4374",
            comment: "",
            inverted: matchSense !== MatchSense.MATCH_SENSE_NORMAL,
            pluginId: pluginClsid,
          },
        }

        postSelectRelatedFilterStart(engine, authToken, capId, selectRelatedBody)
          .then((task: ResponsePostSelectRelatedFilterStart) => {
            if (task.taskId) {
              this.props.setSelectPacketsTask({
                type: "filter",
                taskId: task.taskId,
                progress: 0,
                replace: matchSelection === MatchSelection.MATCH_SELECTION_REPLACE,
              })
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    } else if (matchCriteria === MatchCriteria.MATCH_CRITERIA_ASCII) {
      let criteriaValid = pattern.length > 0
      let patternData: string = pattern
      if (patternFilterType === PatternFilterType.PATTERN_FILTER_TYPE_RAW_DATA) {
        try {
          criteriaValid = pattern.length >= 2
          if (criteriaValid) {
            const patternDataArr = hexStringToData(pattern)
            criteriaValid = patternDataArr.length > 0
            patternData = hexEncodeByteArray(patternDataArr)
          }
        } catch {
          criteriaValid = false
        }
      }

      if (criteriaValid) {
        const selectRelatedBody: RequestPostSelectRelatedFilterStart = {
          id: uuid().toUpperCase(),
          rootNode: {
            caseSensitive: false,
            clsid: "D64E6090-9351-4915-97E3-067251D7546A",
            codePage: patternCodePage !== null ? patternCodePage : 0,
            comment: "",
            endOffset: 0,
            inverted: matchSense !== MatchSense.MATCH_SENSE_NORMAL,
            patternData,
            patternFlags: 0,
            patternType: patternFilterType,
            pspec: 0,
            startOffset: 0,
          },
        }

        postSelectRelatedFilterStart(engine, authToken, capId, selectRelatedBody)
          .then((task: ResponsePostSelectRelatedFilterStart) => {
            if (task.taskId) {
              this.props.setSelectPacketsTask({
                type: "filter",
                taskId: task.taskId,
                progress: 0,
                replace: matchSelection === MatchSelection.MATCH_SELECTION_REPLACE,
              })
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    } else if (matchCriteria === MatchCriteria.MATCH_CRITERIA_LENGTH) {
      if (packetLengthMin <= packetLengthMax) {
        const selectRelatedBody: RequestPostSelectRelatedFilterStart = {
          id: uuid().toUpperCase(),
          rootNode: {
            clsid: "76D9F2C2-20D3-463B-A3D2-50429DF3D23D",
            comment: "",
            inverted: matchSense !== MatchSense.MATCH_SENSE_NORMAL,
            maximumLength: packetLengthMax,
            minimumLength: packetLengthMin,
          },
        }

        postSelectRelatedFilterStart(engine, authToken, capId, selectRelatedBody)
          .then((task: ResponsePostSelectRelatedFilterStart) => {
            if (task.taskId) {
              this.props.setSelectPacketsTask({
                type: "filter",
                taskId: task.taskId,
                progress: 0,
                replace: matchSelection === MatchSelection.MATCH_SELECTION_REPLACE,
              })
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    } else if (matchCriteria === MatchCriteria.MATCH_CRITERIA_RANGE) {
      if (
        packetRangeMin <= packetRangeMax &&
        packetRangeMin >= firstPacketNumber &&
        packetRangeMax <= packetCount
      ) {
        this.props.selectPacketRange(
          packetRangeMin,
          packetRangeMax,
          matchSense !== MatchSense.MATCH_SENSE_NORMAL
        )
      }
    } else if (matchCriteria === MatchCriteria.MATCH_CRITERIA_TIME) {
      const packetStartTimeValue = peekFromDate(new Date(packetStartTime).getTime())
      const packetEndTimeValue = peekFromDate(new Date(packetEndTime).getTime())
      if (
        packetStartTimeValue <= packetEndTimeValue &&
        packetStartTimeValue >= peekFromDate(new Date(this.props.packetStartTime).getTime()) &&
        packetEndTimeValue <= peekFromDate(new Date(this.props.packetEndTime).getTime())
      ) {
        const selectRelatedBody: RequestPostSelectRelatedFilterStart = {
          id: uuid().toUpperCase(),
          rootNode: {
            clsid: "046D9C7D-0D9E-4409-9C2C-D383188F1A95",
            comment: "",
            endTime: packetEndTime,
            inverted: matchSense !== MatchSense.MATCH_SENSE_NORMAL,
            startTime: packetStartTime,
          },
        }

        postSelectRelatedFilterStart(engine, authToken, capId, selectRelatedBody)
          .then((task: ResponsePostSelectRelatedFilterStart) => {
            if (task.taskId) {
              this.props.setSelectPacketsTask({
                type: "filter",
                taskId: task.taskId,
                progress: 0,
                replace: matchSelection === MatchSelection.MATCH_SELECTION_REPLACE,
              })
            }
          })
          .catch(error => {
            console.error(error)
          })
      }
    }
  }

  onRowClick = ({ rowData }: { rowData: any }) => {
    const isGroup = rowData.children !== undefined
    if (isGroup) {
      this.onExpandCollapse(rowData.name)
    }
  }

  rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    const isGroup = rowData.children !== undefined
    if (isGroup) {
      className = cn(className, "group", "clickable")
      key = rowData.name
    } else {
      if (rowData.childIndex != null && rowData.childIndex % 2 !== 0) {
        className = cn(className, "stripe")
      }
      key = rowData.id
    }

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  rowClassName = () => {
    // Stripes applied in rowRenderer
    return ""
  }

  cellRenderer = ({ cellData, rowData }: TableCellProps) => {
    let content
    const isGroup = rowData.children !== undefined
    if (isGroup) {
      const expanded = this.state.expandedGroups.has(cellData)
      const icon = expanded ? "chevron-down" : "chevron-right"
      const iconFolder = expanded ? "folder-open-o" : "folder-o"
      content = (
        <>
          <FontAwesome name={icon} fixedWidth />
          <span style={{ paddingLeft: ".25em" }}>
            <FontAwesome name={iconFolder} fixedWidth style={{ marginRight: "0.25em" }} />
            {cellData} <MutedText>{`(${formatInteger(rowData.children.length)})`}</MutedText>
          </span>
        </>
      )
    } else {
      const { selectedFilters } = this.state
      const id = `filter-${rowData.id}`
      const checked = selectedFilters.includes(rowData.id)
      const marginLeft = rowData.group ? "3em" : "1.7em"
      content = (
        <div style={{ display: "flex", alignItems: "center", marginLeft }}>
          <CheckGroup
            type="checkbox"
            name={rowData.id}
            id={id}
            onChange={this.onCheckFilter}
            checked={checked}
            style={{ color: formatColorForTheme(rowData.color, this.props.theme.name) }}
          >
            {rowData.name}
          </CheckGroup>
        </div>
      )
    }

    return content
  }

  render() {
    const { engineCapabilities, firstPacketNumber, packetCount, showLocalTime } = this.props
    const {
      matchCriteria,
      matchSense,
      matchSelection,
      packetLengthMin,
      packetLengthMax,
      packetRangeMin,
      packetRangeMax,
      packetStartTime,
      packetEndTime,
      pattern,
      patternFilterType,
      pluginClsid,
      selectedFilters,
    } = this.state
    let flattenedFilters = null

    const startTime = packetStartTime ? new Date(packetStartTime) : undefined
    const endTime = packetEndTime ? new Date(packetEndTime) : undefined

    let criteriaValid = true
    let patternValid = true
    if (matchCriteria === MatchCriteria.MATCH_CRITERIA_FILTERS) {
      criteriaValid = selectedFilters.length > 0
      flattenedFilters = this.getFlattenedTree()
    } else if (matchCriteria === MatchCriteria.MATCH_CRITERIA_PLUGIN) {
      criteriaValid = pluginClsid != null
    } else if (matchCriteria === MatchCriteria.MATCH_CRITERIA_ASCII) {
      if (patternFilterType === PatternFilterType.PATTERN_FILTER_TYPE_RAW_DATA) {
        patternValid = pattern.length >= 2 && hexStringToData(pattern).length > 0
      } else {
        patternValid = pattern.length > 0
      }
      criteriaValid = patternValid
    } else if (matchCriteria === MatchCriteria.MATCH_CRITERIA_LENGTH) {
      criteriaValid = packetLengthMin <= packetLengthMax
    } else if (matchCriteria === MatchCriteria.MATCH_CRITERIA_RANGE) {
      criteriaValid =
        packetRangeMin <= packetRangeMax &&
        packetRangeMin >= firstPacketNumber &&
        packetRangeMax <= packetCount
    } else if (matchCriteria === MatchCriteria.MATCH_CRITERIA_TIME) {
      criteriaValid =
        packetStartTime <= packetEndTime &&
        peekFromDate(new Date(packetStartTime).getTime()) >=
          peekFromDate(new Date(this.props.packetStartTime).getTime()) &&
        peekFromDate(new Date(packetEndTime).getTime()) <=
          peekFromDate(new Date(this.props.packetEndTime).getTime())
    }

    const renderPluginsDropDown = () => {
      if (engineCapabilities && Array.isArray(engineCapabilities.pluginsInfo)) {
        const pluginsInfo = engineCapabilities.pluginsInfo.filter(
          pluginInfo => pluginInfo.features.filter
        )
        pluginsInfo.sort((a: EngineCapabilitiesPluginInfo, b: EngineCapabilitiesPluginInfo) =>
          collator.compare(a.name, b.name)
        )
        const dropDownLabels = pluginsInfo.map(pluginInfo => (
          <option key={pluginInfo.clsid} value={pluginInfo.clsid}>
            {pluginInfo.name}
          </option>
        ))
        let selectedLabel =
          pluginsInfo.length > 0
            ? pluginsInfo.find(pluginInfo => pluginInfo.clsid === pluginClsid)
            : undefined
        if (!selectedLabel) {
          selectedLabel = pluginsInfo[0]
        }
        if (dropDownLabels.length > 0) {
          return (
            <Select
              name="plugins"
              id="plugins"
              value={selectedLabel.clsid}
              onChange={this.onChangePlugin}
            >
              {dropDownLabels}
            </Select>
          )
        }
      }
      return <MutedText as="div">None</MutedText>
    }

    const renderPatternFilterTypeDown = () => {
      let patternFilters: PatternFilterTypeObj[] = [
        {
          text: "Default Text",
          filterType: PatternFilterType.PATTERN_FILTER_TYPE_ASCII,
          codePage: null,
        },
        {
          text: "UTF-8",
          filterType: PatternFilterType.PATTERN_FILTER_TYPE_UNICODE,
          codePage: 65001,
        },
        {
          text: "Hex Data",
          filterType: PatternFilterType.PATTERN_FILTER_TYPE_RAW_DATA,
          codePage: null,
        },
        {
          text: "Regular Expression",
          filterType: PatternFilterType.PATTERN_FILTER_TYPE_REGEX,
          codePage: null,
        },
      ]
      patternFilters = patternFilters
        .concat(
          EncodingCommands.map(cmd => {
            return { ...cmd, filterType: PatternFilterType.PATTERN_FILTER_TYPE_UNICODE }
          })
        )
        .map(patternFilter => {
          return {
            ...patternFilter,
            key: this.generatePatternKey(patternFilter.filterType, patternFilter.codePage),
          }
        })

      const dropDownLabels = patternFilters.map(patternFilter => (
        <option key={patternFilter.key} value={patternFilter.key}>
          {patternFilter.text}
        </option>
      ))
      const selectedKey = this.generatePatternKey(
        this.state.patternFilterType,
        this.state.patternCodePage
      )
      let selectedLabel =
        patternFilters.length > 0
          ? patternFilters.find(patternFilter => patternFilter.key === selectedKey)
          : undefined
      if (!selectedLabel) {
        selectedLabel = patternFilters[0]
      }
      if (dropDownLabels.length > 0) {
        return (
          <Select
            name="patternFilterType"
            id="patternFilterType"
            value={selectedLabel.key}
            onChange={this.onChangePatternFilterType}
          >
            {dropDownLabels}
          </Select>
        )
      } else {
        return null
      }
    }

    return (
      <SelectPacketsContent>
        <ButtonGroup style={{ flexShrink: 0 }}>
          <TabButton
            name="selectionCriteriaRadiosFilters"
            onClick={this.onChangeMatchCriteria.bind(this, MatchCriteria.MATCH_CRITERIA_FILTERS)}
            active={matchCriteria === MatchCriteria.MATCH_CRITERIA_FILTERS}
          >
            Filters
          </TabButton>
          <TabButton
            name="selectionCriteriaRadiosPlugin"
            onClick={this.onChangeMatchCriteria.bind(this, MatchCriteria.MATCH_CRITERIA_PLUGIN)}
            active={matchCriteria === MatchCriteria.MATCH_CRITERIA_PLUGIN}
          >
            Plugins
          </TabButton>
          <TabButton
            name="selectionCriteriaRadiosPattern"
            onClick={this.onChangeMatchCriteria.bind(this, MatchCriteria.MATCH_CRITERIA_ASCII)}
            active={matchCriteria === MatchCriteria.MATCH_CRITERIA_ASCII}
          >
            Pattern
          </TabButton>
          <TabButton
            name="selectionCriteriaRadiosLength"
            onClick={this.onChangeMatchCriteria.bind(this, MatchCriteria.MATCH_CRITERIA_LENGTH)}
            active={matchCriteria === MatchCriteria.MATCH_CRITERIA_LENGTH}
          >
            Packet Length
          </TabButton>
          <TabButton
            name="selectionCriteriaRadiosRange"
            onClick={this.onChangeMatchCriteria.bind(this, MatchCriteria.MATCH_CRITERIA_RANGE)}
            active={matchCriteria === MatchCriteria.MATCH_CRITERIA_RANGE}
          >
            Packet Range
          </TabButton>
          <TabButton
            name="selectionCriteriaRadiosTime"
            onClick={this.onChangeMatchCriteria.bind(this, MatchCriteria.MATCH_CRITERIA_TIME)}
            active={matchCriteria === MatchCriteria.MATCH_CRITERIA_TIME}
          >
            Packet Times
          </TabButton>
        </ButtonGroup>
        <MatchHeaderGrid style={{ flexShrink: 0 }}>
          <div style={{ justifySelf: "end" }}>Select packets that:</div>
          <CheckGroup
            type="radio"
            name="matchSenseRadios"
            id="matchSenseRadiosNormal"
            aria-label="Normal"
            checked={matchSense === MatchSense.MATCH_SENSE_NORMAL}
            onChange={this.onChangeMatchSense}
          >
            Match
          </CheckGroup>
          <CheckGroup
            type="radio"
            name="matchSenseRadios"
            id="matchSenseRadiosInverted"
            aria-label="Inverted"
            checked={matchSense === MatchSense.MATCH_SENSE_INVERTED}
            onChange={this.onChangeMatchSense}
          >
            Do not match
          </CheckGroup>
          <div style={{ justifySelf: "end" }}>Current selection:</div>
          <CheckGroup
            type="radio"
            name="matchSelectionRadios"
            id="matchSelectionRadiosReplace"
            aria-label="Replace"
            checked={matchSelection === MatchSelection.MATCH_SELECTION_REPLACE}
            onChange={this.onChangeMatchSelection}
          >
            Replace
          </CheckGroup>
          <CheckGroup
            type="radio"
            name="matchSelectionRadios"
            id="matchSelectionRadiosAddTo"
            aria-label="AddTo"
            checked={matchSelection === MatchSelection.MATCH_SELECTION_ADD_TO}
            onChange={this.onChangeMatchSelection}
          >
            Add to
          </CheckGroup>
          <PrimaryButton
            disabled={!criteriaValid}
            onClick={this.onSubmit}
            style={{ gridColumnStart: "4", gridRow: "1 / span 2", marginLeft: "auto" }}
          >
            Select Packets
          </PrimaryButton>
        </MatchHeaderGrid>
        {matchCriteria === MatchCriteria.MATCH_CRITERIA_FILTERS && flattenedFilters && (
          <CriteriaGroup>
            <FormGroup
              style={{
                flexGrow: 1,
                position: "relative",
                minHeight: "200px",
              }}
            >
              <OmniTable
                data={flattenedFilters}
                rowCount={flattenedFilters.length}
                rowHeight={25}
                disableHeader
                cellRenderer={this.cellRenderer}
                columnDesc={[
                  {
                    dataKey: "name",
                    width: 200,
                    flexGrow: 1,
                  },
                ]}
                rowRenderer={this.rowRenderer}
                rowClassName={this.rowClassName}
                onRowClick={this.onRowClick}
              />
            </FormGroup>
            <FormGroup noMargin>
              <ButtonBar>
                <LightButton size="sm" onClick={this.onEnableAll}>
                  Enable All
                </LightButton>
                <LightButton size="sm" onClick={this.onDisableAll}>
                  Disable All
                </LightButton>
              </ButtonBar>
            </FormGroup>
          </CriteriaGroup>
        )}
        {matchCriteria === MatchCriteria.MATCH_CRITERIA_PLUGIN && (
          <CriteriaGroup>
            <FormGroup>
              <Label for="plugins">Analysis Module</Label>
              {renderPluginsDropDown()}
            </FormGroup>
          </CriteriaGroup>
        )}
        {matchCriteria === MatchCriteria.MATCH_CRITERIA_ASCII && (
          <CriteriaGroup>
            <FormGroup>
              <Label for="patternFilterType">Format</Label>
              {renderPatternFilterTypeDown()}
            </FormGroup>
            <FormGroup>
              <Label for="pattern">Pattern</Label>
              <Input
                type="text"
                name="pattern"
                id="pattern"
                placeholder="Enter a pattern"
                value={pattern}
                onChange={this.onChangePattern}
                invalid={!patternValid}
                spellCheck={false}
              />
            </FormGroup>
          </CriteriaGroup>
        )}
        {matchCriteria === MatchCriteria.MATCH_CRITERIA_LENGTH && (
          <CriteriaGroup>
            <HorizontalFormGroup noMargin>
              <span>Packet length is between</span>
              <Input
                style={{ width: "8rem" }}
                type="number"
                name="lengthMin"
                id="lengthMin"
                aria-label="Minimum packet length"
                onChange={this.onChangePacketLengthMin}
                value={packetLengthMin}
                min={0}
                max={32767}
                step={1}
                disabled={matchCriteria !== MatchCriteria.MATCH_CRITERIA_LENGTH}
              />
              <span>and</span>
              <Input
                style={{ width: "8rem" }}
                type="number"
                name="lengthMax"
                id="lengthMax"
                aria-label="Maximum packet length"
                onChange={this.onChangePacketLengthMax}
                value={packetLengthMax}
                min={0}
                max={32767}
                step={1}
                disabled={matchCriteria !== MatchCriteria.MATCH_CRITERIA_LENGTH}
              />
              <span>bytes</span>
            </HorizontalFormGroup>
          </CriteriaGroup>
        )}
        {matchCriteria === MatchCriteria.MATCH_CRITERIA_RANGE && (
          <CriteriaGroup>
            <HorizontalFormGroup noMargin>
              <span>Packet range:</span>
              <Input
                style={{ width: "10rem" }}
                type="number"
                name="packetRangeMin"
                id="packetRangeMin"
                aria-label="Minimum packet number"
                onChange={this.onChangePacketRangeMin}
                value={packetRangeMin}
                min={firstPacketNumber}
                max={packetCount}
                step={1}
                disabled={matchCriteria !== MatchCriteria.MATCH_CRITERIA_RANGE}
              />
              <span>through</span>
              <Input
                style={{ width: "10rem" }}
                type="number"
                name="packetRangeMax"
                id="packetRangeMax"
                aria-label="Maximum packet number"
                onChange={this.onChangePacketRangeMax}
                value={packetRangeMax}
                min={firstPacketNumber}
                max={packetCount}
                step={1}
                disabled={matchCriteria !== MatchCriteria.MATCH_CRITERIA_RANGE}
              />
            </HorizontalFormGroup>
          </CriteriaGroup>
        )}
        {matchCriteria === MatchCriteria.MATCH_CRITERIA_TIME && (
          <CriteriaGroup>
            <FormGroup>
              <Label for="packetStartTime">Packet start time{showLocalTime ? "" : " (UTC)"}</Label>
              <DateTimePicker
                id="packetStartTime"
                selected={startTime ? startTime : new Date()}
                onChange={this.onChangePacketStartTime}
                showLocalTime={showLocalTime}
              />
            </FormGroup>
            <FormGroup>
              <Label for="packetStopTime">Packet end time{showLocalTime ? "" : " (UTC)"}</Label>
              <DateTimePicker
                id="packetStopTime"
                selected={endTime ? endTime : new Date()}
                onChange={this.onChangePacketEndTime}
                showLocalTime={showLocalTime}
              />
            </FormGroup>
          </CriteriaGroup>
        )}
      </SelectPacketsContent>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  engineCapabilities: getCapabilities(state),
  showLocalTime: getShowLocalTime(state),
})

const SelectPacketsSidebarWithTheme = withTheme(connect(mapStateToProps)(SelectPacketsSidebar))
export { SelectPacketsSidebarWithTheme as SelectPacketsSidebar }
