import { MediaType, MediaSubType } from "../api/types/mediaTypes"

export function isEthernet(mt: MediaType, mst: MediaSubType) {
  return mt === MediaType.MEDIA_TYPE_802_3 && mst === MediaSubType.MEDIA_SUBTYPE_NATIVE
}

export function isWireless(mt: MediaType, mst: MediaSubType) {
  return (
    mst === MediaSubType.MEDIA_SUBTYPE_802_11_B ||
    mst === MediaSubType.MEDIA_SUBTYPE_802_11_A ||
    mst === MediaSubType.MEDIA_SUBTYPE_802_11_GEN
  )
}

export function isWan(mt: MediaType) {
  return mt === MediaType.MEDIA_TYPE_COWAN || mt === MediaType.MEDIA_TYPE_WAN
}

export function getMediaTypeString(mt: MediaType) {
  switch (mt) {
    case MediaType.MEDIA_TYPE_802_3:
      return "Ethernet"
    case MediaType.MEDIA_TYPE_802_5:
      return "Token Ring"
    case MediaType.MEDIA_TYPE_FDDI:
      return "FDDI"
    case MediaType.MEDIA_TYPE_WAN:
      return "WAN"
    case MediaType.MEDIA_TYPE_LOCALTALK:
      return "LocalTalk"
    case MediaType.MEDIA_TYPE_DIX:
      return "DIX"
    case MediaType.MEDIA_TYPE_ARCNET_RAW:
      return "ARCNET (raw)"
    case MediaType.MEDIA_TYPE_ARCNET_878_2:
      return "ARCNET (878.2)"
    case MediaType.MEDIA_TYPE_ATM:
      return "ATM"
    case MediaType.MEDIA_TYPE_WIRELESS_WAN:
      return "Wireless WAN"
    case MediaType.MEDIA_TYPE_IRDA:
      return "Infrared (IrDA)"
    case MediaType.MEDIA_TYPE_BPC:
      return "Broadcast Architecture"
    case MediaType.MEDIA_TYPE_COWAN:
      return "Connection-Oriented WAN"
    case MediaType.MEDIA_TYPE_1394:
      return "IEEE 1394 (FireWire)"
    default:
      break
  }
  return ""
}

export function getMediaSubTypeString(mst: MediaSubType) {
  switch (mst) {
    case MediaSubType.MEDIA_SUBTYPE_NATIVE:
      return "Native"
    case MediaSubType.MEDIA_SUBTYPE_802_11_B:
      return "Wireless 802.11b"
    case MediaSubType.MEDIA_SUBTYPE_802_11_A:
      return "Wireless 802.11a"
    case MediaSubType.MEDIA_SUBTYPE_802_11_GEN:
      return "Wireless 802.11"
    case MediaSubType.MEDIA_SUBTYPE_WAN_PPP:
      return "WAN PPP"
    case MediaSubType.MEDIA_SUBTYPE_WAN_FRAME_RELAY:
      return "Frame Relay"
    case MediaSubType.MEDIA_SUBTYPE_WAN_X25:
      return "X.25"
    case MediaSubType.MEDIA_SUBTYPE_WAN_X25E:
      return "X.25e"
    case MediaSubType.MEDIA_SUBTYPE_WAN_IPARS:
      return "IPARS"
    case MediaSubType.MEDIA_SUBTYPE_WAN_U200:
      return "U200"
    case MediaSubType.MEDIA_SUBTYPE_WAN_Q931:
      return "Q931"
    default:
      break
  }
  return ""
}

export function getMediaString(mt: MediaType, mst: MediaSubType) {
  if (mst !== MediaSubType.MEDIA_SUBTYPE_NATIVE) {
    return getMediaSubTypeString(mst)
  } else {
    return getMediaTypeString(mt)
  }
}
