import * as React from "react"
import { produce } from "immer"
import { toNumber } from "lodash"
import { Col, Form, FormGroup } from "reactstrap"
import { withTheme } from "styled-components"
import { v4 as uuid } from "uuid"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { Label } from "../common/Form"
import { Input } from "../common/Input"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../common/Modal"
import { Select } from "../common/Select"
import { GraphItemObject } from "../../api/types"
import { MediaSpecType } from "../../api/types/mediaTypes"
import { GraphItemUnitType, PeekNodeStatsItemType } from "../../api/types/peekTypes"

export function generateNodeName(mst: MediaSpecType, address: string) {
  let mstString = ""
  switch (mst) {
    case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_WIRELESS_ADDRESS:
    case MediaSpecType.MEDIA_SPEC_TYPE_TOKEN_RING_ADDRESS:
      mstString = ""
      break
    case MediaSpecType.MEDIA_SPEC_TYPE_WAN_DLCI_ADDRESS:
      mstString = "DLCI-"
      break
    case MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS:
      mstString = "IP-"
      break
    case MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS:
      mstString = "IPv6-"
      break
    case MediaSpecType.MEDIA_SPEC_TYPE_APPLETALK_ADDRESS:
      mstString = "AT-"
      break
    case MediaSpecType.MEDIA_SPEC_TYPE_DECNET_ADDRESS:
      mstString = "DEC-"
      break
    case MediaSpecType.MEDIA_SPEC_TYPE_IPX_ADDRESS:
      mstString = "IPX-"
      break
    default:
      break
  }
  return mstString + address
}

type NodesModalProps = {
  clsid: string
  graphItems: GraphItemObject[]
  theme: any
  unitType: GraphItemUnitType
  onCancel: (clsid: string) => void
  onSubmit: (clsid: string, graphItems: GraphItemObject[]) => void
}

type NodesModalState = {
  address: string
  graphItems: GraphItemObject[]
  mst: MediaSpecType
  nodeStatsType: PeekNodeStatsItemType
}

class NodesModal extends React.Component<NodesModalProps, NodesModalState> {
  state: NodesModalState = {
    address: "0.0.0.0",
    graphItems: this.props.graphItems,
    mst: MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS,
    nodeStatsType: PeekNodeStatsItemType.PEEK_NODE_STATS_ITEM_TYPE_SENT,
  }

  getDefaultMediaSpecString(mst: MediaSpecType) {
    let mstString = ""
    switch (mst) {
      case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS:
      case MediaSpecType.MEDIA_SPEC_TYPE_WIRELESS_ADDRESS:
      case MediaSpecType.MEDIA_SPEC_TYPE_TOKEN_RING_ADDRESS:
        mstString = "00:00:00:00:00:00"
        break
      case MediaSpecType.MEDIA_SPEC_TYPE_WAN_DLCI_ADDRESS:
        mstString = "0"
        break
      case MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS:
        mstString = "0.0.0.0"
        break
      case MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS:
        mstString = "0000:0000:0000:0000:0000:0000:0000:0000"
        break
      case MediaSpecType.MEDIA_SPEC_TYPE_APPLETALK_ADDRESS:
      case MediaSpecType.MEDIA_SPEC_TYPE_DECNET_ADDRESS:
        mstString = "0.0"
        break
      case MediaSpecType.MEDIA_SPEC_TYPE_IPX_ADDRESS:
        mstString = "0.000000000000"
        break
      default:
        break
    }
    return mstString
  }

  onChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      produce(draft => {
        draft.address = value
      })
    )
  }

  onChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      produce(draft => {
        draft.address = this.getDefaultMediaSpecString(toNumber(value))
        draft.mst = toNumber(value)
      })
    )
  }

  onChangeUnits = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      produce(draft => {
        draft.nodeStatsType = toNumber(value)
      })
    )
  }

  onCancel = () => {
    const { clsid } = this.props
    this.props.onCancel(clsid)
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const { clsid, unitType } = this.props
    const { address, graphItems, mst, nodeStatsType } = this.state
    graphItems.push({
      clsid: "B1DAFDE2-68F2-4D35-AAA7-5861B35A3E9F",
      description: address,
      id: uuid().toUpperCase(),
      mediaSpec: address,
      mediaSpecType: mst,
      name: generateNodeName(mst, address),
      nodeStatsType,
      unitType,
    })
    this.props.onSubmit(clsid, graphItems)
  }

  render() {
    const { address } = this.state
    const validAddress = address !== undefined && address.length !== 0

    const renderTypeDropDown = () => {
      const mstItems = [
        { id: MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS, text: "Ethernet Address" },
        { id: MediaSpecType.MEDIA_SPEC_TYPE_WIRELESS_ADDRESS, text: "Wireless Address" },
        { id: MediaSpecType.MEDIA_SPEC_TYPE_WAN_DLCI_ADDRESS, text: "WAN DLCI Address" },
        { id: MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS, text: "IP" },
        { id: MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS, text: "IPv6" },
        { id: MediaSpecType.MEDIA_SPEC_TYPE_APPLETALK_ADDRESS, text: "AppleTalk" },
        { id: MediaSpecType.MEDIA_SPEC_TYPE_DECNET_ADDRESS, text: "DECnet" },
        { id: MediaSpecType.MEDIA_SPEC_TYPE_IPX_ADDRESS, text: "IPX" },
      ]
      const dropDownLabels = mstItems.map(mstItem => (
        <option key={mstItem.id} value={mstItem.id}>
          {mstItem.text}
        </option>
      ))
      if (dropDownLabels.length > 0) {
        return (
          <Select name="nodeType" id="nodeType" value={this.state.mst} onChange={this.onChangeType}>
            {dropDownLabels}
          </Select>
        )
      } else {
        return null
      }
    }

    const renderUnitsDropDown = () => {
      const unitString =
        this.props.unitType === GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_PACKETS ? "Packets" : "Bytes"

      const unitsItems = [
        { id: PeekNodeStatsItemType.PEEK_NODE_STATS_ITEM_TYPE_SENT, text: unitString + " From" },
        { id: PeekNodeStatsItemType.PEEK_NODE_STATS_ITEM_TYPE_RECEIVED, text: unitString + " To" },
        {
          id: PeekNodeStatsItemType.PEEK_NODE_STATS_ITEM_TYPE_BROADCAST,
          text: "Broadcast " + unitString,
        },
        {
          id: PeekNodeStatsItemType.PEEK_NODE_STATS_ITEM_TYPE_MULTICAST,
          text: "Multicast " + unitString,
        },
        { id: PeekNodeStatsItemType.PEEK_NODE_STATS_ITEM_TYPE_TOTAL, text: "Total " + unitString },
      ]
      const dropDownLabels = unitsItems.map(unitsItem => (
        <option key={unitsItem.id} value={unitsItem.id}>
          {unitsItem.text}
        </option>
      ))
      if (dropDownLabels.length > 0) {
        return (
          <Select
            name="nodeUnits"
            id="nodeUnits"
            value={this.state.nodeStatsType}
            onChange={this.onChangeUnits}
          >
            {dropDownLabels}
          </Select>
        )
      } else {
        return null
      }
    }

    return (
      <Modal size="md" isOpen={true}>
        <ModalHeader toggle={this.onCancel}>Node</ModalHeader>
        <ModalBody>
          <Form id="nodes-form" onSubmit={this.onSubmit} noValidate>
            <FormGroup row>
              <Label md="2" for="nodeAddress">
                Address
              </Label>
              <Col md="10">
                <Input
                  type="text"
                  name="nodeAddress"
                  id="nodeAddress"
                  onChange={this.onChangeAddress}
                  value={address}
                  required
                  invalid={!validAddress}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label md="2" for="nodeType">
                Type
              </Label>
              <Col md="10">{renderTypeDropDown()}</Col>
            </FormGroup>
            <FormGroup row noMargin>
              <Label md="2" for="nodeUnits">
                Units
              </Label>
              <Col md="10">{renderUnitsDropDown()}</Col>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={this.onCancel}>Cancel</SecondaryButton>
          <PrimaryButton disabled={!validAddress} type="submit" form="nodes-form">
            Add
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    )
  }
}

const NodesModalWithTheme = withTheme(NodesModal)
export { NodesModalWithTheme as NodesModal }
