import * as React from "react"
import FontAwesome from "react-fontawesome"
import { InputGroup } from "reactstrap"
import { Input } from "../Input"
import { InputButton } from "../Buttons"

type FilterBoxProps = {
  id?: string
  name?: string
  ref?: React.Ref<HTMLInputElement>
  value?: string
  placeholder?: string
  onChange: (filter: string) => void
  size?: "lg" | "sm"
  "aria-label"?: string
  disabled?: boolean
  style?: React.CSSProperties
}

export const FilterBox = React.forwardRef<React.ElementRef<"input">, FilterBoxProps>(
  (props, ref) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange(e.target.value)
    }

    const onClear = () => {
      props.onChange("")
    }

    return (
      <InputGroup style={{ flexWrap: "nowrap" }}>
        <Input
          id={props.id}
          name={props.name}
          innerRef={ref}
          bsSize={props.size}
          className="border-right-0"
          placeholder={props.placeholder}
          value={props.value}
          onChange={onChange}
          aria-label={props["aria-label"]}
          disabled={props.disabled}
          spellCheck={false}
          style={props.style}
        />
        <InputButton
          size={props.size}
          aria-label="Clear"
          onClick={onClear}
          disabled={props.disabled}
        >
          <FontAwesome name="times" />
        </InputButton>
      </InputGroup>
    )
  }
)

export default FilterBox
