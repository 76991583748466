import styled, { css } from "styled-components"
import { Table as ReactstrapTable } from "reactstrap"

// LiveNX colors - header: background: #EAECF3, text: #A0A1B0, border: #E3E2E9

const hoverMixin = css`
  & > tbody > tr:hover {
    background-color: ${props => props.theme.tableHoverColor};
  }
`

export const Table = styled(({ hover, ...props }) => <ReactstrapTable {...props} />)`
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.tableBackgroundColor};
  border: ${props => props.theme.tableBorder};
  margin: 0;

  & > :not(caption) > * > * {
    background-color: unset;
    box-shadow: unset;
  }

  & > thead > tr > th {
    height: 30px;
    padding: 0.25rem;
    vertical-align: middle;
    border: none;
    border-bottom: 1px solid ${props => props.theme.tableHeaderBorderColor};
    color: ${props => props.theme.tableHeaderColor};
    background-color: ${props => props.theme.tableHeaderBackgroundColor};
    font-size: ${props => props.theme.tableHeaderFontSize};
    font-weight: bold;
    text-transform: ${props => props.theme.tableHeaderTextTransform};
  }

  & > tbody > tr > td {
    padding: 0.25rem;
    border-top: 1px solid ${props => props.theme.tableGridColor};
    border-bottom: none;
    vertical-align: middle;
    color: ${props => props.theme.textColor};
    background-color: none;
  }

  & > tbody > tr > th {
    padding: 0.25rem;
    border-top: 1px solid ${props => props.theme.tableGridColor};
    border-bottom: none;
    vertical-align: middle;
    color: ${props => props.theme.tableHeaderColor};
    background-color: none;
  }

  & > tbody > tr > th {
    font-weight: bold;
  }

  & > tbody > tr {
    background-color: ${props => props.theme.tableBackgroundColor};
  }

  & > tbody > tr:nth-child(even) {
    background-color: ${props => props.theme.tableStripeColor};
  }

  ${props => (props.hover ? hoverMixin : null)}
`
