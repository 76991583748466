import ThemeInterface from "./theme"
import * as Colors from "./colorScheme"

const lightTheme: ThemeInterface = {
  name: "Light",
  backgroundColor: "#f7f7f7",
  textColor: Colors.gray900,
  textMutedColor: Colors.gray700,
  selectedColor: Colors.gray1100,
  selectedBackgroundColor: Colors.blue200,
  markedBackgroundColor: Colors.blue100,
  errorColor: Colors.red600,
  dangerColor: Colors.red900,
  dangerBackgroundColor: Colors.red100,
  warningColor: Colors.yellow800,
  warningBackgroundColor: Colors.yellow100,
  infoColor: Colors.blue900,
  infoBackgroundColor: Colors.blue200,
  successColor: Colors.green700,
  successBackgroundColor: Colors.green100,
  linkColor: Colors.primary500,
  linkHoverColor: Colors.primary700,
  outerPadding: "0",
  innerPadding: "8px",
  primaryButtonColor: "#fff",
  primaryButtonBackgroundColor: Colors.primary500,
  primaryButtonBorderColor: Colors.primary500,
  primaryButtonHoverColor: "#fff",
  primaryButtonHoverBackgroundColor: Colors.primary600,
  primaryButtonHoverBorderColor: Colors.primary600,
  secondaryButtonColor: Colors.gray900,
  secondaryButtonBackgroundColor: Colors.gray100,
  secondaryButtonBorderColor: Colors.gray500,
  secondaryButtonHoverColor: Colors.gray900,
  secondaryButtonHoverBackgroundColor: Colors.gray400,
  secondaryButtonHoverBorderColor: Colors.gray600,
  lightButtonColor: Colors.gray900,
  lightButtonBackgroundColor: "#fff",
  lightButtonBorderColor: Colors.gray500,
  lightButtonHoverColor: Colors.gray900,
  lightButtonHoverBackgroundColor: Colors.gray200,
  lightButtonHoverBorderColor: Colors.gray500,
  dangerButtonColor: "#fff",
  dangerButtonBackgroundColor: Colors.red500,
  dangerButtonBorderColor: Colors.red500,
  dangerButtonHoverColor: "#fff",
  dangerButtonHoverBackgroundColor: Colors.red600,
  dangerButtonHoverBorderColor: Colors.red600,
  tabButtonColor: Colors.primary600,
  tooltipColor: "#fff",
  tooltipBackgroundColor: "#000",
  inputColor: Colors.gray900,
  inputPlaceholderColor: Colors.gray700,
  inputBackgroundColor: "#fff",
  inputBorderColor: Colors.gray500,
  inputDisabledBackgroundColor: Colors.gray300,
  inputDisabledBorderColor: Colors.gray300,
  inputFocusColor: Colors.gray1100,
  inputFocusBorderColor: Colors.primary100,
  inputCheckBackgroundColor: "#fff",
  inputCheckBorderColor: "hsl(218,16%,70%)",
  inputCheckDisabledBackgroundColor: Colors.gray300,
  inputCheckDisabledBorderColor: "hsl(218,16%,70%)",
  inputGroupButtonColor: Colors.gray800,
  inputGroupButtonHoverColor: Colors.gray1100,
  dropdownMenuColor: Colors.gray1100,
  dropdownMenuBackgroundColor: "#fff",
  dropdownItemHoverColor: Colors.gray1100,
  dropdownItemHoverBackgroundColor: Colors.gray200,
  dropdownItemDisabledColor: Colors.gray700,
  dropdownItemDisabledBackgroundColor: "transparent",
  dropdownItemActiveColor: "#fff",
  dropdownItemActiveBackgroundColor: Colors.primary500,
  dropdownItemDividerColor: Colors.gray500,
  popoverBackgroundColor: "#fff",
  popoverHeaderBackgroundColor: Colors.gray200,
  toastBoxShadow: "0 0.5rem 1rem rgba(0,0,0,0.18)",
  toastBackgroundColor: "#fff",
  toastHeaderBackgroundColor: Colors.gray200,
  modalBackgroundColor: "#fff",
  modalHeaderBackgroundColor: "#fff",
  modalHeaderBorder: `1px solid ${Colors.gray200}`,
  modalHeaderTextTransform: "uppercase",
  modalFooterBackgroundColor: "#fff",
  modalFooterBorder: `1px solid ${Colors.gray400}`,
  dateTimePickerHeaderBackgroundColor: "#fff",
  dateTimePickerBorderColor: Colors.gray500,
  barGaugeBackgroundColor: Colors.gray300,
  barGaugeDefaultColor: Colors.gray800,
  dividerColor: Colors.gray500,
  dividerHoverColor: Colors.primary500,
  tableBackgroundColor: "#fff",
  tableBorderColor: Colors.gray500,
  tableBorder: `1px solid ${Colors.gray500}`,
  tableHeaderBackgroundColor: Colors.gray300,
  tableHeaderBorderWidth: "1px 1px 0 1px",
  tableHeaderBorderColor: Colors.gray500,
  tableHeaderColor: Colors.gray700,
  tableHeaderFontSize: "10px",
  tableHeaderTextTransform: "uppercase",
  tableGridColor: Colors.gray300,
  tableHoverColor: "hsla(218,22%,27%,0.1)",
  tableStripeColor: Colors.gray100,
  tableGroupBackgroundColor: Colors.gray400,
  propTableHeaderColor: Colors.gray700,
  propTableHeaderFontSize: "11px",
  propTableHeaderTextTransform: "uppercase",
  panelPadding: "1rem",
  panelBackground: "#fff",
  panelBorder: "none",
  panelBorderRadius: "2px",
  panelBoxShadow: "0 2px 4px 0 rgba(0,0,0,0.12)",
  panelTitleBackground: Colors.gray300,
  sidebarBoxShadow: "0 0 16px 0 rgba(0,0,0,0.24), 0 0 4px 0 rgba(0,0,0,0.12)",
  sidebarBackground: "#fff",
  sidebarTitleBackground: Colors.gray300,
  sidebarTitleBorder: `1px solid ${Colors.gray500}`,
  chartGridColor: Colors.gray500,
  chartTooltipBackgroundColor: "#fff",
  chartTooltipBorder: `1px solid ${Colors.gray500}`,
  navMarginBottom: "0",
  navBorderRadius: "0",
  navBoxShadow: "0 1px 4px rgba(0,0,0,0.24)",
  navPadding: "6px 8px",
  navHighlightColor: undefined,
  headerColor: Colors.gray700,
  headerBackgroundColor: Colors.brandGray,
  breadcrumbColor: Colors.gray900,
  breadcrumbBackgroundColor: Colors.gray600,
  breadcrumbSeparatorColor: Colors.primary600,
  engineNavColor: "#fff",
  engineNavBackgroundColor: Colors.primary500,
  settingsNavColor: Colors.gray800,
  settingsNavBackgroundColor: Colors.gray300,
  adminNavColor: Colors.gray800,
  adminNavBackgroundColor: Colors.gray300,
  captureNavColor: Colors.gray800,
  captureNavBackgroundColor: Colors.gray300,
  viewHeaderBorder: `2px solid ${Colors.gray500}`,
  viewFooterBackgroundColor: "hsl(214,12%,94%)",
  viewFooterBorder: "none",
  viewFooterBoxShadow: "0 1px 4px rgba(0,0,0,0.24)",
  sparklineStrokeColor: "rgb(35,98,173)",
  sparklineFillColor: "rgba(35,98,173,0.25)",
  searchTermColors: [
    "hsl(60,100%,80%)",
    "hsl(29,100%,86%)",
    "hsl(180,100%,86%)",
    "hsl(300,100%,86%)",
    "hsl(120,100%,86%)",
  ],
  decodePlainColor: Colors.gray900,
  decodeLayerColor: "hsl(216,100%,30%)",
  decodeDataColor: "hsl(0,100%,25%)",
  decodeHeaderColor: "hsl(148,61%,28%)",
  decodeMessageColor: "hsl(60,100%,23%)",
  decodeDumpColor: "hsl(300,100%,30%)",
  decodeDebugColor: "hsl(300,100%,30%)",
  decodeOffsetColor: Colors.gray700,
  filterOrGroupBackgroundColor: Colors.gray200,
  filterOrGroupBorder: `1px solid ${Colors.gray600}`,
  filterOrColor: "#fff",
  filterOrBackgroundColor: Colors.blue400,
  filterAndGroupBackgroundColor: Colors.gray400,
  filterAndGroupBorder: `1px solid ${Colors.gray600}`,
  filterAndColor: "#fff",
  filterAndBackgroundColor: Colors.orange600,
  filterNodeBorder: `1px solid ${Colors.gray600}`,
  filterNotColor: Colors.red600,
  clientColor: "hsl(212,52%,53%)",
  clientBackgroundColor: "hsl(212,52%,94%)",
  clientPacketLabelColor: "inherit",
  clientPacketLabelBackgroundColor: "hsl(212,52%,84%)",
  clientMissingDataColor: "#fff",
  clientMissingDataBackgroundColor: "hsl(212,52%,48%)",
  clientRepeatedDataColor: Colors.red900,
  clientRepeatedDataBackgroundColor: "hsl(212,52%,97%)",
  serverColor: "hsl(80,43%,55%)",
  serverBackgroundColor: "hsl(80,43%,94%)",
  serverPacketLabelColor: "inherit",
  serverPacketLabelBackgroundColor: "hsl(78,41%,84%)",
  serverMissingDataColor: "#fff",
  serverMissingDataBackgroundColor: "hsl(77,41%,32%)",
  serverRepeatedDataColor: Colors.red900,
  serverRepeatedDataBackgroundColor: "hsl(77,41%,97%)",
  flowGraphLine1Color: "rgb(0,128,0)",
  flowGraphLine2Color: "rgb(33,33,33)",
  flowGraphLine3Color: "rgb(0,0,136)",
  peerMapGraphBackgroundColor: "#ffffff",
  peerMapSidebarOptionsBackgroundColor: "rgba(232, 236, 241, 0.5)",
  peerMapSidebarBorderColor: "#e8ecf1",
  peerMapSidebarBackground: "#ffffff",
  peerMapLabelBackgroundColorFont: "#FAFAFA",
  tooltipWindowHeaderBackgroundColor: Colors.gray400,
  tooltipWindowBackgroundColor: "#ffffff",
  tooltipWindowTableHeaderBackgroundColor: Colors.gray400,
  tooltipWindowTableMutedBackgroundColor: Colors.gray100,
  tooltipWindowTableMutedTextColor: Colors.gray700,
  tooltipWindowDropdownBackgroundColor: "#ffffff",
  upgradeBarColor: "#000",
  upgradeBarBackgroundColor: "#ffa500",
  voipReportSectionTitle: "#424242",
  voipReportSectionSubTitle: "#616161",
  voipReportSectionHeader: Colors.gray300,
  voipReportSectionSubHeader: Colors.gray200,
  voipReportTableBorder: "#ddd",
  previewColor: "rgb(255,69,0)",
  decryptionKeyBorder: `1px solid ${Colors.gray600}`,
  linkStateDownColor: Colors.gray500,
  linkStateUpColor: Colors.green400,
  subPanelPadding: "1rem",
  subPanelBackground: "#fdfdfd",
  subPanelBorder: `1px solid ${Colors.gray600}`,
  subPanelBorderRadius: "2px",
  subPanelBoxShadow: "0 2px 4px 0 rgba(0,0,0,0.12)",
  hardwareChannelAndBackgroundColor: Colors.primary600,
  hardwareChannelAndColor: "#fff",
  hardwareChannelAndGroupBackgroundColor: Colors.gray400,
  hardwareChannelAndGroupBorder: `1px solid ${Colors.gray600}`,
  hardwareChannelNodeBorder: `1px solid ${Colors.gray600}`,
  hardwareChannelOrBackgroundColor: Colors.blue400,
  hardwareChannelOrColor: "#fff",
  hardwareChannelOrGroupBackgroundColor: Colors.gray200,
  hardwareChannelOrGroupBorder: `1px solid ${Colors.gray600}`,
  dataReductionSlicingColor: "hsl(210,50%,88%)",
  dataReductionCompressionColor: "hsl(211,50%,80%)",
  dataReductionCombinedColor: "hsl(213,50%,40%)",
  forensicSearchHistoryBackgroundColor: "#fafafa",
}

export default lightTheme
