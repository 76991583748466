import * as React from "react"
import styled from "styled-components"
import {
  Sidebar,
  SidebarBody,
  SidebarContent,
  SidebarHeader,
  SidebarTitle,
} from "../common/Sidebar"
import PropTable from "../common/PropTable"
import { CloseButton, TabButton } from "../common/Buttons"
import { ReconstructionsDesc } from "../../api/types"
import { parseHeaders, formatHeaderProp } from "../../utils/expertUtils"
import { formatDuration, formatISODateTime } from "../../utils/formatUtils"
import { ButtonGroup } from "reactstrap"
import { Label } from "../common/Form"
import { fetchReconstructions } from "../../api/api"
import ReconstructionsHexPane from "./ReconstructionsHex"
import { protocolOptions } from "./protocols"
import {
  HeaderDiv,
  formatCountryName,
  formatPayloadSize,
  getDisplayOption,
  getNameFromNameTable,
  getSlicedMessage,
  parseHeadersWrapper,
} from "./utils"
import ReconstructionsDropdown from "./ReconstructionsDropdown"
import ReconstructionsImage from "./ReconstructionsImage"
import ReconstructionsText from "./ReconstructionsText"
import { useSelector } from "react-redux"
import { getShowLocalTime } from "../../store"
import ReconstructionsContentsProvider from "./reconstructions-contents"

interface ReconstructionsSidebarProps {
  isOpen: boolean
  entry: string | null
  reconstructions: ReconstructionsDesc[] | null
  engine: string
  authToken: string
  type: string
  capId: string
  onClose: () => void
  theme: any
}

const ContentPane = styled.div`
  padding: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1rem;
  }
`

const ReconstructionsSidebar: React.FC<ReconstructionsSidebarProps> = ({
  isOpen,
  entry,
  reconstructions,
  engine,
  authToken,
  type,
  capId,
  onClose,
  theme,
}) => {
  const [activeTab, setActiveTab] = React.useState<string>("Details")
  const [reconstructionObject, setReconstructionObject] = React.useState<string | Uint8Array>("")
  const [activeDataType, setActiveDataType] = React.useState<string>("Auto")
  const [override, setOverride] = React.useState<string>("")

  const changeTab = (tab: string) => {
    setActiveTab(tab)
  }

  const changeDataType = (dataType: string) => {
    setActiveDataType(dataType)
  }

  const showLocalTime = useSelector(getShowLocalTime)

  let useHeaders: boolean = false
  const properties: any = {}
  const requestHeaderProperties: any = {}
  const responseHeaderProperties: any = {}

  // Store the field names
  const requestFieldNames: Array<string> = []
  const responseFieldNames: Array<string> = []

  // Each request and response has an initial message that is not categorized.
  let initialMessageRequest: string = ""
  let initialMessageResponse: string = ""

  const reconstruction = reconstructions?.find(
    (listEntry: ReconstructionsDesc) => listEntry.id.toString() === entry
  )

  if (reconstruction) {
    properties["Name"] = reconstruction.fileName ? reconstruction.fileName : "(default)"
    properties["File ID"] = reconstruction.id
    properties["Request ID"] = reconstruction.requestID
    properties["Flow ID"] = reconstruction.flowID
    properties["Client Addr"] = getNameFromNameTable(
      reconstruction.clientAddressName,
      reconstruction.clientAddress,
      reconstruction.clientAddressColor,
      theme
    )
    properties["Client Port"] = getNameFromNameTable(
      reconstruction.clientPortName,
      reconstruction.clientPort.toString(),
      reconstruction.clientPortColor,
      theme
    )
    properties["Client Country"] = formatCountryName(
      reconstruction.clientCountry,
      reconstruction.clientCountryCode
    )
    properties["Client City"] = reconstruction.clientCity
    properties["Client Latitude"] = reconstruction.clientLatitude
    properties["Client Longitude"] = reconstruction.clientLongitude
    properties["Server Addr"] = getNameFromNameTable(
      reconstruction.serverAddressName,
      reconstruction.serverAddress,
      reconstruction.serverAddressColor,
      theme
    )
    properties["Server Port"] = getNameFromNameTable(
      reconstruction.serverPortName,
      reconstruction.serverPort.toString(),
      reconstruction.serverPortColor,
      theme
    )
    properties["Server Country"] = formatCountryName(
      reconstruction.serverCountry,
      reconstruction.serverCountryCode
    )
    properties["Server City"] = reconstruction.serverCity
    properties["Server Latitude"] = reconstruction.serverLatitude
    properties["Server Longitude"] = reconstruction.serverLongitude
    properties["URI"] = reconstruction.uri
    properties["Content-Type"] = reconstruction.contentType
    properties["Referer"] = reconstruction.referer
    properties["Host"] = reconstruction.host
    properties["Size"] = formatPayloadSize(reconstruction.payloadSize)

    if (reconstruction.fileSizeLimit) {
      properties["File Size Limit"] = formatPayloadSize(reconstruction.fileSizeLimit)
    }

    properties["First Packet"] = reconstruction.firstPacket
    properties["Last Packet"] = reconstruction.lastPacket
    properties["Start"] = formatISODateTime(reconstruction.firstTimestamp, 6, showLocalTime)
    properties["Finish"] = formatISODateTime(reconstruction.lastTimestamp, 6, showLocalTime)
    properties["Duration"] = formatDuration(reconstruction.duration, 6)
    properties["Request Headers"] = reconstruction.requestHeaders
    properties["Response Headers"] = reconstruction.responseHeaders
    properties["Protocol"] = protocolOptions[reconstruction.protocol].label

    useHeaders = properties["Protocol"] === "HTTP" || properties["Protocol"] === "SMTP"

    // Create an array of strings to split out different fields based on newlines
    if (reconstruction.requestHeaders) {
      if (useHeaders) {
        initialMessageRequest = parseHeadersWrapper(
          reconstruction.requestHeaders,
          requestHeaderProperties,
          requestFieldNames
        )
      }
    }

    if (reconstruction.responseHeaders) {
      if (properties["Protocol"] === "HTTP") {
        initialMessageResponse = parseHeadersWrapper(
          reconstruction.responseHeaders,
          responseHeaderProperties,
          responseFieldNames
        )
      } else if (properties["Protocol"] === "SMTP") {
        const reformattedResponseHeaders = reconstruction.responseHeaders.split("\r\n")
        for (let i = 0; i < reformattedResponseHeaders.length; i++) {
          const responseHeader: string = reformattedResponseHeaders[i]
          reformattedResponseHeaders[i] = responseHeader.replace(" ", ": ")
        }
        parseHeaders(reformattedResponseHeaders, responseHeaderProperties, responseFieldNames)
      }
    }
  }

  const propList: string[] = [
    "Name",
    "File ID",
    "Request ID",
    "Flow ID",
    "Client Addr",
    "Client Port",
    "Client Country",
    "Client City",
    "Client Latitude",
    "Client Longitude",
    "Server Addr",
    "Server Port",
    "Server Country",
    "Server City",
    "Server Latitude",
    "Server Longitude",
    "URI",
    "Content-Type",
    "Referer",
    "Host",
    "Size",
    "File Size Limit",
    "First Packet",
    "Last Packet",
    "Start",
    "Finish",
    "Duration",
    "Protocol",
  ]

  // Remove File Size Limit if an older engine
  if (reconstruction?.fileSizeLimit === undefined) {
    delete propList[21]
  }

  // Remove content type from the prop list if not using HTTP or SMTP
  if (!useHeaders) {
    delete propList[17]
  }

  React.useEffect(() => {
    if (reconstruction) {
      setReconstructionObject("")

      switch (activeDataType) {
        case "Image": {
          setOverride("image")
          break
        }
        case "Text": {
          setOverride(reconstruction.contentType?.includes("json") ? "json" : "text")
          break
        }
        case "Hexadecimal": {
          setOverride("audio")
          break
        }
        default: {
          setOverride("")
          break
        }
      }

      fetchReconstructions(engine, authToken, type, capId, reconstruction.id.toString(), override)
        .then(response => {
          if (response) {
            setReconstructionObject(response)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }, [reconstruction, authToken, capId, engine, type, activeDataType, override])

  const renderContents = (contentType: string, desc: ReconstructionsDesc | undefined) => {
    const dropDownEllipse = (
      <ReconstructionsDropdown activeDataType={activeDataType} onChangeDataType={changeDataType} />
    )

    const fileSize = properties["Size"]
    const dataDisplayOption = getDisplayOption(contentType, override)
    const slicedMessage = getSlicedMessage(fileSize, desc?.fileSizeLimit)
    const isImage = dataDisplayOption?.includes("image")
    const isText =
      dataDisplayOption?.includes("text") ||
      dataDisplayOption?.includes("javascript") ||
      dataDisplayOption?.includes("json") ||
      dataDisplayOption?.includes("multipart")

    return (
      <ReconstructionsContentsProvider
        contentType={contentType}
        fileSize={fileSize}
        slicedMessage={slicedMessage}
        ellipse={dropDownEllipse}
      >
        {isImage && <ReconstructionsImage reconstruction={reconstructionObject as string} />}
        {isText && <ReconstructionsText reconstruction={reconstructionObject as string} />}
        {!isImage && !isText && (
          <ReconstructionsHexPane packetData={reconstructionObject as Uint8Array} />
        )}
      </ReconstructionsContentsProvider>
    )
  }

  const tabButtons = ["Details", useHeaders ? "Headers" : null, "Contents"]

  const tabs = (
    <ButtonGroup style={{ flexShrink: 0 }}>
      {tabButtons.map(tab => {
        if (!tab) return null

        return (
          <TabButton
            key={tab}
            name={tab}
            onClick={() => {
              changeTab(tab)
            }}
            active={activeTab === tab}
          >
            {tab}
          </TabButton>
        )
      })}
    </ButtonGroup>
  )

  return (
    <Sidebar open={isOpen} width="45rem">
      <SidebarBody open={isOpen}>
        <SidebarHeader>
          <SidebarTitle>{entry !== null ? `Reconstruction ${entry} Details` : null}</SidebarTitle>
          <CloseButton onClick={() => onClose()} />
        </SidebarHeader>
        <SidebarContent>
          <ContentPane>
            {tabs}
            {activeTab === "Details" ? (
              <HeaderDiv>
                <PropTable propList={propList} data={properties} skipEmptyRows />
              </HeaderDiv>
            ) : null}
            {activeTab === "Headers" && useHeaders ? (
              <>
                <HeaderDiv>
                  <Label>Request Headers</Label>
                  <h6>{initialMessageRequest}</h6>
                  <PropTable
                    propList={requestFieldNames}
                    data={requestHeaderProperties}
                    skipEmptyRows
                  />
                </HeaderDiv>
                <HeaderDiv>
                  <Label>Response Headers</Label>
                  <h6>{initialMessageResponse}</h6>
                  <PropTable
                    propList={responseFieldNames}
                    formatProp={formatHeaderProp}
                    data={responseHeaderProperties}
                    skipEmptyRows
                  />
                </HeaderDiv>
              </>
            ) : null}
            {activeTab === "Contents" && reconstructionObject !== ""
              ? renderContents(properties["Content-Type"], reconstruction)
              : null}
          </ContentPane>
        </SidebarContent>
      </SidebarBody>
    </Sidebar>
  )
}

export default ReconstructionsSidebar
