import * as React from "react"
import styled from "styled-components"
import { Alert } from "../Alert"
import { Panel } from "../Panel"
import { formatInteger } from "../../../utils/formatUtils"

// <View>
//   <ViewHeader>
//     <ViewHeaderTitle>
//     <ViewHeaderButtons>
//   </ViewHeader>
//   <ViewMaxWidth>
//     <ViewContent>
//       content
//     </ViewContent>
//   </ViewMaxWidth>
// <View>

export const View = styled.main<{ padding?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${props => (props.padding ? props.theme.innerPadding : 0)};
  flex-grow: 1;
  position: relative;
`

View.defaultProps = {
  padding: true,
}

export const ViewMaxWidthOuter = styled.div<{ maxWidth?: number | string }>`
  flex-grow: 1;
  display: flex;
  justify-content: center;

  & > * {
    max-width: ${props => (props.maxWidth !== undefined ? props.maxWidth : "960px")};
  }
`

export const ViewMaxWidthInner = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`

export const ViewMaxWidth = ({
  maxWidth,
  children,
}: {
  maxWidth?: number | string
  children: React.ReactNode
}) => {
  return (
    <ViewMaxWidthOuter maxWidth={maxWidth}>
      <ViewMaxWidthInner>{children}</ViewMaxWidthInner>
    </ViewMaxWidthOuter>
  )
}

export const ViewContent = styled.div`
  flex-grow: 1;
  min-height: 250px;
`

export const ViewContentScrollable = styled(ViewContent)`
  overflow-y: auto;
  height: 0;
  margin: 2px 0;
`

export const ViewHeader = styled.div<{ border?: boolean }>`
  display: flex;
  border-bottom: ${props => (props.border ? props.theme.viewHeaderBorder : "none")};
  margin-bottom: 8px;
  padding-bottom: ${props => (props.border ? "4px" : "0")};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

ViewHeader.defaultProps = {
  border: true,
}

export const ViewHeaderItems = styled.div<{ border?: boolean }>`
  display: flex;
  flex-direction: column;
  border-bottom: ${props => (props.border ? props.theme.viewHeaderBorder : "none")};
  margin-bottom: 8px;
  padding-bottom: ${props => (props.border ? "4px" : "0")};

  & > * + * {
    margin-top: 4px;
  }
`

ViewHeaderItems.defaultProps = {
  border: true,
}

export const ViewHeaderPanel = styled(Panel)`
  position: relative;
  margin-bottom: 10px;
`

export const ViewContentContainer = styled.div<{ maxWidth?: number | string }>`
  width: 100%;
  padding: 3rem;
  margin: 1rem auto;
  max-width: ${props => props.maxWidth};
  background: ${props => props.theme.modalBackgroundColor};
  box-shadow: ${props => props.theme.panelBoxShadow};
`

export const ViewTitleStyle = styled.h4`
  flex-grow: 1;
  margin: 0;
  line-height: 1.5;
  white-space: nowrap;

  @media (max-width: 768px) {
    border-bottom: ${props => props.theme.viewHeaderBorder};
    margin-bottom: 4px;
    padding-bottom: 4px;
  }
`

export const ViewSubTitleStyle = styled.span`
  font-size: 80%;
  font-weight: normal;
  color: ${props => props.theme.textMutedColor};
  white-space: nowrap;
`

type ViewHeaderTitleProps = {
  title: string
  count?: number
  flexGrow?: number | "inherit" | "initial" | "-moz-initial" | "revert" | "unset" | undefined
}

export class ViewHeaderTitle extends React.PureComponent<ViewHeaderTitleProps> {
  render() {
    const { title, count, flexGrow } = this.props
    if (count === undefined || count === 0) {
      return <ViewTitleStyle style={{ flexGrow: flexGrow || 1 }}>{title}</ViewTitleStyle>
    } else {
      return (
        <ViewTitleStyle style={{ flexGrow: flexGrow || 1 }}>
          {title} <ViewSubTitleStyle>({formatInteger(count)})</ViewSubTitleStyle>
        </ViewTitleStyle>
      )
    }
  }
}

export const ViewHeaderButtons = styled.div`
  display: flex;

  & button,
  & a {
    white-space: nowrap;
  }

  & > * + * {
    margin-left: 4px !important;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    & > * + * {
      margin-left: 0 !important;
      margin-top: 4px !important;
    }
  }
`

export const ViewFooter = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  background-color: ${props => props.theme.viewFooterBackgroundColor};
  border-top: ${props => props.theme.viewFooterBorder};
  box-shadow: ${props => props.theme.viewFooterBoxShadow};

  & > :not(:first-child) {
    margin-left: 0.25rem;
  }

  & > :not(:last-child) {
    margin-right: 0.25rem;
  }
`

export const ViewAlert = styled(Alert)`
  padding: 0.5rem 1rem;
  margin-bottom: 8px;

  & .close {
    padding: 0.5rem 1rem;
  }
`

export const ViewAlertContent = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: 0.5rem;
  }
`

export const ViewContentFooter = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 2rem;

  & > :not(:first-child) {
    margin-left: 0.25rem;
  }

  & > :not(:last-child) {
    margin-right: 0.25rem;
  }
`
