import styled from "styled-components"
import { IconInformational, IconMinor, IconMajor, IconSevere } from "../../../common/Icons"
import { Widget, WidgetBody } from "../../DashboardWidget"
import { WidgetProps } from "../../../../api/types/dashboardTypes"
import { formatInteger } from "../../../../utils/formatUtils"
import DashboardWidgetHeader from "../../DashboardWidgetHeader"

const Events = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

const EventCounter = styled.div`
  font-size: larger;
  & > span {
    margin-right: 0.5rem;
  }
`

const GlobalEventCountersWidget = ({ widget, data, commands }: WidgetProps) => {
  const title = widget.title || "Events"
  const eventCounts: any | undefined = data && data.counts

  return (
    <Widget>
      <DashboardWidgetHeader title={title} widget={widget} commands={commands} />
      <WidgetBody>
        {eventCounts && (
          <Events>
            <EventCounter>
              <IconInformational height={24} width={24} />
              &nbsp;
              {formatInteger(eventCounts.informational)}
            </EventCounter>
            <EventCounter>
              <IconMinor height={24} width={24} />
              &nbsp;
              {formatInteger(eventCounts.minor)}
            </EventCounter>
            <EventCounter>
              <IconMajor height={24} width={24} />
              &nbsp;
              {formatInteger(eventCounts.major)}
            </EventCounter>
            <EventCounter>
              <IconSevere height={24} width={24} />
              &nbsp;
              {formatInteger(eventCounts.severe)}
            </EventCounter>
          </Events>
        )}
      </WidgetBody>
    </Widget>
  )
}

export default GlobalEventCountersWidget
