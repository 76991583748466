import * as React from "react"
import FontAwesome from "react-fontawesome"
import {
  AutoSizer,
  Column,
  Index,
  TableProps,
  TableHeaderRowProps,
  TableCellDataGetterParams,
  TableCellProps,
  TableCellRenderer,
} from "react-virtualized"
import { VirtualTable } from "../VirtualTable"
import { IconDropdownToggle } from "../Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../Dropdown"

type Without<T, K> = Pick<T, Exclude<keyof T, K>>
type TablePropsNoHeight = Without<TableProps, "height">
export type OmniTableProps = TablePropsNoHeight & {
  triggerRender?: number
  data: any
  cellRenderer?: TableCellRenderer
  renderCommands?: TableCellRenderer
  rowClassName?: string | ((info: Index) => string) | undefined
  columnDesc: any[]
  onShowDefaultColumns?: () => void
  onShowAllColumns?: () => void
  onToggleColumn?: (event: any) => void
}

export class OmniTable extends React.Component<OmniTableProps> {
  table: any | null = null

  rowGetter = ({ index }: { index: number }) => {
    const { data } = this.props
    return data[index]
  }

  cellDataGetter = ({ dataKey, rowData }: TableCellDataGetterParams) => {
    if (!rowData) return ""
    return rowData[dataKey]
  }

  headerRowRenderer = ({ className, columns, style }: TableHeaderRowProps) => {
    // Adjust the right padding to take into account borders on the table
    // so header columns line up with cells properly.
    if (typeof style.paddingRight === "number" && style.paddingRight >= 2) {
      style.paddingRight = +style.paddingRight - 2
    }
    // Just do what defaultHeaderRowRenderer does.
    return (
      <div className={className} role="row" style={style}>
        {columns}
      </div>
    )
  }

  cellRenderer = ({ cellData }: TableCellProps) => {
    return cellData
  }

  commandHeaderRenderer = () => {
    const { columnDesc, onToggleColumn, onShowDefaultColumns, onShowAllColumns } = this.props
    if (columnDesc && onToggleColumn) {
      const items: any[] = []

      if (onShowDefaultColumns) {
        items.push(
          <DropdownItem key="defaultColumns" onClick={onShowDefaultColumns}>
            <FontAwesome name="blank" fixedWidth /> Default Columns
          </DropdownItem>
        )
      }

      if (onShowAllColumns) {
        items.push(
          <DropdownItem key="allColumns" onClick={onShowAllColumns}>
            <FontAwesome name="blank" fixedWidth /> All Columns
          </DropdownItem>
        )
      }

      if (items.length) {
        items.push(<DropdownItem key="divider" divider />)
      }

      columnDesc.forEach(col => {
        let label = col.label
        if (col.listLabel !== undefined) {
          label = col.listLabel
        }
        if (label === undefined || (typeof label === "string" && label.length === 0)) {
          // No label.
        } else {
          items.push(
            <DropdownItem key={col.dataKey} name={col.dataKey} onClick={onToggleColumn}>
              <FontAwesome name={col.visible ? "check" : "blank"} fixedWidth /> {label}
            </DropdownItem>
          )
        }
      })

      return (
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle aria-label="Toggle Columns">
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>{items}</DropdownMenu>
        </UncontrolledDropdownWithPortal>
      )
    }

    return null
  }

  rowClassName = ({ index }: { index: number }) => {
    if (index >= 0 && index % 2 !== 0) {
      return "stripe"
    }
    return ""
  }

  render() {
    const {
      data,
      headerHeight = 30,
      rowHeight = 26,
      columnDesc,
      rowGetter = this.rowGetter,
      headerRowRenderer = this.headerRowRenderer,
      cellDataGetter = this.cellDataGetter,
      cellRenderer = this.cellRenderer,
      rowClassName = this.rowClassName,
      renderCommands,
      ...tableProps
    } = this.props
    if (!data) return null
    const columns: any[] = []
    columnDesc.forEach(col => {
      const { visible = true } = col
      if (visible) {
        const { key: unused1, dataKey, alignRight = false, visible: unused2, ...colProps } = col
        const key = col.key || dataKey
        const className = alignRight ? "right" : undefined
        columns.push(
          <Column
            key={key}
            dataKey={dataKey}
            className={className}
            headerClassName={className}
            cellDataGetter={cellDataGetter}
            cellRenderer={cellRenderer}
            {...colProps}
          />
        )
      }
    })
    if (renderCommands) {
      columns.push(
        <Column
          key="commands"
          dataKey="commands"
          width={28}
          flexShrink={0}
          cellRenderer={renderCommands}
          headerRenderer={this.commandHeaderRenderer}
          disableSort
        />
      )
    }
    return (
      <AutoSizer>
        {({ height, width }) => (
          <VirtualTable
            ref={(ref: any) => {
              this.table = ref
            }}
            width={width}
            height={height}
            headerHeight={headerHeight}
            rowHeight={rowHeight}
            rowGetter={rowGetter}
            headerRowRenderer={headerRowRenderer}
            rowClassName={rowClassName}
            rowCount={0}
            {...tableProps}
          >
            {columns}
          </VirtualTable>
        )}
      </AutoSizer>
    )
  }
}
