import * as React from "react"
import styled from "styled-components"

export const IFrame = styled.iframe`
  border: none;
  padding-bottom: 20px;
  width: 800px;
  height: 400px;
`

interface IframeComponentProps {
  content: string
  title: string
}

const IframeComponent: React.FC<IframeComponentProps> = ({ content, title }) => {
  const [contentExists, setContentExists] = React.useState<boolean>(false)

  React.useEffect(() => {
    const checkContent = async () => {
      try {
        const response = await fetch(content)

        if (response.ok) {
          const html = await response.text()
          setContentExists(html.trim().length !== 0)
        } else {
          setContentExists(response.ok)
        }
      } catch (error) {
        console.error("Failed to fetch content:", error)
      }
    }

    checkContent()
  }, [content])

  return <>{contentExists && <IFrame src={content} title={title} />}</>
}

export default IframeComponent
