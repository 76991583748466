import { useTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import { IconDropdownToggle } from "../../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../../common/Dropdown"
import { WidgetHeader, WidgetTitle, WidgetOptions } from "../DashboardWidget"
import { Widget, WidgetCommands } from "../../../api/types/dashboardTypes"

export type DashboardWidgetHeaderProps = {
  title: string
  widget: Widget
  commands: WidgetCommands
}

const DashboardWidgetHeader = ({ title, widget, commands }: DashboardWidgetHeaderProps) => {
  const theme = useTheme()

  return (
    <WidgetHeader>
      <WidgetTitle>{title}</WidgetTitle>
      <WidgetOptions>
        <UncontrolledDropdown>
          <IconDropdownToggle aria-label="Settings">
            <FontAwesome name="gear" style={{ color: theme.textMutedColor }} />
          </IconDropdownToggle>
          <DropdownMenu end>
            <DropdownItem onClick={() => commands.onEditWidget(widget)}>Edit</DropdownItem>
            <DropdownItem onClick={() => commands.onRemoveWidget(widget)}>Remove</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </WidgetOptions>
    </WidgetHeader>
  )
}

export default DashboardWidgetHeader
