import * as React from "react"
import { useSelector } from "react-redux"
import { FormGroup } from "reactstrap"
import styled from "styled-components"
import { ExpertSearch } from "./ExpertSearchTypes"
import { ControlStrip } from "../Layout"
import { DateTimePicker } from "../DateTimePicker"
import { Input } from "../Input"
import { Label, CheckGroup } from "../Form"
import { Popover, PopoverBody } from "../Popover"
import { PrimaryButton, SecondaryButton } from "../Buttons"
import { getShowLocalTime } from "../../../store"

const CheckGroupWrapper = styled.div`
  display: flex;

  & > :first-child {
    flex-shrink: 0;
  }

  & > :last-child {
    flex-grow: 1;
  }
`

const ExpertSearchPopoverBody = styled(PopoverBody)`
  padding: 1rem;

  & label {
    margin-bottom: 0.25rem;
  }
`

type ExpertSearchPopoverProps = {
  isOpen: boolean
  search: ExpertSearch
  target: string
  firstTimestamp?: string
  lastTimestamp?: string
  onOK: (search: ExpertSearch) => void
  onCancel: () => void
}

const ExpertSearchPopover: React.FC<ExpertSearchPopoverProps> = ({
  isOpen,
  search,
  target,
  firstTimestamp,
  lastTimestamp,
  onOK,
  onCancel,
}) => {
  const [isClientAddressEnabled, setClientAddressEnabled] = React.useState<boolean>(
    search.clientAddress.length > 0
  )
  const [isClientPortEnabled, setClientPortEnabled] = React.useState<boolean>(
    search.clientPort.length > 0
  )
  const [isServerAddressEnabled, setServerAddressEnabled] = React.useState<boolean>(
    search.serverAddress.length > 0
  )
  const [isServerPortEnabled, setServerPortEnabled] = React.useState<boolean>(
    search.serverPort.length > 0
  )
  const [isProtocolEnabled, setProtocolEnabled] = React.useState<boolean>(
    search.protocol.length > 0
  )
  const [isApplicationEnabled, setApplicationEnabled] = React.useState<boolean>(
    search.application.length > 0
  )
  const [isMPLSEnabled, setMPLSEnabled] = React.useState<boolean>(search.mpls.length > 0)
  const [isVLANEnabled, setVLANEnabled] = React.useState<boolean>(search.vlan.length > 0)
  const [isVXLANGPIDEnabled, setVXLANGPIDEnabled] = React.useState<boolean>(
    search.vxlanGPID.length > 0
  )
  const [isVXLANVNIEnabled, setVXLANVNIEnabled] = React.useState<boolean>(
    search.vxlanVNI.length > 0
  )
  const [isTimeRangeEnabled, setTimeRangeEnabled] = React.useState<boolean>(
    search.start.length > 0 && search.finish.length > 0
  )

  const [clientAddress, setClientAddress] = React.useState<string>(search.clientAddress)
  const [clientPort, setClientPort] = React.useState<string>(search.clientPort)
  const [serverAddress, setServerAddress] = React.useState<string>(search.serverAddress)
  const [serverPort, setServerPort] = React.useState<string>(search.serverPort)
  const [protocol, setProtocol] = React.useState<string>(search.protocol)
  const [application, setApplication] = React.useState<string>(search.application)
  const [mpls, setMPLS] = React.useState<string>(search.mpls)
  const [vlan, setVLAN] = React.useState<string>(search.vlan)
  const [vxlanGPID, setVXLANGPID] = React.useState<string>(search.vxlanGPID)
  const [vxlanVNI, setVXLANVNI] = React.useState<string>(search.vxlanVNI)
  const [start, setStart] = React.useState<string>(
    search.start || firstTimestamp || new Date().toISOString()
  )
  const [finish, setFinish] = React.useState<string>(
    search.finish || lastTimestamp || new Date().toISOString()
  )

  const showLocalTime = useSelector(getShowLocalTime)

  return (
    <Popover placement="bottom" isOpen={isOpen} target={target} fade={false}>
      <ExpertSearchPopoverBody>
        <FormGroup>
          <Label for="search-client-address" style={{ marginLeft: "1.5rem" }}>
            Client Address
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search-client-address"
              checked={isClientAddressEnabled}
              onChange={() => setClientAddressEnabled(!isClientAddressEnabled)}
            />
            <Input
              type="text"
              id="search-client-address"
              disabled={!isClientAddressEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setClientAddress(event.target.value)
              }}
              value={clientAddress}
            />
          </CheckGroupWrapper>
        </FormGroup>
        <FormGroup>
          <Label for="search-client-port" style={{ marginLeft: "1.5rem" }}>
            Client Port
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search-client-port"
              checked={isClientPortEnabled}
              onChange={() => setClientPortEnabled(!isClientPortEnabled)}
            />
            <Input
              type="text"
              id="search-client-port"
              disabled={!isClientPortEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setClientPort(event.target.value)
              }}
              value={clientPort}
            />
          </CheckGroupWrapper>
        </FormGroup>
        <FormGroup>
          <Label for="search-server-address" style={{ marginLeft: "1.5rem" }}>
            Server Address
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search-server-address"
              checked={isServerAddressEnabled}
              onChange={() => setServerAddressEnabled(!isServerAddressEnabled)}
            />
            <Input
              type="text"
              id="search-server-address"
              disabled={!isServerAddressEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setServerAddress(event.target.value)
              }}
              value={serverAddress}
            />
          </CheckGroupWrapper>
        </FormGroup>
        <FormGroup>
          <Label for="search-server-port" style={{ marginLeft: "1.5rem" }}>
            Server Port
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search-server-port"
              checked={isServerPortEnabled}
              onChange={() => setServerPortEnabled(!isServerPortEnabled)}
            />
            <Input
              type="text"
              id="search-server-port"
              disabled={!isServerPortEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setServerPort(event.target.value)
              }}
              value={serverPort}
            />
          </CheckGroupWrapper>
        </FormGroup>
        <FormGroup>
          <Label for="search-protocol" style={{ marginLeft: "1.5rem" }}>
            Protocol
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search-protocol"
              checked={isProtocolEnabled}
              onChange={() => setProtocolEnabled(!isProtocolEnabled)}
            />
            <Input
              type="text"
              id="search-protocol"
              disabled={!isProtocolEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setProtocol(event.target.value)
              }}
              value={protocol}
            />
          </CheckGroupWrapper>
        </FormGroup>
        <FormGroup>
          <Label for="search-application" style={{ marginLeft: "1.5rem" }}>
            Application
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search-application"
              checked={isApplicationEnabled}
              onChange={() => setApplicationEnabled(!isApplicationEnabled)}
            />
            <Input
              type="text"
              id="search-application"
              disabled={!isApplicationEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setApplication(event.target.value)
              }}
              value={application}
            />
          </CheckGroupWrapper>
        </FormGroup>
        <FormGroup>
          <Label for="search-mpls" style={{ marginLeft: "1.5rem" }}>
            MPLS
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search-mpls"
              checked={isMPLSEnabled}
              onChange={() => setMPLSEnabled(!isMPLSEnabled)}
            />
            <Input
              type="text"
              id="search-mpls"
              disabled={!isMPLSEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMPLS(event.target.value)
              }}
              value={mpls}
            />
          </CheckGroupWrapper>
        </FormGroup>
        <FormGroup>
          <Label for="search-vlan" style={{ marginLeft: "1.5rem" }}>
            VLAN
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search-vlan"
              checked={isVLANEnabled}
              onChange={() => setVLANEnabled(!isVLANEnabled)}
            />
            <Input
              type="text"
              id="search-vlan"
              disabled={!isVLANEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setVLAN(event.target.value)
              }}
              value={vlan}
            />
          </CheckGroupWrapper>
        </FormGroup>
        <FormGroup>
          <Label for="search-vxlan-gpid" style={{ marginLeft: "1.5rem" }}>
            VXLAN GPID
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search-vxlan-gpid"
              checked={isVXLANGPIDEnabled}
              onChange={() => setVXLANGPIDEnabled(!isVXLANGPIDEnabled)}
            />
            <Input
              type="text"
              id="search-vxlan-gpid"
              disabled={!isVXLANGPIDEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setVXLANGPID(event.target.value)
              }}
              value={vxlanGPID}
            />
          </CheckGroupWrapper>
        </FormGroup>
        <FormGroup>
          <Label for="search-vxlan-vni" style={{ marginLeft: "1.5rem" }}>
            VXLAN VNI
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search-vxlan-vni"
              checked={isVXLANVNIEnabled}
              onChange={() => setVXLANVNIEnabled(!isVXLANVNIEnabled)}
            />
            <Input
              type="text"
              id="search-vxlan-vni"
              disabled={!isVXLANVNIEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setVXLANVNI(event.target.value)
              }}
              value={vxlanVNI}
            />
          </CheckGroupWrapper>
        </FormGroup>
        <FormGroup>
          <CheckGroupWrapper>
            <div style={{ paddingTop: "2rem" }}>
              <CheckGroup
                type="checkbox"
                id="search-time-range-enabled"
                checked={isTimeRangeEnabled}
                onChange={() => setTimeRangeEnabled(!isTimeRangeEnabled)}
              />
            </div>
            <div>
              <FormGroup>
                <Label for="search-start">Start{showLocalTime ? "" : " (UTC)"}</Label>
                <DateTimePicker
                  name="search-start"
                  id="search-start"
                  disabled={!isTimeRangeEnabled}
                  selected={new Date(start)}
                  onChange={(date: Date | null) => {
                    if (date != null) {
                      setStart(date.toISOString())
                    }
                  }}
                  showLocalTime={showLocalTime}
                />
              </FormGroup>
              <FormGroup noMargin>
                <Label for="search-finish">Finish{showLocalTime ? "" : " (UTC)"}</Label>
                <DateTimePicker
                  name="search-finish"
                  id="search-finish"
                  disabled={!isTimeRangeEnabled}
                  selected={new Date(finish)}
                  onChange={(date: Date | null) => {
                    if (date != null) {
                      setFinish(date.toISOString())
                    }
                  }}
                  showLocalTime={showLocalTime}
                />
              </FormGroup>
            </div>
          </CheckGroupWrapper>
        </FormGroup>
        <ControlStrip justify="flex-end" style={{ paddingLeft: "1.5rem" }}>
          <SecondaryButton size="sm" onClick={() => onCancel()}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            size="sm"
            onClick={() =>
              onOK({
                clientAddress: isClientAddressEnabled ? clientAddress : "",
                clientPort: isClientPortEnabled ? clientPort : "",
                serverAddress: isServerAddressEnabled ? serverAddress : "",
                serverPort: isServerPortEnabled ? serverPort : "",
                protocol: isProtocolEnabled ? protocol : "",
                application: isApplicationEnabled ? application : "",
                mpls: isMPLSEnabled ? mpls.replace(/[^\d\s]/g, "") : "",
                vlan: isVLANEnabled ? vlan.replace(/[^\d\s]/g, "") : "",
                vxlanGPID: isVXLANGPIDEnabled ? vxlanGPID.replace(/[^\d\s]/g, "") : "",
                vxlanVNI: isVXLANVNIEnabled ? vxlanVNI.replace(/[^\d\s]/g, "") : "",
                start: isTimeRangeEnabled ? start : "",
                finish: isTimeRangeEnabled ? finish : "",
              })
            }
          >
            Search
          </PrimaryButton>
        </ControlStrip>
      </ExpertSearchPopoverBody>
    </Popover>
  )
}

export default ExpertSearchPopover
