import * as React from "react"
import styled, { useTheme } from "styled-components"
import { useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router-dom"
import FontAwesome from "react-fontawesome"
import { cloneDeep } from "lodash"
import ExpertSettingsModal, {
  eventFinderViews,
  expertResponseToExpertDescriptions,
  expertResponseToExpertLayers,
  expertResponseToExpertSettings,
  ExpertSettingsEx,
  expertSettingsToExpertRequests,
  queryEventFinder,
} from "../ExpertSettingsModal"
import { IconButton, LightButton } from "../common/Buttons"
import { Table } from "../common/Table"
import { formatExpertValue, getExpertValueFromRowData } from "../../utils/expertUtils"
import { formatInteger } from "../../utils/formatUtils"
import { expertExecuteCFS, expertQueryCFS } from "../../api/api"
import {
  CaptureProperties,
  ExpertDescription,
  ExpertLayerMapping,
  ExpertQueryResponse,
  ExpertSettings,
  ExpertValueItem,
  ForensicSearchProperties,
  RequestExpertExecute,
  RequestExpertQuery,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { getEngine, getAuthToken, getShowLocalTime } from "../../store"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { ExpertColumn, ExpertView } from "../../api/types/expertTypes"

const FlowDetailsContent = styled.div`
  padding: 8px;

  & > * + * {
    margin-top: 1rem;
  }
`

const DetailsTable = styled(Table)`
  & > tbody > tr > th,
  & > thead > tr > th {
    text-align: right;
    white-space: nowrap;
    color: ${props => props.theme.propTableHeaderColor};
    font-size: ${props => props.theme.propTableHeaderFontSize};
    line-height: 1.5rem;
    text-transform: ${props => props.theme.propTableHeaderTextTransform};
  }

  & > tbody > tr > th:nth-child(1) {
    width: 1%;
  }

  & > tbody > tr > td {
    word-break: break-all;
    text-align: right;
  }

  & > tbody > tr > td.break-normal {
    word-break: normal;
  }
`

const EventSummaryTable = styled(Table)`
  & th {
    font-weight: bold;
    color: ${props => props.theme.propTableHeaderColor};
    font-size: ${props => props.theme.propTableHeaderFontSize};
    line-height: 1.5rem;
    text-transform: ${props => props.theme.propTableHeaderTextTransform};
  }

  & th:nth-child(1),
  & td:nth-child(1) {
    width: 16px;
  }

  & th:nth-child(4),
  & td:nth-child(4) {
    text-align: right;
  }
`

const TableTitle = styled.h5`
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
  text-transform: uppercase;
  display: none;
`

type ExpertDetailsProps = {
  captureProperties: CaptureProperties | ForensicSearchProperties | null
  engineCapabilities: ResponseGetEngineCapabilities | null
  resultSet: ExpertQueryResponse | null
  resultSetIndex?: number
  rowListIndex?: number
  type: string
  userId: string
}

const ExpertDetails = ({
  captureProperties,
  engineCapabilities,
  resultSet,
  resultSetIndex = 0,
  rowListIndex = 0,
  type,
  userId,
}: ExpertDetailsProps) => {
  const [expertDescriptions, setExpertDescriptions] = React.useState<ExpertDescription[]>([])
  const [expertLayers, setExpertLayers] = React.useState<ExpertLayerMapping[]>([])
  const [expertSettings, setExpertSettings] = React.useState<ExpertSettingsEx | null>(null)
  const match = useRouteMatch()
  const engine = useSelector(getEngine)
  const authToken = useSelector(getAuthToken)
  const [showProblemId, setShowProblemId] = React.useState<number | null>(null)
  const [showExpertSettingsModal, setShowExpertSettingsModal] = React.useState(false)
  const history = useHistory()
  const theme = useTheme()
  const showLocalTime = useSelector(getShowLocalTime)
  if (!resultSet || !resultSet.results) return null
  const results = resultSet.results[resultSetIndex]
  if (!results.rowList || !results.rowList.length) return null
  const columnList = results.columnList
  const rowData = results.rowList[rowListIndex]
  if (!rowData) return null

  const fmt = (columnId: ExpertColumn) => {
    const value = getExpertValueFromRowData(rowData, columnList, columnId)
    if (value != null && value.value != null) {
      const formatted = formatExpertValue(
        columnId,
        value.value,
        value.rendered,
        rowData,
        columnList,
        showLocalTime
      )
      if (
        formatted != null &&
        theme.name === "Light" &&
        value.color != null &&
        value.color !== "#000000"
      ) {
        return (
          <span title={String(formatted)} style={{ color: value.color }}>
            {formatted}
          </span>
        )
      } else {
        return formatted
      }
    }
    return undefined
  }

  const fmtAvgSize = (bytesColumnId: ExpertColumn, packetsColumnId: ExpertColumn) => {
    const bytes = getExpertValueFromRowData(rowData, columnList, bytesColumnId)
    if (bytes && bytes.value) {
      const packets = getExpertValueFromRowData(rowData, columnList, packetsColumnId)
      if (packets && packets.value) {
        return formatInteger((bytes.value as number) / (packets.value as number))
      }
    }
    return undefined
  }

  const flowId = getExpertValueFromRowData(
    rowData,
    columnList,
    ExpertColumn.EXPERT_COLUMN_STREAM_ID
  )

  let eventRows
  const eventCounts = getExpertValueFromRowData(
    rowData,
    columnList,
    ExpertColumn.EXPERT_COLUMN_PROBLEM_SUMMARY_LIST
  )
  if (eventCounts && Array.isArray(eventCounts.value)) {
    ;(eventCounts.value as ExpertValueItem[]).sort((a: ExpertValueItem, b: ExpertValueItem) => {
      if (a.value < b.value) return 1
      if (a.value > b.value) return -1
      return 0
    })
    eventRows = (eventCounts.value as ExpertValueItem[]).map((event: ExpertValueItem) => (
      <tr key={event.problemId}>
        <td>
          {formatExpertValue(
            ExpertColumn.EXPERT_COLUMN_EVENT_SEVERITY_MAX,
            event.severity,
            null,
            null,
            null,
            showLocalTime
          )}
        </td>
        <td>{event.layer}</td>
        <td>
          <div style={{ display: "flex", alignItems: "center" }}>
            {event.problem}
            <IconButton
              aria-label="Details"
              onClick={clickEvent => {
                clickEvent.stopPropagation()
                if (event) {
                  const query: RequestExpertQuery = {
                    query: eventFinderViews.map((view: ExpertView) => {
                      return { ...cloneDeep(queryEventFinder), view }
                    }),
                  }

                  const { type, capId } = match.params as any
                  expertQueryCFS(engine, authToken, type, capId, query)
                    .then((resultSet: ExpertQueryResponse) => {
                      if (Array.isArray(resultSet.results)) {
                        const expertDescriptions: ExpertDescription[] =
                          expertResponseToExpertDescriptions(resultSet.results)
                        const expertLayers: ExpertLayerMapping[] = expertResponseToExpertLayers(
                          resultSet.results
                        )
                        const expertSettings: ExpertSettingsEx | null =
                          expertResponseToExpertSettings(resultSet.results)

                        if (expertSettings) {
                          setExpertDescriptions(expertDescriptions)
                          setExpertLayers(expertLayers)
                          setExpertSettings(expertSettings)
                          setShowProblemId(event.problemId)
                          setShowExpertSettingsModal(true)
                        }
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              }}
            >
              <FontAwesome name="external-link" fixedWidth style={{ paddingLeft: "1em" }} />
            </IconButton>
          </div>
        </td>
        <td>{formatInteger(event.value)}</td>
      </tr>
    ))
  }

  const onExpertSettingsCancel = () => {
    setExpertDescriptions([])
    setExpertLayers([])
    setExpertSettings(null)
    setShowProblemId(null)
    setShowExpertSettingsModal(false)
  }

  const onExpertSettingsOK = (settings: ExpertSettings) => {
    const request: RequestExpertExecute = {
      execute: expertSettingsToExpertRequests(settings),
    }

    const { type, capId } = match.params as any
    expertExecuteCFS(engine, authToken, type, capId, request)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        setExpertDescriptions([])
        setExpertLayers([])
        setExpertSettings(null)
        setShowProblemId(null)
        setShowExpertSettingsModal(false)
      })
  }

  // make sure the user can modify captures
  let canModifyCapture = true
  if (captureProperties && engineCapabilities) {
    const isUserOwner = userId === captureProperties.creatorSID
    const policies = engineCapabilities.userRights.policies
    canModifyCapture = isUserOwner || policies.includes(EngineUserPolicies.modifyCaptures)
  }

  return (
    <FlowDetailsContent>
      {flowId != null && typeof flowId.value === "number" && captureProperties !== null ? (
        <div>
          <LightButton
            disabled={!captureProperties.packetBufferEnabled || !captureProperties.expertEnabled}
            onClick={() => history.push(`flow-visualizer/${flowId.value}`)}
          >
            Flow Visualizer
          </LightButton>
        </div>
      ) : null}

      <div>
        <TableTitle>Client Server</TableTitle>
        <DetailsTable size="sm">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Client</th>
              <th>Server</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Address</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS)}</td>
            </tr>
            <tr>
              <th>Port</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_PORT)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_PORT)}</td>
            </tr>
            <tr>
              <th>Packets Sent</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT)}</td>
            </tr>
            <tr>
              <th>Bytes Sent</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_BYTE_COUNT)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_SENT_BYTE_COUNT)}</td>
            </tr>
            <tr>
              <th>Avg. Size (Bytes)</th>
              <td>
                {fmtAvgSize(
                  ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_BYTE_COUNT,
                  ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT
                )}
              </td>
              <td>
                {fmtAvgSize(
                  ExpertColumn.EXPERT_COLUMN_SERVER_SENT_BYTE_COUNT,
                  ExpertColumn.EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT
                )}
              </td>
            </tr>
            <tr>
              <th>First Packet Time</th>
              <td className="break-normal">{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_START_TIME)}</td>
              <td className="break-normal">{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_START_TIME)}</td>
            </tr>
            <tr>
              <th>Last Packet Time</th>
              <td className="break-normal">{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_END_TIME)}</td>
              <td className="break-normal">{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_END_TIME)}</td>
            </tr>
            <tr>
              <th>Routed Hops</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_HOP_COUNT)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_HOP_COUNT)}</td>
            </tr>
            <tr>
              <th>TCP Min Window</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_TCP_WINDOW_MIN)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_TCP_WINDOW_MIN)}</td>
            </tr>
            <tr>
              <th>TCP Max Window</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_TCP_WINDOW_MAX)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_TCP_WINDOW_MAX)}</td>
            </tr>
            <tr>
              <th>Network Delay</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_NETWORK_DELAY)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_NETWORK_DELAY)}</td>
            </tr>
          </tbody>
        </DetailsTable>
      </div>

      <div>
        <TableTitle>Details</TableTitle>
        <DetailsTable size="sm">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>TCP Status</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_TCP_STATUS)}</td>
            </tr>
            <tr>
              <th>3-Way Handhsake</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_THREE_WAY_HANDSHAKE_TIME)}</td>
            </tr>
            <tr>
              <th>TLS Version</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_TLS_VERSION)}</td>
            </tr>
            <tr>
              <th>TLS Cert Not Before</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_BEFORE)}</td>
            </tr>
            <tr>
              <th>TLS Cert Not After</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_AFTER)}</td>
            </tr>
            <tr>
              <th>TLS Handshake (sec)</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_TLS_HANDSHAKE_LENGTH)}</td>
            </tr>
            <tr>
              <th>MPLS</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_MPLS)}</td>
            </tr>
            <tr>
              <th>VLAN</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VLAN)}</td>
            </tr>
            <tr>
              <th>VXLAN Group Policy ID</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VXLAN_GPID)}</td>
            </tr>
            <tr>
              <th>VXLAN VNI</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VXLAN_VNI)}</td>
            </tr>
          </tbody>
        </DetailsTable>
      </div>

      <div>
        <TableTitle>Latency &amp; Throughput</TableTitle>
        <DetailsTable size="sm">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Best</th>
              <th>Worst</th>
              <th>Average</th>
              <th>Turns</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Network Latency (sec)</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_NET_LATENCY_BEST)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_NET_LATENCY_WORST)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_NET_LATENCY)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_NET_LATENCY_SAMPLE_COUNT)}</td>
            </tr>
            <tr>
              <th>Application Latency (sec)</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_APP_LATENCY_BEST)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_APP_LATENCY_WORST)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_APP_LATENCY)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_APP_LATENCY_SAMPLE_COUNT)}</td>
            </tr>
            <tr>
              <th>Response Time (sec)</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_DELAY_BEST)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_DELAY_WORST)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_DELAY)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_DELAY_SAMPLE_COUNT)}</td>
            </tr>
            <tr>
              <th>C &rarr; S bps</th>
              <td>
                {fmt(ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_BEST)}
              </td>
              <td>
                {fmt(ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_WORST)}
              </td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_SAMPLE_COUNT)}</td>
            </tr>
            <tr>
              <th>S &rarr; C bps</th>
              <td>
                {fmt(ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_BEST)}
              </td>
              <td>
                {fmt(ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_WORST)}
              </td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND)}</td>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_SAMPLE_COUNT)}</td>
            </tr>
          </tbody>
        </DetailsTable>
      </div>

      <div>
        <TableTitle>Event Summary</TableTitle>
        <EventSummaryTable size="sm">
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Layer</th>
              <th>Event</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>{eventRows}</tbody>
        </EventSummaryTable>
      </div>

      {showExpertSettingsModal && expertSettings && showProblemId !== null && (
        <ExpertSettingsModal
          expertDescriptions={expertDescriptions}
          expertLayers={expertLayers}
          expertSettings={expertSettings.settings}
          initialExpertId={showProblemId}
          maxStreamCountMax={expertSettings.maxStreamCountMax}
          readOnly={type !== "captures" || !canModifyCapture}
          type={type}
          onCancel={onExpertSettingsCancel}
          onOK={onExpertSettingsOK}
          engine={engine}
          authToken={authToken}
        />
      )}
    </FlowDetailsContent>
  )
}

export default ExpertDetails
