import * as React from "react"
import { cloneDeep, toNumber } from "lodash"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { ButtonGroup, Col, FormGroup, Row } from "reactstrap"
import { CheckGroup, FormView, FieldSet, HorizontalFormGroup, Legend } from "../common/Form"
import { ControlStrip } from "../common/Layout"
import { Input } from "../common/Input"
import { LightButton, LightDangerButton } from "../common/Buttons"
import { MutedText } from "../common/MutedText"
import { Table } from "../common/Table"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import { formatFloat } from "../../utils/formatUtils"
import {
  MSAProjectProperties,
  MSAProjectMappingProfile,
  MSAProjectOptions,
  MSAProjectSegment,
} from "../../api/types"
import { renumberSegments } from "./utils"
import SegmentModal from "./SegmentModal"

const SegmentsGroup = styled.div`
  & > * + * {
    margin-top: 0.5rem;
  }
`

const SegmentsTable = styled(Table)`
  & th:nth-child(1),
  & td:nth-child(1) {
    width: 3%;
    text-align: center;
  }

  & th:nth-child(2),
  & td:nth-child(2) {
    width: 37%;
  }

  & th:nth-child(3),
  & td:nth-child(3),
  & th:nth-child(4),
  & td:nth-child(4) {
    width: 11%;
  }

  & th:nth-child(5),
  & td:nth-child(5) {
    width: 19%;
  }

  & th:nth-child(6),
  & td:nth-child(6) {
    width: 19%;
    text-align: center;
  }
`

const SegmentName = styled.span`
  font-weight: 500;
`

type ProjectOptionsFormProps = {
  formId: string
  project: MSAProjectProperties
  onSubmit: (project: MSAProjectProperties) => void
}

const ProjectOptionsForm = ({ formId, project, onSubmit }: ProjectOptionsFormProps) => {
  const [name, setName] = React.useState(project.name)
  const [notes, setNotes] = React.useState(project.notes)
  const [segments, setSegments] = React.useState<MSAProjectSegment[]>(cloneDeep(project.segments))
  const [mappingProfiles, setMappingProfiles] = React.useState<MSAProjectMappingProfile[]>(
    cloneDeep(project.mappingProfiles)
  )
  const [options, setOptions] = React.useState<MSAProjectOptions>(cloneDeep(project.options))
  const [editSegmentIndex, setEditSegmentIndex] = React.useState(-1)

  const getMappingProfileName = (id: number) => {
    const mappingProfile = mappingProfiles.find(mappingProfile => mappingProfile.id === id)
    return mappingProfile ? mappingProfile.name : "(none)"
  }

  const onAutoArrange = () => {
    const { flowEntries, segmentInfo } = project
    if (flowEntries != null && segmentInfo != null) {
      // Find a flow that has an entry for every segment and gather the
      // segment IDs and client TTL values.
      type SegmentTTL = {
        segmentId: number
        ttl: number
      }
      let vSegTTL: SegmentTTL[] = []
      for (const flowEntry of flowEntries) {
        vSegTTL = []
        if (flowEntry.flowList.length === segmentInfo.length) {
          for (let i = 0; i < flowEntry.flowList.length; i++) {
            const flowId = flowEntry.flowList[i]
            const flowInfo = segmentInfo[i].flows.find(flowInfo => flowInfo.id === flowId)
            if (flowInfo) {
              vSegTTL.push({ segmentId: segmentInfo[i].segmentId, ttl: flowInfo.clientTTL })
            }
          }
        }
        if (vSegTTL.length === segmentInfo.length) {
          break
        }
      }

      // Sort the segments by client TTL descending.
      const newSegments = cloneDeep(segments)
      newSegments.sort((a, b) => {
        const aSegTTL = vSegTTL.find(segTTL => segTTL.segmentId === a.id)
        const bSegTTL = vSegTTL.find(segTTL => segTTL.segmentId === b.id)
        if (aSegTTL && bSegTTL) {
          if (aSegTTL.ttl < bSegTTL.ttl) {
            return 1
          } else if (aSegTTL.ttl > bSegTTL.ttl) {
            return -1
          }
        }
        return 0
      })

      // Renumber the segments.
      renumberSegments(newSegments)

      // Set the segments.
      setSegments(newSegments)
    }
  }

  return (
    <FormView
      id={formId}
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        onSubmit({
          ...project,
          mappingProfiles,
          name,
          notes,
          options,
          segments,
        })
      }}
      noValidate
    >
      <FieldSet>
        <Legend>Name</Legend>
        <FormGroup>
          <Row>
            <Col>
              <Input
                type="text"
                name="name"
                id="name"
                aria-label="Name"
                autoComplete="off"
                value={name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setName(event.target.value)
                }}
              />
            </Col>
          </Row>
        </FormGroup>
      </FieldSet>

      <FieldSet>
        <Legend>Segments</Legend>
        <FormGroup>
          <Row>
            <Col>
              <SegmentsGroup>
                <MutedText as="div">
                  Arrange segments by the route taken by the packet from the client to the server
                </MutedText>
                <SegmentsTable>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>Name/File</th>
                      <th>Calc. Offset</th>
                      <th>Manual Offset</th>
                      <th>Mapping Profile</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {segments.map((seg, i) => (
                      <tr key={seg.id}>
                        <td>{i + 1}</td>
                        <td>
                          <SegmentName>{seg.name}</SegmentName>
                          <br />
                          <MutedText>{seg.file}</MutedText>
                        </td>
                        <td>
                          {options.syncMethod === "auto" && seg.calcOffsetCount > 0
                            ? formatFloat(seg.calcOffset / 1000000000, 6)
                            : "\u2014"}
                        </td>
                        <td>{formatFloat(seg.manualOffset / 1000000000, 6)}</td>
                        <td>{getMappingProfileName(seg.mappingProfileId)}</td>
                        <td>
                          <ButtonGroup>
                            <LightButton
                              size="sm"
                              aria-label="Move up"
                              id={`segment-move-up-${seg.id}`}
                              disabled={i === 0}
                              onClick={() => {
                                const newSegments = cloneDeep(segments)
                                const [moved] = newSegments.splice(i, 1)
                                newSegments.splice(i - 1, 0, moved)
                                renumberSegments(newSegments)
                                setSegments(newSegments)
                              }}
                            >
                              <FontAwesome name="arrow-up" fixedWidth />
                            </LightButton>
                            <UncontrolledTooltip
                              placement="top"
                              target={`segment-move-up-${seg.id}`}
                            >
                              Move Up
                            </UncontrolledTooltip>
                            <LightButton
                              size="sm"
                              aria-label="Move down"
                              id={`segment-move-down-${seg.id}`}
                              disabled={i + 1 === segments.length}
                              onClick={() => {
                                const newSegments = cloneDeep(segments)
                                const [moved] = newSegments.splice(i, 1)
                                newSegments.splice(i + 1, 0, moved)
                                renumberSegments(newSegments)
                                setSegments(newSegments)
                              }}
                            >
                              <FontAwesome name="arrow-down" fixedWidth />
                            </LightButton>
                            <UncontrolledTooltip
                              placement="top"
                              target={`segment-move-down-${seg.id}`}
                            >
                              Move Down
                            </UncontrolledTooltip>
                            <LightButton
                              size="sm"
                              aria-label="Edit"
                              id={`segment-edit-${seg.id}`}
                              onClick={() => {
                                setEditSegmentIndex(i)
                              }}
                            >
                              <FontAwesome name="pencil" fixedWidth />
                            </LightButton>
                            <UncontrolledTooltip placement="top" target={`segment-edit-${seg.id}`}>
                              Edit
                            </UncontrolledTooltip>
                            <LightDangerButton
                              size="sm"
                              aria-label="Delete"
                              id={`segment-delete-${seg.id}`}
                              onClick={() => {
                                const newSegments = cloneDeep(segments)
                                newSegments.splice(i, 1)
                                renumberSegments(newSegments)
                                setSegments(newSegments)
                              }}
                            >
                              <FontAwesome name="trash-o" fixedWidth />
                            </LightDangerButton>
                            <UncontrolledTooltip
                              placement="top"
                              target={`segment-delete-${seg.id}`}
                            >
                              Delete
                            </UncontrolledTooltip>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </SegmentsTable>
                <ControlStrip justify="flex-start">
                  <LightButton
                    size="sm"
                    disabled={
                      segments.length <= 1 ||
                      project.flowEntries == null ||
                      project.segmentInfo == null
                    }
                    onClick={onAutoArrange}
                  >
                    Auto Arrange
                  </LightButton>
                  <LightButton
                    size="sm"
                    disabled={segments.length === 0}
                    onClick={() => {
                      setSegments(
                        segments.map(segment => {
                          return { ...segment, manualOffset: 0 }
                        })
                      )
                    }}
                  >
                    Clear Manual Offsets
                  </LightButton>
                </ControlStrip>
              </SegmentsGroup>
            </Col>
          </Row>
        </FormGroup>
      </FieldSet>

      <FieldSet>
        <Legend>Synchronization</Legend>
        <FormGroup>
          <Row>
            <Col>
              <CheckGroup
                type="radio"
                id="syncMethodNone"
                name="syncMethodNone"
                onChange={() => {
                  setOptions({
                    ...options,
                    syncMethod: "none",
                  })
                }}
                checked={options.syncMethod === "none"}
              >
                Disable auto-synchronization
              </CheckGroup>
              <CheckGroup
                type="radio"
                id="syncMethodAuto"
                name="syncMethodAuto"
                onChange={() => {
                  setOptions({
                    ...options,
                    syncMethod: "auto",
                  })
                }}
                checked={options.syncMethod === "auto"}
              >
                Automatically calculate synchronization offsets
              </CheckGroup>
            </Col>
          </Row>
        </FormGroup>
      </FieldSet>

      <FieldSet>
        <Legend>Limits</Legend>
        <FormGroup>
          <Row>
            <Col>
              <HorizontalFormGroup noMargin>
                <CheckGroup
                  type="checkbox"
                  name="analysisPacketLimitEnabled"
                  id="analysisPacketLimitEnabled"
                  onChange={() => {
                    setOptions({
                      ...options,
                      analysisPacketLimitEnabled: !options.analysisPacketLimitEnabled,
                    })
                  }}
                  checked={options.analysisPacketLimitEnabled}
                >
                  Limit the number of packets analyzed per flow to
                </CheckGroup>
                <Input
                  style={{ width: "8rem" }}
                  type="number"
                  name="analysisPacketLimit"
                  id="analysisPacketLimit"
                  aria-label="Analysis packet limit"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setOptions({
                      ...options,
                      analysisPacketLimit: toNumber(event.target.value),
                    })
                  }}
                  value={options.analysisPacketLimit}
                  disabled={!options.analysisPacketLimitEnabled}
                  min={1}
                  step={1}
                />
              </HorizontalFormGroup>
            </Col>
          </Row>
        </FormGroup>
      </FieldSet>

      <FieldSet style={{ margin: 0 }}>
        <Legend>Notes</Legend>
        <FormGroup>
          <Row>
            <Col>
              <Input
                type="textarea"
                name="notes"
                id="notes"
                aria-label="Notes"
                value={notes}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNotes(event.target.value)
                }}
                spellCheck={false}
                rows={Math.max(Math.min(notes.split(/\r\n|\r|\n/).length, 10), 4)}
              />
            </Col>
          </Row>
        </FormGroup>
      </FieldSet>
      {editSegmentIndex !== -1 ? (
        <SegmentModal
          segment={segments[editSegmentIndex]}
          mappingProfiles={mappingProfiles}
          onOK={(segment: MSAProjectSegment, newMappingProfiles: MSAProjectMappingProfile[]) => {
            const newSegments = cloneDeep(segments)
            newSegments[editSegmentIndex] = segment
            setMappingProfiles(newMappingProfiles)
            setSegments(newSegments)
            setEditSegmentIndex(-1)
          }}
          onCancel={() => {
            setEditSegmentIndex(-1)
          }}
        />
      ) : null}
    </FormView>
  )
}

export default ProjectOptionsForm
