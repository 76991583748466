import * as React from "react"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { Alert } from "../common/Alert"
import { Input } from "../common/Input"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"

const Form = styled.form`
  margin: 1rem 0;

  & input,
  & button {
    width: 100%;
  }

  & > * + * {
    margin-top: 1rem;
  }
`

const FormFooter = styled.div`
  display: flex;

  & > * + * {
    margin-left: 0.5rem;
  }
`

type TwoFactorCodeProps = {
  onVerify: (code: string) => void
  onCancel: () => void
  authError: string | null
  authInProgress: boolean
}

const TwoFactorCode = ({ onVerify, onCancel, authError, authInProgress }: TwoFactorCodeProps) => {
  const [code, setCode] = React.useState("")

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onVerify(code)
  }

  return (
    <>
      <Form id="twoFactorVerifyForm" onSubmit={onSubmit} noValidate>
        <div>
          Enter the code from your two-factor app.
          <br />
          If you've lost your device, you may enter one of your recovery codes.
        </div>
        <Input
          type={"text"}
          id="otp"
          name="otp"
          placeholder="Two-factor code"
          autoComplete="one-time-code"
          autoFocus
          required
          inputMode="numeric"
          pattern="[0-9]{6}"
          value={code}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setCode(event.target.value)
          }}
        />
        <FormFooter>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton type="submit" id="twoFactorVerifyForm" disabled={code.length < 6}>
            <FontAwesome name={authInProgress ? "circle-o-notch" : "blank"} fixedWidth spin />{" "}
            Verify <FontAwesome name="blank" fixedWidth />
          </PrimaryButton>
        </FormFooter>
      </Form>
      {authError != null && (
        <Alert color="danger" className="text-center">
          {authError}
        </Alert>
      )}
    </>
  )
}

export default TwoFactorCode
