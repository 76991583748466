import * as React from "react"
import { useImmerReducer } from "use-immer"
import {
  EngineCaptureSessions,
  EngineConnection,
  SearchContext,
  SelectedCaptureSession,
} from "../types"

type State = {
  counter: number
  viewFilter: string
  searchContext: SearchContext
  engineFilter: string
  selectedEngines: string[]
  engineConnections: EngineConnection[]
  captureSessions: EngineCaptureSessions[]
  selectedCaptureSessions: SelectedCaptureSession[]
}

export const ON_NEW_SEARCH = "ON_NEW_SEARCH"
export const SET_VIEW_FILTER = "SET_VIEW_FILTER"
export const SET_SEARCH_CONTEXT = "SET_SEARCH_CONTEXT"
export const SET_ENGINE_FILTER = "SET_ENGINE_FILTER"
export const SET_SELECTED_ENGINES = "SET_SELECTED_ENGINES"
export const SET_ENGINE_CONNECTIONS = "SET_ENGINE_CONNECTIONS"
export const SET_CAPTURE_SESSIONS = "SET_CAPTURE_SESSIONS"
export const SET_SELECTED_CAPTURE_SESSIONS = "SET_SELECTED_CAPTURE_SESSIONS"

type Action =
  | { type: typeof ON_NEW_SEARCH }
  | { type: typeof SET_VIEW_FILTER; payload: string }
  | { type: typeof SET_SEARCH_CONTEXT; payload: SearchContext }
  | { type: typeof SET_ENGINE_FILTER; payload: string }
  | { type: typeof SET_SELECTED_ENGINES; payload: string[] }
  | { type: typeof SET_ENGINE_CONNECTIONS; payload: EngineConnection[] }
  | { type: typeof SET_CAPTURE_SESSIONS; payload: EngineCaptureSessions[] }
  | { type: typeof SET_SELECTED_CAPTURE_SESSIONS; payload: SelectedCaptureSession[] }

type Dispatch = (action: Action) => void

function initContext() {
  const endTime = new Date()
  const startTime = new Date(new Date().setDate(endTime.getDate() - 1))
  return {
    counter: 1,
    viewFilter: "",
    searchContext: {
      name: "Distributed Forensic Search 1",
      startTime,
      endTime,
      slopTime: 10,
      filter: "",
      sameCreds: false,
      sameUsername: "",
      samePassword: "",
      sameClientAuth: false,
      merge: false,
    },
    engineFilter: "",
    selectedEngines: [],
    engineConnections: [],
    captureSessions: [],
    selectedCaptureSessions: [],
  }
}

const StateContext = React.createContext<State | undefined>(undefined)

const DispatchContext = React.createContext<Dispatch | undefined>(undefined)

function reducer(draft: State, action: Action) {
  switch (action.type) {
    case ON_NEW_SEARCH:
      draft.counter += 1
      draft.searchContext.name = `Distributed Forensic Search ${draft.counter}`
      draft.engineConnections = []
      break
    case SET_VIEW_FILTER:
      draft.viewFilter = action.payload
      break
    case SET_SEARCH_CONTEXT:
      draft.searchContext = action.payload
      break
    case SET_ENGINE_FILTER:
      draft.engineFilter = action.payload
      break
    case SET_SELECTED_ENGINES:
      draft.selectedEngines = action.payload
      break
    case SET_ENGINE_CONNECTIONS:
      draft.engineConnections = action.payload
      break
    case SET_CAPTURE_SESSIONS:
      draft.captureSessions = action.payload
      break
    case SET_SELECTED_CAPTURE_SESSIONS:
      draft.selectedCaptureSessions = action.payload
      break
  }
}

export const DistributedForensicSearchProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [state, dispatch] = useImmerReducer<State, Action>(reducer, initContext())

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  )
}

export function useViewFilter() {
  const state = React.useContext(StateContext)
  if (state == null) {
    throw new Error("Missing provider")
  }
  return state.viewFilter
}

export function useSearchContext() {
  const state = React.useContext(StateContext)
  if (state == null) {
    throw new Error("Missing provider")
  }
  return state.searchContext
}

export function useEngineFilter() {
  const state = React.useContext(StateContext)
  if (state == null) {
    throw new Error("Missing provider")
  }
  return state.engineFilter
}

export function useSelectedEngines() {
  const state = React.useContext(StateContext)
  if (state == null) {
    throw new Error("Missing provider")
  }
  return state.selectedEngines
}

export function useEngineConnections() {
  const state = React.useContext(StateContext)
  if (state == null) {
    throw new Error("Missing provider")
  }
  return state.engineConnections
}

export function useCaptureSessions() {
  const state = React.useContext(StateContext)
  if (state == null) {
    throw new Error("Missing provider")
  }
  return state.captureSessions
}

export function useSelectedCaptureSessions() {
  const state = React.useContext(StateContext)
  if (state == null) {
    throw new Error("Missing provider")
  }
  return state.selectedCaptureSessions
}

export function useSearchDispatch() {
  const dispatch = React.useContext(DispatchContext)
  if (dispatch == null) {
    throw new Error("Missing provider")
  }
  return dispatch
}

export function onNewSearch(dispatch: Dispatch) {
  dispatch({ type: ON_NEW_SEARCH })
}

export function setViewFilter(dispatch: Dispatch, filter: string) {
  dispatch({ type: SET_VIEW_FILTER, payload: filter })
}

export function setSearchContext(dispatch: Dispatch, searchContext: SearchContext) {
  dispatch({ type: SET_SEARCH_CONTEXT, payload: searchContext })
}

export function setEngineFilter(dispatch: Dispatch, filter: string) {
  dispatch({ type: SET_ENGINE_FILTER, payload: filter })
}

export function setSelectedEngines(dispatch: Dispatch, selectedEngines: string[]) {
  dispatch({ type: SET_SELECTED_ENGINES, payload: selectedEngines })
}

export function setEngineConnections(dispatch: Dispatch, engineConnections: EngineConnection[]) {
  dispatch({ type: SET_ENGINE_CONNECTIONS, payload: engineConnections })
}

export function setCaptureSessions(dispatch: Dispatch, captureSessions: EngineCaptureSessions[]) {
  dispatch({ type: SET_CAPTURE_SESSIONS, payload: captureSessions })
}

export function setSelectedCaptureSessions(
  dispatch: Dispatch,
  selectedCaptureSessions: SelectedCaptureSession[]
) {
  dispatch({ type: SET_SELECTED_CAPTURE_SESSIONS, payload: selectedCaptureSessions })
}
