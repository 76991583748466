import * as React from "react"
import { Form, FormGroup } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../../common/Modal"
import { Input } from "../../common/Input"
import { Label } from "../../common/Form"
import { PrimaryButton, SecondaryButton } from "../../common/Buttons"
import { Dashboard } from "../../../api/types/dashboardTypes"

type DashboardModalProps = {
  dashboard: Dashboard | null
  onOK: (dashboard: Dashboard) => void
  onCancel: () => void
}

const DashboardModal = ({ dashboard, onOK, onCancel }: DashboardModalProps) => {
  const [name, setName] = React.useState(dashboard ? dashboard.name : "")
  const title = dashboard ? "Edit Dashboard" : "Add Dashboard"

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (dashboard) {
      onOK({
        ...dashboard,
        name,
      })
    } else {
      onOK({ id: "", name })
    }
  }

  return (
    <Modal isOpen={true} toggle={onCancel}>
      <ModalHeader toggle={onCancel}>{title}</ModalHeader>
      <ModalBody>
        <Form id="dashboardForm" onSubmit={handleSubmit} noValidate>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" form="dashboardForm">
          OK
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  )
}

export default DashboardModal
