import * as React from "react"
import { jwtDecode } from "jwt-decode"
import { useDispatch, useSelector } from "react-redux"
import styled, { createGlobalStyle, ThemeProvider } from "styled-components"
import useClickAway from "react-use/lib/useClickAway"
import useMount from "react-use/lib/useMount"
import { isNumber, isString } from "lodash"
import qs from "qs"
import { Switch, Route, Redirect, useHistory, useLocation } from "react-router-dom"
import Uploady, { Batch, BatchItem, CreateOptions } from "@rpldy/uploady"
import UploadDropZone, { GetFilesMethod } from "@rpldy/upload-drop-zone"
import UploadProgress from "../common/UploadProgress"
import { SidebarLeft as Sidebar, SidebarBodyLeft as SidebarBody } from "../common/Sidebar"
import BreadcrumbNav from "../BreadcrumbNav/BreadcrumbNav"
import IdleTimeout from "../common/IdleTimeout"
import Interval from "../common/Interval"
import ActivationView from "../ActivationView"
import AppSettings from "../AppSettings"
import AppSidebar from "../AppSidebar"
import Header from "../Header"
import EngineSession from "../EngineSession"
import DashboardView from "../MultiEngineDashboard/DashboardView"
import { DistributedForensicSearchProvider } from "../DistributedForensicSearch/Context"
import DistributedForensicSearchWizard from "../DistributedForensicSearch/Wizard"
import DistributedForensicSearchListView from "../DistributedForensicSearch/ListView"
import DistributedForensicSearchDetailView from "../DistributedForensicSearch/DetailView"
import EngineConfigurationSyncView from "../EngineConfigurationSyncView"
import ForensicSearchModal from "../ForensicSearchModal"
import MSAProjectListView from "../MSA/ProjectListView"
import MSAProjectView from "../MSA/ProjectView"
import MSAProjectOptionsView from "../MSA/ProjectOptionsView"
import NewMSAProjectView from "../MSA/NewProjectView"
import EnginesView from "../EnginesView"
import { createForensicSearch, fetchFilesList, getFileUploadURL, logInRefresh } from "../../api/api"
import {
  DatabaseRowGetFileList,
  RequestCreateForensicSearch,
  ResponseCreateForensicSearch,
  ResponseGetFileList,
} from "../../api/types"
import { PeekFilterMode } from "../../api/types/peekTypes"
import {
  getServer,
  getServerAuthToken,
  getServerRefreshToken,
  getServerVersion,
  getServerName,
  getServerLicensed,
  getServerLicenseExpired,
  getServerSerialNumber,
  getEnginesModificationTime,
  getEnginesUpdateCounter,
  getEnginesFetchInProgress,
  getEngines,
  getEngine,
  getAuthToken,
  getAuthInProgress,
  getCurrentTheme,
  getServerStatusUpdateCounter,
  getStatusUpdateCounter,
  getIdleTimeout,
  getIsNavOpen,
  AppDispatch,
} from "../../store"
import { fetchCapabilities } from "../../store/capabilities"
import { fetchEngines, setCurrentEngine } from "../../store/engines"
import { fetchStatus } from "../../store/status"
import {
  setAuthToken,
  setEngine,
  serverLogIn,
  serverLogOut,
  setServerAuthToken,
} from "../../store/auth"
import { setIsNavOpen } from "../../store/ui"
import {
  getEngineUrl,
  getEngineForensicsUrl,
  getEngineForensicSearchUrl,
  getLoginUrl,
  LOGIN_PATH,
  ACTIVATE_PATH,
  ENGINES_PATH,
  DASHBOARDS_PATH,
  DISTRIBUTED_FORENSIC_SEARCH_PATH,
  DISTRIBUTED_FORENSIC_SEARCHES_PATH,
  NEW_DISTRIBUTED_FORENSIC_SEARCH_PATH,
  MULTI_SEGMENT_ANALYSIS_PATH,
  MULTI_SEGMENT_ANALYSIS_PROJECT_PATH,
  MULTI_SEGMENT_ANALYSIS_PROJECT_OPTIONS_PATH,
  NEW_MULTI_SEGMENT_ANALYSIS_PATH,
  APP_SETTINGS_PATH,
  CROSS_LAUNCH_PATH,
  ENGINE_PATH,
  ENGINE_CONFIGURATION_SYNC_PATH,
} from "../../routes"
import {
  UPLOAD_PACKET_ACCEPT,
  UploadType,
  validPacketFileExtensions,
} from "../../utils/uploadUtils"

const GlobalStyles = createGlobalStyle`
  :root {
    color-scheme: ${props => (props.theme.name === "Light" ? "light" : "dark")};
  }
`

const AppRoot = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${props => props.theme.backgroundColor};
  color: ${props => props.theme.textColor};
`

const AppWrapper = styled(UploadDropZone)`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.outerPadding};
`

const AppInner = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  position: relative;
`

const AppContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`

export const App = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch: AppDispatch = useDispatch()
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const serverRefreshToken = useSelector(getServerRefreshToken)
  const serverVersion = useSelector(getServerVersion)
  const serverName = useSelector(getServerName)
  const serverSerialNumber = useSelector(getServerSerialNumber)
  const enginesModificationTime = useSelector(getEnginesModificationTime)
  const enginesUpdateCounter = useSelector(getEnginesUpdateCounter)
  const enginesUpdating = useSelector(getEnginesFetchInProgress)
  const engines = useSelector(getEngines)
  const engine = useSelector(getEngine)
  const authToken = useSelector(getAuthToken)
  const authInProgress = useSelector(getAuthInProgress)
  const theme = useSelector(getCurrentTheme)
  const licensed = useSelector(getServerLicensed)
  const licenseExpired = useSelector(getServerLicenseExpired)
  const statusUpdateCounter = useSelector(getStatusUpdateCounter)
  const serverStatusUpdateCounter = useSelector(getServerStatusUpdateCounter)
  const idleTimeout = useSelector(getIdleTimeout)
  const isNavOpen = useSelector(getIsNavOpen)
  const refSidebar = React.useRef(null)
  const [isStatusEnabled, setStatusEnabled] = React.useState(true)
  const [uploadType, setUploadType] = React.useState<UploadType>(UploadType.UPLOAD_TYPE_NONE)
  const [selectedFiles, setSelectedFiles] = React.useState<DatabaseRowGetFileList[]>([])
  const [showForensicSearchModal, setShowForensicSearchModal] = React.useState<boolean>(false)

  useMount(() => {
    if (location.search) {
      // Handle auto-login with query parameters
      const params = qs.parse(location.search, { ignoreQueryPrefix: true })
      if (isString(params.authToken)) {
        dispatch(setAuthToken(params.authToken))
      } else if (isString(params.username) && isString(params.password)) {
        const otp = isString(params.otp) ? params.otp : undefined
        dispatch(
          serverLogIn(server, {
            username: params.username,
            password: params.password,
            otp,
            tokenType: "Bearer",
          })
        )
      }
    }
  })

  const onRefreshServerStatus = React.useCallback(() => {
    if (serverAuthToken && server !== engine) {
      dispatch(fetchStatus(server, serverAuthToken, false, true))
    }
  }, [server, serverAuthToken, engine, dispatch])

  React.useEffect(() => {
    onRefreshServerStatus()
  }, [onRefreshServerStatus])

  const onRefreshEngineCapabilities = React.useCallback(() => {
    if (authToken && !authInProgress) {
      dispatch(fetchCapabilities(engine, authToken))
    }
  }, [engine, authToken, authInProgress, dispatch])

  const onRefreshEngineStatus = React.useCallback(() => {
    if (authToken && !authInProgress) {
      dispatch(fetchStatus(engine, authToken, true, engine === server))
    }
  }, [server, engine, authToken, authInProgress, dispatch])

  const onRefreshStatus = React.useCallback(() => {
    if (serverRefreshToken) {
      try {
        const decoded = jwtDecode(serverAuthToken)
        if (decoded.exp) {
          const now = Date.now()
          const exp = decoded.exp * 1000
          if (now + 60000 > exp) {
            setStatusEnabled(false)
            logInRefresh(server, serverAuthToken, { refreshToken: serverRefreshToken })
              .then(resp => {
                dispatch(
                  setServerAuthToken({
                    authToken: resp.authToken,
                    refreshToken: resp.refreshToken ?? serverRefreshToken,
                  })
                )
                if (authToken === serverAuthToken) {
                  dispatch(setAuthToken(resp.authToken))
                }
                setStatusEnabled(true)
              })
              .catch(() => {
                dispatch(setServerAuthToken({ authToken: null, refreshToken: null }))
              })
          } else {
            onRefreshServerStatus()
            onRefreshEngineStatus()
          }
        }
      } catch {}
    } else {
      onRefreshServerStatus()
      onRefreshEngineStatus()
    }
  }, [
    onRefreshServerStatus,
    onRefreshEngineStatus,
    server,
    serverAuthToken,
    serverRefreshToken,
    authToken,
    dispatch,
  ])

  React.useEffect(() => {
    onRefreshEngineCapabilities()
    onRefreshEngineStatus()
  }, [onRefreshEngineCapabilities, onRefreshEngineStatus])

  React.useEffect(() => {
    onRefreshEngineStatus()
  }, [statusUpdateCounter, onRefreshEngineStatus])

  React.useEffect(() => {
    onRefreshStatus()
  }, [serverStatusUpdateCounter, onRefreshStatus])

  // OD-1160 Peek web auto refresh
  React.useEffect(() => {
    if (
      import.meta.env.MODE === "production" &&
      serverVersion != null &&
      serverVersion.length !== 0 &&
      serverVersion !== import.meta.env.VITE_VERSION
    ) {
      localStorage.removeItem("acceptedTerms") // Show click-through EULA on upgrade (OD-4358)
      window.location.reload()
    }
  }, [serverVersion])

  React.useEffect(() => {
    // enginesModificationTime is null until the initial status
    // update, so wait until at least the first status is returned
    // and don't update if already in progress.
    if (serverAuthToken && enginesModificationTime != null && !enginesUpdating) {
      dispatch(fetchEngines(server, serverAuthToken))
    }
    // Disable the warning for enginesUpdating
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [server, serverAuthToken, enginesModificationTime, enginesUpdateCounter, dispatch])

  useClickAway(refSidebar, (event: any) => {
    if (event?.target?.id !== "header--sidebar-button") {
      dispatch(setIsNavOpen(false))
    }
  })

  const renderCrossLaunch = () => {
    if (engine !== server) {
      dispatch(setCurrentEngine(null))
      dispatch(setEngine({ engine: server, authToken: serverAuthToken }))
    }
    history.replace(getEngineForensicsUrl() + location.search)
    return null
  }

  // Redirect to the login page if the user is not logged in.
  if (!serverAuthToken) {
    // Remove any auth params that might be in the search params so they don't come back.
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete("authToken")
    searchParams.delete("username")
    searchParams.delete("password")
    const search = searchParams.toString()
    const next = location.pathname + (search.length !== 0 ? "?" + search : "")
    history.replace({ pathname: LOGIN_PATH, state: { next } })
    return null
  }

  const onIdle = () => {
    dispatch(serverLogOut(server, { authToken: serverAuthToken }))
  }

  // Build the app content.
  // Prevent the app from rendering until capabilities and status
  // are available. This could look bad if those 2 calls don't return
  // quickly but it also prevents some jumpy renders in some views.
  let content: React.ReactNode
  if (serverAuthToken && serverName) {
    if (!licensed || licenseExpired) {
      content = (
        <ActivationView
          engine={server}
          authToken={serverAuthToken}
          engineVersion={serverVersion ?? ""}
          serialNumber={serverSerialNumber}
          nextUrl={getLoginUrl()}
        />
      )
    } else if (engines != null) {
      content = (
        <>
          <BreadcrumbNav />
          <Switch>
            <Redirect exact path="/" to={getEngineUrl()} />
            <Route exact path={ACTIVATE_PATH} component={ActivationView} />
            <Route exact path={DASHBOARDS_PATH} component={DashboardView} />
            <Route
              exact
              path={NEW_DISTRIBUTED_FORENSIC_SEARCH_PATH}
              component={DistributedForensicSearchWizard}
            />
            <Route
              exact
              path={DISTRIBUTED_FORENSIC_SEARCH_PATH}
              component={DistributedForensicSearchDetailView}
            />
            <Route
              exact
              path={DISTRIBUTED_FORENSIC_SEARCHES_PATH}
              component={DistributedForensicSearchListView}
            />
            <Route
              exact
              path={ENGINE_CONFIGURATION_SYNC_PATH}
              component={EngineConfigurationSyncView}
            />
            <Route
              exact
              path={MULTI_SEGMENT_ANALYSIS_PROJECT_OPTIONS_PATH}
              component={MSAProjectOptionsView}
            />
            <Route exact path={NEW_MULTI_SEGMENT_ANALYSIS_PATH} component={NewMSAProjectView} />
            <Route exact path={MULTI_SEGMENT_ANALYSIS_PROJECT_PATH} component={MSAProjectView} />
            <Route exact path={MULTI_SEGMENT_ANALYSIS_PATH} component={MSAProjectListView} />
            <Route exact path={ENGINES_PATH} component={EnginesView} />
            <Route exact path={APP_SETTINGS_PATH} component={AppSettings} />
            <Route path={ENGINE_PATH}>
              <EngineSession />
            </Route>
            <Route exact path={CROSS_LAUNCH_PATH} render={renderCrossLaunch} />
            <Redirect to="/" />
          </Switch>
        </>
      )
    }
  }

  const onDropHandler = (e: DragEvent, getFiles: GetFilesMethod) => {
    setUploadType(UploadType.UPLOAD_TYPE_DRAG_DROP)
    return getFiles()
  }

  const onUploadStart = (batch: Batch, options: CreateOptions) => {
    if (uploadType === UploadType.UPLOAD_TYPE_DRAG_DROP) {
      // don't allow multiple files and make sure the file extension is one we support
      if (batch.items.length > 1 || !validPacketFileExtensions(batch)) {
        return false
      }
    }
    return
  }

  const onUploadFinish = (batchItem: BatchItem) => {
    if (uploadType === UploadType.UPLOAD_TYPE_DRAG_DROP) {
      fetchFilesList(engine, authToken)
        .then((files: ResponseGetFileList) => {
          const fileItem = files.rows.find(
            (row: DatabaseRowGetFileList) =>
              row.PartialPath && row.PartialPath.split(/(\\|\/)/g).pop() === batchItem.file.name
          )
          if (fileItem) {
            setSelectedFiles([fileItem])
            setShowForensicSearchModal(true)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const onUploadFinalize = (batch: Batch) => {
    setUploadType(UploadType.UPLOAD_TYPE_NONE)
  }

  const onForensicSearchCancel = () => {
    setSelectedFiles([])
    setShowForensicSearchModal(false)
  }

  const onForensicSearchOK = (query: RequestCreateForensicSearch) => {
    setSelectedFiles([])
    setShowForensicSearchModal(false)
    query.filterMode = query.filter
      ? PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_MATCHING_ANY
      : PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_ALL

    createForensicSearch(engine, authToken, query)
      .then((response: ResponseCreateForensicSearch) => {
        history.push(getEngineForensicSearchUrl(response.id))
      })
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <ThemeProvider theme={theme}>
      <DistributedForensicSearchProvider>
        <GlobalStyles />
        <Uploady
          accept={UPLOAD_PACKET_ACCEPT}
          destination={{
            url: getFileUploadURL(engine),
            headers: {
              authorization: `Token ${authToken}`,
            },
            method: "POST",
          }}
        >
          <AppRoot>
            <Interval timeout={10000} enabled={isStatusEnabled} callback={onRefreshStatus} />
            {isNumber(idleTimeout) && idleTimeout > 0 ? (
              <IdleTimeout idleTimeout={idleTimeout} onIdle={onIdle} />
            ) : null}
            <AppWrapper dropHandler={onDropHandler}>
              <Header />
              <AppInner>
                <Sidebar ref={refSidebar} open={isNavOpen} width="22rem">
                  <SidebarBody open={isNavOpen}>
                    <AppSidebar />
                  </SidebarBody>
                </Sidebar>
                <AppContent>{content}</AppContent>
              </AppInner>
            </AppWrapper>
            <UploadProgress
              start={onUploadStart}
              finish={onUploadFinish}
              finalize={onUploadFinalize}
              uploadType={uploadType}
            />
            {showForensicSearchModal && (
              <ForensicSearchModal
                onCancel={onForensicSearchCancel}
                onOK={onForensicSearchOK}
                captureSessionId={selectedFiles.length > 0 ? selectedFiles[0].SessionID : undefined}
                mediaType={selectedFiles.length > 0 ? selectedFiles[0].MediaType : undefined}
                mediaSubType={selectedFiles.length > 0 ? selectedFiles[0].MediaSubType : undefined}
                startTime={selectedFiles.length > 0 ? selectedFiles[0].SessionStartTime : undefined}
                endTime={
                  selectedFiles.length > 0
                    ? selectedFiles[selectedFiles.length - 1].SessionEndTime
                    : undefined
                }
                name={
                  selectedFiles.length > 0 && selectedFiles[0].FileName
                    ? selectedFiles[0].FileName.replace(/\.[^/.]+$/, "")
                    : undefined
                }
                file={selectedFiles.length > 0 ? selectedFiles[0].PartialPath : undefined}
              />
            )}
          </AppRoot>
        </Uploady>
      </DistributedForensicSearchProvider>
    </ThemeProvider>
  )
}

export default App
