import * as React from "react"

export const IconMinor = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 10240 10240"
    shapeRendering="geometricPrecision"
    height={16}
    width={16}
    {...props}
  >
    <path
      d="M5120 640c2474 0 4480 2006 4480 4480S7594 9600 5120 9600 640 7594 640 5120 2646 640 5120 640z"
      fill="#3d9b34"
      stroke="#1d42b2"
    />
    <path
      d="M4700 6720h853c118 0 213 96 213 214v853c0 117-95 213-213 213h-853c-117 0-213-96-213-213v-853c0-118 96-214 213-214zm-220-4480h1280c88 0 160 72 160 160v1059c0 273-20 505-68 773l-309 1736c-13 77-79 132-157 132h-532c-78 0-144-55-157-132l-309-1736c-48-268-68-500-68-773V2400c0-88 72-160 160-160z"
      fill="#fff"
    />
  </svg>
)
