import * as React from "react"
import { connect } from "react-redux"
import { cloneDeep } from "lodash"
import { withTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Label } from "recharts"
import ChartTooltip from "../../common/ChartTooltip"
import { ChartType } from "../../../api/types/chartTypes"
import { ChartComponents } from "../../../utils/chartComponents"
import {
  FullWidthWidget,
  WidgetHeader,
  WidgetTitle,
  WidgetSubTitle,
  WidgetOptions,
  WidgetBody,
  NoData,
} from "../Dashboard"
import { IconDropdownToggle } from "../../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../../common/Dropdown"
import {
  chartTypeSupportsSmoothing,
  formatChartTooltip,
  formatTimeChartTooltipLabel,
  formatTimeChartAxis,
  getChartColor,
} from "../../../utils/chartUtils"
import { formatSampleInterval } from "../../../utils/formatUtils"
import {
  setTrafficHistoryWidgetChartType,
  setTrafficHistoryWidgetChartScale,
  setTrafficHistoryWidgetChartInterpolation,
} from "../../../store/ui"
import {
  getTrafficHistoryWidgetChartType,
  getTrafficHistoryWidgetChartScale,
  getTrafficHistoryWidgetChartInterpolation,
  getShowLocalTime,
} from "../../../store"

type Props = {
  history: any | null
  chartType: ChartType
  chartScale: "linear" | "log"
  chartInterpolation: string
  showLocalTime: boolean
  theme: any
  setTrafficHistoryWidgetChartType: (chartType: ChartType) => void
  setTrafficHistoryWidgetChartScale: (chartScale: string) => void
  setTrafficHistoryWidgetChartInterpolation: (chartInterpolation: string) => void
}

class TrafficHistoryWidget extends React.Component<Props> {
  onChartType = (e: any) => {
    this.props.setTrafficHistoryWidgetChartType(e.target.name)
  }

  onChartScale = (e: any) => {
    this.props.setTrafficHistoryWidgetChartScale(e.target.name)
  }

  onChartSmoothing = () => {
    const chartInterpolation = this.props.chartInterpolation === "linear" ? "monotone" : "linear"
    this.props.setTrafficHistoryWidgetChartInterpolation(chartInterpolation)
  }

  render() {
    const { history, theme, chartType, chartScale, chartInterpolation } = this.props
    const chartComponent = ChartComponents[chartType]
    const Chart = chartComponent.chart
    const Series = chartComponent.series
    const supportsSmoothing = chartTypeSupportsSmoothing(chartType)
    const seriesProps = cloneDeep(chartComponent.seriesProps)
    if (seriesProps.dot) {
      seriesProps.dot.stroke = theme.panelBackground
    }
    if (seriesProps.activeDot) {
      seriesProps.activeDot.stroke = theme.panelBackground
    }
    if (supportsSmoothing) {
      seriesProps.type = chartInterpolation
    }
    return (
      <FullWidthWidget>
        <WidgetHeader>
          <WidgetTitle>
            Network Utilization
            {history && (
              <WidgetSubTitle>{`${formatSampleInterval(
                history.interval / 1000
              )} Average`}</WidgetSubTitle>
            )}
          </WidgetTitle>
          <WidgetOptions>
            <UncontrolledDropdown>
              <IconDropdownToggle aria-label="Settings">
                <FontAwesome name="gear" style={{ color: theme.textMutedColor }} />
              </IconDropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  name={ChartType.COLUMN}
                  active={chartType === ChartType.COLUMN}
                  onClick={this.onChartType}
                >
                  Column
                </DropdownItem>
                <DropdownItem
                  name={ChartType.OVERLAID_SKYLINE}
                  active={chartType === ChartType.OVERLAID_SKYLINE}
                  onClick={this.onChartType}
                >
                  Skyline
                </DropdownItem>
                <DropdownItem
                  name={ChartType.AREA}
                  active={chartType === ChartType.AREA}
                  onClick={this.onChartType}
                >
                  Area
                </DropdownItem>
                <DropdownItem
                  name={ChartType.LINE}
                  active={chartType === ChartType.LINE}
                  onClick={this.onChartType}
                >
                  Line
                </DropdownItem>
                <DropdownItem
                  name={ChartType.LINE_POINTS}
                  active={chartType === ChartType.LINE_POINTS}
                  onClick={this.onChartType}
                >
                  Line/Points
                </DropdownItem>
                {/*
                <DropdownItem divider />
                <DropdownItem
                  name="linear"
                  active={chartScale === "linear"}
                  onClick={this.onChartScale}
                >
                  Linear
                </DropdownItem>
                <DropdownItem
                  disabled
                  name="log"
                  active={chartScale === "log"}
                  onClick={this.onChartScale}
                >
                  Logarithmic
                </DropdownItem>
                */}
                <DropdownItem divider />
                <DropdownItem
                  name="smoothing"
                  active={chartInterpolation !== "linear"}
                  onClick={this.onChartSmoothing}
                  disabled={!supportsSmoothing}
                >
                  Smoothing
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </WidgetOptions>
        </WidgetHeader>
        <WidgetBody minHeight="240px">
          {history ? (
            <ResponsiveContainer height={240} width="100%">
              <Chart
                data={history.data}
                margin={{ top: 10, right: 32, left: 0, bottom: 0 }}
                {...chartComponent.chartProps}
              >
                <Tooltip
                  isAnimationActive={false}
                  labelFormatter={formatTimeChartTooltipLabel.bind(
                    this,
                    history.startTime,
                    history.interval,
                    this.props.showLocalTime
                  )}
                  formatter={formatChartTooltip}
                  content={<ChartTooltip />}
                />
                <CartesianGrid stroke={theme.chartGridColor} vertical={false} />
                <YAxis scale={chartScale} stroke={theme.textColor}>
                  <Label
                    value="Mbits/s"
                    fill={theme.textColor}
                    style={{ fontWeight: 600 }}
                    position="insideLeft"
                    angle={-90}
                    offset={8}
                    dy={14}
                  />
                </YAxis>
                <XAxis
                  stroke={theme.textColor}
                  tickFormatter={formatTimeChartAxis.bind(
                    this,
                    history.startTime,
                    history.interval,
                    this.props.showLocalTime
                  )}
                  height={22}
                />
                <Series
                  key="mbps"
                  dataKey="mbps"
                  name="noname"
                  unit="Mbits/s"
                  stroke={getChartColor(0)}
                  fill={getChartColor(0)}
                  {...seriesProps}
                />
              </Chart>
            </ResponsiveContainer>
          ) : (
            <NoData>No Data</NoData>
          )}
        </WidgetBody>
      </FullWidthWidget>
    )
  }
}

const mapStateToProps = (state: any) => ({
  chartType: getTrafficHistoryWidgetChartType(state) || ChartType.OVERLAID_SKYLINE,
  chartScale: getTrafficHistoryWidgetChartScale(state) || "linear",
  chartInterpolation: getTrafficHistoryWidgetChartInterpolation(state) || "linear",
  showLocalTime: getShowLocalTime(state),
})

const mapDisptachToProps = {
  setTrafficHistoryWidgetChartType,
  setTrafficHistoryWidgetChartScale,
  setTrafficHistoryWidgetChartInterpolation,
}

export default withTheme(connect(mapStateToProps, mapDisptachToProps)(TrafficHistoryWidget))
