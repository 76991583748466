import styled, { keyframes } from "styled-components"

export const Dot = styled.span<{ show: boolean }>`
  display: inline-block;
  width: 1.2857em;
  height: 0.6154em;
  position: relative;

  & ::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0.335em;
    width: 0.6154em;
    height: 0.6154em;
    background: ${props => props.theme.textColor};
    border-radius: 100%;
    visibility: ${props => (props.show ? "visible" : "hidden")};
  }
`

// This animated dot expands and contracts from it's middle,
// however the transform causes sibling element to shift around.

export const Dot1 = styled.span<{ show: boolean }>`
  display: inline-block;
  width: 1.2857em;
  height: 0.6154em;
  position: relative;

  & ::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0.335em;
    width: 0.6154em;
    height: 0.6154em;
    background: ${props => props.theme.textColor};
    border-radius: 100%;
    transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    transform: ${props => (props.show ? "scale(1)" : "scale(0)")};
  }
`

// Attempt to fix the jumping dot with backface-visiblity,
// but causes a 1-pixel shift in Chrome.

const DotWrapper = styled.span`
  backface-visibility: hidden;
`

export const DotOuter = styled.span`
  display: inline-block;
  width: 1.2857em;
  height: 0.6154em;
  position: relative;
`

export const DotInner = styled.div<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0.335em;
  width: 0.6154em;
  height: 0.6154em;
  background: ${props => props.theme.textColor};
  border-radius: 100%;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  transform: ${props => (props.show ? "scale(1)" : "scale(0)")};
`

export const Dot2 = ({ show }: { show: boolean }) => (
  <DotWrapper>
    <DotOuter>
      <DotInner show={show} />
    </DotOuter>
  </DotWrapper>
)

const blink = keyframes`
  50% {color: transparent;}
`
export const AnimatedDot = styled.span`
  animation: 1s ${blink} infinite;
  &:nth-child(1) {
    animation-delay: 0ms;
  }
  &:nth-child(2) {
    animation-delay: 250ms;
  }
  &:nth-child(3) {
    animation-delay: 500ms;
  }
`
