import * as React from "react"
import { cloneDeep, toNumber } from "lodash"
import styled, { useTheme } from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import FontAwesome from "react-fontawesome"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import useMeasure from "react-use/lib/useMeasure"
import { Alert } from "../common/Alert"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import { IconDropdownToggle } from "../common/Buttons"
import { ExpertTable, ExpertTableColumn } from "../common/ExpertTable"
import {
  addExpertColumns,
  getExpertColumnName,
  getExpertQuery,
  getExpertResults,
  getExpertValueFromRowData,
} from "../../utils/expertUtils"
import { setSelectPacketsTask } from "../../store/selectPackets"
import {
  getEngine,
  getAuthToken,
  getNamesModificationTime,
  getShowAddressNames,
  getShowPortNames,
  getVoIPCallVisualizerColumns,
  getVoIPCallVisualizerSortBy,
  getVoIPCallVisualizerSortDirection,
  getShowLocalTime,
} from "../../store"
import {
  setPacketsSelection,
  setPacketsDecodePacketNumber,
  setVoIPCallVisualizerColumns,
  setVoIPCallVisualizerSort,
} from "../../store/ui"
import { expertQueryCFS, postSelectRelatedExpertStart } from "../../api/api"
import {
  RequestExpertQuery,
  ExpertQueryResponse,
  ExpertValue,
  RequestPostSelectRelatedExpertStart,
  ResponsePostSelectRelatedExpertStart,
  ResultsSelectRelatedResponse,
} from "../../api/types"
import { ExpertColumn, ExpertView } from "../../api/types/expertTypes"
import * as VoIPTypes from "../../api/types/voipTypes"
import ThemeInterface from "../../themes/theme"

const defaultColumns: ExpertTableColumn[] = [
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER),
    width: 88,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SIGNALING_MSG_INDEX.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SIGNALING_MSG_INDEX),
    width: 88,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_TIME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_TIME),
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_RELATIVE_TIME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_RELATIVE_TIME),
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_END_TIME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_END_TIME),
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DURATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DURATION),
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_NAME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_NAME),
    width: 250,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
    className: "bounce",
    headerClassName: "bounce",
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CALL_ID.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CALL_ID),
    width: 88,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_FLOW_INDEX.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_FLOW_INDEX),
    width: 88,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS),
    width: 160,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SOURCE_PORT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SOURCE_PORT),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS),
    width: 160,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DEST_PORT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DEST_PORT),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_FROM.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_FROM),
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_TO.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_TO),
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_RESPONSE_CODE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_RESPONSE_CODE),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_RESPONSE_TEXT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_RESPONSE_TEXT),
    width: 200,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SEQUENCE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SEQUENCE),
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SEQUENCE_METHOD.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SEQUENCE_METHOD),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CODEC.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CODEC),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MEDIA_TYPE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MEDIA_TYPE),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SSRC.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SSRC),
    width: 80,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DSCP.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DSCP),
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MEDIA_PACKET_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MEDIA_PACKET_COUNT),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CONTROL_PACKET_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CONTROL_PACKET_COUNT),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_JITTER.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_JITTER),
    width: 88,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MOS_LQ.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MOS_LQ),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MOS_CQ.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MOS_CQ),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MOS_NOM.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MOS_NOM),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_R_FACTOR_LISTENING.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_R_FACTOR_LISTENING),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_R_FACTOR_CONVERSATIONAL.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_R_FACTOR_CONVERSATIONAL),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_R_FACTOR_G107.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_R_FACTOR_G107),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_R_FACTOR_NOMINAL.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_R_FACTOR_NOMINAL),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MOS_AV.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MOS_AV),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MOS_V.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MOS_V),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VSTQ.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VSTQ),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
]

const ViewWrapper = styled.div`
  position: relative;
  flex-grow: 1;

  & .signalingtable .ReactVirtualized__Table__rowColumn.bounce {
    padding-top: 0;
    padding-bottom: 0;
    height: inherit;
  }
`

const queryTemplate: RequestExpertQuery = {
  query: [
    {
      columnList: [],
      orderBy: [
        ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER,
        ExpertColumn.EXPERT_COLUMN_SIGNALING_MSG_INDEX,
      ],
      orderByAscending: true,
      view: ExpertView.EXPERT_VIEW_VOIP_SIGNALING,
      viewSettings: {
        la: "\u2190",
        ra: "\u2192",
        ba: "\u2194",
        showAddressNames: true,
        showPortNames: true,
      },
    },
    {
      columnList: [
        ExpertColumn.EXPERT_COLUMN_CALLER_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_GATEKEEPER_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_CALLEE_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_CALL_ID,
        ExpertColumn.EXPERT_COLUMN_FLOW_INDEX,
        ExpertColumn.EXPERT_COLUMN_START_TIME,
      ],
      orderBy: [ExpertColumn.EXPERT_COLUMN_START_TIME],
      orderByAscending: true,
      view: ExpertView.EXPERT_VIEW_VOIP_CALL_FLOW,
      viewSettings: {
        la: "\u2190",
        ra: "\u2192",
        ba: "\u2194",
        showAddressNames: true,
        showPortNames: true,
      },
    },
  ],
}

type RouteParams = {
  type: string
  capId: string
  callId: string
}

type SignalingViewProps = {
  canViewPackets: boolean
}

const SignalingView: React.FC<SignalingViewProps> = ({ canViewPackets }) => {
  const dispatch = useDispatch()
  const engine = useSelector(getEngine)
  const authToken = useSelector(getAuthToken)
  const namesModTime = useSelector(getNamesModificationTime)
  const showAddressNames = useSelector(getShowAddressNames)
  const showPortNames = useSelector(getShowPortNames)
  const columns: ExpertTableColumn[] = useSelector(getVoIPCallVisualizerColumns) || defaultColumns
  const sortBy: ExpertColumn =
    useSelector(getVoIPCallVisualizerSortBy) || ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER
  const sortDirection: SortDirectionType =
    useSelector(getVoIPCallVisualizerSortDirection) || SortDirection.ASC
  const { type, capId, callId } = useParams<RouteParams>()
  const history = useHistory()
  const [resultSet, setResultSet] = React.useState<ExpertQueryResponse | null>(null)
  const [nodes, setNodes] = React.useState<string[]>([])
  const [error, setError] = React.useState<any | null>(null)
  const [bounceHeaderRef, { width: bounceWidth }] = useMeasure<HTMLDivElement>()
  const showLocalTime = useSelector(getShowLocalTime)

  React.useEffect(() => {
    const request = cloneDeep(queryTemplate)

    // Set the columns in the query.
    const querySignaling = getExpertQuery(request.query, ExpertView.EXPERT_VIEW_VOIP_SIGNALING)
    const queryCallFlows = getExpertQuery(request.query, ExpertView.EXPERT_VIEW_VOIP_CALL_FLOW)
    if (callId && querySignaling && queryCallFlows) {
      querySignaling.columnList = columns
        .filter(col => col.visible)
        .map(col => toNumber(col.dataKey))
      addExpertColumns(querySignaling.columnList, [
        ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER,
        ExpertColumn.EXPERT_COLUMN_TIME,
        ExpertColumn.EXPERT_COLUMN_CALL_ID,
        ExpertColumn.EXPERT_COLUMN_FLOW_INDEX,
        ExpertColumn.EXPERT_COLUMN_MEDIA_PACKET_COUNT,
        ExpertColumn.EXPERT_COLUMN_CONTROL_PACKET_COUNT,
      ])
      if (querySignaling.columnList.includes(ExpertColumn.EXPERT_COLUMN_NAME)) {
        addExpertColumns(querySignaling.columnList, [
          ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS,
          ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS,
          ExpertColumn.EXPERT_COLUMN_FLAGS,
        ])
      }
      if (querySignaling.viewSettings) {
        querySignaling.viewSettings.showAddressNames = showAddressNames
        querySignaling.viewSettings.showPortNames = showPortNames
      }
      if (queryCallFlows.viewSettings) {
        queryCallFlows.viewSettings.showAddressNames = showAddressNames
        queryCallFlows.viewSettings.showPortNames = showPortNames
      }

      // Set the call id in the query.
      querySignaling.where = {
        criteria: "parent",
        key: callId.split(",").map((id: string) => {
          return {
            column: ExpertColumn.EXPERT_COLUMN_CALL_ID,
            value: id,
          }
        }),
      }
      queryCallFlows.where = querySignaling.where

      // Set the sort in the query.
      querySignaling.orderBy = [sortBy, ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER]
      querySignaling.orderByAscending = sortDirection === SortDirection.ASC

      expertQueryCFS(engine, authToken, type, capId, request)
        .then((response: ExpertQueryResponse) => {
          if (response) {
            // Build the node list
            const nodes: string[] = []
            const callFlows = getExpertResults(
              response.results,
              ExpertView.EXPERT_VIEW_VOIP_CALL_FLOW
            )
            if (callFlows && Array.isArray(callFlows.rowList) && callFlows.rowList.length > 0) {
              const addNode = (value?: ExpertValue) => {
                if (value != null && value.rendered != null) {
                  if (!nodes.includes(value.rendered)) {
                    nodes.push(value.rendered)
                  }
                }
              }
              for (const row of callFlows.rowList) {
                addNode(
                  getExpertValueFromRowData(
                    row,
                    callFlows.columnList,
                    ExpertColumn.EXPERT_COLUMN_CALLER_ADDRESS
                  )
                )
                addNode(
                  getExpertValueFromRowData(
                    row,
                    callFlows.columnList,
                    ExpertColumn.EXPERT_COLUMN_GATEKEEPER_ADDRESS
                  )
                )
                addNode(
                  getExpertValueFromRowData(
                    row,
                    callFlows.columnList,
                    ExpertColumn.EXPERT_COLUMN_CALLEE_ADDRESS
                  )
                )
                addNode(
                  getExpertValueFromRowData(
                    row,
                    callFlows.columnList,
                    ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS
                  )
                )
                addNode(
                  getExpertValueFromRowData(
                    row,
                    callFlows.columnList,
                    ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS
                  )
                )
              }
            }

            // Set the node list
            setNodes(nodes)

            // Set the response
            setResultSet(response)
          }
        })
        .catch(() => {
          setError("An error occurred")
        })
    }
  }, [
    engine,
    authToken,
    type,
    capId,
    callId,
    namesModTime,
    showAddressNames,
    showPortNames,
    columns,
    sortBy,
    sortDirection,
  ])

  const onShowDefaultColumns = () => {
    dispatch(setVoIPCallVisualizerColumns(defaultColumns))
  }

  const onShowAllColumns = () => {
    const newColumns = cloneDeep(columns)
    newColumns.forEach(col => {
      col.visible = true
    })
    dispatch(setVoIPCallVisualizerColumns(newColumns))
  }

  const onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColumns = cloneDeep(columns)
    const col = newColumns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      dispatch(setVoIPCallVisualizerColumns(newColumns))
    }
  }

  const onSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
  }) => {
    dispatch(setVoIPCallVisualizerSort(parseInt(sortBy, 10) as ExpertColumn, sortDirection))
  }

  const onGoToPacket = (rowIndex: number) => {
    if (!resultSet || !resultSet.results) return
    const results = getExpertResults(resultSet.results, ExpertView.EXPERT_VIEW_VOIP_SIGNALING)
    if (!results || !results.rowList || rowIndex >= results.rowList.length) return
    const packetNumber = getExpertValueFromRowData(
      results.rowList[rowIndex],
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER
    )
    const packetTimeStamp = getExpertValueFromRowData(
      results.rowList[rowIndex],
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_TIME
    )
    if (
      packetNumber &&
      typeof packetNumber.value === "number" &&
      packetTimeStamp &&
      typeof packetTimeStamp.value === "string"
    ) {
      dispatch(setPacketsDecodePacketNumber(packetNumber.value))
      dispatch(
        setPacketsSelection(capId, {
          packets: [packetNumber.value],
          firstPacketDateTime: packetTimeStamp.value,
          lastPacketDateTime: packetTimeStamp.value,
        })
      )
      history.push({
        pathname: "../packets",
        state: { goToPacketNumber: packetNumber.value },
      })
    }
  }

  const onSelectRelated = (command: string, rowIndex: number) => {
    if (!resultSet || !resultSet.results) return
    const results = getExpertResults(resultSet.results, ExpertView.EXPERT_VIEW_VOIP_SIGNALING)
    if (!results || !results.rowList || rowIndex >= results.rowList.length) return

    let body: RequestPostSelectRelatedExpertStart | undefined = undefined
    switch (command) {
      case "call":
        {
          const callId = getExpertValueFromRowData(
            results.rowList[rowIndex],
            results.columnList,
            ExpertColumn.EXPERT_COLUMN_CALL_ID
          )
          if (callId && typeof callId.value === "number") {
            body = {
              criteria: [
                {
                  voipCallId: callId.value,
                },
              ],
            }
          }
        }
        break
      case "source":
        {
          const sourceAddr = getExpertValueFromRowData(
            results.rowList[rowIndex],
            results.columnList,
            ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS
          )
          if (sourceAddr && typeof sourceAddr.value === "object") {
            body = {
              criteria: [
                {
                  clientAddress: sourceAddr.value as any,
                },
              ],
            }
          }
        }
        break
      case "dest":
        {
          const destAddr = getExpertValueFromRowData(
            results.rowList[rowIndex],
            results.columnList,
            ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS
          )
          if (destAddr && typeof destAddr.value === "object") {
            body = {
              criteria: [
                {
                  serverAddress: destAddr.value as any,
                },
              ],
            }
          }
        }
        break
      case "rtp": {
        const callId = getExpertValueFromRowData(
          results.rowList[rowIndex],
          results.columnList,
          ExpertColumn.EXPERT_COLUMN_CALL_ID
        )
        if (callId && typeof callId.value === "number") {
          const query: RequestExpertQuery = {
            query: [
              {
                columnList: [
                  ExpertColumn.EXPERT_COLUMN_CALL_ID,
                  ExpertColumn.EXPERT_COLUMN_RTP_PACKET_NUMBER_LIST,
                  ExpertColumn.EXPERT_COLUMN_RTP_TIME_LIST,
                  ExpertColumn.EXPERT_COLUMN_RTCP_PACKET_NUMBER_LIST,
                  ExpertColumn.EXPERT_COLUMN_RTCP_TIME_LIST,
                ],
                firstRowIndex: rowIndex,
                limitRowCount: 1,
                orderBy: [ExpertColumn.EXPERT_COLUMN_CALL_ID],
                orderByAscending: true,
                view: ExpertView.EXPERT_VIEW_VOIP_SIGNALING,
                where: {
                  criteria: "parent",
                  key: [
                    {
                      column: ExpertColumn.EXPERT_COLUMN_CALL_ID,
                      value: callId.value.toString(),
                    },
                  ],
                },
              },
            ],
          }
          expertQueryCFS(engine, authToken, type, capId, query)
            .then((response: ExpertQueryResponse) => {
              const packetNumbersResults = getExpertResults(
                response.results,
                ExpertView.EXPERT_VIEW_VOIP_SIGNALING
              )
              if (packetNumbersResults && packetNumbersResults.rowList) {
                const selection: ResultsSelectRelatedResponse = { packets: [] }
                for (const row of packetNumbersResults.rowList) {
                  // Add RTP packets.
                  const rtpPackets = getExpertValueFromRowData(
                    row,
                    packetNumbersResults.columnList,
                    ExpertColumn.EXPERT_COLUMN_RTP_PACKET_NUMBER_LIST
                  )
                  if (
                    rtpPackets &&
                    Array.isArray(rtpPackets.value) &&
                    rtpPackets.value.length > 0
                  ) {
                    selection.packets = selection.packets.concat(rtpPackets.value as number[])
                  }

                  const rtpPacketTimes = getExpertValueFromRowData(
                    row,
                    packetNumbersResults.columnList,
                    ExpertColumn.EXPERT_COLUMN_RTP_TIME_LIST
                  )
                  if (
                    rtpPacketTimes &&
                    Array.isArray(rtpPacketTimes.value) &&
                    rtpPacketTimes.value.length > 0
                  ) {
                    rtpPacketTimes.value.sort()
                    if (
                      !selection.firstPacketDateTime ||
                      rtpPacketTimes.value[0] < selection.firstPacketDateTime
                    ) {
                      selection.firstPacketDateTime = rtpPacketTimes.value[0] as string
                    }
                    if (
                      !selection.lastPacketDateTime ||
                      rtpPacketTimes.value[rtpPacketTimes.value.length - 1] <
                        selection.firstPacketDateTime
                    ) {
                      selection.lastPacketDateTime = rtpPacketTimes.value[
                        rtpPacketTimes.value.length - 1
                      ] as string
                    }
                  }

                  // Add RTCP packets.
                  const rtcpPackets = getExpertValueFromRowData(
                    row,
                    packetNumbersResults.columnList,
                    ExpertColumn.EXPERT_COLUMN_RTCP_PACKET_NUMBER_LIST
                  )
                  if (
                    rtcpPackets &&
                    Array.isArray(rtcpPackets.value) &&
                    rtcpPackets.value.length > 0
                  ) {
                    selection.packets = selection.packets.concat(rtcpPackets.value as number[])
                  }

                  const rtcpPacketTimes = getExpertValueFromRowData(
                    row,
                    packetNumbersResults.columnList,
                    ExpertColumn.EXPERT_COLUMN_RTCP_TIME_LIST
                  )
                  if (
                    rtcpPacketTimes &&
                    Array.isArray(rtcpPacketTimes.value) &&
                    rtcpPacketTimes.value.length > 0
                  ) {
                    rtcpPacketTimes.value.sort()
                    if (
                      !selection.firstPacketDateTime ||
                      rtcpPacketTimes.value[0] < selection.firstPacketDateTime
                    ) {
                      selection.firstPacketDateTime = rtcpPacketTimes.value[0] as string
                    }
                    if (
                      !selection.lastPacketDateTime ||
                      rtcpPacketTimes.value[rtcpPacketTimes.value.length - 1] <
                        selection.firstPacketDateTime
                    ) {
                      selection.lastPacketDateTime = rtcpPacketTimes.value[
                        rtcpPacketTimes.value.length - 1
                      ] as string
                    }
                  }
                }
                if (selection.packets.length > 0) {
                  selection.packets.sort((a: number, b: number) => {
                    if (a < b) return -1
                    if (a > b) return 1
                    return 0
                  })
                  dispatch(setPacketsDecodePacketNumber(selection.packets[0]))
                  dispatch(setPacketsSelection(capId, selection))
                  history.push({
                    pathname: "../packets",
                    state: { goToPacketNumber: selection.packets[0] },
                  })
                }
              }
            })
            .catch(error => {
              console.error(error)
            })
        }
        break
      }
      default:
        break
    }
    if (body) {
      postSelectRelatedExpertStart(engine, authToken, capId, body)
        .then((task: ResponsePostSelectRelatedExpertStart) => {
          if (task.taskId) {
            dispatch(setSelectPacketsTask({ type: "expert", taskId: task.taskId, progress: 0 }))
            history.push("../packets")
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const renderCommands = ({ rowIndex, rowData }: TableCellProps) => {
    if (!resultSet || !resultSet.results) return
    const results = getExpertResults(resultSet.results, ExpertView.EXPERT_VIEW_VOIP_SIGNALING)
    if (!results) return

    const mediaPacketCount = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_MEDIA_PACKET_COUNT
    )
    let hasRTPorRTCP =
      mediaPacketCount != null &&
      mediaPacketCount.value != null &&
      (mediaPacketCount.value as number) > 0
    if (!hasRTPorRTCP) {
      const controlPacketCount = getExpertValueFromRowData(
        rowData,
        results.columnList,
        ExpertColumn.EXPERT_COLUMN_CONTROL_PACKET_COUNT
      )
      hasRTPorRTCP =
        controlPacketCount != null &&
        controlPacketCount.value != null &&
        (controlPacketCount.value as number) > 0
    }

    return (
      <div className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            <DropdownItem disabled={!canViewPackets} onClick={onGoToPacket.bind(null, rowIndex)}>
              Go To Packet
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              disabled={!canViewPackets}
              onClick={onSelectRelated.bind(null, "call", rowIndex)}
            >
              Select Related Packets by Call
            </DropdownItem>
            <DropdownItem
              disabled={!canViewPackets}
              onClick={onSelectRelated.bind(null, "source", rowIndex)}
            >
              Select Related Packets by Source
            </DropdownItem>
            <DropdownItem
              disabled={!canViewPackets}
              onClick={onSelectRelated.bind(null, "dest", rowIndex)}
            >
              Select Related Packets by Destination
            </DropdownItem>
            <DropdownItem
              disabled={!hasRTPorRTCP || !canViewPackets}
              onClick={onSelectRelated.bind(null, "rtp", rowIndex)}
            >
              Select Related Packets by RTP/RTCP
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </div>
    )
  }

  const renderNameHeader = () => {
    return <BounceHeader ref={bounceHeaderRef} nodes={nodes} />
  }

  const renderNameCell = ({ rowIndex }: TableCellProps) => {
    if (nodes.length > 1 && resultSet != null) {
      const signaling = getExpertResults(resultSet.results, ExpertView.EXPERT_VIEW_VOIP_SIGNALING)
      if (signaling && signaling.rowList && rowIndex < signaling.rowList.length) {
        const sourceAddr = getExpertValueFromRowData(
          signaling.rowList[rowIndex],
          signaling.columnList,
          ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS
        )
        const destAddr = getExpertValueFromRowData(
          signaling.rowList[rowIndex],
          signaling.columnList,
          ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS
        )
        const flags = getExpertValueFromRowData(
          signaling.rowList[rowIndex],
          signaling.columnList,
          ExpertColumn.EXPERT_COLUMN_FLAGS
        )
        const summary = getExpertValueFromRowData(
          signaling.rowList[rowIndex],
          signaling.columnList,
          ExpertColumn.EXPERT_COLUMN_NAME
        )
        if (
          sourceAddr &&
          sourceAddr.rendered &&
          destAddr &&
          destAddr.rendered &&
          flags &&
          flags.value != null &&
          summary &&
          summary.value != null
        ) {
          return (
            <Bounce
              nodes={nodes}
              sourceAddr={sourceAddr.rendered}
              destAddr={destAddr.rendered}
              flags={flags.value as number}
              summary={summary.value as string}
              width={bounceWidth}
              height={36}
            />
          )
        }
      }
    }
    return null
  }

  const customColumns = cloneDeep(columns)
  const nameColumn = customColumns.find(
    col => col.dataKey === ExpertColumn.EXPERT_COLUMN_NAME.toString()
  )
  if (nameColumn) {
    nameColumn.headerRenderer = renderNameHeader
    nameColumn.cellRenderer = renderNameCell
  }

  return (
    <ViewWrapper>
      {error ? (
        <Alert color="danger" fade={false}>
          {typeof error === "string" ? error : `${error.code} ${error.reason}`}
        </Alert>
      ) : resultSet != null ? (
        <ExpertTable
          resultSet={resultSet}
          columnDesc={customColumns}
          renderCommands={renderCommands}
          onShowDefaultColumns={onShowDefaultColumns}
          onShowAllColumns={onShowAllColumns}
          onToggleColumn={onToggleColumn}
          sort={onSort}
          sortBy={sortBy.toString()}
          sortDirection={sortDirection}
          rowHeight={36}
          className="signalingtable"
          showLocalTime={showLocalTime}
        />
      ) : null}
    </ViewWrapper>
  )
}

type BounceHeaderProps = {
  nodes: string[]
}

const BounceHeader = React.forwardRef<HTMLDivElement, BounceHeaderProps>(({ nodes }, ref) => {
  if (nodes.length === 0) return null

  const width = 100 / nodes.length
  const nodeElem: React.ReactNode[] = []
  for (let i = 0; i < nodes.length; i++) {
    const style: React.CSSProperties = {
      flex: `1 1 ${width}%`,
      textAlign: "center",
    }
    if (i === 0) {
      style.textAlign = "left"
    } else if (i + 1 === nodes.length) {
      style.textAlign = "right"
    }
    nodeElem.push(
      <div key={i} style={style}>
        {nodes[i]}
      </div>
    )
  }
  return (
    <div
      ref={ref}
      style={{
        flex: "1 1 100%",
        display: "flex",
        paddingLeft: "0.25rem",
        paddingRight: "0.25rem",
      }}
    >
      {nodeElem}
    </div>
  )
})

type BounceProps = {
  nodes: string[]
  sourceAddr: string
  destAddr: string
  flags: number
  summary?: string
  width: number
  height: number
}

const Bounce: React.FC<React.PropsWithChildren<BounceProps>> = ({
  nodes,
  sourceAddr,
  destAddr,
  flags,
  summary,
  width,
  height,
}) => {
  const theme = useTheme() as ThemeInterface

  if (width === 0 || nodes.length < 2) return null

  const m = 6
  const w = width - 2 * m
  const h = height
  const l = m
  const cxNode = w / (nodes.length - 1)

  // Generate the vertical node lines
  const nodeLines = nodes.map((node, i) => {
    const x = l + i * cxNode
    return (
      <line
        key={i}
        x1={x}
        y1={0}
        x2={x}
        y2={height}
        stroke={theme.tableGridColor}
        strokeWidth={2}
      />
    )
  })

  // Generate the bounce line
  const yBounce = h - h / 3
  const sourceAddrIndex = nodes.findIndex(node => node === sourceAddr)
  const destAddrIndex = nodes.findIndex(node => node === destAddr)
  if (sourceAddrIndex === -1 || destAddrIndex === -1) return null
  const xSource = l + cxNode * sourceAddrIndex
  let xDest = l + cxNode * destAddrIndex
  // Offset dest for arrowhead/line overlap
  xDest = xDest > xSource ? xDest - 1 : xDest + 1
  let markerStart: string | undefined = undefined
  if ((flags & VoIPTypes.SIGNALING_FLAG_INVITE) !== 0) {
    markerStart = "url(#diamond)"
  } else if ((flags & VoIPTypes.SIGNALING_FLAG_ACK) !== 0) {
    markerStart = "url(#bar)"
  } else if ((flags & VoIPTypes.SIGNALING_FLAG_BYE) !== 0) {
    markerStart = "url(#square)"
  }

  // Determine the text color
  let summaryColor = theme.textColor
  if ((flags & (VoIPTypes.SIGNALING_FLAG_WARNING | VoIPTypes.SIGNALING_FLAG_ERROR)) !== 0) {
    summaryColor = theme.dangerColor
  }

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <marker
          id="arrow"
          viewBox="0 0 14 14"
          refX="6"
          refY="7"
          markerWidth="14"
          markerHeight="14"
          orient="auto-start-reverse"
        >
          <path d="M 0 3 L 7 7 L 0 11 z" fill={theme.textColor} />
        </marker>
        <marker
          id="diamond"
          viewBox="0 0 14 14"
          refX="7"
          refY="7"
          markerWidth="14"
          markerHeight="14"
        >
          <rect
            x="3.5"
            y="3.5"
            width="7"
            height="7"
            fill="none"
            stroke={theme.textColor}
            transform="rotate(45 7 7)"
          />
        </marker>
        <marker id="bar" viewBox="0 0 14 14" refX="7" refY="7" markerWidth="14" markerHeight="14">
          <line x1="7.5" y1="2.5" x2="7.5" y2="11.5" fill="none" stroke={theme.textColor} />
        </marker>
        <marker
          id="square"
          viewBox="0 0 14 14"
          refX="7"
          refY="7"
          markerWidth="14"
          markerHeight="14"
        >
          <rect x="3.5" y="3.5" width="7" height="7" fill="none" stroke={theme.textColor} />
        </marker>
      </defs>
      <g>
        <g>{nodeLines}</g>
        <g transform="translate(0 0.5)">
          <line
            x1={xSource}
            y1={yBounce}
            x2={xDest}
            y2={yBounce}
            stroke={theme.textColor}
            markerStart={markerStart}
            markerEnd="url(#arrow)"
          />
          {summary && (
            <text
              x={Math.min(xSource, xDest) + 10}
              y={yBounce - m}
              fontSize="10px"
              textAnchor="start"
              fill={summaryColor}
            >
              {summary}
            </text>
          )}
        </g>
      </g>
    </svg>
  )
}

export default SignalingView
