export enum DecodeTagClass {
  Endline = 1,
  String = 2,
  Value = 3,
  Protocol = 4,
  Address = 5,
  Time = 6,
  DataDump = 7,
  Port = 8,
  Object = 9,
}

export enum DecodeTagType {
  RawString = 1,
  CString = 2,
  PString = 3,
  Int8 = 10,
  Int16 = 11,
  Int32 = 12,
  Int64 = 13,
  FourCharCode = 14,
  EthernetProtocol = 20,
  LSAPProtocol = 21,
  SNAPProtocol = 22,
  EthernetAddress = 30,
  TokenRingAddress = 31,
  IPv4Address = 32,
  IPv6Address = 33,
  AppleTalkShortAddress = 34,
  AppleTalkLongAddress = 35,
  AppleTalkDDPDestinationAddress = 36,
  AppleTalkDDPSourceAddress = 37,
  ANSITime = 40,
  IPTime = 41,
  AFPTime = 42,
  NTPTime = 43,
  SMBTime = 44,
  IPPort = 50,
  NetwarePort = 51,
}

export enum DecodeTagStyle {
  Plain = 0,
  Layer = 1,
  Data = 2,
  Header = 3,
  Message = 4,
  Invisible = 5,
  Dump = 6,
  Indent = 7,
  IndentEnd = 8,
  IndentData = 9,
  IndentHeader = 10,
  Offset = 11,
  NameTable = 15,
}

export enum DecodeDisplayType {
  Unspecified = 0,
  Binary = 1,
  SignedDecimal = 2,
  UnsignedDecimal = 3,
  Hex = 4,
  ASCII = 5,
  EBCDIC = 6,
  UTF16LE = 7,
  UTF16BE = 8,
}

export enum DecodeDisplayFlag {
  Linefeed = 0x01,
  AbsoluteColumn = 0x02,
  NoPrepend = 0x04,
  Display7Bit = 0x08,
  LittleEndian = 0x10,
  Summary = 0x20,
  NoSummary = 0x40,
  NoLeadingZeroes = 0x80,
}

export type DecodeSnippet = {
  text: string
  summary?: string
  tagClass: DecodeTagClass
  tagType: DecodeTagType
  offset?: number
  size?: number
  mask?: string
  flags: DecodeDisplayFlag
  style: DecodeTagStyle
  type: DecodeDisplayType
  column: number
  red?: number
  green?: number
  blue?: number
}

export type DecodeSnippetItem = {
  snippets: DecodeSnippet[]
  children?: DecodeSnippetItem[]
}

export type Decode = {
  packet: number
  lines: DecodeSnippetItem[]
}

export type DecodeLayer = {
  offset: number
  size: number
  color: string
}

export type PacketDecode = {
  result: number
  packet: number
  data: number[]
  decode: Decode
  layers: DecodeLayer[]
}
