import * as React from "react"
import { produce } from "immer"
import { Col, Form, FormGroup, Row } from "reactstrap"
import FontAwesome from "react-fontawesome"
import { AutoSizer, ListRowProps } from "react-virtualized"
import styled, { withTheme } from "styled-components"
import { v4 as uuid } from "uuid"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import FilterBox from "../common/FilterBox"
import { CheckGroup } from "../common/Form"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../common/Modal"
import { ViewContent, ViewHeader, ViewHeaderButtons } from "../common/View"
import { VirtualList } from "../common/VirtualList"
import {
  GraphItemObject,
  SummaryStatisticGraphItemObject,
  SummaryStatisticsObjectSnapshot,
  SummaryStatisticsObjectSnapshotItem,
} from "../../api/types"
import {
  GraphItemUnitType,
  PeekSummaryStatFlags,
  PeekSummaryStatType,
} from "../../api/types/peekTypes"
import { collator, partialSort } from "../../utils/sortUtils"

const TOP_PARENT_ID = "00000000-0000-0000-0000-000000000000"
const PEEK_SUMMARY_STATS_SORT_SUB_ITEMS = 0x8

const SummaryGroupRow = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${props => props.theme.tableGridColor};
  background-color: ${props => props.theme.tableGroupBackgroundColor};
  font-weight: bold;
  cursor: pointer;
`

const SummaryGroupLabel = styled.div`
  flex: 1 1 200px;
  padding-left: 0.25em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SummaryStatRow = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${props => props.theme.tableGridColor};
  background-color: ${props => props.theme.tableBackgroundColor};

  &.stripe {
    background-color: ${props => props.theme.tableStripeColor};
  }

  &:hover {
    background-color: ${props => props.theme.tableHoverColor};
  }
`

const SummaryStatLabel = styled.div`
  flex: 1 1 200px;
  padding: 0.25rem 0.25rem 0.25rem 1.536em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SummaryStatLabelEmpty = styled.div`
  flex: 1 1 200px;
  padding: 0.25rem 0.25rem 0.25rem 3.036em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

type SummaryStatisticsTableProps = {
  collapsedItems: Set<string>
  graphItems: GraphItemObject[]
  snapshot: SummaryStatisticsObjectSnapshotItem[] | null
  unitType: GraphItemUnitType
  onExpandCollapse: (item: SummaryStatisticsObjectSnapshotItem) => void
  onExpandAll: () => void
  onCollapseAll: () => void
  onUpdateGraphItems: (item: SummaryStatisticsObjectSnapshotItem, checked: boolean) => void
}

class SummaryStatisticsTable extends React.Component<SummaryStatisticsTableProps> {
  onClick = (item: SummaryStatisticsObjectSnapshotItem, event: React.MouseEvent<any>) => {
    const { collapsedItems, onExpandCollapse, onExpandAll, onCollapseAll } = this.props
    if (event.altKey) {
      const collapsed = collapsedItems.has(item.id)
      if (collapsed) {
        onExpandAll()
      } else {
        onCollapseAll()
      }
    } else {
      onExpandCollapse(item)
    }
  }

  onChangeCheckbox = (
    item: SummaryStatisticsObjectSnapshotItem,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.props.onUpdateGraphItems(item, event.target.checked)
  }

  rowRenderer = ({ key, index, style }: ListRowProps) => {
    const { collapsedItems, graphItems, snapshot, unitType } = this.props
    if (Array.isArray(snapshot)) {
      const item = snapshot[index]
      if (item.parentId === TOP_PARENT_ID) {
        const collapsed = collapsedItems.has(item.id)
        const icon = collapsed ? "chevron-right" : "chevron-down"
        return (
          <SummaryGroupRow
            key={key}
            style={style}
            onClick={this.onClick.bind(this, item)}
            role="button"
          >
            <FontAwesome name={icon} fixedWidth />
            <SummaryGroupLabel>{item.label}</SummaryGroupLabel>
          </SummaryGroupRow>
        )
      } else {
        let isGraphable =
          (item.flags & PeekSummaryStatFlags.PEEK_SUMMARY_STAT_FLAG_UNGRAPHABLE) === 0
        if (isGraphable) {
          switch (item.type) {
            case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_PACKETS:
              isGraphable = unitType === GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_PACKETS
              break
            case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_BYTES:
              isGraphable = unitType === GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_BYTES
              break
            case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_VALUE_PAIR:
            case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_INT:
            case PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DOUBLE:
              isGraphable = true
              break
            default:
              break
          }
        }
        const checked =
          graphItems.find(graphItem => graphItem.statisticItemId === item.id) !== undefined
        return (
          <SummaryStatRow
            key={key}
            style={style}
            className={index % 2 === 0 ? "stripe" : undefined}
          >
            {isGraphable && (
              <SummaryStatLabel>
                <CheckGroup
                  type="checkbox"
                  name={item.id}
                  id={`summary-${item.id}`}
                  onChange={this.onChangeCheckbox.bind(this, item)}
                  checked={checked}
                >
                  {item.label}
                </CheckGroup>
              </SummaryStatLabel>
            )}
            {!isGraphable && <SummaryStatLabelEmpty>{item.label}</SummaryStatLabelEmpty>}
          </SummaryStatRow>
        )
      }
    }
    return <div />
  }

  render() {
    const { snapshot } = this.props
    return (
      <AutoSizer>
        {({ height, width }) => (
          <VirtualList
            width={width}
            height={height}
            rowHeight={26}
            rowCount={Array.isArray(snapshot) ? snapshot.length : 0}
            rowRenderer={this.rowRenderer}
            triggerUpdate1={snapshot}
          />
        )}
      </AutoSizer>
    )
  }
}

type SummaryModalProps = {
  clsid: string
  graphItems: GraphItemObject[]
  graphStats: SummaryStatisticsObjectSnapshot
  theme: any
  unitType: GraphItemUnitType
  onCancel: (clsid: string) => void
  onSubmit: (clsid: string, graphItems: GraphItemObject[]) => void
}

type SummaryModalState = {
  collapsedItems: Set<string>
  filter: string
  graphItems: GraphItemObject[]
}

class SummaryModal extends React.Component<SummaryModalProps, SummaryModalState> {
  state: SummaryModalState = {
    collapsedItems: new Set(),
    filter: "",
    graphItems: this.props.graphItems,
  }

  onExpandCollapse = (item: SummaryStatisticsObjectSnapshotItem) => {
    const collapsedItems = new Set(this.state.collapsedItems)
    if (collapsedItems.has(item.id)) {
      collapsedItems.delete(item.id)
    } else {
      collapsedItems.add(item.id)
    }
    this.setState({ collapsedItems })
  }

  onExpandAll = () => {
    const collapsedItems = new Set<string>()
    this.setState({ collapsedItems })
  }

  onCollapseAll = () => {
    const { graphStats } = this.props
    if (graphStats && graphStats.items) {
      const collapsedItems = new Set<string>()
      for (let i = 0, len = graphStats.items.length; i < len; i++) {
        const item = graphStats.items[i]
        if (item.parentId === TOP_PARENT_ID) {
          collapsedItems.add(item.id)
        }
      }
      this.setState({ collapsedItems })
    }
  }

  onChangeFilter = (filter: string) => {
    this.setState({ filter })
  }

  onUpdateGraphItems = (item: SummaryStatisticsObjectSnapshotItem, checked: boolean) => {
    const { clsid, graphStats, unitType } = this.props
    this.setState(
      produce(draft => {
        if (checked) {
          let description = item.label
          if (item.parentId !== TOP_PARENT_ID) {
            const parentItem = graphStats.items.find(graphStat => graphStat.id === item.parentId)
            if (parentItem !== undefined) {
              description = parentItem.label + ": " + item.label
            }
          }
          draft.graphItems.push({
            clsid,
            description,
            id: uuid().toUpperCase(),
            name: item.label,
            statisticFlags: item.flags,
            statisticItemId: item.id,
            statisticType: item.type,
            unitType,
          })
        } else {
          draft.graphItems = draft.graphItems.filter(
            (graphItem: GraphItemObject) =>
              graphItem.clsid === clsid &&
              (graphItem as SummaryStatisticGraphItemObject).statisticItemId !== item.id
          )
        }
      })
    )
  }

  onCancel = () => {
    const { clsid } = this.props
    this.props.onCancel(clsid)
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const { clsid } = this.props
    const { graphItems } = this.state
    this.props.onSubmit(clsid, graphItems)
  }

  getFlattenedTree = (): SummaryStatisticsObjectSnapshotItem[] | null => {
    // TODO: handle subgroups?
    const { graphStats } = this.props
    if (graphStats && graphStats.items) {
      const filter = this.state.filter.toLowerCase()
      let items: SummaryStatisticsObjectSnapshotItem[]
      if (filter) {
        // Apply the filter to non-parent items
        items = graphStats.items.filter(
          item => item.parentId === TOP_PARENT_ID || item.label.toLowerCase().indexOf(filter) !== -1
        )
        // Remove empty parents
        items = items.filter(
          item =>
            item.parentId !== TOP_PARENT_ID ||
            items.findIndex(innerItem => innerItem.parentId === item.id) !== -1
        )
      } else {
        items = graphStats.items
      }
      const rows: SummaryStatisticsObjectSnapshotItem[] = []
      const sortItems: string[] = []
      items.forEach(item => {
        if (item.parentId === TOP_PARENT_ID) {
          rows.push(item)
          if ((item.flags & PEEK_SUMMARY_STATS_SORT_SUB_ITEMS) !== 0) {
            sortItems.push(item.id)
          }
        } else {
          const collapsed = this.state.collapsedItems.has(item.parentId)
          if (!collapsed) {
            rows.push(item)
            if ((item.flags & PEEK_SUMMARY_STATS_SORT_SUB_ITEMS) !== 0) {
              sortItems.push(item.id)
            }
          }
        }
      })

      // Sort any groups that require it
      sortItems.forEach(sortItem => {
        const parentIndex = rows.findIndex(row => row.id === sortItem)
        if (parentIndex !== -1) {
          const firstChildIndex = parentIndex + 1
          if (firstChildIndex < rows.length && rows[firstChildIndex].parentId === sortItem) {
            let lastChildIndex = firstChildIndex
            while (lastChildIndex < rows.length && rows[lastChildIndex].parentId === sortItem) {
              lastChildIndex++
            }
            if (lastChildIndex > firstChildIndex) {
              partialSort(rows, firstChildIndex, lastChildIndex, (a, b) => {
                return collator.compare(a.label, b.label)
              })
            }
          }
        }
      })
      return rows
    }
    return null
  }

  render() {
    const { unitType } = this.props
    const { collapsedItems, filter, graphItems } = this.state
    const flattenedTree = this.getFlattenedTree()

    return (
      <Modal size="md" isOpen={true}>
        <ModalHeader toggle={this.onCancel}>Summary</ModalHeader>
        <ModalBody>
          <Form id="summary-form" onSubmit={this.onSubmit}>
            <Row>
              <Col md={12}>
                <ViewHeader border={false} style={{ flexDirection: "row-reverse" }}>
                  <ViewHeaderButtons>
                    <FilterBox
                      aria-label="Search"
                      placeholder="Search"
                      onChange={this.onChangeFilter}
                      value={filter}
                    />
                  </ViewHeaderButtons>
                </ViewHeader>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup noMargin>
                  <ViewContent>
                    {flattenedTree && (
                      <SummaryStatisticsTable
                        collapsedItems={collapsedItems}
                        graphItems={graphItems}
                        snapshot={flattenedTree}
                        unitType={unitType}
                        onExpandCollapse={this.onExpandCollapse}
                        onExpandAll={this.onExpandAll}
                        onCollapseAll={this.onCollapseAll}
                        onUpdateGraphItems={this.onUpdateGraphItems}
                      />
                    )}
                  </ViewContent>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={this.onCancel}>Cancel</SecondaryButton>
          <PrimaryButton type="submit" form="summary-form">
            Update
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    )
  }
}

const SummaryModalWithTheme = withTheme(SummaryModal)
export { SummaryModalWithTheme as SummaryModal }
