import { CSSProperties } from "react"

// See getStringSize() in recharts

const MEASUREMENT_SPAN_ID = "__measurement_span"

const SPAN_STYLE = {
  position: "absolute",
  top: "-20000px",
  left: 0,
  padding: 0,
  margin: 0,
  border: "none",
  whiteSpace: "pre",
}

export const measureText = (text: string, style: CSSProperties = {}) => {
  try {
    if (text != null) {
      let measurementSpan = document.getElementById(MEASUREMENT_SPAN_ID)
      if (!measurementSpan) {
        measurementSpan = document.createElement("span")
        measurementSpan.setAttribute("id", MEASUREMENT_SPAN_ID)
        document.body.appendChild(measurementSpan)
      }

      const measurementSpanStyle: Record<string, any> = { ...SPAN_STYLE, ...style }
      Object.keys(measurementSpanStyle).map(styleKey => {
        if (measurementSpan) {
          ;(measurementSpan.style as Record<string, any>)[styleKey] = measurementSpanStyle[styleKey]
        }
        return styleKey
      })

      measurementSpan.textContent = text

      const rect = measurementSpan.getBoundingClientRect()

      return { width: rect.width, height: rect.height }
    }
  } catch {}
  return { width: 0, height: 0 }
}
