import * as React from "react"
import { NameEditModal } from "../NameEditModal"
import { NameTableEntry } from "../../api/types"
import { fetchNames, postNames } from "../../api/api"
import { MediaSpecType } from "../../api/types/mediaTypes"
import { MergeOptions } from "../../api/types/nameTypes"

export type InsertNameEntry = {
  title: string
  entry: string
  entryType: MediaSpecType
}

export type InsertNamesModalProps = {
  engine: string
  authToken: string
  entries: InsertNameEntry[]
  onOK: () => void
  onCancel: () => void
}

const InsertNamesModal = (props: InsertNamesModalProps) => {
  const [index, setIndex] = React.useState(0)
  const [entries, setEntries] = React.useState<NameTableEntry[]>([])
  const [groups, setGroups] = React.useState<string[]>([])

  const onEditOK = (entry: NameTableEntry) => {
    postNames(props.engine, props.authToken, [entry], MergeOptions.Manual).catch(error => {
      console.error(error)
    })

    if (index + 1 >= props.entries.length) {
      props.onOK()
    } else {
      setIndex(index + 1)
    }
  }

  const onEditCancel = () => {
    if (index + 1 >= props.entries.length) {
      props.onCancel()
    } else {
      setIndex(index + 1)
    }
  }

  React.useEffect(() => {
    fetchNames(props.engine, props.authToken)
      .then(response => {
        if (response && Array.isArray(response.names)) {
          // Gather the name table groups from all types.
          const groupsSet = new Set<string>()
          for (let i = 0, len = response.names.length; i < len; i++) {
            const group = response.names[i].group
            if (group) {
              groupsSet.add(group)
            }
          }
          setGroups(Array.from(groupsSet).sort())

          // Create the list of name table entries to edit by
          // finding the items to edit in the name table by
          // entry or name.
          const newEntries: NameTableEntry[] = []
          for (let i = 0; i < props.entries.length; i++) {
            const currentEntry = response.names.find(
              item =>
                item.entryType === props.entries[i].entryType &&
                (item.entry === props.entries[i].entry || item.name === props.entries[i].entry)
            )
            if (currentEntry) {
              newEntries.push(currentEntry)
            } else {
              newEntries.push({
                color: "#000000",
                entry: props.entries[i].entry,
                entryType: props.entries[i].entryType,
                group: "",
                name: "",
              })
            }
          }
          setEntries(newEntries)
        }
      })
      .catch(() => {})
  }, [props.engine, props.authToken, props.entries])

  if (entries.length === 0) {
    return null
  }

  return (
    <NameEditModal
      key={index}
      initialEntry={entries[index]}
      groups={groups}
      title={props.entries[index].title}
      onOK={onEditOK}
      onCancel={onEditCancel}
    />
  )
}

export { InsertNamesModal }
