import * as React from "react"
import { toNumber } from "lodash"
import {
  dateFormat,
  timeFormat,
  formatInteger,
  formatFloat,
  timeFormatUTC,
  dateFormatUTC,
} from "./formatUtils"
import { ChartType } from "../api/types/chartTypes"

export const chartColors = [
  "rgb(35,98,173)",
  "rgb(176,36,33)",
  "rgb(130,173,48)",
  "rgb(96,61,139)",
  "rgb(30,151,184)",
  "rgb(245,124,24)",
  "rgb(128,13,128)",
  "rgb(0,33,83)",
  "rgb(85,0,0)",
  "rgb(55,83,0)",
  "rgb(33,10,59)",
  "rgb(0,54,118)",
  "rgb(164,45,0)",
  "rgb(96,0,96)",
]

export function getChartColor(i: number) {
  return chartColors[i % chartColors.length]
}

export function getChartColorCount() {
  return chartColors.length
}

export const chartTypeSupportsSmoothing = (chartType: ChartType): boolean => {
  return (
    chartType === ChartType.AREA ||
    chartType === ChartType.STACKED_AREA ||
    chartType === ChartType.LINE ||
    chartType === ChartType.LINE_POINTS
  )
}

export const formatIntegerChartTooltip = (
  value: string | number | (string | number)[],
  name: string,
  props: any
) => {
  const label = name !== "noname" ? `${name}: ` : ""
  const unit = props.unit || ""
  const color = props.color || "#000"
  const style = {
    margin: "2px 0",
    padding: "2px",
    lineHeight: "1rem",
  }
  const colorStyle = {
    display: "inline-block",
    borderLeft: `3px solid ${color}`,
    width: "9px",
  }
  return (
    <div style={style}>
      {label && <div style={colorStyle}>&nbsp;</div>}
      {`${label}${formatInteger(toNumber(value))} ${unit}`}
    </div>
  )
}

export const formatChartTooltip = (
  value: string | number | (string | number)[],
  name: string,
  props: any
) => {
  const label = name !== "noname" ? `${name}: ` : ""
  const unit = props.unit || ""
  const color = props.color || "#000"
  const style = {
    margin: "2px 0",
    padding: "2px",
    lineHeight: "1rem",
  }
  const colorStyle = {
    display: "inline-block",
    borderLeft: `3px solid ${color}`,
    width: "9px",
  }
  return (
    <div style={style}>
      {label && <div style={colorStyle}>&nbsp;</div>}
      {`${label}${formatFloat(toNumber(value), 3)} ${unit}`}
    </div>
  )
}

export const formatPercentChartLabel = (value: string | number): React.ReactNode =>
  `${formatFloat(toNumber(value), 1)}%`

export const formatPercentChartTooltip = (
  valueProp: string,
  totalProp: string,
  value: any,
  name: string,
  props: any
) => (
  <span>
    <span>{`${formatFloat(value, 2)}%`}</span>
    <br />
    <span>{`[${formatInteger(props.payload[valueProp])} out of ${formatInteger(
      props.payload[totalProp]
    )}]`}</span>
  </span>
)

export const formatTimeChartAxis = (
  startTime: number,
  interval: number,
  showLocalTime: boolean,
  i: number
) => {
  const t = startTime + i * interval
  return showLocalTime ? timeFormat.format(t) : timeFormatUTC.format(t)
}

export const formatTimeChartTooltipLabel = (
  startTime: number,
  interval: number,
  showLocalTime: boolean,
  i: string | number
) => {
  i = toNumber(i)
  const t = startTime + i * interval
  return showLocalTime
    ? `${dateFormat.format(t)} ${timeFormat.format(t)}`
    : `${dateFormatUTC.format(t)} ${timeFormatUTC.format(t)}Z`
}
