import * as React from "react"
import { cloneDeep } from "lodash"
import { useSelector } from "react-redux"
import { useTheme } from "styled-components"
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Label,
  LabelList,
} from "recharts"
import ChartTooltip from "../../../common/ChartTooltip"
import { TimelineDataData, VlanMplsTimelineDataData } from "../../../../api/types"
import { ChartType } from "../../../../api/types/chartTypes"
import { EngineCapabilities } from "../../../../api/types/engineTypes"
import { ChartComponents } from "../../../../utils/chartComponents"
import {
  chartTypeSupportsSmoothing,
  formatChartTooltip,
  formatTimeChartTooltipLabel,
  formatTimeChartAxis,
  formatPercentChartLabel,
  formatPercentChartTooltip,
  getChartColor,
} from "../../../../utils/chartUtils"
import { formatInteger } from "../../../../utils/formatUtils"
import { getCapabilities, getShowLocalTime } from "../../../../store"
import { WidgetProps, TimelineDataViewType } from "../../../../api/types/dashboardTypes"
import { Widget, WidgetBody, WidgetMessage } from "../../DashboardWidget"
import DashboardWidgetHeader from "../../DashboardWidgetHeader"

const ForensicChartWidget = ({ widget, data, commands }: WidgetProps) => {
  const theme = useTheme()
  const engineCapabilities = useSelector(getCapabilities)
  const showLocalTime = useSelector(getShowLocalTime)
  const [hoverKey, setHoverKey] = React.useState(null)

  if (data == null || data.data == null || data.data.length === 0) {
    return (
      <Widget>
        <DashboardWidgetHeader title={widget.title} widget={widget} commands={commands} />
        <WidgetBody>
          <WidgetMessage>No Data</WidgetMessage>
        </WidgetBody>
      </Widget>
    )
  }

  function onMouseEnterLegend(e: any) {
    setHoverKey(e.dataKey)
  }

  function onMouseLeaveLegend(e: any) {
    if (e.dataKey === hoverKey) {
      setHoverKey(null)
    }
  }

  const chartType =
    widget.settings != null && widget.settings.chartType != null
      ? widget.settings.chartType
      : ChartType.OVERLAID_SKYLINE
  const chartScale = "linear"
  const chartInterpolation = "linear"
  const startTime = Date.parse(data.startTime)
  const sampleInterval = data.sampleInterval
  const chartComponent = ChartComponents[chartType]
  let Chart = chartComponent.chart
  const chartProps = chartComponent.chartProps
  const Series = chartComponent.series
  const seriesProps = cloneDeep(chartComponent.seriesProps)
  if (seriesProps.dot) {
    seriesProps.dot.stroke = theme.panelBackground
  }
  if (seriesProps.activeDot) {
    seriesProps.activeDot.stroke = theme.panelBackground
  }
  if (chartTypeSupportsSmoothing(chartType)) {
    seriesProps.type = chartInterpolation
  }
  let title = ""
  let xAxisProps: any = {
    tickFormatter: (value: any) =>
      formatTimeChartAxis(startTime, sampleInterval * 1000, showLocalTime, value),
    height: 22,
  }
  let yAxisProps: any = {}
  let yLabel = ""
  let yLabelOffset = 0
  let tooltipLabelFormatter: any | undefined = (label: string | number) =>
    formatTimeChartTooltipLabel(startTime, sampleInterval * 1000, showLocalTime, label)
  let tooltipFormatter = formatChartTooltip
  let enableHover = false
  let series: any[] = []
  switch (data.viewType) {
    case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_MBITS:
      title = "Network Utilization"
      yLabel = "Mbits/s"
      series = [
        <Series
          key="mbps"
          dataKey="mbps"
          name="noname"
          unit={yLabel}
          stroke={getChartColor(0)}
          fill={getChartColor(0)}
          {...seriesProps}
          legendType="none"
        />,
      ]
      break
    case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_PACKETS:
      title = "Network Utilization"
      yLabel = "Packets/s"
      yLabelOffset = 20
      enableHover = true
      series = [
        <Series
          key="packets"
          dataKey="packets"
          name="Total"
          unit={yLabel}
          stroke={getChartColor(0)}
          fill={getChartColor(0)}
          {...seriesProps}
        />,
        <Series
          key="dropped"
          dataKey="dropped"
          name="Dropped"
          unit={yLabel}
          stroke={getChartColor(1)}
          fill={getChartColor(1)}
          {...seriesProps}
        />,
        <Series
          key="crc"
          dataKey="crc"
          name="CRC"
          unit={yLabel}
          stroke={getChartColor(2)}
          fill={getChartColor(2)}
          {...seriesProps}
        />,
        <Series
          key="undersize"
          dataKey="undersize"
          name="Runt"
          unit={yLabel}
          stroke={getChartColor(3)}
          fill={getChartColor(3)}
          {...seriesProps}
        />,
        <Series
          key="oversize"
          dataKey="oversize"
          name="Jumbo"
          unit={yLabel}
          stroke={getChartColor(4)}
          fill={getChartColor(4)}
          {...seriesProps}
        />,
      ]
      break
    case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_DEST_TYPE:
      title = "Unicast/Multicast/Broadcast"
      yLabel = "Packets/s"
      yLabelOffset = 20
      enableHover = true
      series = [
        <Series
          key="unicast"
          dataKey="unicast"
          name="Unicast"
          unit={yLabel}
          stroke={getChartColor(0)}
          fill={getChartColor(0)}
          {...seriesProps}
        />,
        <Series
          key="multicast"
          dataKey="multicast"
          name="Multicast"
          unit={yLabel}
          stroke={getChartColor(1)}
          fill={getChartColor(1)}
          {...seriesProps}
        />,
        <Series
          key="broadcast"
          dataKey="broadcast"
          name="Broadcast"
          unit={yLabel}
          stroke={getChartColor(2)}
          fill={getChartColor(2)}
          {...seriesProps}
        />,
      ]
      break
    case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_SIZE_DIST: {
      title = "Packet Sizes"
      Chart = BarChart
      xAxisProps = { dataKey: "entry" }
      yAxisProps = { domain: [0, 100] }
      tooltipLabelFormatter = undefined
      tooltipFormatter = (value: string | number | (string | number)[], name: string, props: any) =>
        formatPercentChartTooltip("packets", "totalPackets", value, name, props)
      const labelListProps = {
        fill: theme.textMutedColor,
        stroke: "none",
      }
      series = [
        <Bar key="0" dataKey="pct" name="Packets" legendType="none" isAnimationActive={false}>
          <LabelList
            dataKey="pct"
            position="top"
            formatter={formatPercentChartLabel}
            {...labelListProps}
          />
          {data.data.map((entry: TimelineDataData, i: number) => (
            <Cell key={i} fill={getChartColor(i)} />
          ))}
        </Bar>,
      ]
      break
    }
    case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_VLAN:
      if (
        engineCapabilities?.capabilities.includes(EngineCapabilities.vxlanTimelineStats) &&
        data.data.length > 0 &&
        (data.data[0] as VlanMplsTimelineDataData).vxlan !== undefined
      ) {
        title = "MPLS/VLAN/VXLAN"
        yLabel = "Packets/s"
        yLabelOffset = 20
        enableHover = true
        series = [
          <Series
            key="packets"
            dataKey="packets"
            name="Total"
            unit={yLabel}
            stroke={getChartColor(0)}
            fill={getChartColor(0)}
            {...seriesProps}
          />,
          <Series
            key="mpls"
            dataKey="mpls"
            name="MPLS"
            unit={yLabel}
            stroke={getChartColor(1)}
            fill={getChartColor(1)}
            {...seriesProps}
          />,
          <Series
            key="vlan"
            dataKey="vlan"
            name="VLAN"
            unit={yLabel}
            stroke={getChartColor(2)}
            fill={getChartColor(2)}
            {...seriesProps}
          />,
          <Series
            key="vxlan"
            dataKey="vxlan"
            name="VXLAN"
            unit={yLabel}
            stroke={getChartColor(3)}
            fill={getChartColor(3)}
            {...seriesProps}
          />,
        ]
      } else {
        title = "VLAN/MPLS"
        yLabel = "Packets/s"
        yLabelOffset = 20
        enableHover = true
        series = [
          <Series
            key="packets"
            dataKey="packets"
            name="Total"
            unit={yLabel}
            stroke={getChartColor(0)}
            fill={getChartColor(0)}
            {...seriesProps}
          />,
          <Series
            key="mpls"
            dataKey="mpls"
            name="MPLS"
            unit={yLabel}
            stroke={getChartColor(1)}
            fill={getChartColor(1)}
            {...seriesProps}
          />,
          <Series
            key="vlan"
            dataKey="vlan"
            name="VLAN"
            unit={yLabel}
            stroke={getChartColor(2)}
            fill={getChartColor(2)}
            {...seriesProps}
          />,
        ]
      }
      break
    case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_PROTOCOLS_MBITS:
      title = "Protocols"
      yLabel = "Mbits/s"
      enableHover = true
      series = [
        <Series
          key="ipv4"
          dataKey="ipv4"
          name="IPv4"
          unit={yLabel}
          stroke={getChartColor(0)}
          fill={getChartColor(0)}
          {...seriesProps}
        />,
        <Series
          key="ipv6"
          dataKey="ipv6"
          name="IPv6"
          unit={yLabel}
          stroke={getChartColor(1)}
          fill={getChartColor(1)}
          {...seriesProps}
        />,
        <Series
          key="tcp"
          dataKey="tcp"
          name="TCP"
          unit={yLabel}
          stroke={getChartColor(2)}
          fill={getChartColor(2)}
          {...seriesProps}
        />,
        <Series
          key="udp"
          dataKey="udp"
          name="UDP"
          unit={yLabel}
          stroke={getChartColor(3)}
          fill={getChartColor(3)}
          {...seriesProps}
        />,
        <Series
          key="icmp"
          dataKey="icmp"
          name="ICMP"
          unit={yLabel}
          stroke={getChartColor(4)}
          fill={getChartColor(4)}
          {...seriesProps}
        />,
      ]
      break
    case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_PROTOCOLS_PACKETS:
      title = "Protocols"
      yLabel = "Packets/s"
      yLabelOffset = 20
      enableHover = true
      series = [
        <Series
          key="ipv4"
          dataKey="ipv4"
          name="IPv4"
          unit={yLabel}
          stroke={getChartColor(0)}
          fill={getChartColor(0)}
          {...seriesProps}
        />,
        <Series
          key="ipv6"
          dataKey="ipv6"
          name="IPv6"
          unit={yLabel}
          stroke={getChartColor(1)}
          fill={getChartColor(1)}
          {...seriesProps}
        />,
        <Series
          key="tcp"
          dataKey="tcp"
          name="TCP"
          unit={yLabel}
          stroke={getChartColor(2)}
          fill={getChartColor(2)}
          {...seriesProps}
        />,
        <Series
          key="udp"
          dataKey="udp"
          name="UDP"
          unit={yLabel}
          stroke={getChartColor(3)}
          fill={getChartColor(3)}
          {...seriesProps}
        />,
        <Series
          key="icmp"
          dataKey="icmp"
          name="ICMP"
          unit={yLabel}
          stroke={getChartColor(4)}
          fill={getChartColor(4)}
          {...seriesProps}
        />,
      ]
      break
    case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_CALL_QUALITY:
      title = "Call Quality"
      yLabel = "Calls"
      Chart = BarChart
      series = [
        <Bar
          key="good"
          dataKey="good"
          name="Good"
          unit={yLabel}
          stackId="a"
          fill="#75d015"
          legendType="square"
          isAnimationActive={false}
        />,
        <Bar
          key="fair"
          dataKey="fair"
          name="Fair"
          unit={yLabel}
          stackId="a"
          fill="#ffdf00"
          legendType="square"
          isAnimationActive={false}
        />,
        <Bar
          key="poor"
          dataKey="poor"
          name="Poor"
          unit={yLabel}
          stackId="a"
          fill="#ffa126"
          legendType="square"
          isAnimationActive={false}
        />,
        <Bar
          key="bad"
          dataKey="bad"
          name="Bad"
          unit={yLabel}
          stackId="a"
          fill="#ff1a1a"
          legendType="square"
          isAnimationActive={false}
        />,
        <Bar
          key="unknown"
          dataKey="unknown"
          name="Unknown"
          unit={yLabel}
          stackId="a"
          fill="#999999"
          legendType="square"
          isAnimationActive={false}
        />,
      ]
      break
    case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_CALL_UTILIZATION:
      title = "Call vs. Network Utilization"
      yLabel = "Mbits/s"
      enableHover = true
      series = [
        <Series
          key="mbps"
          dataKey="mbps"
          name="Network Utilization"
          unit={yLabel}
          stroke={getChartColor(0)}
          fill={getChartColor(0)}
          {...seriesProps}
        />,
        <Series
          key="callMbps"
          dataKey="callMbps"
          name="Call Utilization"
          unit={yLabel}
          stroke={getChartColor(1)}
          fill={getChartColor(1)}
          {...seriesProps}
        />,
      ]
      break
    case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_WIRELESS_PACKETS:
      title = "Wireless Packets"
      yLabel = "Packets/s"
      yLabelOffset = 20
      enableHover = true
      series = [
        <Series
          key="packets"
          dataKey="packets"
          name="Total"
          unit={yLabel}
          stroke={getChartColor(0)}
          fill={getChartColor(0)}
          {...seriesProps}
        />,
        <Series
          key="data"
          dataKey="data"
          name="Data"
          unit={yLabel}
          stroke={getChartColor(1)}
          fill={getChartColor(1)}
          {...seriesProps}
        />,
        <Series
          key="management"
          dataKey="management"
          name="Management"
          unit={yLabel}
          stroke={getChartColor(2)}
          fill={getChartColor(2)}
          {...seriesProps}
        />,
        <Series
          key="control"
          dataKey="control"
          name="Control"
          unit={yLabel}
          stroke={getChartColor(3)}
          fill={getChartColor(3)}
          {...seriesProps}
        />,
      ]
      break
    case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_WIRELESS_RETRIES:
      title = "Wireless Retries"
      yLabel = "Packets/s"
      yLabelOffset = 20
      enableHover = true
      series = [
        <Series
          key="packets"
          dataKey="packets"
          name="Total"
          unit={yLabel}
          stroke={getChartColor(0)}
          fill={getChartColor(0)}
          {...seriesProps}
        />,
        <Series
          key="data"
          dataKey="data"
          name="Data"
          unit={yLabel}
          stroke={getChartColor(1)}
          fill={getChartColor(1)}
          {...seriesProps}
        />,
        <Series
          key="retry"
          dataKey="retry"
          name="Retry"
          unit={yLabel}
          stroke={getChartColor(2)}
          fill={getChartColor(2)}
          {...seriesProps}
        />,
      ]
      break
    case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_APPLICATIONS_MBITS:
      title = "Applications"
      yLabel = "Mbits/s"
      enableHover = true
      if (data.data.length > 0) {
        series = Object.keys(data.data[0]).map(key => {
          if (key === "totalPackets" || data === null) return null
          const app = data.apps[key]
          const name = app && app.name ? app.name : key
          const color = app && app.color ? app.color : "#808080"
          return (
            <Series
              key={key}
              dataKey={key}
              name={name}
              unit={yLabel}
              stroke={color}
              fill={color}
              {...seriesProps}
            />
          )
        })
      }
      break
    case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_APPLICATIONS_PACKETS:
      title = "Applications"
      yLabel = "Packets/s"
      yLabelOffset = 20
      enableHover = true
      if (data.data.length > 0) {
        series = Object.keys(data.data[0]).map(key => {
          if (key === "totalPackets" || data === null) return null
          const app = data.apps[key]
          const name = app && app.name ? app.name : key
          const color = app && app.color ? app.color : "#808080"
          return (
            <Series
              key={key}
              dataKey={key}
              name={name}
              unit={yLabel}
              stroke={color}
              fill={color}
              {...seriesProps}
            />
          )
        })
      }
      break
    default:
      break
  }

  if (enableHover && hoverKey && series) {
    series = series.map(s => {
      if (s && hoverKey !== s.props.dataKey) {
        return React.cloneElement(s, {
          strokeOpacity: 0.1,
          fillOpacity: 0.1,
        })
      } else {
        return s
      }
    })
  }

  return (
    <Widget>
      <DashboardWidgetHeader title={widget.title} widget={widget} commands={commands} />
      <WidgetBody>
        <ResponsiveContainer>
          <Chart
            data={data.data}
            margin={{ top: 14, right: 32, left: 6 + yLabelOffset, bottom: 0 }}
            {...chartProps}
          >
            <Tooltip
              isAnimationActive={false}
              labelFormatter={tooltipLabelFormatter}
              formatter={tooltipFormatter}
              content={<ChartTooltip />}
            />
            <CartesianGrid stroke={theme.chartGridColor} vertical={false} />
            <YAxis
              scale={chartScale}
              stroke={theme.textColor}
              tickFormatter={formatInteger}
              {...yAxisProps}
            >
              <Label
                value={yLabel}
                fill={theme.textColor}
                style={{ fontWeight: 600 }}
                position="insideLeft"
                angle={-90}
                offset={4 - yLabelOffset}
                dy={20}
              />
            </YAxis>
            <XAxis stroke={theme.textColor} {...xAxisProps} />
            {series}
            <Legend
              iconSize={10}
              onMouseEnter={onMouseEnterLegend}
              onMouseLeave={onMouseLeaveLegend}
            />
          </Chart>
        </ResponsiveContainer>
      </WidgetBody>
    </Widget>
  )
}

export default ForensicChartWidget
