import * as React from "react"
import { Col, FormGroup } from "reactstrap"
import { cloneDeep, toNumber } from "lodash"
import { ErrorText } from "../common/ErrorText"
import { FormText, Label, SubSectionLabel } from "../common/Form"
import { Input } from "../common/Input"
import { Select } from "../common/Select"
import { HardwareChannelFilterValue, MediaSpec } from "../../api/types"
import {
  HardwareValueLength,
  HardwareValueOffset,
  HardwareValueOperator,
} from "../../api/types/hardwareTypes"
import { MediaSpecType } from "../../api/types/mediaTypes"
import { formatMediaSpec, parseMediaSpec } from "../../utils/mediaSpec"

type HardwareChannelValueFormProps = {
  valueFilter: HardwareChannelFilterValue
  onChangeValueFilter: (valueFilter: HardwareChannelFilterValue) => void
  onSetValidValueFilter: (id: string, invalid: boolean) => void
}

type HardwareChannelValueFormState = {
  invalidMask: boolean
  invalidOffsetLocation: boolean
  invalidOperator: boolean
  invalidValue: boolean
  mask: string
  value: string
}

export class HardwareChannelValueForm extends React.Component<
  HardwareChannelValueFormProps,
  HardwareChannelValueFormState
> {
  state: HardwareChannelValueFormState = {
    invalidMask: false,
    invalidOffsetLocation: false,
    invalidOperator: false,
    invalidValue: false,
    mask: "0xFF",
    value: "0",
  }

  componentDidMount = () => {
    const { valueFilter } = this.props

    const value = this.getValueString(
      valueFilter.value,
      valueFilter.valueMediaSpec,
      valueFilter.length
    )
    this.setState({
      invalidMask: !this.validateMask(valueFilter.mask, valueFilter.length),
      invalidOffsetLocation: !this.validateOffsetLocation(valueFilter.offsetLocation),
      invalidOperator: !this.validateOperator(valueFilter.operator, valueFilter.length),
      invalidValue: !this.validateValue(value, valueFilter.length, valueFilter.operator),
      mask: `0x${valueFilter.mask.toString(16).toUpperCase()}`,
      value,
    })
  }

  getMaskOffsetMax = (length: number) => {
    let max = 0
    switch (length) {
      default:
      case HardwareValueLength.HARDWARE_VALUE_LENGTH_1_BYTE:
        max = 0xff
        break
      case HardwareValueLength.HARDWARE_VALUE_LENGTH_2_BYTE:
        max = 0xffff
        break
      case HardwareValueLength.HARDWARE_VALUE_LENGTH_4_BYTE:
        max = 0xffffffff
        break
      case HardwareValueLength.HARDWARE_VALUE_LENGTH_MAC_ADDRESS:
      case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV4_ADDRESS:
      case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV6_ADDRESS:
        break
    }
    return max
  }

  getValueString = (value: number, valueMediaSpec: MediaSpec, length: number) => {
    let valueString = this.state.value
    try {
      switch (length) {
        default:
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_1_BYTE:
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_2_BYTE:
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_4_BYTE:
          valueString = value.toString()
          break
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_MAC_ADDRESS:
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV4_ADDRESS:
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV6_ADDRESS:
          valueString = formatMediaSpec(valueMediaSpec, { showAny: false })
          break
      }
    } catch {}
    return valueString
  }

  validateMask = (mask: number | string, length: number) => {
    const candidateMask = typeof mask === "string" ? toNumber(mask) : mask
    const offsetMaskMax = this.getMaskOffsetMax(length)
    let valid =
      !Number.isNaN(candidateMask) &&
      (offsetMaskMax === 0 || (candidateMask > 0 && candidateMask <= offsetMaskMax))

    if (valid) {
      // mask must represent a single contiuous range
      let testbit = 1
      let foundOne = false
      let foundZeroSwitch = false
      for (let i = 0; i < 32; i++) {
        if (candidateMask & testbit) {
          if (foundZeroSwitch) {
            valid = false
            break
          } else if (!foundOne) {
            foundOne = true
          }
        } else if (foundOne && !foundZeroSwitch) {
          foundZeroSwitch = true
        }
        testbit <<= 1
      }
    }

    return valid
  }

  validateOffset = (offset: number) => {
    return offset >= 0 && offset <= HardwareValueOffset.HARDWARE_VALUE_OFFSET_MAXIMUM
  }

  validateOffsetLocation = (offsetLocation: number) => {
    return !Number.isNaN(offsetLocation) && offsetLocation >= -1024 && offsetLocation <= 1024
  }

  validateOperator = (operator: number, length: number) => {
    let invalid = true
    try {
      switch (length) {
        default:
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_1_BYTE:
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_2_BYTE:
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_4_BYTE:
          invalid =
            operator < 0 || operator >= HardwareValueOperator.HARDWARE_VALUE_OPERATOR_MAXIMUM
          break
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_MAC_ADDRESS:
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV4_ADDRESS:
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV6_ADDRESS:
          invalid =
            operator !== HardwareValueOperator.HARDWARE_VALUE_OPERATOR_EQUAL_TO &&
            operator !== HardwareValueOperator.HARDWARE_VALUE_OPERATOR_NOT_EQUAL_TO
          break
      }
    } catch {}
    return !invalid
  }

  validateValue = (value: string, length: number, operator: number) => {
    let invalid = true
    try {
      const num = toNumber(value)
      switch (length) {
        default:
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_1_BYTE:
          invalid = Number.isNaN(num) || num < 0 || num > 0xff
          break
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_2_BYTE:
          invalid = Number.isNaN(num) || num < 0 || num > 0xffff
          break
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_4_BYTE:
          invalid = Number.isNaN(num) || num < 0 || num > 0xffffffff
          break
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_MAC_ADDRESS:
          parseMediaSpec(value, MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS)
          invalid = false
          break
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV4_ADDRESS:
          parseMediaSpec(value, MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS)
          invalid = false
          break
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV6_ADDRESS:
          parseMediaSpec(value, MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS)
          invalid = false
          break
      }

      if (
        !invalid &&
        operator === HardwareValueOperator.HARDWARE_VALUE_OPERATOR_LESS_THAN &&
        num <= 0
      ) {
        invalid = true
      }
    } catch {}
    return !invalid
  }

  onChangeLength = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { valueFilter } = this.props
    const length = toNumber(event.target.value)

    // change the value if necessary
    let mask = this.state.mask
    let valueMediaSpec = cloneDeep(valueFilter.valueMediaSpec)
    try {
      switch (length) {
        default:
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_1_BYTE:
          mask = "0xFF"
          valueMediaSpec = parseMediaSpec("0.0.0.0", MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS)
          break
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_2_BYTE:
          mask = "0xFFFF"
          valueMediaSpec = parseMediaSpec("0.0.0.0", MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS)
          break
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_4_BYTE:
          mask = "0xFFFFFFFF"
          valueMediaSpec = parseMediaSpec("0.0.0.0", MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS)
          break
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_MAC_ADDRESS:
          valueMediaSpec = parseMediaSpec(
            "0x000000000000",
            MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS
          )
          break
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV4_ADDRESS:
          valueMediaSpec = parseMediaSpec("0.0.0.0", MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS)
          break
        case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV6_ADDRESS:
          valueMediaSpec = parseMediaSpec("0::0", MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS)
          break
      }
    } catch {}
    const value = this.getValueString(valueFilter.value, valueMediaSpec, length)

    // validate fields that depend on length
    const invalidMask = !this.validateMask(mask, length)
    const invalidOperator = !this.validateOperator(valueFilter.operator, length)
    const invalidValue = !this.validateValue(value, length, valueFilter.operator)

    this.props.onChangeValueFilter({
      ...valueFilter,
      length,
      mask: !invalidMask ? toNumber(mask) : 0xff,
      operator: !invalidOperator
        ? valueFilter.operator
        : HardwareValueOperator.HARDWARE_VALUE_OPERATOR_EQUAL_TO,
      valueMediaSpec,
    })
    this.props.onSetValidValueFilter(
      valueFilter.id,
      invalidMask || this.state.invalidOffsetLocation || invalidValue
    )
    this.setState({ invalidMask, invalidValue, mask, value })
  }

  onChangeMask = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { valueFilter } = this.props
    const mask = event.target.value

    // validate the mask
    const invalidMask = !this.validateMask(mask, valueFilter.length)

    if (!invalidMask) {
      this.props.onChangeValueFilter({
        ...valueFilter,
        mask: toNumber(mask),
      })
    }
    this.props.onSetValidValueFilter(
      valueFilter.id,
      invalidMask ||
        this.state.invalidOffsetLocation ||
        this.state.invalidOperator ||
        this.state.invalidValue
    )
    this.setState({ invalidMask, mask })
  }

  onChangeOffset = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { valueFilter } = this.props
    const offset = toNumber(event.target.value)

    this.props.onChangeValueFilter({ ...valueFilter, offset })
    this.props.onSetValidValueFilter(
      valueFilter.id,
      this.state.invalidMask ||
        this.state.invalidOffsetLocation ||
        this.state.invalidOperator ||
        this.state.invalidValue
    )
  }

  onChangeOffsetLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { valueFilter } = this.props
    const offsetLocation = toNumber(event.target.value)

    // validate the offset location
    const invalidOffsetLocation = !this.validateOffsetLocation(offsetLocation)

    this.props.onChangeValueFilter({ ...valueFilter, offsetLocation })
    this.props.onSetValidValueFilter(
      valueFilter.id,
      this.state.invalidMask ||
        invalidOffsetLocation ||
        this.state.invalidOperator ||
        this.state.invalidValue
    )
    this.setState({ invalidOffsetLocation })
  }

  onChangeOperator = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { valueFilter } = this.props
    const operator = toNumber(event.target.value)

    // validate the operator
    const invalidOperator = !this.validateOperator(operator, valueFilter.length)

    if (!invalidOperator) {
      this.props.onChangeValueFilter({ ...valueFilter, operator })
    }

    const value = this.getValueString(
      valueFilter.value,
      valueFilter.valueMediaSpec,
      valueFilter.length
    )
    const invalidValue = !this.validateValue(value, valueFilter.length, operator)

    this.props.onSetValidValueFilter(
      valueFilter.id,
      this.state.invalidMask || this.state.invalidOffsetLocation || invalidOperator || invalidValue
    )
    this.setState({ invalidOperator, invalidValue })
  }

  onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { valueFilter } = this.props
    const { value } = event.target

    // validate the value
    const invalidValue = !this.validateValue(value, valueFilter.length, valueFilter.operator)

    if (!invalidValue) {
      try {
        switch (valueFilter.length) {
          default:
          case HardwareValueLength.HARDWARE_VALUE_LENGTH_1_BYTE:
          case HardwareValueLength.HARDWARE_VALUE_LENGTH_2_BYTE:
          case HardwareValueLength.HARDWARE_VALUE_LENGTH_4_BYTE:
            this.props.onChangeValueFilter({ ...valueFilter, value: toNumber(value) })
            break
          case HardwareValueLength.HARDWARE_VALUE_LENGTH_MAC_ADDRESS:
            this.props.onChangeValueFilter({
              ...valueFilter,
              valueMediaSpec: parseMediaSpec(value, MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS),
            })
            break
          case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV4_ADDRESS:
            this.props.onChangeValueFilter({
              ...valueFilter,
              valueMediaSpec: parseMediaSpec(value, MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS),
            })
            break
          case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV6_ADDRESS:
            this.props.onChangeValueFilter({
              ...valueFilter,
              valueMediaSpec: parseMediaSpec(value, MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS),
            })
            break
        }
      } catch {}
    }
    this.props.onSetValidValueFilter(
      valueFilter.id,
      this.state.invalidMask ||
        this.state.invalidOffsetLocation ||
        this.state.invalidOperator ||
        invalidValue
    )
    this.setState({ invalidValue, value })
  }

  render() {
    const { valueFilter } = this.props
    const { invalidMask, invalidOffsetLocation, invalidOperator, invalidValue, mask, value } =
      this.state

    // generate operator options
    let operatorOptions: React.ReactNode[] = [
      <option
        key={HardwareValueOperator.HARDWARE_VALUE_OPERATOR_EQUAL_TO}
        value={HardwareValueOperator.HARDWARE_VALUE_OPERATOR_EQUAL_TO}
      >
        = (equals)
      </option>,
      <option
        key={HardwareValueOperator.HARDWARE_VALUE_OPERATOR_NOT_EQUAL_TO}
        value={HardwareValueOperator.HARDWARE_VALUE_OPERATOR_NOT_EQUAL_TO}
      >
        != (not equals)
      </option>,
    ]
    switch (valueFilter.length) {
      default:
      case HardwareValueLength.HARDWARE_VALUE_LENGTH_1_BYTE:
      case HardwareValueLength.HARDWARE_VALUE_LENGTH_2_BYTE:
      case HardwareValueLength.HARDWARE_VALUE_LENGTH_4_BYTE:
        operatorOptions = operatorOptions.concat([
          <option
            key={HardwareValueOperator.HARDWARE_VALUE_OPERATOR_GREATER_THAN}
            value={HardwareValueOperator.HARDWARE_VALUE_OPERATOR_GREATER_THAN}
          >
            &gt; (greater than)
          </option>,
          <option
            key={HardwareValueOperator.HARDWARE_VALUE_OPERATOR_GREATER_THAN_OR_EQUAL_TO}
            value={HardwareValueOperator.HARDWARE_VALUE_OPERATOR_GREATER_THAN_OR_EQUAL_TO}
          >
            &gt;= (greater than or equals)
          </option>,
          <option
            key={HardwareValueOperator.HARDWARE_VALUE_OPERATOR_LESS_THAN}
            value={HardwareValueOperator.HARDWARE_VALUE_OPERATOR_LESS_THAN}
          >
            &lt; (less than)
          </option>,
          <option
            key={HardwareValueOperator.HARDWARE_VALUE_OPERATOR_LESS_THAN_OR_EQUAL_TO}
            value={HardwareValueOperator.HARDWARE_VALUE_OPERATOR_LESS_THAN_OR_EQUAL_TO}
          >
            &lt;= (less than or equals)
          </option>,
        ])
        break
      case HardwareValueLength.HARDWARE_VALUE_LENGTH_MAC_ADDRESS:
      case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV4_ADDRESS:
      case HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV6_ADDRESS:
        break
    }

    return (
      <>
        <FormGroup row>
          <Label md="3" for={`valueFilterOffsetLocation${valueFilter.id}`}>
            <SubSectionLabel>Offset</SubSectionLabel>
          </Label>
          <Col md="9">
            <Input
              type="number"
              name={`valueFilterOffsetLocation${valueFilter.id}`}
              id={`valueFilterOffsetLocation${valueFilter.id}`}
              value={valueFilter.offsetLocation}
              onChange={this.onChangeOffsetLocation}
              required
              invalid={invalidOffsetLocation}
              min={-1024}
              max={1024}
              step={1}
            />
            {invalidOffsetLocation && (
              <ErrorText>Must be between -1024 and 1024 (inclusive)</ErrorText>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3" for={`valueFilterOffset${valueFilter.id}`}>
            <SubSectionLabel>Offset Relative To</SubSectionLabel>
          </Label>
          <Col md="9">
            <Select
              name={`valueFilterOffset${valueFilter.id}`}
              id={`valueFilterOffset${valueFilter.id}`}
              value={valueFilter.offset}
              onChange={this.onChangeOffset}
            >
              <option value={HardwareValueOffset.HARDWARE_VALUE_OFFSET_BEGINNING_OF_PACKET}>
                the beginning of each packet
              </option>
              <option value={HardwareValueOffset.HARDWARE_VALUE_OFFSET_LAYER_3_HEADER}>
                the beginning of the Layer 3 header
              </option>
              <option value={HardwareValueOffset.HARDWARE_VALUE_OFFSET_LAYER_4_HEADER}>
                the beginning of the Layer 4 header
              </option>
              <option value={HardwareValueOffset.HARDWARE_VALUE_OFFSET_LAYER_4_PAYLOAD}>
                the beginning of the Layer 4 payload
              </option>
              <option value={HardwareValueOffset.HARDWARE_VALUE_OFFSET_INNER_LAYER_3_HEADER}>
                the beginning of the inner Layer 3 header
              </option>
              <option value={HardwareValueOffset.HARDWARE_VALUE_OFFSET_INNER_LAYER_4_HEADER}>
                the beginning of the inner Layer 4 header
              </option>
              <option value={HardwareValueOffset.HARDWARE_VALUE_OFFSET_INNER_LAYER_4_PAYLOAD}>
                the beginning of the inner Layer 4 payload
              </option>
            </Select>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3" for={`valueFilterLength${valueFilter.id}`}>
            <SubSectionLabel>Length</SubSectionLabel>
          </Label>
          <Col md="9">
            <Select
              name={`valueFilterLength${valueFilter.id}`}
              id={`valueFilterLength${valueFilter.id}`}
              value={valueFilter.length}
              onChange={this.onChangeLength}
            >
              <option value={HardwareValueLength.HARDWARE_VALUE_LENGTH_1_BYTE}>1 Byte</option>
              <option value={HardwareValueLength.HARDWARE_VALUE_LENGTH_2_BYTE}>2 Bytes</option>
              <option value={HardwareValueLength.HARDWARE_VALUE_LENGTH_4_BYTE}>4 Bytes</option>
              <option value={HardwareValueLength.HARDWARE_VALUE_LENGTH_MAC_ADDRESS}>
                MAC Address
              </option>
              <option value={HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV4_ADDRESS}>
                IPv4 Address
              </option>
              <option value={HardwareValueLength.HARDWARE_VALUE_LENGTH_IPV6_ADDRESS}>
                IPv6 Address
              </option>
            </Select>
          </Col>
        </FormGroup>
        {(valueFilter.length === HardwareValueLength.HARDWARE_VALUE_LENGTH_1_BYTE ||
          valueFilter.length === HardwareValueLength.HARDWARE_VALUE_LENGTH_2_BYTE ||
          valueFilter.length === HardwareValueLength.HARDWARE_VALUE_LENGTH_4_BYTE) && (
          <>
            <FormGroup row>
              <Label md="3">
                <SubSectionLabel>Mask</SubSectionLabel>
              </Label>
              <Col md="9">
                <Input
                  type="text"
                  name={`valueFilterMask${valueFilter.id}`}
                  id={`valueFilterMask${valueFilter.id}`}
                  value={mask}
                  onChange={this.onChangeMask}
                  required
                  invalid={invalidMask}
                />
                <FormText>Must represent only a single continuous range of bits.</FormText>
              </Col>
            </FormGroup>
          </>
        )}
        <FormGroup row>
          <Label md="3" for={`valueFilterOperator${valueFilter.id}`}>
            <SubSectionLabel>Operator</SubSectionLabel>
          </Label>
          <Col md="9">
            <Select
              name={`valueFilterOperator${valueFilter.id}`}
              id={`valueFilterOperator${valueFilter.id}`}
              value={valueFilter.operator}
              invalid={invalidOperator}
              onChange={this.onChangeOperator}
            >
              {operatorOptions}
            </Select>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label md="3" for={`valueFilterValue${valueFilter.id}`}>
            <SubSectionLabel>Value</SubSectionLabel>
          </Label>
          <Col md="9">
            <Input
              type="text"
              name={`valueFilterValue${valueFilter.id}`}
              id={`valueFilterValue${valueFilter.id}`}
              value={value}
              onChange={this.onChangeValue}
              required
              invalid={invalidValue}
            />
          </Col>
        </FormGroup>
      </>
    )
  }
}
