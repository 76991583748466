import { ExpertColumn } from "../../../api/types/expertTypes"

export type ExpertSearch = {
  clientAddress: string
  clientPort: string
  serverAddress: string
  serverPort: string
  protocol: string
  application: string
  mpls: string
  vlan: string
  vxlanGPID: string
  vxlanVNI: string
  start: string
  finish: string
}

export const emptyExpertSearch: ExpertSearch = {
  clientAddress: "",
  clientPort: "",
  serverAddress: "",
  serverPort: "",
  protocol: "",
  application: "",
  mpls: "",
  vlan: "",
  vxlanGPID: "",
  vxlanVNI: "",
  start: "",
  finish: "",
} as const

export type ExpertSearchGroup = {
  columnId: ExpertColumn
  columnIndex: number
  searchValue: string
}
