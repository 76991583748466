import * as React from "react"
import { AlertProps as ReactstrapUncontrolledAlertProps } from "reactstrap"
import { Alert } from "../Alert"

type UncontrolledAlertProps = ReactstrapUncontrolledAlertProps & {
  fade?: boolean // Missing prop from reactstrap types
  component?: React.ComponentType<React.PropsWithChildren<any>>
}

type UncontrolledAlertState = {
  isOpen: boolean
}

export class UncontrolledAlert extends React.PureComponent<
  UncontrolledAlertProps,
  UncontrolledAlertState
> {
  state: UncontrolledAlertState = {
    isOpen: true,
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    let { component: AlertComponent } = this.props
    if (!AlertComponent) {
      AlertComponent = Alert
    }
    return <AlertComponent isOpen={this.state.isOpen} toggle={this.toggle} {...this.props} />
  }
}

export default UncontrolledAlert
