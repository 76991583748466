export enum Wireless80211Bands {
  WIRELESS_802_11_BANDS_ALL_BANDS = 0, // All
  WIRELESS_802_11_BANDS_BAND = 1, // vanilla 802.11 band (2MBit, 2.4GHz)
  WIRELESS_802_11_BANDS_B_BAND = 2, // 802.11b band (11MBit 2.4GHz)
  WIRELESS_802_11_BANDS_A_BAND = 3, // 802.11a band (54MBit 5GHz)
  WIRELESS_802_11_BANDS_G_BAND = 4, // 802.11g band (54MBit 2.4GHz)
  WIRELESS_802_11_BANDS_N_BAND = 6, // 802.11n beam forming band 100MBit+ (still not released)
  WIRELESS_802_11_BANDS_A_TURBO_BAND = 7, // 802.11a doubled speed (108 Mbit 5GHz)
  WIRELESS_802_11_BANDS_G_TURBO_BAND = 8, // 802.11g doubled speed (108Mbit 5Ghz)
  WIRELESS_802_11_BANDS_SUPER_G_BAND = 9, // 802.11g dynamic turbo band
  WIRELESS_802_11_BANDS_A_LICENSED_1MHZ_BAND = 100, // [La1]"802.11a 1MHz licensed band" (FCC only for now)
  WIRELESS_802_11_BANDS_A_LICENSED_5MHZ_BAND = 101, // [La5]"802.11a 5MHz licensed band" (FCC only for now)
  WIRELESS_802_11_BANDS_A_LICENSED_10MHZ_BAND = 102, // [La10]"802.11a 10MHz licensed band" (FCC only for now)
  WIRELESS_802_11_BANDS_A_LICENSED_15MHZ_BAND = 103, // [La15]"802.11a 15MHz licensed band" (FCC only for now)
  WIRELESS_802_11_BANDS_A_LICENSED_20MHZ_BAND = 104, // [La20]"802.11a 20MHz licensed band" (FCC only for now)
  WIRELESS_802_11_BANDS_AC_BW80_PRIMARY_0_BAND = 201, // 802.11ac primary 0
  WIRELESS_802_11_BANDS_AC_BW80_PRIMARY_1_BAND = 202, // 802.11ac primary 1
  WIRELESS_802_11_BANDS_AC_BW80_PRIMARY_2_BAND = 203, // 802.11ac primary 2
  WIRELESS_802_11_BANDS_AC_BW80_PRIMARY_3_BAND = 204, // 802.11ac primary 3
  WIRELESS_802_11_BANDS_UNKNOWN_5_BAND = 205, // [u5]"Unknown 5"
  WIRELESS_802_11_BANDS_UNKNOWN_6_BAND = 206, // [u6]"Unknown 6"
  WIRELESS_802_11_BANDS_UNKNOWN_7_BAND = 207, // [u7]"Unknown 7"
  WIRELESS_802_11_BANDS_UNKNOWN_8_BAND = 208, // [u8]"Unknown 8"
  WIRELESS_802_11_BANDS_UNKNOWN_9_BAND = 209, // [u9]"Unknown 9"
  WIRELESS_802_11_BANDS_N_20MHZ_BAND = 300, // [n20] "802.11n 20MHz"
  WIRELESS_802_11_BANDS_N_40MHZ_BAND = 301, // [n40] "802.11n 40MHz"
  WIRELESS_802_11_BANDS_N_40MHZ_LOW_BAND = 302, // [n40l] "802.11n 40MHz low"
  WIRELESS_802_11_BANDS_N_40MHZ_HIGH_BAND = 303, // [n40h] "802.11n 40MHz high"
  WIRELESS_802_11_BANDS_AC_RESERVED = 400, // 802.11ac Cisco AP sends this value sometimes. No one knows what it means.
  WIRELESS_802_11_BANDS_AC_NSS_2_BAND_0 = 401, // 802.11ac
  WIRELESS_802_11_BANDS_AC_NSS_2_BAND_1 = 402, // 802.11ac
  WIRELESS_802_11_BANDS_AC_NSS_2_BAND_2 = 403, // 802.11ac
  WIRELESS_802_11_BANDS_AC_NSS_2_BAND_3 = 404, // 802.11ac
}

export const WIRELESS_802_11_BANDS_BG_BAND = Wireless80211Bands.WIRELESS_802_11_BANDS_G_BAND
