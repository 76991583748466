import * as React from "react"
import { cloneDeep, isNumber, isString } from "lodash"
import styled, { useTheme, DefaultTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import { Bar, BarChart, CartesianGrid, Cell, LabelList, XAxis, YAxis } from "recharts"
import { Link } from "../common/Link"
import { Panel } from "../common/Panel"
import { SecondaryButton, SecondaryDangerButton } from "../common/Buttons"
import PropTable from "../common/PropTable"
import { MutedText } from "../common/MutedText"
import { getCaptureButtonText } from "../../utils/captureUtils"
import { formatPercentChartLabel } from "../../utils/chartUtils"
import { propToLabel, formatProp } from "../../utils/propUtils"
import { getEngineCaptureUrl } from "../../routes"
import { CaptureProperties } from "../../api/types"

type PropList = (string | null)[][]

const forensicCapturePropList: PropList = [
  ["status", "startTime"],
  ["adapter", "stopTime"],
  ["linkSpeed", "duration"],
  ["mediaType", "dataSizeReserved"],
  ["ctdEnabled", "filtersEnabled"],
  ["ctdIntelligent", "hardwareProfileName"],
  ["indexingEnabled", "creator"],
  ["packetsReceived", "modificationBy"],
  ["packetsFiltered", "modificationType"],
  ["packetsDropped", null],
] as const

const defaultPropList: PropList = [
  ["status", "startTime"],
  ["adapter", "stopTime"],
  ["linkSpeed", "duration"],
  ["mediaType", "bufferCapacity"],
  ["ctdEnabled", "alarms"],
  ["packetsReceived", "filtersEnabled"],
  ["packetsFiltered", "hardwareProfileName"],
  ["packetsAnalyzed", "creator"],
  ["analysisDroppedPackets", "modificationBy"],
  ["packetsDropped", "modificationType"],
] as const

function getDefaultPropList(captureProps: CaptureProperties) {
  return captureProps.ctdEnabled && !captureProps.packetBufferEnabled
    ? forensicCapturePropList
    : defaultPropList
}

const HorizontalGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
`

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  text-decoration: none;
  grid-column-end: span 1;
  grid-row-end: span 1;
  align-items: center;
  justify-content: end;
`

const GridItemTitle = styled.h4`
  margin: 0;
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: ${props => props.theme.textMutedColor};
`

const GridItemValue = styled.div`
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  text-decoration: none;
  color: ${props => props.theme.textColor};
`

const DataReductionChart = ({ captureProps }: { captureProps: ExtendedCaptureProperties }) => {
  const theme = useTheme() as DefaultTheme

  if (captureProps.combinedRatio === undefined) return null

  let slicing: number | undefined
  if (captureProps.slicingRatio !== undefined) {
    slicing = (1 - captureProps.slicingRatio) * 100
  }
  if (slicing === 0) {
    slicing = undefined
  }

  let compression: number | undefined
  if (captureProps.compressionRatio !== undefined) {
    compression = (1 - captureProps.compressionRatio) * 100
  }
  if (compression === 0) {
    compression = undefined
  }

  const combined = (1 - captureProps.combinedRatio) * 100

  const data: Array<{ name: string; val: number; color: string }> = []
  if (slicing !== undefined) {
    data.push({ name: "Slicing", val: slicing, color: theme.dataReductionSlicingColor })
  }
  if (compression !== undefined) {
    data.push({ name: "Compression", val: compression, color: theme.dataReductionCompressionColor })
  }
  if (combined !== undefined) {
    data.push({ name: "Total", val: combined, color: theme.dataReductionCombinedColor })
  }

  if (data.length === 0) return null

  // Compute the height with bar width, gap, and margin.
  const height = data.length * 10 + (data.length - 1) * 4 + 10

  return (
    <BarChart
      width={220}
      height={height}
      data={data}
      margin={{ top: 0, right: 32, left: 24, bottom: 8 }}
      layout="vertical"
    >
      <CartesianGrid stroke={theme.chartGridColor} horizontal={false} />
      <Bar dataKey="val" layout="vertical" isAnimationActive={false} barSize={10}>
        <LabelList
          dataKey="val"
          position="right"
          formatter={formatPercentChartLabel}
          fontSize={10}
          fill={theme.textMutedColor}
          stroke="none"
        />
        {data.map(item => (
          <Cell key={item.name} fill={item.color} />
        ))}
      </Bar>
      <YAxis
        dataKey="name"
        type="category"
        style={{ textTransform: "uppercase" }}
        fontSize={10}
        stroke={theme.propTableHeaderColor}
        interval={0}
      />
      <XAxis hide={true} type="number" domain={[0, 100]} />
    </BarChart>
  )
}

const DataRetentionGrid = ({
  captureProps,
  propToLabel,
  formatProp,
}: {
  captureProps: ExtendedCaptureProperties | null
  propToLabel: (prop: string) => string
  formatProp: (prop: string, data: any) => React.ReactNode
}) => {
  if (captureProps === null) return null
  return (
    <HorizontalGrid>
      <GridItem>
        {captureProps.retentionFactor ? (
          <DataReductionChart captureProps={captureProps} />
        ) : (
          <GridItemValue>{"\u2014"}</GridItemValue>
        )}
        <GridItemTitle>Data Reduction</GridItemTitle>
      </GridItem>
      <GridItem>
        <GridItemValue>{formatProp("retentionFactor", captureProps) ?? "\u2014"}</GridItemValue>
        <GridItemTitle>{propToLabel("retentionFactor")}</GridItemTitle>
      </GridItem>
      <GridItem>
        <GridItemValue>{formatProp("retentionTime", captureProps) ?? "\u2014"}</GridItemValue>
        <GridItemTitle>{propToLabel("retentionTime")}</GridItemTitle>
      </GridItem>
    </HorizontalGrid>
  )
}

const Capture = styled(Panel)`
  display: flex;
  flex-direction: row;

  & + & {
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const CaptureItem = styled.div`
  flex-grow: 1;
`

const ButtonStrip = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 1rem;

  & button {
    white-space: nowrap;
  }

  & button + button {
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    padding: 1rem 0 0 0;

    & button + button {
      margin-left: 4px;
      margin-top: 0;
    }
  }
`

type ExtendedCaptureProperties = CaptureProperties & {
  combinedRatio?: number
  retentionTime?: number
}

type CapturePanelProps = {
  capture: CaptureProperties
  isViewEnabled: boolean
  isStartStopPending: boolean
  isDeletePending: boolean
  isStartStopEnabled: boolean
  isCaptureOptionsEnabled: boolean
  isDeleteCaptureEnabled: boolean
  onToggleCapture: (capId: string) => void
  onCaptureOptions: (capId: string) => void
  onDeleteCapture: (capId: string) => void
  showLocalTime: boolean
}

const CapturePanel = (props: CapturePanelProps) => {
  const {
    isViewEnabled,
    isStartStopPending,
    isDeletePending,
    isStartStopEnabled,
    isCaptureOptionsEnabled,
    isDeleteCaptureEnabled,
    onToggleCapture,
    onCaptureOptions,
    onDeleteCapture,
    showLocalTime,
  } = props

  const capture: ExtendedCaptureProperties = cloneDeep(props.capture)
  if (isNumber(capture.slicingRatio) && isNumber(capture.compressionRatio)) {
    capture.combinedRatio = capture.slicingRatio * capture.compressionRatio
  } else if (isNumber(capture.slicingRatio)) {
    capture.combinedRatio = capture.slicingRatio
  } else {
    capture.combinedRatio = capture.compressionRatio
  }
  if (isString(capture.dataStartTime) && isString(capture.dataStopTime)) {
    const start = Date.parse(capture.dataStartTime)
    const stop = Date.parse(capture.dataStopTime)
    capture.retentionTime = (stop - start) * 1000000
  }

  const captureStatusText = getCaptureButtonText(capture.status)
  const capturePropList = cloneDeep(getDefaultPropList(capture))
  if (capture.spotlightCapture) {
    capturePropList.push(["flowsDropped", null])
  }

  return (
    <Capture key={capture.id}>
      <CaptureItem>
        <h5>
          {!isViewEnabled ? (
            capture.name
          ) : (
            <Link to={getEngineCaptureUrl(capture.id)}>{capture.name}</Link>
          )}
          {capture.comment && <MutedText as="small"> {capture.comment}</MutedText>}
        </h5>
        <PropTable
          propList={capturePropList}
          data={capture}
          propToLabel={propToLabel}
          formatProp={(prop: string, data: any) => {
            return formatProp(prop, data, { showLocalTime })
          }}
        />
        {"retentionFactor" in capture && (
          <DataRetentionGrid
            captureProps={capture}
            propToLabel={propToLabel}
            formatProp={(prop: string, data: any) => {
              return formatProp(prop, data, { showLocalTime })
            }}
          />
        )}
      </CaptureItem>
      <ButtonStrip>
        <SecondaryButton
          size="sm"
          disabled={!isStartStopEnabled || isStartStopPending}
          onClick={onToggleCapture.bind(this, capture.id)}
        >
          <FontAwesome
            fixedWidth
            name={isStartStopPending ? "circle-o-notch" : captureStatusText.icon}
            spin={isStartStopPending}
          />{" "}
          {captureStatusText.text}
        </SecondaryButton>
        <SecondaryButton
          size="sm"
          disabled={!isCaptureOptionsEnabled}
          onClick={onCaptureOptions.bind(this, capture.id)}
        >
          <FontAwesome fixedWidth name="sliders" /> Capture Options
        </SecondaryButton>
        <SecondaryDangerButton
          size="sm"
          disabled={!isDeleteCaptureEnabled || isDeletePending}
          onClick={onDeleteCapture.bind(this, capture.id)}
        >
          <FontAwesome
            fixedWidth
            name={isDeletePending ? "circle-o-notch" : "trash-o"}
            spin={isDeletePending}
          />{" "}
          Delete Capture
        </SecondaryDangerButton>
      </ButtonStrip>
    </Capture>
  )
}

export default CapturePanel
