import styled from "styled-components"

export const Widget = styled.section`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: ${props => props.theme.panelBorder};
  background: ${props => props.theme.panelBackground};
  border-radius: ${props => props.theme.panelBorderRadius};
  box-shadow: ${props => props.theme.panelBoxShadow};
  height: inherit;
  width: inherit;
  overflow: hidden;
`

export const WidgetHeader = styled.div.attrs({
  className: "grid-drag-handle",
})`
  flex: 0 0 auto;
  display: flex;
  cursor: move;
  margin: 0;
  padding: 4px 8px;
  border-bottom: ${props => props.theme.panelBorder};
  background: ${props => props.theme.panelTitleBackground};
  border-top-left-radius: ${props => props.theme.panelBorderRadius};
  border-top-right-radius: ${props => props.theme.panelBorderRadius};
`

export const WidgetTitle = styled.h5`
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
`

export const WidgetSubTitle = styled.span`
  margin-left: 0.5rem;
  font-size: 80%;
  font-weight: normal;
  color: ${props => props.theme.textMutedColor};
`

export const WidgetOptions = styled.span.attrs({
  className: "grid-drag-cancel show-on-hover",
})`
  margin-left: auto;
  display: flex;
`

export const WidgetBody = styled.div<{ minHeight?: string }>`
  flex: 1 1 100%;
  padding: ${props => props.theme.panelPadding};
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${props => (props.minHeight ? props.minHeight : "auto")};
  box-sizing: content-box;
  min-height: 0;
  overflow: hidden;
`

export const WidgetMessage = styled.h5`
  display: inline-block;
  margin: 0;
  text-align: center;
  color: ${props => props.theme.textMutedColor};
`
