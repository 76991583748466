export enum MediaType {
  MEDIA_TYPE_802_3 = 0, // Ethernet (802.3)
  MEDIA_TYPE_802_5 = 1, // Token Ring (802.5)
  MEDIA_TYPE_FDDI = 2, // FDDI
  MEDIA_TYPE_WAN = 3, // WAN
  MEDIA_TYPE_LOCALTALK = 4, // LocalTalk
  MEDIA_TYPE_DIX = 5, // DEC/Intel/Xerox (DIX) Ethernet
  MEDIA_TYPE_ARCNET_RAW = 6, // ARCNET (raw)
  MEDIA_TYPE_ARCNET_878_2 = 7, // ARCNET (878.2)
  MEDIA_TYPE_ATM = 8, // ATM
  MEDIA_TYPE_WIRELESS_WAN = 9, // Various wireless media
  MEDIA_TYPE_IRDA = 10, // Infrared (IrDA)
  MEDIA_TYPE_BPC = 11, // Broadcast Architecture
  MEDIA_TYPE_COWAN = 12, // Connection-oriented WAN
  MEDIA_TYPE_1394 = 13, // IEEE 1394 (FireWire)
}

export enum MediaSubType {
  MEDIA_SUBTYPE_NATIVE = 0, // Native media specified by the media type.
  MEDIA_SUBTYPE_802_11_B = 1, // Wireless LAN network using 802.11b.
  MEDIA_SUBTYPE_802_11_A = 2, // Wireless LAN network using 802.11a.
  MEDIA_SUBTYPE_802_11_GEN = 3, // All possible 802.11 types, a, b, g, and combinations
  MEDIA_SUBTYPE_WAN_PPP = 4, // PPP over a T1 or an E1
  MEDIA_SUBTYPE_WAN_FRAME_RELAY = 5, // Frame Relay
  MEDIA_SUBTYPE_WAN_X25 = 6, // X.25
  MEDIA_SUBTYPE_WAN_X25E = 7, // X.25mod128
  MEDIA_SUBTYPE_WAN_IPARS = 8, // IPARS
  MEDIA_SUBTYPE_WAN_U200 = 9, // U200
  MEDIA_SUBTYPE_WAN_Q931 = 10, // Q.931
}

export enum MediaDomain {
  MEDIA_DOMAIN_NULL = 0, // No domain
  MEDIA_DOMAIN_FCC = 1, // Federal Communications Commission (United States)
  MEDIA_DOMAIN_MKK = 2, // Musen Setsubi Kensa Kentei Kyokai (Japan)
  MEDIA_DOMAIN_ETSI = 3, // European Telecommunications Standards Institute (Europe)
}

export enum MediaSpecClass {
  MEDIA_SPEC_CLASS_NULL = 0, // No class.
  MEDIA_SPEC_CLASS_PROTOCOL = 1, // Protocol media spec.
  MEDIA_SPEC_CLASS_ADDRESS = 2, // Address media spec.
  MEDIA_SPEC_CLASS_PORT = 3, // Port media spec.
}

export enum MediaSpecType {
  MEDIA_SPEC_TYPE_NULL = 0, // No media spec type
  MEDIA_SPEC_TYPE_ETHERNET_PROTOCOL = 1, // Ethernet protocol
  MEDIA_SPEC_TYPE_LSAP = 2, // LSAP
  MEDIA_SPEC_TYPE_SNAP = 3, // SNAP
  MEDIA_SPEC_TYPE_LAP = 4, // AppleTalk LAP
  MEDIA_SPEC_TYPE_DDP = 5, // AppleTalk DDP
  MEDIA_SPEC_TYPE_MAC_CONTROL = 6, // MAC control
  MEDIA_SPEC_TYPE_PROTOSPEC_HIERARCHY = 7, // ProtoSpec ID Hierarchy
  MEDIA_SPEC_TYPE_APPLICATION_ID = 8, // NAVL Application ID
  MEDIA_SPEC_TYPE_PROTOSPEC = 9, // ProtoSpec
  MEDIA_SPEC_TYPE_ETHERNET_ADDRESS = 10, // Ethernet address
  MEDIA_SPEC_TYPE_TOKEN_RING_ADDRESS = 11, // Token Ring address
  MEDIA_SPEC_TYPE_LAP_ADDRESS = 12, // AppleTalk LAP address
  MEDIA_SPEC_TYPE_WIRELESS_ADDRESS = 13, // Wireless address
  MEDIA_SPEC_TYPE_APP_ID = 14, // PACE2 Application ID
  MEDIA_SPEC_TYPE_APPLETALK_ADDRESS = 20, // AppleTalk address
  MEDIA_SPEC_TYPE_IP_ADDRESS = 21, // IP address
  MEDIA_SPEC_TYPE_DECNET_ADDRESS = 22, // DECnet address
  MEDIA_SPEC_TYPE_OTHER_ADDRESS = 23, // Other address
  MEDIA_SPEC_TYPE_IPV6_ADDRESS = 24, // IPv6 address
  MEDIA_SPEC_TYPE_IPX_ADDRESS = 25, // IPX address
  MEDIA_SPEC_TYPE_ERROR = 32, // Error
  MEDIA_SPEC_TYPE_AT_PORT = 33, // AppleTalk port
  MEDIA_SPEC_TYPE_IP_PORT = 34, // IP port
  MEDIA_SPEC_TYPE_NW_PORT = 35, // NetWare port
  MEDIA_SPEC_TYPE_TCP_PORT_PAIR = 36, // TCP port port
  MEDIA_SPEC_TYPE_WAN_PPP_PROTOCOL = 37, // WAN PPP protocol
  MEDIA_SPEC_TYPE_WAN_FRAME_RELAY_PROTOCOL = 38, // WAN FrameRelay protocol
  MEDIA_SPEC_TYPE_WAN_X25_PROTOCOL = 39, // WAN X.25 protocol
  MEDIA_SPEC_TYPE_WAN_X25E_PROTOCOL = 40, // WAN X.25mod128 protocol
  MEDIA_SPEC_TYPE_WAN_IPARS_PROTOCOL = 41, // WAN IPARS protocol
  MEDIA_SPEC_TYPE_WAN_U200_PROTOCOL = 42, // WAN U200 protocol
  MEDIA_SPEC_TYPE_WAN_DLCI_ADDRESS = 43, // WAN DLCI address
  MEDIA_SPEC_TYPE_WAN_Q931_PROTOCOL = 44, // WAN Q931 protocol
}
