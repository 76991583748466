import * as React from "react"
import { cloneDeep } from "lodash"
import { produce } from "immer"
import { Form, FormGroup } from "reactstrap"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { DateTimePicker } from "../common/DateTimePicker"
import { CheckGroup, Label } from "../common/Form"
import { Input } from "../common/Input"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import {
  AuthenticationToken,
  RequestCreateAuthenticationToken,
  RequestModifyAuthenticationToken,
} from "../../api/types"

const defaultAuthenticationToken: AuthenticationToken = {
  authentication: true,
  authTokenId: "",
  client: "",
  enabled: true,
  expirationTime: new Date().toISOString(),
  label: "",
  lastActivityTime: "",
  userDomain: "",
  userId: "",
  userInfoId: 0,
  userName: "",
} as const

export const getRequestCreateToken = (
  token: AuthenticationToken
): RequestCreateAuthenticationToken => {
  return {
    authentication: token.authentication,
    client: token.client,
    enabled: token.enabled,
    expirationTime: token.expirationTime,
    label: token.label,
    userDomain: token.userDomain,
    userId: token.userId,
    userInfoId: token.userInfoId,
    userName: token.userName,
  }
}

export const getRequestModifyToken = (
  token: AuthenticationToken
): RequestModifyAuthenticationToken => {
  return {
    authentication: token.authentication,
    client: token.client,
    enabled: token.enabled,
    expirationTime: token.expirationTime,
    label: token.label,
  }
}

type APITokenModalProps = {
  token: AuthenticationToken | null
  showLocalTime: boolean
  onOK: (token: AuthenticationToken) => void
  onCancel: () => void
}

type APITokenModalState = {
  token: AuthenticationToken
}

class APITokenModal extends React.Component<APITokenModalProps, APITokenModalState> {
  state: APITokenModalState = {
    token: cloneDeep(this.props.token !== null ? this.props.token : defaultAuthenticationToken),
  }

  componentDidMount() {
    // set expiration date of new tokens to 1 year in the future
    if (this.props.token === null) {
      this.setState(
        produce(draft => {
          const newDate = new Date()
          newDate.setFullYear(newDate.getFullYear() + 1)
          draft.token.expirationTime = newDate.toISOString()
        })
      )
    }
  }

  onChangeBoolean = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { token } = this.state
    this.setState({
      token: {
        ...token,
        [event.target.name]: event.target.checked,
      },
    })
  }

  onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { token } = this.state
    this.setState({
      token: {
        ...token,
        [event.target.name]: event.target.value,
      },
    })
  }

  onChangeExpirationTime = (date: Date | null) => {
    if (date != null) {
      const { token } = this.state
      const expirationTime = date.toISOString()
      this.setState({
        token: {
          ...token,
          expirationTime,
        },
      })
    } else {
      const { token } = this.state
      this.setState({
        token: {
          ...token,
          expirationTime: "",
        },
      })
    }
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    this.props.onOK(this.state.token)
  }

  render() {
    const { onCancel, showLocalTime } = this.props
    const { token } = this.state

    // validate token
    const isExpirationDateValid =
      token.expirationTime.length !== 0 && Date.parse(token.expirationTime) > Date.now()
    const isValid = token.label.length !== 0 && isExpirationDateValid

    return (
      <Modal isOpen={true}>
        <ModalHeader toggle={onCancel}>
          {this.props.token !== null ? "Edit" : "Insert"} API Token
        </ModalHeader>
        <ModalBody>
          <Form id="apiTokenForm" onSubmit={this.onSubmit} noValidate>
            <FormGroup>
              <Label for="label">Label</Label>
              <Input
                type="text"
                name="label"
                id="label"
                onChange={this.onChangeText}
                value={token.label}
                invalid={token.label.length === 0}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="enabled">Enabled</Label>
              <CheckGroup
                type="checkbox"
                name="enabled"
                id="enabled"
                onChange={this.onChangeBoolean}
                checked={token.enabled}
              >
                API token in use
              </CheckGroup>
            </FormGroup>
            {this.props.token !== null && (
              <FormGroup>
                <Label for="user">User</Label>
                <Input
                  type="text"
                  name="user"
                  id="user"
                  disabled={true}
                  value={
                    token.userDomain.length > 0
                      ? `${token.userDomain}\\${token.userName}`
                      : token.userName
                  }
                />
              </FormGroup>
            )}
            {/*<FormGroup>
              <Label for="authentication">Authentication</Label>
              <CheckGroup
                type="checkbox"
                name="authentication"
                id="authentication"
                onChange={this.onChangeBoolean}
                checked={token.authentication}
              >
                Require authentication to use API token
              </CheckGroup>
            </FormGroup>*/}
            <FormGroup noMargin>
              <Label for="expirationTime">Expiration Time {showLocalTime ? "" : "(UTC)"}</Label>
              <DateTimePicker
                id="expirationTime"
                selected={new Date(token.expirationTime)}
                onChange={this.onChangeExpirationTime}
                showLocalTime={showLocalTime}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton type="submit" form="apiTokenForm" disabled={!isValid}>
            OK
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    )
  }
}

export default APITokenModal
