import React, { ReactNode, createContext, useContext } from "react"

interface ReconstructionContents {
  contentType: string
  fileSize: string
  slicedMessage: string
  ellipse: JSX.Element
}

type ExtendedProps<Props> = Props & {
  children?: ReactNode
}

const ReconstructionContentsContext = createContext<ReconstructionContents | undefined>(undefined)

export const useReconstructionsContentsContext = () => {
  const context = useContext(ReconstructionContentsContext)

  if (!context) {
    throw new Error(
      "useReconstructionsContextsContext must be used within an ReconstructionsContentsProvider"
    )
  }

  return context
}

const ReconstructionsContentsProvider: React.FC<ExtendedProps<ReconstructionContents>> = ({
  contentType,
  fileSize,
  slicedMessage,
  ellipse,
  children,
}) => {
  return (
    <ReconstructionContentsContext.Provider
      value={{ contentType, fileSize, slicedMessage, ellipse }}
    >
      {children}
    </ReconstructionContentsContext.Provider>
  )
}

export default ReconstructionsContentsProvider
