import * as React from "react"
import { toNumber } from "lodash"
import { Input } from "../common/Input"
import { CheckGroup, HorizontalFormGroup, InlineLabel, SubSection } from "../common/Form"
import { Select } from "../common/Select"
import { PeekStatsLimitSettings } from "../../api/types"

type StatsLimitFormProps = {
  baseName: string
  statsLimit: PeekStatsLimitSettings | null
  setStatsLimit: (statsLimit: PeekStatsLimitSettings) => void
}

export class StatsLimitForm extends React.PureComponent<StatsLimitFormProps> {
  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { statsLimit } = this.props
    if (statsLimit) {
      const name = event.target.name.split("-")[1]
      switch (name) {
        case "limit":
        case "severity":
          this.props.setStatsLimit({
            ...statsLimit,
            [name]: toNumber(event.target.value),
          })
          break
        case "notify":
        case "enabled":
          this.props.setStatsLimit({
            ...statsLimit,
            [name]: event.target.checked,
          })
          break
        case "stop":
        case "reset":
          this.props.setStatsLimit({
            ...statsLimit,
            reset: event.target.checked && name === "reset",
          })
          break
        default:
          break
      }
    }
  }

  render() {
    const { baseName, statsLimit } = this.props
    if (!statsLimit) return null

    return (
      <>
        <HorizontalFormGroup>
          <InlineLabel for={baseName + "-limit"}>When the limit</InlineLabel>
          <Input
            style={{ width: "8rem" }}
            type="number"
            name={baseName + "-limit"}
            id={baseName + "-limit"}
            onChange={this.onChange}
            value={statsLimit.limit}
            min={1}
            max={1000000000}
            step={1}
          />
          <span>is reached</span>
        </HorizontalFormGroup>
        <HorizontalFormGroup>
          <CheckGroup
            type="checkbox"
            name={baseName + "-notify"}
            id={baseName + "-notify"}
            onChange={this.onChange}
            checked={statsLimit.notify}
          >
            Notify
          </CheckGroup>
          &nbsp;&nbsp;
          <InlineLabel for={baseName + "-severity"}>Severity</InlineLabel>
          <Select
            name={baseName + "-severity"}
            id={baseName + "-severity"}
            onChange={this.onChange}
            value={statsLimit.severity}
            disabled={!statsLimit.notify}
          >
            <option value="0">Informational</option>
            <option value="1">Minor</option>
            <option value="2">Major</option>
            <option value="3">Severe</option>
          </Select>
        </HorizontalFormGroup>
        <CheckGroup
          type="checkbox"
          name={baseName + "-enabled"}
          id={baseName + "-enabled"}
          onChange={this.onChange}
          checked={statsLimit.enabled}
        >
          Limit statistics collection
        </CheckGroup>
        <SubSection>
          <CheckGroup
            type="radio"
            name={baseName + "-stop"}
            id={baseName + "-stop"}
            onChange={this.onChange}
            checked={!statsLimit.reset}
            disabled={!statsLimit.enabled}
          >
            Stop collecting statistics
          </CheckGroup>
          <CheckGroup
            type="radio"
            name={baseName + "-reset"}
            id={baseName + "-reset"}
            onChange={this.onChange}
            checked={statsLimit.reset}
            disabled={!statsLimit.enabled}
          >
            Reset statistics
          </CheckGroup>
        </SubSection>
      </>
    )
  }
}
