import * as React from "react"
import cn from "classnames"
import FontAwesome from "react-fontawesome"
import styled from "styled-components"
import Input from "../Input"

const CustomSelect = styled.div`
  position: relative;
  display: flex;

  & select {
    flex-grow: 1;
    cursor: pointer;
    appearance: none;
    background: ${props => props.theme.inputBackgroundColor};
    padding-right: 1.75rem;

    &.is-empty-select {
      color: ${props => props.theme.inputPlaceholderColor};
    }
  }

  & .select-icon {
    opacity: 0.65;
    position: absolute;
    font-size: 10px;
    right: 0.7rem;
    top: 0.7rem;
    pointer-events: none;
  }

  .input-group > && {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
  }

  .input-group > &&:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .input-group && select:focus {
    z-index: 3;
  }
`

export class Select extends React.Component<any> {
  render() {
    const { ...props } = this.props
    const isEmpty = props.value === null || props.value === undefined || props.value === ""
    return (
      <CustomSelect>
        <Input
          {...props}
          type="select"
          className={cn(props.className, { "is-empty-select": isEmpty })}
        />
        <FontAwesome name="chevron-down" className="select-icon" />
      </CustomSelect>
    )
  }
}
