import * as React from "react"
import { cloneDeep } from "lodash"
import { connect } from "react-redux"
import { RouteComponentProps } from "react-router-dom"
import { Helmet } from "react-helmet"
import { produce } from "immer"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { ButtonGroup } from "reactstrap"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { Alert } from "../common/Alert"
import Interval from "../common/Interval"
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledDropdownWithPortal,
} from "../common/Dropdown"
import { Link } from "../common/Link"
import { OmniTable } from "../common/OmniTable"
import {
  View,
  ViewMaxWidth,
  ViewContent,
  ViewHeader,
  ViewHeaderTitle,
  ViewHeaderButtons,
} from "../common/View"
import {
  LightButton,
  LightDangerButton,
  LightDropdownToggle,
  IconDropdownToggle,
} from "../common/Buttons"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import FilterBox from "../common/FilterBox"
import ConfirmationModal from "../common/ConfirmationModal"
import {
  getCaptureButtonText,
  getCaptureOptionsFromTemplate,
  getDefaultCaptureOptions,
  LIVEFLOW_CAPTURE_ID,
  LIVEFLOW_CLSID,
  THREATEYE_NV_CAPTURE_ID,
  THREATEYE_NV_CLSID,
} from "../../utils/captureUtils"
import { formatProp } from "../../utils/propUtils"
import { collator, sortAdapters, sortCaptureTemplates } from "../../utils/sortUtils"
import {
  getEngineNewCaptureUrl,
  getEngineCaptureOptionsUrl,
  getEngineCaptureUrl,
} from "../../routes"
import {
  getEngine,
  getAuthToken,
  getUserId,
  getCapabilities,
  getCapturesViewType,
  getCapturesFilter,
  getCapturesColumns,
  getCapturesSortBy,
  getCapturesSortDirection,
  getStatus,
  getShowLocalTime,
} from "../../store"
import {
  setCapturesViewType,
  setCapturesFilter,
  setCapturesColumns,
  setCapturesSort,
} from "../../store/ui"
import {
  fetchAdapters,
  deleteCapture,
  fetchCaptures,
  fetchCaptureTemplates,
  startCapture,
  stopCapture,
} from "../../api/api"
import {
  Adapter,
  CaptureProperties,
  CaptureTemplate,
  ResponseGetAdapters,
  ResponseGetCaptureList,
  ResponseGetCaptureTemplates,
  ResponseGetEngineCapabilities,
  ResponseGetStatus,
  Results,
} from "../../api/types"
import {
  ActivationType,
  hasActivationCapability,
  hasPluginCapability,
} from "../../api/types/activationTypes"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { CaptureTemplateType, PeekCaptureState } from "../../api/types/peekTypes"
import CapturePanel from "../CapturePanel"

const defaultColumns = [
  {
    dataKey: "name",
    label: "Name",
    width: 200,
    flexGrow: 1,
    visible: true,
  },
  {
    dataKey: "comment",
    label: "Comment",
    width: 200,
    flexGrow: 1,
    visible: false,
  },
  {
    dataKey: "status",
    label: "Status",
    width: 200,
    visible: true,
  },
  {
    dataKey: "adapter",
    label: "Adapter",
    width: 140,
    visible: true,
  },
  {
    dataKey: "linkSpeed",
    label: "Link Speed",
    width: 140,
    visible: false,
  },
  {
    dataKey: "mediaType",
    label: "Media",
    width: 120,
    visible: false,
  },
  {
    dataKey: "bufferCapacity",
    label: "Buffer Size",
    width: 120,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "ctdEnabled",
    label: "Capture To Disk",
    width: 140,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "ctdIntelligent",
    label: "Intelligent CTD",
    width: 140,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "packetsReceived",
    label: "Packets Received",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "packetsFiltered",
    label: "Packets Filtered",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "packetsAnalyzed",
    label: "Packets Analyzed",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "analysisDroppedPackets",
    label: "Analysis Dropped Packets",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "packetsDropped",
    label: "Packets Dropped",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "flowsDropped",
    label: "Flows Dropped",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "startTime",
    label: "Start Time",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "stopTime",
    label: "Stop Time",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "duration",
    label: "Duration",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "alarms",
    label: "Alarms",
    width: 140,
    visible: false,
  },
  {
    dataKey: "filtersEnabled",
    label: "Filters",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "hardwareProfileName",
    label: "Hardware Profile",
    width: 120,
    visible: false,
  },
  {
    dataKey: "creator",
    label: "Owner",
    width: 140,
    visible: false,
  },
  {
    dataKey: "modificationBy",
    label: "Modified By",
    width: 140,
    visible: false,
  },
  {
    dataKey: "modificationType",
    label: "Action",
    width: 140,
    visible: false,
  },
]

const CommandStrip = styled.div`
  display: flex;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
`

type CapturesListProps = {
  userId: string
  engineCapabilities: ResponseGetEngineCapabilities
  captures: CaptureProperties[]
  startPending: string[]
  stopPending: string[]
  deletePending: string[]
  onToggleCapture: (capId: string) => void
  onCaptureOptions: (capId: string) => void
  onDeleteCapture: (capId: string) => void
  showLocalTime: boolean
}

const CapturesList = ({
  userId,
  engineCapabilities,
  captures,
  startPending,
  stopPending,
  deletePending,
  onToggleCapture,
  onCaptureOptions,
  onDeleteCapture,
  showLocalTime,
}: CapturesListProps) => {
  const rows = captures.map(capture => {
    const policies = engineCapabilities.userRights.policies
    const isStartStopPending = startPending.includes(capture.id) || stopPending.includes(capture.id)
    const isDeletePending = deletePending.includes(capture.id)
    const isUserOwner = userId === capture.creatorSID
    const isViewEnabled =
      !capture.threatEyeNVCapture &&
      !capture.spotlightCapture &&
      (isUserOwner ||
        ((!engineCapabilities.capabilities.includes(
          EngineCapabilities.viewCapturesForensicSearchesACL
        ) ||
          policies.includes(EngineUserPolicies.viewCaptures)) &&
          (policies.includes(EngineUserPolicies.viewPackets) ||
            policies.includes(EngineUserPolicies.viewStats))))
    const isStartStopEnabled =
      isUserOwner || policies.includes(EngineUserPolicies.startStopCaptures)
    const isCaptureOptionsEnabled =
      isUserOwner || policies.includes(EngineUserPolicies.modifyCaptures)
    const isDeleteCaptureEnabled =
      isUserOwner || policies.includes(EngineUserPolicies.deleteCaptures)

    return (
      <CapturePanel
        key={capture.id}
        capture={capture}
        isViewEnabled={isViewEnabled}
        isStartStopPending={isStartStopPending}
        isDeletePending={isDeletePending}
        isStartStopEnabled={isStartStopEnabled}
        isCaptureOptionsEnabled={isCaptureOptionsEnabled}
        isDeleteCaptureEnabled={isDeleteCaptureEnabled}
        onToggleCapture={onToggleCapture}
        onCaptureOptions={onCaptureOptions}
        onDeleteCapture={onDeleteCapture}
        showLocalTime={showLocalTime}
      />
    )
  })
  return <List>{rows}</List>
}

type CapturesViewProps = RouteComponentProps & {
  dispatch: Function
  engine: string
  authToken: string
  userId: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  engineStatus: ResponseGetStatus | null
  viewType: "list" | "table"
  filter: string
  showLocalTime: boolean
  columns: any[]
  sortBy: string
  sortDirection: SortDirectionType
}

type CapturesViewState = {
  adapters: Adapter[] | null
  captures: CaptureProperties[] | null
  captureTemplates: CaptureTemplate[] | null
  showDeleteAllConfirm: boolean
  showDeleteConfirm: boolean
  capId: string | null
  errorMessage: string | null
  startPending: string[]
  stopPending: string[]
  deletePending: string[]
}

class CapturesView extends React.Component<CapturesViewProps, CapturesViewState> {
  state: CapturesViewState = {
    adapters: null,
    captures: null,
    captureTemplates: null,
    showDeleteAllConfirm: false,
    showDeleteConfirm: false,
    capId: null,
    errorMessage: null,
    startPending: [],
    stopPending: [],
    deletePending: [],
  }

  componentDidMount() {
    this.onRefresh()

    const { engine, authToken } = this.props

    fetchCaptureTemplates(engine, authToken)
      .then((response: ResponseGetCaptureTemplates) => {
        if (Array.isArray(response.templates)) {
          const captureTemplates = response.templates
          captureTemplates.sort(sortCaptureTemplates)
          this.setState({ captureTemplates })
        }
      })
      .catch(error => {
        this.setState({ errorMessage: `Error ${error.code}: ${error.reason}` })
      })

    fetchAdapters(engine, authToken)
      .then((response: ResponseGetAdapters) => {
        if (Array.isArray(response.adapters)) {
          const adapters = response.adapters
          adapters.sort(sortAdapters)
          this.setState({ adapters })
        }
      })
      .catch(error => {
        this.setState({ errorMessage: `Error ${error.code}: ${error.reason}` })
      })
  }

  onRefresh = () => {
    const { engine, authToken } = this.props
    fetchCaptures(engine, authToken)
      .then((response: ResponseGetCaptureList) => {
        if (Array.isArray(response.captures)) {
          const captures = response.captures
          const { viewType, sortBy, sortDirection } = this.props
          if (viewType === "list") {
            this.sort(captures, "name", SortDirection.ASC)
          } else {
            this.sort(captures, sortBy, sortDirection)
          }
          this.setState({ captures })
        }
      })
      .catch(error => {
        this.setState({ errorMessage: `Error ${error.code}: ${error.reason}` })
      })
  }

  onChangeFilter = (filter: string) => {
    this.props.dispatch(setCapturesFilter(filter))
  }

  onShowDefaultColumns = () => {
    this.props.dispatch(setCapturesColumns(defaultColumns))
  }

  onShowAllColumns = () => {
    const columns = cloneDeep(this.props.columns)
    columns.forEach(col => {
      col.visible = true
    })
    this.props.dispatch(setCapturesColumns(columns))
  }

  onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const columns = cloneDeep(this.props.columns)
    const col = columns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      this.props.dispatch(setCapturesColumns(columns))
    }
  }

  onNewCapture = () => {
    this.props.history.push(getEngineNewCaptureUrl())
  }

  onNewThreatEyeNVCapture = () => {
    const { engineStatus } = this.props

    const captureOptions = cloneDeep(getDefaultCaptureOptions(engineStatus))
    if (captureOptions.generalSettings) {
      captureOptions.generalSettings.captureId = THREATEYE_NV_CAPTURE_ID
      captureOptions.generalSettings.threatEyeNVCapture = true
      if (engineStatus && engineStatus.customSettings === 2) {
        captureOptions.generalSettings.captureToDisk = false
        captureOptions.generalSettings.ctdFileSize = 83886080 // 80 MB
        captureOptions.generalSettings.ctdMaxFileAge = 21600 // 6 hours
        captureOptions.generalSettings.ctdUseMaxFileAge = true
        captureOptions.generalSettings.ctdKeepLastFiles = false
        captureOptions.generalSettings.ctdPriority = false
        captureOptions.generalSettings.ctdIntelligent = false
        captureOptions.generalSettings.enableTimelineStats = false
        captureOptions.generalSettings.startCapture = false
      }
      captureOptions.generalSettings.bufferSize = 0
      captureOptions.generalSettings.ctdFilePattern = "ThreatEye-"
      captureOptions.generalSettings.name = "ThreatEye Capture"
    }

    this.props.history.push({
      pathname: getEngineNewCaptureUrl(),
      state: { template: captureOptions },
    })
  }

  onNewLiveFlowCapture = () => {
    const { engineStatus } = this.props

    const captureOptions = cloneDeep(getDefaultCaptureOptions(engineStatus))
    if (captureOptions.generalSettings) {
      captureOptions.generalSettings.captureId = LIVEFLOW_CAPTURE_ID
      captureOptions.generalSettings.spotlightCapture = true
      if (engineStatus && engineStatus.customSettings === 2) {
        captureOptions.generalSettings.captureToDisk = false
        captureOptions.generalSettings.ctdFileSize = 83886080 // 80 MB
        captureOptions.generalSettings.ctdMaxFileAge = 21600 // 6 hours
        captureOptions.generalSettings.ctdUseMaxFileAge = true
        captureOptions.generalSettings.ctdKeepLastFiles = false
        captureOptions.generalSettings.ctdPriority = false
        captureOptions.generalSettings.ctdIntelligent = false
        captureOptions.generalSettings.enableTimelineStats = false
        captureOptions.generalSettings.startCapture = false
      }
      captureOptions.generalSettings.bufferSize = 0
      captureOptions.generalSettings.ctdFilePattern = "LiveFlow-"
      captureOptions.generalSettings.enableAppStats = true
      captureOptions.generalSettings.name = "LiveFlow Capture"
    }

    this.props.history.push({
      pathname: getEngineNewCaptureUrl(),
      state: { template: captureOptions },
    })
  }

  onNewTemplateCapture = (ct: CaptureTemplate) => {
    const { adapters, captures } = this.state
    const { engineCapabilities, engineStatus } = this.props
    this.props.history.push({
      pathname: getEngineNewCaptureUrl(),
      state: {
        template: getCaptureOptionsFromTemplate(
          ct,
          adapters,
          captures !== null
            ? captures.map((captureProps: CaptureProperties) => captureProps.name)
            : [],
          engineCapabilities,
          engineStatus
        ),
      },
    })
  }

  onStartAllCaptures = () => {
    const captures = this.getCaptures()
    if (captures) {
      const requests: Promise<any>[] = []
      const captureIds: string[] = []
      captures.forEach(capture => {
        const { engine, authToken } = this.props
        requests.push(startCapture(engine, authToken, capture.id))
        captureIds.push(capture.id)
      })
      this.setState(
        produce(draft => {
          captureIds.forEach(capId => {
            if (!draft.startPending.includes(capId)) {
              draft.startPending.push(capId)
            }
          })
        })
      )
      Promise.all(requests)
        .then(() => {
          this.setState({ errorMessage: null })
          this.onRefresh()
        })
        .catch(() => {
          this.setState({ errorMessage: "Error starting all captures" })
        })
        .finally(() => {
          this.setState(
            produce(draft => {
              captureIds.forEach(capId => {
                const index = draft.startPending.indexOf(capId)
                if (index !== -1) {
                  draft.startPending.splice(index, 1)
                }
              })
            })
          )
        })
    }
  }

  onStopAllCaptures = () => {
    const captures = this.getCaptures()
    if (captures) {
      const requests: Promise<any>[] = []
      const captureIds: string[] = []
      captures.forEach(capture => {
        const { engine, authToken } = this.props
        requests.push(stopCapture(engine, authToken, capture.id))
        captureIds.push(capture.id)
      })
      this.setState(
        produce(draft => {
          captureIds.forEach(capId => {
            if (!draft.stopPending.includes(capId)) {
              draft.stopPending.push(capId)
            }
          })
        })
      )
      Promise.all(requests)
        .then(() => {
          this.setState({ errorMessage: null })
          this.onRefresh()
        })
        .catch(() => {
          this.setState({ errorMessage: "Error stopping all captures" })
        })
        .finally(() => {
          this.setState(
            produce(draft => {
              captureIds.forEach(capId => {
                const index = draft.stopPending.indexOf(capId)
                if (index !== -1) {
                  draft.stopPending.splice(index, 1)
                }
              })
            })
          )
        })
    }
  }

  onDeleteAll = () => {
    this.setState({ showDeleteAllConfirm: true })
  }

  onDeleteAllCancel = () => {
    this.setState({ showDeleteAllConfirm: false })
  }

  onDeleteAllOK = () => {
    this.setState({ showDeleteAllConfirm: false })
    const captures = this.getCaptures()
    if (captures) {
      const requests: Promise<any>[] = []
      captures.forEach(capture => {
        const { engine, authToken } = this.props
        requests.push(deleteCapture(engine, authToken, capture.id))
      })
      Promise.all(requests)
        .then(() => {
          this.onRefresh()
        })
        .catch(() => {
          this.setState({ errorMessage: "Error deleting all captures" })
        })
    }
  }

  onViewCapture = (capId: string) => {
    this.props.history.push(`${this.props.match.url}/${capId}`)
  }

  onToggleCapture = (capId: string) => {
    const captures = this.getCaptures()
    if (captures) {
      const capture = captures.find(capture => capture.id === capId)
      if (capture) {
        const isCapturing = (capture.status & PeekCaptureState.PEEK_CAPTURE_STATE_CAPTURING) !== 0
        if (isCapturing) {
          this.onStopCapture(capId)
        } else {
          this.onStartCapture(capId)
        }
      }
    }
  }

  onStartCapture = (capId: string) => {
    const { engine, authToken } = this.props
    this.setState(
      produce(draft => {
        if (!draft.startPending.includes(capId)) {
          draft.startPending.push(capId)
        }
      })
    )
    startCapture(engine, authToken, capId)
      .then(() => {
        this.setState({ errorMessage: null })
        this.onRefresh()
      })
      .catch(error => {
        this.setState({ errorMessage: `Error ${error.code}: ${error.reason}` })
      })
      .finally(() => {
        this.setState(
          produce(draft => {
            const index = draft.startPending.indexOf(capId)
            if (index !== -1) {
              draft.startPending.splice(index, 1)
            }
          })
        )
      })
  }

  onStopCapture = (capId: string) => {
    const { engine, authToken } = this.props
    this.setState(
      produce(draft => {
        if (!draft.stopPending.includes(capId)) {
          draft.stopPending.push(capId)
        }
      })
    )
    stopCapture(engine, authToken, capId)
      .then(() => {
        this.setState({ errorMessage: null })
        this.onRefresh()
      })
      .catch(error => {
        this.setState({ errorMessage: `Error ${error.code}: ${error.reason}` })
      })
      .finally(() => {
        this.setState(
          produce(draft => {
            const index = draft.stopPending.indexOf(capId)
            if (index !== -1) {
              draft.stopPending.splice(index, 1)
            }
          })
        )
      })
  }

  onCaptureOptions = (capId: string) => {
    this.props.history.push(getEngineCaptureOptionsUrl(capId))
  }

  onDeleteCapture = (capId: string) => {
    this.setState({ showDeleteConfirm: true, capId: capId })
  }

  onDeleteCaptureCancel = () => {
    this.setState({ showDeleteConfirm: false, capId: null })
  }

  onDeleteCaptureOK = () => {
    const { capId } = this.state
    this.setState({ showDeleteConfirm: false, capId: null })
    if (capId) {
      const { engine, authToken } = this.props
      this.setState(
        produce(draft => {
          if (!draft.deletePending.includes(capId)) {
            draft.deletePending.push(capId)
          }
        })
      )
      deleteCapture(engine, authToken, capId)
        .then(() => {
          this.onRefresh()
        })
        .catch(error => {
          this.setState({ errorMessage: `Error ${error.code}: ${error.reason}` })
        })
        .finally(() => {
          this.setState(
            produce(draft => {
              const index = draft.deletePending.indexOf(capId)
              if (index !== -1) {
                draft.deletePending.splice(index, 1)
              }
            })
          )
        })
    }
  }

  onChangeView = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(setCapturesViewType(event.currentTarget.name))
    this.onRefresh()
  }

  onSort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
    this.sort(this.state.captures, sortBy, sortDirection)
    this.props.dispatch(setCapturesSort(sortBy, sortDirection))
  }

  sort(captures: CaptureProperties[] | null, sortBy: string, sortDirection: SortDirectionType) {
    if (captures) {
      captures.sort((a, b) => {
        let result = 0

        const valueA = a[sortBy as keyof CaptureProperties]
        const valueB = b[sortBy as keyof CaptureProperties]

        if (typeof valueA === "string" && typeof valueB === "string") {
          result = collator.compare(valueA, valueB)
        } else if (typeof valueA === "number" && typeof valueB === "number") {
          if (valueA > valueB) {
            result = 1
          } else if (valueA < valueB) {
            result = -1
          }
        }

        if (result === 0) {
          // Fall back to name.
          result = collator.compare(a.name, b.name)
        }

        if (sortDirection === SortDirection.DESC) result = -result

        return result
      })
    }
  }

  cellRenderer = ({ dataKey, rowData }: TableCellProps) => {
    if (dataKey === "name") {
      const { userId, engineCapabilities } = this.props
      if (!engineCapabilities) return null
      const policies = engineCapabilities.userRights.policies
      const isUserOwner = userId === rowData.creatorSID
      const isViewEnabled =
        !rowData.threatEyeNVCapture &&
        !rowData.spotlightCapture &&
        (isUserOwner ||
          ((!engineCapabilities.capabilities.includes(
            EngineCapabilities.viewCapturesForensicSearchesACL
          ) ||
            policies.includes(EngineUserPolicies.viewCaptures)) &&
            (policies.includes(EngineUserPolicies.viewPackets) ||
              policies.includes(EngineUserPolicies.viewStats))))
      return !isViewEnabled ? (
        rowData.name
      ) : (
        <Link to={getEngineCaptureUrl(rowData.id)}>{rowData.name}</Link>
      )
    }
    return formatProp(dataKey, rowData)
  }

  commandCellRenderer = ({ rowData }: TableCellProps) => {
    const { userId, engineCapabilities } = this.props
    const { startPending, stopPending } = this.state
    const pending = startPending.concat(stopPending)
    if (!engineCapabilities) return null

    const policies = engineCapabilities.userRights.policies
    const isUserOwner = userId === rowData.creatorSID
    const isViewEnabled =
      !rowData.threatEyeNVCapture &&
      !rowData.spotlightCapture &&
      (isUserOwner ||
        ((!engineCapabilities.capabilities.includes(
          EngineCapabilities.viewCapturesForensicSearchesACL
        ) ||
          policies.includes(EngineUserPolicies.viewCaptures)) &&
          (policies.includes(EngineUserPolicies.viewPackets) ||
            policies.includes(EngineUserPolicies.viewStats))))
    const isStartStopEnabled =
      isUserOwner || policies.includes(EngineUserPolicies.startStopCaptures)
    const isCaptureOptionsEnabled =
      isUserOwner || policies.includes(EngineUserPolicies.modifyCaptures)
    const isDeleteCaptureEnabled =
      isUserOwner || policies.includes(EngineUserPolicies.deleteCaptures)

    const captureStatusText = pending.includes(rowData.id)
      ? null
      : getCaptureButtonText(rowData.status)

    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            {!rowData.threatEyeNVCapture && !rowData.spotlightCapture && (
              <DropdownItem
                disabled={!isViewEnabled}
                onClick={this.onViewCapture.bind(this, rowData.id)}
              >
                View Capture
              </DropdownItem>
            )}
            {captureStatusText !== null && (
              <DropdownItem
                disabled={!isStartStopEnabled}
                onClick={this.onToggleCapture.bind(this, rowData.id)}
              >
                {captureStatusText.text}
              </DropdownItem>
            )}
            <DropdownItem
              disabled={!isCaptureOptionsEnabled}
              onClick={this.onCaptureOptions.bind(this, rowData.id)}
            >
              Capture Options
            </DropdownItem>
            <DropdownItem
              disabled={!isDeleteCaptureEnabled}
              onClick={this.onDeleteCapture.bind(this, rowData.id)}
            >
              Delete Capture
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  getCaptures = () => {
    let { captures } = this.state
    const { filter } = this.props
    if (captures && filter) {
      const lowerCaseFilter = filter.toLowerCase()
      captures = captures.filter(
        capture =>
          capture.name.toLowerCase().includes(lowerCaseFilter) ||
          capture.comment.toLowerCase().includes(lowerCaseFilter)
      )
    }
    return captures
  }

  render() {
    const {
      errorMessage,
      showDeleteAllConfirm,
      showDeleteConfirm,
      startPending,
      stopPending,
      deletePending,
      captureTemplates,
    } = this.state
    const { userId, engineCapabilities, viewType, filter, columns, sortBy, sortDirection } =
      this.props
    if (!engineCapabilities) return null

    // Get the filtered list of captures
    const captures = this.getCaptures()

    // Count is the total without any filtering
    const count = this.state.captures?.length ?? 0

    // Check access policies
    const policies = engineCapabilities.userRights.policies
    const canCreateCapture = policies.includes(EngineUserPolicies.createCapture)
    const canDeleteCaptures = policies.includes(EngineUserPolicies.deleteCaptures)
    const canStartStopCaptures = policies.includes(EngineUserPolicies.startStopCaptures)
    const userOwnsAllCaptures = captures
      ? captures.every((capture: CaptureProperties) => userId === capture.creatorSID)
      : false

    // LiveFlow
    const isLiveFlowEnabled =
      (hasActivationCapability(
        engineCapabilities.activationInfo.capabilities,
        ActivationType.ACTIVATION_TYPE_FEATURE_LIVEFLOW
      ) ||
        (hasActivationCapability(
          engineCapabilities.activationInfo.capabilities,
          ActivationType.ACTIVATION_TYPE_FEATURE_THREATEYE_NV
        ) &&
          Array.isArray(engineCapabilities.pluginsInfo) &&
          !hasPluginCapability(engineCapabilities.pluginsInfo, THREATEYE_NV_CLSID))) &&
      Array.isArray(engineCapabilities.pluginsInfo) &&
      hasPluginCapability(engineCapabilities.pluginsInfo, LIVEFLOW_CLSID)
    const liveFlowCapture = this.state.captures
      ? this.state.captures.find((capture: CaptureProperties) => capture.spotlightCapture)
      : undefined

    // ThreatEye NV
    const isThreatEyeNVEnabled =
      hasActivationCapability(
        engineCapabilities.activationInfo.capabilities,
        ActivationType.ACTIVATION_TYPE_FEATURE_THREATEYE_NV
      ) &&
      Array.isArray(engineCapabilities.pluginsInfo) &&
      hasPluginCapability(engineCapabilities.pluginsInfo, THREATEYE_NV_CLSID)
    const threatEyeNVCapture = this.state.captures
      ? this.state.captures.find((capture: CaptureProperties) => capture.threatEyeNVCapture)
      : undefined

    // capture templates
    let captureTemplateOptions: JSX.Element[] = []
    if (captureTemplates) {
      // default
      const defaultCaptureTemplates: JSX.Element[] = []
      captureTemplates
        .filter(
          (ct: CaptureTemplate) => ct.type === CaptureTemplateType.CAPTURE_TEMPLATE_TYPE_DEFAULT
        )
        .forEach((ct: CaptureTemplate) => {
          if (
            ct &&
            ct.template &&
            ct.template.generalSettings &&
            ct.template.generalSettings.name !== undefined
          ) {
            defaultCaptureTemplates.push(
              <DropdownItem
                key={ct.id}
                disabled={!canCreateCapture}
                onClick={this.onNewTemplateCapture.bind(this, ct)}
              >
                New &ldquo;{ct.template.generalSettings.name}&rdquo;
              </DropdownItem>
            )
          }
        })

      // user
      const userCaptureTemplates: JSX.Element[] = []
      captureTemplates
        .filter((ct: CaptureTemplate) => ct.type === CaptureTemplateType.CAPTURE_TEMPLATE_TYPE_USER)
        .forEach((ct: CaptureTemplate) => {
          if (
            ct &&
            ct.template &&
            ct.template.generalSettings &&
            ct.template.generalSettings.name !== undefined
          ) {
            userCaptureTemplates.push(
              <DropdownItem
                key={ct.id}
                disabled={!canCreateCapture}
                onClick={this.onNewTemplateCapture.bind(this, ct)}
              >
                New &ldquo;{ct.template.generalSettings.name}&rdquo;
              </DropdownItem>
            )
          }
        })

      // combine default and user templates with dividers
      captureTemplateOptions = (
        defaultCaptureTemplates.length > 0 ? [<DropdownItem key="dividerDefault" divider />] : []
      ).concat(
        defaultCaptureTemplates,
        userCaptureTemplates.length > 0 ? [<DropdownItem key="dividerUser" divider />] : [],
        userCaptureTemplates
      )
    }

    return (
      <View>
        <Alert
          color="danger"
          isOpen={errorMessage !== null}
          toggle={() => this.setState({ errorMessage: null })}
        >
          {errorMessage}
        </Alert>
        <Helmet title="Captures" />
        <BreadcrumbItem to={this.props.match.url} title="Captures" />
        <Interval timeout={5000} enabled={true} callback={this.onRefresh} />
        <ViewHeader>
          <ViewHeaderTitle title="Captures" count={count} />
          <ViewHeaderButtons>
            <FilterBox
              aria-label="Search"
              placeholder="Search"
              onChange={this.onChangeFilter}
              value={filter}
            />
            <UncontrolledDropdown>
              <LightDropdownToggle
                caret
                aria-label="New Capture"
                id="new-capture"
                disabled={!canCreateCapture}
                style={{ width: "100%" }}
              >
                <FontAwesome name="plus" /> New Capture
              </LightDropdownToggle>
              <DropdownMenu>
                <DropdownItem disabled={!canCreateCapture} onClick={this.onNewCapture}>
                  New Capture
                </DropdownItem>
                {isLiveFlowEnabled && (
                  <DropdownItem
                    disabled={!canCreateCapture || liveFlowCapture !== undefined}
                    onClick={this.onNewLiveFlowCapture}
                  >
                    New &ldquo;LiveFlow Capture&rdquo;
                  </DropdownItem>
                )}
                {isThreatEyeNVEnabled && (
                  <DropdownItem
                    disabled={!canCreateCapture || threatEyeNVCapture !== undefined}
                    onClick={this.onNewThreatEyeNVCapture}
                  >
                    New &ldquo;ThreatEye Capture&rdquo;
                  </DropdownItem>
                )}
                {captureTemplateOptions}
              </DropdownMenu>
            </UncontrolledDropdown>
            <LightButton
              id="start-all"
              disabled={!canStartStopCaptures && !userOwnsAllCaptures}
              onClick={this.onStartAllCaptures}
            >
              <FontAwesome name="play" /> Start All
            </LightButton>
            <LightButton
              id="stop-all"
              disabled={!canStartStopCaptures && !userOwnsAllCaptures}
              onClick={this.onStopAllCaptures}
            >
              <FontAwesome name="stop" /> Stop All
            </LightButton>
            <LightDangerButton
              id="delete-all"
              disabled={!canDeleteCaptures && !userOwnsAllCaptures}
              onClick={this.onDeleteAll}
            >
              <FontAwesome name="trash-o" /> Delete All
            </LightDangerButton>
            <ButtonGroup>
              <LightButton
                id="list-view"
                name="list"
                aria-label="List view"
                onClick={this.onChangeView}
                active={viewType === "list"}
              >
                <FontAwesome name="align-justify" />
              </LightButton>
              <UncontrolledTooltip placement="top" target="list-view">
                List View
              </UncontrolledTooltip>
              <LightButton
                id="table-view"
                name="table"
                aria-label="Table view"
                onClick={this.onChangeView}
                active={viewType === "table"}
              >
                <FontAwesome name="table" />
              </LightButton>
              <UncontrolledTooltip placement="top" target="table-view">
                Table View
              </UncontrolledTooltip>
            </ButtonGroup>
            <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefresh}>
              <FontAwesome name="refresh" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="refresh">
              Refresh
            </UncontrolledTooltip>
          </ViewHeaderButtons>
        </ViewHeader>
        <ViewMaxWidth maxWidth={viewType === "list" ? undefined : "none"}>
          <ViewContent>
            {captures ? (
              viewType === "list" ? (
                <CapturesList
                  userId={userId}
                  engineCapabilities={engineCapabilities}
                  captures={captures}
                  startPending={startPending}
                  stopPending={stopPending}
                  deletePending={deletePending}
                  onToggleCapture={this.onToggleCapture}
                  onCaptureOptions={this.onCaptureOptions}
                  onDeleteCapture={this.onDeleteCapture}
                  showLocalTime={this.props.showLocalTime}
                />
              ) : (
                <OmniTable
                  data={captures}
                  rowHeight={31}
                  rowCount={captures.length}
                  columnDesc={columns}
                  cellRenderer={this.cellRenderer}
                  renderCommands={this.commandCellRenderer}
                  onShowDefaultColumns={this.onShowDefaultColumns}
                  onShowAllColumns={this.onShowAllColumns}
                  onToggleColumn={this.onToggleColumn}
                  sort={this.onSort}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                />
              )
            ) : null}
          </ViewContent>
        </ViewMaxWidth>
        {showDeleteAllConfirm && (
          <ConfirmationModal
            message={
              <span>
                Are you sure you want to delete all captures?
                <br />
                <br />
                Packet data stored on disk, statistics, and reports will not be deleted.
              </span>
            }
            onNo={this.onDeleteAllCancel}
            onYes={this.onDeleteAllOK}
            show={showDeleteAllConfirm}
            title="Delete All Captures"
          />
        )}
        {showDeleteConfirm && (
          <ConfirmationModal
            message={
              <span>
                Are you sure you want to delete the capture?
                <br />
                <br />
                Packet data stored on disk, statistics, and reports will not be deleted.
              </span>
            }
            onNo={this.onDeleteCaptureCancel}
            onYes={this.onDeleteCaptureOK}
            show={showDeleteConfirm}
            title="Delete Capture"
          />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  userId: getUserId(state),
  engineCapabilities: getCapabilities(state),
  engineStatus: getStatus(state),
  viewType: getCapturesViewType(state) || "list",
  filter: getCapturesFilter(state) || "",
  showLocalTime: getShowLocalTime(state),
  columns: getCapturesColumns(state) || defaultColumns,
  sortBy: getCapturesSortBy(state) || "name",
  sortDirection: getCapturesSortDirection(state) || SortDirection.ASC,
})

export default connect(mapStateToProps)(CapturesView)
