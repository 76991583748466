import * as React from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { Helmet } from "react-helmet"
import { SortDirection, SortDirectionType, TableCellProps, TableRowProps } from "react-virtualized"
import cn from "classnames"
import FontAwesome from "react-fontawesome"
import { remove } from "lodash"
import APITokenModal, { getRequestCreateToken, getRequestModifyToken } from "./APITokenModal"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { Alert } from "../common/Alert"
import { LightButton, LightDangerButton, IconButton } from "../common/Buttons"
import { ConfirmationModal } from "../common/ConfirmationModal"
import { FilterBox } from "../common/FilterBox"
import { CellCheckGroup, CellCheckGroupIndeterminate } from "../common/Form"
import { CenterContent } from "../common/Layout"
import { OmniTable } from "../common/OmniTable"
import { Spinner } from "../common/Spinner"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import { View, ViewContent, ViewHeader, ViewHeaderButtons, ViewHeaderTitle } from "../common/View"
import {
  getEngine,
  getAuthenticationTokensFilter,
  getAuthenticationTokensSortBy,
  getAuthenticationTokensSortDirection,
  getAuthToken,
  getCapabilities,
  getShowLocalTime,
} from "../../store"
import {
  createAuthenticationToken,
  deleteAuthenticationTokens,
  fetchAuthenticationTokens,
  setAuthenticationToken,
} from "../../api/api"
import {
  AuthenticationToken,
  ResponseGetAuthenticationTokens,
  ResponseGetEngineCapabilities,
  ResponsePostToken,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { setAuthenticationTokensFilter, setAuthenticationTokensSort } from "../../store/ui"
import { getEngineHomeUrl } from "../../routes"
import { formatISODateTime } from "../../utils/formatUtils"

const columnDesc = [
  {
    dataKey: "checked",
    label: "",
    width: 28,
    flexShrink: 0,
    disableSort: true,
    className: "fullsize",
    headerClassName: "fullsize",
  },
  {
    dataKey: "label",
    label: "Label",
    width: 200,
    flexGrow: 1,
  },
  {
    dataKey: "enabled",
    label: "API Token In Use",
    width: 200,
  },
  /*{
    dataKey: "authentication",
    label: "Requires Authentication",
    width: 250,
  },*/
  {
    dataKey: "user",
    label: "User",
    width: 200,
  },
  {
    dataKey: "expirationTime",
    label: "Expiration Time",
    width: 200,
  },
  {
    dataKey: "lastActivityTime",
    label: "Last Activity Time",
    width: 200,
  },
]

type EngineTokensViewProps = RouteComponentProps & {
  authToken: string
  dispatch: Function
  engine: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  filter: string
  sortBy: string
  sortDirection: SortDirectionType
  showLocalTime: boolean
}

type EngineTokensViewState = {
  checkedItems: string[]
  fetchError: any | null
  requestToken: AuthenticationToken | null
  requestTokenId: string | null
  showAPIToken: boolean
  showDeleteConfirm: boolean | null
  tokens: AuthenticationToken[] | null
  updateMessage: any | null
}

class EngineTokensView extends React.Component<EngineTokensViewProps, EngineTokensViewState> {
  state: EngineTokensViewState = {
    checkedItems: [],
    fetchError: null,
    requestToken: null,
    requestTokenId: null,
    showAPIToken: false,
    showDeleteConfirm: null,
    tokens: null,
    updateMessage: null,
  }

  componentDidMount() {
    this.onRefresh()
  }

  onRefresh = () => {
    const { engine, authToken } = this.props
    fetchAuthenticationTokens(engine, authToken)
      .then((response: ResponseGetAuthenticationTokens) => {
        const { sortBy, sortDirection } = this.props
        this.sort(response.tokens, sortBy, sortDirection)
        const tokenIds: string[] = response.tokens.map(
          (token: AuthenticationToken) => token.authTokenId
        )
        const checkedItems: string[] = this.state.checkedItems.filter((id: string) =>
          tokenIds.includes(id)
        )
        this.setState({ checkedItems, tokens: response.tokens })
      })
      .catch(error => {
        this.setState({ fetchError: error })
      })
  }

  onChangeFilter = (filter: string) => {
    this.props.dispatch(setAuthenticationTokensFilter(filter))
  }

  onInsert = () => {
    this.setState({ showAPIToken: true, requestToken: null, requestTokenId: null })
  }

  onEdit = () => {
    const { checkedItems, tokens } = this.state
    if (tokens && checkedItems.length === 1) {
      const token = tokens.find((t: AuthenticationToken) => t.authTokenId === checkedItems[0])
      if (token) {
        this.setState({
          showAPIToken: true,
          requestToken: token,
          requestTokenId: token.authTokenId,
        })
      }
    }
  }

  onAPITokenCancel = () => {
    this.setState({ showAPIToken: false, requestToken: null, requestTokenId: null })
  }

  onAPITokenOK = (token: AuthenticationToken) => {
    const { requestTokenId } = this.state
    const { engine, authToken } = this.props
    if (requestTokenId !== null) {
      // modify current token
      setAuthenticationToken(engine, authToken, requestTokenId, getRequestModifyToken(token))
        .then(() => {
          this.onRefresh()
        })
        .catch(error => {
          this.setState({ fetchError: error })
        })
    } else {
      // create new token
      createAuthenticationToken(engine, authToken, getRequestCreateToken(token))
        .then((response: ResponsePostToken) => {
          this.setState({
            updateMessage: (
              <div>
                API token <b>{response.authToken}</b>{" "}
                <CopyToClipboard text={response.authToken}>
                  <IconButton aria-label="Copy API token to clipboard" id="copy-api-token">
                    <FontAwesome name="clipboard" />
                  </IconButton>
                </CopyToClipboard>
                <UncontrolledTooltip placement="top" target="copy-api-token">
                  Copy API token to clipboard
                </UncontrolledTooltip>{" "}
                created with label <b>{token.label}</b>. Please copy this token as it will not be
                displayed again.
              </div>
            ),
          })
          this.onRefresh()
        })
        .catch(error => {
          this.setState({ fetchError: error })
        })
    }
    this.setState({ showAPIToken: false, requestToken: null, requestTokenId: null })
  }

  showDelete = () => {
    this.setState({ showDeleteConfirm: true })
  }

  onDeleteCancel = () => {
    this.setState({ showDeleteConfirm: false })
  }

  onDeleteConfirm = () => {
    this.setState({ showDeleteConfirm: false })

    const { checkedItems } = this.state
    const { engine, authToken } = this.props
    deleteAuthenticationTokens(engine, authToken, checkedItems)
      .then(() => {
        this.onRefresh()
      })
      .catch(error => {
        this.setState({ fetchError: error })
      })
  }

  onChangeCheckbox = (token: AuthenticationToken, event: React.ChangeEvent<HTMLInputElement>) => {
    const { checkedItems } = this.state
    const isExist = remove(checkedItems, (id: string) => id === token.authTokenId)
    if (isExist.length === 0) {
      checkedItems.push(token.authTokenId)
    }
    this.setState({ checkedItems })
  }

  onSort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
    // Should really be sorting in componentDidUpdate when sortBy/Direction change
    // but since we're sorting in place this is easier and avoid extra renders.
    this.sort(this.state.tokens, sortBy, sortDirection)
    this.props.dispatch(setAuthenticationTokensSort(sortBy, sortDirection))
  }

  sort(tokens: AuthenticationToken[] | null, sortBy: string, sortDirection: SortDirectionType) {
    if (Array.isArray(tokens)) {
      const collator = new Intl.Collator(undefined, { sensitivity: "base", numeric: true })
      tokens.sort((a: AuthenticationToken, b: AuthenticationToken) => {
        let result = collator.compare(a[sortBy], b[sortBy])
        if (result === 0) {
          // Fall back to label.
          result = collator.compare(a.label, b.label)
        }
        if (sortDirection === SortDirection.DESC) result = -result
        return result
      })
    }
  }

  onRowClick = ({ rowData, event }: { rowData: any; event: React.MouseEvent<HTMLElement> }) => {
    // Exclude clicks on checkboxes.
    const tagName = (event?.target as HTMLElement)?.tagName
    if (tagName === "LABEL" || tagName === "INPUT") {
      return
    }
    const { tokens } = this.state
    if (tokens) {
      const token = tokens.find((t: AuthenticationToken) => t.authTokenId === rowData.authTokenId)
      if (token) {
        this.setState({
          showAPIToken: true,
          requestToken: token,
          requestTokenId: token.authTokenId,
        })
      }
    }
  }

  rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    className = cn(className, "clickable")
    key = rowData.authTokenId

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  checkedHeaderRenderer = () => {
    const { tokens, checkedItems } = this.state
    let checkState = 0
    if (tokens != null && checkedItems.length > 0) {
      if (tokens.length === checkedItems.length) {
        checkState = 1
      } else {
        checkState = 2
      }
    }
    return (
      <CellCheckGroupIndeterminate
        type="checkbox"
        value={checkState}
        id="check-all"
        onChange={() => {
          if (tokens != null) {
            const newChecked = []
            if (checkState !== 1) {
              for (const token of tokens) {
                newChecked.push(token.authTokenId)
              }
            }
            this.setState({ checkedItems: newChecked })
          }
        }}
      />
    )
  }

  cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content = null
    switch (dataKey) {
      case "checked": {
        const checked =
          this.state.checkedItems.find((id: string) => id === rowData.authTokenId) !== undefined
        content = (
          <CellCheckGroup
            id={`checkbox-${rowData.authTokenId}`}
            type="checkbox"
            checked={checked}
            onChange={this.onChangeCheckbox.bind(this, rowData)}
          />
        )
        break
      }
      case "authTokenId":
      case "label":
      case "client":
        content = cellData
        break
      case "user":
        if (rowData.userDomain.length > 0) {
          content = `${rowData.userDomain}\\${rowData.userName}`
        } else {
          content = rowData.userName
        }
        break
      case "enabled":
      case "authentication":
        content = cellData ? "Yes" : "No"
        break
      case "expirationTime":
      case "lastActivityTime":
        content = formatISODateTime(cellData, 0, this.props.showLocalTime)
        break
      default:
        break
    }
    return content
  }

  render() {
    const { engineCapabilities, filter, sortBy, sortDirection } = this.props
    let { tokens } = this.state
    const { fetchError, requestToken, showAPIToken, showDeleteConfirm, updateMessage } = this.state

    if (
      engineCapabilities?.userRights.policies.includes(
        engineCapabilities?.capabilities.includes(EngineCapabilities.configureAPITokensACL)
          ? EngineUserPolicies.configureAPITokens
          : EngineUserPolicies.configureEngine
      ) === false
    ) {
      return <Redirect to={`${getEngineHomeUrl()}`} />
    }

    if (fetchError !== null) {
      return (
        <Alert color="danger">
          {typeof fetchError === "string" ? fetchError : `${fetchError.code} ${fetchError.reason}`}
        </Alert>
      )
    }
    if (tokens === null) {
      return (
        <>
          <CenterContent>
            <Spinner />
          </CenterContent>
        </>
      )
    }

    // Get the total count before filtering.
    const count = tokens !== null ? tokens.length : 0

    if (tokens && filter) {
      const lowerCaseFilter = filter.toLowerCase()
      tokens = tokens.filter(
        token =>
          token.label.toLowerCase().indexOf(lowerCaseFilter) !== -1 ||
          (token.userDomain + token.userName).toLowerCase().indexOf(lowerCaseFilter) !== -1
      )
    }

    // add the checkbox header renderer to the columns
    const columns: any[] = [...columnDesc]
    const checkedColumn = columns.find(col => col.dataKey === "checked")
    if (checkedColumn) {
      checkedColumn.headerRenderer = this.checkedHeaderRenderer
    }

    return (
      <View>
        <Helmet title="Configure API Tokens" />
        <BreadcrumbItem to={this.props.match.url} title="Configure API Tokens" />
        <Alert
          color="info"
          isOpen={updateMessage !== null}
          toggle={() => this.setState({ updateMessage: null })}
        >
          {updateMessage}
        </Alert>
        <ViewHeader>
          <ViewHeaderTitle title="API Tokens" count={count} />
          <ViewHeaderButtons>
            <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefresh}>
              <FontAwesome name="refresh" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="refresh">
              Refresh
            </UncontrolledTooltip>
          </ViewHeaderButtons>
        </ViewHeader>
        <ViewHeaderButtons style={{ marginBottom: "4px" }}>
          <div>
            <FilterBox
              aria-label="Search"
              placeholder="Search"
              onChange={this.onChangeFilter}
              value={filter}
            />
          </div>
          <LightButton aria-label="Insert" id="insert" onClick={this.onInsert}>
            <FontAwesome name="plus" /> Insert
          </LightButton>
          <LightButton
            aria-label="Edit"
            id="edit"
            onClick={this.onEdit}
            disabled={this.state.checkedItems.length !== 1}
          >
            <FontAwesome name="pencil" /> Edit
          </LightButton>
          <LightDangerButton
            aria-label="Delete"
            id="delete"
            onClick={this.showDelete}
            disabled={this.state.checkedItems.length === 0}
          >
            <FontAwesome name="trash-o" /> Delete
          </LightDangerButton>
        </ViewHeaderButtons>
        <ViewContent>
          {tokens && (
            <OmniTable
              data={tokens}
              rowHeight={31}
              rowCount={tokens.length}
              columnDesc={columns}
              rowRenderer={this.rowRenderer}
              cellRenderer={this.cellRenderer}
              onRowClick={this.onRowClick}
              sort={this.onSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            />
          )}
        </ViewContent>
        {showDeleteConfirm && (
          <ConfirmationModal
            message="Are you sure you want to delete the selected API tokens?"
            onNo={this.onDeleteCancel}
            onYes={this.onDeleteConfirm}
            show={showDeleteConfirm}
            title="Delete API Tokens"
          />
        )}
        {showAPIToken && (
          <APITokenModal
            token={requestToken}
            showLocalTime={this.props.showLocalTime}
            onOK={this.onAPITokenOK}
            onCancel={this.onAPITokenCancel}
          />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  authToken: getAuthToken(state),
  engine: getEngine(state),
  engineCapabilities: getCapabilities(state) || null,
  filter: getAuthenticationTokensFilter(state) || "",
  sortBy: getAuthenticationTokensSortBy(state) || "label",
  sortDirection: getAuthenticationTokensSortDirection(state) || SortDirection.ASC,
  showLocalTime: getShowLocalTime(state),
})

export default connect(mapStateToProps)(EngineTokensView)
