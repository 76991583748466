import * as React from "react"

export const IconMajor = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 10240 10240"
    shapeRendering="geometricPrecision"
    height={16}
    width={16}
    {...props}
  >
    <path
      d="M5680 1282l3846 6712c117 205 117 439 0 644s-319 322-554 322H1281c-234 0-436-117-553-322s-117-439 0-644l3846-6712c117-205 318-322 553-322s436 117 553 322z"
      fill="#fec213"
    />
    <path
      d="M5120 6720c353 0 640 287 640 640s-287 640-640 640-640-287-640-640 287-640 640-640zm-320-2880h640c176 0 320 144 320 320v802c0 110-12 204-38 311l-252 1006c-18 72-81 121-155 121h-390c-74 0-137-49-155-121l-252-1006c-26-107-38-201-38-311v-802c0-176 144-320 320-320z"
      fill="#252525"
    />
  </svg>
)
