import * as React from "react"
import { FormGroup } from "reactstrap"
import { Label } from "../../common/Form"
import { Select } from "../../common/Select"
import { fetchCaptures } from "../../../api/api"
import { Engine, ResponseGetCaptureList } from "../../../api/types"

function getCaptures(
  server: string,
  authToken: string,
  engine: Engine,
  setData: (data: ResponseGetCaptureList) => void
) {
  fetchCaptures(`${server}/engines/${engine.id}`, authToken)
    .then(captures => {
      setData(captures)
    })
    .catch(error => {
      console.error(error)
    })
}

type CaptureInfo = {
  id: string
  name: string
}

type CaptureCountersWidgetFormProps = {
  server: string
  authToken: string
  engine: Engine
  settings: any | null
  setSettings: (settings: any | null) => void
}

const CaptureCountersWidgetForm = ({
  server,
  authToken,
  engine,
  settings,
  setSettings,
}: CaptureCountersWidgetFormProps) => {
  const [captures, setCaptures] = React.useState<CaptureInfo[]>([])

  React.useEffect(() => {
    getCaptures(server, authToken, engine, data => {
      if (Array.isArray(data.captures)) {
        const newCaptures: CaptureInfo[] = data.captures.map((capture: any) => {
          return { id: capture.id, name: capture.name }
        })
        setCaptures(newCaptures)
        if (newCaptures.length > 0) {
          setSettings((settings: any | null) => {
            if (settings) {
              return {
                ...settings,
                captureId: newCaptures[0].id,
              }
            } else {
              return { captureId: newCaptures[0].id }
            }
          })
        }
      }
    })
  }, [server, authToken, engine, setSettings])

  return (
    <FormGroup>
      <Label for="capture">Capture</Label>
      <Select
        id="capture"
        value={settings && settings.captureId ? settings.captureId : ""}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const captureId = e.target.value
          if (settings) {
            setSettings({
              ...settings,
              captureId,
            })
          } else {
            setSettings({ captureId })
          }
        }}
      >
        {captures.map(capture => (
          <option key={capture.id} value={capture.id}>
            {capture.name}
          </option>
        ))}
      </Select>
    </FormGroup>
  )
}

export default CaptureCountersWidgetForm
