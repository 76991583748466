import * as React from "react"
import { connect } from "react-redux"
import { StaticContext } from "react-router"
import { Prompt, Redirect, RouteComponentProps } from "react-router-dom"
import { IndexRange } from "react-virtualized"
import { v4 as uuid } from "uuid"
import { cloneDeep, debounce, sortedIndex, sortedLastIndex, uniq } from "lodash"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import SplitterLayout from "react-splitter-layout"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import {
  View,
  ViewContent,
  ViewHeaderItems,
  ViewHeader,
  ViewHeaderTitle,
  ViewHeaderButtons,
} from "../common/View"
import Alert from "../common/Alert"
import { BarGauge } from "../common/BarGauge"
import { CloseButton, LightButton, LightDropdownToggle } from "../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../common/Dropdown"
import ForensicSearchModal from "../ForensicSearchModal"
import Interval from "../common/Interval"
import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarTitle,
  SidebarContent,
} from "../common/Sidebar"
import { Toast, ToastBody } from "../common/Toast"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import { GoToPacketInput } from "./GoToPacketInput"
import { SelectPacketsSidebar } from "./SelectPacketsSidebar"
import { SelectPacketsProgress, SelectPacketsResults } from "./SelectPacketsPanel"
import FilterBar from "./FilterBar"
import PacketsTable, { defaultColumns } from "./PacketsTable"
import DecodeView from "./DecodeView"
import ApplicationDescriptionModal from "../ApplicationDescriptionModal"
import ProtocolDescriptionModal from "../ProtocolDescriptionModal"
import { InsertNamesModal, InsertNameEntry } from "../InsertNamesModal"
import PacketsDownloadModal from "../PacketsDownloadModal"
import { MediaSubType, MediaType } from "../../api/types/mediaTypes"
import { download } from "../../utils/downloadUtils"
import { filterExpressionTypeFromMediaSpecType } from "../../utils/filterUtils"
import { formatInteger } from "../../utils/formatUtils"
import { formatMediaSpec, mediaSpecToProtoSpecID } from "../../utils/mediaSpec"
import { ranges } from "../../utils/ranges"
import {
  getCaptureForensicSearchUrl,
  getEngineForensicSearchUrl,
  getEngineNewFilterUrl,
  getNewDistributedForensicSearchUrl,
} from "../../routes"
import {
  getEngine,
  getAuthToken,
  getCapabilities,
  getNamesModificationTime,
  getShowAddressNames,
  getShowPortNames,
  getPacketsColumns,
  getPacketsColumnsDecode,
  getPacketsSelection,
  getPacketsShowDecodeView,
  getPacketsDecodePacketNumber,
  getPacketsShowFilterBar,
  getSelectPacketsTask,
  getUserId,
  getShowLocalTime,
} from "../../store"
import { updateStatus } from "../../store/status"
import {
  setPacketsColumns,
  setPacketsColumnsDecode,
  setPacketsSelection,
  setPacketsShowDecodeView,
  setPacketsDecodePacketNumber,
  setPacketsShowFilterBar,
} from "../../store/ui"
import { setCurrentEngine } from "../../store/engines"
import { setSelectPacketsTask } from "../../store/selectPackets"
import {
  createForensicSearch,
  deleteFiles,
  deleteSaveCaptureTask,
  fetchApplications,
  fetchCFSPacketList,
  fetchProtocols,
  fetchSaveCaptureTask,
  fetchSelectRelatedCancel,
  fetchSelectRelatedExpertCancel,
  fetchSelectRelatedExpertProgress,
  fetchSelectRelatedExpertResults,
  fetchSelectRelatedFilterCancel,
  fetchSelectRelatedFilterConfigCancel,
  fetchSelectRelatedFilterConfigProgress,
  fetchSelectRelatedFilterConfigResults,
  fetchSelectRelatedFilterProgress,
  fetchSelectRelatedFilterResults,
  fetchSelectRelatedProgress,
  fetchSelectRelatedResults,
  fetchSelectRelatedWebCancel,
  fetchSelectRelatedWebProgress,
  fetchSelectRelatedWebResults,
  getFileDownloadURL,
  postSaveCaptureTask,
  postSelectRelatedStart,
  resolveAddresses,
  saveCFSPackets,
  saveCFSSelectedPackets,
} from "../../api/api"
import {
  AddressResolverRequestEntry,
  ApplicationInfo,
  Filter,
  Packet,
  ProgressSelectRelatedResponse,
  Protocol,
  RequestCreateForensicSearch,
  RequestPostSelectRelatedStart,
  ResponseGetEngineCapabilities,
  ResultsSelectRelatedResponse,
} from "../../api/types"
import {
  PeekFilterMode,
  PeekFileViewStatus,
  PeekSelectRelatedParam,
} from "../../api/types/peekTypes"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { FilterNode, AddressFilterNode, PortFilterNode, ProtocolFilterNode } from "../../api/types"

const PacketsViewContent = styled(ViewContent)`
  position: relative;

  & .splitter-layout .layout-pane {
    overflow: hidden;
  }

  & .splitter-layout > .layout-splitter {
    width: 5px;
    background-color: ${props => props.theme.dividerColor} !important;
    transition: background-color 0.15s ease-in-out;
  }

  & .splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: 100%;
    height: 5px;
  }

  & .layout-splitter:hover,
  & .splitter-layout.layout-changing > .layout-splitter {
    background-color: ${props => props.theme.dividerHoverColor} !important;
  }
`

const ToastWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  min-width: 200px;
`

type SaveCaptureTask = {
  operation: "download" | "open" | "save"
  taskId: number
  localFileName: string
  progress: number
}

type LocationState = {
  goToPacketNumber?: number
  filter?: Filter
}

type PacketsViewProps = RouteComponentProps<CaptureRouteParams, StaticContext, LocationState> &
  CaptureViewProps & {
    engine: string
    authToken: string
    engineCapabilities: ResponseGetEngineCapabilities | null
    namesModTime?: string
    showAddressNames: boolean
    showPortNames: boolean
    showLocalTime: boolean
    columns: any[]
    decodeColumns: any[]
    selectedPackets: ReturnType<typeof getPacketsSelection>
    showDecodeView: boolean
    decodePacketNumber: number
    showFilterBar: boolean
    selectPacketsTask: any | null
    updateStatus: () => void
    setCurrentEngine: (id: string | null) => void
    setPacketsColumns: (columns: any[]) => void
    setPacketsColumnsDecode: (columns: any[]) => void
    setPacketsSelection: typeof setPacketsSelection
    setPacketsShowDecodeView: (show: boolean) => void
    setPacketsDecodePacketNumber: (decodePacketNumber: number) => void
    setPacketsShowFilterBar: (show: boolean) => void
    setSelectPacketsTask: (task: any) => void
    userId: string
  }

type PacketsViewState = {
  packets: Packet[]
  firstPacketNumber: number
  firstPacketTimestamp: string
  lastPacketTimestamp: string
  packetCount: number
  mediaType: MediaType
  mediaSubType: MediaSubType
  protocolDescriptions: Protocol[] | null
  protocolDescriptionId: number | null
  applicationDescriptions: ApplicationInfo[] | null
  applicationDescriptionId: string | number | null
  downloadFileExt: string
  downloadFileName: string
  saveCaptureTask: SaveCaptureTask | null
  selectPacketsOpen: boolean
  insertNameEntries: InsertNameEntry[] | null
  showPacketsDownloadModal: boolean
  showForensicSearchModal: boolean
  remoteFilePath: string
  toastMessage: React.ReactNode
  isLoadingNewRows: boolean
  saveFileAlertColor: string | null
  saveFileAlertMessage: string | null
  batchSize: number
}

class PacketsView extends React.Component<PacketsViewProps, PacketsViewState> {
  mounted: boolean = false
  table: any | null = null

  state: PacketsViewState = {
    packets: [],
    firstPacketNumber: 0,
    firstPacketTimestamp: "",
    lastPacketTimestamp: "",
    packetCount: 0,
    mediaType: MediaType.MEDIA_TYPE_802_3,
    mediaSubType: MediaSubType.MEDIA_SUBTYPE_NATIVE,
    protocolDescriptions: null,
    protocolDescriptionId: null,
    applicationDescriptions: null,
    applicationDescriptionId: null,
    downloadFileExt: "",
    downloadFileName: "",
    saveCaptureTask: null,
    selectPacketsOpen: false,
    insertNameEntries: null,
    showPacketsDownloadModal: false,
    showForensicSearchModal: false,
    remoteFilePath: "",
    toastMessage: null,
    isLoadingNewRows: false,
    saveFileAlertColor: null,
    saveFileAlertMessage: null,
    batchSize: 100,
  }

  componentDidMount() {
    this.mounted = true
    this.onRefresh()
    this.onRefreshProtocolDescriptions()
    this.onRefreshApplicationDescriptions()
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    window.addEventListener("beforeunload", this.onBeforeUnload)
  }

  componentWillUnmount() {
    this.mounted = false
    this.onSaveCaptureTaskCancel()
    window.removeEventListener("beforeunload", this.onBeforeUnload)
  }

  componentDidUpdate({ namesModTime, showAddressNames, showPortNames }: PacketsViewProps) {
    if (
      this.props.namesModTime !== namesModTime ||
      this.props.showAddressNames !== showAddressNames ||
      this.props.showPortNames !== showPortNames
    ) {
      this.reset()
    }
  }

  onBeforeUnload = (event: BeforeUnloadEvent) => {
    const { saveCaptureTask } = this.state
    if (saveCaptureTask) {
      // OD-2411 Prevent possible 'orphaned' file.
      // This requires the fetch api to use the 'keepalive' option.
      this.onSaveCaptureTaskCancel()
      if (navigator.userAgent.toLowerCase().indexOf("firefox") !== -1) {
        // Firefox doesn't support the 'keeplive' option (https://caniuse.com/?search=keepalive).
        // Return some text to make the browser stop and notify the user so the request works.
        // Note: the actual text doesn't matter.
        event.returnValue = "Save file task canceled"
        event.preventDefault()
      }
    }
    return undefined
  }

  reset() {
    this.setState({ packets: [] }, () => {
      if (this.table && this.table.infiniteLoader) {
        this.table.infiniteLoader.resetLoadMoreRowsCache(true)
      }
    })
  }

  getPacketsSelection = () => {
    const { selectedPackets: packetsSelection } = this.props
    const { capId } = this.props.match.params
    const selectedPackets =
      packetsSelection !== null ? packetsSelection.find(foo => foo.id === capId) : null
    return selectedPackets ? selectedPackets.selection : null
  }

  onRefresh = () => {
    // Just get the basic info, mainly packet count.
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSPacketList(engine, authToken, type, capId, 0, 0)
      .then(packets => {
        if (packets.packetCount !== undefined) {
          this.setState(
            {
              firstPacketNumber: packets.firstPacketNumber,
              firstPacketTimestamp: packets.firstPacketDateTime,
              lastPacketTimestamp: packets.lastPacketDateTime,
              packetCount: packets.packetCount,
              mediaType: packets.mediaType,
              mediaSubType: packets.mediaSubType,
            },
            () => {
              if (this.props.location.state && this.props.location.state.goToPacketNumber) {
                this.onGoToPacket(this.props.location.state.goToPacketNumber)
              }
            }
          )
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onRefreshProtocolDescriptions = () => {
    const { engine, authToken } = this.props
    fetchProtocols(engine, authToken)
      .then(protocols => {
        if (protocols && protocols.protocols) {
          this.setState({ protocolDescriptions: protocols.protocols })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onRefreshApplicationDescriptions = () => {
    const { engine, authToken } = this.props
    fetchApplications(engine, authToken)
      .then(applications => {
        if (applications && applications.applications) {
          this.setState({ applicationDescriptions: applications.applications })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onDownloadPackets = () => {
    const { captureProperties } = this.props
    const fileName = captureProperties ? captureProperties.name : "packets"
    const fileExt = localStorage.getItem("downloadPacketsFileExt")
    this.setState({
      downloadFileExt: fileExt !== null ? fileExt : "pkt",
      downloadFileName: fileName,
      showPacketsDownloadModal: true,
    })
  }

  onPacketsDownloadOK = (
    saveSelected: boolean,
    saveToEngine: boolean,
    fileName: string,
    fileExt: string
  ) => {
    const { engine, authToken, engineCapabilities } = this.props
    const { type, capId } = this.props.match.params
    const selectedPackets = this.getPacketsSelection()
    const localFileName = `${fileName}.${fileExt}`
    const remoteFileName = `${uuid().toUpperCase()}.${fileExt}`
    const sync = false
    localStorage.setItem("downloadPacketsFileExt", fileExt)
    if (
      engineCapabilities !== null &&
      engineCapabilities.capabilities.includes(EngineCapabilities.saveCaptureTasks)
    ) {
      const packetRanges =
        saveSelected && selectedPackets !== null ? ranges(selectedPackets.packets) : undefined
      postSaveCaptureTask(
        engine,
        authToken,
        capId,
        saveToEngine ? localFileName : remoteFileName,
        saveToEngine,
        packetRanges
      )
        .then(response => {
          if (saveToEngine) {
            this.setState({
              saveCaptureTask: {
                operation: "save",
                taskId: response.taskId,
                localFileName,
                progress: 0,
              },
              toastMessage: (
                <span>
                  <b>Saving file to engine:</b> {localFileName}
                </span>
              ),
            })
          } else {
            this.setState({
              saveCaptureTask: {
                operation: "download",
                taskId: response.taskId,
                localFileName,
                progress: 0,
              },
              toastMessage: (
                <span>
                  <b>Downloading file:</b> {localFileName}
                </span>
              ),
            })
          }
        })
        .catch(error => {
          console.error(error)
        })
    } else {
      if (saveSelected) {
        if (selectedPackets !== null) {
          const packetRanges = ranges(selectedPackets.packets)
          this.setState({
            toastMessage: (
              <span>
                <b>Saving file:</b> {localFileName}
              </span>
            ),
          })
          saveCFSSelectedPackets(engine, authToken, type, capId, remoteFileName, sync, packetRanges)
            .then(response => {
              download(
                getFileDownloadURL(engine, authToken, response.path, localFileName, true),
                response.fileName
              )
            })
            .catch(error => {
              console.error(error)
            })
            .finally(() => {
              this.setState({ toastMessage: null })
            })
        }
      } else {
        this.setState({
          toastMessage: (
            <span>
              <b>Saving file:</b> {localFileName}
            </span>
          ),
        })
        saveCFSPackets(engine, authToken, type, capId, remoteFileName, false)
          .then(response => {
            download(
              getFileDownloadURL(engine, authToken, response.path, localFileName, true),
              response.fileName
            )
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            this.setState({ toastMessage: null })
          })
      }
    }
    this.setState({ downloadFileExt: "", downloadFileName: "", showPacketsDownloadModal: false })
  }

  onPacketsDownloadCancel = () => {
    this.setState({ downloadFileExt: "", downloadFileName: "", showPacketsDownloadModal: false })
  }

  onSaveCaptureTaskProgress = () => {
    const { engine, authToken } = this.props
    const { saveCaptureTask } = this.state
    if (saveCaptureTask && saveCaptureTask.progress !== 100) {
      fetchSaveCaptureTask(engine, authToken, saveCaptureTask.taskId)
        .then(response => {
          if (response.progress != null) {
            if (response.progress === 100) {
              this.setState({ saveCaptureTask: null, toastMessage: null })
              if (response.result === 0) {
                if (
                  typeof response.path === "string" &&
                  response.path.length > 0 &&
                  typeof response.fileName === "string" &&
                  response.fileName.length > 0
                ) {
                  if (saveCaptureTask.operation === "download") {
                    download(
                      getFileDownloadURL(
                        engine,
                        authToken,
                        response.path,
                        saveCaptureTask.localFileName,
                        true
                      ),
                      response.fileName
                    )
                  } else if (saveCaptureTask.operation === "open") {
                    if (!this.mounted) {
                      deleteFiles(engine, authToken, [response.path]).catch(error => {
                        console.log(error)
                      })
                    } else {
                      this.setState({
                        showForensicSearchModal: true,
                        remoteFilePath: response.path,
                      })
                    }
                  } else if (saveCaptureTask.operation === "save") {
                    this.setState({
                      saveFileAlertColor: "success",
                      saveFileAlertMessage: `${saveCaptureTask.localFileName} has been saved to the engine.`,
                    })
                  }
                }
              } else {
                // Non-zero result.
                this.setState({
                  saveFileAlertColor: "danger",
                  saveFileAlertMessage: `Failed to ${saveCaptureTask.operation} file.`,
                })
              }
            } else {
              // Server still working.
              this.setState({
                saveCaptureTask: { ...saveCaptureTask, progress: response.progress },
              })
            }
          }
        })
        .catch(error => {
          // Server error.
          console.error(error)
          this.setState({
            saveFileAlertColor: "danger",
            saveFileAlertMessage: `Failed to ${saveCaptureTask.operation} file.`,
          })
          this.onSaveCaptureTaskCancel()
        })
    }
  }

  onSaveCaptureTaskCancel = () => {
    const { engine, authToken } = this.props
    const { saveCaptureTask } = this.state
    if (saveCaptureTask) {
      deleteSaveCaptureTask(engine, authToken, saveCaptureTask.taskId)
        .then(() => {
          this.setState({ saveCaptureTask: null, toastMessage: null })
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  onSelectPacketRange = (
    firstPacketNumber: number,
    lastPacketNumber: number,
    inverted: boolean
  ) => {
    if (firstPacketNumber <= lastPacketNumber) {
      const selectedPackets = []
      if (inverted) {
        for (let i = this.state.firstPacketNumber; i < firstPacketNumber; i++) {
          selectedPackets.push(i)
        }
        for (let i = lastPacketNumber + 1; i <= this.state.packetCount; i++) {
          selectedPackets.push(i)
        }
      } else {
        for (let i = firstPacketNumber; i <= lastPacketNumber; i++) {
          selectedPackets.push(i)
        }
      }

      // Unfortunately, packet timestamps for the range are not available.
      const { capId } = this.props.match.params
      this.props.setPacketsSelection(capId, { packets: selectedPackets })
      if (selectedPackets.length > 0 && this.table && this.table.table) {
        const firstSelectedPacketNumber = selectedPackets[0]
        const index = firstSelectedPacketNumber - this.state.firstPacketNumber
        this.table.table.scrollToRow(index)
        this.props.setPacketsDecodePacketNumber(firstSelectedPacketNumber)
      }
    }
  }

  onSelectPacketsTaskProgress = () => {
    const { engine, authToken, selectPacketsTask } = this.props
    if (selectPacketsTask && selectPacketsTask.progress !== 100) {
      let progessFunc: (
        engine: string,
        authToken: string,
        taskId: number
      ) => Promise<ProgressSelectRelatedResponse> = fetchSelectRelatedProgress
      let resultsFunc: (
        engine: string,
        authToken: string,
        taskId: number
      ) => Promise<ResultsSelectRelatedResponse> = fetchSelectRelatedResults
      if (selectPacketsTask.type === "expert") {
        progessFunc = fetchSelectRelatedExpertProgress
        resultsFunc = fetchSelectRelatedExpertResults
      } else if (selectPacketsTask.type === "filter") {
        progessFunc = fetchSelectRelatedFilterProgress
        resultsFunc = fetchSelectRelatedFilterResults
      } else if (selectPacketsTask.type === "filter-config") {
        progessFunc = fetchSelectRelatedFilterConfigProgress
        resultsFunc = fetchSelectRelatedFilterConfigResults
      } else if (selectPacketsTask.type === "web") {
        progessFunc = fetchSelectRelatedWebProgress
        resultsFunc = fetchSelectRelatedWebResults
      }
      // Check progress for the current task.
      progessFunc(engine, authToken, selectPacketsTask.taskId)
        .then(task => {
          if (task.progress) {
            this.props.setSelectPacketsTask({
              type: selectPacketsTask.type,
              taskId: selectPacketsTask.taskId,
              progress: task.progress,
            })
            if (task.progress === 100) {
              // Task complete, get results
              resultsFunc(engine, authToken, selectPacketsTask.taskId)
                .then(selection => {
                  if (Array.isArray(selection.packets)) {
                    this.props.setSelectPacketsTask(null)
                    const selectedPackets = this.getPacketsSelection()
                    if (
                      selectPacketsTask.replace !== undefined &&
                      !selectPacketsTask.replace &&
                      selectedPackets !== null
                    ) {
                      // Combine the packet selection.
                      selection.packets = uniq(
                        selection.packets.concat(selectedPackets.packets)
                      ).sort((a, b) => {
                        if (a < b) return -1
                        if (a > b) return 1
                        return 0
                      })
                      // Combine the first packet times.
                      if (
                        typeof selection.firstPacketDateTime === "string" &&
                        typeof selectedPackets.firstPacketDateTime === "string"
                      ) {
                        if (selectedPackets.firstPacketDateTime < selection.firstPacketDateTime) {
                          selection.firstPacketDateTime = selectedPackets.firstPacketDateTime
                        }
                      }
                      // Combine the last packet times.
                      if (
                        typeof selection.lastPacketDateTime === "string" &&
                        typeof selectedPackets.lastPacketDateTime === "string"
                      ) {
                        if (selectedPackets.lastPacketDateTime > selection.lastPacketDateTime) {
                          selection.lastPacketDateTime = selectedPackets.lastPacketDateTime
                        }
                      }
                    }
                    const { capId } = this.props.match.params
                    this.props.setPacketsSelection(capId, selection)
                    if (selection.packets.length > 0 && this.table && this.table.table) {
                      const firstSelectedPacketNumber = selection.packets[0]
                      const index = firstSelectedPacketNumber - this.state.firstPacketNumber
                      this.table.table.scrollToRow(index)
                      this.props.setPacketsDecodePacketNumber(firstSelectedPacketNumber)
                    }
                  }
                })
                .catch(error => {
                  console.error(error)
                })
            }
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  onSelectPacketsTaskCancel = () => {
    const { engine, authToken, selectPacketsTask } = this.props
    if (selectPacketsTask) {
      let cancelFunc: (engine: string, authToken: string, taskId: number) => Promise<void> =
        fetchSelectRelatedCancel
      if (selectPacketsTask.type === "expert") {
        cancelFunc = fetchSelectRelatedExpertCancel
      } else if (selectPacketsTask.type === "filter") {
        cancelFunc = fetchSelectRelatedFilterCancel
      } else if (selectPacketsTask.type === "filter-config") {
        cancelFunc = fetchSelectRelatedFilterConfigCancel
      } else if (selectPacketsTask.type === "web") {
        cancelFunc = fetchSelectRelatedWebCancel
      }
      cancelFunc(engine, authToken, selectPacketsTask.taskId)
        .then(() => {
          this.props.setSelectPacketsTask(null)
          this.props.setPacketsSelection(this.props.match.params.capId, null)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  onSelectPacketsResultsClose = () => {
    this.props.setPacketsSelection(this.props.match.params.capId, null)
  }

  onPrevSelectedPacket = () => {
    const { decodePacketNumber } = this.props
    const selectedPackets = this.getPacketsSelection()
    if (decodePacketNumber !== 0 && selectedPackets !== null) {
      const index = sortedIndex(selectedPackets.packets, decodePacketNumber)
      if (index > 0) {
        const packetNumber = selectedPackets.packets[index - 1]
        this.props.setPacketsDecodePacketNumber(packetNumber)
        const { firstPacketNumber } = this.state
        if (packetNumber >= firstPacketNumber) {
          const row = packetNumber - firstPacketNumber
          this.table.table.scrollToRow(row)
        }
      }
    }
  }

  onNextSelectedPacket = () => {
    const { decodePacketNumber } = this.props
    const selectedPackets = this.getPacketsSelection()
    if (decodePacketNumber !== 0 && selectedPackets !== null) {
      const index = sortedLastIndex(selectedPackets.packets, decodePacketNumber)
      if (index !== -1) {
        const packetNumber = selectedPackets.packets[index]
        this.props.setPacketsDecodePacketNumber(packetNumber)
        const { firstPacketNumber } = this.state
        if (packetNumber >= firstPacketNumber) {
          const row = packetNumber - firstPacketNumber
          this.table.table.scrollToRow(row)
        }
      }
    }
  }

  onPrevPacket = () => {
    const { decodePacketNumber } = this.props
    const { packets } = this.state
    if (decodePacketNumber !== 0 && packets) {
      const index = packets.findIndex(packet => packet.packetNumber === decodePacketNumber)
      if (index > 0) {
        const packetNumber = packets[index - 1].packetNumber
        this.props.setPacketsDecodePacketNumber(packetNumber)
        const { firstPacketNumber } = this.state
        if (packetNumber >= firstPacketNumber) {
          const row = packetNumber - firstPacketNumber
          this.table.table.scrollToRow(row)
        }
      }
    }
  }

  onNextPacket = () => {
    const { decodePacketNumber } = this.props
    const { packets } = this.state
    if (decodePacketNumber !== 0 && packets) {
      const index = packets.findIndex(packet => packet.packetNumber === decodePacketNumber)
      if (index !== -1 && index + 1 < packets.length) {
        const packetNumber = packets[index + 1].packetNumber
        this.props.setPacketsDecodePacketNumber(packetNumber)
        const { firstPacketNumber } = this.state
        if (packetNumber >= firstPacketNumber) {
          const row = packetNumber - firstPacketNumber
          this.table.table.scrollToRow(row)
        }
      }
    }
  }

  onGoToPacket = (packetNumber: number) => {
    const { firstPacketNumber, packetCount } = this.state
    if (packetNumber >= firstPacketNumber && packetNumber <= packetCount) {
      this.props.setPacketsDecodePacketNumber(packetNumber)
      const row = packetNumber - firstPacketNumber
      this.table.table.scrollToRow(row)
    }
  }

  onSelectPacketsOpen = (open: boolean) => {
    this.setState({ selectPacketsOpen: open })
  }

  onToggleDecodeView = () => {
    this.props.setPacketsShowDecodeView(!this.props.showDecodeView)
  }

  onToggleFilterBar = () => {
    this.props.setPacketsShowFilterBar(!this.props.showFilterBar)
  }

  onFlowVisualizer = (rowData: Packet) => {
    if (rowData.flowId) {
      this.props.history.push(`flow-visualizer/${rowData.flowId}`)
    }
  }

  onSelectRelated = (command: PeekSelectRelatedParam, rowData: Packet) => {
    const body: RequestPostSelectRelatedStart = {
      packets: [rowData.packetNumber],
      preferLogicalAddress: true,
      selectRelatedBy: command,
    }
    const { capId } = this.props.match.params
    const { engine, authToken } = this.props
    postSelectRelatedStart(engine, authToken, capId, body)
      .then(task => {
        if (task.taskId) {
          this.props.setSelectPacketsTask({ type: "packets", taskId: task.taskId, progress: 0 })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onOpenSelectedPackets = () => {
    const { engine, authToken, engineCapabilities } = this.props
    const { type, capId } = this.props.match.params
    const selectedPackets = this.getPacketsSelection()
    if (selectedPackets !== null) {
      const remoteFileName = `${uuid().toUpperCase()}.pkt`
      const sync = false
      const packetRanges = ranges(selectedPackets.packets)
      if (
        engineCapabilities !== null &&
        engineCapabilities.capabilities.includes(EngineCapabilities.saveCaptureTasks)
      ) {
        postSaveCaptureTask(engine, authToken, capId, remoteFileName, false, packetRanges)
          .then(response => {
            this.setState({
              saveCaptureTask: {
                operation: "open",
                taskId: response.taskId,
                localFileName: "",
                progress: 0,
              },
              toastMessage: (
                <span>
                  <b>Saving selected packets...</b>
                </span>
              ),
            })
          })
          .catch(error => {
            console.error(error)
          })
      } else {
        this.setState({
          toastMessage: (
            <span>
              <b>Saving selected packets...</b>
            </span>
          ),
        })
        saveCFSSelectedPackets(engine, authToken, type, capId, remoteFileName, sync, packetRanges)
          .then(response => {
            if (!this.mounted) {
              deleteFiles(engine, authToken, [response.path]).catch(error => {
                console.log(error)
              })
            } else {
              this.setState({ showForensicSearchModal: true, remoteFilePath: response.path })
            }
          })
          .catch(error => {
            console.error(error)
          })
          .finally(() => {
            if (this.mounted) {
              this.setState({ toastMessage: null })
            }
          })
      }
    }
  }

  onOpenSelectedPacketsCancel = () => {
    this.setState({ showForensicSearchModal: false })
    const { engine, authToken } = this.props
    const { remoteFilePath } = this.state
    if (remoteFilePath) {
      deleteFiles(engine, authToken, [remoteFilePath])
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.setState({ remoteFilePath: "" })
        })
    }
  }

  onOpenSelectedPacketsOK = (query: RequestCreateForensicSearch) => {
    const { engine, authToken } = this.props
    this.setState({ showForensicSearchModal: false })
    query.filterMode = query.filter
      ? PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_MATCHING_ANY
      : PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_ALL
    query.deleteFiles = true
    query.indexing = false
    query.useDatabase = false
    createForensicSearch(engine, authToken, query)
      .then(response => {
        this.props.history.push(getEngineForensicSearchUrl(response.id))
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.setState({ remoteFilePath: "" })
      })
  }

  onMSA = (rowData: Packet) => {
    let addressFilterNode: string | undefined
    if (rowData.source && rowData.destination && rowData.sourceMediaSpec) {
      const parts: string[] = []
      parts.push(`type:${filterExpressionTypeFromMediaSpecType(rowData.sourceMediaSpec.type)}`)
      parts.push(`addr1:'${rowData.source}'`)
      parts.push(`addr2:'${rowData.destination}'`)
      addressFilterNode = `addr(${parts.join(", ")})`
    } else if (
      rowData.sourceLogical &&
      rowData.destinationLogical &&
      rowData.sourceLogicalMediaSpec
    ) {
      const parts: string[] = []
      parts.push(
        `type:${filterExpressionTypeFromMediaSpecType(rowData.sourceLogicalMediaSpec.type)}`
      )
      parts.push(`addr1:'${rowData.sourceLogical}'`)
      parts.push(`addr2:'${rowData.destinationLogical}'`)
      addressFilterNode = `addr(${parts.join(", ")})`
    } else if (
      rowData.sourcePhysical &&
      rowData.destinationPhysical &&
      rowData.sourcePhysicalMediaSpec
    ) {
      const parts: string[] = []
      parts.push(
        `type:${filterExpressionTypeFromMediaSpecType(rowData.sourcePhysicalMediaSpec.type)}`
      )
      parts.push(`addr1:'${rowData.sourcePhysical}'`)
      parts.push(`addr2:'${rowData.destinationPhysical}'`)
      addressFilterNode = `addr(${parts.join(", ")})`
    }

    let portFilterNode: string | undefined
    if (rowData.sourcePort && rowData.destinationPort && rowData.sourcePortMediaSpec) {
      const parts: string[] = []
      const type = filterExpressionTypeFromMediaSpecType(rowData.sourcePortMediaSpec.type)
      if (type) {
        parts.push(`type:${type}`)
      }
      parts.push(`port1:'${rowData.sourcePort}'`)
      parts.push(`port2:'${rowData.destinationPort}'`)
      portFilterNode = `port(${parts.join(", ")})`
    }

    let filter: string | undefined
    if (addressFilterNode && portFilterNode) {
      filter = `${addressFilterNode} & ${portFilterNode}`
    } else if (addressFilterNode) {
      filter = addressFilterNode
    } else if (portFilterNode) {
      filter = portFilterNode
    }

    this.props.setCurrentEngine(null)
    this.props.history.push({
      pathname: getNewDistributedForensicSearchUrl(),
      state: {
        startTime: this.props.captureProperties?.startTime,
        endTime: this.props.captureProperties?.stopTime,
        filter,
      } as any,
    })
  }

  onMakeFilter = (rowData: Packet) => {
    let addressFilterNode: AddressFilterNode | undefined
    if (
      rowData.source &&
      rowData.destination &&
      rowData.sourceMediaSpec &&
      rowData.destinationMediaSpec &&
      rowData.sourceMediaSpec.type &&
      rowData.destinationMediaSpec.type
    ) {
      addressFilterNode = {
        accept1To2: true,
        accept2To1: true,
        address1: formatMediaSpec(rowData.sourceMediaSpec),
        address2: formatMediaSpec(rowData.destinationMediaSpec),
        clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
        comment: "",
        inverted: false,
        type: rowData.sourceMediaSpec.type,
      }
    } else if (
      rowData.sourceLogical &&
      rowData.destinationLogical &&
      rowData.sourceLogicalMediaSpec &&
      rowData.destinationLogicalMediaSpec &&
      rowData.sourceLogicalMediaSpec.type &&
      rowData.destinationLogicalMediaSpec
    ) {
      addressFilterNode = {
        accept1To2: true,
        accept2To1: true,
        address1: formatMediaSpec(rowData.sourceLogicalMediaSpec),
        address2: formatMediaSpec(rowData.destinationLogicalMediaSpec),
        clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
        comment: "",
        inverted: false,
        type: rowData.sourceLogicalMediaSpec.type,
      }
    } else if (
      rowData.sourcePhysical &&
      rowData.destinationPhysical &&
      rowData.sourcePhysicalMediaSpec &&
      rowData.destinationPhysicalMediaSpec &&
      rowData.sourcePhysicalMediaSpec.type &&
      rowData.destinationPhysicalMediaSpec.type
    ) {
      addressFilterNode = {
        accept1To2: true,
        accept2To1: true,
        address1: formatMediaSpec(rowData.sourcePhysicalMediaSpec),
        address2: formatMediaSpec(rowData.destinationPhysicalMediaSpec),
        clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
        comment: "",
        inverted: false,
        type: rowData.sourcePhysicalMediaSpec.type,
      }
    }

    let protocolFilterNode: ProtocolFilterNode | undefined
    if (rowData.protocolMediaSpec) {
      protocolFilterNode = {
        clsid: "A43DDCC0-CDD2-46B4-8114-68E5FAF35112",
        comment: "",
        inverted: false,
        protocol: rowData.protocolMediaSpec,
        protospecPath: "",
        sliceToHeader: false,
      }
    }

    let portFilterNode: PortFilterNode | undefined
    if (
      rowData.sourcePort &&
      rowData.destinationPort &&
      rowData.sourcePortMediaSpec &&
      rowData.destinationPortMediaSpec &&
      rowData.sourcePortMediaSpec.type &&
      rowData.destinationPortMediaSpec.type
    ) {
      portFilterNode = {
        accept1To2: true,
        accept2To1: true,
        clsid: "B3279AE9-91E1-4D0A-8ABD-6D1BDC5471A9",
        comment: "",
        inverted: false,
        port1: formatMediaSpec(rowData.sourcePortMediaSpec),
        port2: formatMediaSpec(rowData.destinationPortMediaSpec),
        type: rowData.sourcePortMediaSpec.type,
      }
    }

    let rootFilterNode: FilterNode | undefined
    if (addressFilterNode !== undefined) {
      rootFilterNode = addressFilterNode
    }
    if (protocolFilterNode) {
      if (addressFilterNode) {
        addressFilterNode.andNode = protocolFilterNode
      } else {
        rootFilterNode = protocolFilterNode
      }
    }
    if (portFilterNode) {
      if (protocolFilterNode) {
        protocolFilterNode.andNode = portFilterNode
      } else if (addressFilterNode) {
        addressFilterNode.andNode = portFilterNode
      } else {
        rootFilterNode = portFilterNode
      }
    }

    const filter: Filter = {
      clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
      color: "#000000",
      comment: "",
      created: "",
      group: "",
      id: "",
      modified: "",
      name: "Untitled",
      rootNode: rootFilterNode,
    }
    this.props.history.push({
      pathname: getEngineNewFilterUrl(),
      state: { filter },
    })
  }

  onInsertIntoNameTable = (rowData: Packet) => {
    const insertNameEntries: InsertNameEntry[] = []
    if (rowData.source != null && rowData.sourceMediaSpec != null) {
      insertNameEntries.push({
        title: "Source Address",
        entry: rowData.source,
        entryType: rowData.sourceMediaSpec.type,
      })
    }
    if (rowData.destination != null && rowData.destinationMediaSpec != null) {
      insertNameEntries.push({
        title: "Destination Address",
        entry: rowData.destination,
        entryType: rowData.destinationMediaSpec.type,
      })
    }
    if (insertNameEntries.length > 0) {
      this.setState({ insertNameEntries })
    }
  }

  onResolveNames = (rowData: Packet) => {
    const entries: AddressResolverRequestEntry[] = []
    try {
      if (rowData.sourceMediaSpec != null) {
        entries.push({
          entry: formatMediaSpec(rowData.sourceMediaSpec),
          entryType: rowData.sourceMediaSpec.type,
        })
      }
      if (rowData.destinationMediaSpec != null) {
        entries.push({
          entry: formatMediaSpec(rowData.destinationMediaSpec),
          entryType: rowData.destinationMediaSpec.type,
        })
      }
    } catch (e) {
      console.error(e)
    }
    if (entries.length > 0) {
      resolveAddresses(this.props.engine, this.props.authToken, entries).catch(error => {
        console.error(error)
      })
      this.props.updateStatus()
    }
  }

  onInsertIntoNameTableOK = () => {
    this.setState({ insertNameEntries: null })
    this.reset()
  }

  onInsertIntoNameTableCancel = () => {
    this.setState({ insertNameEntries: null })
  }

  onProtocolDescription = (rowData: Packet) => {
    if (rowData.protocolMediaSpec) {
      const protocolDescriptionId = mediaSpecToProtoSpecID(rowData.protocolMediaSpec)
      if (protocolDescriptionId !== 0) {
        this.setState({ protocolDescriptionId })
      }
    }
  }

  onProtocolDescriptionClose = () => {
    this.setState({ protocolDescriptionId: null })
  }

  onApplicationDescription = (rowData: Packet) => {
    if (rowData.applicationId) {
      this.setState({ applicationDescriptionId: rowData.applicationId })
    }
  }

  onApplicationDescriptionClose = () => {
    this.setState({ applicationDescriptionId: null })
  }

  isRowLoaded = ({ index }: { index: number }) => {
    const { firstPacketNumber } = this.state
    const packetNumber = firstPacketNumber + index
    return this.isPacketLoaded(packetNumber)
  }

  isPacketLoaded = (packetNumber: number) => {
    const { packets } = this.state
    return packets.findIndex(packet => packet.packetNumber === packetNumber) >= 0
  }

  scrollToPacket = (packetNumber: number) => {
    const { firstPacketNumber, packetCount } = this.state
    if (this.table && this.table.table) {
      if (packetNumber < firstPacketNumber || packetNumber > packetCount) {
        this.table.table.scrollToRow(packetCount - firstPacketNumber)
      } else {
        this.table.table.scrollToRow(packetNumber - firstPacketNumber)
      }
    }
  }

  loadMoreRows = debounce(({ startIndex, stopIndex }: IndexRange) => {
    if (this.state.isLoadingNewRows) {
      return Promise.resolve()
    }

    this.setState({ isLoadingNewRows: true })

    const { engine, authToken, showAddressNames, showPortNames } = this.props
    const { firstPacketNumber, batchSize } = this.state
    const requestedFirstPacket = firstPacketNumber + startIndex
    const requestedPacketCount = stopIndex - startIndex + 1
    const columns = this.props.columns.filter(col => col.visible).map(col => col.dataKey)
    const decodeColumns = this.props.decodeColumns.map(dcol => dcol.dataKey)
    const { type, capId } = this.props.match.params
    return fetchCFSPacketList(
      engine,
      authToken,
      type,
      capId,
      requestedFirstPacket,
      requestedPacketCount,
      columns,
      decodeColumns,
      null,
      showAddressNames,
      showPortNames
    )
      .then(response => {
        if (Array.isArray(response.packets)) {
          if (response.packets.length === 0) {
            this.setState(
              {
                firstPacketNumber: response.firstPacketNumber,
                firstPacketTimestamp: response.firstPacketDateTime,
                lastPacketTimestamp: response.lastPacketDateTime,
                packetCount: response.packetCount,
                isLoadingNewRows: false,
              },
              () => {
                if (
                  requestedFirstPacket < response.firstPacketNumber ||
                  requestedFirstPacket > response.packetCount
                ) {
                  this.scrollToPacket(response.packetCount)
                }
              }
            )
          } else {
            let packets = response.packets
            let resetCache = false
            const packetsLength = this.state.packets.length
            if (packetsLength >= batchSize * 2) {
              resetCache = true
            } else if (packetsLength > 0) {
              const respPacketsLength = response.packets.length
              if (
                this.state.packets[0].packetNumber ===
                packets[respPacketsLength - 1].packetNumber + 1
              ) {
                // Response packets directly precece existing state.
                packets = packets.concat(this.state.packets)
              } else if (
                this.state.packets[packetsLength - 1].packetNumber ===
                packets[0].packetNumber - 1
              ) {
                // Response packets immediately follow existing state.
                packets = this.state.packets.concat(packets)
              } else {
                resetCache = true
              }
            }
            this.setState(
              {
                packets,
                firstPacketTimestamp: response.firstPacketDateTime,
                lastPacketTimestamp: response.lastPacketDateTime,
                isLoadingNewRows: false,
              },
              () => {
                if (resetCache && this.table && this.table.infiniteLoader) {
                  this.table.infiniteLoader.resetLoadMoreRowsCache(false)
                }
              }
            )
          }
        }
      })
      .catch(error => {
        this.setState({ isLoadingNewRows: false })
        console.error(error)
      })
  }, 50)

  onToggleColumn = (e: any) => {
    const columns = cloneDeep(this.props.columns)
    const col = columns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      this.props.setPacketsColumns(columns)
    } else {
      const decodeColumns = cloneDeep(this.props.decodeColumns)
      const dcol = decodeColumns.find(dcol => dcol.dataKey === e.target.name)
      if (dcol) {
        this.props.setPacketsColumnsDecode(
          decodeColumns.filter(col => {
            return col !== dcol
          })
        )
      }
    }
    this.reset()
  }

  onAddDecodeColumn = (ancestry: string, summary: string, text: string) => {
    const decodeColumns = cloneDeep(this.props.decodeColumns)
    const col = {
      dataKey: `decode${ancestry}/${summary}`,
      label: `Decode${ancestry}/${text}`,
      width: 160,
      flexGrow: true,
      alignRight: false,
      visible: true,
      ethernet: true,
      wireless: true,
      wan: true,
    }

    const found = decodeColumns.find(dcol => dcol.dataKey === col.dataKey)
    if (!found) {
      decodeColumns.push(col)
      this.props.setPacketsColumnsDecode(decodeColumns)
    }

    this.reset()
  }

  onRowClick = ({ rowData }: { rowData: Packet }) => {
    if (
      rowData.packetNumber !== undefined &&
      rowData.packetNumber !== this.props.decodePacketNumber
    ) {
      this.props.setPacketsDecodePacketNumber(rowData.packetNumber)
    }
    if (!this.props.showDecodeView) {
      this.props.setPacketsShowDecodeView(true)
    }
  }

  render() {
    const {
      packets,
      firstPacketNumber,
      firstPacketTimestamp,
      lastPacketTimestamp,
      packetCount,
      mediaType,
      mediaSubType,
      protocolDescriptions,
      protocolDescriptionId,
      applicationDescriptions,
      applicationDescriptionId,
      toastMessage,
      downloadFileExt,
      downloadFileName,
      saveCaptureTask,
      selectPacketsOpen,
      insertNameEntries,
      showPacketsDownloadModal,
      showForensicSearchModal,
      saveFileAlertColor,
      saveFileAlertMessage,
    } = this.state
    const rowCount =
      packetCount > firstPacketNumber ? packetCount - firstPacketNumber + 1 : packetCount
    const {
      captureProperties,
      engineCapabilities,
      columns,
      decodeColumns,
      showDecodeView,
      decodePacketNumber,
      showFilterBar,
      selectPacketsTask,
      userId,
    } = this.props
    const { type, capId } = this.props.match.params

    const columnsCombined = columns.concat(decodeColumns)

    // make sure the user can view packets for this capture or forensic search and download files
    let canUploadFiles = true
    let canViewPackets = true
    let canSaveFiles = true
    let canCreateForensicSearch = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canUploadFiles = policies.includes(EngineUserPolicies.uploadFiles)
      canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
      canSaveFiles = policies.includes(
        engineCapabilities.capabilities.includes(EngineCapabilities.saveFilesACL)
          ? EngineUserPolicies.saveFiles
          : EngineUserPolicies.downloadFiles
      )
      canCreateForensicSearch =
        !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL) ||
        policies.includes(EngineUserPolicies.createForensicSearch)
    }
    if (!canViewPackets) {
      return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
    }

    const selectedPackets = this.getPacketsSelection()
    const isDecodeSnippetsSupported =
      engineCapabilities !== null &&
      engineCapabilities.capabilities.includes(EngineCapabilities.decodeSnippets)
    const isOpenSelectedPacketsSupported =
      engineCapabilities !== null &&
      engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchWithoutDatabase)
    const isDecodeColumnSupported =
      engineCapabilities !== null &&
      engineCapabilities.capabilities.includes(EngineCapabilities.webPacketDecodeColumns)
    const isInProgressForensicSearch =
      captureProperties !== null &&
      "openResult" in captureProperties && // Is it a forensic search?
      captureProperties.status === PeekFileViewStatus.PEEK_FILE_VIEW_STATUS_OPENING
    const downloadDisabled = rowCount === 0 || isInProgressForensicSearch || !canSaveFiles

    let prevPacketDisabled = true
    let nextPacketDisabled = true
    if (decodePacketNumber !== 0 && packets && packets.length > 0) {
      prevPacketDisabled = decodePacketNumber === packets[0].packetNumber
      nextPacketDisabled = decodePacketNumber === packets[packets.length - 1].packetNumber
    }

    let prevSelectedPacketDisabled = true
    let nextSelectedPacketDisabled = true
    if (
      decodePacketNumber !== 0 &&
      selectedPackets !== null &&
      selectedPackets.packets.length > 0
    ) {
      prevSelectedPacketDisabled = decodePacketNumber === selectedPackets.packets[0]
      nextSelectedPacketDisabled =
        decodePacketNumber === selectedPackets.packets[selectedPackets.packets.length - 1]
    }
    const openSelectedPacketsDisabled =
      selectedPackets === null ||
      selectedPackets.packets.length === 0 ||
      downloadDisabled ||
      !canCreateForensicSearch

    return (
      <View>
        <Prompt
          when={toastMessage !== null}
          message="Warning! Leaving this view will stop the file save in progress."
        />
        <BreadcrumbItem to={this.props.match.url} title="Packets" />
        <Interval
          enabled={selectPacketsTask !== null}
          timeout={1000}
          callback={this.onSelectPacketsTaskProgress}
        />
        <Interval
          enabled={saveCaptureTask !== null}
          timeout={1000}
          callback={this.onSaveCaptureTaskProgress}
        />
        <Alert
          color={saveFileAlertColor !== null ? saveFileAlertColor : "light"}
          isOpen={saveFileAlertMessage !== null}
          toggle={() => this.setState({ saveFileAlertColor: null, saveFileAlertMessage: null })}
        >
          {saveFileAlertMessage}
        </Alert>
        <ViewHeaderItems>
          <ViewHeader border={false} style={{ marginBottom: 0 }}>
            <ViewHeaderTitle title="Packets" count={packetCount} />
            <ViewHeaderButtons>
              <LightButton
                aria-label="Previous packet"
                id="prev-packet"
                onClick={this.onPrevPacket}
                disabled={prevPacketDisabled}
              >
                <FontAwesome name="arrow-left" />
              </LightButton>
              <UncontrolledTooltip
                disabled={prevPacketDisabled}
                placement="top"
                target="prev-packet"
              >
                Previous Packet
              </UncontrolledTooltip>
              <LightButton
                aria-label="Next packet"
                id="next-packet"
                onClick={this.onNextPacket}
                disabled={nextPacketDisabled}
              >
                <FontAwesome name="arrow-right" />
              </LightButton>
              <UncontrolledTooltip
                disabled={nextPacketDisabled}
                placement="top"
                target="next-packet"
              >
                Next Packet
              </UncontrolledTooltip>
              <GoToPacketInput
                value={decodePacketNumber}
                min={firstPacketNumber}
                max={packetCount}
                onGoToPacket={this.onGoToPacket}
              />
              <LightButton
                aria-label="Select"
                id="select"
                onClick={this.onSelectPacketsOpen.bind(this, true)}
              >
                Select
              </LightButton>
              <LightButton
                aria-label="Download"
                id="download"
                disabled={downloadDisabled}
                onClick={this.onDownloadPackets}
              >
                <FontAwesome name="download" />
              </LightButton>
              <UncontrolledTooltip placement="top" target="download">
                Download
              </UncontrolledTooltip>
              <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefresh}>
                <FontAwesome name="refresh" />
              </LightButton>
              <UncontrolledTooltip placement="top" target="refresh">
                Refresh
              </UncontrolledTooltip>
              <UncontrolledDropdown>
                <LightDropdownToggle aria-label="View options" id="view-options">
                  <FontAwesome name="ellipsis-h" />
                </LightDropdownToggle>
                <DropdownMenu end>
                  <DropdownItem onClick={this.onToggleFilterBar}>
                    {showFilterBar ? "Hide Filter Bar" : "Show Filter Bar"}
                  </DropdownItem>
                  <DropdownItem onClick={this.onToggleDecodeView}>
                    {showDecodeView ? "Hide Decode View" : "Show Decode View"}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </ViewHeaderButtons>
          </ViewHeader>
          {showFilterBar && <FilterBar capId={this.props.match.params.capId} />}
          {selectPacketsTask !== null ? (
            <SelectPacketsProgress
              progress={selectPacketsTask.progress}
              onCancel={this.onSelectPacketsTaskCancel}
            />
          ) : selectedPackets !== null ? (
            <SelectPacketsResults
              message={`${formatInteger(selectedPackets.packets.length)} packets selected`}
              onPrevSelectedPacket={this.onPrevSelectedPacket}
              prevSelectedPacketDisabled={prevSelectedPacketDisabled}
              onNextSelectedPacket={this.onNextSelectedPacket}
              nextSelectedPacketDisabled={nextSelectedPacketDisabled}
              onOpenSelectedPackets={
                isOpenSelectedPacketsSupported ? this.onOpenSelectedPackets : undefined
              }
              openSelectedPacketsDisabled={openSelectedPacketsDisabled}
              onClose={this.onSelectPacketsResultsClose}
            />
          ) : null}
        </ViewHeaderItems>
        <PacketsViewContent>
          {packets ? (
            <SplitterLayout vertical>
              <PacketsTable
                ref={ref => {
                  this.table = ref
                }}
                packets={packets}
                firstPacketNumber={firstPacketNumber}
                rowCount={rowCount}
                engineCapabilities={engineCapabilities}
                mediaType={mediaType}
                mediaSubType={mediaSubType}
                columnDesc={columnsCombined}
                selectedPackets={selectedPackets}
                decodePacketNumber={decodePacketNumber}
                captureProperties={captureProperties}
                canDoMSA={canUploadFiles && canCreateForensicSearch}
                canViewPackets={canViewPackets}
                onToggleColumn={this.onToggleColumn}
                onRowClick={this.onRowClick}
                isRowLoaded={this.isRowLoaded}
                loadMoreRows={this.loadMoreRows as (params: IndexRange) => Promise<void>}
                onFlowVisualizer={this.onFlowVisualizer}
                onSelectRelated={this.onSelectRelated}
                onInsertIntoNameTable={this.onInsertIntoNameTable}
                onResolveNames={this.onResolveNames}
                onMakeFilter={this.onMakeFilter}
                onProtocolDescription={this.onProtocolDescription}
                onApplicationDescription={this.onApplicationDescription}
                onMSA={this.onMSA}
                showLocalTime={this.props.showLocalTime}
              />
              {showDecodeView && (
                <DecodeView
                  type={this.props.match.params.type}
                  capId={this.props.match.params.capId}
                  engine={this.props.engine}
                  authToken={this.props.authToken}
                  viewType={isDecodeSnippetsSupported ? "standard" : "html"}
                  decodePacketNumber={decodePacketNumber}
                  namesModTime={this.props.namesModTime}
                  onAddDecodeColumn={isDecodeColumnSupported ? this.onAddDecodeColumn : null}
                />
              )}
            </SplitterLayout>
          ) : null}
        </PacketsViewContent>
        <ProtocolDescriptionModal
          isOpen={protocolDescriptionId !== null}
          protocolId={protocolDescriptionId}
          descriptions={protocolDescriptions}
          onClose={this.onProtocolDescriptionClose}
        />
        <ApplicationDescriptionModal
          isOpen={applicationDescriptionId !== null}
          application={applicationDescriptionId}
          descriptions={applicationDescriptions}
          onClose={this.onApplicationDescriptionClose}
        />
        <Sidebar open={selectPacketsOpen}>
          <SidebarBody open={selectPacketsOpen}>
            <SidebarHeader>
              <SidebarTitle>Select Packets</SidebarTitle>
              <CloseButton onClick={() => this.onSelectPacketsOpen(false)} />
            </SidebarHeader>
            <SidebarContent>
              <SelectPacketsSidebar
                capId={capId}
                firstPacketNumber={firstPacketNumber}
                packetCount={packetCount}
                packetStartTime={firstPacketTimestamp}
                packetEndTime={lastPacketTimestamp}
                selectPacketRange={this.onSelectPacketRange}
                setSelectPacketsTask={this.props.setSelectPacketsTask}
              />
            </SidebarContent>
          </SidebarBody>
        </Sidebar>
        {insertNameEntries != null && (
          <InsertNamesModal
            engine={this.props.engine}
            authToken={this.props.authToken}
            entries={insertNameEntries}
            onOK={this.onInsertIntoNameTableOK}
            onCancel={this.onInsertIntoNameTableCancel}
          />
        )}
        {showPacketsDownloadModal && (
          <PacketsDownloadModal
            hasSelectedPackets={selectedPackets !== null && selectedPackets.packets.length !== 0}
            fileExt={downloadFileExt}
            fileName={downloadFileName}
            onOK={this.onPacketsDownloadOK}
            onCancel={this.onPacketsDownloadCancel}
          />
        )}
        {showForensicSearchModal && selectedPackets !== null && (
          <ForensicSearchModal
            onCancel={this.onOpenSelectedPacketsCancel}
            onOK={this.onOpenSelectedPacketsOK}
            startTime={
              selectedPackets.firstPacketDateTime
                ? selectedPackets.firstPacketDateTime
                : this.props.captureProperties?.startTime
            }
            endTime={
              selectedPackets.lastPacketDateTime
                ? selectedPackets.lastPacketDateTime
                : this.props.captureProperties?.stopTime
            }
            mediaType={mediaType}
            mediaSubType={mediaSubType}
            name={`${this.props.captureProperties?.name} - Selection`}
            file={this.state.remoteFilePath}
          />
        )}
        {toastMessage !== null ? (
          saveCaptureTask !== null ? (
            <ToastWrapper>
              <Toast>
                <ToastBody style={{ display: "flex", flexDirection: "column", minWidth: "226px" }}>
                  <div style={{ wordBreak: "break-all", marginBottom: "0.5rem" }}>
                    {toastMessage}
                  </div>
                  <BarGauge
                    aria-label="Progress"
                    value={saveCaptureTask.progress}
                    max={100}
                    title={`${formatInteger(saveCaptureTask.progress)}%`}
                  />
                  <LightButton style={{ marginTop: "1rem" }} onClick={this.onSaveCaptureTaskCancel}>
                    Cancel
                  </LightButton>
                </ToastBody>
              </Toast>
            </ToastWrapper>
          ) : (
            <ToastWrapper>
              <Toast>
                <ToastBody>{toastMessage}</ToastBody>
              </Toast>
            </ToastWrapper>
          )
        ) : null}
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  engineCapabilities: getCapabilities(state) || null,
  namesModTime: getNamesModificationTime(state),
  showAddressNames: getShowAddressNames(state),
  showPortNames: getShowPortNames(state),
  showLocalTime: getShowLocalTime(state),
  columns: getPacketsColumns(state) || defaultColumns,
  decodeColumns: getPacketsColumnsDecode(state) || [],
  selectedPackets: getPacketsSelection(state) || null,
  showDecodeView: getPacketsShowDecodeView(state),
  decodePacketNumber: getPacketsDecodePacketNumber(state),
  showFilterBar: getPacketsShowFilterBar(state),
  selectPacketsTask: getSelectPacketsTask(state),
  userId: getUserId(state),
})

const mapDisptachToProps = {
  updateStatus,
  setCurrentEngine,
  setPacketsColumns,
  setPacketsColumnsDecode,
  setPacketsSelection,
  setPacketsShowDecodeView,
  setPacketsDecodePacketNumber,
  setPacketsShowFilterBar,
  setSelectPacketsTask,
}

export default connect(mapStateToProps, mapDisptachToProps)(PacketsView)
