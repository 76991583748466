import { createSlice, PayloadAction, Dispatch } from "@reduxjs/toolkit"
import * as API from "../api/api"
import { RequestPostLogin, RequestPostLogout, ResponsePostLogin, ResponseError } from "../api/types"
import { compare as compareVersions } from "compare-versions"

interface AuthState {
  server: string
  serverAuthToken: string | null
  serverRefreshToken: string | null
  serverTempAuthToken: string | null
  serverVersion: string | null
  serverError: string | null
  serverLoggingIn: boolean
  serverTwoFactor: boolean
  engine: string
  authToken: string | null
  tempAuthToken: string | null
  engineVersion: string | null
  error: string | null
  loggingIn: boolean
  twoFactor: boolean
}

export const initialState: AuthState = {
  server: "",
  serverAuthToken: null,
  serverRefreshToken: null,
  serverTempAuthToken: null,
  serverVersion: null,
  serverError: null,
  serverLoggingIn: false,
  serverTwoFactor: false,
  engine: "",
  authToken: null,
  tempAuthToken: null,
  engineVersion: null,
  error: null,
  loggingIn: false,
  twoFactor: false,
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setServerAuthToken: (
      state,
      action: PayloadAction<{ authToken: string | null; refreshToken: string | null }>
    ) => {
      state.serverAuthToken = action.payload.authToken
      state.serverRefreshToken = action.payload.refreshToken
      state.serverTempAuthToken = null
      state.serverError = null
      state.serverLoggingIn = false
      state.serverTwoFactor = false
    },
    setServerLogIn: (state, action: PayloadAction<string>) => {
      state.server = action.payload
      state.serverError = null
      state.serverLoggingIn = true
    },
    setServerLogInSuccess: (state, action: PayloadAction<ResponsePostLogin>) => {
      if (action.payload.authAction === "configure") {
        state.serverAuthToken = null
        state.serverRefreshToken = null
        state.serverTempAuthToken = action.payload.authToken
      } else {
        state.serverAuthToken = action.payload.authToken
        state.serverRefreshToken = action.payload.refreshToken ?? null
        state.serverTempAuthToken = null
      }
      state.serverVersion = action.payload.engineVersion
      state.serverError = null
      state.serverLoggingIn = false
      state.serverTwoFactor = false
    },
    setServerLogInError: (
      state,
      action: PayloadAction<{ status: number; twoFactor: boolean; error?: string }>
    ) => {
      state.serverAuthToken = null
      state.serverRefreshToken = null
      state.serverTempAuthToken = null
      state.serverVersion = null
      state.serverLoggingIn = false
      switch (action.payload.status) {
        case 200:
          state.serverError = "Invalid authentication response"
          break
        case 401:
          if (state.serverTwoFactor) {
            state.serverError = "Invalid code or credentials"
          } else if (action.payload.error) {
            state.serverError = `Error: ${action.payload.error}`
          } else {
            if (action.payload.twoFactor) {
              state.serverError = null
              state.serverTwoFactor = true
            } else {
              state.serverError = "Username or password incorrect"
            }
          }
          break
        case 502:
          state.serverError = "Service not available"
          state.serverTwoFactor = false
          break
        default:
          state.serverError = "A network error occurred"
          state.serverTwoFactor = false
          break
      }
    },
    setServerLogOut: () => {},
    setServerLogOutSuccess: state => {
      state.serverAuthToken = null
      state.serverRefreshToken = null
      state.serverTempAuthToken = null
      state.serverVersion = null
      state.serverError = null
      state.serverLoggingIn = false
      state.serverTwoFactor = false
      state.authToken = null
      state.tempAuthToken = null
      state.engineVersion = null
      state.error = null
      state.loggingIn = false
      state.twoFactor = false
    },
    setServerLogOutError: (state, action: PayloadAction<string | null>) => {
      state.serverAuthToken = null
      state.serverRefreshToken = null
      state.serverTempAuthToken = null
      state.serverVersion = null
      state.serverError = action.payload
      state.serverLoggingIn = false
      state.serverTwoFactor = false
      state.authToken = null
      state.tempAuthToken = null
      state.engineVersion = null
      state.error = null
      state.loggingIn = false
      state.twoFactor = false
    },
    setAuthToken: (state, action: PayloadAction<string | null>) => {
      state.authToken = action.payload
      state.tempAuthToken = null
      state.error = null
      state.loggingIn = false
      state.twoFactor = false
    },
    setEngine: (
      state,
      action: PayloadAction<{
        engine: string
        authToken: string | null
      }>
    ) => {
      state.engine = action.payload.engine
      state.authToken = action.payload.authToken
      state.tempAuthToken = null
      state.error = null
      state.loggingIn = false
      state.twoFactor = false
    },
    setLogIn: (state, action: PayloadAction<string>) => {
      state.engine = action.payload
      state.error = null
      state.loggingIn = true
    },
    setLogInSuccess: (state, action: PayloadAction<ResponsePostLogin>) => {
      if (action.payload.authAction === "configure") {
        state.authToken = null
        state.tempAuthToken = action.payload.authToken
        state.engineVersion = action.payload.engineVersion
        state.error = null
        state.loggingIn = false
        state.twoFactor = false
      } else {
        state.authToken = action.payload.authToken
        state.tempAuthToken = null
        state.engineVersion = action.payload.engineVersion
        state.error = null
        state.loggingIn = false
        state.twoFactor = false
      }
    },
    setLogInError: (
      state,
      action: PayloadAction<{ status: number; twoFactor: boolean; error?: string }>
    ) => {
      state.authToken = null
      state.tempAuthToken = null
      state.engineVersion = null
      state.loggingIn = false
      switch (action.payload.status) {
        case 200:
          state.error = "Invalid authentication response"
          break
        case 401:
          if (state.twoFactor) {
            state.error = "Invalid code or credentials"
          } else if (action.payload.error) {
            state.error = `Error: ${action.payload.error}`
          } else {
            if (action.payload.twoFactor) {
              state.error = null
              state.twoFactor = true
            } else {
              state.error = "Username or password incorrect"
            }
          }
          break
        case 502:
          state.error = "Service not available"
          state.twoFactor = false
          break
        default:
          state.error = "Network error"
          state.twoFactor = false
          break
      }
    },
    setLogInVersionError: (state, action: PayloadAction<string>) => {
      state.authToken = null
      state.tempAuthToken = null
      state.engineVersion = null
      state.error = action.payload
      state.loggingIn = false
      state.twoFactor = false
    },
    setLogOut: () => {},
    setLogOutSuccess: state => {
      state.authToken = null
      state.tempAuthToken = null
      state.engineVersion = null
      state.error = null
      state.loggingIn = false
      state.twoFactor = false
    },
    setLogOutError: (state, action: PayloadAction<string | null>) => {
      state.authToken = null
      state.tempAuthToken = null
      state.engineVersion = null
      state.error = action.payload
      state.loggingIn = false
      state.twoFactor = false
    },
  },
})

export default authSlice.reducer

// Actions

export const { actions } = authSlice
export const { setServerAuthToken, setServerLogInError, setAuthToken, setEngine } = actions

export const handleServerLogIn = (server: string, logInFunc: () => Promise<Response>) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setServerLogIn(server))
    return logInFunc()
      .then(response => {
        const { status } = response
        if (response.ok) {
          response
            .json()
            .then((json: ResponsePostLogin) => {
              if (json && json.authToken && json.engineVersion) {
                dispatch(actions.setServerLogInSuccess(json))
              } else {
                dispatch(actions.setServerLogInError({ status, twoFactor: false }))
              }
            })
            .catch(() => {
              dispatch(actions.setServerLogInError({ status, twoFactor: false }))
            })
        } else {
          const twoFactor =
            status === 401 && !!response.headers.get("WWW-Authenticate")?.match(/Token-2FA/i)
          response
            .json()
            .then((json: ResponseError) => {
              if (json && json.message) {
                dispatch(
                  actions.setServerLogInError({ status, twoFactor: false, error: json.message })
                )
              } else {
                dispatch(actions.setServerLogInError({ status, twoFactor }))
              }
            })
            .catch(() => {
              dispatch(actions.setServerLogInError({ status, twoFactor }))
            })
        }
      })
      .catch(() => {
        dispatch(actions.setServerLogInError({ status: 500, twoFactor: false }))
      })
  }
}

export const serverLogIn = (server: string, request: RequestPostLogin, init?: RequestInit) => {
  return handleServerLogIn(server, API.logIn.bind(null, server, request, init))
}

export const serverLogInCertificate = (server: string) => {
  return handleServerLogIn(server, API.logInCertificate.bind(null, server))
}

export const serverLogOut = (server: string, request: RequestPostLogout, init?: RequestInit) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setServerLogOut())
    return API.logOut(server, request, init)
      .then(response => {
        if (response.ok) {
          dispatch(actions.setServerLogOutSuccess())
        } else {
          dispatch(actions.setServerLogOutError(response.statusText))
        }
      })
      .catch(() => {
        dispatch(actions.setServerLogOutError("Network error"))
      })
  }
}

export const serverLogOutSuccessAction = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setServerLogOut())
    dispatch(actions.setServerLogOutSuccess())
  }
}

export const serverLogOutErrorAction = (error: string | null) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setServerLogOut())
    dispatch(actions.setServerLogOutError(error))
  }
}

export const handleLogIn = (engine: string, logInFunc: () => Promise<Response>) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setLogIn(engine))
    return logInFunc()
      .then(response => {
        const { status } = response
        if (response.ok) {
          response
            .json()
            .then((json: ResponsePostLogin) => {
              if (json && json.authToken && json.engineVersion) {
                if (
                  compareVersions(
                    json.engineVersion,
                    String(import.meta.env.VITE_MINIMUM_API_VERSION),
                    ">="
                  )
                ) {
                  dispatch(actions.setLogInSuccess(json))
                } else {
                  // We don't actual know the engine type at this point
                  // so just fake it.
                  dispatch(
                    actions.setLogInVersionError(
                      JSON.stringify({
                        engineType: "LiveWire",
                        engineVersion: json.engineVersion,
                      })
                    )
                  )
                }
              } else {
                dispatch(actions.setLogInError({ status, twoFactor: false }))
              }
            })
            .catch(() => {
              dispatch(actions.setLogInError({ status, twoFactor: false }))
            })
        } else {
          const twoFactor =
            status === 401 && !!response.headers.get("WWW-Authenticate")?.match(/Token-2FA/i)
          response
            .json()
            .then((json: ResponseError) => {
              if (json && json.message) {
                dispatch(actions.setLogInError({ status, twoFactor: false, error: json.message }))
              } else {
                dispatch(actions.setLogInError({ status, twoFactor }))
              }
            })
            .catch(() => {
              dispatch(actions.setLogInError({ status, twoFactor }))
            })
        }
      })
      .catch(() => {
        dispatch(actions.setLogInError({ status: 500, twoFactor: false }))
      })
  }
}

export const logIn = (server: string, request: RequestPostLogin, init?: RequestInit) => {
  return handleLogIn(server, API.logIn.bind(null, server, request, init))
}

export const logInCertificate = (server: string, init?: RequestInit) => {
  return handleLogIn(server, API.logInCertificate.bind(null, server, init))
}

/*
export const logOut = (engine: string, request: RequestPostLogout, init?: RequestInit) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setLogOut())
    return API.logOut(engine, request, init)
      .then(response => {
        if (response.ok) {
          dispatch(actions.setLogOutSuccess())
        } else {
          dispatch(actions.setLogOutError(response.statusText))
        }
      })
      .catch(() => {
        dispatch(actions.setLogOutError("Network error"))
      })
  }
}
*/

/*
export const logOutSuccessAction = () => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setLogOut())
    dispatch(actions.setLogOutSuccess())
  }
}
*/

export const logOutErrorAction = (error: string | null) => {
  return (dispatch: Dispatch) => {
    dispatch(actions.setLogOut())
    dispatch(actions.setLogOutError(error))
  }
}

// Selectors

export const getServer = (state: AuthState) => state.server

export const getServerAuthToken = (state: AuthState) => state.serverAuthToken || ""

export const getServerRefreshToken = (state: AuthState) => state.serverRefreshToken || ""

export const getServerTempAuthToken = (state: AuthState) => state.serverTempAuthToken

export const getServerAuthError = (state: AuthState) => state.serverError

export const getServerAuthInProgress = (state: AuthState) => state.serverLoggingIn

export const getServerVersion = (state: AuthState) => state.serverVersion

export const getServerTwoFactorAuth = (state: AuthState) => state.serverTwoFactor

export const getEngine = (state: AuthState) => state.engine

export const getAuthToken = (state: AuthState) => state.authToken || ""

export const getTempAuthToken = (state: AuthState) => state.tempAuthToken || ""

export const getAuthError = (state: AuthState) => state.error

export const getAuthInProgress = (state: AuthState) => state.loggingIn

export const getTwoFactorAuth = (state: AuthState) => state.twoFactor
