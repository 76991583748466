import * as React from "react"
import styled from "styled-components"

export const Table = styled.table`
  & > tbody > tr > th {
    padding: 0 0.25rem 0 0 !important;
    border: none !important;
    vertical-align: top;
    white-space: nowrap;
    text-align: right;
    font-weight: bold;
    color: ${props => props.theme.propTableHeaderColor};
    font-size: ${props => props.theme.propTableHeaderFontSize};
    line-height: 1.5rem;
    text-transform: ${props => props.theme.propTableHeaderTextTransform};
  }

  & > tbody > tr > td {
    padding: 0 0 0 0.25rem !important;
    border: none !important;
    vertical-align: top;

    &:not(:last-child) {
      padding-right: 15px !important;
    }
  }
`

const Title = styled.h5`
  text-align: center;
`

export type LabelFormatter = (prop: string) => string
export type PropFormatter = (
  prop: string,
  data: any,
  options?: PropFormatterOptions
) => React.ReactNode
export type PropFormatterOptions = { showLocalTime: boolean }

export type PropTableProps = {
  data: any | null
  title?: string
  propList?: any[]
  propToLabel?: LabelFormatter | any
  formatProp?: PropFormatter
  skipEmptyRows?: boolean
  headerSuffix?: string
}

export default class PropTable extends React.Component<PropTableProps> {
  static defaultProps = {
    data: null,
    title: "",
    propList: [],
    propToLabel: (prop: string) => prop,
    formatProp: (prop: string, data: any) => data[prop],
    skipEmptyRows: false,
    headerSuffix: "",
  }

  formatHeaderFunc: LabelFormatter

  constructor(props: PropTableProps) {
    super(props)
    this.formatHeaderFunc =
      props.propToLabel instanceof Function ? props.propToLabel : this.formatHeaderObj
  }

  formatHeaderObj = (prop: string) => {
    return this.props.propToLabel[prop]
  }

  formatHeader = (prop: string) => {
    let h = this.formatHeaderFunc(prop)
    if (h) {
      const { headerSuffix = ":" } = this.props
      h += headerSuffix
    }
    return h
  }

  formatProp = (prop: string, data: any) => data[prop]

  render() {
    const { data, title, propList = [], formatProp = this.formatProp, skipEmptyRows } = this.props
    const rows = []
    if (title) {
      let colspan = 1
      if (propList.length > 0) {
        if (Array.isArray(propList[0])) {
          colspan = propList[0].length * 2
        } else {
          colspan = 2
        }
      }
      rows.push(
        <tr key="title">
          <th colSpan={colspan}>
            <Title>{title}</Title>
          </th>
        </tr>
      )
    }
    for (let i = 0; i < propList.length; i++) {
      const d = propList[i]
      if (Array.isArray(d)) {
        const cells = []
        for (let j = 0; j < d.length; j++) {
          const dd = d[j]
          cells.push(<th key={j}>{this.formatHeader(dd)}</th>)
          cells.push(<td key={j + 10000}>{formatProp(dd, data)}</td>)
        }
        rows.push(<tr key={i}>{cells}</tr>)
      } else {
        const dd = formatProp(d, data)
        if (skipEmptyRows && !dd) continue
        rows.push(
          <tr key={i}>
            <th>{this.formatHeader(d)}</th>
            <td>{dd}</td>
          </tr>
        )
      }
    }
    return (
      <Table>
        <tbody>{rows}</tbody>
      </Table>
    )
  }
}
