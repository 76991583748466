import * as React from "react"
import styled from "styled-components"
import bg from "../Login/login.png"

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: ${props => props.theme.textColor};
  background: url(${bg}) 50% 0;
  background-size: cover;
  background-color: #16161f;
`

const ErrorText = styled.h1`
  color: #fff;
`

type ErrorBoundaryProps = {}

type ErrorBoundaryState = {
  hasError: boolean
}

export class ErrorBoundary extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    hasError: false,
  }

  static getDerivedStateFromError(error: any) {
    console.error(error)
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorWrapper>
          <ErrorText>Something went wrong. Please refresh.</ErrorText>
        </ErrorWrapper>
      )
    }

    return this.props.children
  }
}
