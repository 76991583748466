import * as React from "react"
import { Col, FormGroup } from "reactstrap"
import FontAwesome from "react-fontawesome"
import { produce } from "immer"
import { cloneDeep, toNumber } from "lodash"
import styled from "styled-components"
import { LightButton, PrimaryButton, SecondaryButton } from "../common/Buttons"
import { CheckGroup, HorizontalFormGroup, Label } from "../common/Form"
import { Input } from "../common/Input"
import { Slider } from "../common/Slider"
import { ExpertDescription, ExpertProblemObject } from "../../api/types"
import { ExpertSensitivity, ExpertSettingGroupId } from "../../api/types/expertTypes"
import {
  generateExpertValue,
  generateExpertMinSamplePeriod,
  valueAssistToValue,
  valueToValueAssist,
} from "../../utils/expertUtils"
import { timeUnitToString, timeUnitToWPTime } from "../../utils/formatUtils"

const ProblemEditContent = styled.div`
  padding: 13px;

  & > * + * {
    margin-top: 1rem;
  }
`

export const ProblemEditFooter = styled.div`
  display: flex;

  & > * {
    margin: 0;
  }

  & > :not(:first-child) {
    margin-left: 0.25rem;
  }

  & > :not(:last-child) {
    margin-right: 0.25rem;
  }
`

const SliderInnerWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const SliderControlWrapper = styled.div`
  padding: 0 4px;
`

const SliderMinMax = styled.div`
  display: flex;
  font-size: 12px;

  & > :not(:first-child) {
    margin-left: auto;
  }
`

type ProblemEditSidebarProps = {
  expertDescription: ExpertDescription
  expertProblemCurrent: ExpertProblemObject
  expertProblemDefault: ExpertProblemObject
  readOnly?: boolean | undefined
  onClose: () => void
  onUpdateProblem: (
    updatedProblemCurrent: ExpertProblemObject,
    updatedProblemDefault: ExpertProblemObject
  ) => void
  onPostProblem: (updatedProblemDefault: ExpertProblemObject) => void
}

type ProblemEditSidebarState = {
  expertProblemCurrent: ExpertProblemObject
  expertProblemDefault: ExpertProblemObject
}

class ProblemEditSidebar extends React.Component<ProblemEditSidebarProps, ProblemEditSidebarState> {
  state: ProblemEditSidebarState = {
    expertProblemCurrent: cloneDeep(this.props.expertProblemCurrent),
    expertProblemDefault: cloneDeep(this.props.expertProblemDefault),
  }

  onCancel() {
    this.props.onClose()
  }

  onChangeValue(event: React.ChangeEvent<HTMLInputElement>) {
    const { expertDescription } = this.props
    if (
      expertDescription.hasValue &&
      expertDescription.valueMin !== undefined &&
      expertDescription.valueMax !== undefined
    ) {
      let value = toNumber(event.target.value)
      if (value >= expertDescription.valueMin && value <= expertDescription.valueMax) {
        value /=
          expertDescription.valueDisplayMultiplier > 0
            ? expertDescription.valueDisplayMultiplier
            : 1
        this.setState(
          produce(draft => {
            draft.expertProblemCurrent.value = Math.round(value)
          })
        )
      }
    }
  }

  onChangeMinimumSample(event: React.ChangeEvent<HTMLInputElement>) {
    const { expertDescription } = this.props
    if (
      expertDescription.hasMinSamplePeriod &&
      expertDescription.minSamplePeriodMin !== undefined &&
      expertDescription.minSamplePeriodMax !== undefined
    ) {
      const value = toNumber(event.target.value)
      if (
        value >= expertDescription.minSamplePeriodMin &&
        value <= expertDescription.minSamplePeriodMax
      ) {
        if (expertDescription.minSamplePeriodUnits !== undefined) {
          const unitTime = timeUnitToWPTime(expertDescription.minSamplePeriodUnits)
          this.setState(
            produce(draft => {
              draft.expertProblemCurrent.minimumSample = unitTime !== 0 ? value * unitTime : value
            })
          )
        } else {
          this.setState(
            produce(draft => {
              draft.expertProblemCurrent.minimumSample = value
            })
          )
        }
      }
    }
  }

  onChangeSensitivity(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
    this.setState(
      produce(draft => {
        draft.expertProblemCurrent.sensitivity = toNumber(value) as ExpertSensitivity
      })
    )
  }

  onChangeValueAssist(value: number) {
    const { expertDescription } = this.props
    if (
      expertDescription.hasValueAssist &&
      expertDescription.valueAssistLeft !== undefined &&
      expertDescription.valueAssistRight !== undefined &&
      expertDescription.valueAssistLogScale !== undefined
    ) {
      const valueAssist = valueToValueAssist(
        value,
        expertDescription.valueAssistLeft,
        expertDescription.valueAssistRight,
        expertDescription.valueAssistLogScale
      )
      this.setState(
        produce(draft => {
          draft.expertProblemCurrent.value = valueAssist
        })
      )
    }
  }

  onConfigure() {
    //console.log("configure")
  }

  onRevertToDefault() {
    this.setState(
      produce(draft => {
        draft.expertProblemCurrent = {
          ...draft.expertProblemDefault,
          settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_CURRENT,
        }
      })
    )
  }

  onSave() {
    this.props.onUpdateProblem(this.state.expertProblemCurrent, this.state.expertProblemDefault)
  }

  onSetAsDefault() {
    this.setState(
      produce(draft => {
        draft.expertProblemDefault = {
          ...draft.expertProblemCurrent,
          settingGroupId: ExpertSettingGroupId.EXPERT_SETTING_GROUP_ID_DEFAULT,
        }
        this.props.onPostProblem(draft.expertProblemDefault)
      })
    )
  }

  render() {
    const { expertDescription, readOnly } = this.props
    const { expertProblemCurrent } = this.state

    const valueObj =
      expertProblemCurrent.value !== null
        ? generateExpertValue(expertProblemCurrent.value, expertDescription)
        : null

    const minSamplePeriod =
      expertProblemCurrent.minimumSample !== null
        ? generateExpertMinSamplePeriod(expertProblemCurrent.minimumSample, expertDescription)
        : null

    const renderSensitivityRadios = (sensitivity: ExpertSensitivity) => {
      const labels = [
        { id: ExpertSensitivity.EXPERT_SENSITIVITY_LOW, text: "Low" },
        { id: ExpertSensitivity.EXPERT_SENSITIVITY_MEDIUM, text: "Medium" },
        { id: ExpertSensitivity.EXPERT_SENSITIVITY_HIGH, text: "High" },
      ]
      const radioLabels = labels.map(label => (
        <CheckGroup
          type={"radio"}
          key={`sensitivity-${label.id}`}
          id={`sensitivity-${label.id}`}
          name={`sensitivity-${label.id}`}
          onChange={this.onChangeSensitivity.bind(this)}
          value={label.id}
          checked={label.id === sensitivity}
          disabled={!!readOnly}
        >
          {label.text}
        </CheckGroup>
      ))
      return <> {radioLabels} </>
    }

    return (
      <ProblemEditContent>
        {expertDescription.hasValue &&
          expertDescription.valueMin !== undefined &&
          expertDescription.valueMax !== undefined &&
          valueObj && (
            <FormGroup row>
              <Label md="4" for="value">
                Value
              </Label>
              <Col md="8">
                <HorizontalFormGroup noMargin>
                  <Input
                    style={{ width: "8rem" }}
                    type="number"
                    name="value"
                    id="value"
                    onChange={this.onChangeValue.bind(this)}
                    value={valueObj.value}
                    min={expertDescription.valueMin}
                    max={expertDescription.valueMax}
                    step={valueObj.step}
                    readOnly={!!readOnly}
                  />
                  {expertDescription.valueUnits !== undefined && (
                    <span>{expertDescription.valueUnits}</span>
                  )}
                </HorizontalFormGroup>
              </Col>
            </FormGroup>
          )}
        {expertDescription.hasMinSamplePeriod &&
          expertDescription.minSamplePeriodMax !== undefined &&
          expertDescription.minSamplePeriodMin !== undefined &&
          minSamplePeriod && (
            <FormGroup row>
              <Label md="4" for="minimumSample">
                Minimum Sample
              </Label>
              <Col md="8">
                <HorizontalFormGroup noMargin>
                  <Input
                    style={{ width: "8rem" }}
                    type="number"
                    name="minimumSample"
                    id="minimumSample"
                    onChange={this.onChangeMinimumSample.bind(this)}
                    value={minSamplePeriod}
                    min={expertDescription.minSamplePeriodMin}
                    max={expertDescription.minSamplePeriodMax}
                    step={1}
                    readOnly={!!readOnly}
                  />
                  {expertDescription.minSamplePeriodUnits !== undefined && (
                    <span>{timeUnitToString(expertDescription.minSamplePeriodUnits)}</span>
                  )}
                </HorizontalFormGroup>
              </Col>
            </FormGroup>
          )}
        {expertDescription.hasValueAssist &&
          expertDescription.valueAssistLeft !== undefined &&
          expertDescription.valueAssistRight !== undefined &&
          expertDescription.valueAssistLogScale !== undefined &&
          expertProblemCurrent.value !== null && (
            <FormGroup row>
              <Col md={{ size: 8, offset: 4 }}>
                <SliderInnerWrapper>
                  <SliderControlWrapper>
                    <Slider
                      onChange={this.onChangeValueAssist.bind(this)}
                      value={valueAssistToValue(
                        expertProblemCurrent.value,
                        expertDescription.valueAssistLeft,
                        expertDescription.valueAssistRight,
                        expertDescription.valueAssistLogScale
                      )}
                      min={0}
                      max={1000}
                      step={1}
                      disabled={!!readOnly}
                    />
                  </SliderControlWrapper>
                  <SliderMinMax>
                    <div>Dial-up</div>
                    <div>Internet</div>
                    <div>LAN</div>
                    <div>Fast LAN</div>
                  </SliderMinMax>
                </SliderInnerWrapper>
              </Col>
            </FormGroup>
          )}
        {expertDescription.hasSensitivity && expertProblemCurrent.sensitivity !== null && (
          <FormGroup row>
            <Label md="4" for="sensitivity" style={{ paddingTop: "0px" }}>
              Sensitivity
            </Label>
            <Col md="8">{renderSensitivityRadios(expertProblemCurrent.sensitivity)}</Col>
          </FormGroup>
        )}
        {expertDescription.hasConfigure && (
          <FormGroup row>
            <Label md="4" for="configure">
              Configure
            </Label>
            <Col md="8">
              <SecondaryButton disabled={true} onClick={this.onConfigure.bind(this)}>
                Configure
              </SecondaryButton>
            </Col>
          </FormGroup>
        )}
        <ProblemEditFooter>
          {!readOnly &&
            (expertDescription.hasValue ||
              expertDescription.hasMinSamplePeriod ||
              expertDescription.hasValueAssist ||
              expertDescription.hasSensitivity ||
              expertDescription.hasConfigure) && (
              <>
                <LightButton id="revertDefault" onClick={this.onRevertToDefault.bind(this)}>
                  <FontAwesome name="history" /> Revert To Default
                </LightButton>
                <LightButton id="setDefault" onClick={this.onSetAsDefault.bind(this)}>
                  <FontAwesome name="history" flip="horizontal" /> Set As Default
                </LightButton>
              </>
            )}
          {!readOnly && (
            <>
              <SecondaryButton style={{ marginLeft: "auto" }} onClick={this.onCancel.bind(this)}>
                Cancel
              </SecondaryButton>
              <PrimaryButton onClick={this.onSave.bind(this)}>Save</PrimaryButton>
            </>
          )}
          {!!readOnly && (
            <PrimaryButton style={{ marginLeft: "auto" }} onClick={this.onCancel.bind(this)}>
              Close
            </PrimaryButton>
          )}
        </ProblemEditFooter>
      </ProblemEditContent>
    )
  }
}

export default ProblemEditSidebar
