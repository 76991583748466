import * as React from "react"
import { Form } from "reactstrap"
import { TableCellProps, TableRowProps } from "react-virtualized"
import { AnalysisOptionsBorderlessButton, PrimaryButton } from "../common/Buttons"
import { HorizontalFormGroup } from "../common/Form"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../common/Modal"
import { OmniTable } from "../common/OmniTable"
import PluginAdapterModal from "../PluginAdapterModal"
import { EngineCapabilitiesPluginInfo } from "../../api/types"

type AdapterParams = {
  id: string
  pluginInfo: EngineCapabilitiesPluginInfo
  title: string
}

type ConfigureAdaptersModalProps = {
  adapterPlugins: EngineCapabilitiesPluginInfo[]
  isOpen?: boolean
  onClose: () => void
}

type ConfigureAdaptersModalState = {
  adapterParams: AdapterParams | null
  showPluginAdapterModal: boolean
}

class ConfigureAdaptersModal extends React.Component<
  ConfigureAdaptersModalProps,
  ConfigureAdaptersModalState
> {
  state: ConfigureAdaptersModalState = {
    adapterParams: null,
    showPluginAdapterModal: false,
  }

  cellRenderer = ({ cellData, rowData }: TableCellProps) => {
    return (
      <HorizontalFormGroup noMargin>
        {cellData}
        &nbsp;&nbsp;
        <AnalysisOptionsBorderlessButton onClick={this.onConfigure.bind(this, rowData)} />
      </HorizontalFormGroup>
    )
  }

  onConfigure = (adapterPlugin: EngineCapabilitiesPluginInfo) => {
    this.setState({
      adapterParams: {
        id: "{00000000-0000-0000-0000-000000000000}",
        pluginInfo: adapterPlugin,
        title: "",
      },
      showPluginAdapterModal: true,
    })
  }

  onPluginAdapterClose = () => {
    this.setState({
      adapterParams: null,
      showPluginAdapterModal: false,
    })
  }

  rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    this.props.onClose()
  }

  render() {
    const { adapterPlugins, isOpen = true, onClose } = this.props
    const { adapterParams, showPluginAdapterModal } = this.state
    if (!isOpen) return null

    return (
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>Configure Adapters</ModalHeader>
        <ModalBody>
          <Form id="configure-adapters-form" onSubmit={this.onSubmit}>
            <div style={{ position: "relative", height: "150px" }}>
              <OmniTable
                data={adapterPlugins}
                rowCount={adapterPlugins.length}
                rowHeight={25}
                disableHeader
                cellRenderer={this.cellRenderer}
                columnDesc={[
                  {
                    dataKey: "name",
                    width: 200,
                    flexGrow: 1,
                  },
                ]}
                rowRenderer={this.rowRenderer}
              />
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <PrimaryButton type="submit" form="configure-adapters-form">
            Close
          </PrimaryButton>
        </ModalFooter>
        {showPluginAdapterModal && adapterParams && (
          <PluginAdapterModal
            adapterId={adapterParams.id}
            adapterTitle={adapterParams.title}
            pluginId={adapterParams.pluginInfo.clsid}
            pluginName={adapterParams.pluginInfo.name}
            onClose={this.onPluginAdapterClose}
          />
        )}
      </Modal>
    )
  }
}

export default ConfigureAdaptersModal
