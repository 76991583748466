export enum PROTOCOL_TYPES {
  ALL = -1,
  HTTP = 0,
  SMTP = 1,
  POP = 2,
  SMB = 3,
  TLS_CERT = 4,
  FTP = 5,
  TFTP = 6,
}

export type ProtocolOption = {
  protocol: PROTOCOL_TYPES
  label: string
}

export const protocolOptions: ProtocolOption[] = [
  {
    protocol: PROTOCOL_TYPES.HTTP,
    label: "HTTP",
  },
  {
    protocol: PROTOCOL_TYPES.SMTP,
    label: "SMTP",
  },
  {
    protocol: PROTOCOL_TYPES.POP,
    label: "POP",
  },
  {
    protocol: PROTOCOL_TYPES.SMB,
    label: "SMB",
  },
  {
    protocol: PROTOCOL_TYPES.TLS_CERT,
    label: "TLS_CERT",
  },
  {
    protocol: PROTOCOL_TYPES.FTP,
    label: "FTP",
  },
  {
    protocol: PROTOCOL_TYPES.TFTP,
    label: "TFTP",
  },
  {
    protocol: PROTOCOL_TYPES.ALL,
    label: "All Protocols",
  },
]
