import * as React from "react"
import { useHistory } from "react-router-dom"
import copy from "copy-to-clipboard"
import SplitterLayout from "react-splitter-layout"
import { InsertNamesModal, InsertNameEntry } from "../InsertNamesModal"
import DecodePane from "./DecodePane"
import DecodePaneText from "./DecodePaneText"
import HexPane from "./HexPane"
import EncodingModal from "./EncodingModal"
import { getEngineNewFilterUrl } from "../../routes"
import {
  fetchCFSPacketData,
  fetchCFSPacketDecode,
  fetchCFSPacketDecodeText,
  PacketDecodeTextFormat,
  resolveAddresses,
} from "../../api/api"
import { Filter, NameResolverRequestEntry } from "../../api/types"
import { PacketDecode, DecodeSnippetItem } from "./types"

function updateParentSizes(line: DecodeSnippetItem, parents: DecodeSnippetItem[]) {
  // Postorder traverse children
  if (line.children) {
    parents.push(line)
    for (const child of line.children) {
      updateParentSizes(child, parents)
    }
    parents.pop()
  }

  if (line.snippets.length > 0) {
    // Calculate line offset/size
    let startOffset = 0
    let endOffset = 0
    for (const item of line.snippets) {
      if (item.offset != null && item.size != null) {
        if (startOffset === 0) {
          startOffset = item.offset
        } else {
          startOffset = Math.min(startOffset, item.offset)
        }
        if (endOffset === 0) {
          endOffset = item.offset + item.size
        } else {
          endOffset = Math.max(endOffset, item.offset + item.size)
        }
      }
    }
    const lineOffset = startOffset
    const lineSize = endOffset - startOffset

    if (lineSize !== 0) {
      const first = line.snippets[0]
      if (first.offset == null) {
        first.offset = lineOffset
      }
      if (first.size == null) {
        first.size = lineSize
      }
    }

    if (lineSize !== 0) {
      // Add the line offset/size to the first
      // snippet of each parent
      for (const parent of parents) {
        if (parent.snippets.length > 0) {
          const first = parent.snippets[0]
          if (first.offset == null) {
            first.offset = lineOffset
          }
          if (first.size == null) {
            first.size = lineSize
          }
          const min = Math.min(first.offset, lineOffset)
          const max = Math.max(first.offset + first.size, lineOffset + lineSize)
          first.offset = min
          first.size = max - min
        }
      }
    }
  }
}

function processDecode(packetDecode: PacketDecode) {
  const parents: DecodeSnippetItem[] = []
  for (const line of packetDecode.decode.lines) {
    updateParentSizes(line, parents)
  }
}

export type DecodeViewProps = {
  engine: string
  authToken: string
  type: string
  capId: string
  viewType: "standard" | "html"
  decodePacketNumber: number
  namesModTime?: string
  onAddDecodeColumn: null | ((fullLabel: string, shortLabel: string, text: string) => void)
}

const DecodeView = ({
  engine,
  authToken,
  type,
  capId,
  viewType,
  decodePacketNumber,
  namesModTime,
  onAddDecodeColumn,
}: DecodeViewProps) => {
  const history = useHistory()
  const [packetDecode, setPacketDecode] = React.useState<PacketDecode | null>(null)
  const [packetData, setPacketData] = React.useState<number[] | null>(null)
  const [packetDecodeText, setPacketDecodeText] = React.useState<string | null>(null)
  const [encoding, setEncoding] = React.useState(0)
  const [showOffsets, setShowOffsets] = React.useState(true)
  const [showColors, setShowColors] = React.useState(true)
  const [offsets, setOffsets] = React.useState<"decimal" | "hex">("decimal")
  const [selectedOffset, setSelectedOffset] = React.useState<number | undefined>()
  const [hexSelection, setHexSelection] = React.useState<number[]>([])
  const [showEncodingModal, setShowEncodingModal] = React.useState(false)
  const [insertNameEntry, setInsertNameEntry] = React.useState<InsertNameEntry | null>(null)

  const onRefresh = React.useCallback(() => {
    if (decodePacketNumber !== 0) {
      if (viewType === "standard") {
        fetchCFSPacketDecode(
          engine,
          authToken,
          type,
          capId,
          decodePacketNumber,
          showOffsets,
          encoding
        )
          .then((packetDecode: PacketDecode) => {
            processDecode(packetDecode)
            setPacketDecode(packetDecode)
          })
          .catch(error => {
            console.error(error)
          })

        // Get decode tags for debugging
        /*
        fetchCFSPacketDecodeText(
          engine,
          authToken,
          type,
          capId,
          decodePacketNumber,
          showOffsets,
          encoding,
          "application/vnd+omni.decode+tags"
        )
          .then(() => {})
          .catch(error => {
            console.error(error)
          })
        */
      } else if (viewType === "html") {
        fetchCFSPacketData(engine, authToken, type, capId, decodePacketNumber)
          .then(packetData => {
            if (packetData.data) {
              setPacketData(packetData.data)
            }
          })
          .catch(() => {
            setPacketData(null)
          })

        fetchCFSPacketDecodeText(
          engine,
          authToken,
          type,
          capId,
          decodePacketNumber,
          showOffsets,
          encoding,
          "text/html"
        )
          .then(packetDecode => {
            setPacketDecodeText(packetDecode)
          })
          .catch(() => {
            setPacketDecodeText(null)
          })
      }
    }
  }, [engine, authToken, type, capId, viewType, decodePacketNumber, encoding, showOffsets])

  const onClickDecode = React.useCallback(
    (line: number, offset: number, size: number, mask: string) => {
      setHexSelection([offset, offset + size])
    },
    [setHexSelection]
  )

  React.useEffect(() => {
    onRefresh()
  }, [onRefresh, namesModTime])

  const onSetEncoding = (newEncoding: number) => {
    if (newEncoding < 0) {
      setShowEncodingModal(true)
    } else {
      setEncoding(newEncoding)
    }
  }

  const onMakeFilter = (filter: Filter) => {
    history.push({
      pathname: getEngineNewFilterUrl(),
      state: { filter },
    })
  }

  const onInsertIntoNameTable = (insertNameEntry: InsertNameEntry) => {
    setInsertNameEntry(insertNameEntry)
  }

  const onInsertIntoNameTableOK = () => {
    setInsertNameEntry(null)
  }

  const onInsertIntoNameTableCancel = () => {
    setInsertNameEntry(null)
  }

  const onResolveNames = (entries: NameResolverRequestEntry[]) => {
    resolveAddresses(engine, authToken, entries).catch(error => {
      console.error(error)
    })
  }

  const onCopyAs = (format: PacketDecodeTextFormat) => {
    if (decodePacketNumber !== 0) {
      fetchCFSPacketDecodeText(
        engine,
        authToken,
        type,
        capId,
        decodePacketNumber,
        showOffsets,
        encoding,
        format
      )
        .then(text => {
          copy(text, {
            format: format === "text/html" ? format : "text/plain",
          })
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  return (
    <div>
      {viewType === "standard" ? (
        <SplitterLayout>
          <DecodePane
            decode={packetDecode}
            encoding={encoding}
            setEncoding={onSetEncoding}
            showOffsets={showOffsets}
            setShowOffsets={setShowOffsets}
            selectedOffset={selectedOffset}
            onClickLine={onClickDecode}
            onMakeFilter={onMakeFilter}
            onInsertIntoNameTable={onInsertIntoNameTable}
            onResolveNames={onResolveNames}
            onCopyAs={onCopyAs}
            onAddDecodeColumn={onAddDecodeColumn}
          />
          <HexPane
            packetData={packetDecode !== null ? packetDecode.data : null}
            bytesPerRow={16}
            offsets={offsets}
            setOffsets={setOffsets}
            showColors={showColors}
            setShowColors={setShowColors}
            colorBlocks={packetDecode?.layers}
            selection={hexSelection}
            onClickOffset={setSelectedOffset}
          />
        </SplitterLayout>
      ) : (
        <SplitterLayout>
          <DecodePaneText
            packetDecode={packetDecodeText}
            decodePacketNumber={decodePacketNumber}
            showOffsets={showOffsets}
            setShowOffsets={setShowOffsets}
            onCopyAs={onCopyAs}
          />
          <HexPane
            packetData={packetData}
            bytesPerRow={16}
            offsets={offsets}
            setOffsets={setOffsets}
          />
        </SplitterLayout>
      )}
      {showEncodingModal ? (
        <EncodingModal
          encoding={encoding}
          onOK={(encoding: number) => {
            setEncoding(encoding)
            setShowEncodingModal(false)
          }}
          onCancel={() => {
            setShowEncodingModal(false)
          }}
        />
      ) : null}
      {insertNameEntry != null ? (
        <InsertNamesModal
          engine={engine}
          authToken={authToken}
          entries={[insertNameEntry]}
          onOK={onInsertIntoNameTableOK}
          onCancel={onInsertIntoNameTableCancel}
        />
      ) : null}
    </div>
  )
}

export default DecodeView
