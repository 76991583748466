import * as React from "react"
import { cloneDeep, toNumber } from "lodash"
import qs from "qs"
import { connect } from "react-redux"
import { Prompt, Redirect, RouteComponentProps } from "react-router-dom"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { v4 as uuid } from "uuid"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { View, ViewContent, ViewHeader, ViewHeaderTitle, ViewHeaderButtons } from "../common/View"
import { LightButton, LightDropdownToggle, IconDropdownToggle } from "../common/Buttons"
import { Alert } from "../common/Alert"
import { BarGauge } from "../common/BarGauge"
import { DangerText } from "../common/DangerText"
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledDropdownWithPortal,
} from "../common/Dropdown"
import Interval from "../common/Interval"
import { Panel } from "../common/Panel"
import PropTable from "../common/PropTable"
import { Toast, ToastBody } from "../common/Toast"
import { ChartType } from "../../api/types/chartTypes"
import ConfirmationModal from "../common/ConfirmationModal"
import DownloadPacketsModal from "../DownloadPacketsModal"
import ForensicSearchModal from "../ForensicSearchModal"
import { SuccessTextWithBackground } from "../common/SuccessText"
import { TopStatsViewType, ForensicsTopStats, ForensicsTopStatsWidget } from "./TopStats"
import { ForensicsGraphWidget, ForensicsGraphSelection } from "./TimelineStats"
import CaptureSessionsTable from "./CaptureSessionsTable"
import { SparklineDataCache } from "./sparklineDataCache"
import { chartTypeSupportsSmoothing, getChartColor } from "../../utils/chartUtils"
import {
  peekToDate,
  peekFromDate,
  formatInteger,
  formatDuration,
  formatISODateTime,
  formatLinkSpeed,
} from "../../utils/formatUtils"
import { getMediaString } from "../../utils/mediaUtils"
import { MediaSubType, MediaType } from "../../api/types/mediaTypes"
import {
  getEngineForensicsUrl,
  getEngineForensicSearchUrl,
  getEngineForensicsCaptureSessionUrl,
} from "../../routes"
import {
  getEngine,
  getAuthToken,
  getNamesModificationTime,
  getShowAddressNames,
  getShowPortNames,
  getShowLocalTime,
  getForensicsViewRefreshInterval,
  getForensicsViewType,
  getForensicsSampleInterval,
  getForensicsTopTalkersViewType,
  getForensicsTopProtocolsViewType,
  getForensicsTopStatsChartType,
  getForensicsTopStatsChartScale,
  getForensicsTimelineChartType,
  getForensicsTimelineChartScale,
  getForensicsTimelineChartInterpolation,
  getForensicsTimelineSelection,
  getForensicsTimelineSelectionStart,
  getForensicsTimelineSelectionEnd,
  getCapabilities,
  getUserId,
} from "../../store"
import {
  setForensicsViewRefreshInterval,
  setForensicsViewType,
  setForensicsSampleInterval,
  setForensicsTopTalkersViewType,
  setForensicsTopProtocolsViewType,
  setForensicsTopStatsChartType,
  setForensicsTopStatsChartScale,
  setForensicsTimelineChartType,
  setForensicsTimelineChartScale,
  setForensicsTimelineChartInterpolation,
  setForensicsTimelineSelection,
} from "../../store/ui"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import {
  CaptureFlags,
  CaptureState,
  PeekFileViewStatus,
  PeekFilterMode,
  TimelineDataViewType,
} from "../../api/types/peekTypes"
import {
  CaptureProperties,
  DatabaseRowGetCaptureSessions,
  ForensicSearchProperties,
  RequestCreateForensicSearch,
  ResponseCreateForensicSearch,
  ResponseGetCaptureSessions,
  ResponseGetEngineCapabilities,
  ResponseGetSaveCaptureTask,
  ResponseGetTimelineData,
  ResponseGetTimelineStatistics,
  ResponsePostSaveCapture,
  ResponsePostSaveCaptureTask,
  TimelineDataType,
  TimelineStatisticsEntry,
  TimelineStatisticsType,
} from "../../api/types"
import {
  createForensicSearch,
  deleteCaptureSession,
  deleteAllCaptureSessions,
  deleteFiles,
  deleteForensicSearch,
  deleteSaveCaptureTask,
  fetchCaptureSessions,
  fetchCFSProperties,
  fetchSaveCaptureTask,
  fetchTimelineData,
  fetchTimelineStatistics,
  getFileDownloadURL,
  postSaveCaptureTask,
  saveCFSPackets,
} from "../../api/api"
import { download } from "../../utils/downloadUtils"

const propList = [
  ["Data Start Time", "Media", "Adapter"],
  ["Data End Time", "Link Speed", "Owner"],
  ["Data Duration", "Packets", "Sample Interval"],
  ["Status", "Packets Dropped", null],
]

const DataURL: any = {
  0: "utilization-mbps",
  1: "packets",
  2: "multicast",
  3: "packet-sizes",
  4: "vlan-mpls",
  5: "protocols-mbps",
  6: "protocols-pps",
  7: "call-quality",
  8: "call-utilization",
  9: "wireless-packets",
  10: "wireless-retries",
  11: "applications-mbps",
  12: "applications-pps",
}

const TopStatsURL = {
  [TopStatsViewType.PHYSICAL]: "top-physical-nodes",
  [TopStatsViewType.IP]: "top-ipv4-nodes",
  [TopStatsViewType.IPV6]: "top-ipv6-nodes",
  [TopStatsViewType.PROTOCOLS]: "top-protocols",
  [TopStatsViewType.APPLICATIONS]: "top-applications",
}

const GearIcon = styled(FontAwesome).attrs({ name: "gear" })`
  color: ${props => props.theme.textMutedColor};
`

const OptionsButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`

type TopStatsOptionsButtonProps = {
  viewTypeLeft: string
  viewTypeRight: string
  chartType: ChartType
  chartScale: string
  onViewTypeLeft: (event: React.MouseEvent<any>) => void
  onViewTypeRight: (event: React.MouseEvent<any>) => void
  onChartType: (event: React.MouseEvent<any>) => void
  onChartScale: (event: React.MouseEvent<any>) => void
}

const TopStatsOptionsButton: React.FC<TopStatsOptionsButtonProps> = ({
  viewTypeLeft,
  viewTypeRight,
  chartType,
  chartScale,
  onViewTypeLeft,
  onViewTypeRight,
  onChartType,
  onChartScale,
}) => (
  <OptionsButton>
    <UncontrolledDropdownWithPortal
      dropdownToggle={
        <IconDropdownToggle aria-label="Settings">
          <GearIcon />
        </IconDropdownToggle>
      }
    >
      <DropdownMenu end>
        <DropdownItem
          name={TopStatsViewType.IP}
          active={viewTypeLeft === TopStatsViewType.IP}
          onClick={onViewTypeLeft}
        >
          Top Talkers by IP Address
        </DropdownItem>
        <DropdownItem
          name={TopStatsViewType.IPV6}
          active={viewTypeLeft === TopStatsViewType.IPV6}
          onClick={onViewTypeLeft}
        >
          Top Talkers by IPv6 Address
        </DropdownItem>
        <DropdownItem
          name={TopStatsViewType.PHYSICAL}
          active={viewTypeLeft === TopStatsViewType.PHYSICAL}
          onClick={onViewTypeLeft}
        >
          Top Talkers by Physical Address
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          name={TopStatsViewType.PROTOCOLS}
          active={viewTypeRight === TopStatsViewType.PROTOCOLS}
          onClick={onViewTypeRight}
        >
          Top Protocols by Bytes
        </DropdownItem>
        <DropdownItem
          name={TopStatsViewType.APPLICATIONS}
          active={viewTypeRight === TopStatsViewType.APPLICATIONS}
          onClick={onViewTypeRight}
        >
          Top Applications by Bytes
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          name={ChartType.HORIZONTAL_BAR}
          active={chartType === ChartType.HORIZONTAL_BAR}
          onClick={onChartType}
        >
          Bar
        </DropdownItem>
        <DropdownItem
          name={ChartType.COLUMN}
          active={chartType === ChartType.COLUMN}
          onClick={onChartType}
        >
          Column
        </DropdownItem>
        <DropdownItem
          name={ChartType.PIE}
          active={chartType === ChartType.PIE}
          onClick={onChartType}
        >
          Pie
        </DropdownItem>
        <DropdownItem
          name={ChartType.DONUT}
          active={chartType === ChartType.DONUT}
          onClick={onChartType}
        >
          Donut
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem name="auto" active={chartScale === "auto"} onClick={onChartScale}>
          Auto Scale
        </DropdownItem>
        <DropdownItem name="fixed" active={chartScale === "fixed"} onClick={onChartScale}>
          Fixed Scale
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdownWithPortal>
  </OptionsButton>
)

type TimelineStatsOptionsButtonProps = {
  disabled: boolean
  chartType: ChartType
  chartScale: string
  chartInterpolation: string
  onChartType: (event: React.MouseEvent<any>) => void
  onChartScale: (event: React.MouseEvent<any>) => void
  onChartSmoothing: (event: React.MouseEvent<any>) => void
}

const TimelineStatsOptionsButton: React.FC<
  React.PropsWithChildren<TimelineStatsOptionsButtonProps>
> = ({ disabled, chartType, chartInterpolation, onChartType, onChartSmoothing }) => (
  <OptionsButton>
    <UncontrolledDropdownWithPortal
      dropdownToggle={
        <IconDropdownToggle aria-label="Settings" disabled={disabled}>
          <GearIcon />
        </IconDropdownToggle>
      }
    >
      <DropdownMenu end>
        <DropdownItem
          name={ChartType.COLUMN}
          active={chartType === ChartType.COLUMN}
          onClick={onChartType}
        >
          Column
        </DropdownItem>
        <DropdownItem
          name={ChartType.STACKED_COLUMN}
          active={chartType === ChartType.STACKED_COLUMN}
          onClick={onChartType}
        >
          Stacked Column
        </DropdownItem>
        <DropdownItem
          name={ChartType.OVERLAID_SKYLINE}
          active={chartType === ChartType.OVERLAID_SKYLINE}
          onClick={onChartType}
        >
          Skyline
        </DropdownItem>
        <DropdownItem
          name={ChartType.STACKED_SKYLINE}
          active={chartType === ChartType.STACKED_SKYLINE}
          onClick={onChartType}
        >
          Stacked Skyline
        </DropdownItem>
        <DropdownItem
          name={ChartType.AREA}
          active={chartType === ChartType.AREA}
          onClick={onChartType}
        >
          Area
        </DropdownItem>
        <DropdownItem
          name={ChartType.STACKED_AREA}
          active={chartType === ChartType.STACKED_AREA}
          onClick={onChartType}
        >
          Stacked Area
        </DropdownItem>
        <DropdownItem
          name={ChartType.LINE}
          active={chartType === ChartType.LINE}
          onClick={onChartType}
        >
          Line
        </DropdownItem>
        <DropdownItem
          name={ChartType.LINE_POINTS}
          active={chartType === ChartType.LINE_POINTS}
          onClick={onChartType}
        >
          Line/Points
        </DropdownItem>
        {/*
        <DropdownItem divider />
        <DropdownItem name="linear" active={chartScale === "linear"} onClick={onChartScale}>
          Linear
        </DropdownItem>
        <DropdownItem disabled name="log" active={chartScale === "log"} onClick={onChartScale}>
          Logarithmic
        </DropdownItem>
        */}
        <DropdownItem divider />
        <DropdownItem
          name="smoothing"
          active={chartInterpolation !== "linear"}
          onClick={onChartSmoothing}
          disabled={!chartTypeSupportsSmoothing(chartType)}
        >
          Smoothing
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdownWithPortal>
  </OptionsButton>
)

const ForensicsViewContent = styled(ViewContent)`
  display: flex;
  flex-direction: column;
`

const ForensicsPanel = styled(Panel)`
  position: relative;

  & + & {
    margin-top: 8px;
  }

  &:last-child {
    flex-grow: 1;
    min-height: 250px;
  }
`

const ToastWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  min-width: 200px;
`

interface Bookmark {
  captureSessionId: number
  name?: string
  adapter?: string
  capture?: string
  startTime?: string
  endTime?: string
  filter?: string
}

type DownloadPacketsTask = {
  operation: "search" | "save"
  localFileName: string
  remoteFileName: string
  forensicSearchId: string | null
  saveTaskId: number | null
  progress: number
}

type RouteInfo = {
  sessId: string | undefined
}

type ForensicsViewProps = RouteComponentProps<RouteInfo> & {
  dispatch: Function
  engine: string
  authToken: string
  namesModTime?: string
  showAddressNames: boolean
  showPortNames: boolean
  showLocalTime: boolean
  viewType: number
  refreshInterval: number
  sampleInterval: number
  topStatsTalkersViewType: string
  topStatsProtocolsViewType: string
  topStatsChartType: ChartType
  topStatsChartScale: string
  chartType: ChartType
  chartScale: string
  chartInterpolation: string
  refSelection: boolean
  refAreaLeft: string | number
  refAreaRight: string | number
  engineCapabilities: ResponseGetEngineCapabilities | null
  userId: string
}

type ForensicsViewState = {
  captureSessions: ResponseGetCaptureSessions | null
  timelineData: ResponseGetTimelineData | null
  topTalkers: ResponseGetTimelineStatistics | null
  topProtocols: ResponseGetTimelineStatistics | null
  showForensicSearchModal: boolean
  bookmark: Bookmark | null
  error: any | null
  selectedRow: any | null
  showDeleteCaptureSessionConfirm: boolean
  showDeleteAllCaptureSessionsConfirm: boolean
  sparklineDataCounter: number
  downloadPacketsTask: DownloadPacketsTask | null
  downloadPacketsRefreshInterval: number
  showDownloadPacketsModal: boolean
  toastMessage: React.ReactNode
}

class ForensicsView extends React.Component<ForensicsViewProps, ForensicsViewState> {
  mounted: boolean = false
  sparklineDataCache: SparklineDataCache | null = null

  state: ForensicsViewState = {
    captureSessions: null,
    timelineData: null,
    topTalkers: null,
    topProtocols: null,
    showForensicSearchModal: false,
    bookmark: null,
    error: null,
    selectedRow: null,
    showDeleteCaptureSessionConfirm: false,
    showDeleteAllCaptureSessionsConfirm: false,
    sparklineDataCounter: 0,
    downloadPacketsTask: null,
    downloadPacketsRefreshInterval: 5000,
    showDownloadPacketsModal: false,
    toastMessage: null,
  }

  constructor(props: ForensicsViewProps) {
    super(props)
    this.sparklineDataCache = new SparklineDataCache(
      this.props.engine,
      this.props.authToken,
      () => {
        if (this.mounted) {
          this.setState(prevState => {
            return { sparklineDataCounter: prevState.sparklineDataCounter + 1 }
          })
        }
      }
    )
  }

  componentDidMount() {
    this.mounted = true
    this.onRefresh()
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    window.addEventListener("beforeunload", this.onBeforeUnload)
  }

  componentWillUnmount() {
    this.mounted = false
    this.onDownloadPacketsTaskCancel()
    window.removeEventListener("beforeunload", this.onBeforeUnload)
  }

  onBeforeUnload = (event: BeforeUnloadEvent) => {
    const { downloadPacketsTask } = this.state
    if (downloadPacketsTask) {
      // OD-2411 Prevent possible 'orphaned' file.
      // This requires the fetch api to use the 'keepalive' option.
      this.onDownloadPacketsTaskCancel()
      if (navigator.userAgent.toLowerCase().indexOf("firefox") !== -1) {
        // Firefox doesn't support the 'keeplive' option (https://caniuse.com/?search=keepalive).
        // Return some text to make the browser stop and notify the user so the request works.
        // Note: the actual text doesn't matter.
        event.returnValue = "Download file task canceled"
        event.preventDefault()
      }
    }
    return undefined
  }

  componentDidUpdate(prevProps: ForensicsViewProps) {
    if (this.props.match.params.sessId !== prevProps.match.params.sessId) {
      this.props.dispatch(setForensicsTimelineSelection(false, "", ""))
      this.setState({ error: "" })
      this.onRefreshStats(this.props.match.params.sessId)
    }
    if (
      this.props.namesModTime !== prevProps.namesModTime ||
      this.props.showAddressNames !== prevProps.showAddressNames ||
      this.props.viewType !== prevProps.viewType ||
      this.props.sampleInterval !== prevProps.sampleInterval
    ) {
      this.onRefreshStats(this.props.match.params.sessId)
    }
    if (
      this.props.refAreaLeft !== prevProps.refAreaLeft ||
      this.props.refAreaRight !== prevProps.refAreaRight ||
      this.props.refSelection !== prevProps.refSelection
    ) {
      if (!this.props.refSelection) {
        this.onRefreshStats(this.props.match.params.sessId)
      }
    }
    if (this.props.topStatsTalkersViewType !== prevProps.topStatsTalkersViewType) {
      this.setState({ topTalkers: null }, () => this.onRefreshStats(this.props.match.params.sessId))
    }
    if (this.props.topStatsProtocolsViewType !== prevProps.topStatsProtocolsViewType) {
      this.setState({ topProtocols: null }, () =>
        this.onRefreshStats(this.props.match.params.sessId)
      )
    }
    // make sure the user can create forensic searches
    let canCreateForensicSearch = true
    if (
      this.props.engineCapabilities &&
      this.props.engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL)
    ) {
      const policies = this.props.engineCapabilities.userRights.policies
      canCreateForensicSearch = policies.includes(EngineUserPolicies.createForensicSearch)
    }
    if (
      canCreateForensicSearch &&
      (this.props.location.search !== prevProps.location.search ||
        (this.props.location.search && this.state.bookmark === null))
    ) {
      if (this.props.location.search) {
        const params = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
        const bookmark: Bookmark = { captureSessionId: -1 }
        if ("captureSessionId" in params) {
          bookmark.captureSessionId = toNumber(params.captureSessionId)
        }
        bookmark.name = params.name as string
        bookmark.adapter = params.adapter as string
        bookmark.capture = params.capture as string
        if (Number.isNaN(Number(params.startTime))) {
          bookmark.startTime = params.startTime as string
        }
        if (Number.isNaN(Number(params.endTime))) {
          bookmark.endTime = params.endTime as string
        }
        bookmark.filter = params.filter as string
        const showDialog = params.showDialog === undefined || params.showDialog ? true : false
        this.setState({ bookmark, showForensicSearchModal: showDialog })
      } else {
        this.setState({ bookmark: null, showForensicSearchModal: false })
      }
    }
  }

  onRefresh = () => {
    const { engine, authToken } = this.props
    fetchCaptureSessions(engine, authToken)
      .then(captureSessions => {
        if (this.mounted && captureSessions && captureSessions.rows) {
          this.setState({ captureSessions, error: "" })

          if (this.props.match.params.sessId != null) {
            const sessId = toNumber(this.props.match.params.sessId)
            if (captureSessions) {
              if (captureSessions.rows.findIndex(row => row.SessionID === sessId) === -1) {
                this.props.history.push(getEngineForensicsUrl())
              }
            }
          }

          this.onRefreshStats(this.props.match.params.sessId)

          // Request updated sparkline data for active sessions.
          if (this.sparklineDataCache !== null) {
            for (const row of captureSessions.rows) {
              if (
                row.CaptureState === CaptureState.CAPTURE_STATE_CAPTURING &&
                row.SessionID !== undefined
              ) {
                this.sparklineDataCache.fetchSessionData(row.SessionID)
              }
            }
          }
        }
      })
      .catch(error => {
        if (this.mounted) {
          this.setState({ error: `${error.code} ${error.reason}` })
        }
      })
  }

  onRefreshStats = (sessId: string | undefined) => {
    if (sessId !== undefined) {
      const { selStartTime, selEndTime } = this.getTimelineGraphSelectedTimes()
      const { engine, authToken } = this.props
      fetchTimelineStatistics(
        engine,
        authToken,
        toNumber(sessId),
        TopStatsURL[this.props.topStatsTalkersViewType] as TimelineStatisticsType,
        selStartTime,
        selEndTime
      )
        .then((topTalkers: ResponseGetTimelineStatistics) => {
          if (this.mounted && topTalkers && topTalkers.entries) {
            topTalkers.entries.forEach((entry: TimelineStatisticsEntry, i: number) => {
              if (!this.props.showAddressNames) {
                entry.name = entry.entry
              }
              if (entry.color === "#000000") {
                entry.color = getChartColor(i)
              }
            })
            this.setState({ topTalkers })
          }
        })
        .catch(() => {})

      fetchTimelineStatistics(
        engine,
        authToken,
        toNumber(sessId),
        TopStatsURL[this.props.topStatsProtocolsViewType] as TimelineStatisticsType,
        selStartTime,
        selEndTime
      )
        .then((topProtocols: ResponseGetTimelineStatistics) => {
          if (this.mounted && topProtocols && topProtocols.entries) {
            topProtocols.entries.forEach((entry: TimelineStatisticsEntry, i: number) => {
              if (!this.props.showPortNames) {
                entry.name = entry.entry
              }
              if (entry.color === "#000000") {
                entry.color = getChartColor(i)
              }
            })
            this.setState({ topProtocols })
          }
        })
        .catch(() => {})

      fetchTimelineData(
        engine,
        authToken,
        toNumber(sessId),
        DataURL[this.props.viewType] as TimelineDataType,
        this.props.sampleInterval
      )
        .then((timelineData: ResponseGetTimelineData) => {
          if (this.mounted && timelineData && timelineData.data) {
            this.setState({ timelineData })
          }
        })
        .catch(() => {})
    } else {
      this.setState({ topTalkers: null, topProtocols: null, timelineData: null })
    }
  }

  onRefreshIntervalChanged = (refreshInterval: number) => {
    this.props.dispatch(setForensicsViewRefreshInterval(refreshInterval))
  }

  onViewTypeChanged = (viewType: number) => {
    this.props.dispatch(setForensicsViewType(viewType))
    if (viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_SIZE_DIST) {
      this.props.dispatch(setForensicsTimelineSelection(false, "", ""))
    }
  }

  onSampleIntervalChanged = (sampleInterval: number) => {
    this.props.dispatch(setForensicsSampleInterval(sampleInterval))
  }

  onTopStatsTalkersViewType = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(setForensicsTopTalkersViewType(event.currentTarget.name))
  }

  onTopStatsProtocolsViewType = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(setForensicsTopProtocolsViewType(event.currentTarget.name))
  }

  onTopStatsChartType = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(setForensicsTopStatsChartType(event.currentTarget.name))
  }

  onTopStatsChartScale = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(setForensicsTopStatsChartScale(event.currentTarget.name))
  }

  onChartType = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(setForensicsTimelineChartType(event.currentTarget.name))
  }

  onChartScale = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(setForensicsTimelineChartScale(event.currentTarget.name))
  }

  onChartSmoothing = () => {
    const chartInterpolation = this.props.chartInterpolation === "linear" ? "monotone" : "linear"
    this.props.dispatch(setForensicsTimelineChartInterpolation(chartInterpolation))
  }

  onDownloadPackets = () => {
    this.setState({ showDownloadPacketsModal: true })
  }

  onDownloadPacketsCancel = () => {
    this.setState({ showDownloadPacketsModal: false })
  }

  onDownloadPacketsOK = (query: RequestCreateForensicSearch, fileName: string, fileExt: string) => {
    this.setState({ showDownloadPacketsModal: false })
    query.filterMode = query.filter
      ? PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_MATCHING_ANY
      : PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_ALL
    if (query.endTime === new Date(0).toISOString()) {
      delete query.endTime
    }
    if (query.startTime === new Date(0).toISOString()) {
      delete query.startTime
    }

    // create the forensic search
    const { engine, authToken } = this.props
    createForensicSearch(engine, authToken, query)
      .then((response: ResponseCreateForensicSearch) => {
        this.setState({
          downloadPacketsTask: {
            operation: "search",
            localFileName: `${fileName}.${fileExt}`,
            remoteFileName: `${uuid().toUpperCase()}.${fileExt}`,
            forensicSearchId: response.id,
            saveTaskId: null,
            progress: 0,
          },
          toastMessage: (
            <span>
              <b>Searching for packets...</b>
            </span>
          ),
        })
      })
      .catch(error => {
        if (this.mounted) {
          this.setState({ error: `${error.code} ${error.reason}` })
        }
      })
  }

  onDownloadPacketsRefresh = () => {
    const { engine, authToken } = this.props
    const { downloadPacketsTask } = this.state
    if (downloadPacketsTask && downloadPacketsTask.forensicSearchId !== null) {
      // are we currently foresnic searching or saving/downloading the packet file?
      if (downloadPacketsTask.operation === "search") {
        // get the forensic search status
        fetchCFSProperties(
          engine,
          authToken,
          "forensic-searches",
          downloadPacketsTask.forensicSearchId
        )
          .then((captureProperties: CaptureProperties | ForensicSearchProperties) => {
            const forensicSearchProperties = captureProperties as ForensicSearchProperties
            if (
              forensicSearchProperties.status !== PeekFileViewStatus.PEEK_FILE_VIEW_STATUS_COMPLETE
            ) {
              // the forensic search is still running, so just update the progress
              this.setState({
                downloadPacketsTask: {
                  ...downloadPacketsTask,
                  progress: forensicSearchProperties.loadPercentProgress,
                },
                toastMessage: (
                  <span>
                    <b>{forensicSearchProperties.loadProgress}</b>
                  </span>
                ),
              })
            } else {
              // the forensic search is complete
              this.setState({
                downloadPacketsTask: {
                  ...downloadPacketsTask,
                  operation: "save",
                  progress: 0,
                },
                toastMessage: (
                  <span>
                    <b>Savinging file:</b> {downloadPacketsTask.localFileName}
                  </span>
                ),
              })
            }
          })
          .catch(error => {
            if (this.mounted) {
              this.setState({ error: `${error.code} ${error.reason}` })
            }
            this.onDownloadPacketsTaskCancel()
          })
      } else if (downloadPacketsTask.operation === "save") {
        // determine if there is a save file task in progress
        if (downloadPacketsTask.saveTaskId === null) {
          if (
            this.props.engineCapabilities !== null &&
            this.props.engineCapabilities.capabilities.includes(EngineCapabilities.saveCaptureTasks)
          ) {
            // start a save capture task
            postSaveCaptureTask(
              engine,
              authToken,
              downloadPacketsTask.forensicSearchId,
              downloadPacketsTask.remoteFileName,
              false,
              undefined
            )
              .then((response: ResponsePostSaveCaptureTask) => {
                this.setState({
                  downloadPacketsTask: {
                    ...downloadPacketsTask,
                    saveTaskId: response.taskId,
                    progress: 0,
                  },
                  toastMessage: (
                    <span>
                      <b>Saving file:</b> {downloadPacketsTask.localFileName}
                    </span>
                  ),
                })
              })
              .catch(error => {
                if (this.mounted) {
                  this.setState({ error: `${error.code} ${error.reason}` })
                }
                this.onDownloadPacketsTaskCancel()
              })
          } else {
            // save the packets
            this.setState({
              toastMessage: (
                <span>
                  <b>Saving file:</b> {downloadPacketsTask.localFileName}
                </span>
              ),
            })
            let saveCaptureFile: ResponsePostSaveCapture | null = null
            saveCFSPackets(
              engine,
              authToken,
              "forensic-searches",
              downloadPacketsTask.forensicSearchId,
              downloadPacketsTask.remoteFileName,
              false
            )
              .then((response: ResponsePostSaveCapture) => {
                saveCaptureFile = cloneDeep(response)

                // now that the save operation is complete, we can delete the forensic search
                return downloadPacketsTask.forensicSearchId !== null
                  ? deleteForensicSearch(engine, authToken, downloadPacketsTask.forensicSearchId)
                  : Promise.resolve({ results: [] })
              })
              .then(() => {
                this.setState({
                  downloadPacketsTask: { ...downloadPacketsTask, forensicSearchId: null },
                })

                // download the file
                if (saveCaptureFile) {
                  download(
                    getFileDownloadURL(
                      engine,
                      authToken,
                      saveCaptureFile.path,
                      downloadPacketsTask.localFileName,
                      true
                    ),
                    saveCaptureFile.fileName
                  )
                }
              })
              .then(() => {
                if (!this.mounted && saveCaptureFile) {
                  deleteFiles(engine, authToken, [saveCaptureFile.path]).catch(error => {
                    if (this.mounted) {
                      this.setState({ error: `${error.code} ${error.reason}` })
                    }
                  })
                }
              })
              .catch(error => {
                if (this.mounted) {
                  this.setState({ error: `${error.code} ${error.reason}` })
                }
              })
              .finally(() => {
                this.setState({ downloadPacketsTask: null, toastMessage: null })
              })
          }
        } else {
          // get the save file task status
          fetchSaveCaptureTask(engine, authToken, downloadPacketsTask.saveTaskId)
            .then((response: ResponseGetSaveCaptureTask) => {
              // has the save file process finished?
              if (response.progress === 100) {
                this.setState({ downloadPacketsTask: null, toastMessage: null })

                // now that the save operation is complete, we can delete the forensic search
                const task =
                  downloadPacketsTask.forensicSearchId !== null
                    ? deleteForensicSearch(engine, authToken, downloadPacketsTask.forensicSearchId)
                    : Promise.resolve({ results: [] })
                task.then(() => {
                  // download packet file
                  if (response.result === 0) {
                    if (
                      typeof response.path === "string" &&
                      response.path.length > 0 &&
                      typeof response.fileName === "string" &&
                      response.fileName.length > 0
                    ) {
                      download(
                        getFileDownloadURL(
                          engine,
                          authToken,
                          response.path,
                          downloadPacketsTask.localFileName,
                          true
                        ),
                        response.fileName
                      )
                      if (!this.mounted) {
                        deleteFiles(engine, authToken, [response.path]).catch(error => {
                          if (this.mounted) {
                            this.setState({ error: `${error.code} ${error.reason}` })
                          }
                        })
                      }
                    }
                  } else {
                    // TODO: show error if not cancelled
                  }
                })
              } else {
                // still saving, so just update the progress
                this.setState({
                  downloadPacketsTask: { ...downloadPacketsTask, progress: response.progress },
                })
              }
            })
            .catch(error => {
              if (this.mounted) {
                this.setState({ error: `${error.code} ${error.reason}` })
              }
              this.onDownloadPacketsTaskCancel()
            })
        }
      }
    }
  }

  onDownloadPacketsTaskCancel = () => {
    const { engine, authToken } = this.props
    const downloadPacketsTask = cloneDeep(this.state.downloadPacketsTask)

    this.setState({ downloadPacketsTask: null, toastMessage: null })

    // delete the capture task and forensic search if they still exist
    if (downloadPacketsTask) {
      ;(downloadPacketsTask.saveTaskId !== null
        ? deleteSaveCaptureTask(engine, authToken, downloadPacketsTask.saveTaskId)
        : Promise.resolve()
      )
        .then(() =>
          downloadPacketsTask.forensicSearchId !== null
            ? deleteForensicSearch(engine, authToken, downloadPacketsTask.forensicSearchId)
            : Promise.resolve({ results: [] })
        )
        .catch(error => {
          console.error(error)
        })
    }
  }

  onForensicSearch = () => {
    this.setState({ showForensicSearchModal: true })
  }

  onForensicSearchCancel = () => {
    this.setState({ showForensicSearchModal: false })
  }

  onForensicSearchOK = (query: RequestCreateForensicSearch) => {
    this.setState({ showForensicSearchModal: false })
    query.filterMode = query.filter
      ? PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_MATCHING_ANY
      : PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_ALL
    if (query.endTime === new Date(0).toISOString()) {
      delete query.endTime
    }
    if (query.startTime === new Date(0).toISOString()) {
      delete query.startTime
    }

    const { engine, authToken } = this.props
    createForensicSearch(engine, authToken, query)
      .then((response: ResponseCreateForensicSearch) => {
        if (this.mounted) {
          this.props.history.push(getEngineForensicSearchUrl(response.id))
        }
      })
      .catch(error => {
        if (this.mounted) {
          this.setState({ error: `${error.code} ${error.reason}` })
        }
      })
  }

  onDeleteCaptureSessions = (captureId: number, name: string) => {
    this.setState({ selectedRow: { captureId, name }, showDeleteCaptureSessionConfirm: true })
  }

  onDeleteCaptureSessionCancel = () => {
    this.setState({ selectedRow: null, showDeleteCaptureSessionConfirm: false })
  }

  onDeleteCaptureSessionOK = () => {
    const { selectedRow } = this.state
    if (selectedRow) {
      const captureId = selectedRow.captureId
      this.setState({ selectedRow: null, showDeleteCaptureSessionConfirm: false })
      const { engine, authToken } = this.props
      deleteCaptureSession(engine, authToken, captureId)
        .then(() => {
          if (this.mounted) {
            this.onRefresh()
          }
        })
        .catch(error => {
          if (this.mounted) {
            this.setState({ error: `${error.code} ${error.reason}` })
          }
        })
    }
  }

  onDeleteAllCaptureSessions = () => {
    this.setState({ showDeleteAllCaptureSessionsConfirm: true })
  }

  onDeleteAllCaptureSessionsCancel = () => {
    this.setState({ showDeleteAllCaptureSessionsConfirm: false })
  }

  onDeleteAllCaptureSessionsOK = () => {
    this.setState({ showDeleteAllCaptureSessionsConfirm: false })
    const { engine, authToken } = this.props
    deleteAllCaptureSessions(engine, authToken)
      .then(() => {
        if (this.mounted) {
          this.onRefresh()
        }
      })
      .catch(error => {
        if (this.mounted) {
          this.setState({ error: `${error.code} ${error.reason}` })
        }
      })
  }

  onTimelineGraphClearSelection = () => {
    this.props.dispatch(setForensicsTimelineSelection(false, "", ""))
  }

  onTimelineGraphMouseDown = (event: any) => {
    if (
      this.props.viewType !== TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_SIZE_DIST &&
      event &&
      event.activeLabel
    ) {
      this.props.dispatch(setForensicsTimelineSelection(true, event.activeLabel, ""))
    } else {
      this.props.dispatch(setForensicsTimelineSelection(false, "", ""))
    }
  }

  onTimelineGraphMouseMove = (event: any) => {
    if (
      this.props.refSelection &&
      this.props.refAreaLeft &&
      event &&
      event.activeLabel !== undefined
    ) {
      this.props.dispatch(
        setForensicsTimelineSelection(true, this.props.refAreaLeft, event.activeLabel)
      )
    }
  }

  onTimelineGraphMouseUp = () => {
    this.props.dispatch(
      setForensicsTimelineSelection(false, this.props.refAreaLeft, this.props.refAreaRight)
    )
  }

  getTimelineGraphSelection = () => {
    const { refAreaLeft, refAreaRight } = this.props
    if (refAreaLeft !== "" && refAreaRight !== "") {
      let selStart = toNumber(refAreaLeft)
      let selEnd = toNumber(refAreaRight)
      if (selEnd < selStart) {
        const temp = selStart
        selStart = selEnd
        selEnd = temp
      }
      return { selStart, selEnd }
    }
    return null
  }

  getTimelineGraphSelectedTimes = () => {
    let selStartTime = 0
    let selEndTime = 0
    const { timelineData } = this.state
    if (timelineData && timelineData.data && timelineData.data.length && timelineData.startTime) {
      const selection = this.getTimelineGraphSelection()
      if (selection) {
        const ts = peekFromDate(Date.parse(timelineData.startTime))
        const sampleTime = timelineData.sampleInterval * 1000000000
        selStartTime = ts + selection.selStart * sampleTime
        selEndTime = ts + selection.selEnd * sampleTime
      }
    }
    return { selStartTime, selEndTime }
  }

  getTimelineGraphSelectedPackets = () => {
    let packetCount = 0
    const { timelineData } = this.state
    if (timelineData && timelineData.data && timelineData.data.length) {
      const selection = this.getTimelineGraphSelection()
      if (selection) {
        for (
          let i = selection.selStart, len = timelineData.data.length;
          i < len && i < selection.selEnd;
          i++
        ) {
          const entry = timelineData.data[i]
          if ("totalPackets" in entry) {
            packetCount += entry["totalPackets"]
          }
        }
      }
    }
    return packetCount
  }

  formatProp = (columnId: string, data: DatabaseRowGetCaptureSessions) => {
    switch (columnId) {
      case "Data Start Time":
        if (data !== null && data.StartTimestamp != null) {
          return formatISODateTime(data.StartTimestamp, 6, this.props.showLocalTime)
        }
        break
      case "Data End Time":
        if (data !== null && data.StopTimestamp != null) {
          return formatISODateTime(data.StopTimestamp, 6, this.props.showLocalTime)
        }
        break
      case "Data Duration":
        if (data !== null && data.StartTimestamp != null && data.StopTimestamp != null) {
          const start = Date.parse(data.StartTimestamp)
          const stop = Date.parse(data.StopTimestamp)
          return formatDuration((stop - start) * 1000000)
        }
        break
      case "Status":
        if (data !== null && "CaptureState" in data) {
          const status = data.CaptureState
          if (status === CaptureState.CAPTURE_STATE_IDLE) {
            return "Completed"
          } else {
            return <SuccessTextWithBackground>Active</SuccessTextWithBackground>
          }
        }
        break
      case "Media":
        if (data !== null) {
          if (data.MediaType !== undefined && data.MediaSubType !== undefined) {
            let mt = data.MediaType
            let mst = data.MediaSubType
            mt = typeof mt === "string" ? parseInt(mt, 10) : mt
            mst = typeof mst === "string" ? parseInt(mst, 10) : mst
            return getMediaString(mt, mst)
          }
        }
        break
      case "Link Speed":
        if (data !== null) {
          if ("LinkSpeed" in data) {
            return formatLinkSpeed(data.LinkSpeed)
          }
        }
        break
      case "Packets":
        if (data !== null) {
          if (data.PacketCount !== undefined) {
            return formatInteger(data.PacketCount)
          }
        }
        break
      case "Packets Dropped":
        if (data !== null) {
          if (data.DroppedCount !== undefined) {
            const droppedCount = formatInteger(data.DroppedCount)
            if (data.DroppedCount > 0) {
              return <DangerText>{droppedCount}</DangerText>
            } else {
              return droppedCount
            }
          }
        }
        break
      case "Adapter":
        if (data !== null) {
          if (data.AdapterName !== undefined) {
            return data.AdapterName
          }
        }
        break
      case "Owner":
        if (data !== null) {
          if (data.Owner !== undefined) {
            return data.Owner
          }
        }
        break
      case "Sample Interval": {
        const { timelineData } = this.state
        if (timelineData) {
          const { sampleInterval } = timelineData
          if (sampleInterval === 0) {
            return ""
          } else if (sampleInterval < 60) {
            return `${sampleInterval} Sec. Avg.`
          } else if (sampleInterval < 3600) {
            return `${sampleInterval / 60} Min. Avg.`
          } else if (sampleInterval < 86400) {
            return `${sampleInterval / 3600} Hour Avg.`
          } else {
            return `${sampleInterval / 86400} Day Avg.`
          }
        }
        break
      }
      default:
        break
    }
    return ""
  }

  render() {
    const {
      viewType,
      refreshInterval,
      sampleInterval,
      topStatsTalkersViewType,
      topStatsProtocolsViewType,
      topStatsChartType,
      topStatsChartScale,
      chartType,
      chartScale,
      chartInterpolation,
      refAreaLeft,
      refAreaRight,
      engineCapabilities,
      showLocalTime,
    } = this.props
    const {
      captureSessions,
      topTalkers,
      topProtocols,
      timelineData,
      error,
      showForensicSearchModal,
      showDeleteCaptureSessionConfirm: showDeleteCaptureSessionsConfirm,
      showDeleteAllCaptureSessionsConfirm,
      selectedRow,
      sparklineDataCounter,
      downloadPacketsTask,
      downloadPacketsRefreshInterval,
      showDownloadPacketsModal,
      toastMessage,
    } = this.state
    let sessId: number | undefined = undefined
    if (this.props.match.params.sessId !== undefined) {
      sessId = toNumber(this.props.match.params.sessId)
    }
    let row: DatabaseRowGetCaptureSessions | undefined = {}
    if (captureSessions !== null) {
      if (sessId !== undefined) {
        row = captureSessions.rows.find(row => row.SessionID === sessId)
        if (!row) {
          row = {}
          sessId = undefined
        }
      }
      if (sessId === undefined) {
        // Find the most recent session.
        let recentSessId: number | undefined = undefined
        let recentStartTimestamp: number | undefined = 0
        for (let i = 0; i < captureSessions.rows.length; ++i) {
          const startTimestamp = captureSessions.rows[i].StartTimestamp
          if (startTimestamp != null) {
            const start = Date.parse(startTimestamp)
            if (start > recentStartTimestamp) {
              recentStartTimestamp = start
              recentSessId = captureSessions.rows[i].SessionID
            }
          }
        }
        if (recentSessId) {
          return (
            <Redirect
              to={`${getEngineForensicsCaptureSessionUrl(recentSessId)}/${
                this.props.location.search
              }`}
            />
          )
        }
      }
    }

    const showTopStats =
      row &&
      row.CaptureFlags !== undefined &&
      (row.CaptureFlags & CaptureFlags.CAPTURE_TOP_STATS) !== 0

    const url = sessId !== undefined ? getEngineForensicsCaptureSessionUrl(sessId) : ""

    const { selStartTime, selEndTime } = this.getTimelineGraphSelectedTimes()
    const selectedPackets = this.getTimelineGraphSelectedPackets()

    let searchParams = null
    if (showForensicSearchModal || showDownloadPacketsModal) {
      const { bookmark } = this.state
      if (bookmark) {
        if (sessId === bookmark.captureSessionId) {
          searchParams = {
            captureSessionId: bookmark.captureSessionId,
            name:
              bookmark.name ||
              (row && row.Name !== undefined ? row.Name.replace(/\.[^/.]+$/, "") : ""),
            mediaType: row && row.MediaType,
            mediaSubType: row && row.MediaSubType,
            startTime: bookmark.startTime || (row && row.StartTimestamp),
            endTime: bookmark.endTime || (row && row.StopTimestamp),
            filter: bookmark.filter,
          }
        } else {
          searchParams = {
            captureSessionId: bookmark.captureSessionId,
            name: bookmark.name,
            mediaType: MediaType.MEDIA_TYPE_802_3,
            mediaSubType: MediaSubType.MEDIA_SUBTYPE_NATIVE,
            startTime: bookmark.startTime,
            endTime: bookmark.endTime,
            filter: bookmark.filter,
          }
        }
      } else {
        const start = selStartTime ? new Date(peekToDate(selStartTime)).toISOString() : undefined
        const end = selEndTime ? new Date(peekToDate(selEndTime)).toISOString() : undefined
        searchParams = {
          captureSessionId: sessId,
          name: row && row.Name && row.Name !== undefined ? row.Name.replace(/\.[^/.]+$/, "") : "",
          mediaType: row && row.MediaType,
          mediaSubType: row && row.MediaSubType,
          startTime: start || (row && row.StartTimestamp),
          endTime: end || (row && row.StopTimestamp),
        }
      }
    }

    if (captureSessions === null) return null

    // make sure the user can create forensic searches
    let canCreateForensicSearch = true
    let canSaveFiles = true
    if (
      engineCapabilities &&
      engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL)
    ) {
      const policies = engineCapabilities.userRights.policies
      canCreateForensicSearch = policies.includes(EngineUserPolicies.createForensicSearch)
      canSaveFiles = policies.includes(
        engineCapabilities.capabilities.includes(EngineCapabilities.saveFilesACL)
          ? EngineUserPolicies.saveFiles
          : EngineUserPolicies.downloadFiles
      )
    }

    return (
      <View>
        <Prompt
          when={toastMessage !== null}
          message="Warning! Leaving this view will stop the file download in progress."
        />
        <Helmet title="Forensics" />
        <BreadcrumbItem to={getEngineForensicsUrl()} title="Forensics" />
        {row && row.Name && <BreadcrumbItem to={url} title={row.Name} />}
        {error && (
          <Alert color="danger">
            {typeof error === "string" ? error : `${error.code} ${error.reason}`}
          </Alert>
        )}
        <Interval
          timeout={refreshInterval * 1000}
          enabled={refreshInterval !== 0}
          callback={this.onRefresh}
        />
        <Interval
          timeout={downloadPacketsRefreshInterval}
          enabled={downloadPacketsTask !== null}
          callback={this.onDownloadPacketsRefresh}
        />
        <ViewHeader>
          <ViewHeaderTitle title={row && row.Name !== undefined ? row.Name : ""} />
          <ViewHeaderButtons>
            <LightButton disabled={!canCreateForensicSearch} onClick={this.onForensicSearch}>
              <FontAwesome name="search" flip="horizontal" /> Forensic Search
            </LightButton>
            <LightButton
              disabled={!canCreateForensicSearch || !canSaveFiles}
              onClick={this.onDownloadPackets}
            >
              <FontAwesome name="download" /> Download Packets
            </LightButton>
            <UncontrolledDropdown>
              <LightDropdownToggle caret style={{ width: "100%" }}>
                View
              </LightDropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  active={viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_MBITS}
                  onClick={this.onViewTypeChanged.bind(
                    this,
                    TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_MBITS
                  )}
                >
                  Network Utilization (Mbits/s)
                </DropdownItem>
                <DropdownItem
                  active={viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_PACKETS}
                  onClick={this.onViewTypeChanged.bind(
                    this,
                    TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_PACKETS
                  )}
                >
                  Network Utilization (Packets/s)
                </DropdownItem>
                <DropdownItem
                  active={viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_DEST_TYPE}
                  onClick={this.onViewTypeChanged.bind(
                    this,
                    TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_DEST_TYPE
                  )}
                >
                  Unicast/Multicast/Broadcast
                </DropdownItem>
                <DropdownItem
                  active={viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_SIZE_DIST}
                  onClick={this.onViewTypeChanged.bind(
                    this,
                    TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_SIZE_DIST
                  )}
                >
                  Packet Sizes
                </DropdownItem>
                <DropdownItem
                  active={viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_VLAN}
                  onClick={this.onViewTypeChanged.bind(
                    this,
                    TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_VLAN
                  )}
                >
                  {engineCapabilities?.capabilities.includes(EngineCapabilities.vxlanTimelineStats)
                    ? "MPLS/VLAN/VXLAN"
                    : "VLAN/MPLS"}
                </DropdownItem>
                <DropdownItem
                  active={viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_PROTOCOLS_MBITS}
                  onClick={this.onViewTypeChanged.bind(
                    this,
                    TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_PROTOCOLS_MBITS
                  )}
                >
                  Protocols (Mbits/s)
                </DropdownItem>
                <DropdownItem
                  active={
                    viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_PROTOCOLS_PACKETS
                  }
                  onClick={this.onViewTypeChanged.bind(
                    this,
                    TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_PROTOCOLS_PACKETS
                  )}
                >
                  Protocols (Packets/s)
                </DropdownItem>
                <DropdownItem
                  active={
                    viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_APPLICATIONS_MBITS
                  }
                  onClick={this.onViewTypeChanged.bind(
                    this,
                    TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_APPLICATIONS_MBITS
                  )}
                >
                  Applications (Mbits/s)
                </DropdownItem>
                <DropdownItem
                  active={
                    viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_APPLICATIONS_PACKETS
                  }
                  onClick={this.onViewTypeChanged.bind(
                    this,
                    TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_APPLICATIONS_PACKETS
                  )}
                >
                  Applications (Packets/s)
                </DropdownItem>
                <DropdownItem
                  active={viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_CALL_QUALITY}
                  onClick={this.onViewTypeChanged.bind(
                    this,
                    TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_CALL_QUALITY
                  )}
                >
                  Call Quality
                </DropdownItem>
                <DropdownItem
                  active={
                    viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_CALL_UTILIZATION
                  }
                  onClick={this.onViewTypeChanged.bind(
                    this,
                    TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_CALL_UTILIZATION
                  )}
                >
                  Call vs. Network Utilization
                </DropdownItem>
                <DropdownItem
                  active={
                    viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_WIRELESS_PACKETS
                  }
                  onClick={this.onViewTypeChanged.bind(
                    this,
                    TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_WIRELESS_PACKETS
                  )}
                >
                  Wireless Packets (Packets/s)
                </DropdownItem>
                <DropdownItem
                  active={
                    viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_WIRELESS_RETRIES
                  }
                  onClick={this.onViewTypeChanged.bind(
                    this,
                    TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_WIRELESS_RETRIES
                  )}
                >
                  Wireless Retries (Packets/s)
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown>
              <LightDropdownToggle caret style={{ width: "100%" }}>
                Sample Interval
              </LightDropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  active={sampleInterval === 0}
                  onClick={this.onSampleIntervalChanged.bind(this, 0)}
                >
                  Automatic
                </DropdownItem>
                <DropdownItem
                  active={sampleInterval === 1}
                  onClick={this.onSampleIntervalChanged.bind(this, 1)}
                >
                  1 Sec. Avg.
                </DropdownItem>
                <DropdownItem
                  active={sampleInterval === 2}
                  onClick={this.onSampleIntervalChanged.bind(this, 2)}
                >
                  2 Sec. Avg.
                </DropdownItem>
                <DropdownItem
                  active={sampleInterval === 5}
                  onClick={this.onSampleIntervalChanged.bind(this, 5)}
                >
                  5 Sec. Avg.
                </DropdownItem>
                <DropdownItem
                  active={sampleInterval === 10}
                  onClick={this.onSampleIntervalChanged.bind(this, 10)}
                >
                  10 Sec. Avg.
                </DropdownItem>
                <DropdownItem
                  active={sampleInterval === 15}
                  onClick={this.onSampleIntervalChanged.bind(this, 15)}
                >
                  15 Sec. Avg.
                </DropdownItem>
                <DropdownItem
                  active={sampleInterval === 30}
                  onClick={this.onSampleIntervalChanged.bind(this, 30)}
                >
                  30 Sec. Avg.
                </DropdownItem>
                <DropdownItem
                  active={sampleInterval === 60}
                  onClick={this.onSampleIntervalChanged.bind(this, 60)}
                >
                  1 Min. Avg.
                </DropdownItem>
                <DropdownItem
                  active={sampleInterval === 120}
                  onClick={this.onSampleIntervalChanged.bind(this, 120)}
                >
                  2 Min. Avg.
                </DropdownItem>
                <DropdownItem
                  active={sampleInterval === 300}
                  onClick={this.onSampleIntervalChanged.bind(this, 300)}
                >
                  5 Min. Avg.
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown group>
              <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefresh}>
                <FontAwesome name="refresh" />
              </LightButton>
              <LightDropdownToggle split />
              <DropdownMenu end>
                <DropdownItem
                  active={refreshInterval === 0}
                  onClick={this.onRefreshIntervalChanged.bind(this, 0)}
                >
                  Manual
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 5}
                  onClick={this.onRefreshIntervalChanged.bind(this, 5)}
                >
                  5 Sec.
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 10}
                  onClick={this.onRefreshIntervalChanged.bind(this, 10)}
                >
                  10 Sec.
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 30}
                  onClick={this.onRefreshIntervalChanged.bind(this, 30)}
                >
                  30 Sec.
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 60}
                  onClick={this.onRefreshIntervalChanged.bind(this, 60)}
                >
                  1 Min.
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 120}
                  onClick={this.onRefreshIntervalChanged.bind(this, 120)}
                >
                  2 Min.
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 300}
                  onClick={this.onRefreshIntervalChanged.bind(this, 300)}
                >
                  5 Min.
                </DropdownItem>
                <DropdownItem
                  active={refreshInterval === 600}
                  onClick={this.onRefreshIntervalChanged.bind(this, 600)}
                >
                  10 Min.
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </ViewHeaderButtons>
        </ViewHeader>
        <ForensicsViewContent>
          <ForensicsPanel>
            <PropTable
              data={row}
              propList={propList}
              formatProp={this.formatProp}
              key={sampleInterval}
            />
          </ForensicsPanel>
          {showTopStats && (
            <ForensicsPanel>
              <TopStatsOptionsButton
                viewTypeLeft={topStatsTalkersViewType}
                viewTypeRight={topStatsProtocolsViewType}
                chartType={topStatsChartType}
                chartScale={topStatsChartScale}
                onViewTypeLeft={this.onTopStatsTalkersViewType}
                onViewTypeRight={this.onTopStatsProtocolsViewType}
                onChartType={this.onTopStatsChartType}
                onChartScale={this.onTopStatsChartScale}
              />
              <ForensicsTopStats>
                <ForensicsTopStatsWidget
                  data={topTalkers}
                  viewType={topStatsTalkersViewType}
                  chartType={topStatsChartType}
                  chartScale={topStatsChartScale}
                />
                <ForensicsTopStatsWidget
                  data={topProtocols}
                  viewType={topStatsProtocolsViewType}
                  chartType={topStatsChartType}
                  chartScale={topStatsChartScale}
                />
              </ForensicsTopStats>
            </ForensicsPanel>
          )}
          <ForensicsPanel>
            <TimelineStatsOptionsButton
              disabled={viewType === TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_SIZE_DIST}
              chartType={chartType}
              chartScale={chartScale}
              chartInterpolation={chartInterpolation}
              onChartType={this.onChartType}
              onChartScale={this.onChartScale}
              onChartSmoothing={this.onChartSmoothing}
            />
            <ForensicsGraphSelection
              selStartTime={selStartTime}
              selEndTime={selEndTime}
              packetCount={selectedPackets}
              onClearSelection={this.onTimelineGraphClearSelection}
            />
            <ForensicsGraphWidget
              engineCapabilities={engineCapabilities}
              data={timelineData}
              viewType={viewType}
              chartType={chartType}
              showLocalTime={showLocalTime}
              chartScale={chartScale}
              chartInterpolation={chartInterpolation}
              refAreaLeft={refAreaLeft}
              refAreaRight={refAreaRight}
              height={240}
              onMouseDown={this.onTimelineGraphMouseDown}
              onMouseMove={this.onTimelineGraphMouseMove}
              onMouseUp={this.onTimelineGraphMouseUp}
            />
          </ForensicsPanel>
          <ForensicsPanel>
            <CaptureSessionsTable
              triggerRender={sparklineDataCounter}
              captureSessions={captureSessions}
              captureSessionId={sessId}
              onDeleteCaptureSessions={this.onDeleteCaptureSessions}
              onDeleteAllCaptureSessions={this.onDeleteAllCaptureSessions}
              sparklineDataCache={this.sparklineDataCache}
              engineCapabilities={engineCapabilities}
              userId={this.props.userId}
            />
          </ForensicsPanel>
        </ForensicsViewContent>
        {showForensicSearchModal && searchParams && (
          <ForensicSearchModal
            onCancel={this.onForensicSearchCancel}
            onOK={this.onForensicSearchOK}
            captureSessionId={searchParams.captureSessionId}
            name={searchParams.name}
            mediaType={searchParams.mediaType}
            mediaSubType={searchParams.mediaSubType}
            startTime={searchParams.startTime}
            endTime={searchParams.endTime}
            filter={searchParams.filter}
          />
        )}
        {showDownloadPacketsModal && searchParams && (
          <DownloadPacketsModal
            onCancel={this.onDownloadPacketsCancel}
            onOK={this.onDownloadPacketsOK}
            captureSessionId={searchParams.captureSessionId}
            name={searchParams.name}
            mediaType={searchParams.mediaType}
            mediaSubType={searchParams.mediaSubType}
            startTime={searchParams.startTime}
            endTime={searchParams.endTime}
            filter={searchParams.filter}
          />
        )}
        {showDeleteCaptureSessionsConfirm && selectedRow && (
          <ConfirmationModal
            message={`Delete "${selectedRow.name}", including all capture sessions, packet data, and statistics?`}
            onNo={this.onDeleteCaptureSessionCancel}
            onYes={this.onDeleteCaptureSessionOK}
            show={true}
            title="Delete Capture Session"
          />
        )}
        {showDeleteAllCaptureSessionsConfirm && (
          <ConfirmationModal
            message="Delete all capture sessions, packet data, and statistics?"
            onNo={this.onDeleteAllCaptureSessionsCancel}
            onYes={this.onDeleteAllCaptureSessionsOK}
            show={true}
            title="Delete All Capture Sessions"
          />
        )}
        {toastMessage !== null ? (
          downloadPacketsTask !== null ? (
            <ToastWrapper>
              <Toast>
                <ToastBody style={{ display: "flex", flexDirection: "column", minWidth: "226px" }}>
                  <div style={{ wordBreak: "break-all", marginBottom: "0.5rem" }}>
                    {toastMessage}
                  </div>
                  <BarGauge
                    aria-label="Progress"
                    value={downloadPacketsTask.progress}
                    max={100}
                    title={`${formatInteger(downloadPacketsTask.progress)}%`}
                  />
                  <LightButton
                    style={{ marginTop: "1rem" }}
                    onClick={this.onDownloadPacketsTaskCancel}
                  >
                    Cancel
                  </LightButton>
                </ToastBody>
              </Toast>
            </ToastWrapper>
          ) : (
            <ToastWrapper>
              <Toast>
                <ToastBody>{toastMessage}</ToastBody>
              </Toast>
            </ToastWrapper>
          )
        ) : null}
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  namesModTime: getNamesModificationTime(state),
  showAddressNames: getShowAddressNames(state),
  showPortNames: getShowPortNames(state),
  showLocalTime: getShowLocalTime(state),
  refreshInterval: getForensicsViewRefreshInterval(state) || 10,
  viewType: getForensicsViewType(state) || TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_MBITS,
  sampleInterval: getForensicsSampleInterval(state) || 0,
  topStatsTalkersViewType: getForensicsTopTalkersViewType(state) || TopStatsViewType.IP,
  topStatsProtocolsViewType:
    getForensicsTopProtocolsViewType(state) || TopStatsViewType.APPLICATIONS,
  topStatsChartType: getForensicsTopStatsChartType(state) || ChartType.HORIZONTAL_BAR,
  topStatsChartScale: getForensicsTopStatsChartScale(state) || "fixed",
  chartType: getForensicsTimelineChartType(state) || ChartType.OVERLAID_SKYLINE,
  chartScale: getForensicsTimelineChartScale(state) || "linear",
  chartInterpolation: getForensicsTimelineChartInterpolation(state) || "linear",
  refSelection: getForensicsTimelineSelection(state) || false,
  refAreaLeft: getForensicsTimelineSelectionStart(state) || "",
  refAreaRight: getForensicsTimelineSelectionEnd(state) || "",
  engineCapabilities: getCapabilities(state) || null,
  userId: getUserId(state),
})

export default connect(mapStateToProps)(ForensicsView)
