import * as React from "react"
import { connect } from "react-redux"
import { RouteComponentProps } from "react-router-dom"
import { Helmet } from "react-helmet"
import { ButtonGroup } from "reactstrap"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { cloneDeep } from "lodash"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { Alert } from "../common/Alert"
import Interval from "../common/Interval"
import { Link } from "../common/Link"
import { OmniTable } from "../common/OmniTable"
import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarTitle,
  SidebarContent,
} from "../common/Sidebar"
import {
  View,
  ViewMaxWidth,
  ViewContent,
  ViewHeader,
  ViewHeaderTitle,
  ViewHeaderButtons,
} from "../common/View"
import { CloseButton, IconDropdownToggle, LightButton, LightDangerButton } from "../common/Buttons"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import FilterBox from "../common/FilterBox"
import ConfirmationModal from "../common/ConfirmationModal"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import ForensicSearchHistoryView from "../ForensicSearchHistoryView"
import { collator } from "../../utils/sortUtils"
import { formatProp } from "../../utils/propUtils"
import { getEngineForensicSearchUrl } from "../../routes"
import {
  getEngine,
  getAuthToken,
  getCapabilities,
  getForensicSearchesColumns,
  getForensicSearchesFilter,
  getForensicSearchesSortBy,
  getForensicSearchesSortDirection,
  getForensicSearchesViewType,
  getUserId,
  getShowLocalTime,
} from "../../store"
import {
  setForensicSearchesColumns,
  setForensicSearchesFilter,
  setForensicSearchesSort,
  setForensicSearchesViewType,
} from "../../store/ui"
import {
  deleteForensicSearch,
  fetchForensicSearches,
  stopLoadForensicSearch,
  putForensicSearch,
} from "../../api/api"
import {
  ForensicSearchProperties,
  ResponseGetEngineCapabilities,
  ResponseGetForensicSearches,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { PeekFileViewStatus } from "../../api/types/peekTypes"
import ForensicSearchPanel from "../ForensicSearchPanel"
import ForensicSearchEditNameModal from "./ForensicSearchEditNameModal"

const defaultColumns = [
  {
    dataKey: "name",
    label: "Name",
    width: 200,
    flexGrow: 1,
    visible: true,
  },
  {
    dataKey: "captureName",
    label: "Capture",
    width: 200,
    visible: true,
  },
  {
    dataKey: "status",
    label: "Status",
    width: 200,
    visible: true,
  },
  {
    dataKey: "adapter",
    label: "Adapter",
    width: 140,
    visible: true,
  },
  {
    dataKey: "linkSpeed",
    label: "Link Speed",
    width: 140,
    visible: false,
  },
  {
    dataKey: "mediaType",
    label: "Media",
    width: 120,
    visible: false,
  },
  {
    dataKey: "packetCount",
    label: "Packets",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "startTime",
    label: "Start Time",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "stopTime",
    label: "Stop Time",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "duration",
    label: "Duration",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "creator",
    label: "Owner",
    width: 140,
    visible: false,
  },
  {
    dataKey: "creationTime",
    label: "Created",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "modificationBy",
    label: "Modified By",
    width: 140,
    visible: false,
  },
  {
    dataKey: "modificationType",
    label: "Action",
    width: 140,
    visible: false,
  },
]

const CommandStrip = styled.div`
  display: flex;
`

const List = styled.div`
  display: flex;
  flex-direction: column;
`

const ForensicSearchWrapper = styled.div`
  flex-grow: 1;
  display: flex;
`

const ForensicSearchHistoryViewSidebarContent = styled(SidebarContent)`
  background-color: ${props => props.theme.forensicSearchHistoryBackgroundColor};
`

type ForensicSearchesListProps = {
  engineCapabilities: ResponseGetEngineCapabilities
  forensicSearches: ForensicSearchProperties[]
  userId: string
  onDeleteForensicSearch: (id: string) => void
  onUpdateForensicSearchName: (id: string, name: string) => void
  onStopLoadForensicSearch: (id: string) => void
  showLocalTime: boolean
}

const ForensicSearchesList = ({
  engineCapabilities,
  forensicSearches,
  userId,
  onDeleteForensicSearch,
  onUpdateForensicSearchName,
  onStopLoadForensicSearch,
  showLocalTime,
}: ForensicSearchesListProps) => {
  const rows = forensicSearches.map(forensicSearch => {
    const policies = engineCapabilities.userRights.policies
    const isUserOwner = userId === forensicSearch.creatorSID
    const isViewEnabled =
      isUserOwner ||
      ((!engineCapabilities.capabilities.includes(
        EngineCapabilities.viewCapturesForensicSearchesACL
      ) ||
        policies.includes(EngineUserPolicies.viewForensicSearches)) &&
        (policies.includes(EngineUserPolicies.viewPackets) ||
          policies.includes(EngineUserPolicies.viewStats)))
    const isDeleteForensicSearchEnabled =
      !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL) ||
      isUserOwner ||
      policies.includes(EngineUserPolicies.deleteForensicSearches)

    // Verify if users can update forensic searches
    const isEditingOfNameEnabled =
      engineCapabilities &&
      engineCapabilities.capabilities.includes(EngineCapabilities.putForensicSearch)

    return (
      <ForensicSearchPanel
        key={forensicSearch.id}
        forensicSearch={forensicSearch}
        isViewEnabled={isViewEnabled}
        isDeleteForensicSearchEnabled={isDeleteForensicSearchEnabled}
        isEditingOfNameEnabled={isEditingOfNameEnabled}
        onDeleteForensicSearch={onDeleteForensicSearch}
        onUpdateForensicSearchName={onUpdateForensicSearchName}
        onStopLoadForensicSearch={onStopLoadForensicSearch}
        showLocalTime={showLocalTime}
      />
    )
  })
  return <List>{rows}</List>
}

type ForensicSearchesViewProps = RouteComponentProps & {
  dispatch: Function
  engine: string
  authToken: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  filter: string
  showLocalTime: boolean
  userId: string
  viewType: "list" | "table"
  columns: any[]
  sortBy: string
  sortDirection: SortDirectionType
}

type ForensicSearchesViewState = {
  forensicSearches: ForensicSearchProperties[] | null
  fetchError: any | null
  showDeleteAllConfirm: boolean
  showDeleteConfirm: boolean
  forensicSearchId: string | null
  refreshInterval: number
  isHistoryOpen: boolean
  isHistorySearchOpen: boolean
  forensicSearchName: string | null
  isNameBeingEdited: boolean
}

class ForensicSearchesView extends React.Component<
  ForensicSearchesViewProps,
  ForensicSearchesViewState
> {
  state: ForensicSearchesViewState = {
    forensicSearches: null,
    fetchError: null,
    showDeleteAllConfirm: false,
    showDeleteConfirm: false,
    forensicSearchId: null,
    refreshInterval: 5000,
    isHistoryOpen: false,
    isHistorySearchOpen: false,
    forensicSearchName: null,
    isNameBeingEdited: false,
  }

  componentDidMount() {
    this.onRefresh()
  }

  onRefresh = () => {
    const { engine, authToken } = this.props
    fetchForensicSearches(engine, authToken)
      .then((forensicSearches: ResponseGetForensicSearches) => {
        if (Array.isArray(forensicSearches.forensicSearches)) {
          const { viewType, sortBy, sortDirection } = this.props
          if (viewType === "list") {
            this.sort(forensicSearches.forensicSearches, "name", SortDirection.ASC)
          } else {
            this.sort(forensicSearches.forensicSearches, sortBy, sortDirection)
          }
          const refreshInterval = forensicSearches.forensicSearches.find(
            it => it.status !== PeekFileViewStatus.PEEK_FILE_VIEW_STATUS_COMPLETE
          )
            ? 2500
            : 5000
          this.setState({ forensicSearches: forensicSearches.forensicSearches, refreshInterval })
        }
      })
      .catch(error => {
        this.setState({ fetchError: error })
      })
  }

  onChangeFilter = (filter: string) => {
    this.props.dispatch(setForensicSearchesFilter(filter))
  }

  onShowDefaultColumns = () => {
    this.props.dispatch(setForensicSearchesColumns(defaultColumns))
  }

  onShowAllColumns = () => {
    const columns = cloneDeep(this.props.columns)
    columns.forEach(col => {
      col.visible = true
    })
    this.props.dispatch(setForensicSearchesColumns(columns))
  }

  onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const columns = cloneDeep(this.props.columns)
    const col = columns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      this.props.dispatch(setForensicSearchesColumns(columns))
    }
  }

  onStopAll = () => {
    const forensicSearches = this.getForensicSearches()
    if (forensicSearches) {
      const requests = forensicSearches.map(forensicSearch => {
        const { engine, authToken } = this.props
        return stopLoadForensicSearch(engine, authToken, forensicSearch.id)
      })
      Promise.all(requests)
        .then(() => {
          this.onRefresh()
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  onDeleteAll = () => {
    this.setState({ showDeleteAllConfirm: true })
  }

  onDeleteAllCancel = () => {
    this.setState({ showDeleteAllConfirm: false })
  }

  onDeleteAllOK = () => {
    this.setState({ showDeleteAllConfirm: false })
    const forensicSearches = this.getForensicSearches()
    if (forensicSearches) {
      const requests = forensicSearches.map(forensicSearch => {
        const { engine, authToken } = this.props
        return deleteForensicSearch(engine, authToken, forensicSearch.id)
      })
      Promise.all(requests)
        .then(() => {
          this.onRefresh()
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  onDeleteForensicSearch = (forensicSearchId: string) => {
    this.setState({ showDeleteConfirm: true, forensicSearchId })
  }

  onDeleteCancel = () => {
    this.setState({ showDeleteConfirm: false, forensicSearchId: null })
  }

  onDeleteOK = () => {
    const { forensicSearchId } = this.state
    this.setState({ showDeleteConfirm: false, forensicSearchId: null })
    if (forensicSearchId !== null) {
      const { engine, authToken } = this.props
      deleteForensicSearch(engine, authToken, forensicSearchId)
        .then(() => {
          this.onRefresh()
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  onUpdateForensicSearchName = (forensicSearchId: string, name: string) => {
    this.setState({ forensicSearchId: null, forensicSearchName: null, isNameBeingEdited: false })
    if (forensicSearchId !== null && name !== this.state.forensicSearchName) {
      const { engine, authToken } = this.props
      putForensicSearch(engine, authToken, forensicSearchId, { name })
        .then(() => {
          this.onRefresh()
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  onStopLoadForensicSearch = (forensicSearchId: string) => {
    const { engine, authToken } = this.props
    stopLoadForensicSearch(engine, authToken, forensicSearchId)
      .then(() => {
        this.onRefresh()
      })
      .catch(error => {
        console.error(error)
      })
  }

  onSetHistorySearchOpen = (isHistorySearchOpen: boolean) => {
    this.setState({ isHistorySearchOpen })
  }

  onToggleForensicSearchHistory = () => {
    this.setState({ isHistoryOpen: !this.state.isHistoryOpen, isHistorySearchOpen: false })
  }

  onViewForensicSearch = (forensicSearchId: string) => {
    this.props.history.push(`${this.props.match.url}/${forensicSearchId}`)
  }

  onChangeView = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.props.dispatch(setForensicSearchesViewType(event.currentTarget.name))
    this.onRefresh()
  }

  onSort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
    this.sort(this.state.forensicSearches, sortBy, sortDirection)
    this.props.dispatch(setForensicSearchesSort(sortBy, sortDirection))
  }

  sort(
    forensicSearches: ForensicSearchProperties[] | null,
    sortBy: string,
    sortDirection: SortDirectionType
  ) {
    if (forensicSearches) {
      forensicSearches.sort((a, b) => {
        let result = 0

        const valueA = a[sortBy as keyof ForensicSearchProperties]
        const valueB = b[sortBy as keyof ForensicSearchProperties]

        if (typeof valueA === "string" && typeof valueB === "string") {
          result = collator.compare(valueA, valueB)
        } else if (typeof valueA === "number" && typeof valueB === "number") {
          if (valueA > valueB) {
            result = 1
          } else if (valueA < valueB) {
            result = -1
          }
        }

        if (result === 0 && a.creationTime !== undefined && b.creationTime !== undefined) {
          // Secondary sort on creation time and put more recent
          // searches at the top.
          result = collator.compare(b.creationTime, a.creationTime)
        }
        if (result === 0) {
          // Secondary sort on data start time and put searches
          // for older data at the bottom.
          result = collator.compare(a.startTime, b.startTime)
        }

        if (sortDirection === SortDirection.DESC) result = -result

        return result
      })
    }
  }

  onCancelUpdateNameChange = () => {
    this.setState({ forensicSearchId: null, forensicSearchName: null, isNameBeingEdited: false })
  }

  onShowSearchNameEditModal = (forensicSearchId: string, forensicSearchName: string) => {
    this.setState({ forensicSearchId, forensicSearchName, isNameBeingEdited: true })
  }

  cellRenderer = ({ dataKey, rowData }: TableCellProps) => {
    if (dataKey === "name") {
      const { userId, engineCapabilities } = this.props
      if (!engineCapabilities) return null
      const policies = engineCapabilities.userRights.policies
      const isUserOwner = userId === rowData.creatorSID
      const isViewEnabled =
        isUserOwner ||
        ((!engineCapabilities.capabilities.includes(
          EngineCapabilities.viewCapturesForensicSearchesACL
        ) ||
          policies.includes(EngineUserPolicies.viewForensicSearches)) &&
          (policies.includes(EngineUserPolicies.viewPackets) ||
            policies.includes(EngineUserPolicies.viewStats)))
      return rowData.openResult !== 0 || !isViewEnabled ? (
        rowData.name
      ) : (
        <Link to={getEngineForensicSearchUrl(rowData.id)}>{rowData.name}</Link>
      )
    }
    return formatProp(dataKey, rowData, { showLocalTime: this.props.showLocalTime })
  }

  commandCellRenderer = ({ rowData }: TableCellProps) => {
    const { userId, engineCapabilities } = this.props
    if (!engineCapabilities) return null

    const policies = engineCapabilities.userRights.policies
    const isUserOwner = userId === rowData.creatorSID
    const isViewEnabled =
      isUserOwner ||
      ((!engineCapabilities.capabilities.includes(
        EngineCapabilities.viewCapturesForensicSearchesACL
      ) ||
        policies.includes(EngineUserPolicies.viewForensicSearches)) &&
        (policies.includes(EngineUserPolicies.viewPackets) ||
          policies.includes(EngineUserPolicies.viewStats)))

    const isEditingOfNameEnabled =
      engineCapabilities &&
      engineCapabilities.capabilities.includes(EngineCapabilities.putForensicSearch)

    // make sure the user can delete forensic searches
    let canDeleteForensicSearch = true
    if (
      engineCapabilities &&
      engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL)
    ) {
      const policies = engineCapabilities.userRights.policies
      canDeleteForensicSearch =
        userId === rowData.creatorSID ||
        policies.includes(EngineUserPolicies.deleteForensicSearches)
    }

    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            <DropdownItem
              disabled={!isViewEnabled}
              onClick={this.onViewForensicSearch.bind(this, rowData.id)}
            >
              View
            </DropdownItem>
            {isEditingOfNameEnabled && (
              <DropdownItem
                onClick={this.onShowSearchNameEditModal.bind(this, rowData.id, rowData.name)}
              >
                Rename
              </DropdownItem>
            )}
            {rowData.status !== PeekFileViewStatus.PEEK_FILE_VIEW_STATUS_COMPLETE && (
              <DropdownItem onClick={this.onStopLoadForensicSearch.bind(this, rowData.id)}>
                Stop
              </DropdownItem>
            )}
            <DropdownItem
              disabled={!canDeleteForensicSearch}
              onClick={this.onDeleteForensicSearch.bind(this, rowData.id)}
            >
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  getForensicSearches = () => {
    let { forensicSearches } = this.state
    const { filter } = this.props
    if (forensicSearches && filter) {
      const lowerCaseFilter = filter.toLowerCase()
      forensicSearches = forensicSearches.filter(forensicSearch =>
        forensicSearch.name.toLowerCase().includes(lowerCaseFilter)
      )
    }
    return forensicSearches
  }

  render() {
    const {
      showDeleteAllConfirm,
      showDeleteConfirm,
      refreshInterval,
      forensicSearchId,
      isHistoryOpen,
      isHistorySearchOpen,
      fetchError,
      forensicSearchName,
      isNameBeingEdited,
    } = this.state
    const {
      dispatch,
      engineCapabilities,
      filter,
      userId,
      viewType,
      columns,
      sortBy,
      sortDirection,
    } = this.props
    if (!engineCapabilities) return null

    // Get the filtered list of forensic searches
    const forensicSearches = this.getForensicSearches()

    // Count is the total without any filtering
    const count = this.state.forensicSearches?.length ?? 0

    // Make sure the user can delete forensic searches
    let canDeleteForensicSearches = true
    if (
      engineCapabilities &&
      engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL)
    ) {
      let userOwnsAllForensicSearches = false
      if (forensicSearches) {
        userOwnsAllForensicSearches = forensicSearches.every(
          (fs: ForensicSearchProperties) => userId === fs.creatorSID
        )
      }
      const policies = engineCapabilities.userRights.policies
      canDeleteForensicSearches =
        userOwnsAllForensicSearches || policies.includes(EngineUserPolicies.deleteForensicSearches)
    }

    return (
      <ForensicSearchWrapper>
        <View>
          <Helmet title="Forensic Searches" />
          <BreadcrumbItem to={this.props.match.url} title="Forensic Searches" />
          {fetchError && (
            <Alert color="danger">
              {typeof fetchError === "string"
                ? fetchError
                : `${fetchError.code} ${fetchError.reason}`}
            </Alert>
          )}
          <Interval timeout={refreshInterval} enabled={true} callback={this.onRefresh} />
          <ViewHeader>
            <ViewHeaderTitle title="Forensic Searches" count={count} />
            <ViewHeaderButtons>
              <FilterBox
                aria-label="Search"
                placeholder="Search"
                onChange={this.onChangeFilter}
                value={filter}
              />
              <LightButton
                id="stop-all"
                disabled={(forensicSearches?.length ?? 0) === 0}
                onClick={this.onStopAll}
              >
                <FontAwesome name="stop" /> Stop All
              </LightButton>
              <LightDangerButton
                id="delete-all"
                disabled={(forensicSearches?.length ?? 0) === 0 || !canDeleteForensicSearches}
                onClick={this.onDeleteAll}
              >
                <FontAwesome name="trash-o" /> Delete All
              </LightDangerButton>
              {engineCapabilities &&
                engineCapabilities.capabilities.includes(
                  EngineCapabilities.forensicSearchHistory
                ) && (
                  <LightButton
                    id="history"
                    active={isHistoryOpen}
                    onClick={this.onToggleForensicSearchHistory}
                  >
                    <FontAwesome name="history" /> History
                  </LightButton>
                )}
              <ButtonGroup>
                <LightButton
                  id="list-view"
                  name="list"
                  aria-label="List view"
                  onClick={this.onChangeView}
                  active={viewType === "list"}
                >
                  <FontAwesome name="align-justify" />
                </LightButton>
                <UncontrolledTooltip placement="top" target="list-view">
                  List View
                </UncontrolledTooltip>
                <LightButton
                  id="table-view"
                  name="table"
                  aria-label="Table view"
                  onClick={this.onChangeView}
                  active={viewType === "table"}
                >
                  <FontAwesome name="table" />
                </LightButton>
                <UncontrolledTooltip placement="top" target="table-view">
                  Table View
                </UncontrolledTooltip>
              </ButtonGroup>
              <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefresh}>
                <FontAwesome name="refresh" />
              </LightButton>
              <UncontrolledTooltip placement="top" target="refresh">
                Refresh
              </UncontrolledTooltip>
            </ViewHeaderButtons>
          </ViewHeader>
          <ViewMaxWidth maxWidth={viewType === "list" ? undefined : "none"}>
            <ViewContent>
              {forensicSearches ? (
                viewType === "list" ? (
                  <ForensicSearchesList
                    engineCapabilities={engineCapabilities}
                    forensicSearches={forensicSearches}
                    userId={userId}
                    onDeleteForensicSearch={this.onDeleteForensicSearch}
                    onUpdateForensicSearchName={this.onUpdateForensicSearchName}
                    onStopLoadForensicSearch={this.onStopLoadForensicSearch}
                    showLocalTime={this.props.showLocalTime}
                  />
                ) : (
                  <OmniTable
                    data={forensicSearches}
                    rowHeight={31}
                    rowCount={forensicSearches.length}
                    columnDesc={columns}
                    cellRenderer={this.cellRenderer}
                    renderCommands={this.commandCellRenderer}
                    onShowDefaultColumns={this.onShowDefaultColumns}
                    onShowAllColumns={this.onShowAllColumns}
                    onToggleColumn={this.onToggleColumn}
                    sort={this.onSort}
                    sortBy={sortBy}
                    sortDirection={sortDirection}
                  />
                )
              ) : null}
            </ViewContent>
          </ViewMaxWidth>
          <Sidebar open={isHistoryOpen}>
            <SidebarBody open={isHistoryOpen}>
              <SidebarHeader>
                <SidebarTitle>Forensic Search History</SidebarTitle>
                <CloseButton onClick={() => this.onToggleForensicSearchHistory()} />
              </SidebarHeader>
              <ForensicSearchHistoryViewSidebarContent>
                <ForensicSearchHistoryView
                  dispatchFunc={dispatch}
                  engineCapabilities={engineCapabilities}
                  isHistorySearchOpen={isHistorySearchOpen}
                  showLocalTime={this.props.showLocalTime}
                  onSetHistorySearchOpen={this.onSetHistorySearchOpen}
                />
              </ForensicSearchHistoryViewSidebarContent>
            </SidebarBody>
          </Sidebar>
          {isNameBeingEdited && forensicSearchId && forensicSearchName && (
            <ForensicSearchEditNameModal
              title="Edit Forensic Search Name"
              searchName={forensicSearchName}
              id={forensicSearchId}
              onOk={this.onUpdateForensicSearchName}
              onCancel={this.onCancelUpdateNameChange}
              show={isNameBeingEdited}
            />
          )}
          {showDeleteAllConfirm && (
            <ConfirmationModal
              message="Are you sure you want to delete all forensic searches?"
              onNo={this.onDeleteAllCancel}
              onYes={this.onDeleteAllOK}
              show={showDeleteAllConfirm}
              title="Delete Forensic Searches"
            />
          )}
          {showDeleteConfirm && (
            <ConfirmationModal
              message="Are you sure you want to delete the forensic search?"
              onNo={this.onDeleteCancel}
              onYes={this.onDeleteOK}
              show={showDeleteConfirm}
              title="Delete Forensic Search"
            />
          )}
        </View>
      </ForensicSearchWrapper>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  engineCapabilities: getCapabilities(state) || null,
  filter: getForensicSearchesFilter(state) || "",
  showLocalTime: getShowLocalTime(state),
  userId: getUserId(state),
  viewType: getForensicSearchesViewType(state) || "list",
  columns: getForensicSearchesColumns(state) || defaultColumns,
  sortBy: getForensicSearchesSortBy(state) || "name",
  sortDirection: getForensicSearchesSortDirection(state) || SortDirection.ASC,
})

export default connect(mapStateToProps)(ForensicSearchesView)
