import * as React from "react"
import styled from "styled-components"
import FileSaver from "file-saver"
import FontAwesome from "react-fontawesome"
import { cloneDeep, isNumber, toNumber } from "lodash"
import { Redirect } from "react-router-dom"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { CenterContent } from "../common/Layout"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import ExpertDetails from "../ExpertDetails"
import ExpertSettingsModal, {
  eventFinderViews,
  expertResponseToExpertDescriptions,
  expertResponseToExpertLayers,
  expertResponseToExpertSettings,
  ExpertSettingsEx,
  expertSettingsToExpertRequests,
  queryEventFinder,
} from "../ExpertSettingsModal"
import { ExpertTable, ExpertTableColumn } from "../common/ExpertTable"
import { InsertNamesModal, InsertNameEntry } from "../InsertNamesModal"
import { LightButton, IconDropdownToggle, CloseButton } from "../common/Buttons"
import ExpertSearchPopover from "../common/ExpertSearchPopover"
import ExpertSearchResults from "../common/ExpertSearchPopover/ExpertSearchResults"
import { emptyExpertSearch, ExpertSearch } from "../common/ExpertSearchPopover/ExpertSearchTypes"
import PropTable from "../common/PropTable"
import { Spinner } from "../common/Spinner"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarTitle,
  SidebarContent,
} from "../common/Sidebar"
import {
  View,
  ViewContent,
  ViewHeader,
  ViewHeaderButtons,
  ViewHeaderPanel,
  ViewHeaderTitle,
} from "../common/View"
import {
  addExpertColumns,
  exportExpertToCSV,
  filterExpressionFromRowData,
  filterFromRowData,
  filterResultSetBySearch,
  formatExpertHeaderCountsProp,
  getExpertColumnName,
  getExpertHeaderCounts,
  getExpertValueFromRowData,
  isMSAEnabled,
  selectRelatedExpertRequestFromRowData,
} from "../../utils/expertUtils"
import { formatMediaSpec } from "../../utils/mediaSpec"
import {
  getEngine,
  getAuthToken,
  getExpertFlowsSearch,
  getNamesModificationTime,
  getShowAddressNames,
  getShowPortNames,
  getShowLocalTime,
  getExpertFlowsColumns,
  getExpertFlowsSortBy,
  getExpertFlowsSortDirection,
  getCapabilities,
  getUserId,
} from "../../store"
import { updateStatus } from "../../store/status"
import { setCurrentEngine } from "../../store/engines"
import { setSelectPacketsTask } from "../../store/selectPackets"
import { setExpertFlowsColumns, setExpertFlowsSort, setExpertFlowsSearch } from "../../store/ui"
import {
  getCaptureForensicSearchUrl,
  getEngineNewFilterUrl,
  getNewDistributedForensicSearchUrl,
} from "../../routes"
import {
  expertExecuteCFS,
  expertQueryCFS,
  postSelectRelatedExpertStart,
  resolveAddresses,
} from "../../api/api"
import {
  AddressResolverRequestEntry,
  ExpertDescription,
  ExpertLayerMapping,
  ExpertQueryResponse,
  ExpertSettings,
  ExpertValue,
  MediaSpec,
  RequestExpertExecute,
  RequestExpertQuery,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { ExpertColumn, ExpertView } from "../../api/types/expertTypes"

const defaultColumns: ExpertTableColumn[] = [
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_STREAM_ID.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_STREAM_ID),
    width: 76,
    flexGrow: 0,
    flexShrink: 0,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS),
    width: 150,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_PORT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_PORT),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY),
    width: 120,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_CITY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_CITY),
    width: 120,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_LATITUDE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_LATITUDE),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_LONGITUDE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_LONGITUDE),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS),
    width: 150,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_PORT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_PORT),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY),
    width: 120,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_CITY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_CITY),
    width: 120,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_LATITUDE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_LATITUDE),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_LONGITUDE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_LONGITUDE),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_PROBLEM_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_PROBLEM_COUNT),
    width: 90,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_PROTOCOL.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_PROTOCOL),
    width: 140,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_APPLICATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_APPLICATION),
    width: 140,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_HOP_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_HOP_COUNT),
    width: 60,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_PACKET_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_PACKET_COUNT),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_BYTE_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_BYTE_COUNT),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_BYTE_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_BYTE_COUNT),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_SENT_BYTE_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_SENT_BYTE_COUNT),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_START_TIME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_START_TIME),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_END_TIME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_END_TIME),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DURATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DURATION),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_WIRELESS_RETRY_PERCENT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_WIRELESS_RETRY_PERCENT),
    width: 80,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_THREE_WAY_HANDSHAKE_TIME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_THREE_WAY_HANDSHAKE_TIME),
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SERVER_NETWORK_DELAY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SERVER_NETWORK_DELAY),
    width: 160,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CLIENT_NETWORK_DELAY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CLIENT_NETWORK_DELAY),
    width: 160,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_NET_LATENCY_SAMPLE_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_NET_LATENCY_SAMPLE_COUNT),
    width: 160,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_NET_LATENCY_BEST.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_NET_LATENCY_BEST),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_NET_LATENCY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_NET_LATENCY),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_NET_LATENCY_WORST.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_NET_LATENCY_WORST),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_APP_LATENCY_SAMPLE_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_APP_LATENCY_SAMPLE_COUNT),
    width: 170,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_APP_LATENCY_BEST.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_APP_LATENCY_BEST),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_APP_LATENCY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_APP_LATENCY),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_APP_LATENCY_WORST.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_APP_LATENCY_WORST),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DELAY_SAMPLE_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DELAY_SAMPLE_COUNT),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DELAY_BEST.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DELAY_BEST),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DELAY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DELAY),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DELAY_WORST.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DELAY_WORST),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_SAMPLE_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_SAMPLE_COUNT),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_BEST.toString(),
    label: getExpertColumnName(
      ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_BEST
    ),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND.toString(),
    label: getExpertColumnName(
      ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND
    ),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey:
      ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_WORST.toString(),
    label: getExpertColumnName(
      ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_WORST
    ),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_SAMPLE_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_SAMPLE_COUNT),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_BEST.toString(),
    label: getExpertColumnName(
      ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_BEST
    ),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND.toString(),
    label: getExpertColumnName(
      ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND
    ),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey:
      ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_WORST.toString(),
    label: getExpertColumnName(
      ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_WORST
    ),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_TLS_VERSION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_TLS_VERSION),
    width: 80,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_BEFORE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_BEFORE),
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_AFTER.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_AFTER),
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_TLS_HANDSHAKE_LENGTH.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_TLS_HANDSHAKE_LENGTH),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_TCP_STATUS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_TCP_STATUS),
    width: 108,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MPLS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MPLS),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VLAN.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VLAN),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VXLAN_GPID.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VXLAN_GPID),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VXLAN_VNI.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VXLAN_VNI),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
] as const

const propList = [["Flows Analyzed", "Flows Recycled", "Events Detected", "Packets Dropped"]]

const CommandStrip = styled.div`
  display: flex;
`

const queryTemplate: RequestExpertQuery = {
  query: [
    {
      columnList: [],
      orderBy: [ExpertColumn.EXPERT_COLUMN_STREAM_ID],
      orderByAscending: true,
      view: ExpertView.EXPERT_VIEW_STREAM,
      viewSettings: {
        la: "\u2190",
        ra: "\u2192",
        ba: "\u2194",
        showAddressNames: true,
        showPortNames: true,
        showLocalTime: true,
      },
    },
    {
      columnList: [],
      view: ExpertView.EXPERT_VIEW_HEADER_COUNTERS,
    },
  ],
} as const

const queryDetailsTemplate: RequestExpertQuery = {
  query: [
    {
      columnList: [
        ExpertColumn.EXPERT_COLUMN_STREAM_ID,
        ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_CLIENT_PORT,
        ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_SERVER_PORT,
        ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT,
        ExpertColumn.EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT,
        ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_BYTE_COUNT,
        ExpertColumn.EXPERT_COLUMN_SERVER_SENT_BYTE_COUNT,
        ExpertColumn.EXPERT_COLUMN_CLIENT_START_TIME,
        ExpertColumn.EXPERT_COLUMN_SERVER_START_TIME,
        ExpertColumn.EXPERT_COLUMN_CLIENT_END_TIME,
        ExpertColumn.EXPERT_COLUMN_SERVER_END_TIME,
        ExpertColumn.EXPERT_COLUMN_CLIENT_HOP_COUNT,
        ExpertColumn.EXPERT_COLUMN_SERVER_HOP_COUNT,
        ExpertColumn.EXPERT_COLUMN_CLIENT_TCP_WINDOW_MIN,
        ExpertColumn.EXPERT_COLUMN_SERVER_TCP_WINDOW_MIN,
        ExpertColumn.EXPERT_COLUMN_CLIENT_TCP_WINDOW_MAX,
        ExpertColumn.EXPERT_COLUMN_SERVER_TCP_WINDOW_MAX,
        ExpertColumn.EXPERT_COLUMN_SERVER_NETWORK_DELAY,
        ExpertColumn.EXPERT_COLUMN_CLIENT_NETWORK_DELAY,
        ExpertColumn.EXPERT_COLUMN_NET_LATENCY_BEST,
        ExpertColumn.EXPERT_COLUMN_NET_LATENCY_WORST,
        ExpertColumn.EXPERT_COLUMN_NET_LATENCY,
        ExpertColumn.EXPERT_COLUMN_NET_LATENCY_SAMPLE_COUNT,
        ExpertColumn.EXPERT_COLUMN_APP_LATENCY_BEST,
        ExpertColumn.EXPERT_COLUMN_APP_LATENCY_WORST,
        ExpertColumn.EXPERT_COLUMN_APP_LATENCY,
        ExpertColumn.EXPERT_COLUMN_APP_LATENCY_SAMPLE_COUNT,
        ExpertColumn.EXPERT_COLUMN_DELAY_BEST,
        ExpertColumn.EXPERT_COLUMN_DELAY_WORST,
        ExpertColumn.EXPERT_COLUMN_DELAY,
        ExpertColumn.EXPERT_COLUMN_DELAY_SAMPLE_COUNT,
        ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_BEST,
        ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND_WORST,
        ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_BITS_PER_SECOND,
        ExpertColumn.EXPERT_COLUMN_THROUGHPUT_CLIENT_TO_SERVER_SAMPLE_COUNT,
        ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_BEST,
        ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND_WORST,
        ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_BITS_PER_SECOND,
        ExpertColumn.EXPERT_COLUMN_THROUGHPUT_SERVER_TO_CLIENT_SAMPLE_COUNT,
        ExpertColumn.EXPERT_COLUMN_PROBLEM_SUMMARY_LIST,
        ExpertColumn.EXPERT_COLUMN_TCP_STATUS,
        ExpertColumn.EXPERT_COLUMN_THREE_WAY_HANDSHAKE_TIME,
        ExpertColumn.EXPERT_COLUMN_TLS_VERSION,
        ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_BEFORE,
        ExpertColumn.EXPERT_COLUMN_TLS_CERT_VALIDITY_NOT_AFTER,
        ExpertColumn.EXPERT_COLUMN_TLS_HANDSHAKE_LENGTH,
        ExpertColumn.EXPERT_COLUMN_MPLS,
        ExpertColumn.EXPERT_COLUMN_VLAN,
        ExpertColumn.EXPERT_COLUMN_VXLAN_GPID,
        ExpertColumn.EXPERT_COLUMN_VXLAN_VNI,
      ],
      limitRowCount: 1,
      orderBy: [ExpertColumn.EXPERT_COLUMN_STREAM_ID],
      orderByAscending: true,
      view: ExpertView.EXPERT_VIEW_STREAM,
      viewSettings: {
        la: "\u2190",
        ra: "\u2192",
        ba: "\u2194",
        showAddressNames: true,
        showPortNames: true,
        showLocalTime: true,
      },
      where: {
        criteria: "parent",
        key: [
          {
            column: ExpertColumn.EXPERT_COLUMN_STREAM_ID,
            value: "1",
          },
        ],
      },
    },
  ],
} as const

type ExpertSettingsState = {
  expertSettings: ExpertSettingsEx
  expertLayers: ExpertLayerMapping[]
  expertDescriptions: ExpertDescription[]
}

const ExpertFlowsView = ({ captureProperties }: CaptureViewProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const { type, capId } = useParams<CaptureRouteParams>()
  const engine = useSelector(getEngine)
  const authToken = useSelector(getAuthToken)
  const userId = useSelector(getUserId)
  const engineCapabilities: ResponseGetEngineCapabilities | null = useSelector(getCapabilities)
  const namesModTime: string | undefined = useSelector(getNamesModificationTime)
  const showAddressNames: boolean = useSelector(getShowAddressNames)
  const showPortNames: boolean = useSelector(getShowPortNames)
  const showLocalTime: boolean = useSelector(getShowLocalTime)
  const columns: ExpertTableColumn[] = useSelector(getExpertFlowsColumns) || defaultColumns
  const sortBy: ExpertColumn =
    useSelector(getExpertFlowsSortBy) || ExpertColumn.EXPERT_COLUMN_STREAM_ID
  const sortDirection: SortDirectionType =
    useSelector(getExpertFlowsSortDirection) || SortDirection.ASC
  const [detailsResultSet, setDetailsResultSet] = React.useState<ExpertQueryResponse | null>(null)
  const [detailsFlowId, setDetailsFlowId] = React.useState<number>(0)
  const [detailsOpen, setDetailsOpen] = React.useState<boolean>(false)
  const [insertNameEntries, setInsertNameEntries] = React.useState<InsertNameEntry[] | null>(null)
  const [expertSettings, setExpertSettings] = React.useState<ExpertSettingsState | null>(null)
  const [expertSearchOpen, setExpertSearchOpen] = React.useState<boolean>(false)
  const expertSearch = (useSelector(getExpertFlowsSearch) as ExpertSearch) || emptyExpertSearch

  const query = useQuery({
    staleTime: 30000,
    queryKey: [type, capId, "expertflows"],
    queryFn: () => {
      const query = cloneDeep(queryTemplate)
      query.query[0].columnList = columns
        .filter(col => col.visible)
        .map(col => parseInt(col.dataKey, 10) as ExpertColumn)
      const columnList = query.query[0].columnList
      addExpertColumns(columnList, [
        ExpertColumn.EXPERT_COLUMN_TYPE,
        ExpertColumn.EXPERT_COLUMN_EVENT_SEVERITY_MAX,
        ExpertColumn.EXPERT_COLUMN_STREAM_ID,
        ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_CLIENT_PORT,
        ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_SERVER_PORT,
        ExpertColumn.EXPERT_COLUMN_APPLICATION,
        ExpertColumn.EXPERT_COLUMN_PROTOCOL,
        ExpertColumn.EXPERT_COLUMN_FLOW_TYPE,
        ExpertColumn.EXPERT_COLUMN_START_TIME,
        ExpertColumn.EXPERT_COLUMN_END_TIME,
        ExpertColumn.EXPERT_COLUMN_MPLS,
        ExpertColumn.EXPERT_COLUMN_VLAN,
        ExpertColumn.EXPERT_COLUMN_VXLAN_GPID,
        ExpertColumn.EXPERT_COLUMN_VXLAN_VNI,
      ])
      if (columnList.includes(ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY)) {
        columnList.push(ExpertColumn.EXPERT_COLUMN_CLIENT_COUNTRY_CODE)
      }
      if (columnList.includes(ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY)) {
        columnList.push(ExpertColumn.EXPERT_COLUMN_SERVER_COUNTRY_CODE)
      }
      if (query.query[0].orderBy !== undefined) {
        if (sortBy !== ExpertColumn.EXPERT_COLUMN_STREAM_ID) {
          query.query[0].orderBy = [sortBy, ExpertColumn.EXPERT_COLUMN_STREAM_ID]
        } else {
          query.query[0].orderBy = [sortBy]
        }
      }
      query.query[0].orderByAscending = sortDirection === SortDirection.ASC
      if (query.query[0].viewSettings) {
        query.query[0].viewSettings.showAddressNames = showAddressNames
        query.query[0].viewSettings.showPortNames = showPortNames
        query.query[0].viewSettings.showLocalTime = showLocalTime
      }
      return expertQueryCFS(engine, authToken, type, capId, query)
    },
  })

  const resultSet = query.data
    ? filterResultSetBySearch(query.data, 0, expertSearch, showLocalTime)
    : null

  React.useEffect(() => {
    setDetailsOpen(false)
    query.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namesModTime, showAddressNames, showPortNames, showLocalTime, columns, sortBy, sortDirection])

  const onExpertSettings = () => {
    const query: RequestExpertQuery = {
      query: eventFinderViews.map(view => {
        return { ...cloneDeep(queryEventFinder), view }
      }),
    }

    expertQueryCFS(engine, authToken, type, capId, query)
      .then(resultSet => {
        if (Array.isArray(resultSet.results)) {
          const expertDescriptions = expertResponseToExpertDescriptions(resultSet.results)
          const expertLayers = expertResponseToExpertLayers(resultSet.results)
          const expertSettings = expertResponseToExpertSettings(resultSet.results)
          if (expertSettings) {
            setExpertSettings({
              expertSettings,
              expertLayers,
              expertDescriptions,
            })
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const onExpertSettingsCancel = () => {
    setExpertSettings(null)
  }

  const onExpertSettingsOK = (settings: ExpertSettings) => {
    const request: RequestExpertExecute = {
      execute: expertSettingsToExpertRequests(settings),
    }

    expertExecuteCFS(engine, authToken, type, capId, request).finally(() => {
      setExpertSettings(null)
    })
  }

  const onExport = () => {
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]
    const csv = exportExpertToCSV(results, columns)
    if (csv !== undefined) {
      FileSaver.saveAs(new Blob([csv], { type: "text/plain;charset=utf8" }), "ExpertFlows.csv")
    }
  }

  const onSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
  }) => {
    dispatch(setExpertFlowsSort(parseInt(sortBy, 10) as ExpertColumn, sortDirection))
  }

  const onShowDefaultColumns = () => {
    dispatch(setExpertFlowsColumns(defaultColumns))
  }

  const onShowAllColumns = () => {
    const newColumns = cloneDeep(columns)
    newColumns.forEach(col => {
      col.visible = true
    })
    dispatch(setExpertFlowsColumns(newColumns))
  }

  const onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColumns = cloneDeep(columns)
    const col = newColumns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      dispatch(setExpertFlowsColumns(newColumns))
    }
  }

  const onFlowVisualizer = (rowData: ExpertValue[]) => {
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]
    const flowId = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_STREAM_ID
    )
    if (flowId != null && isNumber(flowId.value)) {
      history.push(`flow-visualizer/${flowId.value}`)
    }
  }

  const onSelectRelated = (command: string, rowData: ExpertValue[]) => {
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    const request = selectRelatedExpertRequestFromRowData(command, rowData, results.columnList)
    if (request) {
      postSelectRelatedExpertStart(engine, authToken, capId, request)
        .then(task => {
          if (task.taskId) {
            dispatch(setSelectPacketsTask({ type: "expert", taskId: task.taskId, progress: 0 }))
            history.push("packets")
          }
        })
        .catch(() => {
          console.error("Error during select related")
        })
    }
  }

  const onMSA = (rowData: ExpertValue[]) => {
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]
    const startTime = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_START_TIME
    )
    const endTime = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_END_TIME
    )
    const filter = filterExpressionFromRowData(rowData, results.columnList)
    if (startTime && endTime && filter) {
      dispatch(setCurrentEngine(null))
      history.push({
        pathname: getNewDistributedForensicSearchUrl(),
        state: { startTime: startTime.value, endTime: endTime.value, filter },
      })
    }
  }

  const onMakeFilter = (rowData: ExpertValue[]) => {
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]
    const filter = filterFromRowData(rowData, results.columnList)
    if (filter) {
      history.push({
        pathname: getEngineNewFilterUrl(),
        state: { filter },
      })
    }
  }

  const onInsertIntoNameTable = (rowData: ExpertValue[]) => {
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    const insertNameEntries: InsertNameEntry[] = []

    const clientAddr = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS
    )
    if (clientAddr && clientAddr.rendered && clientAddr.value) {
      insertNameEntries.push({
        title: "Client",
        entry: clientAddr.rendered,
        entryType: (clientAddr.value as MediaSpec).type,
      })
    }

    const serverAddr = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS
    )
    if (serverAddr && serverAddr.rendered && serverAddr.value) {
      insertNameEntries.push({
        title: "Server",
        entry: serverAddr.rendered,
        entryType: (serverAddr.value as MediaSpec).type,
      })
    }

    if (insertNameEntries.length > 0) {
      setInsertNameEntries(insertNameEntries)
    }
  }

  const onInsertIntoNameTableOK = () => {
    setInsertNameEntries(null)
    query.refetch()
  }

  const onInsertIntoNameTableCancel = () => {
    setInsertNameEntries(null)
  }

  const onResolveNames = (rowData: ExpertValue[]) => {
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    const entries: AddressResolverRequestEntry[] = []

    try {
      const clientAddr = getExpertValueFromRowData(
        rowData,
        results.columnList,
        ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS
      )
      if (clientAddr != null && clientAddr.value != null) {
        const clientAddrSpec = clientAddr.value as MediaSpec
        entries.push({
          entry: formatMediaSpec(clientAddrSpec),
          entryType: clientAddrSpec.type,
        })
      }
    } catch (e) {
      console.error(e)
    }

    try {
      const serverAddr = getExpertValueFromRowData(
        rowData,
        results.columnList,
        ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS
      )
      if (serverAddr != null && serverAddr.value != null) {
        const serverAddrSpec = serverAddr.value as MediaSpec
        entries.push({
          entry: formatMediaSpec(serverAddrSpec),
          entryType: serverAddrSpec.type,
        })
      }
    } catch (e) {
      console.error(e)
    }

    if (entries.length > 0) {
      resolveAddresses(engine, authToken, entries).catch(error => {
        console.error(error)
      })
      dispatch(updateStatus())
    }
  }

  const onDetailsOpen = (open: boolean) => {
    setDetailsOpen(open)
  }

  const onSearch = () => {
    setExpertSearchOpen(!expertSearchOpen)
  }

  const onSearchCancel = () => {
    setExpertSearchOpen(false)
  }

  const onSearchOK = (search: ExpertSearch) => {
    dispatch(setExpertFlowsSearch(search))
    setExpertSearchOpen(false)
  }

  const onClearSearch = () => {
    onSearchOK(emptyExpertSearch)
  }

  const onRowClick = ({ rowData }: { rowData: ExpertValue[] }) => {
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    const flowIdValue = getExpertValueFromRowData(
      rowData,
      results.columnList,
      ExpertColumn.EXPERT_COLUMN_STREAM_ID
    )
    if (flowIdValue != null && flowIdValue.value != null) {
      const flowId = toNumber(flowIdValue.value)
      const query = cloneDeep(queryDetailsTemplate)
      if (query.query[0].where !== undefined && query.query[0].where.key !== undefined) {
        query.query[0].where.key[0].value = flowId.toString()
      }
      if (query.query[0].viewSettings) {
        query.query[0].viewSettings.showAddressNames = showAddressNames
        query.query[0].viewSettings.showPortNames = showPortNames
        query.query[0].viewSettings.showLocalTime = showLocalTime
      }
      expertQueryCFS(engine, authToken, type, capId, query)
        .then(detailsResultSet => {
          if (detailsResultSet.results) {
            setDetailsResultSet(detailsResultSet)
            setDetailsFlowId(flowId)
            setDetailsOpen(true)
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const renderCommands = ({ rowData }: TableCellProps) => {
    if (!resultSet || !resultSet.results) return undefined
    const results = resultSet.results[0]

    // make sure the user can view packets for this capture or forensic search
    let canCreateForensicSearch = true
    let canUploadFiles = true
    let canViewPackets = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canCreateForensicSearch =
        !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL) ||
        policies.includes(EngineUserPolicies.createForensicSearch)
      canUploadFiles = policies.includes(EngineUserPolicies.uploadFiles)
      canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
    }

    const packetsDisabled = captureProperties === null || !captureProperties.packetBufferEnabled
    const msaDisabled =
      !isMSAEnabled(rowData, results.columnList) || !canUploadFiles || !canCreateForensicSearch

    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            <DropdownItem disabled={packetsDisabled} onClick={onFlowVisualizer.bind(this, rowData)}>
              Flow Visualizer
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={onSelectRelated.bind(this, "client", rowData)}
            >
              Select Related Packets by Client
            </DropdownItem>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={onSelectRelated.bind(this, "server", rowData)}
            >
              Select Related Packets by Server
            </DropdownItem>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={onSelectRelated.bind(this, "clientAndServer", rowData)}
            >
              Select Related Packets by Client &amp; Server
            </DropdownItem>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={onSelectRelated.bind(this, "port", rowData)}
            >
              Select Related Packets by Port
            </DropdownItem>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={onSelectRelated.bind(this, "flowId", rowData)}
            >
              Select Related Packets by Flow ID
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem disabled={msaDisabled} onClick={onMSA.bind(this, rowData)}>
              Multi-Segment Analysis
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={onMakeFilter.bind(this, rowData)}>Make Filter</DropdownItem>
            <DropdownItem onClick={onInsertIntoNameTable.bind(this, rowData)}>
              Insert Into Name Table
            </DropdownItem>
            <DropdownItem onClick={onResolveNames.bind(this, rowData)}>Resolve Names</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  // make sure the user can modify captures and view stats for this capture or forensic search
  let canModifyCapture = true
  if (captureProperties && engineCapabilities) {
    const isUserOwner = userId === captureProperties.creatorSID
    const policies = engineCapabilities.userRights.policies
    canModifyCapture = isUserOwner || policies.includes(EngineUserPolicies.modifyCaptures)
    const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
    if (!canViewStats) {
      return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
    }
  }

  const expertHeaderCounts = getExpertHeaderCounts(resultSet)
  return (
    <View>
      <BreadcrumbItem to={match.url} title="Expert Flows" />
      <ViewHeader>
        <ViewHeaderTitle title="Expert Flows" />
        <ViewHeaderButtons>
          <LightButton id="searchExpertFlows" onClick={onSearch}>
            Search
          </LightButton>
          {expertSearchOpen && (
            <ExpertSearchPopover
              isOpen={true}
              search={expertSearch}
              target="searchExpertFlows"
              onOK={onSearchOK}
              onCancel={onSearchCancel}
            />
          )}
          <LightButton aria-label="Export" id="export" onClick={onExport}>
            <FontAwesome name="download" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="export">
            Export
          </UncontrolledTooltip>
          <LightButton aria-label="Expert Settings" id="expertSettings" onClick={onExpertSettings}>
            <FontAwesome name="cog" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="expertSettings">
            Configure Expert Settings
          </UncontrolledTooltip>
          <LightButton
            aria-label="Refresh"
            id="refresh"
            onClick={() => {
              query.refetch()
            }}
          >
            <FontAwesome name="refresh" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="refresh">
            Refresh
          </UncontrolledTooltip>
        </ViewHeaderButtons>
      </ViewHeader>
      <ExpertSearchResults search={expertSearch} onClearSearch={onClearSearch} />
      {resultSet ? (
        <>
          <ViewHeaderPanel>
            <PropTable
              data={expertHeaderCounts}
              propList={propList}
              formatProp={formatExpertHeaderCountsProp}
            />
          </ViewHeaderPanel>
          <ViewContent>
            <ExpertTable
              resultSet={resultSet}
              renderCommands={renderCommands}
              columnDesc={columns}
              onShowDefaultColumns={onShowDefaultColumns}
              onShowAllColumns={onShowAllColumns}
              onToggleColumn={onToggleColumn}
              onRowClick={onRowClick}
              sort={onSort}
              sortBy={sortBy.toString()}
              sortDirection={sortDirection}
              showLocalTime={showLocalTime}
            />
          </ViewContent>
        </>
      ) : (
        <CenterContent>
          <Spinner />
        </CenterContent>
      )}
      <Sidebar open={detailsOpen}>
        <SidebarBody open={detailsOpen}>
          <SidebarHeader>
            <SidebarTitle>{`Flow ${detailsFlowId} Details`}</SidebarTitle>
            <CloseButton onClick={() => onDetailsOpen(false)} />
          </SidebarHeader>
          <SidebarContent>
            <ExpertDetails
              captureProperties={captureProperties}
              engineCapabilities={engineCapabilities}
              resultSet={detailsResultSet}
              type={type}
              userId={userId}
            />
          </SidebarContent>
        </SidebarBody>
      </Sidebar>
      {insertNameEntries && (
        <InsertNamesModal
          engine={engine}
          authToken={authToken}
          entries={insertNameEntries}
          onOK={onInsertIntoNameTableOK}
          onCancel={onInsertIntoNameTableCancel}
        />
      )}
      {expertSettings && (
        <ExpertSettingsModal
          expertDescriptions={expertSettings.expertDescriptions}
          expertLayers={expertSettings.expertLayers}
          expertSettings={expertSettings.expertSettings.settings}
          maxStreamCountMax={expertSettings.expertSettings.maxStreamCountMax}
          readOnly={type !== "captures" || !canModifyCapture}
          type={type}
          onCancel={onExpertSettingsCancel}
          onOK={onExpertSettingsOK}
          engine={engine}
          authToken={authToken}
        />
      )}
    </View>
  )
}

export default ExpertFlowsView
