import * as React from "react"
import styled from "styled-components"
import { rgba } from "polished"

// Currently limited use with collapsing panel on the left and
// on the right a thing with 8px padding.

const DividerStyle = styled.div<{ isOpen: boolean; rightPositioned?: boolean }>`
  flex-shrink: 0;
  position: relative;
  border-left: ${props => (props.rightPositioned ? "0" : "2px")} solid transparent;
  border-right: ${props => (props.rightPositioned ? "2px" : "0")} solid transparent;
  width: 15px;
  transition: border 0.5s ease;
  margin-right: ${props => (props.rightPositioned ? "0" : "-8px")};
  margin-left: ${props => (props.rightPositioned ? "-8px" : "0")};
  z-index: 1;

  &:hover {
    border-color: ${props =>
      props.isOpen ? props.theme.primaryButtonHoverBackgroundColor : "transparent"};
  }
`

const DividerHandle = styled.div<{ isOpen: boolean; rightPositioned?: boolean }>`
  position: absolute;
  left: -2px;
  top: 50%;
  width: ${props => (props.isOpen ? "15px" : "12px")};
  height: 63px;
  transform: ${props => (props.rightPositioned ? "rotate(180deg)" : "rotate(0deg)")}
    translateY(-50%);
  background: ${props =>
    props.isOpen ? "none" : rgba(props.theme.primaryButtonBackgroundColor, 0.2)};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
`

const DividerButtonOpen = styled.div`
  position: absolute;
  width: 15px;
  height: 24px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  &::before {
    position: absolute;
    height: 14px;
    display: block;
    width: 2px;
    left: 7px;
    top: 0;
    background: ${props => props.theme.primaryButtonBackgroundColor};
    border-radius: 12px;
    content: "";
    transform-origin: 1px 13px 0;
    transition: transform 0.2s ease;
  }

  &::after {
    position: absolute;
    height: 14px;
    display: block;
    width: 2px;
    left: 7px;
    top: 12px;
    background: ${props => props.theme.primaryButtonBackgroundColor};
    border-radius: 12px;
    content: "";
    transform-origin: 1px 1px 0;
    transition: transform 0.2s ease;
  }

  ${DividerStyle}:hover &::before {
    transform: rotate(20deg) translateX(-2px);
  }

  ${DividerStyle}:hover &::after {
    transform: rotate(-20deg) translateX(-2px);
  }
`

const DividerButtonClosed = styled.div`
  position: absolute;
  width: 12px;
  height: 24px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  &::before {
    position: absolute;
    height: 14px;
    display: block;
    width: 2px;
    left: 7px;
    top: 0;
    background: ${props => props.theme.primaryButtonBackgroundColor};
    border-radius: 12px;
    content: "";
    transform-origin: 1px 13px 0;
    transform: rotate(-20deg);
  }

  &::after {
    position: absolute;
    height: 14px;
    display: block;
    width: 2px;
    left: 7px;
    top: 12px;
    background: ${props => props.theme.primaryButtonBackgroundColor};
    border-radius: 12px;
    content: "";
    transform-origin: 1px 1px 0;
    transform: rotate(20deg);
  }
`

type DividerProps = {
  isOpen: boolean
  onToggle: () => void
  rightPositioned?: boolean
}

const Divider: React.FC<DividerProps> = ({ isOpen, onToggle, rightPositioned }) => {
  return (
    <DividerStyle isOpen={isOpen} rightPositioned={rightPositioned}>
      <DividerHandle isOpen={isOpen} onClick={() => onToggle()} rightPositioned={rightPositioned}>
        {isOpen ? <DividerButtonOpen /> : <DividerButtonClosed />}
      </DividerHandle>
    </DividerStyle>
  )
}

export default Divider
