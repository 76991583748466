import * as React from "react"
import cn from "classnames"
import styled from "styled-components"
import { mix } from "polished"
import { useSelector } from "react-redux"
import FontAwesome from "react-fontawesome"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../common/Dropdown"
import { LightDropdownToggle } from "../common/Buttons"
import { isDarkTheme } from "../../store"

const HexPaneOuter = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const HexPaneInner = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: ${props => props.theme.tableBackgroundColor};
  border-right: 1px solid ${props => props.theme.tableBorderColor};
  border-bottom: 1px solid ${props => props.theme.tableBorderColor};
`

const HexPaneStyle = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  outline: none;
`

const Offsets = styled.pre`
  flex-shrink: 0;
  padding: 8px;
  margin: 0;
  overflow: visible;
  font-size: 1rem;
  color: ${props => props.theme.textMutedColor};
  background: ${props => props.theme.tableHeaderBackgroundColor};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: calc(4ch + 16px);
`

const HexData = styled.pre`
  flex-shrink: 0;
  padding: 8px;
  margin: 0;
  overflow: visible;
  font-size: 1rem;
  color: ${props => props.theme.textColor};
  cursor: default;
  outline: none;

  & .byte {
    display: inline-block;
    /*border: 1px solid transparent;*/
    padding: 0 0.25rem;
  }

  & .byte.selected {
    color: ${props => props.theme.selectedColor};
    background-color: ${props => props.theme.selectedBackgroundColor};
    border-top-color: ${props => props.theme.selectedBackgroundColor};
    border-bottom-color: ${props => props.theme.selectedBackgroundColor};
  }

  & .byte.selected.selstart {
    border-left-color: ${props => props.theme.selectedBackgroundColor};
  }

  & .byte.selected.selend {
    border-right-color: ${props => props.theme.selectedBackgroundColor};
  }
`

const Ascii = styled.pre`
  flex-shrink: 0;
  padding: 8px;
  margin: 0;
  overflow: visible;
  font-size: 1rem;
  color: ${props => props.theme.textColor};
  cursor: default;
  outline: none;

  & .char {
    display: inline-block;
    /*border: 1px solid transparent;*/
  }

  & .char.selected {
    color: ${props => props.theme.selectedColor};
    background-color: ${props => props.theme.selectedBackgroundColor};
    border-top-color: ${props => props.theme.selectedBackgroundColor};
    border-bottom-color: ${props => props.theme.selectedBackgroundColor};
  }

  & .char.selected.selstart {
    border-left-color: ${props => props.theme.selectedBackgroundColor};
  }

  & .char.selected.selend {
    border-right-color: ${props => props.theme.selectedBackgroundColor};
  }
`

function zeroPad(num: string) {
  const s = "0" + num
  return s.substr(s.length - 2)
}

function printCharFromCharCode(ch: number) {
  return ch >= 0x20 && ch <= 0x7e ? String.fromCharCode(ch) : "."
}

function getColors(isDarkTheme: boolean, color: string) {
  if (isDarkTheme) {
    return [mix(0.3, color, "#fff"), mix(0.5, color, "#000")]
  } else {
    return [color, mix(0.25, color, "#fff")]
  }
}

export type ColorBlocks = {
  offset: number
  size: number
  color: string
}

export type HexPaneProps = {
  packetData: number[] | null
  bytesPerRow?: number
  offsets?: "decimal" | "hex"
  setOffsets?: (offsets: "decimal" | "hex") => void
  showColors?: boolean
  setShowColors?: (show: boolean) => void
  colorBlocks?: ColorBlocks[]
  selection?: number[]
  onClickOffset?: (offset: number) => void
}

const HexPane = ({
  packetData,
  bytesPerRow,
  offsets,
  setOffsets,
  showColors,
  setShowColors,
  colorBlocks,
  selection,
  onClickOffset,
}: HexPaneProps) => {
  const isDark = useSelector(isDarkTheme)
  const hexDataRef = React.useRef<HTMLPreElement | null>(null)

  React.useEffect(() => {
    if (hexDataRef.current != null && selection != null && selection.length > 0) {
      const byte = hexDataRef.current.querySelector(`.byte[data-offset="${selection[0]}"]`)
      if (byte != null) {
        byte.scrollIntoView({ block: "nearest" })
      }
    }
  }, [selection])

  if (packetData == null || bytesPerRow == null) {
    return (
      <HexPaneOuter>
        <HexPaneInner />
      </HexPaneOuter>
    )
  }

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onClickOffset != null) {
      const offset = event.currentTarget.dataset.offset
      if (offset != null) {
        onClickOffset(Number(offset))
      }
    }
  }

  const getByteColor = (offset: number): string | undefined => {
    if (colorBlocks != null) {
      for (const block of colorBlocks) {
        if (offset >= block.offset && offset < block.offset + block.size) {
          return block.color
        }
      }
    }
    return undefined
  }

  const getSel = (offset: number) => {
    const sel = {
      selected: false,
      selstart: false,
      selend: false,
    }
    if (Array.isArray(selection) && selection.length === 2) {
      sel.selected = offset >= selection[0] && offset < selection[1]
      sel.selstart = offset === selection[0]
      sel.selend = offset + 1 === selection[1]
    }
    return sel
  }

  const offsetData: React.ReactNode[] = []
  for (let i = 0; i < packetData.length; i += bytesPerRow) {
    let offset = ""
    if (offsets === "decimal") {
      offset = i.toString(10)
    } else {
      offset = i.toString(16).toUpperCase()
    }
    offsetData.push(
      <div key={i}>
        <span>{offset}</span>
      </div>
    )
  }

  const hexData: React.ReactNode[] = []
  let hexLine: React.ReactNode[] = []
  for (let i = 0; i < packetData.length; i++) {
    const sel = getSel(i)
    const style: React.CSSProperties = {}
    if (showColors && !sel.selected) {
      const color = getByteColor(i)
      if (color != null) {
        const colors = getColors(isDark, color)
        style.color = colors[0]
        style.backgroundColor = colors[1]
      }
    }
    hexLine.push(
      <span className={cn("byte", sel)} key={i} data-offset={i} style={style} onClick={onClick}>
        {zeroPad(packetData[i].toString(16).toUpperCase())}
      </span>
    )
    if ((i + 1) % bytesPerRow === 0) {
      hexData.push(
        <div key={hexData.length} className="line">
          {hexLine}
        </div>
      )
      hexLine = []
    }
  }
  if (hexLine.length > 0) {
    hexData.push(
      <div key={hexData.length} className="line">
        {hexLine}
      </div>
    )
  }

  const textData: React.ReactNode[] = []
  let textLine: React.ReactNode[] = []
  for (let i = 0; i < packetData.length; i++) {
    const sel = getSel(i)
    const style: React.CSSProperties = {}
    if (showColors && !sel.selected) {
      const color = getByteColor(i)
      if (color != null) {
        const colors = getColors(isDark, color)
        style.color = colors[0]
        style.backgroundColor = colors[1]
      }
    }
    const char = printCharFromCharCode(packetData[i])
    textLine.push(
      <span className={cn("char", sel)} key={i} data-offset={i} style={style} onClick={onClick}>
        {char}
      </span>
    )
    if ((i + 1) % bytesPerRow === 0) {
      textData.push(
        <div key={textData.length} className="line">
          {textLine}
        </div>
      )
      textLine = []
    }
  }
  if (textLine.length > 0) {
    textData.push(
      <div key={textData.length} className="line">
        {textLine}
      </div>
    )
  }

  return (
    <HexPaneOuter>
      <HexPaneInner>
        <HexPaneStyle>
          <Offsets>{offsetData}</Offsets>
          <HexData ref={hexDataRef} tabIndex={0}>
            {hexData}
          </HexData>
          <Ascii tabIndex={0}>{textData}</Ascii>
        </HexPaneStyle>
        <UncontrolledDropdown
          style={{
            position: "absolute",
            top: "8px",
            right: "25px",
          }}
        >
          <LightDropdownToggle
            aria-label="Decode options"
            style={{
              boxShadow: "0 3px 5px -1px rgba(0, 0, 0, 0.18)",
              padding: 0,
              width: "23px",
              height: "23px",
            }}
          >
            <FontAwesome name="chevron-down" fixedWidth />
          </LightDropdownToggle>
          <DropdownMenu end>
            {offsets != null && setOffsets != null ? (
              <>
                <DropdownItem active={offsets === "decimal"} onClick={() => setOffsets("decimal")}>
                  Decimal Offsets
                </DropdownItem>
                <DropdownItem active={offsets === "hex"} onClick={() => setOffsets("hex")}>
                  Hexadecimal Offsets
                </DropdownItem>
              </>
            ) : null}
            {showColors != null && setShowColors != null ? (
              <>
                <DropdownItem divider />
                <DropdownItem active={showColors} onClick={() => setShowColors(!showColors)}>
                  Show Colors
                </DropdownItem>
              </>
            ) : null}
          </DropdownMenu>
        </UncontrolledDropdown>
      </HexPaneInner>
    </HexPaneOuter>
  )
}

HexPane.defaultProps = {
  packetData: null,
  bytesPerRow: 16,
  offsets: "decimal",
  showColors: true,
}

export default HexPane
