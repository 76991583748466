import {
  Batch,
  BatchItem,
  CreateOptions,
  useAbortBatch,
  useBatchStartListener,
  useBatchFinalizeListener,
  useItemFinishListener,
  useItemProgressListener,
  useItemStartListener,
} from "@rpldy/uploady"
import React from "react"
import styled from "styled-components"
import { SecondaryButton } from "../Buttons"
import { Panel } from "../Panel"
import { WaitModal } from "../WaitModal"
import { formatInteger } from "../../../utils/formatUtils"
import { UploadType } from "../../../utils/uploadUtils"
import Alert from "../Alert"

const UploadProgressPanel = styled(Panel)`
  display: flex;
  align-items: center;
  justify-content: stretch;

  & > * + * {
    margin-left: 0.5rem;
  }
`

const UploadList = styled.ul`
  margin: 0;
  padding: 0;
`

const UploadListItem = styled.li`
  padding: 0;
  display: block;
`

type Upload = {
  name: string
  progress: number
}

const UploadProgress = ({
  start,
  finish,
  finalize,
  uploadType,
}: {
  start: (batch: Batch, options: CreateOptions) => void | boolean
  finish: (batchItem: BatchItem) => void
  finalize: (batch: Batch) => void
  uploadType: UploadType
}) => {
  const abortBatch = useAbortBatch()
  const [uploads, setUploads] = React.useState<Record<string, Upload>>({})
  const [batchId, setBatchId] = React.useState<string | null>(null)
  const [hasFailed, setFailed] = React.useState<boolean>(false)

  useBatchStartListener((batch: Batch, options: CreateOptions) => {
    if (uploadType !== UploadType.UPLOAD_TYPE_NONE) {
      setBatchId(batch.id)
      return start(batch, options)
    }
  })

  useItemStartListener((batchItem: BatchItem) => {
    if (uploadType !== UploadType.UPLOAD_TYPE_NONE) {
      setUploads({})
    }
  })

  useItemProgressListener((batchItem: BatchItem) => {
    if (uploadType !== UploadType.UPLOAD_TYPE_NONE) {
      if (batchItem.completed != null) {
        const upload = uploads[batchItem.id] || {
          name: batchItem.url || batchItem.file.name,
          progress: 0,
        }

        if (upload.progress !== batchItem.completed) {
          upload.progress = batchItem.completed
          setUploads({
            ...uploads,
            [batchItem.id]: upload,
          })
        }
      }
    }
  })

  useItemFinishListener((batchItem: BatchItem) => {
    if (uploadType !== UploadType.UPLOAD_TYPE_NONE) {
      if (uploadType !== UploadType.UPLOAD_TYPE_UPLOAD_PACKETS) {
        setUploads({})
      }
    }
    finish(batchItem)
  })

  useBatchFinalizeListener((batch: Batch) => {
    if (uploadType !== UploadType.UPLOAD_TYPE_NONE) {
      if (batch.loaded === 0 && batch.state !== "cancelled") {
        setFailed(true)
      }
      setBatchId(null)
      finalize(batch)
    }
  })

  const onCancel = () => {
    if (batchId !== null) {
      abortBatch(batchId)
      setUploads({})
    }
  }

  if (hasFailed) {
    return (
      <Alert color="danger" fade={true} toggle={() => setFailed(false)}>
        Upload failed. The file size may be too large or the connection has dropped.
      </Alert>
    )
  }

  const entries = Object.entries(uploads)
  if (entries.length === 0) return null

  if (
    uploadType === UploadType.UPLOAD_TYPE_OPEN_FILE ||
    uploadType === UploadType.UPLOAD_TYPE_DRAG_DROP
  ) {
    const message = entries
      .map(([id, { name, progress }]) => `Uploading File... ${formatInteger(progress)}%`)
      .join(", ")
    return (
      <WaitModal message={message} show={true} onCancel={onCancel} title="Opening Packet File" />
    )
  } else if (uploadType === UploadType.UPLOAD_TYPE_UPLOAD_PACKETS) {
    const allFinished = entries.every(([id, { name, progress }]) => progress === 100)
    return (
      <UploadProgressPanel>
        <UploadList style={{ flexGrow: 1 }}>
          {entries.map(([id, { name, progress }]) => (
            <UploadListItem key={id}>
              Uploading &lsquo;{name}&rsquo;... {formatInteger(progress)}%
            </UploadListItem>
          ))}
        </UploadList>
        {allFinished ? (
          <SecondaryButton onClick={() => setUploads({})}>Close</SecondaryButton>
        ) : (
          <SecondaryButton onClick={() => onCancel()}>Cancel</SecondaryButton>
        )}
      </UploadProgressPanel>
    )
  } else {
    return null
  }
}

export default UploadProgress
