import styled from "styled-components"

export const SuccessText = styled.span`
  color: ${props => props.theme.successColor};
  font-weight: 500;
`

export const SuccessTextWithBackground = styled.span`
  color: ${props => props.theme.successColor};
  background-color: ${props => props.theme.successBackgroundColor};
`
