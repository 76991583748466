import * as React from "react"
import { toNumber } from "lodash"
import { Form, FormGroup } from "reactstrap"
import { Input } from "../common/Input"
import { CheckGroup, Label } from "../common/Form"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import { LightButton, PrimaryButton, SecondaryButton } from "../common/Buttons"
import { isPortValid } from "./utils"
import { MSAProjectMapping } from "../../api/types"

type MappingModalProps = {
  mapping: MSAProjectMapping
  onOK: (mapping: MSAProjectMapping) => void
  onCancel: () => void
}

const MappingModal = ({ mapping, onOK, onCancel }: MappingModalProps) => {
  const [address1, setAddress1] = React.useState(mapping.address1)
  const [address2, setAddress2] = React.useState(mapping.address2)
  const [port1, setPort1] = React.useState(mapping.port1)
  const [port2, setPort2] = React.useState(mapping.port2)
  const [portsEnabled, setPortsEnabled] = React.useState(
    isPortValid(mapping.port1) || isPortValid(mapping.port2)
  )

  const isAddress1Valid = address1.length > 0
  const isAddress2Valid = address2.length > 0
  const isPort1Valid = isPortValid(port1)
  const isPort2Valid = isPortValid(port1)
  let isValid = isAddress1Valid && isAddress2Valid
  if (portsEnabled) {
    isValid = isValid && isPort1Valid && isPort2Valid
  }

  return (
    <Modal isOpen={true}>
      <ModalHeader toggle={onCancel}>Edit Mapping</ModalHeader>
      <ModalBody>
        <Form
          id="mapping-form"
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            event.stopPropagation()
            onOK({
              ...mapping,
              address1,
              address2,
              port1,
              port2,
            })
          }}
          noValidate
        >
          <FormGroup row noMargin>
            <FormGroup className="col-md-8">
              <Label for="mapping-address1">From Address</Label>
              <Input
                type="text"
                id="mapping-address1"
                value={address1}
                invalid={!isAddress1Valid}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setAddress1(event.target.value)
                }
              />
            </FormGroup>
            <FormGroup className="col-md-4">
              <Label for="mapping-port1" disabled={!portsEnabled}>
                From Port
              </Label>
              <Input
                type="number"
                id="mapping-port1"
                value={port1}
                invalid={portsEnabled && !isPort1Valid}
                disabled={!portsEnabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPort1(toNumber(event.target.value))
                }
                min={1}
                max={65535}
                step={1}
              />
            </FormGroup>
          </FormGroup>
          <FormGroup row noMargin>
            <FormGroup className="col-md-8">
              <Label for="mapping-address2">To Address</Label>
              <Input
                type="text"
                id="mapping-address2"
                value={address2}
                invalid={!isAddress2Valid}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setAddress2(event.target.value)
                }
              />
            </FormGroup>
            <FormGroup className="col-md-4">
              <Label for="mapping-port2" disabled={!portsEnabled}>
                To Port
              </Label>
              <Input
                type="number"
                id="mapping-port2"
                value={port2}
                invalid={portsEnabled && !isPort2Valid}
                disabled={!portsEnabled}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPort2(toNumber(event.target.value))
                }
                min={1}
                max={65535}
                step={1}
              />
            </FormGroup>
          </FormGroup>
          <FormGroup row noMargin>
            <FormGroup className="col-md-8">
              <LightButton
                size="sm"
                id="mapping-swap"
                onClick={() => {
                  const newAddress1 = address2
                  const newPort1 = port2
                  const newAddress2 = address1
                  const newPort2 = port1
                  setAddress1(newAddress1)
                  setPort1(newPort1)
                  setAddress2(newAddress2)
                  setPort2(newPort2)
                }}
              >
                Swap From/To
              </LightButton>
            </FormGroup>
            <FormGroup className="col-md-4">
              <CheckGroup
                type="checkbox"
                id="mapping-ports-enabled"
                checked={portsEnabled}
                onChange={() => {
                  setPortsEnabled(!portsEnabled)
                }}
              >
                Enable ports
              </CheckGroup>
            </FormGroup>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter style={{ paddingTop: "1rem" }}>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" form="mapping-form" disabled={!isValid}>
          OK
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  )
}

export default MappingModal
