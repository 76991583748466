// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto
// https://dev.to/halan/4-ways-of-symmetric-cryptography-and-javascript-how-to-aes-with-javascript-3o1b

const passwordPrefix = "g+X=e6kqfg"

const toBase64 = (buffer: Uint8Array) => btoa(String.fromCharCode.apply(null, Array.from(buffer)))

const fromBase64 = (buffer: string) => Uint8Array.from(atob(buffer), c => c.charCodeAt(0))

const PBKDF2 = async (
  password: string,
  salt: Uint8Array,
  iterations: number = 100000,
  length: number = 256,
  hash: string = "SHA-256",
  algorithm: string = "AES-CBC"
) => {
  const keyMaterial = await crypto.subtle.importKey(
    "raw",
    new TextEncoder().encode(password),
    { name: "PBKDF2" },
    false,
    ["deriveKey"]
  )

  return await crypto.subtle.deriveKey(
    {
      name: "PBKDF2",
      salt,
      iterations,
      hash,
    },
    keyMaterial,
    { name: algorithm, length },
    false,
    ["encrypt", "decrypt"]
  )
}

export async function encrypt(password: string, text: string): Promise<string> {
  let salt = new Uint8Array(16)
  let iv = new Uint8Array(16)

  // Encode the text
  const encodedText = new TextEncoder().encode(text)

  let encrypted: Uint8Array
  if (crypto.subtle) {
    // Generate Salt and IV
    salt = crypto.getRandomValues(salt)
    iv = crypto.getRandomValues(iv)

    // Create the key
    const key = await PBKDF2(passwordPrefix + password, salt)

    // Encrypt the text
    const encryptedText = await crypto.subtle.encrypt({ name: "AES-CBC", iv }, key, encodedText)
    encrypted = new Uint8Array(encryptedText)
  } else {
    //console.warn("webcrypto not available!")

    // Text is not actually encrypted, and Salt and IV are all zeroes
    encrypted = encodedText
  }

  // Combine the Salt, IV, and Encrypted Data
  const combined = new Uint8Array(salt.length + iv.length + encrypted.length)
  combined.set(salt)
  combined.set(iv, salt.length)
  combined.set(encrypted, salt.length + iv.length)

  // Base64 the combined result
  return toBase64(combined)
}

export async function decrypt(password: string, text: string): Promise<string> {
  // Un-base64 the combined data
  const combined = fromBase64(text)

  // Extract the Salt, IV, and Encrypted Data
  const salt = combined.slice(0, 16)
  const iv = combined.slice(16, 16 + 16)
  const encrypted = combined.slice(16 + 16)

  // Check for no encryption (Salt and IV all zero)
  if (salt.every(v => v === 0) && iv.every(v => v === 0)) {
    return new TextDecoder().decode(encrypted)
  }

  // Check for webcrypto available
  if (!crypto.subtle) {
    throw Error("decryption not available")
  }

  // Create the key
  const key = await PBKDF2(passwordPrefix + password, salt)

  // Decrypt the text
  const encodedText = await crypto.subtle.decrypt({ name: "AES-CBC", iv }, key, encrypted)

  // Unencode the result
  return new TextDecoder().decode(encodedText)
}
