import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Switch, Route, Redirect } from "react-router-dom"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import UpgradeBar from "../UpgradeBar"
import EngineNav from "../EngineNav"
import EngineLogin from "../EngineLogin"
import ActivationView from "../ActivationView"
import EngineHomeView from "../EngineHomeView"
import CapturesView from "../CapturesView"
import ForensicsView from "../ForensicsView"
import FilesView from "../FilesView"
import ForensicSearchesView from "../ForensicSearchesView"
import EventsView from "../EventsView"
import AdaptersView from "../AdaptersView"
import AdapterOptionsView from "../AdapterOptionsView"
import HardwareProfileView from "../AdapterOptionsView/HardwareProfileView"
import Admin from "../Admin"
import Capture from "../Capture"
import EngineTokensView from "../EngineTokensView"
import PluginEngineView from "../PluginEngineView"
import {
  getEnginesUrl,
  getEngineUrl,
  getEngineHomeUrl,
  ENGINE_PATH,
  ENGINE_HOME_PATH,
  ENGINE_CAPTURES_PATH,
  ENGINE_NEW_CAPTURE_PATH,
  ENGINE_CAPTURE_OPTIONS_PATH,
  CAPTURE_FORENSIC_SEARCH_PATH,
  ENGINE_FORENSICS_PATH,
  ENGINE_FORENSICS_CAPTURE_SESSION_PATH,
  ENGINE_FILES_PATH,
  ENGINE_FORENSIC_SEARCHES_PATH,
  ENGINE_EVENTS_PATH,
  ENGINE_ADAPTERS_PATH,
  ENGINE_ADAPTER_PATH,
  ENGINE_ADAPTER_NEW_HARDWARE_PROFILE_PATH,
  ENGINE_ADAPTER_HARDWARE_PROFILE_PATH,
  ENGINE_ACTIVATE_PATH,
  ENGINE_CONFIGURE_PATH,
  ENGINE_TOKENS_PATH,
  ENGINE_SETTINGS_PATH,
  ENGINE_ADMIN_PATH,
  ENGINE_PLUGIN_PATH,
} from "../../routes"
import {
  getServer,
  getServerAuthToken,
  getServerRefreshToken,
  getCurrentEngine,
  getEnginesModificationTime,
  getEngine,
  getAuthToken,
  getAuthInProgress,
  getAuthError,
  getTempAuthToken,
  getTwoFactorAuth,
  getEngineType,
  getEngineVersion,
  getEngineFileVersion,
  getEngineName,
  getEngineLicensed,
  getEngineLicenseExpired,
  getEngineSerialNumber,
  getEngineCustomSettings,
  AppDispatch,
} from "../../store"
import { updateEngines } from "../../store/engines"
import { isJWT, putEngine } from "../../api/api"
import { Engine } from "../../api/types"
import { logIn, setEngine } from "../../store/auth"
import { decrypt } from "../../utils/crypto"
import { defaultEngineName, isUsingGroupAuthentication } from "../../utils/engineUtils"

const CaptureOptionsView = React.lazy(() => import("../CaptureOptionsView"))
const EngineConfigView = React.lazy(() => import("../EngineConfigView"))
const Settings = React.lazy(() => import("../Settings"))

const EngineNameUpdater = () => {
  const dispatch: AppDispatch = useDispatch()
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const currentEngine = useSelector(getCurrentEngine)
  const enginesModTime = useSelector(getEnginesModificationTime)
  const engine = useSelector(getEngine)
  const authToken = useSelector(getAuthToken)
  const engineName = useSelector(getEngineName)
  const updateModTime = React.useRef<string | null>(null)

  // Check engine name and update engine list if necessary.
  if (
    authToken != null &&
    engineName != null &&
    currentEngine != null &&
    enginesModTime !== updateModTime.current &&
    engine !== server &&
    engineName !== currentEngine.remoteName
  ) {
    updateModTime.current = enginesModTime
    const newEngine: Engine = { ...currentEngine, remoteName: engineName }
    putEngine(server, serverAuthToken, newEngine).finally(() => {
      dispatch(updateEngines())
    })
  }

  return null
}

const EngineSession = () => {
  const dispatch: AppDispatch = useDispatch()
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const serverRefreshToken = useSelector(getServerRefreshToken)
  const currentEngine = useSelector(getCurrentEngine)
  const engine = useSelector(getEngine)
  const authToken = useSelector(getAuthToken)
  const authInProgress = useSelector(getAuthInProgress)
  const authError = useSelector(getAuthError)
  const tempAuthToken = useSelector(getTempAuthToken)
  const twoFactorAuth = useSelector(getTwoFactorAuth)
  const engineType = useSelector(getEngineType)
  const engineVersion = useSelector(getEngineVersion)
  const engineFileVersion = useSelector(getEngineFileVersion)
  const engineName = useSelector(getEngineName)
  const licensed = useSelector(getEngineLicensed)
  const licenseExpired = useSelector(getEngineLicenseExpired)
  const serialNumber = useSelector(getEngineSerialNumber)
  const engineCustomSettings = useSelector(getEngineCustomSettings)

  React.useEffect(() => {
    if (currentEngine == null) {
      if (authToken !== serverAuthToken) {
        dispatch(setEngine({ engine: server, authToken: serverAuthToken }))
      }
    } else if (!authToken && !authInProgress && !authError && !tempAuthToken && !twoFactorAuth) {
      if (currentEngine.username.length !== 0 && currentEngine.password.length !== 0) {
        decrypt(currentEngine.username, currentEngine.password)
          .then(password => {
            dispatch(
              logIn(`${server}/engines/${currentEngine.id}`, {
                username: currentEngine.username,
                password: password,
              })
            )
          })
          .catch(error => {
            console.error("Error decrypting password", error)
          })
      } else if (isUsingGroupAuthentication(currentEngine) && isJWT(serverAuthToken)) {
        dispatch(setEngine({ engine, authToken: serverAuthToken }))
      }
    }
  }, [
    currentEngine,
    dispatch,
    server,
    serverAuthToken,
    serverRefreshToken,
    engine,
    authToken,
    authInProgress,
    authError,
    tempAuthToken,
    twoFactorAuth,
  ])

  // Get the engine display name.
  const engineDisplayName = currentEngine
    ? currentEngine.name || engineName || currentEngine.remoteName || defaultEngineName
    : engineName || defaultEngineName

  // TODO: get engine capabilities and status here

  let content: React.ReactNode
  if (!authToken || !engineName) {
    if (currentEngine) {
      content = (
        <EngineLogin
          engine={`${server}/engines/${currentEngine.id}`}
          engineName={engineDisplayName}
          engineHost={currentEngine.host}
          username={currentEngine.username}
          password={currentEngine.password}
        />
      )
    }
  } else {
    if (!licensed || licenseExpired) {
      content = (
        <ActivationView
          engine={engine}
          authToken={authToken}
          engineVersion={engineVersion}
          serialNumber={serialNumber}
          nextUrl={getEngineHomeUrl()}
        />
      )
    } else {
      content = (
        <>
          {engineCustomSettings !== 2 ? (
            <UpgradeBar engineType={engineType} engineVersion={engineFileVersion} />
          ) : null}
          <EngineNav />
          <React.Suspense fallback={<div />}>
            <Switch>
              <Redirect exact path={ENGINE_PATH} to={getEngineHomeUrl()} />
              <Route exact path={ENGINE_HOME_PATH} component={EngineHomeView} />
              <Route exact path={ENGINE_CAPTURES_PATH} component={CapturesView} />
              <Route exact path={ENGINE_NEW_CAPTURE_PATH} component={CaptureOptionsView} />
              <Route exact path={ENGINE_CAPTURE_OPTIONS_PATH} component={CaptureOptionsView} />
              <Route path={CAPTURE_FORENSIC_SEARCH_PATH} component={Capture} />
              <Route exact path={ENGINE_FORENSICS_PATH} component={ForensicsView} />
              <Route exact path={ENGINE_FORENSICS_CAPTURE_SESSION_PATH} component={ForensicsView} />
              <Route exact path={ENGINE_FILES_PATH} component={FilesView} />
              <Route exact path={ENGINE_FORENSIC_SEARCHES_PATH} component={ForensicSearchesView} />
              <Route exact path={ENGINE_EVENTS_PATH} component={EventsView} />
              <Route exact path={ENGINE_ADAPTERS_PATH} component={AdaptersView} />
              <Route exact path={ENGINE_ADAPTER_PATH} component={AdapterOptionsView} />
              <Route
                exact
                path={ENGINE_ADAPTER_NEW_HARDWARE_PROFILE_PATH}
                component={HardwareProfileView}
              />
              <Route
                exact
                path={ENGINE_ADAPTER_HARDWARE_PROFILE_PATH}
                component={HardwareProfileView}
              />
              <Route path={ENGINE_ACTIVATE_PATH}>
                <ActivationView
                  engine={engine}
                  authToken={authToken}
                  engineVersion={engineVersion}
                  serialNumber={serialNumber}
                  nextUrl={getEngineHomeUrl()}
                />
              </Route>
              <Route path={ENGINE_SETTINGS_PATH} component={Settings} />
              <Route path={ENGINE_ADMIN_PATH} component={Admin} />
              <Route exact path={ENGINE_CONFIGURE_PATH} component={EngineConfigView} />
              <Route exact path={ENGINE_TOKENS_PATH} component={EngineTokensView} />
              <Route exact path={ENGINE_PLUGIN_PATH} component={PluginEngineView} />
            </Switch>
          </React.Suspense>
        </>
      )
    }
  }

  return (
    <>
      <EngineNameUpdater />
      <BreadcrumbItem to={getEnginesUrl()} title="Engines" />
      <BreadcrumbItem to={getEngineUrl()} title={engineDisplayName} />
      {content}
    </>
  )
}

export default React.memo(EngineSession)
