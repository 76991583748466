import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { View, ViewContent } from "../common/View"
import Interval from "../common/Interval"
import { Dashboard } from "../Dashboard/Dashboard"
import CallSummaryWidget from "../Dashboard/CallSummaryWidget"
import CallQualityDistributionWidget from "../Dashboard/CallQualityDistributionWidget"
import CallQualityWidget from "../Dashboard/CallQualityWidget"
import CallQualityByCodecWidget from "../Dashboard/CallQualityByCodecWidget"
import CallVolumeByCodecWidget from "../Dashboard/CallVolumeByCodecWidget"
import CallUtilizationWidget from "../Dashboard/CallUtilizationWidget"
import { getEngine, getAuthToken, getCapabilities, getUserId } from "../../store"
import { fetchCFSStatistics } from "../../api/api"
import {
  CallStatistics,
  CallStatisticsObject,
  CallStatisticsObjectCallCodecQualityCodec,
  CallStatisticsObjectCallCodecQualityCodecSample,
  CallStatisticsObjectCallQualitySample,
  HistoryStatistics,
  RequestGetStatisticsStatistic,
  ResponseGetEngineCapabilities,
  SummaryStatistics,
  SummaryStatisticsObject,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { getCaptureForensicSearchUrl } from "../../routes"

type VoIPDashboardViewProps = RouteComponentProps<CaptureRouteParams> &
  CaptureViewProps & {
    engine: string
    authToken: string
    engineCapabilities: ResponseGetEngineCapabilities | null
    userId: string
  }

type VoIPDashboardViewState = {
  callSummary: any | null
  callQualityDistribution: any | null
  callQuality: any | null
  callQualityByCodec: any | null
  callVolumeByCodec: any | null
  callUtilization: any | null
  networkUtilization: any | null
}

class VoIPDashboardView extends React.Component<VoIPDashboardViewProps, VoIPDashboardViewState> {
  state: VoIPDashboardViewState = {
    callSummary: null,
    callQualityDistribution: null,
    callQuality: null,
    callQualityByCodec: null,
    callVolumeByCodec: null,
    callUtilization: null,
    networkUtilization: null,
  }

  componentDidMount() {
    this.onRefresh()
  }

  onRefresh = () => {
    this.onRefreshSummaryStatistics()
    this.onRefreshCallStatistics()
    this.onRefreshTrafficHistoryStatistics()
  }

  onRefreshSummaryStatistics = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSStatistics<SummaryStatistics>(
      engine,
      authToken,
      type,
      capId,
      "summary" as RequestGetStatisticsStatistic
    )
      .then((summaryStatistics: SummaryStatistics) => {
        let summary: SummaryStatisticsObject | null = null
        if (summaryStatistics && summaryStatistics.summary) {
          summary = summaryStatistics.summary
        }
        if (summary && summary.snapshots) {
          const snapshot = summary.snapshots.find(
            snapshot => summary !== null && snapshot.id === summary.currentSnapshotId
          )
          if (snapshot) {
            let callSummary: any = {}
            snapshot.items.forEach(item => {
              switch (item.label) {
                case "Total Calls":
                  callSummary.totalCalls = item.value
                  break
                case "Current Calls":
                  callSummary.currentCalls = item.value
                  break
                case "Open Calls":
                  callSummary.openCalls = item.value
                  break
                case "Closed Calls":
                  callSummary.closedCalls = item.value
                  break
                case "Average Call Duration":
                  callSummary.avgCallDuration = item.value
                  break
                case "Total Media Packet Loss %":
                  callSummary.totalMediaPacketLossPercent = item.value
                  break
                case "Voice Packet Loss %":
                  callSummary.voicePacketLossPercent = item.value
                  break
                case "Video Packet Loss %":
                  callSummary.videoPacketLossPercent = item.value
                  break
                case "Audio Packet Loss %":
                  callSummary.audioPacketLossPercent = item.value
                  break
                case "MOS-CQ":
                  callSummary.mosCQ = item.value
                  break
                case "MOS-A":
                  callSummary.mosA = item.value
                  break
                case "MOS-V":
                  callSummary.mosV = item.value
                  break
                default:
                  break
              }
            })
            if (callSummary.totalCalls === 0) {
              callSummary = null
            }
            this.setState({ callSummary })
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onRefreshCallStatistics = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSStatistics<CallStatistics>(
      engine,
      authToken,
      type,
      capId,
      "call" as RequestGetStatisticsStatistic
    )
      .then((callStatistics: CallStatistics) => {
        let call: CallStatisticsObject | null = null
        if (callStatistics && callStatistics.call) {
          call = callStatistics.call
        }
        let callQualityDistribution: any | null = null
        let callQuality: any | null = null
        let callQualityByCodec: any | null = null
        let callVolumeByCodec: any | null = null
        let callUtilization: any | null = null
        if (call && call.qualityDistribution) {
          const { qualityDistribution } = call
          callQualityDistribution = {
            allCalls: [
              {
                name: "Good",
                calls: qualityDistribution.allCalls.good,
                fill: "#75d015",
                stroke: "rgb(123,184,59)",
              },
              {
                name: "Fair",
                calls: qualityDistribution.allCalls.fair,
                fill: "#ffdf00",
                stroke: "rgb(195,195,48)",
              },
              {
                name: "Poor",
                calls: qualityDistribution.allCalls.poor,
                fill: "#ffa126",
                stroke: "rgb(203,154,88)",
              },
              {
                name: "Bad",
                calls: qualityDistribution.allCalls.bad,
                fill: "#ff1a1a",
                stroke: "rgb(195,47,47)",
              },
            ],
            openCalls: [
              {
                name: "Good",
                calls: qualityDistribution.openCalls.good,
                fill: "#75d015",
                stroke: "rgb(123,184,59)",
              },
              {
                name: "Fair",
                calls: qualityDistribution.openCalls.fair,
                fill: "#ffdf00",
                stroke: "rgb(195,195,48)",
              },
              {
                name: "Poor",
                calls: qualityDistribution.openCalls.poor,
                fill: "#ffa126",
                stroke: "rgb(203,154,88)",
              },
              {
                name: "Bad",
                calls: qualityDistribution.openCalls.bad,
                fill: "#ff1a1a",
                stroke: "rgb(195,47,47)",
              },
            ],
          }

          const totalAllCalls = callQualityDistribution.allCalls.reduce(
            (sum: number, d: any) => sum + d.calls,
            0
          )
          if (totalAllCalls > 0) {
            callQualityDistribution.allCalls.forEach((d: any) => {
              d.pct = (d.calls * 100.0) / totalAllCalls
              d.totalCalls = totalAllCalls
            })
          }

          const totalOpenCalls = callQualityDistribution.openCalls.reduce(
            (sum: number, d: any) => sum + d.calls,
            0
          )
          if (totalOpenCalls > 0) {
            callQualityDistribution.openCalls.forEach((d: any) => {
              d.pct = (d.calls * 100.0) / totalOpenCalls
              d.totalCalls = totalOpenCalls
            })
          }

          if (totalAllCalls === 0 && totalOpenCalls === 0) {
            callQualityDistribution = null
          }
        }

        if (call && call.callQuality && call.callQuality.length > 0) {
          callQuality = call.callQuality[0]
          callQuality.interval *= 1000
          callQuality.startTime = Date.parse(callQuality.startTime)

          // Look for non-zero data.
          if (
            !callQuality.samples.find(
              (s: CallStatisticsObjectCallQualitySample) =>
                s.good || s.fair || s.poor || s.bad || s.unknown
            )
          ) {
            callQuality = null
          }
        }

        if (call && call.callCodecQuality && call.callCodecQuality.length > 0) {
          const callCodecQuality = call.callCodecQuality[0]
          if (
            callCodecQuality.codecs &&
            callCodecQuality.codecs.length > 0 &&
            callCodecQuality.codecs[0].samples &&
            callCodecQuality.codecs[0].samples.length > 0
          ) {
            const sampleCount = callCodecQuality.codecs.reduce(
              (count: number, codecs: any) => Math.max(count, codecs.samples.length),
              0
            )

            callQualityByCodec = {}
            callQualityByCodec.interval = callCodecQuality.interval * 1000
            callQualityByCodec.startTime = Date.parse(callCodecQuality.startTime)
            callQualityByCodec.codecs = callCodecQuality.codecs
              .filter(
                (codec: CallStatisticsObjectCallCodecQualityCodec) =>
                  !codec.samples.every(
                    (sample: CallStatisticsObjectCallCodecQualityCodecSample) =>
                      sample.averageMOS === 0
                  )
              )
              .map(codec => codec.name)
            callQualityByCodec.data = []
            callQualityByCodec.data.length = sampleCount
            for (let j = 0; j < sampleCount; j++) {
              callQualityByCodec.data[j] = {}
            }
            for (let i = 0; i < callCodecQuality.codecs.length; i++) {
              const codec = callCodecQuality.codecs[i]
              for (let j = 0; j < codec.samples.length; j++) {
                const mos = codec.samples[j].averageMOS
                callQualityByCodec.data[j][codec.name] = mos !== 0 ? mos / 100 : undefined
              }
            }

            callVolumeByCodec = {}
            callVolumeByCodec.interval = callCodecQuality.interval * 1000
            callVolumeByCodec.startTime = Date.parse(callCodecQuality.startTime)
            callVolumeByCodec.codecs = callCodecQuality.codecs.map(codec => codec.name)
            callVolumeByCodec.data = []
            callVolumeByCodec.data.length = sampleCount
            for (let j = 0; j < sampleCount; j++) {
              callVolumeByCodec.data[j] = {}
            }
            for (let i = 0; i < callCodecQuality.codecs.length; i++) {
              const codec = callCodecQuality.codecs[i]
              for (let j = 0; j < codec.samples.length; j++) {
                callVolumeByCodec.data[j][codec.name] = codec.samples[j].calls
              }
            }
          }
        }

        if (call && call.utilization && call.utilization.length > 0) {
          callUtilization = {
            startTime: Date.parse(call.utilization[0].startTime),
            interval: call.utilization[0].interval * 1000,
            data: call.utilization[0].samples.map((mbps: number) => {
              return { mbps: mbps }
            }),
          }
        }

        this.setState({
          callQualityDistribution,
          callQuality,
          callQualityByCodec,
          callVolumeByCodec,
          callUtilization,
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  onRefreshTrafficHistoryStatistics = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSStatistics<HistoryStatistics>(
      engine,
      authToken,
      type,
      capId,
      "history" as RequestGetStatisticsStatistic
    )
      .then((history: HistoryStatistics) => {
        if (history && history.history && history.history.length > 0) {
          const historyData = history.history[0]
          if (historyData.samples && historyData.samples.length > 0) {
            this.setState({
              networkUtilization: {
                startTime: Date.parse(historyData.startTime),
                interval: historyData.interval * 1000,
                data: historyData.samples.map((bytesPerSec: number) => {
                  return { mbps: (bytesPerSec * 8) / 1000000.0 }
                }),
              },
            })
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  render() {
    const { captureProperties, engineCapabilities, userId } = this.props
    const {
      callSummary,
      callQualityDistribution,
      callQuality,
      callQualityByCodec,
      callVolumeByCodec,
      callUtilization,
      networkUtilization,
    } = this.state
    const { type, capId } = this.props.match.params

    // make sure the user can view stats for this capture or forensic search
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
      if (!canViewStats) {
        return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
      }
    }

    return (
      <View>
        <BreadcrumbItem to={this.props.match.url} title="Voice & Video Dashboard" />
        <Interval timeout={10000} enabled={true} callback={this.onRefresh} />
        <ViewContent>
          <Dashboard>
            <CallSummaryWidget callSummary={callSummary} />
            <CallQualityDistributionWidget callQualityDistribution={callQualityDistribution} />
            <CallQualityWidget callQuality={callQuality} />
            <CallQualityByCodecWidget callQualityByCodec={callQualityByCodec} />
            <CallVolumeByCodecWidget callVolumeByCodec={callVolumeByCodec} />
            <CallUtilizationWidget
              callUtilization={callUtilization}
              networkUtilization={networkUtilization}
            />
          </Dashboard>
        </ViewContent>
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  engineCapabilities: getCapabilities(state) || null,
  userId: getUserId(state),
})

export default connect(mapStateToProps)(VoIPDashboardView)
