import * as React from "react"
import styled from "styled-components"
import FileSaver from "file-saver"
import FontAwesome from "react-fontawesome"
import { cloneDeep, isNumber, isString } from "lodash"
import { Redirect } from "react-router-dom"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { CenterContent } from "../common/Layout"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import ExpertSettingsModal, {
  eventFinderViews,
  expertResponseToExpertDescriptions,
  expertResponseToExpertLayers,
  expertResponseToExpertSettings,
  ExpertSettingsEx,
  expertSettingsToExpertRequests,
  queryEventFinder,
} from "../ExpertSettingsModal"
import { ExpertTable, ExpertTableColumn } from "../common/ExpertTable"
import { IconInformational, IconMinor, IconMajor, IconSevere } from "../common/Icons"
import { LightButton, IconDropdownToggle } from "../common/Buttons"
import { Spinner } from "../common/Spinner"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import { View, ViewContent, ViewHeader, ViewHeaderTitle, ViewHeaderButtons } from "../common/View"
import {
  exportExpertToCSV,
  getExpertColumnName,
  getExpertValueFromRowData,
} from "../../utils/expertUtils"
import { formatInteger } from "../../utils/formatUtils"
import {
  getEngine,
  getAuthToken,
  getNamesModificationTime,
  getShowAddressNames,
  getShowPortNames,
  getShowLocalTime,
  getExpertEventLogColumns,
  getExpertEventLogSortBy,
  getExpertEventLogSortDirection,
  getCapabilities,
  getUserId,
} from "../../store"
import {
  setExpertEventLogColumns,
  setExpertEventLogSort,
  setPacketsSelection,
  setPacketsDecodePacketNumber,
} from "../../store/ui"
import { expertExecuteCFS, expertQueryCFS } from "../../api/api"
import {
  ExpertDescription,
  ExpertLayerMapping,
  ExpertSettings,
  ExpertValue,
  RequestExpertExecute,
  RequestExpertQuery,
  ResultsSelectRelatedResponse,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { ExpertColumn, ExpertSeverity, ExpertView } from "../../api/types/expertTypes"
import { getCaptureForensicSearchUrl } from "../../routes"

const defaultColumns: ExpertTableColumn[] = [
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_EVENT_SEVERITY_MAX.toString(),
    label: "",
    listLabel: "Severity",
    width: 24,
    flexGrow: 0,
    flexShrink: 0,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_EVENT_TIME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_EVENT_TIME),
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_PROTOCOL_LAYER.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_PROTOCOL_LAYER),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MESSAGE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MESSAGE),
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SOURCE_PORT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SOURCE_PORT),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DEST_PORT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DEST_PORT),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_STREAM_ID.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_STREAM_ID),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_REQUEST_ID.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_REQUEST_ID),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CALL_ID.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CALL_ID),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_FLOW_INDEX.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_FLOW_INDEX),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
] as const

const CommandStrip = styled.div`
  display: flex;
`

const EventsViewCounters = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
`

const EventsViewCounter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & + & {
    margin-left: 1rem;
  }
`

const queryTemplate: RequestExpertQuery = {
  query: [
    {
      columnList: [],
      orderBy: [
        ExpertColumn.EXPERT_COLUMN_EVENT_TIME,
        ExpertColumn.EXPERT_COLUMN_EVENT_SERIAL_NUMBER,
      ],
      orderByAscending: true,
      view: ExpertView.EXPERT_VIEW_EVENT_LOG,
      viewSettings: {
        la: "\u2190",
        ra: "\u2192",
        ba: "\u2194",
        showAddressNames: true,
        showPortNames: true,
        showLocalTime: true,
      },
      where: {
        criteria: "severity",
        severity: [],
      },
    },
    {
      columnList: [],
      view: ExpertView.EXPERT_VIEW_HEADER_COUNTERS,
    },
  ],
} as const

type ExpertSettingsState = {
  expertSettings: ExpertSettingsEx
  expertLayers: ExpertLayerMapping[]
  expertDescriptions: ExpertDescription[]
}

const ExpertEventLogView = ({ captureProperties }: CaptureViewProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const { type, capId } = useParams<CaptureRouteParams>()
  const engine = useSelector(getEngine)
  const authToken = useSelector(getAuthToken)
  const userId = useSelector(getUserId)
  const engineCapabilities: ResponseGetEngineCapabilities | null = useSelector(getCapabilities)
  const namesModTime: string | undefined = useSelector(getNamesModificationTime)
  const showAddressNames: boolean = useSelector(getShowAddressNames)
  const showPortNames: boolean = useSelector(getShowPortNames)
  const showLocalTime: boolean = useSelector(getShowLocalTime)
  const columns: ExpertTableColumn[] = useSelector(getExpertEventLogColumns) || defaultColumns
  const sortBy: ExpertColumn =
    useSelector(getExpertEventLogSortBy) || ExpertColumn.EXPERT_COLUMN_EVENT_TIME
  const sortDirection: SortDirectionType =
    useSelector(getExpertEventLogSortDirection) || SortDirection.ASC
  const [showInformational, setShowInformational] = React.useState(true)
  const [showMinor, setShowMinor] = React.useState(true)
  const [showMajor, setShowMajor] = React.useState(true)
  const [showSevere, setShowSevere] = React.useState(true)
  const [expertSettings, setExpertSettings] = React.useState<ExpertSettingsState | null>(null)

  const query = useQuery({
    staleTime: 30000,
    queryKey: [type, capId, "experteventlog"],
    queryFn: () => {
      const query = cloneDeep(queryTemplate)
      const queryEventLog = query.query[0]
      queryEventLog.columnList = columns
        .filter(col => col.visible)
        .map(col => parseInt(col.dataKey, 10) as ExpertColumn)
      queryEventLog.columnList.push(ExpertColumn.EXPERT_COLUMN_EVENT_SERIAL_NUMBER)
      if (!queryEventLog.columnList.includes(ExpertColumn.EXPERT_COLUMN_EVENT_TIME)) {
        queryEventLog.columnList.push(ExpertColumn.EXPERT_COLUMN_EVENT_TIME)
      }
      if (!queryEventLog.columnList.includes(ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER)) {
        queryEventLog.columnList.push(ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER)
      }
      if (!queryEventLog.columnList.includes(ExpertColumn.EXPERT_COLUMN_OTHER_PACKET_NUMBER)) {
        queryEventLog.columnList.push(ExpertColumn.EXPERT_COLUMN_OTHER_PACKET_NUMBER)
      }
      if (!queryEventLog.columnList.includes(ExpertColumn.EXPERT_COLUMN_PROTOSPEC)) {
        queryEventLog.columnList.push(ExpertColumn.EXPERT_COLUMN_PROTOSPEC)
      }
      if (queryEventLog.orderBy !== undefined) {
        queryEventLog.orderBy[0] = sortBy
      }
      queryEventLog.orderByAscending = sortDirection === SortDirection.ASC
      if (query.query[0].viewSettings) {
        query.query[0].viewSettings.showAddressNames = showAddressNames
        query.query[0].viewSettings.showPortNames = showPortNames
        query.query[0].viewSettings.showLocalTime = showLocalTime
      }
      if (queryEventLog.where !== undefined && queryEventLog.where.severity !== undefined) {
        if (showInformational) {
          queryEventLog.where.severity.push(ExpertSeverity.EXPERT_SEVERITY_INFORMATIONAL)
        }
        if (showMinor) {
          queryEventLog.where.severity.push(ExpertSeverity.EXPERT_SEVERITY_MINOR)
        }
        if (showMajor) {
          queryEventLog.where.severity.push(ExpertSeverity.EXPERT_SEVERITY_MAJOR)
        }
        if (showSevere) {
          queryEventLog.where.severity.push(ExpertSeverity.EXPERT_SEVERITY_SEVERE)
        }
      }
      return expertQueryCFS(engine, authToken, type, capId, query)
    },
  })

  const resultSet = query.data ?? null

  React.useEffect(() => {
    query.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    namesModTime,
    showAddressNames,
    showPortNames,
    showLocalTime,
    columns,
    sortBy,
    sortDirection,
    showInformational,
    showMinor,
    showMajor,
    showSevere,
  ])

  const onExpertSettings = () => {
    const query: RequestExpertQuery = {
      query: eventFinderViews.map(view => {
        return { ...cloneDeep(queryEventFinder), view }
      }),
    }

    expertQueryCFS(engine, authToken, type, capId, query)
      .then(resultSet => {
        if (Array.isArray(resultSet.results)) {
          const expertDescriptions = expertResponseToExpertDescriptions(resultSet.results)
          const expertLayers = expertResponseToExpertLayers(resultSet.results)
          const expertSettings = expertResponseToExpertSettings(resultSet.results)
          if (expertSettings) {
            setExpertSettings({
              expertSettings,
              expertLayers,
              expertDescriptions,
            })
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const onExpertSettingsCancel = () => {
    setExpertSettings(null)
  }

  const onExpertSettingsOK = (settings: ExpertSettings) => {
    const request: RequestExpertExecute = {
      execute: expertSettingsToExpertRequests(settings),
    }

    expertExecuteCFS(engine, authToken, type, capId, request).finally(() => {
      setExpertSettings(null)
    })
  }

  const onExport = () => {
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]
    const csv = exportExpertToCSV(results, columns)
    if (csv !== undefined) {
      FileSaver.saveAs(new Blob([csv], { type: "text/plain;charset=utf8" }), "ExpertEventLog.csv")
    }
  }

  const onSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
  }) => {
    dispatch(setExpertEventLogSort(parseInt(sortBy, 10) as ExpertColumn, sortDirection))
  }

  const onShowDefaultColumns = () => {
    dispatch(setExpertEventLogColumns(defaultColumns))
  }

  const onShowAllColumns = () => {
    const newColumns = cloneDeep(columns)
    newColumns.forEach(col => {
      col.visible = true
    })
    dispatch(setExpertEventLogColumns(newColumns))
  }

  const onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColumns = cloneDeep(columns)
    const col = newColumns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      dispatch(setExpertEventLogColumns(newColumns))
    }
  }

  const onSelectRelated = (rowData: ExpertValue[]) => {
    const relatedPackets = getRelatedPackets(rowData)
    const decodePacketNumber = relatedPackets.packets.length > 0 ? relatedPackets.packets[0] : 0
    relatedPackets.packets.sort((a, b) => {
      if (a < b) return -1
      if (a > b) return 1
      return 0
    })
    if (decodePacketNumber !== 0) {
      dispatch(setPacketsDecodePacketNumber(decodePacketNumber))
    }
    dispatch(setPacketsSelection(capId, relatedPackets))
    history.push({
      pathname: "packets",
      state: { goToPacketNumber: decodePacketNumber },
    })
  }

  const getRelatedPackets = (rowData: ExpertValue[]): ResultsSelectRelatedResponse => {
    const selection: ResultsSelectRelatedResponse = { packets: [] }

    if (resultSet && resultSet.results && resultSet.results.length > 1) {
      const results = resultSet.results[0]

      const packetNumber = getExpertValueFromRowData(
        rowData,
        results.columnList,
        ExpertColumn.EXPERT_COLUMN_PACKET_NUMBER
      )
      if (packetNumber && isNumber(packetNumber.value) && packetNumber.value !== 0) {
        selection.packets.push(packetNumber.value)

        const packetTimeStamp = getExpertValueFromRowData(
          rowData,
          results.columnList,
          ExpertColumn.EXPERT_COLUMN_EVENT_TIME
        )
        if (packetTimeStamp && isString(packetTimeStamp.value)) {
          selection.firstPacketDateTime = packetTimeStamp.value
          selection.lastPacketDateTime = packetTimeStamp.value
        }
      }

      const otherPacketNumber = getExpertValueFromRowData(
        rowData,
        results.columnList,
        ExpertColumn.EXPERT_COLUMN_OTHER_PACKET_NUMBER
      )
      if (otherPacketNumber && isNumber(otherPacketNumber.value) && otherPacketNumber.value !== 0) {
        selection.packets.push(otherPacketNumber.value)
        // Since we don't have the other packet timestamp,
        // unset the first and last packet timestamps.
        selection.firstPacketDateTime = undefined
        selection.lastPacketDateTime = undefined
      }
    }

    return selection
  }

  const renderCommands = ({ rowData }: TableCellProps) => {
    const packetsDisabled = captureProperties === null || !captureProperties.packetBufferEnabled
    const relatedPackets = getRelatedPackets(rowData)
    const selectRelatedPacketsDisabled = packetsDisabled || relatedPackets.packets.length === 0

    // make sure the user can view packets for this capture or forensic search
    let canViewPackets = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
    }

    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            <DropdownItem
              disabled={selectRelatedPacketsDisabled || !canViewPackets}
              onClick={onSelectRelated.bind(this, rowData)}
            >
              Select Related Packets
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  // make sure the user can modify captures and view stats for this capture or forensic search
  let canModifyCapture = true
  if (captureProperties && engineCapabilities) {
    const isUserOwner = userId === captureProperties.creatorSID
    const policies = engineCapabilities.userRights.policies
    canModifyCapture = isUserOwner || policies.includes(EngineUserPolicies.modifyCaptures)
    const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
    if (!canViewStats) {
      return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
    }
  }

  // Extract the counts from the HEADER_COUNTERS result set
  const counts = { total: 0, informational: 0, minor: 0, major: 0, severe: 0 }
  if (resultSet && resultSet.results && resultSet.results.length > 1) {
    const headerCounters = resultSet.results[1]
    if (headerCounters.rowList !== undefined) {
      for (let i = 0, len = headerCounters.rowList.length; i < len; i++) {
        const row = headerCounters.rowList[i]
        if (Array.isArray(row) && row.length === 2) {
          if (row[0].value === "EVENT_COUNT_CURRENT") {
            counts.total = row[1].value as number
          } else if (row[0].value === "EVENT_COUNT_INFORMATIONAL") {
            counts.informational = row[1].value as number
          } else if (row[0].value === "EVENT_COUNT_MINOR") {
            counts.minor = row[1].value as number
          } else if (row[0].value === "EVENT_COUNT_MAJOR") {
            counts.major = row[1].value as number
          } else if (row[0].value === "EVENT_COUNT_SEVERE") {
            counts.severe = row[1].value as number
          }
        }
      }
    }
  }

  return (
    <View>
      <BreadcrumbItem to={match.url} title="Expert Event Log" />
      <ViewHeader>
        <ViewHeaderTitle title="Expert Event Log" count={counts.total} />
        <EventsViewCounters>
          <EventsViewCounter>
            <LightButton
              id="showInformational"
              aria-label="Informational"
              active={showInformational}
              onClick={() => setShowInformational(!showInformational)}
            >
              <IconInformational />
            </LightButton>
            &nbsp;
            {formatInteger(counts.informational)}
            <UncontrolledTooltip placement="top" target="showInformational">
              Informational
            </UncontrolledTooltip>
          </EventsViewCounter>
          <EventsViewCounter>
            <LightButton
              id="showMinor"
              aria-label="Minor"
              active={showMinor}
              onClick={() => setShowMinor(!showMinor)}
            >
              <IconMinor />
            </LightButton>
            &nbsp;
            {formatInteger(counts.minor)}
            <UncontrolledTooltip placement="top" target="showMinor">
              Minor
            </UncontrolledTooltip>
          </EventsViewCounter>
          <EventsViewCounter>
            <LightButton
              id="showMajor"
              aria-label="Major"
              active={showMajor}
              onClick={() => setShowMajor(!showMajor)}
            >
              <IconMajor />
            </LightButton>
            &nbsp;
            {formatInteger(counts.major)}
            <UncontrolledTooltip placement="top" target="showMajor">
              Major
            </UncontrolledTooltip>
          </EventsViewCounter>
          <EventsViewCounter>
            <LightButton
              id="showSevere"
              aria-label="Severe"
              active={showSevere}
              onClick={() => setShowSevere(!showSevere)}
            >
              <IconSevere />
            </LightButton>
            &nbsp;
            {formatInteger(counts.severe)}
            <UncontrolledTooltip placement="top" target="showSevere">
              Severe
            </UncontrolledTooltip>
          </EventsViewCounter>
        </EventsViewCounters>
        <ViewHeaderButtons>
          <LightButton aria-label="Export" id="export" onClick={onExport}>
            <FontAwesome name="download" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="export">
            Export
          </UncontrolledTooltip>
          <LightButton aria-label="Expert Settings" id="expertSettings" onClick={onExpertSettings}>
            <FontAwesome name="cog" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="expertSettings">
            Configure Expert Settings
          </UncontrolledTooltip>
          <LightButton
            aria-label="Refresh"
            id="refresh"
            onClick={() => {
              query.refetch()
            }}
          >
            <FontAwesome name="refresh" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="refresh">
            Refresh
          </UncontrolledTooltip>
        </ViewHeaderButtons>
      </ViewHeader>
      {resultSet ? (
        <ViewContent>
          <ExpertTable
            resultSet={resultSet}
            renderCommands={renderCommands}
            columnDesc={columns}
            onShowDefaultColumns={onShowDefaultColumns}
            onShowAllColumns={onShowAllColumns}
            onToggleColumn={onToggleColumn}
            sort={onSort}
            sortBy={sortBy.toString()}
            sortDirection={sortDirection}
            showLocalTime={showLocalTime}
          />
        </ViewContent>
      ) : (
        <CenterContent>
          <Spinner />
        </CenterContent>
      )}
      {expertSettings && (
        <ExpertSettingsModal
          expertDescriptions={expertSettings.expertDescriptions}
          expertLayers={expertSettings.expertLayers}
          expertSettings={expertSettings.expertSettings.settings}
          maxStreamCountMax={expertSettings.expertSettings.maxStreamCountMax}
          readOnly={type !== "captures" || !canModifyCapture}
          type={type}
          onCancel={onExpertSettingsCancel}
          onOK={onExpertSettingsOK}
          engine={engine}
          authToken={authToken}
        />
      )}
    </View>
  )
}

export default ExpertEventLogView
