import { useTheme } from "styled-components"
import PercentChart from "../../../common/PercentChart"
import { ChartType } from "../../../../api/types/chartTypes"
import { Widget, WidgetBody, WidgetMessage } from "../../DashboardWidget"
import { WidgetProps, TopStatsViewType } from "../../../../api/types/dashboardTypes"
import DashboardWidgetHeader from "../../DashboardWidgetHeader"

const TopStatsViewTitle = {
  [TopStatsViewType.PHYSICAL]: "Top Talkers by Physical Address",
  [TopStatsViewType.IP]: "Top Talkers by IP Address",
  [TopStatsViewType.IPV6]: "Top Talkers by IPv6 Address",
  [TopStatsViewType.PROTOCOLS]: "Top Protocols by Bytes",
  [TopStatsViewType.APPLICATIONS]: "Top Applications by Bytes",
}

const ForensicTopStatsWidget = ({ widget, data, commands }: WidgetProps) => {
  const theme = useTheme()

  const viewType = widget.settings.viewType != null ? widget.settings.viewType : TopStatsViewType.IP
  const title = widget.title || TopStatsViewTitle[viewType]

  return (
    <Widget>
      <DashboardWidgetHeader title={title} widget={widget} commands={commands} />
      <WidgetBody>
        {data != null && Array.isArray(data.entries) && data.entries.length > 0 ? (
          <PercentChart
            data={data.entries}
            chartType={ChartType.HORIZONTAL_BAR}
            fixedScale={true}
            valueKey="bytes"
            totalKey="totalBytes"
            theme={theme}
          />
        ) : (
          <WidgetMessage>No Data</WidgetMessage>
        )}
      </WidgetBody>
    </Widget>
  )
}

export default ForensicTopStatsWidget
