import * as React from "react"
import styled, { useTheme, DefaultTheme } from "styled-components"
import FileSaver from "file-saver"
import FontAwesome from "react-fontawesome"
import { cloneDeep } from "lodash"
import { Redirect } from "react-router-dom"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useRouteMatch, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { CenterContent } from "../common/Layout"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import ExpertSettingsModal, {
  eventFinderViews,
  expertResponseToExpertDescriptions,
  expertResponseToExpertLayers,
  expertResponseToExpertSettings,
  ExpertSettingsEx,
  expertSettingsToExpertRequests,
  queryEventFinder,
} from "../ExpertSettingsModal"
import { ExpertTable, ExpertTableColumn } from "../common/ExpertTable"
import { LightButton, IconDropdownToggle } from "../common/Buttons"
import { Spinner } from "../common/Spinner"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import { View, ViewContent, ViewHeader, ViewHeaderTitle, ViewHeaderButtons } from "../common/View"
import { setExpertEventSummarySort } from "../../store/ui"
import { setSelectPacketsTask } from "../../store/selectPackets"
import {
  getEngine,
  getAuthToken,
  getExpertEventSummarySortBy,
  getExpertEventSummarySortDirection,
  getCapabilities,
  getUserId,
  getShowLocalTime,
} from "../../store"
import { exportExpertToCSV, formatExpertValue, getExpertColumnName } from "../../utils/expertUtils"
import { formatInteger } from "../../utils/formatUtils"
import { expertExecuteCFS, expertQueryCFS, postSelectRelatedExpertStart } from "../../api/api"
import {
  ExpertDescription,
  ExpertLayerMapping,
  ExpertSettings,
  ExpertValue,
  RequestExpertExecute,
  RequestExpertQuery,
  RequestPostSelectRelatedExpertStart,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { ExpertColumn, ExpertView } from "../../api/types/expertTypes"
import { getCaptureForensicSearchUrl } from "../../routes"

const columnDesc: ExpertTableColumn[] = [
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_EVENT_SEVERITY_MAX.toString(),
    label: "",
    width: 28,
    flexGrow: 0,
    flexShrink: 0,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_PROTOCOL_LAYER.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_PROTOCOL_LAYER),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_EVENT_NAME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_EVENT_NAME),
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_PROBLEM_COUNT.toString(),
    label: "Count",
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_START_TIME.toString(),
    label: "First Time",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_END_TIME.toString(),
    label: "Last Time",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
] as const

const CommandStrip = styled.div`
  display: flex;
`

const queryTemplate: RequestExpertQuery = {
  query: [
    {
      columnList: [
        ExpertColumn.EXPERT_COLUMN_PROBLEM_ID,
        ExpertColumn.EXPERT_COLUMN_EVENT_SEVERITY_MAX,
        ExpertColumn.EXPERT_COLUMN_PROTOCOL_LAYER,
        ExpertColumn.EXPERT_COLUMN_EVENT_NAME,
        ExpertColumn.EXPERT_COLUMN_PROBLEM_COUNT,
        ExpertColumn.EXPERT_COLUMN_START_TIME,
        ExpertColumn.EXPERT_COLUMN_END_TIME,
      ],
      orderBy: [ExpertColumn.EXPERT_COLUMN_PROBLEM_ID],
      orderByAscending: true,
      view: ExpertView.EXPERT_VIEW_EVENT_COUNTS,
      viewSettings: {
        la: "\u2190",
        ra: "\u2192",
        ba: "\u2194",
        showAddressNames: true,
        showPortNames: true,
      },
    },
  ],
} as const

type ExpertSettingsState = {
  expertSettings: ExpertSettingsEx
  expertLayers: ExpertLayerMapping[]
  expertDescriptions: ExpertDescription[]
}

const ExpertEventSummaryView = ({ captureProperties }: CaptureViewProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const match = useRouteMatch()
  const { type, capId } = useParams<CaptureRouteParams>()
  const theme = useTheme() as DefaultTheme
  const engine = useSelector(getEngine)
  const authToken = useSelector(getAuthToken)
  const userId = useSelector(getUserId)
  const engineCapabilities: ResponseGetEngineCapabilities | null = useSelector(getCapabilities)
  const showLocalTime: boolean = useSelector(getShowLocalTime)
  const sortBy: ExpertColumn =
    useSelector(getExpertEventSummarySortBy) || ExpertColumn.EXPERT_COLUMN_PROBLEM_COUNT
  const sortDirection: SortDirectionType =
    useSelector(getExpertEventSummarySortDirection) || SortDirection.DESC
  const [expertSettings, setExpertSettings] = React.useState<ExpertSettingsState | null>(null)

  const query = useQuery({
    staleTime: 30000,
    queryKey: [type, capId, sortBy, sortDirection, "expertsummary"],
    queryFn: () => {
      const query = cloneDeep(queryTemplate)
      if (query.query[0].orderBy !== undefined) {
        if (sortBy !== ExpertColumn.EXPERT_COLUMN_PROBLEM_ID) {
          query.query[0].orderBy = [sortBy, ExpertColumn.EXPERT_COLUMN_PROBLEM_ID]
        } else {
          query.query[0].orderBy = [sortBy]
        }
      }
      query.query[0].orderByAscending = sortDirection === SortDirection.ASC
      return expertQueryCFS(engine, authToken, type, capId, query)
    },
  })

  const resultSet = query.data ?? null

  const onExpertSettings = () => {
    const query: RequestExpertQuery = {
      query: eventFinderViews.map((view: ExpertView) => {
        return { ...cloneDeep(queryEventFinder), view }
      }),
    }
    expertQueryCFS(engine, authToken, type, capId, query)
      .then(resultSet => {
        if (Array.isArray(resultSet.results)) {
          const expertDescriptions = expertResponseToExpertDescriptions(resultSet.results)
          const expertLayers = expertResponseToExpertLayers(resultSet.results)
          const expertSettings = expertResponseToExpertSettings(resultSet.results)
          if (expertSettings) {
            setExpertSettings({
              expertSettings,
              expertLayers,
              expertDescriptions,
            })
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const onExpertSettingsCancel = () => {
    setExpertSettings(null)
  }

  const onExpertSettingsOK = (settings: ExpertSettings) => {
    const request: RequestExpertExecute = {
      execute: expertSettingsToExpertRequests(settings),
    }

    expertExecuteCFS(engine, authToken, type, capId, request).finally(() => {
      setExpertSettings(null)
    })
  }

  const onExport = () => {
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]
    const csv = exportExpertToCSV(results, columnDesc)
    if (csv !== undefined) {
      FileSaver.saveAs(
        new Blob([csv], { type: "text/plain;charset=utf8" }),
        "ExpertEventSummary.csv"
      )
    }
  }

  const onSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
  }) => {
    dispatch(setExpertEventSummarySort(parseInt(sortBy, 10) as ExpertColumn, sortDirection))
  }

  const onSelectRelated = (rowData: ExpertValue[]) => {
    const body: RequestPostSelectRelatedExpertStart = {
      criteria: [
        {
          problemId: rowData[0].value as number,
        },
      ],
    }
    postSelectRelatedExpertStart(engine, authToken, capId, body)
      .then(task => {
        if (task.taskId) {
          dispatch(setSelectPacketsTask({ type: "expert", taskId: task.taskId, progress: 0 }))
          history.push("packets")
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const cellRenderer = ({ dataKey, rowData }: TableCellProps) => {
    if (!resultSet || !resultSet.results || rowData == null) return null
    const columnId = parseInt(dataKey, 10) as ExpertColumn
    const results = resultSet.results[0]
    const columnIndex = results.columnList.indexOf(columnId)
    if (columnIndex === -1) return null
    const value = rowData[columnIndex]
    if (value != null && value.value != null) {
      let content: React.ReactNode
      const formatted =
        columnId === ExpertColumn.EXPERT_COLUMN_PROBLEM_COUNT
          ? formatInteger(value.value)
          : formatExpertValue(
              columnId,
              value.value,
              value.rendered,
              rowData,
              results.columnList,
              showLocalTime
            )
      if (
        formatted != null &&
        theme.name === "Light" &&
        value.color != null &&
        value.color !== "#000000"
      ) {
        content = (
          <span title={String(formatted)} style={{ color: value.color }}>
            {formatted}
          </span>
        )
      } else {
        content = formatted
      }

      return content
    }
    return null
  }

  const renderCommands = ({ rowData }: TableCellProps) => {
    const packetsDisabled = captureProperties === null || !captureProperties.packetBufferEnabled

    // make sure the user can view packets for this capture or forensic search
    let canViewPackets = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
    }

    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={onSelectRelated.bind(this, rowData)}
            >
              Select Related Packets
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  // make sure the user can modify captures and view stats for this capture or forensic search
  let canModifyCapture = true
  if (captureProperties && engineCapabilities) {
    const isUserOwner = userId === captureProperties.creatorSID
    const policies = engineCapabilities.userRights.policies
    canModifyCapture = isUserOwner || policies.includes(EngineUserPolicies.modifyCaptures)
    const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
    if (!canViewStats) {
      return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
    }
  }

  const count =
    resultSet && resultSet.results && resultSet.results[0].rowList
      ? resultSet.results[0].rowList.length
      : 0
  return (
    <View>
      <BreadcrumbItem to={match.url} title="Expert Event Summary" />
      <ViewHeader>
        <ViewHeaderTitle title="Expert Event Summary" count={count} />
        <ViewHeaderButtons>
          <LightButton aria-label="Export" id="export" onClick={onExport}>
            <FontAwesome name="download" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="export">
            Export
          </UncontrolledTooltip>
          <LightButton aria-label="Expert Settings" id="expertSettings" onClick={onExpertSettings}>
            <FontAwesome name="cog" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="expertSettings">
            Configure Expert Settings
          </UncontrolledTooltip>
          <LightButton
            aria-label="Refresh"
            id="refresh"
            onClick={() => {
              query.refetch()
            }}
          >
            <FontAwesome name="refresh" />
          </LightButton>
          <UncontrolledTooltip placement="top" target="refresh">
            Refresh
          </UncontrolledTooltip>
        </ViewHeaderButtons>
      </ViewHeader>
      {resultSet ? (
        <ViewContent>
          <ExpertTable
            resultSet={resultSet}
            renderCommands={renderCommands}
            columnDesc={columnDesc}
            sort={onSort}
            sortBy={sortBy.toString()}
            sortDirection={sortDirection}
            cellRenderer={cellRenderer}
            showLocalTime={showLocalTime}
          />
        </ViewContent>
      ) : (
        <CenterContent>
          <Spinner />
        </CenterContent>
      )}
      {expertSettings && (
        <ExpertSettingsModal
          expertDescriptions={expertSettings.expertDescriptions}
          expertLayers={expertSettings.expertLayers}
          expertSettings={expertSettings.expertSettings.settings}
          maxStreamCountMax={expertSettings.expertSettings.maxStreamCountMax}
          readOnly={type !== "captures" || !canModifyCapture}
          type={type}
          onCancel={onExpertSettingsCancel}
          onOK={onExpertSettingsOK}
          engine={engine}
          authToken={authToken}
        />
      )}
    </View>
  )
}

export default ExpertEventSummaryView
