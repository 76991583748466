import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { Helmet } from "react-helmet"
import FontAwesome from "react-fontawesome"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { Table } from "../common/Table"
import { View, ViewContent, ViewHeader, ViewHeaderTitle, ViewHeaderButtons } from "../common/View"
import { LightButton } from "../common/Buttons"
import { getEngine, getAuthToken, getCapabilities, getShowLocalTime } from "../../store"
import { formatDuration, formatISODateTime } from "../../utils/formatUtils"
import { fetchConnectedUsers } from "../../api/api"
import {
  ConnectedUser,
  ResponseGetConnectedUsers,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { getDefaultAdminUrl } from "../../utils/engineUtils"

type ConnectedUsersTableProps = {
  users: ResponseGetConnectedUsers
  showLocalTime: boolean
}

class ConnectedUsersTable extends React.Component<ConnectedUsersTableProps> {
  render() {
    const { users } = this.props
    const rows = users.users.map((user: ConnectedUser, i: number) => {
      const startTime = Date.parse(user.startTime)
      const endTime = Date.parse(user.endTime)
      const duration = (endTime - startTime) * 1000000
      return (
        <tr key={i}>
          <td>{user.name}</td>
          <td>{user.ipAddress}</td>
          <td>{user.port}</td>
          <td>{formatISODateTime(user.startTime, 0, this.props.showLocalTime)}</td>
          <td>{formatISODateTime(user.endTime, 0, this.props.showLocalTime)}</td>
          <td>{formatDuration(duration)}</td>
        </tr>
      )
    })
    return (
      <Table hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Client</th>
            <th>Port</th>
            <th>Connected Time</th>
            <th>Last Activity</th>
            <th>Duration</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    )
  }
}

type ConnectedUsersViewProps = RouteComponentProps & {
  engine: string
  authToken: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  showLocalTime: boolean
}

type ConnectedUsersViewState = {
  users: ResponseGetConnectedUsers | null
}

class ConnectedUsersView extends React.Component<ConnectedUsersViewProps, ConnectedUsersViewState> {
  state: ConnectedUsersViewState = {
    users: null,
  }

  componentDidMount() {
    this.onRefresh()
  }

  onRefresh = () => {
    const { engine, authToken } = this.props
    fetchConnectedUsers(engine, authToken)
      .then((users: ResponseGetConnectedUsers) => {
        if (users.users) {
          users.users.sort((a: ConnectedUser, b: ConnectedUser) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1
            if (a.ipAddress < b.ipAddress) return -1
            if (a.ipAddress > b.ipAddress) return 1
            if (a.port < b.port) return -1
            if (a.port > b.port) return 1
            return 0
          })
          this.setState({ users })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  render() {
    const { engineCapabilities } = this.props
    const { users } = this.state
    const count = users !== null ? users.users.length : 0

    if (
      !engineCapabilities?.capabilities.includes(EngineCapabilities.listConnectedUsers) ||
      !engineCapabilities?.userRights.policies.includes(EngineUserPolicies.configureEngine)
    ) {
      return <Redirect to={`${getDefaultAdminUrl(engineCapabilities)}`} />
    }

    return (
      <View>
        <Helmet title="Connected Users" />
        <BreadcrumbItem to={this.props.match.url} title="Connected Users" />
        <ViewHeader>
          <ViewHeaderTitle title="Connected Users" count={count} />
          <ViewHeaderButtons>
            <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefresh}>
              <FontAwesome name="refresh" />
            </LightButton>
          </ViewHeaderButtons>
        </ViewHeader>
        <ViewContent>
          {users && <ConnectedUsersTable users={users} showLocalTime={this.props.showLocalTime} />}
        </ViewContent>
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  engineCapabilities: getCapabilities(state) || null,
  showLocalTime: getShowLocalTime(state),
})

export default connect(mapStateToProps)(ConnectedUsersView)
