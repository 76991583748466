import * as React from "react"
import styled from "styled-components"
import { FormGroup } from "reactstrap"
import { ControlStrip } from "../common/Layout"
import { DateTimePicker } from "../common/DateTimePicker"
import { Input } from "../common/Input"
import { Label, CheckGroup } from "../common/Form"
import { Popover, PopoverBody } from "../common/Popover"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { AuditLogSearch } from "./AuditLogSearch"
import { useSelector } from "react-redux"
import { getShowLocalTime } from "../../store"

const AuditLogSearchPopoverBody = styled(PopoverBody)`
  padding: 1rem;

  & label {
    margin-bottom: 0.25rem;
  }
`

const CheckGroupWrapper = styled.div`
  display: flex;

  & > :first-child {
    flex-shrink: 0;
  }

  & > :last-child {
    flex-grow: 1;
  }
`

type AuditLogSearchPopoverProps = {
  isOpen: boolean
  target: string
  search: AuditLogSearch
  firstTimestamp?: string
  lastTimestamp?: string
  onOK: (search: AuditLogSearch) => void
  onCancel: () => void
}

const AuditLogSearchPopover: React.FC<AuditLogSearchPopoverProps> = ({
  isOpen,
  target,
  search,
  firstTimestamp,
  lastTimestamp,
  onOK,
  onCancel,
}) => {
  const showLocalTime = useSelector(getShowLocalTime)
  const [isMessageEnabled, setMessageEnabled] = React.useState(search.message.length > 0)
  const [message, setMessage] = React.useState(search.message)
  const [isClientEnabled, setClientEnabled] = React.useState(search.client.length > 0)
  const [client, setClient] = React.useState(search.client)
  const [isUserEnabled, setUserEnabled] = React.useState(search.user.length > 0)
  const [user, setUser] = React.useState(search.user)
  const [isTimeRangeEnabled, setTimeRangeEnabled] = React.useState(
    search.startTime.length > 0 && search.stopTime.length > 0
  )
  const [startTime, setStartTime] = React.useState(
    search.startTime || firstTimestamp || new Date().toISOString()
  )
  const [stopTime, setStopTime] = React.useState(
    search.stopTime || lastTimestamp || new Date().toISOString()
  )

  return (
    <Popover placement="bottom" isOpen={isOpen} target={target} fade={false}>
      <AuditLogSearchPopoverBody>
        <FormGroup>
          <Label for="search--message" style={{ marginLeft: "1.5rem" }}>
            Message
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search--message-enabled"
              checked={isMessageEnabled}
              onChange={() => setMessageEnabled(!isMessageEnabled)}
            />
            <Input
              type="text"
              id="search--message"
              disabled={!isMessageEnabled}
              spellCheck={false}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setMessage(event.target.value)
              }}
              value={message}
              style={{ width: "232px" }}
            />
          </CheckGroupWrapper>
        </FormGroup>

        <FormGroup>
          <Label for="search--client" style={{ marginLeft: "1.5rem" }}>
            Client
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search--client-enabled"
              checked={isClientEnabled}
              onChange={() => setClientEnabled(!isClientEnabled)}
            />
            <Input
              type="text"
              id="search--client"
              disabled={!isClientEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setClient(event.target.value)
              }}
              value={client}
            />
          </CheckGroupWrapper>
        </FormGroup>

        <FormGroup>
          <Label for="search--user" style={{ marginLeft: "1.5rem" }}>
            User
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search--user-enabled"
              checked={isUserEnabled}
              onChange={() => setUserEnabled(!isUserEnabled)}
            />
            <Input
              type="text"
              id="search--user"
              disabled={!isUserEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUser(event.target.value)
              }}
              value={user}
            />
          </CheckGroupWrapper>
        </FormGroup>

        <FormGroup>
          <CheckGroupWrapper>
            <div style={{ paddingTop: "2rem" }}>
              <CheckGroup
                type="checkbox"
                id="search--time-range-enabled"
                checked={isTimeRangeEnabled}
                onChange={() => setTimeRangeEnabled(!isTimeRangeEnabled)}
              />
            </div>
            <div>
              <FormGroup>
                <Label for="search--start-time">
                  Start&nbsp;time{showLocalTime ? "" : " (UTC)"}
                </Label>
                <DateTimePicker
                  id="search--start-time"
                  selected={new Date(startTime)}
                  disabled={!isTimeRangeEnabled}
                  onChange={(date: Date | null) => {
                    if (date != null) {
                      setStartTime(date.toISOString())
                    }
                  }}
                  showLocalTime={showLocalTime}
                />
              </FormGroup>
              <FormGroup noMargin>
                <Label for="search--stop-time">End time{showLocalTime ? "" : " (UTC)"}</Label>
                <DateTimePicker
                  id="search--stop-time"
                  selected={new Date(stopTime)}
                  disabled={!isTimeRangeEnabled}
                  onChange={(date: Date | null) => {
                    if (date != null) {
                      setStopTime(date.toISOString())
                    }
                  }}
                  showLocalTime={showLocalTime}
                />
              </FormGroup>
            </div>
          </CheckGroupWrapper>
        </FormGroup>

        <ControlStrip justify="flex-end" style={{ paddingLeft: "1.5rem" }}>
          <SecondaryButton size="sm" onClick={() => onCancel()}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            size="sm"
            onClick={() =>
              onOK({
                message: isMessageEnabled ? message : "",
                client: isClientEnabled ? client : "",
                user: isUserEnabled ? user : "",
                startTime: isTimeRangeEnabled ? startTime : "",
                stopTime: isTimeRangeEnabled ? stopTime : "",
              })
            }
          >
            Search
          </PrimaryButton>
        </ControlStrip>
      </AuditLogSearchPopoverBody>
    </Popover>
  )
}

export default AuditLogSearchPopover
