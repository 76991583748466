import React from "react"
import {
  endOfDay,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subWeeks,
} from "date-fns"
import FontAwesome from "react-fontawesome"
import styled from "styled-components"
import { LightButton, LinkButton } from "../Buttons"
import { Label } from "../Form"
import { Popover, PopoverBody } from "../Popover"
import { utcToLocalCheck } from "../../../utils/formatUtils"

type DateRangePresetsProps = {
  showLocalTime: boolean
  onChangeTimes: (start: Date, end: Date) => void
  onRevertTimes?: () => void
}

const DateRangePresetsPopoverBody = styled(PopoverBody)`
  display: flex;
  gap: 1rem;
  padding: 1rem;
`

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
`

const DateRangePresets = (props: DateRangePresetsProps) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const setTimes = (start: Date, end: Date) => {
    props.onChangeTimes(start, end)
  }

  const onSearch = () => {
    setIsOpen(!isOpen)
  }

  const onToggle = () => {
    setIsOpen(!isOpen)
  }

  const { showLocalTime } = props
  const now = new Date()
  const yesterday = subDays(now, 1)
  const lastweek = subWeeks(now, 1)

  return (
    <div>
      <LightButton size="sm" id="dateRangePresets" onClick={() => onSearch()}>
        Presets <FontAwesome name="caret-down"></FontAwesome>
      </LightButton>
      <Popover
        placement="bottom"
        isOpen={isOpen}
        target="dateRangePresets"
        trigger="legacy"
        toggle={onToggle}
        fade={false}
      >
        <DateRangePresetsPopoverBody>
          <div>
            <Label>Presets</Label>
            <StyledDiv>
              {props.onRevertTimes && (
                <LinkButton
                  size="sm"
                  id="defaults"
                  color="link"
                  onClick={() => {
                    if (props.onRevertTimes) {
                      props.onRevertTimes()
                    }
                    onSearch()
                  }}
                >
                  Defaults
                </LinkButton>
              )}
              <LinkButton
                size="sm"
                id="today"
                onClick={() => {
                  setTimes(utcToLocalCheck(startOfDay(now), showLocalTime), now)
                  onSearch()
                }}
              >
                Today
              </LinkButton>
              <LinkButton
                size="sm"
                id="yesterday"
                onClick={() => {
                  setTimes(
                    utcToLocalCheck(startOfDay(yesterday), showLocalTime),
                    utcToLocalCheck(endOfDay(yesterday), showLocalTime)
                  )
                  onSearch()
                }}
              >
                Yesterday
              </LinkButton>
              <LinkButton
                size="sm"
                id="thisweek"
                onClick={() => {
                  setTimes(utcToLocalCheck(startOfWeek(now), showLocalTime), now)
                  onSearch()
                }}
              >
                This week
              </LinkButton>
              <LinkButton
                size="sm"
                id="lastweek"
                onClick={() => {
                  setTimes(
                    utcToLocalCheck(startOfWeek(lastweek), showLocalTime),
                    utcToLocalCheck(endOfWeek(lastweek), showLocalTime)
                  )
                  onSearch()
                }}
              >
                Last week
              </LinkButton>
              <LinkButton
                size="sm"
                id="thismonth"
                onClick={() => {
                  setTimes(utcToLocalCheck(startOfMonth(now), showLocalTime), now)
                  onSearch()
                }}
              >
                This month
              </LinkButton>
            </StyledDiv>
          </div>
          <div>
            <Label>Relative</Label>
            <StyledDiv>
              <LinkButton
                size="sm"
                id="last60sec"
                onClick={() => {
                  setTimes(subMinutes(now, 1), now)
                  onSearch()
                }}
              >
                Last 60 seconds
              </LinkButton>
              <LinkButton
                size="sm"
                id="last5min"
                onClick={() => {
                  setTimes(subMinutes(now, 5), now)
                  onSearch()
                }}
              >
                Last 5 minutes
              </LinkButton>
              <LinkButton
                size="sm"
                id="last30min"
                onClick={() => {
                  setTimes(subMinutes(now, 30), now)
                  onSearch()
                }}
              >
                Last 30 minutes
              </LinkButton>
              <LinkButton
                size="sm"
                id="last1hour"
                onClick={() => {
                  setTimes(subHours(now, 1), now)
                  onSearch()
                }}
              >
                Last 1 hour
              </LinkButton>
              <LinkButton
                size="sm"
                id="last24hour"
                onClick={() => {
                  setTimes(subHours(now, 24), now)
                  onSearch()
                }}
              >
                Last 24 hours
              </LinkButton>
              <LinkButton
                size="sm"
                id="last7days"
                onClick={() => {
                  setTimes(subDays(now, 7), now)
                  onSearch()
                }}
              >
                Last 7 days
              </LinkButton>
              <LinkButton
                size="sm"
                id="last1month"
                onClick={() => {
                  setTimes(subMonths(now, 1), now)
                  onSearch()
                }}
              >
                Last 1 month
              </LinkButton>
            </StyledDiv>
          </div>
        </DateRangePresetsPopoverBody>
      </Popover>
    </div>
  )
}

export default DateRangePresets
