import * as React from "react"
import cn from "classnames"
import { cloneDeep } from "lodash"
import {
  SortDirectionType,
  SortIndicator,
  TableCellProps,
  TableHeaderProps,
  TableRowProps,
} from "react-virtualized"
import styled from "styled-components"
import {
  ConfigurationType,
  ConfigurationItem,
  ConfigurationItems,
  sortConfigurationItems,
} from "./types"
import { Alert } from "../common/Alert"
import { CheckGroup, CheckGroupIndeterminate } from "../common/Form"
import { MutedText } from "../common/MutedText"
import { OmniTable } from "../common/OmniTable"

const ChooseConfigurationOptionsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const ChooseConfigurationTable = styled.div`
  position: relative;
  flex-grow: 1;
  min-height: 250px;
`

const NoteList = styled.ul`
  margin-bottom: 0px;
`

const columnDesc = [
  {
    dataKey: "name",
    label: "Configuration",
    width: 250,
    headerStyle: { paddingLeft: "1.7857rem" },
  },
  {
    dataKey: "description",
    label: "Description",
    width: 250,
    flexGrow: 1,
  },
]

type ChooseConfigurationProps = {
  sortBy: string
  setSortBy: (sortBy: string) => void
  sortDirection: SortDirectionType
  setSortDirection: (sortDirection: SortDirectionType) => void
  deleteCaptureSessions: boolean
  setDeleteCaptureSessions: (deleteCaptureSessions: boolean) => void
  selectedConfigurationTypes: ConfigurationType[]
  setSelectedConfigurationTypes: (selectedConfigurationTypes: ConfigurationType[]) => void
}

const ChooseConfiguration = ({
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  deleteCaptureSessions,
  setDeleteCaptureSessions,
  selectedConfigurationTypes,
  setSelectedConfigurationTypes,
}: ChooseConfigurationProps) => {
  const onSort = ({
    sortBy,
    sortDirection,
    event,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
    event: any
  }) => {
    const tagName = (event?.target as HTMLElement)?.tagName
    if (tagName === "LABEL" || tagName === "INPUT") {
      return
    }
    setSortBy(sortBy)
    setSortDirection(sortDirection)
  }

  const onCheckConfiguration = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    const configType = event.target.name as ConfigurationType
    const newSelectedConfigurationTypes = selectedConfigurationTypes.filter(
      (configurationType: ConfigurationType) => configurationType !== configType
    )
    // If the configuration type was selected, it is now removed from the list, else if
    // the list hasn't changed it needs to be added.
    if (newSelectedConfigurationTypes.length === selectedConfigurationTypes.length) {
      newSelectedConfigurationTypes.push(configType)
    }
    setSelectedConfigurationTypes(newSelectedConfigurationTypes)
  }

  const onEnableAll = () => {
    const newSelectedConfigurationTypes = ConfigurationItems.map(
      (configItem: ConfigurationItem) => configItem.type
    )
    setSelectedConfigurationTypes(newSelectedConfigurationTypes)
  }

  const onDisableAll = () => {
    setSelectedConfigurationTypes([])
  }

  const rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    if (rowData.childIndex != null && rowData.childIndex % 2 !== 0) {
      className = cn(className, "stripe")
    }
    key = rowData.type

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  const rowClassName = () => {
    // Stripes applied in rowRenderer
    return ""
  }

  const nameHeaderRenderer = ({ dataKey, label, sortBy, sortDirection }: TableHeaderProps) => {
    let checkState = 0
    if (selectedConfigurationTypes.length > 0) {
      if (ConfigurationItems.length === selectedConfigurationTypes.length) {
        checkState = 1
      } else {
        checkState = 2
      }
    }
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
        }}
      >
        <div style={{ fontSize: "1rem" }}>
          <CheckGroupIndeterminate
            type="checkbox"
            value={checkState}
            id="check-all"
            onChange={() => {
              if (checkState !== 1) {
                onEnableAll()
              } else {
                onDisableAll()
              }
            }}
          />
        </div>
        <span>{label}</span>
        {sortBy === dataKey ? <SortIndicator sortDirection={sortDirection} /> : null}
      </div>
    )
  }

  const cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content
    const configItem: ConfigurationItem = rowData
    switch (dataKey) {
      case "name":
        {
          const checked = selectedConfigurationTypes.includes(configItem.type)
          content = (
            <div style={{ display: "flex", alignItems: "center", marginLeft: "1.536em" }}>
              <CheckGroup
                type="checkbox"
                name={configItem.type}
                id={configItem.type}
                onChange={onCheckConfiguration}
                checked={checked}
              >
                <span title={cellData}>{cellData}</span>
              </CheckGroup>
            </div>
          )
        }
        break
      case "description":
        content = cellData
        break
    }
    return content
  }

  // Add the checkbox header renderer to the columns.
  const columns: any[] = [...columnDesc]
  const nameColumn = columns.find(col => col.dataKey === "name")
  if (nameColumn) {
    nameColumn.headerRenderer = nameHeaderRenderer
  }

  // Get and sort the list of configurations.
  const configItems = cloneDeep(ConfigurationItems)
  sortConfigurationItems(configItems, sortBy, sortDirection)

  return (
    <>
      <Alert color="info" fade={false}>
        <b>When pushing alarms, filters, graphs or hardware profiles to other engines:</b>
        <NoteList>
          <li>
            When pushing alarms to other engines, alarms currently being used by captures on the
            target engine will be removed from those captures if they are not found in the pushed
            alarms.
          </li>
          <li>
            When pushing filters to other engines, filters currently being used by captures on the
            target engine will be removed from those captures if they are not found in the pushed
            filters.
          </li>
          <li>
            When pushing graphs to other engines, graphs currently being used by captures on the
            target engine will be removed from those captures if they are not found in the pushed
            graphs.
          </li>
          <li>
            When pushing hardware profiles to other engines, hardware profiles currently being used
            by captures on the target engine will be removed from those captures if they are not
            found in the pushed hardware profiles.
          </li>
        </NoteList>
      </Alert>
      <Alert color="info" fade={false}>
        <b>When pushing captures to other engines:</b>
        <NoteList>
          <li>
            It is recommended to additionally select all other configuration items that are
            referenced in the captures (
            {ConfigurationItems.filter(
              (configItem: ConfigurationItem) => configItem.captureDependent
            )
              .map((configItem: ConfigurationItem) => configItem.name)
              .join(", ")}
            ). If a configuration setting used in a pushed capture is not found on the target
            engine, the capture will still be pushed but that configuration setting will be removed.
          </li>
          <li>
            If the storage available on the target engine is not large enough, the total storage
            available of the target engine will be divided evenly amongst all pushed captures.
          </li>
          <li>
            If the adapter specified by a pushed capture is not found on the target engine, the
            first adapter on the target engine of similar type will be selected for the pushed
            capture.
          </li>
          <li>
            The user pushing captures to the target engine will become the owner of these pushed
            captures on the target engine.
          </li>
          <li>
            The state of the capture will also be synced. If a capture is currently capturing or not
            capturing, then the pushed capture on the target engine will also be capturing or not be
            capturing, respectively.
          </li>
        </NoteList>
      </Alert>
      <ChooseConfigurationOptionsBlock>
        <CheckGroup
          type="checkbox"
          id="deleteCaptureSessions"
          name="deleteCaptureSessions"
          onChange={() => {
            setDeleteCaptureSessions(!deleteCaptureSessions)
          }}
          checked={deleteCaptureSessions}
        >
          Remove all capture data on selected engines when pushing captures
        </CheckGroup>
        <MutedText style={{ marginLeft: "1.5rem" }}>
          Deletes capture sessions and packet files for captures that no longer exist on selected
          engines
        </MutedText>
      </ChooseConfigurationOptionsBlock>
      <ChooseConfigurationTable>
        <OmniTable
          data={configItems}
          rowCount={configItems.length}
          rowHeight={25}
          cellRenderer={cellRenderer}
          columnDesc={columns}
          rowRenderer={rowRenderer}
          rowClassName={rowClassName}
          sort={onSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      </ChooseConfigurationTable>
    </>
  )
}

export default ChooseConfiguration
