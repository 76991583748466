import * as React from "react"

const Cloud = (props: React.SVGAttributes<SVGElement>) => {
  const { fill = "#fff", stroke = "#333", ...svgProps } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10240 10240"
      xmlSpace="preserve"
      width="1024px"
      height="1024px"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      imageRendering="optimizeQuality"
      {...svgProps}
    >
      <g>
        <path
          fill={stroke}
          d="m 1920,4319 c 0,-1325 1075,-2399 2400,-2399 777,0 1492,375 1937,982 100,-15 201,-22 303,-22 1149,0 2080,931 2080,2080 0,11 0,21 0,32 572,292 960,888 960,1568 0,972 -788,1760 -1760,1760 -1707,0 -3413,0 -5120,0 -1149,0 -2080,-931 -2080,-2080 0,-861 525,-1606 1280,-1921 z"
        />
        <path
          fill={fill}
          d="m 8009.3593,5455.7686 c 55.7831,-156.9692 85.7407,-326.4128 85.7407,-502.0936 0,-826.4273 -666.2984,-1496.925 -1487.55,-1496.925 -225.1985,0 -439.0339,50.937 -630.1427,140.3367 C 5706.7558,2929.7077 5054.9197,2458.8 4293.5833,2458.8 c -1004.0962,0 -1818.1166,819.1506 -1818.1166,1829.575 0,174.6413 23.7594,343.0453 69.2124,502.0936 C 1767.8474,4840.3661 1153.2,5490.0731 1153.2,6284.275 c 0,826.4273 666.2984,1496.925 1487.55,1496.925 1757.1684,0 3552.5586,0 5289.0667,0 639.4399,0 1156.9833,-520.8052 1156.9833,-1164.275 0,-616.442 -475.1896,-1120.6147 -1077.4407,-1161.1564 z"
        />
      </g>
    </svg>
  )
}

export default Cloud
