import React from "react"
import styled from "styled-components"
import { useReactToPrint } from "react-to-print"
import FontAwesome from "react-fontawesome"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../common/Dropdown"
import { LightDropdownToggle } from "../common/Buttons"
import lightTheme from "../../themes/lightTheme"
import { PacketDecodeTextFormat } from "../../api/api"

const DecodePaneOuter = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const DecodePaneInner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  background-color: ${props => props.theme.tableBackgroundColor};
  border-left: 1px solid ${props => props.theme.tableBorderColor};
  border-bottom: 1px solid ${props => props.theme.tableBorderColor};
`

const DecodePaneStyle = styled.div`
  padding: 8px;
  outline: 0;
  background-color: ${props => props.theme.tableBackgroundColor};

  & pre {
    margin: 0;
    border: none;
    font-size: 1rem;
    color: ${props => props.theme.textColor};
    overflow: visible;
  }

  & .plain {
    background-color: inherit;
    color: ${props => props.theme.decodePlainColor};
  }

  & .layer {
    background-color: inherit;
    color: ${props => props.theme.decodeLayerColor};
    text-decoration: underline;
    font-weight: bold;
  }

  & .data {
    background-color: inherit;
    color: ${props => props.theme.decodeDataColor};
    font-weight: bold;
  }

  & .header {
    background-color: inherit;
    color: ${props => props.theme.decodeHeaderColor};
    font-weight: bold;
  }

  & .message {
    background-color: inherit;
    color: ${props => props.theme.decodeMessageColor};
    font-style: italic;
  }

  & .dump {
    background-color: inherit;
    color: ${props => props.theme.decodeDumpColor};
  }

  & .debug {
    background-color: inherit;
    color: ${props => props.theme.decodeDebugColor};
  }

  & .offset {
    background-color: inherit;
    color: ${props => props.theme.decodeOffsetColor};
    font-size: 11px;
  }

  & .name {
    background-color: inherit;
  }

  @media print {
    display: block;
    background: none;
    border: none;

    @page {
      margin: 0.5in;
    }

    & .plain {
      color: ${() => lightTheme.decodePlainColor};
    }

    & .layer {
      color: ${() => lightTheme.decodeLayerColor};
    }

    & .data {
      color: ${() => lightTheme.decodeDataColor};
    }

    & .header {
      color: ${() => lightTheme.decodeHeaderColor};
    }

    & .message {
      color: ${() => lightTheme.decodeMessageColor};
    }

    & .dump {
      color: ${() => lightTheme.decodeDumpColor};
    }

    & .debug {
      color: ${() => lightTheme.decodeDebugColor};
    }

    & .offset {
      color: ${() => lightTheme.decodeOffsetColor};
    }
  }
`

export type DecodePaneTextProps = {
  packetDecode: string | null
  decodePacketNumber: number
  showOffsets: boolean
  setShowOffsets: (showOffsets: boolean) => void
  onCopyAs: (format: PacketDecodeTextFormat) => void
}

const DecodePaneText = ({
  packetDecode,
  decodePacketNumber,
  showOffsets,
  setShowOffsets,
  onCopyAs,
}: DecodePaneTextProps) => {
  const parentRef = React.useRef<HTMLDivElement | null>(null)

  const handlePrint = useReactToPrint({
    contentRef: parentRef,
    documentTitle: `Packet ${decodePacketNumber}`,
  })

  return (
    <DecodePaneOuter>
      <DecodePaneInner>
        {packetDecode !== null ? (
          <>
            <DecodePaneStyle ref={parentRef} tabIndex={0}>
              <div dangerouslySetInnerHTML={{ __html: packetDecode }} />
            </DecodePaneStyle>
            <UncontrolledDropdown
              style={{
                position: "absolute",
                top: "8px",
                right: "25px",
              }}
            >
              <LightDropdownToggle
                aria-label="Decode options"
                style={{
                  boxShadow: "0 3px 5px -1px rgba(0, 0, 0, 0.18)",
                  padding: 0,
                  width: "23px",
                  height: "23px",
                }}
              >
                <FontAwesome name="chevron-down" fixedWidth />
              </LightDropdownToggle>
              <DropdownMenu end>
                <DropdownItem active={showOffsets} onClick={() => setShowOffsets(!showOffsets)}>
                  Show Offsets
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => {
                    if (handlePrint) {
                      handlePrint()
                    }
                  }}
                >
                  Print
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => onCopyAs("text/plain")}>Copy as Text</DropdownItem>
                <DropdownItem onClick={() => onCopyAs("application/rtf")}>
                  Copy as Rich Text
                </DropdownItem>
                <DropdownItem onClick={() => onCopyAs("text/html")}>Copy as HTML</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ) : null}
      </DecodePaneInner>
    </DecodePaneOuter>
  )
}

export default DecodePaneText
