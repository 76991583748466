import * as React from "react"
import { connect } from "react-redux"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { cloneDeep } from "lodash"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { Panel } from "../common/Panel"
import {
  View,
  ViewMaxWidth,
  ViewContent,
  ViewHeader,
  ViewHeaderTitle,
  ViewHeaderButtons,
} from "../common/View"
import { LightButton, SecondaryButton, SecondaryDangerButton } from "../common/Buttons"
import { ConfirmationModal } from "../common/ConfirmationModal"
import PropTable from "../common/PropTable"
import ConfigureAdaptersModal from "../ConfigureAdaptersModal"
import LinkState from "../LinkState"
import PluginAdapterModal from "../PluginAdapterModal"
import { getAdapterId } from "../../utils/adapterUtils"
import { propToLabel, formatProp } from "../../utils/propUtils"
import { collator } from "../../utils/sortUtils"
import { getCapabilities, getEngine, getAuthToken, AppDispatch } from "../../store"
import { deleteAdapter, fetchAdapters, fetchCaptures, refreshAdapters } from "../../api/api"
import {
  Adapter,
  AdapterInfo,
  CaptureProperties,
  EngineCapabilitiesPluginInfo,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { ActivationType, hasActivationCapability } from "../../api/types/activationTypes"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { MediaType, MediaSubType } from "../../api/types/mediaTypes"
import { PeekAdapterFeatures, PeekAdapterType, PluginCategory } from "../../api/types/peekTypes"

const propList = [
  "title",
  "mediaType",
  "linkSpeed",
  "linkState",
  "address",
  "Omnipeek API",
  "Features",
  "Version",
]

const AdapterTable = styled.div`
  display: flex;
  flex-direction: column;
`

const AdapterRow = styled(Panel)`
  display: flex;
  flex-direction: row;

  & + & {
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const AdapterItem = styled.div`
  flex-grow: 1;
`

const Title = styled.h5``

const ButtonStrip = styled.div`
  display: flex;
  flex-direction: column;

  & button + button {
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    margin-top: 8px;

    & button + button {
      margin-left: 4px;
      margin-top: 0;
    }
  }
`

function formatAdapterProp(columnId: string, data: Adapter) {
  const adapterInfo = data.info
  if (adapterInfo === undefined) return ""
  switch (columnId) {
    case "address":
      if ("address" in adapterInfo) {
        if (adapterInfo.address !== "000000000000") {
          const match = adapterInfo.address.match(/.{1,2}/g)
          if (match) {
            return match.join(":")
          } else {
            return formatProp(columnId, adapterInfo)
          }
        }
      }
      return ""
    case "Omnipeek API": {
      let api = ""
      if ("interfaceFeatures" in adapterInfo && typeof adapterInfo.interfaceFeatures === "number") {
        api = adapterInfo.interfaceFeatures !== 0 ? "Yes" : "No"
      }
      if ("features" in adapterInfo && typeof adapterInfo.features === "number") {
        if ((adapterInfo.features & PeekAdapterFeatures.PEEK_ADAPTER_ERROR_CAPTURE) !== 0) {
          api = "Yes"
        }
      }
      return api
    }
    case "Features": {
      const features: string[] = []
      if ("features" in adapterInfo && typeof adapterInfo.features === "number") {
        if ((adapterInfo.features & PeekAdapterFeatures.PEEK_ADAPTER_PACKET_SLICE) !== 0) {
          features.push("Slicing")
        }
        if ((adapterInfo.features & PeekAdapterFeatures.PEEK_ADAPTER_DEDUPLICATION) !== 0) {
          features.push("Deduplication")
        }
        if ((adapterInfo.features & PeekAdapterFeatures.PEEK_ADAPTER_XYRATEX_FILTER) !== 0) {
          features.push("Filters")
        }
        if ((adapterInfo.features & PeekAdapterFeatures.PEEK_ADAPTER_HARDWARE_STATS) !== 0) {
          features.push("Statistics")
        }
        if ((adapterInfo.features & PeekAdapterFeatures.PEEK_ADAPTER_PIPELINE_CAPTURE) !== 0) {
          features.push("Pipeline")
        }
      }
      return features.join(" ")
    }
    case "Version":
      if ("versions" in adapterInfo) {
        return adapterInfo.versions
      }
      return ""
    case "linkState":
      if ("linkState" in adapterInfo) {
        const linkState = adapterInfo.linkState
        if (
          Array.isArray(linkState) ||
          (typeof linkState === "number" && (linkState === 0 || linkState === 1))
        ) {
          return <LinkState linkState={linkState} />
        }
      }
      return ""

    default:
      break
  }
  return formatProp(columnId, adapterInfo)
}

const AdaptersTable: React.FC<{
  adapterPlugins: EngineCapabilitiesPluginInfo[]
  adapters: Adapter[]
  canConfigureEngine: boolean
  captures: CaptureProperties[]
  onConfigure: (adapter: AdapterInfo) => void
  onDelete: (adapter: AdapterInfo) => void
  supportsConfigure: (adapter: AdapterInfo) => boolean
}> = ({
  adapterPlugins,
  adapters,
  canConfigureEngine,
  captures,
  onConfigure,
  onDelete,
  supportsConfigure,
}) => {
  const captureAdapterIds = captures.map((capture: CaptureProperties) =>
    getAdapterId(capture.adapterInfo)
  )
  const rows = adapters.map((adapter: Adapter) => {
    const id = getAdapterId(adapter.info)
    let adapterPropList = cloneDeep(propList)
    if ("title" in adapter.info && adapter.info.description === adapter.info.title) {
      adapterPropList = propList.filter(value => value !== "title")
    }
    const isPluginAdapter =
      adapter.info.type === PeekAdapterType.PEEK_PLUGIN_ADAPTER &&
      adapterPlugins.findIndex(
        (ap: EngineCapabilitiesPluginInfo) =>
          "pluginId" in adapter.info && ap.clsid === adapter.info.pluginId
      ) !== -1
    return (
      <AdapterRow key={id}>
        <AdapterItem>
          <Title>{adapter.info.description}</Title>
          <PropTable
            propList={adapterPropList}
            data={adapter}
            propToLabel={propToLabel}
            formatProp={formatAdapterProp}
            skipEmptyRows={true}
          />
        </AdapterItem>
        {((adapter.info.type !== PeekAdapterType.PEEK_PLUGIN_ADAPTER &&
          supportsConfigure(adapter.info)) ||
          isPluginAdapter) && (
          <ButtonStrip>
            <SecondaryButton
              size="sm"
              disabled={!canConfigureEngine}
              onClick={() => onConfigure(adapter.info)}
            >
              <FontAwesome name="sliders" /> Adapter Options
            </SecondaryButton>
            {"deletable" in adapter.info && adapter.info.deletable && isPluginAdapter && (
              <SecondaryDangerButton
                disabled={captureAdapterIds.includes(id)}
                size="sm"
                onClick={() => onDelete(adapter.info)}
              >
                <FontAwesome name="trash-o" /> Delete
              </SecondaryDangerButton>
            )}
          </ButtonStrip>
        )}
      </AdapterRow>
    )
  })
  return <AdapterTable>{rows}</AdapterTable>
}

type PluginAdapterParams = {
  adapterId: string
  pluginId: string
  pluginName: string
  title: string
}

type AdaptersViewProps = RouteComponentProps & {
  dispatch: AppDispatch
  engine: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  authToken: string
}

type AdaptersViewState = {
  adapters: Adapter[]
  captures: CaptureProperties[]
  deleteAdapterId: string | null
  fetchError: any | null
  pluginAdapterParams: PluginAdapterParams | null
  showConfigureAdapters: boolean
  showDeleteConfirm: boolean
  showPluginAdapterModal: boolean
}

class AdaptersView extends React.Component<AdaptersViewProps, AdaptersViewState> {
  state: AdaptersViewState = {
    adapters: [],
    captures: [],
    deleteAdapterId: null,
    fetchError: null,
    pluginAdapterParams: null,
    showConfigureAdapters: false,
    showDeleteConfirm: false,
    showPluginAdapterModal: false,
  }

  componentDidMount() {
    this.onRefresh()
  }

  onConfigure = (adapter: AdapterInfo) => {
    if ("identifier" in adapter && "pluginId" in adapter && "pluginName" in adapter) {
      this.setState({
        pluginAdapterParams: {
          adapterId: adapter.identifier,
          pluginId: adapter.pluginId,
          pluginName: adapter.pluginName,
          title: "",
        },
        showPluginAdapterModal: true,
      })
    } else {
      this.props.history.push(`${this.props.match.url}/${getAdapterId(adapter)}/${adapter.type}/`)
    }
  }

  onConfigureAdaptersClose = () => {
    this.setState({
      showConfigureAdapters: false,
    })
    this.onRefresh()
  }

  onDelete = (adapter: AdapterInfo) => {
    const id = getAdapterId(adapter)
    if (id !== "") {
      this.setState({
        deleteAdapterId: id,
        showDeleteConfirm: true,
      })
    }
  }

  onDeleteCancel = () => {
    this.setState({
      deleteAdapterId: null,
      showDeleteConfirm: false,
    })
  }

  onDeleteOK = () => {
    if (this.state.deleteAdapterId !== null) {
      const { engine, authToken } = this.props
      deleteAdapter(engine, authToken, this.state.deleteAdapterId)
        .then(() => {
          this.onRefresh()
        })
        .catch(error => {
          this.setState({ fetchError: error })
        })
    }
    this.setState({
      deleteAdapterId: null,
      showDeleteConfirm: false,
    })
  }

  onInsert = () => {
    this.setState({
      showConfigureAdapters: true,
    })
  }

  onPluginAdapterClose = () => {
    this.setState({
      pluginAdapterParams: null,
      showPluginAdapterModal: false,
    })
    this.onRefresh()
  }

  onRefresh = () => {
    const { engine, authToken } = this.props
    const requestAdapters = fetchAdapters(engine, authToken)
    const requestCaptures = fetchCaptures(engine, authToken)
    Promise.all([requestAdapters, requestCaptures])
      .then(([adapters, captures]) => {
        if (Array.isArray(adapters.adapters) && Array.isArray(captures.captures)) {
          adapters.adapters.sort((a: Adapter, b: Adapter) => {
            const aFeatures = "features" in a.info && a.info.features ? a.info.features : 0
            const bFeatures = "features" in b.info && b.info.features ? b.info.features : 0
            if (aFeatures !== 0 && bFeatures === 0) {
              return -1
            } else if (aFeatures === 0 && bFeatures !== 0) {
              return 1
            }
            return collator.compare(a.info.description, b.info.description)
          })
          this.setState({
            adapters: adapters.adapters,
            captures: captures.captures,
          })
        }
      })
      .catch(error => {
        this.setState({ fetchError: error })
      })
  }

  onRefreshAdapters = () => {
    const { engine, authToken } = this.props
    refreshAdapters(engine, authToken).finally(() => {
      this.onRefresh()
    })
  }

  supportsConfigure = (adapter: AdapterInfo): boolean => {
    const { engineCapabilities } = this.props

    // if this media type is not enabled, don't allow it
    if (engineCapabilities && engineCapabilities.activationInfo.capabilities !== undefined) {
      let supported = false

      switch (adapter.mediaType) {
        case MediaType.MEDIA_TYPE_802_3:
          switch (adapter.mediaSubType) {
            case MediaSubType.MEDIA_SUBTYPE_NATIVE:
              supported = hasActivationCapability(
                engineCapabilities.activationInfo.capabilities,
                ActivationType.ACTIVATION_TYPE_CAPTURE_ETHERNET
              )
              break
            case MediaSubType.MEDIA_SUBTYPE_802_11_B:
            case MediaSubType.MEDIA_SUBTYPE_802_11_A:
            case MediaSubType.MEDIA_SUBTYPE_802_11_GEN:
              supported = hasActivationCapability(
                engineCapabilities.activationInfo.capabilities,
                ActivationType.ACTIVATION_TYPE_CAPTURE_WIRELESS
              )
              break
          }
          break
        case MediaType.MEDIA_TYPE_WAN:
        case MediaType.MEDIA_TYPE_COWAN:
          switch (adapter.mediaSubType) {
            case MediaSubType.MEDIA_SUBTYPE_WAN_PPP:
            case MediaSubType.MEDIA_SUBTYPE_WAN_FRAME_RELAY:
            case MediaSubType.MEDIA_SUBTYPE_WAN_X25:
            case MediaSubType.MEDIA_SUBTYPE_WAN_X25E:
            case MediaSubType.MEDIA_SUBTYPE_WAN_IPARS:
            case MediaSubType.MEDIA_SUBTYPE_WAN_U200:
            case MediaSubType.MEDIA_SUBTYPE_WAN_Q931:
            default:
              supported = hasActivationCapability(
                engineCapabilities.activationInfo.capabilities,
                ActivationType.ACTIVATION_TYPE_CAPTURE_WAN
              )
              break
          }
          break
      }
      if (!supported) return false

      // also check for GIG
      if (adapter.clsid === "56A9F62B-0048-469E-BD47-121AB13237C6" && "features" in adapter) {
        if ((adapter.features & PeekAdapterFeatures.PEEK_ADAPTER_GIG_SUPPORT) !== 0) {
          supported = hasActivationCapability(
            engineCapabilities.activationInfo.capabilities,
            ActivationType.ACTIVATION_TYPE_CAPTURE_GIG_HARDWARE
          )
        }
      }
      if (!supported) return false
    }

    return true
  }

  render() {
    const { engineCapabilities } = this.props
    const {
      adapters,
      captures,
      pluginAdapterParams,
      showConfigureAdapters,
      showDeleteConfirm,
      showPluginAdapterModal,
    } = this.state

    // make sure the user can configure the engine
    let canConfigureEngine = true
    if (engineCapabilities) {
      const policies = engineCapabilities.userRights.policies
      canConfigureEngine = policies.includes(EngineUserPolicies.configureEngine)
    }

    const adapterPlugins: EngineCapabilitiesPluginInfo[] = []
    if (engineCapabilities && Array.isArray(engineCapabilities.pluginsInfo)) {
      engineCapabilities.pluginsInfo.forEach((info: EngineCapabilitiesPluginInfo) => {
        if (
          Array.isArray(info.categoryIds) &&
          info.categoryIds.includes(PluginCategory.PLUGIN_CATEGORY_OMNI_WEB_PLUGIN_ADAPTER)
        ) {
          adapterPlugins.push({ ...info })
        }
      })
    }

    return (
      <View>
        <Helmet title="Adapters" />
        <BreadcrumbItem to={this.props.match.url} title="Adapters" />
        <ViewHeader>
          <ViewHeaderTitle title="Adapters" count={adapters.length} />
          <ViewHeaderButtons>
            {adapterPlugins.length > 0 && (
              <LightButton
                aria-label="Insert"
                id="insert"
                disabled={!canConfigureEngine}
                onClick={() => this.onInsert()}
              >
                <FontAwesome name="plus" /> Insert
              </LightButton>
            )}
            <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefreshAdapters}>
              <FontAwesome name="refresh" />
            </LightButton>
          </ViewHeaderButtons>
        </ViewHeader>
        <ViewMaxWidth>
          <ViewContent>
            {adapters && (
              <AdaptersTable
                adapterPlugins={adapterPlugins}
                adapters={adapters}
                canConfigureEngine={canConfigureEngine}
                captures={captures}
                onConfigure={this.onConfigure}
                onDelete={this.onDelete}
                supportsConfigure={this.supportsConfigure}
              />
            )}
          </ViewContent>
        </ViewMaxWidth>
        {showConfigureAdapters && (
          <ConfigureAdaptersModal
            adapterPlugins={adapterPlugins}
            isOpen={showConfigureAdapters}
            onClose={this.onConfigureAdaptersClose}
          />
        )}
        {showDeleteConfirm && (
          <ConfirmationModal
            message="Are you sure you want to delete the adapter?"
            onNo={this.onDeleteCancel}
            onYes={this.onDeleteOK}
            show={showDeleteConfirm}
            title="Delete Adapter"
          />
        )}
        {showPluginAdapterModal && pluginAdapterParams && (
          <PluginAdapterModal
            adapterId={pluginAdapterParams.adapterId}
            adapterTitle={pluginAdapterParams.title}
            pluginId={pluginAdapterParams.pluginId}
            pluginName={pluginAdapterParams.pluginName}
            onClose={this.onPluginAdapterClose}
          />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  engineCapabilities: getCapabilities(state),
  authToken: getAuthToken(state),
})

export default connect(mapStateToProps)(withRouter(AdaptersView))
