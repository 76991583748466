import * as React from "react"
import cn from "classnames"
import styled from "styled-components"
import { useReactToPrint } from "react-to-print"
import { useSelector } from "react-redux"
import FontAwesome from "react-fontawesome"
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledDropdownWithPortal,
} from "../common/Dropdown"
import { IconDropdownToggle, LightDropdownToggle } from "../common/Buttons"
import { InsertNameEntry } from "../InsertNamesModal"
import lightTheme from "../../themes/lightTheme"
import { isDarkTheme } from "../../store"
import { PacketDecodeTextFormat } from "../../api/api"
import { Filter, NameResolverRequestEntry } from "../../api/types"
import { parseMediaSpec } from "../../utils/mediaSpec"
import { MediaSpecType } from "../../api/types/mediaTypes"
import {
  PacketDecode,
  DecodeDisplayFlag,
  DecodeDisplayType,
  DecodeTagClass,
  DecodeTagType,
  DecodeTagStyle,
  DecodeSnippetItem,
  DecodeSnippet,
} from "./types"

const DecodePaneOuter = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const DecodePaneInner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  background-color: ${props => props.theme.tableBackgroundColor};
  border-left: 1px solid ${props => props.theme.tableBorderColor};
  border-bottom: 1px solid ${props => props.theme.tableBorderColor};
`

const DecodePaneStyle = styled.pre`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: visible;
  padding: 8px;
  margin: 0;
  font-size: 1rem;
  color: ${props => props.theme.textColor};
  cursor: default;
  outline: none;
  border: none;

  & .line {
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 0.25rem;
    cursor: default;
    white-space: nowrap;
  }

  & .line[hidden] {
    display: none;
  }

  & .line .expand-icon {
    margin-right: 0.25rem;
    opacity: 0.65;
    cursor: pointer;
  }

  & .line .icon {
    margin-right: 0.25rem;
  }

  & .line .plain,
  & .line .style-0 {
    color: ${props => props.theme.decodePlainColor};
  }

  & .line .layer,
  & .line .style-1,
  & .line .style-7 {
    color: ${props => props.theme.decodeLayerColor};
    text-decoration: underline;
    font-weight: bold;
  }

  & .line .layer.summary,
  & .line .style-1.summary,
  & .line .style-7.summary {
    text-decoration: none;
  }

  & .line .data,
  & .line .style-2,
  & .line .style-9 {
    color: ${props => props.theme.decodeDataColor};
    font-weight: bold;
  }

  & .line .header,
  & .line .style-3,
  & .line .style-10 {
    color: ${props => props.theme.decodeHeaderColor};
    font-weight: bold;
  }

  & .line .message,
  & .line .style-4 {
    color: ${props => props.theme.decodeMessageColor};
    font-style: italic;
  }

  & .line .dump,
  & .line .style-6 {
    color: ${props => props.theme.decodeDumpColor};
  }

  & .line .debug,
  & .line .style-5 {
    color: ${props => props.theme.decodeDebugColor};
    display: none;
  }

  & .line .offset,
  & .line .style-11 {
    color: ${props => props.theme.decodeOffsetColor};
    font-size: 11px;
  }

  & .line .name,
  & .line .style-15 {
    font-style: italic;
  }

  @media screen {
    & .line:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    & .line.selected {
      background-color: ${props => props.theme.selectedBackgroundColor};
      border-color: ${props => props.theme.selectedBackgroundColor};
    }

    & .line.selected > * {
      color: ${props => props.theme.selectedColor};
    }

    & .line .commands {
      visibility: hidden;
    }

    & .line:hover .commands {
      visibility: visible;
    }

    /*
    & .line.selected .commands {
      visibility: visible;
    }
    */

    & .line:focus-within .commands,
    & .line .commands:focus-within {
      visibility: visible;
    }
  }

  @media print {
    display: block;
    background: none;

    @page {
      margin: 0.5in;
    }

    & .line .expand-icon,
    & .line .icon,
    & .line .commands {
      display: none;
    }

    & .line .plain,
    & .line .style-0 {
      color: ${() => lightTheme.decodePlainColor};
    }

    & .line .layer,
    & .line .style-1,
    & .line .style-7 {
      color: ${() => lightTheme.decodeLayerColor};
    }

    & .line .data,
    & .line .style-2,
    & .line .style-9 {
      color: ${() => lightTheme.decodeDataColor};
    }

    & .line .header,
    & .line .style-3,
    & .line .style-10 {
      color: ${() => lightTheme.decodeHeaderColor};
    }

    & .line .message,
    & .line .style-4 {
      color: ${() => lightTheme.decodeMessageColor};
    }

    & .line .dump,
    & .line .style-6 {
      color: ${() => lightTheme.decodeDumpColor};
    }

    & .line .debug,
    & .line .style-5 {
      color: ${() => lightTheme.decodeDebugColor};
    }

    & .line .offset,
    & .line .style-11 {
      color: ${() => lightTheme.decodeOffsetColor};
    }
  }
`

function snippetToMediaSpecType(snippet: DecodeSnippet) {
  switch (snippet.tagClass) {
    case DecodeTagClass.Address:
      switch (snippet.tagType) {
        case DecodeTagType.IPv4Address:
          return MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
        case DecodeTagType.IPv6Address:
          return MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS
        case DecodeTagType.EthernetAddress:
          return MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS
        case DecodeTagType.TokenRingAddress:
          return MediaSpecType.MEDIA_SPEC_TYPE_TOKEN_RING_ADDRESS
        case DecodeTagType.AppleTalkLongAddress:
          return MediaSpecType.MEDIA_SPEC_TYPE_APPLETALK_ADDRESS
      }
      break
    case DecodeTagClass.Protocol:
      switch (snippet.tagType) {
        case DecodeTagType.EthernetProtocol:
          return MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_PROTOCOL
        case DecodeTagType.LSAPProtocol:
          return MediaSpecType.MEDIA_SPEC_TYPE_LSAP
        case DecodeTagType.SNAPProtocol:
          return MediaSpecType.MEDIA_SPEC_TYPE_SNAP
      }
      break
    case DecodeTagClass.Port:
      switch (snippet.tagType) {
        case DecodeTagType.IPPort:
          return MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT
        case DecodeTagType.NetwarePort:
          return MediaSpecType.MEDIA_SPEC_TYPE_NW_PORT
      }
      break
  }
  return MediaSpecType.MEDIA_SPEC_TYPE_NULL
}

type DecodeLineProps = {
  snippets: DecodeSnippet[]
  line: number
  hidden: boolean
  selected: boolean
  expandable: boolean
  expanded: boolean
  encoding: number
  showOffsets: boolean
  isDark: boolean
  ancestralText: string
  duplicateSiblingIndex: number
  onClickLine?: (line: number, offset: number, size: number, mask: string) => void
  onClickLineIcon?: (line: number) => void
  onMakeFilter: (filter: Filter) => void
  onInsertIntoNameTable: (insertNameEntry: InsertNameEntry) => void
  onResolveNames: (entries: NameResolverRequestEntry[]) => void
  onAddDecodeColumn: null | ((fullLabel: string, shortLabel: string, text: string) => void)
}

const DecodeLine = ({
  snippets,
  line,
  hidden,
  selected,
  expandable,
  expanded,
  encoding,
  showOffsets,
  isDark,
  ancestralText,
  duplicateSiblingIndex,
  onClickLine,
  onClickLineIcon,
  onMakeFilter,
  onInsertIntoNameTable,
  onResolveNames,
  onAddDecodeColumn,
}: DecodeLineProps) => {
  if (Array.isArray(snippets)) {
    const nodes = []
    let curCol = 0
    let lineOffset = 0
    let lineSize = 0
    let lineMask = ""
    let hasData = false
    let filterSnippet: DecodeSnippet | null = null
    let insertNameSnippet: DecodeSnippet | null = null
    let resolveNameSnippet: DecodeSnippet | null = null
    for (let i = 0; i < snippets.length; i++) {
      const snippet = snippets[i]
      const { text, tagClass, tagType, style, type, column, offset, size, mask, red, green, blue } =
        snippet
      const colorStyle: React.CSSProperties = {}
      if (column !== 0) {
        if (column > curCol) {
          const spaces = column - curCol
          const pad = "".padStart(spaces, "\u00a0") // nbsp
          nodes.push(<span key={`pad-${column}`}>{pad}</span>)
          curCol += spaces
        }
      }
      if (i === 0) {
        if (expandable) {
          const icon = expanded ? "chevron-down" : "chevron-right"
          nodes.push(
            <span
              key="icon"
              className="expand-icon"
              onClick={() => {
                if (onClickLineIcon != null) {
                  onClickLineIcon(line)
                }
              }}
            >
              <FontAwesome name={icon} fixedWidth />
            </span>
          )
        } else {
          /*
          let icon = "blank"
          let flip: FontAwesomeFlip | undefined
          for (const s of snippets) {
            if (s.tagClass === DecodeTagClass.Protocol) {
              icon = "code-fork"
              flip = "vertical"
            } else if (s.tagClass === DecodeTagClass.Address) {
              icon = "laptop"
            } else if (s.tagClass === DecodeTagClass.Port) {
              icon = "handshake-o"
            }
          }
          nodes.push(
            <span key="icon" className="icon">
              <FontAwesome name={icon} fixedWidth flip={flip} />
            </span>
          )
          */
          nodes.push(<span key="icon" className="icon" style={{ width: "15.43px" }} />)
        }
      }

      if (text.length > 0 && style !== DecodeTagStyle.Invisible) {
        if (!isDark && red != null && green != null && blue != null) {
          colorStyle.color = `rgb(${red},${green},${blue})`
        }
        hasData = hasData || style === DecodeTagStyle.Plain // Plain style is data
        nodes.push(
          <span key={i} className={`style-${style}`} style={colorStyle}>
            {text}
          </span>
        )
        curCol += text.length
      }

      if (offset != null && size != null) {
        if (lineOffset === 0) {
          lineOffset = offset
        } else {
          lineOffset = Math.min(lineOffset, offset)
        }
        if (lineSize === 0) {
          lineSize = size
        } else {
          const lineEndOffset = Math.max(lineOffset + lineSize, offset + size)
          lineSize = lineEndOffset - lineOffset
        }
        if (mask != null) {
          lineMask = mask
        }
      }

      if (style === DecodeTagStyle.Plain) {
        if (filterSnippet === null) {
          switch (tagClass) {
            case DecodeTagClass.Address:
            case DecodeTagClass.Protocol:
            case DecodeTagClass.Port:
              switch (tagType) {
                case DecodeTagType.AppleTalkDDPDestinationAddress:
                case DecodeTagType.AppleTalkDDPSourceAddress:
                case DecodeTagType.AppleTalkShortAddress:
                  break
                default:
                  filterSnippet = snippet
                  break
              }
              break
            case DecodeTagClass.Value:
              if (size != null && size > 0) {
                filterSnippet = snippet
              }
              break
            case DecodeTagClass.String:
              if (type === DecodeDisplayType.ASCII && size != null && size > 0) {
                filterSnippet = snippet
              }
              break
          }
        }

        if (insertNameSnippet === null) {
          switch (tagClass) {
            case DecodeTagClass.Address:
            case DecodeTagClass.Protocol:
            case DecodeTagClass.Port:
              insertNameSnippet = snippet
              break
          }
        }

        if (resolveNameSnippet === null) {
          switch (tagClass) {
            case DecodeTagClass.Address:
              switch (tagType) {
                case DecodeTagType.IPv4Address:
                case DecodeTagType.IPv6Address:
                  resolveNameSnippet = snippet
                  break
              }
              break
          }
        }
      }
    }

    if (showOffsets && lineSize !== 0 && hasData) {
      // Format offset
      let offsetText = ""
      let maskText = ""
      if (lineMask.length > 0) {
        maskText += `\u00a0Mask ${lineMask}`
      }
      if (lineSize === 1) {
        offsetText = `\u00a0\u00a0[${lineOffset}${maskText}]`
      } else {
        offsetText = `\u00a0\u00a0[${lineOffset}-${lineOffset + lineSize - 1}${maskText}]`
      }
      nodes.push(
        <span key="offset" className="offset">
          {offsetText}
        </span>
      )
    }

    const getFilter = () => {
      let filter: Filter | null = null
      if (filterSnippet !== null) {
        switch (filterSnippet.tagClass) {
          case DecodeTagClass.Address:
            filter = {
              clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
              color: "#000000",
              comment: "",
              created: "",
              group: "",
              id: "",
              modified: "",
              name: "Untitled",
              rootNode: {
                accept1To2: true,
                accept2To1: true,
                address1: filterSnippet.text,
                address2: "",
                clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
                comment: "",
                inverted: false,
                type: snippetToMediaSpecType(filterSnippet),
              },
            }
            break
          case DecodeTagClass.Protocol:
            filter = {
              clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
              color: "#000000",
              comment: "",
              created: "",
              group: "",
              id: "",
              modified: "",
              name: "Untitled",
              rootNode: {
                clsid: "A43DDCC0-CDD2-46B4-8114-68E5FAF35112",
                comment: "",
                inverted: false,
                protocol: parseMediaSpec(filterSnippet.text, snippetToMediaSpecType(filterSnippet)),
                protospecPath: "",
                sliceToHeader: false,
              },
            }
            break
          case DecodeTagClass.Port:
            filter = {
              clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
              color: "#000000",
              comment: "",
              created: "",
              group: "",
              id: "",
              modified: "",
              name: "Untitled",
              rootNode: {
                accept1To2: true,
                accept2To1: true,
                clsid: "B3279AE9-91E1-4D0A-8ABD-6D1BDC5471A9",
                comment: "",
                inverted: false,
                port1: filterSnippet.text,
                port2: "",
                type: snippetToMediaSpecType(filterSnippet),
              },
            }
            break
          case DecodeTagClass.Value:
            if (
              filterSnippet.text.length > 0 &&
              filterSnippet.offset != null &&
              filterSnippet.size != null &&
              filterSnippet.size > 0
            ) {
              const isSigned = (filterSnippet.flags & DecodeDisplayType.SignedDecimal) !== 0
              let value = 0
              if (filterSnippet.type === DecodeDisplayType.Hex) {
                value = parseInt(filterSnippet.text, 16)
              } else if (filterSnippet.type === DecodeDisplayType.Binary) {
                if (filterSnippet.text.startsWith("%")) {
                  value = parseInt(filterSnippet.text.slice(1), 2)
                } else {
                  value = parseInt(filterSnippet.text, 2)
                }
              } else {
                value = parseInt(filterSnippet.text, 10)
              }
              let valueType = 0
              let mask = 0
              switch (filterSnippet.tagType) {
                case DecodeTagType.Int8:
                  valueType = isSigned ? 1 : 0
                  mask = 0xff
                  break
                case DecodeTagType.Int16:
                  valueType = isSigned ? 3 : 2
                  mask = 0xffff
                  break
                case DecodeTagType.Int32:
                  valueType = isSigned ? 5 : 4
                  mask = 0xffffffff
                  break
                case DecodeTagType.Int64:
                  valueType = isSigned ? 7 : 6
                  mask = 0xffffffffffffffff // TODO
                  break
              }

              if (filterSnippet.mask != null && filterSnippet.mask.length > 0) {
                mask = parseInt(filterSnippet.mask)
              }

              let valueFlags = 0
              if (
                (filterSnippet.flags & DecodeDisplayFlag.LittleEndian) === 0 &&
                filterSnippet.size > 1
              ) {
                valueFlags |= 0x01 // valueFilterFlagNetworkByteOrder
              }
              if (filterSnippet.type === DecodeDisplayType.SignedDecimal) {
                valueFlags |= 0x02 // valueFilterFlagSigned
              }
              if (
                filterSnippet.type === DecodeDisplayType.Hex ||
                filterSnippet.text.startsWith("0x")
              ) {
                valueFlags |= 0x04 // valueFilterFlagHex
              }

              if (!Number.isNaN(value) && !Number.isNaN(mask)) {
                filter = {
                  clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
                  color: "#000000",
                  comment: "",
                  created: "",
                  group: "",
                  id: "",
                  modified: "",
                  name: "Untitled",
                  rootNode: {
                    clsid: "019D6856-7380-4FE6-99E7-04EC78732AFB",
                    comment: "",
                    inverted: false,
                    mask: {
                      data: mask,
                      type: valueType,
                    },
                    offset: {
                      data: filterSnippet.offset,
                      pspec: 0,
                    },
                    value: {
                      data: value,
                      type: valueType,
                    },
                    valueFlags: valueFlags,
                    valueOperation: 1, // equals
                  },
                }
              }
            }
            break
          case DecodeTagClass.String:
            if (
              filterSnippet.type === DecodeDisplayType.ASCII &&
              filterSnippet.offset != null &&
              filterSnippet.size != null &&
              filterSnippet.size > 0
            ) {
              filter = {
                clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
                color: "#000000",
                comment: "",
                created: "",
                group: "",
                id: "",
                modified: "",
                name: "Untitled",
                rootNode: {
                  caseSensitive: true,
                  clsid: "D64E6090-9351-4915-97E3-067251D7546A",
                  codePage: encoding,
                  comment: "",
                  endOffset: filterSnippet.offset + filterSnippet.size,
                  inverted: false,
                  patternData: filterSnippet.text,
                  patternFlags: 0,
                  patternType: 0, // patternFilterTypeAscii
                  pspec: 0,
                  startOffset: filterSnippet.offset,
                },
              }
            }
            break
        }
      }
      return filter
    }

    if (nodes.length <= 1) return null

    const snippetIndex: number = snippets.findIndex(snippet => {
      return snippet.style !== DecodeTagStyle.Invisible
    })

    return (
      <div
        key={line}
        hidden={hidden}
        onClick={() => {
          if (onClickLine != null) {
            onClickLine(line, lineOffset, lineSize, lineMask)
          }
        }}
        className={cn("line", { selected, expandable, expanded })}
        data-id={line}
        data-offset={lineOffset}
        data-size={lineSize}
        data-mask={lineMask}
      >
        <span className="commands">
          <UncontrolledDropdownWithPortal
            dropdownToggle={
              <IconDropdownToggle aria-label="Decode options">
                <FontAwesome name="ellipsis-h" fixedWidth />
              </IconDropdownToggle>
            }
          >
            <DropdownMenu>
              <DropdownItem
                disabled={filterSnippet === null}
                onClick={() => {
                  const filter = getFilter()
                  if (filter != null) {
                    onMakeFilter(filter)
                  }
                }}
              >
                Make Filter
              </DropdownItem>
              <DropdownItem
                disabled={insertNameSnippet === null}
                onClick={() => {
                  if (insertNameSnippet !== null) {
                    onInsertIntoNameTable({
                      title: "Insert Name",
                      entry: insertNameSnippet.text,
                      entryType: snippetToMediaSpecType(insertNameSnippet),
                    })
                  }
                }}
              >
                Insert Into Name Table
              </DropdownItem>
              <DropdownItem
                disabled={resolveNameSnippet === null}
                onClick={() => {
                  if (resolveNameSnippet !== null) {
                    onResolveNames([
                      {
                        entry: resolveNameSnippet.text,
                        entryType: snippetToMediaSpecType(resolveNameSnippet),
                      },
                    ])
                  }
                }}
              >
                Resolve Names
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem
                disabled={
                  snippets.length < 1 ||
                  snippetIndex === -1 ||
                  onAddDecodeColumn === null ||
                  ![
                    DecodeTagStyle.Data,
                    DecodeTagStyle.Header,
                    DecodeTagStyle.IndentData,
                    DecodeTagStyle.IndentHeader,
                  ].includes(snippets[snippetIndex].style)
                }
                onClick={() => {
                  if (onAddDecodeColumn) {
                    const modSummary = snippets[snippetIndex].summary || snippets[0].text
                    const duplicateIndex =
                      duplicateSiblingIndex > 0 ? `[${duplicateSiblingIndex}]` : ""
                    const summary =
                      (modSummary.endsWith(":")
                        ? modSummary.substring(0, modSummary.length - 1)
                        : modSummary) + duplicateIndex
                    const modText = snippets[snippetIndex].text
                    const text =
                      (modText.endsWith(":") ? modText.substring(0, modText.length - 1) : modText) +
                      duplicateIndex
                    onAddDecodeColumn(ancestralText, summary, text)
                  }
                }}
              >
                Add as Decode Column
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdownWithPortal>
        </span>
        {nodes}
      </div>
    )
  }
  return null
}

type DecodeLineCollapsedProps = {
  item: DecodeSnippetItem
  line: number
  selected: boolean
  encoding: number
  showOffsets: boolean
  isDark: boolean
  onClickLine?: (line: number, offset: number, size: number, mask: string) => void
  onClickLineIcon?: (line: number) => void
}

const DecodeLineCollapsed = ({
  item,
  line,
  selected,
  showOffsets,
  isDark,
  onClickLine,
  onClickLineIcon,
}: DecodeLineCollapsedProps) => {
  if (Array.isArray(item.snippets)) {
    const nodes = []
    let lineOffset = 0
    let lineSize = 0
    let lineMask = ""
    for (let i = 0; i < item.snippets.length; i++) {
      const snippet = item.snippets[i]
      const { summary, style, offset, size, mask, red, green, blue } = snippet
      if (i === 0) {
        nodes.push(
          <span
            key="icon"
            className="expand-icon"
            onClick={() => {
              if (onClickLineIcon != null) {
                onClickLineIcon(line)
              }
            }}
          >
            <FontAwesome name="chevron-right" fixedWidth />
          </span>
        )

        if (offset != null && size != null) {
          if (lineOffset === 0) {
            lineOffset = offset
          } else {
            lineOffset = Math.min(lineOffset, offset)
          }
          if (lineSize === 0) {
            lineSize = size
          } else {
            const lineEndOffset = Math.max(lineOffset + lineSize, offset + size)
            lineSize = lineEndOffset - lineOffset
          }
          if (mask != null) {
            lineMask = mask
          }
        }

        // Format offset
        let offsetText = ""
        if (showOffsets) {
          if (lineSize === 1) {
            offsetText = `[${lineOffset}]`
          } else if (lineSize > 1) {
            offsetText = `[${lineOffset}-${lineOffset + lineSize - 1}]`
          }
          offsetText = offsetText.padEnd(10, "\u00a0")
        }
        if (offsetText.length > 0) {
          nodes.push(
            <span key="offset" className="offset">
              {offsetText}
            </span>
          )
        }
      }

      if (style !== DecodeTagStyle.Invisible) {
        if (summary != null && summary.length > 0) {
          const colorStyle: React.CSSProperties = {}
          if (!isDark && red != null && green != null && blue != null) {
            colorStyle.color = `rgb(${red},${green},${blue})`
          }
          nodes.push(
            <span key={i} className={`style-${style} summary`} style={colorStyle}>
              {summary}
            </span>
          )
          const spaces = summary.length < 12 ? 12 - summary.length : 1
          const pad = "".padStart(spaces, "\u00a0") // nbsp
          nodes.push(<span key={`pad-${i}`}>{pad}</span>)
        }
      }
    }

    if (item.children != null) {
      let key = nodes.length
      for (let i = 0; i < item.children.length; i++) {
        const childItem = item.children[i]
        const startKey = key
        for (let j = 0; j < childItem.snippets.length; j++) {
          const childSnippet = childItem.snippets[j]
          const { text, summary, style, tagClass, red, green, blue } = childSnippet
          const colorStyle: React.CSSProperties = {}
          if (!isDark && red != null && green != null && blue != null) {
            colorStyle.color = `rgb(${red},${green},${blue})`
          }
          if (style === DecodeTagStyle.Message || style === DecodeTagStyle.Invisible) continue
          if (
            tagClass === DecodeTagClass.Address ||
            tagClass === DecodeTagClass.Protocol ||
            tagClass === DecodeTagClass.Port
          ) {
            if (j + 1 < childItem.snippets.length) {
              const nextSnippet = childItem.snippets[j + 1]
              if (nextSnippet.style === DecodeTagStyle.NameTable) continue
            }
          }
          if (
            (tagClass === DecodeTagClass.String || tagClass === DecodeTagClass.Object) &&
            style !== DecodeTagStyle.Plain
          ) {
            if (summary == null) break
            if (summary === "Reserved:" || summary === "Pad:") continue
            nodes.push(
              <span key={key} className={`style-${style} summary`} style={colorStyle}>
                {summary.replace(":", "=")}
              </span>
            )
            key++
          } else {
            nodes.push(
              <span key={key} className={`style-${style} summary`} style={colorStyle}>
                {text}
              </span>
            )
            key++
          }
        }
        if (key !== startKey) {
          nodes.push(<span key={key}>&nbsp;</span>)
          key++
        }
      }
    }

    if (nodes.length === 0) return null
    return (
      <div
        key={line}
        onClick={() => {
          if (onClickLine != null) {
            onClickLine(line, lineOffset, lineSize, lineMask)
          }
        }}
        className={cn("line expandable expanded", { selected })}
        data-id={line}
        data-offset={lineOffset}
        data-size={lineSize}
        data-mask={lineMask}
      >
        <span style={{ width: "19.43px" }} />
        {nodes}
      </div>
    )
  }
  return null
}

export type DecodePaneProps = {
  decode: PacketDecode | null
  encoding: number
  setEncoding: (encoding: number) => void
  showOffsets: boolean
  setShowOffsets: (showOffsets: boolean) => void
  selectedOffset?: number
  onClickLine?: (line: number, offset: number, size: number, mask: string) => void
  onMakeFilter: (filter: Filter) => void
  onInsertIntoNameTable: (insertNameEntry: InsertNameEntry) => void
  onResolveNames: (entries: NameResolverRequestEntry[]) => void
  onCopyAs: (format: PacketDecodeTextFormat) => void
  onAddDecodeColumn: null | ((fullLabel: string, shortLabel: string, text: string) => void)
}

const DecodePane = ({
  decode,
  encoding,
  setEncoding,
  showOffsets,
  setShowOffsets,
  selectedOffset,
  onClickLine,
  onMakeFilter,
  onInsertIntoNameTable,
  onResolveNames,
  onCopyAs,
  onAddDecodeColumn,
}: DecodePaneProps) => {
  const isDark = useSelector(isDarkTheme)
  const [selection, setSelection] = React.useState(-1)
  const [collapsedLines, setCollapsedLines] = React.useState(new Set<number>())
  const parentRef = React.useRef<HTMLPreElement | null>(null)

  const handlePrint = useReactToPrint({
    contentRef: parentRef,
    documentTitle: decode !== null ? `Packet ${decode.packet}` : undefined,
  })

  React.useEffect(() => {
    if (selectedOffset != null && parentRef.current != null) {
      let clickLine = 0
      let clickOffset = 0
      let clickSize = 0
      let clickMask = ""
      let clickElem: Element | null = null
      const lineList = parentRef.current.querySelectorAll(".line")
      for (let i = 0; i < lineList.length; i++) {
        const lineEl = lineList[i]
        if (lineEl instanceof HTMLDivElement) {
          const { id, offset, size, mask } = lineEl.dataset
          if (id != null && offset != null && size != null && mask != null) {
            const lineVal = +id
            const offsetVal = +offset
            const sizeVal = +size
            if (sizeVal > 0) {
              if (selectedOffset >= offsetVal && selectedOffset < offsetVal + sizeVal) {
                if (clickSize === 0 || sizeVal < clickSize) {
                  clickLine = lineVal
                  clickOffset = offsetVal
                  clickSize = sizeVal
                  clickMask = mask
                  clickElem = lineEl
                }
              }
            }
          }
        }
      }
      if (clickLine !== 0) {
        setSelection(clickLine)
        if (clickElem != null) {
          clickElem.scrollIntoView({ block: "nearest" })
        }
        if (onClickLine != null) {
          onClickLine(clickLine, clickOffset, clickSize, clickMask)
        }
      }
    }
  }, [selectedOffset, onClickLine])

  const onExpandAll = () => {
    setCollapsedLines(new Set<number>())
  }

  const onCollapseAll = () => {
    if (parentRef.current != null) {
      const newCollapsedLines = new Set<number>()
      const lineList = parentRef.current.querySelectorAll(".line.expandable")
      for (let i = 0; i < lineList.length; i++) {
        const lineEl = lineList[i]
        if (lineEl instanceof HTMLDivElement) {
          const { id } = lineEl.dataset
          if (id != null) {
            newCollapsedLines.add(+id)
          }
        }
      }
      setCollapsedLines(newCollapsedLines)
    }
  }

  /*
  const onChooseDecoder = () => {
    console.log("choose decoder")
  }
  */

  const onEncoding = (codePage: number) => {
    setEncoding(codePage)
  }

  const onShowHideOffsets = () => {
    setShowOffsets(!showOffsets)
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault()
        if (parentRef.current !== null) {
          const q = parentRef.current.querySelector(`.line[data-id="${selection + 1}"]`)
          if (q !== null && q instanceof HTMLElement) {
            let lineEl = q
            while (lineEl !== null && lineEl.hidden) {
              const sibling = lineEl.nextElementSibling
              if (sibling !== null && sibling instanceof HTMLElement) {
                lineEl = sibling
              } else {
                break
              }
            }
            if (lineEl !== null) {
              const id = lineEl.dataset.id
              if (id !== undefined) {
                const line = +id
                setSelection(line)
                lineEl.scrollIntoView({ block: "nearest" })
                if (onClickLine != null) {
                  const { offset, size, mask } = lineEl.dataset
                  if (offset != null && size != null && mask != null) {
                    onClickLine(line, Number(offset), Number(size), mask)
                  }
                }
              }
            }
          }
        }
        break
      case "ArrowUp":
        event.preventDefault()
        if (parentRef.current !== null) {
          const q = parentRef.current.querySelector(`.line[data-id="${selection - 1}"]`)
          if (q !== null && q instanceof HTMLElement) {
            let lineEl = q
            while (lineEl !== null && lineEl.hidden) {
              const sibling = lineEl.previousElementSibling
              if (sibling !== null && sibling instanceof HTMLElement) {
                lineEl = sibling
              } else {
                break
              }
            }
            if (lineEl !== null) {
              const id = lineEl.dataset.id
              if (id !== undefined) {
                const line = +id
                setSelection(line)
                lineEl.scrollIntoView({ block: "nearest" })
                if (onClickLine != null) {
                  const { offset, size, mask } = lineEl.dataset
                  if (offset != null && size != null && mask != null) {
                    onClickLine(line, Number(offset), Number(size), mask)
                  }
                }
              }
            }
          }
        }
        break
      case "ArrowLeft":
        {
          const newCollapsedLines = new Set(collapsedLines)
          newCollapsedLines.add(selection)
          setCollapsedLines(newCollapsedLines)
          event.preventDefault()
        }
        break
      case "ArrowRight":
        {
          const newCollapsedLines = new Set(collapsedLines)
          newCollapsedLines.delete(selection)
          setCollapsedLines(newCollapsedLines)
          event.preventDefault()
        }
        break
    }
  }

  const renderLines = (
    items: DecodeSnippetItem[],
    getNextLine: () => number,
    parentExpanded: boolean,
    ancestralText: string = ""
  ) => {
    const nodes: React.ReactNode[] = []
    const namesMap = new Map<string, number>()
    for (let i = 0; i < items.length; ++i) {
      const item = items[i]
      let expanded = true
      let line = 0
      let nameIndex = 0
      if (Array.isArray(item.snippets)) {
        line = getNextLine()
        expanded = !collapsedLines.has(line)
        const expandable = item.children != null && item.children.length > 0
        const isLayer = item.snippets.length > 0 && item.snippets[0].style === DecodeTagStyle.Layer
        if (item.snippets.length > 0) {
          const key = item.snippets[0].summary || item.snippets[0].text
          let val: number | undefined = namesMap.get(key)
          if (!val) {
            val = 0
          }
          nameIndex = val
          namesMap.set(key, val + 1)
        }
        if (isLayer && expandable && !expanded) {
          nodes.push(
            <DecodeLineCollapsed
              key={line}
              onClickLine={(line: number, offset: number, size: number, mask: string) => {
                setSelection(line)
                if (onClickLine != null) {
                  onClickLine(line, offset, size, mask)
                }
              }}
              onClickLineIcon={(line: number) => {
                const newCollapsedLines = new Set(collapsedLines)
                if (newCollapsedLines.has(line)) {
                  newCollapsedLines.delete(line)
                } else {
                  newCollapsedLines.add(line)
                }
                setCollapsedLines(newCollapsedLines)
              }}
              item={item}
              line={line}
              selected={line === selection}
              encoding={encoding}
              showOffsets={showOffsets}
              isDark={isDark}
            />
          )
        } else {
          nodes.push(
            <DecodeLine
              key={line}
              onClickLine={(line: number, offset: number, size: number, mask: string) => {
                setSelection(line)
                if (onClickLine != null) {
                  onClickLine(line, offset, size, mask)
                }
              }}
              onClickLineIcon={(line: number) => {
                const newCollapsedLines = new Set(collapsedLines)
                if (newCollapsedLines.has(line)) {
                  newCollapsedLines.delete(line)
                } else {
                  newCollapsedLines.add(line)
                }
                setCollapsedLines(newCollapsedLines)
              }}
              snippets={item.snippets}
              line={line}
              selected={line === selection}
              expandable={expandable}
              expanded={expanded}
              encoding={encoding}
              showOffsets={showOffsets}
              hidden={!parentExpanded}
              isDark={isDark}
              ancestralText={ancestralText}
              duplicateSiblingIndex={nameIndex}
              onMakeFilter={onMakeFilter}
              onInsertIntoNameTable={onInsertIntoNameTable}
              onResolveNames={onResolveNames}
              onAddDecodeColumn={onAddDecodeColumn}
            />
          )
        }
      }
      if (item.children != null && item.children.length > 0) {
        const text = item.snippets[0].summary || item.snippets[0].text
        const summary = text.endsWith(":") ? text.substring(0, text.length - 1) : text
        const duplicateIndex = nameIndex > 0 ? `[${nameIndex}]` : ""

        const lines = renderLines(
          item.children,
          getNextLine,
          expanded && parentExpanded,
          ancestralText + "/" + summary + duplicateIndex
        )
        nodes.push(lines)
      }
    }
    return nodes
  }

  let nextLine = 0
  const getNextLine = () => {
    nextLine = nextLine + 1
    return nextLine
  }

  return (
    <DecodePaneOuter>
      <DecodePaneInner>
        {decode != null ? (
          <>
            <DecodePaneStyle ref={parentRef} onKeyDown={onKeyDown} tabIndex={0}>
              {renderLines(decode.decode.lines, getNextLine, true)}
            </DecodePaneStyle>
            <UncontrolledDropdown
              style={{
                position: "absolute",
                top: "8px",
                right: "25px",
              }}
            >
              <LightDropdownToggle
                aria-label="Decode options"
                style={{
                  boxShadow: "0 3px 5px -1px rgba(0, 0, 0, 0.18)",
                  padding: 0,
                  width: "23px",
                  height: "23px",
                }}
              >
                <FontAwesome name="chevron-down" fixedWidth />
              </LightDropdownToggle>
              <DropdownMenu end>
                <DropdownItem onClick={onExpandAll}>Expand All</DropdownItem>
                <DropdownItem onClick={onCollapseAll}>Collapse All</DropdownItem>
                <DropdownItem divider />
                <DropdownItem active={showOffsets} onClick={() => onShowHideOffsets()}>
                  Show Offsets
                </DropdownItem>
                {/*
                <DropdownItem onClick={onChooseDecoder}>Choose Decoder</DropdownItem>
                */}
                <DropdownItem divider />
                <DropdownItem active={encoding === 0} onClick={() => onEncoding(0)}>
                  ASCII
                </DropdownItem>
                <DropdownItem active={encoding === 65001} onClick={() => onEncoding(65001)}>
                  UTF-8
                </DropdownItem>
                <DropdownItem
                  active={encoding !== 0 && encoding !== 65001}
                  onClick={() => onEncoding(-1)}
                >
                  More Encodings
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  onClick={() => {
                    if (handlePrint) {
                      handlePrint()
                    }
                  }}
                >
                  Print
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => onCopyAs("text/plain")}>Copy as Text</DropdownItem>
                <DropdownItem onClick={() => onCopyAs("application/rtf")}>
                  Copy as Rich Text
                </DropdownItem>
                <DropdownItem onClick={() => onCopyAs("text/html")}>Copy as HTML</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ) : null}
      </DecodePaneInner>
    </DecodePaneOuter>
  )
}

export default DecodePane
