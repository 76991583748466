import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { Alert } from "../common/Alert"
import { CenterContent } from "../common/Layout"
import ConfirmationModal from "../common/ConfirmationModal"
import FilterBox from "../common/FilterBox"
import { LightButton, SecondaryButton, SecondaryDangerButton } from "../common/Buttons"
import { Link } from "../common/Link"
import { Panel } from "../common/Panel"
import PropTable, { PropFormatterOptions } from "../common/PropTable"
import { Spinner } from "../common/Spinner"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import {
  View,
  ViewMaxWidth,
  ViewContent,
  ViewHeader,
  ViewHeaderTitle,
  ViewHeaderButtons,
} from "../common/View"
import { collator } from "../../utils/sortUtils"
import { formatISODateTime } from "../../utils/formatUtils"
import {
  getMultiSegmentAnalysisUrl,
  getMultiSegmentAnalysisProjectUrl,
  getNewDistributedForensicSearchUrl,
} from "../../routes"
import {
  getCapabilities,
  getServer,
  getServerAuthToken,
  getMSAListFilter,
  getShowLocalTime,
  getUserId,
} from "../../store"
import { setMSAListFilter } from "../../store/ui"
import * as API from "../../api/api"
import { MSAProjectItem, ResponseGetEngineCapabilities } from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"

const ViewHeaderButtonStrip = styled.div`
  display: flex;

  & .btn {
    white-space: nowrap;
    flex-grow: 1;
  }

  & > * + * {
    margin-left: 4px;
  }
`

const ProjectTable = styled.div`
  display: flex;
  flex-direction: column;
`

const ProjectPanel = styled(Panel)`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 8px;
  }
`

const ProjectTop = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const ProjectProperties = styled.div`
  flex-grow: 1;
`

const ProjectButtons = styled.div`
  display: flex;
  flex-direction: column;

  & button + button {
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    margin-top: 8px;

    & button + button {
      margin-left: 4px;
      margin-top: 0;
    }
  }
`

const propList = [
  ["status", "filter"],
  ["created", "owner"],
  ["startTime", "notes"],
  ["endTime", ""],
]

function propToLabel(prop: string) {
  switch (prop) {
    case "file":
      return "File"
    case "startTime":
      return "Start Time"
    case "endTime":
      return "End Time"
    case "duration":
      return "Duration"
    case "filter":
      return "Filter"
    case "created":
      return "Created"
    case "notes":
      return "Notes"
    case "owner":
      return "Owner"
    case "progress":
      return "Progress"
    case "status":
      return "Status"
    default:
      break
  }
  return ""
}

function formatProp(prop: string, project: MSAProjectItem, options?: PropFormatterOptions) {
  switch (prop) {
    case "file":
      return project.file
    case "startTime":
      return formatISODateTime(project.startTime, 3, options?.showLocalTime)
    case "endTime":
      return formatISODateTime(project.endTime, 3, options?.showLocalTime)
    case "duration":
      return undefined
    case "filter":
      return project.filter
    case "created":
      return formatISODateTime(project.created, 3, options?.showLocalTime)
    case "notes":
      return project.notes
    case "owner":
      return project.owner
    case "progress":
      return (project as any).progress
    case "status":
      return (project as any).status
    default:
      break
  }
  return undefined
}

const ProjectListView = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const queryClient = useQueryClient()
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const viewFilter = useSelector(getMSAListFilter)
  const userId = useSelector(getUserId)
  const engineCapabilities: ResponseGetEngineCapabilities = useSelector(getCapabilities)
  const [error, setError] = React.useState<string | null>(null)
  const [currentId, setCurrentId] = React.useState<string | null>(null)
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false)
  const [deletePending, setDeletePending] = React.useState<string[]>([])
  const showLocalTime = useSelector(getShowLocalTime)

  const { data: projects, error: queryError } = useQuery({
    refetchInterval: 10000,
    queryKey: ["msa-projects"],
    queryFn: () => {
      return API.fetchMSAProjects(server, serverAuthToken)
    },
    select: response => {
      if (Array.isArray(response.projects)) {
        response.projects.sort((a, b) => {
          let result = collator.compare(a.name, b.name)
          if (result === 0) {
            // Names are the same. Put the most recently created project on top.
            if (a.created && b.created) {
              if (a.created < b.created) {
                result = 1
              } else if (a.created > b.created) {
                result = -1
              }
            }
          }
          return result
        })
        return response.projects
      }
      return undefined
    },
  })

  React.useEffect(() => {
    if (queryError && "code" in queryError && "reason" in queryError) {
      setError(`${queryError.code} ${queryError.reason}`)
    }
  }, [queryError])

  const { mutate: deleteMSAProject } = useMutation({
    mutationFn: (id: string) => {
      setError(null)
      return API.deleteMSAProject(server, serverAuthToken, id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["msa-projects"] })
    },
    onError: () => {
      setError("Delete failed")
    },
    onSettled: (data, error, id) => {
      setDeletePending(prev => prev.filter(nextId => nextId !== id))
    },
  })

  const { mutate: stopMSAProject } = useMutation({
    mutationFn: (id: string) => {
      setError(null)
      return API.stopMSAProject(server, serverAuthToken, id)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["msa-projects"] })
    },
    onError: () => {
      setError("Failed to stop analysis")
    },
  })

  const onNewSearch = () => {
    history.push(getNewDistributedForensicSearchUrl())
  }

  const onDelete = (forensicSearchId: string) => {
    setCurrentId(forensicSearchId)
    setShowDeleteConfirm(true)
  }

  const onDeleteCancel = () => {
    setCurrentId(null)
    setShowDeleteConfirm(false)
  }

  const onDeleteOK = () => {
    const id = currentId
    setCurrentId(null)
    setShowDeleteConfirm(false)
    if (id !== null) {
      setDeletePending(prev => {
        const newDeletePending = prev.filter(nextId => nextId !== id)
        newDeletePending.push(id)
        return newDeletePending
      })
      deleteMSAProject(id)
    }
  }

  const onStop = (id: string) => {
    stopMSAProject(id)
  }

  const getMSAProjects = () => {
    let viewProjects = projects
    if (viewProjects && viewFilter) {
      const lowerCaseFilter = viewFilter.toLowerCase()
      viewProjects = viewProjects.filter(project =>
        project.name.toLowerCase().includes(lowerCaseFilter)
      )
    }
    return viewProjects
  }

  // Make sure the user has permissions.
  let canUploadFiles = true
  let canViewStats = true
  let canCreateForensicSearch = true
  let canDeleteForensicSearches = true
  if (engineCapabilities) {
    const policies = engineCapabilities.userRights.policies
    canUploadFiles = policies.includes(EngineUserPolicies.uploadFiles)
    canViewStats = policies.includes(EngineUserPolicies.viewStats)
    if (engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL)) {
      canCreateForensicSearch = policies.includes(EngineUserPolicies.createForensicSearch)
      canDeleteForensicSearches = policies.includes(EngineUserPolicies.deleteForensicSearches)
    }
  }

  // Get the filtered list of msa projects.
  const viewProjects = getMSAProjects()

  // Count is the total without any filtering.
  const count = viewProjects?.length ?? 0

  return (
    <View>
      <Helmet title="Multi-Segment Analysis Projects" />
      <BreadcrumbItem to={getMultiSegmentAnalysisUrl()} title="Multi-Segment Analysis Projects" />
      <ViewHeader>
        <ViewHeaderTitle title="Projects" count={count} />
        <ViewHeaderButtons>
          <FilterBox
            id="msa-project-filter"
            aria-label="Search"
            placeholder="Search"
            onChange={(filter: string) => {
              dispatch(setMSAListFilter(filter))
            }}
            value={viewFilter}
          />
          <ViewHeaderButtonStrip>
            <LightButton
              onClick={onNewSearch}
              disabled={!canUploadFiles || !canCreateForensicSearch}
            >
              <FontAwesome name="search" flip="horizontal" /> New Search
            </LightButton>
            <LightButton
              aria-label="Refresh"
              id="refresh"
              onClick={() => {
                setError(null)
                queryClient.invalidateQueries({ queryKey: ["msa-projects"] })
              }}
            >
              <FontAwesome name="refresh" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="refresh">
              Refresh
            </UncontrolledTooltip>
          </ViewHeaderButtonStrip>
        </ViewHeaderButtons>
      </ViewHeader>
      {error !== null ? <Alert color="danger">{error}</Alert> : null}
      {viewProjects ? (
        <ViewMaxWidth>
          <ViewContent>
            <ProjectTable>
              {viewProjects.map(project => {
                const isDeleting = deletePending.includes(project.id)
                return (
                  <ProjectPanel key={project.id}>
                    <ProjectTop>
                      <ProjectProperties>
                        <h5>
                          {canViewStats || userId === project.owner ? (
                            <Link to={getMultiSegmentAnalysisProjectUrl(project.id)}>
                              {project.name}
                            </Link>
                          ) : (
                            <>{project.name}</>
                          )}
                        </h5>
                        <PropTable
                          propList={propList}
                          data={project}
                          propToLabel={propToLabel}
                          formatProp={(prop: string, project: MSAProjectItem) => {
                            return formatProp(prop, project, { showLocalTime })
                          }}
                        />
                      </ProjectProperties>
                      <ProjectButtons>
                        <SecondaryButton size="sm" onClick={() => onStop(project.id)} hidden>
                          <FontAwesome fixedWidth name="stop" /> Stop
                        </SecondaryButton>
                        <SecondaryDangerButton
                          disabled={
                            isDeleting || (!canDeleteForensicSearches && userId !== project.owner)
                          }
                          size="sm"
                          onClick={() => onDelete(project.id)}
                        >
                          <FontAwesome
                            fixedWidth
                            name={isDeleting ? "circle-o-notch" : "trash-o"}
                            spin={isDeleting}
                          />{" "}
                          Delete
                        </SecondaryDangerButton>
                      </ProjectButtons>
                    </ProjectTop>
                  </ProjectPanel>
                )
              })}
            </ProjectTable>
          </ViewContent>
        </ViewMaxWidth>
      ) : (
        <CenterContent>
          <Spinner />
        </CenterContent>
      )}
      {showDeleteConfirm && (
        <ConfirmationModal
          message={<span>Are you sure you want to delete the multi-segment analysis project?</span>}
          onNo={onDeleteCancel}
          onYes={onDeleteOK}
          show={showDeleteConfirm}
          title="Delete Multi-Segment Analysis Project"
        />
      )}
    </View>
  )
}

export default ProjectListView
