import { BreadcrumbInterface } from "../api/types/componentTypes"

// Action types
export const types = {
  ADD_BREADCRUMB: "BREADCRUMB/ADD",
  UPDATE_BREADCRUMB: "BREADCRUMB/UPDATE",
  REMOVE_BREADCRUMB: "BREADCRUMB/REMOVE",
}

// Reducer
export default function reducer(state: BreadcrumbInterface[] = [], action: any) {
  switch (action.type) {
    case types.ADD_BREADCRUMB:
      return [...state, action.payload]
    case types.UPDATE_BREADCRUMB:
      return state.map(breadcrumb => {
        return breadcrumb.id === action.payload.id ? action.payload : breadcrumb
      })
    case types.REMOVE_BREADCRUMB:
      return state.filter(breadcrumb => {
        return breadcrumb.id !== action.payload.id
      })
    default:
      break
  }

  return state
}

// Actions

export const addBreadcrumb = (id: string, to: string, title: string) => ({
  type: types.ADD_BREADCRUMB,
  payload: { id, to, title },
})

export const updateBreadcrumb = (id: string, to: string, title: string) => ({
  type: types.UPDATE_BREADCRUMB,
  payload: { id, to, title },
})

export const removeBreadcrumb = (id: string) => ({
  type: types.REMOVE_BREADCRUMB,
  payload: { id },
})

// Selectors

export const getBreadcrumbs = (state: BreadcrumbInterface[]) => state
