import * as React from "react"
import { Form } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { EngineCapabilitiesPluginInfo, PluginObject, PluginOption } from "../../api/types"
import { PeekVariantType } from "../../api/types/peekTypes"
import { PluginsListForm } from "../CaptureOptionsView/PluginsListForm"
import { CLSID_COMPASS } from "../../utils/pluginUtils"

type PluginsListModalProps = {
  compassEnabled: boolean
  pluginsConfig: PluginOption[] | undefined
  pluginsInfo: EngineCapabilitiesPluginInfo[]
  pluginsList: PluginObject[] | undefined
  onOK: (pluginsConfig: PluginOption[], pluginsList: PluginObject[]) => void
  onCancel: () => void
}

type PluginsListModalState = {
  pluginsConfig: PluginOption[]
  pluginsList: PluginObject[]
}

const getInitialPluginsConfig = (props: PluginsListModalProps) => {
  if (props.pluginsConfig != null) {
    return [...props.pluginsConfig]
  } else if (props.pluginsInfo) {
    return props.pluginsInfo.map((info: EngineCapabilitiesPluginInfo) => {
      return {
        clsid: info.clsid,
        name: info.name,
        options: "",
      }
    })
  }
  return []
}

const getInitialPluginsList = (props: PluginsListModalProps) => {
  if (props.pluginsList != null) {
    return [...props.pluginsList]
  } else if (props.pluginsInfo) {
    return props.pluginsInfo.map((info: EngineCapabilitiesPluginInfo) => {
      return {
        type: PeekVariantType.PEEK_VT_BSTR,
        value: `{${info.clsid}}`,
      }
    })
  }
  return []
}

class PluginsListModal extends React.Component<PluginsListModalProps, PluginsListModalState> {
  state: PluginsListModalState = {
    pluginsConfig: getInitialPluginsConfig(this.props),
    pluginsList: getInitialPluginsList(this.props),
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    this.props.onOK(this.state.pluginsConfig, this.state.pluginsList)
  }

  setPluginsConfig = (pluginsConfig: PluginOption[]) => {
    this.setState({ pluginsConfig })
  }

  setPluginsList = (pluginsList: PluginObject[]) => {
    const { compassEnabled } = this.props
    const compassIndex = pluginsList.findIndex(
      (pobj: PluginObject) => pobj.value === `{${CLSID_COMPASS}}`
    )

    if (compassEnabled && compassIndex === -1) {
      pluginsList.push({
        type: PeekVariantType.PEEK_VT_BSTR,
        value: `{${CLSID_COMPASS}}`,
      })
    } else if (!compassEnabled && compassIndex !== -1) {
      pluginsList.splice(compassIndex, 1)
    }

    this.setState({ pluginsList })
  }

  render() {
    const { pluginsInfo, onCancel } = this.props
    return (
      <Modal isOpen={true}>
        <ModalHeader toggle={onCancel}>Analysis Modules</ModalHeader>
        <ModalBody>
          <Form id="analysisModulesForm" onSubmit={this.onSubmit} noValidate>
            <PluginsListForm
              contextId={"{00000000-0000-0000-0000-000000000000}"}
              pluginsConfig={this.state.pluginsConfig}
              pluginsInfo={
                pluginsInfo
                  ? pluginsInfo.filter(
                      (pinfo: EngineCapabilitiesPluginInfo) => pinfo.clsid !== CLSID_COMPASS
                    )
                  : []
              }
              pluginsList={this.state.pluginsList}
              setPluginsConfig={this.setPluginsConfig}
              setPluginsList={this.setPluginsList}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
          <PrimaryButton type="submit" form="analysisModulesForm">
            OK
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    )
  }
}

export default PluginsListModal
