import * as React from "react"
import styled from "styled-components"

const OuterBar = styled.div`
  display: flex;
  height: 1rem;
  overflow: hidden;
  background-color: ${props => props.theme.barGaugeBackgroundColor};
`

const InnerBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.theme.barGaugeDefaultColor};
  color: #fff;
  text-align: center;
  white-space: nowrap;
`

type BarGaugeProps = {
  value: number
  max?: number
  color?: string
  title?: string
  style?: React.CSSProperties
  "aria-label"?: string
}

export class BarGauge extends React.PureComponent<React.PropsWithChildren<BarGaugeProps>> {
  render() {
    const { value, max = 100, color, children, "aria-label": ariaLabel, ...props } = this.props
    const percent = (value / max) * 100
    return (
      <OuterBar {...props}>
        <InnerBar
          style={{ width: `${percent}%`, backgroundColor: color }}
          role="meter"
          aria-valuenow={value}
          aria-valuemax={max}
          aria-label={ariaLabel}
          children={children}
        />
      </OuterBar>
    )
  }
}

export default BarGauge
