import * as React from "react"
import styled from "styled-components"
import { SecondaryButton } from "../common/Buttons"
import { Panel } from "../common/Panel"
import { AuditLogSearch } from "./AuditLogSearch"
import { formatISODateTime } from "../../utils/formatUtils"
import { useSelector } from "react-redux"
import { getShowLocalTime } from "../../store"

const SearchResultsPanel = styled(Panel)`
  display: flex;
  align-items: center;
  justify-content: stretch;
  white-space: nowrap;

  & > * + * {
    margin-left: 0.5rem;
  }
`

const SearchList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  flex-grow: 1;
  flex-wrap: wrap;

  & > * + * {
    margin-left: 0.5rem;
  }
`

const SearchListItem = styled.li`
  border: 1px dotted ${props => props.theme.inputBorderColor};
  display: flex;
  list-style: none;
  padding: 0.375rem 0.5rem;
`

const SearchItemLabel = styled.div`
  padding-right: 0.25rem;
  white-space: nowrap;
  font-weight: 700;
  color: ${props => props.theme.propTableHeaderColor};
  font-size: ${props => props.theme.propTableHeaderFontSize};
  line-height: 1.5rem;
  text-transform: ${props => props.theme.propTableHeaderTextTransform};
`

const SearchItem = styled.span``

type AuditLogSearchPopoverProps = {
  search: AuditLogSearch
  onClearSearch: () => void
}

const AuditLogSearchResults: React.FC<AuditLogSearchPopoverProps> = ({ search, onClearSearch }) => {
  const showLocalTime = useSelector(getShowLocalTime)
  const renderSearchItems = () => {
    const items: React.ReactNode[] = []
    if (search.message.length > 0) {
      items.push(
        <SearchListItem key="message">
          <SearchItemLabel>Message</SearchItemLabel>
          <SearchItem>{search.message}</SearchItem>
        </SearchListItem>
      )
    }
    if (search.client.length > 0) {
      items.push(
        <SearchListItem key="client">
          <SearchItemLabel>Client</SearchItemLabel>
          <SearchItem>{search.client}</SearchItem>
        </SearchListItem>
      )
    }
    if (search.user.length > 0) {
      items.push(
        <SearchListItem key="user">
          <SearchItemLabel>User</SearchItemLabel>
          <SearchItem>{search.user}</SearchItem>
        </SearchListItem>
      )
    }
    if (search.startTime.length > 0 && search.stopTime.length > 0) {
      items.push(
        <SearchListItem key="time-range">
          <SearchItemLabel>Time Range</SearchItemLabel>
          <SearchItem>
            {formatISODateTime(search.startTime, 0, showLocalTime)} &rarr;
            {formatISODateTime(search.stopTime, 0, showLocalTime)}
          </SearchItem>
        </SearchListItem>
      )
    }
    return items
  }

  const searchItems = renderSearchItems()
  if (searchItems.length === 0) return null
  return (
    <SearchResultsPanel>
      <SearchList>{searchItems}</SearchList>
      <SecondaryButton onClick={() => onClearSearch()}>Clear Search</SecondaryButton>
    </SearchResultsPanel>
  )
}

export default AuditLogSearchResults
