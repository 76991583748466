import * as React from "react"
import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
  LabelList,
} from "recharts"
import ChartTooltip from "../ChartTooltip"
import { ChartType } from "../../../api/types/chartTypes"
import { formatPercentChartLabel, formatPercentChartTooltip } from "../../../utils/chartUtils"
import { measureText } from "../../../utils/measureText"

type AxisDomain = React.ComponentProps<typeof XAxis>["domain"]

type PercentChartProps = {
  data?: any | null
  height?: number
  chartType: string
  fixedScale: boolean
  valueKey: string
  totalKey: string
  theme: any
}

class PercentChart extends React.Component<PercentChartProps> {
  tooltipFormatter = (value: number, name: string, entry: any) => {
    return formatPercentChartTooltip(this.props.valueKey, this.props.totalKey, value, name, entry)
  }

  render() {
    const { data, height, chartType, fixedScale, valueKey, totalKey, theme } = this.props
    if (!data) return null
    let chart: React.ReactNode = null
    const domain: AxisDomain = fixedScale
      ? [0, 100]
      : [0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]
    const labelListProps = {
      fill: theme.textMutedColor,
      stroke: "none",
    }
    switch (chartType) {
      case ChartType.HORIZONTAL_BAR:
        {
          const tickWidth = Math.min(
            Math.ceil(
              data.reduce((max: number, d: any) => Math.max(max, measureText(d.name).width), 0)
            ) + 4,
            250
          )
          chart = (
            <BarChart
              data={data}
              margin={{ top: 4, right: 48, left: 48 + (tickWidth - 100), bottom: 0 }}
              layout="vertical"
            >
              <Tooltip
                isAnimationActive={false}
                formatter={formatPercentChartTooltip.bind(this, valueKey, totalKey)}
                content={<ChartTooltip />}
              />
              <CartesianGrid stroke={theme.chartGridColor} horizontal={false} />
              <Bar dataKey="pct" layout="vertical" isAnimationActive={false}>
                <LabelList
                  dataKey="pct"
                  position="right"
                  formatter={formatPercentChartLabel}
                  {...labelListProps}
                />
                {data.map((entry: any, i: number) => (
                  <Cell key={i} fill={entry.color} />
                ))}
              </Bar>
              <YAxis
                dataKey="name"
                type="category"
                stroke={theme.textColor}
                interval={0}
                tick={{ width: tickWidth }}
              />
              <XAxis type="number" domain={domain} stroke={theme.textColor} height={22} />
            </BarChart>
          )
        }
        break
      case ChartType.COLUMN:
        chart = (
          <BarChart data={data} margin={{ top: 10, right: 18, left: 0, bottom: 0 }}>
            <Tooltip
              isAnimationActive={false}
              formatter={formatPercentChartTooltip.bind(this, valueKey, totalKey)}
              content={<ChartTooltip />}
            />
            <CartesianGrid stroke={theme.chartGridColor} vertical={false} />
            <Bar dataKey="pct" isAnimationActive={false}>
              <LabelList
                dataKey="pct"
                position="top"
                formatter={formatPercentChartLabel}
                {...labelListProps}
              />
              {data.map((entry: any, i: number) => (
                <Cell key={i} fill={entry.color} />
              ))}
            </Bar>
            <YAxis type="number" domain={domain} stroke={theme.textColor} interval={0} width={32} />
            <XAxis dataKey="name" type="category" stroke={theme.textColor} height={22} />
          </BarChart>
        )
        break
      case ChartType.PIE:
        chart = (
          <PieChart>
            <Tooltip
              isAnimationActive={false}
              formatter={formatPercentChartTooltip.bind(this, valueKey, totalKey)}
              content={<ChartTooltip />}
            />
            <Pie data={data} dataKey="pct" isAnimationActive={false}>
              {data.map((entry: any, i: number) => (
                <Cell key={i} fill={entry.color} stroke={theme.textColor} />
              ))}
            </Pie>
            <Legend iconSize={10} />
          </PieChart>
        )
        break
      case ChartType.DONUT:
        chart = (
          <PieChart>
            <Tooltip
              isAnimationActive={false}
              formatter={formatPercentChartTooltip.bind(this, valueKey, totalKey)}
              content={<ChartTooltip />}
            />
            <Pie data={data} dataKey="pct" isAnimationActive={false} innerRadius={40}>
              {data.map((entry: any, i: number) => (
                <Cell key={i} fill={entry.color} stroke={theme.textColor} />
              ))}
            </Pie>
            <Legend iconSize={10} />
          </PieChart>
        )
        break
      default:
        break
    }
    return chart ? (
      <ResponsiveContainer height={height} width="100%">
        {chart as React.ReactElement}
      </ResponsiveContainer>
    ) : null
  }
}

export default PercentChart
