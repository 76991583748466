import * as React from "react"
import { toNumber } from "lodash"
import styled from "styled-components"
import { Input } from "../common/Input"
import { Slider } from "../common/Slider"
import { formatInteger, formatMB } from "../../utils/formatUtils"

const SliderOuterWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-start;

  & > * + * {
    margin-left: 1rem;
  }
`

const SliderInnerWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const SliderControlWrapper = styled.div`
  padding: 0 4px;
`

const SliderMinMax = styled.div`
  display: flex;
  justify-content: space-between;
`

const SliderFooter = styled.div`
  display: flex;
  justify-content: center;
`

// memory size constants
const MEMORY_KIBIBYTE = 1 << 10 // 1024
const MEMORY_MEBIBYTE = 1 << 20 // 1048576

// flow count constants
const FLOW_COUNT_SCALAR = 1000
const FLOW_COUNT_MIN = 1 * FLOW_COUNT_SCALAR

// flow memory consumption
const FLOW_MEMORY_SINGLE = 3 * MEMORY_KIBIBYTE

type MaximumFlowsSliderProps = {
  disabled?: boolean
  maxStreamCount: number
  maxStreamCountMax: number
  name: string
  onChange: (value: number) => void
}

export class MaximumFlowsSlider extends React.PureComponent<MaximumFlowsSliderProps> {
  onChangeEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { maxStreamCountMax } = this.props

    const maxStreamCountMin = Math.floor(FLOW_COUNT_MIN / FLOW_COUNT_SCALAR)

    let flowCount = toNumber(event.target.value)
    if (flowCount <= maxStreamCountMin) {
      flowCount = maxStreamCountMin
    } else if (flowCount >= maxStreamCountMax) {
      flowCount = maxStreamCountMax
    }

    this.props.onChange(flowCount)
  }

  onChangeSlider = (value: number) => {
    this.props.onChange(value)
  }

  render() {
    const { maxStreamCount, maxStreamCountMax, name, disabled = false } = this.props
    const maxStreamCountMin = Math.floor(FLOW_COUNT_MIN / FLOW_COUNT_SCALAR)

    const actualMemoryAmount = maxStreamCount * FLOW_MEMORY_SINGLE
    const memoryUsage =
      Math.floor((actualMemoryAmount + MEMORY_MEBIBYTE - 1) / MEMORY_MEBIBYTE) * MEMORY_MEBIBYTE

    return (
      <SliderOuterWrapper>
        <SliderInnerWrapper>
          <SliderControlWrapper>
            <Slider
              onChange={this.onChangeSlider}
              value={maxStreamCount}
              min={maxStreamCountMin}
              max={maxStreamCountMax}
              step={1}
              marks={{
                [maxStreamCountMin]: "",
                [maxStreamCountMax]: "",
              }}
              disabled={disabled}
            />
          </SliderControlWrapper>
          <SliderMinMax>
            <div>{`${formatInteger(maxStreamCountMin)}`}</div>
            <div>{`${formatInteger(maxStreamCountMax)}`}</div>
          </SliderMinMax>
          <SliderFooter>(Memory Usage: {formatMB(memoryUsage)})</SliderFooter>
        </SliderInnerWrapper>
        <Input
          style={{ width: "8rem" }}
          type="number"
          name={name}
          id={name}
          aria-label="Maximum Flows & Events"
          onChange={this.onChangeEdit}
          value={maxStreamCount}
          min={maxStreamCountMin}
          max={maxStreamCountMax}
          step={1}
          readOnly={disabled}
        />
      </SliderOuterWrapper>
    )
  }
}
