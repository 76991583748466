import * as React from "react"
import { toNumber } from "lodash"
import { Form, FormGroup } from "reactstrap"
import { Label } from "../common/Form"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { Select } from "../common/Select"

type EncodingModalProps = {
  encoding: number
  onOK: (encoding: number) => void
  onCancel: () => void
}

const EncodingModal = (props: EncodingModalProps) => {
  const [encoding, setEncoding] = React.useState(props.encoding)

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    props.onOK(encoding)
  }

  return (
    <Modal isOpen={true} toggle={props.onCancel}>
      <ModalHeader toggle={props.onCancel}>Choose Encoding</ModalHeader>
      <ModalBody>
        <Form id="encodingForm" onSubmit={handleSubmit} noValidate>
          <FormGroup noMargin>
            <Label for="encoding--encoding">Encoding</Label>
            <Select
              name="encoding--encoding"
              id="encoding--encoding"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setEncoding(toNumber(event.target.value))
              }}
              value={encoding}
            >
              <option value={0}>ASCII</option>
              <option value={65001}>UTF-8</option>
              <option value={28596}>Arabic (ISO)</option>
              <option value={1256}>Arabic (Windows)</option>
              <option value={28594}>Baltic (ISO)</option>
              <option value={1257}>Baltic (Windows)</option>
              <option value={28592}>Central European (ISO)</option>
              <option value={1250}>Central European (Windows)</option>
              <option value={51936}>Chinese Simplified (EUC)</option>
              <option value={936}>Chinese Simplified (GB2312)</option>
              <option value={54936}>Chinese Simplified (GB18030)</option>
              <option value={950}>Chinese Traditional (Big5)</option>
              <option value={28595}>Cyrillic (ISO)</option>
              <option value={20866}>Cyrillic (KOI8-R)</option>
              <option value={21866}>Cyrillic (KOI8-U)</option>
              <option value={1251}>Cyrillic (Windows)</option>
              <option value={37}>EBCDIC (US)</option>
              <option value={28597}>Greek (ISO)</option>
              <option value={1253}>Greek (Windows)</option>
              <option value={28598}>Hebrew (ISO)</option>
              <option value={1255}>Hebrew (Windows)</option>
              <option value={20932}>Japanese (EUC)</option>
              <option value={932}>Japanese (Shift-JIS)</option>
              <option value={51949}>Korean (EUC)</option>
              <option value={874}>Thai (Windows)</option>
              <option value={28599}>Turkish (ISO)</option>
              <option value={1254}>Turkish (Windows)</option>
              <option value={28591}>Western European (ISO)</option>
              <option value={1252}>Western European (Windows)</option>
              <option value={1258}>Vietnamese (Windows)</option>
            </Select>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={props.onCancel}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" form="encodingForm">
          OK
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  )
}

export default EncodingModal
