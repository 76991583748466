import * as React from "react"
import { connect } from "react-redux"
import { toNumber } from "lodash"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { useParams, useRouteMatch, RouteComponentProps } from "react-router-dom"
import { ButtonGroup } from "reactstrap"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { TabButton } from "../common/Buttons"
import { View, ViewContent, ViewHeader, ViewHeaderTitle, ViewHeaderButtons } from "../common/View"
import { formatInteger } from "../../utils/formatUtils"
import SignalingView from "./SignalingView"
import RTPView from "./RTPView"
import { getVoIPCallVisualizerViewType, getUserId, getCapabilities } from "../../store"
import { setVoIPCallVisualizerViewType } from "../../store/ui"
import {
  CaptureProperties,
  ResponseGetEngineCapabilities,
  ForensicSearchProperties,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
// See: //wp/eng/private/zig/doc/zzmisc/voipview.html

const VoIPCallVisualizerViewContent = styled(ViewContent)`
  display: flex;
  flex-direction: column;
`

type RouteParams = {
  type: string
  capId: string
  callId: string
}

type RouteInfo = {
  type: string
  capId: string
}

type VoIPCallVisualizerViewProps = RouteComponentProps<RouteInfo> & {
  captureProperties: CaptureProperties | ForensicSearchProperties | null
  engineCapabilities: ResponseGetEngineCapabilities | null
  userId: string
}

const VoIPCallVisualizerView: React.FC<VoIPCallVisualizerViewProps> = ({
  captureProperties,
  engineCapabilities,
  userId,
}) => {
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const { callId } = useParams<RouteParams>()
  const viewType: "signaling" | "rtp" = useSelector(getVoIPCallVisualizerViewType) || "signaling"

  const callIds = callId ? callId.split(",").map((id: string) => formatInteger(toNumber(id))) : []
  const title = callIds.length === 1 ? `Call ${callIds[0]}` : `Calls ${callIds.join(", ")}`

  let canViewPackets = true
  if (captureProperties && engineCapabilities) {
    const isUserOwner = userId === captureProperties.creatorSID
    const policies = engineCapabilities.userRights.policies
    canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
  }

  return (
    <View>
      <BreadcrumbItem to={match.url} title={title} />
      <ViewHeader>
        <ViewHeaderTitle title={title} />
        <ViewHeaderButtons>
          <ButtonGroup>
            <TabButton
              name="signaling"
              onClick={() => dispatch(setVoIPCallVisualizerViewType("signaling"))}
              active={viewType === "signaling"}
            >
              Signaling
            </TabButton>
            <TabButton
              name="rtp"
              onClick={() => dispatch(setVoIPCallVisualizerViewType("rtp"))}
              active={viewType === "rtp"}
            >
              RTP
            </TabButton>
          </ButtonGroup>
        </ViewHeaderButtons>
      </ViewHeader>
      <VoIPCallVisualizerViewContent>
        {viewType === "signaling" ? <SignalingView canViewPackets={canViewPackets} /> : null}
        {viewType === "rtp" ? <RTPView /> : null}
      </VoIPCallVisualizerViewContent>
    </View>
  )
}

const mapStateToProps = (state: any) => ({
  userId: getUserId(state),
  engineCapabilities: getCapabilities(state) || null,
})

export default connect(mapStateToProps)(VoIPCallVisualizerView)
