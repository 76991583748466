import * as React from "react"
import { cloneDeep, toNumber } from "lodash"
import cn from "classnames"
import FontAwesome from "react-fontawesome"
import { FormGroup } from "reactstrap"
import {
  SortDirection,
  SortDirectionType,
  SortIndicator,
  TableCellProps,
  TableHeaderProps,
  TableRowProps,
} from "react-virtualized"
import { LightButton, PrimaryButton } from "../../common/Buttons"
import { MutedText } from "../../common/MutedText"
import { OmniTable } from "../../common/OmniTable"
import { ButtonBar, CheckGroup, CheckGroupIndeterminate } from "../../common/Form"
import {
  formatInteger,
  formatDuration,
  formatISODateTime,
  formatGB,
  formatLinkSpeed,
} from "../../../utils/formatUtils"
import { getEngineDisplayName } from "../../../utils/engineUtils"
import { getMediaString } from "../../../utils/mediaUtils"
import { compareCaptureSessions } from "../../../utils/sortUtils"
import { DatabaseRowGetCaptureSessions, Engine } from "../../../api/types"
import { EngineCaptureSessions, SearchContext, SelectedCaptureSession } from "../types"
import { WizardProps } from "./types"
import {
  WizardHeader,
  WizardHeaderTitleWrapper,
  WizardHeaderTitle,
  WizardHeaderSubTitle,
} from "./Styles"
import { useSelector } from "react-redux"
import { getShowLocalTime } from "../../../store"

const defaultColumns = [
  {
    dataKey: "Name",
    label: "Engine/Capture Session",
    width: 200,
    flexGrow: 1,
    flexShrink: 0,
    visible: 1,
  },
  {
    dataKey: "SessionStartTimestamp",
    label: "Session Start Time",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "StartTimestamp",
    label: "Data Start Time",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "StopTimestamp",
    label: "Data End Time",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "Duration",
    label: "Duration",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "StorageUnits",
    label: "Size",
    width: 100,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "PacketCount",
    label: "Packets",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "DroppedCount",
    label: "Packets Dropped",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "MediaType",
    label: "Media",
    width: 120,
    visible: false,
  },
  {
    dataKey: "AdapterName",
    label: "Adapter",
    width: 200,
    visible: true,
  },
  {
    dataKey: "AdapterAddr",
    label: "Adapter Address",
    width: 140,
    visible: false,
  },
  {
    dataKey: "LinkSpeed",
    label: "Link Speed",
    width: 140,
    visible: false,
  },
  {
    dataKey: "Owner",
    label: "Owner",
    width: 140,
    visible: false,
  },
]

type ChooseCaptureSessionsRowItem = {
  engineId: string
  childIndex: number
  row: DatabaseRowGetCaptureSessions
}

type ChooseCaptureSessionsRowData = ChooseCaptureSessionsRowItem | EngineCaptureSessions

function isEngineRowData(rowData: ChooseCaptureSessionsRowData) {
  return "captureSessions" in rowData
}

function rowDataToId(rowData: ChooseCaptureSessionsRowData) {
  if (isEngineRowData(rowData)) {
    return `${(rowData as EngineCaptureSessions).engineId}-eng`
  } else {
    const captureSession = (rowData as ChooseCaptureSessionsRowItem).row
    if (captureSession.SessionID != null && captureSession.CaptureGUID != null) {
      return `${captureSession.SessionID}-${captureSession.CaptureGUID}`
    }
  }
  return "id"
}

type ChooseCaptureSessionsProps = WizardProps & {
  engines: Engine[]
  captureSessions: EngineCaptureSessions[]
  selectedCaptureSessions: SelectedCaptureSession[]
  setSelectedCaptureSessions: (selectedCaptureSessions: SelectedCaptureSession[]) => void
  searchContext: SearchContext
  setSearchContext: (context: SearchContext) => void
}

const ChooseCaptureSessions = ({
  onNext,
  onPrevious,
  engines,
  captureSessions,
  selectedCaptureSessions,
  setSelectedCaptureSessions,
  searchContext,
  setSearchContext,
}: ChooseCaptureSessionsProps) => {
  const [columns, setColumns] = React.useState<any[]>(defaultColumns)
  const [sortBy, setSortBy] = React.useState("StartTimestamp")
  const [sortDirection, setSortDirection] = React.useState<SortDirectionType>(SortDirection.DESC)
  const [collapsedEngines, setCollapsedEngines] = React.useState(new Set<string>())
  const showLocalTime = useSelector(getShowLocalTime)

  const onShowDefaultColumns = () => {
    setColumns(defaultColumns)
  }

  const onShowAllColumns = () => {
    const newColumns = cloneDeep(defaultColumns)
    newColumns.forEach((col: any) => {
      col.visible = true
    })
    setColumns(newColumns)
  }

  const onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColumns = cloneDeep(columns)
    const col = newColumns.find((col: any) => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      setColumns(newColumns)
    }
  }

  const onSort = ({
    sortBy,
    sortDirection,
    event,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
    event: any
  }) => {
    const tagName = (event?.target as HTMLElement)?.tagName
    if (tagName === "LABEL" || tagName === "INPUT") {
      return
    }
    setSortBy(sortBy)
    setSortDirection(sortDirection)
  }

  const onExpandCollapse = (engineId: string) => {
    const newCollapsedEngines = new Set<string>(collapsedEngines)
    if (newCollapsedEngines.has(engineId)) {
      newCollapsedEngines.delete(engineId)
    } else {
      newCollapsedEngines.add(engineId)
    }
    setCollapsedEngines(newCollapsedEngines)
  }

  const onEnableAll = () => {
    const newSelectedCaptureSessions: SelectedCaptureSession[] = []
    for (const engineCaptureSession of captureSessions) {
      for (const row of engineCaptureSession.captureSessions.rows) {
        if (row.SessionID != null) {
          newSelectedCaptureSessions.push({
            engineId: engineCaptureSession.engineId,
            sessionId: row.SessionID,
          })
        }
      }
    }
    setSelectedCaptureSessions(newSelectedCaptureSessions)
  }

  const onDisableAll = () => {
    setSelectedCaptureSessions([])
  }

  const isChecked = (engineId: string, row: DatabaseRowGetCaptureSessions) => {
    if (row.SessionID != null) {
      if (
        selectedCaptureSessions.find(
          selectedCaptureSession =>
            selectedCaptureSession.engineId === engineId &&
            selectedCaptureSession.sessionId === row.SessionID
        )
      ) {
        return true
      }
    }
    return false
  }

  const toggleCheck = (engineId: string, row: DatabaseRowGetCaptureSessions) => {
    const newSelectedCaptureSessions: SelectedCaptureSession[] = []
    for (const selectedCaptureSession of selectedCaptureSessions) {
      if (
        selectedCaptureSession.engineId !== engineId ||
        row.SessionID !== selectedCaptureSession.sessionId
      ) {
        newSelectedCaptureSessions.push({
          engineId: selectedCaptureSession.engineId,
          sessionId: selectedCaptureSession.sessionId,
        })
      }
    }
    if (newSelectedCaptureSessions.length === selectedCaptureSessions.length) {
      if (row.SessionID != null) {
        newSelectedCaptureSessions.push({ engineId, sessionId: row.SessionID })
      }
    }
    setSelectedCaptureSessions(newSelectedCaptureSessions)
  }

  const onRowClick = ({ rowData }: { rowData: ChooseCaptureSessionsRowData }) => {
    if (isEngineRowData(rowData)) {
      onExpandCollapse((rowData as EngineCaptureSessions).engineId)
    }
  }

  const rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    if (isEngineRowData(rowData)) {
      className = cn(className, "group", "clickable")
      if (Array.isArray(columns) && columns.length > 1) {
        // Only need the first column
        columns = [columns[0]]
      }
    } else {
      if (rowData.childIndex != null && rowData.childIndex % 2 !== 0) {
        className = cn(className, "stripe")
      }
    }

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  const rowClassName = () => {
    // Stripes applied in rowRenderer
    return ""
  }

  const nameHeaderRenderer = ({ dataKey, label, sortBy, sortDirection }: TableHeaderProps) => {
    let checkState = 0
    if (selectedCaptureSessions.length > 0) {
      let totalCaptureSessions = 0
      for (const s of captureSessions) {
        totalCaptureSessions += s.captureSessions.rows.length
      }
      if (totalCaptureSessions === selectedCaptureSessions.length) {
        checkState = 1
      } else {
        checkState = 2
      }
    }
    return (
      <div
        style={{
          paddingLeft: "1.536rem",
          display: "flex",
          alignItems: "center",
          flexWrap: "nowrap",
        }}
      >
        <div style={{ fontSize: "1rem" }}>
          <CheckGroupIndeterminate
            type="checkbox"
            value={checkState}
            id="check-all"
            onChange={() => {
              if (checkState !== 1) {
                onEnableAll()
              } else {
                onDisableAll()
              }
            }}
          />
        </div>
        <span>{label}</span>
        {sortBy === dataKey ? <SortIndicator sortDirection={sortDirection} /> : null}
      </div>
    )
  }

  const cellRenderer = ({ dataKey, rowData }: TableCellProps) => {
    let content
    if (isEngineRowData(rowData)) {
      const rowItem: EngineCaptureSessions = rowData

      const expanded = !collapsedEngines.has(rowItem.engineId)
      const icon = expanded ? "chevron-down" : "chevron-right"

      let checkCount = 0
      for (const row of rowItem.captureSessions.rows) {
        if (isChecked(rowItem.engineId, row)) {
          checkCount++
        }
      }
      let checkState = 0
      if (checkCount === rowItem.captureSessions.rows.length) {
        checkState = 1
      } else if (checkCount > 0) {
        checkState = 2
      }

      const engine = engines.find(engine => engine.id === rowItem.engineId)

      content = (
        <div style={{ display: "flex", alignItems: "center" }}>
          <FontAwesome name={icon} fixedWidth />
          <span style={{ paddingLeft: ".25em" }}>
            <CheckGroupIndeterminate
              type="checkbox"
              value={checkState}
              id={rowDataToId(rowItem)}
              onChange={() => {
                const newSelectedCaptureSessions: SelectedCaptureSession[] = []
                for (const selectedCaptureSession of selectedCaptureSessions) {
                  if (selectedCaptureSession.engineId !== rowItem.engineId) {
                    newSelectedCaptureSessions.push({
                      engineId: selectedCaptureSession.engineId,
                      sessionId: selectedCaptureSession.sessionId,
                    })
                  }
                }

                if (checkState !== 1) {
                  const engineCaptureSession = captureSessions.find(
                    engineCaptureSession => engineCaptureSession.engineId === rowItem.engineId
                  )
                  if (engineCaptureSession) {
                    for (const row of engineCaptureSession.captureSessions.rows) {
                      if (row.SessionID != null) {
                        newSelectedCaptureSessions.push({
                          engineId: engineCaptureSession.engineId,
                          sessionId: row.SessionID,
                        })
                      }
                    }
                  }
                }

                setSelectedCaptureSessions(newSelectedCaptureSessions)
              }}
            >
              <span style={{ marginRight: "0.25rem" }}>{getEngineDisplayName(engine)}</span>
              <MutedText>{`(${formatInteger(rowItem.captureSessions.rows.length)})`}</MutedText>
            </CheckGroupIndeterminate>
          </span>
        </div>
      )
    } else {
      const rowItem: ChooseCaptureSessionsRowItem = rowData
      const cellData = rowItem.row[dataKey]
      switch (dataKey) {
        case "Name":
          content = (
            <div style={{ paddingLeft: "3.036rem" }}>
              <CheckGroup
                type="checkbox"
                checked={isChecked(rowItem.engineId, rowItem.row)}
                id={rowDataToId(rowData)}
                onChange={() => {
                  toggleCheck(rowItem.engineId, rowItem.row)
                }}
              >
                <span title={cellData}>{cellData}</span>
              </CheckGroup>
            </div>
          )
          break
        case "SessionStartTimestamp":
        case "StartTimestamp":
        case "StopTimestamp":
          if (cellData) {
            content = formatISODateTime(cellData, 0, showLocalTime)
          }
          break
        case "Duration":
          if (rowData.row.StartTimestamp && rowData.row.StopTimestamp) {
            const start = Date.parse(rowData.row.StartTimestamp)
            const stop = Date.parse(rowData.row.StopTimestamp)
            content = formatDuration((stop - start) * 1000000)
          }
          break
        case "StorageUnits":
          if (cellData != null) {
            content = formatGB(cellData)
          }
          break
        case "PacketCount":
          if (cellData != null) {
            content = formatInteger(cellData)
          }
          break
        case "DroppedCount":
          if (cellData != null) {
            content = formatInteger(cellData)
          }
          break
        case "MediaType":
          if ("MediaType" in rowData.row && "MediaSubType" in rowData.row) {
            content = getMediaString(
              toNumber(rowData.row.MediaType),
              toNumber(rowData.row.MediaSubType)
            )
          }
          break
        case "LinkSpeed":
          if (cellData != null) {
            content = formatLinkSpeed(cellData)
          }
          break
        case "AdapterName":
        case "AdapterAddr":
        case "Owner":
          content = cellData
          break
        default:
          break
      }
    }

    return content
  }

  const commandCellRenderer = () => {
    return null
  }

  const getFlattenedTree = () => {
    // Sort capture sessions
    const sortedCaptureSessions = cloneDeep(captureSessions)
    for (const engineCaptureSession of sortedCaptureSessions) {
      if (engineCaptureSession.captureSessions != null) {
        engineCaptureSession.captureSessions.rows.sort((a, b) =>
          compareCaptureSessions(a, b, sortBy, sortDirection)
        )
      }
    }

    // Sort top level
    sortedCaptureSessions.sort((a, b) => {
      let rowA: DatabaseRowGetCaptureSessions | null = null
      let rowB: DatabaseRowGetCaptureSessions | null = null
      if (a.captureSessions.rows.length > 0) {
        rowA =
          sortDirection === SortDirection.DESC
            ? a.captureSessions.rows[0]
            : a.captureSessions.rows[a.captureSessions.rows.length - 1]
      }
      if (b.captureSessions.rows.length > 0) {
        rowB =
          sortDirection === SortDirection.DESC
            ? b.captureSessions.rows[0]
            : b.captureSessions.rows[b.captureSessions.rows.length - 1]
      }
      if (rowA != null && rowB != null) {
        return compareCaptureSessions(rowA, rowB, sortBy, sortDirection)
      } else if (rowA != null) {
        return 1
      } else {
        return -1
      }
    })

    // Flatten the list into rows
    const rows: ChooseCaptureSessionsRowData[] = []
    for (const engineCaptureSession of sortedCaptureSessions) {
      rows.push(engineCaptureSession)
      if (!collapsedEngines.has(engineCaptureSession.engineId)) {
        for (let i = 0, len = engineCaptureSession.captureSessions.rows.length; i < len; i++) {
          rows.push({
            engineId: engineCaptureSession.engineId,
            childIndex: i,
            row: engineCaptureSession.captureSessions.rows[i],
          })
        }
      }
    }

    return rows
  }

  // Build the flattened tree for the table.
  const flattenedTree = getFlattenedTree()

  // Add the checkbox header renderer to the columns.
  const columnsPatched: any[] = [...columns]
  const nameColumn = columns.find(col => col.dataKey === "Name")
  if (nameColumn) {
    nameColumn.headerRenderer = nameHeaderRenderer
  }

  return (
    <>
      <WizardHeader>
        <WizardHeaderTitleWrapper>
          <WizardHeaderTitle>Capture Sessions & Merge</WizardHeaderTitle>
          <WizardHeaderSubTitle>Choose capture sessions and merge options</WizardHeaderSubTitle>
        </WizardHeaderTitleWrapper>
        <ButtonBar>
          <LightButton onClick={onPrevious}>Previous</LightButton>
          <PrimaryButton onClick={onNext} disabled={selectedCaptureSessions.length === 0}>
            Start
          </PrimaryButton>
        </ButtonBar>
      </WizardHeader>

      {flattenedTree && (
        <>
          <FormGroup noMargin style={{ marginBottom: "2rem" }}>
            <CheckGroup
              type="checkbox"
              id="merge"
              name="merge"
              onChange={() => {
                setSearchContext({
                  ...searchContext,
                  merge: !searchContext.merge,
                })
              }}
              checked={searchContext.merge && selectedCaptureSessions.length > 1}
              disabled={selectedCaptureSessions.length < 2}
            >
              Merge files
            </CheckGroup>
            <MutedText
              as="div"
              style={{
                margin: "0.5rem 1.5rem 0 1.5rem",
              }}
            >
              &#8226; Merge files when each engine receives a part of network traffic, for example
              from a load balancer.
              <br />
              &#8226; Don't merge files when each engine receives the same network traffic crossing
              different points in the network.
            </MutedText>
          </FormGroup>

          <FormGroup
            noMargin
            style={{
              position: "relative",
              flexGrow: 1,
              minHeight: "250px",
            }}
          >
            <OmniTable
              data={flattenedTree}
              rowCount={flattenedTree.length}
              rowHeight={25}
              cellRenderer={cellRenderer}
              renderCommands={commandCellRenderer}
              columnDesc={columnsPatched}
              rowRenderer={rowRenderer}
              rowClassName={rowClassName}
              onRowClick={onRowClick}
              onShowDefaultColumns={onShowDefaultColumns}
              onShowAllColumns={onShowAllColumns}
              onToggleColumn={onToggleColumn}
              sort={onSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            />
          </FormGroup>
        </>
      )}
    </>
  )
}

export default ChooseCaptureSessions
