import { collator } from "../../utils/sortUtils"
import {
  MSAProjectMapping,
  MSAProjectMappingProfile,
  MSAProjectOptions,
  MSAProjectSegment,
} from "../../api/types"

export const defaultOptions: MSAProjectOptions = {
  analysisPacketLimit: 100000,
  analysisPacketLimitEnabled: true,
  ipv6: true,
  ladderPacketLimit: 100000,
  ladderPacketLimitEnabled: false,
  maxFlows: 10000,
  syncMethod: "auto",
  tcp: true,
  udp: true,
}

export function renumberSegments(segments: MSAProjectSegment[]) {
  for (let i = 0; i < segments.length; i++) {
    segments[i].num = i + 1
  }
}

export function isPortValid(port: number) {
  return !Number.isNaN(port) && port >= 1 && port <= 65535
}

export function compareMappingProfiles(a: MSAProjectMappingProfile, b: MSAProjectMappingProfile) {
  return collator.compare(a.name, b.name)
}

export function compareMappings(a: MSAProjectMapping, b: MSAProjectMapping) {
  let result = collator.compare(a.address1, b.address1)
  if (result === 0) {
    if (a.port1 !== 0 && b.port1 !== 0) {
      if (a.port1 < b.port1) {
        result = -1
      } else if (a.port1 > b.port1) {
        result = 1
      }
    }
    if (result === 0) {
      result = collator.compare(a.address2, b.address2)
      if (a.port2 !== 0 && b.port2 !== 0) {
        if (a.port2 < b.port2) {
          result = -1
        } else if (a.port2 > b.port2) {
          result = 1
        }
      }
    }
  }
  return result
}
