import * as React from "react"
import { produce } from "immer"
import { Col, Form, FormGroup, Row } from "reactstrap"
import FontAwesome from "react-fontawesome"
import { TableCellProps } from "react-virtualized"
import styled, { withTheme } from "styled-components"
import { v4 as uuid } from "uuid"
import { PrimaryButton, SecondaryButton, IconButton } from "../common/Buttons"
import { PopoverHeader, PopoverBody } from "../common/Popover"
import { UncontrolledPopover } from "../common/UncontrolledPopover"
import FilterBox from "../common/FilterBox"
import { CheckGroup } from "../common/Form"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../common/Modal"
import { MutedText } from "../common/MutedText"
import { OmniTable } from "../common/OmniTable"
import { ViewContent, ViewHeader, ViewHeaderButtons } from "../common/View"
import { ApplicationGraphItemObject, ApplicationInfo, GraphItemObject } from "../../api/types"
import { GraphItemUnitType } from "../../api/types/peekTypes"
import { formatColorForTheme } from "../../utils/formatUtils"

const columnDesc = [
  {
    dataKey: "name",
    width: 150,
    flexGrow: 1,
  },
  {
    dataKey: "description",
    width: 200,
    flexGrow: 2,
  },
  {
    dataKey: "buttons",
    label: "",
    width: 25,
    flexShrink: 0,
    disableSort: true,
  },
]

const ButtonStrip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

type ApplicationsModalProps = {
  applications: ApplicationInfo[]
  clsid: string
  graphItems: GraphItemObject[]
  theme: any
  unitType: GraphItemUnitType
  onCancel: (clsid: string) => void
  onSubmit: (clsid: string, graphItems: GraphItemObject[]) => void
}

type ApplicationsModalState = {
  filter: string
  graphItems: GraphItemObject[]
  isNAVL: boolean
}

class ApplicationsModal extends React.Component<ApplicationsModalProps, ApplicationsModalState> {
  state: ApplicationsModalState = {
    filter: "",
    graphItems: this.props.graphItems,
    isNAVL: this.props.applications.length > 0 && typeof this.props.applications[0].id == "string",
  }

  cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    const { clsid } = this.props
    const { graphItems } = this.state
    const checked =
      graphItems
        .filter((graphItem: GraphItemObject) => graphItem.clsid === clsid)
        .findIndex(
          (graphItem: GraphItemObject) =>
            (graphItem as ApplicationGraphItemObject).applicationId === rowData.id
        ) !== -1

    let content = null
    switch (dataKey) {
      case "name":
        content = (
          <CheckGroup
            type="checkbox"
            name={rowData.id}
            id={`application-${rowData.id}`}
            onChange={this.onChangeCheckbox.bind(this, rowData)}
            checked={checked}
          >
            <span
              style={{ color: formatColorForTheme(rowData.color, this.props.theme.name) }}
              title={cellData}
            >
              {cellData}
            </span>
          </CheckGroup>
        )
        break
      case "description":
        content = (
          <span
            style={{ color: formatColorForTheme(rowData.color, this.props.theme.name) }}
            title={cellData}
          >
            {cellData}
          </span>
        )
        break
      case "buttons":
        content = (
          <ButtonStrip>
            <IconButton id={`detailsPopover-${rowData.id}`}>
              <FontAwesome fixedWidth name="info-circle" />
            </IconButton>
            <UncontrolledPopover
              trigger="focus"
              placement="left"
              target={`detailsPopover-${rowData.id}`}
              fade={false}
            >
              <PopoverHeader>{rowData.name}</PopoverHeader>
              <PopoverBody>
                <b>Category:</b> {rowData.category}
                <br />
                <b>Description:</b> {rowData.description}
                {rowData.productivity !== undefined ? (
                  <span>
                    <br />
                    <b>Productivity:</b> {rowData.productivity}
                  </span>
                ) : null}
                {rowData.risk !== undefined ? (
                  <span>
                    <br />
                    <b>Risk:</b> {rowData.risk}
                  </span>
                ) : null}
              </PopoverBody>
            </UncontrolledPopover>
          </ButtonStrip>
        )
        break
      default:
        break
    }
    return content
  }

  onChangeCheckbox = (application: ApplicationInfo, event: React.ChangeEvent<HTMLInputElement>) => {
    const { clsid, unitType } = this.props
    const { checked } = event.target
    this.setState(
      produce(draft => {
        if (draft.graphItems) {
          if (checked) {
            draft.graphItems.push({
              applicationId: application.id,
              clsid,
              description: application.description,
              id: uuid().toUpperCase(),
              name: application.name,
              unitType,
            })
          } else {
            draft.graphItems = draft.graphItems.filter(
              (graphItem: GraphItemObject) =>
                graphItem.clsid === clsid &&
                (graphItem as ApplicationGraphItemObject).applicationId !== application.id
            )
          }
        }
      })
    )
  }

  onChangeFilter = (filter: string) => {
    this.setState({ filter })
  }

  onCancel = () => {
    const { clsid } = this.props
    this.props.onCancel(clsid)
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const { clsid } = this.props
    const { graphItems } = this.state
    this.props.onSubmit(clsid, graphItems)
  }

  render() {
    let { applications } = this.props
    const { filter, isNAVL } = this.state
    if (applications && filter) {
      const lowerCaseFilter = filter.toLowerCase()
      applications = applications.filter(
        (application: ApplicationInfo) =>
          application.name.toLowerCase().indexOf(lowerCaseFilter) !== -1
      )
    }

    return (
      <Modal size="lg" isOpen={true}>
        <ModalHeader toggle={this.onCancel}>Applications</ModalHeader>
        <ModalBody>
          <Form id="applications-form" onSubmit={this.onSubmit}>
            <Row>
              <Col lg={12}>
                <ViewHeader border={false} style={{ flexDirection: "row-reverse" }}>
                  <ViewHeaderButtons>
                    <FilterBox
                      aria-label="Search"
                      placeholder="Search"
                      onChange={this.onChangeFilter}
                      value={filter}
                    />
                  </ViewHeaderButtons>
                </ViewHeader>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormGroup noMargin>
                  <ViewContent>
                    <OmniTable
                      data={applications}
                      rowCount={applications.length}
                      rowHeight={25}
                      disableHeader
                      cellRenderer={this.cellRenderer}
                      columnDesc={columnDesc}
                    />
                  </ViewContent>
                </FormGroup>
              </Col>
            </Row>
            {isNAVL ? (
              <Row style={{ color: "#808080", marginTop: "1rem" }}>
                <Col lg={6}>
                  <MutedText>
                    Productivity is scored relative to a work environment, and follows this scheme:
                    <ol style={{ marginBottom: "0rem" }}>
                      <li>Not suitable inside of a working environment</li>
                      <li>Unlikely to be used for work tasks</li>
                      <li>
                        Broad-use traffic that could be used for either personal or work-related
                        tasks
                      </li>
                      <li>Likely work-oriented traffic</li>
                      <li>Traffic is solely for work or office</li>
                    </ol>
                  </MutedText>
                </Col>
                <Col lg={6}>
                  <MutedText>
                    Risk is determined on a scale of 1 to 5 using 4 weighted risk factors:
                    <br />1 pt. Excessive bandwidth
                    <br />1 pt. Potential data leakage
                    <br />1 pt. Prone to misuse
                    <br />2 pts. Contains or is used by malware
                  </MutedText>
                </Col>
              </Row>
            ) : null}
          </Form>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={this.onCancel}>Cancel</SecondaryButton>
          <PrimaryButton type="submit" form="applications-form">
            Update
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    )
  }
}

const ApplicationsModalWithTheme = withTheme(ApplicationsModal)
export { ApplicationsModalWithTheme as ApplicationsModal }
