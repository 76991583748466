import * as React from "react"
import { cloneDeep } from "lodash"
import { connect } from "react-redux"
import { RouteComponentProps } from "react-router-dom"
import { AutoSizer } from "react-virtualized"
import styled from "styled-components"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import ChooseFileModal from "../ChooseFileModal"
import { Alert } from "../common/Alert"
import { MessageModal } from "../common/MessageModal"
import { View, ViewContent, ViewHeader, ViewHeaderTitle } from "../common/View"
import ForensicSearchModal from "../ForensicSearchModal"
import {
  getAuthToken,
  getCapabilities,
  getCurrentTheme,
  getEngine,
  getShowAddressNames,
  getShowLocalTime,
  getShowPortNames,
  getStatus,
} from "../../store"
import ThemeInterface from "../../themes/theme"
import { peekToDate } from "../../utils/formatUtils"
import { getEngineForensicSearchUrl, getEnginePluginUrl } from "../../routes"
import { createForensicSearch, fetchFile, putFile, sendGlobalPluginMessage } from "../../api/api"
import {
  EngineCapabilitiesPluginInfo,
  RequestCreateForensicSearch,
  RequestPostPluginsMessage,
  ResponseGetEngineCapabilities,
  ResponseGetStatus,
  ResponsePostPluginsMessage,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { PeekFilterMode } from "../../api/types/peekTypes"
import {
  WebPluginBrowseFlags,
  WebPluginMessage,
  WebPluginData_BrowseRemoteResponse,
  WebPluginData_CommandMessageResponse,
  WebPluginData_CommandMessageResponseDisplayMessage,
  WebPluginData_CommandMessageResponseForensicSearch,
  WebPluginData_GetRemoteFileResponse,
  WebPluginData_PluginMessage,
  WebPluginData_SetRemoteFileResponse,
  WebPluginMessageCommand,
  WebPluginMessageRequest,
  WebPluginMessageResponse,
} from "../../api/types/pluginTypes"

const EngineIFrame = styled.iframe`
  border: 0;
  display: block;
  margin: 0 auto;
  position: relative;
`

type BrowseParams = {
  flags: number
  path: string
}

type DisplayMessageParams = {
  message: string
  title: string
}

type ForensicSearchParams = {
  filter: string
  start: number
  stop: number
}

type RouteInfo = {
  pluginId: string
}

type PluginEngineViewProps = RouteComponentProps<RouteInfo> & {
  authToken: string
  dispatch: Function
  engine: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  engineStatus: ResponseGetStatus | null
  showAddressNames: boolean
  showPortNames: boolean
  showLocalTime: boolean
  theme: ThemeInterface | undefined
}

type PluginEngineViewState = {
  browseParams: BrowseParams | null
  displayMessageParams: DisplayMessageParams | null
  error: any | null
  forensicSearchParams: ForensicSearchParams | null
  showBrowseModal: boolean
  showDisplayMessage: boolean
  showForensicSearchModal: boolean
}

class PluginEngineView extends React.Component<PluginEngineViewProps, PluginEngineViewState> {
  iframe: any | null = null

  state: PluginEngineViewState = {
    browseParams: null,
    displayMessageParams: null,
    error: null,
    forensicSearchParams: null,
    showBrowseModal: false,
    showDisplayMessage: false,
    showForensicSearchModal: false,
  }

  componentDidMount = () => {
    // add interface for responding to messages from the plugin options iframe
    window.addEventListener("message", this.onMessage)
  }

  componentDidUpdate(prevProps: PluginEngineViewProps, prevState: PluginEngineViewState) {
    if (this.isValidIFrame()) {
      // send message for the name table
      if (
        this.props.showAddressNames !== prevProps.showAddressNames ||
        this.props.showPortNames !== prevProps.showPortNames ||
        this.props.showLocalTime !== prevProps.showLocalTime
      ) {
        const wpmNameTable: WebPluginMessageRequest = {
          data: {
            showAddressNames: this.props.showAddressNames,
            showPortNames: this.props.showPortNames,
            showLocalTime: this.props.showLocalTime,
          },
          message: WebPluginMessage.WEB_PLUGIN_MESSAGE_NAME_TABLE,
        }
        this.iframe.contentWindow.postMessage(wpmNameTable)
      }
      // send message for the theme
      if (this.props.theme && prevProps.theme && this.props.theme.name !== prevProps.theme.name) {
        const wpmTheme: WebPluginMessageRequest = {
          data: cloneDeep(this.props.theme),
          message: WebPluginMessage.WEB_PLUGIN_MESSAGE_THEME,
        }
        this.iframe.contentWindow.postMessage(wpmTheme)
      }
    }
  }

  componentWillUnmount = () => {
    if (this.isValidIFrame()) {
      // send message for preparing to unload
      const wpmPrepareToUnload: WebPluginMessageRequest = {
        data: null,
        message: WebPluginMessage.WEB_PLUGIN_MESSAGE_PREPARE_TO_UNLOAD,
      }
      this.iframe.contentWindow.postMessage(wpmPrepareToUnload)
    }

    // remove interface for responding to messages from the plugin options iframe
    window.removeEventListener("message", this.onMessage)
  }

  getEngineStatus = () => {
    const engineStatus: any = {}
    if (this.props.engineStatus) {
      // extract the address and port from the engine status address
      let address = ""
      let port = ""
      if (this.props.engineStatus.address !== undefined) {
        const results = this.props.engineStatus.address.split(":")
        if (results.length > 0) {
          address = results[0]
        }
        if (results.length > 1) {
          port = results[1]
        }
      }

      // construct the engine status JSON object
      engineStatus.address = address
      engineStatus.dataFolder =
        this.props.engineStatus.dataFolder !== undefined ? this.props.engineStatus.dataFolder : ""
      engineStatus.domain =
        this.props.engineStatus.userDomain !== undefined ? this.props.engineStatus.userDomain : ""
      engineStatus.engineType =
        this.props.engineStatus.engineType !== undefined ? this.props.engineStatus.engineType : ""
      engineStatus.name =
        this.props.engineStatus.name !== undefined ? this.props.engineStatus.name : ""
      engineStatus.operatingSystem =
        this.props.engineStatus.operatingSystem !== undefined
          ? this.props.engineStatus.operatingSystem
          : ""
      engineStatus.port = port
      engineStatus.user =
        this.props.engineStatus.userName !== undefined ? this.props.engineStatus.userName : ""
    }

    return engineStatus
  }

  isValidIFrame = () => {
    return this.iframe && this.iframe.contentWindow
  }

  onChooseFileCancel = () => {
    this.setState({
      browseParams: null,
      showBrowseModal: false,
    })
  }

  onChooseFileOK = (path: string) => {
    if (this.isValidIFrame()) {
      // send message for browse remote
      const wpmBrowseRemote: WebPluginMessageRequest = {
        data: path,
        message: WebPluginMessage.WEB_PLUGIN_MESSAGE_BROWSE_REMOTE,
      }
      this.iframe.contentWindow.postMessage(wpmBrowseRemote)
    }

    this.setState({
      browseParams: null,
      showBrowseModal: false,
    })
  }

  onDisplayMessageOK = () => {
    this.setState({
      displayMessageParams: null,
      showDisplayMessage: false,
    })
  }

  onErrorDismiss() {
    this.setState({ error: null })
  }

  onForensicSearchCancel = () => {
    this.setState({
      forensicSearchParams: null,
      showForensicSearchModal: false,
    })
  }

  onForensicSearchOK = (query: RequestCreateForensicSearch) => {
    this.setState({
      forensicSearchParams: null,
      showForensicSearchModal: false,
    })

    // create a forensic search and redirect to it
    query.filterMode = query.filter
      ? PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_MATCHING_ANY
      : PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_ALL
    createForensicSearch(this.props.engine, this.props.authToken, query)
      .then(response => {
        this.props.history.push(getEngineForensicSearchUrl(response.id))
      })
      .catch(error => {
        this.setState({ error: `${error.code} ${error.reason}` })
      })
  }

  onLoad = (event: any) => {
    if (this.isValidIFrame()) {
      // send message for the engine info
      const wpmEngineInfo: WebPluginMessageRequest = {
        data: this.getEngineStatus(),
        message: WebPluginMessage.WEB_PLUGIN_MESSAGE_ENGINE_INFO,
      }
      this.iframe.contentWindow.postMessage(wpmEngineInfo)

      // send message for the name table
      const wpmNameTable: WebPluginMessageRequest = {
        data: {
          showAddressNames: this.props.showAddressNames,
          showPortNames: this.props.showPortNames,
          showLocalTime: this.props.showLocalTime,
        },
        message: WebPluginMessage.WEB_PLUGIN_MESSAGE_NAME_TABLE,
      }
      this.iframe.contentWindow.postMessage(wpmNameTable)

      // send message for the theme
      const wpmTheme: WebPluginMessageRequest = {
        data: cloneDeep(this.props.theme),
        message: WebPluginMessage.WEB_PLUGIN_MESSAGE_THEME,
      }
      this.iframe.contentWindow.postMessage(wpmTheme)
    }
  }

  onMessage = (event: MessageEvent) => {
    // IMPORTANT: Check the origin of the data!
    const doMessage =
      true /*import.meta.env.MODE !== "production" || ~event.origin.indexOf(this.props.engine)*/
    if (doMessage) {
      const response: WebPluginMessageResponse = event.data

      if (response) {
        // determine which message was received and respond accordingly
        if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_BROWSE_REMOTE) {
          const wpmBrowseRemoteResponse: WebPluginData_BrowseRemoteResponse = response.data

          // make sure the user can configure the engine
          const { engineCapabilities } = this.props
          let canConfigureEngine = true
          if (engineCapabilities) {
            const policies = engineCapabilities.userRights.policies
            canConfigureEngine = policies.includes(EngineUserPolicies.configureEngine)
          }

          // show the remote browser modal
          if (
            canConfigureEngine &&
            wpmBrowseRemoteResponse &&
            wpmBrowseRemoteResponse.flags !== null &&
            wpmBrowseRemoteResponse.flags !== undefined &&
            typeof wpmBrowseRemoteResponse.flags === "number" &&
            wpmBrowseRemoteResponse.path !== null &&
            wpmBrowseRemoteResponse.path !== undefined &&
            typeof wpmBrowseRemoteResponse.path === "string"
          ) {
            this.setState({
              browseParams: {
                flags: wpmBrowseRemoteResponse.flags,
                path: wpmBrowseRemoteResponse.path,
              },
              showBrowseModal: true,
            })
          }
        } else if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_COMMAND_MESSAGE) {
          const wpmCommandMessageResponse: WebPluginData_CommandMessageResponse = response.data

          if (
            wpmCommandMessageResponse !== null &&
            wpmCommandMessageResponse !== undefined &&
            wpmCommandMessageResponse.id !== null &&
            wpmCommandMessageResponse.id !== undefined &&
            typeof wpmCommandMessageResponse.id === "number" &&
            wpmCommandMessageResponse.transid !== null &&
            wpmCommandMessageResponse.transid !== undefined &&
            typeof wpmCommandMessageResponse.transid === "number"
          ) {
            // determine which command was received
            if (
              wpmCommandMessageResponse.id ===
              WebPluginMessageCommand.WEB_PLUGIN_MESSAGE_COMMAND_DISPLAY_MESSAGE
            ) {
              const wpmCommandMessageResponseDisplayMessage: WebPluginData_CommandMessageResponseDisplayMessage =
                wpmCommandMessageResponse as WebPluginData_CommandMessageResponseDisplayMessage

              if (
                wpmCommandMessageResponseDisplayMessage &&
                wpmCommandMessageResponseDisplayMessage.message !== null &&
                wpmCommandMessageResponseDisplayMessage.message !== undefined &&
                typeof wpmCommandMessageResponseDisplayMessage.message === "string" &&
                wpmCommandMessageResponseDisplayMessage.title !== null &&
                wpmCommandMessageResponseDisplayMessage.title !== undefined &&
                typeof wpmCommandMessageResponseDisplayMessage.title === "string"
              ) {
                // display the message modal
                this.setState({
                  displayMessageParams: {
                    message: wpmCommandMessageResponseDisplayMessage.message,
                    title: wpmCommandMessageResponseDisplayMessage.title,
                  },
                  showDisplayMessage: true,
                })

                // send the result
                if (this.isValidIFrame()) {
                  const wpmCommandMessageRequest: WebPluginMessageRequest = {
                    data: {
                      id: wpmCommandMessageResponse.id,
                      result: 0,
                      transid: wpmCommandMessageResponse.transid,
                    },
                    message: WebPluginMessage.WEB_PLUGIN_MESSAGE_COMMAND_MESSAGE,
                  }
                  this.iframe.contentWindow.postMessage(wpmCommandMessageRequest)
                }
              }
            } else if (
              wpmCommandMessageResponse.id ===
              WebPluginMessageCommand.WEB_PLUGIN_MESSAGE_COMMAND_FORENSIC_SEARCH
            ) {
              const wpmCommandMessageResponseForensicSearch: WebPluginData_CommandMessageResponseForensicSearch =
                wpmCommandMessageResponse as WebPluginData_CommandMessageResponseForensicSearch

              // make sure the user can create forensic searches
              const { engineCapabilities } = this.props
              let canCreateForensicSearch = true
              if (
                engineCapabilities &&
                engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL)
              ) {
                const policies = engineCapabilities.userRights.policies
                canCreateForensicSearch = policies.includes(EngineUserPolicies.createForensicSearch)
              }

              if (
                canCreateForensicSearch &&
                wpmCommandMessageResponseForensicSearch &&
                wpmCommandMessageResponseForensicSearch.filter !== null &&
                wpmCommandMessageResponseForensicSearch.filter !== undefined &&
                typeof wpmCommandMessageResponseForensicSearch.filter === "string" &&
                wpmCommandMessageResponseForensicSearch.start !== null &&
                wpmCommandMessageResponseForensicSearch.start !== undefined &&
                typeof wpmCommandMessageResponseForensicSearch.start === "number" &&
                wpmCommandMessageResponseForensicSearch.stop !== null &&
                wpmCommandMessageResponseForensicSearch.stop !== undefined &&
                typeof wpmCommandMessageResponseForensicSearch.stop === "number"
              ) {
                // show the forensic search modal
                this.setState({
                  forensicSearchParams: {
                    filter: wpmCommandMessageResponseForensicSearch.filter,
                    start: wpmCommandMessageResponseForensicSearch.start,
                    stop: wpmCommandMessageResponseForensicSearch.stop,
                  },
                  showForensicSearchModal: true,
                })

                // send the result
                if (this.isValidIFrame()) {
                  const wpmCommandMessageRequest: WebPluginMessageRequest = {
                    data: {
                      id: wpmCommandMessageResponse.id,
                      result: 0,
                      transid: wpmCommandMessageResponse.transid,
                    },
                    message: WebPluginMessage.WEB_PLUGIN_MESSAGE_COMMAND_MESSAGE,
                  }
                  this.iframe.contentWindow.postMessage(wpmCommandMessageRequest)
                }
              }
            }
          }
        } else if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_GET_REMOTE_FILE) {
          const wpmGetRemoteFileResponse: WebPluginData_GetRemoteFileResponse = response.data

          // get the remote file from the capture engine
          if (
            wpmGetRemoteFileResponse !== null &&
            wpmGetRemoteFileResponse !== undefined &&
            typeof wpmGetRemoteFileResponse === "string"
          ) {
            fetchFile(this.props.engine, this.props.authToken, wpmGetRemoteFileResponse)
              .then((content: ArrayBuffer) => {
                if (this.isValidIFrame()) {
                  // send the remote file to the plugin
                  const wpmGetRemoteFileRequest: WebPluginMessageRequest = {
                    data: new TextDecoder().decode(content),
                    message: WebPluginMessage.WEB_PLUGIN_MESSAGE_GET_REMOTE_FILE,
                  }
                  this.iframe.contentWindow.postMessage(wpmGetRemoteFileRequest)
                }
              })
              .catch((error: any) => {
                this.setState({ error: error.toString() })
              })
          }
        } else if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_PLUGIN_MESSAGE) {
          const wpmPluginMessageResponse: WebPluginData_PluginMessage = response.data

          // send the plugin message to the capture engine
          if (wpmPluginMessageResponse) {
            const { pluginId } = this.props.match.params
            const pluginMessage: RequestPostPluginsMessage = { ...wpmPluginMessageResponse }
            sendGlobalPluginMessage(
              this.props.engine,
              this.props.authToken,
              pluginId,
              pluginMessage
            )
              .then((json: ResponsePostPluginsMessage) => {
                if (this.isValidIFrame()) {
                  // send the plugin message result to the plugin
                  const wpmPluginMessageRequest: WebPluginMessageRequest = {
                    data: json,
                    message: WebPluginMessage.WEB_PLUGIN_MESSAGE_PLUGIN_MESSAGE,
                  }
                  this.iframe.contentWindow.postMessage(wpmPluginMessageRequest)
                }
              })
              .catch(error => {
                this.setState({ error: `${error.code} ${error.reason}` })
              })
          }
        } else if (response.message === WebPluginMessage.WEB_PLUGIN_MESSAGE_SET_REMOTE_FILE) {
          const wpmSetRemoteFileResponse: WebPluginData_SetRemoteFileResponse = response.data

          // make sure the user can upload files
          const { engineCapabilities } = this.props
          let canUploadFiles = true
          if (engineCapabilities) {
            const policies = engineCapabilities.userRights.policies
            canUploadFiles = policies.includes(EngineUserPolicies.uploadFiles)
          }

          // send the remote file to the capture engine
          if (
            canUploadFiles &&
            wpmSetRemoteFileResponse &&
            wpmSetRemoteFileResponse.content !== null &&
            wpmSetRemoteFileResponse.content !== undefined &&
            typeof wpmSetRemoteFileResponse.content === "string" &&
            wpmSetRemoteFileResponse.path !== null &&
            wpmSetRemoteFileResponse.path !== undefined &&
            typeof wpmSetRemoteFileResponse.path === "string"
          ) {
            const contentBuf = new ArrayBuffer(wpmSetRemoteFileResponse.content.length)
            const contentArray = new Uint8Array(contentBuf)
            for (let i = 0, strLen = wpmSetRemoteFileResponse.content.length; i < strLen; i++) {
              contentArray[i] = wpmSetRemoteFileResponse.content.charCodeAt(i)
            }
            putFile(
              this.props.engine,
              this.props.authToken,
              wpmSetRemoteFileResponse.path,
              contentBuf
            )
              .then((content: ArrayBuffer) => {
                if (this.isValidIFrame()) {
                  // send the modified file
                  const wpmSetRemoteFileRequest: WebPluginMessageRequest = {
                    data: new TextDecoder().decode(content),
                    message: WebPluginMessage.WEB_PLUGIN_MESSAGE_SET_REMOTE_FILE,
                  }
                  this.iframe.contentWindow.postMessage(wpmSetRemoteFileRequest)
                }
              })
              .catch((error: any) => {
                this.setState({ error: error.toString() })
              })
          }
        }
      }
    }

    return Promise.resolve("")
  }

  render() {
    const { engineCapabilities, engineStatus } = this.props
    const {
      browseParams,
      displayMessageParams,
      error,
      forensicSearchParams,
      showBrowseModal,
      showDisplayMessage,
      showForensicSearchModal,
    } = this.state
    const { pluginId } = this.props.match.params

    // construct the path to the plugin web files
    const base =
      engineStatus && engineStatus.os === "Windows" && import.meta.env.MODE !== "production"
        ? "https://127.0.0.1:8887/"
        : this.props.engine + "/plugins/"
    const url = base + pluginId + "/engine/"

    const defaultDir = engineStatus && engineStatus.os === "Windows" ? "C:\\" : "/"

    // get the plugin name
    let pluginName = "Plugin"
    if (engineCapabilities && Array.isArray(engineCapabilities.pluginsInfo)) {
      const pluginInfo = engineCapabilities.pluginsInfo.find(
        (info: EngineCapabilitiesPluginInfo) => info.clsid === pluginId
      )
      if (pluginInfo) {
        pluginName = pluginInfo.name
      }
    }

    return (
      <View>
        <BreadcrumbItem to={getEnginePluginUrl(pluginId)} title={pluginName} />
        {error !== null && (
          <Alert color="danger" isOpen={error !== null} toggle={this.onErrorDismiss.bind(this)}>
            {typeof error === "string" ? error : `${error.code} ${error.reason}`}
          </Alert>
        )}
        <ViewHeader>
          <ViewHeaderTitle title={pluginName} />
        </ViewHeader>
        <ViewContent>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <EngineIFrame
                  height={height + "px"}
                  id="pluginCaptureFrame"
                  onLoad={this.onLoad}
                  ref={(ref: any) => {
                    this.iframe = ref
                  }}
                  sandbox="allow-same-origin allow-scripts"
                  src={url}
                  width={width + "px"}
                />
              )
            }}
          </AutoSizer>
        </ViewContent>
        {showBrowseModal && browseParams && (
          <ChooseFileModal
            defaultDir={defaultDir}
            dir={browseParams.path}
            onCancel={this.onChooseFileCancel}
            onOK={this.onChooseFileOK}
            showFiles={
              (browseParams.flags & WebPluginBrowseFlags.WEB_PLUGIN_BROWSE_FLAGS_SHOW_FILES) !== 0
            }
            showHiddenFiles={
              (browseParams.flags & WebPluginBrowseFlags.WEB_PLUGIN_BROWSE_FLAGS_SHOW_HIDDEN) !== 0
            }
            showHiddenFilesControl={
              (browseParams.flags &
                WebPluginBrowseFlags.WEB_PLUGIN_BROWSE_FLAGS_HIDE_HIDDEN_CONTROL) ===
              0
            }
            title={
              (browseParams.flags & WebPluginBrowseFlags.WEB_PLUGIN_BROWSE_FLAGS_SHOW_FILES) !== 0
                ? "Remote File Browser"
                : "Remote Folder Browser"
            }
          />
        )}
        {showDisplayMessage && displayMessageParams && (
          <MessageModal
            message={displayMessageParams.message !== undefined ? displayMessageParams.message : ""}
            onOK={this.onDisplayMessageOK}
            show={showDisplayMessage}
            title={displayMessageParams.title !== undefined ? displayMessageParams.title : ""}
          />
        )}
        {showForensicSearchModal && forensicSearchParams && (
          <ForensicSearchModal
            captureSessionId={undefined}
            endTime={new Date(
              forensicSearchParams.stop ? peekToDate(forensicSearchParams.stop) : Date.now()
            ).toISOString()}
            filter={forensicSearchParams.filter !== undefined ? forensicSearchParams.filter : ""}
            onCancel={this.onForensicSearchCancel}
            onOK={this.onForensicSearchOK}
            mediaSubType={undefined}
            mediaType={undefined}
            name={pluginName + " Forensic Search"}
            startTime={new Date(
              forensicSearchParams.start ? peekToDate(forensicSearchParams.start) : Date.now()
            ).toISOString()}
          />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  authToken: getAuthToken(state),
  engine: getEngine(state),
  engineCapabilities: getCapabilities(state) || null,
  engineStatus: getStatus(state),
  showAddressNames: getShowAddressNames(state),
  showPortNames: getShowPortNames(state),
  showLocalTime: getShowLocalTime(state),
  theme: getCurrentTheme(state),
})

export default connect(mapStateToProps)(PluginEngineView)
