import * as React from "react"
import { toNumber } from "lodash"
import { useSelector } from "react-redux"
import { Col, Form, FormGroup } from "reactstrap"
import { ColorPicker } from "../common/ColorPicker"
import { PrimaryButton, SecondaryButton, LightButton } from "../common/Buttons"
import { Label, FormText } from "../common/Form"
import { Input } from "../common/Input"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../common/Modal"
import { Select } from "../common/Select"
import { getEngine, getAuthToken } from "../../store"
import {
  parseMediaSpec,
  getMediaSpecTypeDefault,
  getMediaSpecClassFromMediaSpecType,
  getMediaSpecTypeExample,
} from "../../utils/mediaSpec"
import { NameTableEntry, AddressResolverResponse, NameResolverResponse } from "../../api/types"
import { MediaSpecType, MediaSpecClass } from "../../api/types/mediaTypes"
import { resolveAddresses, resolveNames } from "../../api/api"

function isValidEntryString(entry: string, entryType: MediaSpecType): boolean {
  let valid = true
  try {
    parseMediaSpec(entry, entryType)
  } catch {
    valid = false
  }
  return valid
}

type NameEditModalProps = {
  initialEntry: NameTableEntry | null
  groups: string[]
  title?: string
  onOK: (entry: NameTableEntry) => void
  onCancel: () => void
}

const NameEditModal: React.FC<NameEditModalProps> = ({
  initialEntry,
  groups,
  title,
  onOK,
  onCancel,
}) => {
  const engine = useSelector(getEngine)
  const authToken = useSelector(getAuthToken)

  const [name, setName] = React.useState(initialEntry?.name || "Untitled")
  const [group, setGroup] = React.useState(initialEntry?.group ?? "")
  const [entryType, setEntryType] = React.useState(
    initialEntry?.entryType ?? MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
  )
  const [entry, setEntry] = React.useState(
    initialEntry?.entry ?? getMediaSpecTypeDefault(entryType)
  )
  const [color, setColor] = React.useState(initialEntry?.color ?? "#000000")
  const [nodeType, setNodeType] = React.useState(initialEntry?.nodeType ?? "Unknown")
  const [trust, setTrust] = React.useState(initialEntry?.trust ?? "Unknown")

  const modalTitle = title || (initialEntry != null ? "Edit Name" : "Insert Name")
  const isNameValid = name.length !== 0
  const isEntryValid = isValidEntryString(entry, entryType)
  const entryTypeExample = getMediaSpecTypeExample(entryType)
  const isNodeTypeEnabled =
    getMediaSpecClassFromMediaSpecType(entryType) === MediaSpecClass.MEDIA_SPEC_CLASS_ADDRESS
  const isTrustEnabled = isNodeTypeEnabled
  const isResolveEnabled =
    entryType === MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS ||
    entryType === MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS

  const onChangeEntryType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEntryType: MediaSpecType = toNumber(event.target.value)
    setEntryType(newEntryType)
    if (!isValidEntryString(entry, newEntryType) || entry === getMediaSpecTypeDefault(entryType)) {
      setEntry(getMediaSpecTypeDefault(newEntryType))
    }
  }

  const onResolveAddress = () => {
    resolveAddresses(engine, authToken, [
      {
        entry: entry,
        entryType: entryType,
      },
    ])
      .then((response: AddressResolverResponse) => {
        if (response && Array.isArray(response.entries)) {
          const result = response.entries.find(item => item.entry === entry)
          if (result && result.result) {
            setName(result.result)
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const onResolveName = () => {
    resolveNames(engine, authToken, [
      {
        entry: name,
        entryType: entryType,
      },
    ])
      .then((response: NameResolverResponse) => {
        if (response && Array.isArray(response.entries)) {
          const result = response.entries.find(item => item.entry === name)
          if (result && result.result) {
            setEntry(result.result)
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const getEntry = (): NameTableEntry => {
    return {
      color: color,
      entry: entry,
      entryType: entryType,
      group: group,
      modified: new Date().toISOString(),
      name: name,
      nodeType: isNodeTypeEnabled && nodeType !== "Unknown" ? nodeType : undefined,
      source: "User",
      trust: isTrustEnabled && trust !== "Unknown" ? trust : undefined,
      used: initialEntry?.used ?? undefined,
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    onOK(getEntry())
  }

  return (
    <Modal size="md" isOpen={true} style={{ maxWidth: "600px" }}>
      <ModalHeader toggle={onCancel}>{modalTitle}</ModalHeader>
      <ModalBody>
        <Form id="name-edit-form" onSubmit={onSubmit} noValidate>
          <FormGroup row>
            <Label md="2" for="name-edit--name">
              Name
            </Label>
            <Col md="10">
              <Input
                type="text"
                id="name-edit--name"
                name="name-edit--name"
                maxLength={63}
                autoFocus
                spellCheck={false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setName(event.target.value)
                }
                value={name}
                required
                invalid={!isNameValid}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2" for="name-edit--group">
              Group
            </Label>
            <Col md="10">
              <Input
                type="text"
                id="name-edit--group"
                name="name-edit--group"
                list="grouplist"
                maxLength={63}
                autoComplete="off"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setGroup(event.target.value)
                }
                value={group}
              />
              {groups && (
                <datalist id="grouplist">
                  {groups.map(group => (
                    <option key={group} value={group} />
                  ))}
                </datalist>
              )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2" for="name-edit--type">
              Type
            </Label>
            <Col md="10">
              <Select
                id="name-edit--type"
                name="name-edit--type"
                value={entryType}
                onChange={onChangeEntryType}
              >
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS}>
                  Ethernet Address
                </option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_WIRELESS_ADDRESS}>
                  Wireless Address
                </option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_WAN_DLCI_ADDRESS}>
                  WAN DLCI Address
                </option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS}>IP Address</option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_IPV6_ADDRESS}>IPv6 Address</option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_APPLETALK_ADDRESS}>
                  AppleTalk Address
                </option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_DECNET_ADDRESS}>DECnet Address</option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_IPX_ADDRESS}>IPX Address</option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_PROTOCOL}>
                  Ethernet Protocol
                </option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_LSAP}>802.2 LSAP Value</option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_SNAP}>802.2 SNAP ID</option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_WAN_PPP_PROTOCOL}>
                  WAN PPP Protocol
                </option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_WAN_FRAME_RELAY_PROTOCOL}>
                  WAN Frame Relay Protocol
                </option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT}>TCP-UDP-SCTP Port</option>
                <option value={MediaSpecType.MEDIA_SPEC_TYPE_NW_PORT}>NetWare Port</option>
              </Select>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2" for="name-edit--entry">
              Entry
            </Label>
            <Col md="10">
              <Input
                type="text"
                id="name-edit--entry"
                name="name-edit--entry"
                maxLength={63}
                spellCheck={false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setEntry(event.target.value)
                }
                value={entry}
                required
                invalid={!isEntryValid}
              />
              {entryTypeExample && <FormText>{`Ex.: ${entryTypeExample}`}</FormText>}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2" for="name-edit--color">
              Color
            </Label>
            <Col md="10">
              <ColorPicker
                id="name-edit--color"
                color={color}
                onChange={(newColor: string) => {
                  setColor(newColor)
                }}
              />
            </Col>
          </FormGroup>
          <FormGroup row disabled={!isNodeTypeEnabled}>
            <Label md="2" for="name-edit--node-type">
              Node Type
            </Label>
            <Col md="10">
              <Select
                id="name-edit--node-type"
                name="name-edit--node-type"
                disabled={!isNodeTypeEnabled}
                value={nodeType}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setNodeType(event.target.value)
                }
              >
                <option value="Unknown">Unknown</option>
                <option value="Workstation">Workstation</option>
                <option value="Server">Server</option>
                <option value="Router">Router</option>
                <option value="Switch">Switch</option>
                <option value="Repeater">Repeater</option>
                <option value="Printer">Printer</option>
                <option value="Access Point">Access Point</option>
              </Select>
            </Col>
          </FormGroup>
          <FormGroup row disabled={!isTrustEnabled} noMargin>
            <Label md="2" for="name-edit--trust">
              Trust
            </Label>
            <Col md="10">
              <Select
                id="name-edit--trust"
                name="name-edit--trust"
                disabled={!isTrustEnabled}
                value={trust}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setTrust(event.target.value)
                }
              >
                <option value="Unknown">Unknown</option>
                <option value="Known">Known</option>
                <option value="Trusted">Trusted</option>
                <option value="Untrusted">Untrusted</option>
              </Select>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <LightButton
          id="name-edit--resolve-address"
          disabled={!isResolveEnabled}
          onClick={() => onResolveAddress()}
        >
          Resolve Address
        </LightButton>
        <LightButton
          id="name-edit--resolve-name"
          disabled={!isResolveEnabled}
          onClick={() => onResolveName()}
        >
          Resolve Name
        </LightButton>
        <SecondaryButton id="cancel" onClick={() => onCancel()} style={{ marginLeft: "auto" }}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          id="ok"
          disabled={!isNameValid || !isEntryValid}
          type="submit"
          form="name-edit-form"
        >
          OK
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  )
}

export { NameEditModal }
