import * as React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { NavLink, withRouter, RouteComponentProps } from "react-router-dom"
import { CaptureRouteParams } from "../Capture"
import { getUserId, getCapabilities } from "../../store"
import {
  CaptureProperties,
  EngineCapabilitiesPluginInfo,
  ForensicSearchProperties,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { PluginCategory } from "../../api/types/peekTypes"
import { CLSID_COMPASS } from "../../utils/pluginUtils"

function capabilityCheck(engineCapabilities: ResponseGetEngineCapabilities, capability: string) {
  return engineCapabilities.capabilities.includes(capability)
}

function accessCheck(engineCapabilities: ResponseGetEngineCapabilities, policy: string) {
  return engineCapabilities.userRights.policies.includes(policy)
}

const StatisticsIDs = {
  appFlowStats: "4224FD28-5996-4C62-8DCD-3D615E71C1A1",
  appHistoryStats: "7548DBE5-A180-4A4B-A88B-81EE45994D29",
  appResponseTimeStats: "64EF540F-1582-4115-86CD-C1378EF862AC",
  applicationStats: "376D6465-31B3-4359-8E8B-CE0CCBC4FB6A",
  callStats: "2C98A67E-7C8B-4A26-BF0E-FB6E59EF328D",
  conversationStats: "5C91C7DE-D4EB-4AC2-84F5-84829A8837BF",
  countryStats: "BAC56A49-681B-47EC-81B6-923A9392DFD1",
  dashboardStats: "5F520656-9C9D-4897-964D-AE7E199604DE",
  errorStats: "DEA0A0A2-05DF-41F2-8E87-F97303D7D849",
  expert: "3C24866B-CC05-4CD3-8473-75AABF4A6D68",
  mplsVlanVxlanStats: "FEB68956-0D48-49EF-9609-F8DB108F7909",
  networkStats: "81A6B979-D9E2-45ED-8EF7-928D61C53356",
  nodeHierarchyStats: "1338335F-33D7-475C-B222-A7C8EDAE1FCE",
  nodeStats: "7FABA337-7DB5-4029-B400-400D39DEE2EC",
  protocolStats: "2D33B8EB-CCFB-4AF8-B04D-39AB35EFB677",
  protocolByIdStats: "135C247F-539C-49BB-9395-CAB32304944F",
  sizeStats: "9BF9BAF3-85A2-497B-82B7-BA36D650B976",
  summaryStats: "54A9726D-EA8F-434A-911F-9750E3229DAF",
  voip: "0E6A736F-5B28-40B5-8F49-FF1576D1B280",
  WLAN: "804D54CF-3035-4D0F-AD6E-9A8CCE78D68C",
}

function hasStat(captureProperties: CaptureProperties | ForensicSearchProperties, stat: string) {
  return captureProperties.statsEnabled.includes(stat)
}

const NavDiv = styled.nav`
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem 1rem 1rem;
  box-shadow: ${props => props.theme.navBoxShadow};
  overflow: hidden;
  user-select: none;
  color: ${props => props.theme.captureNavColor};
  background-color: ${props => props.theme.captureNavBackgroundColor};
`

const NavItemGroup = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
`

const NavItemGroupTitle = styled.h5`
  color: inherit;
  padding: 0;
  margin: 6px 0 3px 0;
  white-space: nowrap;
`

const NavItem = styled.li`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`

const NavItemLink = styled(NavLink)`
  height: calc(1.5rem + 3px);
  color: inherit;
  text-decoration: none;
  white-space: nowrap;
  border-bottom: 3px solid transparent;
  transition: border-bottom-color 0.15s ease-in-out;
  margin-left: 1rem;

  &:hover,
  &.active {
    color: inherit;
    text-decoration: none;
    border-bottom: 3px solid currentColor;
  }
`

type CaptureNavProps = {
  userId: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  captureProperties: CaptureProperties | ForensicSearchProperties
}

class CaptureNav extends React.Component<
  CaptureNavProps & RouteComponentProps<CaptureRouteParams>
> {
  render() {
    const { userId, engineCapabilities, captureProperties } = this.props
    if (!engineCapabilities) return null

    const { type } = this.props.match.params

    // Check capabilities.
    const bCapabilityExpert = capabilityCheck(engineCapabilities, EngineCapabilities.expertQuery)
    const bCapabilityWeb = capabilityCheck(engineCapabilities, EngineCapabilities.remoteWebAnalysis)
    const bCapabilityVoice = capabilityCheck(engineCapabilities, EngineCapabilities.voice)
    const bCapabilityReconstructions = capabilityCheck(
      engineCapabilities,
      EngineCapabilities.reconstructions
    )
    const bCapabilityStats = capabilityCheck(engineCapabilities, EngineCapabilities.stats)
    const bCapabilityPacketBuffer = capabilityCheck(
      engineCapabilities,
      EngineCapabilities.packetbuffer
    )
    const bCapabilityLogging = capabilityCheck(engineCapabilities, EngineCapabilities.logging)
    const bCapabilityGraphs = capabilityCheck(engineCapabilities, EngineCapabilities.graphs)
    const bCapabilityAppStats = capabilityCheck(
      engineCapabilities,
      EngineCapabilities.applicationStats
    )

    // TODO: check activation
    const bActivatedExpert = true
    const bActivatedWeb = true
    const bActivatedVoice = true
    const bActivatedTelchemy = true
    const bActivatedDashboard = true
    const bActivatedEngineStats = true
    const bActivatedPeerMap = true
    const bActivatedGraphs = true

    // TODO: check access policy
    const isUserOwner = userId === captureProperties.creatorSID
    const bPermitViewStats =
      isUserOwner || accessCheck(engineCapabilities, EngineUserPolicies.viewStats)
    const bPermitViewPackets =
      isUserOwner || accessCheck(engineCapabilities, EngineUserPolicies.viewPackets)
    const bSynthesizesPackets = false //?

    const bStatsViews = bCapabilityStats && bPermitViewStats && bActivatedEngineStats

    const bShowSynthesizedPackets = false

    const bTabNetworkDashboard =
      bStatsViews &&
      bActivatedDashboard &&
      (hasStat(captureProperties, StatisticsIDs.dashboardStats) ||
        hasStat(captureProperties, StatisticsIDs.networkStats) ||
        hasStat(captureProperties, StatisticsIDs.errorStats) ||
        hasStat(captureProperties, StatisticsIDs.nodeStats) ||
        hasStat(captureProperties, StatisticsIDs.protocolStats))
    const bTabApplicationsDashboard =
      bStatsViews &&
      bCapabilityAppStats &&
      bActivatedDashboard &&
      (hasStat(captureProperties, StatisticsIDs.applicationStats) ||
        hasStat(captureProperties, StatisticsIDs.appFlowStats) ||
        hasStat(captureProperties, StatisticsIDs.appHistoryStats))
    const bTabPackets =
      captureProperties.packetBufferEnabled &&
      bCapabilityPacketBuffer &&
      bPermitViewPackets &&
      (!bSynthesizesPackets || bShowSynthesizedPackets)
    const bTabEvents =
      (("logEnabled" in captureProperties && captureProperties.logEnabled) ||
        type === "captures") &&
      bCapabilityLogging
    const bTabExpert =
      bPermitViewStats &&
      captureProperties.expertEnabled !== undefined &&
      captureProperties.expertEnabled &&
      bActivatedExpert &&
      bCapabilityExpert
    const bTabWeb =
      bPermitViewStats &&
      captureProperties.webEnabled !== undefined &&
      captureProperties.webEnabled &&
      bActivatedWeb &&
      bCapabilityWeb
    const bTabVoice =
      bPermitViewStats &&
      captureProperties.voiceEnabled !== undefined &&
      captureProperties.voiceEnabled &&
      bCapabilityVoice &&
      (bActivatedVoice || bActivatedTelchemy)
    const bTabReconstructions =
      bPermitViewStats &&
      captureProperties.reconstructionsEnabled !== undefined &&
      captureProperties.reconstructionsEnabled &&
      bCapabilityReconstructions
    const bTabPeerMap =
      bStatsViews &&
      bActivatedPeerMap &&
      hasStat(captureProperties, StatisticsIDs.nodeStats) &&
      hasStat(captureProperties, StatisticsIDs.protocolStats) &&
      hasStat(captureProperties, StatisticsIDs.conversationStats)
    const bTabGraphs =
      bPermitViewStats && captureProperties.graphsEnabled && bActivatedGraphs && bCapabilityGraphs
    const bTabNodes =
      bStatsViews &&
      hasStat(captureProperties, StatisticsIDs.nodeStats) &&
      hasStat(captureProperties, StatisticsIDs.nodeHierarchyStats)
    const bTabProtocols = bStatsViews && hasStat(captureProperties, StatisticsIDs.protocolStats)
    const bTabApplications =
      bStatsViews && hasStat(captureProperties, StatisticsIDs.applicationStats)
    const bTabCountries = bStatsViews && hasStat(captureProperties, StatisticsIDs.countryStats)
    const bTabMPLSVLANVXLAN =
      bStatsViews && hasStat(captureProperties, StatisticsIDs.mplsVlanVxlanStats)
    const bTabSummary = bStatsViews && hasStat(captureProperties, StatisticsIDs.summaryStats)
    const bTabWireless = hasStat(captureProperties, StatisticsIDs.WLAN)

    type NavItemInfo = {
      title: string
      url: string
      enabled: boolean
    }

    type TabGroup = {
      title: string
      tabs: NavItemInfo[]
    }

    const tabs: TabGroup[] = [
      {
        title: "Dashboard",
        tabs: [
          {
            title: "Network",
            url: "network-dashboard",
            enabled: bTabNetworkDashboard,
          },
          {
            title: "Applications",
            url: "app-dashboard",
            enabled: bTabApplicationsDashboard,
          },
          {
            title: "Voice & Video",
            url: "voip-dashboard",
            enabled: bTabVoice,
          },
        ],
      },
      {
        title: "Capture",
        tabs: [
          {
            title: "Packets",
            url: "packets",
            enabled: bTabPackets,
          },
          {
            title: "Events",
            url: "events",
            enabled: bTabEvents,
          },
        ],
      },
      {
        title: "Expert",
        tabs: [
          {
            title: "Clients/Servers",
            url: "expert-clients-servers",
            enabled: bTabExpert,
          },
          {
            title: "Flows",
            url: "expert-flows",
            enabled: bTabExpert,
          },
          {
            title: "Applications",
            url: "expert-applications",
            enabled: bTabExpert,
          },
          {
            title: "Event Summary",
            url: "expert-event-summary",
            enabled: bTabExpert,
          },
          {
            title: "Event Log",
            url: "expert-event-log",
            enabled: bTabExpert,
          },
        ],
      },
      {
        title: "Web",
        tabs: [
          {
            title: "Servers",
            url: "web-servers",
            enabled: bTabWeb,
          },
          {
            title: "Clients",
            url: "web-clients",
            enabled: bTabWeb,
          },
          {
            title: "Pages",
            url: "web-pages",
            enabled: bTabWeb,
          },
          {
            title: "Requests",
            url: "web-requests",
            enabled: bTabWeb,
          },
        ],
      },
      {
        title: "Voice & Video",
        tabs: [
          {
            title: "Calls",
            url: "voip-calls",
            enabled: bTabVoice,
          },
          {
            title: "Media",
            url: "voip-media",
            enabled: bTabVoice,
          },
        ],
      },
      {
        title: "Visuals",
        tabs: [
          {
            title: "Peer Map",
            url: "peer-map",
            enabled: bTabPeerMap,
          },
          {
            title: "Graphs",
            url: "graphs",
            enabled: bTabGraphs,
          },
          {
            title: "Reconstructions",
            url: "reconstructions",
            enabled: bTabReconstructions,
          },
        ],
      },
      {
        title: "Statistics",
        tabs: [
          {
            title: "Summary",
            url: "summary",
            enabled: bTabSummary,
          },
          {
            title: "Nodes",
            url: "nodes",
            enabled: bTabNodes,
          },
          {
            title: "Protocols",
            url: "protocols",
            enabled: bTabProtocols,
          },
          {
            title: "Applications",
            url: "applications",
            enabled: bTabApplications,
          },
          {
            title: "Countries",
            url: "countries",
            enabled: bTabCountries,
          },
          {
            title: "MPLS/VLAN/VXLAN",
            url: "mplsvlanvxlan",
            enabled: bTabMPLSVLANVXLAN,
          },
        ],
      },
      {
        title: "Wireless",
        tabs: [
          {
            title: "WLAN",
            url: "nodes-wireless",
            enabled: bTabWireless,
          },
        ],
      },
    ]

    if (
      Array.isArray(captureProperties.pluginsEnabled) &&
      engineCapabilities != null &&
      Array.isArray(engineCapabilities.pluginsInfo)
    ) {
      const webPluginsDashboard: any[] = []
      const webPluginsTools: any[] = []
      engineCapabilities.pluginsInfo.forEach((info: EngineCapabilitiesPluginInfo) => {
        if (
          bPermitViewStats &&
          captureProperties.pluginsEnabled.includes(info.clsid) &&
          Array.isArray(info.categoryIds) &&
          info.categoryIds.includes(PluginCategory.PLUGIN_CATEGORY_OMNI_WEB_PLUGIN_CAPTURE)
        ) {
          if (info.clsid === CLSID_COMPASS) {
            // compass
            webPluginsDashboard.push({
              title: info.name,
              url: `plugin/${info.clsid}`,
              enabled: true,
            })
          } else {
            webPluginsTools.push({
              title: info.name,
              url: `plugin/${info.clsid}`,
              enabled: true,
            })
          }
        }
      })
      if (webPluginsDashboard.length > 0) {
        const dashboardTabIndex = tabs.findIndex(tab => tab.title === "Dashboard")
        if (dashboardTabIndex !== -1) {
          tabs[dashboardTabIndex].tabs.push(...webPluginsDashboard)
        }
      }
      if (webPluginsTools.length > 0) {
        tabs.push({
          title: "Tools",
          tabs: webPluginsTools,
        })
      }
    }

    let tabGroups: React.ReactNode[] = []
    tabs.forEach(group => {
      const items: React.ReactNode[] = []
      group.tabs.forEach(item => {
        if (item.enabled) {
          items.push(
            <NavItem key={item.title}>
              <NavItemLink to={`${this.props.match.url}/${item.url}`}>{item.title}</NavItemLink>
            </NavItem>
          )
        }
      })
      if (items.length) {
        tabGroups.push(
          <NavItem key={group.title}>
            <NavItemGroup>
              <NavItem>
                <NavItemGroupTitle>{group.title}</NavItemGroupTitle>
              </NavItem>
              {items}
            </NavItemGroup>
          </NavItem>
        )
      }
    })

    // Don't show anything except the Home tab if this is a
    // forensic search with no results.
    const forensicSearchProperties = captureProperties as ForensicSearchProperties
    if (
      forensicSearchProperties.openResult !== undefined &&
      forensicSearchProperties.openResult !== 0
    ) {
      tabGroups = []
    }

    return (
      <NavDiv>
        <NavItemGroup>
          <NavItem>
            <NavItemGroup>
              <NavItem>
                <NavItemGroupTitle style={{ marginTop: 0 }}>
                  <NavItemLink to={`${this.props.match.url}/home`} style={{ marginLeft: 0 }}>
                    <FontAwesome name="home" /> Home
                  </NavItemLink>
                </NavItemGroupTitle>
              </NavItem>
            </NavItemGroup>
          </NavItem>
          {tabGroups}
        </NavItemGroup>
      </NavDiv>
    )
  }
}

const mapStateToProps = (state: object) => ({
  userId: getUserId(state),
  engineCapabilities: getCapabilities(state) || null,
})

export default withRouter(connect(mapStateToProps)(CaptureNav))
