import * as React from "react"
import { clamp, toNumber } from "lodash"
import { InputGroup } from "reactstrap"
import { Input } from "../common/Input"
import { LightButton } from "../common/Buttons"

type GoToPacketInputProps = {
  value: number
  min: number
  max: number
  onGoToPacket: (packetNumber: number) => void
}

type GoToPacketInputState = {
  invalid: boolean
}

export class GoToPacketInput extends React.Component<GoToPacketInputProps> {
  input: any | null = null

  state: GoToPacketInputState = {
    invalid: false,
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.input) {
      let invalid = false
      const value = toNumber(this.input.value)
      if (!Number.isNaN(value)) {
        const { min, max } = this.props
        invalid = value < min || value > max
      }
      this.setState({ invalid })
    }
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.onGo()
    }
  }

  onGo = () => {
    if (this.input) {
      const value = toNumber(this.input.value)
      if (!Number.isNaN(value)) {
        const { onGoToPacket } = this.props
        onGoToPacket(value)
      }
    }
  }

  render() {
    const { invalid } = this.state
    const { value, min, max } = this.props
    const defaultValue = clamp(value, min, max)
    // TODO: find a way to avoid recreating the input
    return (
      <InputGroup>
        <Input
          innerRef={(ref: any) => {
            this.input = ref
          }}
          style={{ width: "10em" }}
          type="number"
          id="decode-packet-number"
          aria-label="Decode packet number"
          key={defaultValue}
          defaultValue={String(defaultValue)}
          onChange={this.onChange}
          onKeyDown={this.onKeyDown}
          className="border-right-0"
          min={min}
          max={max}
          step={1}
          invalid={invalid}
        />
        <LightButton disabled={invalid} onClick={this.onGo}>
          Go
        </LightButton>
      </InputGroup>
    )
  }
}
