import * as React from "react"
import cn from "classnames"
import styled from "styled-components"

export const StepProgressList = styled.ol`
  position: relative;
  display: flex;
  align-items: flex-end;
  padding: 0;
  margin: 0;

  & > * + * {
    margin-left: 0.5rem;
  }
`

export const StepProgressListItem = styled.li`
  display: block;
  flex: 1 1 100%;
  padding-bottom: 0.25rem;
  text-transform: uppercase;
  color: ${props => props.theme.textMutedColor};
  border-bottom: 0.25rem solid ${props => props.theme.tableBorderColor};

  &.done {
    color: ${props =>
      props.theme.name === "Dark"
        ? props.theme.textColor
        : props.theme.primaryButtonBackgroundColor};
    border-bottom-color: ${props => props.theme.primaryButtonBackgroundColor};
  }
`

type StepProgressProps = {
  items: React.ReactNode[]
  value: number
}

export const StepProgress = ({ items, value }: StepProgressProps) => {
  let nodes: React.ReactNode[] = []
  if (items.length > 0) {
    nodes = items.map((item, i) => {
      const pct = (100 / items.length) * i
      return (
        <StepProgressListItem
          key={i}
          style={{ left: `${pct}%` }}
          className={cn({ done: i <= value })}
        >
          {item}
        </StepProgressListItem>
      )
    })
  }

  return <StepProgressList>{nodes}</StepProgressList>
}
