import * as React from "react"
import styled, { useTheme, DefaultTheme } from "styled-components"
import { useSelector } from "react-redux"
import { PopoverHeader, PopoverBody } from "../common/Popover"
import PropTable from "../common/PropTable"
import { UncontrolledPopover } from "../common/UncontrolledPopover"
import { formatDuration, formatInteger, formatISODateTime } from "../../utils/formatUtils"
import { measureText } from "../../utils/measureText"
import { getShowAddressNames, getShowLocalTime, getShowPortNames } from "../../store"
import { MSAProjectFlowInfo, MSAProjectProperties, MSAProjectSegmentInfo } from "../../api/types"
import Cloud from "./Cloud"

const Outer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const Inner = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  background-color: ${props => props.theme.tableBackgroundColor};
  border-left: 1px solid ${props => props.theme.tableBorderColor};
  border-bottom: 1px solid ${props => props.theme.tableBorderColor};
  border-right: 1px solid ${props => props.theme.tableBorderColor};
`

const Style = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
  margin-top: auto;
  margin-bottom: auto;
  & > * {
    flex-shrink: 0;
  }
`

type SegmentsViewProps = {
  project: MSAProjectProperties
  flowEntryIndex: number
}

type ViewItem = {
  segInfo: MSAProjectSegmentInfo
  flowInfo?: MSAProjectFlowInfo
  nextDelayValid: boolean
  prevDelayValid: boolean
  nextDelay: number[] // 0=avg, 1=min, 2=max
  prevDelay: number[] // 0=avg, 1=min, 2=max
  nextPacketsLost: number
  prevPacketsLost: number
  nextHops: number
  prevHops: number
}

type ViewState = {
  nextDelayValid: boolean
  prevDelayValid: boolean
  nextDelay: number[] // 0=avg, 1=min, 2=max
  prevDelay: number[] // 0=avg, 1=min, 2=max
  nextPacketsLost: number
  prevPacketsLost: number
  viewItems: ViewItem[]
}

type Point = {
  x: number
  y: number
}

type Rect = {
  x: number
  y: number
  width: number
  height: number
}

const SegmentsView = ({ project, flowEntryIndex }: SegmentsViewProps) => {
  const theme = useTheme() as DefaultTheme
  const showAddressNames: boolean = useSelector(getShowAddressNames)
  const showPortNames: boolean = useSelector(getShowPortNames)
  const [viewState, setViewState] = React.useState<ViewState | null>(null)
  const showLocalTime = useSelector(getShowLocalTime)

  React.useEffect(() => {
    if (
      project.segmentInfo &&
      project.flowEntries &&
      flowEntryIndex >= 0 &&
      flowEntryIndex < project.flowEntries.length
    ) {
      const flowEntry = project.flowEntries[flowEntryIndex]

      const newViewState: ViewState = {
        nextDelayValid: false,
        prevDelayValid: false,
        nextDelay: [0, 0, 0],
        prevDelay: [0, 0, 0],
        nextPacketsLost: 0,
        prevPacketsLost: 0,
        viewItems: [],
      }
      for (let iSeg = 0, nSeg = project.segmentInfo.length; iSeg < nSeg; iSeg++) {
        const segInfo = project.segmentInfo[iSeg]
        const flowId = flowEntry.flowList[iSeg]
        const flowInfo = segInfo.flows.find(flowInfo => flowInfo.id === flowId)

        const newViewItem: ViewItem = {
          segInfo: segInfo,
          flowInfo: flowInfo,
          nextDelayValid: false,
          prevDelayValid: false,
          nextDelay: [0, 0, 0],
          prevDelay: [0, 0, 0],
          nextPacketsLost: 0,
          prevPacketsLost: 0,
          nextHops: -1,
          prevHops: -1,
        }

        if (flowInfo) {
          if (
            flowInfo.nextDelayCount !== undefined &&
            flowInfo.nextDelayCount > 0 &&
            flowInfo.nextDelayAccum !== undefined &&
            flowInfo.nextDelayMin !== undefined &&
            flowInfo.nextDelayMax !== undefined
          ) {
            newViewItem.nextDelayValid = true
            newViewItem.nextDelay[0] = flowInfo.nextDelayAccum / flowInfo.nextDelayCount
            newViewItem.nextDelay[1] = flowInfo.nextDelayMin
            newViewItem.nextDelay[2] = flowInfo.nextDelayMax
          }

          if (
            flowInfo.prevDelayCount !== undefined &&
            flowInfo.prevDelayCount > 0 &&
            flowInfo.prevDelayAccum !== undefined &&
            flowInfo.prevDelayMin !== undefined &&
            flowInfo.prevDelayMax !== undefined
          ) {
            newViewItem.prevDelayValid = true
            newViewItem.prevDelay[0] = flowInfo.prevDelayAccum / flowInfo.prevDelayCount
            newViewItem.prevDelay[1] = flowInfo.prevDelayMin
            newViewItem.prevDelay[2] = flowInfo.prevDelayMax
          }

          newViewItem.nextPacketsLost = flowInfo.nextLostPackets
          newViewItem.prevPacketsLost = flowInfo.prevLostPackets

          const iNextSeg = iSeg + 1
          if (iNextSeg < flowEntry.flowList.length && iNextSeg < project.segmentInfo.length) {
            const nextFlowId = flowEntry.flowList[iNextSeg]
            const nextSegInfo = project.segmentInfo[iNextSeg]
            const nextFlowInfo = nextSegInfo.flows.find(flowInfo => flowInfo.id === nextFlowId)
            if (nextFlowInfo) {
              if (flowInfo.clientTTL > 0 && nextFlowInfo.clientTTL > 0) {
                newViewItem.nextHops = Math.abs(flowInfo.clientTTL - nextFlowInfo.clientTTL)
              }
              if (flowInfo.serverTTL > 0 && nextFlowInfo.serverTTL > 0) {
                newViewItem.prevHops = Math.abs(nextFlowInfo.serverTTL - flowInfo.serverTTL)
              }
            }
          }
        }

        newViewState.viewItems.push(newViewItem)

        if (flowEntry.nextDelayCount > 0) {
          newViewState.nextDelayValid = true
          newViewState.nextDelay[0] = flowEntry.nextDelayAccum / flowEntry.nextDelayCount
          newViewState.nextDelay[1] = flowEntry.nextDelayMin
          newViewState.nextDelay[2] = flowEntry.nextDelayMax
        }

        if (flowEntry.prevDelayCount > 0) {
          newViewState.prevDelayValid = true
          newViewState.prevDelay[0] = flowEntry.prevDelayAccum / flowEntry.prevDelayCount
          newViewState.prevDelay[1] = flowEntry.prevDelayMin
          newViewState.prevDelay[2] = flowEntry.prevDelayMax
        }

        newViewState.nextPacketsLost = flowEntry.nextLostPackets
        newViewState.prevPacketsLost = flowEntry.prevLostPackets
      }
      setViewState(newViewState)
    } else {
      setViewState(null)
    }
  }, [project.segmentInfo, project.flowEntries, flowEntryIndex])

  const formatSegmentProp = (prop: string, data: any) => {
    const viewItem = data as ViewItem
    const { flowInfo } = viewItem
    if (!flowInfo) return null
    const isTCP = flowInfo.ipProtocol === 6
    switch (prop) {
      case "Client": {
        const address = (showAddressNames && flowInfo.clientAddressName) || flowInfo.clientAddress
        const port = (showPortNames && flowInfo.clientPortName) || flowInfo.clientPort
        return `${address}:${port}`
      }
      case "Server": {
        const address = (showAddressNames && flowInfo.serverAddressName) || flowInfo.serverAddress
        const port = (showPortNames && flowInfo.serverPortName) || flowInfo.serverPort
        return `${address}:${port}`
      }
      case "Packets":
        return formatInteger(flowInfo.packets)
      case "Client Packets":
        return formatInteger(flowInfo.clientPackets)
      case "Server Packets":
        return formatInteger(flowInfo.serverPackets)
      case "Packets Lost":
        return formatInteger(flowInfo.nextLostPackets + flowInfo.prevLostPackets)
      case "Client Packets Lost":
        return formatInteger(flowInfo.nextLostPackets)
      case "Server Packets Lost":
        return formatInteger(flowInfo.prevLostPackets)
      case "Client Retransmissions":
        if (isTCP) {
          return formatInteger(flowInfo.clientRetransmissions)
        }
        break
      case "Server Retransmissions":
        if (isTCP) {
          return formatInteger(flowInfo.serverRetransmissions)
        }
        break
      case "Start":
        return formatISODateTime(flowInfo.startTime, 6, showLocalTime)
      case "Finish":
        return formatISODateTime(flowInfo.endTime, 6, showLocalTime)
      case "Duration":
        return formatDuration(flowInfo.duration, 6)
      case "TCP Status":
        if (isTCP) {
          return flowInfo.tcpStatus
        }
        break
    }
    return null
  }

  const formatCloudProp = (prop: string, data: any) => {
    const viewItem = data as ViewItem
    const { nextHops, prevHops } = viewItem
    switch (prop) {
      case "Client Hops":
        if (nextHops !== -1) {
          return nextHops.toString()
        }
        break
      case "Server Hops":
        if (prevHops !== -1) {
          return prevHops.toString()
        }
        break
    }
    return null
  }

  const renderHopArrow = (
    key: string,
    x1: number,
    x2: number,
    y: number,
    h: number,
    isClient: boolean,
    value: string
  ) => {
    const size = measureText(value, { fontSize: "12px" })

    const color = isClient ? theme.clientColor : theme.serverColor
    const markerUrl = isClient ? "url(#arrowClient)" : "url(#arrowServer)"

    const swapped = x1 > x2
    if (swapped) {
      const temp = x1
      x1 = x2
      x2 = temp
    }

    const ptCenter: Point = {
      x: x1 + (x2 - x1) / 2,
      y: y - h,
    }

    const rectText: Rect = {
      x: ptCenter.x - size.width / 2,
      y: ptCenter.y - size.height / 2,
      width: size.width,
      height: size.height,
    }

    const ptTextLeft: Point = {
      x: rectText.x,
      y: rectText.y + rectText.height / 2,
    }

    const ptTextRight: Point = {
      x: rectText.x + rectText.width,
      y: rectText.y + rectText.height / 2,
    }

    let r = Math.abs((ptTextLeft.x - x1) / h)
    if (r > 2.8) r -= 1.0

    const cp1: Point[] = [
      {
        x: x1 + (ptTextLeft.x - x1) / 10,
        y: ptTextLeft.y + h / r,
      },
      {
        x: x1 + (ptTextLeft.x - x1) / 2,
        y: ptTextLeft.y,
      },
    ]

    const cp2: Point[] = [
      {
        x: ptTextRight.x + (x2 - ptTextRight.x) / 2,
        y: ptTextRight.y,
      },
      {
        x: x2 - (x2 - ptTextRight.x) / 10,
        y: ptTextLeft.y + h / r,
      },
    ]

    return (
      <g key={key}>
        {/*
        <circle cx={cp1[0].x} cy={cp1[0].y} r="3" fill="red" />
        <circle cx={cp1[1].x} cy={cp1[1].y} r="3" fill="red" />
        <circle cx={cp2[0].x} cy={cp2[0].y} r="3" fill="red" />
        <circle cx={cp2[1].x} cy={cp2[1].y} r="3" fill="red" />
        <rect
          x={rectText.x}
          y={rectText.y}
          width={rectText.width}
          height={rectText.height}
          stroke="palevioletred"
          fill="none"
        />
        */}
        <path
          stroke={color}
          fill="none"
          d={`M ${x1},${y} C ${cp1[0].x},${cp1[0].y} ${cp1[1].x},${cp1[1].y} ${ptTextLeft.x},${ptTextLeft.y}`}
          markerStart={swapped ? markerUrl : undefined}
        />
        <path
          stroke={color}
          fill="none"
          d={`M ${ptTextRight.x},${ptTextRight.y} C ${cp2[0].x},${cp2[0].y} ${cp2[1].x},${cp2[1].y} ${x2},${y}`}
          markerEnd={swapped ? undefined : markerUrl}
        />
        <text
          x={ptCenter.x}
          y={ptCenter.y}
          fill={theme.textColor}
          stroke="none"
          fontSize="12"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {value}
        </text>
      </g>
    )
  }

  const renderSvg = (title: string, delayIndex: number) => {
    let svg: React.ReactNode
    if (viewState && viewState.viewItems.length >= 2) {
      const cxyMargins = 8
      const cxExtra = 50
      const segmentWidth = 220
      const lineHeight = 20
      const cyOuterDelay = 60
      const cyInnerDelay = 26
      const cxyCloud = 68

      const rectImage: Rect = {
        x: cxyMargins + cxExtra,
        y: cxyMargins + lineHeight,
        width: segmentWidth * (viewState.viewItems.length - 1),
        height: 160,
      }
      const rectTitle: Rect = {
        x: cxyMargins,
        y: cxyMargins,
        width: rectImage.width + 2 * cxExtra,
        height: lineHeight,
      }
      const rectSVG: Rect = {
        x: 0,
        y: 0,
        width: rectImage.width + 2 * cxyMargins + 2 * cxExtra,
        height: rectTitle.height + rectImage.height + 2 * cxyMargins,
      }
      const cxLabelMax = segmentWidth - 110
      const cxLabelMin = 40
      const ySegmentLine = rectImage.y + rectImage.height / 2

      const nodes: React.ReactNode[] = []
      const tooltips: React.ReactNode[] = []
      for (let i = 0, n = viewState.viewItems.length; i < n; i++) {
        const viewItem = viewState.viewItems[i]

        const label = project.segments[i].name
        const labelSize = measureText(label, { fontSize: "14px", fontWeight: 500 })
        const cxLabel = Math.max(Math.min(Math.ceil(labelSize.width) + 8, cxLabelMax), cxLabelMin)
        const x = rectImage.x + i * segmentWidth

        // Draw the segment names.
        const idSegNode = `seg-${title.replace(/ /g, "-")}-${delayIndex}-seg-${
          project.segments[i].id
        }`
        nodes.push(
          <rect
            key={`seg-${i}-rect`}
            id={idSegNode}
            x={x - cxLabel / 2}
            y={ySegmentLine - lineHeight / 2}
            width={cxLabel}
            height={lineHeight}
            fill={theme.tableBackgroundColor}
            stroke={theme.textColor}
          />
        )
        const idClipPath = `seg-${i}-label-clip`
        nodes.push(
          <clipPath key={idClipPath} id={idClipPath}>
            <rect
              x={x - cxLabel / 2 + 2}
              y={ySegmentLine - lineHeight / 2 + 1}
              width={cxLabel - 4}
              height={lineHeight - 2}
            />
          </clipPath>
        )
        nodes.push(
          <text
            key={`seg-${i}-label`}
            x={x}
            y={ySegmentLine}
            fill={theme.textColor}
            stroke="none"
            fontSize="14"
            fontWeight="500"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ pointerEvents: "none" }}
            clipPath={`url(#${idClipPath})`}
          >
            {label}
          </text>
        )
        tooltips.push(
          <UncontrolledPopover
            key={`seg-pop-${i}`}
            trigger="hover"
            placement="bottom"
            target={idSegNode}
            maxWidth="400px"
            style={{ fontSize: "1rem" }}
          >
            <PopoverHeader style={{ margin: "0.5rem 0.5rem 0 0.5rem" }}>{label}</PopoverHeader>
            <PopoverBody>
              <PropTable
                propList={[
                  "Client",
                  "Server",
                  "Packets",
                  "Client Packets",
                  "Server Packets",
                  "Packets Lost",
                  "Client Packets Lost",
                  "Server Packets Lost",
                  "Client Retransmissions",
                  "Server Retransmissions",
                  "Start",
                  "Finish",
                  "Duration",
                  "TCP Status",
                ]}
                data={viewItem}
                formatProp={formatSegmentProp}
                skipEmptyRows={true}
              />
            </PopoverBody>
          </UncontrolledPopover>
        )

        if (i === 0) {
          // Draw the end-to-end client arrow.
          const x1Client = x
          const x2Client = rectImage.x + (viewState.viewItems.length - 1) * segmentWidth
          const y1Client = ySegmentLine - lineHeight / 2
          if (viewState.nextDelayValid) {
            nodes.push(
              renderHopArrow(
                "client-delay-arrow",
                x1Client,
                x2Client,
                y1Client,
                cyOuterDelay,
                true,
                formatDuration(viewState.nextDelay[delayIndex], 6)
              )
            )
          }

          // Draw the end-to-end server arrow.
          const x1Server = x2Client
          const x2Server = x
          const y1Server = ySegmentLine + lineHeight / 2
          if (viewState.prevDelayValid) {
            nodes.push(
              renderHopArrow(
                "server-delay-arrow",
                x1Server,
                x2Server,
                y1Server,
                -cyOuterDelay,
                false,
                formatDuration(viewState.prevDelay[delayIndex], 6)
              )
            )
          }
        }

        if (i < n - 1) {
          // Draw the segment cloud.
          const idCloudNode = `cloud-${title.replace(/ /g, "-")}-${delayIndex}-seg-${
            project.segments[i].id
          }`
          nodes.push(
            <Cloud
              key={`cloud-${i}`}
              id={idCloudNode}
              x={x + segmentWidth / 2 - cxyCloud / 2}
              y={ySegmentLine - cxyCloud / 1.8}
              width={cxyCloud}
              height={cxyCloud}
              stroke={theme.textColor}
              fill={theme.tableBackgroundColor}
            />
          )
          const { nextHops, prevHops } = viewItem
          if (nextHops !== -1 && prevHops !== -1) {
            // Format the hop count. Intentionally not using comma-formatting.
            const hops =
              nextHops === prevHops
                ? nextHops.toString()
                : `${nextHops.toString()},${prevHops.toString()}`
            nodes.push(
              <text
                key={`hops-${i}`}
                x={x + segmentWidth / 2}
                y={ySegmentLine}
                fill={nextHops > 1 || prevHops > 1 ? theme.dangerColor : theme.textColor}
                stroke="none"
                fontSize="12"
                fontWeight="500"
                textAnchor="middle"
                dominantBaseline="middle"
                style={{ pointerEvents: "none" }}
              >
                {hops}
              </text>
            )
          }
          tooltips.push(
            <UncontrolledPopover
              key={`cloud-pop-${i}`}
              trigger="hover"
              placement="bottom"
              target={idCloudNode}
              maxWidth="400px"
              style={{ fontSize: "1rem" }}
            >
              <PopoverHeader style={{ margin: "0.5rem 0.5rem 0 0.5rem" }}>{`${
                project.segments[i].name
              } \u2194 ${project.segments[i + 1].name}`}</PopoverHeader>
              <PopoverBody>
                <PropTable
                  propList={["Client Hops", "Server Hops"]}
                  data={viewItem}
                  formatProp={formatCloudProp}
                  skipEmptyRows={true}
                />
              </PopoverBody>
            </UncontrolledPopover>
          )

          // Draw the delays between intermediate segments.
          if (n > 2) {
            const nextViewItem = viewState.viewItems[i + 1]

            const bNextDelay = viewItem.nextDelayValid
            const bPrevDelay = nextViewItem.prevDelayValid

            const nextDelay = viewItem.nextDelay[delayIndex]
            const prevDelay = nextViewItem.prevDelay[delayIndex]

            if (bNextDelay) {
              // Draw the client arrow.
              const x1Client = x + 10
              const x2Client = x + segmentWidth - 10
              const y1Client = ySegmentLine - lineHeight / 2
              nodes.push(
                renderHopArrow(
                  `client-hop-delay-${i}`,
                  x1Client,
                  x2Client,
                  y1Client,
                  cyInnerDelay,
                  true,
                  formatDuration(nextDelay, 6)
                )
              )
            }

            if (bPrevDelay) {
              // Draw the server arrow.
              const x1Server = x + segmentWidth - 10
              const x2Server = x + 10
              const y1Server = ySegmentLine + lineHeight / 2
              nodes.push(
                renderHopArrow(
                  `server-hop-delay-${i}`,
                  x1Server,
                  x2Server,
                  y1Server,
                  -cyInnerDelay,
                  false,
                  formatDuration(prevDelay, 6)
                )
              )
            }
          }
        }
      }

      return (
        <>
          <svg
            viewBox={`0 0 ${rectSVG.width} ${rectSVG.height}`}
            width={rectSVG.width}
            height={rectSVG.height}
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <marker
                id="arrowClient"
                viewBox="0 0 14 14"
                refX="6"
                refY="7"
                markerWidth="14"
                markerHeight="14"
                orient="auto-start-reverse"
              >
                <path d="M 0 3 L 7 7 L 0 11 L 2 7 z" fill={theme.clientColor} />
              </marker>
              <marker
                id="arrowServer"
                viewBox="0 0 14 14"
                refX="6"
                refY="7"
                markerWidth="14"
                markerHeight="14"
                orient="auto-start-reverse"
              >
                <path d="M 0 3 L 7 7 L 0 11 L 2 7 z" fill={theme.serverColor} />
              </marker>
            </defs>
            {/*
            <g>
              <rect
                x={rectSVG.x}
                y={rectSVG.y}
                width={rectSVG.width}
                height={rectSVG.height}
                fill="none"
                stroke="olive"
                strokeOpacity="0.5"
                strokeDasharray="1 1"
              />
              <rect
                x={rectImage.x}
                y={rectImage.y}
                width={rectImage.width}
                height={rectImage.height}
                fill="none"
                stroke="coral"
                strokeOpacity="0.5"
                strokeDasharray="1 1"
              />
            </g>
            */}
            <g>
              <text
                x={rectTitle.x + rectTitle.width / 2}
                y={rectTitle.y + rectTitle.height / 2}
                fill={theme.textColor}
                stroke="none"
                fontSize="16"
                fontWeight="500"
                textAnchor="middle"
                dominantBaseline="middle"
              >
                {title}
              </text>
            </g>
            <g>
              <line
                x1={rectImage.x}
                y1={ySegmentLine}
                x2={rectImage.x + rectImage.width}
                y2={ySegmentLine}
                stroke={theme.textColor}
                strokeWidth="3"
              ></line>
            </g>
            <g>{nodes}</g>
          </svg>
          {tooltips}
        </>
      )
    }
    return svg
  }

  return (
    <Outer>
      <Inner>
        <Style>
          {renderSvg("Average Delay Time", 0)}
          {renderSvg("Minimum Delay Time", 1)}
          {renderSvg("Maximum Delay Time", 2)}
        </Style>
      </Inner>
    </Outer>
  )
}

export default SegmentsView
