import * as React from "react"
import { debounce, toNumber } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { Col, FormGroup } from "reactstrap"
import { ButtonBar, FormFeedback, FieldSet, FormView, Label, Legend } from "../../common/Form"
import { DateTimePicker } from "../../common/DateTimePicker"
import { PrimaryButton } from "../../common/Buttons"
import DateRangePresets from "../../common/DateRangePresets"
import { Input } from "../../common/Input"
import { FilterBar } from "../../FilterBar"
import { formatDurationRange, peekFromDate } from "../../../utils/formatUtils"
import { SearchContext } from "../types"
import { WizardProps } from "./types"
import {
  WizardHeader,
  WizardHeaderTitleWrapper,
  WizardHeaderTitle,
  WizardHeaderSubTitle,
} from "./Styles"
import {
  getCapabilities,
  getRecentFilterBarExpressions,
  getServer,
  getServerAuthToken,
  getShowLocalTime,
} from "../../../store"
import { pushRecentFilterBarExpression } from "../../../store/ui"
import { postFilterConvert } from "../../../api/api"

type SearchProps = WizardProps & {
  searchContext: SearchContext
  setSearchContext: (context: SearchContext) => void
}

const SearchForm = ({ onNext, searchContext, setSearchContext }: SearchProps) => {
  const dispatch = useDispatch()
  const engineCapabilities = useSelector(getCapabilities)
  const recentFilterBarExpressions = useSelector(getRecentFilterBarExpressions)
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const showLocalTime = useSelector(getShowLocalTime)
  const [filterError, setFilterError] = React.useState("")

  const onChangeFilter = (filter: string) => {
    setSearchContext({
      ...searchContext,
      filter,
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateFilter = React.useCallback(
    debounce((filter: string) => {
      if (filter.length !== 0) {
        postFilterConvert(server, serverAuthToken, filter, true)
          .then(response => {
            if (!response.valid) {
              let errorMessage = ""
              if (Array.isArray(response.errorsParsing) && response.errorsParsing.length > 0) {
                errorMessage = `Character ${response.errorsParsing[0].position}: ${response.errorsParsing[0].message}`
              } else if (response.errorConversion) {
                errorMessage = response.errorConversion
              } else {
                errorMessage = "Error in filter expression"
              }
              setFilterError(errorMessage)
            } else {
              setFilterError("")
            }
          })
          .catch(error => {
            console.error(error)
          })
      } else {
        setFilterError("")
      }
    }, 500),
    [server, serverAuthToken, setFilterError]
  )

  React.useEffect(() => {
    validateFilter(searchContext.filter)
  }, [searchContext.filter, validateFilter])

  const duration = formatDurationRange(
    peekFromDate(searchContext.startTime.valueOf()),
    peekFromDate(searchContext.endTime.valueOf()),
    3
  )

  const setTimes = (start: Date, end: Date) => {
    setSearchContext({
      ...searchContext,
      startTime: new Date(start),
      endTime: new Date(end),
    })
  }

  const setStartTime = (date: Date) => {
    setSearchContext({
      ...searchContext,
      startTime: new Date(date),
    })
  }

  const setEndTime = (date: Date) => {
    setSearchContext({
      ...searchContext,
      endTime: new Date(date),
    })
  }

  const isNameValid = searchContext.name.length > 0
  const isTimeValid = searchContext.endTime > searchContext.startTime
  const isFilterValid = filterError.length === 0
  const isValid = isNameValid && isTimeValid && isFilterValid

  return (
    <>
      <WizardHeader>
        <WizardHeaderTitleWrapper>
          <WizardHeaderTitle>Time Range &amp; Filter</WizardHeaderTitle>
          <WizardHeaderSubTitle>
            Choose the time range and filter for the search
          </WizardHeaderSubTitle>
        </WizardHeaderTitleWrapper>
        <ButtonBar>
          <PrimaryButton
            disabled={!isValid}
            onClick={() => {
              if (searchContext.filter.length !== 0) {
                dispatch(pushRecentFilterBarExpression(searchContext.filter))
              }
              onNext()
            }}
          >
            Next
          </PrimaryButton>
        </ButtonBar>
      </WizardHeader>

      <FormView id="downloadEngineFilesForm">
        <FormGroup row>
          <Label md="2" for="name">
            Name
          </Label>
          <Col md="8">
            <Input
              type="text"
              name="name"
              id="name"
              value={searchContext.name}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setSearchContext({
                  ...searchContext,
                  name: event.target.value,
                })
              }}
              invalid={!isNameValid}
              required
            />
          </Col>
        </FormGroup>
        <FieldSet>
          <Legend>Time Range {showLocalTime ? "" : "(UTC)"}</Legend>
          <FormGroup row>
            <Label md="2" for="startTime">
              Start Time
            </Label>
            <Col md="8">
              <DateTimePicker
                id="startTime"
                selected={searchContext.startTime}
                onChange={(date: Date | null) => {
                  if (date != null) {
                    setStartTime(date)
                  }
                }}
                showLocalTime={showLocalTime}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2" for="endTime">
              End Time
            </Label>
            <Col md="8">
              <DateTimePicker
                id="endTime"
                selected={searchContext.endTime}
                onChange={(date: Date | null) => {
                  if (date != null) {
                    setEndTime(date)
                  }
                }}
                showLocalTime={showLocalTime}
              />
              {!isTimeValid ? (
                <FormFeedback style={{ display: "block" }}>The time range is invalid</FormFeedback>
              ) : null}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2" />
            <Col md="8">
              <DateRangePresets showLocalTime={showLocalTime} onChangeTimes={setTimes} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2">Duration</Label>
            <Col md="8" style={{ display: "flex", alignItems: "center" }}>
              <div>{duration}</div>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2" for="slop">
              {"\u00b1"} Extra Seconds
            </Label>
            <Col md="8">
              <Input
                type="number"
                id="slop"
                name="slop"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchContext({
                    ...searchContext,
                    slopTime: toNumber(event.target.value),
                  })
                }}
                value={searchContext.slopTime}
                min={0}
                max={10000}
                step={1}
              />
            </Col>
          </FormGroup>
        </FieldSet>

        <FieldSet>
          <Legend>Filter</Legend>
          <FormGroup row noMargin>
            <Col md={{ size: 8, offset: 2 }}>
              <FilterBar
                engineCapabilities={engineCapabilities}
                filterBarExpression={searchContext.filter}
                filterBarError={filterError}
                recentFilterBarExpressions={recentFilterBarExpressions}
                onChange={onChangeFilter}
              />
            </Col>
          </FormGroup>
        </FieldSet>
      </FormView>
    </>
  )
}

export default SearchForm
