import * as React from "react"
import { Form, FormGroup } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../../common/Modal"
import { Input } from "../../common/Input"
import { Label } from "../../common/Form"
import { Select } from "../../common/Select"
import { PrimaryButton, SecondaryButton } from "../../common/Buttons"
import { getEngineDisplayName } from "../../../utils/engineUtils"
import { Engine } from "../../../api/types"
import { Widget, WidgetType } from "../../../api/types/dashboardTypes"
import CaptureCountersWidgetForm from "./CaptureCountersWidgetForm"
import ForensicChartWidgetForm from "./ForensicChartWidgetForm"
import ForensicTopStatsWidgetForm from "./ForensicTopStatsWidgetForm"

type AddWidgetModalProps = {
  server: string
  authToken: string
  engines: Engine[]
  widget: Widget | null
  onOK: (widget: Widget) => void
  onCancel: () => void
}

const AddWidgetModal = ({
  server,
  authToken,
  engines,
  widget,
  onOK,
  onCancel,
}: AddWidgetModalProps) => {
  const [engine, setEngine] = React.useState<Engine | null>(() => {
    if (widget) {
      const foundEngine = engines.find(engine => engine.id === widget.engine)
      if (foundEngine) {
        return foundEngine
      }
    }
    return engines.length > 0 ? engines[0] : null
  })
  const [widgetType, setWidgetType] = React.useState<WidgetType>(() =>
    widget ? widget.type : "capture-counters"
  )
  const [title, setTitle] = React.useState(() => (widget ? widget.title : ""))
  const [settings, setSettings] = React.useState(() => (widget ? widget.settings : {}))

  const engineId = engine ? engine.id : ""

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (widget) {
      onOK({
        ...widget,
        type: widgetType,
        title: title,
        engine: engineId,
        settings: settings,
      })
    } else {
      onOK({
        id: "",
        x: 0,
        y: 0,
        h: 6,
        w: 4,
        type: widgetType,
        title: title,
        engine: engineId,
        dashboard: "",
        settings: settings,
      })
    }
  }

  if (!engine) return null

  let settingsForm: any = null
  switch (widgetType) {
    case "capture-counters":
      settingsForm = (
        <CaptureCountersWidgetForm
          server={server}
          authToken={authToken}
          engine={engine}
          settings={settings}
          setSettings={setSettings}
        />
      )
      break
    case "forensic-chart":
      settingsForm = (
        <ForensicChartWidgetForm
          server={server}
          authToken={authToken}
          engine={engine}
          settings={settings}
          setSettings={setSettings}
        />
      )
      break
    case "forensic-top-stats":
      settingsForm = (
        <ForensicTopStatsWidgetForm
          server={server}
          authToken={authToken}
          engine={engine}
          settings={settings}
          setSettings={setSettings}
        />
      )
      break
  }

  return (
    <Modal isOpen={true}>
      <ModalHeader toggle={onCancel}>Add Widget</ModalHeader>
      <ModalBody>
        <Form id="addWidgetForm" onSubmit={onSubmit} noValidate>
          <FormGroup>
            <Label for="engine">Engine</Label>
            <Select
              id="engine"
              value={engineId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (Array.isArray(engines)) {
                  const selectedId = e.target.value
                  const selectedEngine = engines.find(engine => engine.id === selectedId)
                  if (selectedEngine) {
                    setEngine(selectedEngine)
                  }
                }
              }}
            >
              {engines.map(engine => (
                <option key={engine.id} value={engine.id}>
                  {getEngineDisplayName(engine)}
                </option>
              ))}
            </Select>
          </FormGroup>
          <FormGroup>
            <Label for="widget">Widget Type</Label>
            <Select
              id="widget"
              value={widgetType}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWidgetType(e.target.value as WidgetType)
              }
            >
              <option value="capture-counters">Capture Counters</option>
              <option value="global-event-counters">Global Event Counts</option>
              <option value="forensic-chart">Forensic Chart</option>
              <option value="forensic-top-stats">Forensic Top Stats</option>
            </Select>
          </FormGroup>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input
              type="text"
              name="title"
              id="title"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
            />
          </FormGroup>
          {settingsForm}
        </Form>
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" form="addWidgetForm">
          OK
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  )
}

export default AddWidgetModal
