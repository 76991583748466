import * as React from "react"
import { connect } from "react-redux"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { cloneDeep, toNumber } from "lodash"
import cn from "classnames"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { SortDirection, SortDirectionType, TableCellProps, TableRowProps } from "react-virtualized"
import { Batch, BatchItem, CreateOptions } from "@rpldy/uploady"
import { asUploadButton } from "@rpldy/upload-button"
import { Alert } from "../common/Alert"
import { FilterBox } from "../common/FilterBox"
import { MutedText } from "../common/MutedText"
import { OmniTable } from "../common/OmniTable"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import ConfirmationModal from "../common/ConfirmationModal"
import ForensicSearchModal from "../ForensicSearchModal"
import { CenterContent } from "../common/Layout"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import { LightButton, IconDropdownToggle } from "../common/Buttons"
import { Spinner } from "../common/Spinner"
import {
  View,
  ViewContent,
  ViewHeader,
  ViewHeaderButtons,
  ViewHeaderItems,
  ViewHeaderTitle,
} from "../common/View"
import {
  getAuthToken,
  getCapabilities,
  getEngine,
  getFilesFilter,
  getFilesColumns,
  getFilesSortBy,
  getFilesSortDirection,
  getFilesCollapsedGroups,
  getUserId,
  getShowLocalTime,
} from "../../store"
import {
  setFilesFilter,
  setFilesColumns,
  setFilesSort,
  setFilesCollapsedGroups,
} from "../../store/ui"
import {
  formatISODateTime,
  formatDurationRange,
  formatGB,
  formatInteger,
  formatLinkSpeed,
  formatTimeZone,
  peekFromDate,
} from "../../utils/formatUtils"
import { collator } from "../../utils/sortUtils"
import { getMediaString } from "../../utils/mediaUtils"
import { getEngineForensicSearchUrl } from "../../routes"
import {
  createForensicSearch,
  deleteFiles,
  fetchFilesList,
  getFileDownloadURL,
  synchronizeDatabase,
} from "../../api/api"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { PeekFilterMode } from "../../api/types/peekTypes"
import {
  DatabaseRowGetFileList,
  RequestCreateForensicSearch,
  ResponseCreateForensicSearch,
  ResponseDeleteFiles,
  ResponseGetEngineCapabilities,
  ResponseGetFileList,
} from "../../api/types"
import { compareFiles } from "./utils"
import { defaultColumns } from "./columns"
import UploadProgress from "../common/UploadProgress"
import { UploadType, validPacketFileExtensions } from "../../utils/uploadUtils"

const FileGroup = styled.span`
  padding-left: 0.25em;
  font-weight: bold;
`

const CommandStrip = styled.div`
  display: flex;
`

const UploadButton = asUploadButton(
  React.forwardRef((props, ref) => (
    <LightButton {...props} ref={ref} id="upload" name="file">
      <FontAwesome name="upload" /> Upload Packets
    </LightButton>
  ))
)

const OpenFileButton = asUploadButton(
  React.forwardRef((props, ref) => (
    <LightButton {...props} ref={ref} id="openfile" name="openfile">
      <FontAwesome name="folder-open-o" /> Open File
    </LightButton>
  ))
)

const defaultCollapsedGroups = new Set<string>()

type FilesViewProps = RouteComponentProps & {
  dispatch: Function
  engine: string
  authToken: string
  filter: string
  showLocalTime: boolean
  columns: any[]
  sortBy: string
  sortDirection: SortDirectionType
  collapsedGroups: Set<string>
  engineCapabilities: ResponseGetEngineCapabilities | null
  userId: string
}

const FilesView: React.FC<FilesViewProps> = ({
  dispatch,
  engine,
  authToken,
  filter,
  columns,
  sortBy,
  sortDirection,
  collapsedGroups,
  engineCapabilities,
  userId,
  ...viewProps
}): JSX.Element => {
  const [files, setFiles] = React.useState<ResponseGetFileList | null>(null)
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [selectedFiles, setSelectedFiles] = React.useState<DatabaseRowGetFileList[]>([])
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState<boolean>(false)
  const [showForensicSearchModal, setShowForensicSearchModal] = React.useState<boolean>(false)
  const [showSynchronizeConfirm, setShowSynchronizeConfirm] = React.useState<boolean>(false)
  const [uploadType, setUploadType] = React.useState<UploadType>(UploadType.UPLOAD_TYPE_NONE)

  const onRefresh = React.useCallback(async () => {
    fetchFilesList(engine, authToken)
      .then((files: ResponseGetFileList) => {
        setFiles(files)
      })
      .catch(error => {
        console.error(error)
        setErrorMessage(error)
      })
  }, [engine, authToken])

  React.useEffect(() => {
    onRefresh()
  }, [onRefresh])

  const onUploadStart = (batch: Batch, options: CreateOptions) => {
    if (
      uploadType === UploadType.UPLOAD_TYPE_OPEN_FILE ||
      uploadType === UploadType.UPLOAD_TYPE_UPLOAD_PACKETS
    ) {
      // don't allow multiple files
      if (uploadType === UploadType.UPLOAD_TYPE_OPEN_FILE && batch.items.length > 1) {
        setErrorMessage("Only 1 file may be opened at a time")
        return false
      }

      // make sure the file extension is one we support
      if (!validPacketFileExtensions(batch)) {
        setErrorMessage("Invalid file type")
        return false
      }
    }

    return
  }

  const onUploadFinish = (batchItem: BatchItem) => {
    if (uploadType === UploadType.UPLOAD_TYPE_OPEN_FILE) {
      fetchFilesList(engine, authToken)
        .then((files: ResponseGetFileList) => {
          setFiles(files)
          const fileItem = files.rows.find(
            (row: DatabaseRowGetFileList) => row.FileName && row.FileName === batchItem.file.name
          )
          if (fileItem) {
            setSelectedFiles([fileItem])
            setShowForensicSearchModal(true)
          }
        })
        .catch(error => {
          console.error(error)
          setErrorMessage(error)
        })
    } else {
      onRefresh()
    }
  }

  const onUploadFinalize = (batch: Batch) => {
    if (uploadType === UploadType.UPLOAD_TYPE_OPEN_FILE) {
      setUploadType(UploadType.UPLOAD_TYPE_NONE)
    }
  }

  const onExpandAllFiles = () => {
    dispatch(setFilesCollapsedGroups(defaultCollapsedGroups))
  }

  const onCollapseAllFiles = (rows: any[]) => {
    const newCollapsedGroups = new Set(collapsedGroups)

    rows.forEach((row: any) => {
      if (row.children !== undefined) {
        if (!newCollapsedGroups.has(row.name)) {
          newCollapsedGroups.add(row.name)
        }
      }
    })

    dispatch(setFilesCollapsedGroups(newCollapsedGroups))
  }

  const cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    const isGroup = rowData.children !== undefined
    if (isGroup) {
      const firstVisibleColumn = columns.find(column => column.visible)
      if (dataKey === firstVisibleColumn.dataKey) {
        const collapsed = collapsedGroups.has(rowData.name)
        const icon = collapsed ? "chevron-right" : "chevron-down"
        return (
          <>
            <FontAwesome name={icon} fixedWidth />
            <FileGroup>
              {rowData.name} <MutedText>{`(${formatInteger(rowData.children.length)})`}</MutedText>
            </FileGroup>
          </>
        )
      } else {
        return null
      }
    }
    let content
    switch (dataKey) {
      case "FileName":
        content = (
          <span style={{ paddingLeft: "1.536em" }} title={cellData}>
            {cellData}
          </span>
        )
        break
      case "PartialPath":
      case "CaptureName":
      case "AdapterName":
      case "AdapterAddr":
      case "Owner":
        content = cellData
        break
      case "SessionStartTime":
      case "SessionEndTime":
        if (cellData) {
          content = formatISODateTime(cellData, 0, viewProps.showLocalTime)
        }
        break
      case "duration":
        if (
          "SessionStartTime" in rowData &&
          "SessionEndTime" in rowData &&
          rowData.SessionStartTime &&
          rowData.SessionEndTime
        ) {
          content = formatDurationRange(
            peekFromDate(Date.parse(rowData.SessionStartTime)),
            peekFromDate(Date.parse(rowData.SessionEndTime)),
            3
          )
        }
        break
      case "FileSize":
        if (cellData !== undefined) {
          content = formatGB(cellData)
        }
        break
      case "FileIndex":
      case "PacketCount":
        if (cellData !== undefined) {
          content = formatInteger(cellData)
        }
        break
      case "LinkSpeed":
        if (cellData) {
          content = formatLinkSpeed(cellData)
        }
        break
      case "MediaType":
        if ("MediaType" in rowData && "MediaSubType" in rowData) {
          content = getMediaString(toNumber(rowData.MediaType), toNumber(rowData.MediaSubType))
        }
        break
      case "TimeZoneBias":
        if ("TimeZoneBias" in rowData) {
          content = formatTimeZone(rowData.TimeZoneBias)
        }
        break
      default:
        break
    }
    return content
  }

  const commandCellRenderer = ({ rowData }: TableCellProps) => {
    // make sure the user can delete or download this file
    let canDeleteFiles = true
    let canDownloadFiles = true
    let canCreateForensicSearch = true
    if (engineCapabilities) {
      const isUserOwner = userId === rowData.Owner
      const policies = engineCapabilities.userRights.policies
      canDeleteFiles = isUserOwner || policies.includes(EngineUserPolicies.deleteFiles)
      canDownloadFiles = policies.includes(EngineUserPolicies.downloadFiles)
      canCreateForensicSearch =
        !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL) ||
        policies.includes(EngineUserPolicies.createForensicSearch)
    }

    const isGroup = rowData.children !== undefined
    if (isGroup) {
      return (
        <CommandStrip className="commands">
          <UncontrolledDropdownWithPortal
            dropdownToggle={
              <IconDropdownToggle>
                <FontAwesome name="ellipsis-h" fixedWidth />
              </IconDropdownToggle>
            }
          >
            <DropdownMenu end>
              <DropdownItem disabled={!canDeleteFiles} onClick={onDelete.bind(this, rowData)}>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdownWithPortal>
        </CommandStrip>
      )
    }

    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            <DropdownItem
              disabled={!canCreateForensicSearch}
              onClick={onForensicSearch.bind(this, rowData)}
            >
              Forensic Search
            </DropdownItem>
            <DropdownItem
              tag="a"
              href={getFileDownloadURL(engine, authToken, rowData.PartialPath, rowData.FileName)}
              disabled={!canDownloadFiles}
              download={rowData.FileName}
            >
              Download
            </DropdownItem>
            <DropdownItem disabled={!canDeleteFiles} onClick={onDelete.bind(this, rowData)}>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  const getFlattenedTree = () => {
    if (files == null) return null

    // Prepare filter data
    const isColumnVisible = (cols: any[], dataKey: string) => {
      const col = cols.find(col => col.dataKey === dataKey)
      return col != null && col.visible
    }
    const lowerCaseFilter = filter.toLowerCase()
    const hasFilter = lowerCaseFilter.length > 0
    const isAdapterNameVisible = hasFilter && isColumnVisible(columns, "AdapterName")
    const isAdapterAddrVisible = hasFilter && isColumnVisible(columns, "AdapterAddr")
    const filterStringProperty = (
      filter: string,
      row: DatabaseRowGetFileList,
      propertyName: string
    ) => {
      const property = row[propertyName]
      return typeof property === "string" && property.toLowerCase().indexOf(filter) !== -1
    }
    const filterRow = (filter: string, row: DatabaseRowGetFileList) => {
      return (
        filterStringProperty(lowerCaseFilter, row, "CaptureName") ||
        filterStringProperty(lowerCaseFilter, row, "FileName") ||
        (isAdapterNameVisible && filterStringProperty(lowerCaseFilter, row, "AdapterName")) ||
        (isAdapterAddrVisible && filterStringProperty(lowerCaseFilter, row, "AdapterAddr"))
      )
    }

    // Build hierarchical representation
    const groups: any[] = []
    files.rows.forEach((row: DatabaseRowGetFileList) => {
      // Filter on any fields that make sense
      if (hasFilter && !filterRow(filter, row)) {
        return
      }

      const group = groups.find(group =>
        row.CaptureName != null ? group.name === row.CaptureName : group.name === "Unspecified"
      )
      if (group) {
        group.children.push(row)
      } else {
        groups.push({
          name: row.CaptureName ? row.CaptureName : "Unspecified",
          children: [row],
        })
      }
    })

    // Sort children
    groups.forEach(group => {
      group.children.sort((a: DatabaseRowGetFileList, b: DatabaseRowGetFileList) =>
        compareFiles(a, b, sortBy, sortDirection, collator)
      )
    })

    // Sort top level groups
    if (sortBy === "FileName") {
      groups.sort((a, b) => {
        let result = 0
        if (a.name === "") {
          result = 1
        } else if (b.name === "") {
          result = -1
        } else {
          result = collator.compare(a.name, b.name)
        }
        if (sortDirection === SortDirection.DESC) result = -result
        return result
      })
    } else {
      groups.sort((a: any, b: any) => {
        if (sortDirection === SortDirection.ASC) {
          return compareFiles(
            a.children[a.children.length - 1],
            b.children[b.children.length - 1],
            sortBy,
            sortDirection,
            collator
          )
        } else {
          return compareFiles(a.children[0], b.children[0], sortBy, sortDirection, collator)
        }
      })
    }

    // Flatten the list into rows
    const rows: any[] = []
    groups.forEach((group: any) => {
      rows.push(group)
      const collapsed = collapsedGroups.has(group.name)
      if (!collapsed) {
        group.children.forEach((item: DatabaseRowGetFileList, index: number) => {
          rows.push({ ...item, childIndex: index })
        })
      }
    })

    return rows
  }

  const onChangeFilter = (filter: string) => {
    dispatch(setFilesFilter(filter))
  }

  const onSynchronize = () => {
    setShowSynchronizeConfirm(true)
  }

  const onSynchronizeCancel = () => {
    setShowSynchronizeConfirm(false)
  }

  const onSynchronizeOK = () => {
    setShowSynchronizeConfirm(false)
    synchronizeDatabase(engine, authToken)
      .then(() => {
        onRefresh()
      })
      .catch(error => {
        console.error(error)
        setErrorMessage("Failed to synchronize files")
      })
  }

  const onDelete = (rowData: DatabaseRowGetFileList) => {
    if (rowData.children !== undefined) {
      const newSelectedFiles = rowData.children.map((rowData: DatabaseRowGetFileList) => {
        return { ...rowData }
      })
      setSelectedFiles(newSelectedFiles)
    } else {
      setSelectedFiles([{ ...rowData }])
    }
    setShowDeleteConfirm(true)
  }

  const onDeleteCancel = () => {
    setSelectedFiles([])
    setShowDeleteConfirm(false)
  }

  const onDeleteOK = () => {
    if (selectedFiles.length > 0) {
      const doomedFiles = selectedFiles.map(file => file.PartialPath || "")
      setSelectedFiles([])
      setShowDeleteConfirm(false)
      deleteFiles(engine, authToken, doomedFiles)
        .then((response: ResponseDeleteFiles) => {
          const errors = response.results.reduce((accumulator, value) => {
            return value.result === 0 ? accumulator : accumulator + 1
          }, 0)
          if (errors !== 0) {
            const newErrorMessage =
              errors > 1
                ? `Failed to delete ${formatInteger(errors)} files`
                : "Failed to delete 1 file"
            setErrorMessage(newErrorMessage)
          }
          onRefresh()
        })
        .catch(error => {
          console.error(error)
          setErrorMessage("Failed to delete files")
        })
    }
  }

  const onExpandCollapse = (group: any) => {
    const newCollapsedGroups = new Set(collapsedGroups)
    if (newCollapsedGroups.has(group)) {
      newCollapsedGroups.delete(group)
    } else {
      newCollapsedGroups.add(group)
    }
    dispatch(setFilesCollapsedGroups(newCollapsedGroups))
  }

  const onForensicSearch = (rowData: DatabaseRowGetFileList) => {
    setSelectedFiles([{ ...rowData }])
    setShowForensicSearchModal(true)
  }

  const onForensicSearchCancel = () => {
    setSelectedFiles([])
    setShowForensicSearchModal(false)
  }

  const onForensicSearchOK = (query: RequestCreateForensicSearch) => {
    setSelectedFiles([])
    setShowForensicSearchModal(false)
    query.filterMode = query.filter
      ? PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_MATCHING_ANY
      : PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_ALL

    createForensicSearch(engine, authToken, query)
      .then((response: ResponseCreateForensicSearch) => {
        viewProps.history.push(getEngineForensicSearchUrl(response.id))
      })
      .catch(error => {
        console.error(error)
        setErrorMessage("Failed to create forensic search")
      })
  }

  const onRowClick = ({ rowData }: { rowData: any }) => {
    const isGroup = rowData.children !== undefined
    if (isGroup) {
      onExpandCollapse(rowData.name)
    }
  }

  const onShowAllColumns = () => {
    const copiedColumns = cloneDeep(columns)
    copiedColumns.forEach(col => {
      col.visible = true
    })
    dispatch(setFilesColumns(copiedColumns))
  }

  const onShowDefaultColumns = () => {
    dispatch(setFilesColumns(defaultColumns))
  }

  const onSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
  }) => {
    dispatch(setFilesSort(sortBy, sortDirection))
  }

  const onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const copiedColumns = cloneDeep(columns)
    const col = copiedColumns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      dispatch(setFilesColumns(copiedColumns))
    }
  }

  const rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    const isGroup = rowData.children !== undefined
    if (isGroup) {
      className = cn(className, "group", "clickable")
      key = rowData.name
      if (Array.isArray(columns) && columns.length > 0) {
        // Only need the first and last columns
        columns = [columns[0], columns[columns.length - 1]]
      }
    } else {
      if (rowData.childIndex != null && rowData.childIndex % 2 !== 0) {
        className = cn(className, "stripe")
      }
      key = rowData.PartialPath
    }

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  const rowClassName = () => {
    // Stripes applied in rowRenderer
    return ""
  }

  const flattenedTree = getFlattenedTree()
  if (flattenedTree == null) {
    return (
      <>
        <Helmet title="Files" />
        <BreadcrumbItem to={viewProps.match.url} title="Files" />
        <CenterContent>
          <Spinner />
        </CenterContent>
      </>
    )
  }

  let canUploadFiles = true
  let canCreateForensicSearch = true
  if (engineCapabilities) {
    const policies = engineCapabilities.userRights.policies
    canUploadFiles = policies.includes(EngineUserPolicies.uploadFiles)
    canCreateForensicSearch =
      !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL) ||
      policies.includes(EngineUserPolicies.createForensicSearch)
  }

  let count = 0
  if (Array.isArray(flattenedTree)) {
    flattenedTree.forEach(file => {
      if (Array.isArray(file.children)) {
        count += file.children.length
      }
    })
  }

  return (
    <View>
      <Alert
        color="danger"
        fade={false}
        isOpen={errorMessage !== null}
        toggle={() => setErrorMessage(null)}
      >
        {errorMessage}
      </Alert>
      <Helmet title="Files" />
      <BreadcrumbItem to={viewProps.match.url} title="Files" />
      <ViewHeaderItems>
        <ViewHeader border={false} style={{ marginBottom: 0 }}>
          <ViewHeaderTitle title="Files" count={count} />
          <ViewHeaderButtons>
            <FilterBox
              aria-label="Search"
              placeholder="Search"
              onChange={onChangeFilter}
              value={filter}
            />
            <OpenFileButton
              extraProps={{
                disabled: !canUploadFiles || !canCreateForensicSearch,
              }}
              onClick={() => setUploadType(UploadType.UPLOAD_TYPE_OPEN_FILE)}
            />
            <UploadButton
              extraProps={{ disabled: !canUploadFiles }}
              onClick={() => setUploadType(UploadType.UPLOAD_TYPE_UPLOAD_PACKETS)}
            />
            <LightButton id="synchronize" onClick={onSynchronize}>
              <FontAwesome name="database" /> Synchronize
            </LightButton>
            <LightButton id="Expand" onClick={onExpandAllFiles}>
              Expand All
            </LightButton>
            <LightButton id="Collapse" onClick={() => onCollapseAllFiles(flattenedTree)}>
              Collapse All
            </LightButton>
            <LightButton aria-label="Refresh" id="refresh" onClick={onRefresh}>
              <FontAwesome name="refresh" />
            </LightButton>
          </ViewHeaderButtons>
        </ViewHeader>
        <UploadProgress
          start={onUploadStart}
          finish={onUploadFinish}
          finalize={onUploadFinalize}
          uploadType={uploadType}
        />
      </ViewHeaderItems>
      <ViewContent>
        <OmniTable
          data={flattenedTree}
          rowCount={flattenedTree.length}
          columnDesc={columns}
          rowRenderer={rowRenderer}
          rowClassName={rowClassName}
          cellRenderer={cellRenderer}
          renderCommands={commandCellRenderer}
          onRowClick={onRowClick}
          onShowDefaultColumns={onShowDefaultColumns}
          onShowAllColumns={onShowAllColumns}
          onToggleColumn={onToggleColumn}
          sort={onSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      </ViewContent>
      {showForensicSearchModal && (
        <ForensicSearchModal
          onCancel={onForensicSearchCancel}
          onOK={onForensicSearchOK}
          captureSessionId={selectedFiles.length > 0 ? selectedFiles[0].SessionID : undefined}
          mediaType={selectedFiles.length > 0 ? selectedFiles[0].MediaType : undefined}
          mediaSubType={selectedFiles.length > 0 ? selectedFiles[0].MediaSubType : undefined}
          startTime={selectedFiles.length > 0 ? selectedFiles[0].SessionStartTime : undefined}
          endTime={
            selectedFiles.length > 0
              ? selectedFiles[selectedFiles.length - 1].SessionEndTime
              : undefined
          }
          name={
            selectedFiles.length > 0 && selectedFiles[0].FileName
              ? selectedFiles[0].FileName.replace(/\.[^/.]+$/, "")
              : undefined
          }
          file={selectedFiles.length > 0 ? selectedFiles[0].PartialPath : undefined}
        />
      )}
      {showDeleteConfirm && (
        <ConfirmationModal
          message={
            <span>
              Delete the selected {selectedFiles.length > 1 ? "files" : "file"}?
              <br />
              <br />
              Deleting files associated with an active capture session is not recommended. Please
              delete an active capture before deleting any files from that session.
            </span>
          }
          onNo={onDeleteCancel}
          onYes={onDeleteOK}
          show={showDeleteConfirm}
          title={`Delete ${selectedFiles.length > 1 ? "Files" : "File"}`}
        />
      )}
      {showSynchronizeConfirm && (
        <ConfirmationModal
          message="Synchronizing packet files to the file system is a lengthy process that may interfere with capture-to-disk. Are you sure you want to continue?"
          onNo={onSynchronizeCancel}
          onYes={onSynchronizeOK}
          show={showSynchronizeConfirm}
          title="Synchronize"
        />
      )}
    </View>
  )
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  filter: getFilesFilter(state) || "",
  showLocalTime: getShowLocalTime(state),
  columns: getFilesColumns(state) || defaultColumns,
  sortBy: getFilesSortBy(state) || "FileName",
  sortDirection: getFilesSortDirection(state) || SortDirection.ASC,
  collapsedGroups: getFilesCollapsedGroups(state) || defaultCollapsedGroups,
  engineCapabilities: getCapabilities(state) || null,
  userId: getUserId(state),
})

export default connect(mapStateToProps)(withRouter(FilesView))
