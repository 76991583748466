import * as React from "react"
import { Helmet } from "react-helmet"
import { useHistory, useParams } from "react-router-dom"
import { useSelector } from "react-redux"
import BreadcrumbItem from "../../BreadcrumbNav/BreadcrumbItem"
import Interval from "../../common/Interval"
import { Alert } from "../../common/Alert"
import ConfirmationModal from "../../common/ConfirmationModal"
import { View, ViewMaxWidth, ViewContent } from "../../common/View"
import { getDistributedForensicSearchesUrl, getDistributedForensicSearchUrl } from "../../../routes"
import { getServer, getServerAuthToken } from "../../../store"
import {
  deleteDistributedForensicSearch,
  fetchDistributedForensicSearch,
  stopDistributedForensicSearch,
} from "../../../api/api"
import { DistributedForensicSearchProperties } from "../../../api/types"
import Panel from "../Panel"

type RouteParams = {
  id: string
}

const DetailView = () => {
  const { id } = useParams<RouteParams>()
  const history = useHistory()
  const server = useSelector(getServer)
  const serverAuthToken = useSelector(getServerAuthToken)
  const [distributedForensicSearch, setDistributedForensicSearch] =
    React.useState<DistributedForensicSearchProperties | null>(null)
  const [error, setError] = React.useState<any | null>(null)
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [refreshInterval, setRefreshInterval] = React.useState(2500)

  const onRefresh = React.useCallback(() => {
    fetchDistributedForensicSearch(server, serverAuthToken, id)
      .then(response => {
        setError(null)
        setDistributedForensicSearch(response)
        setRefreshInterval(response.status !== "complete" ? 2500 : 10000)
      })
      .catch(error => {
        setError(error)
      })
  }, [server, serverAuthToken, id])

  React.useEffect(() => {
    onRefresh()
  }, [onRefresh])

  const onStop = () => {
    if (distributedForensicSearch != null) {
      stopDistributedForensicSearch(server, serverAuthToken, distributedForensicSearch.id)
        .then(() => {
          onRefresh()
        })
        .catch(() => {
          setError("Failed to stop search")
        })
    }
  }

  const onDelete = () => {
    setShowDeleteConfirm(true)
  }

  const onDeleteCancel = () => {
    setShowDeleteConfirm(false)
  }

  const onDeleteOK = () => {
    setShowDeleteConfirm(false)
    if (distributedForensicSearch != null) {
      setIsDeleting(true)
      deleteDistributedForensicSearch(server, serverAuthToken, distributedForensicSearch.id)
        .then(() => {
          history.push(getDistributedForensicSearchesUrl())
        })
        .catch(() => {
          setError("Delete failed")
        })
        .finally(() => {
          setIsDeleting(false)
        })
    }
  }

  const name = distributedForensicSearch?.name ?? ""

  return (
    <View>
      <Helmet title="Distributed Forensic Searches" />
      <BreadcrumbItem
        to={getDistributedForensicSearchesUrl()}
        title="Distributed Forensic Searches"
      />
      {name && <BreadcrumbItem to={getDistributedForensicSearchUrl(id)} title={name} />}
      <ViewMaxWidth>
        <ViewContent>
          {error != null ? (
            <Alert color="danger">
              {typeof error === "string" ? error : `${error.code} ${error.reason}`}
            </Alert>
          ) : (
            <>
              <Interval timeout={refreshInterval} enabled={true} callback={onRefresh} />
              {distributedForensicSearch ? (
                <Panel
                  distributedForensicSearch={distributedForensicSearch}
                  server={server}
                  serverAuthToken={serverAuthToken}
                  onDelete={onDelete}
                  isDeleting={isDeleting}
                  onStop={onStop}
                  showDetails={true}
                />
              ) : null}
            </>
          )}
        </ViewContent>
      </ViewMaxWidth>
      {showDeleteConfirm && (
        <ConfirmationModal
          message="Are you sure you want to delete the distributed forensic search?"
          onNo={onDeleteCancel}
          onYes={onDeleteOK}
          show={showDeleteConfirm}
          title="Delete Distributed Forensic Search"
        />
      )}
    </View>
  )
}

export default DetailView
