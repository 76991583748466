import * as React from "react"
import { cloneDeep } from "lodash"
import styled, { withTheme } from "styled-components"
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Label,
  LabelList,
  ReferenceArea,
} from "recharts"
import ChartTooltip from "../common/ChartTooltip"
import { ChartType } from "../../api/types/chartTypes"
import { ChartComponents } from "../../utils/chartComponents"
import { NoData } from "../Dashboard/Dashboard"
import {
  chartTypeSupportsSmoothing,
  formatChartTooltip,
  formatTimeChartTooltipLabel,
  formatTimeChartAxis,
  formatPercentChartLabel,
  formatPercentChartTooltip,
  getChartColor,
} from "../../utils/chartUtils"
import {
  formatInteger,
  formatFloat,
  formatDurationRange,
  formatDateTime,
} from "../../utils/formatUtils"
import {
  ResponseGetEngineCapabilities,
  ResponseGetTimelineData,
  TimelineDataData,
  VlanMplsTimelineDataData,
} from "../../api/types"
import { EngineCapabilities } from "../../api/types/engineTypes"
import { TimelineDataViewType } from "../../api/types/peekTypes"
import { LightButton } from "../common/Buttons"

const ForensicsGraph = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
`

const ChartTitle = styled.h5`
  margin: 0;
  text-align: center;
`

const ChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 240px;
`

const Placeholder = () => (
  <ForensicsGraph>
    <ChartTitle>&nbsp;</ChartTitle>
    <ChartWrapper>
      <NoData>No Data</NoData>
    </ChartWrapper>
  </ForensicsGraph>
)

type ForensicsGraphProps = {
  engineCapabilities: ResponseGetEngineCapabilities | null
  theme: any
  data: ResponseGetTimelineData | null
  viewType: number
  chartType: ChartType
  chartScale: string
  chartInterpolation: string
  refAreaLeft: string | number
  refAreaRight: string | number
  showLocalTime: boolean
  height: number
  onMouseDown: (event: React.MouseEvent<any>) => void
  onMouseMove: (event: React.MouseEvent<any>) => void
  onMouseUp: (event: React.MouseEvent<any>) => void
}

type ForensicsGraphState = {
  hoverKey: string | null
}

class ForensicsGraphComp extends React.Component<ForensicsGraphProps, ForensicsGraphState> {
  state: ForensicsGraphState = {
    hoverKey: null,
  }

  onMouseEnterLegend = (e: any) => {
    this.setState({ hoverKey: e.dataKey })
  }

  onMouseLeaveLegend = (e: any) => {
    if (e.dataKey === this.state.hoverKey) {
      this.setState({ hoverKey: null })
    }
  }

  render() {
    const {
      chartType,
      chartScale,
      chartInterpolation,
      engineCapabilities,
      refAreaLeft,
      refAreaRight,
      height,
      onMouseDown,
      onMouseMove,
      onMouseUp,
      data,
    } = this.props
    if (data === null || data.data.length === 0) return <Placeholder />
    const { hoverKey } = this.state
    const startTime = Date.parse(data.startTime)
    const sampleInterval = data.sampleInterval
    const chartComponent = ChartComponents[chartType]
    let Chart = chartComponent.chart
    const chartProps = chartComponent.chartProps
    const Series = chartComponent.series
    const seriesProps = cloneDeep(chartComponent.seriesProps)
    if (seriesProps.dot) {
      seriesProps.dot.stroke = this.props.theme.panelBackground
    }
    if (seriesProps.activeDot) {
      seriesProps.activeDot.stroke = this.props.theme.panelBackground
    }
    if (chartTypeSupportsSmoothing(chartType)) {
      seriesProps.type = chartInterpolation
    }
    let title = ""
    let xAxisProps: any = {
      tickFormatter: formatTimeChartAxis.bind(
        this,
        startTime,
        sampleInterval * 1000,
        this.props.showLocalTime
      ),
      height: 22,
    }
    let yAxisProps: any = {}
    let yLabel = ""
    let yLabelOffset = 0
    let tooltipLabelFormatter: any | undefined = formatTimeChartTooltipLabel.bind(
      this,
      startTime,
      sampleInterval * 1000,
      this.props.showLocalTime
    )
    let tooltipFormatter = formatChartTooltip
    let enableHover = false
    let series: any[] = []
    switch (data.viewType) {
      case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_MBITS:
        title = "Network Utilization"
        yLabel = "Mbits/s"
        series = [
          <Series
            key="mbps"
            dataKey="mbps"
            name="noname"
            unit={yLabel}
            stroke={getChartColor(0)}
            fill={getChartColor(0)}
            {...seriesProps}
            legendType="none"
          />,
        ]
        break
      case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_PACKETS:
        title = "Network Utilization"
        yLabel = "Packets/s"
        yLabelOffset = 20
        enableHover = true
        series = [
          <Series
            key="packets"
            dataKey="packets"
            name="Total"
            unit={yLabel}
            stroke={getChartColor(0)}
            fill={getChartColor(0)}
            {...seriesProps}
          />,
          <Series
            key="dropped"
            dataKey="dropped"
            name="Dropped"
            unit={yLabel}
            stroke={getChartColor(1)}
            fill={getChartColor(1)}
            {...seriesProps}
          />,
          <Series
            key="crc"
            dataKey="crc"
            name="CRC"
            unit={yLabel}
            stroke={getChartColor(2)}
            fill={getChartColor(2)}
            {...seriesProps}
          />,
          <Series
            key="undersize"
            dataKey="undersize"
            name="Runt"
            unit={yLabel}
            stroke={getChartColor(3)}
            fill={getChartColor(3)}
            {...seriesProps}
          />,
          <Series
            key="oversize"
            dataKey="oversize"
            name="Jumbo"
            unit={yLabel}
            stroke={getChartColor(4)}
            fill={getChartColor(4)}
            {...seriesProps}
          />,
        ]
        break
      case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_DEST_TYPE:
        title = "Unicast/Multicast/Broadcast"
        yLabel = "Packets/s"
        yLabelOffset = 20
        enableHover = true
        series = [
          <Series
            key="unicast"
            dataKey="unicast"
            name="Unicast"
            unit={yLabel}
            stroke={getChartColor(0)}
            fill={getChartColor(0)}
            {...seriesProps}
          />,
          <Series
            key="multicast"
            dataKey="multicast"
            name="Multicast"
            unit={yLabel}
            stroke={getChartColor(1)}
            fill={getChartColor(1)}
            {...seriesProps}
          />,
          <Series
            key="broadcast"
            dataKey="broadcast"
            name="Broadcast"
            unit={yLabel}
            stroke={getChartColor(2)}
            fill={getChartColor(2)}
            {...seriesProps}
          />,
        ]
        break
      case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_SIZE_DIST: {
        title = "Packet Sizes"
        Chart = BarChart
        xAxisProps = { dataKey: "entry" }
        yAxisProps = { domain: [0, 100] }
        tooltipLabelFormatter = undefined
        tooltipFormatter = formatPercentChartTooltip.bind(this, "packets", "totalPackets")
        const labelListProps = {
          fill: this.props.theme.textMutedColor,
          stroke: "none",
        }
        series = [
          <Bar key="0" dataKey="pct" name="Packets" legendType="none" isAnimationActive={false}>
            <LabelList
              dataKey="pct"
              position="top"
              formatter={formatPercentChartLabel}
              {...labelListProps}
            />
            {data.data.map((entry: TimelineDataData, i: number) => (
              <Cell key={i} fill={getChartColor(i)} />
            ))}
          </Bar>,
        ]
        break
      }
      case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_VLAN:
        if (
          engineCapabilities?.capabilities.includes(EngineCapabilities.vxlanTimelineStats) &&
          data.data.length > 0 &&
          (data.data[0] as VlanMplsTimelineDataData).vxlan !== undefined
        ) {
          title = "MPLS/VLAN/VXLAN"
          yLabel = "Packets/s"
          yLabelOffset = 20
          enableHover = true
          series = [
            <Series
              key="packets"
              dataKey="packets"
              name="Total"
              unit={yLabel}
              stroke={getChartColor(0)}
              fill={getChartColor(0)}
              {...seriesProps}
            />,
            <Series
              key="mpls"
              dataKey="mpls"
              name="MPLS"
              unit={yLabel}
              stroke={getChartColor(1)}
              fill={getChartColor(1)}
              {...seriesProps}
            />,
            <Series
              key="vlan"
              dataKey="vlan"
              name="VLAN"
              unit={yLabel}
              stroke={getChartColor(2)}
              fill={getChartColor(2)}
              {...seriesProps}
            />,
            <Series
              key="vxlan"
              dataKey="vxlan"
              name="VXLAN"
              unit={yLabel}
              stroke={getChartColor(3)}
              fill={getChartColor(3)}
              {...seriesProps}
            />,
          ]
        } else {
          title = "VLAN/MPLS"
          yLabel = "Packets/s"
          yLabelOffset = 20
          enableHover = true
          series = [
            <Series
              key="packets"
              dataKey="packets"
              name="Total"
              unit={yLabel}
              stroke={getChartColor(0)}
              fill={getChartColor(0)}
              {...seriesProps}
            />,
            <Series
              key="mpls"
              dataKey="mpls"
              name="MPLS"
              unit={yLabel}
              stroke={getChartColor(1)}
              fill={getChartColor(1)}
              {...seriesProps}
            />,
            <Series
              key="vlan"
              dataKey="vlan"
              name="VLAN"
              unit={yLabel}
              stroke={getChartColor(2)}
              fill={getChartColor(2)}
              {...seriesProps}
            />,
          ]
        }
        break
      case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_PROTOCOLS_MBITS:
        title = "Protocols"
        yLabel = "Mbits/s"
        enableHover = true
        series = [
          <Series
            key="ipv4"
            dataKey="ipv4"
            name="IPv4"
            unit={yLabel}
            stroke={getChartColor(0)}
            fill={getChartColor(0)}
            {...seriesProps}
          />,
          <Series
            key="ipv6"
            dataKey="ipv6"
            name="IPv6"
            unit={yLabel}
            stroke={getChartColor(1)}
            fill={getChartColor(1)}
            {...seriesProps}
          />,
          <Series
            key="tcp"
            dataKey="tcp"
            name="TCP"
            unit={yLabel}
            stroke={getChartColor(2)}
            fill={getChartColor(2)}
            {...seriesProps}
          />,
          <Series
            key="udp"
            dataKey="udp"
            name="UDP"
            unit={yLabel}
            stroke={getChartColor(3)}
            fill={getChartColor(3)}
            {...seriesProps}
          />,
          <Series
            key="icmp"
            dataKey="icmp"
            name="ICMP"
            unit={yLabel}
            stroke={getChartColor(4)}
            fill={getChartColor(4)}
            {...seriesProps}
          />,
        ]
        break
      case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_PROTOCOLS_PACKETS:
        title = "Protocols"
        yLabel = "Packets/s"
        yLabelOffset = 20
        enableHover = true
        series = [
          <Series
            key="ipv4"
            dataKey="ipv4"
            name="IPv4"
            unit={yLabel}
            stroke={getChartColor(0)}
            fill={getChartColor(0)}
            {...seriesProps}
          />,
          <Series
            key="ipv6"
            dataKey="ipv6"
            name="IPv6"
            unit={yLabel}
            stroke={getChartColor(1)}
            fill={getChartColor(1)}
            {...seriesProps}
          />,
          <Series
            key="tcp"
            dataKey="tcp"
            name="TCP"
            unit={yLabel}
            stroke={getChartColor(2)}
            fill={getChartColor(2)}
            {...seriesProps}
          />,
          <Series
            key="udp"
            dataKey="udp"
            name="UDP"
            unit={yLabel}
            stroke={getChartColor(3)}
            fill={getChartColor(3)}
            {...seriesProps}
          />,
          <Series
            key="icmp"
            dataKey="icmp"
            name="ICMP"
            unit={yLabel}
            stroke={getChartColor(4)}
            fill={getChartColor(4)}
            {...seriesProps}
          />,
        ]
        break
      case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_CALL_QUALITY:
        title = "Call Quality"
        yLabel = "Calls"
        Chart = BarChart
        series = [
          <Bar
            key="good"
            dataKey="good"
            name="Good"
            unit={yLabel}
            stackId="a"
            fill="#75d015"
            legendType="square"
            isAnimationActive={false}
          />,
          <Bar
            key="fair"
            dataKey="fair"
            name="Fair"
            unit={yLabel}
            stackId="a"
            fill="#ffdf00"
            legendType="square"
            isAnimationActive={false}
          />,
          <Bar
            key="poor"
            dataKey="poor"
            name="Poor"
            unit={yLabel}
            stackId="a"
            fill="#ffa126"
            legendType="square"
            isAnimationActive={false}
          />,
          <Bar
            key="bad"
            dataKey="bad"
            name="Bad"
            unit={yLabel}
            stackId="a"
            fill="#ff1a1a"
            legendType="square"
            isAnimationActive={false}
          />,
          <Bar
            key="unknown"
            dataKey="unknown"
            name="Unknown"
            unit={yLabel}
            stackId="a"
            fill="#999999"
            legendType="square"
            isAnimationActive={false}
          />,
        ]
        break
      case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_CALL_UTILIZATION:
        title = "Call vs. Network Utilization"
        yLabel = "Mbits/s"
        enableHover = true
        series = [
          <Series
            key="mbps"
            dataKey="mbps"
            name="Network Utilization"
            unit={yLabel}
            stroke={getChartColor(0)}
            fill={getChartColor(0)}
            {...seriesProps}
          />,
          <Series
            key="callMbps"
            dataKey="callMbps"
            name="Call Utilization"
            unit={yLabel}
            stroke={getChartColor(1)}
            fill={getChartColor(1)}
            {...seriesProps}
          />,
        ]
        break
      case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_WIRELESS_PACKETS:
        title = "Wireless Packets"
        yLabel = "Packets/s"
        yLabelOffset = 20
        enableHover = true
        series = [
          <Series
            key="packets"
            dataKey="packets"
            name="Total"
            unit={yLabel}
            stroke={getChartColor(0)}
            fill={getChartColor(0)}
            {...seriesProps}
          />,
          <Series
            key="data"
            dataKey="data"
            name="Data"
            unit={yLabel}
            stroke={getChartColor(1)}
            fill={getChartColor(1)}
            {...seriesProps}
          />,
          <Series
            key="management"
            dataKey="management"
            name="Management"
            unit={yLabel}
            stroke={getChartColor(2)}
            fill={getChartColor(2)}
            {...seriesProps}
          />,
          <Series
            key="control"
            dataKey="control"
            name="Control"
            unit={yLabel}
            stroke={getChartColor(3)}
            fill={getChartColor(3)}
            {...seriesProps}
          />,
        ]
        break
      case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_WIRELESS_RETRIES:
        title = "Wireless Retries"
        yLabel = "Packets/s"
        yLabelOffset = 20
        enableHover = true
        series = [
          <Series
            key="packets"
            dataKey="packets"
            name="Total"
            unit={yLabel}
            stroke={getChartColor(0)}
            fill={getChartColor(0)}
            {...seriesProps}
          />,
          <Series
            key="data"
            dataKey="data"
            name="Data"
            unit={yLabel}
            stroke={getChartColor(1)}
            fill={getChartColor(1)}
            {...seriesProps}
          />,
          <Series
            key="retry"
            dataKey="retry"
            name="Retry"
            unit={yLabel}
            stroke={getChartColor(2)}
            fill={getChartColor(2)}
            {...seriesProps}
          />,
        ]
        break
      case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_APPLICATIONS_MBITS:
        title = "Applications"
        yLabel = "Mbits/s"
        enableHover = true
        if (data.data.length > 0) {
          series = Object.keys(data.data[0]).map(key => {
            if (key === "totalPackets" || data === null) return null
            const app = data.apps[key]
            const name = app && app.name ? app.name : key
            const color = app && app.color ? app.color : "#808080"
            return (
              <Series
                key={key}
                dataKey={key}
                name={name}
                unit={yLabel}
                stroke={color}
                fill={color}
                {...seriesProps}
              />
            )
          })
        }
        break
      case TimelineDataViewType.TIMELINE_DATA_VIEW_TYPE_APPLICATIONS_PACKETS:
        title = "Applications"
        yLabel = "Packets/s"
        yLabelOffset = 20
        enableHover = true
        if (data.data.length > 0) {
          series = Object.keys(data.data[0]).map(key => {
            if (key === "totalPackets" || data === null) return null
            const app = data.apps[key]
            const name = app && app.name ? app.name : key
            const color = app && app.color ? app.color : "#808080"
            return (
              <Series
                key={key}
                dataKey={key}
                name={name}
                unit={yLabel}
                stroke={color}
                fill={color}
                {...seriesProps}
              />
            )
          })
        }
        break
      default:
        break
    }

    if (enableHover && hoverKey && series) {
      series = series.map(s => {
        if (s && hoverKey !== s.props.dataKey) {
          return React.cloneElement(s, {
            strokeOpacity: 0.1,
            fillOpacity: 0.1,
          })
        } else {
          return s
        }
      })
    }

    return (
      <ForensicsGraph>
        <ChartTitle>{title}</ChartTitle>
        <ResponsiveContainer height={height} width="100%">
          <Chart
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            data={data.data}
            margin={{ top: 14, right: 32, left: 6 + yLabelOffset, bottom: 0 }}
            {...chartProps}
          >
            <Tooltip
              isAnimationActive={false}
              labelFormatter={tooltipLabelFormatter}
              formatter={tooltipFormatter}
              content={<ChartTooltip />}
            />
            <CartesianGrid stroke={this.props.theme.chartGridColor} vertical={false} />
            <YAxis
              scale={chartScale}
              stroke={this.props.theme.textColor}
              tickFormatter={formatInteger}
              {...yAxisProps}
            >
              <Label
                value={yLabel}
                fill={this.props.theme.textColor}
                style={{ fontWeight: 600 }}
                position="insideLeft"
                angle={-90}
                offset={4 - yLabelOffset}
                dy={20}
              />
            </YAxis>
            <XAxis stroke={this.props.theme.textColor} {...xAxisProps} />
            {series}
            {refAreaLeft !== "" && refAreaRight !== "" && (
              <ReferenceArea x1={refAreaLeft} x2={refAreaRight} ifOverflow="extendDomain" />
            )}
            <Legend
              iconSize={10}
              onMouseEnter={this.onMouseEnterLegend}
              onMouseLeave={this.onMouseLeaveLegend}
            />
          </Chart>
        </ResponsiveContainer>
      </ForensicsGraph>
    )
  }
}

export const ForensicsGraphWidget = withTheme(ForensicsGraphComp)

const SelectionList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
`

const SelectionListItem = styled.li`
  display: flex;
  list-style: none;
  padding-left: 0.5rem;

  &:first-child {
    padding-left: 0;
  }
`

const SelectionLabel = styled.div`
  padding-right: 0.25rem;
  white-space: nowrap;
  font-weight: bold;
  color: ${props => props.theme.propTableHeaderColor};
  font-size: ${props => props.theme.propTableHeaderFontSize};
  line-height: 1.5rem;
  text-transform: ${props => props.theme.propTableHeaderTextTransform};
`

const SelectionItem = styled.div``

type ForensicsGraphSelectionProps = {
  selStartTime: number
  selEndTime: number
  packetCount: number
  onClearSelection: () => void
}

export const ForensicsGraphSelection: React.FC<ForensicsGraphSelectionProps> = ({
  selStartTime,
  selEndTime,
  packetCount,
  onClearSelection,
}) => {
  if (!selStartTime || !selEndTime) {
    return null
  }

  let packets = ""
  if (packetCount >= 1000000000) {
    // >= 1 B
    packets = `${formatFloat(packetCount / 1000000000, 3)} B`
  } else if (packetCount >= 10000000) {
    // >= 10 M
    packets = `${formatFloat(packetCount / 1000000, 3)} M`
  } else {
    packets = formatInteger(packetCount)
  }

  return (
    <SelectionList>
      <SelectionListItem>
        <SelectionLabel>Selection</SelectionLabel>
        <SelectionItem>
          {formatDateTime(selStartTime)} &rarr; {formatDateTime(selEndTime)}
        </SelectionItem>
      </SelectionListItem>
      <SelectionListItem>
        <SelectionLabel>Duration</SelectionLabel>
        <SelectionItem>{formatDurationRange(selStartTime, selEndTime)}</SelectionItem>
      </SelectionListItem>
      <SelectionListItem>
        <SelectionLabel>Packets</SelectionLabel>
        <SelectionItem>{packets}</SelectionItem>
      </SelectionListItem>
      <SelectionListItem>
        <SelectionItem>
          <LightButton size="sm" onClick={onClearSelection}>
            Clear Selection
          </LightButton>
        </SelectionItem>
      </SelectionListItem>
    </SelectionList>
  )
}
