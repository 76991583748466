import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { cloneDeep, isEqual } from "lodash"
import { v4 as uuid } from "uuid"
import styled, { DefaultTheme, withTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import FileSaver from "file-saver"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import { defaultAlarm } from "../AlarmsEditView"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { defaultGraph } from "../GraphTemplatesEditView"
import { OmniTable } from "../common/OmniTable"
import { IconSevere } from "../common/Icons"
import {
  View,
  ViewContent,
  ViewHeader,
  ViewHeaderTitle,
  ViewHeaderButtons,
  ViewAlert,
  ViewAlertContent,
} from "../common/View"
import { LightButton, IconDropdownToggle, IconButton } from "../common/Buttons"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import Interval from "../common/Interval"
import { UncontrolledAlert } from "../common/UncontrolledAlert"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import FilterBox from "../common/FilterBox"
import { Select } from "../common/Select"
import BarGauge from "../common/BarGauge"
import ProtocolDescriptionModal from "../ProtocolDescriptionModal"
import { InsertNamesModal, InsertNameEntry } from "../InsertNamesModal"
import { filterExpressionTypeFromMediaSpecType } from "../../utils/filterUtils"
import { formatInteger, formatFloat, formatISODateTime } from "../../utils/formatUtils"
import csvStringify from "../../utils/csvStringify"
import {
  getCaptureForensicSearchUrl,
  getEngineNewFilterUrl,
  getEngineNewGraphUrl,
  getEngineNewAlarmUrl,
  getNewDistributedForensicSearchUrl,
} from "../../routes"
import {
  getEngine,
  getAuthToken,
  getNamesModificationTime,
  getShowPortNames,
  getProtocolStatsViewType,
  getProtocolStatsFilter,
  getProtocolStatsSortBy,
  getProtocolStatsSortDirection,
  getCapabilities,
  getUserId,
  getShowLocalTime,
} from "../../store"
import { setCurrentEngine } from "../../store/engines"
import {
  setProtocolStatsViewType,
  setProtocolStatsFilter,
  setProtocolStatsSort,
} from "../../store/ui"
import { setSelectPacketsTask } from "../../store/selectPackets"
import { fetchCFSStatistics, fetchProtocols, postSelectRelatedFilterStart } from "../../api/api"
import {
  AlarmInfo,
  Filter,
  GraphObject,
  Protocol,
  ProtocolsStatistics,
  ProtocolsStatisticsObject,
  RequestGetStatisticsStatistic,
  RequestPostSelectRelatedFilterStart,
  ResponsePostSelectRelatedFilterStart,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { MediaSpecType } from "../../api/types/mediaTypes"
import { GraphItemUnitType, PeekProtocolStatType } from "../../api/types/peekTypes"

const defaultColumns = [
  {
    dataKey: "protocol",
    label: "Protocol",
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "percentage",
    label: "Percentage",
    width: 140,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "bytes",
    label: "Bytes",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "packets",
    label: "Packets",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
]

const ViewType = {
  FLAT: "Flat",
  HIERARCHY: "Hierarchy",
}

const CommandStrip = styled.div`
  display: flex;
`

function getClientId(protocol: ProtocolsStatisticsObject): string {
  return `${protocol.protocol}-${protocol.hierarchy.join("-")}`
}

function walkHierarchy(items: any[], fun: Function, params: any) {
  items.forEach(item => {
    fun(item, params)
    walkHierarchy(item.children, fun, params)
  })
}

function updateTotals(protocol: any) {
  function getTotals(protocol: any, totals: any) {
    totals.bytes += protocol.bytes
    totals.packets += protocol.packets
  }
  const totals = { bytes: 0, packets: 0 }
  walkHierarchy([protocol], getTotals, totals)
  protocol.totalBytes = totals.bytes
  protocol.totalPackets = totals.packets
}

function findProtocol(items: any[], id: number | null, hierarchy: any): any | null {
  if (id !== null) {
    for (let i = 0, len = items.length; i < len; i++) {
      const item = items[i]
      if (item.id === id && isEqual(item.hierarchy, hierarchy)) {
        return item
      } else {
        const child = findProtocol(item.children, id, hierarchy)
        if (child !== null) return child
      }
    }
  }
  return null
}

function addProtocol(
  hierarchy: any[],
  protocols: ProtocolsStatisticsObject[],
  protocol: ProtocolsStatisticsObject
): any {
  let parents = protocol.hierarchy
  if (parents.length && parents[0] === protocol.id) {
    parents = parents.slice(1)
  }
  if (parents.length === 0) {
    const item = Object.assign({}, protocol, {
      clientId: getClientId(protocol),
      depth: 0,
      children: [],
    })
    hierarchy.push(item)
    return item
  } else {
    const parentItem = findOrAddProtocol(
      hierarchy,
      protocols,
      protocols.find(
        (it: ProtocolsStatisticsObject) => it.id === parents[0] && isEqual(it.hierarchy, parents)
      )
    )
    if (parentItem) {
      const item = Object.assign({}, protocol, {
        clientId: getClientId(protocol),
        depth: parentItem.depth + 1,
        children: [],
      })
      parentItem.children.push(item)
      return item
    }
    return undefined
  }
}

function findOrAddProtocol(
  hierarchy: any[],
  protocols: ProtocolsStatisticsObject[],
  protocol: ProtocolsStatisticsObject | undefined
) {
  if (protocol) {
    let item = findProtocol(hierarchy, protocol.id, protocol.hierarchy)
    if (item === null) {
      item = addProtocol(hierarchy, protocols, protocol)
    }
    return item
  }
  return undefined
}

function buildProtocolHierarchy(protocols: ProtocolsStatisticsObject[]): any[] {
  const hierarchy: any[] = []
  protocols.forEach((protocol: ProtocolsStatisticsObject) => {
    findOrAddProtocol(hierarchy, protocols, protocol)
  })
  return hierarchy
}

function buildTree(items: any[], collapsedItems: any): any[] {
  return items.reduce((tree, item) => {
    const collapsed = !!collapsedItems[item.clientId]
    if (collapsed || item.children.length === 0) {
      return [...tree, item]
    }
    return [...tree, item, ...buildTree(item.children, collapsedItems)]
  }, [])
}

type ProtocolStatisticsViewProps = RouteComponentProps<CaptureRouteParams> &
  CaptureViewProps & {
    dispatch: Function
    engine: string
    authToken: string
    namesModTime?: string
    showPortNames: boolean
    viewType: string
    filter: string
    showLocalTime: boolean
    columns: any[]
    sortBy: string
    sortDirection: SortDirectionType
    theme: DefaultTheme
    engineCapabilities: ResponseGetEngineCapabilities | null
    userId: string
  }

type ProtocolStatisticsViewState = {
  protocolDescriptions: Protocol[] | null
  protocolStatistics: ProtocolsStatistics | null
  protocolHierarchy: any | null
  collapsedItems: any
  protocolDescriptionId: number | null
  insertNameEntry: InsertNameEntry | null
}

class ProtocolStatisticsView extends React.Component<
  ProtocolStatisticsViewProps,
  ProtocolStatisticsViewState
> {
  state: ProtocolStatisticsViewState = {
    protocolDescriptions: null,
    protocolStatistics: null,
    protocolHierarchy: null,
    collapsedItems: {},
    protocolDescriptionId: null,
    insertNameEntry: null,
  }

  componentDidMount() {
    this.onRefreshProtocolDescriptions()
    this.onRefresh()
  }

  componentDidUpdate({ namesModTime, showPortNames }: ProtocolStatisticsViewProps) {
    if (this.props.namesModTime !== namesModTime || this.props.showPortNames !== showPortNames) {
      this.onRefresh()
    }
  }
  onRefreshProtocolDescriptions = () => {
    const { engine, authToken } = this.props
    fetchProtocols(engine, authToken)
      .then(protocols => {
        if (protocols && protocols.protocols) {
          this.setState({ protocolDescriptions: protocols.protocols })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onRefresh = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSStatistics<ProtocolsStatistics>(
      engine,
      authToken,
      type,
      capId,
      "protocols" as RequestGetStatisticsStatistic
    )
      .then((protocolStatistics: ProtocolsStatistics) => {
        if (protocolStatistics.protocols) {
          const { viewType, sortBy, sortDirection } = this.props
          if (viewType === ViewType.FLAT) {
            protocolStatistics.protocols = protocolStatistics.protocols.filter(
              protocol => protocol.packets > 0
            )
            const protocols: ProtocolsStatisticsObject[] = []
            protocolStatistics.protocols.forEach(protocol => {
              const existing = protocols.find(item => item.id === protocol.id)
              if (existing) {
                if (
                  existing.firstTime == null ||
                  (protocol.firstTime != null && protocol.firstTime < existing.firstTime)
                ) {
                  existing.firstTime = protocol.firstTime
                }
                if (
                  existing.lastTime == null ||
                  (protocol.lastTime != null && protocol.lastTime > existing.lastTime)
                ) {
                  existing.lastTime = protocol.lastTime
                }
                existing.packets += protocol.packets
                existing.bytes += protocol.bytes
              } else {
                protocols.push(protocol)
              }
            })
            protocolStatistics.protocols = protocols
            this.sortFlat(protocolStatistics, sortBy, sortDirection)
            this.setState({ protocolStatistics })
          } else if (viewType === ViewType.HIERARCHY) {
            const protocolHierarchy = buildProtocolHierarchy(protocolStatistics.protocols)
            this.sortHierarchy(protocolHierarchy, sortBy, sortDirection)
            this.setState({ protocolStatistics, protocolHierarchy })
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onExport = () => {
    let csv = ""
    const { viewType } = this.props
    if (viewType === ViewType.FLAT) {
      const { protocolStatistics } = this.state
      const { filter } = this.props
      let protocols = protocolStatistics && protocolStatistics.protocols
      if (protocols && filter) {
        const lowerCaseFilter = filter.toLowerCase()
        protocols = protocols.filter(stat => {
          const protocol = (this.props.showPortNames && stat.name) || stat.protocol
          return protocol.toLowerCase().includes(lowerCaseFilter)
        })
      }
      csv += "Protocol,Bytes,Packets\n"
      if (protocols) {
        protocols.forEach(rowData => {
          const row = []
          row.push(csvStringify((this.props.showPortNames && rowData.name) || rowData.protocol))
          row.push(rowData.bytes)
          row.push(rowData.packets)
          csv += row.join(",") + "\n"
        })
      }
    } else if (viewType === ViewType.HIERARCHY) {
      const { protocolHierarchy, collapsedItems } = this.state
      csv += "Indent,Protocol,Bytes,Packets\n"
      if (protocolHierarchy) {
        const tree = buildTree(protocolHierarchy, collapsedItems)
        if (tree) {
          tree.forEach(rowData => {
            const row = []
            let protocol = (this.props.showPortNames && rowData.name) || rowData.protocol
            if (this.state.protocolDescriptions) {
              const desc = this.state.protocolDescriptions.find(desc => desc.id === rowData.id)
              if (desc) {
                protocol = desc.hierName
              }
            }
            row.push(rowData.depth + 1)
            row.push(csvStringify(protocol))
            row.push(rowData.bytes)
            row.push(rowData.packets)
            csv += row.join(",") + "\n"
          })
        }
      }
    }

    FileSaver.saveAs(
      new Blob([csv], { type: "text/plain;charset=utf8" }),
      "Protocol Statistics.csv"
    )
  }

  onChangeViewType = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ protocolStatistics: null, protocolHierarchy: null }, () => this.onRefresh())
    this.props.dispatch(setProtocolStatsViewType(e.target.value))
  }

  onChangeFilter = (filter: string) => {
    this.props.dispatch(setProtocolStatsFilter(filter))
  }

  onExpandCollapse = (id: string) => {
    const collapsedItems = { ...this.state.collapsedItems }
    const collapsed = !!collapsedItems[id]
    collapsedItems[id] = !collapsed
    this.setState({ collapsedItems })
  }

  onSelectRelated = (rowData: ProtocolsStatisticsObject) => {
    let protospecPath
    if (this.props.viewType === ViewType.HIERARCHY) {
      protospecPath = rowData.hierarchy.reverse().join("/")
    } else {
      protospecPath = ""
    }
    const body: RequestPostSelectRelatedFilterStart = {
      id: uuid(),
      rootNode: {
        clsid: "A43DDCC0-CDD2-46B4-8114-68E5FAF35112",
        comment: "",
        inverted: false,
        protocol: rowData.mediaSpec,
        protospecPath: protospecPath,
        sliceToHeader: false,
      },
    }
    const { capId } = this.props.match.params
    const { engine, authToken } = this.props
    postSelectRelatedFilterStart(engine, authToken, capId, body)
      .then((task: ResponsePostSelectRelatedFilterStart) => {
        if (task.taskId) {
          this.props.dispatch(
            setSelectPacketsTask({ type: "filter", taskId: task.taskId, progress: 0 })
          )
          this.props.history.push("packets")
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onMSA = (rowData: ProtocolsStatisticsObject) => {
    const type = filterExpressionTypeFromMediaSpecType(rowData.mediaSpec.type)
    const filter = `protocol(${type}:'${rowData.protocol}')`
    this.props.dispatch(setCurrentEngine(null))
    this.props.history.push({
      pathname: getNewDistributedForensicSearchUrl(),
      state: { startTime: rowData.firstTime, endTime: rowData.lastTime, filter },
    })
  }

  onMakeFilter = (rowData: ProtocolsStatisticsObject) => {
    let protospecPath
    if (this.props.viewType === ViewType.HIERARCHY) {
      protospecPath = rowData.hierarchy.reverse().join("/")
    } else {
      protospecPath = ""
    }
    const filter: Filter = {
      clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
      color: "#000000", // TODO: color
      comment: "",
      created: "",
      group: "",
      id: "",
      modified: "",
      name: "Untitled",
      rootNode: {
        clsid: "A43DDCC0-CDD2-46B4-8114-68E5FAF35112",
        comment: "",
        inverted: false,
        protocol: rowData.mediaSpec,
        protospecPath: protospecPath,
        sliceToHeader: false,
      },
    }
    this.props.history.push({
      pathname: getEngineNewFilterUrl(),
      state: { filter },
    })
  }

  onMakeGraph = (rowData: ProtocolsStatisticsObject) => {
    const { protocolDescriptions } = this.state

    const name = (this.props.showPortNames && rowData.name) || rowData.protocol

    let description = ""
    if (protocolDescriptions) {
      const protocol = protocolDescriptions.find(desc => desc.id === rowData.id)
      if (protocol !== undefined) {
        description = protocol.longName
      }
    }

    const graph: GraphObject = {
      ...cloneDeep(defaultGraph),
      graphItems: [
        {
          clsid: "A541981C-E2E4-4BA5-8429-C71207EF9E6B",
          description,
          id: uuid().toUpperCase(),
          name: name,
          protospecId: rowData.id,
          unitType: GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_BYTES,
        },
      ],
      id: uuid().toUpperCase(),
      templateId: uuid().toUpperCase(),
      title: name,
    }

    this.props.history.push({
      pathname: getEngineNewGraphUrl(),
      state: { graph },
    })
  }

  onMakeAlarm = (rowData: ProtocolsStatisticsObject) => {
    const name = (this.props.showPortNames && rowData.name) || rowData.protocol
    const alarm: AlarmInfo = {
      ...cloneDeep(defaultAlarm),
      name: name,
      statisticsTracker: {
        clsid: "CDC6DD80-C8AC-4968-8A1C-CC30932E2E84",
        history: 60,
        protocol: cloneDeep(rowData.mediaSpec),
        statisticsType: PeekProtocolStatType.PEEK_PROTOCOL_STAT_TYPE_BYTES,
      },
    }
    this.props.history.push({
      pathname: getEngineNewAlarmUrl(),
      state: { alarm },
    })
  }

  onInsertIntoNameTable = (rowData: ProtocolsStatisticsObject) => {
    this.setState({
      insertNameEntry: {
        title: "Protocol",
        entry: rowData.protocol,
        entryType: rowData.mediaSpec.type,
      },
    })
  }

  onInsertIntoNameTableOK = () => {
    this.setState({ insertNameEntry: null })
    this.onRefresh()
  }

  onInsertIntoNameTableCancel = () => {
    this.setState({ insertNameEntry: null })
  }

  onProtocolDescription = (rowData: ProtocolsStatisticsObject) => {
    this.setState({ protocolDescriptionId: rowData.id })
  }

  onProtocolDescriptionClose = () => {
    this.setState({ protocolDescriptionId: null })
  }

  onExpandAll = () => {
    const { protocolStatistics } = this.state
    const collapsedItems: any[] = []
    if (protocolStatistics) {
      protocolStatistics.protocols.forEach((protocol: ProtocolsStatisticsObject) => {
        const clientId: any = getClientId(protocol)
        if (clientId in collapsedItems) {
          collapsedItems[clientId] = false
        }
      })
    }
    this.setState({ collapsedItems })
  }

  collapsedItemsRecurse = (
    collapsedItems: any,
    rowData: ProtocolsStatisticsObject,
    collapsed: boolean
  ) => {
    const clientId: any = getClientId(rowData)
    if (clientId in collapsedItems || collapsed) {
      collapsedItems[clientId] = collapsed
    }
    if ("children" in rowData && Array.isArray(rowData.children)) {
      rowData.children.forEach((child: ProtocolsStatisticsObject) => {
        this.collapsedItemsRecurse(collapsedItems, child, collapsed)
      })
    }
  }

  onExpandSelection = (rowData: ProtocolsStatisticsObject) => {
    const collapsedItems = cloneDeep(this.state.collapsedItems)
    this.collapsedItemsRecurse(collapsedItems, rowData, false)
    this.setState({ collapsedItems })
  }

  onCollapseAll = () => {
    const { protocolStatistics } = this.state
    const collapsedItems: any[] = []
    if (protocolStatistics) {
      protocolStatistics.protocols.forEach((protocol: ProtocolsStatisticsObject) => {
        const clientId: any = getClientId(protocol)
        collapsedItems[clientId] = true
      })
    }
    this.setState({ collapsedItems })
  }

  onCollapseSelection = (rowData: ProtocolsStatisticsObject) => {
    const collapsedItems = cloneDeep(this.state.collapsedItems)
    this.collapsedItemsRecurse(collapsedItems, rowData, true)
    this.setState({ collapsedItems })
  }

  cellRendererFlat = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content
    switch (dataKey) {
      case "protocol":
        {
          const protocol = (this.props.showPortNames && rowData.name) || rowData.protocol
          if (rowData.color != null && this.props.theme.name === "Light") {
            content = (
              <span style={{ color: rowData.color }} title={protocol}>
                {protocol}
              </span>
            )
          } else {
            content = protocol
          }
        }
        break
      case "percentage":
        {
          let percentage = 0
          if (this.state.protocolStatistics) {
            percentage = (rowData.bytes / this.state.protocolStatistics.totalBytes) * 100
          }
          content = (
            <BarGauge
              aria-label="Percentage of bytes"
              value={percentage}
              max={100}
              color={rowData.color}
              title={`${formatFloat(percentage, 3)}%`}
            />
          )
        }
        break
      case "bytes":
        content = formatInteger(cellData)
        break
      case "packets":
        content = formatInteger(cellData)
        break
      default:
        break
    }
    return content
  }

  cellRendererHierarchy = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content
    const collapsed = !!this.state.collapsedItems[rowData.clientId]
    if (collapsed && rowData.totalBytes === undefined) updateTotals(rowData)
    const fontStyle = !collapsed && rowData.bytes === 0 ? "italic" : undefined
    switch (dataKey) {
      case "protocol":
        {
          let protocol = (this.props.showPortNames && rowData.name) || rowData.protocol
          if (this.state.protocolDescriptions) {
            const desc = this.state.protocolDescriptions.find(desc => desc.id === rowData.id)
            if (desc) {
              protocol = desc.hierName
            }
          }
          const color = this.props.theme.name === "Light" && rowData.color
          const icon =
            rowData.children.length === 0 ? "blank" : collapsed ? "chevron-right" : "chevron-down"
          content = (
            <div style={{ paddingLeft: `${rowData.depth * 1.257}em`, fontStyle: fontStyle }}>
              <IconButton
                aria-label="Expand/Collapse"
                onClick={this.onExpandCollapse.bind(this, rowData.clientId)}
              >
                <FontAwesome name={icon} fixedWidth />
              </IconButton>
              <span style={{ paddingLeft: ".25em", color }} title={protocol}>
                {protocol}
              </span>
            </div>
          )
        }
        break
      case "percentage":
        {
          const bytes = collapsed ? rowData.totalBytes : rowData.bytes
          let percentage = 0
          if (this.state.protocolStatistics) {
            percentage = (bytes / this.state.protocolStatistics.totalBytes) * 100
          }
          content = (
            <BarGauge
              aria-label="Percentage of bytes"
              value={percentage}
              max={100}
              color={rowData.color}
              title={`${formatFloat(percentage, 3)}%`}
            />
          )
        }
        break
      case "bytes":
        {
          const bytes = collapsed ? rowData.totalBytes : cellData
          content = <span style={{ fontStyle: fontStyle }}>{formatInteger(bytes)}</span>
        }
        break
      case "packets":
        {
          const packets = collapsed ? rowData.totalPackets : cellData
          content = <span style={{ fontStyle: fontStyle }}>{formatInteger(packets)}</span>
        }
        break
      default:
        break
    }
    return content
  }

  commandCellRenderer = ({ rowData }: TableCellProps) => {
    const { captureProperties, engineCapabilities, userId, viewType } = this.props
    const packetsDisabled = captureProperties === null || !captureProperties.packetBufferEnabled

    // make sure the user can view packets for this capture or forensic search
    let canCreateForensicSearch = true
    let canUploadFiles = true
    let canViewPackets = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canCreateForensicSearch =
        !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL) ||
        policies.includes(EngineUserPolicies.createForensicSearch)
      canUploadFiles = policies.includes(EngineUserPolicies.uploadFiles)
      canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
    }

    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            {viewType === ViewType.HIERARCHY && (
              <>
                <DropdownItem onClick={this.onExpandSelection.bind(this, rowData)}>
                  Expand Selection
                </DropdownItem>
                <DropdownItem onClick={this.onCollapseSelection.bind(this, rowData)}>
                  Collapse Selection
                </DropdownItem>
                <DropdownItem divider />
              </>
            )}
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, rowData)}
            >
              Select Related Packets
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              disabled={!canUploadFiles || !canCreateForensicSearch}
              onClick={this.onMSA.bind(this, rowData)}
            >
              Multi-Segment Analysis
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.onMakeFilter.bind(this, rowData)}>Make Filter</DropdownItem>
            <DropdownItem onClick={this.onMakeGraph.bind(this, rowData)}>Make Graph</DropdownItem>
            <DropdownItem onClick={this.onMakeAlarm.bind(this, rowData)}>Make Alarm</DropdownItem>
            <DropdownItem
              disabled={rowData.mediaSpec.type === MediaSpecType.MEDIA_SPEC_TYPE_PROTOSPEC}
              onClick={this.onInsertIntoNameTable.bind(this, rowData)}
            >
              Insert Into Name Table
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              disabled={rowData.id === 0}
              onClick={this.onProtocolDescription.bind(this, rowData)}
            >
              Protocol Description
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  onSort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
    const { viewType } = this.props
    if (viewType === ViewType.FLAT) {
      this.sortFlat(this.state.protocolStatistics, sortBy, sortDirection)
    } else if (viewType === ViewType.HIERARCHY) {
      this.sortHierarchy(this.state.protocolHierarchy, sortBy, sortDirection)
    }
    this.props.dispatch(setProtocolStatsSort(sortBy, sortDirection))
  }

  sortFlat(
    protocolStatistics: ProtocolsStatistics | null,
    sortBy: string,
    sortDirection: SortDirectionType
  ) {
    if (protocolStatistics && protocolStatistics.protocols) {
      if (sortBy === "percentage") sortBy = "bytes"
      const collator = new Intl.Collator(undefined, { sensitivity: "base", numeric: true })
      protocolStatistics.protocols.sort(
        (a: ProtocolsStatisticsObject, b: ProtocolsStatisticsObject) => {
          let result = 0
          switch (sortBy) {
            case "protocol":
              {
                const valueA = (this.props.showPortNames && a.name) || a.protocol
                const valueB = (this.props.showPortNames && b.name) || b.protocol
                result = collator.compare(valueA, valueB)
              }
              break
            case "bytes":
            case "packets":
              {
                const valueA = a[sortBy]
                const valueB = b[sortBy]
                if (valueA > valueB) {
                  result = 1
                } else if (valueA < valueB) {
                  result = -1
                }
              }
              break
            default:
              break
          }
          if (result === 0) {
            // Fall back to name.
            const valueA = (this.props.showPortNames && a.name) || a.protocol
            const valueB = (this.props.showPortNames && b.name) || b.protocol
            result = collator.compare(valueA, valueB)
          }
          if (sortDirection === SortDirection.DESC) result = -result
          return result
        }
      )
    }
  }

  sortHierarchy(protocolHierarchy: any | null, sortBy: string, sortDirection: SortDirectionType) {
    if (protocolHierarchy) {
      if (sortBy === "percentage") sortBy = "bytes"
      if (sortBy === "bytes") sortBy = "totalBytes"
      if (sortBy === "packets") sortBy = "totalPackets"

      const collator = new Intl.Collator(undefined, { sensitivity: "base", numeric: true })

      const sortItems = (items: any[]) => {
        items.sort((a, b) => {
          if (a.totalBytes === undefined) updateTotals(a)
          if (b.totalBytes === undefined) updateTotals(b)
          let result = 0
          switch (sortBy) {
            case "protocol":
              {
                let valueA = (this.props.showPortNames && a.name) || a.protocol
                let valueB = (this.props.showPortNames && b.name) || b.protocol
                if (this.state.protocolDescriptions) {
                  const descA = this.state.protocolDescriptions.find(desc => desc.id === a.id)
                  if (descA) {
                    valueA = descA.hierName
                  }
                  const descB = this.state.protocolDescriptions.find(desc => desc.id === b.id)
                  if (descB) {
                    valueB = descB.hierName
                  }
                }
                result = collator.compare(valueA, valueB)
              }
              break
            case "bytes":
            case "packets":
            case "totalBytes":
            case "totalPackets":
              {
                const valueA = a[sortBy]
                const valueB = b[sortBy]
                if (valueA > valueB) {
                  result = 1
                } else if (valueA < valueB) {
                  result = -1
                }
              }
              break
            default:
              break
          }
          if (result === 0) {
            // Fall back to name.
            let valueA = (this.props.showPortNames && a.name) || a.protocol
            let valueB = (this.props.showPortNames && b.name) || b.protocol
            if (this.state.protocolDescriptions) {
              const descA = this.state.protocolDescriptions.find(desc => desc.id === a.id)
              if (descA) {
                valueA = descA.hierName
              }
              const descB = this.state.protocolDescriptions.find(desc => desc.id === b.id)
              if (descB) {
                valueB = descB.hierName
              }
            }
            result = collator.compare(valueA, valueB)
          }
          if (sortDirection === SortDirection.DESC) result = -result
          return result
        })
      }

      const deepSort = (items: any[]) => {
        sortItems(items)
        items.forEach(item => {
          deepSort(item.children)
        })
      }

      deepSort(protocolHierarchy)
    }
  }

  render() {
    const {
      protocolStatistics,
      protocolHierarchy,
      collapsedItems,
      protocolDescriptions,
      protocolDescriptionId,
      insertNameEntry,
    } = this.state
    const {
      captureProperties,
      engineCapabilities,
      viewType,
      filter,
      sortBy,
      sortDirection,
      userId,
    } = this.props

    // make sure the user can view stats for this capture or forensic search
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
      if (!canViewStats) {
        const { type, capId } = this.props.match.params
        return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
      }
    }

    let count = 0
    let protocols = null
    let tree = null
    if (viewType === ViewType.FLAT) {
      if (protocolStatistics) {
        protocols = protocolStatistics.protocols
        count = protocols.length
        if (protocols && filter) {
          const lowerCaseFilter = filter.toLowerCase()
          protocols = protocols.filter(stat => {
            const protocol = (this.props.showPortNames && stat.name) || stat.protocol
            return protocol.toLowerCase().includes(lowerCaseFilter)
          })
        }
      }
    } else if (viewType === ViewType.HIERARCHY) {
      if (protocolHierarchy) {
        tree = buildTree(protocolHierarchy, collapsedItems)
        if (tree) {
          count = tree.length
        }
      }
    }
    let limitMessage = ""
    let limitIcon = null
    if (protocolStatistics && protocolStatistics.timeLimitReached) {
      limitMessage = `Protocol statistics limit reached at ${formatISODateTime(
        protocolStatistics.timeLimitReached,
        0,
        this.props.showLocalTime
      )}`
      limitIcon = <IconSevere />
    }
    return (
      <View>
        <BreadcrumbItem to={this.props.match.url} title="Protocol Statistics" />
        <Interval timeout={30000} enabled={true} callback={this.onRefresh} />
        <ViewHeader>
          <ViewHeaderTitle title="Protocols" count={count} />
          <ViewHeaderButtons>
            <Select
              name="viewType"
              id="viewType"
              aria-label="View Type"
              value={viewType}
              onChange={this.onChangeViewType}
              style={{ width: "auto" }}
            >
              <option>{ViewType.FLAT}</option>
              <option>{ViewType.HIERARCHY}</option>
            </Select>
            {viewType === ViewType.HIERARCHY && (
              <>
                <LightButton id="expand-all" onClick={this.onExpandAll}>
                  Expand All
                </LightButton>
                <LightButton id="collapse-all" onClick={this.onCollapseAll}>
                  Collapse All
                </LightButton>
              </>
            )}
            <FilterBox
              aria-label="Search"
              placeholder="Search"
              onChange={this.onChangeFilter}
              disabled={viewType !== ViewType.FLAT}
              value={filter}
            />
            <LightButton aria-label="Export" id="export" onClick={this.onExport}>
              <FontAwesome name="download" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="export">
              Export
            </UncontrolledTooltip>
            <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefresh}>
              <FontAwesome name="refresh" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="refresh">
              Refresh
            </UncontrolledTooltip>
          </ViewHeaderButtons>
        </ViewHeader>
        {limitMessage && (
          <UncontrolledAlert component={ViewAlert} color="danger" fade={false}>
            <ViewAlertContent>
              {limitIcon}
              {limitMessage}
            </ViewAlertContent>
          </UncontrolledAlert>
        )}
        <ViewContent>
          {protocols && (
            <OmniTable
              data={protocols}
              rowCount={protocols.length}
              columnDesc={defaultColumns}
              cellRenderer={this.cellRendererFlat}
              renderCommands={this.commandCellRenderer}
              sort={this.onSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            />
          )}
          {tree && (
            <OmniTable
              data={tree}
              rowCount={tree.length}
              columnDesc={defaultColumns}
              cellRenderer={this.cellRendererHierarchy}
              renderCommands={this.commandCellRenderer}
              sort={this.onSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            />
          )}
        </ViewContent>
        <ProtocolDescriptionModal
          isOpen={protocolDescriptionId !== null}
          protocolId={protocolDescriptionId}
          descriptions={protocolDescriptions}
          onClose={this.onProtocolDescriptionClose}
        />
        {insertNameEntry != null && (
          <InsertNamesModal
            engine={this.props.engine}
            authToken={this.props.authToken}
            entries={[insertNameEntry]}
            onOK={this.onInsertIntoNameTableOK}
            onCancel={this.onInsertIntoNameTableCancel}
          />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  namesModTime: getNamesModificationTime(state),
  showPortNames: getShowPortNames(state),
  viewType: getProtocolStatsViewType(state) || ViewType.FLAT,
  filter: getProtocolStatsFilter(state) || "",
  showLocalTime: getShowLocalTime(state),
  sortBy: getProtocolStatsSortBy(state) || "percentage",
  sortDirection: getProtocolStatsSortDirection(state) || SortDirection.DESC,
  engineCapabilities: getCapabilities(state) || null,
  userId: getUserId(state),
})

export default connect(mapStateToProps)(withTheme(ProtocolStatisticsView))
