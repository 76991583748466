import * as React from "react"
import { useSelector } from "react-redux"
import { useHistory, useParams, useRouteMatch } from "react-router-dom"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { View, ViewMaxWidth, ViewContent } from "../common/View"
import ConfirmationModal from "../common/ConfirmationModal"
import { getEngine, getAuthToken, getUserId, getCapabilities, getShowLocalTime } from "../../store"
import {
  deleteCFS,
  startCapture,
  stopCapture,
  putForensicSearch,
  stopLoadForensicSearch,
} from "../../api/api"
import {
  ResponseGetEngineCapabilities,
  CaptureProperties,
  ForensicSearchProperties,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { PeekCaptureState } from "../../api/types/peekTypes"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import CapturePanel from "../CapturePanel"
import ForensicSearchPanel from "../ForensicSearchPanel"

const CaptureHomeView = ({ captureProperties, onRefreshCaptureProperties }: CaptureViewProps) => {
  const history = useHistory()
  const match = useRouteMatch()
  const { type, capId } = useParams<CaptureRouteParams>()
  const engine = useSelector(getEngine)
  const authToken = useSelector(getAuthToken)
  const userId = useSelector(getUserId)
  const engineCapabilities: ResponseGetEngineCapabilities = useSelector(getCapabilities)
  const showLocalTime: boolean = useSelector(getShowLocalTime)
  const [isStartCapturePending, setStartCapturePending] = React.useState(false)
  const [isStopCapturePending, setStopCapturePending] = React.useState(false)
  const [isDeleteCapturePending, setDeleteCapturePending] = React.useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false)

  const onStartCapture = () => {
    setStartCapturePending(true)
    startCapture(engine, authToken, capId)
      .then(() => {
        onRefreshCaptureProperties()
      })
      .catch(() => {})
      .finally(() => {
        setStartCapturePending(false)
      })
  }

  const onStopCapture = () => {
    setStopCapturePending(true)
    stopCapture(engine, authToken, capId)
      .then(() => {
        onRefreshCaptureProperties()
      })
      .catch(() => {})
      .finally(() => {
        setStopCapturePending(false)
      })
  }

  const onToggleCapture = () => {
    if (captureProperties) {
      const isCapturing =
        (captureProperties.status & PeekCaptureState.PEEK_CAPTURE_STATE_CAPTURING) !== 0
      if (isCapturing) {
        onStopCapture()
      } else {
        onStartCapture()
      }
    }
  }

  const onCaptureOptions = () => {
    history.push("options")
  }

  const onDelete = () => {
    setShowDeleteConfirm(true)
  }

  const onDeleteCancel = () => {
    setShowDeleteConfirm(false)
  }

  const onDeleteOK = () => {
    setShowDeleteConfirm(false)
    setDeleteCapturePending(true)
    deleteCFS(engine, authToken, type, capId)
      .then(() => {
        onRefreshCaptureProperties()
      })
      .catch(() => {})
      .finally(() => {
        setDeleteCapturePending(false)
      })
  }

  const onUpdateForensicSearchName = (forensicSearchId: string, name: string) => {
    if (forensicSearchId !== null) {
      putForensicSearch(engine, authToken, forensicSearchId, { name })
        .then(() => {
          onRefreshCaptureProperties()
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const onStopLoadForensicSearch = () => {
    stopLoadForensicSearch(engine, authToken, capId)
      .then(() => {
        onRefreshCaptureProperties()
      })
      .catch(() => {})
  }

  const isCapture = type === "captures"
  const isForensicSearch = type === "forensic-searches"
  const policies = engineCapabilities.userRights.policies
  const isUserOwner = userId === captureProperties.creatorSID
  const isStartStopCapturePending = isStartCapturePending || isStopCapturePending
  const isStartStopCaptureEnabled =
    isCapture &&
    !isStartStopCapturePending &&
    (isUserOwner || policies.includes(EngineUserPolicies.startStopCaptures))
  const isCaptureOptionsEnabled =
    isCapture && (isUserOwner || policies.includes(EngineUserPolicies.modifyCaptures))
  const isDeleteCaptureEnabled =
    isCapture &&
    !isDeleteCapturePending &&
    (isUserOwner || policies.includes(EngineUserPolicies.deleteCaptures))
  const isDeleteForensicSearchEnabled =
    isForensicSearch &&
    (!engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL) ||
      isUserOwner ||
      policies.includes(EngineUserPolicies.deleteForensicSearches))

  // Verify if users can update forensic searches
  const isEditingOfNameEnabled =
    engineCapabilities &&
    engineCapabilities.capabilities.includes(EngineCapabilities.putForensicSearch)

  return (
    <View>
      <BreadcrumbItem to={match.url} title="Home" />
      <ViewMaxWidth>
        <ViewContent>
          {isCapture ? (
            <CapturePanel
              capture={captureProperties as CaptureProperties}
              isViewEnabled={false}
              isStartStopPending={isStartStopCapturePending}
              isDeletePending={isDeleteCapturePending}
              isStartStopEnabled={isStartStopCaptureEnabled}
              isCaptureOptionsEnabled={isCaptureOptionsEnabled}
              isDeleteCaptureEnabled={isDeleteCaptureEnabled}
              onToggleCapture={onToggleCapture}
              onCaptureOptions={onCaptureOptions}
              onDeleteCapture={onDelete}
              showLocalTime={showLocalTime}
            />
          ) : (
            <ForensicSearchPanel
              forensicSearch={captureProperties as ForensicSearchProperties}
              isViewEnabled={false}
              isDeleteForensicSearchEnabled={isDeleteForensicSearchEnabled}
              onDeleteForensicSearch={onDelete}
              isEditingOfNameEnabled={isEditingOfNameEnabled}
              onUpdateForensicSearchName={onUpdateForensicSearchName}
              onStopLoadForensicSearch={onStopLoadForensicSearch}
              showLocalTime={showLocalTime}
            />
          )}
        </ViewContent>
      </ViewMaxWidth>
      {showDeleteConfirm && isCapture && (
        <ConfirmationModal
          show={true}
          message={
            <span>
              Are you sure you want to delete the capture?
              <br />
              <br />
              Packet data stored on disk, statistics, and reports will not be deleted.
            </span>
          }
          onNo={onDeleteCancel}
          onYes={onDeleteOK}
          title="Delete Capture"
        />
      )}
      {showDeleteConfirm && isForensicSearch && (
        <ConfirmationModal
          show={true}
          message="Are you sure you want to delete the forensic search?"
          onNo={onDeleteCancel}
          onYes={onDeleteOK}
          title="Delete Forensic Search"
        />
      )}
    </View>
  )
}

export default CaptureHomeView
