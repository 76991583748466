import * as React from "react"
import styled, { useTheme } from "styled-components"
import { useHistory } from "react-router-dom"
import { ButtonGroup } from "reactstrap"
import { LightButton, TabButton } from "../common/Buttons"
import PropTable from "../common/PropTable"
import { Table } from "../common/Table"
import {
  formatExpertValue,
  getExpertValueFromRowData,
  parseHeaders,
  formatHeaderProp,
} from "../../utils/expertUtils"
import { CaptureProperties, ExpertValue, ForensicSearchProperties } from "../../api/types"
import { ExpertColumn } from "../../api/types/expertTypes"
import { useSelector } from "react-redux"
import { getShowLocalTime } from "../../store"

const WebRequestDetailsContent = styled.div`
  padding: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 1rem;
  }
`

const DetailsTable = styled(Table)`
  & th {
    text-align: right;
    white-space: nowrap;
    font-weight: bold;
    color: ${props => props.theme.propTableHeaderColor};
    font-size: ${props => props.theme.propTableHeaderFontSize};
    line-height: 1.5rem;
    text-transform: ${props => props.theme.propTableHeaderTextTransform};
  }

  & th:nth-child(1) {
    width: 1%;
  }

  & td {
    word-break: break-all;
    text-align: right;
  }

  & td.break-normal {
    word-break: normal;
  }
`

const DetailsTableTitle = styled.h6`
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
  text-transform: uppercase;
  display: none;
`

const HeadersTableTitle = styled.h6`
  margin: 0 0 0.25rem 0;
  font-size: 1rem;
  text-transform: uppercase;
`

const HeaderDiv = styled.div`
  word-break: break-all;
`

export enum RequestInfo {
  REQUEST_INFO_DETAILS,
  REQUEST_INFO_HEADERS,
}

type WebRequestDetailsProps = {
  captureProperties: CaptureProperties | ForensicSearchProperties | null
  columnList: ExpertColumn[] | null
  rowData: ExpertValue[] | null
}

const WebRequestDetails = ({ captureProperties, columnList, rowData }: WebRequestDetailsProps) => {
  const [requestInfo, setRequestInfo] = React.useState(RequestInfo.REQUEST_INFO_DETAILS)
  const history = useHistory()
  const theme = useTheme()
  const showLocalTime = useSelector(getShowLocalTime)
  if (!columnList || !rowData) return null

  const fmt = (columnId: ExpertColumn) => {
    const value = getExpertValueFromRowData(rowData, columnList, columnId)
    if (value != null && value.value != null) {
      const formatted = formatExpertValue(
        columnId,
        value.value,
        value.rendered,
        rowData,
        columnList,
        showLocalTime
      )
      if (
        formatted != null &&
        theme.name === "Light" &&
        value.color != null &&
        value.color !== "#000000"
      ) {
        return (
          <span title={String(formatted)} style={{ color: value.color }}>
            {formatted}
          </span>
        )
      } else {
        return formatted
      }
    }
    return undefined
  }

  const flowId = getExpertValueFromRowData(
    rowData,
    columnList,
    ExpertColumn.EXPERT_COLUMN_STREAM_ID
  )

  const requestHeaderProperties: any = {}
  const requestFieldNames: Array<string> = []
  let initialMessageRequest: string = ""
  const requestHeaderValue = getExpertValueFromRowData(
    rowData,
    columnList,
    ExpertColumn.EXPERT_COLUMN_REQUEST_HEADER
  )
  if (requestHeaderValue != null && typeof requestHeaderValue.value === "string") {
    // Create an array of strings to split out different fields based on newlines
    const splitRequest: Array<string> = requestHeaderValue.value.split("\r\n")

    // Store message separate and remove from array
    initialMessageRequest = splitRequest.splice(0, 1)[0]

    // Split out the field names using ": " as the delimiter value
    parseHeaders(splitRequest, requestHeaderProperties, requestFieldNames)
  }

  const responseHeaderProperties: any = {}
  const responseFieldNames: Array<string> = []
  let initialMessageResponse: string = ""
  const responseHeaderValue = getExpertValueFromRowData(
    rowData,
    columnList,
    ExpertColumn.EXPERT_COLUMN_RESPONSE_HEADER
  )
  if (responseHeaderValue != null && typeof responseHeaderValue.value === "string") {
    // Create an array of strings to split out different fields based on newlines
    const splitResponse: Array<string> = responseHeaderValue.value.split("\r\n")

    // Store message separate and remove from array
    initialMessageResponse = splitResponse.splice(0, 1)[0]

    // Split out the field names using ": " as the delimiter value
    parseHeaders(splitResponse, responseHeaderProperties, responseFieldNames)
  }

  return (
    <WebRequestDetailsContent>
      {flowId != null && typeof flowId.value === "number" && captureProperties !== null ? (
        <div>
          <LightButton
            disabled={!captureProperties.packetBufferEnabled || !captureProperties.expertEnabled}
            onClick={() => history.push(`flow-visualizer/${flowId.value}`)}
          >
            Flow Visualizer
          </LightButton>
        </div>
      ) : null}

      <ButtonGroup style={{ flexShrink: 0 }}>
        <TabButton
          name="requestInfoRadios"
          onClick={() => setRequestInfo(RequestInfo.REQUEST_INFO_DETAILS)}
          active={requestInfo === RequestInfo.REQUEST_INFO_DETAILS}
        >
          Details
        </TabButton>
        <TabButton
          name="requestInfoRadios"
          onClick={() => setRequestInfo(RequestInfo.REQUEST_INFO_HEADERS)}
          active={requestInfo === RequestInfo.REQUEST_INFO_HEADERS}
        >
          Headers
        </TabButton>
      </ButtonGroup>

      {requestInfo === RequestInfo.REQUEST_INFO_DETAILS ? (
        <div>
          <DetailsTableTitle>Details</DetailsTableTitle>
          <DetailsTable size="sm">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Client</th>
                <th>Server</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>URI / Host</th>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_URI)}</td>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_HOST)}</td>
              </tr>
              <tr>
                <th>Response Code</th>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_RESPONSE_CODE)}</td>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_RESPONSE_TEXT)}</td>
              </tr>
              <tr>
                <th>Referer</th>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_REFERER)}</td>
                <td />
              </tr>
              <tr>
                <th>Content-Type</th>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_CONTENT_TYPE)}</td>
                <td />
              </tr>
              <tr>
                <th>Request ID</th>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_REQUEST_ID)}</td>
                <td />
              </tr>
              <tr>
                <th>Page ID</th>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_PAGE_REQUEST_ID)}</td>
                <td />
              </tr>
              <tr>
                <th>Flow ID</th>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_STREAM_ID)}</td>
                <td />
              </tr>
              <tr>
                <th>Address</th>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_ADDRESS)}</td>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_ADDRESS)}</td>
              </tr>
              <tr>
                <th>Port</th>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_PORT)}</td>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_PORT)}</td>
              </tr>
              <tr>
                <th>Packets</th>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_PACKET_COUNT)}</td>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_SENT_PACKET_COUNT)}</td>
              </tr>
              <tr>
                <th>Bytes</th>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_SENT_BYTE_COUNT)}</td>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_SENT_BYTE_COUNT)}</td>
              </tr>
              <tr>
                <th>Data Bytes</th>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_REQUEST_PAYLOAD_BYTE_COUNT)}</td>
                <td>{fmt(ExpertColumn.EXPERT_COLUMN_RESPONSE_PAYLOAD_BYTE_COUNT)}</td>
              </tr>
              <tr>
                <th>Start</th>
                <td className="break-normal">
                  {fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_START_TIME)}
                </td>
                <td className="break-normal">
                  {fmt(ExpertColumn.EXPERT_COLUMN_SERVER_START_TIME)}
                </td>
              </tr>
              <tr>
                <th>Finish</th>
                <td className="break-normal">{fmt(ExpertColumn.EXPERT_COLUMN_CLIENT_END_TIME)}</td>
                <td className="break-normal">{fmt(ExpertColumn.EXPERT_COLUMN_SERVER_END_TIME)}</td>
              </tr>
            </tbody>
          </DetailsTable>
        </div>
      ) : null}

      {requestInfo === RequestInfo.REQUEST_INFO_HEADERS ? (
        <>
          <HeaderDiv>
            <HeadersTableTitle>Request Headers</HeadersTableTitle>
            <h6>{initialMessageRequest}</h6>
            <PropTable propList={requestFieldNames} data={requestHeaderProperties} skipEmptyRows />
          </HeaderDiv>
          <HeaderDiv>
            <HeadersTableTitle>Response Headers</HeadersTableTitle>
            <h6>{initialMessageResponse}</h6>
            <PropTable
              propList={responseFieldNames}
              formatProp={formatHeaderProp}
              data={responseHeaderProperties}
              skipEmptyRows
            />
          </HeaderDiv>
        </>
      ) : null}
    </WebRequestDetailsContent>
  )
}

export default WebRequestDetails
