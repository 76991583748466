import * as React from "react"
import styled from "styled-components"
import { FormGroup } from "reactstrap"
import { ControlStrip } from "../common/Layout"
import { DateTimePicker } from "../common/DateTimePicker"
import { Input } from "../common/Input"
import { Label, CheckGroup } from "../common/Form"
import { Popover, PopoverBody } from "../common/Popover"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"
import { Select } from "../common/Select"
import { ForensicSearchHistorySearch } from "./ForensicSearchHistorySearchTypes"
import { useSelector } from "react-redux"
import { getShowLocalTime } from "../../store"

const ForensicSearchHistorySearchPopoverBody = styled(PopoverBody)`
  padding: 1rem;

  & label {
    margin-bottom: 0.25rem;
  }
`

const CheckGroupWrapper = styled.div`
  display: flex;

  & > :first-child {
    flex-shrink: 0;
  }

  & > :last-child {
    flex-grow: 1;
  }
`

type ForensicSearchHistorySearchPopoverProps = {
  isOpen: boolean
  target: string
  search: ForensicSearchHistorySearch
  firstTimestamp: string
  lastTimestamp: string
  onOK: (search: ForensicSearchHistorySearch) => void
  onCancel: () => void
}

const ForensicSearchHistorySearchPopover: React.FC<ForensicSearchHistorySearchPopoverProps> = ({
  isOpen,
  target,
  search,
  firstTimestamp,
  lastTimestamp,
  onOK,
  onCancel,
}) => {
  const showLocalTime = useSelector(getShowLocalTime)
  const [isFavoriteEnabled, setFavoriteEnabled] = React.useState(search.favorite !== null)
  const [isNameEnabled, setNameEnabled] = React.useState(search.name.length > 0)
  const [isOwnerEnabled, setOwnerEnabled] = React.useState(search.owner.length > 0)
  const [favorite, setFavorite] = React.useState(search.favorite !== null ? search.favorite : false)
  const [name, setName] = React.useState(search.name)
  const [owner, setOwner] = React.useState(search.owner)
  const [isTimeRangeEnabled, setTimeRangeEnabled] = React.useState(
    search.startTime.length > 0 && search.stopTime.length > 0
  )
  const [startTime, setStartTime] = React.useState(
    search.startTime || firstTimestamp || new Date().toISOString()
  )
  const [stopTime, setStopTime] = React.useState(
    search.stopTime || lastTimestamp || new Date().toISOString()
  )

  return (
    <Popover placement="bottom" isOpen={isOpen} target={target} fade={false}>
      <ForensicSearchHistorySearchPopoverBody>
        <FormGroup>
          <Label for="search--favorite" style={{ marginLeft: "1.5rem" }}>
            Favorite
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search--favorite-enabled"
              checked={isFavoriteEnabled}
              onChange={() => setFavoriteEnabled(!isFavoriteEnabled)}
            />
            <Select
              name="search--favorite"
              id="search--favorite"
              aria-label="Favorite"
              disabled={!isFavoriteEnabled}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setFavorite(event.target.value === "true")
              }}
              value={favorite ? "true" : "false"}
            >
              <option value={"true"}>Yes</option>
              <option value={"false"}>No</option>
            </Select>
          </CheckGroupWrapper>
        </FormGroup>

        <FormGroup>
          <Label for="search--name" style={{ marginLeft: "1.5rem" }}>
            Name
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search--name-enabled"
              checked={isNameEnabled}
              onChange={() => setNameEnabled(!isNameEnabled)}
            />
            <Input
              type="text"
              id="search--name"
              disabled={!isNameEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value)
              }}
              value={name}
            />
          </CheckGroupWrapper>
        </FormGroup>

        <FormGroup>
          <Label for="search--owner" style={{ marginLeft: "1.5rem" }}>
            Owner
          </Label>
          <CheckGroupWrapper>
            <CheckGroup
              type="checkbox"
              id="search--owner-enabled"
              checked={isOwnerEnabled}
              onChange={() => setOwnerEnabled(!isOwnerEnabled)}
            />
            <Input
              type="text"
              id="search--owner"
              disabled={!isOwnerEnabled}
              bsSize="sm"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setOwner(event.target.value)
              }}
              value={owner}
            />
          </CheckGroupWrapper>
        </FormGroup>

        <FormGroup>
          <CheckGroupWrapper>
            <div style={{ paddingTop: "2rem" }}>
              <CheckGroup
                type="checkbox"
                id="search--time-range-enabled"
                checked={isTimeRangeEnabled}
                onChange={() => setTimeRangeEnabled(!isTimeRangeEnabled)}
              />
            </div>
            <div>
              <FormGroup>
                <Label for="search--start-time">
                  Begin&nbsp;time{showLocalTime ? "" : " (UTC)"}
                </Label>
                <DateTimePicker
                  id="search--start-time"
                  disabled={!isTimeRangeEnabled}
                  selected={new Date(startTime)}
                  onChange={(date: Date | null) => {
                    if (date != null) {
                      setStartTime(date.toISOString())
                    }
                  }}
                  showLocalTime={showLocalTime}
                />
              </FormGroup>
              <FormGroup noMargin>
                <Label for="search--stop-time">Finish time{showLocalTime ? "" : " (UTC)"}</Label>
                <DateTimePicker
                  id="search--stop-time"
                  disabled={!isTimeRangeEnabled}
                  selected={new Date(stopTime)}
                  onChange={(date: Date | null) => {
                    if (date != null) {
                      setStopTime(date.toISOString())
                    }
                  }}
                  showLocalTime={showLocalTime}
                />
              </FormGroup>
            </div>
          </CheckGroupWrapper>
        </FormGroup>

        <ControlStrip justify="flex-end" style={{ paddingLeft: "1.5rem" }}>
          <SecondaryButton size="sm" onClick={() => onCancel()}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            size="sm"
            onClick={() =>
              onOK({
                favorite: isFavoriteEnabled ? favorite : null,
                name: isNameEnabled ? name : "",
                owner: isOwnerEnabled ? owner : "",
                startTime: isTimeRangeEnabled ? startTime : "",
                stopTime: isTimeRangeEnabled ? stopTime : "",
              })
            }
          >
            Search
          </PrimaryButton>
        </ControlStrip>
      </ForensicSearchHistorySearchPopoverBody>
    </Popover>
  )
}

export default ForensicSearchHistorySearchPopover
