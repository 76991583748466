import * as React from "react"
import cn from "classnames"
import styled from "styled-components"
import { rgba } from "polished"
import FontAwesome from "react-fontawesome"
import { Button, CloseButton as ReactstrapCloseButton, DropdownToggle } from "reactstrap"

export const PrimaryButton = styled(Button).attrs({ color: "primary" })`
  color: ${props => props.theme.primaryButtonColor};
  background-color: ${props => props.theme.primaryButtonBackgroundColor};
  border-color: ${props => props.theme.primaryButtonBorderColor};

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.primaryButtonHoverColor};
    background-color: ${props => props.theme.primaryButtonHoverBackgroundColor};
    border-color: ${props => props.theme.primaryButtonHoverBorderColor};
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.primaryButtonBorderColor, 0.5)};
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.primaryButtonHoverColor};
    background-color: ${props => props.theme.primaryButtonHoverBackgroundColor};
    border-color: ${props => props.theme.primaryButtonHoverBorderColor};
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.primaryButtonColor};
    background-color: ${props => props.theme.primaryButtonBackgroundColor};
    border-color: ${props => props.theme.primaryButtonBorderColor};
    cursor: default;
  }

  & > .fa {
    opacity: 0.8;
  }
`

export const SecondaryButton = styled(Button).attrs({ color: "secondary" })`
  color: ${props => props.theme.secondaryButtonColor};
  background-color: ${props => props.theme.secondaryButtonBackgroundColor};
  border-color: ${props => props.theme.secondaryButtonBorderColor};

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.secondaryButtonHoverColor};
    background-color: ${props => props.theme.secondaryButtonHoverBackgroundColor};
    border-color: ${props => props.theme.secondaryButtonHoverBorderColor};
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.secondaryButtonBorderColor, 0.5)};
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.secondaryButtonHoverColor};
    background-color: ${props => props.theme.secondaryButtonHoverBackgroundColor};
    border-color: ${props => props.theme.secondaryButtonHoverBorderColor};
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.secondaryButtonColor};
    background-color: ${props => props.theme.secondaryButtonBackgroundColor};
    border-color: ${props => props.theme.secondaryButtonBorderColor};
    cursor: default;
  }

  & > .fa {
    opacity: 0.65;
  }
`

export const SecondaryBorderlessButton = styled(Button).attrs({ color: "secondary" })`
  color: ${props => props.theme.secondaryButtonColor};
  background-color: transparent;
  border-color: transparent;

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.secondaryButtonHoverColor};
    background-color: ${props => props.theme.secondaryButtonHoverBackgroundColor};
    border-color: ${props => props.theme.secondaryButtonHoverBorderColor};
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.secondaryButtonBorderColor, 0.5)};
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.secondaryButtonHoverColor};
    background-color: ${props => props.theme.secondaryButtonHoverBackgroundColor};
    border-color: ${props => props.theme.secondaryButtonHoverBorderColor};
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.secondaryButtonColor};
    background-color: transparent;
    border-color: transparent;
    cursor: default;
  }

  & > .fa {
    opacity: 0.65;
  }
`

export const LightButton = styled(Button).attrs({ color: "light" })`
  color: ${props => props.theme.lightButtonColor};
  background-color: ${props => props.theme.lightButtonBackgroundColor};
  border-color: ${props => props.theme.lightButtonBorderColor};

  .input-group && {
    border-color: ${props => props.theme.inputBorderColor};
  }

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.lightButtonHoverColor};
    background-color: ${props => props.theme.lightButtonHoverBackgroundColor};
    border-color: ${props => props.theme.lightButtonHoverBorderColor};
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.lightButtonBorderColor, 0.5)};
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.lightButtonHoverColor};
    background-color: ${props => props.theme.lightButtonHoverBackgroundColor};
    border-color: ${props => props.theme.lightButtonHoverBorderColor};
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.lightButtonColor};
    background-color: ${props => props.theme.lightButtonBackgroundColor};
    border-color: ${props => props.theme.lightButtonBorderColor};
    cursor: default;
  }

  & > .fa {
    opacity: 0.65;
  }
`

export const LightBorderlessButton = styled(Button).attrs({ color: "light" })`
  color: ${props => props.theme.lightButtonColor};
  background-color: transparent;
  border-color: transparent;

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.lightButtonHoverColor};
    background-color: ${props => props.theme.lightButtonHoverBackgroundColor};
    border-color: ${props => props.theme.lightButtonHoverBorderColor};
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.lightButtonBorderColor, 0.5)};
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.lightButtonHoverColor};
    background-color: ${props => props.theme.lightButtonHoverBackgroundColor};
    border-color: ${props => props.theme.lightButtonHoverBorderColor};
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.lightButtonColor};
    background-color: transparent;
    border-color: transparent;
    cursor: default;
  }

  & > .fa {
    opacity: 0.65;
  }
`

export const LinkButton = styled(Button).attrs({ color: "link" })`
  color: ${props => props.theme.linkColor};
  text-align: left;
  margin: 0;
  padding: 0;
  line-height: 1rem;

  &:disabled,
  &.disabled {
    cursor: default;
  }

  & > .fa {
    opacity: 0.65;
  }
`

export const ToggleButton = styled(Button).attrs({ color: "light" })`
  color: ${props => props.theme.lightButtonColor};
  background-color: ${props => props.theme.lightButtonBackgroundColor};
  border-color: ${props => props.theme.lightButtonBorderColor};

  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    color: ${props => props.theme.primaryButtonHoverColor};
    background-color: ${props => props.theme.primaryButtonHoverBackgroundColor};
    border-color: ${props => props.theme.primaryButtonHoverBorderColor};
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.primaryButtonBorderColor, 0.5)};
  }

  &:not(:disabled):hover {
    color: ${props => props.theme.primaryButtonHoverColor};
    background-color: ${props => props.theme.primaryButtonHoverBackgroundColor};
    border-color: ${props => props.theme.primaryButtonHoverBorderColor};
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.primaryButtonColor};
    background-color: ${props => props.theme.primaryButtonBackgroundColor};
    border-color: ${props => props.theme.primaryButtonBorderColor};
  }

  &:disabled:focus,
  &.disabled:focus {
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.lightButtonBorderColor, 0.5)};
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.lightButtonColor};
    background-color: ${props => props.theme.lightButtonBackgroundColor};
    border-color: ${props => props.theme.lightButtonBorderColor};
    cursor: default;
  }

  & > .fa {
    opacity: 0.65;
  }
`

export const TabButton = styled(Button).attrs({ color: "light" })`
  color: ${props => props.theme.lightButtonColor};
  background-color: ${props => props.theme.lightButtonBackgroundColor};
  border-color: ${props => props.theme.lightButtonBorderColor};
  border-radius: 0;

  &:after {
    content: "";
    position: absolute;
    top: auto;
    bottom: -1px;
    left: 0;
    height: 2px;
    width: 100%;
    background: ${props => props.theme.tabButtonColor};
    transition: opacity 0.15s ease;
    opacity: 0;
  }

  &:not(:disabled):not(.disabled):active:after,
  &:not(:disabled):not(.disabled).active:after {
    opacity: 1;
  }

  &:focus,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    color: ${props => props.theme.tabButtonColor};
    background-color: ${props => props.theme.lightButtonBackgroundColor};
    border-color: ${props => props.theme.lightButtonBorderColor};
    box-shadow: none;
  }

  &:not(:disabled):hover {
    color: ${props => props.theme.tabButtonColor};
    background-color: ${props => props.theme.lightButtonBackgroundColor};
    border-color: ${props => props.theme.lightButtonBorderColor};
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.tabButtonColor};
    background-color: ${props => props.theme.lightButtonBackgroundColor};
    border-color: ${props => props.theme.lightButtonBorderColor};
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.lightButtonColor};
    background-color: ${props => props.theme.lightButtonBackgroundColor};
    border-color: ${props => props.theme.lightButtonBorderColor};
    cursor: default;
  }

  & > .fa {
    opacity: 0.65;
  }
`

export const DangerButton = styled(Button).attrs({ color: "danger" })`
  color: ${props => props.theme.dangerButtonColor};
  background-color: ${props => props.theme.dangerButtonBackgroundColor};
  border-color: ${props => props.theme.dangerButtonBackgroundColor};

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.dangerButtonHoverColor};
    background-color: ${props => props.theme.dangerButtonHoverBackgroundColor};
    border-color: ${props => props.theme.dangerButtonHoverBackgroundColor};
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.dangerButtonBackgroundColor, 0.5)};
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.dangerButtonHoverColor};
    background-color: ${props => props.theme.dangerButtonHoverBackgroundColor};
    border-color: ${props => props.theme.dangerButtonHoverBackgroundColor};
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.dangerButtonColor};
    background-color: ${props => props.theme.dangerButtonBackgroundColor};
    border-color: ${props => props.theme.dangerButtonBackgroundColor};
    cursor: default;
  }

  & > .fa {
    opacity: 0.8;
  }
`

export const SecondaryDangerButton = styled(Button).attrs({ color: "danger" })`
  color: ${props => props.theme.secondaryButtonColor};
  background-color: ${props => props.theme.secondaryButtonBackgroundColor};
  border-color: ${props => props.theme.secondaryButtonBorderColor};

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.dangerButtonHoverColor};
    background-color: ${props => props.theme.dangerButtonHoverBackgroundColor};
    border-color: ${props => props.theme.dangerButtonHoverBackgroundColor};
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.dangerButtonBorderColor, 0.5)};
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.dangerButtonHoverColor};
    background-color: ${props => props.theme.dangerButtonHoverBackgroundColor};
    border-color: ${props => props.theme.dangerButtonHoverBackgroundColor};
  }

  &:disabled:focus,
  &.disabled:focus {
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.secondaryButtonBorderColor, 0.5)};
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.secondaryButtonColor};
    background-color: ${props => props.theme.secondaryButtonBackgroundColor};
    border-color: ${props => props.theme.secondaryButtonBorderColor};
    cursor: default;
  }

  & > .fa {
    opacity: 0.65;
  }
`

export const LightDangerButton = styled(Button).attrs({ color: "danger" })`
  color: ${props => props.theme.lightButtonColor};
  background-color: ${props => props.theme.lightButtonBackgroundColor};
  border-color: ${props => props.theme.lightButtonBorderColor};

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.dangerButtonHoverColor};
    background-color: ${props => props.theme.dangerButtonHoverBackgroundColor};
    border-color: ${props => props.theme.dangerButtonHoverBackgroundColor};
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.dangerButtonBorderColor, 0.5)};
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.dangerButtonHoverColor};
    background-color: ${props => props.theme.dangerButtonHoverBackgroundColor};
    border-color: ${props => props.theme.dangerButtonHoverBackgroundColor};
  }

  &:disabled:focus,
  &.disabled:focus {
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.lightButtonBorderColor, 0.5)};
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.lightButtonColor};
    background-color: ${props => props.theme.lightButtonBackgroundColor};
    border-color: ${props => props.theme.lightButtonBorderColor};
    cursor: default;
  }

  & > .fa {
    opacity: 0.65;
  }
`

export const LightDropdownToggle = styled(DropdownToggle)`
  color: ${props => props.theme.lightButtonColor};
  background-color: ${props => props.theme.lightButtonBackgroundColor};
  border-color: ${props => props.theme.lightButtonBorderColor};

  .input-group && {
    border-color: ${props => props.theme.inputBorderColor};
  }

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.lightButtonHoverColor} !important;
    background-color: ${props => props.theme.lightButtonHoverBackgroundColor} !important;
    border-color: ${props => props.theme.lightButtonHoverBorderColor} !important;
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.lightButtonBorderColor, 0.5)} !important;
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &[aria-expanded="true"] {
    color: ${props => props.theme.lightButtonHoverColor} !important;
    background-color: ${props => props.theme.lightButtonHoverBackgroundColor} !important;
    border-color: ${props => props.theme.lightButtonHoverBorderColor} !important;
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.lightButtonColor};
    background-color: ${props => props.theme.lightButtonBackgroundColor};
    border-color: ${props => props.theme.lightButtonBorderColor};
    cursor: default;
  }

  & > .fa,
  &.dropdown-toggle::after {
    opacity: 0.65;
  }
`

export const SecondaryDropdownToggle = styled(DropdownToggle)`
  color: ${props => props.theme.secondaryButtonColor};
  background-color: ${props => props.theme.secondaryButtonBackgroundColor};
  border-color: ${props => props.theme.secondaryButtonBorderColor};

  .input-group && {
    border-color: ${props => props.theme.inputBorderColor};
  }

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.secondaryButtonHoverColor} !important;
    background-color: ${props => props.theme.secondaryButtonHoverBackgroundColor} !important;
    border-color: ${props => props.theme.secondaryButtonHoverBorderColor} !important;
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.secondaryButtonBorderColor, 0.5)} !important;
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &[aria-expanded="true"] {
    color: ${props => props.theme.secondaryButtonHoverColor} !important;
    background-color: ${props => props.theme.secondaryButtonHoverBackgroundColor} !important;
    border-color: ${props => props.theme.secondaryButtonHoverBorderColor} !important;
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.secondaryButtonColor};
    background-color: ${props => props.theme.secondaryButtonBackgroundColor};
    border-color: ${props => props.theme.secondaryButtonBorderColor};
    cursor: default;
  }

  & > .fa,
  &.dropdown-toggle::after {
    opacity: 0.65;
  }
`

export const IconDropdownToggle = styled(DropdownToggle)`
  padding: 2px;
  font-size: 1rem;
  line-height: 1rem;
  color: ${props => props.theme.textColor};
  background-color: transparent;
  border: none;
  border-radius: 0;

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.textColor} !important;
    background-color: transparent !important;
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.secondaryButtonBorderColor, 0.5)} !important;
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &[aria-expanded="true"] {
    color: ${props => props.theme.textColor} !important;
    background-color: transparent !important;
    border: none !important;
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.textColor} !important;
    background-color: transparent !important;
    border: none !important;
    cursor: default;
  }

  & > .fa,
  &.dropdown-toggle::after {
    opacity: 0.65;
  }
`

export const RoundButton = styled(LightButton)`
  border-radius: 50%;
  padding: 0.5rem;
  line-height: 1rem;
  height: 2.15385rem;
  width: 2.15385rem;

  & > * {
    height: 1rem;
    width: 1rem;
  }
`

export const CloseButton = styled(ReactstrapCloseButton)`
  filter: ${props =>
    props.theme.name === "Dark" ? "invert(1) grayscale(100%) brightness(200%)" : undefined};
`

export const InputButton = styled(Button).attrs({ color: "light" })`
  border-left: none !important;
  color: ${props => props.theme.inputGroupButtonColor};
  background-color: ${props => props.theme.inputBackgroundColor};
  border-color: ${props => props.theme.inputBorderColor};
  transition: none;

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.inputGroupButtonHoverColor};
    background-color: ${props => props.theme.inputBackgroundColor};
    border-color: ${props => props.theme.inputBorderColor};
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.inputBorderColor, 0.5)};
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.inputGroupButtonHoverColor};
    background-color: ${props => props.theme.inputBackgroundColor};
    border-color: ${props => props.theme.inputBorderColor};
  }

  &:disabled,
  &.disabled {
    color: ${props => props.theme.textMutedColor};
    background-color: ${props => props.theme.inputDisabledBackgroundColor};
    border-color: ${props => props.theme.inputDisabledBackgroundColor};
    opacity: 1;
    cursor: default;
  }

  & > .fa {
    opacity: 0.65;
  }
`

export const IconButton = styled.button.attrs({
  type: "button",
})`
  display: inline-block;
  margin: 0;
  padding: 0;
  color: ${props => props.theme.textColor};
  font-weight: normal;
  white-space: nowrap;
  user-select: none;
  border: none;
  background: none;

  &:not(:disabled) {
    cursor: pointer;
  }

  & > .fa {
    opacity: 0.65;
  }

  &:not(:disabled):hover > .fa,
  &:not(:disabled):active > .fa {
    opacity: 1;
  }
`

const FileLabel = styled.label`
  margin: 0;

  color: ${props => props.theme.lightButtonColor};
  background-color: ${props => props.theme.lightButtonBackgroundColor};
  border-color: ${props => props.theme.lightButtonBorderColor};

  &:focus,
  &:not(:disabled):not(.disabled):active:focus {
    color: ${props => props.theme.lightButtonHoverColor};
    background-color: ${props => props.theme.lightButtonHoverBackgroundColor};
    border-color: ${props => props.theme.lightButtonHoverBorderColor};
    box-shadow: 0 0 0 0.2rem ${props => rgba(props.theme.lightButtonBorderColor, 0.5)};
  }

  &:not(:disabled):hover,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: ${props => props.theme.lightButtonHoverColor};
    background-color: ${props => props.theme.lightButtonHoverBackgroundColor};
    border-color: ${props => props.theme.lightButtonHoverBorderColor};
  }

  & > .fa {
    opacity: 0.65;
  }
`

const FileInput = styled.input`
  display: none !important;
`

type FileInputButtonProps = {
  accept: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  id?: string
  children: any
  className?: string
}

export class FileInputButton extends React.Component<FileInputButtonProps> {
  render() {
    const { accept, onChange, children, id, className } = this.props
    return (
      <FileLabel tabIndex={0} id={id} className={cn("btn btn-light", className)}>
        <FileInput accept={accept} type="file" onChange={onChange} />
        {children}
      </FileLabel>
    )
  }
}

const optionsButtonPadding = Object.freeze({
  p1: "1px 2px",
  p2: "2px 4px",
  p3: "3px 6px",
})

export const OptionsButton = styled(LightButton)<{ padding?: keyof typeof optionsButtonPadding }>`
  padding: ${props => optionsButtonPadding[props.padding || "p1"]};
  line-height: 1;
`

export const AnalysisOptionsButton = ({ ...props }) => (
  <OptionsButton {...props}>
    <FontAwesome name="cog" /> Configure
  </OptionsButton>
)

export const OptionsBorderlessButton = styled(LightBorderlessButton)`
  padding: 1px 2px;
  line-height: 1;
`

export const AnalysisOptionsBorderlessButton = ({ ...props }) => (
  <OptionsBorderlessButton {...props}>
    <FontAwesome name="cog" />
  </OptionsBorderlessButton>
)
