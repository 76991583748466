import * as React from "react"

export const IconEngine = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      shapeRendering="geometricPrecision"
      height={16}
      width={16}
      {...props}
    >
      <g transform="translate(0.5,0.5)">
        <rect
          x="2"
          y="2"
          width="12"
          height="12"
          rx="1"
          ry="1"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
        />
        <g
          fill="currentColor"
          stroke="none"
          transform="matrix(0.11212805,0,0,-0.10277888,-17.026956,51.128941)"
        >
          <g transform="translate(258.17628,382.51494)">
            <path d="m 0,0 c -2.986,7.722 -5.778,15.076 -8.299,21.856 -2.505,6.742 -5.016,13.212 -7.463,19.231 -2.459,6.052 -4.899,11.91 -7.251,17.418 -2.384,5.58 -4.932,11.216 -7.57,16.754 l -0.368,0.773 h -8.219 l -0.369,-0.775 c -2.639,-5.565 -5.197,-11.206 -7.606,-16.768 -2.442,-5.642 -4.894,-11.503 -7.284,-17.415 -2.406,-5.939 -4.925,-12.403 -7.483,-19.213 -2.541,-6.762 -5.24,-14.056 -8.095,-21.885 l -0.643,-1.767 11.938,3.721 c 0.957,2.646 1.89,5.246 2.773,7.751 1.161,3.304 2.396,6.651 3.678,9.969 l 2.308,6.287 c 2.759,7.432 5.45,14.419 7.996,20.765 2.15,5.368 4.395,10.772 6.689,16.103 2.294,-5.302 4.564,-10.699 6.764,-16.081 2.598,-6.362 5.32,-13.357 8.089,-20.786 l 2.313,-6.257 c 1.1,-3.236 2.251,-6.561 3.458,-9.974 0.887,-2.503 1.843,-5.077 2.826,-7.67 L 0.697,-1.805 Z" />
          </g>
          <g transform="translate(211.90328,388.32354)">
            <path d="M 0,0 C 0.14,0.396 0.292,0.828 0.504,1.432 1.079,3.067 1.669,4.663 2.271,6.22 l 1.107,3.015 c 1.324,3.567 2.603,6.891 3.839,9.973 1.235,3.081 2.506,6.119 3.811,9.113 1.303,-2.98 2.585,-6.013 3.847,-9.099 1.26,-3.086 2.555,-6.415 3.886,-9.987 L 19.875,6.22 C 20.402,4.671 20.953,3.079 21.531,1.445 21.734,0.872 21.877,0.462 22.01,0.091 L 10.998,3.428 Z" />
          </g>
        </g>
      </g>
    </svg>
  )
}
