import * as React from "react"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { connect } from "react-redux"
import { Helmet } from "react-helmet"
import { RouteComponentProps } from "react-router-dom"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { View, ViewContent } from "../common/View"
import Toggle from "../common/Toggle"
import { getThemes, getCurrentTheme, AppDispatch } from "../../store"
import { setTheme } from "../../store/theme"

const NightModeWrapper = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 0.5rem;
  }
`

const NightModeToggle = ({ ...attributes }) => (
  <NightModeWrapper>
    <FontAwesome name="sun-o" size="lg" style={{ verticalAlign: "middle" }} />
    <Toggle {...attributes} />
    <FontAwesome name="moon-o" size="lg" />
  </NightModeWrapper>
)

type AppSettingsProps = RouteComponentProps & {
  dispatch: AppDispatch
  theme: any
  themes: Array<object>
}

class AppSettings extends React.Component<AppSettingsProps> {
  onToggleNightMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.dispatch(setTheme(e.target.checked ? "Dark" : "Light"))
  }

  render() {
    return (
      <View>
        <Helmet title="App Settings" />
        <BreadcrumbItem to={this.props.match.url} title="App Settings" />
        <ViewContent>
          <h4>Night Mode</h4>
          <NightModeToggle
            icons={false}
            checked={this.props.theme.name === "Dark"}
            onChange={this.onToggleNightMode}
          />
        </ViewContent>
      </View>
    )
  }
}

const mapStateToProps = (state: object) => ({
  themes: getThemes(state),
  theme: getCurrentTheme(state),
})

export default connect(mapStateToProps)(AppSettings)
