import * as React from "react"
import { cloneDeep, maxBy, minBy } from "lodash"
import cn from "classnames"
import FontAwesome from "react-fontawesome"
import styled from "styled-components"
import { SortDirection, SortDirectionType, TableCellProps, TableRowProps } from "react-virtualized"
import { useDispatch, useSelector } from "react-redux"
import { CellCheckGroup } from "../common/Form"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import { IconButton, IconDropdownToggle } from "../common/Buttons"
import { InsertNameEntry } from "../InsertNamesModal"
import { OmniTable } from "../common/OmniTable"
import { collator } from "../../utils/sortUtils"
import { formatISODateTime, formatDuration, formatInteger } from "../../utils/formatUtils"
import {
  getMSAFlowsColumns,
  getMSAFlowsFilter,
  getMSAFlowsSortBy,
  getMSAFlowsSortDirection,
  getShowAddressNames,
  getShowPortNames,
  getShowLocalTime,
  isDarkTheme,
} from "../../store"
import { setMSAFlowsColumns, setMSAFlowsSort } from "../../store/ui"
import {
  AddressFilterNode,
  AddressResolverRequestEntry,
  Filter,
  FilterNode,
  MediaSpec,
  MSAProjectFlowEntry,
  MSAProjectFlowInfo,
  MSAProjectProperties,
  PortFilterNode,
} from "../../api/types"

const defaultColumns = [
  {
    dataKey: "checked",
    label: "",
    width: 28,
    flexShrink: 0,
    disableSort: true,
    className: "fullsize",
  },
  {
    dataKey: "flow",
    label: "Flow/Segment",
    width: 200,
    flexGrow: 1,
    visible: true,
  },
  {
    dataKey: "clientAddress",
    label: "Client Addr",
    width: 200,
    flexGrow: 1,
    visible: false,
  },
  {
    dataKey: "clientPort",
    label: "Client Port",
    width: 120,
    flexGrow: 1,
    visible: false,
  },
  {
    dataKey: "serverAddress",
    label: "Server Addr",
    width: 200,
    flexGrow: 1,
    visible: false,
  },
  {
    dataKey: "serverPort",
    label: "Server Port",
    width: 140,
    visible: false,
  },
  {
    dataKey: "protocol",
    label: "Protocol",
    width: 140,
    visible: true,
  },
  {
    dataKey: "application",
    label: "Application",
    width: 140,
    visible: true,
  },
  {
    dataKey: "packets",
    label: "Packets",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "clientPackets",
    label: "Client Packets",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "serverPackets",
    label: "Server Packets",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "packetsAnalyzed",
    label: "Packets Analyzed",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "packetsLost",
    label: "Packets Lost",
    width: 140,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "clientPacketsLost",
    label: "Client Packets Lost",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "serverPacketsLost",
    label: "Server Packets Lost",
    width: 140,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "clientRetransmissions",
    label: "Client Retransmissions",
    width: 160,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "serverRetransmissions",
    label: "Server Retransmissions",
    width: 160,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "startTime",
    label: "Start",
    width: 180,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "endTime",
    label: "Finish",
    width: 180,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "duration",
    label: "Duration",
    width: 120,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "tcpStatus",
    label: "TCP Status",
    width: 120,
    visible: false,
  },
]

const FlowEntryCell = styled.div`
  position: relative;
  display: flex;
`

// The flow entry row has a checkbox and expands/collapses with
// a row for flow from each segment beneath it.
type FlowEntryRowData = {
  index: number
  key: string
  flowEntry: MSAProjectFlowEntry
  flows: MSAProjectFlowInfo[]
}

// The flow info row represents a flow for one segment in the
// flow entry. Flow info may be missing for a segment.
type FlowInfoRowData = {
  segmentIndex: number
  key: string
  flowInfo?: MSAProjectFlowInfo
}

type FlowsTableRowData = FlowEntryRowData | FlowInfoRowData

function isFlowEntryRowData(rowData: FlowsTableRowData) {
  return (rowData as FlowEntryRowData).flowEntry !== undefined
}

function isFlowInfoRowData(rowData: FlowsTableRowData) {
  return (rowData as FlowInfoRowData).segmentIndex !== undefined
}

function keyFromFlowEntry(i: number) {
  return `fe-${i}`
}

export type FlowsTableAction =
  | {
      type: "EXPAND_ALL"
    }
  | {
      type: "COLLAPSE_ALL"
    }

export type FlowsTableProps = {
  project: MSAProjectProperties
  checkedFlowEntry: number
  setCheckedFlowEntryIndex: (flowEntryIndex: number) => void
  openSegmentFile?: (segmentIndex: number) => void
  makeFilter: (filter: Filter) => void
  insertIntoNameTable: (insertNameEntries: InsertNameEntry[]) => void
  resolveNames: (entries: AddressResolverRequestEntry[]) => void
  action?: FlowsTableAction
}

const FlowsTable = ({
  project,
  checkedFlowEntry,
  setCheckedFlowEntryIndex,
  openSegmentFile,
  makeFilter,
  insertIntoNameTable,
  resolveNames,
  action,
}: FlowsTableProps) => {
  const dispatch = useDispatch()
  const showAddressNames: boolean = useSelector(getShowAddressNames)
  const showPortNames: boolean = useSelector(getShowPortNames)
  const showLocalTime: boolean = useSelector(getShowLocalTime)
  const isDark = useSelector(isDarkTheme)
  const viewFilter: string = useSelector(getMSAFlowsFilter) || ""
  const columns = useSelector(getMSAFlowsColumns) || defaultColumns
  const sortBy: string = useSelector(getMSAFlowsSortBy) || "flow"
  const sortDirection: SortDirectionType =
    useSelector(getMSAFlowsSortDirection) || SortDirection.ASC
  const [collapsedFlowEntries, setCollapsedFlowEntries] = React.useState<Set<string>>(
    new Set<string>()
  )

  React.useEffect(() => {
    if (action) {
      switch (action.type) {
        case "EXPAND_ALL":
          setCollapsedFlowEntries(new Set<string>())
          break
        case "COLLAPSE_ALL":
          if (project.flowEntries) {
            const newCollapsedFlowEntries = new Set<string>()
            for (let i = 0, n = project.flowEntries.length; i < n; i++) {
              newCollapsedFlowEntries.add(keyFromFlowEntry(i))
            }
            setCollapsedFlowEntries(newCollapsedFlowEntries)
          }
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action])

  const formatFlowEntry = (flowEntry: MSAProjectFlowEntry) => {
    const clientAddress =
      (showAddressNames && flowEntry.clientAddressName) || flowEntry.clientAddress
    const clientPort = (showPortNames && flowEntry.clientPortName) || flowEntry.clientPort
    const serverAddress =
      (showAddressNames && flowEntry.serverAddressName) || flowEntry.serverAddress
    const serverPort = (showPortNames && flowEntry.serverPortName) || flowEntry.serverPort
    // TODO: format IPv6 addresses differently
    return `${clientAddress}:${clientPort} \u2194 ${serverAddress}:${serverPort}`
  }

  const renderFlowEntry = (flowEntry: MSAProjectFlowEntry) => {
    const clientAddress =
      (showAddressNames && flowEntry.clientAddressName) || flowEntry.clientAddress
    const clientAddressColor = isDark ? undefined : flowEntry.clientAddressColor
    const clientPort = (showPortNames && flowEntry.clientPortName) || flowEntry.clientPort
    const clientPortColor = isDark ? undefined : flowEntry.clientPortColor
    const serverAddress =
      (showAddressNames && flowEntry.serverAddressName) || flowEntry.serverAddress
    const serverAddressColor = isDark ? undefined : flowEntry.serverAddressColor
    const serverPort = (showPortNames && flowEntry.serverPortName) || flowEntry.serverPort
    const serverPortColor = isDark ? undefined : flowEntry.serverPortColor
    // TODO: format IPv6 addresses differently
    return (
      <>
        <span style={{ color: clientAddressColor }}>{clientAddress}</span>
        {":"}
        <span style={{ color: clientPortColor }}>{clientPort}</span>
        {" \u2194 "}
        <span style={{ color: serverAddressColor }}>{serverAddress}</span>
        {":"}
        <span style={{ color: serverPortColor }}>{serverPort}</span>
      </>
    )
  }

  const matchFlowEntry = (filter: string, flowEntry: MSAProjectFlowEntry) => {
    // Try the flow entry text first.
    if (formatFlowEntry(flowEntry).toLowerCase().indexOf(filter) !== -1) return true

    // Try protocol and application for each segment.
    for (let i = 0, n = flowEntry.flowList.length; i < n; i++) {
      if (project.segmentInfo && i < project.segmentInfo.length) {
        const flowInfo = project.segmentInfo[i].flows.find(
          flowInfo => flowInfo.id === flowEntry.flowList[i]
        )
        if (flowInfo) {
          if (
            flowInfo.protocol?.toLowerCase().indexOf(filter) !== -1 ||
            flowInfo.application?.toLowerCase().indexOf(filter) !== -1
          ) {
            return true
          }
        }
      }
    }

    return false
  }

  const getFlowEntries = (): FlowEntryRowData[] | undefined => {
    let viewFlowEntries: FlowEntryRowData[] | undefined = undefined
    if (project.flowEntries) {
      viewFlowEntries = []
      const lowerCaseFilter = viewFilter.toLowerCase()
      for (let i = 0, n = project.flowEntries.length; i < n; i++) {
        const flowEntry = project.flowEntries[i]
        if (lowerCaseFilter.length === 0 || matchFlowEntry(lowerCaseFilter, flowEntry)) {
          const flows: MSAProjectFlowInfo[] = []
          flowEntry.flowList.forEach((flowId, segmentIndex) => {
            if (project.segmentInfo && segmentIndex < project.segmentInfo.length) {
              const flowInfo = project.segmentInfo[segmentIndex].flows.find(
                flowInfo => flowInfo.id === flowId
              )
              if (flowInfo) {
                flows.push(flowInfo)
              }
            }
          })
          viewFlowEntries.push({
            index: i,
            key: keyFromFlowEntry(i),
            flowEntry,
            flows,
          })
        }
      }
    }
    return viewFlowEntries
  }

  const viewFlowEntries = getFlowEntries()

  const onMakeFilter = (rowData: FlowsTableRowData) => {
    let address1: string | undefined
    let address1MediaSpec: MediaSpec | undefined
    let address2: string | undefined
    let address2MediaSpec: MediaSpec | undefined
    let port1: string | undefined
    let port1MediaSpec: MediaSpec | undefined
    let port2: string | undefined
    let port2MediaSpec: MediaSpec | undefined
    if (isFlowEntryRowData(rowData)) {
      const data = rowData as FlowEntryRowData
      address1 = data.flowEntry.clientAddress
      address1MediaSpec = data.flowEntry.clientAddressMediaSpec
      address2 = data.flowEntry.serverAddress
      address2MediaSpec = data.flowEntry.serverAddressMediaSpec
      port1 = data.flowEntry.clientPort.toString()
      port1MediaSpec = data.flowEntry.clientPortMediaSpec
      port2 = data.flowEntry.serverPort.toString()
      port2MediaSpec = data.flowEntry.serverPortMediaSpec
    } else {
      const data = rowData as FlowInfoRowData
      if (data.flowInfo) {
        address1 = data.flowInfo.clientAddress
        address1MediaSpec = data.flowInfo.clientAddressMediaSpec
        address2 = data.flowInfo.serverAddress
        address2MediaSpec = data.flowInfo.serverAddressMediaSpec
        port1 = data.flowInfo.clientPort.toString()
        port1MediaSpec = data.flowInfo.clientPortMediaSpec
        port2 = data.flowInfo.serverPort.toString()
        port2MediaSpec = data.flowInfo.serverPortMediaSpec
      }
    }

    let addressFilterNode: AddressFilterNode | undefined
    if (
      address1 &&
      address1MediaSpec &&
      address1MediaSpec.type &&
      address2 &&
      address2MediaSpec &&
      address2MediaSpec.type
    ) {
      addressFilterNode = {
        accept1To2: true,
        accept2To1: true,
        address1: address1,
        address2: address2,
        clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
        comment: "",
        inverted: false,
        type: address1MediaSpec.type,
      }
    }

    let portFilterNode: PortFilterNode | undefined
    if (
      port1 &&
      port1MediaSpec &&
      port1MediaSpec.type &&
      port2 &&
      port2MediaSpec &&
      port2MediaSpec.type
    ) {
      portFilterNode = {
        accept1To2: true,
        accept2To1: true,
        clsid: "B3279AE9-91E1-4D0A-8ABD-6D1BDC5471A9",
        comment: "",
        inverted: false,
        port1: port1,
        port2: port2,
        type: port1MediaSpec.type,
      }
    }

    let rootFilterNode: FilterNode | undefined
    if (addressFilterNode !== undefined) {
      rootFilterNode = addressFilterNode
    }
    if (portFilterNode) {
      if (addressFilterNode) {
        addressFilterNode.andNode = portFilterNode
      } else {
        rootFilterNode = portFilterNode
      }
    }

    const filter: Filter = {
      clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
      color: "#000000",
      comment: "",
      created: "",
      group: "",
      id: "",
      modified: "",
      name: "Untitled",
      rootNode: rootFilterNode,
    }
    makeFilter(filter)
  }

  const onInsertIntoNameTable = (rowData: FlowsTableRowData) => {
    let address1: string | undefined
    let address1MediaSpec: MediaSpec | undefined
    let address2: string | undefined
    let address2MediaSpec: MediaSpec | undefined
    if (isFlowEntryRowData(rowData)) {
      const data = rowData as FlowEntryRowData
      address1 = data.flowEntry.clientAddress
      address1MediaSpec = data.flowEntry.clientAddressMediaSpec
      address2 = data.flowEntry.serverAddress
      address2MediaSpec = data.flowEntry.serverAddressMediaSpec
    } else {
      const data = rowData as FlowInfoRowData
      if (data.flowInfo) {
        address1 = data.flowInfo.clientAddress
        address1MediaSpec = data.flowInfo.clientAddressMediaSpec
        address2 = data.flowInfo.serverAddress
        address2MediaSpec = data.flowInfo.serverAddressMediaSpec
      }
    }

    const insertNameEntries: InsertNameEntry[] = []
    if (address1 && address1MediaSpec) {
      insertNameEntries.push({
        title: "Client Address",
        entry: address1,
        entryType: address1MediaSpec.type,
      })
    }
    if (address2 && address2MediaSpec) {
      insertNameEntries.push({
        title: "Server Address",
        entry: address2,
        entryType: address2MediaSpec.type,
      })
    }
    if (insertNameEntries.length > 0) {
      insertIntoNameTable(insertNameEntries)
    }
  }

  const onResolveNames = (rowData: FlowsTableRowData) => {
    let address1: string | undefined
    let address1MediaSpec: MediaSpec | undefined
    let address2: string | undefined
    let address2MediaSpec: MediaSpec | undefined
    if (isFlowEntryRowData(rowData)) {
      const data = rowData as FlowEntryRowData
      address1 = data.flowEntry.clientAddress
      address1MediaSpec = data.flowEntry.clientAddressMediaSpec
      address2 = data.flowEntry.serverAddress
      address2MediaSpec = data.flowEntry.serverAddressMediaSpec
    } else {
      const data = rowData as FlowInfoRowData
      if (data.flowInfo) {
        address1 = data.flowInfo.clientAddress
        address1MediaSpec = data.flowInfo.clientAddressMediaSpec
        address2 = data.flowInfo.serverAddress
        address2MediaSpec = data.flowInfo.serverAddressMediaSpec
      }
    }

    const entries: AddressResolverRequestEntry[] = []
    try {
      if (address1 && address1MediaSpec) {
        entries.push({
          entry: address1,
          entryType: address1MediaSpec.type,
        })
      }
      if (address2 && address2MediaSpec) {
        entries.push({
          entry: address2,
          entryType: address2MediaSpec.type,
        })
      }
    } catch (e) {
      console.error(e)
    }
    if (entries.length > 0) {
      resolveNames(entries)
    }
  }

  const onExpandCollapse = (key: string) => {
    const newCollapsedGroups = new Set(collapsedFlowEntries)
    if (newCollapsedGroups.has(key)) {
      newCollapsedGroups.delete(key)
    } else {
      newCollapsedGroups.add(key)
    }
    setCollapsedFlowEntries(newCollapsedGroups)
  }

  const onShowDefaultColumns = () => {
    dispatch(setMSAFlowsColumns(defaultColumns))
  }

  const onShowAllColumns = () => {
    const newColumns = cloneDeep(columns)
    newColumns.forEach((col: any) => {
      col.visible = true
    })
    dispatch(setMSAFlowsColumns(newColumns))
  }

  const onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColumns = cloneDeep(columns)
    const col = newColumns.find((col: any) => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      dispatch(setMSAFlowsColumns(newColumns))
    }
  }

  const onSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
  }) => {
    dispatch(setMSAFlowsSort(sortBy, sortDirection))
  }

  const rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    if (isFlowEntryRowData(rowData)) {
      const data = rowData as FlowEntryRowData
      key = data.key
      className = cn(className, "group")
      if (Array.isArray(columns) && columns.length > 0) {
        columns = columns.slice(0, 2) // Only need the first 2 columns
      }
    } else {
      const data = rowData as FlowInfoRowData
      key = data.key
      if (data.segmentIndex % 2 !== 0) {
        className = cn(className, "stripe")
      }
    }

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  const rowClassName = () => {
    // Stripes applied in rowRenderer
    return ""
  }

  const cellRenderer = ({ dataKey, rowData }: TableCellProps) => {
    let content
    if (isFlowEntryRowData(rowData)) {
      const data: FlowEntryRowData = rowData

      switch (dataKey) {
        case "checked":
          content = (
            <CellCheckGroup
              type="checkbox"
              checked={data.index === checkedFlowEntry}
              id={data.key}
              onChange={() => {
                setCheckedFlowEntryIndex(data.index)
              }}
            />
          )
          break
        case "flow":
          {
            const collapsed = collapsedFlowEntries.has(data.key)
            const icon = collapsed ? "chevron-right" : "chevron-down"
            content = (
              <FlowEntryCell>
                <IconButton
                  aria-label="Expand/Collapse"
                  onClick={event => {
                    event.stopPropagation()
                    onExpandCollapse(data.key)
                  }}
                  style={{ width: "100%", textAlign: "left" }}
                >
                  <FontAwesome name={icon} fixedWidth />
                  <span style={{ marginLeft: ".25rem" }}>{renderFlowEntry(data.flowEntry)}</span>
                </IconButton>
              </FlowEntryCell>
            )
          }
          break
      }
    } else {
      const data: FlowInfoRowData = rowData
      const { segmentIndex, flowInfo } = data
      if (dataKey === "flow") {
        content = <div style={{ textAlign: "right" }}>{project.segments[segmentIndex].name}</div>
      } else if (flowInfo) {
        switch (dataKey) {
          case "clientAddress":
            content = (showAddressNames && flowInfo.clientAddressName) || flowInfo.clientAddress
            if (!isDark && flowInfo.clientAddressColor) {
              content = (
                <span title={content} style={{ color: flowInfo.clientAddressColor }}>
                  {content}
                </span>
              )
            }
            break
          case "clientPort":
            content = (showPortNames && flowInfo.clientPortName) || flowInfo.clientPort
            if (!isDark && flowInfo.clientPortColor) {
              content = (
                <span title={content} style={{ color: flowInfo.clientPortColor }}>
                  {content}
                </span>
              )
            }
            break
          case "serverAddress":
            content = (showAddressNames && flowInfo.serverAddressName) || flowInfo.serverAddress
            if (!isDark && flowInfo.serverAddressColor) {
              content = (
                <span title={content} style={{ color: flowInfo.serverAddressColor }}>
                  {content}
                </span>
              )
            }
            break
          case "serverPort":
            content = (showPortNames && flowInfo.serverPortName) || flowInfo.serverPort
            if (!isDark && flowInfo.serverPortColor) {
              content = (
                <span title={content} style={{ color: flowInfo.serverPortColor }}>
                  {content}
                </span>
              )
            }
            break
          case "protocol":
            content = flowInfo.protocol
            if (!isDark && flowInfo.protocolColor) {
              content = (
                <span title={content} style={{ color: flowInfo.protocolColor }}>
                  {content}
                </span>
              )
            }
            break
          case "application":
            content = flowInfo.application
            if (!isDark && flowInfo.applicationColor) {
              content = (
                <span title={content} style={{ color: flowInfo.applicationColor }}>
                  {content}
                </span>
              )
            }
            break
          case "packets":
            content = formatInteger(flowInfo.packets)
            break
          case "clientPackets":
            content = formatInteger(flowInfo.clientPackets)
            break
          case "serverPackets":
            content = formatInteger(flowInfo.serverPackets)
            break
          case "packetsAnalyzed":
            content = formatInteger(flowInfo.packetInfo.length)
            break
          case "packetsLost":
            content = formatInteger(flowInfo.nextLostPackets + flowInfo.prevLostPackets)
            break
          case "clientPacketsLost":
            content = formatInteger(flowInfo.nextLostPackets)
            break
          case "serverPacketsLost":
            content = formatInteger(flowInfo.prevLostPackets)
            break
          case "clientRetransmissions":
            if (flowInfo.ipProtocol === 6) {
              content = formatInteger(flowInfo.clientRetransmissions)
            }
            break
          case "serverRetransmissions":
            if (flowInfo.ipProtocol === 6) {
              content = formatInteger(flowInfo.serverRetransmissions)
            }
            break
          case "startTime":
            content = formatISODateTime(flowInfo.startTime, 6, showLocalTime)
            break
          case "endTime":
            content = formatISODateTime(flowInfo.endTime, 6, showLocalTime)
            break
          case "duration":
            content = formatDuration(flowInfo.duration, 6)
            break
          case "tcpStatus":
            content = flowInfo.tcpStatus
            break
          default:
            break
        }
      }
    }
    return content
  }

  const commandCellRenderer = ({ rowData }: TableCellProps) => {
    let segmentIndex = -1
    if (isFlowInfoRowData(rowData)) {
      segmentIndex = (rowData as FlowInfoRowData).segmentIndex
    }

    const hasAddresses =
      isFlowEntryRowData(rowData) || (rowData as FlowInfoRowData).flowInfo != null

    return (
      <div className="commands" style={{ display: "flex" }}>
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            {openSegmentFile && segmentIndex !== -1 ? (
              <>
                <DropdownItem
                  onClick={() => {
                    openSegmentFile(segmentIndex)
                  }}
                >
                  Open File &ldquo;{project.segments[segmentIndex].file}&rdquo;
                </DropdownItem>
                <DropdownItem divider />
              </>
            ) : null}
            <DropdownItem onClick={onMakeFilter.bind(this, rowData)} disabled={!hasAddresses}>
              Make Filter
            </DropdownItem>
            <DropdownItem
              onClick={onInsertIntoNameTable.bind(this, rowData)}
              disabled={!hasAddresses}
            >
              Insert Into Name Table
            </DropdownItem>
            <DropdownItem onClick={onResolveNames.bind(this, rowData)} disabled={!hasAddresses}>
              Resolve Names
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </div>
    )
  }

  const getFlattenedTree = () => {
    if (viewFlowEntries) {
      viewFlowEntries.sort((a, b) => {
        let result = 0
        const sortOrder = [sortBy, "startTime"]
        for (let i = 0; i < sortOrder.length && result === 0; i++) {
          const sortField = sortOrder[i]
          switch (sortField) {
            case "flow":
              result = collator.compare(formatFlowEntry(a.flowEntry), formatFlowEntry(b.flowEntry))
              break
            case "clientAddress":
            case "serverAddress":
              result = collator.compare(a.flowEntry[sortField], b.flowEntry[sortField])
              break
            case "clientPort":
            case "serverPort":
              {
                const aValue = a.flowEntry[sortField]
                const bValue = b.flowEntry[sortField]
                if (aValue > bValue) {
                  result = 1
                } else if (aValue < bValue) {
                  result = -1
                }
              }
              break
            case "protocol":
            case "tcpStatus":
              {
                let aValue = ""
                a.flows.forEach(flowInfo => {
                  if (collator.compare(aValue, flowInfo[sortField]) > 0) {
                    aValue = flowInfo[sortField]
                  }
                })
                let bValue = ""
                b.flows.forEach(flowInfo => {
                  if (collator.compare(bValue, flowInfo[sortField]) > 0) {
                    bValue = flowInfo[sortField]
                  }
                })
                result = collator.compare(aValue, bValue)
              }
              break
            case "packetsAnalyzed":
              {
                let aValue = 0
                a.flows.forEach(flowInfo => {
                  aValue = Math.max(aValue, flowInfo.packetInfo.length)
                })
                let bValue = 0
                b.flows.forEach(flowInfo => {
                  bValue = Math.max(bValue, flowInfo.packetInfo.length)
                })
                if (aValue > bValue) {
                  result = 1
                } else if (aValue < bValue) {
                  result = -1
                }
              }
              break
            case "clientPacketsLost":
              {
                let aValue = 0
                a.flows.forEach(flowInfo => {
                  aValue = Math.max(aValue, flowInfo.nextLostPackets)
                })
                let bValue = 0
                b.flows.forEach(flowInfo => {
                  bValue = Math.max(bValue, flowInfo.nextLostPackets)
                })
                if (aValue > bValue) {
                  result = 1
                } else if (aValue < bValue) {
                  result = -1
                }
              }
              break
            case "serverPacketsLost":
              {
                let aValue = 0
                a.flows.forEach(flowInfo => {
                  aValue = Math.max(aValue, flowInfo.prevLostPackets)
                })
                let bValue = 0
                b.flows.forEach(flowInfo => {
                  bValue = Math.max(bValue, flowInfo.prevLostPackets)
                })
                if (aValue > bValue) {
                  result = 1
                } else if (aValue < bValue) {
                  result = -1
                }
              }
              break
            case "packetsLost":
              {
                let aValue = 0
                a.flows.forEach(flowInfo => {
                  aValue = Math.max(aValue, flowInfo.nextLostPackets + flowInfo.prevLostPackets)
                })
                let bValue = 0
                b.flows.forEach(flowInfo => {
                  bValue = Math.max(bValue, flowInfo.nextLostPackets + flowInfo.prevLostPackets)
                })
                if (aValue > bValue) {
                  result = 1
                } else if (aValue < bValue) {
                  result = -1
                }
              }
              break
            case "startTime":
              {
                const aValueObj = minBy(a.flows, sortField)
                const bValueObj = minBy(b.flows, sortField)
                if (aValueObj !== undefined && bValueObj !== undefined) {
                  const aValue = aValueObj[sortField]
                  const bValue = bValueObj[sortField]
                  if (aValue > bValue) {
                    result = 1
                  } else if (aValue < bValue) {
                    result = -1
                  }
                }
              }
              break
            case "packets":
            case "clientPackets":
            case "serverPackets":
            case "endTime":
            case "duration":
              {
                const aValueObj = maxBy(a.flows, sortField)
                const bValueObj = maxBy(b.flows, sortField)
                if (aValueObj !== undefined && bValueObj !== undefined) {
                  const aValue = aValueObj[sortField]
                  const bValue = bValueObj[sortField]
                  if (aValue > bValue) {
                    result = 1
                  } else if (aValue < bValue) {
                    result = -1
                  }
                }
              }
              break
            case "clientRetransmissions":
            case "serverRetransmissions":
              {
                if (a.flowEntry.ipProtocol !== 6) {
                  result = 1
                } else if (b.flowEntry.ipProtocol !== 6) {
                  result = -1
                } else {
                  const aValueObj = maxBy(a.flows, sortField)
                  const bValueObj = maxBy(b.flows, sortField)
                  if (aValueObj !== undefined && bValueObj !== undefined) {
                    const aValue = aValueObj[sortField]
                    const bValue = bValueObj[sortField]
                    if (aValue > bValue) {
                      result = 1
                    } else if (aValue < bValue) {
                      result = -1
                    }
                  }
                }
              }
              break
          }
        }

        if (sortDirection === SortDirection.DESC) result = -result
        return result
      })

      // Note: children do not get sorted.

      // Flatten the list into rows.
      const rows: FlowsTableRowData[] = []
      viewFlowEntries.forEach(flowEntry => {
        rows.push(flowEntry)
        const collapsed = collapsedFlowEntries.has(flowEntry.key)
        if (!collapsed) {
          flowEntry.flowEntry.flowList.forEach((flowId, segmentIndex) => {
            if (project.segmentInfo && segmentIndex < project.segmentInfo.length) {
              const flowInfo = project.segmentInfo[segmentIndex].flows.find(
                flowInfo => flowInfo.id === flowId
              )
              const key = flowEntry.key + "-" + segmentIndex.toString()
              rows.push({ segmentIndex, key, flowInfo })
            }
          })
        }
      })

      return rows
    }
    return []
  }

  const flattenedTree = getFlattenedTree()

  return (
    <OmniTable
      data={flattenedTree}
      rowCount={flattenedTree.length}
      columnDesc={columns}
      rowRenderer={rowRenderer}
      rowClassName={rowClassName}
      cellRenderer={cellRenderer}
      renderCommands={commandCellRenderer}
      onShowDefaultColumns={onShowDefaultColumns}
      onShowAllColumns={onShowAllColumns}
      onToggleColumn={onToggleColumn}
      sort={onSort}
      sortBy={sortBy}
      sortDirection={sortDirection}
    />
  )
}

export default FlowsTable
