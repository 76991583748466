import * as React from "react"
import { connect } from "react-redux"
import { debounce } from "lodash"
import { v4 as uuid } from "uuid"
import BaseFilterBar from "../FilterBar"
import {
  setPacketsFilterBarExpression,
  setPacketsFilterBarError,
  pushRecentFilterBarExpression,
} from "../../store/ui"
import { setSelectPacketsTask } from "../../store/selectPackets"
import {
  getEngine,
  getAuthToken,
  getCapabilities,
  getPacketsFilterBarExpression,
  getPacketsFilterBarError,
  getRecentFilterBarExpressions,
} from "../../store"
import { postFilterConvert, postSelectRelatedFilterStart } from "../../api/api"
import { ResponseGetEngineCapabilities } from "../../api/types"

type FilterBarProps = {
  engine: string
  authToken: string
  capId: string
  engineCapabilities: ResponseGetEngineCapabilities | null
  filterBarExpression: string
  filterBarError: string
  recentFilterBarExpressions: string[]
  setPacketsFilterBarExpression: (expression: string) => void
  setPacketsFilterBarError: (error: string) => void
  pushRecentFilterBarExpression: (expression: string) => void
  setSelectPacketsTask: (task: any) => void
}

class FilterBar extends React.Component<FilterBarProps> {
  componentDidUpdate({ filterBarExpression }: FilterBarProps) {
    if (this.props.filterBarExpression !== filterBarExpression) {
      this.validateFilterDebounced()
    }
  }

  onApplyFilter = () => {
    const { engine, authToken, filterBarExpression } = this.props
    postFilterConvert(engine, authToken, filterBarExpression)
      .then(response => {
        if (!response.valid) {
          let errorMessage = ""
          if (Array.isArray(response.errorsParsing) && response.errorsParsing.length > 0) {
            errorMessage = `Character ${response.errorsParsing[0].position}: ${response.errorsParsing[0].message}`
          } else if (response.errorConversion) {
            errorMessage = response.errorConversion
          } else {
            errorMessage = "Error in filter expression"
          }
          this.props.setPacketsFilterBarError(errorMessage)
        } else if (response.filter && response.filter.rootNode) {
          this.props.setPacketsFilterBarError("")
          this.props.pushRecentFilterBarExpression(filterBarExpression)
          const { capId } = this.props
          const { engine, authToken } = this.props
          postSelectRelatedFilterStart(engine, authToken, capId, {
            id: uuid(),
            rootNode: response.filter.rootNode,
          })
            .then(task => {
              if (task.taskId) {
                this.props.setSelectPacketsTask({
                  type: "filter",
                  taskId: task.taskId,
                  progress: 0,
                })
              }
            })
            .catch(error => {
              console.error(error)
            })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  validateFilter = () => {
    const { engine, authToken, filterBarExpression } = this.props
    postFilterConvert(engine, authToken, filterBarExpression, true)
      .then(response => {
        if (!response.valid) {
          let errorMessage = ""
          if (Array.isArray(response.errorsParsing) && response.errorsParsing.length > 0) {
            errorMessage = `Character ${response.errorsParsing[0].position}: ${response.errorsParsing[0].message}`
          } else if (response.errorConversion) {
            errorMessage = response.errorConversion
          } else {
            errorMessage = "Error in filter expression"
          }
          this.props.setPacketsFilterBarError(errorMessage)
        } else {
          this.props.setPacketsFilterBarError("")
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  validateFilterDebounced = debounce(this.validateFilter, 500)

  render() {
    const { engineCapabilities, filterBarExpression, filterBarError, recentFilterBarExpressions } =
      this.props
    return (
      <BaseFilterBar
        engineCapabilities={engineCapabilities}
        filterBarExpression={filterBarExpression}
        filterBarError={filterBarError}
        recentFilterBarExpressions={recentFilterBarExpressions}
        onChange={this.props.setPacketsFilterBarExpression}
        onApply={this.onApplyFilter}
      />
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  engineCapabilities: getCapabilities(state),
  filterBarExpression: getPacketsFilterBarExpression(state) || "",
  filterBarError: getPacketsFilterBarError(state) || "",
  recentFilterBarExpressions: getRecentFilterBarExpressions(state),
})

const mapDisptachToProps = {
  setPacketsFilterBarExpression,
  setPacketsFilterBarError,
  pushRecentFilterBarExpression,
  setSelectPacketsTask,
}

export default connect(mapStateToProps, mapDisptachToProps)(FilterBar)
