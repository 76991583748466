import * as React from "react"
import { connect, useSelector } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { cloneDeep } from "lodash"
import styled, { useTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import FileSaver from "file-saver"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { IconInformational, IconMinor, IconMajor, IconSevere } from "../common/Icons"
import {
  View,
  ViewAlert,
  ViewAlertContent,
  ViewContent,
  ViewHeader,
  ViewHeaderButtons,
  ViewHeaderTitle,
  ViewHeaderPanel,
} from "../common/View"
import { LightButton, IconDropdownToggle, CloseButton } from "../common/Buttons"
import { CenterContent } from "../common/Layout"
import { Spinner } from "../common/Spinner"
import { DropdownMenu, DropdownItem, UncontrolledDropdownWithPortal } from "../common/Dropdown"
import PropTable from "../common/PropTable"
import { UncontrolledAlert } from "../common/UncontrolledAlert"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import { InsertNamesModal, InsertNameEntry } from "../InsertNamesModal"
import { Table } from "../common/Table"
import {
  Sidebar,
  SidebarBody,
  SidebarHeader,
  SidebarTitle,
  SidebarContent,
} from "../common/Sidebar"
import { ExpertTable, ExpertTableColumn } from "../common/ExpertTable"
import ExpertSettingsModal, {
  eventFinderViews,
  expertResponseToExpertDescriptions,
  expertResponseToExpertLayers,
  expertResponseToExpertSettings,
  ExpertSettingsEx,
  expertSettingsToExpertRequests,
  queryEventFinder,
} from "../ExpertSettingsModal"
import { mediaSpecFromProtoSpec } from "../../utils/mediaSpec"
import { formatDateTime } from "../../utils/formatUtils"
import { formatMediaSpec } from "../../utils/mediaSpec"
import {
  addExpertColumns,
  exportExpertToCSV,
  formatExpertValue,
  formatVoIPHeaderCountsProp,
  getExpertColumnName,
  getExpertValueFromRowData,
  getVoIPHeaderCounts,
} from "../../utils/expertUtils"
import { getCaptureForensicSearchUrl, getEngineNewFilterUrl } from "../../routes"
import {
  getEngine,
  getAuthToken,
  getNamesModificationTime,
  getShowAddressNames,
  getShowPortNames,
  getVoIPMediaColumns,
  getVoIPMediaSortBy,
  getVoIPMediaSortDirection,
  getCapabilities,
  getUserId,
  getShowLocalTime,
} from "../../store"
import { updateStatus } from "../../store/status"
import { setVoIPMediaColumns, setVoIPMediaSort } from "../../store/ui"
import { setSelectPacketsTask } from "../../store/selectPackets"
import {
  expertExecuteCFS,
  expertQueryCFS,
  postSelectRelatedExpertStart,
  resolveAddresses,
} from "../../api/api"
import {
  AddressResolverRequestEntry,
  ExpertDescription,
  ExpertLayerMapping,
  ExpertQueryResponse,
  ExpertSettings,
  ExpertValue,
  Filter,
  MediaSpec,
  RequestExpertExecute,
  RequestExpertQuery,
  RequestPostSelectRelatedExpertStart,
  ResponsePostSelectRelatedExpertStart,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { ExpertColumn, ExpertView } from "../../api/types/expertTypes"
import { FilterNode, AddressFilterNode, PortFilterNode, ProtocolFilterNode } from "../../api/types"
import { MediaSpecType } from "../../api/types/mediaTypes"

const defaultColumns: ExpertTableColumn[] = [
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CALL_ID.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CALL_ID),
    width: 104,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_FLOW_INDEX.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_FLOW_INDEX),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SSRC.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SSRC),
    width: 80,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_NAME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_NAME),
    width: 120,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_STREAM_ID.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_STREAM_ID),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_FROM.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_FROM),
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_TO.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_TO),
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_GATEWAY_ASSIGNED_CALL_ID.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_GATEWAY_ASSIGNED_CALL_ID),
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_ASSERTED_IDENTITY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_ASSERTED_IDENTITY),
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_END_CAUSE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_END_CAUSE),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SIGNALING_TYPE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SIGNALING_TYPE),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_PROTOCOL.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_PROTOCOL),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CODEC.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CODEC),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_BIT_RATE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_BIT_RATE),
    width: 80,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MEDIA_TYPE.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MEDIA_TYPE),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DSCP.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DSCP),
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CALLER_ADDRESS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CALLER_ADDRESS),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CALLER_PORT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CALLER_PORT),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CALLEE_ADDRESS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CALLEE_ADDRESS),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_CALLEE_PORT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_CALLEE_PORT),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_GATEKEEPER_ADDRESS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_GATEKEEPER_ADDRESS),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_GATEKEEPER_PORT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_GATEKEEPER_PORT),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SOURCE_PORT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SOURCE_PORT),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS),
    width: 150,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DEST_PORT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DEST_PORT),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MEDIA_PACKET_COUNT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MEDIA_PACKET_COUNT),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_SETUP_TIME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_SETUP_TIME),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_PDD.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_PDD),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_ONE_WAY_DELAY.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_ONE_WAY_DELAY),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_START_TIME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_START_TIME),
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_END_TIME.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_END_TIME),
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_DURATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_DURATION),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_JITTER.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_JITTER),
    width: 70,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_PACKET_LOSS_PERCENT.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_PACKET_LOSS_PERCENT),
    width: 110,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MOS_LQ.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MOS_LQ),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MOS_CQ.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MOS_CQ),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MOS_NOM.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MOS_NOM),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MOS_AV.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MOS_AV),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_MOS_V.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_MOS_V),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_R_FACTOR_LISTENING.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_R_FACTOR_LISTENING),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_R_FACTOR_CONVERSATIONAL.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_R_FACTOR_CONVERSATIONAL),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_R_FACTOR_G107.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_R_FACTOR_G107),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_R_FACTOR_NOMINAL.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_R_FACTOR_NOMINAL),
    width: 120,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VSTQ.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VSTQ),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VOICE_LOSS_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VOICE_LOSS_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VOICE_DISCARD_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VOICE_DISCARD_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VOICE_CODEC_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VOICE_CODEC_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VOICE_DELAY_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VOICE_DELAY_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VOICE_SIGNAL_LEVEL_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VOICE_SIGNAL_LEVEL_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VOICE_NOISE_LEVEL_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VOICE_NOISE_LEVEL_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VOICE_ECHO_LEVEL_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VOICE_ECHO_LEVEL_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VOICE_RECENCY_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VOICE_RECENCY_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VIDEO_LOSS_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VIDEO_LOSS_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VIDEO_DISCARD_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VIDEO_DISCARD_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_QUANTIZATION_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_QUANTIZATION_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_BANDWIDTH_RESTRICTIONS_DEGRADATION.toString(),
    label: getExpertColumnName(
      ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_BANDWIDTH_RESTRICTIONS_DEGRADATION
    ),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RESOLUTION_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RESOLUTION_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RATE_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RATE_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VIDEO_GOP_LENGTH_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VIDEO_GOP_LENGTH_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VIDEO_AVAILABLE_NETWORK_BANDWIDTH_DEGRADATION.toString(),
    label: getExpertColumnName(
      ExpertColumn.EXPERT_COLUMN_VIDEO_AVAILABLE_NETWORK_BANDWIDTH_DEGRADATION
    ),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VIDEO_DELAY_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VIDEO_DELAY_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VIDEO_AV_SYNC_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VIDEO_AV_SYNC_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: ExpertColumn.EXPERT_COLUMN_VIDEO_RECENCY_DEGRADATION.toString(),
    label: getExpertColumnName(ExpertColumn.EXPERT_COLUMN_VIDEO_RECENCY_DEGRADATION),
    width: 100,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
]

const propList = [["Current Calls", "Media Flows"]]

const CommandStrip = styled.div`
  display: flex;
`

const queryTemplate: RequestExpertQuery = {
  query: [
    {
      columnList: [],
      orderBy: [ExpertColumn.EXPERT_COLUMN_CALL_ID, ExpertColumn.EXPERT_COLUMN_FLOW_INDEX],
      orderByAscending: true,
      view: ExpertView.EXPERT_VIEW_VOIP_MEDIA,
      viewSettings: {
        la: "\u2190",
        ra: "\u2192",
        ba: "\u2194",
        showAddressNames: true,
        showPortNames: true,
      },
    },
    {
      columnList: [],
      view: ExpertView.EXPERT_VIEW_HEADER_COUNTERS,
    },
  ],
}

const queryDetailsTemplate: RequestExpertQuery = {
  query: [
    {
      columnList: [
        ExpertColumn.EXPERT_COLUMN_CALL_ID,
        ExpertColumn.EXPERT_COLUMN_FLOW_INDEX,
        ExpertColumn.EXPERT_COLUMN_SSRC,
        ExpertColumn.EXPERT_COLUMN_STREAM_ID,
        ExpertColumn.EXPERT_COLUMN_CALLER_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_CALLER_PORT,
        ExpertColumn.EXPERT_COLUMN_CALLEE_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_CALLEE_PORT,
        ExpertColumn.EXPERT_COLUMN_GATEKEEPER_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_GATEKEEPER_PORT,
        ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_SOURCE_PORT,
        ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS,
        ExpertColumn.EXPERT_COLUMN_DEST_PORT,
        ExpertColumn.EXPERT_COLUMN_MEDIA_PACKET_COUNT,
        ExpertColumn.EXPERT_COLUMN_DSCP,
        ExpertColumn.EXPERT_COLUMN_R_FACTOR_LISTENING,
        ExpertColumn.EXPERT_COLUMN_R_FACTOR_CONVERSATIONAL,
        ExpertColumn.EXPERT_COLUMN_R_FACTOR_G107,
        ExpertColumn.EXPERT_COLUMN_R_FACTOR_NOMINAL,
        ExpertColumn.EXPERT_COLUMN_VSTQ,
        ExpertColumn.EXPERT_COLUMN_NAME,
        ExpertColumn.EXPERT_COLUMN_FROM,
        ExpertColumn.EXPERT_COLUMN_TO,
        ExpertColumn.EXPERT_COLUMN_GATEWAY_ASSIGNED_CALL_ID,
        ExpertColumn.EXPERT_COLUMN_ASSERTED_IDENTITY,
        ExpertColumn.EXPERT_COLUMN_END_CAUSE,
        ExpertColumn.EXPERT_COLUMN_SIGNALING_TYPE,
        ExpertColumn.EXPERT_COLUMN_PROTOCOL,
        ExpertColumn.EXPERT_COLUMN_CODEC,
        ExpertColumn.EXPERT_COLUMN_BIT_RATE,
        ExpertColumn.EXPERT_COLUMN_MEDIA_TYPE,
        ExpertColumn.EXPERT_COLUMN_SETUP_TIME,
        ExpertColumn.EXPERT_COLUMN_PDD,
        ExpertColumn.EXPERT_COLUMN_START_TIME,
        ExpertColumn.EXPERT_COLUMN_END_TIME,
        ExpertColumn.EXPERT_COLUMN_DURATION,
        ExpertColumn.EXPERT_COLUMN_ONE_WAY_DELAY,
        ExpertColumn.EXPERT_COLUMN_PACKET_LOSS_PERCENT,
        ExpertColumn.EXPERT_COLUMN_JITTER,
        ExpertColumn.EXPERT_COLUMN_MOS_LQ,
        ExpertColumn.EXPERT_COLUMN_MOS_CQ,
        ExpertColumn.EXPERT_COLUMN_MOS_NOM,
        ExpertColumn.EXPERT_COLUMN_MOS_AV,
        ExpertColumn.EXPERT_COLUMN_MOS_V,
        ExpertColumn.EXPERT_COLUMN_VOICE_LOSS_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VOICE_DISCARD_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VOICE_CODEC_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VOICE_DELAY_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VOICE_SIGNAL_LEVEL_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VOICE_NOISE_LEVEL_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VOICE_ECHO_LEVEL_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VOICE_RECENCY_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VIDEO_LOSS_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VIDEO_DISCARD_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_QUANTIZATION_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_BANDWIDTH_RESTRICTIONS_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RESOLUTION_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RATE_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VIDEO_GOP_LENGTH_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VIDEO_AVAILABLE_NETWORK_BANDWIDTH_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VIDEO_DELAY_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VIDEO_AV_SYNC_DEGRADATION,
        ExpertColumn.EXPERT_COLUMN_VIDEO_RECENCY_DEGRADATION,
      ],
      firstRowIndex: 0,
      limitRowCount: 1,
      orderBy: [ExpertColumn.EXPERT_COLUMN_FLOW_INDEX],
      orderByAscending: true,
      scrollBack: 0,
      view: ExpertView.EXPERT_VIEW_VOIP_MEDIA,
      where: {
        criteria: "parent",
        key: [
          {
            column: ExpertColumn.EXPERT_COLUMN_CALL_ID,
            value: "1",
          },
          {
            column: ExpertColumn.EXPERT_COLUMN_FLOW_INDEX,
            value: "1",
          },
        ],
      },
      viewSettings: {
        la: "\u2190",
        ra: "\u2192",
        ba: "\u2194",
        showAddressNames: true,
        showPortNames: true,
      },
    },
  ],
}

const CallDetailsContent = styled.div`
  padding: 8px;
  overflow-y: auto;

  & > * + * {
    margin-top: 1rem;
  }
`

const DetailsTable = styled(Table)`
  & th {
    text-align: right;
    white-space: nowrap;
    font-weight: bold;
    color: ${props => props.theme.propTableHeaderColor};
    font-size: ${props => props.theme.propTableHeaderFontSize};
    line-height: 1.5rem;
    text-transform: ${props => props.theme.propTableHeaderTextTransform};
  }

  & th:nth-child(1) {
    width: 1%;
  }

  & td {
    word-break: break-all;
  }
`

const TableTitle = styled.h5`
  margin: 0;
  text-transform: uppercase;
  display: none;
`

type MediaFlowDetailsProps = {
  resultSet: ExpertQueryResponse | null
  resultSetIndex?: number
  rowListIndex?: number
  voipVisualizerEnabled: boolean
  onVoIPVisualizer: (rowData: ExpertValue[]) => void
}

const MediaFlowDetails = ({
  resultSet,
  resultSetIndex = 0,
  rowListIndex = 0,
  voipVisualizerEnabled,
  onVoIPVisualizer,
}: MediaFlowDetailsProps) => {
  const theme = useTheme()
  const showLocalTime = useSelector(getShowLocalTime)
  if (!resultSet || !resultSet.results) return null
  const results = resultSet.results[resultSetIndex]
  if (!results.rowList || !results.rowList.length) return null
  const columnList = results.columnList
  const rowData = results.rowList[rowListIndex]

  const fmt = (columnId: ExpertColumn) => {
    const value = getExpertValueFromRowData(rowData, columnList, columnId)
    if (value != null && value.value != null) {
      const formatted = formatExpertValue(
        columnId,
        value.value,
        value.rendered,
        rowData,
        columnList,
        showLocalTime
      )
      if (
        formatted != null &&
        theme.name === "Light" &&
        value.color != null &&
        value.color !== "#000000"
      ) {
        return (
          <span title={String(formatted)} style={{ color: value.color }}>
            {formatted}
          </span>
        )
      } else {
        return formatted
      }
    }
    return undefined
  }

  return (
    <CallDetailsContent>
      <div>
        <LightButton disabled={!voipVisualizerEnabled} onClick={() => onVoIPVisualizer(rowData)}>
          Voice &amp; Video Flow Visualizer
        </LightButton>
      </div>

      <div>
        <TableTitle>Media Details</TableTitle>
        <DetailsTable size="sm">
          <tbody>
            <tr>
              <th>Call Number</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CALL_ID)}</td>
            </tr>
            <tr>
              <th>Flow Index</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_FLOW_INDEX)}</td>
            </tr>
            <tr>
              <th>SSRC</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_SSRC)}</td>
            </tr>
            <tr>
              <th>Flow ID</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_STREAM_ID)}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_NAME)}</td>
            </tr>
            <tr>
              <th>From</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_FROM)}</td>
            </tr>
            <tr>
              <th>To</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_TO)}</td>
            </tr>
            <tr>
              <th>Call ID</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_GATEWAY_ASSIGNED_CALL_ID)}</td>
            </tr>
            <tr>
              <th>Asserted Identity</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_ASSERTED_IDENTITY)}</td>
            </tr>
            <tr>
              <th>Caller Address</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CALLER_ADDRESS)}</td>
            </tr>
            <tr>
              <th>Caller Port</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CALLER_PORT)}</td>
            </tr>
            <tr>
              <th>Callee Address</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CALLEE_ADDRESS)}</td>
            </tr>
            <tr>
              <th>Callee Port</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CALLEE_PORT)}</td>
            </tr>
            <tr>
              <th>Gatekeeper Address</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_GATEKEEPER_ADDRESS)}</td>
            </tr>
            <tr>
              <th>Gatekeeper Port</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_GATEKEEPER_PORT)}</td>
            </tr>
            <tr>
              <th>Source Address</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS)}</td>
            </tr>
            <tr>
              <th>Source Port</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_SOURCE_PORT)}</td>
            </tr>
            <tr>
              <th>Dest Address</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS)}</td>
            </tr>
            <tr>
              <th>Dest Port</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_DEST_PORT)}</td>
            </tr>
            <tr>
              <th>End Cause</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_END_CAUSE)}</td>
            </tr>
            <tr>
              <th>Signaling</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_SIGNALING_TYPE)}</td>
            </tr>
            <tr>
              <th>Protocol</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_PROTOCOL)}</td>
            </tr>
            <tr>
              <th>Codec</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_CODEC)}</td>
            </tr>
            <tr>
              <th>Bit Rate</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_BIT_RATE)}</td>
            </tr>
            <tr>
              <th>Codec Type</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_MEDIA_TYPE)}</td>
            </tr>
            <tr>
              <th>Setup Time</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_SETUP_TIME)}</td>
            </tr>
            <tr>
              <th>PDD</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_PDD)}</td>
            </tr>
            <tr>
              <th>Start</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_START_TIME)}</td>
            </tr>
            <tr>
              <th>Finish</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_END_TIME)}</td>
            </tr>
            <tr>
              <th>Duration</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_DURATION)}</td>
            </tr>
            <tr>
              <th>One-Way Delay</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_ONE_WAY_DELAY)}</td>
            </tr>
            <tr>
              <th>Packet Loss %</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_PACKET_LOSS_PERCENT)}</td>
            </tr>
            <tr>
              <th>Jitter</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_JITTER)}</td>
            </tr>
            <tr>
              <th>Media Packets</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_MEDIA_PACKET_COUNT)}</td>
            </tr>
            <tr>
              <th>DSCP</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_DSCP)}</td>
            </tr>
            <tr>
              <th>R Factor Listening</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_R_FACTOR_LISTENING)}</td>
            </tr>
            <tr>
              <th>R Factor Conversational</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_R_FACTOR_CONVERSATIONAL)}</td>
            </tr>
            <tr>
              <th>R Factor G.107</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_R_FACTOR_G107)}</td>
            </tr>
            <tr>
              <th>R Factor Nominal</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_R_FACTOR_NOMINAL)}</td>
            </tr>
            <tr>
              <th>MOS-LQ</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_MOS_LQ)}</td>
            </tr>
            <tr>
              <th>MOS-CQ</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_MOS_CQ)}</td>
            </tr>
            <tr>
              <th>MOS-Nom</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_MOS_NOM)}</td>
            </tr>
            <tr>
              <th>MOS-AV</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_MOS_AV)}</td>
            </tr>
            <tr>
              <th>MOS-V</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_MOS_V)}</td>
            </tr>
            <tr>
              <th>VS-TQ</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VSTQ)}</td>
            </tr>
            <tr>
              <th>Voice Loss Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VOICE_LOSS_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Voice Discard Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VOICE_DISCARD_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Voice Codec Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VOICE_CODEC_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Voice Delay Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VOICE_DELAY_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Voice Signal Level Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VOICE_SIGNAL_LEVEL_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Voice Noise Level Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VOICE_NOISE_LEVEL_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Voice Echo Level Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VOICE_ECHO_LEVEL_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Voice Recency Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VOICE_RECENCY_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Video Loss Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VIDEO_LOSS_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Video Discard Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VIDEO_DISCARD_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Video Codec Quantization Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_QUANTIZATION_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Video Codec Bandwidth Restrictions Degradation</th>
              <td>
                {fmt(ExpertColumn.EXPERT_COLUMN_VIDEO_CODEC_BANDWIDTH_RESTRICTIONS_DEGRADATION)}
              </td>
            </tr>
            <tr>
              <th>Video Frame Resolution Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RESOLUTION_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Video Frame Rate Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VIDEO_FRAME_RATE_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Video GOP Length Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VIDEO_GOP_LENGTH_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Video Available Network Bandwidth Degradation</th>
              <td>
                {fmt(ExpertColumn.EXPERT_COLUMN_VIDEO_AVAILABLE_NETWORK_BANDWIDTH_DEGRADATION)}
              </td>
            </tr>
            <tr>
              <th>Video Delay Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VIDEO_DELAY_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Video A/V Synchronization Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VIDEO_AV_SYNC_DEGRADATION)}</td>
            </tr>
            <tr>
              <th>Video Recency Degradation</th>
              <td>{fmt(ExpertColumn.EXPERT_COLUMN_VIDEO_RECENCY_DEGRADATION)}</td>
            </tr>
          </tbody>
        </DetailsTable>
      </div>
    </CallDetailsContent>
  )
}

type VoIPMediaViewProps = RouteComponentProps<CaptureRouteParams> &
  CaptureViewProps & {
    dispatch: Function
    engine: string
    authToken: string
    namesModTime?: string
    showAddressNames: boolean
    showPortNames: boolean
    showLocalTime: boolean
    columns: ExpertTableColumn[]
    sortBy: ExpertColumn
    sortDirection: SortDirectionType
    engineCapabilities: ResponseGetEngineCapabilities | null
    userId: string
  }

type VoIPMediaViewState = {
  resultSet: ExpertQueryResponse | null
  detailsResultSet: ExpertQueryResponse | null
  detailsOpen: boolean
  insertNameEntries: InsertNameEntry[] | null
  showExpertSettingsModal: boolean
  expertDescriptions: ExpertDescription[]
  expertLayers: ExpertLayerMapping[]
  expertSettings: ExpertSettingsEx | null
}

class VoIPMediaView extends React.Component<VoIPMediaViewProps, VoIPMediaViewState> {
  state: VoIPMediaViewState = {
    resultSet: null,
    detailsResultSet: null,
    detailsOpen: false,
    insertNameEntries: null,
    showExpertSettingsModal: false,
    expertDescriptions: [],
    expertLayers: [],
    expertSettings: null,
  }

  componentDidMount() {
    this.onRefresh()
  }

  componentDidUpdate({
    namesModTime,
    showAddressNames,
    showPortNames,
    columns,
    sortBy,
    sortDirection,
  }: VoIPMediaViewProps) {
    if (
      this.props.namesModTime !== namesModTime ||
      this.props.showAddressNames !== showAddressNames ||
      this.props.showPortNames !== showPortNames ||
      this.props.columns !== columns ||
      this.props.sortBy !== sortBy ||
      this.props.sortDirection !== sortDirection
    ) {
      this.setState({ detailsOpen: false })
      this.onRefresh()
    }
  }

  onExpertSettings = () => {
    const query: RequestExpertQuery = {
      query: eventFinderViews.map((view: ExpertView) => {
        return { ...cloneDeep(queryEventFinder), view }
      }),
    }

    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    expertQueryCFS(engine, authToken, type, capId, query)
      .then((resultSet: ExpertQueryResponse) => {
        if (Array.isArray(resultSet.results)) {
          const expertDescriptions: ExpertDescription[] = expertResponseToExpertDescriptions(
            resultSet.results
          )
          const expertLayers: ExpertLayerMapping[] = expertResponseToExpertLayers(resultSet.results)
          const expertSettings: ExpertSettingsEx | null = expertResponseToExpertSettings(
            resultSet.results
          )

          if (expertSettings) {
            this.setState({
              expertDescriptions,
              expertLayers,
              expertSettings,
              showExpertSettingsModal: true,
            })
          }
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onExpertSettingsCancel = () => {
    this.setState({
      expertDescriptions: [],
      expertLayers: [],
      expertSettings: null,
      showExpertSettingsModal: false,
    })
  }

  onExpertSettingsOK = (settings: ExpertSettings) => {
    const request: RequestExpertExecute = {
      execute: expertSettingsToExpertRequests(settings),
    }

    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    expertExecuteCFS(engine, authToken, type, capId, request)
      .then(() => {})
      .catch(() => {})
      .finally(() => {
        this.setState({
          expertDescriptions: [],
          expertLayers: [],
          expertSettings: null,
          showExpertSettingsModal: false,
        })
      })
  }

  onRefresh = () => {
    const { sortBy, sortDirection } = this.props
    const query = cloneDeep(queryTemplate)
    query.query[0].columnList = this.props.columns
      .filter(col => col.visible)
      .map(col => parseInt(col.dataKey, 10) as ExpertColumn)
    addExpertColumns(query.query[0].columnList, [
      ExpertColumn.EXPERT_COLUMN_CALL_ID,
      ExpertColumn.EXPERT_COLUMN_CALLER_ADDRESS,
      ExpertColumn.EXPERT_COLUMN_CALLEE_ADDRESS,
      ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS,
      ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS,
      ExpertColumn.EXPERT_COLUMN_SOURCE_PORT,
      ExpertColumn.EXPERT_COLUMN_DEST_PORT,
      ExpertColumn.EXPERT_COLUMN_PROTOCOL,
      ExpertColumn.EXPERT_COLUMN_STREAM_ID,
      ExpertColumn.EXPERT_COLUMN_FLOW_INDEX,
    ])
    if (query.query[0].orderBy !== undefined) {
      query.query[0].orderBy = [
        ExpertColumn.EXPERT_COLUMN_CALL_ID,
        ExpertColumn.EXPERT_COLUMN_FLOW_INDEX,
      ]
      if (
        sortBy !== ExpertColumn.EXPERT_COLUMN_CALL_ID &&
        sortBy !== ExpertColumn.EXPERT_COLUMN_FLOW_INDEX
      ) {
        query.query[0].orderBy.unshift(sortBy)
      }
    }
    query.query[0].orderByAscending = sortDirection === SortDirection.ASC
    if (query.query[0].viewSettings) {
      query.query[0].viewSettings.showAddressNames = this.props.showAddressNames
      query.query[0].viewSettings.showPortNames = this.props.showPortNames
    }
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    expertQueryCFS(engine, authToken, type, capId, query)
      .then((resultSet: ExpertQueryResponse) => {
        if (resultSet.results) {
          this.setState({ resultSet })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onExport = () => {
    const { resultSet } = this.state
    if (resultSet) {
      if (Array.isArray(resultSet.results)) {
        const results = resultSet.results[0]
        const { columns } = this.props
        const csv = exportExpertToCSV(results, columns)
        if (csv !== undefined) {
          FileSaver.saveAs(
            new Blob([csv], { type: "text/plain;charset=utf8" }),
            "VoiceVideoMedia.csv"
          )
        }
      }
    }
  }

  onSort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
    this.props.dispatch(setVoIPMediaSort(parseInt(sortBy, 10) as ExpertColumn, sortDirection))
  }

  onShowDefaultColumns = () => {
    this.props.dispatch(setVoIPMediaColumns(defaultColumns))
  }

  onShowAllColumns = () => {
    const columns = cloneDeep(this.props.columns)
    columns.forEach(col => {
      col.visible = true
    })
    this.props.dispatch(setVoIPMediaColumns(columns))
  }

  onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const columns = cloneDeep(this.props.columns)
    const col = columns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      this.props.dispatch(setVoIPMediaColumns(columns))
    }
  }

  onVoIPVisualizer = (rowData: ExpertValue[]) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]
    const callIdIndex = results.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_CALL_ID)
    if (callIdIndex !== -1) {
      const callId = rowData[callIdIndex]
      if (callId && typeof callId.value === "number") {
        this.props.history.push(`voice-and-video-visualizer/${callId.value}`)
      }
    }
  }

  onSelectRelated = (command: string, rowData: ExpertValue[]) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    let body: RequestPostSelectRelatedExpertStart | undefined = undefined
    switch (command) {
      case "call":
        {
          const callIdIndex = results.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_FLOW_INDEX)
          if (callIdIndex !== -1) {
            const callId = rowData[callIdIndex]
            if (callId && typeof callId.value === "number") {
              body = {
                criteria: [
                  {
                    voipCallId: callId.value,
                  },
                ],
              }
            }
          }
        }
        break
      case "caller":
        {
          const callerAddressIndex = results.columnList.indexOf(
            ExpertColumn.EXPERT_COLUMN_CALLER_ADDRESS
          )
          if (callerAddressIndex !== -1) {
            const callerAddress = rowData[callerAddressIndex]
            if (callerAddress && typeof callerAddress.value === "object") {
              body = {
                criteria: [
                  {
                    clientAddress: callerAddress.value as any,
                  },
                ],
              }
            }
          }
        }
        break
      case "callee":
        {
          const calleeAddressIndex = results.columnList.indexOf(
            ExpertColumn.EXPERT_COLUMN_CALLEE_ADDRESS
          )
          if (calleeAddressIndex !== -1) {
            const calleeAddress = rowData[calleeAddressIndex]
            if (calleeAddress && typeof calleeAddress.value === "object") {
              body = {
                criteria: [
                  {
                    serverAddress: calleeAddress.value as any,
                  },
                ],
              }
            }
          }
        }
        break
      case "port":
        {
          const sourcePortIndex = results.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_SOURCE_PORT)
          const destPortIndex = results.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_DEST_PORT)
          if (sourcePortIndex !== -1 && destPortIndex !== -1) {
            const sourcePort = rowData[sourcePortIndex]
            const destPort = rowData[destPortIndex]
            if (
              sourcePort &&
              typeof sourcePort.value === "number" &&
              destPort &&
              typeof destPort.value === "number"
            ) {
              body = {
                criteria: [
                  {
                    clientPort: sourcePort.value,
                    serverPort: destPort.value,
                  },
                ],
              }
            }
          }
        }
        break
      case "flowId":
        {
          const streamIdIndex = results.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_STREAM_ID)
          if (streamIdIndex !== -1) {
            const streamId = rowData[streamIdIndex]
            if (streamId && typeof streamId.value === "number") {
              body = {
                criteria: [
                  {
                    streamId: streamId.value as number,
                  },
                ],
              }
            }
          }
        }
        break
      case "mediaFlow":
        {
          const callIdIndex = results.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_CALL_ID)
          const mediaFlowIndex = results.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_FLOW_INDEX)
          if (callIdIndex !== -1 && mediaFlowIndex !== -1) {
            const callId = rowData[callIdIndex]
            const mediaFlow = rowData[mediaFlowIndex]
            if (
              callId &&
              typeof callId.value === "number" &&
              mediaFlow &&
              typeof mediaFlow.value === "number"
            ) {
              body = {
                criteria: [
                  {
                    voipCallId: callId.value,
                    voipCallFlowIndex: mediaFlow.value,
                  },
                ],
              }
            }
          }
        }
        break
      default:
        break
    }
    if (body) {
      const { capId } = this.props.match.params
      const { engine, authToken } = this.props
      postSelectRelatedExpertStart(engine, authToken, capId, body)
        .then((task: ResponsePostSelectRelatedExpertStart) => {
          if (task.taskId) {
            this.props.dispatch(
              setSelectPacketsTask({ type: "expert", taskId: task.taskId, progress: 0 })
            )
            this.props.history.push("packets")
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  onMakeFilter = (rowData: ExpertValue[]) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    let addressFilterNode: AddressFilterNode | undefined
    const sourceAddressIndex = results.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS)
    const destinationAddressIndex = results.columnList.indexOf(
      ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS
    )
    if (sourceAddressIndex !== -1 && destinationAddressIndex !== -1) {
      const sourceAddress = rowData[sourceAddressIndex]
      const destinationAddress = rowData[destinationAddressIndex]
      if (
        sourceAddress !== undefined &&
        typeof sourceAddress.value === "object" &&
        !Array.isArray(sourceAddress.value) &&
        destinationAddress !== undefined &&
        typeof destinationAddress.value === "object" &&
        !Array.isArray(destinationAddress.value)
      ) {
        const sourceAddressSpec = sourceAddress.value as MediaSpec
        const destinationAddressSpec = destinationAddress.value as MediaSpec
        addressFilterNode = {
          accept1To2: true,
          accept2To1: false,
          address1: formatMediaSpec(sourceAddressSpec),
          address2: formatMediaSpec(destinationAddressSpec),
          clsid: "D2ED5346-496C-4EA0-948E-21CDDA1ED723",
          comment: "",
          inverted: false,
          type: sourceAddressSpec.type,
        }
      }
    }

    let protocolFilterNode: ProtocolFilterNode | undefined
    const protocolIndex = results.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_PROTOCOL)
    if (protocolIndex !== -1) {
      const protocol = rowData[protocolIndex]
      if (protocol !== undefined && typeof protocol.value === "number") {
        const pspec = protocol.value & 0xffff
        const type = (protocol.value >> 24) & 0xff
        if (type === 1) {
          protocolFilterNode = {
            clsid: "A43DDCC0-CDD2-46B4-8114-68E5FAF35112",
            comment: "",
            inverted: false,
            protocol: mediaSpecFromProtoSpec(pspec),
            protospecPath: "",
            sliceToHeader: false,
          }
        }
      }
    }

    let portFilterNode: PortFilterNode | undefined
    const sourcePortIndex = results.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_SOURCE_PORT)
    const destinationPortIndex = results.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_DEST_PORT)
    if (sourcePortIndex !== -1 && destinationPortIndex !== -1) {
      const sourcePort = rowData[sourcePortIndex]
      const destinationPort = rowData[destinationPortIndex]
      if (typeof sourcePort.value === "number" && typeof destinationPort.value === "number") {
        portFilterNode = {
          accept1To2: true,
          accept2To1: false,
          clsid: "B3279AE9-91E1-4D0A-8ABD-6D1BDC5471A9",
          comment: "",
          inverted: false,
          port1: String(sourcePort.value),
          port2: String(destinationPort.value),
          type: MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT,
        }
      }
    }

    let rootFilterNode: FilterNode | undefined
    if (addressFilterNode !== undefined) {
      rootFilterNode = addressFilterNode
    }
    if (protocolFilterNode) {
      if (addressFilterNode) {
        addressFilterNode.andNode = protocolFilterNode
      } else {
        rootFilterNode = protocolFilterNode
      }
    }
    if (portFilterNode) {
      if (protocolFilterNode) {
        protocolFilterNode.andNode = portFilterNode
      } else if (addressFilterNode) {
        addressFilterNode.andNode = portFilterNode
      } else {
        rootFilterNode = portFilterNode
      }
    }

    const filter: Filter = {
      clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
      color: "#000000",
      comment: "",
      created: "",
      group: "",
      id: "",
      modified: "",
      name: "Untitled",
      rootNode: rootFilterNode,
    }
    this.props.history.push({
      pathname: getEngineNewFilterUrl(),
      state: { filter },
    })
  }

  onInsertIntoNameTable = (rowData: ExpertValue[]) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    const insertNameEntries: InsertNameEntry[] = []

    const addNameEntry = (col: ExpertColumn, title: string) => {
      const value = getExpertValueFromRowData(rowData, results.columnList, col)
      if (value && value.rendered && value.value) {
        if (!insertNameEntries.find(item => item.entry === value.rendered)) {
          insertNameEntries.push({
            title: title,
            entry: value.rendered,
            entryType: (value.value as any).type,
          })
        }
      }
    }

    addNameEntry(ExpertColumn.EXPERT_COLUMN_CALLER_ADDRESS, "Caller")
    addNameEntry(ExpertColumn.EXPERT_COLUMN_CALLEE_ADDRESS, "Callee")
    addNameEntry(ExpertColumn.EXPERT_COLUMN_GATEKEEPER_ADDRESS, "Gatekeeper")
    addNameEntry(ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS, "Source")
    addNameEntry(ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS, "Destination")

    if (insertNameEntries.length > 0) {
      this.setState({ insertNameEntries })
    }
  }

  onInsertIntoNameTableOK = () => {
    this.setState({ insertNameEntries: null })
    this.onRefresh()
  }

  onInsertIntoNameTableCancel = () => {
    this.setState({ insertNameEntries: null })
  }

  onResolveNames = (rowData: ExpertValue[]) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    const entries: AddressResolverRequestEntry[] = []

    const addEntry = (col: ExpertColumn) => {
      const value = getExpertValueFromRowData(rowData, results.columnList, col)
      if (value && value.value) {
        try {
          const spec = value.value as MediaSpec
          const entry = formatMediaSpec(spec)
          if (!entries.find(item => item.entry === entry)) {
            entries.push({
              entry: entry,
              entryType: spec.type,
            })
          }
        } catch (e) {
          console.error(e)
        }
      }
    }

    addEntry(ExpertColumn.EXPERT_COLUMN_CALLER_ADDRESS)
    addEntry(ExpertColumn.EXPERT_COLUMN_CALLEE_ADDRESS)
    addEntry(ExpertColumn.EXPERT_COLUMN_GATEKEEPER_ADDRESS)
    addEntry(ExpertColumn.EXPERT_COLUMN_SOURCE_ADDRESS)
    addEntry(ExpertColumn.EXPERT_COLUMN_DEST_ADDRESS)

    if (entries.length > 0) {
      resolveAddresses(this.props.engine, this.props.authToken, entries).catch(error => {
        console.error(error)
      })
      this.props.dispatch(updateStatus())
    }
  }

  onDetailsOpen = (open: boolean) => {
    this.setState({ detailsOpen: open })
  }

  onRowClick = ({ rowData }: { rowData: ExpertValue[] }) => {
    const { resultSet } = this.state
    if (!resultSet || !resultSet.results) return
    const results = resultSet.results[0]

    const callIdIndex = results.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_CALL_ID)
    const flowIndexIndex = results.columnList.indexOf(ExpertColumn.EXPERT_COLUMN_FLOW_INDEX)
    if (callIdIndex !== -1 && flowIndexIndex !== -1) {
      const callId = rowData[callIdIndex].value
      const flowIndex = rowData[flowIndexIndex].value

      const query = cloneDeep(queryDetailsTemplate)
      if (query.query[0].where !== undefined && query.query[0].where.key !== undefined) {
        query.query[0].where.key[0].value = callId as string
        query.query[0].where.key[1].value = flowIndex as string
      }
      if (query.query[0].viewSettings) {
        query.query[0].viewSettings.showAddressNames = this.props.showAddressNames
        query.query[0].viewSettings.showPortNames = this.props.showPortNames
      }
      const { type, capId } = this.props.match.params
      const { engine, authToken } = this.props
      expertQueryCFS(engine, authToken, type, capId, query)
        .then((resultSet: ExpertQueryResponse) => {
          if (resultSet.results) {
            this.setState({ detailsResultSet: resultSet, detailsOpen: true })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  renderCommands = ({ rowData }: TableCellProps) => {
    const { captureProperties, engineCapabilities, userId } = this.props
    const packetsDisabled = captureProperties === null || !captureProperties.packetBufferEnabled

    // make sure the user can view packets for this capture or forensic search
    let canViewPackets = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
    }

    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            <DropdownItem
              disabled={packetsDisabled}
              onClick={this.onVoIPVisualizer.bind(this, rowData)}
            >
              Voice &amp; Video Flow Visualizer
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, "call", rowData)}
            >
              Select Related Packets by Call
            </DropdownItem>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, "caller", rowData)}
            >
              Select Related Packets by Caller
            </DropdownItem>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, "callee", rowData)}
            >
              Select Related Packets by Callee
            </DropdownItem>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, "port", rowData)}
            >
              Select Related Packets by Port
            </DropdownItem>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, "flowId", rowData)}
            >
              Select Related Packets by Flow ID
            </DropdownItem>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, "mediaFlow", rowData)}
            >
              Select Related Packets by Media Flow
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.onMakeFilter.bind(this, rowData)}>Make Filter</DropdownItem>
            <DropdownItem onClick={this.onInsertIntoNameTable.bind(this, rowData)}>
              Insert Into Name Table
            </DropdownItem>
            <DropdownItem onClick={this.onResolveNames.bind(this, rowData)}>
              Resolve Names
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  render() {
    const { resultSet, detailsResultSet, detailsOpen, insertNameEntries } = this.state
    const { captureProperties, columns, engineCapabilities, sortBy, sortDirection, userId } =
      this.props

    // make sure the user can moidfy captures and view stats for this capture or forensic search
    let canModifyCapture = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canModifyCapture = isUserOwner || policies.includes(EngineUserPolicies.modifyCaptures)
      const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
      if (!canViewStats) {
        const { type, capId } = this.props.match.params
        return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
      }
    }

    const voipHeaderCounts = getVoIPHeaderCounts(resultSet)
    let maxCallsMessage = ""
    let maxCallsIcon = null
    if (resultSet && Array.isArray(resultSet.results) && resultSet.results.length > 1) {
      const headerCounters = resultSet.results[1]
      if (Array.isArray(headerCounters.rowList)) {
        let maxCallsTimestamp = 0
        let maxCallsNotify = false
        let maxCallsNotifyServerity = 0
        let voipAnalysisStopped = false
        for (let i = 0, len = headerCounters.rowList.length; i < len; i++) {
          const row = headerCounters.rowList[i]
          if (Array.isArray(row) && row.length === 2) {
            if (row[0].value === "VOIP_MAX_CALLS_TIME") {
              maxCallsTimestamp = row[1].value as number
            } else if (row[0].value === "VOIP_MAX_CALLS_NOTIFY") {
              maxCallsNotify = row[1].value !== 0
            } else if (row[0].value === "VOIP_MAX_CALLS_NOTIFY_SEVERITY") {
              maxCallsNotifyServerity = row[1].value as number
            } else if (row[0].value === "VOIP_STOPPED_ANALYSIS") {
              voipAnalysisStopped = row[1].value !== 0
            }
          }
        }
        if (maxCallsTimestamp !== 18446744073709552000 && maxCallsTimestamp !== 0) {
          const maxCallsTime = formatDateTime(maxCallsTimestamp)
          if (voipAnalysisStopped) {
            maxCallsMessage = `Maximum simultaneous calls limit reached at ${maxCallsTime}, analysis stopped`
          } else {
            maxCallsMessage = `Maximum simultaneous calls limit reached at ${maxCallsTime}`
          }
          if (maxCallsNotify) {
            switch (maxCallsNotifyServerity) {
              case 0:
                maxCallsIcon = <IconInformational />
                break
              case 1:
                maxCallsIcon = <IconMinor />
                break
              case 2:
                maxCallsIcon = <IconMajor />
                break
              case 3:
                maxCallsIcon = <IconSevere />
                break
              default:
                break
            }
          }
        }
      }
    }
    return (
      <View>
        <BreadcrumbItem to={this.props.match.url} title="Voice & Video Media" />
        <ViewHeader>
          <ViewHeaderTitle title="Media Flows" />
          <ViewHeaderButtons>
            <LightButton aria-label="Export" id="export" onClick={this.onExport}>
              <FontAwesome name="download" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="export">
              Export
            </UncontrolledTooltip>
            <LightButton
              aria-label="Expert Settings"
              id="expertSettings"
              onClick={this.onExpertSettings}
            >
              <FontAwesome name="cog" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="expertSettings">
              Configure Expert Settings
            </UncontrolledTooltip>
            <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefresh}>
              <FontAwesome name="refresh" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="refresh">
              Refresh
            </UncontrolledTooltip>
          </ViewHeaderButtons>
        </ViewHeader>
        {maxCallsMessage && (
          <UncontrolledAlert component={ViewAlert} color="danger" fade={false}>
            <ViewAlertContent>
              {maxCallsIcon}
              {maxCallsMessage}
            </ViewAlertContent>
          </UncontrolledAlert>
        )}
        {resultSet ? (
          <>
            <ViewHeaderPanel>
              <PropTable
                data={voipHeaderCounts}
                propList={propList}
                formatProp={formatVoIPHeaderCountsProp}
              />
            </ViewHeaderPanel>
            <ViewContent>
              <ExpertTable
                resultSet={resultSet}
                renderCommands={this.renderCommands}
                columnDesc={columns}
                onShowDefaultColumns={this.onShowDefaultColumns}
                onShowAllColumns={this.onShowAllColumns}
                onToggleColumn={this.onToggleColumn}
                onRowClick={this.onRowClick}
                sort={this.onSort}
                sortBy={sortBy.toString()}
                sortDirection={sortDirection}
                showLocalTime={this.props.showLocalTime}
              />
            </ViewContent>
          </>
        ) : (
          <CenterContent>
            <Spinner />
          </CenterContent>
        )}
        <Sidebar open={detailsOpen}>
          <SidebarBody open={detailsOpen}>
            <SidebarHeader>
              <SidebarTitle>Media Details</SidebarTitle>
              <CloseButton onClick={() => this.onDetailsOpen(false)} />
            </SidebarHeader>
            <SidebarContent>
              <MediaFlowDetails
                resultSet={detailsResultSet}
                voipVisualizerEnabled={
                  captureProperties !== null && captureProperties.packetBufferEnabled
                }
                onVoIPVisualizer={this.onVoIPVisualizer}
              />
            </SidebarContent>
          </SidebarBody>
        </Sidebar>
        {insertNameEntries != null && (
          <InsertNamesModal
            engine={this.props.engine}
            authToken={this.props.authToken}
            entries={insertNameEntries}
            onOK={this.onInsertIntoNameTableOK}
            onCancel={this.onInsertIntoNameTableCancel}
          />
        )}
        {this.state.showExpertSettingsModal && this.state.expertSettings && (
          <ExpertSettingsModal
            expertDescriptions={this.state.expertDescriptions}
            expertLayers={this.state.expertLayers}
            expertSettings={this.state.expertSettings.settings}
            maxStreamCountMax={this.state.expertSettings.maxStreamCountMax}
            readOnly={this.props.match.params.type !== "captures" || !canModifyCapture}
            type={this.props.match.params.type}
            onCancel={this.onExpertSettingsCancel}
            onOK={this.onExpertSettingsOK}
            engine={this.props.engine}
            authToken={this.props.authToken}
          />
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  namesModTime: getNamesModificationTime(state),
  showAddressNames: getShowAddressNames(state),
  showPortNames: getShowPortNames(state),
  showLocalTime: getShowLocalTime(state),
  columns: getVoIPMediaColumns(state) || defaultColumns,
  sortBy: getVoIPMediaSortBy(state) || ExpertColumn.EXPERT_COLUMN_CALL_ID,
  sortDirection: getVoIPMediaSortDirection(state) || SortDirection.ASC,
  engineCapabilities: getCapabilities(state) || null,
  userId: getUserId(state),
})

export default connect(mapStateToProps)(VoIPMediaView)
