import * as React from "react"
import FontAwesome from "react-fontawesome"
import { InputGroup } from "reactstrap"
import { DropdownMenu, DropdownItem, UncontrolledDropdown } from "../common/Dropdown"
import { FormFeedback } from "../common/Form"
import { Input } from "../common/Input"
import { LightButton, LightDropdownToggle } from "../common/Buttons"
import { ResponseGetEngineCapabilities } from "../../api/types"
import { EngineCapabilities } from "../../api/types/engineTypes"
import { measureText } from "../../utils/measureText"

type FilterBarProps = {
  tag?: "input" | "textarea"
  engineCapabilities: ResponseGetEngineCapabilities | null
  filterBarExpression: string
  filterBarError: string
  recentFilterBarExpressions?: string[]
  onChange: (filterExpression: string) => void
  onApply?: () => void
}

type FilterBarState = {
  selStart: number | null
}

export class FilterBar extends React.Component<FilterBarProps, FilterBarState> {
  static defaultProps = {
    tag: "textarea",
  }

  state: FilterBarState = {
    selStart: null,
  }

  inputRef = React.createRef<HTMLInputElement | HTMLTextAreaElement>()

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(event.target.value)
  }

  onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Allow Shift+Enter to add line feed
    if (event.key === "Enter" && !event.shiftKey) {
      event.stopPropagation()
      event.preventDefault()
      this.onApply()
    }
  }

  onSelect = () => {
    if (this.inputRef.current) {
      this.setState({ selStart: this.inputRef.current.selectionStart })
    }
  }

  onApply = () => {
    if (this.props.onApply) {
      this.props.onApply()
    }
  }

  onFilterInsertText = (text: string) => {
    const el = this.inputRef.current
    if (el && el.selectionStart !== null && el.selectionEnd !== null) {
      const firstPart = el.value.substring(0, el.selectionStart)
      const lastPart = el.value.substring(el.selectionEnd, el.value.length)
      const newValue = firstPart + text + lastPart
      if (text.endsWith(")")) {
        // Set the value now and put the cursor inside the parens
        el.value = newValue
        el.selectionStart = el.selectionEnd = firstPart.length + text.length - 1
      }
      el.focus()
      this.props.onChange(newValue)
    } else {
      this.props.onChange(this.props.filterBarExpression + text)
    }
  }

  render() {
    const {
      tag,
      engineCapabilities,
      filterBarExpression,
      filterBarError,
      recentFilterBarExpressions,
    } = this.props

    const recentItems =
      recentFilterBarExpressions && recentFilterBarExpressions.length > 0 ? (
        <>
          <DropdownItem header>Recent</DropdownItem>
          {recentFilterBarExpressions.map((expression, i) => (
            <DropdownItem key={i} onClick={this.props.onChange.bind(this, expression)}>
              {expression}
            </DropdownItem>
          ))}
          <DropdownItem divider />
        </>
      ) : null

    let rows: undefined | number = undefined
    if (tag === "textarea") {
      rows = 1
      if (this.inputRef.current && filterBarExpression.length > 0) {
        // Assumes some styles: font-size: 12px, line-height: 18px, and padding on the textarea
        const maxWidth = this.inputRef.current.clientWidth - 18
        const h = measureText(filterBarExpression, {
          fontSize: "12px",
          whiteSpace: "normal",
          width: `${maxWidth}px`,
        }).height
        rows = Math.ceil(h / 18)
        rows += filterBarExpression.split(/\r\n|\r|\n/).length - 1
      }
    }

    let filterBarErrorExtra = ""
    if (filterBarError.startsWith("Char") && this.state.selStart !== null) {
      filterBarErrorExtra = ` \u2014 current position ${this.state.selStart}`
    }

    return (
      <div>
        <InputGroup>
          <UncontrolledDropdown addonType="prepend">
            <LightDropdownToggle caret aria-label="Toggle filter helpers dropdown">
              <FontAwesome name="filter" />
            </LightDropdownToggle>
            <DropdownMenu>
              {recentItems}
              <DropdownItem header>Insert Operator</DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, " & ")}>
                &amp; (And)
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, " | ")}>
                | (Or)
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "!")}>! (Not)</DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "()")}>
                () (Group)
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem header>Insert Expression</DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "filter()")}>
                Filter
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "addr()")}>
                Address
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "ip()")}>IP</DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "protocol()")}>
                Protocol
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "app()")}>
                Application
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "country()")}>
                Country
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "pspec()")}>
                Protospec
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "port()")}>
                Port
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "value()")}>
                Value
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "channel(num:)")}>
                Channel
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "wan()")}>WAN</DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "wireless()")}>
                Wireless
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "pattern()")}>
                Pattern
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "length()")}>
                Length
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "plugin()")}>
                Analysis Module
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "tcpdump()")}>
                tcpdump
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "vlan()")}>
                VLAN
              </DropdownItem>
              <DropdownItem onClick={this.onFilterInsertText.bind(this, "mpls()")}>
                MPLS
              </DropdownItem>
              {engineCapabilities?.capabilities.includes(EngineCapabilities.vxlanFilter) && (
                <DropdownItem onClick={this.onFilterInsertText.bind(this, "vxlan()")}>
                  VXLAN
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
          <Input
            innerRef={this.inputRef}
            tag={tag}
            type="text"
            name="filterbar"
            id="filterbar"
            rows={rows}
            placeholder="Enter a filter expression"
            value={filterBarExpression}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onSelect={this.onSelect}
            invalid={filterBarError.length !== 0}
            spellCheck={false}
            style={{
              minHeight: "calc(1.5em + 0.75rem + 2px)", // Ensure the textarea doesn't get too small
              backgroundImage: "none", // Remove the invalid icon image
              paddingRight: "0.75rem", // Remove the invalid icon padding
              zIndex: 3, // Keep the input on top of the filter button to show the border color
            }}
          />
          {this.props.onApply && (
            <LightButton
              className="border-left-0"
              disabled={filterBarExpression.length === 0 || filterBarError.length !== 0}
              onClick={this.onApply}
            >
              Apply
            </LightButton>
          )}
        </InputGroup>
        {filterBarError.length !== 0 ? (
          <FormFeedback style={{ display: "block", wordBreak: "break-word" }}>
            {filterBarError + filterBarErrorExtra}
          </FormFeedback>
        ) : null}
      </div>
    )
  }
}

export default FilterBar
