import * as React from "react"
import { StaticContext } from "react-router"
import { Prompt, RouteComponentProps } from "react-router-dom"
import { connect } from "react-redux"
import { Col, Collapse, FormGroup } from "reactstrap"
import { cloneDeep, toNumber } from "lodash"
import { produce } from "immer"
import { v4 as uuid } from "uuid"
import { HardwareChannelForm } from "./HardwareChannelForm"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { LightButton, PrimaryButton } from "../common/Buttons"
import { ColorPicker } from "../common/ColorPicker"
import { ErrorText } from "../common/ErrorText"
import {
  CheckGroup,
  CollapsibleLegend,
  CollapsibleSection,
  FieldSet,
  FormTitle,
  FormView,
  HorizontalFormGroup,
  Label,
  SubSection,
} from "../common/Form"
import { IconSevere } from "../common/Icons"
import { Input } from "../common/Input"
import { Select } from "../common/Select"
import {
  View,
  ViewAlert,
  ViewAlertContent,
  ViewContentScrollable,
  ViewContentContainer,
  ViewFooter,
} from "../common/View"
import {
  fetchAdapterHardwareConfigs,
  fetchCaptures,
  setAdapterHardwareConfigs,
} from "../../api/api"
import {
  Adapter,
  CaptureProperties,
  GigAdapterInfo,
  GigHardwareOptions,
  HardwareChannelFilter,
  HardwareOptions,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineCapabilities } from "../../api/types/engineTypes"
import { MediaSpecClass, MediaSpecType } from "../../api/types/mediaTypes"
import { PeekAdapterFeatures, SliceOffsetType } from "../../api/types/peekTypes"
import { getEngine, getAuthToken, getCapabilities } from "../../store"
import { getAdapterId } from "../../utils/adapterUtils"
import { formatInteger } from "../../utils/formatUtils"
import {
  COMPARATOR_LIMIT,
  EXTRACTOR_LIMIT,
  defaultHardwareChannelValueFilter,
  determineHardwareResources,
} from "../../utils/hardwareUtils"
import { getTypeMask } from "../../utils/mediaSpec"
import {
  getEngineAdaptersUrl,
  getEngineAdapterUrl,
  getEngineAdapterNewHardwareProfileUrl,
  getEngineAdapterHardwareProfileUrl,
  getEngineHardwareProfilesUrl,
  getEngineNewHardwareProfileUrl,
  getEngineHardwareProfileUrl,
} from "../../routes"

const SLICE_LENGTH_MIN = 1
const SLICE_LENGTH_DEFAULT = 128
const SLICE_LENGTH_MAX = 10000

function getHardwareChannelFilter(channelNumber: number): HardwareChannelFilter {
  return {
    addressFilter: {
      accept1To2: true,
      accept2To1: true,
      address1: {
        data: "",
        mask: getTypeMask(MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS),
        msclass: MediaSpecClass.MEDIA_SPEC_CLASS_ADDRESS,
        type: MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS,
      },
      address2: {
        data: "",
        mask: 0,
        msclass: MediaSpecClass.MEDIA_SPEC_CLASS_ADDRESS,
        type: MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS,
      },
      ip8023: true,
      ipEType2: true,
    },
    channel: channelNumber,
    clsid: "28E7800C-963E-4B1E-8163-FD42823E0980",
    discardErrors: false,
    exclusiveFilter: false,
    mplsFilter: {
      layers: 1,
      mpls: [],
    },
    portFilter: {
      accept1To2: true,
      accept2To1: true,
      port1: {
        data: "",
        mask: getTypeMask(MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT),
        msclass: MediaSpecClass.MEDIA_SPEC_CLASS_PORT,
        type: MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT,
      },
      port2: {
        data: "",
        mask: 0,
        msclass: MediaSpecClass.MEDIA_SPEC_CLASS_PORT,
        type: MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT,
      },
      tcpPort: true,
      udpPort: true,
    },
    useAddressFilter: false,
    useMPLSFilter: false,
    usePortFilter: false,
    useValueFilter: false,
    useVLANFilter: false,
    valueFilter: { ...defaultHardwareChannelValueFilter, id: uuid().toUpperCase() },
    vlanFilter: {
      layers: 1,
      vlan: [],
    },
  }
}

const hardwareConfigGig: GigHardwareOptions = {
  clsid: "7DA20E4B-A157-469D-AB1A-F454D6CA5AE9",
  color: "#000000",
  comment: "",
  created: "",
  deduplication: false,
  filter: {
    channelFilters: [
      getHardwareChannelFilter(1),
      getHardwareChannelFilter(2),
      getHardwareChannelFilter(3),
      getHardwareChannelFilter(4),
      getHardwareChannelFilter(5),
      getHardwareChannelFilter(6),
      getHardwareChannelFilter(7),
      getHardwareChannelFilter(8),
    ],
    clsid: "F20D3A99-19A3-4B32-9F0D-8110C8F7C7EC",
    oneChannelFilter: false,
  },
  filtering: true,
  id: "",
  modified: "",
  name: "Untitled",
  sliceLength: SLICE_LENGTH_DEFAULT,
  sliceOffset: SliceOffsetType.SLICE_OFFSET_START_OF_PACKET,
  slicing: false,
}

type RouteInfo = {
  adapterId: string
  adapterType: string
  hardwareId: string
}

type LocationState = {
  adapter: Adapter
  hardwareOptions: HardwareOptions[]
  showPrompt: boolean
}

type HardwareProfileViewProps = RouteComponentProps<RouteInfo, StaticContext, LocationState> & {
  engine: string
  authToken: string
  engineCapabilities: ResponseGetEngineCapabilities | null
}

type HardwareProfileViewState = {
  error: any | null
  captures: CaptureProperties[]
  hardwareOption: GigHardwareOptions | null
  hardwareOptions: HardwareOptions[]
  isChannelOpen: boolean[]
  showPrompt: boolean
  validAddress: boolean
  validMPLS: boolean
  validPort: boolean
  validValues: boolean
  validVLAN: boolean
}

class HardwareProfileView extends React.Component<
  HardwareProfileViewProps,
  HardwareProfileViewState
> {
  state: HardwareProfileViewState = {
    error: null,
    captures: [],
    hardwareOption: null,
    hardwareOptions:
      this.props.location.state && Array.isArray(this.props.location.state.hardwareOptions)
        ? this.props.location.state.hardwareOptions
        : [],
    isChannelOpen: [],
    showPrompt:
      this.props.location.state && this.props.location.state.showPrompt !== undefined
        ? this.props.location.state.showPrompt
        : false,
    validAddress: true,
    validMPLS: true,
    validPort: true,
    validValues: true,
    validVLAN: true,
  }

  componentDidMount() {
    let hardwareOption: GigHardwareOptions = {
      ...hardwareConfigGig,
      id: uuid().toUpperCase(),
    }
    const currentDateTime = new Date().toISOString()
    hardwareOption.created = currentDateTime
    hardwareOption.modified = currentDateTime
    let hardwareOptions: HardwareOptions[] = []
    let captures: CaptureProperties[] = []
    let isChannelOpen: boolean[] = []

    if (this.props.location.state && Array.isArray(this.props.location.state.hardwareOptions)) {
      if (this.props.match.params.hardwareId !== undefined) {
        hardwareOption = cloneDeep(
          this.state.hardwareOptions.find(
            (hwc: HardwareOptions) => hwc.id === this.props.match.params.hardwareId
          ) as GigHardwareOptions
        )
      }
      hardwareOptions = cloneDeep(this.state.hardwareOptions)

      if (hardwareOption.filter) {
        isChannelOpen = hardwareOption.filter.channelFilters.map(() => true)
      }

      this.setState({
        hardwareOption,
        hardwareOptions,
        isChannelOpen,
      })
    } else {
      const { engine, authToken } = this.props
      const requestHardwareConfigs = fetchAdapterHardwareConfigs(engine, authToken)
      const requestCaptures = fetchCaptures(engine, authToken)
      Promise.all([requestHardwareConfigs, requestCaptures]).then(
        ([hardwareConfigs, responseCaptures]) => {
          if (Array.isArray(hardwareConfigs.hardwareOptions)) {
            if (this.props.match.params.hardwareId !== undefined) {
              hardwareOption = cloneDeep(
                hardwareConfigs.hardwareOptions.find(
                  (hwc: HardwareOptions) => hwc.id === this.props.match.params.hardwareId
                ) as GigHardwareOptions
              )
            }
            hardwareOptions = cloneDeep(hardwareConfigs.hardwareOptions)
          }

          if (hardwareOption.filter) {
            isChannelOpen = hardwareOption.filter.channelFilters.map(() => true)
          }

          if (Array.isArray(responseCaptures.captures)) {
            captures = cloneDeep(responseCaptures.captures)
          }

          this.setState({
            captures,
            hardwareOption,
            hardwareOptions,
            isChannelOpen,
          })
        }
      )
    }
  }

  getChannelSummary = (index: number) => {
    const summary: string = ""
    return summary
  }

  onCancel = () => {
    this.setState({ showPrompt: false }, () => {
      if (this.props.location.state && this.props.location.state.adapter !== undefined) {
        const { adapter } = this.props.location.state
        this.props.history.push({
          pathname: getEngineAdapterUrl(getAdapterId(adapter.info), adapter.info.type),
          state: this.props.location.state,
        })
      } else {
        this.props.history.goBack()
      }
    })
  }

  onToggle = (index: number) => {
    this.setState(
      produce(draft => {
        if (index < draft.isChannelOpen.length) {
          draft.isChannelOpen[index] = !draft.isChannelOpen[index]
          draft.showPrompt = true
        }
      })
    )
  }

  onChangeColor = (color: string) => {
    this.setState(
      produce(draft => {
        if (draft.hardwareOption) {
          draft.hardwareOption.color = color
          draft.showPrompt = true
        }
      })
    )
  }

  onChangeComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      produce(draft => {
        if (draft.hardwareOption) {
          draft.hardwareOption.comment = value
          draft.showPrompt = true
        }
      })
    )
  }

  onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      produce(draft => {
        if (draft.hardwareOption) {
          draft.hardwareOption.name = value
          draft.showPrompt = true
        }
      })
    )
  }

  onChangeOneChannelFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    this.setState(
      produce(draft => {
        if (draft.hardwareOption && draft.hardwareOption.filter) {
          draft.hardwareOption.filter.oneChannelFilter = checked
          draft.showPrompt = true
        }
      })
    )
  }

  onChangeSliceLength = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const length = toNumber(value)
    this.setState(
      produce(draft => {
        if (draft.hardwareOption) {
          draft.hardwareOption.sliceLength = length
          draft.showPrompt = true
        }
      })
    )
  }

  onChangeSliceOffset = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const offset = toNumber(value)
    this.setState(
      produce(draft => {
        if (draft.hardwareOption) {
          draft.hardwareOption.sliceOffset = offset
          if (
            draft.hardwareOption.sliceLength < SLICE_LENGTH_MIN ||
            draft.hardwareOption.sliceLength > SLICE_LENGTH_MAX
          ) {
            draft.hardwareOption.sliceLength = SLICE_LENGTH_DEFAULT
          }
          draft.showPrompt = true
        }
      })
    )
  }

  onChangeSlicing = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    this.setState(
      produce(draft => {
        if (draft.hardwareOption) {
          draft.hardwareOption.slicing = checked
          draft.showPrompt = true
        }
      })
    )
  }

  onChangeDeduplication = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    this.setState(
      produce(draft => {
        if (draft.hardwareOption) {
          draft.hardwareOption.deduplication = checked
          draft.showPrompt = true
        }
      })
    )
  }

  onCloseAlert = () => {
    this.setState({ error: null })
  }

  onOK = () => {
    // verify the NTPL can handle the hardware profile
    const { hardwareOption } = this.state
    if (
      hardwareOption &&
      hardwareOption.clsid === "7DA20E4B-A157-469D-AB1A-F454D6CA5AE9" &&
      hardwareOption.filter
    ) {
      // determine which channels to check
      const channels = hardwareOption.filter.oneChannelFilter
        ? [hardwareOption.filter.channelFilters[0]]
        : hardwareOption.filter.channelFilters

      const hardwareResources = determineHardwareResources(channels)
      if (
        hardwareResources.extractors > EXTRACTOR_LIMIT ||
        hardwareResources.comparators > COMPARATOR_LIMIT
      ) {
        this.setState({ error: "Hardware profile is too complex, please reduce the complexity" })
      } else {
        this.onSubmit()
      }
    }
  }

  onSubmit = () => {
    const { hardwareOption } = this.state
    if (hardwareOption !== null && this.state.hardwareOptions !== null) {
      const hardwareOptions: HardwareOptions[] = cloneDeep(this.state.hardwareOptions)
      const index = hardwareOptions.findIndex(
        (hoption: HardwareOptions) => hoption.id === hardwareOption.id
      )
      if (index !== -1) {
        hardwareOptions[index] = {
          ...cloneDeep(hardwareOption),
          modified: new Date().toISOString(),
        }
      } else {
        hardwareOptions.push({ ...cloneDeep(hardwareOption), modified: new Date().toISOString() })
      }

      const { engine, authToken } = this.props
      setAdapterHardwareConfigs(engine, authToken, { hardwareOptions })
        .then(() => {
          this.setState({ showPrompt: false }, () => {
            if (this.props.location.state && this.props.location.state.adapter !== undefined) {
              const { adapter } = this.props.location.state
              this.props.history.push({
                pathname: getEngineAdapterUrl(getAdapterId(adapter.info), adapter.info.type),
                state: { ...this.props.location.state, hardwareOptions },
              })
            } else {
              this.props.history.goBack()
            }
          })
        })
        .catch(error => {
          this.setState({ error })
        })
    }
  }

  setChannelFilter = (channelFilter: HardwareChannelFilter) => {
    this.setState(
      produce(draft => {
        if (draft.hardwareOption && draft.hardwareOption.filter) {
          const index = draft.hardwareOption.filter.channelFilters.findIndex(
            (hcf: HardwareChannelFilter) => hcf.channel === channelFilter.channel
          )
          if (index !== -1) {
            draft.hardwareOption.filter.channelFilters[index] = cloneDeep(channelFilter)
            draft.showPrompt = true
          }
        }
      })
    )
  }

  setValidAddress = (validAddress: boolean) => {
    this.setState({ validAddress })
  }

  setValidMPLS = (validMPLS: boolean) => {
    this.setState({ validMPLS })
  }

  setValidPort = (validPort: boolean) => {
    this.setState({ validPort })
  }

  setValidValues = (validValues: boolean) => {
    this.setState({ validValues })
  }

  setValidVLAN = (validVLAN: boolean) => {
    this.setState({ validVLAN })
  }

  render() {
    const { engineCapabilities } = this.props
    const {
      error,
      captures,
      validAddress,
      validMPLS,
      validPort,
      validValues,
      validVLAN,
      hardwareOption,
      isChannelOpen,
      showPrompt,
    } = this.state
    const { adapterId, adapterType, hardwareId } = this.props.match.params
    if (!hardwareOption) return null

    let adapterDescription: string = "Adapter"
    if (this.props.location.state && this.props.location.state.adapter !== undefined) {
      adapterDescription = this.props.location.state.adapter.info.description
    }

    let channelForms = null
    if (hardwareOption.filter) {
      const oneChannelFilter = hardwareOption.filter.oneChannelFilter
      channelForms = hardwareOption.filter.channelFilters.map(
        (hcf: HardwareChannelFilter, hcfIndex: number) => {
          if (hcfIndex < isChannelOpen.length && (hcfIndex === 0 || !oneChannelFilter)) {
            return (
              <FieldSet key={`channel${hcfIndex}`}>
                <CollapsibleLegend
                  title={`Channel ${hcfIndex + 1}`}
                  onClick={this.onToggle.bind(this, hcfIndex)}
                  isOpen={isChannelOpen[hcfIndex]}
                  getSummary={this.getChannelSummary.bind(this, hcfIndex)}
                />
                <CollapsibleSection isOpen={isChannelOpen[hcfIndex]}>
                  <HardwareChannelForm
                    channelFilter={hcf}
                    setChannelFilter={this.setChannelFilter}
                    setValidAddress={this.setValidAddress}
                    setValidMPLS={this.setValidMPLS}
                    setValidPort={this.setValidPort}
                    setValidValues={this.setValidValues}
                    setValidVLAN={this.setValidVLAN}
                    supportVLANMPLS={
                      engineCapabilities !== null &&
                      engineCapabilities.capabilities.includes(
                        EngineCapabilities.hardwareFilterVLANMPLS
                      )
                    }
                    supportValues={
                      engineCapabilities !== null &&
                      engineCapabilities.capabilities.includes(
                        EngineCapabilities.hardwareFilterValues
                      )
                    }
                  />
                </CollapsibleSection>
              </FieldSet>
            )
          } else {
            return null
          }
        }
      )
    }

    // Check to see if an existing capture that doesn't support hardware deduplication has a hardware profile that does.
    let validDeduplication = true
    if (hardwareOption.deduplication) {
      for (let i = 0; i < captures.length; i++) {
        if (
          "hardwareProfileID" in captures[i] &&
          captures[i].hardwareProfileID === hardwareOption.id &&
          "features" in captures[i].adapterInfo &&
          ((captures[i].adapterInfo as GigAdapterInfo).features &
            PeekAdapterFeatures.PEEK_ADAPTER_DEDUPLICATION) ===
            0
        ) {
          validDeduplication = false
          break
        }
      }
    }

    const validProfile = hardwareOption.name !== undefined && hardwareOption.name.length !== 0
    const validSliceLength =
      hardwareOption.sliceLength >= SLICE_LENGTH_MIN &&
      hardwareOption.sliceLength <= SLICE_LENGTH_MAX

    return (
      <View padding={false}>
        <Prompt
          when={showPrompt}
          message="Warning! Changes have not been saved. Discard changes?"
        />
        {adapterId != null && adapterType != null ? (
          <>
            <BreadcrumbItem to={getEngineAdaptersUrl()} title="Adapters" />
            <BreadcrumbItem
              to={getEngineAdapterUrl(adapterId, adapterType)}
              title={adapterDescription}
            />
            <BreadcrumbItem
              to={
                hardwareId != null
                  ? getEngineAdapterHardwareProfileUrl(adapterId, adapterType, hardwareId)
                  : getEngineAdapterNewHardwareProfileUrl(adapterId, adapterType)
              }
              title={hardwareOption.name}
            />
          </>
        ) : (
          <>
            <BreadcrumbItem to={getEngineHardwareProfilesUrl()} title="Hardware Profiles" />
            <BreadcrumbItem
              to={
                hardwareId != null
                  ? getEngineHardwareProfileUrl(hardwareId)
                  : getEngineNewHardwareProfileUrl()
              }
              title={hardwareOption.name}
            />
          </>
        )}
        {error && (
          <ViewAlert isOpen={true} color="danger" toggle={this.onCloseAlert}>
            <ViewAlertContent>
              <IconSevere />
              {typeof error === "string" ? error : `${error.code} ${error.reason}`}
            </ViewAlertContent>
          </ViewAlert>
        )}
        <ViewContentScrollable>
          <ViewContentContainer maxWidth="848px">
            <FormTitle>{hardwareId ? "Edit" : "Insert"} Profile</FormTitle>
            <FormView noValidate>
              <FormGroup row>
                <Label md="2" for="name">
                  Profile
                </Label>
                <Col md="10">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    onChange={this.onChangeName}
                    value={hardwareOption.name}
                    required
                    invalid={!validProfile}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="2" for="color">
                  Color
                </Label>
                <Col md="10">
                  <ColorPicker color={hardwareOption.color} onChange={this.onChangeColor} />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="2" for="comment">
                  Comment
                </Label>
                <Col md="10">
                  <Input
                    type="text"
                    name="comment"
                    id="comment"
                    onChange={this.onChangeComment}
                    value={hardwareOption.comment}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={{ size: 10, offset: 2 }}>
                  <CheckGroup
                    type="checkbox"
                    name="slicing"
                    id="slicing"
                    onChange={this.onChangeSlicing}
                    checked={hardwareOption.slicing}
                  >
                    Slice packets (all channels)
                  </CheckGroup>
                </Col>
              </FormGroup>
              {(engineCapabilities === null ||
                !engineCapabilities.capabilities.includes(
                  EngineCapabilities.hardwareFilterDynamicSlicing
                ) ||
                hardwareOption.sliceOffset === undefined) && (
                <Collapse isOpen={hardwareOption.slicing}>
                  <FormGroup row noMargin>
                    <Col md={{ size: 10, offset: 2 }}>
                      <SubSection>
                        <HorizontalFormGroup>
                          <span>Slice packets from the beginning of each packet</span>
                        </HorizontalFormGroup>
                        <HorizontalFormGroup>
                          <span>plus an additional</span>
                          <Input
                            type="number"
                            name="sliceLength"
                            id="sliceLength"
                            onChange={this.onChangeSliceLength}
                            value={hardwareOption.sliceLength}
                            min={SLICE_LENGTH_MIN}
                            max={SLICE_LENGTH_MAX}
                            step={1}
                            invalid={!validSliceLength}
                          />
                          <span>
                            bytes (must be between {formatInteger(SLICE_LENGTH_MIN)} and{" "}
                            {formatInteger(SLICE_LENGTH_MAX)} inclusive)
                          </span>
                        </HorizontalFormGroup>
                      </SubSection>
                    </Col>
                  </FormGroup>
                </Collapse>
              )}
              {engineCapabilities !== null &&
                engineCapabilities.capabilities.includes(
                  EngineCapabilities.hardwareFilterDynamicSlicing
                ) &&
                hardwareOption.sliceOffset !== undefined && (
                  <Collapse isOpen={hardwareOption.slicing}>
                    <FormGroup row noMargin>
                      <Col md={{ size: 10, offset: 2 }}>
                        <SubSection>
                          <HorizontalFormGroup>
                            <span>Slice packets</span>
                            <Select
                              name={`sliceOffset${hardwareOption.sliceOffset}`}
                              id={`sliceOffset${hardwareOption.sliceOffset}`}
                              value={hardwareOption.sliceOffset}
                              onChange={this.onChangeSliceOffset}
                            >
                              <option value={SliceOffsetType.SLICE_OFFSET_START_OF_PACKET}>
                                from the beginning of each packet
                              </option>
                              <option value={SliceOffsetType.SLICE_OFFSET_LAYER_3_HEADER}>
                                up to and including Layer 3
                              </option>
                              <option value={SliceOffsetType.SLICE_OFFSET_LAYER_4_HEADER}>
                                up to and including Layer 4
                              </option>
                              <option value={SliceOffsetType.SLICE_OFFSET_LAYER_4_PAYLOAD}>
                                from the beginning of the Layer 4 payload
                              </option>
                              <option value={SliceOffsetType.SLICE_OFFSET_INNER_LAYER_3_HEADER}>
                                up to and including inner Layer 3
                              </option>
                              <option value={SliceOffsetType.SLICE_OFFSET_INNER_LAYER_4_HEADER}>
                                up to and including inner Layer 4
                              </option>
                              <option value={SliceOffsetType.SLICE_OFFSET_INNER_LAYER_4_PAYLOAD}>
                                from the beginning of the inner Layer 4 payload
                              </option>
                            </Select>
                          </HorizontalFormGroup>
                          {(hardwareOption.sliceOffset ===
                            SliceOffsetType.SLICE_OFFSET_START_OF_PACKET ||
                            hardwareOption.sliceOffset ===
                              SliceOffsetType.SLICE_OFFSET_LAYER_4_PAYLOAD ||
                            hardwareOption.sliceOffset ===
                              SliceOffsetType.SLICE_OFFSET_INNER_LAYER_4_PAYLOAD) && (
                            <HorizontalFormGroup>
                              <span>plus an additional</span>
                              <Input
                                type="number"
                                name="sliceLength"
                                id="sliceLength"
                                onChange={this.onChangeSliceLength}
                                value={hardwareOption.sliceLength}
                                min={SLICE_LENGTH_MIN}
                                max={SLICE_LENGTH_MAX}
                                step={1}
                                invalid={!validSliceLength}
                              />
                              <span>
                                bytes (must be between {formatInteger(SLICE_LENGTH_MIN)} and{" "}
                                {formatInteger(SLICE_LENGTH_MAX)} inclusive)
                              </span>
                            </HorizontalFormGroup>
                          )}
                        </SubSection>
                      </Col>
                    </FormGroup>
                  </Collapse>
                )}
              {engineCapabilities !== null &&
                engineCapabilities.capabilities.includes(
                  EngineCapabilities.hardwareFilterDeduplication
                ) && (
                  <FormGroup row noMargin>
                    <Col md={{ size: 10, offset: 2 }}>
                      <HorizontalFormGroup
                        style={{ marginBottom: validDeduplication ? "0px" : "8px" }}
                      >
                        <CheckGroup
                          type="checkbox"
                          name="deduplication"
                          id="deduplication"
                          onChange={this.onChangeDeduplication}
                          checked={hardwareOption.deduplication}
                          invalid={!validDeduplication}
                        >
                          Discard duplicate packets
                        </CheckGroup>
                      </HorizontalFormGroup>
                      {!validDeduplication && (
                        <ErrorText style={{ marginLeft: "1.5rem", marginTop: "0.5rem" }}>
                          (An existing capture using this hardware profile does not support
                          discarding duplicate packets)
                        </ErrorText>
                      )}
                    </Col>
                  </FormGroup>
                )}
              {hardwareOption.filter && (
                <>
                  <FormGroup row>
                    <Col md={{ size: 10, offset: 2 }}>
                      <CheckGroup
                        type="checkbox"
                        name="oneChannelFilter"
                        id="oneChannelFilter"
                        onChange={this.onChangeOneChannelFilter}
                        checked={hardwareOption.filter.oneChannelFilter}
                      >
                        Apply Channel 1 settings to all channels
                      </CheckGroup>
                    </Col>
                  </FormGroup>
                  {hardwareOption.filter.channelFilters.length > 0 && (
                    <FormGroup row>
                      <Col md={{ size: 10, offset: 2 }}>{channelForms}</Col>
                    </FormGroup>
                  )}
                </>
              )}
            </FormView>
          </ViewContentContainer>
        </ViewContentScrollable>
        <ViewFooter>
          <LightButton id="cancel" style={{ marginLeft: "auto" }} onClick={this.onCancel}>
            Cancel
          </LightButton>
          <PrimaryButton
            id="ok"
            onClick={this.onOK}
            disabled={
              !validProfile ||
              !validDeduplication ||
              !validSliceLength ||
              !validAddress ||
              !validMPLS ||
              !validPort ||
              !validValues ||
              !validVLAN
            }
          >
            OK
          </PrimaryButton>
        </ViewFooter>
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  engineCapabilities: getCapabilities(state),
})

export default connect(mapStateToProps)(HardwareProfileView)
