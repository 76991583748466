import * as React from "react"
import { StaticContext } from "react-router"
import { Prompt, RouteComponentProps } from "react-router-dom"
import { produce } from "immer"
import { cloneDeep, isEqual, toNumber } from "lodash"
import { connect } from "react-redux"
import FontAwesome from "react-fontawesome"
import { Helmet } from "react-helmet"
import { Col, FormGroup } from "reactstrap"
import { SortDirection, SortDirectionType, TableCellProps, TableRowProps } from "react-virtualized"
import styled from "styled-components"
import { v4 as uuid } from "uuid"
import { ApplicationsModal } from "./ApplicationsModal"
import { NodesModal } from "./NodesModal"
import { ProtocolsModal } from "./ProtocolsModal"
import { SummaryModal } from "./SummaryModal"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { PrimaryButton, SecondaryDangerButton, LightButton } from "../common/Buttons"
import { ErrorText } from "../common/ErrorText"
import { ButtonBar, FormTitle, FormView, HorizontalFormGroup, Label } from "../common/Form"
import { IconSevere } from "../common/Icons"
import { Input } from "../common/Input"
import { OmniTable } from "../common/OmniTable"
import { Select } from "../common/Select"
import {
  View,
  ViewContentScrollable,
  ViewContentContainer,
  ViewFooter,
  ViewAlert,
  ViewAlertContent,
} from "../common/View"
import { getEngineGraphsUrl } from "../../routes"
import {
  fetchApplications,
  fetchGraphStats,
  fetchGraphs,
  fetchProtocols,
  setGraph,
} from "../../api/api"
import {
  ApplicationGraphItemObject,
  ApplicationInfo,
  GraphCollection,
  GraphItemObject,
  GraphObject,
  NodeGraphItemObject,
  Protocol,
  ProtocolGraphItemObject,
  SummaryStatisticGraphItemObject,
  SummaryStatisticsObjectSnapshot,
} from "../../api/types"
import {
  GraphItemUnitType,
  PeekNodeStatsItemType,
  PeekSummaryStatType,
} from "../../api/types/peekTypes"
import { getEngine, getAuthToken } from "../../store"
import { sortApplications, sortProtocols } from "../../utils/sortUtils"

const columnDesc = [
  {
    dataKey: "clsid",
    label: "Type",
    width: 200,
    flexGrow: 1,
  },
  {
    dataKey: "name",
    label: "Name",
    width: 200,
    flexGrow: 1,
  },
  {
    dataKey: "unitType",
    label: "Units",
    width: 200,
    flexGrow: 1,
  },
  {
    dataKey: "description",
    label: "Description",
    width: 200,
    flexGrow: 1,
  },
  {
    dataKey: "buttons",
    label: "",
    width: 72,
    flexShrink: 0,
    disableSort: true,
  },
]

const ButtonStrip = styled.div`
  display: flex;
  justify-content: flex-end;

  & button + button {
    margin-left: 4px;
  }
`

export const ViewContentEx = styled.div`
  flex-grow: 1;
  min-height: 350px;
`

export const defaultGraph: GraphObject = {
  clsid: "5D41B4CA-DDA7-4733-AFA8-545DE3D99E50",
  description: "",
  graphItems: [],
  id: "",
  sampleCount: 0,
  sampleInterval: 0,
  startTime: "1970-01-01T00:00:00.000000Z",
  templateId: "",
  title: "Untitled",
}

type RouteInfo = {
  graphId: string
}

type LocationState = {
  graph?: GraphObject
}

type GraphObjectEx = {
  graph: GraphObject | null
  unitType: number
}

type GraphTemplatesEditViewProps = RouteComponentProps<RouteInfo, StaticContext, LocationState> & {
  engine: string
  authToken: string
}

type GraphTemplatesEditViewState = {
  graph: GraphObject | null
  past: GraphObjectEx[]
  future: GraphObjectEx[]
  error: any | null
  showPrompt: boolean
  applications: ApplicationInfo[] | null
  graphStats: SummaryStatisticsObjectSnapshot | null
  protocols: Protocol[] | null
  isProtocolsOpen: boolean
  unitType: number
  sortBy: string
  sortDirection: SortDirectionType
  showApplicationModal: boolean
  showNodeModal: boolean
  showProtocolModal: boolean
  showSummaryModal: boolean
}

class GraphTemplatesEditView extends React.Component<
  GraphTemplatesEditViewProps,
  GraphTemplatesEditViewState
> {
  state: GraphTemplatesEditViewState = {
    graph: null,
    past: [],
    future: [],
    error: null,
    showPrompt: true,
    applications: null,
    graphStats: null,
    protocols: null,
    isProtocolsOpen: true,
    unitType: GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_BYTES,
    sortBy: "name",
    sortDirection: SortDirection.ASC,
    showApplicationModal: false,
    showNodeModal: false,
    showProtocolModal: false,
    showSummaryModal: false,
  }

  componentDidMount() {
    this.onRefresh()

    const { engine, authToken } = this.props
    const requestApplications = fetchApplications(engine, authToken)
    const requestGraphStats = fetchGraphStats(engine, authToken)
    const requestProtocols = fetchProtocols(engine, authToken)
    Promise.all([requestApplications, requestGraphStats, requestProtocols])
      .then(([applications, graphStats, protocols]) => {
        if (applications.applications) {
          applications.applications.sort(sortApplications)
        }

        if (protocols.protocols) {
          protocols.protocols.sort(sortProtocols)
        }

        this.setState({
          applications: applications.applications,
          graphStats: graphStats.snapshot,
          protocols: protocols.protocols,
        })
      })
      .catch(error => {
        this.setState({ error })
      })
  }

  onRefresh = () => {
    const { engine, authToken } = this.props
    const { graphId } = this.props.match.params
    fetchGraphs(engine, authToken)
      .then((graphs: GraphCollection) => {
        if (graphs && Array.isArray(graphs.graphs)) {
          let graph: GraphObject | undefined
          if (graphId) {
            graph = graphs.graphs.find(graph => graph.templateId === graphId)
          } else {
            if (this.props.location.state && this.props.location.state.graph !== undefined) {
              graph = cloneDeep(this.props.location.state.graph)
            } else {
              graph = cloneDeep(defaultGraph)
            }
            if (graph) {
              graph.id = uuid().toUpperCase()
              graph.templateId = uuid().toUpperCase()
            }
          }
          if (graph) {
            let error = null
            if (this.state.graph) {
              if (!isEqual(graph, this.state.graph)) {
                error = "The graph has changed on the server"
              }
              graph = this.state.graph
            } else {
              graph = cloneDeep(graph)
            }
            let unitType = GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_BYTES
            if (Array.isArray(graph.graphItems) && graph.graphItems.length > 0) {
              unitType = graph.graphItems[0].unitType
            }
            const { sortBy, sortDirection } = this.state
            this.sort(graph, sortBy, sortDirection)
            this.setState({ graph, unitType, error })
          }
        }
      })
      .catch(error => {
        this.setState({ error })
      })
  }

  onChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      produce(draft => {
        if (draft.graph) {
          draft.past.push({ graph: cloneDeep(draft.graph), unitType: draft.unitType })
          draft.graph.title = value
        }
      })
    )
  }

  onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      produce(draft => {
        if (draft.graph) {
          draft.past.push({ graph: cloneDeep(draft.graph), unitType: draft.unitType })
          draft.graph.description = value
        }
      })
    )
  }

  onOK = () => {
    this.onSubmit()
  }

  onCancel = () => {
    this.setState({ showPrompt: false }, () => {
      this.props.history.goBack()
    })
  }

  onSubmit = () => {
    if (this.state.graph) {
      const graph = cloneDeep(this.state.graph)

      const { engine, authToken } = this.props
      setGraph(engine, authToken, graph)
        .then(() => {
          this.setState({ showPrompt: false }, () => {
            this.props.history.goBack()
          })
        })
        .catch(error => {
          this.setState({ error })
        })
    }
  }

  onUndo = () => {
    // 1. Remove the last element from past
    // 2. Set the present to the element we removed from the previous step
    // 3. Insert the old present state at the beginning of the future
    const { graph, past, future, unitType } = this.state
    if (graph && past.length > 0) {
      const previous = past[past.length - 1]
      const newPast = past.slice(0, past.length - 1)
      this.setState({
        graph: previous.graph,
        unitType: previous.unitType,
        past: newPast,
        future: [{ graph: cloneDeep(graph), unitType }, ...future],
      })
    }
  }

  onRedo = () => {
    // 1. Remove the first element from the future
    // 2. Set the present to the element we removed from the previous step
    // 3. Insert the old present state at the end of the past
    const { graph, past, future, unitType } = this.state
    if (graph && future.length > 0) {
      const next = future[0]
      const newFuture = future.slice(1)
      this.setState({
        graph: next.graph,
        unitType: next.unitType,
        past: [...past, { graph: cloneDeep(graph), unitType }],
        future: newFuture,
      })
    }
  }

  onCloseAlert = () => {
    this.setState({ error: null })
  }

  generateUnitsString = (graphItem: GraphItemObject) => {
    let content = ""
    switch (graphItem.unitType) {
      case GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_BYTES:
        content = "Bytes"
        break
      case GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_PACKETS:
        content = "Packets"
        break
      default:
        break
    }
    if (graphItem.clsid === "B1DAFDE2-68F2-4D35-AAA7-5861B35A3E9F") {
      switch ((graphItem as NodeGraphItemObject).nodeStatsType) {
        case PeekNodeStatsItemType.PEEK_NODE_STATS_ITEM_TYPE_SENT:
          content += " From"
          break
        case PeekNodeStatsItemType.PEEK_NODE_STATS_ITEM_TYPE_RECEIVED:
          content += " To"
          break
        case PeekNodeStatsItemType.PEEK_NODE_STATS_ITEM_TYPE_BROADCAST:
          content = "Broadcast " + content
          break
        case PeekNodeStatsItemType.PEEK_NODE_STATS_ITEM_TYPE_MULTICAST:
          content = "Multicast " + content
          break
        case PeekNodeStatsItemType.PEEK_NODE_STATS_ITEM_TYPE_TOTAL:
          content = "Total " + content
          break
        default:
          break
      }
    }
    return content
  }

  onChangeUnitsType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    this.setState(
      produce(draft => {
        if (draft.graph) {
          draft.past.push({ graph: cloneDeep(draft.graph), unitType: draft.unitType })
          draft.graph.graphItems = draft.graph.graphItems.map((graphItem: GraphItemObject) => {
            return {
              ...graphItem,
              unitType: toNumber(value),
            }
          })
          draft.unitType = toNumber(value)
        }
      })
    )
  }

  onDelete = (id: string, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    this.setState(
      produce(draft => {
        if (draft.graph) {
          draft.past.push({ graph: cloneDeep(draft.graph), unitType: draft.unitType })
          draft.graph.graphItems = draft.graph.graphItems.filter(
            (graphItem: GraphItemObject) => graphItem.id !== id
          )
        }
      })
    )
  }

  onOpenGraphItems = (clsid: string) => {
    switch (clsid) {
      case "588BBDEF-59E8-428E-929B-1422520A0F8B":
        this.setState({ showApplicationModal: true })
        break
      case "B1DAFDE2-68F2-4D35-AAA7-5861B35A3E9F":
        this.setState({ showNodeModal: true })
        break
      case "A541981C-E2E4-4BA5-8429-C71207EF9E6B":
        this.setState({ showProtocolModal: true })
        break
      case "0BDD4628-5AFD-4D26-8909-EC371724F51E":
        this.setState({ showSummaryModal: true })
        break
      default:
        break
    }
  }

  onOpenGraphItemsCancel = (clsid: string) => {
    switch (clsid) {
      case "588BBDEF-59E8-428E-929B-1422520A0F8B":
        this.setState({ showApplicationModal: false })
        break
      case "B1DAFDE2-68F2-4D35-AAA7-5861B35A3E9F":
        this.setState({ showNodeModal: false })
        break
      case "A541981C-E2E4-4BA5-8429-C71207EF9E6B":
        this.setState({ showProtocolModal: false })
        break
      case "0BDD4628-5AFD-4D26-8909-EC371724F51E":
        this.setState({ showSummaryModal: false })
        break
      default:
        break
    }
  }

  onOpenGraphItemsSubmit = (clsid: string, graphItems: GraphItemObject[]) => {
    switch (clsid) {
      case "588BBDEF-59E8-428E-929B-1422520A0F8B":
        this.setState({ showApplicationModal: false })
        break
      case "B1DAFDE2-68F2-4D35-AAA7-5861B35A3E9F":
        this.setState({ showNodeModal: false })
        break
      case "A541981C-E2E4-4BA5-8429-C71207EF9E6B":
        this.setState({ showProtocolModal: false })
        break
      case "0BDD4628-5AFD-4D26-8909-EC371724F51E":
        this.setState({ showSummaryModal: false })
        break
      default:
        break
    }
    this.setState(
      produce(draft => {
        if (draft.graph) {
          draft.past.push({ graph: cloneDeep(draft.graph), unitType: draft.unitType })
          draft.graph.graphItems = draft.graph.graphItems
            .filter((graphItem: GraphItemObject) => graphItem.clsid !== clsid)
            .concat(graphItems)
        }
      })
    )
  }

  onSort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
    // Should really be sorting in componentDidUpdate when sortBy/Direction change
    // but since we're sorting in place this is easier and avoid extra renders.
    this.sort(this.state.graph, sortBy, sortDirection)
    this.setState({ sortBy, sortDirection })
  }

  sort(graph: GraphObject | null, sortBy: string, sortDirection: SortDirectionType) {
    if (graph) {
      const collator = new Intl.Collator(undefined, { sensitivity: "base", numeric: true })
      graph.graphItems.sort((a: GraphItemObject, b: GraphItemObject) => {
        if (sortBy === "clsid") {
          const clsidList = [
            { id: "588BBDEF-59E8-428E-929B-1422520A0F8B", name: "Application" },
            { id: "B1DAFDE2-68F2-4D35-AAA7-5861B35A3E9F", name: "Node" },
            { id: "A541981C-E2E4-4BA5-8429-C71207EF9E6B", name: "Protocol" },
            { id: "0BDD4628-5AFD-4D26-8909-EC371724F51E", name: "Summary" },
          ]
          let aName = a.clsid as string
          const aItem = clsidList.find(item => item.id === a[sortBy])
          if (aItem !== undefined) {
            aName = aItem.name
          }
          let bName = b.clsid as string
          const bItem = clsidList.find(item => item.id === b[sortBy])
          if (bItem !== undefined) {
            bName = bItem.name
          }
          let result = collator.compare(aName, bName)
          if (result === 0) {
            // Fall back to name.
            result = collator.compare(a.name, b.name)
          }
          if (sortDirection === SortDirection.DESC) result = -result
          return result
        } else if (sortBy === "unitType") {
          const aUnitType = this.generateUnitsString(a)
          const bUnitType = this.generateUnitsString(b)
          let result = collator.compare(aUnitType, bUnitType)
          if (result === 0) {
            // Fall back to name.
            result = collator.compare(a.name, b.name)
          }
          if (sortDirection === SortDirection.DESC) result = -result
          return result
        } else {
          let result = collator.compare(a[sortBy], b[sortBy])
          if (result === 0) {
            // Fall back to name.
            result = collator.compare(a.name, b.name)
          }
          if (sortDirection === SortDirection.DESC) result = -result
          return result
        }
      })
    }
  }

  rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    key = rowData.id

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content
    const isGraphable = this.isGraphable(rowData)
    switch (dataKey) {
      case "clsid":
        switch (rowData.clsid) {
          case "588BBDEF-59E8-428E-929B-1422520A0F8B":
            content = "Application"
            break
          case "B1DAFDE2-68F2-4D35-AAA7-5861B35A3E9F":
            content = "Node"
            break
          case "A541981C-E2E4-4BA5-8429-C71207EF9E6B":
            content = "Protocol"
            break
          case "0BDD4628-5AFD-4D26-8909-EC371724F51E":
            content = "Summary"
            break
          default:
            break
        }
        break
      case "unitType":
        content = this.generateUnitsString(rowData)
        break
      case "name":
      case "description":
        content = cellData
        break
      case "buttons":
        content = (
          <ButtonStrip>
            <SecondaryDangerButton size="sm" onClick={this.onDelete.bind(this, rowData.id)}>
              <FontAwesome name="trash-o" /> Delete
            </SecondaryDangerButton>
          </ButtonStrip>
        )
        break
      default:
        break
    }
    if (!isGraphable) {
      return <ErrorText as="div">{content}</ErrorText>
    } else {
      return content
    }
  }

  isGraphable = (graphItemObj: GraphItemObject) => {
    const { unitType } = this.state
    let graphable = false
    if (graphItemObj.statisticType === undefined) {
      graphable = true
    } else {
      if (
        graphItemObj.statisticType === PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_VALUE_PAIR ||
        graphItemObj.statisticType === PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_INT ||
        graphItemObj.statisticType === PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_DOUBLE
      ) {
        graphable = true
      } else {
        // if we are graphing bytes then only make graphable those items that all bytes, and same rule for packets
        if (
          unitType === GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_PACKETS &&
          graphItemObj.statisticType === PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_PACKETS
        ) {
          graphable = true
        } else if (
          unitType === GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_BYTES &&
          graphItemObj.statisticType === PeekSummaryStatType.PEEK_SUMMARY_STAT_TYPE_BYTES
        ) {
          graphable = true
        }
      }
    }
    return graphable
  }

  render() {
    const {
      graph,
      graphStats,
      past,
      future,
      error,
      applications,
      protocols,
      unitType,
      showApplicationModal,
      showNodeModal,
      showProtocolModal,
      showPrompt,
      showSummaryModal,
      sortBy,
      sortDirection,
    } = this.state
    if (!graph) return null

    const applicationGraphItems: ApplicationGraphItemObject[] = graph.graphItems.filter(
      graphItem => graphItem.clsid === "588BBDEF-59E8-428E-929B-1422520A0F8B"
    ) as ApplicationGraphItemObject[]

    const nodeGraphItems: NodeGraphItemObject[] = graph.graphItems.filter(
      graphItem => graphItem.clsid === "B1DAFDE2-68F2-4D35-AAA7-5861B35A3E9F"
    ) as NodeGraphItemObject[]

    const protocolGraphItems: ProtocolGraphItemObject[] = graph.graphItems.filter(
      graphItem => graphItem.clsid === "A541981C-E2E4-4BA5-8429-C71207EF9E6B"
    ) as ProtocolGraphItemObject[]

    const summaryGraphItems: SummaryStatisticGraphItemObject[] = graph.graphItems.filter(
      graphItem => graphItem.clsid === "0BDD4628-5AFD-4D26-8909-EC371724F51E"
    ) as SummaryStatisticGraphItemObject[]

    const renderUnitsTypeDropDown = (unitTypeNew: number) => {
      const labels = [
        { id: GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_BYTES, text: "Bytes" },
        { id: GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_PACKETS, text: "Packets" },
      ]
      const dropDownLabels = labels.map(label => (
        <option key={label.id} value={label.id}>
          {label.text}
        </option>
      ))
      if (dropDownLabels.length > 0) {
        return (
          <Select
            name="unitsType"
            id="unitsType"
            value={unitTypeNew}
            onChange={this.onChangeUnitsType}
          >
            {dropDownLabels}
          </Select>
        )
      } else {
        return null
      }
    }

    let validGraphItems = false
    if (graph && Array.isArray(graph.graphItems)) {
      validGraphItems = graph.graphItems.every((graphItemObj: GraphItemObject) =>
        this.isGraphable(graphItemObj)
      )
    }

    return (
      <View padding={false}>
        <Prompt
          when={showPrompt && past.length > 0}
          message="Warning! Changes have not been saved. Discard changes?"
        />
        <Helmet title={graph.title} />
        <BreadcrumbItem to={getEngineGraphsUrl()} title="Graphs" />
        <BreadcrumbItem to={this.props.match.url} title={graph.title} />
        {error && (
          <ViewAlert isOpen={true} color="danger" toggle={this.onCloseAlert}>
            <ViewAlertContent>
              <IconSevere />
              {typeof error === "string" ? error : `${error.code} ${error.reason}`}
            </ViewAlertContent>
          </ViewAlert>
        )}
        <ViewContentScrollable>
          <ViewContentContainer maxWidth="848px">
            <FormTitle>
              {this.props.match.params.graphId !== undefined ? "Edit" : "Insert"} Graph
            </FormTitle>
            <FormView noValidate>
              <FormGroup row>
                <Label md="2" for="name">
                  Name
                </Label>
                <Col md="10">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    onChange={this.onChangeTitle}
                    value={graph.title}
                    required
                    invalid={graph.title === undefined || graph.title.length === 0}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="2" for="description">
                  Comment
                </Label>
                <Col md="10">
                  <Input
                    type="text"
                    name="description"
                    id="description"
                    onChange={this.onChangeDescription}
                    value={graph.description}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="2" for="unitsType">
                  Units
                </Label>
                <Col md="10">
                  <HorizontalFormGroup noMargin>
                    {renderUnitsTypeDropDown(unitType)}
                  </HorizontalFormGroup>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={{ size: 10, offset: 2 }}>
                  <ViewContentEx>
                    <OmniTable
                      data={graph.graphItems}
                      rowHeight={31}
                      rowCount={graph.graphItems.length}
                      columnDesc={columnDesc}
                      rowRenderer={this.rowRenderer}
                      cellRenderer={this.cellRenderer}
                      sort={this.onSort}
                      sortBy={sortBy}
                      sortDirection={sortDirection}
                    />
                  </ViewContentEx>
                </Col>
              </FormGroup>
              {!validGraphItems && (
                <FormGroup row>
                  <Col md={{ size: 10, offset: 2 }}>
                    <ErrorText as="div">
                      The currently selected units do not support the graph items in red
                    </ErrorText>
                  </Col>
                </FormGroup>
              )}
              <FormGroup row noMargin>
                <Col md={{ size: 10, offset: 2 }}>
                  <ButtonBar>
                    <LightButton
                      size="sm"
                      onClick={this.onOpenGraphItems.bind(
                        this,
                        "588BBDEF-59E8-428E-929B-1422520A0F8B"
                      )}
                    >
                      Add/Remove Applications
                    </LightButton>
                    <LightButton
                      size="sm"
                      onClick={this.onOpenGraphItems.bind(
                        this,
                        "B1DAFDE2-68F2-4D35-AAA7-5861B35A3E9F"
                      )}
                    >
                      Add Node
                    </LightButton>
                    <LightButton
                      size="sm"
                      onClick={this.onOpenGraphItems.bind(
                        this,
                        "A541981C-E2E4-4BA5-8429-C71207EF9E6B"
                      )}
                    >
                      Add/Remove Protocols
                    </LightButton>
                    <LightButton
                      size="sm"
                      onClick={this.onOpenGraphItems.bind(
                        this,
                        "0BDD4628-5AFD-4D26-8909-EC371724F51E"
                      )}
                    >
                      Add/Remove Summaries
                    </LightButton>
                  </ButtonBar>
                </Col>
              </FormGroup>
              {showApplicationModal && applications !== null && (
                <ApplicationsModal
                  applications={applications}
                  clsid={"588BBDEF-59E8-428E-929B-1422520A0F8B"}
                  graphItems={applicationGraphItems}
                  unitType={unitType}
                  onCancel={this.onOpenGraphItemsCancel}
                  onSubmit={this.onOpenGraphItemsSubmit}
                />
              )}
              {showNodeModal && (
                <NodesModal
                  clsid={"B1DAFDE2-68F2-4D35-AAA7-5861B35A3E9F"}
                  graphItems={nodeGraphItems}
                  unitType={unitType}
                  onCancel={this.onOpenGraphItemsCancel}
                  onSubmit={this.onOpenGraphItemsSubmit}
                />
              )}
              {showProtocolModal && protocols !== null && (
                <ProtocolsModal
                  clsid={"A541981C-E2E4-4BA5-8429-C71207EF9E6B"}
                  graphItems={protocolGraphItems}
                  protocols={protocols}
                  unitType={unitType}
                  onCancel={this.onOpenGraphItemsCancel}
                  onSubmit={this.onOpenGraphItemsSubmit}
                />
              )}
              {showSummaryModal && graphStats !== null && (
                <SummaryModal
                  clsid={"0BDD4628-5AFD-4D26-8909-EC371724F51E"}
                  graphItems={summaryGraphItems}
                  graphStats={graphStats}
                  unitType={unitType}
                  onCancel={this.onOpenGraphItemsCancel}
                  onSubmit={this.onOpenGraphItemsSubmit}
                />
              )}
            </FormView>
          </ViewContentContainer>
        </ViewContentScrollable>
        <ViewFooter>
          <LightButton id="undo" disabled={past.length === 0} onClick={this.onUndo}>
            <FontAwesome name="undo" /> Undo
          </LightButton>
          <LightButton id="redo" disabled={future.length === 0} onClick={this.onRedo}>
            <FontAwesome name="repeat" /> Redo
          </LightButton>
          <LightButton id="cancel" style={{ marginLeft: "auto" }} onClick={this.onCancel}>
            Cancel
          </LightButton>
          <PrimaryButton
            id="ok"
            disabled={
              !graph ||
              graph.title.length === 0 ||
              graph.graphItems.length === 0 ||
              !validGraphItems
            }
            onClick={this.onOK}
          >
            OK
          </PrimaryButton>
        </ViewFooter>
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
})

export default connect(mapStateToProps)(GraphTemplatesEditView)
