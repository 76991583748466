import * as React from "react"
import { Col, FormGroup } from "reactstrap"
import { SecondaryButton, PrimaryButton } from "../common/Buttons"
import { Input } from "../common/Input"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"

export type ForensicSearchEditNameModalProps = {
  title?: React.ReactNode
  searchName: string
  id: string
  onOk: (id: string, name: string) => void
  onCancel: () => void
  show: boolean
  size?: string | null
}

export const ForensicSearchEditNameModal: React.FC<ForensicSearchEditNameModalProps> = ({
  title,
  searchName,
  id,
  onOk,
  onCancel,
  show,
  size,
}) => {
  const [newForensicName, setNewForensicName] = React.useState(searchName)

  const onOkClicked = () => {
    onOk(id, newForensicName)
  }

  const onForensicSearchNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewForensicName(event.target.value)
  }

  const onForensicSearchNameKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onOk(id, newForensicName)
    } else if (event.key === "Escape") {
      onCancel()
    }
  }

  return (
    <Modal size={size === null ? undefined : size} isOpen={show}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Col md="20">
            <Input
              type="text"
              name={`fs-md-${id}-name`}
              id={`fs-md-${id}-name`}
              aria-label="Edit Name"
              autoFocus
              value={newForensicName}
              onChange={onForensicSearchNameChange.bind(this)}
              onKeyUp={onForensicSearchNameKeyUp.bind(this)}
            />
          </Col>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton disabled={newForensicName === ""} onClick={onOkClicked}>
          OK
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  )
}

export default ForensicSearchEditNameModal
