import styled from "styled-components"
import PropTable from "../../common/PropTable"
import { HalfWidthWidget, WidgetHeader, WidgetTitle, WidgetBody, NoData } from "../Dashboard"
import { formatInteger, formatFloat, formatDuration } from "../../../utils/formatUtils"

const CallSummaryTables = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @supports (-ms-ime-align: auto) {
    /* Edge only */
    justify-content: space-around;
  }
`

const callSummaryTable1 = [
  "totalCalls",
  "currentCalls",
  "openCalls",
  "closedCalls",
  "avgCallDuration",
]

const callSummaryTable2 = [
  "totalMediaPacketLossPercent",
  "voicePacketLossPercent",
  "videoPacketLossPercent",
  "mosCQ",
  "mosV",
]

function getCallSummaryDisplayNames(columnId: string): string {
  switch (columnId) {
    case "totalCalls":
      return "Total calls"
    case "currentCalls":
      return "Current calls"
    case "openCalls":
      return "Open calls"
    case "closedCalls":
      return "Closed calls"
    case "avgCallDuration":
      return "Avg. call duration"
    case "totalMediaPacketLossPercent":
      return "Total media packet loss"
    case "voicePacketLossPercent":
      return "Voice packet loss"
    case "videoPacketLossPercent":
      return "Video packet loss"
    case "mosCQ":
      return "MOS-CQ"
    case "mosV":
      return "MOS-V"
    default:
      break
  }
  return ""
}

function formatCallSummaryData(columnId: string, data: any): string {
  const value = data[columnId]
  switch (columnId) {
    case "totalCalls":
    case "currentCalls":
    case "openCalls":
    case "closedCalls":
      if (value !== undefined) {
        return formatInteger(value)
      }
      break
    case "avgCallDuration":
      if (value !== undefined) {
        return formatDuration(value)
      }
      break
    case "totalMediaPacketLossPercent":
    case "voicePacketLossPercent":
    case "videoPacketLossPercent":
      if (value !== undefined) {
        return `${formatFloat(value, 3)}%`
      }
      break
    case "mosCQ":
    case "mosV":
      if (value !== undefined) {
        return formatFloat(value, 2)
      }
      break
    default:
      break
  }
  return ""
}

const CallSummaryWidget = ({ callSummary }: { callSummary: any }) => (
  <HalfWidthWidget>
    <WidgetHeader>
      <WidgetTitle>Call Summary</WidgetTitle>
    </WidgetHeader>
    <WidgetBody minHeight="166px">
      {callSummary ? (
        <CallSummaryTables>
          <PropTable
            title="Call Counters"
            propList={callSummaryTable1}
            data={callSummary}
            propToLabel={getCallSummaryDisplayNames}
            formatProp={formatCallSummaryData}
          />
          <PropTable
            title="Closed Call Statistics"
            propList={callSummaryTable2}
            data={callSummary}
            propToLabel={getCallSummaryDisplayNames}
            formatProp={formatCallSummaryData}
          />
        </CallSummaryTables>
      ) : (
        <NoData>No Data</NoData>
      )}
    </WidgetBody>
  </HalfWidthWidget>
)

export default CallSummaryWidget
