import * as React from "react"

export const IconSevere = (props: React.SVGAttributes<SVGElement>) => (
  <svg
    viewBox="0 0 10240 10240"
    shapeRendering="geometricPrecision"
    height={16}
    width={16}
    {...props}
  >
    <path
      d="M5120 640c2474 0 4480 2006 4480 4480S7594 9600 5120 9600 640 7594 640 5120 2646 640 5120 640zm1829 2149c-126-126-331-125-455 3L5072 4250 3651 2792c-125-128-329-129-455-3l-508 508c-124 123-125 324-3 449l1439 1477-1439 1476c-122 125-121 326 3 450l508 507c126 126 330 125 455-3l1421-1458 1422 1458c124 128 329 129 455 3l508-507c123-124 125-325 2-450L6020 5223l1439-1477c123-125 121-326-2-449l-508-508z"
      fill="#a60c0c"
    />
  </svg>
)
