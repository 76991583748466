import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { cloneDeep, remove, flatten } from "lodash"
import { v4 as uuid } from "uuid"
import styled, { withTheme, DefaultTheme } from "styled-components"
import FontAwesome from "react-fontawesome"
import FileSaver from "file-saver"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { defaultAlarm } from "../AlarmsEditView"
import { defaultGraph } from "../GraphTemplatesEditView"
import { DropdownMenu, DropdownItem } from "../common/Dropdown"
import { SortDirection, SortDirectionType, TableCellProps } from "react-virtualized"
import { OmniTable } from "../common/OmniTable"
import { View, ViewContent, ViewHeader, ViewHeaderTitle, ViewHeaderButtons } from "../common/View"
import { LightButton, IconDropdownToggle } from "../common/Buttons"
import { UncontrolledDropdownWithPortal } from "../common/Dropdown"
import Interval from "../common/Interval"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import FilterBox from "../common/FilterBox"
import BarGauge from "../common/BarGauge"
import {
  formatInteger,
  formatFloat,
  formatISODateTime,
  formatDuration,
} from "../../utils/formatUtils"
import csvStringify from "../../utils/csvStringify"
import {
  getCaptureForensicSearchUrl,
  getEngineNewFilterUrl,
  getEngineNewAlarmUrl,
  getEngineNewGraphUrl,
  getNewDistributedForensicSearchUrl,
} from "../../routes"
import {
  getEngine,
  getAuthToken,
  getAppStatsFilter,
  getAppStatsColumns,
  getAppStatsSortBy,
  getAppStatsSortDirection,
  getAppStatsViewType,
  getCapabilities,
  getUserId,
  getShowLocalTime,
} from "../../store"
import { setCurrentEngine } from "../../store/engines"
import {
  setAppStatsFilter,
  setAppStatsColumns,
  setAppStatsSort,
  setAppStatsViewType,
} from "../../store/ui"
import { setSelectPacketsTask } from "../../store/selectPackets"
import { fetchApplications, fetchCFSStatistics, postSelectRelatedFilterStart } from "../../api/api"
import {
  AlarmInfo,
  ApplicationInfo,
  ApplicationStatistics,
  ApplicationStatisticsObject,
  Filter,
  GraphObject,
  RequestPostSelectRelatedFilterStart,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineCapabilities, EngineUserPolicies } from "../../api/types/engineTypes"
import { GraphItemUnitType, PeekApplicationStatType } from "../../api/types/peekTypes"
import ApplicationDescriptionSidebar from "./ApplicationDescriptionSidebar"
import { RowMouseEventHandlerParams } from "react-virtualized/dist/es/Table"
import { Select } from "../common/Select"
import TreeTable, { HierarchyDataItem } from "../TreeTable"

enum ViewType {
  APPLICATIONS = "Applications",
  HIERARCHY = "Hierarchy",
}

const defaultColumns = [
  {
    dataKey: "name",
    label: "Application",
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "category",
    label: "Category",
    width: 200,
    flexGrow: 1,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "productivity",
    label: "Productivity",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "risk",
    label: "Risk",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "bytesPercent",
    label: "Bytes %",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: true,
  },
  {
    dataKey: "packetsPercent",
    label: "Packets %",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: false,
    visible: false,
  },
  {
    dataKey: "bytes",
    label: "Bytes",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "packets",
    label: "Packets",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: true,
  },
  {
    dataKey: "firstTime",
    label: "First Time",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "lastTime",
    label: "Last Time",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
  {
    dataKey: "duration",
    label: "Duration",
    width: 140,
    flexGrow: 0,
    flexShrink: 1,
    alignRight: true,
    visible: false,
  },
]

const unsupportedPace2Columns = ["productivity", "risk"] as const

const CommandStrip = styled.div`
  display: flex;
`

type ApplicationStatisticsViewProps = RouteComponentProps<CaptureRouteParams> &
  CaptureViewProps & {
    dispatch: Function
    engine: string
    authToken: string
    engineCapabilities: ResponseGetEngineCapabilities | null
    filter: string
    columns: any[]
    sortBy: string
    sortDirection: SortDirectionType
    theme: DefaultTheme
    userId: string
    viewType: ViewType
    showLocalTime: boolean
  }

type ApplicationStatisticsViewState = {
  applicationDescriptions: ApplicationInfo[] | null
  applicationStatistics: ApplicationStatistics | null
  showApplicationSidebarDescription: string | number | null
  hierarchyData: HierarchyDataItem[]
  expandedData: string[]
  isPace2: boolean
}

class ApplicationStatisticsView extends React.Component<
  ApplicationStatisticsViewProps,
  ApplicationStatisticsViewState
> {
  state: ApplicationStatisticsViewState = {
    applicationDescriptions: null,
    applicationStatistics: null,
    showApplicationSidebarDescription: null,
    hierarchyData: [],
    expandedData: [],
    isPace2:
      this.props.engineCapabilities != null &&
      this.props.engineCapabilities.capabilities.includes(
        EngineCapabilities.ipoquePace2Applications
      ),
  }

  componentDidMount() {
    // Start by fetching the app descriptions.
    const { engine, authToken } = this.props
    fetchApplications(engine, authToken)
      .then(applications => {
        if (applications && applications.applications) {
          this.setState({ applicationDescriptions: applications.applications }, () => {
            // Initial refresh of the app statistics.
            this.onRefresh()
          })
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onRefresh = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSStatistics<ApplicationStatistics>(engine, authToken, type, capId, "application")
      .then(applicationStatistics => {
        if (applicationStatistics && applicationStatistics.applications) {
          const { sortBy, sortDirection } = this.props
          this.sort(applicationStatistics.applications, sortBy, sortDirection)
        }
        this.setState({ applicationStatistics }, () => {
          const { viewType } = this.props
          if (viewType === ViewType.HIERARCHY) {
            this.generateHierarchyData()
          }
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  generateHierarchyData = () => {
    const { applicationStatistics, applicationDescriptions } = this.state
    if (!applicationStatistics || !applicationDescriptions) return
    const applications = cloneDeep(applicationStatistics.applications)
    const existedCategory = new Set()
    let hierarchyData: HierarchyDataItem[] = []
    applications.forEach(app => {
      const appDescr = applicationDescriptions.find(a => app.id === a.id)
      if (!appDescr || existedCategory.has(appDescr.category)) return
      existedCategory.add(appDescr.category)
      hierarchyData.push({
        id: appDescr.category,
        name: appDescr.category,
        children: [],
      })
    })
    hierarchyData = hierarchyData.map(data => {
      let additionalFields: {
        isGroup: boolean
        bytes: number
        packets: number
        productivity?: number
        risk?: number
        duration: number
        firstTime: string
        lastTime: string
      } = {
        isGroup: true,
        bytes: 0,
        packets: 0,
        productivity: undefined,
        risk: undefined,
        duration: 0,
        firstTime: "0",
        lastTime: "0",
      }
      const children = remove(applications, app => {
        const appDescr = applicationDescriptions.find(a => app.id === a.id)
        const isChild = appDescr?.category === data.id
        // This block only needed to add fields to category row at hierarchy table
        if (isChild && appDescr) {
          // Time formatting
          const parsedFirstTime = new Date(app.firstTime).getTime()
          const parsedCurrentFirstTime =
            additionalFields.firstTime === "0"
              ? new Date(app.firstTime).getTime()
              : new Date(additionalFields.firstTime).getTime()
          const parsedLastTime = new Date(app.lastTime).getTime()
          const parsedCurrentLastTime =
            additionalFields.lastTime === "0"
              ? new Date(app.lastTime).getTime()
              : new Date(additionalFields.lastTime).getTime()
          //
          additionalFields = {
            isGroup: additionalFields.isGroup,
            bytes: additionalFields.bytes + app.bytes,
            packets: additionalFields.packets + app.packets,
            duration: additionalFields.duration + app.duration,
            productivity: appDescr?.productivity,
            risk: appDescr?.risk,
            firstTime:
              parsedCurrentFirstTime >= parsedFirstTime
                ? app.firstTime
                : additionalFields.firstTime,
            lastTime:
              parsedCurrentLastTime <= parsedLastTime ? app.lastTime : additionalFields.lastTime,
          }
        }
        return isChild
      })
      return {
        ...data,
        children,
        ...additionalFields,
      }
    })
    this.setState({ hierarchyData }, () => {
      const { sortBy, sortDirection } = this.props
      this.sortHierarchy(sortBy, sortDirection)
    })
  }

  onExport = (apps?: ApplicationStatisticsObject[]) => {
    const { applicationStatistics } = this.state
    const { filter, columns } = this.props
    const visibleColumns = columns.filter(col => col.visible)
    let csv = visibleColumns.map(col => csvStringify(col.label)).join(",") + "\n"

    let applications = applicationStatistics && applicationStatistics.applications
    applications = apps !== undefined ? apps : applications
    if (applications && filter) {
      const lowerCaseFilter = filter.toLowerCase()
      applications = applications.filter(application =>
        application.name ? application.name.toLowerCase().includes(lowerCaseFilter) : false
      )
    }

    if (applications) {
      applications.forEach(rowData => {
        const row: any[] = []
        visibleColumns.forEach(col => {
          let content: string | number = ""
          switch (col.dataKey) {
            case "name":
              content = rowData.name || rowData.id
              break
            case "category":
              if (this.state.applicationDescriptions) {
                const app = this.state.applicationDescriptions.find(app => app.id === rowData.id)
                if (app) {
                  content = app.category
                }
              }
              break
            case "productivity":
              if (this.state.applicationDescriptions) {
                const app = this.state.applicationDescriptions.find(app => app.id === rowData.id)
                if (app?.productivity) {
                  content = app.productivity
                }
              }
              break
            case "risk":
              if (this.state.applicationDescriptions) {
                const app = this.state.applicationDescriptions.find(app => app.id === rowData.id)
                if (app?.risk) {
                  content = app.risk
                }
              }
              break
            case "bytesPercent":
              {
                let percentage = 0
                if (this.state.applicationStatistics) {
                  const totalBytes = this.state.applicationStatistics.totalBytes
                  percentage = (rowData.bytes / totalBytes) * 100
                }
                content = formatFloat(percentage, 6)
              }
              break
            case "packetsPercent":
              {
                let percentage = 0
                if (this.state.applicationStatistics) {
                  const totalPackets = this.state.applicationStatistics.totalPackets
                  percentage = (rowData.packets / totalPackets) * 100
                }
                content = formatFloat(percentage, 6)
              }
              break
            case "bytes":
              content = rowData.bytes
              break
            case "packets":
              content = rowData.packets
              break
            case "firstTime":
              content = formatISODateTime(rowData.firstTime, 0, this.props.showLocalTime)
              break
            case "lastTime":
              content = formatISODateTime(rowData.lastTime, 0, this.props.showLocalTime)
              break
            case "duration":
              content = formatDuration(rowData.duration, 6)
              break
            default:
              break
          }
          row.push(csvStringify(content))
        })
        csv += row.join(",") + "\n"
      })
    }

    FileSaver.saveAs(
      new Blob([csv], { type: "text/plain;charset=utf8" }),
      "Application Statistics.csv"
    )
  }

  onExportHierarchy = () => {
    const { hierarchyData } = this.state
    const applications = flatten(
      hierarchyData.map(data => {
        return [...data.children]
      })
    )
    this.onExport(applications)
  }

  onChangeFilter = (filter: string) => {
    this.props.dispatch(setAppStatsFilter(filter))
  }

  onShowDefaultColumns = () => {
    this.props.dispatch(setAppStatsColumns(defaultColumns))
  }

  onShowAllColumns = () => {
    const columns = cloneDeep(this.props.columns)
    columns.forEach(col => {
      col.visible = true
    })
    this.props.dispatch(setAppStatsColumns(columns))
  }

  onToggleColumn = (e: React.ChangeEvent<HTMLInputElement>) => {
    const columns = cloneDeep(this.props.columns)
    const col = columns.find(col => col.dataKey === e.target.name)
    if (col) {
      col.visible = !col.visible
      this.props.dispatch(setAppStatsColumns(columns))
    }
  }

  onSelectRelated = (rowData: ApplicationStatisticsObject) => {
    const body: RequestPostSelectRelatedFilterStart = {
      id: uuid(),
      rootNode: {
        applicationId: rowData.id,
        clsid: "B588E248-AB43-4AB8-AAFB-E2E1BD2EC428",
        comment: "",
        inverted: false,
      },
    }
    const { capId } = this.props.match.params
    const { engine, authToken } = this.props
    postSelectRelatedFilterStart(engine, authToken, capId, body)
      .then(task => {
        if (task.taskId) {
          this.props.dispatch(
            setSelectPacketsTask({ type: "filter", taskId: task.taskId, progress: 0 })
          )
          this.props.history.push("packets")
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onMSA = (rowData: ApplicationStatisticsObject) => {
    if (rowData.name) {
      const filter = `app('${rowData.name}')`
      this.props.dispatch(setCurrentEngine(null))
      this.props.history.push({
        pathname: getNewDistributedForensicSearchUrl(),
        state: { startTime: rowData.firstTime, endTime: rowData.lastTime, filter },
      })
    }
  }

  onMakeFilter = (rowData: ApplicationStatisticsObject) => {
    const filter: Filter = {
      clsid: "22353029-A733-4FCC-8AC0-782DA33FA464",
      color: "#000000", // TODO rowData.color
      comment: "",
      created: "",
      group: "",
      id: "",
      modified: "",
      name: "Untitled",
      rootNode: {
        applicationId: rowData.id,
        clsid: "B588E248-AB43-4AB8-AAFB-E2E1BD2EC428",
        comment: "",
        inverted: false,
      },
    }
    this.props.history.push({
      pathname: getEngineNewFilterUrl(),
      state: { filter },
    })
  }

  onMakeGraph = (rowData: ApplicationStatisticsObject) => {
    const { applicationDescriptions } = this.state

    let description = ""
    if (applicationDescriptions) {
      const app = applicationDescriptions.find(app => app.id === rowData.id)
      if (app !== undefined) {
        description = app.description
      }
    }

    const graph: GraphObject = {
      ...cloneDeep(defaultGraph),
      graphItems: [
        {
          applicationId: rowData.id,
          clsid: "588BBDEF-59E8-428E-929B-1422520A0F8B",
          description,
          id: uuid().toUpperCase(),
          name: rowData.name || String(rowData.id),
          unitType: GraphItemUnitType.GRAPH_ITEM_UNIT_TYPE_BYTES,
        },
      ],
      id: uuid().toUpperCase(),
      templateId: uuid().toUpperCase(),
      title: rowData.name || String(rowData.id),
    }

    this.props.history.push({
      pathname: getEngineNewGraphUrl(),
      state: { graph },
    })
  }

  onMakeAlarm = (rowData: ApplicationStatisticsObject) => {
    const alarm: AlarmInfo = {
      ...cloneDeep(defaultAlarm),
      name: rowData.name || String(rowData.id),
      statisticsTracker: {
        application: cloneDeep(rowData.mediaSpec),
        clsid: "055E270A-CED1-4A68-A98E-B9A4CCE93FA3",
        history: 60,
        statisticsType: PeekApplicationStatType.PEEK_APPLICATION_STAT_TYPE_BYTES,
      },
    }
    this.props.history.push({
      pathname: getEngineNewAlarmUrl(),
      state: { alarm },
    })
  }

  onAppSidebarDescription = (rowData: ApplicationStatisticsObject) => {
    this.setState({ showApplicationSidebarDescription: rowData.id })
  }

  onAppSidebarDescriptionClose = () => {
    this.setState({ showApplicationSidebarDescription: null })
  }

  cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    let content
    switch (dataKey) {
      case "name":
        if (rowData.color != null && this.props.theme.name === "Light") {
          content = (
            <span style={{ color: rowData.color }} title={cellData}>
              {cellData}
            </span>
          )
        } else {
          content = cellData
        }
        break
      case "category":
        if (this.state.applicationDescriptions) {
          const app = this.state.applicationDescriptions.find(app => app.id === rowData.id)
          if (app) {
            content = app.category
          }
        }
        break
      case "productivity":
        if (rowData.productivity !== undefined) {
          content = cellData
          break
        }
        if (this.state.applicationDescriptions) {
          const app = this.state.applicationDescriptions.find(app => app.id === rowData.id)
          if (app?.productivity) {
            content = app.productivity.toString()
          }
        }
        break
      case "risk":
        if (rowData.risk !== undefined) {
          content = cellData
          break
        }
        if (this.state.applicationDescriptions) {
          const app = this.state.applicationDescriptions.find(app => app.id === rowData.id)
          if (app?.risk) {
            content = app.risk.toString()
          }
        }
        break
      case "bytesPercent":
        {
          if (rowData.bytes === undefined) break
          let percentage = 0
          if (this.state.applicationStatistics) {
            const totalBytes = this.state.applicationStatistics.totalBytes
            percentage = (rowData.bytes / totalBytes) * 100
          }
          content = (
            <BarGauge
              aria-label="Percentage of bytes"
              value={percentage}
              max={100}
              color={rowData.color}
              title={`${formatFloat(percentage, 3)}%`}
            />
          )
        }
        break
      case "packetsPercent":
        {
          if (rowData.packets === undefined) break
          let percentage = 0
          if (this.state.applicationStatistics) {
            const totalPackets = this.state.applicationStatistics.totalPackets
            percentage = (rowData.packets / totalPackets) * 100
          }
          content = (
            <BarGauge
              aria-label="Percentage of packets"
              value={percentage}
              max={100}
              color={rowData.color}
              title={`${formatFloat(percentage, 3)}%`}
            />
          )
        }
        break
      case "bytes":
      case "packets":
        content = formatInteger(cellData)
        break
      case "firstTime":
      case "lastTime":
        content = formatISODateTime(cellData, 0, this.props.showLocalTime)
        break
      case "duration":
        content = formatDuration(rowData.duration, 6)
        break
      default:
        break
    }
    return content
  }

  commandCellRenderer = ({ rowData }: TableCellProps) => {
    if (rowData.isGroup) return undefined
    const { captureProperties, engineCapabilities, userId } = this.props
    const packetsDisabled = captureProperties === null || !captureProperties.packetBufferEnabled

    // make sure the user can view packets for this capture or forensic search
    let canCreateForensicSearch = true
    let canUploadFiles = true
    let canViewPackets = true
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      canCreateForensicSearch =
        !engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL) ||
        policies.includes(EngineUserPolicies.createForensicSearch)
      canUploadFiles = policies.includes(EngineUserPolicies.uploadFiles)
      canViewPackets = isUserOwner || policies.includes(EngineUserPolicies.viewPackets)
    }

    return (
      <CommandStrip className="commands">
        <UncontrolledDropdownWithPortal
          dropdownToggle={
            <IconDropdownToggle>
              <FontAwesome name="ellipsis-h" fixedWidth />
            </IconDropdownToggle>
          }
        >
          <DropdownMenu end>
            <DropdownItem
              disabled={packetsDisabled || !canViewPackets}
              onClick={this.onSelectRelated.bind(this, rowData)}
            >
              Select Related Packets
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              disabled={!canUploadFiles || !canCreateForensicSearch}
              onClick={this.onMSA.bind(this, rowData)}
            >
              Multi-Segment Analysis
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.onMakeFilter.bind(this, rowData)}>Make Filter</DropdownItem>
            <DropdownItem onClick={this.onMakeGraph.bind(this, rowData)}>Make Graph</DropdownItem>
            <DropdownItem onClick={this.onMakeAlarm.bind(this, rowData)}>Make Alarm</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdownWithPortal>
      </CommandStrip>
    )
  }

  onSort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
    this.sort(
      this.state.applicationStatistics ? this.state.applicationStatistics.applications : [],
      sortBy,
      sortDirection
    )
    this.props.dispatch(setAppStatsSort(sortBy, sortDirection))
  }

  onSortHierarchy = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string
    sortDirection: SortDirectionType
  }) => {
    this.sortHierarchy(sortBy, sortDirection)
    this.props.dispatch(setAppStatsSort(sortBy, sortDirection))
  }

  sort(
    applications: ApplicationStatisticsObject[],
    sortBy: string,
    sortDirection: SortDirectionType
  ) {
    const collator = new Intl.Collator(undefined, { sensitivity: "base" })
    applications.sort((a, b) => {
      let result = 0

      let valueA = null
      let valueB = null

      switch (sortBy) {
        case "name":
          valueA = a.name || String(a.id)
          valueB = b.name || String(b.id)
          result = collator.compare(valueA, valueB)
          break
        case "category":
          if (this.state.applicationDescriptions) {
            const app1 = this.state.applicationDescriptions.find(app => app.id === a.id)
            if (app1) valueA = app1.category
            const app2 = this.state.applicationDescriptions.find(app => app.id === b.id)
            if (app2) valueB = app2.category
            if (valueA && valueB) {
              result = collator.compare(valueA, valueB)
            }
          }
          break
        case "productivity":
          if (this.state.applicationDescriptions) {
            const app1 = this.state.applicationDescriptions.find(app => app.id === a.id)
            if (app1) valueA = app1.productivity
            const app2 = this.state.applicationDescriptions.find(app => app.id === b.id)
            if (app2) valueB = app2.productivity
          }
          break
        case "risk":
          if (this.state.applicationDescriptions) {
            const app1 = this.state.applicationDescriptions.find(app => app.id === a.id)
            if (app1) valueA = app1.risk
            const app2 = this.state.applicationDescriptions.find(app => app.id === b.id)
            if (app2) valueB = app2.risk
          }
          break
        case "bytesPercent":
          valueA = a.bytes
          valueB = b.bytes
          break
        case "packetsPercent":
          valueA = a.packets
          valueB = b.packets
          break
        default:
          valueA = a[sortBy as keyof ApplicationStatisticsObject]
          valueB = b[sortBy as keyof ApplicationStatisticsObject]
          break
      }

      if (result === 0) {
        if (valueA != null && valueB != null) {
          if (valueA > valueB) {
            result = 1
          } else if (valueA < valueB) {
            result = -1
          }
        }
        if (result === 0) {
          // Fall back to name.
          valueA = a.name || String(a.id)
          valueB = b.name || String(b.id)
          result = collator.compare(valueA, valueB)
        }
      }

      if (sortDirection === SortDirection.DESC) result = -result

      return result
    })
  }

  sortHierarchy = (sortBy: string, sortDirection: SortDirectionType) => {
    const { hierarchyData } = this.state
    let tableData = cloneDeep(hierarchyData)
    const collator = new Intl.Collator(undefined, { sensitivity: "base" })
    tableData.sort((a, b) => {
      let result = 0

      let valueA = null
      let valueB = null
      switch (sortBy) {
        case "bytesPercent":
          valueA = a.bytes
          valueB = b.bytes
          break
        case "packetsPercent":
          valueA = a.packets
          valueB = b.packets
          break
        default:
          valueA = a[sortBy as keyof ApplicationStatisticsObject]
          valueB = b[sortBy as keyof ApplicationStatisticsObject]
          break
      }
      if (result === 0) {
        if (valueA != null && valueB != null) {
          if (valueA > valueB) {
            result = 1
          } else if (valueA < valueB) {
            result = -1
          }
        }
        if (result === 0) {
          // Fall back to name.
          valueA = a.name || a.id
          valueB = b.name || b.id
          result = collator.compare(valueA, valueB)
        }
      }

      if (sortDirection === SortDirection.DESC) result = -result

      return result
    })

    // Sort inner children applications of the category group
    tableData = tableData.map(data => {
      this.sort(data.children, sortBy, sortDirection)
      return data
    })
    this.setState({ hierarchyData: tableData })
  }

  onRowClick = ({ rowData }: RowMouseEventHandlerParams) => {
    if (rowData.isGroup) return
    this.onAppSidebarDescription(rowData)
  }

  onChangeViewType = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.dispatch(setAppStatsViewType(e.target.value))
    const { sortBy, sortDirection } = this.props
    if (e.target.value === ViewType.APPLICATIONS) {
      this.sort(
        this.state.applicationStatistics ? this.state.applicationStatistics.applications : [],
        sortBy,
        sortDirection
      )
    }
    if (e.target.value === ViewType.HIERARCHY) {
      if (this.state.hierarchyData.length) {
        this.sortHierarchy(sortBy, sortDirection)
      } else {
        this.generateHierarchyData()
      }
    }
  }

  onExpandAll = () => {
    const { hierarchyData } = this.state
    this.setState({ expandedData: hierarchyData.map(row => row.id) })
  }

  onCollapseAll = () => {
    this.setState({ expandedData: [] })
  }

  renderTable = (applications: ApplicationStatisticsObject[] | null) => {
    const { isPace2 } = this.state
    const { columns, sortBy, sortDirection, filter } = this.props
    if (applications && filter) {
      const lowerCaseFilter = filter.toLowerCase()
      applications = applications.filter(application =>
        application.name ? application.name.toLowerCase().includes(lowerCaseFilter) : false
      )
    }
    const filteredColumns = isPace2
      ? columns.filter(column => !unsupportedPace2Columns.includes(column.dataKey))
      : columns
    return (
      applications && (
        <OmniTable
          data={applications}
          rowCount={applications.length}
          columnDesc={filteredColumns}
          cellRenderer={this.cellRenderer}
          onRowClick={this.onRowClick}
          renderCommands={this.commandCellRenderer}
          onShowDefaultColumns={this.onShowDefaultColumns}
          onShowAllColumns={this.onShowAllColumns}
          onToggleColumn={this.onToggleColumn}
          sort={this.onSort}
          sortBy={sortBy}
          sortDirection={sortDirection}
        />
      )
    )
  }

  renderHierarchyTable = () => {
    const { hierarchyData, expandedData, isPace2 } = this.state
    const { columns, sortBy, sortDirection, filter } = this.props
    const filteredColumns = columns.filter(
      column =>
        column.dataKey !== "category" &&
        !(isPace2 && unsupportedPace2Columns.includes(column.dataKey))
    )

    const filteredHierarchyData = cloneDeep(hierarchyData)
    return (
      <TreeTable
        data={filteredHierarchyData}
        filter={filter}
        expandedRows={expandedData}
        columnDesc={filteredColumns}
        cellRenderer={this.cellRenderer}
        onRowClick={this.onRowClick}
        renderCommands={this.commandCellRenderer}
        onShowDefaultColumns={this.onShowDefaultColumns}
        onShowAllColumns={this.onShowAllColumns}
        onToggleColumn={this.onToggleColumn}
        sort={this.onSortHierarchy}
        sortBy={sortBy}
        sortDirection={sortDirection}
      />
    )
  }

  render() {
    const { applicationStatistics, applicationDescriptions, showApplicationSidebarDescription } =
      this.state
    const { captureProperties, engineCapabilities, filter, userId, viewType } = this.props

    // make sure the user can view stats for this capture or forensic search
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
      if (!canViewStats) {
        const { type, capId } = this.props.match.params
        return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
      }
    }

    const applications = applicationStatistics && applicationStatistics.applications
    const count = applications ? applications.length : 0
    return (
      <View>
        <BreadcrumbItem to={this.props.match.url} title="Application Statistics" />
        <Interval timeout={30000} enabled={true} callback={this.onRefresh} />
        <ViewHeader>
          <ViewHeaderTitle title="Applications" count={count} />
          <ViewHeaderButtons>
            <Select
              name="viewType"
              id="viewType"
              aria-label="View Type"
              value={viewType}
              onChange={this.onChangeViewType}
              style={{ width: "auto" }}
            >
              <option>{ViewType.APPLICATIONS}</option>
              <option>{ViewType.HIERARCHY}</option>
            </Select>
            {viewType === ViewType.HIERARCHY && (
              <>
                <LightButton id="expand-all" onClick={this.onExpandAll}>
                  Expand All
                </LightButton>
                <LightButton id="collapse-all" onClick={this.onCollapseAll}>
                  Collapse All
                </LightButton>
              </>
            )}
            <FilterBox
              aria-label="Search"
              placeholder="Search"
              onChange={this.onChangeFilter}
              value={filter}
            />
            <LightButton
              aria-label="Export"
              id="export"
              onClick={
                viewType === ViewType.APPLICATIONS
                  ? this.onExport.bind(this, undefined)
                  : this.onExportHierarchy
              }
            >
              <FontAwesome name="download" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="export">
              Export
            </UncontrolledTooltip>
            <LightButton aria-label="Refresh" id="refresh" onClick={this.onRefresh}>
              <FontAwesome name="refresh" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="refresh">
              Refresh
            </UncontrolledTooltip>
          </ViewHeaderButtons>
        </ViewHeader>
        <ViewContent>
          {viewType === ViewType.APPLICATIONS
            ? this.renderTable(applications)
            : this.renderHierarchyTable()}
        </ViewContent>
        <ApplicationDescriptionSidebar
          isOpen={showApplicationSidebarDescription !== null}
          application={showApplicationSidebarDescription}
          descriptions={applicationDescriptions}
          onClose={this.onAppSidebarDescriptionClose}
        />
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  engineCapabilities: getCapabilities(state) || null,
  filter: getAppStatsFilter(state) || "",
  columns: getAppStatsColumns(state) || defaultColumns,
  sortBy: getAppStatsSortBy(state) || "bytesPercent",
  sortDirection: getAppStatsSortDirection(state) || SortDirection.DESC,
  userId: getUserId(state),
  viewType: getAppStatsViewType(state) || ViewType.APPLICATIONS,
  showLocalTime: getShowLocalTime(state),
})

export default connect(mapStateToProps)(withTheme(ApplicationStatisticsView))
