export enum PeekDomain {
  PEEK_DOMAIN_FCC = 0x10, // US (North America)
  PEEK_DOMAIN_IC = 0x20, // ??
  PEEK_DOMAIN_ETSI = 0x30, // European Telecommunications Standards Institute
  PEEK_DOMAIN_SPAIN = 0x31,
  PEEK_DOMAIN_FRANCE = 0x32,
  PEEK_DOMAIN_MKK = 0x40, // Japan
}

export enum PeekWanProtocol {
  PEEK_WAN_PROT_INVALID = 0x00, // Not a valid Protocol
  PEEK_WAN_PROT_0800 = 0x08, // 0800 TCP/IP only zsdlc.com (Logix Legacy)
  PEEK_WAN_PROT_PPP = 0x10, // PPP
  PEEK_WAN_PROT_FRLY = 0x23, // Frame Relay
  PEEK_WAN_PROT_X25 = 0x25, // X.25
  PEEK_WAN_PROT_X25E = 0x29, // X.25 mod 128
  PEEK_WAN_PROT_Q931 = 0x31, // W.931
  PEEK_WAN_PROT_SNA = 0x40, // 6940 zsdlc
  PEEK_WAN_PROT_IPARS = 0x50, // 6950 zipars.com
  PEEK_WAN_PROT_U200 = 0x60, // 6960 zu200.com
  PEEK_WAN_PROT_SS7 = 0x70, // 6970 zsdlc
}

export enum PeekWirelessSpec {
  PEEK_802_11_B = 0x0000, // 802.11b
  PEEK_802_11_A = 0x0001, // 802.11a
  PEEK_802_11_GEN = 0x0002, // 802.11 gen
}
