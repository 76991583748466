import * as React from "react"
import styled, { withTheme } from "styled-components"
import PercentChart from "../common/PercentChart"
import { NoData } from "../Dashboard/Dashboard"

export const TopStatsViewType = {
  PHYSICAL: "physical",
  IP: "ip",
  IPV6: "ipv6",
  PROTOCOLS: "protocols",
  APPLICATIONS: "applications",
}

const TopStatsViewTitle = {
  [TopStatsViewType.PHYSICAL]: "Top Talkers by Physical Address",
  [TopStatsViewType.IP]: "Top Talkers by IP Address",
  [TopStatsViewType.IPV6]: "Top Talkers by IPv6 Address",
  [TopStatsViewType.PROTOCOLS]: "Top Protocols by Bytes",
  [TopStatsViewType.APPLICATIONS]: "Top Applications by Bytes",
}

export const ForensicsTopStats = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;

  @media (max-width: 768px) {
    grid-template-columns: 100%;
  }
`

const Widget = styled.div`
  display: flex;
  flex-direction: column;
`

const ChartTitle = styled.h5`
  margin: 0;
  text-align: center;
`

const ChartWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 220px;
`

type ChartProps = {
  theme: any
  viewType: string
  data: any
  chartType: string
  chartScale: string
}

class Chart extends React.Component<ChartProps> {
  render() {
    const { viewType, data, chartType, chartScale } = this.props
    return (
      <Widget>
        <ChartTitle>{TopStatsViewTitle[viewType]}</ChartTitle>
        <ChartWrapper>
          {data && data.entries.length > 0 ? (
            <PercentChart
              data={data.entries}
              height={220}
              chartType={chartType}
              fixedScale={chartScale === "fixed"}
              valueKey="bytes"
              totalKey="totalBytes"
              theme={this.props.theme}
            />
          ) : (
            <NoData>No Data</NoData>
          )}
        </ChartWrapper>
      </Widget>
    )
  }
}

export const ForensicsTopStatsWidget = withTheme(Chart)
