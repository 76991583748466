import * as React from "react"

// Copied from https://github.com/nkbt/react-works/tree/master/packages/interval
// with patch for issue #139 applied

type IntervalProps = {
  enabled?: boolean
  timeout?: number
  callback: () => void
}

export class Interval extends React.Component<IntervalProps> {
  static defaultProps = {
    enabled: false,
    timeout: 1000,
  }

  timer: any | null = null

  componentDidMount() {
    if (this.props.enabled) {
      this.start()
    }
  }

  shouldComponentUpdate({ timeout, callback, enabled }: IntervalProps) {
    return (
      this.props.timeout !== timeout ||
      this.props.callback !== callback ||
      this.props.enabled !== enabled
    )
  }

  componentDidUpdate({ enabled, timeout }: IntervalProps) {
    if (this.props.enabled !== enabled || this.props.timeout !== timeout) {
      if (this.props.enabled) {
        this.start()
      } else {
        this.stop()
      }
    }
  }

  componentWillUnmount() {
    this.stop()
  }

  callback = () => {
    if (this.timer) {
      this.props.callback()
      this.start()
    }
  }

  start = () => {
    this.stop()
    this.timer = setTimeout(this.callback, this.props.timeout)
  }

  stop = () => {
    clearTimeout(this.timer)
    this.timer = null
  }

  render = () => false
}

export default Interval
