import styled from "styled-components"
import {
  Modal as ReactstrapModal,
  ModalHeader as ReactstrapModalHeader,
  ModalBody as ReactstrapModalBody,
  ModalFooter as ReactstrapModalFooter,
} from "reactstrap"

export const Modal = styled(ReactstrapModal)`
  & .modal-content {
    color: ${props => props.theme.textColor};
    background-color: ${props => props.theme.modalBackgroundColor};
    padding: 3rem;
  }
`

export const ModalBody = styled(ReactstrapModalBody)`
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.modalBackgroundColor};
  padding: 0;
`

export const ModalHeader = styled(ReactstrapModalHeader)`
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.modalHeaderBackgroundColor};
  border-bottom: none;
  text-transform: ${props => props.theme.modalHeaderTextTransform};
  padding: 0 0 2rem 0;

  & .close,
  & .close:hover {
    color: ${props => props.theme.textColor};
    text-shadow: none;
  }
`

export const ModalFooter = styled(ReactstrapModalFooter)`
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.modalFooterBackgroundColor};
  padding: 2rem 0 0 0;
  border-top: none;

  & > * {
    margin: 0;
  }

  & > :not(:first-child) {
    margin-left: 0.25rem;
  }

  & > :not(:last-child) {
    margin-right: 0.25rem;
  }
`
