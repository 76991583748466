import * as React from "react"
import styled from "styled-components"
import FontAwesome from "react-fontawesome"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import useMedia from "react-use/lib/useMedia"
import { ButtonBar } from "../../common/Form"
import { Link, OutsideLink } from "../../common/Link"
import { MutedText } from "../../common/MutedText"
import { Panel } from "../../common/Panel"
import PropTable from "../../common/PropTable"
import { PropFormatterOptions } from "../../common/PropTable"
import { SecondaryButton, SecondaryDangerButton } from "../../common/Buttons"
import ForensicSearchModal from "../../ForensicSearchModal"
import * as Colors from "../../../themes/colorScheme"
import { download } from "../../../utils/downloadUtils"
import {
  formatDuration,
  formatForensicSearchResult,
  formatKB,
  formatInteger,
  formatISODateTime,
} from "../../../utils/formatUtils"
import {
  getEngineFilesUrl,
  getEngineForensicSearchUrl,
  getNewMultiSegmentAnalysisUrl,
} from "../../../routes"
import { getCapabilities, getShowLocalTime, getUserId } from "../../../store"
import { setCurrentEngine } from "../../../store/engines"
import { removeFileExtension } from "../../../utils/removeFileExtension"
import { EngineCapabilities, EngineUserPolicies } from "../../../api/types/engineTypes"
import { PeekFilterMode, PeekResult } from "../../../api/types/peekTypes"
import { createForensicSearch, getFileDownloadURL } from "../../../api/api"
import {
  DistributedForensicSearchItem,
  DistributedForensicSearchProperties,
  RequestCreateForensicSearch,
  ResponseGetEngineCapabilities,
} from "../../../api/types"

const Search = styled(Panel)`
  display: flex;
  flex-direction: column;

  & + & {
    margin-top: 8px;
  }
`

const SearchTop = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const SearchProperties = styled.div`
  flex-grow: 1;
`

const SearchButtons = styled.div`
  display: flex;
  flex-direction: column;

  & button + button {
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    flex-direction: row;
    margin-top: 8px;

    & button + button {
      margin-left: 4px;
      margin-top: 0;
    }
  }
`

const SearchList = styled.div`
  max-height: 318px;
  overflow: auto;
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.tableBackgroundColor};
  border: ${props => props.theme.tableBorder};
`

const SearchItemStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  border-bottom: 1px solid ${props => props.theme.tableGridColor};
  vertical-align: middle;

  &:nth-child(2n) {
    background-color: ${props => props.theme.tableStripeColor};
  }
`

const ErrorText = styled.div`
  color: ${props => props.theme.errorColor};
`

const SuccessText = styled.div`
  color: ${props => (props.theme.name === "Dark" ? Colors.green400 : props.theme.successColor)};
`

type SearchItemProps = {
  parentSearch: DistributedForensicSearchProperties
  search: DistributedForensicSearchItem
  fileUrl: string
  canDownloadFiles: boolean
}

const SearchItem = ({ parentSearch, search, fileUrl, canDownloadFiles }: SearchItemProps) => {
  const result = search.result >>> 0
  const isCanceled = result === PeekResult.PEEK_RESULT_E_CANCELLED
  let progress: React.ReactNode
  if (search.state === 7) {
    if (isCanceled) {
      progress = <ErrorText>Canceled</ErrorText>
    } else {
      progress = <ErrorText>{formatForensicSearchResult(result)}</ErrorText>
    }
  } else {
    let progressString: React.ReactNode = ""
    switch (search.state) {
      case 0:
        progressString = "Idle"
        break
      case 1:
        if (isCanceled) {
          progressString = "Canceling search"
        } else {
          progressString = "Search"
        }
        break
      case 2:
        if (isCanceled) {
          progressString = "Canceling search"
        } else {
          progressString = `Search progress ${search.progress}%`
        }
        break
      case 3:
        if (isCanceled) {
          progressString = "Canceling saving"
        } else {
          progressString = "Saving"
        }
        break
      case 4:
        progressString = "Deleting search"
        break
      case 5:
        if (isCanceled) {
          progressString = "Canceling download"
        } else {
          progressString = `Downloading progress ${search.progress}%`
        }
        break
      case 6:
        progressString = "Deleting remote file"
        break
      case 7:
        progressString = "Error"
        break
      case 8:
        progressString = <SuccessText>Complete</SuccessText>
        break
      case 9:
        progressString = "Saving"
        break
      case 10:
        progressString = `Saving progress ${search.progress}%`
        break
      case 11:
        progressString = "Canceling saving"
        break
    }
    progress = <div>{progressString}</div>
  }

  const onDownload = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    download(fileUrl, search.fileName)
  }

  let details: React.ReactNode
  if (search.state === 8 && !parentSearch.merge) {
    details = (
      <div>
        File:{" "}
        {canDownloadFiles ? (
          <OutsideLink href="#" role="button" onClick={onDownload}>
            {search.filePath}
          </OutsideLink>
        ) : (
          <>{search.filePath}</>
        )}{" "}
        {formatInteger(search.packetCount)} Packets; {formatKB(search.fileSize)}
      </div>
    )
  } else {
    details = <div>{`${formatInteger(search.packetCount)} Packets`}</div>
  }

  return (
    <SearchItemStyle>
      <div style={{ fontWeight: 500 }}>{search.name}</div>
      {search.engine && <MutedText>{search.engine}</MutedText>}
      {progress}
      {details}
    </SearchItemStyle>
  )
}

function propToLabel(prop: string) {
  switch (prop) {
    case "status":
      return "Status"
    case "startTime":
      return "Search Start Time"
    case "endTime":
      return "Search End Time"
    case "duration":
      return "Search Duration"
    case "slopTime":
      return "\u00b1 Extra Seconds"
    case "filter":
      return "Filter"
    case "created":
      return "Created"
    case "runTime":
      return "Run Time"
    case "storageUsed":
      return "Storage Used"
    case "owner":
      return "Owner"
    default:
      break
  }
  return ""
}

function formatProp(
  prop: string,
  search: DistributedForensicSearchProperties,
  options?: PropFormatterOptions
) {
  switch (prop) {
    case "status":
      switch (search.status) {
        case "idle":
          return "Idle"
        case "running":
          return "Running"
        case "merging":
          return "Merging"
        case "complete":
          return "Complete"
        default:
          break
      }
      return search.status
    case "startTime":
      return formatISODateTime(search.startTime, 3, options?.showLocalTime)
    case "endTime":
      return formatISODateTime(search.endTime, 3, options?.showLocalTime)
    case "duration": {
      const t1 = Date.parse(search.startTime)
      const t2 = Date.parse(search.endTime)
      return formatDuration((t2 - t1) * 1000000)
    }
    case "slopTime":
      return formatInteger(search.slopTime)
    case "filter":
      return search.filter || "None"
    case "created":
      return formatISODateTime(search.created, 0, options?.showLocalTime)
    case "runTime":
      return formatDuration(search.runTime)
    case "storageUsed": {
      let storageUsed = 0
      if (search.merge && search.mergedFileSize != null) {
        storageUsed = search.mergedFileSize
      } else {
        for (const s of search.searches) {
          storageUsed += s.fileSize
        }
      }
      return formatKB(storageUsed)
    }
    case "owner":
      return search.creator || ""
    default:
      break
  }
  return undefined
}

type SearchPanelProps = {
  distributedForensicSearch: DistributedForensicSearchProperties
  url?: string
  server: string
  serverAuthToken: string
  onDelete: () => void
  isDeleting: boolean
  onStop: () => void
  showDetails: boolean
}

const SearchPanel = ({
  distributedForensicSearch,
  url,
  server,
  serverAuthToken,
  onDelete,
  isDeleting,
  onStop,
  showDetails,
}: SearchPanelProps) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const engineCapabilities: ResponseGetEngineCapabilities = useSelector(getCapabilities)
  const showLocalTime = useSelector(getShowLocalTime)
  const userId = useSelector(getUserId)
  const isNarrow = useMedia("(max-width: 768px)")
  const [showForensicSearchModal, setShowForensicSearchModal] = React.useState(false)
  const { status } = distributedForensicSearch
  const isMerging = status === "merging"
  const isComplete = status === "complete"
  const hasSearchResults =
    distributedForensicSearch.searches.findIndex(search => search.packetCount !== 0) !== -1

  const onDownloadAllFiles = () => {
    for (let i = 0; i < distributedForensicSearch.searches.length; i++) {
      const search = distributedForensicSearch.searches[i]
      setTimeout(() => {
        download(
          getFileDownloadURL(server, serverAuthToken, search.filePath, search.fileName),
          search.fileName
        )
      }, i * 1000)
    }
  }

  const onNewMSAProject = () => {
    history.push({
      pathname: getNewMultiSegmentAnalysisUrl(),
      state: { distributedForensicSearch },
    })
  }

  const onForensicSearch = () => {
    setShowForensicSearchModal(true)
  }

  const onForensicSearchCancel = () => {
    setShowForensicSearchModal(false)
  }

  const onForensicSearchOK = (query: RequestCreateForensicSearch) => {
    setShowForensicSearchModal(false)
    query.filterMode = query.filter
      ? PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_MATCHING_ANY
      : PeekFilterMode.PEEK_FILTER_MODE_ACCEPT_ALL
    createForensicSearch(server, serverAuthToken, query)
      .then(response => {
        dispatch(setCurrentEngine(null))
        history.push(getEngineForensicSearchUrl(response.id))
      })
      .catch(error => {
        console.log(error)
      })
  }

  // make sure the user has the correct permissions
  let canUploadFiles = true
  let canDownloadFiles = true
  let canCreateForensicSearch = true
  let canDeleteForensicSearches = true
  if (engineCapabilities) {
    const policies = engineCapabilities.userRights.policies
    canUploadFiles = policies.includes(EngineUserPolicies.uploadFiles)
    canDownloadFiles = policies.includes(EngineUserPolicies.downloadFiles)
    if (engineCapabilities.capabilities.includes(EngineCapabilities.forensicSearchACL)) {
      canCreateForensicSearch = policies.includes(EngineUserPolicies.createForensicSearch)
      canDeleteForensicSearches = policies.includes(EngineUserPolicies.deleteForensicSearches)
    }
  }

  let searchesSection: React.ReactNode
  if (showDetails) {
    searchesSection = (
      <>
        <h5 style={{ marginTop: "1rem" }}>
          Searches{" "}
          <MutedText style={{ fontSize: "1rem", fontWeight: "normal" }}>
            ({formatInteger(distributedForensicSearch.searches.length)})
          </MutedText>
        </h5>
        <SearchList>
          {distributedForensicSearch.searches.map(search => (
            <SearchItem
              key={search.id}
              parentSearch={distributedForensicSearch}
              search={search}
              fileUrl={getFileDownloadURL(
                server,
                serverAuthToken,
                search.filePath,
                search.fileName
              )}
              canDownloadFiles={canDownloadFiles}
            />
          ))}
        </SearchList>
        {!distributedForensicSearch.merge && hasSearchResults && isComplete ? (
          <>
            <ButtonBar style={{ marginTop: "1rem" }}>
              <SecondaryButton
                size="sm"
                disabled={!isComplete || !canUploadFiles || !canCreateForensicSearch}
                onClick={onNewMSAProject}
              >
                New Multi-Segment Analysis Project
              </SecondaryButton>
              <SecondaryButton
                size="sm"
                disabled={!isComplete || !canDownloadFiles}
                onClick={onDownloadAllFiles}
              >
                Download All Files
              </SecondaryButton>
            </ButtonBar>
            <MutedText style={{ marginTop: "1rem" }} as="div">
              <FontAwesome name="info-circle" style={{ opacity: 0.65 }} />
              &nbsp;Files can be managed in the{" "}
              <Link
                to={getEngineFilesUrl()}
                onClick={() => {
                  dispatch(setCurrentEngine(null))
                }}
              >
                Files
              </Link>{" "}
              view. It is now safe to delete this search.
            </MutedText>
          </>
        ) : null}
        {!hasSearchResults && isComplete ? (
          <MutedText style={{ marginTop: "1rem" }} as="div">
            <FontAwesome name="info-circle" style={{ opacity: 0.65 }} />
            &nbsp;No results were found. It is now safe to delete this search.
          </MutedText>
        ) : null}
      </>
    )
  }

  let mergeSection: React.ReactNode
  if (
    showDetails &&
    distributedForensicSearch.merge &&
    hasSearchResults &&
    (isMerging || isComplete)
  ) {
    let mergeDetails: React.ReactNode
    if (isMerging) {
      if (distributedForensicSearch.mergeProgress != null) {
        mergeDetails = (
          <div>{`Merging ${formatInteger(distributedForensicSearch.mergeProgress)}%`}</div>
        )
      }
    } else {
      if (
        distributedForensicSearch.mergedFilePath != null &&
        distributedForensicSearch.mergedFileName != null &&
        distributedForensicSearch.mergedFilePacketCount != null &&
        distributedForensicSearch.mergedFileSize != null
      ) {
        const mergedFileUrl = getFileDownloadURL(
          server,
          serverAuthToken,
          distributedForensicSearch.mergedFilePath,
          distributedForensicSearch.mergedFileName
        )
        mergeDetails = (
          <div>
            File:{" "}
            {canDownloadFiles ? (
              <OutsideLink
                href="#"
                role="button"
                onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                  event.preventDefault()
                  if (distributedForensicSearch.mergedFileName != null) {
                    download(mergedFileUrl, distributedForensicSearch.mergedFileName)
                  }
                }}
              >
                {distributedForensicSearch.mergedFilePath}
              </OutsideLink>
            ) : (
              <>{distributedForensicSearch.mergedFilePath}</>
            )}{" "}
            {formatInteger(distributedForensicSearch.mergedFilePacketCount)} Packets;{" "}
            {formatKB(distributedForensicSearch.mergedFileSize)}
          </div>
        )
      }
    }
    mergeSection = (
      <>
        <h5 style={{ marginTop: "1rem" }}>Merged File</h5>
        {mergeDetails}
        {isComplete ? (
          <>
            <div style={{ marginTop: "0.5rem" }}>
              <SecondaryButton
                size="sm"
                disabled={!isComplete || !canCreateForensicSearch}
                onClick={onForensicSearch}
              >
                Open
              </SecondaryButton>
            </div>
            <MutedText style={{ marginTop: "1rem" }} as="div">
              <FontAwesome name="info-circle" style={{ opacity: 0.65 }} />
              &nbsp;The merged file can be managed in the{" "}
              <Link
                to={getEngineFilesUrl()}
                onClick={() => {
                  dispatch(setCurrentEngine(null))
                }}
              >
                Files
              </Link>{" "}
              view. It is now safe to delete this search.
            </MutedText>
          </>
        ) : null}
      </>
    )
  }

  const propList = isNarrow
    ? [
        "status",
        "startTime",
        "endTime",
        "duration",
        "slopTime",
        "filter",
        "created",
        "runTime",
        "storageUsed",
        "owner",
      ]
    : [
        ["status", "filter"],
        ["startTime", "created"],
        ["endTime", "runTime"],
        ["duration", "storageUsed"],
        ["slopTime", "owner"],
      ]

  return (
    <Search key={distributedForensicSearch.id}>
      <SearchTop>
        <SearchProperties>
          <h5>
            {url && !showDetails ? (
              <Link to={url}>{distributedForensicSearch.name}</Link>
            ) : (
              distributedForensicSearch.name
            )}
          </h5>
          <PropTable
            propList={propList}
            data={distributedForensicSearch}
            propToLabel={propToLabel}
            formatProp={(prop: string, search: DistributedForensicSearchProperties) => {
              return formatProp(prop, search, { showLocalTime: showLocalTime })
            }}
          />
        </SearchProperties>
        <SearchButtons>
          {!isComplete && (
            <SecondaryButton size="sm" onClick={() => onStop()} hidden>
              <FontAwesome fixedWidth name="stop" /> Stop
            </SecondaryButton>
          )}
          <SecondaryDangerButton
            disabled={
              isDeleting ||
              (!canDeleteForensicSearches && userId !== distributedForensicSearch.creatorSID)
            }
            size="sm"
            onClick={() => onDelete()}
          >
            <FontAwesome
              fixedWidth
              name={isDeleting ? "circle-o-notch" : "trash-o"}
              spin={isDeleting}
            />{" "}
            Delete
          </SecondaryDangerButton>
        </SearchButtons>
      </SearchTop>
      {searchesSection}
      {mergeSection}
      {showForensicSearchModal && (
        <ForensicSearchModal
          onCancel={onForensicSearchCancel}
          onOK={onForensicSearchOK}
          startTime={distributedForensicSearch.startTime}
          endTime={distributedForensicSearch.endTime}
          mediaType={distributedForensicSearch.mergedFileMediaType}
          mediaSubType={distributedForensicSearch.mergedFileMediaSubType}
          name={removeFileExtension(
            distributedForensicSearch.mergedFileName || distributedForensicSearch.name
          )}
          file={distributedForensicSearch.mergedFilePath}
        />
      )}
    </Search>
  )
}

export default SearchPanel
