import * as React from "react"
import { Col, FormGroup } from "reactstrap"
import { v4 as uuid } from "uuid"
import { cloneDeep, isEqual, toNumber } from "lodash"
import { OptionsLabel } from "./index"
import { HorizontalFormGroup, SubSection, SubSectionLabel } from "../common/Form"
import { Select } from "../common/Select"
import { AdapterConfiguration, HardwareOptions, WanHardwareOptions } from "../../api/types"
import { PeekWanProtocol } from "../../api/types/driverTypes"

const hardwareConfigWan: WanHardwareOptions = {
  clsid: "F64FCB14-BCA4-4707-8AE9-086349512088",
  color: "#000000",
  comment: "",
  created: "",
  id: "",
  modified: "",
  name: "",
  protocol: PeekWanProtocol.PEEK_WAN_PROT_INVALID,
}

export function getPeekWanProtocolString(protocol: PeekWanProtocol) {
  let protocolString = ""
  switch (protocol) {
    case PeekWanProtocol.PEEK_WAN_PROT_FRLY:
      protocolString = "(Protocol: Frame Relay and LMI)"
      break
    case PeekWanProtocol.PEEK_WAN_PROT_PPP:
      protocolString = "(Protocol: PPP and LCP)"
      break
    default:
      break
  }
  return protocolString
}

type WanFormProps = {
  adapterConfig: AdapterConfiguration
  hardwareOption: WanHardwareOptions | undefined
  setAdapterConfig: (adapterConfig: AdapterConfiguration) => void
  updateHardwareOptions: (hardwareOptions: HardwareOptions[], removeIds: string[]) => void
}

export class WanForm extends React.Component<WanFormProps> {
  componentDidMount() {
    if (!this.props.hardwareOption) {
      const hardwareOption = { ...cloneDeep(hardwareConfigWan), id: uuid().toUpperCase() }
      this.props.updateHardwareOptions([hardwareOption], [])
    }
  }

  shouldComponentUpdate({ adapterConfig, hardwareOption }: WanFormProps) {
    return (
      !isEqual(this.props.adapterConfig, adapterConfig) ||
      !isEqual(this.props.hardwareOption, hardwareOption)
    )
  }

  onChangeProtocol = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { hardwareOption } = this.props
    if (hardwareOption) {
      const value = toNumber(event.target.value)
      this.props.updateHardwareOptions(
        [
          {
            ...hardwareOption,
            protocol: value,
          },
        ],
        []
      )
    }
  }

  render() {
    const { hardwareOption } = this.props
    if (!hardwareOption) return null

    const protocol =
      hardwareOption.protocol === PeekWanProtocol.PEEK_WAN_PROT_FRLY ||
      hardwareOption.protocol === PeekWanProtocol.PEEK_WAN_PROT_PPP
        ? hardwareOption.protocol
        : PeekWanProtocol.PEEK_WAN_PROT_PPP

    return (
      <>
        <FormGroup row>
          <OptionsLabel md="3" for="protocol">
            <SubSectionLabel>Protocol</SubSectionLabel>
          </OptionsLabel>
          <Col md="7">
            <SubSection>
              <HorizontalFormGroup noMargin>
                <Select
                  name="protocol"
                  id="protocol"
                  value={protocol}
                  onChange={this.onChangeProtocol.bind(this)}
                >
                  <option value={PeekWanProtocol.PEEK_WAN_PROT_FRLY}>Frame Relay and LMI</option>
                  <option value={PeekWanProtocol.PEEK_WAN_PROT_PPP}>PPP and LCP</option>
                </Select>
              </HorizontalFormGroup>
            </SubSection>
          </Col>
        </FormGroup>
      </>
    )
  }
}
