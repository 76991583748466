export enum PacketOffset {
  PACKET_OFFSET_DYNOFF_LAYER2_FRAME,
  PACKET_OFFSET_DYNOFF_IPV4_FRAME,
  PACKET_OFFSET_DYNOFF_IPV6_FRAME,
  PACKET_OFFSET_DYNOFF_TCP_UDP_FRAME,
  PACKET_OFFSET_DYNOFF_ETHER_TYPE_FRAME,
  PACKET_OFFSET_DYNOFF_ETHER_TYPE_LEN_FRAME,
}

export interface PacketDataElement {
  dynOffset: number
  offset: number
  size: number
  value: number | string | null
}

export enum HardwareValueLength {
  HARDWARE_VALUE_LENGTH_1_BYTE,
  HARDWARE_VALUE_LENGTH_2_BYTE,
  HARDWARE_VALUE_LENGTH_4_BYTE,
  HARDWARE_VALUE_LENGTH_MAC_ADDRESS,
  HARDWARE_VALUE_LENGTH_IPV4_ADDRESS,
  HARDWARE_VALUE_LENGTH_IPV6_ADDRESS,
  HARDWARE_VALUE_LENGTH_MAXIMUM,
}

export enum HardwareValueOffset {
  HARDWARE_VALUE_OFFSET_BEGINNING_OF_PACKET,
  HARDWARE_VALUE_OFFSET_LAYER_3_HEADER,
  HARDWARE_VALUE_OFFSET_LAYER_4_HEADER,
  HARDWARE_VALUE_OFFSET_LAYER_4_PAYLOAD,
  HARDWARE_VALUE_OFFSET_INNER_LAYER_3_HEADER,
  HARDWARE_VALUE_OFFSET_INNER_LAYER_4_HEADER,
  HARDWARE_VALUE_OFFSET_INNER_LAYER_4_PAYLOAD,
  HARDWARE_VALUE_OFFSET_MAXIMUM,
}

export enum HardwareValueOperator {
  HARDWARE_VALUE_OPERATOR_EQUAL_TO,
  HARDWARE_VALUE_OPERATOR_NOT_EQUAL_TO,
  HARDWARE_VALUE_OPERATOR_LESS_THAN,
  HARDWARE_VALUE_OPERATOR_LESS_THAN_OR_EQUAL_TO,
  HARDWARE_VALUE_OPERATOR_GREATER_THAN,
  HARDWARE_VALUE_OPERATOR_GREATER_THAN_OR_EQUAL_TO,
  HARDWARE_VALUE_OPERATOR_MAXIMUM,
}
