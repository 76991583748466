import * as React from "react"

export type UseClientSize = {
  readonly width: number
  readonly height: number
}
export type UseClientSizeRef<E extends Element = Element> = (element: E) => void
export type UseClientSizeResult<E extends Element = Element> = [UseClientSizeRef<E>, UseClientSize]

const defaultState: UseClientSize = {
  width: 0,
  height: 0,
}

export function useClientSize<E extends Element = Element>(): UseClientSizeResult<E> {
  const [element, ref] = React.useState<E | null>(null)
  const [size, setSize] = React.useState<UseClientSize>(defaultState)

  const observer = React.useMemo(
    () =>
      new ResizeObserver(entries => {
        if (entries[0]) {
          setSize({ width: entries[0].target.clientWidth, height: entries[0].target.clientHeight })
        }
      }),
    []
  )

  React.useLayoutEffect(() => {
    if (!element) return
    observer.observe(element)
    return () => {
      observer.disconnect()
    }
  }, [element, observer])

  return [ref, size]
}
