import styled from "styled-components"
import { Col, FormGroup } from "reactstrap"
import { SecondaryButton } from "../Buttons"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../Modal"
import { Spinner } from "../Spinner"

const CenterCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const WaitModal = ({
  message = "",
  show = false,
  title = "",
  onCancel = undefined,
}: {
  message: string
  show: boolean
  title: string
  onCancel?: () => void
}) => (
  <Modal size="sm" isOpen={show}>
    <ModalHeader>{title}</ModalHeader>
    <ModalBody>
      <FormGroup row>
        <CenterCol md="4">
          <Spinner size="lg" />
        </CenterCol>
        <CenterCol md="8">{message}</CenterCol>
      </FormGroup>
    </ModalBody>
    {onCancel && (
      <ModalFooter>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
      </ModalFooter>
    )}
  </Modal>
)

export default WaitModal
