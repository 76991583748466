import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import FileSaver from "file-saver"
import FontAwesome from "react-fontawesome"
import { AutoSizer } from "react-virtualized"
import CopyToClipboard from "react-copy-to-clipboard"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { View, ViewContent, ViewHeader, ViewHeaderTitle, ViewHeaderButtons } from "../common/View"
import { LightButton } from "../common/Buttons"
import { CenterContent } from "../common/Layout"
import { Spinner } from "../common/Spinner"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import { getEngine, getAuthToken, getCapabilities } from "../../store"
import { fetchSupport } from "../../api/api"
import { ResponseGetEngineCapabilities } from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { getDefaultAdminUrl } from "../../utils/engineUtils"

const SupportViewDiv = styled.pre`
  margin: 0;
  padding: 8px;
  font-size: 1rem;
  tab-size: 2;
  color: ${props => props.theme.textColor};
  background-color: ${props => props.theme.tableBackgroundColor};
  border: ${props => props.theme.tableBorder};
  overflow: auto;
`

type SupportViewProps = RouteComponentProps & {
  engine: string
  authToken: string
  engineCapabilities: ResponseGetEngineCapabilities | null
}

type SupportViewState = {
  fetchError: any | null
  fetchPending: boolean
  supportInfo: string
}

class SupportView extends React.Component<SupportViewProps, SupportViewState> {
  state: SupportViewState = {
    fetchError: null,
    fetchPending: false,
    supportInfo: "",
  }

  componentDidMount() {
    this.onRefresh()
  }

  onRefresh = () => {
    this.setState({ fetchPending: true, fetchError: null, supportInfo: "" })
    const { engine, authToken } = this.props
    fetchSupport(engine, authToken)
      .then((supportInfo: string) => {
        this.setState({ supportInfo, fetchPending: false })
      })
      .catch(error => {
        this.setState({ fetchPending: false, fetchError: error })
      })
  }

  onSave = () => {
    FileSaver.saveAs(
      new Blob([this.state.supportInfo], { type: "text/plain;charset=utf8" }),
      "SupportInformation.txt"
    )
  }

  render() {
    const { engineCapabilities } = this.props
    const { supportInfo, fetchPending } = this.state

    if (!engineCapabilities?.userRights.policies.includes(EngineUserPolicies.configureEngine)) {
      return <Redirect to={`${getDefaultAdminUrl(engineCapabilities)}`} />
    }

    return (
      <View>
        <Helmet title="Support" />
        <BreadcrumbItem to={this.props.match.url} title="Support" />
        <ViewHeader>
          <ViewHeaderTitle title="Support" />
          <ViewHeaderButtons>
            <LightButton
              aria-label="Download"
              id="download"
              disabled={!supportInfo}
              onClick={this.onSave}
            >
              <FontAwesome name="download" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="download">
              Download
            </UncontrolledTooltip>
            <CopyToClipboard text={supportInfo}>
              <LightButton aria-label="Copy to clipboard" id="copy" disabled={!supportInfo}>
                <FontAwesome name="copy" />
              </LightButton>
            </CopyToClipboard>
            <UncontrolledTooltip placement="top" target="copy">
              Copy
            </UncontrolledTooltip>
            <LightButton
              aria-label="Refresh"
              id="refresh"
              disabled={fetchPending}
              onClick={this.onRefresh}
            >
              <FontAwesome name="refresh" />
            </LightButton>
            <UncontrolledTooltip placement="top" target="refresh">
              Refresh
            </UncontrolledTooltip>
          </ViewHeaderButtons>
        </ViewHeader>
        {supportInfo ? (
          <ViewContent>
            <AutoSizer>
              {({ height, width }) => (
                <SupportViewDiv style={{ width, height }}>{supportInfo}</SupportViewDiv>
              )}
            </AutoSizer>
          </ViewContent>
        ) : (
          <CenterContent>
            <Spinner />
          </CenterContent>
        )}
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  engineCapabilities: getCapabilities(state) || null,
})

export default connect(mapStateToProps)(SupportView)
