import styled from "styled-components"
import { ViewContentContainer } from "../../common/View"

export const WizardViewContent = styled(ViewContentContainer)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const WizardHeader = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 2rem;
`

export const WizardHeaderTitleWrapper = styled.div`
  margin-right: 2rem;
`

export const WizardHeaderTitle = styled.h4`
  text-transform: uppercase;
`

export const WizardHeaderSubTitle = styled.h5`
  font-weight: normal;
  margin: 0;
`
