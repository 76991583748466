import * as React from "react"
import { connect } from "react-redux"
import { Redirect, RouteComponentProps } from "react-router-dom"
import BreadcrumbItem from "../BreadcrumbNav/BreadcrumbItem"
import { CaptureRouteParams, CaptureViewProps } from "../Capture"
import { Dashboard } from "../Dashboard/Dashboard"
import Interval from "../common/Interval"
import { View, ViewContent } from "../common/View"
import TopApplicationsByFlowsWidget from "../Dashboard/TopApplicationsByFlowsWidget"
import TopApplicationsByBytesWidget from "../Dashboard/TopApplicationsByBytesWidget"
import TopApplicationCategoriesWidget, {
  TopApplicationByCategory,
} from "../Dashboard/TopApplicationCategoriesWidget"
import ApplicationUtilizationWidget from "../Dashboard/ApplicationUtilizationWidget"
import ApplicationResponseTimeWidget from "../Dashboard/ApplicationResponseTimeWidget"
import { getEngine, getAuthToken, getCapabilities, getUserId } from "../../store"
import { getChartColor, getChartColorCount } from "../../utils/chartUtils"
import { peekFromDate, peekToDate } from "../../utils/formatUtils"
import { djb2 } from "../../utils/stringHash"
import { fetchApplications, fetchCFSStatistics } from "../../api/api"
import {
  ApplicationFlowsStatistics,
  ApplicationFlowsStatisticsObject,
  ApplicationHistoryStatistics,
  ApplicationInfo,
  ApplicationResponseTimesStatistics,
  ApplicationResponseTimesStatisticsObjectSample,
  ApplicationStatistics,
  ApplicationStatisticsObject,
  RequestGetStatisticsStatistic,
  ResponseGetEngineCapabilities,
} from "../../api/types"
import { EngineUserPolicies } from "../../api/types/engineTypes"
import { MediaSpecClass, MediaSpecType } from "../../api/types/mediaTypes"
import { getCaptureForensicSearchUrl } from "../../routes"

type ApplicationsDashboardViewProps = RouteComponentProps<CaptureRouteParams> &
  CaptureViewProps & {
    engine: string
    authToken: string
    engineCapabilities: ResponseGetEngineCapabilities | null
    userId: string
  }

type ApplicationsDashboardViewState = {
  appDescriptions: ApplicationInfo[] | null
  topAppsByFlows: any | null
  topAppsByBytes: any | null
  topAppsByCategory: TopApplicationByCategory[] | null
  appHistory: any | null
  appResponseTimeHistory: any | null
}

class ApplicationsDashboardView extends React.Component<
  ApplicationsDashboardViewProps,
  ApplicationsDashboardViewState
> {
  state: ApplicationsDashboardViewState = {
    appDescriptions: null,
    topAppsByFlows: null,
    topAppsByBytes: null,
    topAppsByCategory: null,
    appHistory: null,
    appResponseTimeHistory: null,
  }

  componentDidMount() {
    this.onRefreshAppDescriptions()
    this.onRefresh()
  }

  onRefreshAppDescriptions = () => {
    const { engine, authToken } = this.props
    fetchApplications(engine, authToken)
      .then(applications => {
        if (applications && applications.applications) {
          this.setState({ appDescriptions: applications.applications }, () =>
            this.onRefreshTopAppsByBytes()
          )
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  onRefresh = () => {
    this.onRefreshTopAppsByFlow()
    this.onRefreshTopAppsByBytes()
    this.onRefreshAppHistory()
    this.onRefreshAppResponseTimeHistory()
  }

  onRefreshTopAppsByFlow = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSStatistics<ApplicationFlowsStatistics>(
      engine,
      authToken,
      type,
      capId,
      "application-flows" as RequestGetStatisticsStatistic
    )
      .then((applicationFlows: ApplicationFlowsStatistics) => {
        let topAppsByFlows = null
        if (
          applicationFlows &&
          applicationFlows.applicationFlows &&
          applicationFlows.applicationFlows.length
        ) {
          topAppsByFlows = applicationFlows.applicationFlows
          topAppsByFlows.sort(
            (a: ApplicationFlowsStatisticsObject, b: ApplicationFlowsStatisticsObject) => {
              if (a.flows < b.flows) return 1
              if (a.flows > b.flows) return -1
              return 0
            }
          )
          if (topAppsByFlows.length > 9) {
            let otherFlows = 0
            for (let i = 9; i < topAppsByFlows.length; ++i) {
              otherFlows += topAppsByFlows[i].flows
            }
            topAppsByFlows = topAppsByFlows.slice(0, 9)
            topAppsByFlows.push({
              id: "Others",
              name: "Others",
              flows: otherFlows,
              color: "#808080",
            })
          }
          const totalFlows = topAppsByFlows.reduce(
            (sum: number, entry: ApplicationFlowsStatisticsObject) => sum + entry.flows,
            0
          )
          topAppsByFlows.forEach((entry: any) => {
            entry.pct = totalFlows !== 0 ? (entry.flows * 100) / totalFlows : 0
            entry.totalFlows = totalFlows
          })
        }
        this.setState({ topAppsByFlows })
      })
      .catch(error => {
        console.error(error)
      })
  }

  onRefreshTopAppsByBytes = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSStatistics<ApplicationStatistics>(
      engine,
      authToken,
      type,
      capId,
      "application" as RequestGetStatisticsStatistic
    )
      .then((applicationStatistics: ApplicationStatistics) => {
        let topAppsByBytes = null
        let topAppsByCategory: TopApplicationByCategory[] | null = null
        if (
          applicationStatistics &&
          applicationStatistics.applications &&
          applicationStatistics.applications.length
        ) {
          topAppsByBytes = applicationStatistics.applications
          topAppsByBytes.sort((a: ApplicationStatisticsObject, b: ApplicationStatisticsObject) => {
            if (a.bytes < b.bytes) return 1
            if (a.bytes > b.bytes) return -1
            return 0
          })
          if (topAppsByBytes.length > 9) {
            let otherBytes = 0
            for (let i = 9; i < topAppsByBytes.length; ++i) {
              otherBytes += topAppsByBytes[i].bytes
            }
            topAppsByBytes = topAppsByBytes.slice(0, 9)
            topAppsByBytes.push({
              id: "Others",
              name: "Others",
              bytes: otherBytes,
              color: "#808080",
              duration: 0,
              firstTime: "",
              lastTime: "",
              packets: 0,
              mediaSpec: {
                data: "",
                msclass: MediaSpecClass.MEDIA_SPEC_CLASS_NULL,
                type: MediaSpecType.MEDIA_SPEC_TYPE_NULL,
              },
            })
          }
          const totalBytes = topAppsByBytes.reduce(
            (sum: number, entry: ApplicationStatisticsObject) => sum + entry.bytes,
            0
          )
          topAppsByBytes.forEach((entry: any) => {
            entry.pct = totalBytes !== 0 ? (entry.bytes * 100) / totalBytes : 0
            entry.totalBytes = totalBytes
          })

          if (this.state.appDescriptions) {
            const categoryHash: Record<string, number> = {}
            for (let i = 0; i < applicationStatistics.applications.length; i++) {
              const app = applicationStatistics.applications[i]
              const desc: ApplicationInfo | undefined = this.state.appDescriptions.find(
                desc => desc.id === app.id
              )
              if (desc) {
                if (desc.category in categoryHash) {
                  categoryHash[desc.category] += app.bytes
                } else {
                  categoryHash[desc.category] = app.bytes
                }
              }
            }
            topAppsByCategory = Object.entries(categoryHash).map(([category, bytes]) => {
              return {
                name: category,
                color: "",
                bytes: bytes,
                totalBytes: 0,
                pct: 0,
              }
            })
            topAppsByCategory.sort((a: any, b: any) => {
              if (a.bytes < b.bytes) return 1
              if (a.bytes > b.bytes) return -1
              return 0
            })
            if (topAppsByCategory.length > 9) {
              let otherBytes = 0
              for (let i = 9; i < topAppsByCategory.length; ++i) {
                otherBytes += topAppsByCategory[i].bytes
              }
              topAppsByCategory = topAppsByCategory.slice(0, 9)
              topAppsByCategory.push({
                name: "Others",
                color: "#808080",
                bytes: otherBytes,
                totalBytes: 0,
                pct: 0,
              })
            }
            const totalCategoryBytes = topAppsByCategory.reduce(
              (sum: number, entry: any) => sum + entry.bytes,
              0
            )
            topAppsByCategory.forEach((entry: any) => {
              entry.pct = totalCategoryBytes !== 0 ? (entry.bytes * 100) / totalCategoryBytes : 0
              entry.totalBytes = totalCategoryBytes
              if (entry.color.length === 0) {
                entry.color = getChartColor(djb2(entry.name) % getChartColorCount())
              }
            })
          }
        }
        this.setState({ topAppsByBytes, topAppsByCategory })
      })
      .catch(error => {
        console.error(error)
      })
  }

  onRefreshAppHistory = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSStatistics<ApplicationHistoryStatistics>(
      engine,
      authToken,
      type,
      capId,
      "application-history" as RequestGetStatisticsStatistic
    )
      .then((applicationHistory: ApplicationHistoryStatistics) => {
        let appHistory = null
        if (
          applicationHistory &&
          applicationHistory.applicationHistory &&
          applicationHistory.applicationHistory.samples &&
          applicationHistory.applicationHistory.samples.length
        ) {
          const applicationHistoryArray = applicationHistory.applicationHistory
          const appHistoryStartTime = peekFromDate(Date.parse(applicationHistoryArray.startTime))
          let apps = []
          for (let i = 0; i < applicationHistoryArray.samples.length; ++i) {
            const sample = applicationHistoryArray.samples[i]
            const appTotal = apps.find(a => a.applicationId === sample.applicationId)
            if (appTotal) {
              appTotal.bytes += sample.bytes
            } else {
              apps.push({
                applicationId: sample.applicationId,
                applicationName: sample.applicationName,
                color: sample.color,
                bytes: sample.bytes,
              })
            }
          }
          apps.sort((a, b) => {
            if (a.bytes < b.bytes) return 1
            if (a.bytes > b.bytes) return -1
            return 0
          })
          if (apps.length > 10) {
            apps = apps.slice(0, 9)
            apps.push({
              applicationId: "*",
              applicationName: "Others",
              color: "#808080",
              bytes: 0,
            })
          }

          const data = []
          let startTime = 0
          let endTime = 0
          for (let i = 0; i < applicationHistoryArray.samples.length; ++i) {
            const sample = applicationHistoryArray.samples[i]
            const appHistorySampleFirstTimestamp = peekFromDate(Date.parse(sample.firstTimestamp))
            const appHistorySampleLastTimestamp = peekFromDate(Date.parse(sample.lastTimestamp))
            if (
              appHistorySampleFirstTimestamp < endTime ||
              appHistorySampleLastTimestamp < endTime
            ) {
              continue
            }

            if (startTime === 0) {
              startTime = Math.floor(appHistorySampleFirstTimestamp / 1000000000) * 1000000000
              if (startTime > appHistoryStartTime) {
                const missingSamples = Math.floor(
                  Math.floor((startTime - appHistoryStartTime) / 1000000000) /
                    applicationHistoryArray.interval
                )
                if (missingSamples > 0) {
                  for (let j = 0; j < missingSamples; ++j) {
                    const emptySample: any = {}
                    apps.forEach(t => {
                      emptySample[t.applicationId] = 0
                    })
                    data.push(emptySample)
                  }
                }
                startTime = appHistoryStartTime
              }
            } else if (appHistorySampleFirstTimestamp > endTime) {
              // TODO
            }

            endTime = appHistorySampleLastTimestamp

            const diff = appHistorySampleLastTimestamp - appHistorySampleFirstTimestamp
            if (diff > 0) {
              const perPacketIFG = 8
              const f = diff / 1000.0

              const datum: any = {}
              for (let k = 0; k < apps.length; k++) {
                datum[apps[k].applicationId] = 0
              }
              let j = i
              while (j < applicationHistoryArray.samples.length) {
                const r = applicationHistoryArray.samples[j]
                if (peekFromDate(Date.parse(r.firstTimestamp)) !== appHistorySampleFirstTimestamp) {
                  j--
                  break
                }
                const mbps = ((r.bytes + r.packets * perPacketIFG) * 8) / f
                let found = false
                for (let k = 0; k < apps.length; k++) {
                  if (r.applicationId === apps[k].applicationId) {
                    datum[r.applicationId] = mbps
                    found = true
                    break
                  }
                }
                if (!found) {
                  datum["*"] += mbps
                }
                j++
              }

              i = j
              data.push(datum)
            }
          }

          appHistory = {
            startTime: peekToDate(
              appHistoryStartTime - applicationHistoryArray.interval * 1000000000
            ),
            interval: applicationHistoryArray.interval * 1000,
            series: apps,
            data: data,
          }
        }
        this.setState({ appHistory })
      })
      .catch(error => {
        console.error(error)
      })
  }

  onRefreshAppResponseTimeHistory = () => {
    const { type, capId } = this.props.match.params
    const { engine, authToken } = this.props
    fetchCFSStatistics<ApplicationResponseTimesStatistics>(
      engine,
      authToken,
      type,
      capId,
      "application-response-times" as RequestGetStatisticsStatistic
    )
      .then((applicationResponseTimes: ApplicationResponseTimesStatistics) => {
        let appResponseTimeHistory = null
        if (
          applicationResponseTimes &&
          applicationResponseTimes.applicationResponseTimes &&
          applicationResponseTimes.applicationResponseTimes.samples &&
          applicationResponseTimes.applicationResponseTimes.samples.length
        ) {
          const applicationResponseTimesArray = applicationResponseTimes.applicationResponseTimes
          const appResponseTimeStartTime = peekFromDate(
            Date.parse(applicationResponseTimesArray.startTime)
          )
          let apps = []
          for (let i = 0; i < applicationResponseTimesArray.samples.length; ++i) {
            const sample: ApplicationResponseTimesStatisticsObjectSample =
              applicationResponseTimesArray.samples[i]
            const appTotal = apps.find(a => a.applicationId === sample.applicationId)
            if (appTotal) {
              appTotal.averageResponseTime += sample.averageResponseTime
            } else {
              apps.push({
                applicationId: sample.applicationId,
                applicationName: sample.applicationName,
                color: sample.color,
                averageResponseTime: sample.averageResponseTime,
              })
            }
          }
          apps.sort((a, b) => {
            if (a.averageResponseTime < b.averageResponseTime) return 1
            if (a.averageResponseTime > b.averageResponseTime) return -1
            return 0
          })
          if (apps.length > 10) {
            apps = apps.slice(0, 10)
          }

          const data = []
          let startTime = 0
          let endTime = 0
          for (let i = 0; i < applicationResponseTimesArray.samples.length; ++i) {
            const sample = applicationResponseTimesArray.samples[i]
            const appResponseTimeSampleFirstTimestamp = peekFromDate(
              Date.parse(sample.firstTimestamp)
            )
            const appResponseTimeSampleLastTimestamp = peekFromDate(
              Date.parse(sample.lastTimestamp)
            )
            if (
              appResponseTimeSampleFirstTimestamp < endTime ||
              appResponseTimeSampleLastTimestamp < endTime
            ) {
              continue
            }

            if (startTime === 0) {
              startTime = Math.floor(appResponseTimeSampleFirstTimestamp / 1000000000) * 1000000000
              if (startTime > appResponseTimeStartTime) {
                const missingSamples = Math.floor(
                  Math.floor((startTime - appResponseTimeStartTime) / 1000000000) /
                    applicationResponseTimesArray.interval
                )
                if (missingSamples > 0) {
                  for (let j = 0; j < missingSamples; ++j) {
                    const emptySample: any = {}
                    apps.forEach(t => {
                      emptySample[t.applicationId] = 0
                    })
                    data.push(emptySample)
                  }
                }
                startTime = appResponseTimeStartTime
              }
            } else if (appResponseTimeSampleFirstTimestamp > endTime) {
              // TODO
            }

            endTime = appResponseTimeSampleLastTimestamp

            const diff = appResponseTimeSampleLastTimestamp - appResponseTimeSampleFirstTimestamp
            if (diff > 0) {
              const datum: any = {}
              for (let k = 0; k < apps.length; k++) {
                datum[apps[k].applicationId] = 0
              }
              let j = i
              while (j < applicationResponseTimesArray.samples.length) {
                const r = applicationResponseTimesArray.samples[j]
                if (r.firstTimestamp !== sample.firstTimestamp) {
                  j--
                  break
                }
                for (let k = 0; k < apps.length; k++) {
                  if (r.applicationId === apps[k].applicationId) {
                    datum[r.applicationId] = r.averageResponseTime
                    break
                  }
                }
                j++
              }

              i = j
              data.push(datum)
            }
          }

          appResponseTimeHistory = {
            startTime: peekToDate(
              appResponseTimeStartTime - applicationResponseTimesArray.interval * 1000000000
            ),
            interval: applicationResponseTimesArray.interval * 1000,
            series: apps,
            data: data,
          }
        }
        this.setState({ appResponseTimeHistory })
      })
      .catch(error => {
        console.error(error)
      })
  }

  render() {
    const { captureProperties, engineCapabilities, userId } = this.props
    const {
      topAppsByFlows,
      topAppsByBytes,
      topAppsByCategory,
      appHistory,
      appResponseTimeHistory,
    } = this.state
    const { type, capId } = this.props.match.params

    // make sure the user can view stats for this capture or forensic search
    if (captureProperties && engineCapabilities) {
      const isUserOwner = userId === captureProperties.creatorSID
      const policies = engineCapabilities.userRights.policies
      const canViewStats = isUserOwner || policies.includes(EngineUserPolicies.viewStats)
      if (!canViewStats) {
        return <Redirect to={`${getCaptureForensicSearchUrl(type, capId)}/home`} />
      }
    }

    return (
      <View>
        <BreadcrumbItem to={this.props.match.url} title="Applications Dashboard" />
        <Interval timeout={10000} enabled={true} callback={this.onRefresh} />
        <ViewContent>
          <Dashboard>
            <TopApplicationsByFlowsWidget topAppsByFlows={topAppsByFlows} />
            <TopApplicationsByBytesWidget topAppsByBytes={topAppsByBytes} />
            <TopApplicationCategoriesWidget topAppsByCategory={topAppsByCategory} />
            <ApplicationUtilizationWidget appHistory={appHistory} />
            <ApplicationResponseTimeWidget appResponseTimeHistory={appResponseTimeHistory} />
          </Dashboard>
        </ViewContent>
      </View>
    )
  }
}

const mapStateToProps = (state: any) => ({
  engine: getEngine(state),
  authToken: getAuthToken(state),
  engineCapabilities: getCapabilities(state) || null,
  userId: getUserId(state),
})

export default connect(mapStateToProps)(ApplicationsDashboardView)
