import * as React from "react"
import styled from "styled-components"
import {
  Dropdown,
  DropdownProps,
  DropdownMenu as ReactstrapDropdownMenu,
  DropdownItem as ReactstrapDropdownItem,
} from "reactstrap"
import Portal from "../Portal"

export const DropdownMenu = styled(ReactstrapDropdownMenu)`
  color: ${props => props.theme.dropdownMenuColor};
  background-color: ${props => props.theme.dropdownMenuBackgroundColor};
  overflow-y: auto;
  max-height: ${window.innerHeight * 0.7}px;
`

type DropdownItemProps = {
  name?: string
  target?: string
  rel?: string
  download?: string
}

export const DropdownItem = styled(ReactstrapDropdownItem)<DropdownItemProps>`
  color: ${props => props.theme.dropdownMenuColor};

  &.dropdown-item:hover,
  &.dropdown-item:focus {
    color: ${props => props.theme.dropdownItemHoverColor};
    background-color: ${props => props.theme.dropdownItemHoverBackgroundColor};
  }

  &.dropdown-item:disabled,
  &.dropdown-item.disabled {
    color: ${props => props.theme.dropdownItemDisabledColor};
    background-color: ${props => props.theme.dropdownItemDisabledBackgroundColor};
  }

  &.dropdown-item:active,
  &.dropdown-item.active {
    color: ${props => props.theme.dropdownItemActiveColor};
    background-color: ${props => props.theme.dropdownItemActiveBackgroundColor};
  }

  &.dropdown-item:active:disabled,
  &.dropdown-item.active.disabled {
    opacity: 0.5;
  }

  &.dropdown-divider {
    border-color: ${props => props.theme.dropdownItemDividerColor};
  }
`

type UncontrolledDropdownState = {
  isOpen: boolean
}

export class UncontrolledDropdown extends React.PureComponent<
  DropdownProps,
  UncontrolledDropdownState
> {
  state: UncontrolledDropdownState = {
    isOpen: false,
  }

  toggle = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation()
    }
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.toggle as () => void} {...this.props}>
        {this.props.children}
      </Dropdown>
    )
  }
}

type UncontrolledDropdownWithPortalProps = {
  dropdownToggle: React.ReactNode
  style?: React.CSSProperties
}

type UncontrolledDropdownWithPortalState = {
  isOpen: boolean
}

export class UncontrolledDropdownWithPortal extends React.PureComponent<
  React.PropsWithChildren<UncontrolledDropdownWithPortalProps>,
  UncontrolledDropdownWithPortalState
> {
  portalContainer = document.createElement("div")

  state: UncontrolledDropdownWithPortalState = {
    isOpen: false,
  }

  componentDidMount() {
    document.body.appendChild(this.portalContainer)
  }

  componentWillUnmount() {
    document.body.removeChild(this.portalContainer)
  }

  toggle = (event: any) => {
    if (event.stopPropagation) {
      event.stopPropagation()
    }
    this.setState({ isOpen: !this.state.isOpen })
  }

  // Not clear if this class is needed anymore - DropdownMenu takes a container
  render() {
    return (
      <Dropdown
        isOpen={this.state.isOpen}
        toggle={this.toggle as () => void}
        style={this.props.style}
      >
        {this.props.dropdownToggle}
        {this.state.isOpen ? (
          <Portal node={this.portalContainer}>{this.props.children}</Portal>
        ) : null}
      </Dropdown>
    )
  }
}
