import * as React from "react"
import { Col, FormGroup } from "reactstrap"
import FontAwesome from "react-fontawesome"
import { SortDirection, SortDirectionType, TableCellProps, TableRowProps } from "react-virtualized"
import styled, { withTheme } from "styled-components"
import { v4 as uuid } from "uuid"
import cn from "classnames"
import { cloneDeep, isEqual } from "lodash"
import { ConfirmationModal } from "../common/ConfirmationModal"
import { CheckGroup } from "../common/Form"
import { OmniTable } from "../common/OmniTable"
import { StrikeThroughText } from "../common/StrikeThroughText"
import { ViewHeaderButtons } from "../common/View"
import { AdapterConfiguration, GigHardwareOptions, HardwareOptions } from "../../api/types"
import {
  FileInputButton,
  LightButton,
  SecondaryButton,
  SecondaryDangerButton,
} from "../common/Buttons"
import { UncontrolledTooltip } from "../common/UncontrolledTooltip"
import { formatColorForTheme, formatISODateTime } from "../../utils/formatUtils"
import { collator } from "../../utils/sortUtils"

const ButtonStrip = styled.div`
  display: flex;
  justify-content: flex-end;

  & button + button {
    margin-left: 4px;
  }
`

const StrikeThroughTextIndent = styled(StrikeThroughText)`
  margin-left: 1.5rem;
`

export const HadwareProfileViewContent = styled.div`
  flex-grow: 1;
  min-height: 430px;
`

const columnDesc = [
  {
    dataKey: "name",
    label: "Profile",
    width: 200,
    flexGrow: 1,
  },
  {
    dataKey: "comment",
    label: "Comment",
    width: 200,
    flexGrow: 2,
  },
  {
    dataKey: "created",
    label: "Created",
    width: 150,
    flexGrow: 1,
  },
  {
    dataKey: "modified",
    label: "Modified",
    width: 150,
    flexGrow: 1,
  },
  {
    dataKey: "buttons",
    label: "",
    width: 230,
    flexShrink: 0,
    disableSort: true,
  },
]

type HardwareFormProps = {
  adapterConfig: AdapterConfiguration
  adapterSupportsDeduplication: boolean
  hardwareOptions: GigHardwareOptions[]
  theme: any
  exportHardwareFilters: () => void
  importHardwareFilters: (importFile: string, deleteAll: boolean) => void
  setAdapterConfig: (adapterConfig: AdapterConfiguration) => void
  showHardwareProfile: (hardwareId: string | null) => void
  updateHardwareOptions: (hardwareOptions: HardwareOptions[], removeIds: string[]) => void
}

type HardwareFormState = {
  importFile: string | null
  showDeleteAllBeforeImportConfirm: boolean
  sortBy: string
  sortDirection: SortDirectionType
}

class HardwareForm extends React.Component<HardwareFormProps, HardwareFormState> {
  state: HardwareFormState = {
    importFile: null,
    showDeleteAllBeforeImportConfirm: false,
    sortBy: "name",
    sortDirection: SortDirection.ASC,
  }

  componentDidMount() {
    const { adapterConfig, adapterSupportsDeduplication, hardwareOptions } = this.props

    // if adapter does not support deduplication but hardware option does, remove the hardware option
    const removeHO: string[] = []
    if (!adapterSupportsDeduplication) {
      for (let i = 0; i < adapterConfig.ids.length; i++) {
        const hardwareOption = hardwareOptions.find(
          (hoc: HardwareOptions) => hoc.id === adapterConfig.ids[i]
        )
        if (hardwareOption && "deduplication" in hardwareOption && hardwareOption.deduplication) {
          removeHO.push(hardwareOption.id)
        }
      }
    }
    if (removeHO.length > 0) {
      this.props.setAdapterConfig({
        ...adapterConfig,
        ids: adapterConfig.ids.filter((id: string) => !removeHO.includes(id)),
      })
    }
  }

  shouldComponentUpdate(
    { adapterConfig, adapterSupportsDeduplication, hardwareOptions }: HardwareFormProps,
    { importFile, showDeleteAllBeforeImportConfirm, sortBy, sortDirection }: HardwareFormState
  ) {
    return (
      !isEqual(this.props.adapterConfig, adapterConfig) ||
      this.props.adapterSupportsDeduplication !== adapterSupportsDeduplication ||
      !isEqual(this.props.hardwareOptions, hardwareOptions) ||
      this.state.importFile !== importFile ||
      this.state.showDeleteAllBeforeImportConfirm !== showDeleteAllBeforeImportConfirm ||
      this.state.sortBy !== sortBy ||
      this.state.sortDirection !== sortDirection
    )
  }

  onChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { adapterConfig } = this.props
    const { id } = event.target

    this.props.setAdapterConfig({
      ...adapterConfig,
      ids: [id],
    })
  }

  onClear = () => {
    const { adapterConfig } = this.props

    this.props.setAdapterConfig({
      ...adapterConfig,
      ids: [],
    })
  }

  onDelete = (id: string, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    this.props.updateHardwareOptions([], [id])
  }

  onDeleteAllBeforeImportCancel = () => {
    this.setState({ showDeleteAllBeforeImportConfirm: false })
    this.onImportExecute(false)
  }

  onDeleteAllBeforeImportOK = () => {
    const { hardwareOptions } = this.props
    this.setState({ showDeleteAllBeforeImportConfirm: false })
    this.props.updateHardwareOptions(
      [],
      hardwareOptions.map((ghoption: GigHardwareOptions) => ghoption.id)
    )
    this.onImportExecute(true)
  }

  onDuplicate = (id: string, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()

    const { hardwareOptions } = this.props
    const hardwareOption = hardwareOptions.find(
      (ghoption: GigHardwareOptions) => ghoption.id === id
    )
    if (hardwareOption) {
      const currentDateTime = new Date().toISOString()
      const newHardwareOption = cloneDeep(hardwareOption)
      newHardwareOption.name = `${newHardwareOption.name} Copy`
      newHardwareOption.id = uuid().toUpperCase()
      newHardwareOption.created = currentDateTime
      newHardwareOption.modified = currentDateTime
      this.props.updateHardwareOptions([newHardwareOption], [])
    }
  }

  onEdit = (id: string, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    const hardwareOption = this.props.hardwareOptions.find(
      (ghoption: GigHardwareOptions) => ghoption.id === id
    )
    if (hardwareOption) {
      this.props.showHardwareProfile(hardwareOption.id)
    }
  }

  onExport = () => {
    const { hardwareOptions } = this.props
    if (hardwareOptions.length > 0) {
      this.props.exportHardwareFilters()
    }
  }

  onImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const file = event.target.files[0]
    if (!file) return
    const reader = new FileReader()
    reader.onerror = (e: any) => {}
    reader.onload = (e: any) => {
      if (e.target.result) {
        this.setState({ importFile: e.target.result, showDeleteAllBeforeImportConfirm: true })
      }
    }
    reader.readAsText(file)

    // Reset the file input so the same file can be used again
    event.target.value = ""
  }

  onImportExecute = (deleteAllOnImport: boolean) => {
    try {
      const { importFile } = this.state
      if (importFile) {
        this.props.importHardwareFilters(importFile, deleteAllOnImport)
      }
    } catch {}
    this.setState({ importFile: null })
  }

  onInsert = () => {
    this.props.showHardwareProfile(null)
  }

  onRowClick = ({ rowData, event }: { rowData: any; event: React.MouseEvent<HTMLElement> }) => {
    const { adapterConfig } = this.props

    this.props.setAdapterConfig({
      ...adapterConfig,
      ids: [rowData.id],
    })
  }

  onSort = ({ sortBy, sortDirection }: { sortBy: string; sortDirection: SortDirectionType }) => {
    this.setState({ sortBy, sortDirection })
  }

  cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    const { adapterConfig, adapterSupportsDeduplication } = this.props
    const checked = adapterConfig.ids.length > 0 ? adapterConfig.ids[0] === rowData.id : false
    const disabled =
      "deduplication" in rowData ? !adapterSupportsDeduplication && rowData.deduplication : false

    let content = null
    switch (dataKey) {
      case "name":
        if (!disabled) {
          content = (
            <CheckGroup
              type="radio"
              name={rowData.id}
              id={`id-${rowData.id}`}
              onChange={this.onChangeFilter.bind(this, rowData)}
              checked={checked}
            >
              <span
                style={{ color: formatColorForTheme(rowData.color, this.props.theme.name) }}
                title={cellData}
              >
                {cellData}
              </span>
            </CheckGroup>
          )
        } else {
          content = (
            <StrikeThroughTextIndent
              style={{ color: formatColorForTheme(rowData.color, this.props.theme.name) }}
              title={cellData}
            >
              {cellData}
            </StrikeThroughTextIndent>
          )
        }
        break
      case "comment":
        if (!disabled) {
          content = (
            <span
              style={{ color: formatColorForTheme(rowData.color, this.props.theme.name) }}
              title={cellData}
            >
              {cellData}
            </span>
          )
        } else {
          content = (
            <StrikeThroughText
              style={{ color: formatColorForTheme(rowData.color, this.props.theme.name) }}
              title={cellData}
            >
              {cellData}
            </StrikeThroughText>
          )
        }
        break
      case "created":
      case "modified": {
        const dateTime = formatISODateTime(cellData)
        if (!disabled) {
          content = (
            <span
              style={{ color: formatColorForTheme(rowData.color, this.props.theme.name) }}
              title={dateTime}
            >
              {dateTime}
            </span>
          )
        } else {
          content = (
            <StrikeThroughText
              style={{ color: formatColorForTheme(rowData.color, this.props.theme.name) }}
              title={dateTime}
            >
              {dateTime}
            </StrikeThroughText>
          )
        }
        break
      }
      case "buttons":
        content = (
          <ButtonStrip>
            <SecondaryButton size="sm" onClick={this.onEdit.bind(this, rowData.id)}>
              <FontAwesome name="pencil" /> Edit
            </SecondaryButton>
            <SecondaryButton size="sm" onClick={this.onDuplicate.bind(this, rowData.id)}>
              <FontAwesome name="clone" /> Duplicate
            </SecondaryButton>
            <SecondaryDangerButton size="sm" onClick={this.onDelete.bind(this, rowData.id)}>
              <FontAwesome name="trash-o" /> Delete
            </SecondaryDangerButton>
          </ButtonStrip>
        )
        break
      default:
        break
    }
    return content
  }

  rowClassName = () => {
    // Stripes applied in rowRenderer
    return ""
  }

  rowRenderer = ({
    className,
    columns,
    index,
    key,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
    style,
  }: TableRowProps & { key: string }) => {
    // copied from react-virtualized defaultRowRenderer.js
    const a11yProps: any = { "aria-rowindex": index + 1 }

    if (onRowClick || onRowDoubleClick || onRowMouseOut || onRowMouseOver || onRowRightClick) {
      a11yProps["aria-label"] = "row"
      a11yProps.tabIndex = 0

      if (onRowClick) {
        a11yProps.onClick = (event: React.MouseEvent<any>) => onRowClick({ event, index, rowData })
      }
      if (onRowDoubleClick) {
        a11yProps.onDoubleClick = (event: React.MouseEvent<any>) =>
          onRowDoubleClick({ event, index, rowData })
      }
      if (onRowMouseOut) {
        a11yProps.onMouseOut = (event: React.MouseEvent<any>) =>
          onRowMouseOut({ event, index, rowData })
      }
      if (onRowMouseOver) {
        a11yProps.onMouseOver = (event: React.MouseEvent<any>) =>
          onRowMouseOver({ event, index, rowData })
      }
      if (onRowRightClick) {
        a11yProps.onContextMenu = (event: React.MouseEvent<any>) =>
          onRowRightClick({ event, index, rowData })
      }
    }

    if (index % 2 !== 0) {
      className = cn(className, "stripe", "clickable")
    } else {
      className = cn(className, "clickable")
    }
    key = rowData.id

    return (
      <div {...a11yProps} className={className} key={key} role="row" style={style}>
        {columns}
      </div>
    )
  }

  render() {
    const { hardwareOptions } = this.props
    const { showDeleteAllBeforeImportConfirm, sortBy, sortDirection } = this.state

    // sort the hardware profiles
    const sortedHardwareOptions = hardwareOptions.sort((a, b) => {
      let result = 0
      if (a[sortBy] === "") {
        result = 1
      } else if (b[sortBy] === "") {
        result = -1
      } else {
        result = collator.compare(a[sortBy], b[sortBy])
      }
      if (sortDirection === SortDirection.DESC) result = -result
      return result
    })

    return (
      <FormGroup row noMargin>
        <Col lg={12}>
          <ViewHeaderButtons style={{ marginBottom: "4px" }}>
            <LightButton id="insert" onClick={this.onInsert.bind(this)}>
              <FontAwesome name="plus" /> Insert
            </LightButton>
            <LightButton id="clear" onClick={this.onClear.bind(this)}>
              <FontAwesome name="times" /> Clear
            </LightButton>
            <FileInputButton
              aria-label="Import"
              id="import"
              accept=".flt,application/xml"
              onChange={this.onImport.bind(this)}
            >
              {" "}
              <FontAwesome name="upload" /> Import
            </FileInputButton>
            <UncontrolledTooltip placement="top" target="import">
              Import
            </UncontrolledTooltip>
            <LightButton
              disabled={sortedHardwareOptions.length === 0}
              id="export"
              onClick={this.onExport.bind(this)}
            >
              <FontAwesome name="download" /> Export
            </LightButton>
          </ViewHeaderButtons>
          <HadwareProfileViewContent>
            <OmniTable
              data={sortedHardwareOptions}
              rowCount={sortedHardwareOptions.length}
              rowHeight={25}
              columnDesc={columnDesc}
              cellRenderer={this.cellRenderer}
              rowClassName={this.rowClassName}
              rowRenderer={this.rowRenderer}
              onRowClick={this.onRowClick}
              sort={this.onSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
            />
          </HadwareProfileViewContent>
        </Col>
        {showDeleteAllBeforeImportConfirm && (
          <ConfirmationModal
            message={"Delete all current hardware filters before import?"}
            onNo={this.onDeleteAllBeforeImportCancel}
            onYes={this.onDeleteAllBeforeImportOK}
            show={showDeleteAllBeforeImportConfirm}
            title="Delete All Hardware Filters"
          />
        )}
      </FormGroup>
    )
  }
}

const HardwareFormWithTheme = withTheme(HardwareForm)
export { HardwareFormWithTheme as HardwareForm }
