import * as React from "react"
import { FormGroup } from "reactstrap"
import { Label } from "../../common/Form"
import { Select } from "../../common/Select"
import { TopStatsViewType } from "../../../api/types/dashboardTypes"
import { fetchCaptureSessions } from "../../../api/api"
import { Engine, ResponseGetCaptureSessions } from "../../../api/types"

function getCaptureSessions(
  server: string,
  authToken: string,
  engine: Engine,
  setData: (data: ResponseGetCaptureSessions) => void
) {
  fetchCaptureSessions(`${server}/engines/${engine.id}`, authToken)
    .then(captureSessions => {
      setData(captureSessions)
    })
    .catch(error => {
      console.error(error)
    })
}

type CaptureSessionInfo = {
  id: string
  name: string
  startTimestamp: string
  active: boolean
}

type ForensicTopStatsWidgetFormProps = {
  server: string
  authToken: string
  engine: Engine
  settings: any | null
  setSettings: (settings: any | null) => void
}

const ForensicTopStatsWidgetForm = ({
  server,
  authToken,
  engine,
  settings,
  setSettings,
}: ForensicTopStatsWidgetFormProps) => {
  const [captureSessions, setCaptureSessions] = React.useState<CaptureSessionInfo[]>([])

  React.useEffect(() => {
    getCaptureSessions(server, authToken, engine, data => {
      if (Array.isArray(data.rows)) {
        const newCaptureSessions: CaptureSessionInfo[] = data.rows.map((captureSession: any) => {
          return {
            id: captureSession.SessionID,
            name: captureSession.Name,
            startTimestamp: captureSession.StartTimestamp,
            active: captureSession.CaptureState !== 0,
          }
        })
        const collator = new Intl.Collator(undefined, { sensitivity: "base" })
        newCaptureSessions.sort((a, b) => {
          return collator.compare(b.startTimestamp, a.startTimestamp)
        })
        setCaptureSessions(newCaptureSessions)
        if (newCaptureSessions.length > 0) {
          setSettings((settings: any | null) => {
            if (settings) {
              return {
                ...settings,
                sessionId: newCaptureSessions[0].id,
              }
            } else {
              return { sessionId: newCaptureSessions[0].id }
            }
          })
        }
      }
    })
  }, [server, authToken, engine, setSettings])

  React.useEffect(() => {
    // Ensure there's a view type in settings.
    setSettings((settings: any | null) => {
      if (settings) {
        if (settings.viewType == null) {
          return {
            ...settings,
            viewType: TopStatsViewType.IP,
          }
        } else {
          return null
        }
      } else {
        return { viewType: TopStatsViewType.IP }
      }
    })
  }, [setSettings])

  return (
    <>
      <FormGroup>
        <Label for="session">Capture Session</Label>
        <Select
          id="session"
          value={settings && settings.sessionId ? settings.sessionId : ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const sessionId = e.target.value
            if (settings) {
              setSettings({
                ...settings,
                sessionId,
              })
            } else {
              setSettings({ sessionId })
            }
          }}
        >
          {captureSessions.map(captureSession => (
            <option key={captureSession.id} value={captureSession.id}>
              {captureSession.name}
            </option>
          ))}
        </Select>
      </FormGroup>
      <FormGroup>
        <Label for="view-type">Chart</Label>
        <Select
          id="view-type"
          value={settings && settings.viewType ? settings.viewType : 0}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const viewType = e.target.value
            if (settings) {
              setSettings({
                ...settings,
                viewType,
              })
            } else {
              setSettings({ viewType: TopStatsViewType.IP })
            }
          }}
        >
          <option value={TopStatsViewType.IP}>Top Talkers by IP Address</option>
          <option value={TopStatsViewType.IPV6}>Top Talkers by IPv6 Address</option>
          <option value={TopStatsViewType.PHYSICAL}>Top Talkers by Physical Address</option>
          <option value={TopStatsViewType.PROTOCOLS}>Top Protocols by Bytes</option>
          <option value={TopStatsViewType.APPLICATIONS}>Top Applications by Bytes</option>
        </Select>
      </FormGroup>
    </>
  )
}

export default ForensicTopStatsWidgetForm
