import * as React from "react"
import { Col, Collapse, FormGroup } from "reactstrap"
import { cloneDeep, isEqual, toNumber } from "lodash"
import FontAwesome from "react-fontawesome"
import { v4 as uuid } from "uuid"
import { HardwareChannelValueForm } from "./HardwareChannelValueForm"
import { LightButton, LightDangerButton } from "../common/Buttons"
import { ErrorText } from "../common/ErrorText"
import { ButtonBar, CheckGroup, Label, SubSectionLabel } from "../common/Form"
import { Input } from "../common/Input"
import { Select } from "../common/Select"
import { NoData } from "../Dashboard/Dashboard"
import { HardwareChannelFilter, HardwareChannelFilterValue } from "../../api/types"
import { MediaSpecType } from "../../api/types/mediaTypes"
import {
  AndGroup,
  EmptyValueFilterRowStyled,
  HardwareValueFilterButtons,
  HardwareValueFilterContent,
  HardwareValueFilterHeader,
  HardwareValueFilterRow,
  HardwareValueFiltersView,
  HardwareValueFilterTitle,
  OrGroup,
  RadioInputWrapper,
} from "./Styles"
import {
  countValueFilters,
  defaultHardwareChannelValueFilter,
  deleteValueFilter,
  insertValueFilter,
  replaceValueFilter,
} from "../../utils/hardwareUtils"
import {
  formatMediaSpec,
  getMediaSpecTypeDefault,
  getTypeMask,
  parseMediaSpec,
  validateMediaSpec,
  validateMediaSpecs,
} from "../../utils/mediaSpec"

enum AddressType {
  ADDRESS_TYPE_PHYSICAL = 0,
  ADDRESS_TYPE_IP = 1,
  ADDRESS_TYPE_ETYPE2IP = 2,
  ADDRESS_TYPE_8023IP = 3,
}

enum FilterDirection {
  FILTER_DIRECTION_BOTH = 0,
  FILTER_DIRECTION_ACCEPT_1_TO_2 = 1,
  FILTER_DIRECTION_ACCEPT_2_TO_1 = 2,
}

enum PortType {
  PORT_TYPE_TCP_UDP = 0,
  PORT_TYPE_TCP = 1,
  PORT_TYPE_UDP = 2,
}

const MPLS_LAYERS_MAX_COUNT = 3
const MPLS_VALUES_MAX_COUNT = 32
const VLAN_LAYERS_MAX_COUNT = 2
const VLAN_VALUES_MAX_COUNT = 32
const VALUES_MAX_COUNT = 4

type HardwareChannelFormProps = {
  channelFilter: HardwareChannelFilter
  setChannelFilter: (channelFilter: HardwareChannelFilter) => void
  setValidAddress: (validAddress: boolean) => void
  setValidMPLS: (validMPLS: boolean) => void
  setValidPort: (validPort: boolean) => void
  setValidValues: (validValues: boolean) => void
  setValidVLAN: (validVLAN: boolean) => void
  supportValues: boolean
  supportVLANMPLS: boolean
}

type HardwareChannelFormState = {
  address1: string
  address2: string
  anyAddress: boolean
  anyPort: boolean
  invalidAddress1: boolean
  invalidAddress2: boolean
  invalidMPLS: boolean
  invalidPort1: boolean
  invalidPort2: boolean
  invalidValueFilters: Set<string>
  invalidVLAN: boolean
  mpls: string
  mplsLayers: number
  port1: string
  port2: string
  valueFilter: HardwareChannelFilterValue
  vlan: string
  vlanLayers: number
}

export class HardwareChannelForm extends React.Component<
  HardwareChannelFormProps,
  HardwareChannelFormState
> {
  state: HardwareChannelFormState = {
    address1: "",
    address2: "",
    anyAddress: true,
    anyPort: true,
    invalidAddress1: false,
    invalidAddress2: false,
    invalidMPLS: false,
    invalidPort1: false,
    invalidPort2: false,
    invalidValueFilters: new Set(),
    invalidVLAN: false,
    mpls: "",
    mplsLayers: 1,
    port1: "",
    port2: "",
    valueFilter: { ...defaultHardwareChannelValueFilter, id: uuid().toUpperCase() },
    vlan: "",
    vlanLayers: 1,
  }

  componentDidMount() {
    const { channelFilter, supportValues, supportVLANMPLS } = this.props

    let address1 = formatMediaSpec(channelFilter.addressFilter.address1)
    if (address1 === "Any address" || address1 === "") {
      address1 = getMediaSpecTypeDefault(
        channelFilter.addressFilter.address1.type === MediaSpecType.MEDIA_SPEC_TYPE_NULL
          ? MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
          : channelFilter.addressFilter.address1.type
      )
    }
    let address2 = formatMediaSpec(channelFilter.addressFilter.address2)
    if (address2 === "Any address" || address2 === "") {
      address2 = getMediaSpecTypeDefault(
        channelFilter.addressFilter.address2.type === MediaSpecType.MEDIA_SPEC_TYPE_NULL
          ? MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
          : channelFilter.addressFilter.address2.type
      )
    }

    let port1 = formatMediaSpec(channelFilter.portFilter.port1)
    if (port1 === "Any port" || port1 === "") {
      port1 = getMediaSpecTypeDefault(
        channelFilter.portFilter.port1.type === MediaSpecType.MEDIA_SPEC_TYPE_NULL
          ? MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT
          : channelFilter.portFilter.port1.type
      )
    }
    let port2 = formatMediaSpec(channelFilter.portFilter.port2)
    if (port2 === "Any port" || port2 === "") {
      port2 = getMediaSpecTypeDefault(
        channelFilter.portFilter.port2.type === MediaSpecType.MEDIA_SPEC_TYPE_NULL
          ? MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT
          : channelFilter.portFilter.port2.type
      )
    }

    this.setState({
      address1,
      address2,
      anyAddress:
        ((channelFilter.addressFilter.address2.mask !== undefined
          ? channelFilter.addressFilter.address2.mask
          : getTypeMask(channelFilter.addressFilter.address2.type as MediaSpecType)) &
          getTypeMask(channelFilter.addressFilter.address2.type as MediaSpecType)) ===
        0,
      anyPort:
        ((channelFilter.portFilter.port2.mask !== undefined
          ? channelFilter.portFilter.port2.mask
          : getTypeMask(channelFilter.portFilter.port2.type as MediaSpecType)) &
          getTypeMask(channelFilter.portFilter.port2.type as MediaSpecType)) ===
        0,
      mpls: supportVLANMPLS ? channelFilter.mplsFilter.mpls.join(", ") : "",
      mplsLayers: supportVLANMPLS ? channelFilter.mplsFilter.layers : 1,
      port1,
      port2,
      valueFilter: supportValues
        ? cloneDeep(channelFilter.valueFilter)
        : { ...defaultHardwareChannelValueFilter, id: uuid().toUpperCase() },
      vlan: supportVLANMPLS ? channelFilter.vlanFilter.vlan.join(", ") : "",
      vlanLayers: supportVLANMPLS ? channelFilter.vlanFilter.layers : 1,
    })
  }

  shouldComponentUpdate(
    { channelFilter, supportValues, supportVLANMPLS }: HardwareChannelFormProps,
    {
      address1,
      address2,
      anyAddress,
      anyPort,
      invalidAddress1,
      invalidAddress2,
      invalidMPLS,
      invalidPort1,
      invalidPort2,
      invalidValueFilters,
      invalidVLAN,
      mpls,
      mplsLayers,
      port1,
      port2,
      valueFilter,
      vlan,
      vlanLayers,
    }: HardwareChannelFormState
  ) {
    return (
      !isEqual(this.props.channelFilter, channelFilter) ||
      this.props.supportValues !== supportValues ||
      this.props.supportVLANMPLS !== supportVLANMPLS ||
      this.state.address1 !== address1 ||
      this.state.address2 !== address2 ||
      this.state.anyAddress !== anyAddress ||
      this.state.anyPort !== anyPort ||
      this.state.invalidAddress1 !== invalidAddress1 ||
      this.state.invalidAddress2 !== invalidAddress2 ||
      this.state.invalidMPLS !== invalidMPLS ||
      this.state.invalidPort1 !== invalidPort1 ||
      this.state.invalidPort2 !== invalidPort2 ||
      !isEqual(this.state.invalidValueFilters, invalidValueFilters) ||
      this.state.invalidVLAN !== invalidVLAN ||
      this.state.mpls !== mpls ||
      this.state.mplsLayers !== mplsLayers ||
      this.state.port1 !== port1 ||
      this.state.port2 !== port2 ||
      !isEqual(this.state.valueFilter, valueFilter) ||
      this.state.vlan !== vlan ||
      this.state.vlanLayers !== vlanLayers
    )
  }

  onChangeAddress1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const { channelFilter } = this.props
    const addressFilter = cloneDeep(channelFilter.addressFilter)
    try {
      addressFilter.address1 = parseMediaSpec(value, addressFilter.address1.type)
      this.props.setChannelFilter({
        ...channelFilter,
        addressFilter,
      })
    } catch {}
    const invalid = !validateMediaSpecs(value, addressFilter.address1.type)
    this.props.setValidAddress(!invalid && !this.state.invalidAddress2)
    this.setState({ address1: value, invalidAddress1: invalid })
  }

  onChangeAddress2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const { channelFilter } = this.props
    const addressFilter = cloneDeep(channelFilter.addressFilter)
    try {
      addressFilter.address2 = parseMediaSpec(value, addressFilter.address2.type)
      this.props.setChannelFilter({
        ...channelFilter,
        addressFilter,
      })
    } catch {}
    const invalid = !validateMediaSpecs(value, addressFilter.address2.type)
    this.props.setValidAddress(!invalid && !this.state.invalidAddress1)
    this.setState({ address2: value, invalidAddress2: invalid })
  }

  onChangeAddress2Type = (channel: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    if (id === `address2AnyEnabled${channel}`) {
      const { channelFilter } = this.props
      const addressFilter = cloneDeep(channelFilter.addressFilter)
      const value = getMediaSpecTypeDefault(addressFilter.address1.type)
      try {
        addressFilter.address2 = parseMediaSpec(value, addressFilter.address1.type, true)
        this.props.setChannelFilter({
          ...channelFilter,
          addressFilter,
        })
      } catch {}
      const invalid = false
      this.props.setValidAddress(!invalid && !this.state.invalidAddress1)
      this.setState({ anyAddress: true, invalidAddress2: invalid })
    } else {
      const { channelFilter } = this.props
      const { address2 } = this.state
      const addressFilter = cloneDeep(channelFilter.addressFilter)
      try {
        addressFilter.address2 = parseMediaSpec(address2, addressFilter.address2.type)
        this.props.setChannelFilter({
          ...channelFilter,
          addressFilter,
        })
      } catch {}
      const invalid = !validateMediaSpecs(address2, addressFilter.address2.type)
      this.props.setValidAddress(!invalid && !this.state.invalidAddress1)
      this.setState({ anyAddress: false, invalidAddress2: invalid })
    }
  }

  onChangeAddressDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const direction = toNumber(event.target.value)
    const { channelFilter } = this.props
    const addressFilter = cloneDeep(channelFilter.addressFilter)
    switch (direction) {
      case FilterDirection.FILTER_DIRECTION_BOTH:
        addressFilter.accept1To2 = true
        addressFilter.accept2To1 = true
        break
      case FilterDirection.FILTER_DIRECTION_ACCEPT_1_TO_2:
        addressFilter.accept1To2 = true
        addressFilter.accept2To1 = false
        break
      case FilterDirection.FILTER_DIRECTION_ACCEPT_2_TO_1:
        addressFilter.accept1To2 = false
        addressFilter.accept2To1 = true
        break
      default:
        break
    }
    this.props.setChannelFilter({
      ...channelFilter,
      addressFilter,
    })
  }

  onChangeAddressType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = toNumber(event.target.value)
    const { channelFilter } = this.props
    const { anyAddress } = this.state
    const addressFilter = cloneDeep(channelFilter.addressFilter)
    let addressValue = ""
    try {
      switch (type) {
        default:
        case AddressType.ADDRESS_TYPE_PHYSICAL:
          addressValue = getMediaSpecTypeDefault(MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS)
          addressFilter.address1 = parseMediaSpec(
            addressValue,
            MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS
          )
          addressFilter.address2 = parseMediaSpec(
            addressValue,
            MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS,
            anyAddress
          )
          break
        case AddressType.ADDRESS_TYPE_IP:
        case AddressType.ADDRESS_TYPE_ETYPE2IP:
        case AddressType.ADDRESS_TYPE_8023IP:
          addressValue = getMediaSpecTypeDefault(MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS)
          addressFilter.address1 = parseMediaSpec(
            addressValue,
            MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS
          )
          addressFilter.address2 = parseMediaSpec(
            addressValue,
            MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS,
            anyAddress
          )
          break
      }
    } catch {}
    addressFilter.ip8023 =
      type === AddressType.ADDRESS_TYPE_8023IP || type === AddressType.ADDRESS_TYPE_IP
    addressFilter.ipEType2 =
      type === AddressType.ADDRESS_TYPE_ETYPE2IP || type === AddressType.ADDRESS_TYPE_IP
    this.props.setChannelFilter({
      ...channelFilter,
      addressFilter,
    })
    const invalid1 = !validateMediaSpecs(addressValue, addressFilter.address1.type)
    const invalid2 = !validateMediaSpecs(addressValue, addressFilter.address2.type)
    this.props.setValidAddress(!invalid1 && !invalid2)
    this.setState({
      address1: addressValue,
      address2: addressValue,
      invalidAddress1: invalid1,
      invalidAddress2: invalid2,
    })
  }

  onChangeDiscardErrors = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { channelFilter } = this.props
    const { checked } = event.target
    this.props.setChannelFilter({ ...channelFilter, discardErrors: checked })
  }

  onChangeExclusiveFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { channelFilter } = this.props
    const { checked } = event.target
    this.props.setChannelFilter({ ...channelFilter, exclusiveFilter: checked })
  }

  onChangeUseAddressFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { channelFilter } = this.props
    const { checked } = event.target
    this.props.setChannelFilter({ ...channelFilter, useAddressFilter: checked })
  }

  onChangeUseMPLSFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { channelFilter } = this.props
    const { checked } = event.target
    this.props.setChannelFilter({ ...channelFilter, useMPLSFilter: checked })
  }

  onChangeUsePortFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { channelFilter } = this.props
    const { checked } = event.target
    this.props.setChannelFilter({ ...channelFilter, usePortFilter: checked })
  }

  onChangeUseValuesFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { channelFilter } = this.props
    const { checked } = event.target
    this.props.setChannelFilter({ ...channelFilter, useValueFilter: checked })
  }

  onChangeUseVLANFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { channelFilter } = this.props
    const { checked } = event.target
    this.props.setChannelFilter({ ...channelFilter, useVLANFilter: checked })
  }

  onChangePort1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const { channelFilter } = this.props
    const portFilter = cloneDeep(channelFilter.portFilter)
    try {
      portFilter.port1 = parseMediaSpec(value, portFilter.port1.type)
      this.props.setChannelFilter({
        ...channelFilter,
        portFilter,
      })
    } catch {}
    const invalid = !validateMediaSpec(value, portFilter.port1.type)
    this.props.setValidPort(!invalid && !this.state.invalidPort2)
    this.setState({ port1: value, invalidPort1: invalid })
  }

  onChangePort2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const { channelFilter } = this.props
    const portFilter = cloneDeep(channelFilter.portFilter)
    try {
      portFilter.port2 = parseMediaSpec(value, portFilter.port2.type)
      this.props.setChannelFilter({
        ...channelFilter,
        portFilter,
      })
    } catch {}
    const invalid = !validateMediaSpec(value, portFilter.port2.type)
    this.props.setValidPort(!invalid && !this.state.invalidPort1)
    this.setState({ port2: value, invalidPort2: invalid })
  }

  onChangePort2Type = (channel: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target
    if (id === `port2AnyEnabled${channel}`) {
      const { channelFilter } = this.props
      const portFilter = cloneDeep(channelFilter.portFilter)
      const value = getMediaSpecTypeDefault(portFilter.port1.type)
      try {
        portFilter.port2 = parseMediaSpec(value, portFilter.port1.type, true)
        this.props.setChannelFilter({
          ...channelFilter,
          portFilter,
        })
      } catch {}
      const invalid = false
      this.props.setValidPort(!invalid && !this.state.invalidPort1)
      this.setState({ anyPort: true, invalidPort2: invalid })
    } else {
      const { channelFilter } = this.props
      const { port2 } = this.state
      const portFilter = cloneDeep(channelFilter.portFilter)
      try {
        portFilter.port2 = parseMediaSpec(port2, portFilter.port2.type)
        this.props.setChannelFilter({
          ...channelFilter,
          portFilter,
        })
      } catch {}
      const invalid = !validateMediaSpec(port2, portFilter.port2.type)
      this.props.setValidPort(!invalid && !this.state.invalidPort1)
      this.setState({ anyPort: false, invalidPort2: invalid })
    }
  }

  onChangePortDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const direction = toNumber(event.target.value)
    const { channelFilter } = this.props
    const portFilter = cloneDeep(channelFilter.portFilter)
    switch (direction) {
      case FilterDirection.FILTER_DIRECTION_BOTH:
        portFilter.accept1To2 = true
        portFilter.accept2To1 = true
        break
      case FilterDirection.FILTER_DIRECTION_ACCEPT_1_TO_2:
        portFilter.accept1To2 = true
        portFilter.accept2To1 = false
        break
      case FilterDirection.FILTER_DIRECTION_ACCEPT_2_TO_1:
        portFilter.accept1To2 = false
        portFilter.accept2To1 = true
        break
      default:
        break
    }
    this.props.setChannelFilter({
      ...channelFilter,
      portFilter,
    })
  }

  onChangePortType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = toNumber(event.target.value)
    const { channelFilter } = this.props
    const { anyPort } = this.state
    const portFilter = cloneDeep(channelFilter.portFilter)
    const portValue = getMediaSpecTypeDefault(MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT)
    try {
      portFilter.port2 = parseMediaSpec(portValue, MediaSpecType.MEDIA_SPEC_TYPE_IP_PORT, anyPort)
    } catch {}
    portFilter.tcpPort = type === PortType.PORT_TYPE_TCP || type === PortType.PORT_TYPE_TCP_UDP
    portFilter.udpPort = type === PortType.PORT_TYPE_UDP || type === PortType.PORT_TYPE_TCP_UDP
    this.props.setChannelFilter({
      ...channelFilter,
      portFilter,
    })
    const invalid2 = !validateMediaSpec(portValue, portFilter.port2.type)
    this.props.setValidPort(!this.state.invalidPort1 && !invalid2)
    this.setState({
      port2: portValue,
      invalidPort2: invalid2,
    })
  }

  onChangeMPLS = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const { channelFilter } = this.props
    const mplsFilter = cloneDeep(channelFilter.mplsFilter)
    try {
      mplsFilter.mpls = value
        .trim()
        .split(/\s*,\s*/)
        .map((id: string) => toNumber(id))
        .filter((id: number) => Number.isInteger(id))
      this.props.setChannelFilter({
        ...channelFilter,
        mplsFilter,
      })
    } catch {}
    const invalid = !(
      /^\s*[0-9]+(\s*,\s*[0-9]+)*\s*$/.test(value) &&
      mplsFilter.mpls.length <= MPLS_VALUES_MAX_COUNT &&
      mplsFilter.mpls.every(m => m >= 0 && m <= 0xfffff)
    )
    this.props.setValidMPLS(!invalid)
    this.setState({ mpls: value, invalidMPLS: invalid })
  }

  onChangeMPLSLayers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = toNumber(event.target.value)
    if (value > 0 && value <= MPLS_LAYERS_MAX_COUNT) {
      const { channelFilter } = this.props
      const mplsFilter = cloneDeep(channelFilter.mplsFilter)
      try {
        mplsFilter.layers = value
        this.props.setChannelFilter({
          ...channelFilter,
          mplsFilter,
        })
      } catch {}
      this.setState({ mplsLayers: value })
    }
  }

  onChangeVLAN = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const { channelFilter } = this.props
    const vlanFilter = cloneDeep(channelFilter.vlanFilter)
    try {
      vlanFilter.vlan = value
        .trim()
        .split(/\s*,\s*/)
        .map((id: string) => toNumber(id))
        .filter((id: number) => Number.isInteger(id))
      this.props.setChannelFilter({
        ...channelFilter,
        vlanFilter,
      })
    } catch {}
    const invalid = !(
      /^\s*[0-9]+(\s*,\s*[0-9]+)*\s*$/.test(value) &&
      vlanFilter.vlan.length <= VLAN_VALUES_MAX_COUNT &&
      vlanFilter.vlan.every(v => v >= 0 && v <= 0xfff)
    )
    this.props.setValidVLAN(!invalid)
    this.setState({ vlan: value, invalidVLAN: invalid })
  }

  onChangeVLANLayers = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = toNumber(event.target.value)
    if (value > 0 && value <= VLAN_LAYERS_MAX_COUNT) {
      const { channelFilter } = this.props
      const vlanFilter = cloneDeep(channelFilter.vlanFilter)
      try {
        vlanFilter.layers = value
        this.props.setChannelFilter({
          ...channelFilter,
          vlanFilter,
        })
      } catch {}
      this.setState({ vlanLayers: value })
    }
  }

  onChangeValueFilter = (newValueFilter: HardwareChannelFilterValue) => {
    const { channelFilter } = this.props
    const valueFilter = replaceValueFilter(cloneDeep(this.state.valueFilter), newValueFilter)
    try {
      this.props.setChannelFilter({
        ...channelFilter,
        valueFilter,
      })
    } catch {}
    this.setState({ valueFilter })
  }

  onDeleteAllValueFilters = () => {
    const { channelFilter } = this.props
    const valueFilter = cloneDeep(defaultHardwareChannelValueFilter)
    try {
      this.props.setChannelFilter({
        ...channelFilter,
        valueFilter,
      })
    } catch {}
    this.setState({ valueFilter })
  }

  onDeleteValueFilter = (deletedValueFilter: HardwareChannelFilterValue) => {
    const { channelFilter } = this.props
    let valueFilter = deleteValueFilter(cloneDeep(this.state.valueFilter), deletedValueFilter.id)
    if (!valueFilter) {
      valueFilter = cloneDeep(defaultHardwareChannelValueFilter)
    }
    try {
      this.props.setChannelFilter({
        ...channelFilter,
        valueFilter,
      })
    } catch {}
    this.setState({ valueFilter })
  }

  onInsertValueFilter = (parentValueFilter: HardwareChannelFilterValue, isAnd: boolean) => {
    if (countValueFilters(this.state.valueFilter) < VALUES_MAX_COUNT) {
      const newValueFilter = {
        ...cloneDeep(defaultHardwareChannelValueFilter),
        id: uuid().toUpperCase(),
      }
      const valueFilter = insertValueFilter(
        cloneDeep(this.state.valueFilter),
        newValueFilter,
        parentValueFilter.id,
        isAnd
      )
      try {
        this.props.setChannelFilter({
          ...this.props.channelFilter,
          valueFilter,
        })
      } catch {}
      this.setState({ valueFilter })
    }
  }

  onSetValidValueFilter = (id: string, invalid: boolean) => {
    const invalidValueFilters = new Set(this.state.invalidValueFilters)
    if (invalid) {
      invalidValueFilters.add(id)
    } else {
      invalidValueFilters.delete(id)
    }
    this.setState({ invalidValueFilters })
    this.props.setValidValues(invalidValueFilters.size === 0)
  }

  renderValueFilter = (valueFilter: HardwareChannelFilterValue, valueFilterCount: number) => {
    return (
      <HardwareValueFilterRow key={`valueFilter${valueFilter.id}`}>
        <HardwareValueFilterHeader>
          <HardwareValueFilterTitle />
          <HardwareValueFilterButtons>
            <LightButton
              size="sm"
              id={`valueFilterAnd${valueFilter.id}`}
              disabled={valueFilterCount >= VALUES_MAX_COUNT}
              onClick={this.onInsertValueFilter.bind(this, valueFilter, true)}
            >
              And
            </LightButton>
            <LightButton
              size="sm"
              id={`valueFilterOr${valueFilter.id}`}
              disabled={valueFilterCount >= VALUES_MAX_COUNT}
              onClick={this.onInsertValueFilter.bind(this, valueFilter, false)}
            >
              Or
            </LightButton>
            <LightDangerButton
              aria-label="Delete"
              size="sm"
              id={`valueFilterDelete${valueFilter.id}`}
              onClick={this.onDeleteValueFilter.bind(this, valueFilter)}
            >
              <FontAwesome name="trash-o" /> Delete
            </LightDangerButton>
          </HardwareValueFilterButtons>
        </HardwareValueFilterHeader>
        <HardwareValueFilterContent>
          <HardwareChannelValueForm
            valueFilter={valueFilter}
            onChangeValueFilter={this.onChangeValueFilter}
            onSetValidValueFilter={this.onSetValidValueFilter}
          />
        </HardwareValueFilterContent>
      </HardwareValueFilterRow>
    )
  }

  renderValueFiltersRecursive = (
    orFilterValue: HardwareChannelFilterValue | undefined,
    andFilterValue: HardwareChannelFilterValue | undefined,
    parentFilterValue: HardwareChannelFilterValue | undefined,
    values: any[],
    valueFilterCount: number
  ) => {
    if (orFilterValue) {
      const orNodes: any = []
      while (orFilterValue) {
        if (orFilterValue.andNode) {
          const tempNode: HardwareChannelFilterValue | undefined = orFilterValue.orNode
          orFilterValue.orNode = undefined
          this.renderValueFiltersRecursive(
            undefined,
            orFilterValue,
            parentFilterValue,
            orNodes,
            valueFilterCount
          )
          orFilterValue.orNode = tempNode
        } else {
          orNodes.push(this.renderValueFilter(orFilterValue, valueFilterCount))
        }
        parentFilterValue = orFilterValue
        orFilterValue = orFilterValue.orNode
      }
      if (values.length === 0 && orNodes.length === 1) {
        values.push(orNodes[0])
      } else {
        values.push(<OrGroup key={`or-${values.length}`}>{orNodes}</OrGroup>)
      }
    }

    if (andFilterValue) {
      const andNodes: any = []
      while (andFilterValue) {
        if (andFilterValue.orNode) {
          this.renderValueFiltersRecursive(
            andFilterValue,
            undefined,
            parentFilterValue,
            andNodes,
            valueFilterCount
          )
          break
        } else {
          andNodes.push(this.renderValueFilter(andFilterValue, valueFilterCount))
        }
        parentFilterValue = andFilterValue
        andFilterValue = andFilterValue.andNode
      }
      if (values.length === 0 && andNodes.length === 1) {
        values.push(andNodes[0])
      } else {
        values.push(<AndGroup key={`and-${values.length}`}>{andNodes}</AndGroup>)
      }
    }
  }

  renderValueFilters(valueFilterCount: number) {
    const { valueFilter } = this.state
    if (!valueFilter) return null
    const values: any[] = []
    this.renderValueFiltersRecursive(valueFilter, undefined, undefined, values, valueFilterCount)
    return values
  }

  render() {
    const { channelFilter, supportVLANMPLS, supportValues } = this.props
    const {
      address1,
      address2,
      anyAddress,
      anyPort,
      invalidAddress1,
      invalidAddress2,
      invalidMPLS,
      invalidPort1,
      invalidPort2,
      invalidValueFilters,
      invalidVLAN,
      mpls,
      mplsLayers,
      port1,
      port2,
      valueFilter,
      vlan,
      vlanLayers,
    } = this.state

    let addressType = AddressType.ADDRESS_TYPE_IP
    switch (channelFilter.addressFilter.address1.type) {
      case MediaSpecType.MEDIA_SPEC_TYPE_ETHERNET_ADDRESS:
        addressType = AddressType.ADDRESS_TYPE_PHYSICAL
        break
      case MediaSpecType.MEDIA_SPEC_TYPE_IP_ADDRESS:
        if (channelFilter.addressFilter.ip8023 && channelFilter.addressFilter.ipEType2) {
          addressType = AddressType.ADDRESS_TYPE_IP
        } else if (channelFilter.addressFilter.ipEType2) {
          addressType = AddressType.ADDRESS_TYPE_ETYPE2IP
        } else if (channelFilter.addressFilter.ip8023) {
          addressType = AddressType.ADDRESS_TYPE_8023IP
        } else {
          addressType = AddressType.ADDRESS_TYPE_IP
        }
        break
      default:
        addressType = AddressType.ADDRESS_TYPE_IP
        break
    }

    let addressDirection = FilterDirection.FILTER_DIRECTION_BOTH
    if (channelFilter.addressFilter.accept1To2 && channelFilter.addressFilter.accept2To1) {
      addressDirection = FilterDirection.FILTER_DIRECTION_BOTH
    } else if (channelFilter.addressFilter.accept1To2) {
      addressDirection = FilterDirection.FILTER_DIRECTION_ACCEPT_1_TO_2
    } else if (channelFilter.addressFilter.accept2To1) {
      addressDirection = FilterDirection.FILTER_DIRECTION_ACCEPT_2_TO_1
    }

    let portDirection = FilterDirection.FILTER_DIRECTION_BOTH
    if (channelFilter.portFilter.accept1To2 && channelFilter.portFilter.accept2To1) {
      portDirection = FilterDirection.FILTER_DIRECTION_BOTH
    } else if (channelFilter.portFilter.accept1To2) {
      portDirection = FilterDirection.FILTER_DIRECTION_ACCEPT_1_TO_2
    } else if (channelFilter.portFilter.accept2To1) {
      portDirection = FilterDirection.FILTER_DIRECTION_ACCEPT_2_TO_1
    }

    let portType = PortType.PORT_TYPE_TCP_UDP
    if (channelFilter.portFilter.tcpPort && channelFilter.portFilter.udpPort) {
      portType = PortType.PORT_TYPE_TCP_UDP
    } else if (channelFilter.portFilter.tcpPort) {
      portType = PortType.PORT_TYPE_TCP
    } else if (channelFilter.portFilter.udpPort) {
      portType = PortType.PORT_TYPE_UDP
    }

    const mplsLayersOptions = []
    for (let layer = 1; layer <= MPLS_LAYERS_MAX_COUNT; layer++) {
      mplsLayersOptions.push(
        <option key={`mplsLayer${layer}`} value={layer}>
          {layer}
        </option>
      )
    }

    const vlanLayersOptions = []
    for (let layer = 1; layer <= VLAN_LAYERS_MAX_COUNT; layer++) {
      vlanLayersOptions.push(
        <option key={`vlanLayer${layer}`} value={layer}>
          {layer}
        </option>
      )
    }

    const valueFilterCount = countValueFilters(valueFilter)
    const valueFilters = this.renderValueFilters(valueFilterCount)

    return (
      <>
        <FormGroup row>
          <Col md="12">
            <CheckGroup
              type="checkbox"
              name={`discardErrors${channelFilter.channel}`}
              id={`discardErrors${channelFilter.channel}`}
              onChange={this.onChangeDiscardErrors}
              checked={channelFilter.discardErrors}
            >
              Discard error packets
            </CheckGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="12">
            <CheckGroup
              type="checkbox"
              name={`exclusiveFilter${channelFilter.channel}`}
              id={`exclusiveFilter${channelFilter.channel}`}
              onChange={this.onChangeExclusiveFilter}
              checked={channelFilter.exclusiveFilter}
            >
              Reject packets matching this filter
            </CheckGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col md="12">
            <CheckGroup
              type="checkbox"
              name={`addressFilter${channelFilter.channel}`}
              id={`addressFilter${channelFilter.channel}`}
              onChange={this.onChangeUseAddressFilter}
              checked={channelFilter.useAddressFilter}
              invalid={invalidAddress1 || invalidAddress2}
            >
              Address filter
            </CheckGroup>
          </Col>
        </FormGroup>
        <Collapse isOpen={channelFilter.useAddressFilter}>
          <FormGroup row>
            <Label md="2" for={`addressType${channelFilter.channel}`}>
              <SubSectionLabel>Type</SubSectionLabel>
            </Label>
            <Col md="10">
              <Select
                name={`addressType${channelFilter.channel}`}
                id={`addressType${channelFilter.channel}`}
                value={addressType}
                onChange={this.onChangeAddressType}
              >
                <option value={AddressType.ADDRESS_TYPE_PHYSICAL}>Physical</option>
                <option value={AddressType.ADDRESS_TYPE_IP}>IP</option>
                <option value={AddressType.ADDRESS_TYPE_ETYPE2IP}>EType2 - IP</option>
                <option value={AddressType.ADDRESS_TYPE_8023IP}>802.3 - IP</option>
              </Select>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2" for={`address1${channelFilter.channel}`}>
              <SubSectionLabel>Address 1</SubSectionLabel>
            </Label>
            <Col md="10">
              <Input
                type="text"
                name={`address1${channelFilter.channel}`}
                id={`address1${channelFilter.channel}`}
                value={address1}
                onChange={this.onChangeAddress1}
                required
                invalid={invalidAddress1}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2" for={`addressDirection${channelFilter.channel}`}>
              <SubSectionLabel>Direction</SubSectionLabel>
            </Label>
            <Col md="10">
              <Select
                name={`addressDirection${channelFilter.channel}`}
                id={`addressDirection${channelFilter.channel}`}
                value={addressDirection}
                onChange={this.onChangeAddressDirection}
              >
                <option value={FilterDirection.FILTER_DIRECTION_ACCEPT_1_TO_2}>
                  {"\u2193 Address 1 to 2"}
                </option>
                <option value={FilterDirection.FILTER_DIRECTION_ACCEPT_2_TO_1}>
                  {"\u2191 Address 2 to 1"}
                </option>
                <option value={FilterDirection.FILTER_DIRECTION_BOTH}>
                  {"\u2195 Both directions"}
                </option>
              </Select>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2" for={`address2${channelFilter.channel}`}>
              <SubSectionLabel>Address 2</SubSectionLabel>
            </Label>
            <Col md="10">
              <RadioInputWrapper>
                <CheckGroup
                  type="radio"
                  name={`address2Radios${channelFilter.channel}`}
                  id={`address2Enabled${channelFilter.channel}`}
                  checked={!anyAddress}
                  onChange={this.onChangeAddress2Type.bind(this, channelFilter.channel)}
                />
                <Input
                  type="text"
                  name={`address2${channelFilter.channel}`}
                  id={`address2${channelFilter.channel}`}
                  value={address2}
                  onChange={this.onChangeAddress2}
                  invalid={invalidAddress2}
                  disabled={anyAddress}
                  required
                />
              </RadioInputWrapper>
              <CheckGroup
                type="radio"
                name={`address2Radios${channelFilter.channel}`}
                id={`address2AnyEnabled${channelFilter.channel}`}
                checked={anyAddress}
                onChange={this.onChangeAddress2Type.bind(this, channelFilter.channel)}
              >
                Any address
              </CheckGroup>
            </Col>
          </FormGroup>
        </Collapse>
        <FormGroup row>
          <Col md="12">
            <CheckGroup
              type="checkbox"
              name={`portFilter${channelFilter.channel}`}
              id={`portFilter${channelFilter.channel}`}
              onChange={this.onChangeUsePortFilter}
              checked={channelFilter.usePortFilter}
              invalid={invalidPort1 || invalidPort2}
            >
              Port filter
            </CheckGroup>
          </Col>
        </FormGroup>
        <Collapse isOpen={channelFilter.usePortFilter}>
          <FormGroup row>
            <Label md="2" for={`portType${channelFilter.channel}`}>
              <SubSectionLabel>Type</SubSectionLabel>
            </Label>
            <Col md="10">
              <Select
                name={`portType${channelFilter.channel}`}
                id={`portType${channelFilter.channel}`}
                value={portType}
                onChange={this.onChangePortType}
              >
                <option value={PortType.PORT_TYPE_TCP_UDP}>TCP-UDP</option>
                <option value={PortType.PORT_TYPE_TCP}>TCP</option>
                <option value={PortType.PORT_TYPE_UDP}>UDP</option>
              </Select>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2" for={`port1${channelFilter.channel}`}>
              <SubSectionLabel>Port 1</SubSectionLabel>
            </Label>
            <Col md="10">
              <Input
                type="text"
                name={`port1${channelFilter.channel}`}
                id={`port1${channelFilter.channel}`}
                value={port1}
                onChange={this.onChangePort1}
                required
                invalid={invalidPort1}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2" for={`portDirection${channelFilter.channel}`}>
              <SubSectionLabel>Direction</SubSectionLabel>
            </Label>
            <Col md="10">
              <Select
                name={`portDirection${channelFilter.channel}`}
                id={`portDirection${channelFilter.channel}`}
                value={portDirection}
                onChange={this.onChangePortDirection}
              >
                <option value={FilterDirection.FILTER_DIRECTION_ACCEPT_1_TO_2}>
                  {"\u2193 Port 1 to 2"}
                </option>
                <option value={FilterDirection.FILTER_DIRECTION_ACCEPT_2_TO_1}>
                  {"\u2191 Port 2 to 1"}
                </option>
                <option value={FilterDirection.FILTER_DIRECTION_BOTH}>
                  {"\u2195 Both directions"}
                </option>
              </Select>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label md="2" for={`port2${channelFilter.channel}`}>
              <SubSectionLabel>Port 2</SubSectionLabel>
            </Label>
            <Col md="10">
              <RadioInputWrapper>
                <CheckGroup
                  type="radio"
                  name={`port2Radios${channelFilter.channel}`}
                  id={`port2Enabled${channelFilter.channel}`}
                  checked={!anyPort}
                  onChange={this.onChangePort2Type.bind(this, channelFilter.channel)}
                />
                <Input
                  type="text"
                  name={`port2${channelFilter.channel}`}
                  id={`port2${channelFilter.channel}`}
                  value={port2}
                  onChange={this.onChangePort2}
                  invalid={invalidPort2}
                  disabled={anyPort}
                  required
                />
              </RadioInputWrapper>
              <CheckGroup
                type="radio"
                name={`port2Radios${channelFilter.channel}`}
                id={`port2AnyEnabled${channelFilter.channel}`}
                checked={anyPort}
                onChange={this.onChangePort2Type.bind(this, channelFilter.channel)}
              >
                Any port
              </CheckGroup>
            </Col>
          </FormGroup>
        </Collapse>
        {supportVLANMPLS && (
          <>
            <FormGroup row>
              <Col md="12">
                <CheckGroup
                  type="checkbox"
                  name={`vlanFilter${channelFilter.channel}`}
                  id={`vlanFilter${channelFilter.channel}`}
                  onChange={this.onChangeUseVLANFilter}
                  checked={channelFilter.useVLANFilter}
                  invalid={invalidVLAN}
                >
                  VLAN filter
                </CheckGroup>
              </Col>
            </FormGroup>
            <Collapse isOpen={channelFilter.useVLANFilter}>
              <FormGroup row>
                <Label md="2" for={`vlanids${channelFilter.channel}`}>
                  <SubSectionLabel>IDs</SubSectionLabel>
                </Label>
                <Col md="7">
                  <Input
                    type="text"
                    name={`vlanids${channelFilter.channel}`}
                    id={`vlanids${channelFilter.channel}`}
                    value={vlan}
                    onChange={this.onChangeVLAN}
                    required
                    invalid={invalidVLAN}
                  />
                </Col>
                <Col md="3" style={{ textAlign: "right" }}>
                  <span>(comma separated, maximum of 32 values)</span>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="2" for={`vlanlayers${channelFilter.channel}`}>
                  <SubSectionLabel>Layers</SubSectionLabel>
                </Label>
                <Col md="10">
                  <Select
                    name={`vlanlayers${channelFilter.channel}`}
                    id={`vlanlayers${channelFilter.channel}`}
                    value={vlanLayers}
                    onChange={this.onChangeVLANLayers}
                  >
                    {vlanLayersOptions}
                  </Select>
                </Col>
              </FormGroup>
            </Collapse>
            <FormGroup row>
              <Col md="12">
                <CheckGroup
                  type="checkbox"
                  name={`mplsFilter${channelFilter.channel}`}
                  id={`mplsFilter${channelFilter.channel}`}
                  onChange={this.onChangeUseMPLSFilter}
                  checked={channelFilter.useMPLSFilter}
                  invalid={invalidMPLS}
                >
                  MPLS filter
                </CheckGroup>
              </Col>
            </FormGroup>
            <Collapse isOpen={channelFilter.useMPLSFilter}>
              <FormGroup row>
                <Label md="2" for={`mplslabels${channelFilter.channel}`}>
                  <SubSectionLabel>Labels</SubSectionLabel>
                </Label>
                <Col md="7">
                  <Input
                    type="text"
                    name={`mplslabels${channelFilter.channel}`}
                    id={`mplslabels${channelFilter.channel}`}
                    value={mpls}
                    onChange={this.onChangeMPLS}
                    required
                    invalid={invalidMPLS}
                  />
                </Col>
                <Col md="3" style={{ textAlign: "right" }}>
                  <span>(comma separated, maximum of 32 values)</span>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label md="2" for={`mplslayers${channelFilter.channel}`}>
                  <SubSectionLabel>Layers</SubSectionLabel>
                </Label>
                <Col md="10">
                  <Select
                    name={`mplslayers${channelFilter.channel}`}
                    id={`mplslayers${channelFilter.channel}`}
                    value={mplsLayers}
                    onChange={this.onChangeMPLSLayers}
                  >
                    {mplsLayersOptions}
                  </Select>
                </Col>
              </FormGroup>
            </Collapse>
          </>
        )}
        {supportValues && (
          <>
            <FormGroup row>
              <Col md="12">
                <CheckGroup
                  type="checkbox"
                  name={`valueFilter${channelFilter.channel}`}
                  id={`valueFilter${channelFilter.channel}`}
                  onChange={this.onChangeUseValuesFilter}
                  checked={channelFilter.useValueFilter}
                >
                  {invalidValueFilters.size === 0 ? (
                    <span>Value filter</span>
                  ) : (
                    <ErrorText>Value filter</ErrorText>
                  )}
                </CheckGroup>
              </Col>
            </FormGroup>
            <Collapse isOpen={channelFilter.useValueFilter}>
              <FormGroup row>
                <Col md="12">
                  <ButtonBar style={{ marginBottom: "0.5rem" }}>
                    <LightButton
                      size="sm"
                      id="valueFilterAnd"
                      disabled={valueFilterCount >= VALUES_MAX_COUNT}
                      onClick={this.onInsertValueFilter.bind(this, valueFilter, true)}
                    >
                      And
                    </LightButton>
                    <LightButton
                      size="sm"
                      id="valueFilterOr"
                      disabled={valueFilterCount >= VALUES_MAX_COUNT}
                      onClick={this.onInsertValueFilter.bind(this, valueFilter, false)}
                    >
                      Or
                    </LightButton>
                    <LightButton
                      size="sm"
                      id="valueFilterDelete"
                      disabled={valueFilterCount === 0}
                      onClick={this.onDeleteAllValueFilters.bind(this)}
                    >
                      <FontAwesome name="trash-o" /> Delete All
                    </LightButton>
                  </ButtonBar>
                  {valueFilterCount > 0 ? (
                    <HardwareValueFiltersView>{valueFilters}</HardwareValueFiltersView>
                  ) : (
                    <HardwareValueFiltersView>
                      <EmptyValueFilterRowStyled>
                        <NoData>No value filters added</NoData>
                      </EmptyValueFilterRowStyled>
                    </HardwareValueFiltersView>
                  )}
                </Col>
              </FormGroup>
            </Collapse>
          </>
        )}
      </>
    )
  }
}
