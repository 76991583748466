import React from "react"
import { ContentOuter, HeaderDiv } from "./utils"
import styled from "styled-components"
import { useReconstructionsContentsContext } from "./reconstructions-contents"

const Image = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 8px;
`

interface ReconstructionsImageProps {
  reconstruction: string
}

const ReconstructionsImage: React.FC<ReconstructionsImageProps> = ({ reconstruction }) => {
  const { contentType, fileSize, slicedMessage, ellipse } = useReconstructionsContentsContext()

  const [imageSizeDetails, setImageSizeDetails] = React.useState<string>("0 x 0 pixels")

  const handleImageSizeDetailsChange = (e: any) => {
    setImageSizeDetails(`${e.target.width} x ${e.target.height} pixels`)
  }

  return (
    <>
      <HeaderDiv>
        {contentType?.includes("image") ? `${imageSizeDetails}; ${fileSize}` : `${fileSize}`}
        {slicedMessage}
        {ellipse}
      </HeaderDiv>
      <ContentOuter>
        <Image>
          <img
            onLoad={handleImageSizeDetailsChange}
            src={reconstruction}
            alt="Reconstructed Object Cannot Be Displayed"
          />
        </Image>
      </ContentOuter>
    </>
  )
}

export default ReconstructionsImage
