import themes from "../themes"

// Action types
export const types = {
  SET_THEME: "THEME/SET",
}

// Initial state
export const initialState = {
  currentThemeName: "Light",
}

// Reducer
export default function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case types.SET_THEME:
      return { ...state, currentThemeName: action.payload.themeName }
    default:
      break
  }

  return state
}

// Actions

export const setTheme = (themeName: string) => ({ type: types.SET_THEME, payload: { themeName } })

// Selectors

export const getThemes = (state: any) => themes

export const getCurrentTheme = (state: any) =>
  themes.find(theme => theme.name === state.currentThemeName) || themes[0]

export const isDarkTheme = (state: any) => getCurrentTheme(state).name === "Dark"
