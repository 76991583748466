export const EncodingCommands = [
  //{ text: "ASCII", codePage: 0 }, // system code page
  //{ text: "UTF-8", codePage: 65001 }, // utf-8
  { text: "Arabic (ISO)", codePage: 28596 }, // iso-8859-6
  { text: "Arabic (Windows)", codePage: 1256 }, // windows-1256
  { text: "Baltic (ISO)", codePage: 28594 }, // iso-8859-4
  { text: "Baltic (Windows)", codePage: 1257 }, // windows-1257
  { text: "Central European (ISO)", codePage: 28592 }, // iso-8859-2
  { text: "Central European (Windows)", codePage: 1250 }, // windows-1250
  //{ text: "Chinese Simplified (EUC)", codePage: 51936 }, // euc-cn
  { text: "Chinese Simplified (GB2312)", codePage: 936 }, // gb2312
  { text: "Chinese Simplified (GB18030)", codePage: 54936 }, // gb18030
  { text: "Chinese Traditional (Big5)", codePage: 950 }, // big5
  { text: "Cyrillic (ISO)", codePage: 28595 }, // iso-8859-5
  { text: "Cyrillic (KOI8-R)", codePage: 20866 }, // koi8-r
  { text: "Cyrillic (KOI8-U)", codePage: 21866 }, // koi8-u
  { text: "Cyrillic (Windows)", codePage: 1251 }, // windows-1251
  { text: "EBCDIC (US)", codePage: 37 }, // ibm037
  { text: "Greek (ISO)", codePage: 28597 }, // iso-8859-7
  { text: "Greek (Windows)", codePage: 1253 }, // windows-1253
  { text: "Hebrew (ISO)", codePage: 28598 }, // iso-8859-8
  { text: "Hebrew (Windows)", codePage: 1255 }, // windows-1255
  { text: "Japanese (EUC)", codePage: 20932 }, // euc-jp
  { text: "Japanese (Shift-JIS)", codePage: 932 }, // shift_jis
  { text: "Korean (EUC)", codePage: 51949 }, // euc-kr
  { text: "Thai (Windows)", codePage: 874 }, // windows-874
  { text: "Turkish (ISO)", codePage: 28599 }, // iso-8859-9
  { text: "Turkish (Windows)", codePage: 1254 }, // windows-1254
  { text: "Western European (ISO)", codePage: 28591 }, // iso-8859-1
  { text: "Western European (Windows)", codePage: 1252 }, // windows-1252
  { text: "Vietnamese (Windows)", codePage: 1258 }, // windows-1258
]

export enum LogicalFilterOperation {
  LOGICAL_FILTER_OPERATION_AND = 0,
  LOGICAL_FILTER_OPERATION_OR = 1,
  LOGICAL_FILTER_OPERATION_NOT = 2,
}

export enum MatchCriteria {
  MATCH_CRITERIA_FILTERS,
  MATCH_CRITERIA_ASCII,
  MATCH_CRITERIA_LENGTH,
  MATCH_CRITERIA_PLUGIN,
  MATCH_CRITERIA_RANGE,
  MATCH_CRITERIA_TIME,
}

export enum MatchSense {
  MATCH_SENSE_NORMAL,
  MATCH_SENSE_INVERTED,
}

export enum MatchSelection {
  MATCH_SELECTION_REPLACE,
  MATCH_SELECTION_ADD_TO,
}

export enum PatternFilterType {
  PATTERN_FILTER_TYPE_ASCII = 0,
  PATTERN_FILTER_TYPE_UNICODE = 1,
  PATTERN_FILTER_TYPE_RAW_DATA = 2,
  PATTERN_FILTER_TYPE_EBCDIC = 3,
  PATTERN_FILTER_TYPE_REGEX = 4,
}

export enum ValueFilterType {
  VALUE_FILTER_TYPE_1_BYTE_UNSIGNED = 0,
  VALUE_FILTER_TYPE_1_BYTE_SIGNED = 1,
  VALUE_FILTER_TYPE_2_BYTES_UNSIGNED = 2,
  VALUE_FILTER_TYPE_2_BYTES_SIGNED = 3,
  VALUE_FILTER_TYPE_4_BYTES_UNSIGNED = 4,
  VALUE_FILTER_TYPE_4_BYTES_SIGNED = 5,
  VALUE_FILTER_TYPE_8_BYTES_UNSIGNED = 6,
  VALUE_FILTER_TYPE_8_BYTES_SIGNED = 7,
}
