import * as React from "react"
import Cookies from "js-cookie"
import { Form, FormGroup } from "reactstrap"
import { Alert } from "../common/Alert"
import { Input } from "../common/Input"
import { Modal, ModalHeader, ModalBody, ModalFooter } from "../common/Modal"
import { PrimaryButton, SecondaryButton } from "../common/Buttons"

type LoginModalProps = {
  username: string
  password: string
  twoFactor: boolean
  title: React.ReactNode
  subTitle?: React.ReactNode
  okText?: string
  error?: React.ReactNode
  onOK: (username: string, password: string, otp: string, clientAuth: boolean) => void
  onCancel: () => void
}

export const LoginModal = (props: LoginModalProps) => {
  const [username, setUsername] = React.useState(props.username)
  const [password, setPassword] = React.useState(props.password)
  const [otp, setOtp] = React.useState("")

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    props.onOK(username, password, otp, false)
  }

  function handleLoginCertificate() {
    props.onOK("", "", "", true)
  }

  const isValid = props.twoFactor ? otp.length >= 6 : username.length > 0 && password.length > 0

  return (
    <Modal isOpen={true} toggle={props.onCancel}>
      <ModalHeader toggle={props.onCancel}>{props.title}</ModalHeader>
      {props.subTitle ? props.subTitle : null}
      <ModalBody>
        <Form id="loginForm" onSubmit={handleSubmit} noValidate>
          {props.twoFactor ? (
            <>
              <FormGroup>
                Enter the code from your two-factor app.
                <br />
                If you've lost your device, you may enter one of your recovery codes.
              </FormGroup>
              <FormGroup noMargin>
                <Input
                  type="text"
                  name="login--otp"
                  id="login--otp"
                  aria-label="Two-factor code"
                  placeholder="Tow-factor code"
                  inputMode="numeric"
                  pattern="[0-9]{6}"
                  value={otp}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setOtp(e.target.value)}
                  autoFocus
                  autoComplete="one-time-code"
                />
              </FormGroup>
            </>
          ) : (
            <>
              <FormGroup>
                <Input
                  type="text"
                  name="login--username"
                  id="login--username"
                  aria-label="Username"
                  placeholder="Username"
                  value={username}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
                  autoFocus
                  autoComplete="engine-login--username"
                />
              </FormGroup>
              <FormGroup noMargin>
                <Input
                  type="password"
                  name="login--password"
                  id="login--password"
                  aria-label="Password"
                  placeholder="Password"
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                  autoComplete="engine-login--password"
                />
              </FormGroup>
            </>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <SecondaryButton onClick={props.onCancel}>Cancel</SecondaryButton>
        <PrimaryButton type="submit" form="loginForm" disabled={!isValid}>
          {props.okText ? props.okText : "OK"}
        </PrimaryButton>
        {!props.twoFactor && Cookies.get("opt-authentication") === "certificate" && (
          <PrimaryButton onClick={handleLoginCertificate}>Login with Certificate</PrimaryButton>
        )}
      </ModalFooter>
      {props.error ? (
        <Alert
          color="danger"
          className="text-center"
          style={{ marginTop: "1rem", marginBottom: 0 }}
        >
          {props.error}
        </Alert>
      ) : null}
    </Modal>
  )
}

export default LoginModal
