import * as React from "react"
import { produce } from "immer"
import { Col, Form, FormGroup, Row } from "reactstrap"
import FontAwesome from "react-fontawesome"
import { TableCellProps } from "react-virtualized"
import styled, { withTheme } from "styled-components"
import { v4 as uuid } from "uuid"
import { PrimaryButton, SecondaryButton, IconButton } from "../common/Buttons"
import { PopoverHeader, PopoverBody } from "../common/Popover"
import { UncontrolledPopover } from "../common/UncontrolledPopover"
import FilterBox from "../common/FilterBox"
import { CheckGroup } from "../common/Form"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../common/Modal"
import { OmniTable } from "../common/OmniTable"
import { ViewContent, ViewHeader, ViewHeaderButtons } from "../common/View"
import { GraphItemObject, Protocol, ProtocolGraphItemObject } from "../../api/types"
import { GraphItemUnitType } from "../../api/types/peekTypes"
import { formatColorForTheme } from "../../utils/formatUtils"

const columnDesc = [
  {
    dataKey: "shortName",
    width: 200,
    flexGrow: 1,
  },
  {
    dataKey: "hierName",
    width: 200,
    flexGrow: 1,
  },
  {
    dataKey: "description",
    width: 200,
    flexGrow: 2,
  },
  {
    dataKey: "buttons",
    label: "",
    width: 25,
    flexShrink: 0,
    disableSort: true,
  },
]

const ButtonStrip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

type ProtocolsModalProps = {
  clsid: string
  graphItems: GraphItemObject[]
  protocols: Protocol[]
  theme: any
  unitType: GraphItemUnitType
  onCancel: (clsid: string) => void
  onSubmit: (clsid: string, graphItems: GraphItemObject[]) => void
}

type ProtocolsModalState = {
  filter: string
  graphItems: GraphItemObject[]
}

class ProtocolsModal extends React.Component<ProtocolsModalProps, ProtocolsModalState> {
  state: ProtocolsModalState = {
    filter: "",
    graphItems: this.props.graphItems,
  }

  cellRenderer = ({ dataKey, cellData, rowData }: TableCellProps) => {
    const { clsid } = this.props
    const { graphItems } = this.state
    const checked =
      graphItems
        .filter((graphItem: GraphItemObject) => graphItem.clsid === clsid)
        .findIndex(
          (graphItem: GraphItemObject) =>
            (graphItem as ProtocolGraphItemObject).protospecId === rowData.id
        ) !== -1

    let content = null
    switch (dataKey) {
      case "shortName":
        content = (
          <CheckGroup
            type="checkbox"
            name={rowData.id}
            id={`protocol-${rowData.id}`}
            onChange={this.onChangeCheckbox.bind(this, rowData)}
            checked={checked}
          >
            <span
              style={{ color: formatColorForTheme(rowData.color, this.props.theme.name) }}
              title={cellData}
            >
              {cellData}
            </span>
          </CheckGroup>
        )
        break
      case "hierName":
      case "description":
        content = (
          <span
            style={{ color: formatColorForTheme(rowData.color, this.props.theme.name) }}
            title={cellData}
          >
            {cellData}
          </span>
        )
        break
      case "buttons":
        if (rowData.description.length > 0) {
          content = (
            <ButtonStrip>
              <IconButton id={`detailsPopover-${rowData.id}`}>
                <FontAwesome fixedWidth name="info-circle" />
              </IconButton>
              <UncontrolledPopover
                trigger="focus"
                placement="left"
                target={`detailsPopover-${rowData.id}`}
                fade={false}
              >
                <PopoverHeader>{rowData.longName}</PopoverHeader>
                <PopoverBody>{rowData.description}</PopoverBody>
              </UncontrolledPopover>
            </ButtonStrip>
          )
        }
        break
      default:
        break
    }
    return content
  }

  onChangeCheckbox = (protocol: Protocol, event: React.ChangeEvent<HTMLInputElement>) => {
    const { clsid, unitType } = this.props
    const { checked } = event.target
    this.setState(
      produce(draft => {
        if (draft.graphItems) {
          if (checked) {
            draft.graphItems.push({
              clsid,
              description: protocol.description,
              id: uuid().toUpperCase(),
              name: protocol.shortName,
              protospecId: protocol.id,
              unitType,
            })
          } else {
            draft.graphItems = draft.graphItems.filter(
              (graphItem: GraphItemObject) =>
                graphItem.clsid === clsid &&
                (graphItem as ProtocolGraphItemObject).protospecId !== protocol.id
            )
          }
        }
      })
    )
  }

  onChangeFilter = (filter: string) => {
    this.setState({ filter })
  }

  onCancel = () => {
    const { clsid } = this.props
    this.props.onCancel(clsid)
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const { clsid } = this.props
    const { graphItems } = this.state
    this.props.onSubmit(clsid, graphItems)
  }

  render() {
    let { protocols } = this.props
    const { filter } = this.state
    if (protocols && filter) {
      const lowerCaseFilter = filter.toLowerCase()
      protocols = protocols.filter(
        (protocol: Protocol) => protocol.shortName.toLowerCase().indexOf(lowerCaseFilter) !== -1
      )
    }

    return (
      <Modal size="lg" isOpen={true} style={{ maxWidth: "800px" }}>
        <ModalHeader toggle={this.onCancel}>Protocols</ModalHeader>
        <ModalBody>
          <Form id="protocols-form" onSubmit={this.onSubmit}>
            <Row>
              <Col lg={12}>
                <ViewHeader border={false} style={{ flexDirection: "row-reverse" }}>
                  <ViewHeaderButtons>
                    <FilterBox
                      aria-label="Search"
                      placeholder="Search"
                      onChange={this.onChangeFilter}
                      value={filter}
                    />
                  </ViewHeaderButtons>
                </ViewHeader>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormGroup noMargin>
                  <ViewContent>
                    <OmniTable
                      data={protocols}
                      rowCount={protocols.length}
                      rowHeight={25}
                      disableHeader
                      cellRenderer={this.cellRenderer}
                      columnDesc={columnDesc}
                    />
                  </ViewContent>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <SecondaryButton onClick={this.onCancel}>Cancel</SecondaryButton>
          <PrimaryButton type="submit" form="protocols-form">
            Update
          </PrimaryButton>
        </ModalFooter>
      </Modal>
    )
  }
}

const ProtocolsModalWithTheme = withTheme(ProtocolsModal)
export { ProtocolsModalWithTheme as ProtocolsModal }
