import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  LineChart,
  Line,
  ScatterChart,
  Scatter,
  PieChart,
  Pie,
} from "recharts"
import { ChartType } from "../api/types/chartTypes"

export const ChartComponents: any = {
  [ChartType.AREA]: {
    chart: AreaChart,
    chartProps: {},
    series: Area,
    seriesProps: {
      legendType: "square",
      isAnimationActive: false,
      dot: false,
      activeDot: { r: 4 },
    },
    gridProps: {},
    xAxisProps: {},
    yAxisProps: {},
  },
  [ChartType.STACKED_AREA]: {
    chart: AreaChart,
    chartProps: {},
    series: Area,
    seriesProps: {
      legendType: "square",
      isAnimationActive: false,
      dot: false,
      activeDot: { r: 4 },
      stackId: "a",
    },
    gridProps: {},
    xAxisProps: {},
    yAxisProps: {},
  },
  [ChartType.COLUMN]: {
    chart: BarChart,
    chartProps: {},
    series: Bar,
    seriesProps: {
      legendType: "square",
      isAnimationActive: false,
    },
    gridProps: {},
    xAxisProps: {},
    yAxisProps: {},
  },
  [ChartType.STACKED_COLUMN]: {
    chart: BarChart,
    chartProps: {},
    series: Bar,
    seriesProps: {
      legendType: "square",
      isAnimationActive: false,
      stackId: "a",
    },
    gridProps: {},
    xAxisProps: {},
    yAxisProps: {},
  },
  [ChartType.SKYLINE]: {
    chart: BarChart,
    chartProps: { barGap: 0, barCategoryGap: 0 },
    series: Bar,
    seriesProps: {
      legendType: "square",
      isAnimationActive: false,
    },
    gridProps: {},
    xAxisProps: {},
    yAxisProps: {},
  },
  [ChartType.STACKED_SKYLINE]: {
    chart: BarChart,
    chartProps: { barGap: 0, barCategoryGap: 0 },
    series: Bar,
    seriesProps: {
      legendType: "square",
      isAnimationActive: false,
      stackId: "a",
    },
    gridProps: {},
    xAxisProps: {},
    yAxisProps: {},
  },
  [ChartType.OVERLAID_SKYLINE]: {
    chart: AreaChart,
    chartProps: {},
    series: Area,
    seriesProps: {
      legendType: "square",
      isAnimationActive: false,
      dot: false,
      activeDot: { r: 4 },
      type: "step",
    },
    gridProps: {},
    xAxisProps: {},
    yAxisProps: {},
  },
  [ChartType.LINE]: {
    chart: LineChart,
    chartProps: {},
    series: Line,
    seriesProps: {
      legendType: "square",
      isAnimationActive: false,
      dot: false,
      activeDot: { r: 4 },
    },
    gridProps: {},
    xAxisProps: {},
    yAxisProps: {},
  },
  [ChartType.LINE_POINTS]: {
    chart: LineChart,
    chartProps: {},
    series: Line,
    seriesProps: {
      legendType: "square",
      isAnimationActive: false,
      dot: { r: 2.5 },
      activeDot: { r: 4 },
    },
    gridProps: {},
    xAxisProps: {},
    yAxisProps: {},
  },
  [ChartType.POINTS]: {
    chart: ScatterChart,
    chartProps: {},
    series: Scatter,
    seriesProps: {
      legendType: "square",
      isAnimationActive: false,
      dot: { r: 2.5 },
      activeDot: { r: 4 },
    },
    gridProps: {},
    xAxisProps: {},
    yAxisProps: {},
  },
  [ChartType.HORIZONTAL_BAR]: {
    chart: BarChart,
    chartProps: {
      layout: "vertical",
    },
    series: Bar,
    seriesProps: {
      layout: "vertical",
      legendType: "square",
      isAnimationActive: false,
    },
    gridProps: {
      horizontal: false,
    },
    xAxisProps: {},
    yAxisProps: {},
  },
  [ChartType.PIE]: {
    chart: PieChart,
    chartProps: {},
    series: Pie,
    seriesProps: {
      isAnimationActive: false,
      outerRadius: 80,
      label: ({ percent }: { percent: number }) => `${(percent * 100).toFixed(1)}%`,
    },
    gridProps: {},
    xAxisProps: {},
    yAxisProps: {},
  },
  [ChartType.DONUT]: {
    chart: PieChart,
    chartProps: {},
    series: Pie,
    seriesProps: {
      isAnimationActive: false,
      outerRadius: 80,
      innerRadius: 40,
      label: true,
    },
    gridProps: {},
    xAxisProps: {},
    yAxisProps: {},
  },
}
